import { map } from 'rxjs/operators';
import { CrossAppAds } from '@sdv/domain/ads/cross-app-ads';
import Resources from 'dating-mobile/src/resources';
import { Linking } from 'react-native';

export class StreamsDiscoveryAdsViewModel {
    constructor() {
        this.currentAd = CrossAppAds.shared().streamsDiscoveryAds.pipe(
            map(type => {
                switch (type) {
                    case CrossAppAds.streamsDiscoveryAdsType.uni:
                        return Resources.images.tubitPromoteCommon();
                    case CrossAppAds.streamsDiscoveryAdsType.mal:
                        return Resources.images.tubitPromoteMale();
                    case CrossAppAds.streamsDiscoveryAdsType.fem:
                        return Resources.images.tubitPromoteFemale();
                }
            }),
        );
    }

    // TODO: Перенести в domain/ads/cross-app-ads
    openAd() {
        Linking.openURL('https://tubit.onelink.me/zKoz/lovingaban');
    }
}
