import React from 'react';
import { TouchableOpacity, Image } from 'react-native';
import PropTypes from 'prop-types';
import LinearGradient from 'react-native-linear-gradient';
import { profileResource } from '@sdv/domain/resources/remote';
import IfConfigValue from '../../../components/config-value/if';
import ImageButton from '../../../components/navigation/views/image-button';
import Resources from '../../../resources';
import ReportButton from '../../../components/report-button';
import SwitcherConfigValue from '../../../components/config-value/switcher';
import styles from './styles';

function withNavigation(Component) {
  class Navigation extends React.Component {
    static displayName = 'user.profile.screen.navigation';

    static propTypes = {
      navigation: PropTypes.object.isRequired,
      id: PropTypes.string,
      backButtonPressed: PropTypes.func,
    };

    getId = () => {
      const { id, navigation } = this.props;

      return (
        id ||
        navigation.getParam('id') ||
        navigation.dangerouslyGetParent().getParam('id')
      );
    };

    backButtonPressed = () => {
      const { navigation } = this.props;

      if (navigation) {
        navigation.pop();
      }
    };

    onPopToRootButtonPress = () => {
      const { navigation } = this.props;

      if (navigation) {
        navigation.popToTop();
      }
    };

    isPreview() {
      const { navigation } = this.props;

      return navigation.getParam('preview');
    }

    render() {
      const { backButtonPressed } = this.props;

      return (
        <Component
          {...this.props}
          backButtonPressed={backButtonPressed}
          onPopToRootButtonPress={this.onPopToRootButtonPress}
          userId={this.getId()}
          isPreview={this.isPreview()}
        />
      );
    }
  }

  Navigation.navigationOptions = ({ navigation }) => {
    const id = navigation.getParam('getId');
    const navigationBarHidden = navigation.getParam('navigationBarHidden');
    const headerShown =
      typeof navigationBarHidden === 'boolean' ? !navigationBarHidden : true;

    function onClose() {
      navigation.pop();
    }

    return {
      headerShown,
      headerTitle: '',
      headerTransparent: true,
      headerStyle: null,
      headerBackground: navigation.isFocused() ? (
        <IfConfigValue
          path="markup.profile-screen.has-top-gradient"
          equalsTo={true}
        >
          <LinearGradient
            colors={['rgba(0,0,0,0.7)', 'transparent']}
            style={{ flex: 1 }}
          />
        </IfConfigValue>
      ) : null,
      headerLeft: () => (
        <IfConfigValue
          path="markup.profile-screen.has-navigation-back-button"
          equalsTo={true}
        >
          <ImageButton
            style={styles.backButton}
            image={Resources.images.backIcon()}
            onPress={onClose}
          />
        </IfConfigValue>
      ),
      headerRight: () => (
        <SwitcherConfigValue path="markup.profile-screen.navigation-right-button">
          {value => {
            switch (value || '') {
              case 'report':
                return id ? (
                  <ReportButton id={id} reportedContent={profileResource(id)} />
                ) : null;
              case 'close':
                return (
                  <TouchableOpacity
                    style={styles.closeButton}
                    onPress={onClose}
                  >
                    <Image source={Resources.images.closeIcon()} />
                  </TouchableOpacity>
                );
              default:
                return null;
            }
          }}
        </SwitcherConfigValue>
      ),
    };
  };

  return Navigation;
}

export default withNavigation;
