import Call from '../../call';
import getSource from '../../getSource';
import getTransport from '../transport/getTransport';

function NetworkEnhancer(attendee, config, api, bus) {
  this.bus = bus;

  let call = new Call(attendee, config, bus);
  let processing = false;
  const transport = getTransport(api, bus);
  const source = getSource(config);

  this.close = function() {
    call && call.destroy();
    call = null;

    processing = false;
  };

  this.isEstablishing = function() {
    return call.isEstablishing();
  };

  this.send = function(message) {
    if (!message.in && !message.out && message.resync) {
      if (message.cta && message.cta === 'decline') {
        message.network = message.network || {};
        message.network.declined = true;
      }

      transport.send(message);

      return;
    }

    if (processing) {
      return;
    }

    if (call.isRenegotiationNeeded() || !call.hasConnection()) {
      processing = true;

      call
        .getSessionDescription(source)
        .then(function(network) {
          if (!processing) {
            // соединение уже закрыто
            return;
          }

          network.ice = call.popIces();

          if (network.sdp.type === 'offer') {
            message.resync = 1;
          }

          if (message.audio) {
            network.audio = true;
          }

          network.version = call.version;
          network.phase = call.phase;
          message.network = network;
          transport.send(message);
          processing = false;
        })
        .catch(
          function(err) {
            console.error(err);
            this.bus.emit('command.chat.media.stop', attendee);
          }.bind(this),
        );

      return;
    }

    message.network = message.network || {};
    message.network.phase = call.phase;
    message.network.version = call.version;

    if (message.audio) {
      message.network.audio = true;
    }

    transport.send(message);
  };

  this._resolveConcurrent = function(message) {
    // по задумке тут нужен rollback на одной из сторон (https://datatracker.ietf.org/doc/draft-ietf-rtcweb-jsep/?include_text=1 4.1.4.2.)
    // но его конечно никто реализовывать не хочет, потому уничтожаем звонки и пробуем соединится заново

    if (+message['sender-id'] < +message['recipient-id']) {
      this.close();
      call = new Call(attendee, config, bus);
      this._process(message);
    }
  };

  function checkSdpSet(state, cb) {
    let timeSpend = 0;
    const step = 100;

    const interval = setInterval(function() {
      timeSpend += step;

      if (timeSpend > 10000 || !call || !call._connection) {
        console.error('cant set offer');
        clearInterval(interval);

        return;
      }

      if (call._connection.signalingState !== state) {
        return;
      }

      console.debug('offer was set ready to answer', timeSpend);
      clearInterval(interval);
      cb();
    }, step);
  }

  this._process = function(message) {
    const { network } = message;

    if (network) {
      call.setRemoteVersion(network.version || 0);

      if (
        call._connection.signalingState === 'have-local-offer' &&
        network.sdp &&
        network.sdp.type === 'offer'
      ) {
        this._resolveConcurrent(message);

        return;
      }

      network.audio && source.toggleAudio(network.audio);

      if (network.sdp || network.ice) {
        call
          .setSessionDescription(network)
          .then(
            function() {
              if (network.sdp && network.sdp.type === 'offer') {
                const testAnswer = {
                  'sender-id': message['recipient-id'],
                  'recipient-id': message['sender-id'],
                  revision: message.revision,
                };

                if (call._connection.signalingState !== 'have-remote-offer') {
                  console.warn(call._connection.signalingState);
                  // старые хромы выстреливают resolve раньше чем нужно (<48), canary уже ок (51)
                  checkSdpSet(
                    'have-remote-offer',
                    function() {
                      call._renegotiation = true;
                      this.send(testAnswer);
                    }.bind(this),
                  );

                  return;
                }

                this.send(testAnswer);
              }
            }.bind(this),
          )
          .catch(error => console.log(error));
      }
    }
  };
}

export default NetworkEnhancer;
