function compare(array1, array2) {
    return array1.length === array2.length && array1.every((v, i) => v === array2[i]);
}

function diff(prev, now) {
    let res,
        counter = 0;

    res = now.map(function (val, i) {

        if (diff(prev[i], val) !== null) {
            counter++;
            return val;
        }
 
        return prev[i];
        

    });

    if (!counter && prev.length === now.length) {
        return null;
    }

    return res;
}

export { compare, diff };
