import React from 'react';
import {
  Platform,
  FlatList,
  Keyboard,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import PropTypes from 'prop-types';
import url from 'url';
import fetch from '@sdv/commons/utils/request';
import guid from '@sdv/commons/utils/guid';
import { Places } from '@sdv/domain/app/google-places';
import ConfigBuilder from 'dating-mobile/src/app/config-builder';
import loadScript from 'dating-mobile/src/utils/load-script';

import TextInput from '../text-input';
import { getPlaceFromGeocodeData } from './utils';

import styles from './styles';

const googleApi = {
  protocol: 'https',
  hostname: 'maps.googleapis.com',
  pathname: 'maps/api/place/autocomplete/json',
};

const googleGeoCodeApi = {
  protocol: 'https',
  hostname: 'maps.googleapis.com',
  pathname: 'maps/api/geocode/json',
};

const SUCCESS = 200;

function basicRequest(requestUrl) {
  return new Promise(async (resolve, reject) => {
    try {
      const { responseText, status } = await fetch(requestUrl);

      if (status === SUCCESS) {
        const response = JSON.parse(responseText);

        if (response.error_message) {
          return reject(response.error_message);
        }

        resolve(response);
      }

      return reject(responseText);
    } catch (error) {
      return reject(error.message);
    }
  });
}

class PlaceAutocomplete extends React.Component {
  static state = { search: null, response: [] };

  static options = {
    language: 'en',
    types: '(regions)',
    key: ConfigBuilder.config.google?.apiKey,
  };

  static propTypes = {
    onDropDownShown: PropTypes.func,
    onSelect: PropTypes.func,
    disableTypewritingSelect: PropTypes.bool,
    onlyCities: PropTypes.bool,
    geocode: PropTypes.bool,
    placeholderTextColor: PropTypes.string,
    inputContainerStyle: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
    ]),
    style: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    placeholder: PropTypes.string,
    defaultValue: PropTypes.string,
    error: PropTypes.string,
    accessibilityLabel: PropTypes.string,
    testID: PropTypes.string,
  };

  state = { request: null, response: [], error: null, listVisible: false };

  componentDidMount() {
    if (Platform.OS === 'web' && !window.google) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${PlaceAutocomplete.options.key}&libraries=places`,
      );
    }
  }

  request = async (data, oldSession) => {
    const { session } = this.state;

    try {
      const response = await (Platform.OS === 'web'
        ? Places.shared().getPredictions(data)
        : basicRequest(data));

      if (oldSession && session !== oldSession) {
        return;
      }
      this.updateState({
        error: null,
        response: response.predictions || [],
        listVisible: response.predictions && response.predictions.length,
      });
    } catch (error) {
      if (oldSession && session !== oldSession) {
        return;
      }
      this.updateState({
        error,
        response: [],
        listVisible: false,
      });
    }
  };

  change = event => {
    const state = {
      session: guid(),
      request: event.nativeEvent.text,
      dropDownSelected: false,
    };

    const value = state.request.trim();

    if (!value) {
      state.session = null;
      state.listVisible = false;
      state.response = [];
      state.error = null;
    }

    this.updateState(state, () => {
      const { onSelect, disableTypewritingSelect } = this.props;
      const { session } = this.state;

      if (this.isPending) {
        return;
      }

      if (disableTypewritingSelect && onSelect) {
        onSelect(null);
      }

      if (value) {
        this.request(this.getPlaceData(value), session);
      }
    });
  };

  getPlaceData = value => {
    const { onlyCities } = this.props;
    const isWeb = Platform.OS === 'web';
    const query = isWeb
      ? {
          types: [PlaceAutocomplete.options.types],
          input: value,
        }
      : {
          ...PlaceAutocomplete.options,
          ...{ input: value },
        };

    if (onlyCities) {
      query.types = isWeb ? ['(cities)'] : '(cities)';
    }

    return isWeb ? query : url.format({ ...googleApi, query });
  };

  getGeocodeUrl = value => {
    const query = {
      ...PlaceAutocomplete.options,
      ...{ address: value },
    };

    return url.format({ ...googleGeoCodeApi, query });
  };

  selectPlace = id => () => {
    const { response } = this.state;
    const { description } = response.filter(value => value.place_id === id)[0];

    this.updateState(
      {
        request: description,
        dropDownSelected: true,
      },
      () => {
        this.select(description);
      },
    );
  };

  selectPlaceText = () => {
    const { disableTypewritingSelect } = this.props;
    const { request } = this.state;

    if (disableTypewritingSelect) {
      this.updateState({ listVisible: false });
      Keyboard.dismiss();

      return;
    }

    this.select(request);
  };

  select = async value => {
    const { geocode, onSelect } = this.props;

    if (geocode) {
      try {
        const { results } = await (Platform.OS === 'web'
          ? Places.shared().getGeocode({ address: value })
          : basicRequest(this.getGeocodeUrl(value)));
        const newGeocode = (results || []).map(getPlaceFromGeocodeData);

        onSelect(newGeocode[0]);
      } catch (error) {
        console.log(error);
      }
    } else {
      onSelect(value);
    }

    this.updateState({ listVisible: false });
    Keyboard.dismiss();
  };

  clear = () => {
    this.updateState({
      request: null,
    });
  };

  updateState = (state, callback) => {
    const { onDropDownShown } = this.props;
    const { listVisible } = this.state;
    const dropDownStatusChanged =
      typeof state.listVisible !== 'undefined' &&
      state.listVisible !== listVisible;

    this.setState(state, (...args) => {
      if (dropDownStatusChanged && onDropDownShown)
        onDropDownShown(listVisible);

      if (callback) callback(...args);
    });
  };

  renderItem = ({ item }) => {
    const props = {
      style: styles.item,
      key: item.place_id,
    };

    if (Platform.OS === 'web') {
      props.onMouseDown = this.selectPlace(item.place_id);
    } else {
      props.onPress = this.selectPlace(item.place_id);
    }

    return (
      <TouchableOpacity {...props}>
        <Text style={styles.text}>{item.description}</Text>
      </TouchableOpacity>
    );
  };

  render() {
    const {
      placeholderTextColor,
      inputContainerStyle,
      style,
      placeholder,
      defaultValue,
      error,
      accessibilityLabel,
      testID,
    } = this.props;
    const { request, response, listVisible, error: stateError } = this.state;
    const value = request !== null ? request : defaultValue;

    return (
      <View style={styles.container}>
        <TextInput
          containerStyle={inputContainerStyle}
          style={style}
          placeholder={placeholder}
          placeholderTextColor={placeholderTextColor}
          value={value}
          onChange={this.change}
          autoCapitalize="none"
          onBlur={this.selectPlaceText}
          error={error}
          accessibilityLabel={accessibilityLabel}
          testID={testID}
        />
        {!!listVisible && (
          <View style={styles.listContainer}>
            <FlatList
              style={styles.list}
              numColumns={1}
              data={response}
              scrollEnabled
              keyExtractor={item => item.place_id}
              renderItem={this.renderItem}
              ListFooterComponent={() => (
                <Text style={styles.text}>{stateError}</Text>
              )}
              keyboardShouldPersistTaps="handled"
            />
          </View>
        )}
      </View>
    );
  }
}

export default PlaceAutocomplete;
