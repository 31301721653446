import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        width: 40,
        height: 40,
        justifyContent: 'center',
        alignItems: 'center',
    },
    button: {
        width: 40,
        height: 40,
        borderRadius: 20,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '$inputBackgroundColor',
        '@flavor lovinga': {
            backgroundColor: '$shadowColor',
        },
        elevation: 8,
        shadowRadius: 8,
        shadowOpacity: 0.15,
        shadowOffset: { width: 0, height: 0 },
    },

    badgeContainer: {
        position: 'absolute',
        right: 0,
        top: 0,
        backgroundColor: '$brandNotificationsColor',
        width: 20,
        height: 20,
        borderRadius: 10,
        borderWidth: 2,
        borderColor: 'white',
        justifyContent: 'center',
        alignItems: 'center',
        '@flavor tubit': {
            borderWidth: 0,
        },
    },
    badge: {
        color: 'white',
        fontFamily: '$brandFontFamily',
        fontSize: 10,
        fontWeight: '700',
        textAlign: 'center',
    },
});

export default styles;
