import React from 'react';
import PropTypes from 'prop-types';
import { STREAM_TOPICS_SELECTION } from 'dating-mobile/src/routing/router/constants';

import { StreamControls } from './stream-controls';
import styles from './styles';

function bindNavigation(Component) {
  class Navigation extends React.Component {
    static displayName =
      'dialogs.stream.screens.stream-description-preset.navigation';

    static propTypes = {
      navigation: PropTypes.object.isRequired,
    };

    goToTopicsSelector = () => {
      const { navigation } = this.props;

      navigation.navigate(STREAM_TOPICS_SELECTION);
    };

    render() {
      return (
        <Component onSelectTopics={this.goToTopicsSelector} {...this.props} />
      );
    }
  }

  Navigation.navigationOptions = () => ({
    headerTransparent: true,
    headerStyle: styles.header,
    headerRight: () => <StreamControls />,
  });

  return Navigation;
}

export default bindNavigation;
