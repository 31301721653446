export default function createActions(id) {

    class Actions {

        setSubject = (subject) => (dispatch) => {
            dispatch(subject);
        };

        setText = (text) => (dispatch) => {
            dispatch(text);
        };

        attach = (media) => (dispatch) => {
            dispatch(media);
        };

        detach = (media) => (dispatch) => {
            dispatch(media);
        };
    }

    Actions.displayName = createActions.getDisplayName(id);

    return Actions;
}

createActions.getDisplayName = function (id) {
    return `dialogs-mailbox-letter-writing.${id}`;
};
