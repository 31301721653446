import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $statusBarStyle: '$brandStatusBarStyle',
    $statusBarBackgroundColor: '$brandColor',
    $bottomContainerGradientColors: ['transparent', 'transparent'],
    $searchButtonEnabled: false,

    '@flavor dma': {
        $bottomContainerGradientColors: ['#ffffff00', 'white'],
        $searchButtonEnabled: true,
    },

    '@flavor secretly': {
        $searchButtonEnabled: true,
        $statusBarBackgroundColor: '$primaryBackgroundColor',
    },

    header: {
        '@flavor secretly': {
            backgroundColor: '$primaryBackgroundColor',
            borderBottomColor: '$primaryBackgroundColor',
            shadowColor: '$primaryBackgroundColor',
        },
    },

    headerTitle: {
        '@flavor secretly': {
            color: '$primaryTextColor',
        },
    },

    container: {
        flex: 1,
        backgroundColor: '$brandColor',
        '@flavor secretly': {
            backgroundColor: '$primaryBackgroundColor',
        },
    },

    scrollView: {
        flexGrow: 1,
    },

    content: {
        flexDirection: 'column',
        alignItems: 'stretch',
    },

    advancedParametersSection: {
        marginTop: 53,
        '@flavor dma': {
            marginTop: 32,
        },
    },

    advancedParametersSectionHeader: {
        marginLeft: 16,
        fontSize: 24,
        marginBottom: 16,
        fontWeight: 'bold',
        fontFamily: '$brandFontFamily',
        color: '$primaryTextColor',
    },

    optionsContainer: {
        flexDirection: 'column',
        alignItems: 'stretch',
    },

    options: {
        marginTop: 12,
        flexDirection: 'row',
        flexWrap: 'wrap',
    },

    option: {
        marginTop: 12,
        height: 32,
        borderRadius: 16,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '$brandTextColor',
    },

    deleteAllOption: {
        backgroundColor: '#7a70b7',
    },

    optionTitle: {
        marginLeft: 16,
        fontSize: 13,
        fontFamily: '$brandFontFamily',
        color: '$brandColor',
    },

    deleteAllOptionTitle: {
        marginRight: 16,
        color: 'white',
    },

    deleteOptionButton: {
        width: 32,
        height: 32,
        marginHorizontal: 4,
        alignItems: 'center',
        justifyContent: 'center',
    },

    addOptionButton: {
        alignSelf: 'center',
        marginTop: 30,
    },

    addOptionButtonTitle: {
        fontSize: 17,
        fontFamily: '$brandFontFamily',
        color: '$infoTextColor',
        textAlign: 'center',
    },

    searchButton: {
        position: 'absolute',
        bottom: 40,
        left: 40,
        right: 40,
        width: undefined,
    },
    searchTitle: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    advancedParametersButtonContainer: {
        marginLeft: 16,
        marginBottom: 19,
        flexDirection: 'row',
    },
    advancedParametersButtonText: {
        color: '$brandLinkTextColor',
        fontFamily: '$brandFontFamily',
        fontSize: 18,
        fontWeight: '500',
    },
    arrowImage: {
        width: 24,
        height: 24,
        marginLeft: 3,
    },

    bottomSpacing: {
        width: '100%',
        height: 120,
    },

    bottomContainer: {
        position: 'absolute',
        width: '100%',
        height: 140,
        bottom: 0,
    },

    bottomResetButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 60,
    },

    bottomResetButtonTitle: {
        color: '$minorAccentColor',
    },
});

export default styles;
