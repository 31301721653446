import { Subscription } from 'rxjs';
import {
  filter,
  switchMap,
  take,
  map,
  distinctUntilChanged,
} from 'rxjs/operators';
import { analytics as createFirebaseTracker } from 'react-native-firebase';
import AppsFlyerTracker from 'dating-mobile/src/services/tracking/apps-flyer-tracker';
import flux from '@sdv/domain/app/flux';
import Session from '@sdv/domain/authorization/session';
import User from '@sdv/domain/user/model';
import { Gender } from '@sdv/domain/user/gender';
import { ProfileBooster } from '@sdv/domain/user/profile-booster';
import { CurrentScreen } from 'dating-mobile/src/services/functional/current-screen';
import * as ROUTES from 'dating-mobile/src/routing/router/constants';

import Service from '../service';

const EVENTS = {
  boostBannerShowed: 'boost_banner_showed',
  boostBannerTapped: 'boost_banner_tapped',
};

const BANNER_TYPE = {
  green: 'green',
  purple: 'purple',
};

export class BoostBannerTracker extends Service {
  static displayName = 'service.tracking.boost-banner-tracker';

  componentDidMount() {
    this.subscribe();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  subscribe() {
    const bannerShowedSubscription = this.bannerShowed().subscribe(bannerType =>
      this.trackEvent(EVENTS.boostBannerShowed, { bannerType }),
    );
    const bannerTappedSubscription = this.bannerTapped().subscribe(bannerType =>
      this.trackEvent(EVENTS.boostBannerTapped, { bannerType }),
    );

    this.disposeBag = new Subscription();
    this.disposeBag.add(bannerShowedSubscription);
    this.disposeBag.add(bannerTappedSubscription);
  }

  unsubscribe() {
    if (this.disposeBag) {
      this.disposeBag.unsubscribe();
    }
  }

  bannerShowed() {
    return this.bannerVisible().pipe(
      switchMap(() => CurrentScreen.shared().stack),
      filter(stack =>
        stack.some(route => route === ROUTES.FEED || route === ROUTES.ACCOUNT),
      ),
      take(1), // Track only once per session
      switchMap(() => this.bannerType()),
    );
  }

  bannerTapped() {
    return this.bannerVisible().pipe(
      switchMap(() => CurrentScreen.shared().stack),
      filter(stack => stack.includes(ROUTES.PURCHASE_MODAL)),
      switchMap(() => this.bannerType()),
    );
  }

  bannerVisible() {
    return Session.shared().userId.pipe(
      filter(Boolean),
      switchMap(userId => ProfileBooster.shared(userId).endTime),
      filter(endTime => !endTime),
      distinctUntilChanged(),
    );
  }

  bannerType() {
    return Session.shared().userId.pipe(
      filter(Boolean),
      switchMap(userId => flux.get(User, userId).store.rxState()),
      filter(({ gender }) => gender === Gender.Male),
      map(({ id }) =>
        id && Number.parseInt(id.slice(0, -2), 10) % 2 === 0
          ? BANNER_TYPE.green
          : BANNER_TYPE.purple,
      ),
      take(1),
    );
  }

  trackEvent(event, values = {}) {
    createFirebaseTracker().logEvent(event, values);
    AppsFlyerTracker.trackEvent(
      event,
      values,
      () => {},
      () => {},
    );
  }
}
