import React from 'react';
import { TouchableWithoutFeedback, View } from 'react-native';
import PropTypes from 'prop-types';
import InboxSelector from 'dating-mobile/src/inbox/views/selector';
import { INBOX_MODES } from 'dating-mobile/src/inbox/inbox-modes';
import { InboxFilter } from '@sdv/domain/dialogs.mailbox/inbox';
import LettersList from 'dating-mobile/src/inbox/views/letters-list';
import Resources from 'dating-mobile/src/resources';
import InboxPlaceholder from 'dating-mobile/src/components/placeholder';

import AllLetterMailbox from '../../views/all-letters';

import styles from './styles';

export default class InboxScreenView extends React.PureComponent {
  static displayName = 'inbox.screens.view';

  static propTypes = {
    navigation: PropTypes.object,
    selectedMode: PropTypes.string,
    isSelectorVisible: PropTypes.bool,
    inboxModeChange: PropTypes.func,
    userId: PropTypes.string,
    dismissSelector: PropTypes.func,
  };

  state = { emptyHeader: false, emptyMainList: false };

  inboxModeChanged = mode => {
    const { inboxModeChange } = this.props;

    if (inboxModeChange) inboxModeChange(mode);
  };

  renderIntroductoryLetters = () => {
    const { selectedMode, userId } = this.props;
    let introductoryListFilter = null;

    switch (selectedMode) {
      case INBOX_MODES.ALL:
        introductoryListFilter = InboxFilter.allIntroductory;
        break;
      case INBOX_MODES.UNREAD:
        introductoryListFilter = InboxFilter.unreadIntroductory;
        break;
      case INBOX_MODES.READ_AND_UNANSWERED:
        break;
    }

    if (!introductoryListFilter) {
      return null;
    }

    return (
      <LettersList
        useDynamicLoading={false}
        defaultCount={2}
        loadMoreCount={10}
        loadMoreButtonTitleFormat={
          Resources.strings['inbox-load-more-introductory-letters-button-title']
        }
        style={styles.introductoryList}
        userId={userId}
        filter={introductoryListFilter}
        onEmptyList={this.onEmptyHeaderList}
      />
    );
  };

  onEmptyHeaderList = isEmpty => {
    this.setState({ emptyHeader: isEmpty });
  };

  onEmptyMainList = isEmpty => {
    this.setState({ emptyMainList: isEmpty });
  };

  onEmptyAllLetterList = isEmpty => {
    this.setState({ emptyHeader: isEmpty, emptyMainList: isEmpty });
  };

  renderInbox = () => {
    const { selectedMode, userId } = this.props;

    if (selectedMode === INBOX_MODES.ALL) {
      return (
        <AllLetterMailbox
          userId={userId}
          onEmptyList={this.onEmptyAllLetterList}
        />
      );
    }
    let flatListFilter = null;

    switch (selectedMode) {
      case INBOX_MODES.ALL:
        break;
      case INBOX_MODES.UNREAD:
        flatListFilter = InboxFilter.unread;
        break;
      case INBOX_MODES.READ_AND_UNANSWERED:
        flatListFilter = InboxFilter.readAndUnanswered;
        break;
    }

    return (
      <LettersList
        useDynamicLoading
        defaultCount={10}
        loadMoreCount={10}
        style={styles.list}
        userId={userId}
        filter={flatListFilter}
        HeaderComponent={this.renderIntroductoryLetters}
        onEmptyList={this.onEmptyMainList}
      />
    );
  };

  dismissSelector = () => {
    const { dismissSelector } = this.props;

    if (dismissSelector) dismissSelector();
  };

  render() {
    const { emptyHeader, emptyMainList } = this.state;
    const { navigation, isSelectorVisible, selectedMode } = this.props;
    const placeholderVisible = !!emptyHeader && !!emptyMainList;

    return (
      <View style={styles.container}>
        {this.renderInbox()}
        {placeholderVisible && (
          <InboxPlaceholder
            bodyText={Resources.strings['inbox-placeholder-text']}
            buttonText={Resources.strings['inbox-placeholder-button-title']}
            navigate={navigation.navigate}
          />
        )}
        {!!isSelectorVisible && (
          <TouchableWithoutFeedback onPress={this.dismissSelector}>
            <View style={styles.overlay} />
          </TouchableWithoutFeedback>
        )}
        {!!isSelectorVisible && (
          <View style={styles.inboxSelectorContainer}>
            <InboxSelector
              style={styles.inboxSelector}
              selectedMode={selectedMode}
              onChange={this.inboxModeChanged}
            />
          </View>
        )}
      </View>
    );
  }
}
