import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Text } from 'react-native';
import Resources from 'dating-mobile/src/resources';
import { DistanceToUser, AliasedDistanceToUser } from '@sdv/domain/location';

export class DistanceToUserView extends PureComponent {
    static propTypes = {
        userId: PropTypes.string,
        styles: PropTypes.any,
        prefix: PropTypes.node,
        suffix: PropTypes.node,
        locationAliasingEnabled: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        const { userId, locationAliasingEnabled } = props;

        this.distanceToUser = locationAliasingEnabled
            ? new AliasedDistanceToUser(userId)
            : new DistanceToUser(userId);

        this.state = {
            distance: null,
        };
    }

    componentDidMount() {
        this.subscribe();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { userId, locationAliasingEnabled } = this.props;

        if (
            (userId && prevProps.userId !== userId) ||
            (locationAliasingEnabled &&
                prevProps.locationAliasingEnabled !== locationAliasingEnabled)
        ) {
            this.subscribtion && this.subscribtion.unsubscribe();
            this.distanceToUser = locationAliasingEnabled
                ? new AliasedDistanceToUser(userId)
                : new DistanceToUser(userId);
            this.subscribe();
        }
    }

    componentWillUnmount() {
        this.subscribtion && this.subscribtion.unsubscribe();
    }

    subscribe = () => {
        this.subscribtion = this.distanceToUser.distance.subscribe(distance =>
            this.setState({
                distance,
            }),
        );
    };

    renderDistance = () => {
        const { distance } = this.state;
        const { prefix, suffix } = this.props;

        return [
            prefix,
            Resources.strings.formatString(Resources.strings['distance-to-user-km-away'], distance),
            suffix,
        ].filter(item => item);
    };

    render() {
        const { distance } = this.state;

        if (!distance) {
            return null;
        }

        return <Text style={this.props.styles}>{this.renderDistance()}</Text>;
    }
}
