import Navigator from 'dating-mobile/src/routing/navigator';
import { TURN_ON_NOTIFICATIONS_SCREEN } from 'dating-mobile/src/routing/router/constants';
import { singleton } from '@sdv/commons/utils/singleton';

class PushNotificationsManualAuthorization {
  static shared = singleton(() => new PushNotificationsManualAuthorization());

  askPushPermissions = () => {
    Navigator.navigate(TURN_ON_NOTIFICATIONS_SCREEN);
  };
}

export default PushNotificationsManualAuthorization;
