import React from 'react';
import PropTypes from 'prop-types';
import { connectActionSheet } from '@expo/react-native-action-sheet';
import Dialog from 'react-native-dialog';
import { Alert, Platform } from 'react-native';
import TextButton from 'dating-mobile/src/components/navigation/views/text-button';
import { UserBlocking } from '@sdv/domain/user/user-blocking';
import { finalize } from 'rxjs/operators';
import { UserProfileAliasing } from '@sdv/domain/user/user-profile-aliasing';
import { ChatAnonymity } from '@sdv/domain/chat/chat-anonymity';
import withImagePicker from 'dating-mobile/src/components/image-picker';
import { MESSAGE_TYPES } from 'dating-mobile/src/models/common.messages/model';
import MessagesModel, {
  getId,
} from 'dating-mobile/src/models/dialogs.messages/utils/messages-adapter';
import flux from '@sdv/domain/app/flux';
import withConfigValue from 'dating-mobile/src/components/config-value';
import { withBlockableUser } from 'dating-mobile/src/user/views/blocked-user/with-blockable-user';
import Resources from '../../resources';
import ImageButton from '../navigation/views/image-button';
import testId from '../../utils/test-id';
import identityId from '../../models/identity/controller/id';

import styles from './styles';

const ACTIONS_MAPPER = {
  EDIT_NAME: 'EDIT_NAME',
  SET_PHOTO: 'SET_PHOTO',
  BLOCK: 'BLOCK',
  UNBLOCK: 'UNBLOCK',
  DELETE: 'DELETE',
  REPORT: 'REPORT',
  CANCEL: 'CANCEL',
  DISCLOSE_PROFILE: 'DISCLOSE_PROFILE',
};

class ReportButton extends React.Component {
  static displayName = 'component.report-button';

  static propTypes = {
    id: PropTypes.string.isRequired,
    identity: PropTypes.string,
    tint: PropTypes.string,
    showIcon: PropTypes.bool,
    allowBlock: PropTypes.bool,
    allowDelete: PropTypes.bool,
    allowAlias: PropTypes.bool,
    reportedContent: PropTypes.string,
    source: PropTypes.object,
    select: PropTypes.func,
    onBlock: PropTypes.func,
    onUnblock: PropTypes.func,
    showActionSheetWithOptions: PropTypes.func,
    blockUserAlertTitle: PropTypes.string,
    blockUserAlertMessage: PropTypes.string,
    unblockUserAlertTitle: PropTypes.string,
    unblockUserAlertMessage: PropTypes.string,
    style: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    appLinkDomain: PropTypes.string,
    showProfileDisclosureButton: PropTypes.bool,
    blockUserOnReport: PropTypes.bool,
    isBlocked: PropTypes.bool,
    onChatDelete: PropTypes.func,
  };

  static defaultProps = {
    blockUserAlertTitle: Resources.strings['block-user-alert-title'],
    blockUserAlertMessage: Resources.strings['block-user-alert-message'],
    unblockUserAlertTitle: Resources.strings['unblock-user-alert-title'],
    unblockUserAlertMessage: Resources.strings['unblock-user-alert-message'],
    onBlock: () => {},
    onUnblock: () => {},
    onChatDelete: () => {},
  };

  static contextTypes = {
    flux: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.userAliases = new UserProfileAliasing();

    this.state = {
      dialogChangeNameVisibility: false,
      writingAsAnonymous: false,
    };
  }

  componentDidMount() {
    const { identity, id } = this.props;
    const chatAnonymity = new ChatAnonymity(identity, id);

    this.subscription = chatAnonymity.writingAsAnonymous.subscribe(
      writingAsAnonymous => {
        this.setState({ writingAsAnonymous });
      },
      () => {},
    );
  }

  componentWillReceiveProps(nextProps) {
    const { source } = this.props;

    if (nextProps.source?.uri !== source?.uri) {
      this.userAliases.setThumbnailAlias(nextProps.source, nextProps.id);
    }
  }

  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }

  renderDialog = () => {
    const { name, dialogChangeNameVisibility } = this.state;

    return (
      <Dialog.Container
        key="dialog-container"
        visible={dialogChangeNameVisibility}
      >
        <Dialog.Title>
          {Resources.strings['dialog-change-name-title']}
        </Dialog.Title>
        <Dialog.Description>
          {Resources.strings['dialog-change-name-description']}
        </Dialog.Description>
        <Dialog.Input
          autoFocus
          wrapperStyle={styles.input}
          value={name}
          onChangeText={this.handleChangeName}
        />
        <Dialog.Button
          label={Resources.strings.cancel}
          onPress={this.handleCancel}
        />
        <Dialog.Button label={Resources.strings.ok} onPress={this.handleOk} />
      </Dialog.Container>
    );
  };

  handleChangeName = name => {
    this.setState({
      name,
    });
  };

  handleCancel = () => {
    this.setState({
      dialogChangeNameVisibility: false,
    });
  };

  handleOk = () => {
    const { id } = this.props;
    const { name } = this.state;

    this.userAliases.setNameAlias(name, id);

    this.setState({
      dialogChangeNameVisibility: false,
    });
  };

  handlePressChangeName = () => {
    this.setState({
      dialogChangeNameVisibility: true,
    });
  };

  handleSetPhoto = () => {
    const { select } = this.props;

    if (select) {
      select();
    }
  };

  discloseProfile = () => {
    const { id, identity, appLinkDomain } = this.props;

    Alert.alert(
      Resources.strings['action-sheet-profile-disclosure-alert-message'],
      '',
      [
        {
          text: Resources.strings.ok,
          onPress: () =>
            appLinkDomain &&
            flux
              .get(MessagesModel, getId(identity, id))
              .actions.send(
                MESSAGE_TYPES.TEXT,
                `https://${appLinkDomain}/app/profile/${identity}`,
              ),
        },
        {
          text: Resources.strings.cancel,
          style: 'cancel',
        },
      ],
    );
  };

  getOptionsData = () => {
    const {
      allowBlock,
      allowAlias,
      showProfileDisclosureButton,
      isBlocked,
      allowDelete,
    } = this.props;
    const { writingAsAnonymous } = this.state;
    const options = [];

    if (!isBlocked && writingAsAnonymous && showProfileDisclosureButton) {
      options.push({
        id: ACTIONS_MAPPER.DISCLOSE_PROFILE,
        title:
          Resources.strings['action-sheet-profile-disclosure-button-title'],
        action: this.discloseProfile,
      });
    }

    if (!isBlocked && allowAlias && !writingAsAnonymous) {
      options.push({
        id: ACTIONS_MAPPER.EDIT_NAME,
        title: Resources.strings['action-sheet-edit-anon-nick-button-title'],
        action: this.handlePressChangeName,
      });

      options.push({
        id: ACTIONS_MAPPER.SET_PHOTO,
        title: Resources.strings['action-sheet-set-anon-photo-button-title'],
        action: this.handleSetPhoto,
      });
    }

    if (!isBlocked && allowBlock) {
      options.push({
        id: ACTIONS_MAPPER.BLOCK,
        title: Resources.strings['report-action-sheet-block-button-title'],
        action: this.blockUser,
      });
    }

    if (!isBlocked) {
      options.push({
        id: ACTIONS_MAPPER.REPORT,
        title: Resources.strings['report-action-sheet-report-button-title'],
        action: this.reportUser,
      });
    }

    if (isBlocked) {
      options.push({
        id: ACTIONS_MAPPER.UNBLOCK,
        title: Resources.strings['report-action-sheet-unblock-button-title'],
        action: this.unblockUser,
      });
    }

    if (allowDelete && isBlocked) {
      options.push({
        id: ACTIONS_MAPPER.DELETE,
        title: Resources.strings['report-action-sheet-delete-button-title'],
        action: this.deleteChat,
      });
    }

    options.push({
      id: ACTIONS_MAPPER.CANCEL,
      title: Resources.strings.cancel,
      action: () => null,
    });

    return options;
  };

  reportButtonTap = () => {
    const { showActionSheetWithOptions } = this.props;
    const optionsData = this.getOptionsData();
    const options = optionsData.map(item => item.title);

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex: optionsData.findIndex(
          item => item.id === ACTIONS_MAPPER.CANCEL,
        ),
        destructiveButtonIndex: optionsData.findIndex(
          item =>
            item.id === ACTIONS_MAPPER.BLOCK ||
            item.id === ACTIONS_MAPPER.DELETE,
        ), // this.props.allowBlock ? 0 : undefined
        showSeparators: Platform.OS === 'web',
      },
      buttonIndex => {
        if (optionsData[buttonIndex]) {
          optionsData[buttonIndex].action();
        }
      },
    );
  };

  blockUser = () => {
    const {
      identity,
      id,
      onBlock,
      blockUserAlertTitle,
      blockUserAlertMessage,
    } = this.props;

    Alert.alert(blockUserAlertTitle, blockUserAlertMessage, [
      {
        text: Resources.strings.block,
        onPress: () => {
          UserBlocking.shared(identity)
            .blockUser(id)
            .pipe(finalize(onBlock))
            .subscribe(
              () => {},
              () => {},
            );
        },
      },
      {
        text: Resources.strings.cancel,
        style: 'cancel',
      },
    ]);
  };

  unblockUser = () => {
    const {
      identity,
      id,
      onUnblock,
      unblockUserAlertTitle,
      unblockUserAlertMessage,
    } = this.props;

    Alert.alert(unblockUserAlertTitle, unblockUserAlertMessage, [
      {
        text: Resources.strings.ok,
        onPress: () => {
          UserBlocking.shared(identity)
            .unblockUser(id)
            .pipe(finalize(onUnblock))
            .subscribe(
              () => {},
              () => {},
            );
        },
      },
      {
        text: Resources.strings.cancel,
        style: 'cancel',
      },
    ]);
  };

  deleteChat = () => {
    const { identity, id, isBlocked, onChatDelete } = this.props;

    Alert.alert(
      Resources.strings['delete-chat-alert-title'],
      Resources.strings['delete-chat-alert-message'],
      [
        {
          text: Resources.strings.delete,
          onPress: () => {
            UserBlocking.shared(identity)
              .deleteChat(id, isBlocked)
              .pipe(finalize(onChatDelete))
              .subscribe(
                () => {},
                () => {},
              );
          },
        },
        {
          text: Resources.strings.cancel,
          style: 'cancel',
        },
      ],
    );
  };

  reportUser = () => {
    const {
      identity,
      id,
      reportedContent,
      blockUserOnReport,
      onBlock,
    } = this.props;

    if (blockUserOnReport) {
      UserBlocking.shared(identity)
        .blockUser(id)
        .pipe(finalize(onBlock))
        .subscribe(
          () => {},
          () => {},
        );
    }

    UserBlocking.shared(identity)
      .reportUser(id, reportedContent)
      .pipe(
        finalize(() => {
          Alert.alert(
            Resources.strings['report-alert-success-title'],
            Resources.strings['report-alert-success-message'],
            [{ text: 'OK', style: 'cancel' }],
            { cancelable: false },
          );
        }),
      )
      .subscribe(
        () => {},
        () => {},
      );
  };

  render() {
    const { showIcon, tint, style } = this.props;

    return [
      showIcon ? (
        <ImageButton
          key="report-button"
          image={Resources.images.reportIcon()}
          onPress={this.reportButtonTap}
          tint={tint}
          style={style}
          {...testId('Report button')}
        />
      ) : (
        <TextButton
          key="report-button"
          title="Report"
          onPress={this.reportButtonTap}
          tint={tint}
          style={style}
          {...testId('Report button')}
        />
      ),
      this.renderDialog(),
    ];
  }
}

export default withBlockableUser(
  withConfigValue(
    identityId(
      withImagePicker(connectActionSheet(ReportButton), {
        title: Resources.strings['action-sheet-set-anon-photo-button-title'],
      }),
      'identity',
    ),
    {
      appLinkDomain: 'app-link-associated-domain',
      blockUserOnReport: 'features.block-user-on-report',
      allowDelete: 'features.allow-delete-blocked-chats',
    },
  ),
  { userIdPropName: 'id' },
);
