import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    modes: {
        flex: 1,
        width: '100%',
        justifyContent: 'center'
    },
    selectedModeTitle: {
        marginLeft: 16,
        fontSize: 17,
        fontFamily: '$brandFontFamily',
        color: 'black',
        '@flavor dma': {
            fontWeight: '500'
        }
    },
    modeTitle: {
        marginLeft: 16,
        fontSize: 17,
        fontFamily: '$brandFontFamily',
        color: '$secondaryTextColor',
        '@flavor dma': {
            fontWeight: '500',
            color: '$infoTextColor'
        }
    },
    itemContainer: {
        height: 60,
        justifyContent: 'center',
        alignItems: 'flex-start',
        backgroundColor: '$secondaryBackgroundColor'
    },
    separator: {
        position: 'absolute',
        height: 1,
        width: '100%',
        marginLeft: 18,
        bottom: 0,
        backgroundColor: '$primaryBorderColor'
    },

    list: {
        backgroundColor: '$secondaryBackgroundColor'
    }
});

export default styles;
