import React from 'react';
import PropTypes from 'prop-types';
import SwitchItem from 'dating-mobile/src/components/switch-item';
import { ScrollView, View, StatusBar } from "react-native";
import styles from "./styles";
import Resources from "../../../resources";

export default class NotificationSettingsView extends React.Component {
    static displayName = 'user.screens.notifications-settings.view';
    static propTypes = {
        favoriteMessageToggle: PropTypes.func,
        messagesDigestToggle: PropTypes.func,
        birthdaysDigestToggle: PropTypes.func,
        favoriteMessageEnabled: PropTypes.bool,
        messagesDigestEnabled: PropTypes.bool,
        birthdaysDigestEnabled: PropTypes.bool,
        getSettings: PropTypes.func
    };

    shouldComponentUpdate(nextProps) {
        return this.props.favoriteMessageEnabled !== nextProps.favoriteMessageEnabled ||
            this.props.messagesDigestEnabled !== nextProps.messagesDigestEnabled ||
            this.props.birthdaysDigestEnabled !== nextProps.birthdaysDigestEnabled
    };

    componentDidMount() {
        this.props.getSettings();
    }

    render() {
        return (
            <View style={styles.container}>
                <StatusBar
                    barStyle={styles.$statusBarStyle}
                    backgroundColor={styles.$statusBarBackgroundColor}
                />
                <ScrollView style={styles.scrollView} contentContainerStyle={styles.content}>
                    <SwitchItem
                        title={Resources.strings['notification-setting-favorite-message']}
                        titleStyle={styles.title}
                        titleContainerStyle={styles.titleContainer}
                        value={this.props.favoriteMessageEnabled}
                        onValueChange={this.props.favoriteMessageToggle}
                    />
                    <SwitchItem
                        title={Resources.strings['notification-setting-messages-digest']}
                        titleStyle={styles.title}
                        titleContainerStyle={styles.titleContainer}
                        value={this.props.messagesDigestEnabled}
                        onValueChange={this.props.messagesDigestToggle}
                    />
                    <SwitchItem
                        title={Resources.strings['notification-setting-birthday-digest']}
                        titleStyle={styles.title}
                        titleContainerStyle={styles.titleContainer}
                        value={this.props.birthdaysDigestEnabled}
                        onValueChange={this.props.birthdaysDigestToggle}
                    />
                </ScrollView>
            </View>
        )
    }
}
