import EStyleSheet from 'react-native-extended-stylesheet';


const styles = EStyleSheet.create({
    switchItem: {
        marginLeft: 0,
        marginRight: 0
    },

    switchItemTitle: {
        fontSize: 10,
    }
});

export default styles;
