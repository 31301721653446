import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: 25,
    },
    textContainer: {
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexShrink: 1,
    },
    image: {
        width: 36,
        height: 36,
        marginRight: 20,
    },
    title: {
        textAlign: 'left',
        fontFamily: '$brandFontFamily',
        fontSize: 17,
        fontWeight: 'bold',
        color: '#000000',
    },

    description: {
        textAlign: 'left',
        fontFamily: '$brandFontFamily',
        fontSize: 15,
        color: '#000000',
    },
});

export default styles;
