import React from 'react';
import PropTypes from 'prop-types';
import { View, TouchableOpacity } from 'react-native';
import Sticker from 'dating-mobile/src/dialogs/common/views/sticker';
import withAspectRatio from 'dating-mobile/src/components/aspect-ratio';
import styles from './styles';

const AspectRatioSticker = withAspectRatio(Sticker);

class StickerItem extends React.Component {
    static displayName = 'dialogs.common.stickers.item';
    static propTypes = {
        sticker: PropTypes.string,
        onPress: PropTypes.func
    };

    shouldComponentUpdate(nextProps) {
        return this.props.sticker !== nextProps.sticker;
    }

    onPressed = () => {
        this.props.onPress && this.props.onPress(this.props.sticker);
    };

    render() {
        return (
            <TouchableOpacity style={styles.container} onPress={this.onPressed}>
                <AspectRatioSticker stickerName={this.props.sticker} style={styles.image} />
            </TouchableOpacity>
        )
    }
}

export default StickerItem;
