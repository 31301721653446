import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({

    container: {
        backgroundColor: '$primaryBackgroundColor',
        flex: 1
    },

    supportMessage: {
        fontFamily: '$brandFontFamily',
        fontSize: 16,
        margin: 16
    },

    title: {
        color: '$primaryTextColor',
    },

    icon: {
        tintColor: undefined
    },

    whatsAppNumber: {
        fontSize: 17,
        color: '#999'
    },

    header: {
        backgroundColor: '$primaryBackgroundColor',
        borderBottomColor: '$primaryBackgroundColor',
        shadowColor: '$primaryBackgroundColor'
    },

    headerTitle: {
        color: '$primaryTextColor'
    },

    tint: {
        color: '$primaryTextColor'
    },

    titleContainer: {
        borderBottomColor: '$primaryBorderColor'
    }
});

export default styles;
