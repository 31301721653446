import UserController from '../../../../models/user/controllers';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import styles from './styles';
import Photo from '../../../../components/photo';
import Button from '../../../../components/buttons/base';
import IfConfigValue from '../../../../components/config-value/if';
import TagsController from '../../../../models/user.tags.targeted/controllers';
import React from 'react';
import testId from '../../../../utils/test-id';

class FinishedBroadcastView extends React.PureComponent {

    constructor(props) {
        super(props);
        const hidden = props.tagsLoaded === true ? this.props.tags.favorite === true : true;
        this.state = { followBlockHidden: hidden };
    }

    componentDidUpdate(prevProps) {

        if (this.props.tagsLoaded === true && prevProps.tagsLoaded === false) {

            this.setState({
                followBlockHidden: this.props.tags.favorite === true
            });

        }

    }

    render() {
        const basename = this.props.user.thumbnail;
        const text = `${this.props.user.name} has finished the broadcast`;

        return (
            <View style={styles.container}>
                <Photo
                    key={basename}
                    userId={this.props.user.id}
                    basename={basename}
                    size={'.50x50'}
                    blurRadius={3}
                    style={styles.thumbnail}
                    {...testId('Streamer photo')}
                />
                <View style={styles.overlay}/>
                <View style={styles.textContainer}>
                    <Text style={styles.text}>{text}</Text>
                    {
                        !this.state.followBlockHidden && (
                            <Text style={styles.followText}>Follow streamer to hook up with the next broadcasts</Text>
                        )
                    }
                </View>
                <View style={styles.backToStreamsContainer}>
                    <Text style={styles.backToStreamsText}>Watch next popular broadcasts</Text>
                    <Button title={'Discover'} onPress={this.props.backButtonTap}/>
                </View>
            </View>
        );

    }

}

const FinishedBroadcastViewHoc = TagsController((props) => {
    return <FinishedBroadcastView {...props}/>;
});

const FinishedBroadcast = UserController(FinishedBroadcastViewHoc);

export default FinishedBroadcast;
