import PropTypes from 'prop-types';
import IdentityModel from '@sdv/domain/identity/model';
import UserDefaults from 'react-native-default-preference';
import { WINK_TYPES } from '@sdv/domain/dialogs.wink';
import { UserNotifying } from '@sdv/domain/notifications/user-notifying';
import { NOTIFICATION_PRIORITY } from '@sdv/domain/notifications';
import { USER_TAGS } from '@sdv/domain/user/tags/user-tags';
import Navigator from 'dating-mobile/src/routing/navigator';
import * as ROUTES from 'dating-mobile/src/routing/router/constants';
import { NotificationType } from '@sdv/domain/notifications/types';
import Service from '../../service';
import Resources from '../../../resources';
import ConfigValue from '../../../components/config-value';
import { randomValuesFromArray } from '../../../utils/random';
import { parseUserId } from '../../../utils/user-id';

const WINK_NOTIFICATION_SHOWN_STORAGE_KEY =
  'service.notifications-provider.wink-notification-snown';

class NotificationsProvider extends Service {
  static displayName = 'service.notifications-provider';

  static contextTypes = {
    flux: PropTypes.object,
  };

  static propTypes = {
    identity: PropTypes.string,
    chatsEnabled: PropTypes.bool,
    winksEnabled: PropTypes.bool,
    giftsEnabled: PropTypes.bool,
    numberOfFastAnswerVariants: PropTypes.number,
    mingleEnabled: PropTypes.bool,
    automaticInvitationsEnabled: PropTypes.bool,
  };

  constructor(props, context) {
    super(props);
    this.identityModel = context.flux.get(IdentityModel);

    this.identity = this.identityModel.store.getState().id;
  }

  componentDidMount() {
    this.identityModel.store.listen(this.onIdentityChanged);
    this.context.flux.events.addListener(
      'event.users.tags.set',
      this.onTagAdded,
    );
    this.context.flux.events.addListener(
      'event.users.events.added',
      this.onChatListEvent,
    );
    this.context.flux.events.addListener(
      'message:message:v2',
      this.onMessageEvent,
    );
    this.context.flux.events.addListener(
      'event.dialogs.automation.productions.added',
      this.onAutomationAddEvent,
    );
    this.context.flux.events.addListener(
      'event.dialogs.automation.productions.removed',
      this.onAutomationEndEvent,
    );
    this.context.flux.bus.addListener(
      'push-notification.received',
      this.onPushReceived,
    );
  }

  componentWillUnmount() {
    this.identityModel.store.unlisten(this.onIdentityChanged);
    this.context.flux.events.removeListener(
      'event.users.tags.set',
      this.onTagAdded,
    );
    this.context.flux.events.removeListener(
      'event.users.events.added',
      this.onChatListEvent,
    );
    this.context.flux.events.removeListener(
      'message:message:v2',
      this.onMessageEvent,
    );
    this.context.flux.events.removeListener(
      'event.dialogs.automation.productions.added',
      this.onAutomationAddEvent,
    );
    this.context.flux.events.removeListener(
      'event.dialogs.automation.productions.removed',
      this.onAutomationEndEvent,
    );
    this.context.flux.bus.removeListener(
      'push-notification.received',
      this.onPushReceived,
    );
  }

  onIdentityChanged = state => {
    this.identity = state.id;
  };

  onTagAdded = data => {
    if (
      this.props.boostProfileEnabled &&
      this.identity &&
      !data['holder-id'] &&
      data['user-id'] === this.identity &&
      data.tag === USER_TAGS.BOOSTED
    ) {
      const notification = {
        type: NotificationType.ProfileBoosted,
        payload: null,
      };

      this.showNotification(notification, NOTIFICATION_PRIORITY.NORMAL);
    }
  };

  onChatListEvent = payload => {
    if (
      !this.props.chatsEnabled ||
      !this.identity ||
      !payload ||
      !payload.payload ||
      !payload.payload['sender-id'] ||
      payload.payload['sender-id'] === this.identity ||
      (!this.props.automaticInvitationsEnabled && payload.payload.auto) ||
      this.isLetterChainEvent(payload)
    ) {
      return;
    }

    const notification = {
      type: NotificationType.MessageEvent,
      payload,
    };

    if (
      this.props.winksEnabled &&
      payload.payload.meta &&
      payload.payload.meta['wink-template'] === WINK_TYPES.SEND_INVITE
    ) {
      notification.fastAnswer = {
        recipientId: payload['user-id'],
        variants: randomValuesFromArray(
          Resources.strings['wink-answers'],
          this.props.numberOfFastAnswerVariants,
        ),
      };
    }

    if (
      this.props.winksEnabled &&
      payload.payload.meta &&
      payload.payload.meta['wink-template'] === WINK_TYPES.LIKE_USER
    ) {
      notification.fastAnswer = {
        recipientId: payload['user-id'],
        variants: randomValuesFromArray(
          Resources.strings['user-like-answers'],
          this.props.numberOfFastAnswerVariants,
        ),
      };
    }

    this.showNotification(notification, NOTIFICATION_PRIORITY.NORMAL, [
      ROUTES.FEED,
      ROUTES.PROFILE,
      ROUTES.DISCOVERY,
      ROUTES.GALLERY,
      ROUTES.TIPS_LIST,
      ROUTES.TIP,
    ]);
  };

  /**
   * @private
   * @param event
   */
  isLetterChainEvent = event => {
    return event.type === 'letter';
  };

  onMessageEvent = async payload => {
    if (
      !this.props.chatsEnabled ||
      !this.identity ||
      !payload ||
      payload.status !== 1
    ) {
      return;
    }

    if (
      this.props.winksEnabled &&
      payload.sender === this.identity &&
      payload.meta &&
      payload.meta['wink-template'] === WINK_TYPES.SEND_INVITE
    ) {
      const shownForUsersString = await UserDefaults.get(
        WINK_NOTIFICATION_SHOWN_STORAGE_KEY,
      );
      const shownForUsers =
        (shownForUsersString && JSON.parse(shownForUsersString)) || [];

      if (shownForUsers.indexOf(this.identity) >= 0) {
        return;
      }

      shownForUsers.push(this.identity);
      await UserDefaults.set(
        WINK_NOTIFICATION_SHOWN_STORAGE_KEY,
        JSON.stringify(shownForUsers),
      );

      const notification = {
        type: NotificationType.Wink,
        payload,
      };

      this.showNotification(notification, NOTIFICATION_PRIORITY.NORMAL, [
        ROUTES.FEED,
        ROUTES.PROFILE,
        ROUTES.DISCOVERY,
        ROUTES.GALLERY,
        ROUTES.TIPS_LIST,
        ROUTES.TIP,
      ]);

      return;
    }

    if (
      this.props.giftsEnabled &&
      payload.sender === this.identity &&
      payload.meta &&
      payload.meta.reference &&
      payload.meta.reference.startsWith('cheer')
    ) {
      const notification = {
        type: NotificationType.Cheer,
        payload,
      };

      this.showNotification(notification, NOTIFICATION_PRIORITY.NORMAL, [
        ROUTES.FEED,
        ROUTES.PROFILE,
        ROUTES.DISCOVERY,
        ROUTES.GALLERY,
        ROUTES.CHEERS,
        ROUTES.TIPS_LIST,
        ROUTES.TIP,
      ]);
    }

    if (
      this.props.inboxEnabled &&
      payload.sender === this.identity &&
      payload.meta &&
      payload.meta.letter
    ) {
      const notification = {
        type: NotificationType.Letter,
        payload,
      };

      this.showNotification(notification, NOTIFICATION_PRIORITY.NORMAL, [
        ROUTES.FEED,
        ROUTES.PROFILE,
        ROUTES.DISCOVERY,
        ROUTES.GALLERY,
        ROUTES.CHEERS,
      ]);
    }
  };

  onAutomationAddEvent = payload => {
    if (
      !this.props.mingleEnabled ||
      !payload ||
      !payload['user-id'] ||
      (payload.product && payload.product !== 'invitation') ||
      payload.approval !== 'approved'
    ) {
      return;
    }

    const notification = {
      type: NotificationType.MingleActive,
      payload: {
        id: parseUserId(payload['user-id']),
      },
    };

    this.showNotification(notification, NOTIFICATION_PRIORITY.NORMAL, [
      ROUTES.FEED,
      ROUTES.PROFILE,
      ROUTES.DISCOVERY,
      ROUTES.GALLERY,
      ROUTES.START_MINGLE,
    ]);
  };

  onAutomationEndEvent = payload => {
    if (
      !this.props.mingleEnabled ||
      !payload ||
      !payload['user-id'] ||
      (payload.product && payload.product !== 'invitation')
    ) {
      return;
    }

    const notification = {
      type: NotificationType.MingleEnd,
      payload: {
        id: parseUserId(payload['user-id']),
      },
    };

    this.showNotification(notification, NOTIFICATION_PRIORITY.NORMAL, [
      ROUTES.FEED,
      ROUTES.PROFILE,
      ROUTES.DISCOVERY,
      ROUTES.GALLERY,
    ]);
  };

  onPushReceived = payload => {
    if (!payload || !payload.uri) {
      return;
    }

    const components = payload.uri.split('/');

    if (
      payload.type === 'messageBoosted' &&
      components[1] === 'dialogs' &&
      components[2]
    ) {
      const notification = {
        type: NotificationType.UnansweredMessage,
        payload: {
          attendee: components[2],
        },
      };

      this.showNotification(notification, NOTIFICATION_PRIORITY.NORMAL);
    }
  };

  showNotification = (payload, priority, allowedScreens) => {
    const currentNavigationStack = Navigator.currentStack();

    if (!currentNavigationStack || !currentNavigationStack.length) {
      return;
    }

    const lastRoute = currentNavigationStack[currentNavigationStack.length - 1];

    if (!allowedScreens || allowedScreens.indexOf(lastRoute) >= 0) {
      UserNotifying.shared(this.identity).notifyUser(payload, priority);
    }
  };
}

export default ConfigValue(NotificationsProvider, {
  winksEnabled: 'features.winks-enabled',
  numberOfFastAnswerVariants: 'fast-answer.number-of-variants',
  giftsEnabled: 'features.chat-gifts-receive-enabled',
  inboxEnabled: 'features.inbox-enabled',
  chatsEnabled: 'features.chats-enabled',
  mingleEnabled: 'features.mingle-enabled',
  automaticInvitationsEnabled: 'features.automatic-invitations-enabled',
  boostProfileEnabled: 'features.boost-profile-enabled',
});
