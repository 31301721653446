import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({

    container: {
        flexDirection: 'row',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    image: {
        marginLeft: 14,
        marginRight: 8,
        width: 38,
        height: 38,
    },
    text: {
        fontSize: 14,
        fontWeight: 'bold',
        fontFamily: '$brandFontFamily',
        color: '$brandTextColor',
        marginLeft: 12,
    },
});

export default styles;
