class ProductConfigActions {
    static displayName = 'config/product';

    set = (config) => (dispatch) => {
        dispatch(config);
    };

    setHost = (host) => (dispatch) => {
        dispatch(host);
    };

    setEndpoint = (key, value) => (dispatch) => {
        dispatch({
            key: key,
            value: value
        })
    };

    setFeature = (key, value) => (dispatch) => {
        dispatch({
            key: key,
            value: value
        })
    };

}

export default ProductConfigActions;

