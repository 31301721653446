import Actions from './actions';

function createMiscDataStore(id) {

    class Store {
        constructor(flux) {
            const actions = flux.getActions(Actions, id);
            this.state = {};
            this.bindAction(actions.get, this.update);
        };

        update = (state) => {
            if (!state) {
                return;
            }

            this.setState(state);
        };
    }

    Store.displayName = createMiscDataStore.getDisplayName(id);

    return Store;

}

createMiscDataStore.getDisplayName = function (id) {
    return `misc-data.${id}`;
};

export default createMiscDataStore;
