import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $statusBarStyle: '$primaryStatusBarStyle',
    $statusBarBackgroundColor: '$primaryBackgroundColor',

    container: {
        backgroundColor: '$primaryBackgroundColor',
        flex: 1,
    },

    tipsViewContainer: {
        flex: 1
    },

    header: {
        backgroundColor: '$primaryBackgroundColor',
        borderBottomColor: '$primaryBackgroundColor',
        shadowColor: '$primaryBackgroundColor'
    },

    headerTitle: {
        color: '$primaryTextColor'
    }

});

export default styles;
