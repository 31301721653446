import React, { PureComponent } from 'react';
import { TouchableOpacity, Image, View } from 'react-native';
import PropTypes from 'prop-types';

import withAutoScale from '../../auto-scale-image';

import styles from './styles';

const ScaledImage = withAutoScale(Image);

class Button extends PureComponent {
    static propTypes = {
        icon: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.object,
            PropTypes.array
        ]),
        style: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.object,
            PropTypes.array
        ]),
        iconStyle: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.object,
            PropTypes.array
        ]),
        disabled: PropTypes.bool,
        onPress: PropTypes.func
    };

    render() {
        const { forwardedRef, icon, iconStyle, ...props } = this.props;

        const content = (<ScaledImage style={[styles.icon, iconStyle || {}]} source={icon} />);

        const containerStyle = [styles.container, props.style || {}];

        return props.onPress
            ? (
                <TouchableOpacity
                    {...props}
                    style={containerStyle}
                    ref={forwardedRef}
                >
                    {content}
                </TouchableOpacity>
            )
            :(
                <View
                    {...props}
                    style={containerStyle}
                    ref={forwardedRef}
                >
                    {content}
                </View>
            );
    }
}

export default React.forwardRef(
    (props, ref) => React.createElement(Button, { forwardedRef: ref, ...props })
);
