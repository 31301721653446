import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Image, Text, TouchableOpacity } from 'react-native';
import Resources from 'dating-mobile/src/resources';

import muteOnImg from './assets/mute-on.png';
import muteOffImg from './assets/mute-off.png';

import styles from './styles';

export default class MuteAudioButton extends PureComponent {
  static displayName = 'call.views.mute-audio-button/view';

  static propTypes = {
    audioMuted: PropTypes.bool,
    disabled: PropTypes.bool,
    setAudioMuted: PropTypes.func,
  };

  toggle = () => {
    const { audioMuted, setAudioMuted } = this.props;

    if (setAudioMuted) {
      setAudioMuted(!audioMuted);
    }
  };

  render() {
    const { disabled, audioMuted } = this.props;
    const icon = audioMuted ? muteOnImg : muteOffImg;

    return (
      <TouchableOpacity
        disabled={disabled}
        style={[styles.container, disabled ? styles.disabled : null]}
        onPress={this.toggle}
      >
        <Image source={icon} />
        <Text style={styles.text}>
          {Resources.strings['audio-call-mute-toggle-text']}
        </Text>
      </TouchableOpacity>
    );
  }
}
