import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  button: {
    backgroundColor: '$acceptColor',
  },

  disabled: {
    opacity: 0.5,
  },
});

export default styles;
