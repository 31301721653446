import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $statusBarStyle: '$brandStatusBarStyle',
    $statusBarBackgroundColor: '$brandColor',
    container: {
        width: '100%',
        height: '100%',
        backgroundColor: '$primaryBackgroundColor'
    },
    flatList: {
        width: '100%',
        height: '100%'
    },
    itemContainer: {
        width: '100%',
        height: 70,
    },
    header: {
        backgroundColor: '$brandColor',
        borderBottomColor: '$brandColor',
        shadowColor: '$brandColor',
        shadowRadius: 0,
        shadowOpacity: 0,
        elevation: 0,
        shadowOffset: {
            height: 0,
        }
    },
    headerTitle: {
        fontFamily: '$brandFontFamily',
        color: '$brandTextColor',
        width: 250
    },
});

export default styles;
