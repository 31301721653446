import Actions from './actions';

function CreateStreamFollowersStore(id) {
    class StreamFollowersStore {
        constructor(flux) {
            const actions = flux.getActions(Actions, id);
            this.state = {};
            this.bindAction(actions.get, this.get);
        }

        get = (counter) => {
            this.setState({counter: counter});
        };
    }

    StreamFollowersStore.displayName = CreateStreamFollowersStore.getDisplayName(id);
    return StreamFollowersStore;
}

CreateStreamFollowersStore.getDisplayName = function (id) {
    return `dialogs-stream-followers.${id}`;
};

export default CreateStreamFollowersStore;
