import React from 'react';
import PropTypes from 'prop-types';
import NavigationItem from 'dating-mobile/src/components/navigation-item';
import Resources from '../../../../resources';
import DictValuesController from '../../../../models/misc-data/controller';

class ProfileParameterItem extends React.Component {
  static displayName = 'profile.parameter.item';

  static propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    placeholder: PropTypes.string,
    type: PropTypes.string.isRequired,
    onPress: PropTypes.func,
    titleStyle: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    accessibilityLabel: PropTypes.string,
    testID: PropTypes.string,
    disabled: PropTypes.bool,
    isInline: PropTypes.bool,
  };

  shouldComponentUpdate(nextProps) {
    const { title, value, placeholder } = this.props;

    return (
      title !== nextProps.title ||
      value !== nextProps.value ||
      placeholder !== nextProps.placeholder
    );
  }

  onPressed = () => {
    const { onPress, type, title, value, isInline } = this.props;

    if (onPress && !isInline) {
      onPress(type, title, value);
    }
  };

  render() {
    const {
      title,
      titleStyle,
      value,
      placeholder,
      accessibilityLabel,
      testID,
      disabled,
      isInline,
    } = this.props;

    return (
      <NavigationItem
        onPress={this.onPressed}
        isInline={isInline}
        title={title || ''}
        titleStyle={titleStyle}
        value={value || placeholder}
        pinVisible
        accessibilityLabel={accessibilityLabel}
        testID={testID}
        disabled={disabled}
      />
    );
  }
}

// eslint-disable-next-line react/no-multi-comp
class ProfileDynamicParameterComponent extends React.PureComponent {
  static displayName = 'profile.dynamic-parameter.item';

  static propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    type: PropTypes.string.isRequired,
    valueFormat: PropTypes.string,
    dictValues: PropTypes.object,
    testID: PropTypes.string,
    onPress: PropTypes.func,
    accessibilityLabel: PropTypes.string,
  };

  render() {
    const {
      dictValues,
      value,
      title,
      type,
      onPress,
      accessibilityLabel,
      testID,
      valueFormat,
    } = this.props;

    let displayText = '';

    if (dictValues) {
      if (typeof value === 'string' && value !== '') {
        displayText = dictValues[value] || '';
        displayText = displayText.trim();
      }

      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          const val = value[i];
          const displayValue = dictValues[val];

          if (displayValue) {
            if (displayText.length > 0) {
              displayText = `${displayText}, ${displayValue}`;
            } else {
              displayText = displayValue;
            }
          }
        }
      }
    }

    if (valueFormat) {
      displayText = Resources.strings.formatString(valueFormat, displayText);
    }

    return (
      <ProfileParameterItem
        title={title}
        value={displayText}
        type={type}
        onPress={onPress}
        accessibilityLabel={accessibilityLabel}
        testID={testID}
      />
    );
  }
}

const ProfileDynamicParameterItem = DictValuesController(
  ProfileDynamicParameterComponent,
);

export { ProfileParameterItem, ProfileDynamicParameterItem };
