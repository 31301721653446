import flux from '@sdv/domain/app/flux';
import IdentityModel from '@sdv/domain/identity/model';
import Keychain from 'dating-mobile/src/utils/keychain';
import MessagesModel, { getId } from '@sdv/domain/dialogs.messages';

export default async (attendeeId, messageText) => {
    const identityModel = flux.get(IdentityModel);
    let identity = identityModel.store.getState();
    if (!identity.id) {
        const token = await Keychain.getToken();
        if (!token) {
            return;
        }
        identity = await (new Promise((resolve, reject) => {
            identityModel.actions.auth(token, (error) => {
                if (error) {
                    return reject(error);
                }
                resolve(identityModel.store.getState());
            });
        }));
    }
    await (new Promise((resolve, reject) => {
        flux.get(MessagesModel, getId(identity.id, attendeeId)).actions.send(messageText, (error) => {
            if (error) {
                return reject(error);
            }
            resolve();
        });
    }));
};
