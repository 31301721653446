import PropTypes from 'prop-types';
import { analytics as createFirebaseTracker } from 'react-native-firebase';
import AppsFlyerTracker from 'dating-mobile/src/services/tracking/apps-flyer-tracker';
import IdentityModel from '@sdv/domain/identity/model';

import Service from '../service';

export default class IdentityTrackingService extends Service {
  static displayName = 'service.tracking.identity';

  static contextTypes = {
    flux: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);

    this.identityModel = context.flux.get(IdentityModel);
    this.setIdentity(this.identityModel.store.getState().id);
  }

  componentDidMount() {
    this.identityModel.store.listen(this.onIdentityChanged);
  }

  componentWillUnmount() {
    this.identityModel.store.unlisten(this.onIdentityChanged);
  }

  onIdentityChanged = state => {
    this.setIdentity(state.id);
  };

  setIdentity = identity => {
    if (identity) {
      createFirebaseTracker().setUserId(identity);
      AppsFlyerTracker.setCustomerUserId(identity, () => {});
    }
  };
}
