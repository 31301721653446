import React from 'react';
import { Image, Text } from 'react-native';
import PropTypes from 'prop-types';
import styles from './styles';
import Resources from 'dating-mobile/src/resources';

export default class NewUserIndicatorView extends React.Component {
    static displayName = 'user.common.views.new-user-indicator.view';
    static propTypes = {
        isNew: PropTypes.bool
    };

    shouldComponentUpdate(nextProps) {
        return this.props.isNew !== nextProps.isNew;
    }

    render() {
        return this.props.isNew && (
            <Text>
                <Image style={styles.newUserIcon} source={Resources.images.newUserIcon()}/>
                <Text>&nbsp;</Text>
            </Text>
        );
    }
}
