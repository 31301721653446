import { Platform } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  $statusBarStyle: '$primaryStatusBarStyle',
  $statusBarBackgroundColor: '$primaryBackgroundColor',
  $coinsIconVisible: true,

  header: {
    backgroundColor: '$primaryBackgroundColor',
    borderBottomColor: '$primaryBackgroundColor',
    shadowColor: '$primaryBackgroundColor',
  },

  headerTitle: {
    color: '$primaryTextColor',
  },

  container: {
    flex: 1,
    backgroundColor: '$primaryBackgroundColor',
  },

  scrollView: {
    flexGrow: 1,
  },

  content: {
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
  },

  profileContainer: {
    paddingTop: 13,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomColor: '$primaryBorderColor',
    paddingBottom: 36,
    borderBottomWidth: 0,
  },

  icon: {
    tintColor: '$primaryTextColor',
  },

  pin: {
    tintColor: '$primaryTextColor',
  },

  userName: {
    color: '$primaryTextColor',
  },

  itemTitleContainer: {
    borderBottomColor: '$primaryBorderColor',
  },

  title: {
    flex: 1,
    color: '$primaryTextColor',
    fontSize: 14,
    fontWeight: 'normal',
    fontFamily: '$brandFontFamily',
  },

  activeMembershipStatus: {
    fontSize: 17,
    fontWeight: '600',
    fontFamily: '$brandFontFamily',
    color: '$primaryTextColor',
  },

  inactiveMembershipStatus: {
    fontSize: 17,
    fontWeight: '600',
    fontFamily: '$brandFontFamily',
    color: '$minorAccentColor',
  },

  balanceContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  balance: {
    color: '#0b7fd7',
    fontSize: 14,
    fontFamily: '$brandFontFamily',
  },

  coin: {
    marginLeft: 6,
    width: 20,
    height: 20,
  },

  pushSettingsStatus: {
    fontSize: 17,
    fontWeight: '600',
    fontFamily: '$brandFontFamily',
    color: '$accentColor',
  },

  edit: {
    color: '$primaryTextColor',
  },

  aboutMeContainer: {
    marginTop: 50,
    marginLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
    borderBottomWidth: 1,
    borderBottomColor: '$primaryBorderColor',
  },

  aboutMe: {
    color: '$primaryTextColor',
    fontSize: 14,
    fontFamily: '$brandFontFamily',
  },

  getToTop: {
    marginLeft: 20,
    marginRight: 20,
    marginTop: 26,
    marginBottom: 10,
    ...Platform.select({
      web: { maxWidth: 700, alignSelf: 'center', width: '100%' },
    }),
  },

  userBadges: {
    paddingTop: 14,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 24,
  },

  headerButtonsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  visibilitySwitcher: {
    fontSize: 14,
    fontWeight: 'normal',
  },

  purchaseStatusTitle: {
    fontSize: 14,
    fontWeight: 'normal',
  },

  activationStatus: {
    fontWeight: 'normal',
    color: '#0b7fd7',
    fontSize: 14,
    minWidth: 50,
    flex: 1,
    fontFamily: '$brandFontFamily',
    flexShrink: 1,
    textAlign: 'right',
  },
});

export default styles;
