import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { View, Text, TouchableOpacity, ImageBackground } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import Resources from 'dating-mobile/src/resources';
import Button from 'dating-mobile/src/components/buttons/base';
import withAspectRatio from 'dating-mobile/src/components/aspect-ratio';
import { AnimatedAppearing } from 'dating-mobile/src/components/animated-appearing';

import styles from './styles';

const AspectRatioImage = withAspectRatio(ImageBackground);

export default class BoostExtendBannerManSmall extends PureComponent {
  static displayName =
    'components.purchase-promotion-banner.boost-extend-man-small.view';

  static propTypes = {
    onPress: PropTypes.func,
    style: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    image: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    remainingTime: PropTypes.number,
    invoice: PropTypes.object,
    placeholder: PropTypes.object,
    hidden: PropTypes.bool,
  };

  render() {
    const {
      style,
      onPress,
      image,
      invoice,
      remainingTime,
      hidden,
      placeholder,
    } = this.props;

    const showPlaceholder = !remainingTime || !invoice || hidden;

    return (
      <View>
        <AnimatedAppearing
          useNativeDriver
          animationDuration={500}
          appearingAnimationName="fadeIn"
          disappearingAnimationName="fadeOut"
          isVisible={showPlaceholder}
          style={{ position: showPlaceholder ? 'relative' : 'absolute' }}
        >
          {placeholder}
        </AnimatedAppearing>
        <AnimatedAppearing
          useNativeDriver
          animationDuration={500}
          appearingAnimationName="fadeIn"
          disappearingAnimationName="fadeOut"
          isVisible={!showPlaceholder}
          style={{ position: !showPlaceholder ? 'relative' : 'absolute' }}
        >
          <View style={style}>
            <View style={styles.background} />
            <TouchableOpacity onPress={onPress}>
              <AspectRatioImage
                style={styles.imageContainer}
                imageStyle={styles.image}
                source={image}
              >
                <View style={styles.textContainer}>
                  <View>
                    <Button
                      style={styles.button}
                      title={
                        <Text style={styles.buttonText}>
                          {
                            Resources.strings[
                              'purchase-promotion-banner-boost-extend-man-small-button-title'
                            ]
                          }
                        </Text>
                      }
                    />
                  </View>
                  <Text style={styles.subtitle}>
                    {Resources.strings.formatString(
                      Resources.strings[
                        'purchase-promotion-banner-boost-extend-man-small-title'
                      ],
                      invoice?.discount || '',
                    )}
                  </Text>
                </View>
              </AspectRatioImage>
            </TouchableOpacity>
            <LinearGradient
              colors={['rgb(255, 255, 255)', 'rgba(255, 255, 255, 0)']}
              style={styles.gradient}
            />
          </View>
        </AnimatedAppearing>
      </View>
    );
  }
}
