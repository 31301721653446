import type from '@sdv/commons/utils/type';

const DELTA = 10 * 60 * 1000;

let clientDate = null;
let serverDate = null;

function facade(api) {
  const controller = {};

  controller.now = function(cb) {
    if (!clientDate) {
      clientDate = Date.now();
    }

    const delta = Date.now() - clientDate;

    if (delta >= DELTA || !serverDate) {
      api.null.get(error => {
        if (error) {
          if (clientDate && serverDate) {
            const newDelta = Date.now() - clientDate;

            serverDate += newDelta;
            clientDate += newDelta;
            cb(serverDate);
          } else {
            clientDate = Date.now();
            serverDate = clientDate;
            cb(serverDate);
          }
        } else {
          api.getDate(function(newServerDate) {
            clientDate = Date.now();
            serverDate = Date.parse(newServerDate);
            cb(serverDate);
          });
        }
      });
    } else {
      serverDate += delta;
      clientDate += delta;
      cb(serverDate);
    }
  };

  // Синхронная функция
  controller.isSameDaySync = function(date, anotherDate) {
    return (
      date.getMonth() === anotherDate.getMonth() &&
      date.getDate() === anotherDate.getDate()
    );
  };

  controller.isSameDay = function(date, cb) {
    if (!type.isFunction(cb)) {
      return;
    }

    controller.now(function(now) {
      const today = new Date(now);

      cb(
        date.getMonth() === today.getMonth() &&
          date.getDate() === today.getDate(),
      );
    });
  };

  return controller;
}

export default facade;
