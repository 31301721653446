import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet } from 'react-native';
import testId from 'dating-mobile/src/utils/test-id';
import ImageButton from 'dating-mobile/src/components/navigation/views/image-button';
import Resources from 'dating-mobile/src/resources';
import SelectionView from './view';
import MiscDataController from '../../../../models/misc-data/controller';

import styles from './styles';

class SelectionValueEditing extends React.PureComponent {
  static displayName = 'user.edit.selection-value-editing';

  static propTypes = {
    navigation: PropTypes.object.isRequired,
    availableValues: PropTypes.array,
    onComplete: PropTypes.func,
    dataType: PropTypes.string,
    selectedItems: PropTypes.array,
  };

  static navigationOptions = ({ navigation, navigationOptions }) => ({
    title: navigation.getParam('title'),
    headerStyle: [navigationOptions.headerStyle, styles.header],
    headerTitleStyle: [navigationOptions.headerTitleStyle, styles.headerTitle],
    headerLeft: () => (
      <ImageButton
        image={Resources.images.backIcon()}
        tint={
          StyleSheet.flatten([
            navigationOptions.headerTitleStyle,
            styles.headerTitle,
          ]).color
        }
        onPress={() => {
          const goBack = navigation.getParam('goBack');

          if (goBack) {
            goBack();
          } else {
            navigation.pop();
          }
        }}
        {...testId('Back button')}
      />
    ),
  });

  constructor(props) {
    super(props);
    this.state = { selectedItems: this.getDefaultSelectedItems() };
  }

  componentDidMount() {
    const { navigation } = this.props;

    navigation.setParams({ goBack: this.goBack });
  }

  goBack = () => {
    const { navigation, onComplete } = this.props;
    const { selectedItems } = this.state;
    const completion = onComplete || navigation.getParam('onComplete');

    if (selectedItems.sort() !== this.getDefaultSelectedItems().sort()) {
      const processedItems = selectedItems.map(item => {
        if (parseInt(item, 10)) {
          return +item;
        }

        return item;
      });
      const value = this.getMultipleSelection()
        ? processedItems
        : processedItems[0] || '';

      completion(value);
    }

    navigation.pop();
  };

  onSelectionChanged = selectedItems => {
    this.setState({ selectedItems });

    if (this.getCloseOnSelect()) {
      this.goBack();
    }
  };

  getAvailableValues = () => {
    const { navigation, availableValues } = this.props;

    return availableValues || navigation.getParam('availableValues');
  };

  getDataType = () => {
    const { navigation, dataType } = this.props;

    return dataType || navigation.getParam('dataType');
  };

  getDefaultSelectedItems = () => {
    const { navigation, selectedItems } = this.props;

    return selectedItems || navigation.getParam('selectedItems') || [];
  };

  getMultipleSelection = () => {
    const { navigation } = this.props;

    return typeof navigation.getParam('multiSelection') === 'boolean'
      ? navigation.getParam('multiSelection')
      : false;
  };

  getAllowUnselection = () => {
    const { navigation } = this.props;

    return typeof navigation.getParam('allowUnselection') === 'boolean'
      ? navigation.getParam('allowUnselection')
      : false;
  };

  getCloseOnSelect = () => {
    const { navigation } = this.props;

    return typeof navigation.getParam('closeOnSelect') === 'boolean'
      ? navigation.getParam('closeOnSelect')
      : false;
  };

  render() {
    if (this.getAvailableValues()) {
      return (
        <SelectionView
          availableValues={this.getAvailableValues()}
          selectedItems={this.getDefaultSelectedItems()}
          multiSelection={this.getMultipleSelection()}
          onSelectionChanged={this.onSelectionChanged}
          allowUnselection={this.getAllowUnselection()}
        />
      );
    }

    return (
      <MiscDataSelectionView
        dataType={this.getDataType()}
        selectedItems={this.getDefaultSelectedItems()}
        multiSelection={this.getMultipleSelection()}
        onSelectionChanged={this.onSelectionChanged}
        allowUnselection={this.getAllowUnselection()}
      />
    );
  }
}

const MiscDataSelectionView = MiscDataController(props => {
  return <SelectionView availableValues={props.dictValues} {...props} />;
});

export default SelectionValueEditing;
