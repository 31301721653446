import { singleton } from '@sdv/commons/utils/singleton';
import { Platform } from 'react-native';

export default class OS {
  static shared = singleton(() => new OS());

  constructor() {
    this.current = Platform.OS;
  }
}
