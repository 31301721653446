import React from 'react';
import PropTypes from 'prop-types';
import {
  I18nManager,
  Platform,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import { SafeAreaView } from 'react-navigation';
import LinearGradient from 'react-native-linear-gradient';
import Swiper from 'react-native-swiper';
import Sticker from '../sticker';
import Stickers from '../stickers';

import styles from './styles';

// Hack
const inverted = Platform.OS === 'android' && I18nManager.isRTL;

export default class StickersKeyboardComponent extends React.Component {
  static displayName = 'dialogs.common.stickers-keyboard';

  static propTypes = {
    stickerPacks: PropTypes.array,
    onStickerSelect: PropTypes.func,
  };

  static defaultProps = {
    stickerPacks: [],
  };

  constructor(props) {
    super(props);

    const { stickerPacks } = props;
    const selectedPackIndex =
      inverted && stickerPacks && stickerPacks.length
        ? stickerPacks.length - 1
        : 0;

    this.state = {
      selectedPackIndex,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { stickerPacks } = this.props;
    const { selectedPackIndex } = this.state;

    return (
      stickerPacks !== nextProps.stickerPacks ||
      selectedPackIndex !== nextState.selectedPackIndex
    );
  }

  componentDidUpdate(prevProps) {
    const { stickerPacks = [] } = this.props;
    const { selectedPackIndex } = this.state;

    if (
      prevProps.stickerPacks[selectedPackIndex] !==
      stickerPacks[selectedPackIndex]
    ) {
      this.setState({
        selectedPackIndex: inverted ? stickerPacks.length - 1 : 0,
      });
    }
  }

  onPackSelected = index => () => {
    const { selectedPackIndex } = this.state;

    this.swiper.scrollBy(
      inverted ? selectedPackIndex - index : index - selectedPackIndex,
      false,
    );
  };

  onPackIndexChange = index => {
    const { stickerPacks } = this.props;
    const selectedPackIndex = inverted
      ? stickerPacks.length - 1 - index
      : index;

    this.setState({ selectedPackIndex });
  };

  renderPack = (pack, index) => {
    const { selectedPackIndex } = this.state;
    const itemStyle =
      selectedPackIndex === index
        ? styles.selectedStickerPack
        : styles.stickerPack;
    const { gradientColor } = StyleSheet.flatten(itemStyle);

    const content = (
      <Sticker
        stickerName={pack.stickers[0].basename}
        style={styles.stickerPackIcon}
      />
    );

    const container = gradientColor ? (
      <LinearGradient colors={gradientColor} style={itemStyle}>
        {content}
      </LinearGradient>
    ) : (
      <View style={itemStyle}>{content}</View>
    );

    return (
      <TouchableOpacity key={pack.name} onPress={this.onPackSelected(index)}>
        {container}
      </TouchableOpacity>
    );
  };

  renderStickers = pack => {
    const { onStickerSelect } = this.props;

    return (
      <Stickers
        key={pack.name}
        stickers={pack.stickers}
        onStickerSelect={onStickerSelect}
      />
    );
  };

  render() {
    const { stickerPacks = [] } = this.props;

    return (
      <SafeAreaView
        forceInset={{ top: 'never', bottom: 'always' }}
        style={styles.container}
      >
        <View style={styles.stickers}>
          <Swiper
            horizontal
            showsButtons={false}
            showsPagination={false}
            loop={false}
            onIndexChanged={this.onPackIndexChange}
            ref={ref => {
              this.swiper = ref;
            }}
          >
            {stickerPacks.map(this.renderStickers)}
          </Swiper>
        </View>
        {stickerPacks.length > 1 && (
          <ScrollView style={styles.stickerPacks} horizontal>
            {stickerPacks.map(this.renderPack)}
          </ScrollView>
        )}
      </SafeAreaView>
    );
  }
}
