import Alt from '@sdv/alt';
import { Api } from '@sdv/network/api';
import ConfigBuilder from 'dating-mobile/src/app/config-builder';
import EventEmitter from 'eventemitter3';
import EventsInbox from 'dating-mobile/src/services/functional/events-inbox';
import LetterEventInbox from '@sdv/domain/dialogs.mailbox/event-inbox';

const flux = new Alt();

flux.AP = {};
flux.AP.wait = function() {};
flux.AP.done = function() {};
flux.bus = new EventEmitter();

// TODO: refactor. Temporary backward compatibility
Api.shared().configure(ConfigBuilder.config);
flux.api = Api.shared().api;
flux.api.hostname = () => Api.shared().hostname;
flux.api.host = () => Api.shared().host;
flux.api.getUserAgent = () => Api.shared().userAgent;

flux.events = new EventsInbox(flux, () => Api.shared().endpoints.ws);
flux.letterEventInbox = new LetterEventInbox(flux);
export default flux;
