import RNPermissions from 'react-native-permissions';
import {
  of,
  from,
  defer,
  fromEvent,
  throwError,
  merge,
  BehaviorSubject,
} from 'rxjs';
import { take, switchMap, map, tap } from 'rxjs/operators';
import { singleton } from '@sdv/commons/utils/singleton';
import { Platform } from 'react-native';
// import NotificationsIOS, {
//   NotificationAction,
//   NotificationCategory,
// } from 'react-native-notifications';
// import { messaging } from 'react-native-firebase';
import Resources from 'dating-mobile/src/resources';

const messaging = {};

const NotificationsIOS = {};
const NotificationAction = {};
const NotificationCategory = {};

export class Permissions {
  static shared = singleton(() => new Permissions());

  permissions = new BehaviorSubject({});

  requestLocationPermission() {
    const permission = 'location';

    return this.requestPermission(permission).pipe(
      map(status => status === 'authorized'),
      tap(hasPermission =>
        this.permissions.next({
          ...this.permissions.getValue(),
          [permission]: hasPermission,
        }),
      ),
    );
  }

  hasLocationPermission() {
    return this.checkPermission('location').pipe(
      map(status => status === 'authorized'),
    );
  }

  requestNotificationsPermission() {
    return Platform.OS === 'ios'
      ? this.requestNotificationsPermissionIOS()
      : this.requestNotificationsPermissionAndroid();
  }

  hasNotificationsPermission() {
    return Platform.OS === 'ios'
      ? this.hasNotificationsPermissionIOS()
      : this.hasNotificationsPermissionAndroid();
  }

  requestNotificationsPermissionIOS() {
    const replyAction = new NotificationAction({
      activationMode: 'background',
      title: Resources.strings['notification-remote-input-action'],
      behavior: 'textInput',
      authenticationRequired: true,
      identifier: 'slide to view',
    });

    const notificationCategory = new NotificationCategory({
      identifier: 'reply',
      actions: [replyAction],
      context: 'default',
    });

    return defer(() => {
      const remoteNotificationsRegistered = fromEvent(
        NotificationsIOS,
        'remoteNotificationsRegistered',
      ).pipe(take(1));

      const remoteNotificationsRegistrationFailed = fromEvent(
        NotificationsIOS,
        'remoteNotificationsRegistrationFailed',
      ).pipe(
        take(1),
        switchMap(error => throwError(error)),
      );

      NotificationsIOS.requestPermissions([notificationCategory]);

      return merge(
        remoteNotificationsRegistered,
        remoteNotificationsRegistrationFailed,
      );
    });
  }

  requestNotificationsPermissionAndroid() {
    return defer(() => of('wwedwed'));
  }

  hasNotificationsPermissionIOS() {
    return defer(() =>
      from(NotificationsIOS.checkPermissions()).pipe(
        map(
          permissions =>
            !!permissions.badge || !!permissions.sound || !!permissions.alert,
        ),
      ),
    );
  }

  hasNotificationsPermissionAndroid() {
    return defer(() => false);
  }

  requestPermission(type) {
    return defer(() => from(RNPermissions.request(type)));
  }

  checkPermission(type) {
    return defer(() => from(RNPermissions.check(type)));
  }
}
