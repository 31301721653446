import React from 'react';
import PropTypes from 'prop-types';
import UserTags from '@sdv/domain/user/tags';
import { Subscription } from 'rxjs';
import connect from '@sdv/connect';
import { UserProfileAliases } from '@sdv/domain/user/user-profile-aliases';
import CredentialsUpdater from 'dating-mobile/src/platform/credentials-updater';
import identityId from '../../../../models/identity/controller/id';
import configValue from '../../../../components/config-value';
import UserEventsModel, {
  getId,
  EVENT_TYPE,
} from '../../../../models/user.events/model';

function mergeChats(contacts, invitations) {
  return [...contacts, ...invitations].sort(
    (a, b) => new Date(b.timestamp) - new Date(a.timestamp),
  );
}

export default function createController(Component) {
  class Controller extends React.Component {
    static displayName = 'dialogs.chats.my-chats.screen.controller';

    static propTypes = {
      canChat: PropTypes.bool,
      contacts: PropTypes.array,
      contactsWithoutUnansweredAutoMessages: PropTypes.array,
      contactsLoaded: PropTypes.bool,
      invitations: PropTypes.array,
      invitationsLoaded: PropTypes.bool,
      getContacts: PropTypes.func,
      getInvitations: PropTypes.func,
      staleChat: PropTypes.func,
      staleChatRequest: PropTypes.func,
      deleteChat: PropTypes.func,
      deleteChatRequest: PropTypes.func,
      chatRequestsEnabled: PropTypes.bool,
      automaticInvitationsEnabled: PropTypes.bool,
      showAllRequests: PropTypes.func,
      startChat: PropTypes.func,
      chatRequestsInSeparateTab: PropTypes.bool,
      hideAutoEventsWithoutAnswers: PropTypes.bool,
      forceShow: PropTypes.bool,
      streamAnnouncementEnabled: PropTypes.bool,
      isFocused: PropTypes.bool,
      mergeInvitationsWithContactsEnabled: PropTypes.bool,
      setNavigationBarState: PropTypes.func,
      identity: PropTypes.string,
    };

    constructor(props) {
      super(props);
      this.state = { filterValue: '', aliasedUsers: {}, isPromoter: false };
    }

    componentDidMount() {
      const {
        contactsLoaded,
        getContacts,
        invitationsLoaded,
        getInvitations,
        setNavigationBarState,
        identity,
      } = this.props;

      if (contactsLoaded === false && getContacts) {
        getContacts();
      }

      if (invitationsLoaded === false && getInvitations) {
        getInvitations();
      }

      if (setNavigationBarState) {
        setNavigationBarState({
          onFilterChange: value => this.onFilterChange(value),
        });
      }
      this.createPromoterSubscription(identity);

      const aliasesSubscription = UserProfileAliases.shared()
        .getAllAliases()
        .subscribe(aliasedUsers => this.setState({ aliasedUsers }));

      this.disposeBag = new Subscription();
      this.disposeBag.add(aliasesSubscription);
    }

    componentWillReceiveProps(nextProps) {
      const { identity } = this.props;

      if (identity !== nextProps.identity) {
        this.createPromoterSubscription(nextProps.identity);
      }
      if (shouldReconnect(this.props, nextProps)) {
        if (nextProps.contactsLoaded === false && nextProps.getContacts) {
          nextProps.getContacts();
        }
        if (nextProps.invitationsLoaded === false && nextProps.getInvitations) {
          nextProps.getInvitations();
        }
      }
    }

    shouldComponentUpdate(nextProps) {
      return nextProps.isFocused || nextProps.forceShow;
    }

    componentWillUnmount() {
      if (this.promoterSubscription) {
        this.promoterSubscription.unsubscribe();
      }

      if (this.disposeBag) {
        this.disposeBag.unsubscribe();
      }
    }

    onFilterChange(filterValue) {
      this.setState({ filterValue });
    }

    getContacts() {
      const {
        contacts,
        contactsWithoutUnansweredAutoMessages,
        hideAutoEventsWithoutAnswers,
      } = this.props;

      return hideAutoEventsWithoutAnswers
        ? contactsWithoutUnansweredAutoMessages
        : contacts;
    }

    onShowAllRequestsButtonPressed = () => {
      const { showAllRequests } = this.props;

      if (showAllRequests) {
        showAllRequests();
      }
    };

    onChatSelected = event => {
      const { staleChatRequest, staleChat, startChat, canChat } = this.props;

      if (!event || !event['user-id']) {
        return;
      }

      const openChat = () => {
        if (event.type === EVENT_TYPE.INVITATION) {
          if (staleChatRequest) {
            staleChatRequest(event['user-id']);
          }
        } else if (event.type === EVENT_TYPE.CONTACT) {
          if (staleChat) {
            staleChat(event['user-id']);
          }
        }
        if (startChat) {
          startChat({
            id: event['user-id'],
          });
        }
      };

      if (canChat) {
        openChat();
      } else if (CredentialsUpdater) {
        CredentialsUpdater.updateCredentials(openChat);
      }
    };

    onDeleteChatButtonPressed = event => {
      const { deleteChatRequest, deleteChat } = this.props;

      if (!event || !event['event-id']) {
        return;
      }

      if (event.type === EVENT_TYPE.INVITATION) {
        deleteChatRequest(event['event-id']);
      } else if (event.type === EVENT_TYPE.CONTACT) {
        deleteChat(event['event-id']);
      }
    };

    getFilteredContacts() {
      const { filterValue, aliasedUsers } = this.state;
      const contacts = this.getContacts();

      if (Array.isArray(contacts)) {
        if (filterValue) {
          return contacts.filter((contact = {}) => {
            const userDetails = contact['user-details'];
            const aliasedUser = userDetails && aliasedUsers[userDetails.id];
            const userName =
              (aliasedUser && aliasedUser.name) ||
              (userDetails && userDetails.name) ||
              '';

            return userName
              .toLocaleLowerCase()
              .includes(filterValue.toLocaleLowerCase());
          });
        }

        return contacts;
      }

      return [];
    }

    createPromoterSubscription = nextUserId => {
      if (this.promoterSubscription) {
        this.promoterSubscription.unsubscribe();
      }

      if (nextUserId) {
        this.promoterSubscription = UserTags.shared()
          .isPromoter(nextUserId)
          .subscribe(promoter => {
            this.setState({
              isPromoter: promoter,
            });
          });
      }
    };

    render() {
      const {
        chatRequestsEnabled,
        chatRequestsInSeparateTab,
        automaticInvitationsEnabled,
        streamAnnouncementEnabled,
        invitations,
        mergeInvitationsWithContactsEnabled,
      } = this.props;
      const { isPromoter } = this.state;
      let userInvitations = [];

      if (chatRequestsEnabled && !chatRequestsInSeparateTab) {
        userInvitations = invitations || [];
        if (!automaticInvitationsEnabled) {
          userInvitations = userInvitations.filter(
            event => !event.payload || !event.payload.auto,
          );
        }
      }

      const contacts = this.getFilteredContacts();

      return (
        <Component
          {...this.props}
          invitations={userInvitations}
          contacts={
            mergeInvitationsWithContactsEnabled
              ? mergeChats(contacts || [], invitations || [])
              : contacts
          }
          onShowAllRequestsButtonPress={this.onShowAllRequestsButtonPressed}
          onChatSelect={this.onChatSelected}
          onDeleteChatButtonPress={this.onDeleteChatButtonPressed}
          showNewsletterButton={isPromoter && streamAnnouncementEnabled}
        />
      );
    }
  }

  function getModels(flux, props) {
    const models = {};

    if (props.identity) {
      models.contactsModel = flux.get(
        UserEventsModel,
        getId(props.identity, EVENT_TYPE.CONTACT),
      );
      models.invitationsModel = flux.get(
        UserEventsModel,
        getId(props.identity, EVENT_TYPE.INVITATION),
      );
    }

    return models;
  }

  function mapModelsToProps(models) {
    const props = {};

    if (models.contactsModel) {
      const contactsModelState = models.contactsModel.store.getState();

      props.contacts = contactsModelState.events;
      props.contactsWithoutUnansweredAutoMessages =
        contactsModelState.eventsWithoutUnansweredAutoMessages;
      props.contactsLoaded = contactsModelState.allEventsLoaded;
    }

    if (models.invitationsModel) {
      const invitationsModelState = models.invitationsModel.store.getState();

      props.invitations = invitationsModelState.events;
      props.invitationsLoaded = invitationsModelState.allEventsLoaded;
    }

    return props;
  }

  function mapModelsToActions(models) {
    const props = {};

    if (models.contactsModel) {
      props.getContacts = models.contactsModel.actions.get;
      props.staleChat = models.contactsModel.actions.stale;
      props.deleteChat = models.contactsModel.actions.delete;
    }

    if (models.invitationsModel) {
      props.getInvitations = models.invitationsModel.actions.get;
      props.staleChatRequest = models.invitationsModel.actions.stale;
      props.deleteChatRequest = models.invitationsModel.actions.delete;
    }

    return props;
  }

  function shouldReconnect(props, newProps) {
    return props.identity !== newProps.identity;
  }

  const ConnectedController = connect(
    getModels,
    mapModelsToProps,
    mapModelsToActions,
    shouldReconnect,
  )(Controller);

  return identityId(
    configValue(ConnectedController, {
      chatRequestsEnabled: 'features.chat-requests-enabled',
      automaticInvitationsEnabled: 'features.automatic-invitations-enabled',
      hideAutoEventsWithoutAnswers: 'features.hide-auto-events-without-answers',
      chatRequestsInSeparateTab: 'markup.chat-requests-separate-tab-enabled',
      streamAnnouncementEnabled: 'features.stream-announcement-enabled',
      mergeInvitationsWithContactsEnabled:
        'features.merge-invitations-with-contacts-enabled',
    }),
    'identity',
  );
}
