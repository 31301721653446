import displayName from './display-name';
import Actions from './actions';
import { mapJsonToChain } from '../letter-repo';

function createStore(userId) {
    class Store {
        constructor(flux) {
            this.state = {
                omit: 0,
                letterChains: [],
                endReached: false
            };

            this.letterChains = [];

            const actions = flux.getActions(Actions, userId);

            this.bindAction(actions.getLetters, this.onGotLetters);

            this.eventInbox = flux.letterEventInbox;
            this.eventInbox.addOnLetterChainAddedListener(this.onChainAdded);
            this.eventInbox.addOnLetterChainRemovedListener(this.onChainRemoved);
        }

        /**
         * @private
         */
        onGotLetters = (payload) => {
            if (payload) {
                this.letterChains = payload.letterChains;
                this.setState({
                    omit: payload.omit + payload.select,
                    endReached: payload.select > payload.letterChains.length,
                    letterChains: this.letterChains
                });
            }
        };

        /**
         * @private
         */
        onChainAdded = (event) => {
            this.letterChains = this.letterChains.filter(chain => chain.chainId !== event['event-id']);
            this.letterChains.unshift(mapJsonToChain(event));
            this.setState({
                letterChains: this.letterChains
            });
        };

        /**
         * @private
         */
        onChainRemoved = (event) => {
            this.letterChains = this.letterChains.filter(chain => chain.chainId !== event['event-id']);
            this.setState({
                letterChains: this.letterChains
            });
        };
    }

    Store.displayName = displayName(userId);

    return Store;
}


createStore.getDisplayName = displayName;

export default createStore;
