import React from 'react';
import PropTypes from 'prop-types';
import { View, Alert, TouchableOpacity, Text } from 'react-native';
import styles from './styles';
import Resources from 'dating-mobile/src/resources';

export default class Error extends React.PureComponent {
    static displayName = 'components.validation-error';
    static propTypes = {
        error: PropTypes.string,
        style: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.object,
            PropTypes.array
        ])
    };

    showAlert = () => {
        if (!this.props.error) {
            return;
        }

        const error = typeof this.props.error === 'string'
            ? this.props.error
            : JSON.stringify(this.props.error);
        Alert.alert('', error, [{text: Resources.strings.ok, style: 'cancel'}], {cancelable: false});
    };

    render() {
        if (!this.props.error) {
            return null;
        }

        return (
            <View style={[styles.errorContainer, this.props.style]}>
                <TouchableOpacity onPress={this.showAlert}>
                    <View style={styles.error}>
                        <Text style={styles.errorIcon}>!</Text>
                    </View>
                </TouchableOpacity>
            </View>
        )
    }
}
