import React from 'react';
import { StyleSheet, View } from 'react-native';
import PropTypes from 'prop-types';

import ImageButton from '../../components/navigation/views/image-button';
import Resources from '../../resources';

import styles from './styles';

function Video({ style, source, onFullscreenPlayerWillDismiss }) {
  return (
    <View style={StyleSheet.flatten([styles.container, style])}>
      <video
        controls
        style={StyleSheet.flatten(styles.video)}
        src={source.uri}
      />
      <View style={styles.closeButton}>
        <ImageButton
          image={Resources.images.closeIcon()}
          tint="#ffffff"
          onPress={onFullscreenPlayerWillDismiss}
        />
      </View>
    </View>
  );
}

Video.propTypes = {
  source: PropTypes.shape({
    uri: PropTypes.string,
    isNetwork: PropTypes.bool,
  }),
  style: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ]),
  onFullscreenPlayerWillDismiss: PropTypes.func,
};

export default Video;
