import Actions from './actions';
import { createOptimisticStore } from '@sdv/model';

function createInterestsStore(id) {

    class Store extends createOptimisticStore(['put']) {

        constructor(flux) {
            const actions = flux.getActions(Actions, id);
            super(actions);

            this.bindAction(actions.get, this.update);
        }

        update(payload) {

            if (!payload) {
                return;
            }

            this.state.data = payload;
            this.setState(this.state);

        }

    }

    Store.displayName = createInterestsStore.getDisplayName(id);

    return Store;
}

createInterestsStore.getDisplayName = function (id) {
    return `user-interests.${id}`;
};

export default createInterestsStore;
