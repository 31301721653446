import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import Resources from 'dating-mobile/src/resources';
import styles from './styles';

export default class Header extends PureComponent {
    static displayName = 'components.stack-navigation.views.header';
    static propTypes = {
        showBack: PropTypes.bool,
        showClose: PropTypes.bool,
        title: PropTypes.node,
        onBackPress: PropTypes.func,
        onClosePress: PropTypes.func,
    };

    render() {
        const { showBack, showClose, onBackPress, onClosePress, title } = this.props;

        return (
            <View style={styles.container}>
                <View style={styles.headerLeft}>
                    {showBack && (
                        <TouchableOpacity style={styles.closeButton} onPress={onBackPress}>
                            <Image
                                style={styles.navigationButtonIcon}
                                source={Resources.images.backIcon()}
                            />
                        </TouchableOpacity>
                    )}
                </View>
                <View style={styles.headerCenter}>
                    <Text style={styles.headerText}>{title}</Text>
                </View>
                <View style={styles.headerRight}>
                    {showClose && (
                        <TouchableOpacity style={styles.closeButton} onPress={onClosePress}>
                            <Image
                                style={styles.navigationButtonIcon}
                                source={Resources.images.closeIcon()}
                            />
                        </TouchableOpacity>
                    )}
                </View>
            </View>
        );
    }
}
