import EStyleSheet from 'react-native-extended-stylesheet';
import { Platform } from 'react-native';
import shouldUseWebLayout from 'dating-mobile/src/utils/web-layout';

const isDesktopLayout = shouldUseWebLayout();

const styles = EStyleSheet.create({
  $googleSignUpButtonVisible: false,
  $alreadyMemberBlockVisible: true,
  $autoplayTimeout: 5,
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'stretch',
  },
  swiperContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  topContainer: {
    width: '100%',
    height: 55,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
  },
  logoContainer: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    ...(isDesktopLayout ? { marginBottom: 80 } : { flex: 1 }),
  },
  safeArea: {
    flex: 1,
    alignItems: isDesktopLayout ? 'flex-end' : 'center',
    justifyContent: 'center',
  },
  scrollViewContainer: {
    width: '100%',
    backgroundColor: isDesktopLayout ? '$primaryBackgroundColor' : 'none',
  },
  scrollViewContentContainer: {
    flexGrow: 1,
    alignItems: 'center',
  },
  slides: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  slide: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
  },
  pagination: {
    marginBottom: 140,
  },
  imageStyle: {
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  slideContent: {
    marginTop: 40,
    alignItems: 'center',
  },
  slideTitle: {
    color: '$brandTextColor',
    fontFamily: '$brandFontFamily',
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: 34,
    marginBottom: 25,
    marginTop: 40,
  },
  slideDescription: {
    color: '$brandTextColor',
    fontFamily: '$brandFontFamily',
    textAlign: 'center',
    fontSize: 16,
    marginBottom: 50,
    marginHorizontal: 25,
  },
  imageContainer: {
    flex: 1,
    flexDirection: 'row',
    marginHorizontal: 40,
  },
  slideInfoImage: {
    flex: 1,
  },
  expander: {
    flexGrow: 1,
  },
  logo: isDesktopLayout
    ? {
        width: 120,
        height: 120,
        marginTop: 100,
      }
    : {
        width: 138,
      },

  signInButton: isDesktopLayout
    ? {
        backgroundColor: 'transparent',
        borderWidth: 2,
        borderColor: '$accentColor',
        width: '60%',
        marginBottom: 42,
      }
    : {
        marginRight: 15,
        height: 40,
        backgroundColor: 'transparent',
        width: undefined,
      },
  signInButtonTitle: {
    fontSize: 16,
    fontFamily: '$brandFontFamily',
    color: isDesktopLayout ? '$accentColor' : 'white',
    fontWeight: '700',
    ...Platform.select({
      android: {
        textDecorationLine: 'underline',
      },
    }),
  },

  signUpButtonsContainer: {
    paddingHorizontal: isDesktopLayout ? '20%' : 40,
    width: '100%',
    justifyContent: 'center',
    paddingBottom: 16,
  },

  oauthButton: {
    marginTop: 16,
  },

  appleSignInButton: {
    marginTop: 5,
    width: '100%',
    height: 54,
  },

  header: {
    width: '100%',
    paddingHorizontal: 37,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  headerLink: {
    fontSize: 16,
    color: '$secondaryTextColor',
  },

  headerHome: {
    fontSize: 16,
  },

  languagePicker: {
    cursor: 'pointer',
  },

  languagePickerTitle: {
    color: '$inputTextColor',
  },

  footer: {
    flex: 1,
    justifyContent: 'flex-end',
    flexDirection: 'column',
    width: '60%',
    alignItems: 'center',
    marginBottom: 15,
  },

  storesWrapper: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 32,
  },

  store: {
    width: 176,
    height: 52,
  },

  copyrightText: {
    color: '$secondaryTextColor',
    fontSize: 12,
  },
  oauthButtonsSeparator: {
    fontSize: 14,
    color: '$brandTextColor',
    textAlign: 'center',
    fontFamily: '$brandFontFamily',
    marginTop: 5,
  },

  appleSignInButtonContainer: {
    width: '100%',
  },

  buttonBig: {
    fontSize: 21,
    fontWeight: '600',
  },

  alreadyMemberBlock: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 16,
  },

  alreadyMemberQuestion: {
    marginRight: 8,
    fontSize: 16,
    color: 'white',
  },

  activityIndicator: {
    position: 'absolute',
    alignSelf: 'center',
  },
  title: {
    ...(isDesktopLayout
      ? {
          color: 'black',
          marginTop: 60,
          fontWeight: 'normal',
          fontSize: 22,
        }
      : {
          fontWeight: 'bold',
          color: '$brandTextColor',
          marginTop: 7,
          fontSize: 34,
        }),
    fontFamily: '$brandFontFamily',
    textAlign: 'center',
  },
  termsAndPrivacyText: {
    color: '$brandTextColor',
  },
});

export default styles;
