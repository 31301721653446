import EStyleSheet from 'react-native-extended-stylesheet';
import { Platform } from 'react-native';

const styles = EStyleSheet.create({
  $statusBarStyle: '$primaryStatusBarStyle',
  $statusBarBackgroundColor: '$primaryBackgroundColor',

  container: {
    flex: 1,
    position: 'relative',
    backgroundColor: '$primaryBackgroundColor',
  },

  header: {
    backgroundColor: '$primaryBackgroundColor',
    borderBottomColor: '$primaryBackgroundColor',
    shadowColor: '$primaryBackgroundColor',
  },

  headerTitle: {
    color: '$primaryTextColor',
    ...Platform.select({
      ios: {
        marginHorizontal: 0,
      },
    }),
  },
});

export default styles;
