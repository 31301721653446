import connect from '@sdv/connect';
import Outbox, { newLetter } from '@sdv/domain/dialogs.mailbox/outbox';
import LetterWritingModel from '@sdv/domain/dialogs.mailbox/letter-writing';
import qs from 'qs';
import React from 'react';
import PropTypes from 'prop-types';
import CallStatus from 'dating-mobile/src/models/call.status';

export default function createController(Component) {

    function withTag(TagComponent) {

        class TagController extends React.Component {
            static displayName = 'inbox.screens.letter-writing.controller.tag';

            constructor(props) {
                super(props);
                this.tag = newLetter();
            }

            render() {
                return (<TagComponent
                    {...this.props}
                    tag={this.tag}
                />);
            }
        }

        return TagController
    }

    class Controller extends React.Component {
        static displayName = 'inbox.screens.letter-writing.controller';
        static contextTypes = {
            flux: PropTypes.object
        };

        componentWillReceiveProps(props) {
            const uri = props.source && props.source.uri;
            const currentUri = this.props.source && this.props.source.uri;

            if (uri && uri !== currentUri) {
                this.processMedia(props.source);
            }
        }

        processMedia = (media) => {
            this.props.attach && this.props.attach(media);
        };

        sendLetter = () => {
            const { sendLetter, recipientId, letterTag } = this.props;
            const letter = { recipientId: recipientId, tag: letterTag };
            sendLetter && sendLetter(letter);
        };

        render() {
            return (<Component
                {...this.props}
                onSendLetterButtonPress={this.sendLetter}
            />);
        }
    }

    function getModels(flux, props) {
        const models = {};

        if (props.senderId && props.recipientId && props.tag) {
            models.outbox = flux.get(Outbox, props.senderId);

            const modelId = qs.stringify({ senderId: props.senderId, recipientId: props.recipientId, letterTag: props.tag });
            models.letterWritingModel = flux.get(LetterWritingModel, modelId);

            models.callStatus = flux.get(CallStatus, props.senderId);
        }

        return models;
    }

    function mapStoresToProps(models) {
        let props = {};

        if (models.letterWritingModel) {
            const state = models.letterWritingModel.store.getState();
            props = { ...props, ...state };
        }

        if (models.callStatus) {
            props.forbidMediaFromCamera = models.callStatus.store.getState().status !== 'idle';
        }

        return props;
    }

    function mapActionsToProps(models) {
        const props = {};

        if (models.letterWritingModel) {
            props.onTextSet = models.letterWritingModel.actions.setText;
            props.onSubjectSet = models.letterWritingModel.actions.setSubject;
            props.attach = models.letterWritingModel.actions.attach;
        }

        if (models.outbox) {
            props.sendLetter = models.outbox.actions.sendLetter;
        }

        return props;
    }

    return  withTag(connect(getModels, mapStoresToProps, mapActionsToProps)(Controller));
}


