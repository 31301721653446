/* eslint-disable no-case-declarations */
import url from 'url';
import request from '@sdv/commons/utils/request';
import OS from '@sdv/domain/app/os';

function createPaidResourceActions(id) {
  const { holder, path } = parseId(id);

  class Actions {
    access = () => async (dispatch, flux) => {
      const uri = url.format({
        protocol: 'https',
        hostname: flux.api.getBaseHost().replace('//', ''),
        pathname: path,
      });

      try {
        const headers = {
          authorization: flux.api.authorize(),
        };

        if (OS.shared().current !== 'web') {
          headers['user-agent'] = flux.api.augment('user-agent');
        }
        const response = await request(uri, {
          method: 'HEAD',
          headers,
        });

        switch (response.status) {
          case 402:
          case 403:
            dispatch({
              status: RESOURCE_ACCESS_STATUS.PAYMENT_REQUIRED,
            });
            break;
          case 200:
            dispatch({
              status: RESOURCE_ACCESS_STATUS.AVAILABLE,
            });
            break;
          case 404:
          case 410:
            dispatch({
              status: RESOURCE_ACCESS_STATUS.NOT_FOUND,
            });
            break;
          case 418:
            const retryAfterHeader = response.responseHeaders['Retry-After'];
            const retryAfter =
              retryAfterHeader &&
              (() => {
                const date = new Date();

                date.setSeconds(
                  date.getSeconds() + parseFloat(retryAfterHeader),
                );

                return date;
              })();

            dispatch({
              status: RESOURCE_ACCESS_STATUS.PROCESSING,
              retryAfter,
            });
            break;
        }
      } catch (error) {
        dispatch(null, error);
      }
    };

    buy = () => (dispatch, flux) => {
      let resourcePurchase;

      if (
        path.match(/^\/dialogs\/messages\/\d+:\d+\/boosted$/) ||
        path.match(/^\/users\/\d+\/tags\/users.boosted$/)
      ) {
        resourcePurchase = path;
      } else if (
        path.startsWith('/dialogs/attachments') ||
        path.startsWith('/dialogs/videos') ||
        path.startsWith('/dialogs/usermedia')
      ) {
        resourcePurchase = `^${path}(\\..*)?`;
      } else {
        // eslint-disable-next-line no-useless-escape
        resourcePurchase = `^${path}(\/.*|\\..*)?`;
      }

      flux.api.paidresources.post(
        holder,
        { resource: resourcePurchase },
        error => {
          if (error && error.status !== 409) {
            dispatch(null, error);
          } else {
            dispatch({
              status: RESOURCE_ACCESS_STATUS.AVAILABLE,
            });
          }
        },
      );
    };
  }

  Actions.displayName = createPaidResourceActions.getDisplayName(id);

  return Actions;
}

createPaidResourceActions.getDisplayName = function(id) {
  return `paid-resource.${id}`;
};

export default createPaidResourceActions;

export const RESOURCE_ACCESS_STATUS = Object.freeze({
  UNKNOWN: -1,
  AVAILABLE: 0,
  PAYMENT_REQUIRED: 1,
  PROCESSING: 2,
  NOT_FOUND: 3,
});

export function parseId(id) {
  let keys;

  // eslint-disable-next-line no-cond-assign
  if (
    typeof id !== 'string' ||
    (keys = id.split(':')).length < 2 ||
    !keys[0] ||
    !keys[1]
  ) {
    throw new Error(
      'PaidResourceModel should have id in format "{holder}:{path}"',
    );
  }

  return {
    holder: keys[0],
    path: keys.slice(1).join(':'),
  };
}

export function getId(holder, path) {
  return `${holder}:${path}`;
}
