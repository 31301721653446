import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, TouchableWithoutFeedback, Platform } from 'react-native';
import OnlineIndicator from 'dating-mobile/src/user/common/views/online-indicator/view';
import UserLocationText from 'dating-mobile/src/user/common/views/user-location';
import LinearGradient from 'react-native-linear-gradient';
import testId from 'dating-mobile/src/utils/test-id';
import shouldUseWebLayout from 'dating-mobile/src/utils/web-layout';
import Resources from 'dating-mobile/src/resources';
import withUser from 'dating-mobile/src/models/user/controllers';
import ReportButton from 'dating-mobile/src/components/report-button';
import { profileResource } from '@sdv/domain/resources/remote';
import { PreviewTags } from '@sdv/domain/user/tags/user-tags';
import withConfigValue from 'dating-mobile/src/components/config-value';
import TravelDestinationText from './travel-destination-text';
import { VipIndicator } from '../../../components/vip-indicator';
import IfConfigValue from '../../../components/config-value/if';
import Photo, { PHOTO_TRANSFORMATION_TYPE } from '../../../components/photo';
import WinkButton from './wink-button';
import styles from './styles';

class UserCardComponent extends React.Component {
  static displayName = 'feed.view.list.item';

  static propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    about: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    thumbnail: PropTypes.string,
    status: PropTypes.number,
    devices: PropTypes.array,
    onWinkButtonPress: PropTypes.func,
    onItemPress: PropTypes.func,
    isContact: PropTypes.bool,
    isOwnProfile: PropTypes.bool,
    allAboutHidden: PropTypes.bool,
    birthday: PropTypes.object,
    meta: PropTypes.object,
    location: PropTypes.object,
    style: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
  };

  shouldComponentUpdate(nextProps) {
    const {
      id,
      name,
      thumbnail,
      status,
      isContact,
      meta,
      birthday,
    } = this.props;
    const travelDestination = meta?.travels?.[0] || {};
    const nextTravelDestination = nextProps.meta?.travels?.[0] || {};
    const age = birthday?.age;
    const nextAge = nextProps.birthday?.age;

    return (
      id !== nextProps.id ||
      name !== nextProps.name ||
      thumbnail !== nextProps.thumbnail ||
      status !== nextProps.status ||
      travelDestination.city !== nextTravelDestination.city ||
      travelDestination.country !== nextTravelDestination.country ||
      age !== nextAge ||
      isContact !== nextProps.isContact
    );
  }

  onWinkButtonPress = () => {
    const { id, onWinkButtonPress } = this.props;

    if (onWinkButtonPress) {
      onWinkButtonPress(id);
    }
  };

  onPressed = () => {
    const { id, onItemPress } = this.props;

    if (onItemPress) {
      onItemPress(id);
    }
  };

  render() {
    const {
      isOwnProfile,
      birthday,
      meta,
      about,
      status,
      name,
      style,
      id,
      thumbnail,
      isContact,
      allAboutHidden,
      tags = [],
      location = {},
      devices = [],
    } = this.props;
    const age = birthday?.age;
    const travelDestination = meta?.travels?.[0] || {};
    const devicesNames = devices.map(item => ({ name: item }));
    const isVip =
      tags.includes(PreviewTags.VipAccount) ||
      tags.includes(PreviewTags.VipBasic);
    const isPremiumVip = tags.includes(PreviewTags.VipPremium);
    const aboutHidden =
      allAboutHidden ||
      (!isOwnProfile && tags.includes(PreviewTags.AboutHidden));
    const hasVipIndicator = isVip || isPremiumVip;
    const isDesktopLayout = shouldUseWebLayout();

    const description = (
      <LinearGradient
        style={styles.descriptionContainer}
        colors={styles.$infoContainerGradientColors}
      >
        <View style={allAboutHidden && styles.textContainerCenter}>
          <Text
            numberOfLines={1}
            style={styles.name}
            {...testId(Resources.strings['user-name-accessibility-label'])}
          >
            <Text>{name}</Text>
            <IfConfigValue
              path="markup.feed-screen.item.has-age"
              equalsTo={true}
            >
              <Text>, {age}</Text>
            </IfConfigValue>
          </Text>
          <IfConfigValue path="features.travels-enabled" equalsTo={true}>
            <TravelDestinationText
              city={travelDestination.city}
              countryCode={travelDestination.country}
            />
          </IfConfigValue>
          {styles.$showLocation && <UserLocationText {...location} />}
        </View>
        <IfConfigValue path="features.anonymous-using-enabled" equalsTo={true}>
          {!aboutHidden && (
            <Text numberOfLines={2} style={styles.about}>
              {about}
            </Text>
          )}
        </IfConfigValue>
        <IfConfigValue
          path="markup.feed-screen.item.has-online-indicator"
          equalsTo={true}
        >
          <View style={styles.onlineContainer}>
            <OnlineIndicator
              online={!!status}
              devices={devicesNames}
              tags={tags}
            />
          </View>
        </IfConfigValue>
      </LinearGradient>
    );

    return (
      <View style={[styles.itemContainer, style]}>
        <TouchableWithoutFeedback onPress={this.onPressed}>
          <View style={styles.item}>
            <IfConfigValue
              path="markup.feed-screen.item.description-position"
              equalsTo="top"
            >
              {description}
            </IfConfigValue>
            <View style={styles.imageContainer}>
              <Photo
                style={styles.image}
                userId={id}
                basename={thumbnail}
                round={styles.$photoRounded}
                transformation={PHOTO_TRANSFORMATION_TYPE.DETECT_FACE}
                placeholder="avatar"
                withoutAuth={Platform.OS === 'web'}
              />
            </View>
            <IfConfigValue path="features.winks-enabled" equalsTo={true}>
              <View style={styles.winkButton}>
                <WinkButton
                  id={id}
                  isContact={isContact}
                  openChat={this.onWinkButtonPress}
                />
              </View>
            </IfConfigValue>
            <IfConfigValue path="features.vip-enabled" equalsTo={true}>
              {hasVipIndicator && (
                <View style={styles.vipIndicator}>
                  <VipIndicator
                    size={isDesktopLayout ? 48 : 40}
                    padding={isDesktopLayout ? 0 : undefined}
                    isPremium={isPremiumVip}
                  />
                </View>
              )}
            </IfConfigValue>
            {!isOwnProfile && !isDesktopLayout && (
              <View
                style={[
                  styles.reportButtonContainer,
                  allAboutHidden &&
                    !hasVipIndicator &&
                    styles.reportButtonContainerCenter,
                ]}
              >
                <ReportButton
                  showIcon
                  allowBlock
                  style={styles.reportButton}
                  id={id}
                  reportedContent={profileResource(id)}
                />
              </View>
            )}
            <IfConfigValue
              path="markup.feed-screen.item.description-position"
              equalsTo="bottom"
            >
              {description}
            </IfConfigValue>
          </View>
        </TouchableWithoutFeedback>
      </View>
    );
  }
}

const UserCard = withConfigValue(UserCardComponent, {
  allAboutHidden: 'markup.feed-screen.item.about-hidden',
});

export default UserCard;

const DataLoadingUserCard = withUser(props => {
  return <UserCard {...props} {...props.user} />;
});

export const UserCardWithOptionalLoading = props => {
  const { name, id } = props;

  if (!name) {
    return <DataLoadingUserCard {...props} id={id} />;
  }

  return <UserCard {...props} />;
};

UserCardWithOptionalLoading.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
};
