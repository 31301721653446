import React from 'react';
import TextInput from '../text-input';
import PropTypes from "prop-types";

export class GapsMaskedInput extends React.PureComponent {
    static displayName = 'components.gaps-masked-input';
    static propTypes = {
        gaps: PropTypes.arrayOf(PropTypes.number),
        onChangeText: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    onTextChange = (text) => {
        const gaps = this.props.gaps;
        if (!gaps && !this.gapsUsed) {
            this.props.onChangeText && this.props.onChangeText(text);
            return;
        }
        this.gapsUsed = true;

        let spacesRemovedText = removeSpaces(text);
        let resultText = spacesRemovedText;
        gaps.forEach((val, index) => {
            const spaceIndex = val + index;
            if (resultText.length > spaceIndex) {
                resultText = insert(resultText, ' ', spaceIndex);
            }
        });

        this.setState({ text: resultText });
        this.props.onChangeText && this.props.onChangeText(spacesRemovedText);
    };

    render() {
        return (<TextInput
            {...this.props}
            onChangeText={this.onTextChange}
            value={this.state.text}
        />);
    }
}

function insert(src, inserting, position) {
    return src.slice(0, position) + inserting + src.slice(position);
}

function removeSpaces(text) {
    return text.replace(/\s+/g, '');
}
