import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Resources from 'dating-mobile/src/resources';

import withUserController from 'dating-mobile/src/models/user/controllers';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';

import Preferences from '../../utils/preferences';
import { BODY_TYPE, PREFERENCE_TYPE } from '../../utils/preferences-types';

const imagesMap = {
    fem: {
        thin: require('./assets/fem/thin.jpg'),
        average: require('./assets/fem/average.jpg'),
        fit: require('./assets/fem/fit.jpg'),
        little: require('./assets/fem/little.jpg'),
    },
    mal: {
        thin: require('./assets/mal/thin.jpg'),
        average: require('./assets/mal/average.jpg'),
        fit: require('./assets/mal/fit.jpg'),
        little: require('./assets/mal/little.jpg'),
    },
};

function getOptions(gender) {
    return [
        {
            id: BODY_TYPE.slim,
            image: imagesMap[gender].thin,
            text: Resources.strings['onboarding-screen-body-type-option-thin-text'],
        },
        {
            id: BODY_TYPE.average,
            image: imagesMap[gender].average,
            text: Resources.strings['onboarding-screen-body-type-option-average-text'],
        },
        {
            id: BODY_TYPE.muscular,
            image: imagesMap[gender].fit,
            text: Resources.strings['onboarding-screen-body-type-option-fit-text'],
        },
        {
            id: BODY_TYPE.fewExtraPounds,
            image: imagesMap[gender].little,
            text: Resources.strings['onboarding-screen-body-type-option-little-text'],
        },
    ];
}

export default function createController(Component) {
    class Controller extends PureComponent {
        static displayName = 'stepped-registration.screens.body-type.controller';

        static propTypes = {
            onComplete: PropTypes.func.isRequired,
            user: PropTypes.object,
            id: PropTypes.string.isRequired,
        };

        state = {
            selected: [],
            gender: null,
            options: null,
        };

        static getDerivedStateFromProps(props, state) {
            const { gender } = props.user;

            if (gender && state.gender !== gender) {
                return {
                    gender,
                    options: getOptions(gender),
                };
            }

            return null;
        }

        handleToggle = id => {
            const { selected } = this.state;

            const isSelected = selected.includes(id);

            if (isSelected) {
                this.setState({
                    selected: selected.filter(option => option !== id),
                });
            } else {
                this.setState({
                    selected: [...selected, id],
                });
            }
        };

        submit = () => {
            const { selected } = this.state;
            const { onComplete, id } = this.props;

            if (selected && selected.length) {
                Preferences.shared(id)
                    .savePreference(PREFERENCE_TYPE.bodyType, selected)
                    .subscribe(onComplete, onComplete);
            }
        };

        render() {
            const { selected, options } = this.state;

            if (!options) {
                return null;
            }

            return (
                <Component
                    selected={selected}
                    options={options}
                    onToggle={this.handleToggle}
                    onButtonPress={this.submit}
                />
            );
        }
    }

    return withIdentityId(withUserController(Controller), 'id');
}
