import { BaseStore } from '@sdv/model';
import qs from 'qs';
import Actions from './actions';
import callsApi from '../call.api/api';
import CallStatus from '../call.status';

function createStore(id) {
  const { user, attendee } = qs.parse(id);

  class Store extends BaseStore {
    constructor(flux) {
      super();

      const actions = flux.getActions(Actions, id);

      this.bindAction(actions.answer, this.answer);
      this.bindAction(actions.reject, this.reject);

      this.callStatus = flux.get(CallStatus, user);
    }

    answer = () => {
      const callState = this.callStatus.store.getState();

      if (
        callState.status === 'ringing' &&
        attendee === callState.invite.sender
      ) {
        callsApi.emit('command.chat.media.answer', callState.invite);
      }
    };

    reject = () => {
      const callState = this.callStatus.store.getState();

      if (
        callState.status === 'ringing' &&
        attendee === callState.invite.sender
      ) {
        callsApi.emit('command.chat.media.decline', callState.invite);
      }
    };
  }

  Store.displayName = createStore.getDisplayName(id);

  return Store;
}

createStore.getDisplayName = function(id) {
  return `call-answering.${id}`;
};

export default createStore;
