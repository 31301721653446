import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Resources from 'dating-mobile/src/resources';

import withUserController from 'dating-mobile/src/models/user/controllers';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';

const imagesMap = {
    fem: require('./assets/fem/bg.jpg'),
    mal: require('./assets/mal/bg.jpg'),
};

export default function createController(Component) {
    class Controller extends PureComponent {
        static displayName = 'stepped-registration.screens.start-setting-preferences.controller';

        static propTypes = {
            onComplete: PropTypes.func.isRequired,
            user: PropTypes.object,
        };

        state = {
            gender: null,
            image: null,
            title: null,
        };

        static getDerivedStateFromProps(props, state) {
            const { gender } = props.user;

            if (gender && state.gender !== gender) {
                return {
                    gender,
                    image: imagesMap[gender],
                    title: Resources.strings[`onboarding-screen-lets-start-${gender}-title`],
                };
            }

            return null;
        }

        render() {
            const { image, title } = this.state;
            const { onComplete } = this.props;

            if (!image) {
                return null;
            }

            return <Component image={image} title={title} onButtonPress={onComplete} />;
        }
    }

    return withIdentityId(withUserController(Controller), 'id');
}
