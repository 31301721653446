import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StatusBar, View } from 'react-native';
import ChatLogWithInput from 'dating-mobile/src/dialogs/messages/views/chat-log-with-input';
import StreamingCta from 'dating-mobile/src/dialogs/messages/views/streaming-cta';
import { DialogApprovalBanner } from 'dating-mobile/src/dialogs/messages/views/dialog-approval-banner';
import { AnimatedAppearing } from 'dating-mobile/src/components/animated-appearing';
import IfConfigValue from '../../../components/config-value/if';
import GalleryView from '../../../user/profile/views/photo-gallery';
import { GALLERY_ITEMS } from '../../../models/user.media/controller/gallery-controller';
import ChatGalleryHeader from '../views/chat-gallery-header';
import { ProfilePreview } from '../views/profile-preview';
import styles from './styles';

export default class ChatScreenView extends PureComponent {
  static displayName = 'dialogs.messages.screen.view';

  static contextTypes = {
    flux: PropTypes.object,
  };

  static propTypes = {
    identity: PropTypes.string,
    attendeeId: PropTypes.string,
    attendee: PropTypes.object,
    setTopBarState: PropTypes.func,
    openProfileEnabled: PropTypes.bool,
    onDialogApprovalBannerClose: PropTypes.func,
    showGalleryInChat: PropTypes.bool,
    privatePhotosEnabled: PropTypes.bool,
    openMedia: PropTypes.func,
    openProfile: PropTypes.func,
    allowAlias: PropTypes.bool,
    showProfilePreview: PropTypes.bool,
    showAudioCallButton: PropTypes.bool,
    isBlocked: PropTypes.bool,
  };

  state = {
    keyboardVisible: false,
  };

  componentDidMount() {
    const {
      allowAlias,
      identity,
      openProfileEnabled,
      setTopBarState,
      showGalleryInChat,
      showProfilePreview,
      showAudioCallButton,
    } = this.props;

    const state = {
      allowAlias,
      showAudioCallButton,
      userId: identity,
      showChatTitle: !showGalleryInChat && !showProfilePreview,
    };

    if (openProfileEnabled) {
      state.onProfilePress = this.onPress;
    }

    if (setTopBarState) {
      setTopBarState(state);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      showGalleryInChat,
      showProfilePreview,
      setTopBarState,
      showAudioCallButton,
    } = this.props;
    const { keyboardVisible } = this.state;

    if (
      showGalleryInChat !== prevProps.showGalleryInChat ||
      showProfilePreview !== prevProps.showProfilePreview ||
      showAudioCallButton !== prevProps.showAudioCallButton
    ) {
      setTopBarState({
        showAudioCallButton,
        showChatTitle:
          !showProfilePreview && (!showGalleryInChat || keyboardVisible),
        animateChatTitle: true,
      });
    }
  }

  onPress = () => {
    const { openProfileEnabled, openProfile, attendeeId } = this.props;

    if (openProfileEnabled) {
      openProfile({ id: attendeeId });
    }
  };

  renderChatGalleryHeader = () => {
    const { attendeeId } = this.props;

    return <ChatGalleryHeader id={attendeeId} onPress={this.onPress} />;
  };

  renderChatGalleryFooter = () => <View style={styles.chatGalleryFooter} />;

  openMedia = item => {
    const { openMedia, attendeeId } = this.props;

    openMedia({
      id: attendeeId,
      basename: item.basename,
    });
  };

  onKeyboardChange = keyboardType => {
    const {
      setTopBarState,
      showGalleryInChat,
      showProfilePreview,
    } = this.props;

    const keyboardVisible = keyboardType !== undefined && keyboardType !== null;

    this.setState({
      keyboardVisible,
    });

    if (setTopBarState) {
      setTopBarState({
        showChatTitle:
          !showProfilePreview && (!showGalleryInChat || keyboardVisible),
        animateChatTitle: true,
      });
    }
  };

  render() {
    const {
      attendee,
      attendeeId,
      privatePhotosEnabled,
      onDialogApprovalBannerClose,
      identity,
      showGalleryInChat,
      showProfilePreview,
      isBlocked,
    } = this.props;

    const { keyboardVisible } = this.state;

    const itemsOrder = privatePhotosEnabled
      ? [
          GALLERY_ITEMS.THUMBNAIL,
          GALLERY_ITEMS.PUBLIC_PHOTOS,
          GALLERY_ITEMS.VIDEOS,
          GALLERY_ITEMS.PRIVATE_PHOTO_ALBUM,
        ]
      : [
          GALLERY_ITEMS.THUMBNAIL,
          GALLERY_ITEMS.PUBLIC_PHOTOS,
          GALLERY_ITEMS.VIDEOS,
        ];

    return (
      <View style={styles.container}>
        <StatusBar
          barStyle={styles.$statusBarStyle}
          backgroundColor={styles.$statusBarBackgroundColor}
          hidden={false}
        />
        <DialogApprovalBanner
          attendeeId={attendeeId}
          onCloseDialog={onDialogApprovalBannerClose}
        />
        <AnimatedAppearing
          useNativeDriver
          animationDuration={300}
          appearingAnimationName="fadeInDown"
          disappearingAnimationName="fadeOutUp"
          isVisible={showProfilePreview}
        >
          <ProfilePreview userId={attendeeId} onPress={this.onPress} />
        </AnimatedAppearing>
        {showGalleryInChat && !showProfilePreview && !keyboardVisible && (
          <View style={styles.chatContainer}>
            <GalleryView
              id={attendeeId}
              open={this.openMedia}
              editingEnabled={false}
              HeaderComponent={this.renderChatGalleryHeader}
              FooterComponent={this.renderChatGalleryFooter}
              rightToLeft
              itemsOrder={itemsOrder}
              showPlaceholder
            />
          </View>
        )}
        <IfConfigValue path="features.streams-enabled" equalsTo={true}>
          <View style={styles.streamingCtaContainer}>
            <StreamingCta attendeeId={attendeeId} />
          </View>
        </IfConfigValue>
        <ChatLogWithInput
          isBlocked={isBlocked}
          user={identity}
          attendee={attendee}
          attendeeId={attendeeId}
          logStyle={styles.log}
          formStyle={styles.form}
          onKeyboardChange={this.onKeyboardChange}
        />
      </View>
    );
  }
}
