import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withImagePicker from 'dating-mobile/src/components/image-picker';
import withUserMedia from './index';

export default function controller(Component, options) {
    class Controller extends PureComponent {
        static displayName = 'models.user.media.controller.media-loader';

        static propTypes = {
            id: PropTypes.string,
            source: PropTypes.object,
            addPhoto: PropTypes.func,
            addVideo: PropTypes.func,
            select: PropTypes.func,
        };

        state = {
            isLoading: false,
        };

        componentWillReceiveProps(props) {
            const { source } = this.props;
            const uri = props.source && props.source.uri;
            const currentUri = source && source.uri;

            if (uri && uri !== currentUri) {
                this.addMedia(props.source);
            }
        }

        onSelect = response => {
            if (this.reject) {
                if (response.didCancel) {
                    this.reject(new Error('User cancelled media selection'));
                    this.resolve = null;
                    this.reject = null;
                } else if (response.error) {
                    this.reject(response.error);
                    this.resolve = null;
                    this.reject = null;
                }
            }
        };

        selectMedia = async () => {
            if (this.resolve || this.reject) {
                return Promise.reject(new Error('Media loading is currently in progress'));
            }

            const { select } = this.props;

            select(this.onSelect);

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        };

        async addMedia(source) {
            const { addPhoto, addVideo } = this.props;

            this.setState({ isLoading: true });

            try {
                const { mediaType, ...sourceForUpload } = source;

                if (mediaType === 'photo') {
                    await addPhoto(sourceForUpload);
                } else if (mediaType === 'video') {
                    await addVideo(sourceForUpload);
                } else {
                    throw new Error('Not supported media type');
                }

                this.setState({ isLoading: false });

                if (this.resolve) {
                    this.resolve();
                    this.resolve = null;
                    this.reject = null;
                }
            } catch (error) {
                this.setState({ isLoading: false });

                if (this.reject) {
                    this.reject(error);
                    this.resolve = null;
                    this.reject = null;
                }
            }
        }

        render() {
            const { isLoading } = this.state;

            return (
                <Component {...this.props} selectMedia={this.selectMedia} isLoading={isLoading} />
            );
        }
    }

    return withUserMedia(withImagePicker(Controller, options));
}
