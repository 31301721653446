import PropTypes from 'prop-types';
import TagsModel from '@sdv/domain/user/tags/personal';
import Service from '../../service';
import UserTaggedModel, {
  getId,
} from '../../../models/users.targeted.tagged/model';

export default class UserTagsProvider extends Service {
  static displayName = 'service.user-tags-provider';

  static contextTypes = {
    flux: PropTypes.object,
  };

  constructor(props, context) {
    super(props);

    this.events = context.flux.events;
  }

  componentDidMount() {
    this.events.addListener('event.users.tags.set', this.tagAdded);
    this.events.addListener('event.users.tags.unset', this.tagRemoved);
  }

  componentWillUnmount() {
    this.events.removeListener('event.users.tags.set', this.tagAdded);
    this.events.removeListener('event.users.tags.unset', this.tagRemoved);
  }

  tagAdded = data => {
    if (data['holder-id'] && data['user-id'] && data.tag) {
      const model = this.context.flux.get(
        UserTaggedModel,
        getId(data['holder-id'], data.tag),
      );

      model.actions.actualize(data['user-id']);

      return;
    }

    if (data['holder-id'] || !data['user-id'] || !data.tag) {
      return;
    }

    if (data['user-id']) {
      const model = this.context.flux.get(TagsModel, data['user-id']);

      model.actions.actualize([data.tag]);
    }
  };

  tagRemoved = data => {
    if (data['holder-id'] && data['user-id'] && data.tag) {
      const model = this.context.flux.get(
        UserTaggedModel,
        getId(data['holder-id'], data.tag),
      );

      model.actions.actualize(null, data['user-id']);

      return;
    }

    if (data['holder-id'] || !data['user-id'] || !data.tag) {
      return;
    }

    if (data['user-id']) {
      const model = this.context.flux.get(TagsModel, data['user-id']);

      model.actions.actualize([], [data.tag]);
    }
  };
}
