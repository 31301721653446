import React from 'react';
import { Linking } from 'react-native';

export default class DeepLinking {
    constructor(flux) {
        this.flux = flux;
        this.deepLinkListeners = [];
        Linking.addEventListener('url', this.onLink);
        this.flux.bus.addListener('push-notification.opened', this.onPushNotificationOpened);
        Linking.getInitialURL().then(this.onLink);
    }

    addDeepLinkListener(listener) {
        if (this.deepLinkListeners.indexOf(listener) < 0) {
            this.deepLinkListeners.push(listener);
        }
    }

    removeDeepLinkListener(listener) {
        this.deepLinkListeners = this.deepLinkListeners.filter(item => item !== listener);
    }

    onLink = (payload) => {
        const url = typeof payload === 'string'
            ? payload
            : (payload || {}).url;
        url && this.deepLinkListeners.forEach(listener => listener(url, null));
    };

    onPushNotificationOpened = (payload) => {
        const { uri, ...otherData } = payload || {};
        uri && this.deepLinkListeners.forEach(listener => listener(uri, otherData));
    };
}
