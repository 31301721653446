import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { PackagesPreloader } from 'dating-mobile/src/payment/utils/packages-preloader';
import { PasswordUpdating } from '@sdv/domain/authorization/password-updating';
import withAuthController from 'dating-mobile/src/models/identity/controller/auth';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';
import Resources from 'dating-mobile/src/resources';

export default function createController(Component) {
  const WrappedComponent = withAuthController(Component);

  class Controller extends PureComponent {
    static displayName = 'stepped-registration.screens.password.controller';

    static propTypes = {
      userId: PropTypes.string,
      onComplete: PropTypes.func.isRequired,
      updatePassword: PropTypes.func.isRequired,
    };

    static contextTypes = {
      flux: PropTypes.object,
    };

    state = {
      error: null,
    };

    componentDidMount() {
      const { userId } = this.props;

      PackagesPreloader.shared(userId).preload();
    }

    onPasswordUpdate = (componentName, credentials) => {
      const { onComplete } = this.props;

      if (credentials && credentials.password) {
        PasswordUpdating.shared().updatePassword(
          credentials.password,
          error => {
            if (error) {
              const errorMessage =
                typeof error === 'string'
                  ? error
                  : error.message ||
                    error.desc ||
                    Resources.strings['error-message-password'];

              this.setState({
                error: errorMessage,
              });
            } else {
              this.setState({ error: null });
              onComplete();
            }
          },
        );
      }
    };

    render() {
      const { error } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          error={error}
          onSubmit={this.onPasswordUpdate}
        />
      );
    }
  }

  return withIdentityId(Controller);
}
