import React from 'react';
import PropTypes from 'prop-types';
import Resources from 'dating-mobile/src/resources';
import withConfigValue from 'dating-mobile/src/components/config-value';

export default WrappedComponent => {
    const hocComponent = ({ features }) => {
        const featuresData = features.map(feature => {
            return {
                id: feature,
                icon: Resources.images[`${feature}FeatureBonusIcon`](),
                label: Resources.strings[`subscription-features-${feature}-text`],
            };
        });

        return <WrappedComponent features={featuresData} />;
    };

    hocComponent.propTypes = {
        features: PropTypes.array,
    };

    return withConfigValue(hocComponent, {
        features: 'membership.subscription-features',
    });
};
