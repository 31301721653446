function createCheersActions(set) {
    class Actions {
        static displayName = createCheersActions.getDisplayName(set);

        get = () => (dispatch, flux) => {
            return flux.api.cheers[set].list((error, response) => {
                if (error) {
                    return dispatch(null, error);
                }

                dispatch({ cheers: response });
            });
        };
    }

    return Actions;
}

createCheersActions.getDisplayName = function(set) {
    return `cheers.${set}`;
};

export default createCheersActions;
