import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    media: {
        width: 80,
        height: 80,
        marginLeft: 12
    },
});

export default styles;
