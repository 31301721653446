import React from 'react';
import PropTypes from 'prop-types';
import { View, FlatList, TouchableOpacity, Text, Image } from 'react-native';
import Resources from '../../../../../resources';
import styles from './styles';
import configValue from '../../../../../components/config-value';
import user from '../../../../../models/user/controllers';
import testId from 'dating-mobile/src/utils/test-id';

class BoostMessagePanel extends React.Component {
    static propTypes = {
        recipient: PropTypes.string.isRequired,
        tag: PropTypes.string.isRequired,
        user: PropTypes.shape({
            name: PropTypes.string,
        }),
        price: PropTypes.number,
        devaluationRatio: PropTypes.number,
        onBoostMessagePress: PropTypes.func,
    };

    shouldComponentUpdate(nextProps) {
        const userName = this.props.user && this.props.user.name;
        const nextUserName = nextProps.user && nextProps.user.name;
        return (
            userName !== nextUserName ||
            this.props.price !== nextProps.price ||
            this.props.devaluationRatio !== nextProps.devaluationRatio
        );
    }

    onBoostMessagePressed = () => {
        this.props.onBoostMessagePress && this.props.onBoostMessagePress(this.props.tag);
    };

    render() {
        const information =
            this.props.user && this.props.user.name
                ? Resources.strings.formatString(
                      Resources.strings['chat-screen-boost-message-text-format'],
                      this.props.user.name,
                  )
                : null;

        const price =
            this.props.price && this.props.devaluationRatio
                ? this.props.price * this.props.devaluationRatio
                : null;

        return (
            <View style={styles.container}>
                <Text style={styles.text}>{information || ''}</Text>
                <TouchableOpacity style={styles.button} onPress={this.onBoostMessagePressed}>
                    <Text
                        style={styles.buttonTitle}
                        {...testId(Resources.strings['boost-message-button-accessibility-label'])}
                    >
                        {Resources.strings['chat-screen-boost-message-button-title']}
                    </Text>
                </TouchableOpacity>
                {!!price && (
                    <View style={styles.priceContainer}>
                        <Image style={styles.coin} source={Resources.images.coinIcon()} />
                        <Text style={styles.price}>{price}</Text>
                    </View>
                )}
            </View>
        );
    }
}

export default user(
    configValue(BoostMessagePanel, {
        devaluationRatio: 'currency.devaluation-ratio',
    }),
    {
        userIdPropName: 'recipient',
    },
);
