import React from 'react';
import PropTypes from 'prop-types';
import connect from '@sdv/connect';
import Model from '../model';

const PREFERENCES_PROPERTY = 'generated.user.preference.controller.preferences';
const GET_PROPERTY = 'generated.user.preference.controller.get';
const PUT_PROPERTY = 'generated.user.preference.controller.put';

export default function createController(Component, options = {}) {

    const userIdPropName = options.userIdPropName || 'id';
    const resultPropName = options.resultPropName || 'preference';

    class Controller extends React.Component {
        static displayName = 'user.preference.controller';
        static propTypes = {
            [userIdPropName]: PropTypes.string,
            [resultPropName]: PropTypes.object,
            [GET_PROPERTY]: PropTypes.func,
            [PUT_PROPERTY]: PropTypes.func,
            [PREFERENCES_PROPERTY]: PropTypes.object
        };
        static contextTypes = {
            flux: PropTypes.object
        };

        componentDidMount() {
            this.props[GET_PROPERTY] && this.props[GET_PROPERTY]();
        }

        componentDidUpdate(prevProps) {
            if (shouldReconnect(prevProps, this.props)) {
                this.props[GET_PROPERTY] && this.props[GET_PROPERTY]();
            }
        }

        update = (payload) => {
            return new Promise((resolve, reject) => {
                if (!this.props[PUT_PROPERTY]) {
                    reject('User id is not defined');
                    return;
                }

                const preferences = this.props[PREFERENCES_PROPERTY];
                this.props[PUT_PROPERTY]({...preferences, ...payload}, (error) => {
                    if (error) {
                        return reject(error);
                    }

                    return resolve();
                });
            });
        };

        render() {
            const { ...props } = this.props;

            delete props[GET_PROPERTY];
            delete props[PUT_PROPERTY];
            delete props[PREFERENCES_PROPERTY];

            return (
                <Component
                    {...props}
                    updatePreference={this.update}
                />
            );
        }
    }

    function getModels(flux, props) {
        const models = {};

        if (props[userIdPropName]) {
            models.preferencesModel = flux.get(Model, props[userIdPropName]);
        }

        return models;
    }

    function mapModelsToProps(models) {
        const preferences = models.preferencesModel
            ? models.preferencesModel.store.getState()
            : {};
        let props = {
            [PREFERENCES_PROPERTY]: preferences
        };
        if (resultPropName === '.') {
            props = {
                ...props,
                ...preferences
            };
        } else {
            props[resultPropName] = preferences;
        }
        return props;
    }

    function mapModelsToActions(models) {
        const props = {};

        if (models.preferencesModel) {
            props[GET_PROPERTY] = models.preferencesModel.actions.get;
            props[PUT_PROPERTY] = models.preferencesModel.actions.put;
        }

        return props;
    }

    function shouldReconnect(props, newProps) {
        return props[userIdPropName] !== newProps[userIdPropName];
    }

    return connect(
        getModels,
        mapModelsToProps,
        mapModelsToActions,
        shouldReconnect
    )(Controller);

};
