import React from 'react';
import PropTypes from 'prop-types';
import {
  Text,
  Platform,
  TouchableWithoutFeedback,
  Linking,
} from 'react-native';
import withSmiles from 'dating-mobile/src/models/dialogs.smiles/controller';
import Smile from '../smile';

const isLink = text =>
  // eslint-disable-next-line no-useless-escape
  /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi.test(
    text,
  );

const buildDeepLink = (link, appLinkDomain, deepLinkScheme) =>
  appLinkDomain && deepLinkScheme
    ? link.replace(
        new RegExp(`^https://${appLinkDomain}/`),
        `${deepLinkScheme}://`,
      )
    : link;

class TextWithSmiles extends React.PureComponent {
  static displayName = 'component.text-with-smiles';

  static propTypes = {
    text: PropTypes.string,
    smileSize: PropTypes.number,
    onlySmilesTextSmileSize: PropTypes.number,
    pattern: PropTypes.string,
    linksEnabled: PropTypes.bool,
    appLinkDomain: PropTypes.string,
    deepLinkScheme: PropTypes.string,
    style: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
      PropTypes.number,
    ]),
    smiles: PropTypes.arrayOf(
      PropTypes.shape({
        basename: PropTypes.string.isRequired,
        pattern: PropTypes.string.isRequired,
        isAnimated: PropTypes.bool.isRequired,
      }),
    ),
  };

  render() {
    const { linksEnabled, appLinkDomain, deepLinkScheme } = this.props;
    let { text } = this.props;
    const {
      pattern,
      smiles,
      onlySmilesTextSmileSize,
      smileSize,
      style,
    } = this.props;

    const parts = [];

    if (text && typeof pattern === 'string') {
      const regExp = new RegExp(pattern);
      let match = text.match(regExp);

      while (match && match[0]) {
        const [newPattern] = match;

        const part = text.substring(0, match.index);

        if (part) {
          if (linksEnabled && isLink(part)) {
            parts.push({
              type: 'link',
              value: part,
            });
          } else {
            parts.push({
              type: 'text',
              value: part,
            });
          }
        }

        const smile = smiles.find(smil => smil.pattern === newPattern);

        if (smile) {
          parts.push({
            type: 'smile',
            value: smile,
          });
        } else {
          parts.push({
            type: 'smile',
            value: {
              basename: newPattern,
            },
          });
        }

        text = text.substring(match.index + newPattern.length);
        match = text.match(regExp);
      }
    }

    if (text) {
      if (linksEnabled && isLink(text)) {
        parts.push({
          type: 'link',
          value: text,
        });
      } else {
        parts.push({
          type: 'text',
          value: text,
        });
      }
    }

    const textContainsOnlySmiles = parts.every(
      part =>
        part.type === 'smile' || (part.type === 'text' && !part.value.trim()),
    );

    let actualSmileSize;
    let onlySmilesTextStyle;

    if (textContainsOnlySmiles && onlySmilesTextSmileSize) {
      actualSmileSize = onlySmilesTextSmileSize;
      onlySmilesTextStyle = Platform.select({
        ios: undefined,
        android: { fontSize: smileSize }, // Android TextView must be as high as image-span inside
      });
    } else if (smileSize) {
      actualSmileSize = smileSize;
    } else {
      actualSmileSize = 20;
    }
    const smileStyle = { width: actualSmileSize, height: actualSmileSize };

    const result = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const part of parts) {
      switch (part.type) {
        case 'text':
          result.push(<Text key={`text-${result.length}`}>{part.value}</Text>);
          break;
        case 'link':
          result.push(
            <TouchableWithoutFeedback
              key={`link-${result.length}`}
              onPress={() =>
                Linking.openURL(
                  buildDeepLink(part.value, appLinkDomain, deepLinkScheme),
                )
              }
            >
              <Text
                style={{
                  textDecorationLine: 'underline',
                  ...Platform.select({
                    web: { cursor: 'pointer' },
                    default: {},
                  }),
                }}
              >
                {part.value}
              </Text>
            </TouchableWithoutFeedback>,
          );
          break;
        case 'smile':
          result.push(
            <Smile
              key={`smile-${result.length}`}
              style={smileStyle}
              smileName={part.value.basename}
              resizeMode="contain"
              fallback
            />,
          );
          break;
      }
    }

    return (
      <Text {...this.props} style={[style, onlySmilesTextStyle]}>
        {result}
      </Text>
    );
  }
}

export default withSmiles(TextWithSmiles);
