import { Platform } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import shouldUseWebLayout from 'dating-mobile/src/utils/web-layout';

const isWebLayout = shouldUseWebLayout();

const commonButtonStyles = {
  height: 50,
  width: '100%',
  maxWidth: 400,
  '@flavor dma': {
    height: 60,
    borderRadius: Platform.select({
      web: 5,
      default: 25,
    }),
  },
  alignItems: 'center',
  justifyContent: 'center',
  ...Platform.select({
    web: {
      marginTop: 10,
      borderRadius: 5,
    },
    default: {
      marginTop: 32,
      borderRadius: 25,
    },
  }),
};

const styles = EStyleSheet.create({
  modal: {
    marginHorizontal: isWebLayout ? '25%' : 16,
    marginVertical: isWebLayout ? '10%' : 16,
  },
  container: Platform.select({
    web: {
      flex: 1,
      backgroundColor: 'white',
      borderRadius: 20,
      justifyContent: 'space-between',
      position: 'relative',
      alignItems: 'center',
      paddingTop: 50,
    },
    default: {
      width: '100%',
      height: '100%',
      backgroundColor: '$brandColor',
      flexDirection: 'column',
      alignItems: 'center',
    },
  }),
  contentContainer: {
    width: '100%',
  },
  content: {
    width: '100%',
    paddingHorizontal: Platform.select({
      web: isWebLayout ? '18%' : 10,
      default: '5%',
    }),
    paddingBottom: 15,
    alignItems: 'center',
  },

  header: {
    marginBottom: isWebLayout ? 50 : 25,
    fontSize: 22,
    fontWeight: 700,
    textAlign: 'center',
    color: Platform.select({
      web: '$inputTextColor',
      default: '$accentTextColor',
    }),
  },

  formLabel: {
    marginTop: 10,
    fontFamily: '$brandFontFamily',
    fontSize: 12,
  },

  browserCardButton: {
    ...commonButtonStyles,
    backgroundColor: '#4284f4',
  },

  purchaseButton: {
    ...commonButtonStyles,
    backgroundColor: '$accentColor',
  },

  purchaseButtonDisabled: {
    ...commonButtonStyles,
    backgroundColor: '$disabledColor',
  },

  purchaseButtonText: {
    fontSize: 16,
    color: '$accentTextColor',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontFamily: '$brandFontFamily',
    '@flavor dma': {
      fontSize: 20,
    },
  },

  price: {
    marginTop: 10,
    marginHorizontal: '10%',
    color: Platform.select({
      web: '$secondaryTextColor',
      default: '$accentTextColor',
    }),
    textAlign: 'center',
    fontFamily: '$brandFontFamily',
  },

  link: {
    color: '$brandLinkTextColor',
    '@flavor ytm': {
      color: '$foregroundColor',
      textDecorationLine: 'underline',
    },
    '@flavor dma': {
      color: '$infoTextColor',
      textDecorationLine: 'underline',
    },
    ...Platform.select({
      web: { cursor: 'pointer' },
      default: {},
    }),
  },

  autobuyCheckboxStyles: {
    paddingHorizontal: Platform.select({
      web: isWebLayout ? '20%' : 10,
      default: 0,
    }),
    width: isWebLayout ? '80%' : '100%',
  },

  activityIndicatorContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  footerWrapper: { width: '100%' },
  footer: {
    width: '100%',
    paddingVertical: 10,
    backgroundColor: '$unselectedColor',
    ...Platform.select({
      web: {
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        paddingHorizontal: isWebLayout ? 0 : 15,
      },
      default: {},
    }),
  },

  footerText: {
    textAlign: 'center',
    color: '$secondaryTextColor',
  },

  paymentProtectionInfo: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 10,
  },

  paymentProtectionIcons: {
    marginLeft: 15,
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 80,
  },
  icon: {
    height: 35,
    width: 35,
  },

  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
  },

  $closeIconColor: '$primaryTextColor',
});

export default styles;
