import React from 'react';
import PropTypes from 'prop-types';
import { Text, TouchableOpacity, View } from 'react-native';
import styles from './styles';
import { InboxFilter } from '@sdv/domain/dialogs.mailbox/inbox';
import LettersList from 'dating-mobile/src/inbox/views/letters-list';
import LetterItemView from '../../letters-list/item';
import BlockUserView from 'dating-mobile/src/inbox/views/block-user';
import Resources from 'dating-mobile/src/resources';
import { SwipeRow } from 'react-native-swipe-list-view';

export default class LettersChainView extends React.PureComponent {
    static displayName = 'inbox.views.all-letters.chain-item';
    static propTypes = {
        id: PropTypes.string,
        user: PropTypes.object,
        data: PropTypes.object,
        userId: PropTypes.string.isRequired,
        onSelect: PropTypes.func.isRequired,
        deleteChain: PropTypes.func.isRequired,
        onScrollEnabled: PropTypes.func
    };

    constructor(props, context) {
        super(props, context);
        this.state = { headerVisible: true };
    }

    onLetterSelected = (letter) => {
        this.props.onSelect && this.props.onSelect(letter);
    };

    onDeletePressed = (item) => () => {
        this.props.deleteChain && this.props.deleteChain(item.chainId);
    };

    onScrollEnabled = (enabled) => {
        this.props.onScrollEnabled && this.props.onScrollEnabled(enabled);
    };

    hideHeader = () => {
        this.setState({
            headerVisible: false
        });
    };

    render() {
        const userId = this.props.id;
        const user = this.props.user || {};
        const data = this.props.data || {};
        const letter = data.headerLetter;

        const loadMoreText = `{0} more emails from ${user.name}`;
        return (
            <View style={styles.verticalContainer}>
                {
                    !!this.state.headerVisible && (
                        <SwipeRow
                            disableRightSwipe={true}
                            rightOpenValue={-176}
                            onRowOpen={() => this.onScrollEnabled(false)}
                            onRowDidClose={() => this.onScrollEnabled(true)}
                        >
                            <View style={styles.swipeContainer}>
                                <BlockUserView userId={this.props.userId} attendeeId={data.attendeeId}/>
                                <TouchableOpacity style={styles.deleteContainer} onPress={this.onDeletePressed(data)}>
                                    <Text style={styles.deleteText}>{Resources.strings.delete}</Text>
                                </TouchableOpacity>
                            </View>
                            <LetterItemView
                                userId={this.props.userId}
                                senderId={userId}
                                data={letter}
                                unreadCount={data.unreadCount}
                                onSelect={this.onLetterSelected}
                            />
                        </SwipeRow>
                    )
                }
                <View style={styles.loadChainButtonContainer}>
                    <LettersList
                        useDynamicLoading={false}
                        defaultCount={0}
                        loadMoreCount={10}
                        totalCount={data.totalCount - 1}
                        loadMoreButtonTitleFormat={loadMoreText}
                        style={styles.list}
                        userId={this.props.userId}
                        filter={InboxFilter.oneAttendee}
                        attendeeId={userId}
                        onScrollEnabled={this.onScrollEnabled}
                        onLoadMoreClicked={this.hideHeader}
                    />
                </View>
            </View>
        );
    }
}


