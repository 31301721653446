import { Platform } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import shouldUseWebLayout from 'dating-mobile/src/utils/web-layout';

const isWebLayout = shouldUseWebLayout();

const input = {
  fontSize: 17,
  borderColor: '$brandBorderColor',
  borderWidth: 1,
  borderRadius: 5,
  fontFamily: '$brandFontFamily',
  fontWeight: '600',
  color: Platform.select({
    web: '$inputTextColor',
    default: '$brandTextColor',
  }),
  textAlign: 'start',
  paddingLeft: 10,
  '@flavor ytm, udates': {
    textAlign: 'left',
  },
  '@flavor secretly': {
    fontWeight: 'normal',
    fontSize: 16,
    height: 40,
  },
};

const styles = EStyleSheet.create({
  input,

  form: {
    width: '100%',
    maxWidth: 400,
  },

  cardNumberRow: {
    position: 'relative',
    width: '100%',
  },

  cardNumber: {
    ...input,
    flex: 1,
    marginTop: 10,
  },
  cardLogoContainer: {
    width: 48,
    position: 'absolute',
    top: Platform.select({
      web: 12,
      default: 62,
    }),
    right: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardLogo: {
    width: 48,
    height: 36,
  },

  expAndCVVRow: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 10,
  },

  cvvLabel: {
    marginHorizontal: 10,
    color: Platform.select({
      web: '$inputTextColor',
      default: '$brandTextColor',
    }),
  },

  expDateInput: {
    ...input,
    width: isWebLayout ? 65 : 80,
  },

  expDateContainer: {
    width: isWebLayout ? 65 : 80,
  },
  cvvInput: {
    ...input,
    width: isWebLayout ? 80 : 80,
  },
  cvvInputContainer: {
    width: isWebLayout ? 55 : 80,
  },
  nameInput: {
    ...input,
    marginTop: 10,
  },
});

export default styles;
