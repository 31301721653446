import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  $statusBarStyle: '$primaryStatusBarStyle',
  $statusBarBackgroundColor: '$primaryBackgroundColor',

  header: {
    backgroundColor: '$primaryBackgroundColor',
    borderBottomColor: '$primaryBackgroundColor',
    shadowColor: '$primaryBackgroundColor',
  },

  headerTitle: {
    color: '$primaryTextColor',
  },

  headerTitleContainer: {
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
  },

  headerTitleContainerSearchBar: {
    width: '100%',
  },

  minimizedContainer: {
    borderRadius: 15,
    backgroundColor: '$primaryBackgroundColor',
    padding: 24,
  },

  container: {
    backgroundColor: '$primaryBackgroundColor',
    flex: 1,
  },

  title: { fontSize: 18, fontWeight: 600, marginBottom: 26 },
  buttonWrapper: { marginTop: 40, paddingHorizontal: '13%' },
  button: { height: 40 },
  buttonText: { fontSize: 14 },
  listContainer: {
    flex: 1,
    alignItems: 'stretch',
  },

  contactsListTitle: {
    marginBottom: 12,
    marginTop: 16,
    fontSize: 18,
    fontFamily: '$brandFontFamily',
    fontWeight: 'bold',
  },
});

export default styles;
