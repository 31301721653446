import React from 'react';
import connect from '@sdv/connect';
import Model from '@sdv/domain/device.permissions';

export default function createController(Component, options={}) {
    const pushNotificationsEnabledName = options.pushNotificationsEnabledName || 'pushNotificationsEnabled';

    function getModels(flux) {
        return { devicePermissionsModel: flux.get(Model) };
    }

    function mapModelsToProps(models) {
        const pushNotificationsEnabled = models.devicePermissionsModel && models.devicePermissionsModel.store.getState().pushNotificationsEnabled;

        return {
            [pushNotificationsEnabledName]: pushNotificationsEnabled
        };
    }

    function mapActionsToProps() {
        return {};
    }

    function shouldReconnect() {
        return false;
    }

    return connect(
        getModels,
        mapModelsToProps,
        mapActionsToProps,
        shouldReconnect
    )(Component);

}
