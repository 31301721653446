import React from 'react';
import connect from '@sdv/connect';
import TypingModel, { getId } from '../../user.events.typing/model';

const START_TYPING_PROPERTY = 'generated.dialogs.messages.startTyping';
const END_TYPING_PROPERTY = 'generated.dialogs.messages.endTyping';

export default function createController(Component, options = {}) {
    const userPropName = options.userPropName || 'user';
    const attendeePropName = options.attendeePropName || 'attendee';
    const onStartTypingPropName = options.onStartTypingPropName || 'onStartTyping';

    class OutgoingTyping extends React.Component {
        static displayName = 'dialogs-messages-outgoing-typing.controller';

        onStartTyping = (...args) => {
            if (!this.props[START_TYPING_PROPERTY] || !this.props[END_TYPING_PROPERTY]) { return; }

            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            } else {
                this.props[START_TYPING_PROPERTY]();
            }
            this.timer = setTimeout(
                () => {
                    this.timer = null;
                    this.props[END_TYPING_PROPERTY]();
                },
                5000
            );
            this.props[onStartTypingPropName] && this.props[onStartTypingPropName](...args)
        };

        componentWillUnmount() {
            if (this.timer) {
                clearTimeout(this.timer);
            }

            if (!this.props[END_TYPING_PROPERTY]) { return; }
            this.props[END_TYPING_PROPERTY]();
        }

        render() {
            const {
                forwardedRef,
                ...props
            } = this.props;

            delete props[START_TYPING_PROPERTY];
            delete props[END_TYPING_PROPERTY];

            return (
                <Component
                    {...props}
                    ref={forwardedRef}
                    {
                        ...{
                            [onStartTypingPropName]: this.onStartTyping
                        }
                    }
                />
            );
        }
    }

    function getModels(flux, props) {
        if (props[userPropName] && props[attendeePropName]) {
            return {
                typingModel: flux.get(TypingModel, getId(props[userPropName], props[attendeePropName]))
            };
        }
        return {};
    }

    function mapActionsToProps(models) {
        const props = {};

        if (models.typingModel) {
            props[START_TYPING_PROPERTY] = models.typingModel.actions.startTyping;
            props[END_TYPING_PROPERTY] = models.typingModel.actions.endTyping;
        }

        return props;
    }

    function shouldReconnect(props, newProps) {
        return props[userPropName] !== newProps[userPropName] || props[attendeePropName] !== newProps[attendeePropName];
    }

    const OutgoingTypingComponent = connect(
        getModels,
        () => {},
        mapActionsToProps,
        shouldReconnect
    )(OutgoingTyping);

    return React.forwardRef((props, ref) => {
        return (<OutgoingTypingComponent {...props} forwardedRef={ref} />);
    });
}
