import React from 'react';
import PropTypes from 'prop-types';
import * as ROUTES from 'dating-mobile/src/routing/router/constants';

function bindNavigation(Component) {
  class Navigation extends React.Component {
    static displayName = 'payment.initial.screen.navigation';

    static propTypes = {
      navigation: PropTypes.object.isRequired,
    };

    purchase = options => {
      const { navigation } = this.props;

      navigation.pop();
      navigation.navigate(ROUTES.PURCHASE_MODAL, {
        ...(options || {}),
        ...(navigation.getParam('params') || {}),
        reason: navigation.getParam('reason'),
        cancelButtonVisible: navigation.getParam('cancelButtonVisible'),
        onComplete: navigation.getParam('onComplete'),
      });
    };

    purchaseCredits = options => {
      const { navigation } = this.props;

      navigation.pop();
      navigation.navigate(ROUTES.CREDITS_MODAL, {
        ...(options || {}),
        ...(navigation.getParam('params') || {}),
        reason: navigation.getParam('reason'),
        cancelButtonVisible: navigation.getParam('cancelButtonVisible'),
        onComplete: navigation.getParam('onComplete'),
      });
    };

    purchaseCallsPack = options => {
      const { navigation } = this.props;

      navigation.pop();
      navigation.navigate(ROUTES.CALLS_PACK_MODAL, {
        ...(options || {}),
        ...(navigation.getParam('params') || {}),
        reason: navigation.getParam('reason'),
        cancelButtonVisible: navigation.getParam('cancelButtonVisible'),
        onComplete: navigation.getParam('onComplete'),
      });
    };

    purchaseMembership = options => {
      const { navigation } = this.props;

      navigation.pop();
      navigation.navigate(ROUTES.DETAILED_BENEFITS_MEMBERSHIP_MODAL, {
        ...(options || {}),
        ...(navigation.getParam('params') || {}),
        reason: navigation.getParam('reason'),
        cancelButtonVisible: navigation.getParam('cancelButtonVisible'),
        onComplete: navigation.getParam('onComplete'),
      });
    };

    render() {
      const { navigation } = this.props;

      return (
        <Component
          {...this.props}
          membershipRequired={navigation.getParam('membershipRequired')}
          creditsRequired={navigation.getParam('creditsRequired')}
          callsPackRequired={navigation.getParam('callsPackRequired')}
          purchase={this.purchase}
          purchaseCredits={this.purchaseCredits}
          purchaseMembership={this.purchaseMembership}
          purchaseCallsPack={this.purchaseCallsPack}
        />
      );
    }
  }

  Navigation.navigationOptions = () => ({
    headerShown: false,
  });

  return Navigation;
}

export default bindNavigation;
