import React from 'react';
import connect from '@sdv/connect';
import Model from '@sdv/domain/user/tags/personal';
import single from '../../../utils/single-execute';

const GET_PROPERTY = 'generated.user-tags.tags.get';

export default function createController(Component, options = {}) {
    const userIdPropName = options.userIdPropName || 'userId';
    const tagsPropName = options.tagsPropName || 'tags';

    class Tags extends React.Component {
        static displayName = 'user-tags.tags';

        componentDidMount() {
            if (!this.props[tagsPropName] && this.props[GET_PROPERTY]) {
                this.loadData();
            }
        }

        componentDidUpdate(prevProps) {
            if (shouldReconnect(prevProps, this.props)) {
                if (!this.props[tagsPropName] && this.props[GET_PROPERTY]) {
                    this.loadData();
                }
            }
        }

        loadData = () => {
            const tag = `${Tags.displayName}.${this.props[userIdPropName]}.get`;
            single(tag, this.props[GET_PROPERTY]);
        };

        render() {
            const { ...props } = this.props;

            delete props[GET_PROPERTY];

            return (<Component {...props} />);
        }

    }

    function getModels(flux, props) {
        const models = {};

        if (props[userIdPropName]) {
            models.tagsModel = flux.get(Model, props[userIdPropName]);
        }

        return models;
    }

    function mapModelsToProps(models) {
        const props = {};

        if (models.tagsModel) {
            props[tagsPropName] = models.tagsModel.store.getState().tags;
        }

        return props;
    }

    function mapModelsToActions(models) {
        const props = {};

        if (models.tagsModel) {
            props[GET_PROPERTY] = models.tagsModel.actions.get;
        }

        return props;
    }

    function shouldReconnect(props, newProps) {
        return props[userIdPropName] !== newProps[userIdPropName];
    }

    return connect(
        getModels,
        mapModelsToProps,
        mapModelsToActions,
        shouldReconnect
    )(Tags);

}

