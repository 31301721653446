import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Animated, Image, TouchableWithoutFeedback, View } from 'react-native';
import Resources from 'dating-mobile/src/resources/index';

import styles from './styles';

export default class PurchaseTabBarItem extends PureComponent {
  static displayName = 'routing.views.purchase-tab-bar-item.view';

  static propTypes = {
    subscriptionRequired: PropTypes.bool,
    isFocused: PropTypes.bool,
    onPress: PropTypes.func,
    testID: PropTypes.string,
    accessibilityLabel: PropTypes.string,
  };

  getImage() {
    const { subscriptionRequired, isFocused } = this.props;

    if (subscriptionRequired) {
      return Resources.images.cupIcon();
    }

    return isFocused
      ? Resources.images.tabBarPurchase()
      : Resources.images.tabBarPurchaseDisabled();
  }

  render() {
    const {
      subscriptionRequired,
      onPress,
      testID,
      accessibilityLabel,
      isFocused,
      ...props
    } = this.props;

    const image = this.getImage();

    const imageStyle = [
      styles.tabBarIcon,
      isFocused ? styles.tabBarIconActive : styles.tabBarIconInactive,
      subscriptionRequired ? styles.tabBarSubscriptionIcon : null,
    ];
    const labelStyle = [
      styles.topTabBarLabelStyle,
      isFocused ? styles.tabBarLabelActive : styles.tabBarLabelInactive,
    ];

    return (
      <TouchableWithoutFeedback
        onPress={onPress}
        testID={testID}
        accessibilityLabel={accessibilityLabel}
      >
        <View {...props}>
          <View style={styles.tabBarIconContainer}>
            <Image source={image} style={imageStyle} />
          </View>
          <Animated.Text numberOfLines={1} style={labelStyle}>
            {
              Resources.strings[
                subscriptionRequired
                  ? 'purchase-screen-subscription-title'
                  : 'purchase-screen-title'
              ]
            }
          </Animated.Text>
        </View>
      </TouchableWithoutFeedback>
    );
  }
}
