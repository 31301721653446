import React from 'react';
import { render } from 'dating-mobile/src/components/rx-render';
import { NewsletterSending } from 'dating-mobile/src/newsletter/newsletter-sending';
import { Props as ResultProps } from './view';

const withIdentityId = require('dating-mobile/src/models/identity/controller/id').default;

type ComponentProps = {
    userId: string;
};

export default function withController<P extends object>(
    Component: React.ComponentType<ResultProps>,
): React.ComponentType<P & ComponentProps> {
    const Controller = render<P & ComponentProps>(props => {
        const { userId } = props;
        const showNewsletter = () => {
            new NewsletterSending(userId).show();
        };

        return <Component {...props} onPress={showNewsletter} />;
    });

    Controller.displayName = 'create-newsletter-button.controller';

    return withIdentityId(Controller);
}
