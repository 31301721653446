import EStyleSheet from 'react-native-extended-stylesheet';
import { Platform } from 'react-native';

const styles = EStyleSheet.create({
  header: {
    backgroundColor: '$primaryBackgroundColor',
    borderBottomColor: '$primaryBackgroundColor',
    shadowColor: '$primaryBackgroundColor',
    shadowRadius: 0,
    shadowOpacity: 0,
    elevation: 0,
    shadowOffset: {
      height: 0,
    },
  },

  headerTitle: {
    color: '$primaryTextColor',
    fontFamily: '$brandFontFamily',
  },

  container: {
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },

  title: {
    fontSize: 32,
    fontFamily: '$brandFontFamily',
    color: '$primaryTextColor',
    fontWeight: '800',
    marginBottom: 32,
    textAlign: 'center',
  },

  discountExpiredTitle: {
    fontSize: 28,
    fontFamily: '$brandFontFamily',
    color: '$primaryTextColor',
    fontWeight: '500',
    marginBottom: 32,
    textAlign: 'center',
  },

  titleText: {
    ...Platform.select({
      android: {
        fontWeight: 'bold',
      },
    }),
  },

  discountText: {
    color: '#e02020',
    ...Platform.select({
      android: {
        fontWeight: 'bold',
      },
    }),
  },

  image: {
    width: '100%',
    marginBottom: 32,
  },

  subtitle: {
    fontSize: 22,
    fontFamily: '$brandFontFamily',
    color: '$primaryTextColor',
    fontWeight: '800',
    textAlign: 'center',
  },

  description: {
    fontSize: 20,
    fontFamily: '$brandFontFamily',
    color: '$primaryTextColor',
    textAlign: 'center',
    marginBottom: 32,
  },

  button: {
    height: undefined,
    width: undefined,
    backgroundColor: '$brandColor',
    borderRadius: 20,
    paddingVertical: 15,
    paddingHorizontal: 40,
  },

  buttonTitle: {
    fontFamily: '$brandFontFamily',
    fontSize: 24,
    fontWeight: '700',
    lineHeight: 40,
    textAlign: 'center',
  },

  buttonTitleContainer: {
    ...Platform.select({
      android: {
        textAlign: 'center',
      },
    }),
  },

  priceContainer: {
    fontFamily: '$brandFontFamily',
    fontSize: 18,
    fontWeight: 'normal',
    textAlign: 'center',
  },

  currentPrice: {
    color: '$brandTextColor',
  },

  oldPrice: {
    textDecorationLine: 'line-through',
    color: '$infoTextColor',
  },

  disabledText: {
    color: '$disabledTextColor',
  },

  activityIndicatorContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default styles;
