import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center'
    },

    image: {
        width: 20,
        height: 20,
        marginRight: 5,
        resizeMode: 'contain',
        '@flavor lovinga': {
            width: 24,
            height: 24
        }
    },

    textWithAttachment: {
        '@flavor lovinga': {
            color: '$primaryTextColor'
        }
    }
});

export default styles;
