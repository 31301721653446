import EStyleSheet from 'react-native-extended-stylesheet';

const commonDiscountContainer = {
  borderRadius: 12,
  paddingVertical: 16,
  paddingHorizontal: 12,
};

const commonDiscountValue = {
  backgroundColor: '$primaryBackgroundColor',
  paddingHorizontal: 8,
  paddingVertical: 8,
  borderRadius: 5,
  fontSize: 12,
  fontWeight: 900,
};

const styles = EStyleSheet.create({
  container: {
    borderRadius: 15,
    backgroundColor: '$primaryBackgroundColor',
    padding: 24,
    marginBottom: 24,
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 40,
  },
  boost: {
    ...commonDiscountContainer,
    marginBottom: 12,
    backgroundColor: '#2d8f5d',
  },
  subscription: {
    ...commonDiscountContainer,
    backgroundColor: '$accentColor',
  },
  discountTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '$brandTextColor',
  },
  boostDiscountValue: { ...commonDiscountValue, color: '#fc3e0f' },
  subscriptionDiscountValue: { ...commonDiscountValue, color: '#2d8f5d' },

  footer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 5,
  },
});

export default styles;
