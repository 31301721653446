import React, { PureComponent } from 'react';
import {
  Text,
  View,
  TextInput,
  TouchableWithoutFeedback,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  StatusBar,
} from 'react-native';
import Resources from 'dating-mobile/src/resources';
import Button from 'dating-mobile/src/components/buttons/base';
import Topic from 'dating-mobile/src/streaming/views/topics-picking/item';
import Photo from 'dating-mobile/src/components/photo';
import StreamController from 'dating-mobile/src/models/dialogs.stream/controllers/controller';
import VideoView from 'dating-mobile/src/dialogs/stream/components/video';
import UserThumbnail from 'dating-mobile/src/user/common/views/thumbnail';
import styles from './styles';

const StreamComponent = StreamController(VideoView);

export default class StreamDescriptionPresetView extends PureComponent {
  renderTopics = () => {
    const { topics, onChangeTopic } = this.props;

    return topics.map(topic => {
      return (
        <Topic
          selected
          displayText={Resources.strings['streams-topics-list'][topic]}
          onPress={onChangeTopic(topic)}
          containerStyle={styles.topicContainer}
          textStyle={styles.topicText}
        />
      );
    });
  };

  renderTopicPlaceholder = () => {
    const { hintVisible } = this.props;
    const containerStyle = hintVisible
      ? styles.topicHint
      : styles.topicContainer;

    return (
      <Topic
        selected={false}
        displayText={Resources.strings['stream-description-preset-tags']}
        onPress={this.props.onSelectTopics}
        containerStyle={containerStyle}
        textStyle={styles.topicText}
      />
    );
  };

  renderTopicsBlock = () => {
    const { topics } = this.props;

    return (
      <View style={styles.topics}>
        {topics && topics.length > 0
          ? this.renderTopics()
          : this.renderTopicPlaceholder()}
      </View>
    );
  };

  render() {
    const { title, onChangeTitle } = this.props;
    const behavior = Platform.OS === 'ios' ? 'padding' : undefined;
    const inputStyle = title ? [styles.input, styles.bold] : styles.input;

    return (
      <View style={styles.content}>
        {Platform.OS === 'ios' && <StatusBar hidden />}
        {Platform.OS === 'android' && (
          <StatusBar
            backgroundColor="transparent"
            barStyle="light-content"
            translucent
          />
        )}
        <StreamComponent
          zOrder={0}
          key={`stream-${this.props.id}`}
          id={this.props.id}
        />
        <KeyboardAvoidingView style={styles.container} behavior={behavior}>
          <ScrollView contentContainerStyle={styles.contentContainer}>
            <View style={styles.presetContainer}>
              <View style={styles.cover}>
                {this.props.cover ? (
                  <Photo style={styles.image} path={this.props.cover} />
                ) : (
                  <UserThumbnail
                    style={styles.image}
                    userId={this.props.id}
                    round
                  />
                )}

                <TouchableWithoutFeedback onPress={this.props.onChangeCover}>
                  <View style={styles.changeCover}>
                    <Text numberOfLines={2} style={styles.changeCoverText}>
                      {
                        Resources.strings[
                          'stream-description-preset-change-cover'
                        ]
                      }
                    </Text>
                  </View>
                </TouchableWithoutFeedback>
              </View>

              <View style={styles.info}>
                <TextInput
                  onChangeText={onChangeTitle}
                  style={inputStyle}
                  placeholder={
                    Resources.strings[
                      'stream-description-preset-input-placeholder'
                    ]
                  }
                  placeholderTextColor={styles.$placeholderColor}
                  multiline
                  value={title}
                />
                {this.renderTopicsBlock()}
              </View>
            </View>

            <View style={styles.buttonContainer}>
              <Button
                style={styles.button}
                title={Resources.strings['stream-description-preset-button-go']}
                onPress={this.props.onSaveChanges}
              />
            </View>
          </ScrollView>
        </KeyboardAvoidingView>
      </View>
    );
  }
}
