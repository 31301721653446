import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        justifyContent: 'space-between',
        height: '100%',
        width: '100%',
    },

    title: {
        color: '$brandTextColor',
        fontSize: 22,
        fontWeight: 'bold',
        fontFamily: '$brandFontFamily',
        marginBottom: 36,
    },

    checkboxContainer: {
        flexGrow: 1,
        flexShrink: 1,
    },

    button: {
        marginBottom: 40,
    },
});

export default styles;
