import React, { useEffect, useRef, useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Stock } from '@sdv/domain/payment/stock';
import UserTags from '@sdv/domain/user/tags';
import { BoostExtendNotification } from '@sdv/domain/user/profile-booster/boost-extend-notification';
import { UserTags as Tags } from '@sdv/domain/user/tags/user-tags';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';
import Resources from 'dating-mobile/src/resources';

import { fireEmoji, medalEmoji } from './constants';
import { NAV_ITEMS } from '../navigation-panel/constants';
import Navigator from '../../navigator';

import styles from './styles';

Specials.propTypes = {
  userId: PropTypes.string,
};

function Specials({ userId }) {
  const disposeBag = useRef();
  const [discountUsed, setDiscountUsed] = useState(false);
  const [introductoryInvoice, setIntroductoryInvoice] = useState(null);
  const [boostInvoice, setBoostInvoice] = useState(null);

  const hasSubscriptionDiscount = !discountUsed && introductoryInvoice;
  const hasAnyDiscounts = hasSubscriptionDiscount || boostInvoice;

  useEffect(() => {
    if (userId) {
      subscribe();
    }

    return unsubscribe;
  }, [userId]);

  function subscribe() {
    disposeBag.current = new Subscription();
    const notification = BoostExtendNotification.shared(userId);

    disposeBag.current.add(
      Stock.shared(userId)
        .introductoryPackagesInStock()
        .subscribe(
          packs => {
            setIntroductoryInvoice(packs?.[0]?.[1]?.[0]);
          },
          () => {},
        ),
    );

    notification.discountInvoice.subscribe(invoice => setBoostInvoice(invoice));

    disposeBag.current.add(
      UserTags.shared()
        .tagsOf(userId)
        .pipe(
          map(tags => tags.includes(Tags.PremiumMembershipDiscount)),
          distinctUntilChanged(),
        )
        .subscribe(setDiscountUsed, () => {}),
    );
  }

  function unsubscribe() {
    if (disposeBag.current) {
      disposeBag.current.unsubscribe();
      disposeBag.current = null;
    }
  }

  function onPress() {
    Navigator.navigate(NAV_ITEMS.BOOST.to);
  }

  return hasAnyDiscounts ? (
    <View style={styles.container}>
      <Text style={styles.title}>{Resources.strings['specials-title']}</Text>
      {boostInvoice && (
        <TouchableOpacity onPress={onPress} style={styles.boost}>
          <Text style={styles.discountTitle}>
            {Resources.strings['specials-boost-discount']}
          </Text>
          <View style={styles.footer}>
            <Text>{[fireEmoji, fireEmoji, fireEmoji]}</Text>
            <Text style={styles.boostDiscountValue}>
              -{boostInvoice?.discount}%
            </Text>
          </View>
        </TouchableOpacity>
      )}
      {hasSubscriptionDiscount && (
        <TouchableOpacity onPress={onPress} style={styles.subscription}>
          <Text style={styles.discountTitle}>
            {Resources.strings['specials-subscripsion-discount']}
          </Text>
          <View style={styles.footer}>
            <Text>{[medalEmoji, medalEmoji, medalEmoji]}</Text>
            <Text style={styles.subscriptionDiscountValue}>
              -{introductoryInvoice?.discount}%
            </Text>
          </View>
        </TouchableOpacity>
      )}
    </View>
  ) : null;
}

export default withIdentityId(Specials);
