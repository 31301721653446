import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'react-native';
import Resources from '../../../../../../resources';

import UserController from '../../../../../../models/user/controllers';

class PrivatePhotoDescription extends React.PureComponent {
    static displayName = 'gallery.content.private-photo-description';
    static propTypes = {
        id: PropTypes.string.isRequired,
        user: PropTypes.shape({
            name: PropTypes.string
        })
    };

    render() {

        const userName = this.props.user && this.props.user.name;

        if (!userName) {
            return null;
        }

        return (
            <Text>
                {
                    Resources.strings.formatString(
                        Resources.strings['gallery-screen-private-photo-description-format'],
                        userName
                    )
                }
            </Text>
        );

    }

}

export default UserController(PrivatePhotoDescription);
