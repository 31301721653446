import React from 'react';
import PropTypes from 'prop-types';
import {
  StatusBar,
  View,
  TouchableOpacity,
  Image,
  PanResponder,
  Animated,
  TouchableWithoutFeedback,
} from 'react-native';
import SafeArea from 'react-native-safe-area';
import { SafeAreaView } from 'react-navigation';
import ChatLogWithInput from 'dating-mobile/src/dialogs/messages/views/chat-log-with-input';
import { ToggleSpeakerphoneButton } from 'dating-mobile/src/call/views/toggle-speakerphone-button';
import CallConnectingInformation from 'dating-mobile/src/call/views/call-connecting-information';
import ServerDate from '@sdv/domain/api/date';
import RotateVideoButton from '../../views/rotate-video-button';
import { MuteAudioButton } from '../../views/mute-audio-button';
import InterruptButton from '../../views/interrupt-button';
import LocalVideo from '../../views/local-video';
import RemoteVideo from '../../views/remote-video';
import ToggleVideoButton from '../../views/toggle-video-button';
import styles from './styles';
import Resources from '../../../resources';
import Message from '../../../dialogs/common/views/message';

export default class ScreenView extends React.Component {
  static displayName = 'call.ongoing-call.screen.view';

  static contextTypes = {
    flux: PropTypes.object,
  };

  static propTypes = {
    userId: PropTypes.string,
    attendeeId: PropTypes.string,
    hasLocalVideo: PropTypes.bool,
    showVideo: PropTypes.bool,
    hasRemoteVideo: PropTypes.bool,
    lastMessage: PropTypes.object,
  };

  disableCameraTipShown = false;

  disableCameraTipTimer = null;

  constructor(props) {
    super(props);

    const initialPosition = new Animated.ValueXY();

    this.state = {
      position: initialPosition,
      disableCameraTipVisible: false,
      chatFormHeight: '50%',
      keyboardVisible: false,
    };

    this.panResponder = PanResponder.create({
      onStartShouldSetPanResponder: () => true,
      onMoveShouldSetPanResponder: (e, gestureState) => {
        // return true if user is swiping, return false if it's a single click
        return !(gestureState.dx === 0 && gestureState.dy === 0);
      },
      onPanResponderGrant: () => {
        const { position } = this.state;

        position.setOffset({
          // eslint-disable-next-line no-underscore-dangle
          x: position.x._value,
          // eslint-disable-next-line no-underscore-dangle
          y: position.y._value,
        });

        position.setValue({ x: 0, y: 0 });
      },
      onPanResponderMove: Animated.event(
        [
          null,
          {
            dx: initialPosition.x,
            dy: initialPosition.y,
          },
        ],
        { useNativeDriver: false },
      ),
      onPanResponderRelease: () => {
        const { position } = this.state;

        position.flattenOffset();
      },
    });
  }

  componentDidMount() {
    const { hasLocalVideo, showVideo } = this.props;
    const { flux } = this.context;

    if (showVideo) {
      if (hasLocalVideo) {
        this.showTip();
      }

      this.serverDate = ServerDate(flux.api);

      this.serverDate.now(date => {
        this.setState({
          videoChatStartDate: date,
        });
      });

      SafeArea.getSafeAreaInsetsForRootView().then(safeArea => {
        this.safeArea = safeArea.safeAreaInsets;
        this.createLocalVideoConstraints();
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      userId,
      attendeeId,
      showVideo,
      hasLocalVideo,
      hasRemoteVideo,
      lastMessage,
    } = this.props;
    const {
      videoChatStartDate,
      keyboardVisible,
      chatFormHeight,
      disableCameraTipVisible,
    } = this.state;

    return (
      userId !== nextProps.userId ||
      attendeeId !== nextProps.attendeeId ||
      showVideo !== nextProps.showVideo ||
      hasLocalVideo !== nextProps.hasLocalVideo ||
      hasRemoteVideo !== nextProps.hasRemoteVideo ||
      disableCameraTipVisible !== nextState.disableCameraTipVisible ||
      chatFormHeight !== nextState.chatFormHeight ||
      (nextState.keyboardVisible && lastMessage !== nextProps.lastMessage) ||
      keyboardVisible !== nextState.keyboardVisible ||
      videoChatStartDate !== nextState.videoChatStartDate
    );
  }

  componentDidUpdate() {
    const { showVideo, hasLocalVideo } = this.props;
    const { disableCameraTipVisible } = this.state;

    if (showVideo) {
      if (disableCameraTipVisible && !hasLocalVideo) {
        this.hideTip();
      } else if (!this.disableCameraTipShown && hasLocalVideo) {
        this.showTip();
      }
    }
  }

  showTip = () => {
    this.disableCameraTipShown = true;
    this.setState(
      {
        disableCameraTipVisible: true,
      },
      () => {
        this.disableCameraTipTimer = setTimeout(() => {
          this.hideTip();
        }, 5000);
      },
    );
  };

  hideTip = () => {
    if (this.disableCameraTipTimer) {
      clearTimeout(this.disableCameraTipTimer);
      this.disableCameraTipTimer = null;
    }
    this.setState({
      disableCameraTipVisible: false,
    });
  };

  onLocalVideoPressed = () => {
    this.toggleVideoButtonRef?.touchableHandlePress();
  };

  onRemoteVideoPressed = () => {
    this.inputFormRef?.dismissKeyboard();
  };

  inputFormHeightChange = inputForm => {
    const state = {};

    state.keyboardVisible = inputForm.keyboardVisible;

    if (inputForm.keyboardVisible) {
      state.chatFormHeight = inputForm.height;
    }

    this.setState(state);
  };

  onLocalVideoFrameChanged = event => {
    const { width, height } = event.nativeEvent.layout;

    if (this.localVideoWidth !== width || this.localVideoHeight !== height) {
      this.localVideoWidth = width;
      this.localVideoHeight = height;
      this.createLocalVideoConstraints();
    }
  };

  onRemoteVideoFrameChanged = event => {
    const { width, height } = event.nativeEvent.layout;

    if (this.remoteVideoWidth !== width || this.remoteVideoHeight !== height) {
      this.remoteVideoWidth = width;
      this.remoteVideoHeight = height;
      this.createLocalVideoConstraints();
    }
  };

  createLocalVideoConstraints = () => {
    const { position } = this.state;

    if (
      !this.remoteVideoWidth ||
      !this.remoteVideoHeight ||
      !this.localVideoWidth ||
      !this.localVideoHeight ||
      !this.safeArea
    ) {
      return;
    }

    const margin = 5;
    const minX = margin;
    const maxX = this.remoteVideoWidth - this.localVideoWidth - margin;

    if (styles.$localStreamDefaultAlignRight && !this.constrainedX) {
      position.setValue({
        x: maxX,
        // eslint-disable-next-line no-underscore-dangle
        y: position.y._value,
      });
    }

    this.constrainedX = position.x.interpolate({
      inputRange: [minX, Math.max(maxX, minX)],
      outputRange: [minX, Math.max(maxX, minX)],
      extrapolate: 'clamp',
    });

    const minY = this.safeArea.top + margin;
    const maxY = this.remoteVideoHeight - this.localVideoHeight - margin;

    this.constrainedY = position.y.interpolate({
      inputRange: [minY, Math.max(maxY, minY)],
      outputRange: [minY, Math.max(maxY, minY)],
      extrapolate: 'clamp',
    });

    this.forceUpdate();
  };

  renderAudioCallLayout() {
    const { userId, attendeeId, showVideo } = this.props;

    return (
      <SafeAreaView
        forceInset={{ top: 'never', bottom: 'always' }}
        style={styles.audioCallContainer}
      >
        <StatusBar
          barStyle={styles.$statusBarStyle}
          backgroundColor={styles.$statusBarBackgroundColor}
        />
        <View style={styles.overlay} />
        <View style={styles.audioCallInformation}>
          <CallConnectingInformation
            userId={userId}
            attendeeId={attendeeId}
            isVideoCall={showVideo}
          />
        </View>
        <View style={styles.audioCallButtonsContainer}>
          <View style={styles.controlButtonsContainer}>
            <ToggleSpeakerphoneButton userId={userId} attendeeId={attendeeId} />
            <MuteAudioButton userId={userId} attendeeId={attendeeId} />
          </View>
          <InterruptButton
            userId={userId}
            attendeeId={attendeeId}
            style={styles.cancelButton}
          />
        </View>
      </SafeAreaView>
    );
  }

  render() {
    const {
      showVideo,
      userId,
      attendeeId,
      hasRemoteVideo,
      hasLocalVideo,
      lastMessage,
    } = this.props;
    const {
      videoChatStartDate,
      keyboardVisible,
      chatFormHeight,
      disableCameraTipVisible,
    } = this.state;

    if (!showVideo) {
      return this.renderAudioCallLayout();
    }

    const actionButtonStyle = hasRemoteVideo
      ? styles.actionButtonOverRemoteStream
      : styles.actionButton;

    const actionButtonIconStyle = hasRemoteVideo
      ? styles.actionButtonIconOverRemoteStream
      : styles.actionButtonIcon;

    const showLastMessage =
      videoChatStartDate &&
      keyboardVisible &&
      lastMessage &&
      lastMessage.timestamp > videoChatStartDate;

    return (
      <View style={styles.container}>
        <StatusBar
          barStyle={styles.$statusBarStyle}
          backgroundColor={styles.$statusBarBackgroundColor}
        />
        {!!hasRemoteVideo && (
          <View style={styles.remoteStreamContainer}>
            <TouchableWithoutFeedback onPress={this.onRemoteVideoPressed}>
              <View style={styles.remoteStreamSubContainer}>
                <RemoteVideo
                  style={styles.remoteStream}
                  userId={userId}
                  attendeeId={attendeeId}
                  onLayout={this.onRemoteVideoFrameChanged}
                  zOrder={0}
                />
                {showLastMessage && (
                  <View style={styles.lastMessage}>
                    <Message
                      item={lastMessage}
                      lastOutgoingMessage={lastMessage.outgoing}
                    />
                  </View>
                )}
              </View>
            </TouchableWithoutFeedback>
          </View>
        )}
        <View
          style={{
            height: hasRemoteVideo ? chatFormHeight : '100%',
            width: '100%',
          }}
        >
          <ChatLogWithInput
            user={userId}
            attendee={attendeeId}
            ref={ref => {
              this.inputFormRef = ref;
            }}
            onInputFormHeightChange={this.inputFormHeightChange}
            forbidMediaFromCamera
            instant={false}
          />
        </View>
        <SafeAreaView
          forceInset={{ top: 'always', bottom: 'never' }}
          style={styles.actionButtonsContainer}
        >
          <View
            pointerEvents={hasLocalVideo ? 'none' : undefined}
            style={
              hasLocalVideo ? styles.actionButtonHiddenContainer : undefined
            }
          >
            <ToggleVideoButton
              ref={ref => {
                this.toggleVideoButtonRef = ref;
              }}
              userId={userId}
              attendeeId={attendeeId}
              showAlert
              iconShowState
              style={actionButtonStyle}
              iconStyle={actionButtonIconStyle}
            />
          </View>
          <View style={styles.actionButtonsGroup}>
            <MuteAudioButton
              userId={userId}
              attendeeId={attendeeId}
              style={actionButtonStyle}
              iconStyle={actionButtonIconStyle}
              iconShowState
            />
            <RotateVideoButton
              userId={userId}
              attendeeId={attendeeId}
              style={actionButtonStyle}
              iconStyle={actionButtonIconStyle}
              hideWhenDisabled
            />
            <InterruptButton
              userId={userId}
              attendeeId={attendeeId}
              showAlert
              style={actionButtonStyle}
              iconStyle={actionButtonIconStyle}
            />
          </View>
        </SafeAreaView>
        {!!hasLocalVideo && (
          <Animated.View
            {...this.panResponder.panHandlers}
            style={[
              styles.localStreamContainer,
              {
                left: this.constrainedX,
                top: this.constrainedY,
              },
            ]}
          >
            <TouchableOpacity
              style={styles.localStreamTouchable}
              onPress={this.onLocalVideoPressed}
            >
              <LocalVideo
                userId={userId}
                attendeeId={attendeeId}
                mode="contain"
                style={styles.localStream}
                onLayout={this.onLocalVideoFrameChanged}
                zOrder={1}
              />
            </TouchableOpacity>
            {disableCameraTipVisible && (
              <View pointerEvents="none" style={styles.tip}>
                <Image
                  style={styles.tipIcon}
                  source={Resources.images.videoCameraIcon()}
                />
              </View>
            )}
          </Animated.View>
        )}
      </View>
    );
  }
}
