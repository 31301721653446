import Actions, { parseId } from './actions';
import { createOptimisticStore } from '@sdv/model';

function createTagStore(id) {

    const { tag } = parseId(id);

    class Store extends createOptimisticStore(['put', 'delete']) {
        constructor(flux) {
            const actions = flux.getActions(Actions, id);
            super(actions);
            this.bindAction(actions.get, this.get);
        }

        get(response) {
            this.state.data = { exist: response && response.name === tag };
            this.setState(this.state);
        }

        static put() {
            return { exist: true };
        }

        static delete() {
            return { exist: false };
        }
    }

    Store.displayName = createTagStore.getDisplayName(id);
    return Store;
}

createTagStore.getDisplayName = function (id) {
    return `user-tag.${id}`;
};

export default createTagStore;
