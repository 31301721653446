import MessagesModel, { parseId } from '@sdv/domain/dialogs.messages';
import { DELIVERY_STATUS as INTERNAL_DELIVERY_STATUS } from '@sdv/domain/dialogs.messages/actions';
import {
  MESSAGE_TYPES,
  DELIVERY_STATUS,
  parseContent,
} from '../../../common.messages/model';

function createMessagesStore(id) {
  const params = parseId(id);

  class MessagesStore {
    constructor(flux) {
      this.model = flux.get(MessagesModel, id);
      this.model.store.listen(this.updateState);
      this.state = {
        messages: this.adaptMessages(this.model.store.getState()),
      };
    }

    updateState = state => {
      this.state.messages = this.adaptMessages(state);
      this.emitChange();
    };

    adaptMessages = state => {
      return state.messages.map(message => {
        const item = {
          tag: message.tag,
          sender: message.sender,
          recipient: message.recipient,
          outgoing: message.sender === params.identity,
          timestamp: message.timestamp,
          placeholder: message.text,
          read: !!message.read,
          needPay: message.needPay,
        };

        if (message.meta && message.meta['wink-template']) {
          item.reason = message.meta['wink-template'];
        }

        item.autoMessage = message.meta?.automation?.product === 'notice';

        const { type, content } = parseContent(message.text, message.meta, [
          MESSAGE_TYPES.TEXT,
          MESSAGE_TYPES.CHEER,
          MESSAGE_TYPES.PHOTO,
          MESSAGE_TYPES.VIDEO,
          MESSAGE_TYPES.STICKER,
          MESSAGE_TYPES.LETTER,
        ]);

        item.type = type;
        item.content = content;

        switch (message.status || 0) {
          case -1:
            item.status = DELIVERY_STATUS.FAILED;
            break;
          case 0:
            item.status = DELIVERY_STATUS.SENDING;
            break;
          case INTERNAL_DELIVERY_STATUS.DELIVERED:
            item.status = DELIVERY_STATUS.DELIVERED;
            break;
          case INTERNAL_DELIVERY_STATUS.UNPAID:
            item.status = DELIVERY_STATUS.UNPAID;
            break;
          default:
            item.status = DELIVERY_STATUS.UNKNOWN;
            break;
        }

        return item;
      });
    };
  }

  MessagesStore.displayName = createMessagesStore.getDisplayName(id);

  return MessagesStore;
}

createMessagesStore.getDisplayName = function(id) {
  return `dialogs-messages-adapter.${id}`;
};

export default createMessagesStore;
