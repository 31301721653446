import PropTypes from 'prop-types';
import url from 'url';
import {
  AppState,
  Platform,
  // NativeAppEventEmitter,
  NativeModules,
} from 'react-native';
import { messaging, notifications } from 'react-native-firebase';
// import NotificationsIOS from 'react-native-notifications';
import PushNotificationsPermissions from '@sdv/domain/app/push-notifications/permissions';
import request from '@sdv/commons/utils/request';
import IdentityModel from '@sdv/domain/identity/model';
import OS from '@sdv/domain/app/os';
import Service from 'dating-mobile/src/services/service';
import Navigator from 'dating-mobile/src/routing/navigator';
import { APP } from 'dating-mobile/src/routing/router/constants';
import { CallKeeper } from 'dating-mobile/src/call/services/call-keeper';
import Resources from '../../../resources';
import ConfigValue from '../../../components/config-value';
import sendMessageAction from './actions/send-message-action';
import { BackgroundRinger } from './android/background-ringer';

const CLOUD_MESSAGING = 'cloudmessages';

export const HIGH_PRIORITY_CHANNEL_ID = 'contact-messsage';
export const NORMAL_PRIORITY_CHANNEL_ID = 'messages-digest';
export const AUDIO_CALLS_CHANNEL_ID = 'audio-call';

const NativeAppEventEmitter = {};
const NotificationsIOS = {};

class NotificationsService extends Service {
  static displayName = 'service.functional.push-notifications';

  static propTypes = {
    requestPermissionsBeforeAuthorization: PropTypes.bool,
  };

  static contextTypes = {
    flux: PropTypes.object,
  };

  constructor(props, context) {
    super(props);
    this.model = context.flux.get(IdentityModel);
    this.id = this.model.store.getState().id;
    this.notificationsConfigured = false;
    this.appState = AppState.currentState;
    this.appInBackground = false;
    this.canSetupNotifications = !!props.requestPermissionsBeforeAuthorization;
    this.authorizedZoneScreenIsActive = false;
    this.deviceToken = null;
    this.pendingActions = [];
    this.initialBadgesResetPerformed = false;
  }

  componentDidMount() {
    AppState.addEventListener('change', this.appStateChanged);
    Navigator.listen(this.screenChanged);
    this.model.store.listen(this.updateState);

    const firebaseNotifications = notifications();

    if (Platform.OS === 'android') {
      let channel = new notifications.Android.Channel(
        HIGH_PRIORITY_CHANNEL_ID,
        Resources.strings.highPriorityNotificationsChannelName,
        notifications.Android.Importance.High,
      );

      firebaseNotifications.android.createChannel(channel);

      channel = new notifications.Android.Channel(
        NORMAL_PRIORITY_CHANNEL_ID,
        Resources.strings.normalPriorityNotificationsChannelName,
        notifications.Android.Importance.Default,
      );

      firebaseNotifications.android.createChannel(channel);

      channel = new notifications.Android.Channel(
        AUDIO_CALLS_CHANNEL_ID,
        Resources.strings.audioCallsNotificationsChannelName,
        notifications.Android.Importance.High,
      )
        .enableVibration(true)
        .setVibrationPattern([1000, 1000])
        .setBypassDnd(true)
        .setSound('ringtone.mp3');

      firebaseNotifications.android.createChannel(channel);

      firebaseNotifications.getInitialNotification().then(payload => {
        if (payload && payload.notification) {
          this.onNotificationOpened(payload.notification, payload.action);
        }
      });

      this.notificationOpenedListener = firebaseNotifications.onNotificationOpened(
        payload => {
          if (payload && payload.notification) {
            this.onNotificationOpened(payload.notification, payload.action);
          }
        },
      );

      this.notificationListener = messaging().onMessage(message => {
        if (!this.appInBackground && message) {
          this.onNotificationReceived(message);
        }
      });
    } else if (Platform.OS === 'ios') {
      NotificationsIOS.getInitialNotification().then(payload => {
        if (payload) {
          this.onNotificationOpened(payload);
        }
      });

      NotificationsIOS.addEventListener(
        'notificationReceivedForeground',
        this.onNotificationReceived,
      );
      NotificationsIOS.addEventListener(
        'notificationReceivedBackground',
        this.notificationReceivedBackground,
      );
      NotificationsIOS.addEventListener(
        'notificationOpened',
        this.onNotificationOpened,
      );
      NativeAppEventEmitter.addListener(
        'notificationActionReceived',
        this.oniOSNotificationActionReceived,
      );
      NotificationsIOS.consumeBackgroundQueue();

      NotificationsIOS.addEventListener(
        'pushKitRegistered',
        this.onPushKitRegistered,
      );
      NotificationsIOS.registerPushKit();
    }

    if (this.props.requestPermissionsBeforeAuthorization) {
      this.safeSetupNotifications();
    }

    this.resetBadgesCount();
  }

  componentWillUnmount() {
    if (Platform.OS === 'ios') {
      NotificationsIOS.removeEventListener(
        'notificationReceivedForeground',
        this.onNotificationReceived,
      );
      NotificationsIOS.removeEventListener(
        'notificationReceivedBackground',
        this.notificationReceivedBackground,
      );
      NotificationsIOS.removeEventListener(
        'notificationOpened',
        this.onNotificationOpened,
      );
      NotificationsIOS.removeEventListener(
        'pushKitRegistered',
        this.onPushKitRegistered,
      );
      NativeAppEventEmitter.removeListener(
        'notificationActionReceived',
        this.oniOSNotificationActionReceived,
      );
    } else {
      // this.notificationOpenedListener();
      // this.notificationListener();
    }

    AppState.removeEventListener('change', this.appStateChanged);
    Navigator.unlisten(this.screenChanged);
    this.model.store.unlisten(this.updateState);
    this.notificationsConfigured = false;
  }

  componentWillReceiveProps(props) {
    if (
      this.props.requestPermissionsBeforeAuthorization !==
      props.requestPermissionsBeforeAuthorization
    ) {
      this.canSetupNotifications =
        props.requestPermissionsBeforeAuthorization ||
        this.authorizedZoneScreenIsActive;
      this.safeSetupNotifications();
    }
  }

  updateState = state => {
    const userId = state.id;

    if (userId !== this.id) {
      this.id = userId;
      this.sendToken();
      this.sendPushKitToken();
      this.executePendingActions();
      this.safeSetupNotifications();

      if (!this.initialBadgesResetPerformed) {
        this.resetBadgesCount();
      }
    }
  };

  screenChanged = ({ stack }) => {
    this.authorizedZoneScreenIsActive = stack.indexOf(APP) >= 0;

    if (!this.props.requestPermissionsBeforeAuthorization) {
      this.canSetupNotifications = this.authorizedZoneScreenIsActive;
      this.safeSetupNotifications();
    }
  };

  appStateChanged = nextAppState => {
    if (this.appState !== 'active' && nextAppState === 'active') {
      this.resetBadgesCount();
    }
    this.appState = nextAppState;
    if (this.appState === 'background') {
      this.appInBackground = true;
    } else if (this.appState === 'active') {
      this.appInBackground = false;
    }
  };

  safeSetupNotifications = () => {
    if (this.notificationsConfigured) {
      return;
    }

    if (this.canSetupNotifications) {
      this.setupNotifications();
    }
  };

  setupNotifications = () => {
    this.notificationsConfigured = true;

    PushNotificationsPermissions.shared().requestNotificationsPermission.subscribe(
      token => {
        console.log(`FCM TOKEN: ${token}`);
        this.onTokenRegistered(token);
      },
      error => console.warn('setupNotifications failed', error),
    );
  };

  onPushKitRegistered = token => {
    this.pushKitToken = token;
    this.sendPushKitToken();
  };

  sendPushKitToken = () => {
    if (this.id && this.pushKitToken && Platform.OS === 'ios') {
      const deviceToken = `${this.pushKitToken}@ios-voip`;

      this.context.flux.api.notifications.addresses.post({
        address: deviceToken,
        channel: CLOUD_MESSAGING,
      });
    }
  };

  sendToken = () => {
    if (this.id && this.deviceToken) {
      const deviceToken = `${this.deviceToken}@${Platform.OS}`;

      this.context.flux.api.notifications.addresses.post({
        address: deviceToken,
        channel: CLOUD_MESSAGING,
      });
    }
  };

  onTokenRegistered = token => {
    this.deviceToken = token;
    this.sendToken();
  };

  onNotificationReceived = payload => {
    // eslint-disable-next-line no-underscore-dangle
    const data = payload._data;

    this.context.flux.bus.emit('push-notification.received', data);
  };

  notificationReceivedBackground = payload => {
    // eslint-disable-next-line no-underscore-dangle
    const data = payload._data;

    if (
      data &&
      data.type === 'incomingcall' &&
      data.mediaMessageUri &&
      data.senderId
    ) {
      CallKeeper.shared().initIncomingCall(data.mediaMessageUri, data.senderId);
    }
  };

  onNotificationOpened = (payload, action) => {
    // eslint-disable-next-line no-underscore-dangle
    const data = payload._data;

    if (Platform.OS === 'android') {
      BackgroundRinger.shared().stopRinging(payload.notificationId);

      data.shouldAnswerCall = action === 'accept_call';
    }

    this.sendOnNotificationOpenedAnalytics(payload);
    this.context.flux.bus.emit('push-notification.opened', data);
  };

  sendOnNotificationOpenedAnalytics(payload) {
    this.executeWithIdentityId(() => {
      this.sendNotificationOpened(payload);
    });
  }

  sendNotificationOpened = payload => {
    // TODO: move logging into separate service
    /*
        Apple push payload contains tree-like structure.
        In Android payload contains cyclic references which can't be explained as JSON.
        Only parts of Android push payload can be used for analytics events JSON-requests.
        */
    const pushData = Platform.select({
      ios: payload,
      // eslint-disable-next-line no-underscore-dangle
      android: payload._data,
    });

    this.context.flux.api.annals.add(this.id, 'push-notification-views', {
      'notification-payload': pushData,
    });

    if (pushData.src === 'winemback' && pushData.id) {
      const headers = {
        'Content-Type': 'application/json',
      };

      if (OS.shared().current !== 'web') {
        headers['User-Agent'] = this.context.flux.api.augment('user-agent');
      }
      request('https://api.winemback.com/messages/click', {
        method: 'POST',
        headers,
        body: JSON.stringify({
          messageId: pushData.id,
        }),
      });
    }
  };

  executeWithIdentityId = action => {
    if (this.id) {
      action();
    } else {
      this.pendingActions.push(() => {
        action();
      });
    }
  };

  executePendingActions() {
    this.pendingActions.forEach(action => {
      action();
    });
    this.pendingActions = [];
  }

  oniOSNotificationActionReceived = payload => {
    if (
      payload.notification.aps.category === 'reply' &&
      payload.identifier === 'slide to view'
    ) {
      sendMessageAction(payload.notification.senderId, payload.text).finally(
        () => {
          NativeModules.RNNotifications.completionHandler(
            payload.completionKey,
          );
        },
      );
    }
  };

  resetBadgesCount = () => {
    const firebaseNotifications = notifications();

    firebaseNotifications.setBadge(0);
    firebaseNotifications.removeAllDeliveredNotifications();
    if (this.id) {
      const pathname = `notifications/cloudmessages/badges/${this.id}`;
      const uri = url.format({
        pathname,
        protocol: 'https',
        hostname: this.context.flux.api.getBaseHost().replace('//', ''),
      });
      const headers = {
        'content-type': 'application/json',
        authorization: this.context.flux.api.authorize(),
      };

      if (OS.shared().current !== 'web') {
        headers['User-Agent'] = this.context.flux.api.augment('user-agent');
      }

      request(uri, {
        method: 'PUT',
        headers,
        body: '0',
      });

      this.initialBadgesResetPerformed = true;
    }
  };
}

export default ConfigValue(NotificationsService, {
  requestPermissionsBeforeAuthorization:
    'permissions.request-before-authorization',
});
