import React from 'react';
import PropTypes from 'prop-types';
import * as ROUTES from 'dating-mobile/src/routing/router/constants';

export default function(Component) {
  class Navigation extends React.Component {
    static displayName = 'streaming.screens.streams-pager.navigation';

    static propTypes = {
      id: PropTypes.string,
      navigation: PropTypes.object,
    };

    getId = () => {
      const { id, navigation } = this.props;

      return id || navigation.getParam('id');
    };

    goBack = () => {
      const { navigation } = this.props;

      navigation.pop();
    };

    switchStream = id => {
      const { navigation } = this.props;

      navigation.navigate(ROUTES.STREAM, { id });
    };

    showDiamonds = id => {
      const { navigation } = this.props;

      navigation.navigate(ROUTES.STREAM_INFO, {
        id: id || this.getId(),
      });
    };

    showProfile = () => {
      const { navigation } = this.props;

      navigation.navigate(ROUTES.STREAM_PROFILE_INFO, {
        id: this.getId(),
      });
    };

    showCurrentlyBroadcasting = onAttach => {
      const { navigation } = this.props;

      navigation.navigate(ROUTES.CURRENTRLY_BROADCASTING, {
        onAttach,
      });
    };

    render() {
      return (
        <Component
          {...this.props}
          showDiamonds={this.showDiamonds}
          showProfile={this.showProfile}
          showCurrentlyBroadcasting={this.showCurrentlyBroadcasting}
          goBack={this.goBack}
          switchStream={this.switchStream}
          id={this.getId()}
        />
      );
    }
  }

  return Navigation;
}
