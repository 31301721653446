import React from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity } from 'react-native';
import Photo from '../../../../components/photo';
import styles from './styles';
import testId from 'dating-mobile/src/utils/test-id'

export default class PhotoItem extends React.PureComponent {
    static displayName = 'user.multi-photo-uploading.photo-item';
    static propTypes = {
        path: PropTypes.string.isRequired,
        onPhotoSelect: PropTypes.func
    };

    onPhotoSelected = () => {
        this.props.onPhotoSelect && this.props.onPhotoSelect(this.props.path);
    };

    render() {
        return (
            <TouchableOpacity onPress={this.onPhotoSelected}>
                <Photo style={styles.photo} path={this.props.path} {...testId('Uploaded photo')} />
            </TouchableOpacity>
        );
    }
}
