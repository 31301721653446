import React, { PureComponent } from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import testId from 'dating-mobile/src/utils/test-id';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';
import withConfigValue from 'dating-mobile/src/components/config-value';
import withIncomingTyping from 'dating-mobile/src/models/user.events.typing/controllers/incoming-typing';
import UserName, {
  UserNameAliased,
} from 'dating-mobile/src/user/common/views/user-name';
import OnlineIndicator from 'dating-mobile/src/user/common/views/online-indicator';
import { UserPhotoAliased } from 'dating-mobile/src/user/common/views/user-thumbnail-aliased';
import withUser from 'dating-mobile/src/models/user/controllers';
import Resources from 'dating-mobile/src/resources';
import { PHOTO_TRANSFORMATION_TYPE } from 'dating-mobile/src/components/photo';
import { Gender } from '@sdv/domain/user/gender';

import styles from './styles';

class ChatTitle extends PureComponent {
  static displayName = 'dialogs.messages.chat-title';

  static propTypes = {
    allowAlias: PropTypes.bool,
    id: PropTypes.string.isRequired,
    attendeeIsTyping: PropTypes.bool,
    user: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      birthday: PropTypes.shape({
        age: PropTypes.number,
      }),
      thumbnail: PropTypes.string,
      'thumbnail-pending': PropTypes.string,
    }),
    onPress: PropTypes.func,
  };

  onPressed = () => {
    const { id, onPress } = this.props;

    onPress(id);
  };

  render() {
    const { allowAlias, user, id, onPress, attendeeIsTyping } = this.props;
    const UserNameComponent = allowAlias ? UserNameAliased : UserName;

    return (
      <TouchableOpacity
        onPress={this.onPressed}
        disabled={!onPress}
        style={{ flex: 1 }}
      >
        <View style={styles.container}>
          <View style={styles.thumbnailContainer}>
            <View style={styles.thumbnailWrapper}>
              <UserPhotoAliased
                round
                forceRetina
                userId={id}
                placeholder="avatar"
                style={styles.thumbnail}
                transformation={PHOTO_TRANSFORMATION_TYPE.DETECT_FACE}
                basename={user['thumbnail-pending'] || user.thumbnail}
                {...testId('Chat attendee thumbnail')}
              />
            </View>
            {!styles.$showOnlineIndicatorUnderName &&
              styles.$onlineIndicatorVisible && (
                <OnlineIndicator
                  userId={id}
                  style={styles.onlineIndicatorIcon}
                />
              )}
          </View>
          <View style={styles.nameContainer}>
            {(!styles.$showOnlineIndicatorUnderName ||
              (!styles.$onlineIndicatorVisible &&
                !styles.$userGenderVisible)) &&
              !!attendeeIsTyping && (
                <Text style={styles.typingInName}>
                  {Resources.strings['chat-typing']}
                </Text>
              )}
            {(styles.$showOnlineIndicatorUnderName || !attendeeIsTyping) && (
              <UserNameComponent
                userId={id}
                showAge={styles.$showAge}
                style={styles.name}
                numberOfLines={1}
                {...testId('Chat attendee name label')}
              />
            )}
            {styles.$showOnlineIndicatorUnderName &&
              styles.$onlineIndicatorVisible && (
                <OnlineIndicator
                  {...testId(
                    Resources.strings['online-indicator-accessibility-label'],
                  )}
                  type="text"
                  userId={id}
                  onlineValue={
                    attendeeIsTyping
                      ? Resources.strings['chat-typing']
                      : undefined
                  }
                  offlineValue={
                    attendeeIsTyping
                      ? Resources.strings['chat-typing']
                      : undefined
                  }
                  style={
                    attendeeIsTyping
                      ? styles.typingInIndicator
                      : styles.onlineIndicatorText
                  }
                />
              )}
            {styles.$userGenderVisible && !attendeeIsTyping && user.gender && (
              <Text style={styles.gender}>
                {Resources.strings[
                  user.gender === Gender.Male
                    ? 'gender-picker-man-item-text'
                    : 'gender-picker-woman-item-text'
                ].toLowerCase()}
              </Text>
            )}
            {styles.$userGenderVisible && attendeeIsTyping && (
              <Text style={styles.typingInIndicator}>
                {Resources.strings['chat-typing']}
              </Text>
            )}
          </View>
        </View>
      </TouchableOpacity>
    );
  }
}

export default withUser(
  withIdentityId(
    withIncomingTyping(
      withConfigValue(ChatTitle, {
        allowAlias: 'features.users-alias-enabled',
      }),
      {
        attendeeIsTypingPropName: 'attendeeIsTyping',
        userPropName: 'identity',
        attendeePropName: 'id',
      },
    ),
    'identity',
  ),
);
