import request from '@sdv/commons/utils/request';
import OS from '@sdv/domain/app/os';
import url from 'url';

function createCodesActions(userId) {
  class Actions {
    activate = (type, code) => async (dispatch, flux) => {
      let path = `/codes/${type}`;

      switch (type) {
        case CODE_TYPE.START_PRIVATE_PHOTOS:
          path = `${path}/${userId}`;
          break;
      }

      let body = {
        ...(code ? { code } : {}),
        'sender-id': userId,
      };

      switch (type) {
        case CODE_TYPE.START_PRIVATE_PHOTOS:
          body = {};
          break;
      }

      const uri = url.format({
        protocol: 'https',
        hostname: flux.api.getBaseHost().replace('//', ''),
        pathname: path,
      });

      try {
        const headers = {
          authorization: flux.api.authorize(),
          'content-type': 'application/json',
        };

        if (OS.shared().current !== 'web') {
          headers['user-agent'] = flux.api.augment('user-agent');
        }

        const response = await request(uri, {
          method: 'POST',
          body: JSON.stringify(body),
          headers,
        });

        if (response.status >= 200 && response.status < 300) {
          dispatch({
            type,
            code,
          });
        } else {
          const error = new Error('Unexpected status code');

          error.status = response.status;
          dispatch(null, error);
        }
      } catch (error) {
        dispatch(null, error);
      }
    };
  }

  Actions.displayName = createCodesActions.getDisplayName(userId);

  return Actions;
}

createCodesActions.getDisplayName = function(id) {
  return `codes.${id}`;
};

export default createCodesActions;

export const CODE_TYPE = Object.freeze({
  START_PRIVATE_PHOTOS: 'privatephotos',
});
