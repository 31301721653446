import React from 'react';

export default class Service extends React.Component {
    shouldComponentUpdate() {
        return false;
    }

    render() {
        return null;
    }
}
