import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-native';
import RoundButton from '../../../components/buttons/round';
import Resources from '../../../resources';

export default class InterruptButton extends React.PureComponent {
  static displayName = 'call.interrupt-button';

  static propTypes = {
    showAlert: PropTypes.bool,
    interrupt: PropTypes.func,
  };

  onPressed = () => {
    const { showAlert, interrupt } = this.props;

    if (showAlert) {
      Alert.alert(Resources.strings['call-finish-alert-title'], null, [
        {
          text: Resources.strings.ok,
          onPress: interrupt,
        },
        {
          text: Resources.strings.cancel,
          style: 'cancel',
        },
      ]);
    } else if (interrupt) {
      interrupt();
    }
  };

  render() {
    return (
      <RoundButton
        {...this.props}
        icon={Resources.images.callEnd()}
        onPress={this.onPressed}
      />
    );
  }
}
