import MallActions from './actions';

function createMallStore(id) {
  class MallStore {
    constructor(flux) {
      const actions = flux.getActions(MallActions, id);

      this.state = {
        products: [],
        lastPurchase: null,
        lastRestoredPurchases: [],
      };

      this.bindAction(actions.get, this.update);
      this.bindAction(actions.purchase, this.purchase);
      this.bindAction(actions.restorePurchases, this.restorePurchases);
    }

    update = data => {
      if (!data || !data.products) {
        return;
      }

      this.setState({
        products: (data || {}).products || [],
      });
    };

    purchase = payload => {
      if (!payload) {
        return;
      }

      if (payload.errorMessage) {
        this.setState(prevState => ({
          ...prevState,
          lastPurchase: null,
          lastPurchaseError: payload,
        }));
      } else if (payload.product) {
        this.setState(prevState => ({
          ...prevState,
          lastPurchase: payload,
          lastPurchaseError: null,
        }));
      }
    };

    restorePurchases = payload => {
      if (!payload || !payload.length) {
        return;
      }

      this.setState(prevState => ({
        ...prevState,
        lastRestoredPurchases: payload,
      }));
    };
  }

  MallStore.displayName = createMallStore.getDisplayName(id);

  return MallStore;
}

createMallStore.getDisplayName = function(id) {
  return `credits-mall.${id}`;
};

export default createMallStore;
