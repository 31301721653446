import React from 'react';
import PropTypes from 'prop-types';
import UserSearchModel, {
  getId as getSearchModelId,
} from '../../../models/users.search/model';
import UserModel from '../../../models/user/model';
import PreferencesModel from '../../../models/user.preference/model';

import connect from '@sdv/connect';
import { randomValuesFromArray } from '../../../utils/random';
import Resources from '../../../resources';
import MessagesModel, { getId } from '@sdv/domain/dialogs.messages';
import MingleModel from '@sdv/domain/dialogs.automation.productions.invitation';
import identityId from '../../../models/identity/controller/id';
import configValue from '../../../components/config-value';
import oppositeGender from '../../../utils/opposite-gender';
import flux from '@sdv/domain/app/flux';

const SEARCH_ID = 'start-messages-consumers';
const COUNT_CONSUMERS = 9;
const COUNT_ANIMATORS = 1;

function fillConsumers(consumersFrom, consumersTo, maxLength, predicate) {
  const consumers = [...consumersTo];

  let consumerIndex = 0;

  while (consumerIndex < consumersFrom.length && consumers.length < maxLength) {
    const consumer = consumersFrom[consumerIndex];

    if (predicate(consumer)) {
      consumers.push(consumer);
    }

    consumerIndex++;
  }

  return consumers;
}

const normalizeId = id =>
  id?.slice(-2).concat(id.padStart(12, '0').slice(0, -2));

const isAnimator = (animators, consumer) =>
  animators?.includes(normalizeId(consumer.id));

function createController(Component) {
  class Controller extends React.Component {
    static displayName = 'user.free-start-messages.screen.controller';
    static propTypes = {
      id: PropTypes.string,
      feedSeed: PropTypes.number,
      user: PropTypes.object,
      preferences: PropTypes.object,
      consumers: PropTypes.array,
      getUser: PropTypes.func,
      getPreferences: PropTypes.func,
      getConsumers: PropTypes.func,
      setConsumerFilters: PropTypes.func,
      complete: PropTypes.func,
      consumersCount: PropTypes.number,
      messagesCount: PropTypes.number,
      defaultMinAge: PropTypes.number,
      defaultMaxAge: PropTypes.number,
      emptyPreferredGenderEnabled: PropTypes.bool,
      searchNearbyEnabled: PropTypes.bool,
    };
    static contextTypes = {
      flux: PropTypes.object,
    };

    state = {
      isLoading: false,
      consumers: [],
    };

    static getDerivedStateFromProps(props) {
      if (Array.isArray(props.consumers)) {
        const animators = fillConsumers(
          props.consumers,
          [],
          COUNT_ANIMATORS,
          consumer => isAnimator(props.animators, consumer),
        );

        const consumers = fillConsumers(
          props.consumers,
          animators,
          COUNT_CONSUMERS,
          consumer => !isAnimator(props.animators, consumer),
        );

        return { consumers };
      }
    }

    constructor(props) {
      super(props);
      this.messages = randomValuesFromArray(
        Resources.strings['mingle-messages'],
        this.props.messagesCount,
      );
    }

    componentDidMount() {
      if (this.props.id) {
        this.search();
      }
    }

    componentDidUpdate(prevProps) {
      if (prevProps.id !== this.props.id && this.props.id) {
        this.search();
      }
    }

    search = () => {
      this.setState(
        {
          isLoading: true,
        },
        () => {
          this.props.getPreferences &&
            this.props.getPreferences(() => {
              this.props.getUser(() => {
                const user = this.props.user || {};
                const preferences = this.props.preferences || {};

                const gender = user.gender || 'mal';
                const preferredGender =
                  preferences['preferred-gender'] ||
                  this.props.emptyPreferredGenderEnabled
                    ? preferences['preferred-gender'] || null
                    : oppositeGender(gender);

                const params = {
                  gender: preferredGender,
                  'preferences.gender': gender,
                  minage: preferences.minage || this.props.defaultMinAge,
                  maxage: preferences.maxage || this.props.defaultMaxAge,
                  seed: (this.props.feedSeed + 1) % 10,
                };
                if (this.props.searchNearbyEnabled && user.country) {
                  params.country = user.country;
                }
                if (this.props.searchNearbyEnabled && user.city) {
                  params.city = user.city;
                }

                this.props.setConsumerFilters(params, () => {
                  this.props.getConsumers(
                    this.props.consumersCount,
                    true,
                    () => {
                      this.setState({
                        isLoading: false,
                      });
                    },
                  );
                });
              });
            });
        },
      );
    };

    onMessageSelect = message => {
      if (this.props.id && message && message.length > 0) {
        const consumers = this.state.consumers;
        for (let i = 0; i < consumers.length; i++) {
          const model = this.context.flux.get(
            MessagesModel,
            getId(this.props.id, consumers[i].id),
          );
          model.actions.send(message);
        }
        const mingleModel = this.context.flux.get(MingleModel, this.props.id);
        mingleModel.actions.put({ text: message });
      }

      // TODO: Move this log to separate tracker after moving this code to separate service.
      const selectedMessageId = this.messages.indexOf(message);
      flux.api.annals.add(this.props.id, 'free-start-message-sent', {
        selectedMessageId,
        message,
      });

      this.props.complete && this.props.complete();
    };

    render() {
      return (
        <Component
          {...this.props}
          {...this.state}
          messages={this.messages}
          onMessageSelect={this.onMessageSelect}
        />
      );
    }
  }

  function getModels(flux, props) {
    return props.id
      ? {
          userModel: flux.get(UserModel, props.id),
          preferencesModel: flux.get(PreferencesModel, props.id),
          feedModel: flux.get(UserSearchModel, props.id),
          consumersModel: flux.get(
            UserSearchModel,
            getSearchModelId(props.id, SEARCH_ID),
          ),
        }
      : {};
  }

  function mapStoresToProps(models) {
    const props = {};

    if (models.userModel) {
      props.user = models.userModel.store.getState();
    }

    if (models.preferencesModel) {
      props.preferences = models.preferencesModel.store.getState();
    }

    if (models.consumersModel) {
      props.consumers = models.consumersModel.store.getState().users;
    }

    if (models.feedModel) {
      props.feedSeed = models.feedModel.store.getState().params.seed;
    }

    return props;
  }

  function mapStoresToActions(models) {
    const props = {};

    if (models.userModel) {
      props.getUser = models.userModel.actions.get;
    }

    if (models.preferencesModel) {
      props.getPreferences = models.preferencesModel.actions.get;
    }

    if (models.consumersModel) {
      props.getConsumers = models.consumersModel.actions.search;
      props.setConsumerFilters = models.consumersModel.actions.setParams;
    }

    return props;
  }

  function shouldReconnect(props, nextProps) {
    return props.id !== nextProps.id;
  }

  const ConnectedController = connect(
    getModels,
    mapStoresToProps,
    mapStoresToActions,
    shouldReconnect,
  )(Controller);

  return identityId(
    configValue(ConnectedController, {
      searchNearbyEnabled: 'features.search-nearby-enabled',
      consumersCount: 'free-start-messages.consumers-count',
      messagesCount: 'free-start-messages.messages-count',
      emptyPreferredGenderEnabled: 'features.empty-preferred-gender-enabled',
      defaultMinAge: 'preferences.default-min-age',
      defaultMaxAge: 'preferences.default-max-age',
      animators: 'animators',
    }),
    'id',
  );
}

export default createController;
