import React, { PureComponent } from 'react';
import { View, Image, Text } from 'react-native';
import Resources from 'dating-mobile/src/resources';

import styles from './styles';

export class FreeBoostBanner extends PureComponent {
    static displayName = 'payment.views.free-boost-banner';

    render() {
        return (
            <View style={styles.container}>
                <View style={styles.imageContainer}>
                    <Image
                        style={styles.image}
                        source={Resources.images.boostIcon()}
                        resizeMode="contain"
                    />
                    <View style={styles.circle} />
                </View>
                <View style={styles.textContainer}>
                    <Text style={styles.title}>
                        <Text style={styles.textBold}>
                            {Resources.strings['free-boost-banner-boost']}
                        </Text>
                        {Resources.strings['free-boost-banner-title']}
                    </Text>
                    <Text style={styles.subtitle}>
                        {Resources.strings['free-boost-banner-subtitle']}
                    </Text>
                </View>
            </View>
        );
    }
}
