import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    buttonContainer: {
        flexShrink: 0,
        flexGrow: 0,
        backgroundColor: '$accentColor',
        width: 50,
        height: 50,
        borderRadius: 25,
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 15,
    },
    avatar: {
        width: 50,
        height: 50,
        borderRadius: 25,
        marginLeft: 20,
        marginRight: 15,
    },
    name: {
        flexShrink: 1,
        color: '$primaryTextColor',
        fontFamily: '$brandFontFamily',
        fontWeight: '600',
        fontSize: 18,
    },
    userDataContainer: {
        flexShrink: 1,
        flexGrow: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
});

export default styles;
