import React from 'react';
import PropTypes from 'prop-types';
import { render } from 'dating-mobile/src/components/rx-render';
import { UserBlocking } from '@sdv/domain/user/user-blocking';

export default function createController(Component) {
  const Controller = render((props, { useObservableAction }) => {
    if (!props.userId || !props.attendeeId) {
      return null;
    }

    const userBlocking = UserBlocking.shared(props.userId);
    const blockUser = useObservableAction(
      () => userBlocking.blockUser(props.attendeeId),
      userBlocking,
    );

    return <Component {...props} blockUser={blockUser} />;
  });

  Controller.displayName = 'call.views.block-user-button.controller';

  Controller.propTypes = {
    userId: PropTypes.string,
    attendeeId: PropTypes.string,
  };

  return Controller;
}
