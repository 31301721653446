import { singleton } from '@sdv/commons/utils/singleton';

export class PayPalBootstrapper {

    static shared = singleton(() => new PayPalBootstrapper());

    /**
     *
     * @param token
     * @return void
     */
    restoreMeBy(token) {
        // Not implemented for RN. RN doesn't redirect anywhere outside the app. No need to recover now.
    }
}
