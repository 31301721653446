import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  container: {
    alignItems: 'center',
  },

  disabled: {
    opacity: 0.5,
  },

  text: {
    fontSize: 12,
    fontFamily: '$brandFontFamily',
    color: '$brandTextColor',
    marginTop: 10,
  },
});

export default styles;
