import React from 'react';
import { View, Text, Image, TextInput, ScrollView } from 'react-native';
import PropTypes from 'prop-types';
import styles from './styles';
import Resources from '../../../resources';
import TopicsView from '../../views/topics-picking';
import Button from '../../../components/buttons/base';
import { AgeAgreementText } from 'dating-mobile/src/components/age-agreement-text';

class StreamsTopicsPicking extends React.PureComponent {
    static displayName = 'streaming.screens.streams-topics-picking.view';
    static propTypes = {
        name: PropTypes.string,
        userId: PropTypes.string,
        save: PropTypes.func,
        onTermsAgreed: PropTypes.func,
        saveButtonDisabled: PropTypes.bool,
        onTextChange: PropTypes.func,
        topicsPickingBonusAmount: PropTypes.number,
    };

    render() {
        const textContent = !!this.props.topicsPickingBonusAmount ? (
            <Text style={styles.buttonText}>
                <Text>
                    {Resources.strings.formatString(
                        Resources.strings['streams-topics-picking-button-text-first-part-format'],
                        this.props.topicsPickingBonusAmount,
                    )}
                </Text>
                <Image style={styles.buttonIcon} source={Resources.images.coinIcon()} />
                <Text>{Resources.strings['streams-topics-picking-button-text-second-part']}</Text>
            </Text>
        ) : (
            <Text style={styles.buttonText}>
                {Resources.strings['streams-topics-picking-complete-button-text']}
            </Text>
        );

        return (
            <ScrollView
                style={styles.container}
                contentContainerStyle={styles.containerContent}
                alwaysBounceVertical={false}
            >
                <View style={styles.titleContainer}>
                    <Text style={styles.title}>
                        {Resources.strings['streams-topics-picking-screen-header']}
                    </Text>
                    <Image source={Resources.images.logo()} style={styles.logo} />
                </View>
                <View style={styles.content}>
                    <TextInput
                        placeholder={
                            Resources.strings['streams-topics-picking-screen-name-placeholder']
                        }
                        placeholderTextColor={styles.$inputPlaceholderColor}
                        selectionColor={styles.$inputSelectionColor}
                        style={styles.nameInput}
                        value={this.props.name}
                        onChangeText={this.onTextChange}
                        keyboardAppearance={styles.$inputKeyboardAppearance}
                    />
                    <View style={styles.topicsContainer}>
                        <Text style={styles.topicsDescription}>
                            {Resources.strings['streams-topics-picking-screen-topics-description']}
                        </Text>
                        <TopicsView userId={this.props.userId} />
                    </View>
                </View>
                <View style={styles.agreementTextStyle}>
                    <AgeAgreementText onEnabledChanged={this.props.onTermsAgreed} />
                </View>
                <View style={styles.buttonContainer}>
                    <Button
                        title={textContent}
                        onPress={this.props.save}
                        disabled={this.props.saveButtonDisabled}
                    />
                </View>
            </ScrollView>
        );
    }

    onTextChange = text => {
        this.props.onTextChange && this.props.onTextChange(text);
    };
}

export default StreamsTopicsPicking;
