import React from 'react';
import PropTypes from 'prop-types';
import { View, Text } from 'react-native';
import UserName from 'dating-mobile/src/user/common/views/user-name';
import styles from './styles';
import Resources from 'dating-mobile/src/resources';

export default class AnonymousChatNotice extends React.Component {
    static displayName = 'dialogs.messages.views.anonymous-chat-notice.view';
    static propTypes = {
        userId: PropTypes.string,
        info: PropTypes.string,
        nameFormat: PropTypes.string
    };

    render() {
        if (!this.props.nameFormat) {
            return null;
        }

        return (
            <View style={styles.container}>
                <View style={styles.nameContainer}>
                    <Text style={styles.nameFormat}>
                        {
                            Resources.strings.formatString(
                                this.props.nameFormat,
                                <UserName
                                    userId={this.props.userId}
                                    style={styles.name}
                                />
                            )
                        }
                    </Text>
                </View>
                {
                    !!this.props.info && (
                        <Text style={styles.info}>{this.props.info}</Text>
                    )
                }
            </View>
        )
    }

}
