import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $useUniversalDatePicker: false,

    '@flavor secretly': {
        $useUniversalDatePicker: true,
    },

    container: {
        width: '100%',
    },

    genderPicker: {
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        '@flavor lovinga': {
            width: '100%',
        },
        '@flavor dma': {
            flex: 1,
        },
    },

    genderPickerWithError: {
        paddingRight: 30,
        '@flavor lovinga': {
            paddingRight: 0,
        },
    },

    genderPickerTitle: {
        fontFamily: '$brandFontFamily',
        fontWeight: 'normal',
        '@flavor lovinga': {
            fontWeight: '600',
        },
        '@flavor udates': {
            color: '$brandPlaceholderColor',
        },
    },

    genderPickerButtonContainerStyle: {
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        '@flavor dma': {
            alignItems: 'flex-end',
        },
    },

    name: {
        fontSize: 17,
        borderBottomColor: '$brandBorderColor',
        borderBottomWidth: 1,
        fontFamily: '$brandFontFamily',
        fontWeight: '600',
        color: '$brandTextColor',
    },

    dateTitle: {
        fontFamily: '$brandFontFamily',
        '@flavor lovinga': {
            textAlign: 'center',
            fontWeight: '600',
        },
        '@flavor udates': {
            color: '$brandTextColor',
            fontWeight: '500',
        },
    },

    dateContainer: {
        width: '100%',
    },

    error: {
        fontSize: 14,
        paddingVertical: 5,
        color: '$errorColor',
        fontFamily: '$brandFontFamily',
    },
});

export default styles;
