import { singleton } from '@sdv/commons/utils/singleton';
import { PersistentPreferencesSaving } from './persistent-preferences-saving';
import { PreferencesInterceptor } from './preferences-interceptor';

import { RegistrationPreferencesTracker } from './registration-preferences-tracker';

export default class Preferences extends PreferencesInterceptor {
    static shared = singleton(userId => new Preferences(userId));

    constructor(userId) {
        super(new PersistentPreferencesSaving(), userId);
    }
}

export class RegistrationPreferencesTracking {
    static shared = singleton(userId => new RegistrationPreferencesTracking(userId));

    constructor(userId) {
        this.tracker = new RegistrationPreferencesTracker(Preferences.shared(userId));
    }
}
