import React from 'react';
import PropTypes from 'prop-types';
import { Image, TouchableOpacity, Text } from 'react-native';
import Resources from 'dating-mobile/src/resources';
import styles from './styles';
import RoundButton from 'dating-mobile/src/components/buttons/round';
import Button from 'dating-mobile/src/components/buttons/base';

export const WRITE_LETTER_BUTTON_TYPE = Object.freeze({
    ICON: 'icon',
    LONG: 'long',
    ROUND: 'round'
});

export default class WriteLetterButton extends React.Component {
    static displayName = 'inbox.views.write-letter-button.view';
    static propTypes = {
        type: PropTypes.string,
        onPress: PropTypes.func,
        style: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.object,
            PropTypes.array
        ])
    };

    render() {
        let button = null;
        let buttonStyle = [styles.button, this.props.style || {}];
        if (this.props.type !== WRITE_LETTER_BUTTON_TYPE.ICON) {
            buttonStyle = [buttonStyle, styles.buttonBackgroundColor];
        }
        switch (this.props.type) {
            case WRITE_LETTER_BUTTON_TYPE.ICON:
                button = (
                    <TouchableOpacity
                        style={buttonStyle}
                        onPress={this.onButtonPressed}
                    >
                        <Image source={Resources.images.letterIcon()}/>
                    </TouchableOpacity>
                );
                break;
            case WRITE_LETTER_BUTTON_TYPE.LONG:
                button = (
                    <Button
                        style={buttonStyle}
                        titleStyle={styles.longButtonTitle}
                        onPress={this.onButtonPressed}
                        title={Resources.strings['profile-screen-send-email-button-title']}
                    />
                );
                break;
            case WRITE_LETTER_BUTTON_TYPE.ROUND:
                button = (
                    <RoundButton
                        style={buttonStyle}
                        iconStyle={styles.roundButtonStyle}
                        onPress={this.onButtonPressed}
                        icon={Resources.images.letterIcon()}
                    />
                );
                break;
        }
        return button;
    }

    onButtonPressed = () => {
        this.props.onPress && this.props.onPress();
    };
}
