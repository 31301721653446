import { Platform } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $statusBarStyle: '$brandStatusBarStyle',
    $statusBarBackgroundColor: '$brandColor',
    $forcePaddingFix: false,

    '@flavor secretly': {
        $forcePaddingFix: true,
    },

    header: {
        backgroundColor: '$brandColor',
        borderBottomColor: '$brandColor',
        shadowColor: '$brandColor',
        shadowRadius: 0,
        shadowOpacity: 0,
        elevation: 0,
        shadowOffset: {
            height: 0,
        }
    },

    headerTitle: {
        fontFamily: '$brandFontFamily',
        color: '$brandTextColor'
    },

    container: {
        backgroundColor: '$brandColor',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'stretch'
    },

    webView: {
        flex: 1,
        backgroundColor: Platform.select({web: 'white'})
    },

    loading: {
        backgroundColor: '$brandColor',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    }

});

export default styles;
