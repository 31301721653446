import { Linking, NativeModules, Platform } from 'react-native';
import Resources from 'dating-mobile/src/resources';
import VersionNumber from 'react-native-version-number';

export default async function openSupport(userId, email) {
  const url = compose(userId, email);

  await openUrl(url);
}

function compose(userId, email) {
  const message = {
    subject: `subject=Request from ${userId} to ${Resources.strings.customerServiceName}`,
  };

  if (Platform.OS === 'web') {
    const ua = navigator.userAgent;

    message.body = `body=\n${ua}`;
  } else {
    const [version, name] = NativeModules.AppConfig.currentDevice.split(';');

    message.body = `body=\nVersion ${VersionNumber.appVersion}\n${version}\n${name}`;
  }

  return encodeURI(`mailto:${email}?${message.subject}&${message.body}`);
}

async function openUrl(url) {
  try {
    await Linking.openURL(url);
  } catch (error) {
    console.log(error);
  }
}
