import React from 'react';
import connect from '@sdv/connect';
import UsersSearchModel from '../model';

const PARAMS_PROPERTY = 'generated.users-search.params.params';

export default function createParams(Component, path, pathPropName, userIdPropName = 'userId', resultPropName = 'params') {

    class Params extends React.Component {
        static displayName = 'users-search.params';

        resolveValue = () => {

            const paramPath = path || (pathPropName && this.props[pathPropName]);

            if (typeof paramPath !== 'string' || !this.props[PARAMS_PROPERTY]) {
                return undefined;
            }

            let value = this.props[PARAMS_PROPERTY];

            if (paramPath) {

                const components = paramPath.split('.');

                for (let index = 0; index < components.length; index ++) {

                    const component = components[index];

                    if (!value.hasOwnProperty(component)) {
                        return undefined;
                    }

                    value = value[component];

                }

            }

            return value;

        };

        render() {
            const { ...props } = this.props;

            const value = this.resolveValue();

            delete props[PARAMS_PROPERTY];

            return (<Component {...props} { ... { [resultPropName]: value } } />);
        }

    }

    function getModels(flux, props) {
        const models = {};

        if (props[userIdPropName]) {
            models.searchModel = flux.get(UsersSearchModel, props[userIdPropName]);
        }

        return models;
    }

    function mapStoresToProps(models) {
        const props = {};

        if (models.searchModel) {
            props[PARAMS_PROPERTY] = models.searchModel.store.getState().params;
        }

        return props;
    }

    function mapStoresToActions() {
        return {};
    }

    function shouldReconnect(props, newProps) {
        return props[userIdPropName] !== newProps[userIdPropName];
    }

    return connect(
        getModels,
        mapStoresToProps,
        mapStoresToActions,
        shouldReconnect
    )(Params);

}
