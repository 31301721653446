/**
 * Модуль отвечает за работу с Payment Request Api
 * Проверку наличия активных карт у пользователя
 * Формирование платежного запроса
 * Формирование данных платежа и данных по картам || токен оплаты
 */
// eslint-disable-next-line import/no-unresolved
import merge from 'merge/merge';

const SUCCESS = 200;
const REDIRECT = 300;
const DEFAULT_CURRENCY = 'USD';
const NOT_SUPPORTED_MESSAGE = "Payment request is't supported";

function init(apibase, method, details, options) {
  const METHOD = method || [];
  const DETAILS = details || {};
  const OPTIONS = options || {};
  const api = apibase;

  function create(...args) {
    const actualArgs = [METHOD, DETAILS, OPTIONS].map((unit, index) =>
      args[index] ? args[index] : unit,
    );

    // Не поддерживается в IE
    try {
      return new window.PaymentRequest(
        actualArgs[0],
        actualArgs[1],
        actualArgs[2],
      );
    } catch (e) {
      return Promise.reject(new Error(e));
    }
  }

  function canMakeApplePayPayment() {
    return new Promise((resolve, reject) => {
      try {
        resolve(window.ApplePaySession.canMakePayments());
      } catch (error) {
        reject(error);
      }
    });
  }

  function canMakePayment(...args) {
    const request = window.ApplePaySession
      ? { canMakePayment: canMakeApplePayPayment }
      : create(...args);

    return new Promise((resolve, reject) => {
      if (!request) {
        return reject(new Error(NOT_SUPPORTED_MESSAGE));
      }

      return request
        .canMakePayment()
        .then(resolve)
        .catch(reject);
    });
  }

  function decorateIfAppleSession(request) {
    if (request.onValidateMerchant) {
      request.onvalidatemerchant = e => {
        const completeValidation = this.completeMerchantValidation.bind(this);
        const xhr = new XMLHttpRequest();

        e.stopPropagation();

        xhr.open('POST', api.getBaseHost() + this.validationEndpoint);

        xhr.onload = () => {
          if (this.status >= SUCCESS && this.status < REDIRECT) {
            completeValidation(JSON.parse(xhr.response));
          } else {
            throw new Error('Merchant validation error.');
          }
        };

        xhr.onerror = error => {
          throw new Error(error);
        };

        xhr.setRequestHeader('Authorization', api.authorize());
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(JSON.stringify({ url: e.validationURL }));
      };
    }

    return request;
  }

  function process(...args) {
    let request;
    let response;

    return new Promise((resolve, reject) => {
      request = decorateIfAppleSession(create(...args));

      return request
        .show()
        .then(result => {
          response = result;

          return result;
        })
        .then(result => {
          response.complete('success');

          return resolve(result);
        })
        .catch(error => {
          if (response) response.complete('fail');

          return reject(new Error(error));
        });
    });
  }

  function createDetailsFromPackages(payload) {
    return {
      total: merge(payload, {
        amount: {
          value: payload.price || 0,
          currency: payload.currency || DEFAULT_CURRENCY,
        },
      }),
    };
  }

  return {
    create,
    canMakePayment,
    process,
    createDetails: createDetailsFromPackages,
  };
}

export default { init };
