import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-native';
import Keychain from '../../../utils/keychain';
import Resources from '../../../resources';

import identity from '../../../models/identity/controller';
import configValue from '../../../components/config-value';
import UserModel from '../../../models/user/model';

export default function createController(Component) {

    class Controller extends React.Component {
        static displayName = 'authentication.auth-loading.screen.controller';
        static contextTypes = {
            flux: PropTypes.object
        };
        static propTypes = {
            demoModeEnabled: PropTypes.bool,
            auth: PropTypes.func.isRequired,
            signUp: PropTypes.func.isRequired,
            showAuth: PropTypes.func.isRequired
        };

        componentDidMount() {
            this.checkAuth().then();
        }

        checkAuth = async () => {

            try {
                const token = await Keychain.getToken();

                if (token) {

                    return await this.props.auth(token);

                }

                if (this.props.demoModeEnabled) {

                    await this.signUpAsAnonymous();

                } else {

                    this.props.showAuth();

                }

            } catch (error) {

                if (error.status === 401 || error.status === 403) {
                    await Keychain.clearToken();

                    await this.checkAuth();
                } else {
                    this.showNetworkError(this.checkAuth);
                }
            }

        };

        signUpAsAnonymous = async () => {
            try {
                const { id, token } = await this.props.signUpAsAnonymous();

                await Keychain.setToken(token);

                const userModel = this.context.flux.get(UserModel, id);
                userModel.actions.patch({
                    name: 'Anonymous'
                });
            } catch (error) {
                this.showNetworkError(this.signUpAsAnonymous);
            }
        };

        showNetworkError = (callback) => {
            Alert.alert(
                Resources.strings['network-error-alert-title'],
                Resources.strings['network-error-alert-message'],
                [
                    {
                        text: Resources.strings.ok,
                        onPress: callback
                    }
                ]
            );
        };

        render() {
            return (<Component {...this.props} />);
        }

    }

    return configValue(
        identity(Controller),
        {
            demoModeEnabled: 'features.demo-mode-enabled'
        }
    );

}
