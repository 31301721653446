import React from 'react';
import Resources from 'dating-mobile/src/resources';
import PropTypes from 'prop-types';
import Badge from '../badge';

export default class UserConversationsBadge extends React.PureComponent {
  static displayName = 'user.common.views.user-badges.user-conversations-badge';

  static propTypes = {
    count: PropTypes.number.isRequired,
  };

  render() {
    const { count } = this.props;

    if (count === undefined) {
      return null;
    }

    return (
      <Badge
        icon={Resources.images.conversationsBadgeIcon()}
        text={Resources.strings.formatString(
          Resources.strings['conversations-badge-title-format'],
          count,
        )}
      />
    );
  }
}
