import EStyleSheet from 'react-native-extended-stylesheet';
import shouldUseWebLayout from 'dating-mobile/src/utils/web-layout';

const isWebLayout = shouldUseWebLayout();

const styles = EStyleSheet.create({
  canHaveUnderline: false,
  $showLookingForTitle: true,
  '@flavor dma': {
    canHaveUnderline: true,
  },
  '@flavor udates': {
    $showLookingForTitle: false,
  },
  container: {
    width: '100%',
    '@flavor lovinga, dma, udates': {
      backgroundColor: '$primaryBackgroundColor',
    },
  },
  aboutMeContainer: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    paddingLeft: 20,
    '@flavor lovinga, udates': {
      paddingLeft: 0,
    },
  },
  itemContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 10,
  },
  itemFieldName: {
    fontSize: 15,
    fontWeight: '400',
    fontFamily: '$brandFontFamily',
    '@flavor lovinga, udates': {
      fontFamily: '$brandFontFamily',
      fontWeight: '400',
      fontSize: 17,
      color: '$primaryTextColor',
    },
    '@flavor dma': {
      fontSize: 18,
    },
  },
  itemFieldValue: {
    fontSize: 15,
    fontWeight: '600',
    fontFamily: '$brandFontFamily',
    '@flavor lovinga, udates': {
      fontFamily: '$brandFontFamily',
      fontWeight: '400',
      fontSize: 17,
      color: '$primaryTextColor',
    },
    '@flavor dma': {
      fontSize: 18,
      fontWeight: '400',
    },
  },
  fewWordsText: {
    textAlign: 'left',
    marginBottom: 24,
    ...(isWebLayout
      ? { fontSize: 20, color: '#989898', marginLeft: 24 }
      : { fontSize: 15, marginLeft: 20 }),
    fontFamily: '$brandFontFamily',
    '@flavor lovinga, udates': {
      color: '$primaryTextColor',
      fontSize: 17,
      marginLeft: 0,
      marginBottom: 0,
    },
    '@flavor dma': {
      marginBottom: 0,
      fontSize: 1,
    },
  },
  fewWordsTitle: {
    ...(isWebLayout
      ? {
          fontSize: 20,
          color: '#989898',
          fontWeight: 600,
          marginTop: 24,
          marginLeft: 24,
          marginBottom: 16,
        }
      : {}),
  },
  bubbleContainer: {
    backgroundColor: '$primaryBackgroundColor',
    borderRadius: isWebLayout ? 6 : 8,
    marginLeft: isWebLayout ? 24 : 8,
    marginRight: isWebLayout ? 24 : 8,
    marginBottom: isWebLayout ? 12 : 8,
    '@flavor lovinga, udates': {
      borderRadius: 0,
      marginLeft: 16,
    },
    '@flavor dma': {
      marginLeft: 16,
    },
  },
  underline: {
    '@flavor dma': {
      marginTop: 32,
      height: 1,
      backgroundColor: '$brandBorderColor',
      width: '100%',
    },
  },
  bubbleTitleContainer: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 16,
  },
  bubbleTitle: {
    marginTop: 20,
    marginBottom: 16,
    marginLeft: 20,
    fontSize: 15,
    fontWeight: '600',
    fontFamily: '$brandFontFamily',
    '@flavor lovinga, udates': {
      color: '$primaryTextColor',
      fontSize: 24,
      fontFamily: '$brandFontFamily',
      fontWeight: '700',
      marginLeft: 0,
      marginTop: 24,
    },
    '@flavor dma': {
      fontSize: 24,
      fontWeight: 'bold',
      color: '$primaryTextColor',
    },
  },
  nameComponent: {
    '@flavor secretly': {
      marginTop: isWebLayout ? 28 : 8,
    },
  },
  nameTitle: {
    flexShrink: 1,
    '@flavor dma': {
      fontSize: 28,
    },
    '@flavor secretly': {
      color: '$primaryTextColor',
      ...(isWebLayout ? { fontSize: 20, fontWeight: 600 } : {}),
    },
  },
  travelDestination: {
    color: '#484D50',
    fontSize: 15,
    marginLeft: 20,
    marginBottom: 15,
  },
  liveInText: {
    marginTop: -18,
    marginLeft: 2,
    fontSize: 14,
    color: '$infoTextColor',
    fontFamily: '$brandFontFamily',
  },
  userGoalsContainer: {
    marginLeft: 16,
  },
  interestItemText: {
    fontSize: 16,
  },
  gallery: {
    margin: 8,
  },
  onlineIndicatorContainer: {
    marginLeft: 10,
  },
  nameContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flexShrink: 1,
  },
  locateIcon: {
    height: 16,
    width: 16,
  },
});

export default styles;
