import { AppState } from 'react-native';
import { config } from 'react-native-firebase';
import isTestBuild from 'dating-mobile/src/utils/is-test-build';
import Service from '../../service';
import { ConfigLoader } from './config-loader';

class ConfigProvider extends Service {
  static displayName = 'service.config-provider';

  componentDidMount() {
    AppState.addEventListener('change', this.onAppStateChanged);

    if (isTestBuild) {
      config().enableDeveloperMode();
    }

    ConfigLoader.shared().reloadConfig();
  }

  componentWillUnmount() {
    AppState.removeEventListener('change', this.onAppStateChanged);
  }

  onAppStateChanged = nextAppState => {
    if (this.appState !== 'active' && nextAppState === 'active') {
      ConfigLoader.shared().reloadConfig();
    }

    this.appState = nextAppState;
  };
}

export default ConfigProvider;
