import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { CONGRATULATION } from 'dating-mobile/src/routing/router/constants';

function bindNavigation(Component) {
  class Navigation extends PureComponent {
    static propTypes = {
      navigation: PropTypes.object.isRequired,
    };

    onPurchaseCompleted = () => {
      const { navigation } = this.props;
      const onComplete = navigation.getParam('onComplete');

      navigation.navigate(CONGRATULATION, { onComplete });
    };

    handleClosePress = () => {
      const { navigation } = this.props;
      const onComplete = navigation.getParam('onComplete');

      if (onComplete) {
        onComplete();
      }
    };

    render() {
      return (
        <Component
          onPurchaseCompleted={this.onPurchaseCompleted}
          onClosePress={this.handleClosePress}
          {...this.props}
        />
      );
    }
  }

  Navigation.navigationOptions = () => {
    return {
      headerShown: false,
    };
  };

  return Navigation;
}

export default bindNavigation;
