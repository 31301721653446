/**
 * Сервис обрабатывает состояния видеочата
 */
import InCallManager from 'react-native-incall-manager';
import merge from 'merge/merge';
import ServerDate from '@sdv/domain/api/date';
import MediaChat from '../index';
import { convertFromApi } from '../../../../utils/object';

// TODO добавить роли. Убран модуль require('modules/roles')
const roles = {};

function create(userId, flux, config, bus) {
  const activeMediaChats = {};
  const invites = {};
  const date = ServerDate(flux.api);
  const canceledInvites = [];

  function addMediaChat(attendee, media) {
    activeMediaChats[attendee] = media;
  }

  function removeMediaChat(attendee) {
    delete activeMediaChats[attendee];
  }

  function start(attendee, options) {
    if (roles.commissions && (!options || !options.phantom)) {
      return;
    }

    if (activeMediaChats[attendee]) {
      return;
    }

    new MediaChat(attendee, flux.api, config, userId, bus).start(options);
  }

  function answer(message) {
    if (activeMediaChats[message.sender]) {
      return;
    }

    new MediaChat(message.sender, flux.api, config, userId, bus).answer(
      message,
    );
  }

  function stop(attendee, options) {
    if (activeMediaChats[attendee] && !activeMediaChats[attendee].phantom.in) {
      activeMediaChats[attendee].stop(options);
    }
  }

  function decline(message) {
    new MediaChat(message.sender, flux.api, config, userId, bus).decline(
      message,
    );
  }

  function toggleAudio(attendee, enable) {
    activeMediaChats[attendee]?.toggleAudio(enable);
  }

  /**
   * @param speakerphoneOn
   * true -> force speaker on
   * false -> force speaker off
   * null -> use default behavior according to media type
   */
  function setForceSpeakerphoneOn(speakerphoneOn) {
    InCallManager.setForceSpeakerphoneOn(speakerphoneOn);
  }

  function toggleVideo(attendee, enable) {
    activeMediaChats[attendee]?.toggleVideo(enable);
  }

  function rotateVideo(attendee) {
    activeMediaChats[attendee]?.rotateVideo();
  }

  function resync(attendee) {
    activeMediaChats[attendee]?.resync();
  }

  // Android шлет phase 0 в случает смены состояния камеры в момент активного видеочата
  function workaroundAndroidPhase0Compatibility(message) {
    if (message.network.browser !== 'android') {
      return;
    }

    if (message.network.version) {
      return;
    }

    const invite =
      (message.in || message.out) &&
      message.resync &&
      message.network &&
      message.network.phase === 0;

    if (!invite) {
      return;
    }

    if (activeMediaChats[message['sender-id']]) {
      // eslint-disable-next-line no-param-reassign
      message.network.phase = 1;
    }
  }

  function emit(data) {
    date.now(serverDate => emitWithTime(data, serverDate));
  }

  function emitWithTime(message, serverDate) {
    let attendee;

    workaroundAndroidPhase0Compatibility(message);

    const data = merge.recursive(true, message);

    if (data.network && data.network.ice) {
      data.network.ice = convertFromApi(data.network.ice);
    }

    if (data.phantom) {
      data.out = false;
    }

    const invite =
      (data.in || data.out) &&
      data.resync &&
      data.network &&
      data.network.phase === 0;

    const expired = serverDate - new Date(data.timestamp).getTime() > 60 * 1000;
    const canceled = canceledInvites.indexOf(data.id) >= 0;

    if (invite && expired === false && canceled === false) {
      if (Object.keys(activeMediaChats).length) {
        return;
      }

      // eslint-disable-next-line no-restricted-syntax
      for (attendee in activeMediaChats) {
        if (hasOwnProperty.call(activeMediaChats, attendee)) {
          if (activeMediaChats[attendee].phantom.in) {
            activeMediaChats[attendee].stop();

            setTimeout(() => emit(data), 0);

            return;
          }
        }
      }

      data.sender = data['sender-id'];
      data.recipient = data['recipient-id'];
      data.meta = { media: true };
      invites[data.sender] = data;
      bus.emit('command.invite.add', data);
    } else {
      if (data.in === false && data.out === false) {
        const senderId = data['sender-id'];
        const inviteData = invites[senderId];

        if (inviteData) {
          canceledInvites.push(inviteData.id);
        }

        bus.emit('command.invite.remove', senderId);
      }

      activeMediaChats[data['sender-id']]?.addMessage(data);
    }
  }

  bus.addListener('event.dialogs.media.messages.added', emit);
  bus.addListener('command.chat.media.start', start);
  bus.addListener('command.chat.media.answer', answer);
  bus.addListener('command.chat.media.audio.toggle', toggleAudio);
  bus.addListener(
    'command.chat.media.audio.speakerphone.toggle',
    setForceSpeakerphoneOn,
  );
  bus.addListener('command.chat.media.video.toggle', toggleVideo);
  bus.addListener('command.chat.media.video.rotate', rotateVideo);
  bus.addListener('command.chat.media.stop', stop);
  bus.addListener('command.chat.media.decline', decline);
  bus.addListener('command.chat.media.resync', resync);
  bus.addListener('event.chat.media.created', addMediaChat);
  bus.addListener('event.chat.media.destroyed', removeMediaChat);

  return bus;
}

export default create;
