import React from 'react';
import PropTypes from 'prop-types';
import UserDefaults from 'react-native-default-preference';

import { OnboardingProgress } from 'dating-mobile/src/authentication/utils/onboarding-progress';
import {
  COMPLETED_STEP,
  REGISTRATION_STEP,
  getOrderedSteps,
  getOauthOrderedSteps,
} from 'dating-mobile/src/authentication/utils/registration-steps';

import withIdentity from '../../models/identity/controller';
import withConfigValue from '../../components/config-value';

const REGISTRATION_STEP_STORAGE_KEY = 'authentication.registration-step';

export default function createController(Component) {
  class Controller extends React.Component {
    static displayName = 'authentication.auth-routing';

    static propTypes = {
      identity: PropTypes.object,
      isFocused: PropTypes.bool,
      demoModeEnabled: PropTypes.bool,
      travelsEnabled: PropTypes.bool,
      feedEnabled: PropTypes.bool,
      choosePreferencesSeparatelyEnabled: PropTypes.bool,
      creditsForStartPrivatePhotosEnabled: PropTypes.bool,
      sendFreeStartMessagesEnabled: PropTypes.bool,
      auth: PropTypes.func.isRequired,
      signUp: PropTypes.func.isRequired,
      showApp: PropTypes.func.isRequired,
      showAuth: PropTypes.func.isRequired,
      showRegistrationStep: PropTypes.func.isRequired,
      streamsTopicsPickingBonusAmount: PropTypes.number,
      anonymousUsingEnabled: PropTypes.bool,
      premiumSubscriptionEnabled: PropTypes.bool,
      onboardingSubscriptionScreenEnabled: PropTypes.bool,
      onboardingBoostScreenEnabled: PropTypes.bool,
      steppedRegistrationEnabled: PropTypes.bool,
      streamsTopicsEnabled: PropTypes.bool,
      goalsEnabled: PropTypes.bool,
    };

    componentDidMount() {
      const { identity } = this.props;

      if (identity?.id && !identity.demo) {
        this.auth(this.props);
      }
    }

    componentWillReceiveProps(nextProps) {
      const { identity } = this.props;

      if (
        !nextProps.identity ||
        !nextProps.identity.id ||
        (identity &&
          identity.id === nextProps.identity.id &&
          identity.authorizationMethod ===
            nextProps.identity.authorizationMethod &&
          identity.demo === nextProps.identity.demo)
      ) {
        return;
      }

      this.auth(nextProps);
    }

    navigateToStep = step => {
      const { showApp, showRegistrationStep } = this.props;

      OnboardingProgress.shared().goToStep(step);

      if (step === COMPLETED_STEP) {
        showApp();

        return;
      }

      const oldSteps = this.registrationSteps();
      const oldIndex = oldSteps.indexOf(step);

      showRegistrationStep(step, {
        registrationInProgress: true,
        onComplete: async () => {
          const { identity } = this.props;
          const steps = this.registrationSteps();
          const index = steps.indexOf(step);
          let nextStep;

          if (index < 0) {
            nextStep = oldSteps
              .slice(oldIndex + 1)
              .find(oldStep => steps.indexOf(oldStep) >= 0);
          } else {
            nextStep = steps[index + 1];
          }
          nextStep = nextStep || COMPLETED_STEP;

          const registrationStepsString = await UserDefaults.get(
            REGISTRATION_STEP_STORAGE_KEY,
          );
          const registrationSteps =
            (registrationStepsString && JSON.parse(registrationStepsString)) ||
            {};

          registrationSteps[identity.id] = nextStep;
          await UserDefaults.set(
            REGISTRATION_STEP_STORAGE_KEY,
            JSON.stringify(registrationSteps),
          );
          this.navigateToStep(nextStep);
        },
      });
    };

    firstStepForOauthRegistration = () => {
      return getOauthOrderedSteps()[0] || COMPLETED_STEP;
    };

    registrationSteps = () => {
      const {
        identity,
        steppedRegistrationEnabled,
        streamsTopicsEnabled,
        streamsTopicsPickingBonusAmount,
        travelsEnabled,
        feedEnabled,
        anonymousUsingEnabled,
        goalsEnabled,
        choosePreferencesSeparatelyEnabled,
        creditsForStartPrivatePhotosEnabled,
        sendFreeStartMessagesEnabled,
        premiumSubscriptionEnabled,
        onboardingSubscriptionScreenEnabled,
        onboardingBoostScreenEnabled,
      } = this.props;

      if (steppedRegistrationEnabled) {
        return this.oauthSteppedRegistration
          ? getOauthOrderedSteps()
          : getOrderedSteps();
      }

      const steps = [];

      if (identity?.demo) {
        if (streamsTopicsEnabled) {
          steps.push(REGISTRATION_STEP.TOPICS_PICKING);

          if (streamsTopicsPickingBonusAmount) {
            steps.push(REGISTRATION_STEP.TOPICS_PICKING_BONUS);
          }
        }

        return steps;
      }

      if (travelsEnabled) {
        steps.push(REGISTRATION_STEP.TRAVEL_DESTINATION);
      }

      if (feedEnabled) {
        if (anonymousUsingEnabled) {
          steps.push(REGISTRATION_STEP.BIRTHDAY_WITH_GENDER);
          steps.push(REGISTRATION_STEP.PHOTO_WITH_BIO);
        } else {
          steps.push(REGISTRATION_STEP.UPDATE_USER);
        }
      }

      if (goalsEnabled) {
        steps.push(REGISTRATION_STEP.USER_GOALS);
      }

      if (feedEnabled && choosePreferencesSeparatelyEnabled) {
        steps.push(REGISTRATION_STEP.CHOOSE_PREFERENCES);
      }

      if (creditsForStartPrivatePhotosEnabled) {
        steps.push(REGISTRATION_STEP.UPLOAD_PRIVATE_PHOTOS);
      }

      if (sendFreeStartMessagesEnabled) {
        steps.push(REGISTRATION_STEP.SEND_START_MESSAGES);
      }

      if (premiumSubscriptionEnabled && onboardingSubscriptionScreenEnabled) {
        steps.push(REGISTRATION_STEP.SUBSCRIPTION);
      }

      if (onboardingBoostScreenEnabled) {
        steps.push(REGISTRATION_STEP.BOOST);
      }

      return steps;
    };

    async auth(nextProps) {
      const user = nextProps.identity.id;
      const { authorizationMethod } = nextProps.identity;

      const registrationStepsString = await UserDefaults.get(
        REGISTRATION_STEP_STORAGE_KEY,
      );
      const registrationSteps =
        (registrationStepsString && JSON.parse(registrationStepsString)) || {};

      if (
        authorizationMethod &&
        authorizationMethod.startsWith('signup') &&
        !registrationSteps[user]
      ) {
        if (
          nextProps.steppedRegistrationEnabled &&
          authorizationMethod !== 'signup:password'
        ) {
          this.oauthSteppedRegistration = true;
        }
        const step =
          nextProps.steppedRegistrationEnabled &&
          authorizationMethod !== 'signup:password'
            ? this.firstStepForOauthRegistration()
            : this.registrationSteps()[0] || COMPLETED_STEP;

        registrationSteps[user] = step;

        await UserDefaults.set(
          REGISTRATION_STEP_STORAGE_KEY,
          JSON.stringify(registrationSteps),
        );

        this.navigateToStep(step);
      } else {
        const step = registrationSteps[user] || COMPLETED_STEP;

        this.navigateToStep(step);
      }
    }

    render() {
      return <Component {...this.props} />;
    }
  }

  return withConfigValue(withIdentity(Controller), {
    demoModeEnabled: 'features.demo-mode-enabled',
    travelsEnabled: 'features.travels-enabled',
    feedEnabled: 'features.feed-enabled',
    choosePreferencesSeparatelyEnabled:
      'features.choose-preferences-separately-during-registration-enabled',
    creditsForStartPrivatePhotosEnabled:
      'features.credits-for-start-private-photos-enabled',
    sendFreeStartMessagesEnabled: 'features.send-free-start-messages-enabled',
    goalsEnabled: 'features.goals-enabled',
    streamsTopicsEnabled: 'features.streams-topics-enabled',
    streamsTopicsPickingBonusAmount: 'bonuses.topics-picking-amount',
    steppedRegistrationEnabled: 'features.stepped-registration-enabled',
    anonymousUsingEnabled: 'features.anonymous-using-enabled',
    premiumSubscriptionEnabled: 'features.premium-subscription-enabled',
    onboardingSubscriptionScreenEnabled:
      'features.onboarding-subscription-screen-enabled',
    onboardingBoostScreenEnabled: 'features.onboarding-boost-screen-enabled',
  });
}
