import MailboxStore from '../base/store';
import Actions from './actions';
import displayName from './display-name';

function createStore(id) {

    class Store extends MailboxStore {

        /**
         * @param {Alt} flux
         */
        constructor(flux) {
            super(flux, flux.getActions(Actions, id));
        }

        onNewLetterAdded(letter) {
            if (letter.sender === id) {
                const letterIds = [...this.letters.keys()];
                for (let letterId of letterIds) {
                    if (this.letters.get(letterId).sender === letter.recipient) {
                        this.letters.delete(letterId);
                    }
                }
                this.updateModel();
            } else if (letter.recipient === id && !letter.introductory && letter.read) {
                this.addNewLetterOrderedByTime(letter);
                this.updateModel();
            }
        }

        onLetterRead(letter) {
            if (letter.recipient === id && !letter.introductory) {
                this.addNewLetterOrderedByTime(letter);
                this.updateModel();
            }
        }
    }

    Store.displayName = displayName(id);

    return Store;
}

createStore.getDisplayName = displayName;

export default createStore;
