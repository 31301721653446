import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import ImageButton from 'dating-mobile/src/components/navigation/views/image-button';
import Resources from 'dating-mobile/src/resources';
import Can from 'dating-mobile/src/models/user.ability/controller';
import testId from 'dating-mobile/src/utils/test-id';
import IfConfigValue from 'dating-mobile/src/components/config-value/if';
import * as ROUTES from 'dating-mobile/src/routing/router/constants';

import styles from './styles';

export default function bindNavigation(Component) {
  class Navigation extends React.Component {
    static displayName = 'user.screens.account.navigation';

    static propTypes = {
      navigation: PropTypes.object.isRequired,
    };

    render() {
      return <Component {...this.props} />;
    }
  }

  Navigation.navigationOptions = ({ navigation, navigationOptions }) => ({
    title: '',
    headerStyle: [navigationOptions.headerStyle, styles.header],
    headerTitleStyle: [navigationOptions.headerTitleStyle, styles.headerTitle],
    headerLeft: () => (
      <View style={styles.headerButtonsContainer}>
        <IfConfigValue path="features.account-settings-enabled" equalsTo={true}>
          <ImageButton
            image={Resources.images.settingsIcon()}
            onPress={() => navigation.navigate(ROUTES.ACCOUNT_SETTINGS)}
            tint={StyleSheet.flatten(styles.headerTitle).color}
          />
        </IfConfigValue>
        <IfConfigValue path="features.faq-enabled" equalsTo={true}>
          <ImageButton
            image={Resources.images.faqIcon()}
            onPress={() => navigation.navigate(ROUTES.FAQ_CATEGORIES)}
            tint={StyleSheet.flatten(styles.headerTitle).color}
          />
        </IfConfigValue>
      </View>
    ),
    headerRight: () => (
      <Can do="update" of="profile">
        {status => (
          <ImageButton
            image={Resources.images.editIcon()}
            onPress={() => navigation.getParam('edit')(status)}
            tint={StyleSheet.flatten(styles.headerTitle).color}
            {...testId('Edit profile button')}
          />
        )}
      </Can>
    ),
  });

  return Navigation;
}
