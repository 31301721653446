import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { View, Image, Text } from 'react-native';
import Modal from 'react-native-modal';
import Resources from 'dating-mobile/src/resources';
import { GradientButton } from 'dating-mobile/src/components/buttons/gradient';

import diamondsImg from './images/diamonds.png';

import styles from './styles';

export default class RegistrationBonusModal extends PureComponent {
  static displayName =
    'stepped-registration.views.registration-bonus-modal.view';

  static propTypes = {
    isVisible: PropTypes.bool,
    bonus: PropTypes.number,
    onClose: PropTypes.func,
  };

  render() {
    const { isVisible, onClose, bonus } = this.props;

    return (
      <Modal
        backdropOpacity={0.4}
        isVisible={isVisible}
        useNativeDriver
        onBackdropPress={onClose}
        style={styles.modal}
      >
        <View style={styles.container}>
          <Text style={styles.title}>
            {Resources.strings['registration-bonus-modal-title']}
          </Text>
          <Text style={styles.subtitle}>
            {Resources.strings['registration-bonus-modal-subtitle']}
          </Text>
          <Image
            style={styles.diamondsIcon}
            source={diamondsImg}
            resizeMode="cover"
          />
          <Text style={styles.bonusText}>
            {Resources.strings.formatString(
              Resources.strings['registration-bonus-modal-bonus-amount'],
              bonus || '',
            )}
          </Text>
          <GradientButton
            onPress={onClose}
            buttonStyles={styles.button}
            buttonTextStyles={styles.buttonText}
            text={
              Resources.strings['registration-bonus-modal-grab-button-title']
            }
          />
        </View>
      </Modal>
    );
  }
}
