// @ts-ignore
import EStyleSheet from 'react-native-extended-stylesheet';

export const styles = EStyleSheet.create({
    container: {
        width: 200,
        height: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    errorContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        paddingHorizontal: 10,
        alignItems: 'center',
        justifyContent: 'center',
    },

    error: {
        fontSize: 14,
        textAlign: 'center',
        color: '$errorColor',
        fontFamily: '$brandFontFamily',
    },

    counter: {
        fontSize: 15,
        color: '$brandTextColor',
        fontWeight: 'bold',
        fontFamily: '$brandFontFamily',
    },

    activityIndicatorContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },

    iconContainer: {
        width: 200,
        height: 200,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '$focusedColor',
        alignSelf: 'center',
    },

    icon: {
        width: 86,
        height: 86,
    },
});
