import View from './view';
import MinimizedView from './minimized';

import withController from './controller';
import withNavigation from './navigation';
import withCanCreateMessage from './can-create-message';

export const MyChatsScreen = withNavigation(
  withCanCreateMessage(withController(View)),
);
export const MyChats = withCanCreateMessage(withController(View));
export const MyChatsMinimized = withCanCreateMessage(
  withController(MinimizedView),
);
