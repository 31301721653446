import React, { Fragment, useMemo } from 'react';
import { ScrollView, Text, View } from 'react-native';
import Resources from 'dating-mobile/src/resources';

import { styles } from './styles';
// @ts-ignore
import { SubscriptionAgreement } from '../subscription-agreement';
// @ts-ignore
import { getLengthOfSubscription } from '../../utils/trial-pirce-formatting';

import { ViewProps } from './types';

export const MembershipSubscriptionAgreementView = (props: ViewProps) => {
  const {
    ios,
    hasPrice,
    trialPeriod,
    android,
    subscriptionPeriodNumber,
    subscriptionPeriodUnit,
    localizedIncludingTax,
    displayPrice,
    trialDisplay,
    additionalContentStyle = {},
    additionalStyle = {},
  } = props;

  const androidTerms = useMemo(() => {
    return (
      android &&
      trialPeriod && (
        <Text>
          {Resources.strings.formatString(
            Resources.strings['subscription-trial-description-format-android'],
            displayPrice,
            trialDisplay,
          )}
        </Text>
      )
    );
  }, [android, trialPeriod]);

  const iOSTerms = useMemo(() => {
    return (
      ios &&
      hasPrice && [
        <Fragment>
          {trialPeriod && (
            <Text>
              {Resources.strings.formatString(
                Resources.strings['subscription-trial-description-format'],
                displayPrice,
                trialDisplay,
              )}
            </Text>
          )}
        </Fragment>,
        <Text>
          <Text>{Resources.strings['subscription-free-trial-title']}</Text>
          <Text>
            {getLengthOfSubscription(
              subscriptionPeriodNumber,
              subscriptionPeriodUnit,
            )}
          </Text>
          <Text>
            {Resources.strings.formatString(
              Resources.strings['subscription-free-trial-price'],
              localizedIncludingTax,
            )}
          </Text>
        </Text>,
      ]
    );
  }, [ios, hasPrice, displayPrice, trialDisplay]);

  return (
    <View style={[styles.termsDescriptionContainer, additionalStyle]}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        alwaysBounceVertical={false}
        contentContainerStyle={additionalContentStyle}
      >
        <SubscriptionAgreement>
          {androidTerms}
          {iOSTerms}
        </SubscriptionAgreement>
      </ScrollView>
    </View>
  );
};

MembershipSubscriptionAgreementView.displayName =
  'payment.views.membership-subscription-agreement.view';
