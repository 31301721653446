import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { SafeAreaView } from 'react-navigation';
import { ProgressBar } from 'dating-mobile/src/components/progress-bar';

export default class OnboardingProgressBarView extends PureComponent {
    static displayName = 'authentication.views.onboarding-progress-bar.view';

    static propTypes = {
        currentProgress: PropTypes.number,
        visible: PropTypes.bool,
        style: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array]),
    };

    static defaultProps = {
        currentProgress: 0,
    };

    render() {
        const { visible, currentProgress, style } = this.props;

        if (!visible) {
            return null;
        }

        return (
            <SafeAreaView
                style={style}
                pointerEvents="none"
                forceInset={{ top: 'always', bottom: 'never' }}
            >
                <ProgressBar currentProgress={currentProgress * 100} />
            </SafeAreaView>
        );
    }
}
