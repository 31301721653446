import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Text, TouchableOpacity, View, Alert } from 'react-native';
import UserDefaults from 'react-native-default-preference';
import url from 'url';
import { withNavigation } from 'react-navigation';

import Identity from '@sdv/domain/identity/model';
import ConfigModel from 'dating-mobile/src/models/config/model';
import testId from 'dating-mobile/src/utils/test-id';
import { AUTH_LOADING } from 'dating-mobile/src/routing/router/constants';
import ConfigBuilder from 'dating-mobile/src/app/config-builder';
import ProductConfigModel from '../../models/config/model/product';
import TextInput from '../text-input';
import styles from './styles';
import Keychain from '../../utils/keychain';
import {
  HOST_URI_STORAGE_KEY,
  STREAMING_ENDPOINT_STORAGE_KEY,
  WS_ENDPOINT_STORAGE_KEY,
} from './constants';

class EndpointSwitcher extends Component {
  static displayName = 'endpoint.switcher';

  static propTypes = {
    hide: PropTypes.func,
    navigation: PropTypes.object,
  };

  static contextTypes = {
    flux: PropTypes.object,
  };

  state = {};

  constructor(props, context) {
    super(props);
    this.flux = context.flux;
    this.identityModel = context.flux.get(Identity);
    this.productConfigModel = context.flux.get(ProductConfigModel);
    this.configModel = context.flux.get(ConfigModel);
  }

  componentDidMount() {
    const { host, endpoints = {} } = this.configModel.store.getState();

    this.update({
      api: host,
      streaming: endpoints.streaming,
      ws: endpoints.ws,
    });
  }

  update = (state, callback) => {
    this.setState(state, () => {
      if (callback) {
        callback();
      }
    });
  };

  save = async () => {
    try {
      const { api, ws, streaming } = this.state;
      const { hide, navigation } = this.props;
      const { host } = url.parse(api);

      this.flux.api.setBaseHost(`//${host}`);
      await UserDefaults.set(HOST_URI_STORAGE_KEY, api);
      this.productConfigModel.actions.setHost(api);

      await UserDefaults.set(STREAMING_ENDPOINT_STORAGE_KEY, streaming);
      this.productConfigModel.actions.setEndpoint('streaming', streaming);

      await UserDefaults.set(WS_ENDPOINT_STORAGE_KEY, ws);
      this.productConfigModel.actions.setEndpoint('ws', ws);

      if (this.flux.events) {
        this.flux.events.reconnect(ws);
      }

      await Keychain.clearToken();
      this.identityModel.actions.signOut();

      if (hide) {
        hide();
      }

      navigation.navigate(AUTH_LOADING);
    } catch {
      Alert.alert('Something went wrong', '');
    }
  };

  reset = () => {
    this.update(
      {
        api: ConfigBuilder.config.host,
        streaming: (ConfigBuilder.config.endpoints || {}).streaming,
        ws: (ConfigBuilder.config.endpoints || {}).ws,
      },
      this.save,
    );
  };

  handleApiEndpointChange = event => {
    const uri = event.nativeEvent.text;

    this.setState({
      api: uri,
    });
  };

  handleStreamingEndpointChange = event => {
    const uri = event.nativeEvent.text;

    this.setState({
      streaming: uri,
    });
  };

  handleWsEndpointChange = event => {
    const uri = event.nativeEvent.text;

    this.setState({
      ws: uri,
    });
  };

  render() {
    const { api, ws, streaming } = this.state;

    return (
      <View style={styles.container}>
        <Text style={styles.title}>Enter Endpoints</Text>
        <TextInput
          style={styles.input}
          placeholder="Api Endpoint"
          autoCapitalize="none"
          value={api}
          onChange={this.handleApiEndpointChange}
          {...testId('Endpoint text')}
        />
        <TextInput
          style={styles.input}
          placeholder="WS Endpoint"
          autoCapitalize="none"
          value={ws}
          onChange={this.handleWsEndpointChange}
        />
        {styles.$streamingInputVisible && (
          <TextInput
            style={styles.input}
            placeholder="Streaming Endpoint"
            autoCapitalize="none"
            value={streaming}
            onChange={this.handleStreamingEndpointChange}
          />
        )}
        <View style={styles.control}>
          <TouchableOpacity onPress={this.save} {...testId('Save button')}>
            <Text style={styles.save}>Save</Text>
          </TouchableOpacity>

          <TouchableOpacity onPress={this.reset}>
            <Text style={styles.reset}>Reset</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}

export default withNavigation(EndpointSwitcher);
