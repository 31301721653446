import React from 'react';
import PropTypes from 'prop-types';
import { Image, Text, TouchableWithoutFeedback, View } from 'react-native';
import Resources from '../../../resources';
import styles from './styles';

class CheerContent extends React.Component {
    static displayName = 'notifications.popup.cheer';
    static propTypes = {
        user: PropTypes.object,
        notification: PropTypes.object,
        openChatAndDismiss: PropTypes.func.isRequired,
        onReadyForDisplay: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.props.onReadyForDisplay();
    }

    openChat = () => {
        if (!this.props.id) {
            return;
        }

        this.props.openChatAndDismiss(this.props.id);
    };

    render() {
        return (
            <TouchableWithoutFeedback onPress={this.openChat}>
                <View style={styles.container}>
                    <View style={styles.giftIconContainer}>
                        <Image style={styles.giftIcon} source={Resources.images.sendGiftIcon()} />
                    </View>
                    <View style={styles.textContainer}>
                        <Text style={styles.text}>{Resources.strings['cheer-notification-text']}</Text>
                    </View>
                </View>
            </TouchableWithoutFeedback>
        );

    }
}

class Cheer extends React.Component {
    render() {
        const userId = (this.props.notification && this.props.notification.recipient) || undefined;
        return (<CheerContent id={userId} {...this.props} />);
    }
}

export default Cheer;
