import React from 'react';
import PropTypes from 'prop-types';
import connect from '@sdv/connect';
import UserGoalsModel from '@sdv/domain/user.goals';

const GET_PROPERTY = 'generated.user.goals.controller.get-action';

function createUserGoalsHocController(Component, options={}) {
    const userIdPropName = options.userIdPropName || 'userId';
    const goalsPropName = options.goalsPropName || 'goals';
    const setActionPropName = options.setActionPropName || 'setGoals';

    class Controller extends React.Component {
        static displayName = 'user.goals.controller';
        static contextTypes = {
            flux: PropTypes.object,
        };

        componentDidMount() {
            this.props[GET_PROPERTY] && this.props[GET_PROPERTY]();
        }

        componentDidUpdate(prevProps) {
            if (shouldReconnect(prevProps, this.props)) {
                this.props[GET_PROPERTY] && this.props[GET_PROPERTY]();
            }
        }

        render() {
            const { GET_PROPERTY, ...props } = this.props;
            return <Component {...props}/>
        }
    }

    function getModels(flux, props) {
        const models = {};

        if (props[userIdPropName]) {
            models.goalsModel = flux.get(UserGoalsModel, props[userIdPropName]);
        }

        return models;
    }

    function mapStoresToProps(models) {
        const props = {};

        if (models.goalsModel) {
            props[goalsPropName] = models.goalsModel.store.getState().goals || [];
        }
        return props;
    }

    function mapStoresToActions(models) {
        const props = {};
        if (models.goalsModel) {
            props[GET_PROPERTY] = models.goalsModel.actions.get;
            props[setActionPropName] = models.goalsModel.actions.put;
        }
        return props;
    }

    function shouldReconnect(props, newProps) {
        return props[userIdPropName] !== newProps[userIdPropName];
    }

    return connect(
        getModels,
        mapStoresToProps,
        mapStoresToActions,
        shouldReconnect
    )(Controller);
}

export default createUserGoalsHocController;
