/* eslint-disable no-template-curly-in-string */
import { singleton } from '@sdv/commons/utils/singleton';
import { filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import Navigator from 'dating-mobile/src/routing/navigator';
import { PAY_PAL } from 'dating-mobile/src/routing/router/constants';
import url from 'url';

const PURCHASE_URL = {
  url:
    'https://www.paypal.com/cgi-bin/webscr?cmd=_express-checkout&token=${token}',
  sandbox_url:
    'https://www.sandbox.paypal.com/cgi-bin/webscr?cmd=_express-checkout&token=${token}',
  tokenPlaceholder: '${token}',
};

/**
 *
 * @return {{cancel: string, success: string}}
 */
export function getPayPalRedirectUrls() {
  return {
    success: 'http://successurl',
    cancel: 'http://cancelurl',
  };
}

function createPaymentUrl(token) {
  return PURCHASE_URL.url.replace(PURCHASE_URL.tokenPlaceholder, token);
}

export class PayPalGateway {
  static shared = singleton(() => new PayPalGateway());

  constructor() {
    this.resultSubject = new Subject();
  }

  /**
   *
   * @private
   * @param token
   * @return {Observable<{success: boolean, token: string}>}
   */
  listenPurchase(token) {
    return this.resultSubject.pipe(filter(result => token === result.token));
  }

  /**
   *
   * @param token
   * @return {Observable<{success: boolean, token: string}>}
   */
  requestPurchase(token) {
    const onRedirect = newUrl => {
      const resultFromUrl = PayPalGateway.getPaymentResultFromUrl(
        newUrl,
        token,
      );

      if (resultFromUrl) {
        this.resultSubject.next(resultFromUrl);
      }

      return !!resultFromUrl;
    };

    const onCancel = () => {
      this.resultSubject.next({ success: false, token });
    };

    Navigator.navigate(PAY_PAL, {
      url: createPaymentUrl(token),
      onRedirect,
      onCancel,
    });

    return this.listenPurchase(token);
  }

  /**
   *
   * @param redirectUrl
   * @return {{success: boolean, token: string}}
   */
  static getPaymentResultFromUrl(redirectUrl, expectedToken) {
    if (!redirectUrl) return undefined;

    const redirectUrls = getPayPalRedirectUrls();
    const parsedUrl = url.parse(redirectUrl, true);
    const { token } = parsedUrl.query;

    if (redirectUrl.startsWith(redirectUrls.success)) {
      if (token) {
        return {
          success: true,
          token,
        };
      }

      return {
        success: false,
        token: expectedToken,
      };
    }

    if (redirectUrl.startsWith(redirectUrls.cancel)) {
      return {
        success: false,
        token,
      };
    }

    return undefined;
  }
}
