import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({

    tint: {
        color: '$brandTextColor'
    },

    container: {
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '$brandColor'
    },

    picker: {
        height: 220,
        width: '100%'
    }

});

export default styles;
