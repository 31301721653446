import React from 'react';
import PropTypes from 'prop-types';
import {View, Text, PanResponder, Animated} from 'react-native';
import styles from './styles';
import Resources from 'dating-mobile/src/resources';
import Button from 'dating-mobile/src/components/buttons/base';
import GestureRecognizer from 'react-native-swipe-gestures';

class FeedSignupBanner extends React.PureComponent {
    static displayName = 'broadcasts.discovery.views.feed-signup-banner.view';
    static propTypes = {
        onClosePress: PropTypes.func,
        onSignupPress: PropTypes.func,
        onSwipeStart: PropTypes.func,
        onSwipeEnd: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = { height: new Animated.Value(styles.$height) }
    }

    componentWillMount() {
        this.panResponder = PanResponder.create({
            onStartShouldSetPanResponder: this.onSwipeStart,
            onMoveShouldSetPanResponder: this.onSwipeStart,
            onPanResponderRelease: this.onSwipeEnd,
            onPanResponderTerminate: this.onSwipeEnd,
        });
    }

    onSwipeStart = (e, gestureState) => {
        const isSwipe = Math.abs(gestureState.dx) >= 2 || Math.abs(gestureState.dy) >= 2;
        if (!this.isSwiping && isSwipe) {
            this.isSwiping = true;

            if (this.props.onSwipeStart) {
                this.props.onSwipeStart();
            }
        }

        return isSwipe;
    };

    onSwipeEnd = () => {
        if (this.isSwiping) {
            this.isSwiping = false;

            if (this.props.onSwipeEnd) {
                this.props.onSwipeEnd();
            }
        }
    };

    closeWithAnimation = (completion) => {
        const duration = 300;
        Animated.timing(this.state.height, {
            toValue: 0,
            duration: duration
        }).start();

        setTimeout(() => {
            completion();
        }, duration)
    };

    render() {
        return (
            <GestureRecognizer
                onSwipeUp={this.props.onClosePress}
                onSwipe={this.onSwipeEnd}
            >
                <Animated.View
                    {...this.panResponder.panHandlers}
                    style={[styles.content, { height: this.state.height }]}
                >
                    <View style={styles.container}>
                        <Text style={styles.description}>{Resources.strings['streams-list-signup-banner-description']}</Text>
                        <Button
                            title={Resources.strings['streams-list-signup-banner-button-title']}
                            titleStyle={styles.buttonTitle}
                            style={styles.buttonContainer}
                            onPress={this.props.onSignupPress}
                        />
                    </View>
                    <View style={styles.bottomLine}/>
                </Animated.View>
            </GestureRecognizer>
        );
    }
}

export default FeedSignupBanner;
