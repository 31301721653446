import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'react-native';
import styles from './styles';
import configValue from '../../../components/config-value';
import Resources from '../../../resources';
import { escapeRegExpSpecialCharacters } from '../../../utils/regexp';

const TERMS_LINK_MARK = '{link:terms}';

class Description extends React.Component {
    static displayName = 'payment.description';
    static propTypes = {
        pricesLink: PropTypes.string,
        openLink: PropTypes.func,
        currency: PropTypes.string,
        devaluationRatio: PropTypes.number,
        informationType: PropTypes.string
    };

    constructor(props) {
        super(props);

        this.linkMarkPattern = `${escapeRegExpSpecialCharacters(TERMS_LINK_MARK)}`;
    }

    readMoreLinkPressed = () => {
        this.props.openLink(Resources.strings.terms, this.props.pricesLink);
    };

    renderInformation = () => {
        if (!this.props.informationType || !this.props.devaluationRatio || !this.props.currency) {
            return null;
        }

        switch (this.props.informationType) {
            case 'motivation':
                return {
                    coins: Resources.strings['credits-screen-motivation-for-purchasing-coins-information-text'],
                    credits: Resources.strings['credits-screen-motivation-for-purchasing-credits-information-text']
                }[this.props.currency] || null;

            case 'prices':
                const oneCreditPriceFormat = {
                    coins: Resources.strings['credits-screen-prices-information-one-coin-price-format'],
                    credits: Resources.strings['credits-screen-prices-information-one-credit-price-format']
                }[this.props.currency] || '{0}';

                const creditsPriceFormat = {
                    coins: Resources.strings['credits-screen-prices-information-coins-price-format'],
                    credits: Resources.strings['credits-screen-prices-information-credits-price-format']
                }[this.props.currency] || '{0}';


                const textMessagePrice = 1 * this.props.devaluationRatio;
                const textMessagePriceText = Resources.strings.formatString(
                    textMessagePrice === 1 ? oneCreditPriceFormat : creditsPriceFormat,
                    textMessagePrice
                );

                const emailPrice = 10 * this.props.devaluationRatio;
                const emailPriceText = Resources.strings.formatString(
                    emailPrice === 1 ? oneCreditPriceFormat : creditsPriceFormat,
                    emailPrice
                );

                let text = Resources.strings.formatString(
                    Resources.strings['credits-screen-prices-information-text-format'],
                    textMessagePriceText,
                    emailPriceText,
                    TERMS_LINK_MARK
                );

                const linkTitles = {
                    [TERMS_LINK_MARK]: Resources.strings['credits-screen-prices-information-read-more-link-test']
                };

                const linkPressHandlers = {
                    [TERMS_LINK_MARK]: this.readMoreLinkPressed
                };

                const result = [];
                const regExp = new RegExp(this.linkMarkPattern);
                let match;
                while ((match = text.match(regExp))) {
                    const pattern = match[0];

                    const part = text.substring(0, match.index);

                    result.push((<Text key={`links-${part}-${result.length}`}>{part}</Text>));

                    result.push(
                        <Text
                            key={`links-${pattern}`}
                            style={styles.link}
                            onPress={linkPressHandlers[pattern]}
                        >
                            {linkTitles[pattern]}
                        </Text>
                    );

                    text = text.substring(match.index + pattern.length);
                }

                result.push((<Text key={`links-${text}-${result.length}`}>{text}</Text>));

                return result;

            default:
                return null;
        }
    };

    render() {
        return (
            <Text style={styles.info}>{this.renderInformation()}</Text>
        );
    }

}

export default configValue(
    Description,
    {
        pricesLink: 'links.prices',
        currency: 'currency.type',
        devaluationRatio: 'currency.devaluation-ratio',
        informationType: 'markup.credits-screen.description'
    }
);
