import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        width: 99,
        borderRadius: 14,
        flexShrink: 0,
        flexGrow: 0,
    },

    containerActive: {
        padding: 2,
        gradientColor: ['#ff9200', '#ff0050'],
    },

    headerContainer: {
        padding: 5,
    },

    headerBorder: {
        position: 'absolute',
        bottom: -3,
        left: 0,
        right: 0,
        flexShrink: 0,
        flexDirection: 'row',
        justifyContent: 'space-evenly',
    },

    headerText: {
        fontSize: 9,
        fontWeight: '900',
        textAlign: 'center',
        fontFamily: '$brandFontFamily',
        color: '#ffffff',
    },

    contentContainer: {
        borderRadius: 14,
        borderWidth: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },

    contentContainerActive: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderWidth: 0,
    },

    mainContentContainer: {
        height: 66,
        justifyContent: 'center',
    },
    mainContentContainerActive: {
        backgroundColor: '#ffffff',
    },

    secondaryContentContainer: {
        height: 40,
        justifyContent: 'center',
    },
    secondaryContentContainerActive: {
        backgroundColor: 'rgba(255, 124, 54, 0.1)',
    },

    secondaryContentTextContainer: {
        fontFamily: '$brandFontFamily',
        textAlign: 'center',
    },

    firstMainText: {
        fontFamily: '$brandFontFamily',
        textAlign: 'center',
        fontSize: 37,
        fontWeight: '900',
        color: 'black',
        alignSelf: 'center',
        flexGrow: 0,
        flexShrink: 1,
        marginBottom: -10,
    },

    firstMainText$free: {
        marginBottom: -5,
        fontSize: 11,
        fontWeight: 'bold',
    },

    secondMainText$free: {
        fontSize: 28,
        marginBottom: -10,
    },

    secondMainText: {
        fontFamily: '$brandFontFamily',
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 10,
        color: 'black',
    },

    secondaryText: {
        fontSize: 13,
        fontWeight: 'bold',
        color: 'white',
    },

    textNotActive: {
        color: '#ffffff',
    },

    separator: {
        height: 1,
        alignSelf: 'stretch',
        backgroundColor: '#ff952a',
    },

    secondaryTextSubtitle: {
        fontSize: 12,
        color: 'rgba(255, 255, 255, 0.8)',
    },

    separatorNotActive: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },

    superscript: {
        fontSize: 12,
        color: 'rgba(255, 255, 255, 0.8)',
        lineHeight: 15,
        textAlignVertical: 'top'

    }
});

export default styles;
