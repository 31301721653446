import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

class WithGradient extends PureComponent {
  static displayName = 'components.with-gradient';

  static propTypes = {
    children: PropTypes.node,
    styles: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    startX: PropTypes.number,
    startY: PropTypes.number,
    endX: PropTypes.number,
    endY: PropTypes.number,
  };

  static defaultProps = {
    startX: 0,
    startY: 0,
    endX: 1,
    endY: 0,
  };

  renderGradient = gradientColor => {
    const { children, styles, startX, startY, endX, endY } = this.props;

    return (
      <LinearGradient
        start={{ x: startX, y: startY }}
        end={{ x: endX, y: endY }}
        colors={gradientColor}
        style={styles}
      >
        {children}
      </LinearGradient>
    );
  };

  renderView = () => {
    const { children, styles } = this.props;

    return <View style={styles}>{children}</View>;
  };

  renderBlock = () => {
    const { styles } = this.props;
    const { gradientColor } = StyleSheet.flatten(styles);

    return gradientColor && gradientColor.length > 1
      ? this.renderGradient(gradientColor)
      : this.renderView();
  };

  render() {
    return this.renderBlock();
  }
}

export default WithGradient;
