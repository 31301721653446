import StackTraceParser from 'stacktrace-parser';
import { Platform } from 'react-native';
import Service from '../service';
import ExceptionsLogger from '../../native-modules/exceptions-logger';

let serviceInitialized = false;

export default class ExceptionsLoggerService extends Service {
  static displayName = 'service.tracking.app-launch';

  constructor(props) {
    super(props);

    if (!serviceInitialized) {
      serviceInitialized = true;
      this.setup();
    }
  }

  setup() {
    if (__DEV__ || Platform.OS === 'web') {
      return;
    }

    function parseErrorStack(error) {
      if (!error || !error.stack) {
        return [];
      }

      return Array.isArray(error.stack)
        ? error.stack
        : StackTraceParser.parse(error.stack);
    }

    function errorHandler(error, isFatal) {
      const stack = parseErrorStack(error);

      ExceptionsLogger.logException(error.message, stack, isFatal);
    }

    global.ErrorUtils.setGlobalHandler(errorHandler);
  }
}
