import { Platform } from 'react-native';

export const shopName = Platform.select({
  ios: 'ios',
  android: 'android',
  web: 'web',
});

const commonMethods = {
  cards: 'cards',
  paypal: 'paypal',
  credits: 'credits',
};

export const SupportedMethods = Platform.select({
  default: {
    ...commonMethods,
    google: 'google',
    apple: 'apple',
  },
  web: commonMethods,
});
