import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import { Text, TouchableOpacity, View } from 'react-native';
import { FILTERS, KIDS_VALUES, PREFERRED_GENDER_VALUES } from '../../../utils/filters';
import Resources from '../../../../resources';

class Option extends React.PureComponent {
    static displayName = 'feed.filter.screen.options.option';
    static propTypes = {
        filter: PropTypes.string,
        dataValue: PropTypes.string,
        value: PropTypes.any,
        onPress: PropTypes.func,
        onDeletePress: PropTypes.func
    };

    getDisplayValue = () => {
        let displayValue;
        if (this.props.value !== undefined && this.props.value !== null) {
            switch (this.props.filter) {
                case FILTERS.HEIGHT:
                    if (this.props.value.min && this.props.value.max) {
                        displayValue = Resources.strings.formatString(
                            Resources.strings['filter-screen-height-item-range-value-format'],
                            this.props.value.min,
                            this.props.value.max
                        )
                    } else if (this.props.value.min) {
                        displayValue = Resources.strings.formatString(
                            Resources.strings['filter-screen-height-item-more-than-value-format'],
                            this.props.value.min
                        )
                    } else if (this.props.value.max) {
                        displayValue = Resources.strings.formatString(
                            Resources.strings['filter-screen-height-item-less-than-value-format'],
                            this.props.value.max
                        )
                    }
                    break;
                case FILTERS.AGE:
                    if (this.props.value.min && this.props.value.max) {
                        displayValue = `${this.props.value.min} - ${this.props.value.max}`;
                    }
                    break;
                case FILTERS.LOCATION:
                case FILTERS.TRAVEL_DESTINATION:
                    if (this.props.dataValue) {
                        const prefix = this.props.value.city
                            ? `${this.props.value.city}, `
                            : '';
                        displayValue = `${prefix}${this.props.dataValue}`;
                    }
                    break;
                case FILTERS.GENDER:
                    displayValue = PREFERRED_GENDER_VALUES[this.props.value];
                    break;
                case FILTERS.KIDS:
                    displayValue = KIDS_VALUES[String(this.props.value)];
                    break;
                default:
                    displayValue = this.props.dataValue;
                    break;
            }
        }
        return displayValue || '';
    };

    onPressed = () => {
        this.props.onPress && this.props.onPress(this.props.filter, this.getDisplayValue());
    };

    onDeletePressed = () => {
        this.props.onDeletePress && this.props.onDeletePress(this.props.filter, this.props.value);
    };

    render() {
        return (
            <View style={styles.container}>
                <TouchableOpacity onPress={this.onPressed}>
                    <View style={styles.content}>
                        <Text style={styles.title}>{this.getDisplayValue()}</Text>
                        <TouchableOpacity style={styles.deleteButton} onPress={this.onDeletePressed}>
                            <Text style={styles.deleteButtonTitle}>×</Text>
                        </TouchableOpacity>
                    </View>
                </TouchableOpacity>
            </View>
        )
    }
}

export default Option;
