import Actions from './actions';
import { createOptimisticStore } from '@sdv/model';

function createTagsStore(id) {

    class Store extends createOptimisticStore(['patch', 'delete']) {

        constructor(flux) {
            const actions = flux.getActions(Actions, id);
            super(actions);
            this.bindAction(actions.get, this.replace);
        }

        replace(payload) {

            if (!payload) {
                return;
            }

            this.state.data = (payload || []).reduce((tags, tag) => {
                tags[tag] = true;
                return tags;
            }, {});

            if (!this.state.data.favorite) {
                this.state.data.favorite = false
            }

            this.setState(this.state);

        }

        static delete(oldState, tag) {
            return { ...oldState, [tag]: false };

        }

        static patch(oldState, tag) {
            return { ...oldState, [tag]: true };
        }

    }

    Store.displayName = createTagsStore.getDisplayName(id);

    return Store;
}

createTagsStore.getDisplayName = function (id) {
    return `user-targeted-tags.${id}`;
};

export default createTagsStore;
