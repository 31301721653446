import React from 'react';
import { Subscription } from 'rxjs';
import StreamDescriptionEditingViewModel from '@sdv/domain/StreamDescriptionEditingViewModel';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';
import Config from 'dating-mobile/src/models/config/model';
import flux from '@sdv/domain/app/flux';

export default function createStreamTopicsSelectionController(Component) {
    class StreamTopicsSelectionController extends React.Component {
        constructor(props) {
            super(props);
            this.viewModel = StreamDescriptionEditingViewModel.shared(props.userId);

            this.state = {
                topics: flux.get(Config).store.getState()['available-stream-topics'],
                selectedTopics: [],
                canChoose: true,
            };
        }

        componentDidMount() {
            this.disposeBag = new Subscription();

            const topicsSubscription = this.viewModel.topics.subscribe(selectedTopics =>
                this.setState({ selectedTopics }),
            );
            this.disposeBag.add(topicsSubscription);

            const canChooseTopicsSubscription = this.viewModel.canSelectTopics.subscribe(
                canChoose => this.setState({ canChoose }),
            );
            this.disposeBag.add(canChooseTopicsSubscription);
        }

        componentWillUnmount() {
            this.disposeBag && this.disposeBag.unsubscribe();
        }

        handleChangeTopic = topic => () => {
            const { selectedTopics, canChoose } = this.state;

            if (canChoose || selectedTopics.includes(topic)) {
                this.viewModel.topicPressed.next(topic);
            }
        };

        handleSaveChanges = () => {
            this.viewModel.saveTopicsButtonPressed.next();
        };

        render() {
            return (
                <Component
                    onChangeTopic={this.handleChangeTopic}
                    onSaveChanges={this.handleSaveChanges}
                    id={this.props.userId}
                    {...this.state}
                />
            );
        }
    }

    return withIdentityId(StreamTopicsSelectionController);
}
