import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import withAuthController from 'dating-mobile/src/models/identity/controller/auth';
import { SigningUp } from '@sdv/domain/application/signing-up';
import Resources from 'dating-mobile/src/resources';
import { EmailValidationError } from '@sdv/domain/authorization/email-validation';

export default function createController(Component) {
    const WrappedComponent = withAuthController(Component);

    class Controller extends PureComponent {
        static displayName = 'stepped-registration.screens.email.controller';

        static contextTypes = {
            flux: PropTypes.object,
        };

        state = {
            error: null,
        };

        submitButtonPressed = false;

        handleError = error => {
            this.submitButtonPressed = false;

            if (error.status === 400 && error.code === 0) {
                this.setState({
                    error: Resources.strings['change-email-already-take-error-message'],
                });
            } else if (error.message === EmailValidationError) {
                this.setState({
                    error: Resources.strings['sign-up-screen-email-validation-error'],
                });
            } else {
                const errorMessage =
                    typeof error === 'string'
                        ? error
                        : error.message || error.desc || Resources.strings['error-message-email'];

                this.setState({
                    error: errorMessage,
                });
            }
        };

        onSubmit = (componentName, credentials) => {
            if (!this.submitButtonPressed && credentials && credentials.email) {
                this.submitButtonPressed = true;
                new SigningUp()
                    .signUp(credentials.email)
                    .subscribe(() => this.setState({ error: null }), this.handleError);
            }
        };

        render() {
            const { error } = this.state;

            return <WrappedComponent {...this.props} error={error} onSubmit={this.onSubmit} />;
        }
    }

    return Controller;
}
