import { Platform } from 'react-native';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { analytics as createFirebaseTracker } from 'react-native-firebase';
import flux from '@sdv/domain/app/flux';
import Session from '@sdv/domain/authorization/session';
import { CurrentScreen } from 'dating-mobile/src/services/functional/current-screen';
import { SPECIAL_OFFER } from 'dating-mobile/src/routing/router/constants';

import Service from '../service';

export class CurrentScreenTracker extends Service {
  static displayName = 'service.tracking.current-screen-tracker';

  componentDidMount() {
    this.subscribe();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  subscribe() {
    const currentScreenSubscription = CurrentScreen.shared().screen.subscribe(
      screen => {
        if (Platform.OS === 'web') {
          createFirebaseTracker().logEvent('screen_view', {
            screen_name: screen,
            screen_class: screen,
          });
        } else {
          createFirebaseTracker().setCurrentScreen(screen, screen);
        }

        if (screen === SPECIAL_OFFER) {
          this.tackSpecialOfferModalShowed();
        }
      },
    );

    this.disposeBag = new Subscription();
    this.disposeBag.add(currentScreenSubscription);
  }

  unsubscribe() {
    if (this.disposeBag) {
      this.disposeBag.unsubscribe();
    }
  }

  tackSpecialOfferModalShowed() {
    Session.shared()
      .userId.pipe(take(1))
      .subscribe(userId => {
        if (userId) {
          flux.api.annals.add(userId, 'special-offer-modal-showed', {});
        }
      });
  }
}
