import React from 'react';
import { View, Text } from 'react-native';
import { NavigationActions } from 'react-navigation';
import PropTypes from 'prop-types';
import Button from 'dating-mobile/src/components/buttons/base';
import Navigator from 'dating-mobile/src/routing/navigator';
import * as ROUTES from 'dating-mobile/src/routing/router/constants';
import Resources from 'dating-mobile/src/resources';

import ChatsView from './view';

import styles from './styles';

MinimizedChatScreen.propTypes = {
  contacts: PropTypes.array,
};

export default function MinimizedChatScreen({ contacts, ...props }) {
  return contacts.length ? (
    <View style={styles.minimizedContainer}>
      <Text style={styles.title}>
        {Resources.strings['chat-screen-minimized-title']}
      </Text>
      <ChatsView
        {...props}
        contacts={contacts.slice(0, 3)}
        minimized
        navigation={Navigator}
      />
      <View style={styles.buttonWrapper}>
        <Button
          style={styles.button}
          titleStyle={styles.buttonText}
          title={Resources.strings['more-nav-bar-item-title']}
          onPress={() =>
            Navigator.navigate(
              ROUTES.CONTACTS_TAB,
              null,
              NavigationActions.navigate({ routeName: ROUTES.CONTACTS }),
            )
          }
        />
      </View>
    </View>
  ) : null;
}
