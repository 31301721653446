function createActions(id) {
    class Actions {
        answer = () => (dispatch) => {
            dispatch();
        };

        reject = () => (dispatch) => {
            dispatch();
        };
    }

    Actions.displayName = createActions.getDisplayName(id);
    return Actions;
}

createActions.getDisplayName = function(id) {
    return `call-answering.${id}`;
};

export default createActions;
