import React from 'react';
import { ChatAnonymity } from '@sdv/domain/chat/chat-anonymity';
import { AutoMessageChecker } from '@sdv/domain/chat/auto-message-checker';
import { Config } from '@sdv/domain/app/config';
import { UserTags as Tags } from '@sdv/domain/user/tags/user-tags';
import { render } from 'dating-mobile/src/components/rx-render';
import { Platform } from 'react-native';

export default function createController(Component) {
  const Controller = render((props, { useDistinct, useObservable }) => {
    const chatAnonymity = useDistinct(
      (identity, attendeeId) => new ChatAnonymity(identity, attendeeId),
      props.identity,
      props.attendeeId,
    );
    const autoMessageChecker = useDistinct(
      (identity, attendeeId) => new AutoMessageChecker(identity, attendeeId),
      props.identity,
      props.attendeeId,
    );

    const writingAsAnonymous = useObservable(
      it => it.writingAsAnonymous,
      chatAnonymity,
    );
    const anonymousChatEnabled = useObservable(
      it => it.anonymousChatEnabled,
      Config.shared(),
    );
    const hasOnlyAutoMessage = useObservable(
      it => it.hasOnlyAutoMessage,
      autoMessageChecker,
    );
    const audioCallReady = props.attendee?.tags?.includes(Tags.AudioCallReady);
    const showAudioCallButton =
      !writingAsAnonymous &&
      props.audioCallsEnabled &&
      (!props.audioCallsRequireTag || audioCallReady) &&
      Platform.OS !== 'web';

    return (
      <Component
        {...props}
        showAudioCallButton={showAudioCallButton}
        showProfilePreview={hasOnlyAutoMessage}
        openProfileEnabled={
          anonymousChatEnabled
            ? props.openProfileEnabled && writingAsAnonymous
            : props.openProfileEnabled
        }
      />
    );
  });

  Controller.displayName = 'dialogs.messages.screen.controller';

  return Controller;
}
