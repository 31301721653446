import React from 'react';
import PropTypes from 'prop-types';
import { WRITE_LETTER } from 'dating-mobile/src/routing/router/constants';

export default function bindNavigation(Component) {
  class Navigation extends React.Component {
    static displayName = 'inbox.views.write-letter-button.navigation';

    static propTypes = {
      navigation: PropTypes.object.isRequired,
      userId: PropTypes.string,
    };

    onWriteLetterButtonPressed = () => {
      const { navigation, userId } = this.props;

      if (navigation)
        navigation.navigate(WRITE_LETTER, {
          recipientId: userId,
        });
    };

    render() {
      return (
        <Component {...this.props} onPress={this.onWriteLetterButtonPressed} />
      );
    }
  }

  return Navigation;
}
