import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  container: {
    width: '100%',
    height: 48,
    '@flavor ytm': {
      height: 32,
    },
    '@flavor dma': {
      height: undefined,
      marginBottom: 8,
    },
    '@flavor secretly': {
      width: undefined,
    },
    flexDirection: 'row',
    alignItems: 'center',
  },

  iconContainer: {
    width: 36,
    height: 36,
    borderRadius: 18,
    backgroundColor: '$accentColor',
    justifyContent: 'center',
    alignItems: 'center',
    '@flavor ytm': {
      width: 3,
      height: 3,
      backgroundColor: '$brandTextColor',
    },
    '@flavor dma': {
      width: 0,
      height: 0,
    },
    '@flavor secretly': {
      width: undefined,
      height: undefined,
      backgroundColor: 'transparent',
    },
  },

  icon: {
    width: 20,
    height: 20,
    tintColor: '$accentTextColor',
    '@flavor secretly': {
      width: 18,
      height: 18,
      tintColor: '$accentColor',
    },
  },

  titleContainer: {
    flex: 1,
    width: '100%',
    marginLeft: 16,
    flexDirection: 'row',
    alignItems: 'center',
    '@flavor ytm': {
      marginLeft: 24,
    },
    '@flavor dma': {
      marginLeft: 0,
    },
    '@flavor secretly': {
      flex: undefined,
      marginLeft: 18,
    },
  },

  title: {
    fontSize: 17,
    fontFamily: '$brandFontFamily',
    color: '$brandTextColor',
    flexShrink: 1,
    '@flavor ytm': {
      fontSize: 18,
    },
    '@flavor dma': {
      width: '100%',
      fontSize: 20,
      textAlign: 'center',
    },
    '@flavor secretly': {
      fontSize: 16,
      color: '$primaryTextColor',
    },
  },
});

export default styles;
