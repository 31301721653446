import React from 'react';
import { StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import Resources from 'dating-mobile/src/resources';
import TextButton from 'dating-mobile/src/components/navigation/views/text-button';

import styles from './styles';

export default function bindNavigation(Component) {
  class Navigation extends React.Component {
    static displayName = 'user.screens.change-email.navigation';

    static propTypes = {
      navigation: PropTypes.object.isRequired,
    };

    render() {
      const { navigation } = this.props;

      return (
        <Component
          {...this.props}
          userId={navigation.getParam('userId')}
          setHeaderState={navigation.setParams}
          close={navigation.pop}
        />
      );
    }
  }

  Navigation.navigationOptions = ({ navigation, navigationOptions }) => ({
    headerTitle: Resources.strings.email,
    headerStyle: [navigationOptions.headerStyle, styles.header],
    headerTitleStyle: [navigationOptions.headerTitleStyle, styles.headerTitle],
    headerLeft: () => (
      <TextButton
        title={Resources.strings.cancel}
        onPress={navigation.getParam('onCancelButtonPress')}
        tint={
          StyleSheet.flatten([
            navigationOptions.headerTitleStyle,
            styles.headerTitle,
          ]).color
        }
      />
    ),
    headerRight: () => (
      <TextButton
        title={Resources.strings.save}
        onPress={navigation.getParam('onSaveButtonPress')}
        tint={
          StyleSheet.flatten([
            navigationOptions.headerTitleStyle,
            styles.headerTitle,
          ]).color
        }
        disabled={!navigation.getParam('saveButtonEnabled')}
        disabledTint={styles.$disabledTint}
      />
    ),
  });

  return Navigation;
}
