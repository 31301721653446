import React from 'react';
import PropTypes from 'prop-types';
import { View, StatusBar, AppState } from 'react-native';
import { SafeAreaView } from 'react-navigation';
import SoundPlayer from 'dating-mobile/src/native-modules/sound-player';
import { ToggleSpeakerphoneButton } from 'dating-mobile/src/call/views/toggle-speakerphone-button';
import { MuteAudioButton } from 'dating-mobile/src/call/views/mute-audio-button';
import { UserThumbnailAliased } from 'dating-mobile/src/user/common/views/user-thumbnail-aliased';
import CallConnectingInformation from '../../views/call-connecting-information';
// import RotateVideoButton from '../../views/rotate-video-button';
// import ToggleVideoButton from '../../views/toggle-video-button';
import InterruptButton from '../../views/interrupt-button';
import LocalVideo from '../../views/local-video';

import styles from './styles';

export default class ConnectingToCallView extends React.Component {
  static displayName = 'call.screens.connecting-to-call.view';

  static propTypes = {
    userId: PropTypes.string,
    attendeeId: PropTypes.string,
    hasLocalVideo: PropTypes.bool,
    showVideo: PropTypes.bool,
  };

  isPlaying = false;

  componentDidMount() {
    this.playSoundFile();
    AppState.addEventListener('change', this.appStateChanged);
    this.appStateChanged(AppState.currentState);
  }

  shouldComponentUpdate(nextProps) {
    const { userId, attendeeId, hasLocalVideo, showVideo } = this.props;

    return (
      userId !== nextProps.userId ||
      attendeeId !== nextProps.attendeeId ||
      hasLocalVideo !== nextProps.hasLocalVideo ||
      showVideo !== nextProps.showVideo
    );
  }

  componentWillUnmount() {
    this.stopSoundFile();
    AppState.removeEventListener('change', this.appStateChanged);
  }

  appStateChanged = nextAppState => {
    if (this.appState !== nextAppState) {
      if (nextAppState === 'active') {
        this.playSoundFile();
      } else {
        this.stopSoundFile();
      }
    }

    this.appState = nextAppState;
  };

  playSoundFile() {
    if (!this.isPlaying) {
      SoundPlayer.playSoundFile('outgoing_call', 'mp3', true);
    }

    this.isPlaying = true;
  }

  stopSoundFile() {
    if (this.isPlaying) {
      SoundPlayer.stop();
    }

    this.isPlaying = false;
  }

  render() {
    const { userId, attendeeId, hasLocalVideo, showVideo } = this.props;

    return (
      <SafeAreaView
        forceInset={{ top: 'never', bottom: 'always' }}
        style={styles.container}
      >
        <StatusBar
          barStyle={styles.$statusBarStyle}
          backgroundColor={styles.$statusBarBackgroundColor}
        />
        {!!showVideo && !!hasLocalVideo && (
          <LocalVideo
            userId={userId}
            attendeeId={attendeeId}
            style={styles.background}
          />
        )}
        {!!showVideo && !hasLocalVideo && (
          <UserThumbnailAliased
            style={styles.background}
            userId={attendeeId}
            size={styles.$hasBackgroundBlurred ? '.150x150' : undefined}
            blurRadius={styles.$hasBackgroundBlurred ? 7 : 0}
            placeholder={undefined}
          />
        )}
        <View style={styles.overlay} />
        <View style={styles.callConnectionInformation}>
          <CallConnectingInformation
            userId={userId}
            attendeeId={attendeeId}
            isVideoCall={showVideo}
          />
        </View>
        <View style={styles.buttonsContainer}>
          <View style={styles.streamButtonsContainer}>
            <ToggleSpeakerphoneButton userId={userId} attendeeId={attendeeId} />
            <MuteAudioButton userId={userId} attendeeId={attendeeId} />
          </View>
          <InterruptButton
            userId={userId}
            attendeeId={attendeeId}
            style={styles.cancelButton}
          />
        </View>
      </SafeAreaView>
    );
  }
}
