import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Image, TouchableOpacity, View } from 'react-native';
import styles from './styles';
import ChatLogWithInput from 'dating-mobile/src/dialogs/messages/views/chat-log-with-input';
import Resources from 'dating-mobile/src/resources';
import UserName from 'dating-mobile/src/user/common/views/user-name';
import FollowButton from 'dating-mobile/src/user/common/views/set-favorite-button';

export default class PrivateChatPopupView extends Component {
    static displayName = 'dialogs.messages.popup.view';
    static propTypes = {
        userId: PropTypes.string,
        attendeeId: PropTypes.string,
        onKeyboardVisible: PropTypes.func,
        onClose: PropTypes.func,
    };

    state = {
        followButtonWidth: undefined,
        closeButtonWidth: undefined,
    };

    onKeyboardChange = type => {
        const visible = type !== undefined && type !== null;
        this.notifyKeyboardVisible(visible);
    };

    notifyKeyboardVisible(visible) {
        this.props.onKeyboardVisible && this.props.onKeyboardVisible(visible);
    }

    componentWillUnmount() {
        this.notifyKeyboardVisible(false);
    }

    onFollowButtonLaidOut = event => {
        const { width } = event.nativeEvent.layout;

        if (this.state.followButtonWidth !== width) {
            this.setState({
                followButtonWidth: width,
            });
        }
    };

    onCloseButtonLaidOut = event => {
        const { width } = event.nativeEvent.layout;

        if (this.state.closeButtonWidth !== width) {
            this.setState({
                closeButtonWidth: width,
            });
        }
    };

    onClose = () => {
        this.props.onClose && this.props.onClose();
    };

    render() {
        const attendeeId = this.props.attendeeId;

        let usernameStyle = styles.username;
        if (
            typeof this.state.followButtonWidth === 'number' &&
            typeof this.state.closeButtonWidth === 'number'
        ) {
            const margin =
                Math.max(this.state.followButtonWidth, this.state.closeButtonWidth) +
                styles._headerContainer.padding * 2;
            usernameStyle = [usernameStyle, { left: margin, right: margin }];
        }

        return (
            <View style={styles.container}>
                <View style={styles.headerContainer}>
                    <FollowButton
                        holderId={this.props.userId}
                        targetId={attendeeId}
                        onLayout={this.onFollowButtonLaidOut}
                        style={styles.followButton}
                    />
                    <UserName
                        userId={attendeeId}
                        numberOfLines={1}
                        ellipsizeMode={'tail'}
                        style={usernameStyle}
                    />
                    <TouchableOpacity
                        style={styles.closeButton}
                        onPress={this.onClose}
                        onLayout={this.onCloseButtonLaidOut}
                    >
                        <Image
                            style={styles.closeButtonImage}
                            source={Resources.images.closeIcon()}
                        />
                    </TouchableOpacity>
                </View>

                <ChatLogWithInput
                    user={this.props.userId}
                    attendee={attendeeId}
                    logStyle={styles.log}
                    formStyle={styles.form}
                    onKeyboardChange={this.onKeyboardChange}
                />
            </View>
        );
    }
}
