import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        flex: 1
    },

    list: {
        flex: 1
    },

    separator: {
        height: 7
    },

    itemContainer: {
        width: '25%'
    }
});

export default styles;
