import withPermissions from 'dating-mobile/src/components/permissions';
import withController from './controller';
import View from './view';

export const AudioCallButton = withPermissions(withController(View), {
  resultProps: {
    microphoneStatus: 'microphone',
  },
  returnStatus: true,
});
