import React, {
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import resolveShadowValue from 'react-native-web/src/exports/StyleSheet/resolveShadowValue';
import TextAncestorContext from 'react-native-web/src/exports/Text/TextAncestorContext';

import ViewPropTypes from './types';

import styles from './styles';

import ImageLoader, { ImageUriCache } from './ImageLoader';

const ERRORED = 'ERRORED';
const LOADED = 'LOADED';
const LOADING = 'LOADING';
const IDLE = 'IDLE';

let globalfilterId = 0;

function createTintColorSVG(tintColor, id) {
  return tintColor && id != null ? (
    <svg
      style={{
        position: 'absolute',
        height: 0,
        visibility: 'hidden',
        width: 0,
      }}
    >
      <defs>
        <filter id={`tint-${id}`} suppressHydrationWarning>
          <feFlood floodColor={`${tintColor}`} key={tintColor} />
          <feComposite in2="SourceAlpha" operator="atop" />
        </filter>
      </defs>
    </svg>
  ) : null;
}

function getFlatStyle(style, blurRadius, filterId) {
  const flatStyle = { ...StyleSheet.flatten(style) };
  const { filter, resizeMode, shadowOffset, tintColor } = flatStyle;

  // Add CSS filters
  // React Native exposes these features as props and proprietary styles
  const filters = [];
  let newFilter = null;

  if (filter) {
    filters.push(filter);
  }
  if (blurRadius) {
    filters.push(`blur(${blurRadius}px)`);
  }
  if (shadowOffset) {
    const shadowString = resolveShadowValue(flatStyle);

    if (shadowString) {
      filters.push(`drop-shadow(${shadowString})`);
    }
  }
  if (tintColor && filterId != null) {
    filters.push(`url(#tint-${filterId})`);
  }

  if (filters.length > 0) {
    newFilter = filters.join(' ');
  }

  // These styles are converted to CSS filters applied to the
  // element displaying the background image.
  delete flatStyle.blurRadius;
  delete flatStyle.shadowColor;
  delete flatStyle.shadowOpacity;
  delete flatStyle.shadowOffset;
  delete flatStyle.shadowRadius;
  delete flatStyle.tintColor;
  // These styles are not supported on View
  delete flatStyle.overlayColor;
  delete flatStyle.resizeMode;

  return [flatStyle, resizeMode, newFilter, tintColor];
}

const Image = forwardRef((props, ref) => {
  const {
    accessibilityLabel,
    blurRadius,
    defaultSource,
    draggable,
    onError,
    onLayout,
    onLoad,
    onLoadEnd,
    onLoadStart,
    onProgress,
    pointerEvents,
    source,
    style,
    ...rest
  } = props;

  const cachedSource = ImageUriCache.get(source);
  const resolvedSource = ImageLoader.resolveSource(source);
  const resolvedDefaultSource = ImageLoader.resolveSource(defaultSource);
  const [state, updateState] = useState(() => {
    if (source != null) {
      const isLoaded = ImageUriCache.has(source);

      if (isLoaded) {
        return LOADED;
      }
    }

    return IDLE;
  });
  const [containerLayout, updateLayout] = useState({});
  const [isIntersecting, setIntersecting] = useState(false);
  const hasTextAncestor = useContext(TextAncestorContext);
  const hiddenImageRef = useRef(null);
  const visibleImageRef = useRef(null);
  const filterRef = useRef(globalfilterId++);
  const requestRef = useRef(null);
  const shouldDisplaySource =
    state === LOADED || (state === LOADING && defaultSource == null);
  const [flatStyle, _resizeMode, filter, tintColor] = getFlatStyle(
    style,
    blurRadius,
    filterRef.current,
  );
  const resizeMode = props.resizeMode || _resizeMode || 'cover';
  const selectedSource = shouldDisplaySource
    ? resolvedSource
    : resolvedDefaultSource;
  const displayImageUri = getDisplayUri();
  const backgroundImage = displayImageUri ? `url("${displayImageUri}")` : null;
  const backgroundSize = getBackgroundSize();
  const observer = new window.IntersectionObserver(([entry]) =>
    setIntersecting(entry.isIntersecting),
  );

  function getDisplayUri() {
    if (cachedSource) return cachedSource.displayImageUri;
    if (
      (selectedSource.headers && Object.keys(selectedSource.headers).length) ||
      selectedSource.method === 'POST'
    )
      return null;

    return selectedSource.uri;
  }

  function getBackgroundSize() {
    if (
      hiddenImageRef.current != null &&
      (resizeMode === 'center' || resizeMode === 'repeat')
    ) {
      const { naturalHeight, naturalWidth } = hiddenImageRef.current;
      const { height, width } = containerLayout;

      if (naturalHeight && naturalWidth && height && width) {
        const scaleFactor = Math.min(
          1,
          width / naturalWidth,
          height / naturalHeight,
        );
        const x = Math.ceil(scaleFactor * naturalWidth);
        const y = Math.ceil(scaleFactor * naturalHeight);

        return `${x}px ${y}px`;
      }
    }

    return undefined;
  }

  function handleLayout(e) {
    if (resizeMode === 'center' || resizeMode === 'repeat' || onLayout) {
      const { layout } = e.nativeEvent;

      if (onLayout) {
        onLayout(e);
      }

      updateLayout(layout);
    }
  }

  // Image loading
  const { uri } = resolvedSource;

  useEffect(() => {
    const commonStyles = [
      styles.image,
      styles[resizeMode],
      { backgroundImage },
      backgroundSize != null && { backgroundSize },
    ];

    if (visibleImageRef.current) {
      visibleImageRef.current.setNativeProps({
        style: commonStyles,
      });
      if (filter) {
        observer.observe(visibleImageRef.current);

        if (isIntersecting) {
          visibleImageRef.current.setNativeProps({
            style: [...commonStyles, { filter }],
          });
        }
      }
    }

    return () => {
      observer.disconnect();
    };
  }, [backgroundImage, backgroundSize, resizeMode, isIntersecting]);

  useEffect(() => {
    abortPendingRequest();

    if (uri) {
      updateState(LOADING);
      if (onLoadStart) {
        onLoadStart();
      }

      requestRef.current = ImageLoader.load(
        source,
        function load(e, imageUri) {
          if (imageUri) {
            ImageUriCache.add(source, imageUri);
          }
          updateState(LOADED);
          if (onLoad) {
            onLoad(e);
          }
          if (onLoadEnd) {
            onLoadEnd();
          }
        },
        function error() {
          updateState(ERRORED);
          if (onError) {
            onError({
              nativeEvent: {
                error: `Failed to load resource ${resolvedSource.uri} (404)`,
              },
            });
          }
          if (onLoadEnd) {
            onLoadEnd();
          }
        },
        function progress(event) {
          if (onProgress) {
            onProgress({
              nativeEvent: event,
            });
          }
        },
      );
    }

    function abortPendingRequest() {
      if (requestRef.current != null) {
        ImageLoader.abort(requestRef.current);
        requestRef.current = null;
      }
    }

    return abortPendingRequest;
  }, [uri]);

  return (
    <View
      {...rest}
      accessibilityLabel={accessibilityLabel}
      onLayout={handleLayout}
      pointerEvents={pointerEvents}
      ref={ref}
      style={StyleSheet.flatten([
        styles.root,
        hasTextAncestor && styles.inline,
        flatStyle,
      ])}
    >
      <View ref={visibleImageRef} suppressHydrationWarning />
      {displayImageUri ? (
        <img
          alt={accessibilityLabel || ''}
          style={imageStyles}
          draggable={Boolean(draggable)}
          ref={hiddenImageRef}
          src={displayImageUri}
        />
      ) : null}
      {createTintColorSVG(tintColor, filterRef.current)}
    </View>
  );
});

Image.displayName = 'Image';

// $FlowFixMe
Image.getSize = function(source, success, failure) {
  ImageLoader.getSize(source, success, failure);
};

// $FlowFixMe
Image.prefetch = function(source) {
  return ImageLoader.prefetch(source);
};

// $FlowFixMe
Image.queryCache = function(sources) {
  return ImageLoader.queryCache(sources);
};

const ImageSourcePropType = PropTypes.oneOfType([
  PropTypes.object,
  PropTypes.string,
  PropTypes.number,
  PropTypes.arrayOf(PropTypes.object),
]);

Image.propTypes = {
  ...ViewPropTypes,
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
  blurRadius: PropTypes.number,
  defaultSource: ImageSourcePropType,
  draggable: PropTypes.bool,
  onError: PropTypes.func,
  onLayout: PropTypes.func,
  onLoad: PropTypes.func,
  onLoadEnd: PropTypes.func,
  onLoadStart: PropTypes.func,
  onProgress: PropTypes.func,
  resizeMode: PropTypes.oneOf([
    'center',
    'contain',
    'cover',
    'none',
    'repeat',
    'stretch',
  ]),
  source: ImageSourcePropType,
  capInsets: PropTypes.shape({
    top: PropTypes.number,
    left: PropTypes.number,
    bottom: PropTypes.number,
    right: PropTypes.number,
  }),
  resizeMethod: PropTypes.oneOf(['auto', 'resize', 'scale']),
};

const imageStyles = {
  ...StyleSheet.absoluteFillObject,
  height: '100%',
  opacity: 0,
  width: '100%',
  zIndex: -1,
};

export default Image;
