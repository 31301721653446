import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $gradientColors: '$brandButtonDisabledGradientColor',
    container: {
        marginRight: 10,
        marginVertical: 5,
        height: 36,
        borderRadius: 18,
        overflow: 'hidden',
    },
    content: {
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    },
    selectedModifier: {
        backgroundColor: '$accentColor',
    },

    icon: {
        marginLeft: 10,
        width: 24,
        height: 24,
    },
    text: {
        marginLeft: 4,
        marginRight: 18,
        color: '$brandTextColor',
        fontFamily: '$brandFontFamily',
        fontSize: 14,
        fontWeight: '600',
    },
    disabled: {
        color: '$disabledColor',
        tintColor: '$disabledColor',
    },
});

export default styles;
