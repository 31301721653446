import React from 'react';
import connect from '@sdv/connect';
import PropTypes from 'prop-types';
import getMailboxForFilter, { createIdForFilter } from '@sdv/domain/dialogs.mailbox/inbox';


export default function createController(Component) {

    class Controller extends React.Component {
        static displayName = 'inbox.views.letters-list.controller';
        static propTypes = {
            userId: PropTypes.string,
            filter: PropTypes.number,
            useDynamicLoading: PropTypes.bool,
            defaultCount: PropTypes.number,
            loadMoreCount: PropTypes.number,
            letters: PropTypes.array,
            getLetters: PropTypes.func,
            endReached: PropTypes.bool,
            readLetter: PropTypes.func,
            attendeeId: PropTypes.string
        };

        componentDidMount() {
            this.props.getLetters && this.props.getLetters(this.props.defaultCount || 0, true);
        }

        componentDidUpdate(prevProps) {
            if (shouldReconnect(prevProps, this.props)) {
                this.props.getLetters && this.props.getLetters(this.props.defaultCount || 0, true);
            }
        }

        loadMore = () => {
            if (!this.endReached) {
                this.props.getLetters && this.props.getLetters(this.props.loadMoreCount || 0);
            }
        };

        onLetterSelected = (letter) => {
            this.props.readLetter && this.props.readLetter(letter);
        };

        render() {
            const props = {...this.props};

            delete props.getLetters;

            return (<Component
                    {...props}
                    onLoadMoreRequest={this.loadMore}
                    onLetterSelect={this.onLetterSelected}/>
            );
        }
    }

    function getModels(flux, props) {
        const models = {};
        if (props.userId && props.filter) {
            const Mailbox = getMailboxForFilter(props.filter, props.attendeeId);
            const id = createIdForFilter(props.filter, props.userId, props.attendeeId);
            models.mailbox = Mailbox && flux.get(Mailbox, id);
        }

        return models;
    }

    function mapStoresToProps(models) {
        const props = {};

        if (models.mailbox) {
            const modelState = models.mailbox.store.getState();
            props.letters = modelState.letters;
            props.endReached = modelState.endReached;
        }

        return props;
    }

    function mapActionsToProps(models) {
        const props = {};

        if (models.mailbox) {
            props.getLetters = models.mailbox.actions.getLetters;
        }

        return props;
    }

    function shouldReconnect(props, nextProps) {
        return props.userId !== nextProps.userId ||
            props.filter !== nextProps.filter ||
            props.attendeeId !== nextProps.attendeeId;
    }

    return connect(
        getModels,
        mapStoresToProps,
        mapActionsToProps,
        shouldReconnect
    )(Controller);
}
