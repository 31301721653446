import { Observable } from 'rxjs';
import AltStore from '@sdv/alt/src/store/AltStore';
import clone from 'lodash/clone'

AltStore.prototype.rxState = function () {
    return new Observable((observer) => {
        if (typeof this.isFilled !== 'function' || this.isFilled()) {
            observer.next(this.getState());
        }
        const dispose = this.listen((state) => {
            observer.next(clone(state));
        });
        return dispose;
    });
};
