import React from 'react';
import PropTypes from 'prop-types';
import { Platform, Text, TouchableOpacity, View } from 'react-native';
import Resources from '../../resources';

import CustomActionSheet from '../action-sheet';
import styles from './styles';

class CustomPicker extends React.Component {
  static displayName = 'component.safe-picker';

  static propTypes = {
    isPlaceholder: PropTypes.bool,
    style: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.number,
      PropTypes.array,
    ]),
    placeholderStyle: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.number,
      PropTypes.array,
    ]),
    titleStyle: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.number,
      PropTypes.array,
    ]),
    buttonContainerStyle: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.number,
      PropTypes.array,
    ]),
    // TODO: Remove or implement on Android
    onClose: PropTypes.func,
    // TODO: Remove or implement on Android
    onDone: PropTypes.func,
    title: PropTypes.node,
    children: PropTypes.node,
  };

  state = {
    modalVisible: false,
  };

  getDisplayText() {
    const { isPlaceholder, title } = this.props;

    if (!isPlaceholder || typeof title === 'string') {
      return title;
    }

    return Resources.strings['safe-picker-default-placeholder'];
  }

  toggleModal = () => {
    this.setState(
      state => ({ modalVisible: !state.modalVisible }),
      () => {
        const { onClose } = this.props;

        if (onClose) {
          onClose();
        }
      },
    );
  };

  onDone = () => {
    this.toggleModal();

    const { onDone } = this.props;

    if (onDone) {
      onDone();
    }
  };

  render() {
    const {
      children,
      buttonContainerStyle,
      isPlaceholder,
      style = {},
      titleStyle = {},
      placeholderStyle = {},
    } = this.props;

    const { modalVisible } = this.state;
    const containerStyle = [styles.container, style];
    const displayText = this.getDisplayText();

    const title = (
      <Text
        style={[
          styles.title,
          titleStyle,
          isPlaceholder ? [styles.placeholder, placeholderStyle] : {},
        ]}
      >
        {displayText}
      </Text>
    );

    if (Platform.OS !== 'ios') {
      return (
        <View style={containerStyle}>
          {title}
          <View style={styles.androidPickerContainer}>{children}</View>
        </View>
      );
    }

    return (
      <View style={containerStyle}>
        <TouchableOpacity
          style={buttonContainerStyle}
          onPress={this.toggleModal}
        >
          {title}
        </TouchableOpacity>
        <CustomActionSheet
          modalVisible={modalVisible}
          onCancel={this.toggleModal}
          onDone={this.onDone}
        >
          <View style={styles.iosPickerContainer}>{children}</View>
        </CustomActionSheet>
      </View>
    );
  }
}

export default CustomPicker;
