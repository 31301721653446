import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  View,
  ActivityIndicator,
  StatusBar,
  Text,
  ScrollView,
} from 'react-native';

import { SubscriptionAgreement } from 'dating-mobile/src/payment/views/subscription-agreement';
import { InitialOfferBenefits } from 'dating-mobile/src/payment/views/membership-benefits/initial-offer';
import Resources from 'dating-mobile/src/resources';
import Button from 'dating-mobile/src/components/buttons/base';
import { formatPrice } from '@sdv/commons/price-with-code-localization';
import { getPeriodText } from 'dating-mobile/src/payment/utils/trial-pirce-formatting';

import styles from './styles';

export default class ScreenView extends PureComponent {
  static displayName = 'stepped-registration.screens.subscription.view';

  static propTypes = {
    onSubmit: PropTypes.func,
    isLoading: PropTypes.bool,
    invoice: PropTypes.object,
  };

  render() {
    const { isLoading, onSubmit, invoice } = this.props;
    const { numberToDisplay, unitToDisplay } = getPeriodText(
      invoice?.price?.subscriptionPeriodNumber,
      invoice?.price?.subscriptionPeriodUnit,
    );
    const localizedPrice = formatPrice(invoice?.price, false);

    return (
      <ScrollView
        style={styles.scrollView}
        contentContainerStyle={styles.content}
        alwaysBounceVertical={false}
      >
        <StatusBar
          barStyle={styles.$statusBarStyle}
          backgroundColor={styles.$statusBarBackgroundColor}
        />
        <View>
          <Text style={styles.title}>
            {Resources.strings['onboarding-screen-subscription-title']}
          </Text>
          <Text style={styles.subTitle}>
            {Resources.strings['onboarding-screen-subscription-subtitle']}
          </Text>
        </View>
        <InitialOfferBenefits style={styles.benefits} />
        <View>
          <Button
            disabledGradientColor={styles.disabledButton.gradientColor}
            style={styles.button}
            disabled={isLoading || !invoice}
            onPress={onSubmit}
            title={
              invoice
                ? Resources.strings.formatString(
                    Resources.strings[
                      'onboarding-screen-subscription-button-title'
                    ],
                    localizedPrice,
                    numberToDisplay,
                    unitToDisplay,
                  )
                : Resources.strings.get
            }
          />
          <Text style={styles.concernText}>
            {Resources.strings['onboarding-screen-subscription-concern']}
          </Text>
          <SubscriptionAgreement />
        </View>
        {isLoading && (
          <View style={styles.activityIndicatorContainer}>
            <ActivityIndicator animating />
          </View>
        )}
      </ScrollView>
    );
  }
}
