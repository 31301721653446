import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';

import styles from './styles';

export default class ProgressBarView extends PureComponent {
    static displayName = 'components.progress-bar.view';

    static propTypes = {
        currentProgress: PropTypes.number,
    };

    static defaultProps = {
        currentProgress: 0,
    };

    render() {
        const { currentProgress } = this.props;
        const fillStyle = { width: `${currentProgress}%` };

        return (
            <View style={styles.container}>
                <View style={[styles.fillContainer, fillStyle]} />
            </View>
        );
    }
}
