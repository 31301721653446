import React from 'react';
import PropTypes from 'prop-types';
import Resources from '../../../../../resources';
import styles from './styles';
import { View, Text, ActivityIndicator, TouchableOpacity, Image, TouchableWithoutFeedback } from 'react-native';
import Photo, { PHOTO_TRANSFORMATION_TYPE } from '../../../../../components/photo';
import { RESOURCE_ACCESS_STATUS } from '../../../../../models/paid-resource/model';
import Button from '../../../../../components/buttons/base';
import IfConfigValue from '../../../../../components/config-value/if';
import PrivatePhotoDescription from './private-photo-description';
import ZoomView from '../../../../../components/zoom-view';

import GalleryContentController from '../../../../../models/user.media/controller/gallery-content-controller';
import Can from 'dating-mobile/src/models/user.ability/controller';
import CredentialsUpdater from 'dating-mobile/src/authentication/utils/credentials-updater';
import withAutoScale from '../../../../../components/auto-scale-image';

const ScaledImage = withAutoScale(Image)

export class GalleryContent extends React.Component {
    static displayName = 'gallery.content';
    static propTypes = {
        id: PropTypes.string,
        media: PropTypes.shape({
            mediatype: PropTypes.string
        }).isRequired,
        path: PropTypes.string,
        status: PropTypes.number.isRequired,
        fullscreen: PropTypes.bool,
        numberOfPrivatePhotos: PropTypes.number,
        onPlayButtonPress: PropTypes.func,
        onSubscribeButtonPress: PropTypes.func,
        onSelected: PropTypes.func,
        togglePrivate: PropTypes.func
    };

    state = {};

    onPressed = () => {
        if (!this.props.fullscreen) {
            this.props.onSelected && this.props.onSelected();
        }
    };

    onViewLaidOut = (event) => {
        let { width, height } = event.nativeEvent.layout;

        if (!width || !height || (this.state.width === width && this.state.height === height)) {
            return;
        }

        this.setState({
            width: width,
            height: height
        });
    };

    shouldComponentUpdate(nextProps, nextState) {
        return this.props.id !== nextProps.id ||
            this.props.media.mediatype !== nextProps.media.mediatype ||
            this.props.path !== nextProps.path ||
            this.props.status !== nextProps.status ||
            this.props.fullscreen !== nextProps.fullscreen ||
            this.props.numberOfPrivatePhotos !== nextProps.numberOfPrivatePhotos ||
            this.props.onPlayButtonPress !== nextProps.onPlayButtonPress ||
            this.props.onSelected !== nextProps.onSelected ||
            this.props.togglePrivate !== nextProps.togglePrivate ||
            this.props.onSubscribeButtonPress !== nextProps.onSubscribeButtonPress ||
            this.state.width !== nextState.width ||
            this.state.height !== nextState.height ||
            this.state.isLoading !== nextState.isLoading
    }

    playVideo = (status) => () => {
        status ? this.props.onPlayButtonPress() : CredentialsUpdater.updateCredentials(this.props.onPlayButtonPress);
    };

    render() {
        const mediaType = this.props.media.mediatype;
        const isPhoto = mediaType && mediaType.startsWith('image');
        const isVideo = mediaType && mediaType.startsWith('video');
        const scalingEnabled = isPhoto && this.props.status === RESOURCE_ACCESS_STATUS.AVAILABLE && this.props.fullscreen;
        const resizeMode = this.props.fullscreen ? 'contain': 'cover';
        const transformationType = this.props.fullscreen
            ? PHOTO_TRANSFORMATION_TYPE.KEEP_ASPECT_RATIO
            : PHOTO_TRANSFORMATION_TYPE.DETECT_FACE;

        const photo = (
            <Photo
                style={styles.photo}
                path={this.props.path}
                transformation={transformationType}
                resizeMode={resizeMode}
                onLoadStart={() => this.setState({ isLoading: true })}
                onLoadEnd={() => this.setState({ isLoading: false })}
            />
        );

        return (
            <View style={styles.container} onLayout={this.onViewLaidOut}>
                {
                    this.state.width && this.state.height && (
                        scalingEnabled
                            ? (
                                (
                                    <ZoomView style={styles.content}
                                              cropWidth={this.state.width}
                                              cropHeight={this.state.height}
                                              imageWidth={this.state.width}
                                              imageHeight={this.state.height}
                                              panToMove={scalingEnabled}
                                              pinchToZoom={scalingEnabled}
                                    >
                                        {photo}
                                    </ZoomView>
                                )
                            )
                            : (
                                <TouchableWithoutFeedback style={styles.content} onPress={this.onPressed}>
                                    {photo}
                                </TouchableWithoutFeedback>
                            )
                    )
                }
                {
                    isPhoto && this.props.status === RESOURCE_ACCESS_STATUS.PAYMENT_REQUIRED && (
                        <View style={styles.descriptionContainer} pointerEvents={'box-none'}>
                            <ScaledImage style={styles.descriptionIcon} source={Resources.images.lockIcon()} />
                            <Text style={styles.descriptionHeader}>
                                {
                                    Resources.strings.formatString(
                                        Resources.strings['gallery-screen-private-photo-title-format'],
                                        this.props.numberOfPrivatePhotos || 0
                                    )
                                }
                            </Text>
                            <Text style={styles.description}>
                                <PrivatePhotoDescription id={this.props.id} />
                            </Text>
                            <IfConfigValue path={'features.private-photos-accessible-with-membership-enabled'} equalsTo={true}>
                                <Button
                                    style={styles.subscribeButton}
                                    title={Resources.strings['gallery-screen-subscribe-button-title']}
                                    onPress={this.props.onSubscribeButtonPress}
                                />
                            </IfConfigValue>
                        </View>
                    )
                }
                {
                    isVideo && this.props.status === RESOURCE_ACCESS_STATUS.AVAILABLE && (
                        <View style={styles.descriptionContainer}>
                            <TouchableOpacity style={{zIndex: 999}} onPress={this.props.onPlayButtonPress}>
                                <ScaledImage source={Resources.images.playVideoIcon()} />
                            </TouchableOpacity>
                        </View>
                    )
                }
                {
                    isVideo && this.props.status === RESOURCE_ACCESS_STATUS.PAYMENT_REQUIRED && (
                        <Can do={'create'} of={'purchase'}>
                            {
                                (status) => (
                                    <View style={styles.descriptionContainer}>
                                        <ScaledImage style={styles.descriptionIcon} source={Resources.images.lockIcon()} />
                                        <Button title={'Play video'} onPress={this.playVideo(status)} />
                                    </View>
                                )
                            }
                        </Can>
                    )
                }
                <View style={styles.descriptionContainer}>
                    <ActivityIndicator style={styles.activityIndicator} animating={this.state.isLoading} />
                </View>
            </View>
        );
    }
}

export default GalleryContentController(GalleryContent);
