import React from 'react';
import PropTypes from 'prop-types';
import identityId from 'dating-mobile/src/models/identity/controller/id';
import UserTagsModel from 'dating-mobile/src/models/user.tags.targeted/model';
import { StreamsAdviser } from '@sdv/domain/streaming/streams-adviser';

function createFollowTopController(Component) {
    class Controller extends React.Component {
        static displayName = 'broadcasts.follow-top.screen.controller';
        static contextTypes = {
            flux: PropTypes.object,
        };
        static propTypes = {
            onComplete: PropTypes.func,
            userId: PropTypes.string,
            streams: PropTypes.array,
        };

        state = {
            streams: [],
            selectedItems: [],
        };

        constructor(props, context) {
            super(props);
            this.flux = context.flux;
        }

        componentDidMount() {
            this.subscribe();
        }

        componentWillUnmount() {
            this.unsubscribe();
        }

        componentDidUpdate(prevProps) {
            if (this.props.userId !== prevProps.userId) {
                this.unsubscribe();
                this.subscribe();
            }
        }

        subscribe() {
            const streamsAdviser = new StreamsAdviser(this.props.userId);

            this.subscription = streamsAdviser.streams.subscribe(streams =>
                this.setState({
                    streams,
                    selectedItems: [...streams],
                }),
            );
        }

        unsubscribe() {
            if (this.subscription) {
                this.subscription.unsubscribe();
                this.subscription = null;
            }
        }

        itemPressed = itemId => {
            let selectedItems = this.state.selectedItems;
            let index = selectedItems.indexOf(itemId);
            if (index !== -1) {
                selectedItems.splice(index, 1);
            } else {
                selectedItems.push(itemId);
            }
            this.setState({ selectedItems: selectedItems });
        };

        onComplete = () => {
            this.state.selectedItems.forEach(selectedId => {
                let { actions } = this.flux.get(
                    UserTagsModel,
                    `${this.props.userId}:${selectedId}`,
                );

                actions.patch('favorite');
            });

            this.props.onComplete && this.props.onComplete(this.state.selectedItems);
        };

        render() {
            return (
                <Component
                    {...this.props}
                    streams={this.state.streams}
                    selectedItems={this.state.selectedItems}
                    itemPressed={this.itemPressed}
                    onComplete={this.onComplete}
                />
            );
        }
    }

    return identityId(Controller);
}

export default createFollowTopController;
