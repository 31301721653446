// @ts-ignore
import { ImageUploader } from '@sdv/domain/app/image-uploader';
import { singleton } from '@sdv/commons/utils/singleton';
import { Subject } from 'rxjs';
import { delay, retry, switchMap, tap } from 'rxjs/operators';

export type UploadResult = {
    progress: number;
    status: string;
    basename: string;
};

type UploadMedia = {
    height: number;
    width: number;
    mediaType: 'photo' | 'video';
    name: string;
    type: string;
    uri: string;
};

export class ThumbnailPhotoUploader {
    static shared = singleton(() => new ThumbnailPhotoUploader());

    shouldUploadPhoto = new Subject<{
        file: UploadMedia;
        userId: string;
        onResultCallback: Function;
    }>();

    constructor() {
        const imageUploader = ImageUploader.shared();

        this.shouldUploadPhoto
            .pipe(
                switchMap(({ file, userId, onResultCallback }) => {
                    return imageUploader.uploadImage(file, `/users/${userId}/photos`).pipe(
                        tap(result => {
                            // eslint-disable-next-line babel/no-unused-expressions
                            onResultCallback && onResultCallback(result);
                        }),
                        delay(3000),
                        retry(10),
                    );
                }),
            )
            .subscribe(() => {}, () => {});
    }

    uploadPhoto = (
        file: UploadMedia,
        userId: string,
        onResultCallback: (result: UploadResult) => void,
    ): void => {
        this.shouldUploadPhoto.next({ file, userId, onResultCallback });
    };
}
