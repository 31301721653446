import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import * as Animatable from 'react-native-animatable';
import Resources from 'dating-mobile/src/resources';
import IconWithText from 'dating-mobile/src/components/icon-with-text';

import styles from './styles';

export default class StreamRerunBanner extends PureComponent {
    static displayName = 'dialogs.stream.views.stream-rerun-banner.view';
    static propTypes = {
        visible: PropTypes.bool,
        needAnimationStart: PropTypes.bool,
    };

    componentDidMount() {
        this.runAnimation();
    }

    componentDidUpdate() {
        if (this.props.needAnimationStart) {
            this.runAnimation();
        }
    }

    runAnimation = () => {
        if (this.view) {
            this.view.bounceOutRight(1000, 5000)
        }
    };

    render() {
        const { visible } = this.props;

        return visible ? (
            <Animatable.View
                ref={element => this.view = element}
                easing="linear"
                useNativeDriver
            >
                <IconWithText
                    text={Resources.strings['stream-screen-rerun-video-text']}
                    textStyle={styles.text}
                    icon={Resources.images.rerunVideoIcon()}
                    containerStyle={styles.container}
                />
            </Animatable.View>
        ) : null
    }
}