import { Platform } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

const defaultBubbleStyle = {
  borderRadius: 20,
  maxWidth: Platform.select({
    web: '100%',
  }),
  overflow: 'hidden',
};

const defaultOutgoingBubbleStyle = {
  ...defaultBubbleStyle,
  backgroundColor: '$brandColor',
};

const defaultBubbleWithOnlySmilesStyle = {
  ...defaultBubbleStyle,
  backgroundColor: 'transparent',
};

const defaultTextStyle = {
  marginHorizontal: 10,
  marginVertical: 10,
  fontSize: 18,
  fontFamily: '$brandFontFamily',
};

const styles = EStyleSheet.create({
  bubbleOutgoing: defaultOutgoingBubbleStyle,

  bubbleOutgoingUnsent: {
    ...defaultBubbleStyle,
    backgroundColor: '#cbcbcb',
  },

  bubbleOutgoingUnpaid: {
    ...defaultOutgoingBubbleStyle,
    backgroundColor: '$brandColor',
    opacity: 0.5,
  },

  bubbleIncoming: {
    ...defaultBubbleStyle,
    backgroundColor: '$secondaryBackgroundColor',
  },

  bubbleWithOnlySmiles: defaultBubbleWithOnlySmilesStyle,

  bubbleUnsentWithOnlySmiles: {
    ...defaultBubbleWithOnlySmilesStyle,
    opacity: 0.5,
  },

  bubbleAutoMessage: {
    ...defaultBubbleWithOnlySmilesStyle,
    backgroundColor: '#34b579',
  },

  bubbleAutoReply: {
    ...defaultBubbleWithOnlySmilesStyle,
    borderColor: '$disabledColor',
    borderWidth: 1,
    paddingHorizontal: 25,
  },

  textIncoming: {
    ...defaultTextStyle,
    color: '$primaryTextColor',
  },

  textOutgoing: {
    ...defaultTextStyle,
    color: 'white',
  },

  textAutoReply: {
    ...defaultTextStyle,
    color: '$primaryTextColor',
    fontSize: 16,
  },

  textAutoMessage: {
    ...defaultTextStyle,
    color: 'white',
  },

  absolute: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },

  textBlurred: {
    opacity: 0.01,
  },

  videoCallButton: {
    marginRight: 16,
    marginLeft: 16,
    marginBottom: 16,
    backgroundColor: '$acceptColor',
  },

  videoCallIcon: {
    tintColor: 'white',
  },
});

export default styles;
