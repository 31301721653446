import Persistence from '@sdv/domain/app/persistence';
import AppFocus from '@sdv/domain/app/application/focus';
import {
    map,
    mapTo,
    shareReplay,
    filter,
    withLatestFrom,
    switchMap,
    takeUntil,
    concat
} from 'rxjs/operators';
import { singleton } from '@sdv/commons/utils/singleton';

export default class UserSession {
    static shared = singleton(() => new UserSession());

    constructor() {
        const inactiveTimeout = 30 * 60 * 1000;

        const appFocus = AppFocus.shared();
        const persistence = Persistence.shared();

        const storedNumberOfVisits = persistence.rxValue('StateBasedAppFocus.numberOfVisits');
        const loadedNumberOfVisits = storedNumberOfVisits.pipe(map(number => number && parseInt(number) || 0));
        const storedAppLeavesActiveStateTime = persistence.rxValue('StateBasedAppFocus.appLeavesActiveStateTime');
        const loadedAppLeavesActiveStateTime = storedAppLeavesActiveStateTime.pipe(map(time => time && parseInt(time) || null));

        const applicationIsActive = appFocus.focused;

        const storeAppLeavesActiveStateTime = time => persistence.rxStore(
            'StateBasedAppFocus.appLeavesActiveStateTime',
            String(time)
        );

        const updatedApplicationLeavesActiveStateTime = applicationIsActive.pipe(
            filter(value => !value),
            map(() => (new Date()).getTime()),
            switchMap(time => storeAppLeavesActiveStateTime(time).pipe(mapTo(time))),
            shareReplay(1)
        );

        const applicationLeavesActiveStateTime = loadedAppLeavesActiveStateTime.pipe(
            takeUntil(updatedApplicationLeavesActiveStateTime),
            concat(updatedApplicationLeavesActiveStateTime),
            shareReplay(1)
        );

        this.applicationLeavesActiveStateTimeSubscription = applicationLeavesActiveStateTime.subscribe();

        const newSessionStarted = applicationIsActive.pipe(
            filter(value => value),
            map(() => (new Date()).getTime()),
            withLatestFrom(applicationLeavesActiveStateTime),
            filter(([appEnteredForegroundTime, appEnteredBackgroundTime]) => {
                return appEnteredBackgroundTime
                    ? appEnteredForegroundTime - appEnteredBackgroundTime >= inactiveTimeout
                    : true;
            })
        );

        const storeNumberOfVisit = number => persistence.rxStore('StateBasedAppFocus.numberOfVisits', String(number));

        const updatedVisitsCount = newSessionStarted.pipe(
            switchMap(() => loadedNumberOfVisits.pipe(
                map(previousVisitsCount => previousVisitsCount + 1),
                switchMap(count => storeNumberOfVisit(count).pipe(mapTo(count)))
            )),
            shareReplay(1)
        );

        this.numberOfVisits = loadedNumberOfVisits.pipe(
            concat(updatedVisitsCount),
            shareReplay(1)
        );

        this.numberOfVisits.subscribe();
    }
}
