import { Config } from '@sdv/domain/app/config';
import { of } from 'rxjs';

export class ChatAnonymity {
  constructor(userId, attendeeId) {
    const userIsAnonymous = id => id && parseFloat(id.slice(-2)) > 50;

    this.writingAsAnonymous = userIsAnonymous(attendeeId)
      ? of(false)
      : Config.shared().anonymousChatEnabled;
  }
}
