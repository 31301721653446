import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $simplifiedView: false,

    '@flavor udates': {
        $simplifiedView: true,
    },

    container: {
        paddingHorizontal: 16
    },
    item: {
        flexDirection: 'row',
        marginBottom: 12,
    },
    icon: {
        width: 24,
        height: 24
    },
    text: {
        fontSize: 18,
        fontFamily: '$brandFontFamily',
        color: '$brandTextColor',
        marginLeft: 5,
    },

    textSimplified: {
        fontSize: 17,
        marginTop: 10,
        fontFamily: '$brandFontFamily',
        color: '$brandTextColor',
    },

    about: {
        marginTop: 10,
        fontSize: 18,
        fontFamily: '$brandFontFamily',
        color: '$brandTextColor',
    }
});

export default styles;
