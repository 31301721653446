import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Text } from 'react-native';

import { formatTimeNumericShort } from '../formatters';

export default class TimeSpent extends PureComponent {
  static displayName = 'components.time.time-spent.view';

  static propTypes = {
    timeSpent: PropTypes.number,
    formatter: PropTypes.func,
  };

  static defaultProps = {
    formatter: formatTimeNumericShort,
  };

  render() {
    const { timeSpent, formatter, ...props } = this.props;

    return <Text {...props}>{formatter(timeSpent)}</Text>;
  }
}
