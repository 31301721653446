import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import withUserController from 'dating-mobile/src/models/user/controllers';
import { Gender } from '@sdv/domain/user/gender';
import { CurrentScreen } from 'dating-mobile/src/services/functional/current-screen';
import { FEED } from 'dating-mobile/src/routing/router/constants';
import { PartialMatchSearch } from '@sdv/domain/users/partial-match-search';
import { Stock } from '@sdv/domain/payment/stock';
import UserTags from '@sdv/domain/user/tags';
import { UserTags as Tags } from '@sdv/domain/user/tags/user-tags';
import { BoostExtendNotification } from '@sdv/domain/user/profile-booster/boost-extend-notification';

import { BoostExtendBannerMan } from './boost-extend-man';
import { BoostExtendBannerWoman } from './boost-extend-woman';
import { PremiumSubscriptionBanner } from './premium-subscription';
import { PremiumSubscription70Banner } from './premium-subscription-70';
import { PremiumSubscriptionBannerSmall } from './premium-subscription-small';
import { BoostExtendBannerManSmall } from './boost-extend-man-small';

const BANNER_TYPE = {
  boost: 0,
  subscription: 1,
};

const BANNER_TYPE_LENGTH = Object.keys(BANNER_TYPE).length;

export const PurchasePromotionBanner = withUserController(
  class Controller extends PureComponent {
    static displayName = 'components.purchase-promotion-banner';

    static propTypes = {
      userId: PropTypes.string,
      user: PropTypes.object,
      hidePremiumSubscriptionBanner: PropTypes.bool,
      small: PropTypes.bool,
      hidden: PropTypes.bool,
    };

    state = {
      bannerType: BANNER_TYPE.boost,
      introductoryInvoice: null,
      discountUsed: false,
      isBoosted: false,
      isBoostPurchased: false,
      endTime: null,
      invoice: null,
    };

    componentDidMount() {
      this.subscribe();
    }

    componentDidUpdate(prevProps) {
      const { userId } = this.props;

      if (userId !== prevProps.userId) {
        this.unsubscribe();
        this.subscribe();
      }
    }

    componentWillUnmount() {
      this.unsubscribe();
    }

    subscribe() {
      const { userId } = this.props;

      if (!userId) {
        return;
      }

      const notification = BoostExtendNotification.shared(userId);

      this.disposeBag = new Subscription();

      this.disposeBag.add(
        CurrentScreen.shared().screen.subscribe(
          screen => {
            if (screen === FEED) {
              this.rotateBanner();
            }
          },
          () => {},
        ),
      );

      this.disposeBag.add(
        PartialMatchSearch.shared(
          userId,
        ).userFeedRepository.omitSubject.subscribe(
          () => {
            this.rotateBanner();
          },
          () => {},
        ),
      );

      this.disposeBag.add(
        Stock.shared(userId)
          .introductoryPackagesInStock()
          .subscribe(
            packs => {
              this.setState({
                introductoryInvoice: packs?.[0]?.[1]?.[0],
              });
            },
            () => {},
          ),
      );

      this.disposeBag.add(
        UserTags.shared()
          .tagsOf(userId)
          .pipe(
            map(tags => tags.includes(Tags.PremiumMembershipDiscount)),
            distinctUntilChanged(),
          )
          .subscribe(
            discountUsed => {
              this.setState({
                discountUsed,
              });
            },
            () => {},
          ),
      );

      this.disposeBag.add(
        notification.userIsBoosted.subscribe(isBoosted =>
          this.setState({ isBoosted }),
        ),
      );

      this.disposeBag.add(
        notification.discountEndTime.subscribe(endTime =>
          this.setState({ endTime }),
        ),
      );

      this.disposeBag.add(
        notification.discountInvoice.subscribe(invoice =>
          this.setState({ invoice }),
        ),
      );

      this.disposeBag.add(
        notification.isBoostPurchased.subscribe(isBoostPurchased =>
          this.setState({ isBoostPurchased }),
        ),
      );
    }

    unsubscribe() {
      if (this.disposeBag) {
        this.disposeBag.unsubscribe();
        this.disposeBag = null;
      }
    }

    rotateBanner() {
      const { bannerType } = this.state;

      if (bannerType < BANNER_TYPE_LENGTH - 1) {
        this.setState({
          bannerType: bannerType + 1,
        });
      } else {
        this.setState({
          bannerType: 0,
        });
      }
    }

    render() {
      const { user, userId, hidePremiumSubscriptionBanner, small } = this.props;
      const {
        introductoryInvoice,
        bannerType,
        discountUsed,
        isBoosted,
        endTime,
        invoice,
        isBoostPurchased,
      } = this.state;

      const shouldShowPremiumSubscriptionBanner =
        !discountUsed &&
        introductoryInvoice &&
        bannerType === BANNER_TYPE.subscription &&
        !hidePremiumSubscriptionBanner;

      let Component;

      if (small) {
        if (shouldShowPremiumSubscriptionBanner) {
          Component = PremiumSubscriptionBannerSmall;
        } else {
          Component = BoostExtendBannerManSmall;
        }
      } else if (user?.gender === Gender.Female) {
        Component = BoostExtendBannerWoman;
      } else if (shouldShowPremiumSubscriptionBanner) {
        if (introductoryInvoice?.discount === 70) {
          Component = PremiumSubscription70Banner;
        } else {
          Component = PremiumSubscriptionBanner;
        }
      } else {
        Component = BoostExtendBannerMan;
      }

      if (!userId || !user || !Component) {
        return null;
      }

      return (
        <Component
          {...this.props}
          boostIsActive={isBoosted}
          isBoostPurchased={isBoostPurchased}
          endTime={invoice ? endTime : null}
          invoice={invoice}
          introductoryInvoice={introductoryInvoice}
        />
      );
    }
  },
  { userIdPropName: 'userId' },
);
