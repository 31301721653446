import connect from '@sdv/connect';
import CallAnsweringModel from '../../../models/call.answering';
import qs from 'qs';

function getModels(flux, props) {
    const models = {};

    if (props.userId && props.attendeeId) {
        const modelId = qs.stringify({ user: props.userId, attendee: props.attendeeId });
        models.callAnswering = flux.get(CallAnsweringModel, modelId);
    }

    return models;
}

function mapStoresToProps(models) {
    return {};
}

function mapActionsToProps(models) {
    const props = {};

    if (models.callAnswering) {
        props.onNotificationPress = models.callAnswering.actions.answer;
    }

    return props;
}

export default connect(
    getModels,
    mapStoresToProps,
    mapActionsToProps
);

