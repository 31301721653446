import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({

    followButton: {
        alignItems: 'center',
        justifyContent: 'center',
        width: undefined,
        height: 30,
        borderRadius: 15,
        paddingLeft: 16,
        paddingRight: 16,
    },
    followButtonTitle: {
        textAlignVertical: 'center',
        fontSize: 14,
    },

});

export default styles;
