import React from 'react';
import { ScrollView, View } from 'react-native';
import PropTypes from 'prop-types';
import styles from './styles';
import TopicItem from './item';
import Resources from '../../../resources';
import Config from 'dating-mobile/src/models/config/model';
import flux from '@sdv/domain/app/flux';

export default class TopicsPicking extends React.PureComponent {
    static displayName = 'streaming.views.topics-picking.view';
    static propTypes = {
        topics: PropTypes.array,
        selectedTopics: PropTypes.array,
        onItemPress: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.topicsDisplayName = flux
            .get(Config)
            .store.getState()
            ['available-stream-topics'].reduce(
                (acc, topic) => ({
                    ...acc,
                    [topic]: Resources.strings['streams-topics-list'][topic],
                }),
                {},
            );
    }

    render() {
        return (
            <View style={styles.container}>
                {(this.props.topics || []).map(topic => {
                    const selected = this.props.selectedTopics.indexOf(topic) >= 0;
                    return (
                        <TopicItem
                            key={topic}
                            displayText={this.topicsDisplayName[topic]}
                            topic={topic}
                            selected={selected}
                            onPress={this.props.onItemPress}
                        />
                    );
                })}
            </View>
        );
    }
}
