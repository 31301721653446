import guid from 'uuid/v4';
import isPromise from 'is-promise';
import * as fn from '../functions';
import * as utils from '../utils/AltUtils';

export default function makeAction(
  alt,
  actionsName,
  namespace,
  name,
  implementation,
  obj,
) {
  const id = utils.uid(alt._actionsRegistry, `${namespace}.${name}`);

  alt._actionsRegistry[id] = 1;

  const data = { id, namespace, name, actionsName };

  // the action itself
  const action = (...args) => {
    const callId = guid();
    const finish =
      typeof args[args.length - 1] === 'function' ? args.pop() : () => {};

    if (alt.AP && alt.AP.wait) {
      alt.AP.wait();
    }

    (alt.eventsCallbacks['event.action.started'] || []).forEach(cb =>
      cb(callId, args, data),
    );

    const invocationResult = implementation.apply(obj, args);

    const dispatch = (payload, finishPayload) => {
      setTimeout(() => {
        alt.dispatch(id, payload, data);
        if (!invocationResult.optimist) {
          finish(finishPayload);
        }
        if (alt.AP && alt.AP.done) {
          alt.AP.done();
        }
        (alt.eventsCallbacks['event.action.ended'] || []).forEach(cb =>
          cb(callId, { payload, finishPayload }, data),
        );
      }, 0);
    };
    // console.error(`Action: ${obj.displayName} ${implementation.name} called with data `, args)
    let actionResult = invocationResult;

    // async functions that return promises should not be dispatched
    if (invocationResult !== undefined && !isPromise(invocationResult)) {
      if (fn.isFunction(invocationResult)) {
        // inner function result should be returned as an action result
        actionResult = invocationResult(
          dispatch,
          alt,
          invocationResult.optimist ? finish : undefined,
        );
      } else {
        dispatch(invocationResult);
      }
    }

    if (invocationResult === undefined) {
      utils.warn('An action was called but nothing was dispatched');
    }

    return actionResult;
  };

  action.defer = (...args) => setTimeout(() => action.apply(null, args));
  action.id = id;
  action.data = data;

  // ensure each reference is unique in the namespace
  const container = alt.actions[actionsName][namespace];
  const namespaceId = utils.uid(container, name);

  container[namespaceId] = action;

  // generate a constant
  const constant = utils.formatAsConstant(namespaceId);

  container[constant] = id;

  return action;
}
