/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
let activePlayer = null;
let finishedPlayingListener = null;

async function handlePlayer(...args) {
  if (activePlayer) {
    await activePlayer.stop();
    createPlayer(...args);
  } else {
    createPlayer(...args);
  }
}

function createPlayer(url, loop = false) {
  activePlayer = new window.Audio(url);
  activePlayer.loop = loop;
}

export default {
  playSoundFile: async (name, type, loop) => {
    await handlePlayer(
      require(`dating-mobile/public/audio/${name}.${type}`),
      loop,
    );

    activePlayer.play();
  },

  playUrl: url => {
    const player = createPlayer(url);

    player.play();
  },

  onFinishedPlaying: callback => {
    if (activePlayer) {
      activePlayer.addEventListener('ended', callback);
      finishedPlayingListener = callback;
    }
  },

  unmount: () => {
    if (activePlayer) {
      activePlayer.removeEventListener('ended', finishedPlayingListener);
      finishedPlayingListener = null;
    }
  },

  pause: () => {
    if (activePlayer) {
      activePlayer.pause();
    }
  },

  resume: () => {
    if (activePlayer) {
      activePlayer.pause();
    }
  },

  stop: () => {
    if (activePlayer) {
      activePlayer.pause();
      activePlayer.currentTime = 0;
    }
  },
};
