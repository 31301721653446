import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Resources from 'dating-mobile/src/resources';
import NavigationItem from 'dating-mobile/src/components/navigation-item';
import AuthenticateButton from '../authenticate-navigation-button';

import styles from './styles';

class AuthenticateNavigationItem extends PureComponent {
    static displayName = 'user.views.authenticate-navigation-item.view';
    static propTypes = {
        isAnonymous: PropTypes.bool,
        onPress: PropTypes.func,
    };

    renderButton = () => {
        return <AuthenticateButton />
    };

    render() {
        const { isAnonymous } = this.props;

        return isAnonymous ? (
            <NavigationItem
                iconStyle={styles.icon}
                iconContainerStyle={styles.iconContainer}
                titleStyle={styles.title}
                title={Resources.strings['account-screen-sign-up-title']}
                hint={Resources.strings['account-screen-complete-registration-hint']}
                ValueComponent={this.renderButton}
                icon={Resources.images.authenticationCTAIcon()}
                onPress={this.props.onPress}
            />
        ) : null;

    }

}

export default AuthenticateNavigationItem;

