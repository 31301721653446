import React from 'react';
import PropTypes from 'prop-types';
import { SafeAreaView, StatusBar, ScrollView, BackHandler } from 'react-native';

import User from '../../views/edit-complex';
import identityId from '../../../../models/identity/controller/id';
import styles from './styles';

class Complex extends React.Component {
  static displayName = 'user.edit.complex';

  static propTypes = {
    navigation: PropTypes.object.isRequired,
    id: PropTypes.string,
  };

  componentDidMount() {
    this.backButtonHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      () => {
        return true;
      },
    );
  }

  componentWillUnmount() {
    this.backButtonHandler.remove();
  }

  send = () => {
    const { navigation } = this.props;
    const onComplete =
      navigation.getParam('onComplete') || (() => navigation.pop());

    onComplete();
  };

  render() {
    const { navigation, id } = this.props;

    return (
      <SafeAreaView style={[styles.safeArea, styles.safeAreaComplex]}>
        <StatusBar
          barStyle={styles.$statusBarStyle}
          backgroundColor={styles.$statusBarBackgroundColorComplex}
        />
        <ScrollView
          horizontal={false}
          style={styles.scroll}
          contentContainerStyle={styles.scrollContent}
          alwaysBounceVertical={false}
          keyboardShouldPersistTaps="handled"
        >
          <User id={id} navigation={navigation} onSubmit={this.send} />
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const ComplexScreen = identityId(Complex, 'id');

ComplexScreen.navigationOptions = ({ navigation }) => ({
  title: navigation.getParam('title'),
  headerLeft: () => null,
  headerRight: () => null,
});

export default ComplexScreen;
