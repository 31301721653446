import React from 'react';
import PropTypes from 'prop-types';
import { withNavigation } from 'react-navigation';
import Resources from 'dating-mobile/src/resources';
import { TERMS } from 'dating-mobile/src/routing/router/constants';

export default function bindNavigation(Component) {
  class Navigation extends React.Component {
    static displayName = 'components.terms-and-privacy-text.navigation';

    static propTypes = {
      navigation: PropTypes.object.isRequired,
      termsLink: PropTypes.string,
      privacyPolicyLink: PropTypes.string,
      useLocalizedLinks: PropTypes.bool,
      halfWidth: PropTypes.bool,
    };

    open = (title, link) => {
      const { halfWidth, navigation } = this.props;

      navigation.navigate(TERMS, {
        title,
        uri: link,
        halfWidth,
      });
    };

    onTermsPressed = () => {
      const { useLocalizedLinks, termsLink } = this.props;

      const actualTermsLink =
        (useLocalizedLinks && termsLink && Resources.strings[termsLink]) ||
        termsLink ||
        '';

      this.open(Resources.strings.terms, actualTermsLink);
    };

    onPrivacyPolicyPressed = () => {
      const { useLocalizedLinks, privacyPolicyLink } = this.props;

      const actualPrivacyPolicyLink =
        (useLocalizedLinks &&
          privacyPolicyLink &&
          Resources.strings[privacyPolicyLink]) ||
        privacyPolicyLink ||
        '';

      this.open(Resources.strings['privacy-policy'], actualPrivacyPolicyLink);
    };

    render() {
      return (
        <Component
          {...this.props}
          onTermsPress={this.onTermsPressed}
          onPrivacyPolicyPress={this.onPrivacyPolicyPressed}
        />
      );
    }
  }

  return withNavigation(Navigation);
}
