import UserDefaults from 'react-native-default-preference';
import * as Keychain from 'react-native-keychain';
import { Platform } from 'react-native';

const OLD_ANDROID_TOKEN_KEY = 'auth_data';
const OLD_ANDROID_SHARED_PREF_NAME = 'sdv_cp';
const TOKEN_KEY = 'user-token';
const TRANSFER_TOKEN_KEY = 'transfer-user-token';

async function setToken(token) {
  // await UserDefaults.set(TRANSFER_TOKEN_KEY, token);

  return Keychain.setGenericPassword(TOKEN_KEY, token);
}

async function getToken() {
  if (Platform.OS === 'ios') {
    const transferToken = await UserDefaults.get(TRANSFER_TOKEN_KEY);

    if (transferToken) {
      await setToken(transferToken);
      await UserDefaults.clear(TRANSFER_TOKEN_KEY);
    }
  } else if (Platform.OS === 'android') {
    const authDataJson = await UserDefaults.getFromCustomStore(
      OLD_ANDROID_SHARED_PREF_NAME,
      OLD_ANDROID_TOKEN_KEY,
    );

    if (authDataJson) {
      try {
        const authData = JSON.parse(authDataJson);

        if (authData.token) {
          const oldToken = authData.token;

          await setToken(oldToken);
          await UserDefaults.clearFromCustomStore(
            OLD_ANDROID_SHARED_PREF_NAME,
            OLD_ANDROID_TOKEN_KEY,
          );
        }
      } catch (e) {
        // not a json
      }
    }
  }

  const credentials = await Keychain.getGenericPassword();
  const token = credentials && credentials.password;

  // if (token) {
  //   if (Platform.OS === 'ios') {
  //     const transferToken = await UserDefaults.get(TRANSFER_TOKEN_KEY);
  //
  //     if (!transferToken) {
  //       await UserDefaults.set(TRANSFER_TOKEN_KEY, token);
  //     }
  //   }
  // }

  return token;
}

async function clearToken() {
  // await UserDefaults.clear(TRANSFER_TOKEN_KEY);

  return Keychain.resetGenericPassword();
}

export default {
  setToken,
  getToken,
  clearToken,
};
