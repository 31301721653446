import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet } from 'react-native';

import ImageButton from 'dating-mobile/src/components/navigation/views/image-button';
import Resources from 'dating-mobile/src/resources';
import identityController, {
  UNAUTHORIZED,
} from 'dating-mobile/src/models/identity/controller';
import authController from 'dating-mobile/src/models/identity/controller/auth';
import { SetNewPassword } from 'dating-mobile/src/authentication/views/set-new-password';
import { PASSWORD_RECOVER } from 'dating-mobile/src/routing/router/constants';

import createScreen from './hoc';
import styles from './styles';

const SetNewPasswordComponent = authController(SetNewPassword);

class SetNewPasswordScreenComponent extends PureComponent {
  static displayName = 'authentication.screens.identity.set-new-password';

  static propTypes = {
    auth: PropTypes.func.isRequired,
    updatePassword: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    signIn: PropTypes.func.isRequired,
    navigation: PropTypes.object.isRequired,
    error: PropTypes.object,
  };

  state = {
    expired: false,
  };

  setNewPassword = async (displayName, { password }) => {
    try {
      const { auth, updatePassword, logout, navigation, signIn } = this.props;
      const token = navigation.getParam('token');
      const email = navigation.getParam('email');

      await auth(token);
      await updatePassword(password);
      await logout();
      await signIn(email, password);
    } catch (error) {
      if (error.status === UNAUTHORIZED) {
        this.setState({ expired: true });
      }
    }
  };

  goToPasswordRecover = () => {
    const { navigation } = this.props;

    navigation.replace(PASSWORD_RECOVER);
  };

  render() {
    const { error } = this.props; // TODO: Show loader using isLoading prop
    const { expired } = this.state;

    return (
      <SetNewPasswordComponent
        expired={expired}
        error={error}
        onSubmit={this.setNewPassword}
        goToPasswordRecover={this.goToPasswordRecover}
      />
    );
  }
}

const SetNewPasswordScreen = createScreen(
  identityController(SetNewPasswordScreenComponent),
);

SetNewPasswordScreen.navigationOptions = () => ({
  headerStyle: styles.header,
  headerBackImage: () => (
    <ImageButton
      image={Resources.images.backIcon()}
      tint={StyleSheet.flatten(styles.tint).color}
    />
  ),
});

export { SetNewPasswordScreen };
