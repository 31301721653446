import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
    View,
    Text,
    ScrollView,
    Platform,
    KeyboardAvoidingView,
    TouchableOpacity,
    Image,
} from 'react-native';

import TextInput from 'dating-mobile/src/components/text-input';
import Resources from 'dating-mobile/src/resources';
import testId from 'dating-mobile/src/utils/test-id';
import Button from 'dating-mobile/src/components/buttons/base';

import styles from './styles';

export default class ScreenView extends PureComponent {
    static displayName = 'stepped-registration.screens.password.view';

    static propTypes = {
        validate: PropTypes.func.isRequired,
        handlerChange: PropTypes.func.isRequired,
        onSubmit: PropTypes.func,
        password: PropTypes.string,
        error: PropTypes.string,
    };

    state = {
        passwordVisible: false,
    };

    constructor(props) {
        super(props);

        this.validate = props.validate('password');
        this.handlerChange = props.handlerChange('password');
    }

    togglePasswordVisibility = () => {
        this.setState(prevState => ({
            passwordVisible: !prevState.passwordVisible,
        }));
    };

    render() {
        const { error = {}, password, onSubmit } = this.props;
        const { passwordVisible } = this.state;
        const validationError = error.password || error.server;
        const inputStyle = validationError ? [styles.input, styles.inputError] : styles.input;
        const visibilityIndicatorStyle = validationError
            ? [styles.visibilityIndicator, styles.visibilityIndicatorError]
            : styles.visibilityIndicator;

        return (
            <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : undefined}>
                <ScrollView
                    keyboardShouldPersistTaps="handled"
                    alwaysBounceVertical={false}
                    style={styles.container}
                    contentContainerStyle={styles.contentContainer}
                >
                    <View>
                        <Text style={styles.subTitle}>
                            {Resources.strings['onboarding-screen-title']}
                        </Text>
                        <Text style={styles.title}>
                            {Resources.strings['onboarding-screen-password-title']}
                        </Text>
                        <View>
                            <TextInput
                                style={inputStyle}
                                containerStyle={styles.containerInput}
                                placeholder={
                                    Resources.strings['onboarding-screen-password-placeholder']
                                }
                                value={password}
                                autoCapitalize="none"
                                autoFocus
                                secureTextEntry={!passwordVisible}
                                textContentType="newPassword"
                                returnKeyType="next"
                                onChange={this.handlerChange}
                                onBlur={this.validate}
                                error={validationError}
                                onSubmitEditing={onSubmit}
                                {...testId('Password input')}
                            />
                            <TouchableOpacity
                                style={visibilityIndicatorStyle}
                                onPress={this.togglePasswordVisibility}
                            >
                                <Image
                                    source={
                                        passwordVisible
                                            ? Resources.images.eyeOpened()
                                            : Resources.images.eyeClosed()
                                    }
                                />
                            </TouchableOpacity>
                        </View>
                        {!!validationError && <Text style={styles.hint}>{validationError}</Text>}
                    </View>
                    <Button
                        disabled={!password}
                        title={Resources.strings.next}
                        onPress={onSubmit}
                        style={styles.button}
                        {...testId('Next button')}
                    />
                </ScrollView>
            </KeyboardAvoidingView>
        );
    }
}
