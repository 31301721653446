import async from 'async';
import date from '@sdv/domain/api/date';
import session from './session';
import getSource from './getSource';
import Network from './channel/decorators/network';

import { diff, mixin } from '../../../utils/object';

const oppositeMap = {
  in: 'out',
  out: 'in',
};

function MediaChat(attendee, api, config, owner, bus) {
  this.bus = bus;
  this.attendee = attendee;
  this.network = new Network(attendee, config, api, this.bus);
  this._prepare();

  this.bus.emit('event.chat.media.created', this.attendee, this);

  // после переноса модуля в орбиту добавлены
  this.userId = owner;
  this.api = api;
  this.date = date(api);
  this.source = getSource(config);
  this.config = config?.media || {};
}

MediaChat.prototype = {
  _unsyncked() {
    return (
      !this.network ||
      this.network.isEstablishing() ||
      this.remote === undefined ||
      this.remote.revision !== this._revision ||
      this.state.in !== this.remote.out ||
      this.state.out !== this.remote.in ||
      this.pending.in ||
      this.pending.out
    );
  },

  _userSet(direction, state) {
    const opposite = oppositeMap[direction];

    this.state[direction] = state && this.remote && this.remote[opposite];
    this.pending[direction] = state && !this.state[direction];
    this.transition[direction] = false;
  },

  _iterateSyncLoop() {
    if (this._nextReqTimeout) {
      clearTimeout(this._nextReqTimeout);
    }

    this._nextReqTimeout = setTimeout(() => {
      !this._unsyncked() ? this._request() : this._iterateSyncLoop();
    }, this.config.interval);
  },

  _request(cta) {
    const message = {
      'sender-id': this.userId,
      'recipient-id': this.attendee,
      revision: this._revision,
    };

    message.phantom = this.phantom.out;
    message.audio = this.audio;
    message.cta = cta;
    message.in = this.state.in || this.pending.in;
    message.out = this.state.out || this.pending.out;

    this._iterateSyncLoop();

    if (this._unsyncked()) {
      message.resync = 1;
    }

    if (this.isClosingMessage(message) || !this.network.isEstablishing()) {
      this.network.send(message);
      this._updateTimeout();
    }

    if (
      !this.state.in &&
      !this.state.out &&
      !this.pending.in &&
      !this.pending.out
    ) {
      this._close();
    }
  },

  _updateTimeout() {
    if (this._timeout) {
      this._timeout = clearTimeout(this._timeout);
    }

    if (this.config.timeout) {
      this._timeout = setTimeout(() => {
        this.stop();
      }, this.config.timeout);
    }
  },

  _prepare() {
    if (this.countDown && this.countDown.in) {
      clearTimeout(this.countDown.in);
    }

    if (this.countDown && this.countDown.out) {
      clearTimeout(this.countDown.out);
    }

    if (this._timeout) {
      this._timeout = clearTimeout(this._timeout);
    }

    this.state = { in: null, out: null };
    this.pending = { in: null, out: null };
    this.transition = { in: null, out: null };
    this.hangup = { in: null, out: null };
    this.countDown = { in: null, out: null };
    this.phantom = { in: false, out: false };
    this.remote = undefined;
    this.audio = false;
    this.camAvailable = false;
    this._revision = 0;

    if (this._nextReqTimeout) {
      clearTimeout(this._nextReqTimeout);
    }

    this._nextReqTimeout = null;
  },

  _close() {
    this.bus.emit('event.chat.media.destroyed', this.attendee);

    // TODO: отрефакторить работу с audio-дорожками в задаче по включению звука в видеочате
    this.source.toggleAudio(true);

    this._prepare();
    this._emitChanges();

    session.clear();

    if (this.network) {
      if (!this.remote && this.network.isEstablishing()) {
        this.bus.emit('event.chat.media.calling.failed', this.attendee);
      }

      this.network.close();
      this.network = null;
    }
  },

  _emitChanges() {
    if (!this.phantom.in) {
      this.bus.emit('event.chat.media.state.changed', this.getState());
    }
  },

  _setOptions(options) {
    if (typeof options.in !== 'undefined') {
      this._userSet('in', !!options.in);
    }

    if (typeof options.out !== 'undefined') {
      this._userSet('out', !!options.out);
    }

    if (options.audio) {
      this.audio = options.audio;
    }

    if (options.phantom) {
      this.phantom.out = options.phantom;
    }

    this._emitChanges();

    if (this.isClosingMessage({}) || !this.network.isEstablishing()) {
      this._revision++;
      this._request(options.cta);
    }
  },

  // TODO: Remove audioOnly default option
  _getSource(options = { audioOnly: true }) {
    const { source } = this;

    return new Promise((resolve, reject) => {
      const getMediaSource = repeat => {
        source
          .get({
            video: options.audioOnly ? false : undefined,
            audio: options.audioOnly ? true : undefined,
          })
          .then(() => {
            source.close();

            if (this.network) {
              resolve();
            }
          })
          .catch(error => {
            let state = 'busy';

            if (!this.network) {
              return;
            }

            if (error && error.name === 'timeout') {
              reject(error);

              return;
            }

            if (error && error.name === 'NotAllowedError') {
              state = 'blocked';
            }

            if (repeat) {
              reject(error);

              return;
            }

            this.bus.once('event.overlay.call-permissions-ignored', () => {
              getMediaSource(true);
            });

            if (error.name === 'SecurityError') {
              this.bus.emit(
                'overlay',
                options.audioOnly ? 'microphone-blocked' : 'camera-blocked',
                state,
              );
            }
          });
      };

      getMediaSource();
    });
  },

  _updatePresence(callback) {
    const id = this.attendee;
    const { api } = this;

    async.parallel(
      {
        presence(next) {
          api.presence.get(id, next);
        },

        user(next) {
          api.users.get(id, next);
        },
      },
      (error, response) => {
        const { presence } = response;
        const { user } = response;
        let cam = false;

        if (!this.network) {
          return;
        }

        presence?.devices?.forEach(device => {
          if (device.name === 'cam') {
            cam = true;
          }
        });

        if (
          !presence?.online &&
          (user.tags || []).indexOf('presence.mobileapp') > -1
        ) {
          cam = true;
        }

        if (!cam) {
          this._close();
        }

        // Важно, чтобы актуализация устройств шла после завершения видеочата, т.к. в актуализации проверяется текущее состояние видеочата.
        this.bus.emit('event.presence.updated', this.attendee, presence);

        if (!cam) {
          return;
        }

        callback();
      },
    );
  },

  addMessage(message) {
    if (
      this._revision !== undefined &&
      message.revision !== null &&
      this._revision > message.revision
    ) {
      return;
    }

    this._revision =
      ((message.in || message.out) && message.revision) || this._revision;

    if (this.isClosingMessage(message)) {
      this._close();

      return;
    }

    this.network._process(message);
    this._process(message);
  },

  answer(message) {
    this.bus.emit('event.chat.media.answered', this.attendee);

    if (message.phantom) {
      this.phantom.in = true;
    }

    if (message.out) {
      this.pending.in = true;
    }

    if (message.in) {
      this.pending.out = true;
    }

    this._emitChanges();

    session.setAnswer(message.network.session);

    // TODO: Pass options
    this._getSource()
      .then(() => {
        this.camAvailable = true;
        this.addMessage(message);
      })
      .catch(() => {
        this.pending.out = false;
        message.in = 0;
        this.addMessage(message);
      });
  },

  start(options) {
    const start = () => {
      this._getSource(options)
        .then(() => {
          this.camAvailable = true;
          this._setOptions(options);
        })
        .catch(() => {
          this.pending.out = false;
          options.out = 0;
          this._setOptions(options);
        });
    };

    this.bus.emit('event.chat.media.started', this.attendee);

    session.new();

    if (options.out) {
      this.pending.out = true;
    }

    if (options.in) {
      this.pending.in = true;
    }

    this._emitChanges();

    if (options.in) {
      this._updatePresence(start);
    } else {
      start();
    }
  },

  stop(options) {
    this._setOptions(mixin(options, { in: 0, out: 0 }));
  },

  decline(message) {
    this._revision =
      ((message.in || message.out) && message.revision) || this._revision;

    if (message.phantom) {
      this.phantom.in = true;
    }

    session.setAnswer(message.network.session);

    this.remote = message;

    if (typeof message.out === 'boolean') {
      this._update('in', message.out);
    }

    if (typeof message.in === 'boolean') {
      this._update('out', message.in);
    }

    this.stop({ cta: 'decline' });
  },

  isClosingMessage(message) {
    if (
      (typeof message.in !== 'boolean' &&
        (this.state.out || this.pending.out)) ||
      (typeof message.out !== 'boolean' && (this.state.in || this.pending.in))
    ) {
      return false;
    }

    if (typeof message.in === 'boolean' && message.in) {
      return false;
    }

    if (typeof message.out === 'boolean' && message.out) {
      return false;
    }

    return true;
  },

  resync() {
    this._request();
  },

  _update(direction, remote) {
    this.transition[direction] =
      remote && !this.state[direction] && !this.pending[direction];
    this.state[direction] =
      remote && (this.state[direction] || this.pending[direction]);
    this.pending[direction] = false;
  },

  _updateHangup(direction, remote) {
    const { hangup } = this.config;
    const remoteHangup = remote && remote[`hangup-${direction}`];

    if (
      !remoteHangup &&
      !this.hangup[direction] &&
      !this.countDown[direction] &&
      remote[oppositeMap[direction]] &&
      hangup
    ) {
      this.countDown[direction] = setTimeout(() => {
        const state = {
          cta: 'hangup',
        };

        state[direction] = false;

        this._setOptions(state);
      }, hangup);
    }

    if (!remoteHangup || remoteHangup === this.hangup[direction]) {
      return;
    }

    this.hangup[direction] = remoteHangup;

    if (this.countDown[direction]) {
      clearTimeout(this.countDown[direction]);
    }

    this.date.now(serverDate => {
      if (!this.network) {
        return;
      }

      const tempInterval = Math.max(
        new Date(this.hangup[direction]).getTime() - serverDate,
        0,
      );

      this.countDown[direction] = setTimeout(
        () => {
          const state = {
            cta: 'hangup',
          };

          state[direction] = false;
          this._setOptions(state);
        },
        tempInterval > 0 ? tempInterval : 0,
      );
    });
  },

  getState() {
    return {
      state: { in: this.state.in, out: this.state.out },
      pending: { in: this.pending.in, out: this.pending.out },
      transition: { in: this.transition.in, out: this.transition.out },
      phantom: { in: this.phantom.in, out: this.phantom.out },
      attendee: this.attendee,
      camAvailable: this.camAvailable,
    };
  },

  toggleVideo(enable) {
    this.source.toggleVideo(enable);
    this._setOptions({ out: enable });
  },

  toggleAudio(enable) {
    this.source.toggleAudio(enable);
  },

  rotateVideo() {
    this.source.rotateVideo();
  },

  _process(remote) {
    this.remote = remote;
    this.state.in = !!this.state.in;
    this.state.out = !!this.state.out;
    this.pending.in = !!this.pending.in;
    this.pending.out = !!this.pending.out;

    const clone = this.getState();

    if (remote.out === true && this.state.in !== true) {
      this.pending.in = true;
    }

    if (typeof remote.out === 'boolean') {
      this._update('in', remote.out);
    }

    if (typeof remote.in === 'boolean') {
      this._update('out', remote.in);
    }

    if (diff(this.state, clone.state) || diff(this.pending, clone.pending)) {
      this._emitChanges();
    }

    if (this.isClosingMessage(remote)) {
      this._close();

      return;
    }

    if (remote.in || remote.out) {
      this._updateTimeout();
    }

    // this._updateHangup('in', remote);
    // this._updateHangup('out', remote);

    if (remote.resync && !this.network.isEstablishing()) {
      this._request();
    }
  },
};

MediaChat.prototype.constructor = MediaChat;

export default MediaChat;
