import React from 'react';
import PropTypes from 'prop-types';
import withWink from 'dating-mobile/src/models/dialogs.wink/controller/wink';

export default function createWinkController(Component, options = {}) {
    const attendeePropName = options.attendeeIdPropName || 'attendeeId';
    const winkDelayPropName = options.winkDelayPropName || 'winkDelay';

    class Winker extends React.PureComponent {
        static displayName = 'dialogs.messages.winker';

        static propTypes = {
            forwardedRef: PropTypes.func,
            wink: PropTypes.func,
            winkEnabled: PropTypes.bool,
        };

        componentDidMount() {
            const { winkEnabled } = this.props;

            if (winkEnabled) {
                this.scheduleWink();
            }
        }

        componentWillUnmount() {
            this.unscheduleWink();
        }

        scheduleWink() {
            const { [winkDelayPropName]: winkDelay } = this.props;

            this.winkTimeout = setTimeout(() => {
                const { wink, [attendeePropName]: attendee } = this.props;

                if (wink) {
                    wink(attendee, 'dialogs.contemplated');
                }
            }, winkDelay);
        }

        unscheduleWink() {
            if (this.winkTimeout) {
                clearTimeout(this.winkTimeout);
                this.winkTimeout = null;
            }
        }

        render() {
            const { forwardedRef, ...props } = this.props;

            return <Component {...props} ref={forwardedRef} onMessageShow={this.onMessageShown} />;
        }
    }

    const WinkingComponent = withWink(Winker, options);

    return React.forwardRef((props, ref) => {
        return <WinkingComponent {...props} forwardedRef={ref} />;
    });
}
