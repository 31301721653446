import Identity from '@sdv/domain/identity/model';
import UserTags from '@sdv/domain/user/tags/personal';
import Config from 'dating-mobile/src/models/config/model';
import EventEmitter from 'eventemitter3';
import guid from '@sdv/commons/utils/guid';
import WebSocketConnection from '../websocket-connection';

const FALLBACK_HOST = 'wss://media.dating.com';

export const StreamConnectionStateChangedEventKey = 'stream.connection.state.changed';

const isPromoter = state =>
    state.tags ? state.tags.indexOf('dialogs.streams.promoter') >= 0 : undefined;

class WebsocketConnection {
    isOpened = false;

    identity = null;

    sendingQueue = [];

    onMessageReceived;

    connectionStateWatchers = [];

    constructor(flux) {
        this.flux = flux;
        this.api = flux.api;
        this.ws = null;
        this.config = flux.get(Config);
        this.config.store.listen(this.onConfigUpdated);
        this.onConfigUpdated(this.config.store.getState());
        this.identity = flux.get(Identity);
        this.identity.store.listen(this.onIdentityUpdated);
        this.onIdentityUpdated(this.identity.store.getState());
    }

    onConfigUpdated = state => {
        const { streaming } = state.endpoints || {};

        if (this.endpointFormat !== streaming) {
            this.endpointFormat = streaming;
            this.reconnect();
        }
    };

    onIdentityUpdated = state => {
        if (this.identity !== state.id) {
            this.identity = state.id;

            if (this.userTags) {
                this.userTags.store.unlisten(this.onUserTagsUpdated);
            }

            this.userTags = null;

            if (!this.identity) {
                this.isPromoter = undefined;
                this.reconnect();

                return;
            }

            this.userTags = this.flux.get(UserTags, this.identity);
            this.userTags.store.listen(this.onUserTagsUpdated);
            this.userTags.actions.get();
            this.isPromoter = isPromoter(this.userTags.store.getState());
            this.reconnect();
        }
    };

    onUserTagsUpdated = state => {
        const promoter = isPromoter(state);

        if (this.isPromoter !== promoter) {
            this.isPromoter = promoter;
            this.reconnect();
        }
    };

    static urlBuilder(endpointFormat, user, promo) {
        if (!user || typeof promo === 'undefined' || !endpointFormat) {
            return null;
        }

        return (key, shard) => {
            let shardedHost = FALLBACK_HOST;

            if (shard && key) {
                shardedHost = endpointFormat.replace('{shard}', shard).replace('{key}', key);
            }

            return `${shardedHost}?i=${user}&promo=${promo ? 1 : 0}`;
        };
    }

    reconnect() {
        if (this.isOpened) {
            this.close();
        }

        const urlBuilder = WebsocketConnection.urlBuilder(
            this.endpointFormat,
            this.identity,
            this.isPromoter,
        );

        if (!urlBuilder) {
            return;
        }

        this.ws = new WebSocketConnection(urlBuilder, this.flux, this.api.getUserAgent());
        this.ws.messages.addListener(e => {
            if (this.onMessageReceived) {
                this.onMessageReceived(e.data);
            }
        });
        this.ws.onOpen = () => {
            this.isOpened = true;
            this.connectionStateWatchers.map(callback => callback(true));
            this.resend();
        };
        this.ws.onClose = () => {
            this.isOpened = false;
            this.connectionStateWatchers.map(callback => callback(false));
        };
    }

    close() {
        this.isOpened = false;
        this.connectionStateWatchers.map(callback => callback(false));

        if (this.ws) {
            this.ws.close();
            this.ws = null;
        }
    }

    send = json => {
        if (!this.ws || !this.ws.send(json)) {
            this.sendingQueue.push(json);
        }
    };

    resend = () => {
        this.sendingQueue.forEach(json => {
            this.send(json);
        });
        this.sendingQueue = [];
    };

    addConnectionStateListener(callback) {
        this.connectionStateWatchers.push(callback);
        callback(this.isOpened);
    }
}

class StreamConnection extends EventEmitter {
    constructor(flux) {
        super();
        this.callbacks = {};
        this.websocket = new WebsocketConnection(flux);
        this.websocket.addConnectionStateListener(isOpened => {
            this.emit(StreamConnectionStateChangedEventKey, isOpened);
        });
        this.websocket.onMessageReceived = data => {
            const json = JSON.parse(data);

            // handle request replies
            if (json.label === 'event.dialogs.streams.reply') {
                const reply = json?.payload?.reply;
                const error = json?.payload?.error;
                const callback = this.callbacks[json.guid];

                if (error) {
                    if (callback && callback.error) {
                        callback.error(error);
                    }
                } else if (callback && callback.success) {
                    callback.success(reply);
                }

                delete this.callbacks[json.guid];
            }
            // handle notifications
            else if (json.label === 'event.dialogs.streams.notification') {
                this.emit(
                    'event.dialogs.streams.notification',
                    json.payload.session,
                    json.payload.notification,
                );
            }
            // emit all other   events;
            else {
                this.emit(json.label, json);
            }
        };
    }

    notify = (label, payload) => {
        const message = {
            label,
            payload,
        };
        const json = JSON.stringify(message);

        this.websocket.send(json);
    };

    sendRequest(label, payload, success, failure) {
        const id = guid();

        this.callbacks[id] = { success, failure };

        const message = {
            label,
            payload,
            guid: id,
        };

        const json = JSON.stringify(message);

        this.websocket.send(json);
    }
}

export default StreamConnection;
