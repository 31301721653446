import React from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import styles from './styles';
import UserVipMemberBadge from './user-vip-badge';
import UserConversationBadge from './user-conversations-badge';

export default class UserBadges extends React.PureComponent {
  static displayName = 'user.common.views.user-badges.view';

  static propTypes = {
    userId: PropTypes.string,
  };

  render() {
    const { userId } = this.props;

    return (
      <View style={styles.container}>
        <UserVipMemberBadge style={styles.badge} userId={userId} />
        <UserConversationBadge style={styles.badge} userId={userId} />
      </View>
    );
  }
}
