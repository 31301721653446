import { cloneNotNull } from '@sdv/commons/utils/object';
import parseUserId from '@sdv/domain/user/user-id';
import url from 'url';

export default class LetterRepo {

    constructor(flux) {
        this.api = flux.api;
    }

    getChains(params, callback) {
        const query = Object.assign({}, cloneNotNull(params));
        this.api.users.events.get(query, (error, result) => {
            if (!error) {
                if (result) {
                    const chains = [];
                    for (let chain of result) {
                        chains.push(mapJsonToChain(chain));
                        callback(chains);
                    }

                } else {
                    callback([]);
                }
            } else {
                callback(null, error);
            }
        });
    }

    deleteChain(userId, eventId, callback = () => {
    }) {
        this.api.users.events.delete(userId, eventId, (error) => {
            if (error) {
                callback(null, error);
            }

            callback(eventId);
        });
    }
}

export function mapJsonToLetter(json) {
    const meta = updateMeta(json['meta'] || []);
    return {
        id: json['id'],
        sender: parseUserId(json['sender-id']),
        recipient: parseUserId(json['recipient-id']),
        introductory: json['introductory'],
        subject: json['subject'],
        text: json['text'],
        tag: json['tag'],
        timestamp: json['timestamp'],
        cover: json['cover'],
        meta: meta,
        replied: json['replied'] === 'replied'
    };
};

function updateMeta(meta) {
    const oldAttachments = meta['attachments'] || [];
    const newAttachments = [];
    for (let attachment of oldAttachments) {
        if (attachment.basename && attachment.mediatype) {
            newAttachments.push(attachment);
        } else if (attachment['base-name'] && attachment['media-type']) {
            newAttachments.push({
                basename: attachment['base-name'],
                mediatype: attachment['media-type']
            });
        } else {
            const attachmentDetails = url.parse(attachment);
            let mediaType = '';
            switch (attachmentDetails && attachmentDetails.protocol) {
                case 'photo:':
                    mediaType = 'image/*';
                    break;
                case 'video:':
                    mediaType = 'video/*';
                    break;
            }
            const basename = attachmentDetails && attachmentDetails.host || '';
            newAttachments.push({
                basename: basename,
                mediatype: mediaType
            });
        }
    }

    delete meta['attachments'];

    return {
        ...meta,
        attachments: newAttachments
    };
}

export function mapJsonToChain(chain) {
    const letter = mapJsonToLetter(chain.payload);
    return {
        chainId: chain['event-id'],
        attendeeId: parseUserId(chain['user-id']),
        unreadCount: chain['fresh'],
        totalCount: chain['total'],
        headerLetter: letter
    };
};

