import React from 'react';
import { Text } from 'react-native';
import PropTypes from 'prop-types';

export default class View extends React.PureComponent {
    static displayName = 'user.common.views.user-about-me-text.view';
    static propTypes = {
        user: PropTypes.shape({
            about: PropTypes.string
        })
    };

    render() {
        return (
            <Text {...this.props}>
                {this.props.user.about}
            </Text>
        );
    }
}
