import Actions from './actions';
import { BaseStore } from '@sdv/model';
import MediaUploader from '../../dialogs.media-uploader';
import qs from 'qs';
import LetterCoverModel from '../letter-cover';
import { randomValueFromArray } from 'dating-mobile/src/utils/random';

export default function createStore(id) {
    const { senderId, recipientId, letterTag } = qs.parse(id);

    class Store extends BaseStore {

        constructor(flux) {
            super();

            this.flux = flux;

            const actions = flux.getActions(Actions, id);
            this.bindAction(actions.setText, this.setText);
            this.bindAction(actions.setSubject, this.setSubject);
            this.bindAction(actions.attach, this.attach);
            this.bindAction(actions.detach, this.detach);

            const letterCoverModel = flux.get(LetterCoverModel);
            const cover = randomValueFromArray(letterCoverModel.store.getState().covers);

            this.state = {
                subject: "",
                text: "",
                cover: cover,
                attachments: [],
                letterTag: letterTag
            };
        }

        setSubject = (subject) => {
            this.setState({
                subject: subject
            });
        };

        setText = (text) => {
            this.setState({
                text: text
            });
        };

        attach = (media) => {
            const { attachments } = this.state;
            const resource = { uri: media.uri, local: true, mediaType: media.mediaType };
            const updatedAttachments = attachments.concat([resource]);
            this.setState({
                attachments: updatedAttachments
            });

            const modelId = qs.stringify({ senderId: senderId, recipientId: recipientId });
            const mediaUploader = this.flux.get(MediaUploader, modelId);
            let dispose;
            const updateUploading = (uploaderState) => {
                const { attachments } = this.state;
                const { uploads } = uploaderState;
                const transfer = uploads[media.uri];
                if (transfer === undefined) { return; }
                const index = attachments.findIndex(uploadedAttachment => uploadedAttachment.uri === transfer.localPath);
                switch (transfer.status) {
                    case 'complete':
                        if (index >= 0) {
                            attachments[index] = {
                                uri: transfer.result.uri,
                                remote: true,
                                mediaType: media.mediaType,
                                basename: transfer.result.basename,
                                preview: transfer.result.preview
                            };
                            const updatedAttachments = attachments.slice();
                            this.setState({
                                attachments: updatedAttachments
                            });
                        }
                        dispose && dispose();
                        break;
                    case 'failed':
                        if (index >= 0) {
                            attachments[index] = { uploadFailed: true };
                            const updatedAttachments = attachments.slice();
                            this.setState({
                                attachments: updatedAttachments
                            });
                        }
                        dispose && dispose();
                        break;
                }
            };
            dispose = mediaUploader.store.listen((state) => updateUploading(state));
            mediaUploader.actions.upload(media);
        };

        detach = (media) => {
            const modelId = qs.stringify({ senderId: senderId, recipientId: recipientId });
            const mediaUploader = this.flux.get(MediaUploader, modelId);
            mediaUploader.actions.cancelUploading(media);

            const { attachments } = this.state;

            const index = attachments.findIndex(attachment => attachment.uri === media.uri);
            if (index >= 0) {
                this.setState({
                    attachments: attachments.filter(filteredMedia => filteredMedia.uri !== media.uri)
                });
            }
        };
    }

    Store.displayName = createStore.getDisplayName(id);

    return Store;
}

createStore.getDisplayName = function (id) {
    return `dialogs-mailbox-letter-writing.${id}`;
};
