import React from 'react';
import { AppState } from 'react-native';
import Service from '../service';
import UserDefaults from 'react-native-default-preference';
import AppsFlyerTracker from 'dating-mobile/src/services/tracking/apps-flyer-tracker';

const APP_ENTERED_BACKGROUND_TIME_KEY = `service.tracking.second-login:app-entered-background-time`;
const FIRST_DAILY_SESSION_START_KEY = `service.tracking.second-login:first-daily-session-start`;
const SECOND_LOGIN_TRACKED_KEY = `service.tracking.second-login:second-login-tracked`;
const HALF_HOUR = 30 * 60 * 1000;
const DAY = HALF_HOUR * 2 * 24;

export default class SecondLoginTrackingService extends Service {
    static displayName = 'service.tracking.second-login';

    componentDidMount() {
        AppState.addEventListener('change', this.onAppStateChanged);
        this.onAppStateChanged(this.appState).then();
    }

    componentWillUnmount() {
        AppState.removeEventListener('change', this.onAppStateChanged);
    }

    onAppStateChanged = async (nextAppState) => {
        if ((!this.appState || this.appState !== 'active') && nextAppState === 'active') {
            const now = (new Date()).getTime();

            const encodedAppEnteredBackgroundTime = await UserDefaults.get(APP_ENTERED_BACKGROUND_TIME_KEY);
            const appEnteredBackgroundTime = (encodedAppEnteredBackgroundTime && parseFloat(encodedAppEnteredBackgroundTime)) || null;
            if (!appEnteredBackgroundTime || now - appEnteredBackgroundTime >= HALF_HOUR) {
                //new session

                const encodedFirstDailySessionStart = await UserDefaults.get(FIRST_DAILY_SESSION_START_KEY);
                const firstDailySessionStart = (encodedFirstDailySessionStart && parseFloat(encodedFirstDailySessionStart)) || null;

                if (firstDailySessionStart && now - firstDailySessionStart < DAY) {
                    const secondLoginTracked = !!(await UserDefaults.get(SECOND_LOGIN_TRACKED_KEY));
                    if (!secondLoginTracked) {
                        this.trackSecondLogin();
                        await UserDefaults.set(SECOND_LOGIN_TRACKED_KEY, '1');
                    }
                } else {
                    await UserDefaults.set(FIRST_DAILY_SESSION_START_KEY, String(now));
                    await UserDefaults.set(SECOND_LOGIN_TRACKED_KEY, '');
                }
            }
        }
        if (this.appState !== 'background' && nextAppState === 'background') {
            await UserDefaults.set(APP_ENTERED_BACKGROUND_TIME_KEY, String((new Date()).getTime()));
        }
        this.appState = nextAppState;
    };

    trackSecondLogin = () => {
        AppsFlyerTracker.trackEvent('second login', {}, () => {}, () => {});
    };

}
