import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, TouchableOpacity } from 'react-native';

import styles from './styles';

export default class TextButton extends React.PureComponent {
    static propTypes = {
        title: PropTypes.string,
        tint: PropTypes.string,
        disabled: PropTypes.bool,
        disabledTint: PropTypes.string,
        onPress: PropTypes.func,
    };

    render() {
        const { onPress, tint, disabled, title, disabledTint } = this.props;
        const touchable = !!onPress;
        const textStyle = [styles.text, tint ? { color: tint } : {}];

        if (disabled) {
            textStyle.push(styles.disabled);

            if (disabledTint) {
                textStyle.push({ color: disabledTint });
            }
        }

        const container = (
            <View style={styles.container}>
                <Text style={textStyle}>{title}</Text>
            </View>
        );

        return touchable ? (
            <TouchableOpacity onPress={onPress} disabled={disabled}>
                {container}
            </TouchableOpacity>
        ) : (
            container
        );
    }
}
