import React from 'react';
import { userPhotoPath } from '@sdv/domain/resources/remote';
import Photo from 'dating-mobile/src/components/photo';
import UserThumbnail from 'dating-mobile/src/user/common/views/thumbnail';
import { UserProfileAliases } from '@sdv/domain/user/user-profile-aliases';
import { render } from 'dating-mobile/src/components/rx-render';
import { AliasPhotosUploader } from '@sdv/domain/user/user-alias-photos-uploader';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';

const withAliases = Component => {
  const AliasedView = render((props, { useObservable }) => {
    let { path } = props;

    const { thumbnail } =
      useObservable(
        (it, id) => it.getAlias(id),
        UserProfileAliases.shared(),
        props.userId,
      ) || {};

    if (!path && props.userId && props.id !== props.userId) {
      if (thumbnail && props.id) {
        path = AliasPhotosUploader.shared().getAliasPhotoPath(
          props.id,
          props.userId,
          thumbnail,
        );
      } else if (props.basename) {
        path = userPhotoPath(
          props.userId,
          props.basename,
          props.private,
          props.preview,
        );
      }
    }

    return <Component {...props} path={path} />;
  });

  AliasedView.displayName = 'user.common.views.user-thumbnail-aliased';

  AliasedView.propTypes = Component.propTypes;

  return AliasedView;
};

export const UserPhotoAliased = withIdentityId(withAliases(Photo), 'id');

export const UserThumbnailAliased = withIdentityId(
  withAliases(UserThumbnail),
  'id',
);
