function createTagsActions(userId) {

    class Actions {

        put = (tag) => (dispatch, flux) => {
            flux.api.users.tags[tag].put(userId, (error, res) => {
            	if (error) {
            		dispatch(null, error);
                    return;
            	}
                dispatch(tag);
            });
        };

        delete = (tag) => (dispatch, flux) => {
            flux.api.users.tags[tag].delete(userId, (error, res) => {
                if (error) {
                    dispatch(null, error);
                    return;
                }
                dispatch(tag);
            });
        };

        get = () => (dispatch, flux) => {

            flux.api.users.tags.get(userId, (error, tags) => {
                if (error) {
                    dispatch(null, error);
                    return;
                }

                dispatch(tags);
            });

        };

        actualize = (newTags, staledTags) => (dispatch) => {
            dispatch({
                new: newTags || [],
                staled: staledTags || []
            });
        };

    }

    Actions.displayName = createTagsActions.getDisplayName(userId);

    return Actions;
}

createTagsActions.getDisplayName = function (id) {
    return `user-tags.${id}`;
};

export default createTagsActions;
