// @ts-ignore
import React, {
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
// @ts-ignore
import { ThumbnailPhotoUploader } from '@sdv/domain/user/user-thumbnail-photo-uploader';
// @ts-ignore
import { UserThumbnailUpdating } from '@sdv/domain/user/user-thumbnail-updating';
import { UploaderConfig } from './types';

export type UploaderConfigProps = {
  uploaderConfig: UploaderConfig;
};

type Props = UploaderConfigProps & {
  user: any;
  source: any;
  takeFromGalleryWithPermissionsRequest: Function;
  takeNewMediaWithPermissionsRequest: Function;
  forwardRef: any;
};

type ProgressState = number;
type StatusState = string;

export const withController = (Component: any) => {
  const Controller = React.memo((props: Props) => {
    const {
      takeNewMediaWithPermissionsRequest,
      takeFromGalleryWithPermissionsRequest,
      user,
      source,
      forwardRef,
      uploaderConfig,
    } = props;

    const thumbnail = user.thumbnail || user['thumbnail-pending'];
    const uri = source ? source.uri : '';
    const { waitUploading, onComplete } = uploaderConfig;

    const [progress, setProgress] = useState<ProgressState>();
    const [status, setStatus] = useState<StatusState>();
    const loading = useMemo(() => status === 'uploading', [status]);

    useEffect(() => {
      const isScreenComplete = !!((!waitUploading && uri) || thumbnail);

      isScreenComplete && onComplete();
    }, [uri, waitUploading, thumbnail, onComplete]);

    useEffect(() => {
      if (source && user.id) {
        ThumbnailPhotoUploader.shared().uploadPhoto(source, user.id, result => {
          setProgress(result.progress);
          setStatus(result.status);

          if (result.basename) {
            UserThumbnailUpdating.shared(user.id).setAsThumbnail(
              result.basename,
            );
          }
        });
      }
    }, [user.id, source]);

    useImperativeHandle(forwardRef, () => ({
      selectNewMedia: () => {
        takeNewMediaWithPermissionsRequest &&
          takeNewMediaWithPermissionsRequest();
      },
      selectFromGallery: () => {
        takeFromGalleryWithPermissionsRequest &&
          takeFromGalleryWithPermissionsRequest();
      },
    }));

    return (
      <Component
        {...props}
        progress={progress}
        loading={loading}
        userId={user.id}
        basename={thumbnail}
        uri={uri}
        waitUploading={waitUploading}
      />
    );
  });

  Controller.displayName =
    'stepped-registration.views.user-thumbnail.controller';

  return Controller;
};
