import React from 'react';
import PropTypes from 'prop-types';
import Config from 'dating-mobile/src/app/config';
import equal from 'fast-deep-equal';
import { of } from 'rxjs';
import { mergeMap, map, scan } from 'rxjs/operators';

export default function createConfigValue(Component, pathes, resultPropName = 'value') {

    class ConfigValue extends React.Component {
        static displayName = 'config.value';
        static propTypes = {
            path: PropTypes.string,
            propName: PropTypes.string
        };

        parameters = {};
        mounted = false;
        values = {};

        constructor(props) {
            super(props);
            this.bindParametersToProps();
        }

        componentDidMount() {
            this.mounted = true;
        }

        componentWillUnmount() {
            this.mounted = false;
            this.unbindParametersFromProps();
        }

        componentDidUpdate() {
            if (!equal(this.requiredParameters(), this.parameters)) {
                this.unbindParametersFromProps();
                this.bindParametersToProps();
            }
        }

        requiredParameters() {
            const config = typeof pathes === 'object'
                ? pathes
                : (pathes && !this.props.path
                    ? { [this.props.propName || resultPropName]: pathes }
                    : {});
            if (this.props.path) {
                config[this.props.propName || resultPropName] = this.props.path;
            }
            return config;
        };

        bindParametersToProps() {
            this.parameters = this.requiredParameters();

            this.subscription = of(...Object.entries(this.parameters || {}))
                .pipe(
                    mergeMap(([key, path]) => {
                        return Config.shared().paramWithPath(path)
                            .pipe(
                                map(value => ({ [key]: value }))
                            )
                    }),
                    scan((result, current) => Object.assign(result, current), {})
                )
                .subscribe(state => {
                    this.values = state;
                    this.mounted && this.forceUpdate();
                });
        };

        unbindParametersFromProps() {
            this.subscription && this.subscription.unsubscribe();
        };

        render() {
            const { forwardedRef, ...props } = this.props;


            return (<Component {...props} ref={forwardedRef} {...this.values} />);
        }
    }

    return React.forwardRef((props, ref) => {
       return <ConfigValue {...props} forwardedRef={ref} />;
    });

}
