import React, { useRef } from 'react';
import { View } from 'react-native';

import Resources from 'dating-mobile/src/resources';
// @ts-ignore
import testId from 'dating-mobile/src/utils/test-id';
// @ts-ignore
import Button from 'dating-mobile/src/components/buttons/base';

import styles from './styles';

type Props = {
    PhotoPicker: React.JSXElementConstructor<any>;
    title: React.ComponentElement<any, any>;
};

export const PhotoUploadingView = (props: Props) => {
    const { PhotoPicker, title } = props;

    const photoMediaRef = useRef<{
        selectNewMedia: Function;
        selectFromGallery: Function;
    }>();

    const selectNewMedia = () => {
        photoMediaRef.current &&
            photoMediaRef.current.selectNewMedia &&
            photoMediaRef.current.selectNewMedia();
    };

    const selectFromGallery = () => {
        photoMediaRef.current &&
            photoMediaRef.current.selectFromGallery &&
            photoMediaRef.current.selectFromGallery();
    };

    return (
        <View style={styles.container}>
            <View>
                {title}
                <View style={styles.photoPickerContainer}>
                    <PhotoPicker ref={photoMediaRef} />
                </View>
            </View>

            <View style={styles.buttonsContainer}>
                <Button
                    title={Resources.strings['onboarding-screen-take-photo-button-text']}
                    onPress={selectNewMedia}
                    style={styles.takePhotoButton}
                    titleStyle={styles.takePhotoButtonText}
                    {...testId('Take photo button')}
                />
                <Button
                    title={Resources.strings['onboarding-screen-gallery-button-text']}
                    onPress={selectFromGallery}
                    style={styles.galleryButton}
                    titleStyle={styles.galleryButtonText}
                    {...testId('Gallery button')}
                />
            </View>
        </View>
    );
};

PhotoUploadingView.displayName = 'stepped-registration.views.photo-uploading.view';
