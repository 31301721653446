import React from 'react';
import PropTypes from 'prop-types';
import { SafeAreaView, ScrollView, StatusBar } from 'react-native';
import styles from './styles';

function createTravelsScreen(Component) {
  class Modal extends React.Component {
    static displayName = 'user.travels.screen';

    static propTypes = {
      navigation: PropTypes.object.isRequired,
    };

    static navigationOptions = ({ navigation }) => ({
      title: navigation.getParam('title'),
      headerStyle: styles.header,
      headerLeft: navigation.getParam('headerLeft'),
      headerTintColor: styles.headerTextColor.color,
      headerRight: navigation.getParam('headerRight'),
    });

    render() {
      const { navigation } = this.props;
      const id = navigation.getParam('id');

      return (
        <SafeAreaView style={styles.safeArea}>
          <StatusBar
            barStyle={styles.$statusBarStyle}
            backgroundColor={styles.$statusBarBackgroundColor}
          />

          <ScrollView
            style={styles.scroll}
            keyboardShouldPersistTaps="always"
            contentContainerStyle={styles.scrollContent}
          >
            <Component id={id} navigation={navigation} />
          </ScrollView>
        </SafeAreaView>
      );
    }
  }

  return Modal;
}

export default createTravelsScreen;
