import React from 'react';
import PropTypes from 'prop-types';
import { Platform, Share } from 'react-native';
import { of, from } from 'rxjs';
import { switchMap, map, mapTo } from 'rxjs/operators';
import { links } from 'react-native-firebase';
import { Persistence } from '@sdv/domain/app/persistence';
import api, { toObservable } from '@sdv/domain/api';
import { render } from 'dating-mobile/src/components/rx-render';

const persistenceKey = 'UserInvitation';
const invitationLinkKey = 'link';

export default function createController(Component) {
  const Controller = render((props, { useObservableAction }) => {
    const persistence = Persistence.shared(persistenceKey, props.userId);

    const onPress = useObservableAction(
      () =>
        persistence.load(invitationLinkKey).pipe(
          switchMap(invitationCode => {
            if (invitationCode) {
              return of(invitationCode);
            }

            return toObservable(api.codes.invitation[''].post, {}).pipe(
              map(({ code }) => code),
              switchMap(code => {
                const link = new links.DynamicLink(
                  `https://zillapp.io?userId=${props.userId}&code=${code}&action=invite`,
                  'https://zillapp.page.link',
                ).android
                  .setPackageName('chat.zill')
                  .ios.setBundleId('io.zillapp')
                  .ios.setAppStoreId('1461449583');

                return from(links().createShortDynamicLink(link, 'SHORT'));
              }),
              switchMap(url =>
                persistence.store(invitationLinkKey, url).pipe(mapTo(url)),
              ),
            );
          }),
          switchMap(url =>
            from(
              Share.share({
                ...Platform.select({
                  ios: { url },
                  android: { message: url },
                }),
              }),
            ),
          ),
        ),
      persistence,
    );

    return <Component {...props} onPress={onPress} />;
  });

  Controller.propTypes = {
    userId: PropTypes.string,
  };

  Controller.displayName = 'user.views.share-button.controller';

  return Controller;
}
