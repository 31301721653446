import { BehaviorSubject, of } from 'rxjs';
import { singleton } from "@sdv/commons/utils/singleton";
import flux from '@sdv/domain/app/flux';

class StreamsTopics {
    static shared = singleton((userId) => new StreamsTopics(userId));

    constructor(userId) {
        this.userId = userId;
        this.availableTopics = of(['mal', 'fem', 'tal', 'mus', 'dan', 'cha', 'ent', 'spo', 'bea']);
        this.selectedTopics = new BehaviorSubject([]);

        // TODO: remove comments after api implementation
        // flux.api.users['streams-topics']
        //     .get(this.userId, (err, data) => {
        //         if (data) {
        //             this.selectedTopics.next(data);
        //         }
        //     });
    }

    selectTopic(topic) {
        const currentTopics = this.selectedTopics.value.slice();
        const index = currentTopics.indexOf(topic);
        if (index < 0) {
            currentTopics.push(topic);
        } else {
            currentTopics.splice(index, 1);
        }
        this.selectedTopics.next(currentTopics);
    }

    save() {
        flux.api.users.tags.streams.put(this.userId, this.selectedTopics.value);
    }

}

export default StreamsTopics;
