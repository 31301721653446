import Actions from './actions';

function createInvitationStore(id) {

    class Store {

        /**
         *
         * @type {{
         *     production: {
         *         active: boolean,
         *         consumers: array<string>,
         *         amount: number?,
         *         timestamp: string? (iso date),
         *         lifetime: string? (iso date),
         *         periodical: {
         *             unavailable: string (iso date)
         *         }?
         *     }
         * }}
         */
        state = {
            production: {}
        };

        constructor(flux) {
            const actions = flux.getActions(Actions, id);

            this.bindAction(actions.get, this.update);
            this.bindAction(actions.put, this.update);
            this.bindAction(actions.actualize, this.patch);
            this.bindAction(actions.delete, this.delete);
        }

        output(state) {
            return { ...state.production };
        }

        update = (production) => {

            if (!production) {
                return;
            }

            production.consumers = production.consumers || [];
            production.active = production.active || false;
            production.amount = production.amount || null;

            this.setState({
                production: production
            });

        };

        patch = (production) => {

            if (!production) {
                return;
            }

            this.setState({
                production: {
                    ...this.state.production,
                    ...production
                }
            });

        };

        delete = (production) => {

            if (!production) {
                return;
            }

            this.patch({
                ...production,
                active: false
            });

        };

    }

    Store.displayName = createInvitationStore.getDisplayName(id);

    Store.config = {
        getState(state) {
            return { ...state.production };
        }
    };

    return Store;

}

createInvitationStore.getDisplayName = function (id) {
    return `dialogs-automation-productions-invitation.${id}`;
};

export default createInvitationStore;
