import React from 'react';
import PropTypes from 'prop-types';
import connect from '@sdv/connect';
import MessagesModel, { getId } from '@sdv/domain/dialogs.messages';
import { DELIVERY_STATUS, generateTag, MessagesActionsError } from '@sdv/domain/dialogs.messages/actions';

export default function createHoc(
    Component,
    propName = 'send',
    userIdPropName = 'userId',
    attendeeIdPropName = 'attendeeId',
    waitForProcessing = false
) {

    class Send extends React.Component {
        static displayName = 'dialogs.messages.send';
        static propTypes = {
            send: PropTypes.func
        };
        static contextTypes = {
            flux: PropTypes.object
        };

        send = (text, reference, tag, callback) => {
            if (!waitForProcessing) {
                return this.props.send(text, reference, tag, callback);
            }

            const senderId = this.props[userIdPropName];
            const messageTag = tag || generateTag(senderId);

            const subscription = this.context.flux.events.addListener('message:message:v2', (payload) => {
                if (!payload || payload.tag !== messageTag) {
                    return;
                }

                subscription.remove();

                switch (payload.status) {
                    case DELIVERY_STATUS.DELIVERED:
                        callback(null);
                        break;
                    case DELIVERY_STATUS.UNPAID:
                        const error = new MessagesActionsError(MessagesActionsError.CODES.PAYMENT_REQUIRED);
                        error.tag = messageTag;
                        callback(error);
                        break;
                    default:
                        callback(new Error('Unknown status code'));
                        break;
                }
            });

            this.props.send(text, reference, messageTag, (error) => {
                if (error) {
                    subscription.remove();
                    callback(error);
                    return;
                }

                this.context.flux.events.pollEvent();
            });
        };

        render() {
            return (<Component {...this.props} send={this.send} />);
        }

    }

    function getModels(flux, props) {
        const models = {};

        if (props[userIdPropName] && props[attendeeIdPropName]) {
            models.messagesModel = flux.get(MessagesModel, getId(props[userIdPropName], props[attendeeIdPropName]));
        }

        return models;
    }

    function mapStoresToProps() {
        return {};
    }

    function mapStoresToActions(models) {
        const props = {};

        if (models.messagesModel) {
            props[propName] = models.messagesModel.actions.send;
        }

        return props;
    }

    function shouldReconnect(props, newProps) {

        return props[userIdPropName] !== newProps[userIdPropName] ||
            props[attendeeIdPropName] !== newProps[attendeeIdPropName];

    }

    return connect(
        getModels,
        mapStoresToProps,
        mapStoresToActions,
        shouldReconnect
    )(Send);

}
