import displayName from './display-name';
import LetterRepo from '../letter-repo';

function createActions(userId) {
    class Actions {

        constructor() {

        }

        getLetters = (select = 20, reload = false) => (dispatch, flux) => {
            const state = flux.getStore(displayName(userId)).getState();

            const params = {
                ...state.params,
                select: select,
                omit: reload ? 0 : state.omit, ...this.options,
                types: '+letter'
            };

            this.repo = new LetterRepo(flux);

            this.repo.getChains(params, (chains, error) => {
                if (!error) {
                    dispatch({ omit: params.omit, select: params.select, letterChains: chains });
                } else {
                    dispatch(null, error);
                }
            });
        }

        deleteChain = (chainId) => (dispatch, flux) => {
            this.repo = new LetterRepo(flux);
            this.repo.deleteChain(userId, chainId);
        };
    }


    Actions.displayName = displayName(userId);

    return Actions;
}

createActions.getDisplayName = displayName;

export default createActions;

