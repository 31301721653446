import React from 'react';
import PropTypes from 'prop-types';
import { BackHandler } from 'react-native';
import View from './view';
import controller from './controller';
import styles from './styles';

const UserGoalView = controller(View);

class UserGoalsScreen extends React.PureComponent {
  static displayName = 'authentication.goals.screen';

  static propTypes = {
    navigation: PropTypes.object.isRequired,
  };

  static navigationOptions = () => ({
    title: null,
    headerStyle: styles.header,
    headerLeft: () => null,
    headerRight: () => null,
    gestureEnabled: false,
  });

  componentDidMount() {
    this.backButtonHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      () => {
        return true;
      },
    );
  }

  componentWillUnmount() {
    this.backButtonHandler.remove();
  }

  complete = () => {
    const { navigation } = this.props;

    const onComplete =
      navigation.getParam('onComplete') || (() => navigation.pop());

    onComplete();
  };

  render() {
    return <UserGoalView complete={this.complete} />;
  }
}

export default UserGoalsScreen;
