import React from 'react';
import Model, { getId } from '../model';
import connect from '@sdv/connect';

const GET_ACTION = 'generated.user-targeted-tagged.controller.get-action';

export default function createHoc(Component, options = {}) {
    const userIdPropName = options.userIdPropName || 'userId';
    const usersPropName = options.usersPropName || 'users';
    const usersLoadedPropName = options.usersLoadedPropName || 'usersLoaded';

    const tag = options.tag;

    class Controller extends React.Component {
        static displayName = 'users-targeted-tagged.controller';

        constructor(props) {
            super(props);
        }

        componentDidMount() {
            if (this.props[usersLoadedPropName] === false) {
                this.props[GET_ACTION] && this.props[GET_ACTION]();
            }
        }

        componentDidUpdate(prevProps): void {
            if (shouldReconnect(prevProps, this.props)) {
                if (this.props[usersLoadedPropName] === false) {
                    this.props[GET_ACTION] && this.props[GET_ACTION]();
                }
            }
        }

        render() {
            const { ...props } = this.props;
            delete props[GET_ACTION];
            return <Component {...props}/>;
        }

    }

    function getModels(flux, props) {
        const models = {};
        if (props[userIdPropName] && tag) {
            models.userTaggedModel = flux.get(Model, getId(props[userIdPropName], tag));
        }
        return models;
    }

    function mapStoresToProps(models) {
        const props = {};

        if (models.userTaggedModel) {
            props[usersLoadedPropName] = models.userTaggedModel.store.isFilled();
            props[usersPropName] = models.userTaggedModel.store.getState().users;
        }

        return props;
    }

    function mapStoresToActions(models) {
        const props = {};

        if (models.userTaggedModel) {
            props[GET_ACTION] = models.userTaggedModel.actions.get;
        }
        return props;
    }

    function shouldReconnect(props, newProps) {
        return props[userIdPropName] !== newProps[userIdPropName];
    }

    return connect(
        getModels,
        mapStoresToProps,
        mapStoresToActions,
        shouldReconnect
    )(Controller);
}
