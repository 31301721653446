import React from 'react';
import PropTypes from 'prop-types';
import { View, StatusBar } from 'react-native';
import styles from './styles';
import TipsView from '../../views/tips';

export default class ScreenView extends React.Component {
    static displayName = 'tips.tips.view';
    static propTypes = {
        tips: PropTypes.array,
        onTipSelect: PropTypes.func,
        isFocused: PropTypes.bool
    };

    componentWillReceiveProps(nextProps) {
        if (!this.props.isFocused && nextProps.isFocused) {
            StatusBar.setBarStyle(styles.$statusBarStyle);
            StatusBar.setBackgroundColor(styles.$statusBarBackgroundColor);
        }
    }

    render() {
        return (
            <View style={styles.container}>
                <StatusBar
                    barStyle={styles.$statusBarStyle}
                    backgroundColor={styles.$statusBarBackgroundColor}
                />

                <View style={styles.tipsViewContainer}>
                    <TipsView tips={this.props.tips} onTipSelect={this.props.onTipSelect} />
                </View>
            </View>
        );
    }
}
