import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $forceLocalizePrice: false,

    '@flavor secretly': {
        $forceLocalizePrice: true,
    },

    container: {
        height: 50,
        flexDirection: 'column',
        backgroundColor: '$accentColor',
        borderRadius: 25,
        '@flavor dma': {
            height: 60,
            borderRadius: 20,
        },
        paddingLeft: 32,
        paddingRight: 32,
    },

    content: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },

    methodContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },

    method: {
        fontSize: 16,
        color: '$accentTextColor',
        fontWeight: 'bold',
        fontFamily: '$brandFontFamily',
        '@flavor dma': {
            fontSize: 20,
        },
    },

    price: {
        fontSize: 16,
        color: '$accentTextColor',
        fontWeight: '500',
        fontFamily: '$brandFontFamily',
        '@flavor lovinga': {
            fontWeight: 'bold',
        },
        '@flavor dma': {
            fontWeight: 'bold',
            fontSize: 20,
        },
    },

    tax: {
        fontSize: 12,
        color: '$minorAccentTextColor',
        fontFamily: '$brandFontFamily',
    },

    priceWithTax: {
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'center',
    },

    icon: {
        marginRight: 4,
        width: 24,
        height: 24,
    },
});

export default styles;
