const { mediaDevices } = navigator;
const {
  MediaStreamTrack,
  MediaStream,
  RTCSessionDescription,
  RTCPeerConnection,
  RTCIceCandidate,
} = window;

export {
  mediaDevices,
  MediaStreamTrack,
  MediaStream,
  RTCSessionDescription,
  RTCPeerConnection,
  RTCIceCandidate,
};
