import React from 'react';
import PropTypes from 'prop-types';
import { Platform } from 'react-native';
import Resources from 'dating-mobile/src/resources';
import withConfigValue from 'dating-mobile/src/components/config-value';

export default WrappedComponent => {
    const hocComponent = ({ features }) => {
        const featuresData = features.map(feature => {
            return {
                id: feature,
                icon: Resources.images[`${feature}SkipFeatureIcon`](),
                title: Resources.strings[`skip-subscribe-features-${feature}-title`],
                description: Resources.strings.formatString(
                    Resources.strings[`skip-subscribe-features-${feature}-description`],
                    Platform.select({
                        ios: Resources.strings[`skip-subscribe-features-${feature}-ios-store-name`],
                        android:
                            Resources.strings[
                                `skip-subscribe-features-${feature}-android-store-name`
                            ],
                    }),
                ),
            };
        });

        return <WrappedComponent features={featuresData} />;
    };

    hocComponent.propTypes = {
        features: PropTypes.array,
    };

    return withConfigValue(hocComponent, {
        features: 'membership.skip-subscribe-features',
    });
};
