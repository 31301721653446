import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { View, Text, ScrollView, Platform, KeyboardAvoidingView } from 'react-native';

import TextInput from 'dating-mobile/src/components/text-input';
import Resources from 'dating-mobile/src/resources';
import testId from 'dating-mobile/src/utils/test-id';
import Button from 'dating-mobile/src/components/buttons/base';

import styles from './styles';

export default class ScreenView extends PureComponent {
    static displayName = 'stepped-registration.screens.name.view';

    static propTypes = {
        validate: PropTypes.func.isRequired,
        handlerChange: PropTypes.func.isRequired,
        onSubmit: PropTypes.func,
        name: PropTypes.string,
        error: PropTypes.string,
    };

    constructor(props) {
        super(props);

        this.validate = props.validate('name');
        this.handlerChange = props.handlerChange('name');
    }

    render() {
        const { error = {}, name, onSubmit } = this.props;
        const validationError = error.name || error.server;
        const inputStyle = validationError ? [styles.input, styles.inputError] : styles.input;

        return (
            <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : undefined}>
                <ScrollView
                    keyboardShouldPersistTaps="handled"
                    alwaysBounceVertical={false}
                    style={styles.container}
                    contentContainerStyle={styles.contentContainer}
                >
                    <View>
                        <Text style={styles.subTitle}>
                            {Resources.strings['onboarding-screen-title']}
                        </Text>
                        <Text style={styles.title}>
                            {Resources.strings['onboarding-screen-name-title']}
                        </Text>
                        <TextInput
                            style={inputStyle}
                            containerStyle={styles.containerInput}
                            placeholder={Resources.strings['onboarding-screen-name-placeholder']}
                            value={name}
                            autoCapitalize="words"
                            autoFocus
                            textContentType="name"
                            returnKeyType="next"
                            onChange={this.handlerChange}
                            onBlur={this.validate}
                            error={validationError}
                            onSubmitEditing={onSubmit}
                            {...testId('Name input')}
                        />
                        {!!validationError && <Text style={styles.hint}>{validationError}</Text>}
                    </View>
                    <Button
                        disabled={!name}
                        title={Resources.strings.next}
                        onPress={onSubmit}
                        style={styles.button}
                        {...testId('Next button')}
                    />
                </ScrollView>
            </KeyboardAvoidingView>
        );
    }
}
