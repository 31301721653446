import React from 'react';
import PropTypes from 'prop-types';
import IdentityModel from '@sdv/domain/identity/model';
import Config from 'dating-mobile/src/app/config';
import * as ROUTES from 'dating-mobile/src/routing/router/constants';
import { OnboardingProgress } from 'dating-mobile/src/authentication/utils/onboarding-progress';

import Keychain from '../../../utils/keychain';
import View from '../../views/initial';
import authRouting from '../../controllers/auth-routing';
import { routeForRegistrationStep } from '../../utils/registration-step-route';
import { routeForRegistrationStep as steppedRouteForRegistrationStep } from '../../utils/stepped-registration-route';

const ScreenView = authRouting(View);

class InitialScreen extends React.Component {
  static contextTypes = {
    flux: PropTypes.object,
  };

  static propTypes = {
    navigation: PropTypes.object.isRequired,
  };

  static navigationOptions = () => ({
    headerShown: false,
  });

  constructor(props, context) {
    super(props, context);
    this.bus = context.flux.bus;
    this.model = context.flux.get(IdentityModel);
  }

  componentDidMount() {
    this.disposeBag = Config.shared()
      .paramWithPath('features.stepped-registration-enabled')
      .subscribe(result => {
        this.steppedRegistrationEnabled = result;
      });
    this.model.store.listen(this.update);
    this.bus.addListener('startScreen_signInButtonTap', this.pushLoginScene);
    this.bus.addListener(
      'startScreen_signUpButtonTap',
      this.pushRegistrationScene,
    );
  }

  componentWillUnmount() {
    if (this.disposeBag) this.disposeBag.unsubscribe();
    this.model.store.unlisten(this.update);
    this.bus.removeListener('startScreen_signInButtonTap', this.pushLoginScene);
    this.bus.removeListener(
      'startScreen_signUpButtonTap',
      this.pushRegistrationScene,
    );
  }

  update = async state => {
    if (state && state.token) {
      await Keychain.setToken(state.token);
    }
  };

  pushLoginScene = () => {
    const { navigation } = this.props;

    navigation.navigate(ROUTES.LOGIN);
  };

  pushRegistrationScene = () => {
    const { navigation } = this.props;

    navigation.navigate(ROUTES.REGISTER, {
      title: 'Take First Step',
      buttonTitle: 'Create Account',
    });
    OnboardingProgress.shared().goToFirstStep();
  };

  showApp = () => {
    const { navigation } = this.props;

    navigation.navigate(ROUTES.APP);
  };

  showRegistrationStep = (step, params) => {
    const { navigation } = this.props;

    const route = this.steppedRegistrationEnabled
      ? steppedRouteForRegistrationStep(step)
      : routeForRegistrationStep(step);

    navigation.navigate(route, params);
  };

  showAuth = () => {
    const { navigation } = this.props;

    navigation.navigate(ROUTES.AUTH);
  };

  render() {
    return (
      <ScreenView
        showApp={this.showApp}
        showRegistrationStep={this.showRegistrationStep}
        showAuth={this.showAuth}
      />
    );
  }
}

export default InitialScreen;
