import { map } from 'rxjs/operators';
import { singleton } from '@sdv/commons/utils/singleton';
import { UserFeedRepository } from '@sdv/domain/app/user/user-feed-repository';

export class PartialMatchSearch {
  static shared = singleton(userId => new PartialMatchSearch(userId));

  constructor(id) {
    this.userFeedRepository = UserFeedRepository.shared(id);

    const usersList = this.userFeedRepository.users.pipe(
      map(users => {
        const matched = users.filter(({ partialMatch }) => !partialMatch);
        const partialMatched = users.filter(({ partialMatch }) => partialMatch);

        return {
          users: [...matched, ...partialMatched],
          partialMatchLength: partialMatched.length,
        };
      }),
    );

    this.allUsers = usersList.pipe(map(({ users }) => users));

    this.partialMatchLength = usersList.pipe(
      map(({ partialMatchLength }) => partialMatchLength),
    );

    this.fetchError = this.userFeedRepository.fetchError;

    this.isLoading = this.userFeedRepository.isLoading;
  }

  getNext() {
    this.userFeedRepository.getNext();
  }
}
