import EStyleSheet from 'react-native-extended-stylesheet';

const container = {
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
  flexDirection: 'row',
  backgroundColor: '$primaryBackgroundColor',
};

const username = {
  fontFamily: '$brandFontFamily',
  fontWeight: 'normal',
  marginRight: 10,
  color: '$primaryTextColor',
  flexShrink: 1,
  '@flavor lovinga, udates': {
    fontSize: 18,
  },
  '@flavor dma': {
    fontSize: 19,
  },
};

const messageContentContainer = {
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',
  '@flavor lovinga, udates, secretly': {
    borderBottomWidth: 1,
    borderColor: '$chatItemsSeparatorColor',
  },
};

const message = {
  fontWeight: '300',
  color: '$secondaryTextColor',
  fontFamily: '$brandFontFamily',
  '@flavor lovinga, udates': {
    fontWeight: 'normal',
    color: '$disabledTextColor',
  },
  '@flavor dma': {
    fontWeight: 'normal',
    color: '$infoTextColor',
  },
};

const timestamp = {
  marginLeft: 10,
  fontFamily: '$brandFontFamily',
  color: '$secondaryTextColor',
  fontSize: 10,
  '@flavor lovinga, udates, secretly': {
    fontSize: 14,
    color: '$disabledTextColor',
  },
  '@flavor dma': {
    fontSize: 15,
    color: '$infoTextColor',
  },
};

const avatarContainer = {
  marginRight: 10,
  alignItems: 'flex-end',
  justifyContent: 'center',
  flexDirection: 'row',
  '@flavor lovinga, udates': {
    marginRight: 16,
  },
};

const styles = EStyleSheet.create({
  $onlineIndicatorVisible: true,
  '@flavor lovinga, tubit, secretly': {
    $onlineIndicatorVisible: false,
  },

  touchable: {
    width: '100%',
  },
  container: {
    ...container,
    height: 80,
    '@flavor lovinga, udates, secretly': {
      height: 96,
    },
  },
  containerSmall: {
    ...container,
    height: 52,
    '@flavor lovinga, udates, secretly': {
      height: 68,
    },
  },
  unreadContainer: {
    ...container,
    backgroundColor: '#EEEEEE',
    '@flavor lovinga, dma, tubit, udates, secretly': {
      backgroundColor: '$primaryBackgroundColor',
    },
  },
  messageContentContainer: { ...messageContentContainer, height: '100%' },
  messageContentContainerSmall: {
    ...messageContentContainer,
    paddingBottom: 5,
  },
  messageContent: {
    flex: 1,
    flexDirection: 'column',
  },
  messageContainer: {
    flex: 1,
  },
  message: {
    ...message,
    fontSize: 17,
  },
  messageSmall: {
    ...message,
    fontSize: 12,
  },
  unreadMessage: {
    fontFamily: '$brandFontFamily',
    fontWeight: 'normal',
    color: '$primaryTextColor',
    '@flavor lovinga': {
      color: '$primaryTextColor',
    },
    '@flavor dma, udates': {
      fontWeight: 'bold',
      color: '$primaryTextColor',
    },
  },
  avatarContainer: {
    ...avatarContainer,
    marginLeft: 16,
  },
  avatarContainerSmall: avatarContainer,
  avatar: {
    width: 55,
    height: 55,
    borderRadius: 22,
    '@flavor lovinga, dma, udates, secretly': {
      width: 68,
      height: 68,
      borderRadius: 34,
    },
    '@flavor tubit': {
      borderRadius: 27,
    },
  },
  avatarSmall: {
    width: 34,
    height: 34,
    borderRadius: 17,
    '@flavor lovinga, dma, udates, secretly': {
      width: 46,
      height: 46,
      borderRadius: 23,
    },
    '@flavor tubit': {
      borderRadius: 22,
    },
  },
  online: {
    marginLeft: -12,
    bottom: 0,
  },
  likedIconContainer: {
    height: 20,
    width: 20,
    borderRadius: 20,
    borderColor: 'white',
    borderWidth: 2,
    backgroundColor: '$accentColor',
    position: 'absolute',
    right: 0,
    bottom: 0,
    padding: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  likedIcon: {
    marginLeft: 0.5,
    height: 16,
    width: 16,
    borderRadius: 12,
    backgroundColor: '$accentColor',
  },
  usernameContainer: {
    marginRight: 14,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    '@flavor lovinga, udates': {
      marginRight: 16,
    },
    '@flavor secretly': {
      marginBottom: 4,
    },
  },
  username: { ...username, fontSize: 17 },
  usernameSmall: { ...username, fontSize: 14 },
  unreadUsername: {
    fontFamily: '$brandFontFamily',
    fontWeight: '600',
    '@flavor lovinga, dma, udates': {
      fontWeight: 'bold',
    },
  },
  messageInfoContainer: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    '@flavor dma': {
      marginTop: 5,
    },
  },
  unreadMessageCount: {
    marginRight: 14,
    marginLeft: 10,
    width: 20,
    height: 20,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '$brandColor',
    '@flavor lovinga, udates': {
      marginRight: 16,
      backgroundColor: '$accentColor',
    },
    '@flavor tubit, secretly': {
      backgroundColor: '$accentColor',
    },
    '@flavor dma': {
      width: 24,
      height: 24,
      borderRadius: 12,
      backgroundColor: '$accentColor',
    },
  },
  unreadMessageCountText: {
    fontFamily: '$brandFontFamily',
    fontSize: 11,
    fontWeight: '500',
    color: '$brandTextColor',
    '@flavor lovinga, udates': {
      fontSize: 12,
      fontWeight: 'bold',
      color: '$accentTextColor',
    },
    '@flavor dma': {
      fontSize: 14,
      fontWeight: 'bold',
      color: '$accentTextColor',
    },
  },
  timestamp,
  unreadTimestamp: {
    ...timestamp,
    '@flavor lovinga, udates': {
      color: '$primaryTextColor',
    },
    '@flavor dma': {
      color: '$primaryTextColor',
      fontSize: 15,
    },
    '@flavor secretly': {
      fontSize: 14,
      color: '$primaryTextColor',
    },
  },
  userInfoContainer: {
    flexShrink: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default styles;
