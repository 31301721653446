import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default function createController(Component) {
  return class Controller extends PureComponent {
    static displayName = 'components.time.time-spent.controller';

    static propTypes = {
      updateInterval: PropTypes.number,
    };

    static defaultProps = {
      updateInterval: 1000,
    };

    state = {
      timeSpent: 0,
    };

    componentDidMount() {
      this.startTimer();
    }

    componentWillUnmount() {
      this.stopTimer();
    }

    updateTimer = () => {
      if (this.startDate) {
        this.setState({
          timeSpent: Date.now() - this.startDate,
        });
      }
    };

    stopTimer() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    }

    startTimer() {
      const { updateInterval } = this.props;

      if (!this.intervalId) {
        this.startDate = Date.now();
        this.intervalId = setInterval(this.updateTimer, updateInterval);
      }
    }

    render() {
      const { updateInterval, ...props } = this.props;
      const { timeSpent } = this.state;

      return <Component {...props} timeSpent={timeSpent} />;
    }
  };
}
