import EStyleSheet from 'react-native-extended-stylesheet';
import shouldUseWebLayout from 'dating-mobile/src/utils/web-layout';

const styles = EStyleSheet.create({
  $statusBarStyle: '$brandStatusBarStyle',
  $statusBarBackgroundColor: '$brandColor',

  container: {
    flex: 1,
    backgroundColor: '$brandColor',
    paddingVertical: 14,
  },
  content: {
    flex: 1,
    backgroundColor: '$brandColor',
  },
  safeArea: {
    flex: 1,
  },
  header: {
    ...(shouldUseWebLayout
      ? { height: 50, justifyContent: 'center', paddingLeft: 30 }
      : {}),
    backgroundColor: '$brandColor',
    borderBottomColor: '$brandColor',
    shadowColor: '$brandColor',
    shadowRadius: 0,
    shadowOpacity: 0,
    elevation: 0,
    shadowOffset: {
      height: 0,
    },
  },
  tint: {
    color: '$brandTextColor',
  },
  activityIndicatorContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default styles;
