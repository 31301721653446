// @ts-ignore
import React from 'react';
// @ts-ignore
import withImagePicker from 'dating-mobile/src/components/image-picker';
// @ts-ignore
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';
// @ts-ignore
import withUser from 'dating-mobile/src/models/user/controllers';
import { ViewComponent } from './view';
import { UploaderConfigProps, withController } from './controller';

const UserThumbnailUploaderWithoutRef = withIdentityId(
    withUser(withImagePicker(withController(ViewComponent))),
    'id',
);

type Props = UploaderConfigProps;

export const UserThumbnailUploader = React.forwardRef((props: Props, ref) => (
    <UserThumbnailUploaderWithoutRef {...props} forwardRef={ref} />
));

export * from './types';
