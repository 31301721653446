import React from 'react';
import PropTypes from 'prop-types';
import { View, StatusBar, Text } from 'react-native';
import { SafeAreaView } from 'react-navigation';
import styles from './styles';
import Resources from '../../../../resources';
import Button from '../../../../components/buttons/base';
import { escapeRegExpSpecialCharacters } from '../../../../utils/regexp';

const CHATS_LINK_MARK = '{link:chats}';

export default class ScreenView extends React.Component {
    static displayName = 'dialogs.mingle.end.screen.view';
    static propTypes = {
        onChatsLinkPress: PropTypes.func,
        onTryAgainButtonPress: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.linkMarkPattern = `${escapeRegExpSpecialCharacters(CHATS_LINK_MARK)}`;
    }

    shouldComponentUpdate() {
        return false;
    }

    renderInformationDescription = () => {
        let text = Resources.strings.formatString(
            Resources.strings['mingle-end-screen-information-description-text-format'],
            CHATS_LINK_MARK
        );

        const titles = {
            [CHATS_LINK_MARK]: Resources.strings['mingle-end-screen-information-description-chats-link-text']
        };

        const pressHandlers = {
            [CHATS_LINK_MARK]: this.onChatsLinkPressed
        };

        const result = [];
        const regExp = new RegExp(this.linkMarkPattern);
        let match;
        while ((match = text.match(regExp))) {
            const pattern = match[0];

            const part = text.substring(0, match.index);

            result.push((<Text key={`links-${part}-${result.length}`}>{part}</Text>));

            result.push(
                <Text
                    key={`links-${pattern}`}
                    style={styles.link}
                    onPress={pressHandlers[pattern]}
                >
                    {titles[pattern]}
                </Text>
            );

            text = text.substring(match.index + pattern.length);
        }

        result.push((<Text key={`links-${text}-${result.length}`}>{text}</Text>));

        return result;
    };

    onChatsLinkPressed = () => {
        this.props.onChatsLinkPress && this.props.onChatsLinkPress();
    };

    onTryAgainButtonPressed = () => {
        this.props.onTryAgainButtonPress && this.props.onTryAgainButtonPress();
    };

    render() {
        return (
            <SafeAreaView forceInset={{ top: 'never', bottom: 'always' }} style={styles.container}>
                <StatusBar
                    barStyle={styles.$statusBarStyle}
                    backgroundColor={styles.$statusBarBackgroundColor}
                />
                <View style={styles.informationContainer}>
                    <Text style={styles.informationHeader}>
                        {Resources.strings['mingle-end-screen-information-header-text']}
                        </Text>
                    <Text style={styles.informationDescription}>
                        {this.renderInformationDescription()}
                    </Text>
                </View>
                <View style={styles.tryAgainButtonContainer}>
                    <Button
                        title={Resources.strings['mingle-end-screen-try-again-button-title']}
                        onPress={this.onTryAgainButtonPressed}
                    />
                </View>
            </SafeAreaView>
        );
    }
}
