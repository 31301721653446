import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  input: {
    width: 9999,
    zIndex: 9999,
    height: '100%',
    boxSizing: 'border-box',
    outlineWidth: 0,
  },
});

export default styles;
