import { BaseStore } from '@sdv/model';
import qs from 'qs';
import Actions from './actions';
import callsApi from '../call.api/api';

function createStore(id) {
  const { attendee } = qs.parse(id);

  class Store extends BaseStore {
    constructor(flux) {
      super();

      const actions = flux.getActions(Actions, id);

      this.bindAction(actions.dial, this.dial);
    }

    dial = ({ audioOnly, requestRemoteVideo }) => {
      callsApi.emit('command.chat.media.start', attendee, {
        audioOnly,
        // audio: audioOnly,
        in: requestRemoteVideo,
        // in: audioOnly || requestRemoteVideo,
        out: true,
      });
    };
  }

  Store.displayName = createStore.getDisplayName(id);

  return Store;
}

createStore.getDisplayName = function(id) {
  return `call-calling.${id}`;
};

export default createStore;
