import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'react-native';
import Resources from 'dating-mobile/src/resources';

export default class UserName extends React.PureComponent {
    static displayName = 'user.common.views.user-name.view';
    static propTypes = {
        userId: PropTypes.string,
        name: PropTypes.string,
        showAge: PropTypes.bool,
        format: PropTypes.string
    };

    render() {
        const user = this.props.user || {};
        const userName = user.name || '';
        const userAge = this.props.user && this.props.user.birthday && this.props.user.birthday.age;
        const format = this.props.format || (this.props.showAge && userAge ? Resources.strings["user-name-with-age-format"] : '{0}');
        const text = Resources.strings.formatString(format, userName, userAge);
        return (<Text {...this.props}>{text}</Text>);
    }
}
