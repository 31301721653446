import { map, distinctUntilChanged, tap } from 'rxjs/operators';
import { Subject, merge, EMPTY } from 'rxjs';
import { singleton } from '@sdv/commons/utils/singleton';
import UserTags from '@sdv/domain/user/tags';
import { USER_TAGS } from '@sdv/domain/user/tags/user-tags';

export class UserPrivacy {
    static shared = singleton(id => new UserPrivacy(id));

    constructor(user) {
        this.user = user;
        this.lastValue = new Subject();
        this.isLoading = false;

        const userHidden = UserTags.shared()
            .tagsOf(user)
            .pipe(map(tags => tags.includes(USER_TAGS.HIDDEN)));

        this.userHidden = merge(userHidden, this.lastValue).pipe(distinctUntilChanged());
    }

    finishLoading = () => {
        this.isLoading = false;
    };

    setPresentInFeed = hidden => {
        if (this.isLoading) {
            return EMPTY;
        }

        this.isLoading = true;
        this.lastValue.next(hidden);

        return UserTags.shared()
            .setTagEnabledForUser(USER_TAGS.HIDDEN, hidden, this.user)
            .pipe(tap(this.finishLoading, this.finishLoading));
    };
}
