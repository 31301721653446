import React from 'react';
import PropTypes from 'prop-types';
import tipsList from '../../assets/tips';

function controller(Component) {

    class Controller extends React.Component {
        static displayName = 'tips.tips.controller';
        static propTypes = {
            showTip: PropTypes.func
        };

        onTipSelected = (tip) => {
            this.props.showTip && this.props.showTip({
                source: tip.source
            });
        };

        render() {
            return (
                <Component
                    {...this.props}
                    tips={tipsList}
                    onTipSelect={this.onTipSelected}
                />
            );
        }
    }

    return Controller;

}

export default controller;
