import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({

    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'flex-start',
        padding: 8,
        backgroundColor: '$brandNotificationsColor',
        borderRadius: 21,
        paddingVertical: 6,
        paddingHorizontal: 8,
    },

    containerFavorite: {
        backgroundColor: '$shadowColor',
    },

    title: {
        fontSize: 14,
        fontWeight: 'bold',
        color: '$brandTextColor',
        marginHorizontal: 8,
        '@flavor lovinga': {
            color: '$minorAccentTextColor'
        }
    },

    followIcon: {
        tintColor: '$brandTextColor',
        '@flavor lovinga': {
            tintColor: '$minorAccentTextColor'
        }
    },

    followIconFavorite: {
        tintColor: '$brandNotificationsColor',
    },

    thumbnail: {
        width: 28,
        height: 28,
        borderRadius: 14,
        borderWidth: 1,
        borderColor: '$brandTextColor',
        '@flavor lovinga': {
            borderColor: '$minorAccentTextColor'
        }
    }
});

export default styles;
