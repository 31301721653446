import React from 'react';
import PropTypes from 'prop-types';
import { StatusBar, Text, TouchableOpacity, View } from 'react-native';
import { SafeAreaView } from 'react-navigation';
import ProfileActionButtonsView from 'dating-mobile/src/user/profile/views/action-buttons';
import Gallery from 'dating-mobile/src/user/gallery/views/gallery';
import testId from 'dating-mobile/src/utils/test-id';
import Resources from 'dating-mobile/src/resources';

import styles from './styles';

export default class ScreenView extends React.Component {
  static displayName = 'gallery.screen.view';

  static propTypes = {
    id: PropTypes.string.isRequired,
    itemsOrder: PropTypes.array,
    basename: PropTypes.string,
    isPrivate: PropTypes.bool,
    togglePrivateButtonVisible: PropTypes.bool,
    onTogglePrivateButtonPressed: PropTypes.func,
    onCheersButtonPress: PropTypes.func,
    onVisibleItemChanged: PropTypes.func,
    privatePhotosEnabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    isPreview: PropTypes.bool,
    identity: PropTypes.string,
  };

  render() {
    const {
      id,
      basename,
      onVisibleItemChanged,
      itemsOrder,
      identity,
      privatePhotosEnabled,
      togglePrivateButtonVisible,
      onTogglePrivateButtonPressed,
      isPrivate,
      isLoading,
      isPreview,
    } = this.props;

    return (
      <View style={styles.container}>
        <StatusBar
          hidden={false}
          barStyle={styles.$statusBarStyle}
          backgroundColor={styles.$statusBarBackgroundColor}
        />
        <Gallery
          id={id}
          basename={basename}
          fullscreen
          onVisibleItemChanged={onVisibleItemChanged}
          itemsOrder={itemsOrder}
          isLoading={isLoading}
        />
        {!isPreview && (
          <SafeAreaView
            forceInset={{ top: 'never', bottom: 'always' }}
            style={styles.actionButtonsContainer}
          >
            <ProfileActionButtonsView userId={identity} attendeeId={id} />
          </SafeAreaView>
        )}
        {!!privatePhotosEnabled && (
          <SafeAreaView
            forceInset={{ top: 'never', bottom: 'always' }}
            style={styles.tagsContainer}
          >
            {styles.$privateTagButtonVisible && !!togglePrivateButtonVisible && (
              <TouchableOpacity
                style={styles.togglePrivateButton}
                onPress={onTogglePrivateButtonPressed}
              >
                <Text
                  style={
                    isPrivate
                      ? styles.unsetPrivateButtonText
                      : styles.setPrivateButtonText
                  }
                  {...testId(
                    isPrivate
                      ? Resources.strings[
                          'media-remove-private-tag-accessibility-label'
                        ]
                      : Resources.strings[
                          'media-add-private-tag-accessibility-label'
                        ],
                  )}
                >
                  {isPrivate
                    ? Resources.strings[
                        'gallery-screen-remove-private-tag-button-title'
                      ]
                    : Resources.strings[
                        'gallery-screen-add-private-tag-button-title'
                      ]}
                </Text>
              </TouchableOpacity>
            )}
          </SafeAreaView>
        )}
      </View>
    );
  }
}
