import StreamsActions from './actions';
import Config from 'dating-mobile/src/models/config/model';

function createStreamsStore(id = 'default') {
    class StreamsStore {
        constructor(flux) {
            const actions = flux.getActions(StreamsActions);
            this.flux = flux;
            this.state = {
                streams: [],
                cooperativeStreams: [],
                streamsLoaded: false,
                topicCategories: {},
            };

            this.bindAction(actions.set, this.set);
            this.bindAction(actions.setAll, this.setAll);
            this.bindAction(actions.add, this.add);
            this.bindAction(actions.addUnfiltered, this.addUnfiltered);
            this.bindAction(actions.remove, this.remove);
            this.bindActions(actions.update, this.update);
        }

        setAll = streams => {
            this.setState({
                allStreams: streams.filter(stream => stream.promo === 0).map(stream => stream.id),
            });
        };

        set = streams => {
            const cooperativeStreams = streams
                .filter(stream => stream.attached)
                .map(stream => stream.id);

            const topicCategories = this.getStreamsWithTopics(streams);

            this.setState({
                cooperativeStreams: cooperativeStreams,
                streams: streams.map(stream => stream.id),
                topicCategories: topicCategories,
                streamsLoaded: true,
            });
        };

        add = payload => {
            let state = {};

            const updatedCoStreams = this.updateCooperativeStreams(payload);
            if (updatedCoStreams) {
                state.cooperativeStreams = updatedCoStreams;
            }

            if (this.state.streams.indexOf(payload.id) === -1) {
                const streams = this.state.streams.slice();
                streams.push(payload.id);
                state.streams = streams;
                if (payload.tags?.length > 0) {
                    const categories = Object.assign({}, this.state.topicCategories);

                    payload.tags.map(topic => {
                        categories[topic]?.push(payload.id);
                    });
                    state.topicCategories = categories;
                }

                this.setState(state);
            }
        };

        addUnfiltered = payload => {
            if (payload.promo === 0 && this.state.allStreams?.indexOf(payload.id) === -1) {
                const streams = this.state.allStreams.slice();
                streams.push(payload.id);
                this.setState({ allStreams: streams });
            }
        };

        remove = payload => {
            // TODO: не выполнять операции ниже, если такого стрима уже нет.
            const streams = this.state.streams.filter(streamId => streamId !== payload.id);
            let state = { streams: streams };

            const cooperativeStreamIndex = this.state.cooperativeStreams.indexOf(payload.id);

            if (cooperativeStreamIndex >= 0) {
                const cooperativeStreams = this.state.cooperativeStreams.slice();
                cooperativeStreams.splice(cooperativeStreamIndex, 1);
                state.cooperativeStreams = cooperativeStreams;
            }

            const categories = Object.assign({}, this.state.topicCategories);
            state.topicCategories = Object.keys(categories).reduce(
                (acc, topic) => ({
                    ...acc,
                    [topic]: categories[topic].filter(stream => stream !== payload.id),
                }),
                {},
            );

            this.setState(state);
        };

        update = payload => {
            const updatedCoStreams = this.updateCooperativeStreams(payload);
            if (updatedCoStreams) {
                this.setState({ cooperativeStreams: updatedCoStreams });
            }
        };

        getStreamsWithTopics = streams => {
            const availableTopics = this.flux.get(Config).store.getState()[
                'available-stream-topics'
            ];

            let topicCategories = {};
            availableTopics.map(topic => {
                topicCategories[topic] =
                    streams
                        .filter(stream => stream?.tags?.indexOf(topic) >= 0)
                        .map(stream => stream.id) || [];
            });
            return topicCategories;
        };

        updateCooperativeStreams = payload => {
            const cooperativeStreamIndex = this.state.cooperativeStreams.indexOf(payload.id);
            if (cooperativeStreamIndex === -1) {
                if (payload.attached) {
                    const cooperativeStreams = this.state.cooperativeStreams.slice();
                    cooperativeStreams.push(payload.id);
                    return cooperativeStreams;
                }
            } else if (!payload.attached) {
                const cooperativeStreams = this.state.cooperativeStreams.slice();
                cooperativeStreams.splice(cooperativeStreamIndex, 1);
                return cooperativeStreams;
            }
            return;
        };
    }

    StreamsStore.displayName = createStreamsStore.getDisplayName(id);

    return StreamsStore;
}

createStreamsStore.getDisplayName = function(id) {
    return `dialogs-streams.${id}`;
};

export default createStreamsStore;
