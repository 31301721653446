import withConfigValue from 'dating-mobile/src/components/config-value';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';
import withWinker from 'dating-mobile/src/models/dialogs.messages/controllers/winker';
import withUserPresence from 'dating-mobile/src/models/user.presence/controllers';
import View from './view';

export default withIdentityId(
    withConfigValue(
        withWinker(
            withUserPresence(View, {
                userIdPropName: 'attendeeId',
            }),
            {
                winkDelayPropName: 'winkDelay',
                attendeeIdPropName: 'attendeeId',
            },
        ),
        {
            winkDelay: 'wink-delay.chat',
            winkEnabled: 'features.user-chat-wink-enabled',
        },
    ),
);
