import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FlatList } from 'react-native';

import { UserTags } from '@sdv/domain/user/tags/user-tags';
import withHasTag from 'dating-mobile/src/models/user.tags/controllers/has-tag';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';

import { PackageListItem } from './item';

import styles from './styles';

class PackageListComponent extends PureComponent {
  static displayName = 'payment.views.package-list-horizontal';

  static propTypes = {
    packages: PropTypes.array,
    selectedPackageIndex: PropTypes.number,
    onPackagePress: PropTypes.func,
    style: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    premiumMembershipIsActive: PropTypes.bool,
    membershipIsInactive: PropTypes.bool,
    callsPackIsActive: PropTypes.bool,
    premiumMembershipDiscountUsed: PropTypes.bool,
  };

  static defaultProps = {
    onPackagePress() {},
  };

  renderItem = ({ item, index }) => {
    const {
      selectedPackageIndex,
      onPackagePress,
      premiumMembershipIsActive,
      membershipIsInactive,
      callsPackIsActive,
      premiumMembershipDiscountUsed,
    } = this.props;

    return (
      <PackageListItem
        selected={selectedPackageIndex === index}
        product={item[0]}
        invoices={item[1]}
        onPress={() => onPackagePress(index)}
        premiumMembershipIsActive={premiumMembershipIsActive}
        membershipIsActive={!membershipIsInactive}
        callsPackIsActive={callsPackIsActive}
        premiumMembershipDiscountUsed={premiumMembershipDiscountUsed}
      />
    );
  };

  render() {
    const { packages, style, selectedPackageIndex } = this.props;

    return (
      <FlatList
        horizontal
        alwaysBounceHorizontal={false}
        style={style}
        contentContainerStyle={styles.container}
        data={packages}
        extraData={selectedPackageIndex}
        renderItem={this.renderItem}
        keyExtractor={item => item?.[1]?.[0]?.sku}
      />
    );
  }
}

export const PackageList = withIdentityId(
  withHasTag(
    withHasTag(
      withHasTag(
        withHasTag(PackageListComponent, {
          tag: UserTags.PremiumMembership,
          userIdPropName: 'id',
          hasTagPropName: 'premiumMembershipIsActive',
        }),
        {
          tag: UserTags.PremiumMembershipDiscount,
          userIdPropName: 'id',
          hasTagPropName: 'premiumMembershipDiscountUsed',
        },
      ),
      {
        tag: UserTags.Membership,
        userIdPropName: 'id',
        hasTagPropName: 'membershipIsInactive',
      },
    ),
    {
      tag: UserTags.CallsPackIsActive,
      userIdPropName: 'id',
      hasTagPropName: 'callsPackIsActive',
    },
  ),
  'id',
);
