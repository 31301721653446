import Actions from '../actions';
import ProductConfigModel from '../product';
import Pages from './page';

class ConfigStore {
    static displayName = 'config';

    constructor(flux) {
        const actions = flux.getActions(Actions);

        this.state = {
            page: null,
            config: {}
        };

        this.flux = flux;
        this.productConfigModel = null;
        this.pageStore = null;

        this.bindAction(actions.change, this.change);

        this.on('init', () => {
            this.productConfigModel = flux.get(ProductConfigModel);
            this.productConfigModel.store.listen(this.actualize);
            this.state.config = this.getConfig();
        });

        this.on('bootstrap', (state) => {
            this.bindPageStore(state.page);
        });
    }

    change = (page) => {
        this.pageStore && this.pageStore.unlisten(this.actualize);
        this.pageStore = null;

        this.bindPageStore(page);
    };

    actualize = () => {
        this.state.config = this.getConfig();
        this.emitChange();
    };

    getConfig() {
        const page = this.pageStore ? this.pageStore.getState() : {};
        return { ...this.productConfigModel.store.getState(), ...page };
    }

    bindPageStore(page) {

        if (page) {
            const PageStore = Pages[page];
            this.pageStore = this.flux.getStore(PageStore.displayName)
                || this.flux.createStore(PageStore.displayName, null, flux);
            this.pageStore.listen(this.actualize);
        }

        this.setState({
            page: page,
            config: this.getConfig()
        });

    };

    output(state) {
        return { ...state.config };
    }

    publicMethods = {
        getPage() {
            return this.state.page;
        }
    };
}

ConfigStore.config = {
    getState(state) {
        return { ...state.config };
    },
    onSerialize(state) {
        return { page: state.page };
    },
    onDeserialize(state) {
        return { page: state.page };
    }
};

export default ConfigStore;
