import React from 'react';
import PropTypes from 'prop-types';
import { StatusBar, StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-navigation';
import testId from 'dating-mobile/src/utils/test-id';
import ImageButton from '../../../../components/navigation/views/image-button';
import Resources from '../../../../resources';
import Interest from '../../views/interests';
import styles from './styles';

class InterestsEditing extends React.PureComponent {
  static displayName = 'user.edit.interests-editing';

  static propTypes = {
    navigation: PropTypes.object.isRequired,
    dataType: PropTypes.string,
    selectedItems: PropTypes.array,
    onComplete: PropTypes.func,
  };

  static navigationOptions = ({ navigation, navigationOptions }) => ({
    title: navigation.getParam('title'),
    headerLeft: () => (
      <ImageButton
        image={Resources.images.backIcon()}
        tint={StyleSheet.flatten(navigationOptions.headerTitleStyle).color}
        onPress={() => {
          const goBack = navigation.getParam('goBack');

          if (goBack) {
            goBack();
          } else {
            navigation.pop();
          }
        }}
        {...testId('Back button')}
      />
    ),
  });

  constructor(props) {
    super(props);
    this.state = { selectedItems: this.getDefaultSelectedItems() };
  }

  componentDidMount() {
    const { navigation } = this.props;

    navigation.setParams({ goBack: this.goBack });
  }

  selectItem = items => {
    this.setState({ selectedItems: items });
  };

  getDataType = () => {
    const { navigation, dataType } = this.props;

    return dataType || navigation.getParam('dataType');
  };

  getDefaultSelectedItems = () => {
    const { navigation, selectedItems } = this.props;

    return selectedItems || navigation.getParam('selectedItems') || [];
  };

  goBack = () => {
    const { navigation, onComplete } = this.props;
    const { selectedItems } = this.state;

    if (selectedItems.sort() !== this.getDefaultSelectedItems().sort()) {
      const completion = onComplete || navigation.getParam('onComplete');

      completion(selectedItems);
    }

    navigation.pop();
  };

  render() {
    const { selectedItems } = this.state;

    return (
      <SafeAreaView
        forceInset={{ top: 'never', bottom: 'always' }}
        style={styles.container}
      >
        <StatusBar
          barStyle={styles.$statusBarStyle}
          backgroundColor={styles.$statusBarBackgroundColor}
        />
        <Interest
          dataType={this.getDataType()}
          selected={selectedItems}
          onPressItem={this.selectItem}
        />
      </SafeAreaView>
    );
  }
}

export default InterestsEditing;
