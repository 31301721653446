import React from 'react';
import PropTypes from 'prop-types';
import StreamFollowers from '../model';

const CreateStreamFollowersController = Component => {
    class StreamFollowersComponent extends React.Component {
        static displayName = 'dialog.stream.followers.controller';
        static contextTypes = {
            flux: PropTypes.object,
        };

        constructor(props, context) {
            super(props);
            this.model = context.flux.get(StreamFollowers, this.props.id);
            this.state = this.model.store.getState();
        }

        componentDidMount() {
            this.model.store.listen(this.update);
            this.model.actions.get();
        }

        componentDidUpdate(prevProps, prevState) {
            if (prevProps.id !== this.props.id) {
                this.model.store.unlisten(this.update);
                this.model = this.context.flux.get(StreamFollowers, this.props.id);
                this.model.store.listen(this.update);
                this.model.actions.get();
            }
        }

        componentWillUnmount() {
            this.model.store.unlisten(this.update);
        }

        update = state => {
            this.setState(state);
        };

        render() {
            return <Component {...this.props} {...this.state} />;
        }
    }

    return StreamFollowersComponent;
};

export default CreateStreamFollowersController;
