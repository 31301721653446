import { StreamConnectionStateChangedEventKey } from 'dating-mobile/src/services/stream-connection';
import { BehaviorSubject } from 'rxjs';
import { singleton } from '@sdv/commons/utils/singleton';
import flux from '@sdv/domain/app/flux';

class StreamConnectionMonitoring {
  static shared = singleton(() => new StreamConnectionMonitoring());

  constructor() {
    const defaultValue =
      (flux.streamConnection &&
        flux.streamConnection.websocket &&
        flux.streamConnection.websocket.isOpened) ||
      false;

    this.currentState = new BehaviorSubject(defaultValue);
    flux.streamConnection?.addListener(
      StreamConnectionStateChangedEventKey,
      this.stateUpdated,
    );
  }

  stateUpdated = isOpened => {
    this.currentState.next(isOpened);
  };
}

export default StreamConnectionMonitoring;
