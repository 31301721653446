import LocalNotifications from '@sdv/domain/notifications/local';

/**
 * @deprecated
 */
export default LocalNotifications;

/**
 * @deprecated
 */
export * from '@sdv/domain/notifications/local';
