import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    header: {
        '@flavor secretly': {
            backgroundColor: '$primaryBackgroundColor',
            borderBottomColor: '$primaryBackgroundColor',
            shadowColor: '$primaryBackgroundColor',
        },
    },
    headerTitle: {
        '@flavor secretly': {
            color: '$primaryTextColor',
        },
    },
});

export default styles;
