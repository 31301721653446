import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    followButton: {
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        '@flavor lovinga': {
            backgroundColor: 'transparent',
        },
    },

    followButtonIcon: {
        tintColor: 'white',
    },

    followButtonTitle: {},

    $buttonHasGradient: false,
    '@flavor lovinga': {
        $buttonHasGradient: true,
    },

    activeGradient: {
        '@flavor lovinga': {
            gradientColor: '$buttonGradientColor',
        },
    },

    disabledGradient: {
        gradientColor: '$brandButtonDisabledGradientColor',
        '@flavor lovinga': {
            gradientColor: '$buttonGradientColor',
        },
    },

    followStarContainer: {
        width: 50,
        height: 50,
        borderRadius: 25,
        alignItems: 'center',
        justifyContent: 'center',
        '@flavor dma': {
            width: 60,
            height: 60,
            borderRadius: 30,
        },
    },
});

export default styles;
