import MailboxStore from '../base/store';
import Actions from './actions';
import displayName from './display-name';

function createStore(id) {
    class Store extends MailboxStore {

        /**
         * @param {Alt} flux
         */
        constructor(flux) {
            super(flux, flux.getActions(Actions, id));
        }

        onNewLetterAdded(letter) {
            if (letter.recipient === id && !letter.introductory && !letter.read) {
                this.addNewLetterOrderedByTime(letter);
                this.updateModel();
            }
        }

        onLetterRead(letter) {
            if (this.letters.has(letter.id)) {
                this.letters.delete(letter.id);
                this.updateModel();
            }
        }
    }

    Store.displayName = displayName(id);

    return Store;
}

createStore.getDisplayName = displayName;

export default createStore;
