import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $placeholderColor: '$brandPlaceholderColor',

    '@flavor secretly': {
        $placeholderColor: '$secondaryTextColor',
    },

    container: {
        flex: 1,
        backgroundColor: '$brandColor',
        paddingLeft: 16,
        paddingRight: 16,
        '@flavor secretly': {
            backgroundColor: '$primaryBackgroundColor',
        },
    },

    textInputContainer: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
    },

    textInput: {
        flex: 1,
        fontSize: 17,
        fontFamily: '$brandFontFamily',
        color: '$brandTextColor',
        '@flavor secretly': {
            color: '$primaryTextColor',
        },
    },

    validationError: {
        position: 'absolute',
        right: 0,
    },
});

export default styles;
