import React from 'react';
import withTagsController from 'dating-mobile/src/models/user.tags.targeted/controllers';
import PropTypes from "prop-types";

export default function createFavoriteController(Component, options={}) {

    const userIdPropName = options.userId || 'holderId';
    const targetIdPropName = options.targetId || 'targetId';

    const displayName = 'user.tags.targeted.favorite.controller';

    const TAGS_PROP_NAME = `generated.${displayName}.tags`;
    const TAGS_LOADED_PROP_NAME = `generated.${displayName}.tagsLoaded`;
    const ADD_TAG_PROP_NAME = `generated.${displayName}.addTag`;
    const DELETE_TAG_PROP_NAME = `generated.${displayName}.deleteTag`;

    class Controller extends React.PureComponent {
        static displayName = displayName;
        static propTypes = {
            deleteTag: PropTypes.func,
            addTag: PropTypes.func,
            [TAGS_PROP_NAME]: PropTypes.shape({
                favorite: PropTypes.bool.isRequired
            }),
            [TAGS_LOADED_PROP_NAME]: PropTypes.bool,
            [ADD_TAG_PROP_NAME]: PropTypes.func,
            [DELETE_TAG_PROP_NAME]: PropTypes.func
        };

        toggleFavorite = () => {
            if (this.props[TAGS_PROP_NAME] && this.props[TAGS_PROP_NAME].favorite) {
                this.props[DELETE_TAG_PROP_NAME] && this.props[DELETE_TAG_PROP_NAME]('favorite');
            } else {
                this.props[ADD_TAG_PROP_NAME] && this.props[ADD_TAG_PROP_NAME]('favorite');
            }
        };

        render() {
            const {
                ...props
            } = this.props;

            const isFavorite = props[TAGS_LOADED_PROP_NAME]
                ? !!props[TAGS_PROP_NAME].favorite
                : undefined;

            delete props[TAGS_PROP_NAME];
            delete props[TAGS_LOADED_PROP_NAME];
            delete props[ADD_TAG_PROP_NAME];
            delete props[DELETE_TAG_PROP_NAME];

            return (<Component
                {...props}
                onPress={this.toggleFavorite}
                isFavorite={isFavorite}
            />);
        }
    }

    return withTagsController(
        Controller,
        {
            userIdPropName,
            targetIdPropName,
            resultPropName: TAGS_PROP_NAME,
            tagsLoadedPropName: TAGS_LOADED_PROP_NAME,
            addTagPropName: ADD_TAG_PROP_NAME,
            deleteTagPropName: DELETE_TAG_PROP_NAME
        }
    );
}
