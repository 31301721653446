import { Platform, Dimensions } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import shouldUseWebLayout from '../../../../utils/web-layout';

const isWebLayout = shouldUseWebLayout();
const { width, height } = Dimensions.get('window');
const isSmallDevice = width <= 700;

const styles = EStyleSheet.create({
  $showGalleryPlaceholder: true,
  $statusBarStyle: '$brandStatusBarStyle',
  $statusBarBackgroundColor: 'black',
  $bottomContainerGradientColors: ['transparent', 'transparent'],

  header: {
    backgroundColor: 'transparent',
  },

  backButton: {
    backgroundColor: '$shadowColor',
    borderRadius: 22,
    marginLeft: 16,
    marginTop: 6,
  },

  container: {
    width: '100%',
    flex: 1,
    backgroundColor: isWebLayout ? '#f8f8f8' : '$secondaryBackgroundColor',
  },

  galleryContainer: {
    width: '100%',
    maxWidth: 700,
    aspectRatio: 1,
  },

  scrollView: {
    width: '100%',
    flex: 1,
    flexGrow: 1,
  },

  scrollViewContent: Platform.select({
    web: { height: isSmallDevice ? height : 'auto' },
  }),

  housingIconContainer: {
    position: 'absolute',
    width: 36,
    height: 36,
    right: 10,
    top: 90,
    borderRadius: 18,
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center',
  },

  actionButtonsContainer: {
    width: '100%',
    paddingBottom: shouldUseWebLayout() ? 70 : 24,
  },

  closeButton: {
    backgroundColor: '#00000080',
    width: 44,
    height: 44,
    borderRadius: 22,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 16,
    marginTop: 16,
  },

  customCloseButtonContainer: {
    width: '100%',
    top: 0,
    overflow: 'visible',
    position: 'absolute',
    alignItems: 'flex-end',
  },

  footer: {
    width: '100%',
    height: 100,
  },

  bottomContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    bottom: 0,
    overflow: 'visible',
  },
});

export default styles;
