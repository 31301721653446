
class StickersActions {
    static displayName = 'dialogs-stickers';

    get = () => (dispatch, flux) => {
        flux.api.dialogs.stickers.list((error, stickers) => {
            if (error) {
                dispatch(null, error);
                return;
            }
            dispatch(stickers);
        })
    };

}

export default StickersActions;
