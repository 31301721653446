import React, {Component} from 'react';
import PropTypes from "prop-types";

import UserController from '../../user/controllers';
import UserRolesController from '../../user.roles/controller';
import Identity from '@sdv/domain/identity/model';

import Can from './can';
const CanUserComponent = [UserController, UserRolesController].reduce((view, controller) => {
    return controller(view);
}, Can);

class CanComponent extends Component {
    static displayName: '';
    static contextTypes = {
        flux: PropTypes.object
    };
    state = {};

    constructor(props, context) {
        super(props);
        this.identityModel = context.flux.get(Identity);
        this.state = this.identityModel.store.getState();
    }

    componentDidMount() {
        this.identityModel.store.listen(this.update);
    }

    componentWillUnmount() {
        this.identityModel.store.unlisten(this.update);
    }

    update = (state) => {
        this.setState(state);
    };

    render() {
        return <CanUserComponent {...this.props} id={this.state.id}/>;
    }

}

export default CanComponent;
