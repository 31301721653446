import React from 'react';
import { StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import * as ROUTES from 'dating-mobile/src/routing/router/constants';
import Resources from '../../../../resources';
import ImageButton from '../../../../components/navigation/views/image-button';

import Controller from './controller';
import View from './view';
import styles from './styles';

const ScreenView = Controller(View);

class Screen extends React.Component {
  static displayName = 'dialogs.mingle.end.screen';

  static propTypes = {
    navigation: PropTypes.object.isRequired,
  };

  static navigationOptions = ({ navigation }) => ({
    headerTitle: '',
    headerStyle: styles.header,
    headerTitleStyle: styles.headerTitle,
    headerLeft: () => null,
    headerRight: () => (
      <ImageButton
        image={Resources.images.closeIcon()}
        tint={StyleSheet.flatten(styles.headerTitle).color}
        onPress={() => navigation.pop()}
      />
    ),
  });

  close = () => {
    const { navigation } = this.props;

    navigation.pop();
  };

  showContacts = () => {
    const { navigation } = this.props;

    navigation.navigate(ROUTES.CONTACTS);
  };

  startMingle = () => {
    const { navigation } = this.props;

    navigation.navigate(ROUTES.START_MINGLE);
  };

  render() {
    return (
      <ScreenView
        showContacts={this.showContacts}
        startMingle={this.startMingle}
        close={this.close}
      />
    );
  }
}

export default Screen;
