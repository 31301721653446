import React from 'react';
import PropTypes from 'prop-types';
import { render } from 'dating-mobile/src/components/rx-render';
import { ChatAnonymity } from '@sdv/domain/chat/chat-anonymity';
import Resources from 'dating-mobile/src/resources';

export default function bind(Component) {

    const Binding = render((props, { useDistinct, useObservable }) => {
        const chatAnonymity = useDistinct((...args) => new ChatAnonymity(...args), props.userId, props.attendeeId);
        const writingAsAnonymous = useObservable(it => it.writingAsAnonymous, chatAnonymity);

        const nameFormat = writingAsAnonymous
            ? Resources.strings['anonymous-chat-writing-as-anonymous-notice']
            : Resources.strings['anonymous-chat-writing-as-real-user-notice-format'];

        const info = writingAsAnonymous
            ? Resources.strings['anonymous-chat-writing-as-anonymous-notice-info']
            : null;

        return (
            <Component
                {...props}
                nameFormat={nameFormat}
                info={info}
            />
        );
    });

    Binding.displayName = 'dialogs.messages.views.anonymous-chat-notice.controller';

    Binding.propTypes = {
        userId: PropTypes.string.isRequired,
        attendeeId: PropTypes.string.isRequired
    };

    return Binding;

}
