import React from 'react';
import PropTypes from 'prop-types';
import { View, FlatList } from 'react-native';
import StickerItem from './item';
import styles from './styles';

export default class Stickers extends React.PureComponent {
    static displayName = 'dialogs.common.stickers';
    static propTypes = {
        stickers: PropTypes.arrayOf(PropTypes.shape({
            basename: PropTypes.string.isRequired
        })),
        onStickerSelect: PropTypes.func
    };

    onStickerPressed = (sticker) => {
        this.props.onStickerSelect && this.props.onStickerSelect(sticker);
    };

    renderItem = ({item}) => {
        return (
            <View key={item.basename} style={styles.itemContainer}>
                <StickerItem sticker={item.basename} selected={false} onPress={this.onStickerPressed} />
            </View>
        )
    };

    itemSeparator = () => {
        return <View style={styles.separator}/>
    };

    render() {
        return (
            <View style={styles.container}>
                <FlatList
                    style={styles.list}
                    renderItem={this.renderItem}
                    data={this.props.stickers || []}
                    numColumns={4}
                    keyExtractor={item => item.basename}
                    ItemSeparatorComponent={this.itemSeparator}
                />
            </View>
        )
    }
}
