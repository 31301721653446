import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native-animatable';

export class AnimatedAppearing extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    isVisible: PropTypes.bool,
    appearingAnimationName: PropTypes.string,
    disappearingAnimationName: PropTypes.string,
    animationDuration: PropTypes.number,
  };

  static defaultProps = {
    isVisible: false,
    appearingAnimationName: 'fadeIn',
    disappearingAnimationName: 'fadeOut',
    animationDuration: 1000,
  };

  constructor(props) {
    super(props);

    this.state = {
      visible: props.isVisible,
    };
  }

  componentDidUpdate(prevProps) {
    const { isVisible } = this.props;

    if (isVisible !== prevProps.isVisible) {
      this.animate();
    }
  }

  handleViewRef = ref => {
    this.view = ref;
  };

  async animate() {
    const {
      isVisible,
      animationDuration,
      appearingAnimationName,
      disappearingAnimationName,
    } = this.props;

    const animationName = isVisible
      ? appearingAnimationName
      : disappearingAnimationName;

    if (isVisible) {
      this.setState({
        visible: true,
      });
    }

    if (this.view && animationName && animationDuration) {
      await this.view[animationName](animationDuration);
    }

    if (!isVisible) {
      this.setState({
        visible: false,
      });
    }
  }

  render() {
    const {
      children,
      appearingAnimationName,
      disappearingAnimationName,
      animationDuration,
      isVisible,
      ...props
    } = this.props;
    const { visible } = this.state;

    return (
      <View ref={this.handleViewRef} {...props}>
        {visible ? children : null}
      </View>
    );
  }
}
