import Service from '../../service';
import PropTypes from 'prop-types';
import withIdentityId from '../../../models/identity/controller/id';
import withConfigValue from '../../../components/config-value';

class FingerprintSender extends Service {
    static displayName = 'service.fingerprint-sender';
    static propTypes = {
        identity: PropTypes.string,
        fingerprint: PropTypes.string
    };
    static contextTypes = {
        flux: PropTypes.object
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.identity !== this.props.identity && nextProps.identity) {
            this.context.flux.api.users.devices.post(nextProps.identity, [{
                name: 'fingerprint',
                meta: {
                    value: nextProps.fingerprint
                }
            }], () => {});
        }
    }

}

export default withIdentityId(
    withConfigValue(
        FingerprintSender,
        {
            fingerprint: 'fingerprint'
        }
    ),
    'identity'
);
