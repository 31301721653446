import React from 'react';
import PropTypes from 'prop-types';
import { Image, View } from 'react-native';
import { getBadgeDisplayText } from 'dating-mobile/src/dialogs/common/utils/badge-display-text';
import NotificationBadge from 'dating-mobile/src/components/notification-badge';
import {
  contacts as withContacts,
  chatRequests as withChatRequests,
  likes as withLikes,
} from 'dating-mobile/src/models/user.events/controllers/events';
import withIdentityId from '../../../models/identity/controller/id';
import withUnreadRequests from '../../../models/user.events/controllers/has-unread-requests';
import withConfigValue from '../../../components/config-value';
import Resources from '../../../resources';
import styles from './styles';

class ChatsTabBarItem extends React.Component {
  static displayName = 'component.chats-tab-bar-item';

  static propTypes = {
    contacts: PropTypes.array,
    chatRequests: PropTypes.array,
    hasUnreadRequests: PropTypes.bool,
    hasUnreadNonAutoRequests: PropTypes.bool,
    chatRequestsEnabled: PropTypes.bool,
    chatRequestsInSeparateTab: PropTypes.bool,
    automaticInvitationsEnabled: PropTypes.bool,
    focused: PropTypes.bool.isRequired,
    withoutCounter: PropTypes.bool,
    likesEnabled: PropTypes.bool,
    likes: PropTypes.array,
  };

  render() {
    const {
      contacts,
      chatRequestsEnabled,
      chatRequestsInSeparateTab,
      chatRequests,
      hasUnreadNonAutoRequests,
      automaticInvitationsEnabled,
      likesEnabled,
      likes,
      focused,
      withoutCounter,
      hasUnreadRequests,
    } = this.props;
    const numberOfUnreadMessagesFromContacts = (contacts || []).reduce(
      (a, b) => a + b.stack,
      0,
    );

    const numberOfUnreadRequests = (chatRequests || []).reduce(
      (a, b) => a + b.stack,
      0,
    );

    const totalEvents =
      numberOfUnreadMessagesFromContacts + numberOfUnreadRequests;

    const hasRequests =
      chatRequestsEnabled &&
      !chatRequestsInSeparateTab &&
      numberOfUnreadRequests > 0 &&
      (hasUnreadNonAutoRequests ||
        (automaticInvitationsEnabled && hasUnreadRequests));

    const hasLikes =
      likesEnabled && (likes || []).reduce((a, b) => a + b.stack, 0);

    let text = null;

    if (totalEvents > 0) {
      text = getBadgeDisplayText(totalEvents);
    } else if (hasRequests || hasLikes) {
      text = '!';
    }

    const imageStyle = [
      styles.icon,
      focused ? styles.activeIcon : styles.inactiveIcon,
    ];

    const source = focused
      ? Resources.images.tabBarChat()
      : Resources.images.tabBarChatDisabled();

    return (
      <View style={styles.container}>
        <Image style={imageStyle} source={source} />
        {!!text && (
          <NotificationBadge
            style={
              withoutCounter ? styles.iconWithoutText : styles.textContainer
            }
            text={withoutCounter ? '' : text}
            textStyle={styles.text}
          />
        )}
      </View>
    );
  }
}

export default withIdentityId(
  withChatRequests(
    withUnreadRequests(
      withContacts(
        withConfigValue(withLikes(ChatsTabBarItem), {
          chatRequestsEnabled: 'features.chat-requests-enabled',
          automaticInvitationsEnabled: 'features.automatic-invitations-enabled',
          chatRequestsInSeparateTab:
            'markup.chat-requests-separate-tab-enabled',
          likesEnabled: 'features.likes-list-enabled',
        }),
      ),
      {
        resultPropName: 'hasUnreadRequests',
        nonAutoResultPropName: 'hasUnreadNonAutoRequests',
      },
    ),
  ),
);
