import React from 'react';
import PropTypes from 'prop-types';
import { View, ScrollView, StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-navigation';
import { GALLERY } from 'dating-mobile/src/routing/router/constants';
import Config from 'dating-mobile/src/app/config';
import Resources from '../../../../resources';
import UserEditName from '../../../common/views/edit/name';
import ShortMediaList from '../../views/short-media-list';
import SwitcherConfigValue from '../../../../components/config-value/switcher';
import ProfileParametersList from '../../views/profile-parameters-view';
import GalleryView from '../../../profile/views/photo-gallery';
import { GALLERY_ITEMS } from '../../../../models/user.media/controller/gallery-controller';
import ImageButton from '../../../../components/navigation/views/image-button';
import testId from '../../../../utils/test-id';
import styles from './styles';

class EditUserScreen extends React.Component {
  static displayName = 'user.edit.screen';

  static propTypes = {
    navigation: PropTypes.object,
  };

  static navigationOptions = ({ navigation, navigationOptions }) => ({
    title: Resources.strings['account-screen-title'],
    headerStyle: [navigationOptions.headerStyle, styles.header],
    headerTitleStyle: [navigationOptions.headerTitleStyle, styles.headerTitle],
    headerLeft: () => (
      <ImageButton
        image={Resources.images.backIcon()}
        tint={
          StyleSheet.flatten([
            navigationOptions.headerTitleStyle,
            styles.headerTitle,
          ]).color
        }
        onPress={() => navigation.pop()}
        {...testId('Back button')}
      />
    ),
  });

  state = {
    privatePhotosEnabled: false,
  };

  componentDidMount() {
    this.subscription = Config.shared()
      .paramWithPath('features.private-photos-enabled')
      .subscribe(privatePhotosEnabled =>
        this.setState({ privatePhotosEnabled }),
      );
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  openMedia = item => {
    const { navigation } = this.props;

    navigation.navigate(GALLERY, {
      id: navigation.getParam('id'),
      basename: item.basename,
    });
  };

  render() {
    const { navigation } = this.props;
    const { privatePhotosEnabled } = this.state;
    const id = navigation.getParam('id');

    const itemsOrder = privatePhotosEnabled
      ? [
          GALLERY_ITEMS.PUBLIC_PHOTOS,
          GALLERY_ITEMS.PRIVATE_PHOTOS,
          GALLERY_ITEMS.VIDEOS,
        ]
      : [GALLERY_ITEMS.PUBLIC_PHOTOS, GALLERY_ITEMS.VIDEOS];

    const fullMediaList = (
      <View style={styles.galleryContainer}>
        <GalleryView
          id={id}
          editingEnabled
          itemsOrder={itemsOrder}
          open={this.openMedia}
        />
      </View>
    );

    return (
      <SafeAreaView
        forceInset={{ top: 'never', bottom: 'always' }}
        style={styles.container}
      >
        <ScrollView alwaysBounceVertical={false}>
          <SwitcherConfigValue path="features.extended-profile-enabled">
            {value => {
              return value ? fullMediaList : <ShortMediaList id={id} />;
            }}
          </SwitcherConfigValue>

          <SwitcherConfigValue path="features.extended-profile-enabled">
            {value => {
              return value ? (
                <View style={styles.editingContainer}>
                  <ProfileParametersList id={id} navigation={navigation} />
                </View>
              ) : (
                <View style={styles.property}>
                  <UserEditName id={id} />
                </View>
              );
            }}
          </SwitcherConfigValue>
        </ScrollView>
      </SafeAreaView>
    );
  }
}

export default EditUserScreen;
