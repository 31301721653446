import flux from '@sdv/domain/app/flux';
import { bindNodeCallback } from 'rxjs';
import { EmailValidation } from '@sdv/domain/authorization/email-validation';
import { switchMap } from 'rxjs/operators';
import Identity from '@sdv/domain/identity/model';
import guid from '@sdv/commons/utils/guid';

export class SigningUp {
    signUp = email => {
        const signUp = bindNodeCallback(flux.get(Identity).actions.signUp);

        return new EmailValidation()
            .validateEmail(email)
            .pipe(switchMap(() => signUp(email, guid())));
    };
}
