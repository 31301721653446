import React from 'react';
import PropTypes from 'prop-types';

function controller(Component) {

    class Controller extends React.Component {
        static displayName = 'components.webview.screen.controller';
        static propTypes = {
            onCancel: PropTypes.func,
            onRedirect: PropTypes.func,
            setNavigationBarState: PropTypes.func,
            close: PropTypes.func,
            navigation: PropTypes.object,
        };

        componentDidMount() {
            this.props.setNavigationBarState && this.props.setNavigationBarState({
                onCloseButtonPress: this.onCloseButtonPress
            });
        }

        onCloseButtonPress = () => {
            this.props.close && this.props.close();
            this.props.onCancel && this.props.onCancel();
        };

        onRedirect = (url) => {
            if (this.props.onRedirect && this.props.onRedirect(url)) {
                this.props.close && this.props.close();
            }
        };

        render() {
            const { ...props } = this.props;
            delete props.onRedirect;
            delete props.setNavigationBarState;
            delete props.onCancel;
            delete props.close;
            delete props.navigation;

            return (<Component
                {...props}
                onRedirect={this.onRedirect}
            />);
        }
    }

    return Controller;
}

export default controller;
