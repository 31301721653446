import Resources from 'dating-mobile/src/resources';

const ONE_SECOND = 1000;
const ONE_MINUTE = ONE_SECOND * 60;
const ONE_HOUR = ONE_MINUTE * 60;

const padZero = num => num.toString().padStart(2, '0');

// [minutes, seconds]
function parseTimeShort(ms) {
  if (!ms) {
    return [0, 0];
  }

  const secInMin = 60;
  const msInSec = 1000;
  const sec = Math.floor(ms / msInSec);

  return [Math.floor(sec / secInMin), sec % secInMin];
}

// [hours, minutes, seconds]
function parseTimeLong(ms) {
  if (!ms) {
    return [0, 0, 0];
  }

  let hours = Math.floor(ms / ONE_HOUR);
  let minutes = Math.floor((ms - hours * ONE_HOUR) / ONE_MINUTE);
  let seconds = Math.ceil(
    (ms - hours * ONE_HOUR - minutes * ONE_MINUTE) / ONE_SECOND,
  );

  if (seconds >= 60) {
    minutes += 1;
    seconds -= 60;
  }

  if (minutes >= 60) {
    hours += 1;
    minutes -= 60;
  }

  return [hours, minutes, seconds];
}

export function formatTimeNumericLong(time) {
  return parseTimeLong(time)
    .map(padZero)
    .join(':');
}

export function formatTimeNumericShort(time) {
  return parseTimeShort(time)
    .map(padZero)
    .join(':');
}

export function formatTimeTextual(time) {
  return Resources.strings.formatString(
    Resources.strings['timer-remaining-time'],
    ...parseTimeShort(time),
  );
}
