import React from 'react';
import PropTypes from 'prop-types';
import StreamsModel from '../model';

const createStreamsController = (Component) => {

    class StreamsComponent extends React.Component {
        static contextTypes = {
            flux: PropTypes.object,
        };

        constructor(props, context) {
            super(props);
            this.model = context.flux.get(StreamsModel);
            this.state = this.model.store.getState();
        }

        componentDidMount() {
            this.model.store.listen(this.updateState);
            this.model.actions.get();
        }

        componentWillUnmount() {
            this.model.store.unlisten(this.updateState);
        }

        updateState = (newState) => {
            this.setState(newState);
        };

        render() {
            return (
                <Component {...this.props} {...this.state}/>
            );
        }
    }

    StreamsComponent.displayName = 'dialogs.streams.controller';

    return StreamsComponent;
};

export default createStreamsController;
