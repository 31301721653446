export default function createActions(userId) {

    class Actions {
        actualize = () => (dispatch, flux) => {
            const body = {
                types: '+introductory+letter',
                fresh: true
            };
            flux.api.users.events.get(
                userId,
                body,
                (error, response) => {
                    if (!error) {
                        const counter = (response['introductory-fresh'] || 0) + (response['letter-fresh'] || 0);
                        dispatch(counter, error);
                    }
                    dispatch(null, error);
                },
                {
                    Accept: 'application/json+vnd.sdv.numeric'
                }
            );
        };
    }

    Actions.displayName = createActions.getDisplayName(userId);

    return Actions;
}

createActions.getDisplayName = function (id) {
    return `unread-letter-counter.${id}`;
};
