import UserDefaults from 'react-native-default-preference';
import { from, defer } from "rxjs";
import { map } from 'rxjs/operators';
import { singleton } from '@sdv/commons/utils/singleton';

/**
 * @deprecated use Persistence instead
 */
export default class UserDefaultsPersistence {
    static shared = singleton((scope, userId) => new UserDefaultsPersistence(scope, userId));

    constructor(scope, userId) {
        const scopePart = scope || '';
        const userIdPart = userId || '';
        let additionToKey = userIdPart;
        if (additionToKey.length > 0) {
            additionToKey = additionToKey + '.' + scopePart;
        } else {
            additionToKey = scopePart;
        }
        this.additionToKey = additionToKey;
    }

    getFullKey = (key) => this.additionToKey + '.' + key;

    store = (key, value) => {
        return UserDefaults.set(this.getFullKey(key), value);
    };

    value = (key) => {
        return UserDefaults.get(this.getFullKey(key));
    };

    rxValue = (key) => defer(() => from(this.value(key)));
    rxStore = (key, value) => defer(() => from(this.store(key, value)));

    load = () => console.error(`Persistence.load() method not exists in UserDefaultsPersistence`);
}

export class Persistence {
    static shared = singleton((scope, userId) => new Persistence(scope, userId));

    constructor(scope, userId) {
        const scopePart = scope || '';
        const userIdPart = userId || '';
        let additionToKey = userIdPart;
        if (additionToKey.length > 0) {
            additionToKey = additionToKey + '.' + scopePart;
        } else {
            additionToKey = scopePart;
        }
        this.additionToKey = additionToKey;
    }

    getFullKey = (key) => this.additionToKey + '.' + key;

    store = (key, value) => defer(() =>
        from(UserDefaults.set(this.getFullKey(key), JSON.stringify(value)))
    );

    load = (key) => defer(() =>
        from(UserDefaults.get(this.getFullKey(key))).pipe(
            map(value => value && JSON.parse(value))
        )
    );

    value = () => console.error(`UserDefaultsPersistence.value() method not exists in Persistence`);
    rxValue = () => console.error(`UserDefaultsPersistence.rxValue() method not exists in Persistence`);
    rxStore = () => console.error(`UserDefaultsPersistence.rxStore() method not exists in Persistence`);
}
