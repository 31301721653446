import EStyleSheet from 'react-native-extended-stylesheet';

const defaultArrowStyle = {
    width: 12,
    height: 8
};

const styles = EStyleSheet.create({
    arrowToTop: {
        ...defaultArrowStyle,
        transform: [
            {
                rotate: '-180deg'
            },
            {
                translateY: 2
            }
        ]
    },
    arrowToBottom: {
        ...defaultArrowStyle,
        transform: [
            {
                rotate: '0deg'
            },
            {
                translateY: -2
            }
        ]
    },

    header: {
        width: 320
    }
});

export default styles;
