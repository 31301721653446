import withConfigValue from 'dating-mobile/src/components/config-value';
import withMallController from 'dating-mobile/src/models/credits.mall/controllers/controller';
import View from './view';
import withController from './controller';
import withIdentityId from '../../../models/identity/controller/id';

export const RestorePurchasesNavigationItem = withIdentityId(
  withMallController(
    withConfigValue(withController(View), {
      restorePurchasesEnabled: 'features.restore-purchases-enabled',
    }),
  ),
  'id',
);
