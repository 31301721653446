import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        width: '100%',
        height: 64,
        flexDirection: 'row',
        alignItems: 'center',
        overflow: 'visible'
    },

    list: {
        width: '100%',
        overflow: 'visible'
    },

    itemSeparator: {
        width: 8
    },

    listHeader: {
        width: 20,
        '@flavor lovinga': {
            width: 8
        }
    },

    listFooter: {
        width: 20,
        '@flavor lovinga': {
            width: 8
        }
    },

    itemContainer: {
        height: 48,
        paddingLeft: 20,
        paddingRight: 16,
        flexDirection: 'row',
        alignItems: 'stretch',
        borderRadius: 24,
        backgroundColor: '$primaryBackgroundColor',
        shadowColor: 'black',
        shadowRadius: 8,
        shadowOpacity: 0.15,
        shadowOffset: { width: 0, height: 0 },
        elevation: 8,
        overflow: 'visible'
    },

    itemContent: {
        flexDirection: 'row',
        alignItems: 'center'
    },

    message: {
        fontFamily: '$brandFontFamily',
        fontSize: 16,
        color: '$primaryTextColor'
    },

    sendIcon: {
        width: 24,
        height: 24,
        marginLeft: 12,
        tintColor: '$accentColor'
    }
});

export default styles;
