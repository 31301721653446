import React from 'react';
import PropTypes from 'prop-types';
import { perf } from 'react-native-firebase';
import { Alert } from 'react-native';
import { Stock } from '@sdv/domain/payment/stock';

import withHasTag from 'dating-mobile/src/models/user.tags/controllers/has-tag';
import { UserTags } from '@sdv/domain/user/tags/user-tags';
import flux from '@sdv/domain/app/flux';
import UserTagsModel from '@sdv/domain/user/tags/personal';
import UserModel from '@sdv/domain/user/model';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';
import withMediaLoader from 'dating-mobile/src/models/user.media/controller/media-loader';
import withUserController from 'dating-mobile/src/models/user/controllers';
import withConfigValue from 'dating-mobile/src/components/config-value';
import Resources from '../../../resources';
import { Purchase } from '../../methods/purchase';

function isFreeBoostOnSubscriptionAvailable(products) {
  const product = products?.[0]?.[0];

  return !!product && !!product.boostTime && !!product.containsMembership;
}

function controller(Component) {
  class Controller extends React.Component {
    static displayName = 'payment.memberships.screen.controller';

    static propTypes = {
      userId: PropTypes.string,
      isFocused: PropTypes.bool,
      id: PropTypes.string,
      reason: PropTypes.string,
      premiumMembershipDiscountUsed: PropTypes.bool,
      topExpensiveProductDiscount: PropTypes.string,
      onPackageClick: PropTypes.func,
      close: PropTypes.func,
      onComplete: PropTypes.func,
      user: PropTypes.object,
      thumbnailRequiredForBoost: PropTypes.bool,
      selectMedia: PropTypes.func,
      setNavigationBarState: PropTypes.func,
    };

    state = {
      packages: [],
      isLoading: false,
    };

    trace = null;

    componentDidMount() {
      const { setNavigationBarState, isFocused, id } = this.props;

      this.setState({ isLoading: true });

      if (setNavigationBarState) {
        setNavigationBarState({
          onCloseButtonPress: this.onFinish,
        });
      }

      if (isFocused && id) {
        this.getPackages();
      }
    }

    componentDidUpdate(prevProps) {
      const { isFocused, id } = this.props;

      if (prevProps.isFocused !== isFocused) {
        if (isFocused) {
          this.startTrace();
        } else {
          this.stopTrace('cancelled');
        }
      }

      if (
        (prevProps.isFocused !== isFocused || prevProps.id !== id) &&
        isFocused &&
        id
      ) {
        this.getPackages();
      }
    }

    onPackagesLoadingError = () => {
      this.stopTrace('error');
    };

    onFinish = (closeModal, product) => {
      const { close, onComplete } = this.props;

      if (close) {
        close(closeModal);
      }

      if (onComplete) {
        onComplete(product);
      }
    };

    onProductSelected = packWithInvoices => {
      const { id, reason, onPackageClick } = this.props;
      const [pack, invoices] = packWithInvoices;

      if (invoices.length > 1) {
        onPackageClick(invoices, this.onPaymentCompleted);
      } else if (invoices.length === 1) {
        this.setState({ isLoading: true });

        Purchase.shared()
          .pay(id, invoices[0], reason, this.checkUserThumbnail)
          .subscribe(({ success }) => {
            this.setState({ isLoading: false });

            if (success) {
              const tags = flux.get(UserTagsModel, id);
              const user = flux.get(UserModel, id);

              if (tags) tags.actions.get();
              if (user) user.actions.get();
              this.onPaymentCompleted(pack);
            }
          });
      }
    };

    onPaymentCompleted(pack) {
      Alert.alert(
        Resources.strings['memberships-screen-success-alert-title'],
        Resources.strings['memberships-screen-success-alert-message'],
        [
          {
            text: Resources.strings.ok,
            style: 'cancel',
            onPress: () => {
              this.onFinish(true, pack);
            },
            onDismiss: () => {
              this.onFinish(true, pack);
            },
          },
        ],
      );
    }

    getPackages() {
      const { id } = this.props;

      Stock.shared(id).preloadedPackagesInStock.subscribe(packages => {
        this.setState({ packages, isLoading: false });
        this.stopTrace('success');
      }, this.onPackagesLoadingError);
    }

    getProducts() {
      const { packages } = this.state;

      return (
        packages &&
        packages
          .filter(product => product[0].containsMembership)
          .sort((a, b) => {
            if (a[0].isPremiumMembership && !b[0].isPremiumMembership) {
              return -1;
            }

            if (b[0].isPremiumMembership && !a[0].isPremiumMembership) {
              return 1;
            }

            return b[0]?.price?.includingTax - a[0]?.price?.includingTax;
          })
      );
    }

    async checkUserThumbnail() {
      const { user, thumbnailRequiredForBoost, selectMedia } = this.props;

      try {
        const thumbnail = user && (user['thumbnail-pending'] || user.thumbnail);
        const freeBoostOnSubscriptionAvailable = isFreeBoostOnSubscriptionAvailable(
          this.getProducts(),
        );

        if (
          thumbnailRequiredForBoost &&
          freeBoostOnSubscriptionAvailable &&
          !thumbnail
        ) {
          await selectMedia();
        }
      } catch (error) {
        // TODO: Handle errors when requirements are specified
      }
    }

    async startTrace() {
      this.trace = perf().newTrace('membership_packages_show');
      this.trace.start();
    }

    async stopTrace(status) {
      if (this.trace) {
        this.trace.putAttribute('status', status);
        await this.trace.stop();
        this.trace = null;
      }
    }

    render() {
      const {
        premiumMembershipDiscountUsed,
        topExpensiveProductDiscount,
      } = this.props;
      const { isLoading } = this.state;

      const topProductDiscount =
        topExpensiveProductDiscount && products.length > 1
          ? Resources.strings.formatString(
              Resources.strings['memberships-screen-discount-text-format'],
              topExpensiveProductDiscount,
            )
          : undefined;

      const products = this.getProducts();

      return (
        <Component
          {...this.props}
          freeBoostOnSubscriptionAvailable={isFreeBoostOnSubscriptionAvailable(
            products,
          )}
          premiumMembershipDiscountUsed={premiumMembershipDiscountUsed}
          products={products}
          topProductDiscount={topProductDiscount}
          onProductSelect={this.onProductSelected}
          isLoading={isLoading}
        />
      );
    }
  }

  return Controller;
}

export default Component =>
  withConfigValue(
    withIdentityId(
      withUserController(
        withMediaLoader(
          withHasTag(
            withHasTag(
              withHasTag(controller(Component), {
                tag: UserTags.Membership,
                userIdPropName: 'id',
                hasTagPropName: 'canSubscribe',
              }),
              {
                tag: UserTags.PremiumMembership,
                userIdPropName: 'id',
                hasTagPropName: 'premiumMembershipIsActive',
              },
            ),
            {
              tag: UserTags.PremiumMembershipDiscount,
              userIdPropName: 'id',
              hasTagPropName: 'premiumMembershipDiscountUsed',
            },
          ),
          {
            title: Resources.strings['boost-profile-banner-media-loader-title'],
          },
        ),
      ),
      'id',
    ),
    {
      topExpensiveProductDiscount: 'membership.top-expensive-product-discount',
      thumbnailRequiredForBoost: 'features.thumbnail-required-for-boost',
      premiumSubscriptionEnabled: 'features.premium-subscription-enabled',
    },
  );
