import Actions from './actions';

function createVowelsStore(id = 'default') {

    class Store {

        constructor(flux) {
            const actions = flux.getActions(Actions, id);

            this.state = {
            };

            this.bindAction(actions.get, this.updateTags);
        }

        updateTags(vowels) {

            if (!vowels) {
                return;
            }

            this.setState({
                vowels: vowels
            });

        }

    }

    Store.displayName = createVowelsStore.getDisplayName(id);

    return Store;
}

createVowelsStore.getDisplayName = function (id) {
    return `user-tags-vowels.${id}`;
};

export default createVowelsStore;
