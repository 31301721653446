export default class UrlService {
  static parseQuery = query =>
    query
      .slice(1)
      .split('&')
      .map(keyAndValue => keyAndValue.split('=').map(decodeURIComponent))
      .reduce((acc, [key, value]) => {
        acc[key] = value;

        return acc;
      }, {});

  static clearQuery() {
    const { location } = window;

    location.assign(location.pathname);
  }
}
