import { singleton } from '@sdv/commons/utils/singleton';
import { BehaviorSubject } from 'rxjs';
// import NetInfo from '@react-native-community/netinfo';
import StreamConnectionMonitoring from '@sdv/domain/app/streaming/stream-connection';
import { ApiMonitoring } from '@sdv/domain/api/monitoring';
const NetInfo = {};
const ConnectionState = Object.freeze({
  UNKNOWN: 0,
  CONNECTED: 1,
  DISCONNECTED: 2,
});

class ConnectionMonitoring {
  static shared = singleton(() => new ConnectionMonitoring());

  constructor() {
    this.networkState = new BehaviorSubject({ state: ConnectionState.UNKNOWN });
    this.websocketState = new BehaviorSubject({
      state: ConnectionState.UNKNOWN,
    });
    this.serverConnectionState = new BehaviorSubject({
      state: ConnectionState.UNKNOWN,
    });

    NetInfo.addEventListener('connectionChange', state => {
      const connectionState =
        state.type !== 'none'
          ? ConnectionState.CONNECTED
          : ConnectionState.DISCONNECTED;
      this.networkState.next({
        state: connectionState,
        networkType: state.effectiveType,
      });
    });
    StreamConnectionMonitoring.shared().currentState.subscribe(
      hasConnection => {
        const connectionState = hasConnection
          ? ConnectionState.CONNECTED
          : ConnectionState.DISCONNECTED;
        this.websocketState.next({ state: connectionState });
      },
    );

    ApiMonitoring.shared().hasBadConnectionToServer.subscribe(badConnection => {
      const connectionState = badConnection
        ? ConnectionState.DISCONNECTED
        : ConnectionState.CONNECTED;
      this.serverConnectionState.next({ state: connectionState });
    });
  }
}

export { ConnectionMonitoring, ConnectionState };
