import flux from '@sdv/domain/app/flux';
import { singleton } from '@sdv/commons/utils/singleton';
import Identity from '@sdv/domain/identity/model';
import '@sdv/commons/rx/store';
import { map, distinctUntilChanged, shareReplay } from 'rxjs/operators';

export default class Session {
    static shared = singleton(() => new Session());

    constructor() {
        const identity = flux
            .get(Identity)
            .store.rxState()
            .pipe(shareReplay(1));

        this.userId = identity.pipe(
            map(state => state.id),
            distinctUntilChanged(),
        );

        this.userIsUsingDemoMode = identity.pipe(
            map(state => state.demo),
            distinctUntilChanged(),
        );

        this.userIsModerator = identity.pipe(
            map(state => (state.roles || []).includes('streams-operator')),
            distinctUntilChanged(),
        );
    }
}
