import EStyleSheet from 'react-native-extended-stylesheet';
import shouldUseWebLayout from 'dating-mobile/src/utils/web-layout';

const isWebLayout = shouldUseWebLayout();

const styles = EStyleSheet.create({
  $addMediaButtonLoadingColor: 'white',

  $mediaButtonIsCompact: true,
  '@flavor dma': {
    $mediaButtonIsCompact: false,
  },

  container: {
    height: '100%',
    width: '100%',
    flexDirection: 'row-reverse',
    justifyContent: 'center',
    alignItems: 'center',
    '@flavor lovinga, udates': {
      justifyContent: 'flex-start',
      paddingLeft: 16,
    },
  },
  videoCallButton: {
    marginRight: 16,
    backgroundColor: '$acceptColor',
  },
  videoCallIcon: {
    tintColor: 'white',
  },
  writeLetterButton: {
    height: 66,
  },
  sendGift: {
    backgroundColor: 'white',
    marginRight: 16,
  },
  buttonContainer: {
    paddingHorizontal: 40,
    marginBottom: 40,
  },

  button: isWebLayout ? { backgroundColor: '$brandColor', width: 284 } : {},
  buttonTitle: isWebLayout ? { color: '$brandTextColor' } : {},

  likeDislikeButtons: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 75,
    paddingLeft: 16,
  },
});

export default styles;
