import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $statusBarStyle: 'light-content',
    $statusBarBackgroundColor: 'black',

    headerTitle: {
        color: 'white',
        fontFamily: '$brandFontFamily'
    },

    container: {
        backgroundColor: 'black',
        flex: 1
    },

    scrollView: {
        flexGrow: 1
    },

    photo: {
    }
});

export default styles;
