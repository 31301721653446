const actions = {
    displayName: 'config/page/satellite',

    set: function (config) {
        return config;
    }

};

export default actions;
