import PropTypes from 'prop-types';
import OpenAppSettings from 'react-native-app-settings';
import { Alert, AppState, Platform } from 'react-native';
import { Subscription } from 'rxjs';
import flux from '@sdv/domain/app/flux';
import { NotificationType } from '@sdv/domain/notifications/types';
import NotificationsModel, {
  NOTIFICATION_PRIORITY,
} from '@sdv/domain/notifications/local';
import Navigator from 'dating-mobile/src/routing/navigator';
import * as ROUTES from 'dating-mobile/src/routing/router/constants';
import Service from 'dating-mobile/src/services/service';
import { CallKeeper } from 'dating-mobile/src/call/services/call-keeper';
import withIdentityId from '../../../models/identity/controller/id';
import withConfigValue from '../../../components/config-value';
import CallPermissions from '../../../models/call.permissions';
import Resources from '../../../resources';

class CallPresenter extends Service {
  static displayName = 'service.call-presenter';

  static propTypes = {
    userId: PropTypes.string,
    videoChatEnabled: PropTypes.bool,
    audioCallsEnabled: PropTypes.bool,
  };

  componentDidMount() {
    this.subscribe();
    Navigator.listen(this.onScreenChanged);
    this.currentAppState = AppState.currentState;
    AppState.addEventListener('change', this.appStateChanged);

    const { userId } = this.props;

    if (userId) {
      this.callPermissions = flux.get(CallPermissions, userId);
      this.callPermissions.store.listen(this.handlePermissionsState);
    }
  }

  componentWillUnmount() {
    this.unsubscribe();
    Navigator.unlisten(this.onScreenChanged);
    AppState.removeEventListener('change', this.appStateChanged);

    if (this.callPermissions?.store)
      this.callPermissions.store.unlisten(this.handlePermissionsState);
  }

  shouldComponentUpdate(nextProps) {
    const { userId } = this.props;

    return userId !== nextProps.userId;
  }

  componentDidUpdate(prevProps) {
    const { userId } = this.props;

    if (userId !== prevProps.userId) {
      if (this.callPermissions?.store)
        this.callPermissions.store.unlisten(this.handlePermissionsState);

      if (userId) {
        this.callPermissions = flux.get(CallPermissions, userId);
        this.callPermissions.store.listen(this.handlePermissionsState);
      }
    }
  }

  subscribe() {
    this.callKeeper = CallKeeper.shared();
    this.disposeBag = new Subscription();

    const showInviteSubscription = this.callKeeper.shouldShowInvite.subscribe(
      attendeeId => {
        if (attendeeId) {
          this.showInvite({ attendeeId });
        }
      },
    );

    const showIncomingCallSubscription = this.callKeeper.shouldShowIncomingCall.subscribe(
      attendeeId => {
        if (attendeeId) {
          this.showIncomingCall({ attendeeId });
        }
      },
    );

    const showSpeakingSubscription = this.callKeeper.shouldShowSpeaking.subscribe(
      attendeeId => {
        if (attendeeId) {
          this.showSpeaking({ attendeeId });
        }
      },
    );

    const showConnectingToCallSubscription = this.callKeeper.shouldShowConnectingToCall.subscribe(
      attendeeId => {
        if (attendeeId) {
          this.showConnectingToCall({ attendeeId });
        }
      },
    );

    const endCallSubscription = this.callKeeper.shouldEndCall.subscribe(() => {
      this.dismissVideoCallStack = true;
      this.onScreenChanged({ stack: Navigator.currentStack() });
      this.callKeeper.stopInCallManager();
      this.endCall();
    });

    this.disposeBag.add(showInviteSubscription);
    this.disposeBag.add(showIncomingCallSubscription);
    this.disposeBag.add(showSpeakingSubscription);
    this.disposeBag.add(showConnectingToCallSubscription);
    this.disposeBag.add(endCallSubscription);
  }

  unsubscribe() {
    if (this.disposeBag) {
      this.disposeBag.unsubscribe();

      this.disposeBag = null;
    }
  }

  shouldShowNativeCaller() {
    return Platform.OS === 'ios';
  }

  appStateChanged = nextAppState => {
    if (this.currentAppState === nextAppState) {
      return;
    }

    // const { userId } = this.props;

    // TODO: Add 30s timeout and then interrupt the call
    // if (nextAppState === 'background' && userId && this.callKeeper) {
    //   this.callKeeper.onEndCall();
    // }

    this.currentAppState = nextAppState;
  };

  handlePermissionsState = state => {
    const { videoChatEnabled, audioCallsEnabled } = this.props;

    if ((videoChatEnabled || audioCallsEnabled) && state.needPermissions) {
      this.showPermissionsAlert(
        state.needMicrophonePermission,
        () => {
          this.callPermissions.actions.reset();
          OpenAppSettings.open();
        },
        () => {
          this.callPermissions.actions.ignore();
        },
      );
    }
  };

  onScreenChanged = ({ stack }) => {
    if (
      !!this.dismissVideoCallStack &&
      stack.indexOf(ROUTES.AUDIO_VIDEO_CALL_MODAL) >= 0
    ) {
      this.dismissVideoCallStack = false;
      Navigator.pop();
    }
  };

  showPermissionsAlert(audioOnly, confirmCallback, rejectCallback) {
    Alert.alert(
      Resources.strings[
        audioOnly
          ? 'audio-call-without-permissions-alert-message'
          : 'call-without-permissions-alert-message'
      ],
      null,
      [
        {
          text:
            Resources.strings[
              'call-without-permissions-alert-open-settings-action-title'
            ],
          onPress: confirmCallback,
        },
        {
          text:
            Resources.strings[
              'call-without-permissions-alert-not-now-action-title'
            ],
          onPress: rejectCallback,
          style: 'cancel',
        },
      ],
      { cancelable: false },
    );
  }

  showInvite({ attendeeId }) {
    const { userId } = this.props;

    const allowedScreens = [
      ROUTES.FEED,
      ROUTES.PROFILE,
      ROUTES.DISCOVERY,
      ROUTES.GALLERY,
      ROUTES.TIPS_LIST,
      ROUTES.TIP,
    ];

    const currentNavigationStack = Navigator.currentStack();

    if (!currentNavigationStack || !currentNavigationStack.length) {
      return;
    }

    const lastRoute = currentNavigationStack[currentNavigationStack.length - 1];

    if (!allowedScreens || allowedScreens.indexOf(lastRoute) >= 0) {
      const notification = {
        type: NotificationType.IncomingCall,
        payload: {
          'attendee-id': attendeeId,
        },
      };
      const notificationsModel = flux.get(NotificationsModel, userId);

      notificationsModel.actions.notify(
        notification,
        NOTIFICATION_PRIORITY.NORMAL,
      );
    }
  }

  async showSpeaking({ attendeeId, showVideo }) {
    this.callKeeper.startInCallManager({ showVideo });
    this.callKeeper.getCurrentCallId(attendeeId);
    Navigator.navigate(ROUTES.ONGOING_CALL, { attendeeId, showVideo });

    if (
      this.shouldShowNativeCaller() &&
      (await CallKeeper.isCallKeepAvailable())
    ) {
      try {
        this.callKeeper.setCurrentCallActive({ attendeeId });
      } catch (e) {
        console.error('Set current call active error:', e.message);
      }
    }
  }

  async showConnectingToCall({ attendeeId, showVideo }) {
    this.callKeeper.startInCallManager({ showVideo });
    this.callKeeper.getCurrentCallId(attendeeId);
    Navigator.navigate(ROUTES.CONNECTING_TO_CALL, { attendeeId, showVideo });

    if (
      this.shouldShowNativeCaller() &&
      (await CallKeeper.isCallKeepAvailable())
    ) {
      try {
        // Do not show on ios
        // this.callKeeper.startCall({
        //   attendeeId,
        //   showVideo, // TODO
        //   callerName: '', // TODO
        // });
      } catch (e) {
        console.error('Start call error:', e.message);
      }
    }
  }

  async showIncomingCall({ attendeeId, showVideo }) {
    if (
      this.shouldShowNativeCaller() &&
      (await CallKeeper.isCallKeepAvailable())
    ) {
      try {
        this.callKeeper.displayIncomingCall({
          attendeeId,
          showVideo, // TODO
          callerName: '', // TODO
        });
      } catch (e) {
        console.error('Display incoming call error:', e.message);
        this.callKeeper.getCurrentCallId(attendeeId);
        Navigator.navigate(ROUTES.INCOMING_CALL, { attendeeId, showVideo });
      }
    } else {
      this.callKeeper.getCurrentCallId(attendeeId);
      Navigator.navigate(ROUTES.INCOMING_CALL, { attendeeId, showVideo });
    }
  }

  async endCall() {
    if (
      this.shouldShowNativeCaller() &&
      (await CallKeeper.isCallKeepAvailable())
    ) {
      try {
        this.callKeeper.endCall();
      } catch (e) {
        console.error('End call error:', e.message);
      }
    }
  }
}

export default withIdentityId(
  withConfigValue(CallPresenter, {
    videoChatEnabled: 'features.video-chat-enabled',
    audioCallsEnabled: 'features.audio-calls-enabled',
  }),
);
