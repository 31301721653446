import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { View, Text } from 'react-native';
import Resources from 'dating-mobile/src/resources';
import withConfigValue from 'dating-mobile/src/components/config-value';

import BenefitItem from './item';

import styles from './styles';

export class Benefits extends PureComponent {
  static displayName = 'payment.membership-benefits.premium-subscription';

  static propTypes = {
    unlimitedMessagesWithMembershipEnabled: PropTypes.bool,
    style: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.number,
      PropTypes.array,
    ]),
  };

  render() {
    const { style, unlimitedMessagesWithMembershipEnabled } = this.props;

    return (
      <View style={[styles.container, style]}>
        <BenefitItem
          title={
            Resources.strings[
              'memberships-screen-basic-filter-benefit-description'
            ]
          }
          icon={Resources.images.filterIcon()}
        />
        <BenefitItem
          title={
            <Text>
              <Text>
                {
                  Resources.strings[
                    'memberships-screen-premium-location-search-0'
                  ]
                }
              </Text>
              <Text>
                {
                  Resources.strings[
                    'memberships-screen-premium-location-search-1'
                  ]
                }
              </Text>
            </Text>
          }
          icon={Resources.images.locationIcon()}
        />
        {!!unlimitedMessagesWithMembershipEnabled && (
          <BenefitItem
            title={
              Resources.strings[
                'memberships-screen-unlimited-messages-benefit-short-description'
              ]
            }
            icon={Resources.images.newChatsIcon()}
            iconStyle={styles.iconWithoutTint}
          />
        )}
        <BenefitItem
          title={
            <Text>
              {Resources.strings['memberships-screen-premium-calls-0']}
            </Text>
          }
          icon={Resources.images.callBenefitIcon()}
        />
        <BenefitItem
          title={Resources.strings.formatString(
            Resources.strings[
              'memberships-screen-basic-more-profiles-views-benefit-description'
            ],
            5,
          )}
          icon={Resources.images.eyeIcon()}
        />
      </View>
    );
  }
}

export const InitialOfferBenefits = withConfigValue(Benefits, {
  unlimitedMessagesWithMembershipEnabled:
    'features.unlimited-messages-with-membership-enabled',
});
