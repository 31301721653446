import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  $replyButtonVisible: false,

  container: {
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  photoContainer: {
    marginLeft: 15,
    marginRight: 11,
  },

  photo: {
    width: 60,
    height: 60,
  },

  contactIcon: {
    left: 0,
    top: 0,
    width: 15,
    height: 15,
    position: 'absolute',
  },

  textContainer: {
    height: '100%',
    flex: 1,
    justifyContent: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 15,
  },

  username: {
    fontSize: 17,
    fontFamily: '$brandFontFamily',
    fontWeight: '600',
    marginBottom: 2,
    color: '$primaryTextColor',
  },

  message: {
    fontWeight: 'normal',
    fontFamily: '$brandFontFamily',
    color: '$primaryTextColor',
  },

  replyButton: {
    height: 24,
    borderRadius: 12,
    marginRight: 14,
    paddingHorizontal: 16,
    backgroundColor: '$accentColor',
    justifyContent: 'center',
  },

  replyButtonTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    fontFamily: '$brandFontFamily',
    color: '$accentTextColor',
  },

  giftIcon: {
    position: 'absolute',
    right: 5,
    bottom: -8,
  },
});

export default styles;
