import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $showBadgeIcon: false,
    '@flavor tubit': {
        $showBadgeIcon: true
    },

    container: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: 'red',
        height: 20,
        borderRadius: 10,
        paddingLeft: 10,
        paddingRight: 10,
        fontFamily: '$brandFontFamily',
        '@flavor lovinga, tubit, udates': {
            backgroundColor: '$accentColor'
        }
    },
    text: {
        color: 'white',
        fontFamily: '$brandFontFamily',
        '@flavor lovinga, tubit, udates': {
            color: '$accentTextColor'
        }
    },
    liveBadgeIcon: {
        width: 14,
        height: 14,
        marginRight: 2
    },
});

export default styles;
