function createLetterActions(id) {

    const {recipient, sender, letter, isIntroductory} = parseId(id);

    class Actions {

        get = () => (dispatch, flux) => {

            const resource = isIntroductory
                ? flux.api.dialogs.letters.introductory.get
                : flux.api.dialogs.letters.get;

            resource(recipient, sender, letter, (error, result) => {
                if (result) {
                    dispatch(result);
                } else {
                    dispatch(null, error);
                }
            });

        };

        markAsRead = () => (dispatch, flux) => {
            flux.api.dialogs.letters.patch(recipient, sender, letter, {read: true}, () => {
                dispatch();
            })
        }
    }

    Actions.displayName = createLetterActions.getDisplayName(id);

    return Actions;
}

createLetterActions.getDisplayName = function (id) {
    return `dialogs-letter.${id}`;
};

export default createLetterActions;

export function parseId(id) {
    let keys;

    if (typeof id !== 'string' || (keys = id.split(':')).length < 3) {
        throw new Error('LetterActions should have id in format "{recipient}:{sender}:{letter}(:free)"');
    }

    return {
        recipient: keys[0],
        sender: keys[1],
        letter: keys[2],
        isIntroductory: keys[3] === 'free'
    };
}

export function getId(recipient, sender, letter, isIntroductory) {
    return `${recipient}:${sender}:${letter}${isIntroductory ? ':free' : ''}`;
}
