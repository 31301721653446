import React from 'react';
import { Text } from 'react-native';
import goals from '../../../../../models/user.goals/controllers';
import preferences from '../../../../../models/user.preference/controllers';
import Resources from '../../../../../resources';
import styles from './styles';
import { USER_GOALS } from '@sdv/domain/user.goals';

class UserGoalsDisplayText extends React.PureComponent {
    static displayName = 'user.profile.views.profile-description.user-goals';

    render() {
        if (!this.props.preference || this.props.preference['preferred-gender'] === undefined) {
            return null;
        }
        let genderText = '';
        let goalText = '';
        switch (this.props.preference['preferred-gender']) {
            case 'mal': genderText = Resources.strings["profile-screen-looking-for-male"]; break;
            case 'fem': genderText = Resources.strings["profile-screen-looking-for-female"]; break;
            default: genderText = Resources.strings["profile-screen-looking-for-both"]; break;
        }
        const sortedGoals = this.props.goals.slice().sort();
        for (let i = 0; i < sortedGoals.length; i++) {
            const goal = sortedGoals[i];
            let goalValue = this.getGoalText(goal);
            if (goalText.length === 0) {
                goalText = goalValue;
            } else {
                if (i === sortedGoals.length - 1) {
                    let format = Resources.strings.formatString(Resources.strings["profile-screen-last-item-format"], goalValue);
                    goalText += format;
                } else {
                    goalText += `, ${goalValue}`;
                }
            }
        }
        let displayText = '';
        if (genderText && goalText) {
            displayText = Resources.strings.formatString(Resources.strings["profile-screen-looking-for-format"], genderText, goalText);
        } else if (genderText) {
            displayText = Resources.strings.formatString(Resources.strings["profile-screen-looking-for-no-goals-format"], genderText);
        }
        return <Text style={styles.textStyle}>{displayText}</Text>
    }

    getGoalText = (goal) => {
        switch (goal) {
            case USER_GOALS.CHAT: return Resources.strings["profile-screen-goal-chat"];
            case USER_GOALS.DATING: return Resources.strings["profile-screen-goal-dating"];
            case USER_GOALS.FRIENDSHIP: return Resources.strings["profile-screen-goal-friendship"];
        }
        return '';
    };
}

export default preferences(goals(UserGoalsDisplayText), { userIdPropName: 'userId'});
