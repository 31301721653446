/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { View, FlatList, Platform } from 'react-native';
import { connectActionSheet } from '@expo/react-native-action-sheet';
import equal from 'fast-deep-equal';

import AddItem from './add-item';
import PhotoItem from './photo-item';
import Resources from '../../../resources';

import styles from './styles';

// eslint-disable-next-line react/no-redundant-should-component-update
class PhotoUploading extends React.PureComponent {
  static displayName = 'user.multi-photo-uploading';

  static propTypes = {
    photos: PropTypes.arrayOf(PropTypes.string),
    canAddPhoto: PropTypes.bool,
    isUploading: PropTypes.bool,
    progress: PropTypes.number,
    onPhotoDelete: PropTypes.func,
    onPhotoSelect: PropTypes.func,
    showActionSheetWithOptions: PropTypes.func,
  };

  state = {};

  shouldComponentUpdate(nextProps, nextState) {
    const { photos, canAddPhoto, isUploading, progress } = this.props;
    const { itemStyle, listStyle } = this.state;

    return (
      !equal(photos, nextProps.photos) ||
      canAddPhoto !== nextProps.canAddPhoto ||
      isUploading !== nextProps.isUploading ||
      progress !== nextProps.progress ||
      itemStyle !== nextState.itemStyle ||
      listStyle !== nextState.listStyle
    );
  }

  componentDidUpdate(prevProps) {
    const { photos, canAddPhoto } = this.props;
    const { itemStyle } = this.state;
    const prevPhotoCount = (prevProps.photos && prevProps.photos.length) || 0;
    const photoCount = (photos && photos.length) || 0;

    if (photoCount > prevPhotoCount && this.listRef) {
      const numberOfItems = photoCount + (canAddPhoto ? 1 : 0);
      const offset =
        styles._header.width +
        styles._footer.width +
        numberOfItems * itemStyle.width +
        (numberOfItems - 1) * styles._separator.width -
        this.containerWidth;

      this.listRef.scrollToOffset({
        offset,
      });
    }
  }

  onPhotoSelected = (...args) => {
    const { showActionSheetWithOptions, onPhotoDelete } = this.props;

    showActionSheetWithOptions(
      {
        options: [Resources.strings.delete, Resources.strings.cancel],
        destructiveButtonIndex: 0,
        cancelButtonIndex: 1,
        showSeparators: Platform.OS === 'web',
      },
      buttonIndex => {
        if (buttonIndex === 0 && onPhotoDelete) {
          onPhotoDelete(...args);
        }
      },
    );
  };

  onNewPhotoSelected = (...args) => {
    const { onPhotoSelect } = this.props;

    if (onPhotoSelect) onPhotoSelect(...args);
  };

  renderItem = ({ item }) => {
    let content;
    const { isUploading, progress } = this.props;
    const { itemStyle } = this.state;

    switch (item.type) {
      case 'photo':
        content = (
          <PhotoItem path={item.path} onPhotoSelect={this.onPhotoSelected} />
        );
        break;
      case 'add-media':
        content = (
          <AddItem
            isUploading={isUploading}
            progress={progress}
            onPhotoSelect={this.onNewPhotoSelected}
          />
        );
        break;
      default:
        return null;
    }

    return (
      <View key={item.key} style={itemStyle}>
        {content}
      </View>
    );
  };

  renderItemSeparator = () => {
    return <View style={styles.separator} />;
  };

  renderHeader = () => {
    return <View style={styles.header} />;
  };

  renderFooter = () => {
    return <View style={styles.footer} />;
  };

  onContainerLayout = event => {
    const { width } = event.nativeEvent.layout;

    if (!width || this.containerWidth === width) {
      return;
    }

    this.containerWidth = width;

    const itemSize =
      this.containerWidth - styles._header.width - styles._footer.width;

    this.setState({
      itemStyle: { width: itemSize, height: itemSize },
      listStyle: { height: itemSize },
    });
  };

  render() {
    const { photos, canAddPhoto } = this.props;
    const { listStyle } = this.state;
    const items = (photos || []).map(path => ({
      type: 'photo',
      key: path,
      path,
    }));

    if (canAddPhoto) {
      items.push({ type: 'add-media', key: 'add-media' });
    }

    return (
      <View style={styles.container} onLayout={this.onContainerLayout}>
        {!!listStyle && (
          <FlatList
            ref={ref => {
              this.listRef = ref;
            }}
            data={items}
            keyExtractor={item => item.key}
            horizontal
            renderItem={this.renderItem}
            ItemSeparatorComponent={this.renderItemSeparator}
            ListHeaderComponent={this.renderHeader}
            ListFooterComponent={this.renderFooter}
            style={[styles.list, listStyle]}
            showsHorizontalScrollIndicator={false}
          />
        )}
      </View>
    );
  }
}

export default connectActionSheet(PhotoUploading);
