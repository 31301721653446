import React from 'react';
import PropTypes from 'prop-types';
import {
  View,
  FlatList,
  Platform,
  ActivityIndicator,
  Text,
  TouchableOpacity,
  Image,
} from 'react-native';
import { HeaderHeightContext } from 'react-navigation-stack';
import testId from 'dating-mobile/src/utils/test-id';
import Resources from 'dating-mobile/src/resources';
import IfConfigValue from 'dating-mobile/src/components/config-value/if';
import Button from 'dating-mobile/src/components/buttons/base';
import { Gender } from '@sdv/domain/user/gender';
import { PurchasePromotionBanner } from 'dating-mobile/src/components/purchase-promotion-banner';
import { FreeCreditsBanner } from '../free-credits-banner';
import HideOnScroll from '../../../components/hide-on-scroll';
import { UserCardWithOptionalLoading } from '../user-card';
import { BoostProfileBanner } from '../boost-profile-banner';
import { FeedAdsBanner } from '../ads';

import styles from './styles';

const HidingHeaderFlatList = HideOnScroll(FlatList);

export default class FeedList extends React.PureComponent {
  static displayName = 'feed.view.list';

  static contextTypes = {
    flux: PropTypes.object,
  };

  static propTypes = {
    userId: PropTypes.string,
    user: PropTypes.object,
    users: PropTypes.array,
    reload: PropTypes.func,
    loadNext: PropTypes.func,
    setHeaderHidden: PropTypes.func,
    onWinkButtonPress: PropTypes.func,
    onItemPress: PropTypes.func,
    isLoading: PropTypes.bool,
    adsRowNumber: PropTypes.number,
    showHeader: PropTypes.bool,
    showFooter: PropTypes.bool,
    hasError: PropTypes.bool,
    partialMatchLength: PropTypes.number,
  };

  static defaultProps = {
    adsRowNumber: 4,
    showHeader: true,
  };

  state = {
    headerHidden: false,
  };

  getUserCard = ({ item }) => {
    const { userId, onItemPress, onWinkButtonPress } = this.props;

    return (
      <UserCardWithOptionalLoading
        {...item}
        style={styles.item}
        key={item.id}
        onItemPress={onItemPress}
        onWinkButtonPress={onWinkButtonPress}
        isOwnProfile={userId === item.id}
        {...testId(Resources.strings['user-card-accessibility-label'])}
      />
    );
  };

  getData = () => {
    const { users, isLoading } = this.props;

    if (isLoading) {
      return [];
    }

    return users;
  };

  getItemLayout = (data, rowIndex) => {
    const height = styles.item.height + styles.item.marginBottom;

    return {
      length: height,
      offset: height * rowIndex,
      index: rowIndex,
    };
  };

  onEndReached = () => {
    const { loadNext } = this.props;

    if (loadNext) {
      loadNext();
    }
  };

  onRefresh = () => {
    const { reload } = this.props;

    if (reload) {
      reload();
    }
  };

  getListHeader(hidden, small) {
    const { showHeader, userId, user, partialMatchLength } = this.props;

    if (!showHeader) {
      return Platform.OS === 'android' ? (
        <HeaderHeightContext.Consumer>
          {height => <View style={{ height }} />}
        </HeaderHeightContext.Consumer>
      ) : null;
    }

    return (
      <View>
        {Platform.OS === 'android' && (
          <HeaderHeightContext.Consumer>
            {height => <View style={{ height }} />}
          </HeaderHeightContext.Consumer>
        )}
        <IfConfigValue path="features.boost-profile-enabled" equalsTo={true}>
          {user?.gender === Gender.Male && (
            <PurchasePromotionBanner
              hidden={hidden}
              small={small}
              userId={userId}
              style={small ? null : styles.banner}
              placeholder={
                small ? null : (
                  <BoostProfileBanner id={userId} style={styles.banner} />
                )
              }
            />
          )}
          {!small && user?.gender === Gender.Female && (
            <BoostProfileBanner id={userId} style={styles.banner} />
          )}
        </IfConfigValue>
        {!small && <FreeCreditsBanner userId={userId} />}
        {!small &&
          !!partialMatchLength &&
          this.getData().length === partialMatchLength && (
            <Text style={styles.noExactMatch}>
              {Resources.strings['feed-screen-no-exact-match-title']}
            </Text>
          )}
      </View>
    );
  }

  getListFooter() {
    const { showFooter } = this.props;

    return showFooter ? (
      <TouchableOpacity style={styles.footerContainer} onPress={this.onRefresh}>
        <Image source={Resources.images.tabBarFeed()} />
        <Text style={styles.footerTitle}>
          {Resources.strings['feed-screen-mingle-title']}
        </Text>
      </TouchableOpacity>
    ) : null;
  }

  getListEmptyComponent() {
    const { hasError, reload, isLoading } = this.props;

    if (isLoading) {
      return (
        <View style={styles.listEmptyContainer}>
          <ActivityIndicator />
        </View>
      );
    }

    if (hasError) {
      return (
        <View style={styles.listEmptyContainer}>
          <Text style={styles.tryAgainText}>
            {Resources.strings['feed-screen-bad-connection-title']}
          </Text>
          <Button
            style={styles.tryAgainButton}
            onPress={reload}
            title={Resources.strings['feed-screen-try-again-button-title']}
          />
        </View>
      );
    }

    return null;
  }

  scrollToOffset = (...args) => {
    if (this.listRef) {
      this.listRef.scrollToOffset(...args);
    }
  };

  getSeparator = ({ leadingItem }) => {
    const { adsRowNumber, partialMatchLength } = this.props;
    const index = styles.$numberOfColumns * adsRowNumber;
    const data = this.getData();
    const targetPosition = data[index];
    const currentPosition = leadingItem[0];

    if (
      targetPosition &&
      currentPosition &&
      targetPosition.id === currentPosition.id
    ) {
      return <FeedAdsBanner />;
    }

    if (
      partialMatchLength &&
      data.indexOf(leadingItem) === data.length - partialMatchLength - 1
    ) {
      return (
        <View style={styles.partialMatchContainer}>
          <View style={styles.line} />
          <Text style={styles.partialMatchText}>
            {Resources.strings['feed-screen-partial-match-title']}
          </Text>
          <View style={styles.line} />
        </View>
      );
    }

    return null;
  };

  onScroll = event => {
    const headerHidden =
      event.nativeEvent.contentOffset.y > (Platform.OS === 'web' ? 200 : 100);

    this.setState({
      headerHidden,
    });
  };

  render() {
    const { setHeaderHidden } = this.props;
    const { headerHidden } = this.state;

    return (
      <View style={styles.container}>
        <View style={styles.smallBannerContainer}>
          {this.getListHeader(!headerHidden, true)}
        </View>
        <HidingHeaderFlatList
          contentContainerStyle={styles.contentContainer}
          ListHeaderComponent={this.getListHeader(false, false)}
          ListFooterComponent={this.getListFooter()}
          ListEmptyComponent={this.getListEmptyComponent()}
          ItemSeparatorComponent={this.getSeparator}
          ref={ref => {
            this.listRef = ref;
          }}
          setHidden={setHeaderHidden}
          onScroll={this.onScroll}
          style={styles.list}
          data={this.getData()}
          onEndReached={this.onEndReached}
          onEndReachedThreshold={1.5}
          renderItem={this.getUserCard}
          getItemLayout={this.getItemLayout}
          keyExtractor={item => item.id}
          onRefresh={this.onRefresh}
          refreshing={false}
          updateCellsBatchingPeriod={20}
          maxToRenderPerBatch={10}
          initialNumToRender={10}
          windowSize={6}
        />
      </View>
    );
  }
}
