import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet } from 'react-native';
import Resources from 'dating-mobile/src/resources';
import ImageButton from 'dating-mobile/src/components/navigation/views/image-button';
import testId from 'dating-mobile/src/utils/test-id';
import { AUTH_LOADING } from 'dating-mobile/src/routing/router/constants';
import styles from './styles';

export default function bindNavigation(Component) {
  class Navigation extends React.Component {
    static displayName = 'user.screens.account-settings.navigation';

    static propTypes = {
      navigation: PropTypes.object.isRequired,
    };

    logout = () => {
      const { navigation } = this.props;

      navigation.navigate(AUTH_LOADING);
    };

    render() {
      return <Component {...this.props} logout={this.logout} />;
    }
  }

  Navigation.navigationOptions = ({ navigation, navigationOptions }) => ({
    headerTitle: Resources.strings['account-settings-screen-title'],
    headerStyle: [navigationOptions.headerStyle, styles.header],
    headerTitleStyle: [navigationOptions.headerTitleStyle, styles.headerTitle],
    headerLeft: () => (
      <ImageButton
        image={Resources.images.backIcon()}
        tint={
          StyleSheet.flatten([
            navigationOptions.headerTitleStyle,
            styles.headerTitle,
          ]).color
        }
        onPress={() => navigation.goBack()}
        {...testId('Back button')}
      />
    ),
  });

  return Navigation;
}
