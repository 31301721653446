import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, TouchableOpacity, Image, StatusBar } from 'react-native';
import { Platform } from 'react-native';

import styles from './styles';
import Resources from 'dating-mobile/src/resources';

import { ConnectionState } from 'dating-mobile/src/platform/connection-monitoring';

class BadConnectionNotification extends React.PureComponent {
    static displayName = 'components.bad-connection-notification.view';
    static propTypes = {
        websocketConnection: PropTypes.bool,
        networkConnection: PropTypes.bool,
        serverConnection: PropTypes.bool,
        onClose: PropTypes.func,
    };

    render() {
        const { websocketConnection, networkConnection, serverConnection, onClose } = this.props;

        const showWebsocketConnectionError = websocketConnection === ConnectionState.DISCONNECTED;
        const showNetworkConnectionError = networkConnection === ConnectionState.DISCONNECTED;
        const showServerConnectionError = serverConnection === ConnectionState.DISCONNECTED;

        const hasAdditionalPadding = Platform.OS === 'android' && StatusBar.currentHeight > 0;
        const containerStyle = [
            styles.container,
            hasAdditionalPadding ? { paddingTop: StatusBar.currentHeight + 100 } : {},
        ];

        return (
            <View style={containerStyle}>
                <View style={styles.textContainer}>
                    <Text style={styles.descriptionText} numberOfLines={2}>
                        {Resources.strings['bad-connection-banner-title']}
                    </Text>
                    {!!showWebsocketConnectionError && (
                        <Text style={styles.descriptionText}>
                            {Resources.strings['bad-connection-banner-websocket-error']}
                        </Text>
                    )}
                    {!!showNetworkConnectionError && (
                        <Text style={styles.descriptionText}>
                            {Resources.strings['bad-connection-banner-network-error']}
                        </Text>
                    )}
                    {!!showServerConnectionError && (
                        <Text style={styles.descriptionText}>
                            {Resources.strings['bad-connection-banner-server-error']}
                        </Text>
                    )}
                </View>
                <TouchableOpacity onPress={onClose} style={styles.closeImageContainer}>
                    <Image style={styles.closeImage} source={Resources.images.closeIcon()} />
                </TouchableOpacity>
            </View>
        );
    }
}

export default BadConnectionNotification;
