import EStyleSheet from 'react-native-extended-stylesheet';

const defaultLookingForTitle = {
    fontFamily: '$brandFontFamily',
    fontSize: 18,
    color: '$brandTextColor',
    '@flavor secretly': {
        color: '$primaryTextColor',
    },
};

const styles = EStyleSheet.create({
    $lookingForDescriptionVisible: true,

    container: {
        width: '100%',
    },

    publicFieldContainer: {
        width: '100%',
    },

    birthdayInfo: {
        fontSize: 12,
        color: '$infoTextColor',
        marginHorizontal: 20,
        marginTop: 8,
        '@flavor secretly': {
            color: '$secondaryTextColor',
        },
    },

    aboutMeContainer: {
        marginTop: 32,
    },
    separator: {
        '@flavor dma': {
            marginLeft: 20,
            marginRight: 0,
            height: 1,
            backgroundColor: '$brandBorderColor',
        },
    },
    infoContainer: {
        flex: 1,
    },
    infoTitle: {
        marginLeft: 16,
        marginTop: 32,
        marginBottom: 16,
        fontSize: 24,
        fontWeight: '700',
        fontFamily: '$brandFontFamily',
        color: '$brandTextColor',
        '@flavor secretly': {
            color: '$primaryTextColor',
        },
    },

    interestTitle: {
        color: '$brandTextColor',
        '@flavor secretly': {
            color: '$primaryTextColor',
        },
    },

    interestsButton: {
        '@flavor dma': {
            color: '$brandLinkTextColor',
        },
    },
    lookingForDescriptionContainer: {
        borderBottomWidth: 1,
        borderBottomColor: '$brandBorderColor',
        marginLeft: 20,
        marginRight: 20,
        marginTop: 20,
        paddingBottom: 32,
        '@flavor secretly': {
            color: '$primaryBorderColor',
        },
    },

    lookingForTitle: {
        ...defaultLookingForTitle,
    },

    lookingForTitlePlaceholder: {
        ...defaultLookingForTitle,
        color: '$infoTextColor',
        '@flavor secretly': {
            color: '$secondaryTextColor',
        },
    },
});

export default styles;
