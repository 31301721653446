import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, TouchableOpacity, Image } from 'react-native';
import styles from './styles';

class StatsView extends React.PureComponent {
    static propTypes = {
        useShadowStyle: PropTypes.bool,
        counter: PropTypes.number,
        icon: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.number
        ]),
    };

    render() {
        const containerStyle = [styles.container, this.props.useShadowStyle ? styles.containerWithShadow : {}];

        const textStyle = [styles.text, this.props.useShadowStyle ? styles.textWithShadow : {}];

        const imageStyle = [styles.image, this.props.useShadowStyle ? styles.imageWithShadow : {}];

        let image = this.props.icon ? <Image source={this.props.icon} style={imageStyle} /> : null;

        return (
            <TouchableOpacity onPress={this.props.onPress} disabled={!this.props.onPress}>
                <View style={containerStyle}>
                    {image}
                    <Text style={textStyle}>{this.props.counter}</Text>
                </View>
            </TouchableOpacity>
        );

    }

}

export default StatsView;
