import React from 'react';
import PropTypes from 'prop-types';
import withIdentityId from '../../../models/identity/controller/id';
import StreamsTopics from '@sdv/domain/streams-topics';
import UserNameEditing from '@sdv/domain/user/name-editing';
import withConfigValue from 'dating-mobile/src/components/config-value';

function createStreamTopicsPickingController(Component) {
    class Controller extends React.PureComponent {
        static displayName = 'streaming.screens.streams-topics-picking.controller';
        static propTypes = {
            userId: PropTypes.string,
            onComplete: PropTypes.func,
            topicsPickingBonusAmount: PropTypes.number,
        };

        constructor(props) {
            super(props);
            this.state = {
                name: '',
                userNameIsValid: false,
                hasSelectedTopics: false,
                termsAgreed: true,
            };
        }

        onTermsAgreed = agreed => {
            this.setState({ termsAgreed: agreed });
        };

        subscribeUserNameEditing = userId => {
            this.streamTopics = StreamsTopics.shared(userId);
            this.userNameEditing = new UserNameEditing(userId);
            this.userNameSubscription = this.userNameEditing.name.subscribe(name => {
                this.setState({ name: name });
            });
            this.userNameValidSubscription = this.userNameEditing.valid.subscribe(valid => {
                this.setState({ userNameIsValid: valid });
            });
        };

        componentWillReceiveProps(nextProps) {
            if (this.props.userId !== nextProps.userId) {
                this.userNameSubscription.unsubscribe();
                this.userNameValidSubscription.unsubscribe();
                this.subscribeUserNameEditing(nextProps.userId);
            }
        }

        componentDidMount() {
            this.streamsTopics = StreamsTopics.shared(this.props.userId);
            this.selectedTopicsSubscription = this.streamsTopics.selectedTopics.subscribe(
                selectedTopics => {
                    this.setState({ hasSelectedTopics: selectedTopics.length > 0 });
                },
            );
            this.subscribeUserNameEditing(this.props.userId);
        }

        componentWillUnmount() {
            this.userNameSubscription.unsubscribe();
            this.userNameValidSubscription.unsubscribe();
            if (this.selectedTopicsSubscription) {
                this.selectedTopicsSubscription.unsubscribe();
            }
        }

        save = () => {
            this.userNameEditing.save();
            this.streamTopics.save();
            this.props.onComplete && this.props.onComplete();
        };

        onTextChange = text => {
            this.userNameEditing.nameSubject.next(text);
        };

        render() {
            return (
                <Component
                    {...this.props}
                    name={this.state.name}
                    onTextChange={this.onTextChange}
                    onTermsAgreed={this.onTermsAgreed}
                    saveButtonDisabled={
                        !this.state.userNameIsValid ||
                        !this.state.hasSelectedTopics ||
                        !this.state.termsAgreed
                    }
                    save={this.save}
                />
            );
        }
    }

    return withIdentityId(
        withConfigValue(Controller, {
            topicsPickingBonusAmount: 'bonuses.topics-picking-amount',
        }),
    );
}

export default createStreamTopicsPickingController;
