function CreateUserReportActions(id) {
    class UserReportActions {
        report = (user, content) => (dispatch, flux) => {
            flux.api.annals.add(id, 'user-report', {'user-id': user, content: content}, (error) => {
                dispatch(null, error);
            });
        };
    }

    UserReportActions.displayName = CreateUserReportActions.getDisplayName(id);
    return UserReportActions
}

CreateUserReportActions.getDisplayName = function(id) {
    return `user-report.${id}`;
};

export default CreateUserReportActions;
