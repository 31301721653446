import qs from 'qs';
import LetterWritingModel from '@sdv/domain/dialogs.mailbox/letter-writing';
import connect from '@sdv/connect';

export default function createController(Component) {

    function getModels(flux, props) {
        const models = {};

        if (props.senderId && props.recipientId && props.letterTag) {
            const modelId = qs.stringify({ senderId: props.senderId, recipientId: props.recipientId, letterTag: props.letterTag });
            models.letterWritingModel = flux.get(LetterWritingModel, modelId);
        }

        return models;
    }

    function mapStoresToProps(models) {
        let props = {};

        if (models.letterWritingModel) {
            props.attachments = models.letterWritingModel.store.getState().attachments;
        }

        return props;
    }

    function mapActionsToProps(models) {
        const props = {};

        if (models.letterWritingModel) {
            props.onRemoveAttachmentButtonPress = models.letterWritingModel.actions.detach;
        }

        return props;
    }

    return connect(getModels, mapStoresToProps, mapActionsToProps)(Component);
}
