import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  container: {
    flexDirection: 'column',
    paddingTop: 6,
    paddingBottom: 6,
  },

  imageContainer: {
    marginTop: -10,
    marginBottom: -10,
    width: '100%',
    aspectRatio: 1,
  },

  image: {
    flex: 1,
    margin: 10,
  },

  priceContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 10,
  },

  coin: {
    width: 18,
    height: 18,
  },

  price: {
    fontSize: 12,
    fontWeight: 'normal',
    fontFamily: '$brandFontFamily',
    color: '#868686',
  },

  freeBadge: {
    width: '100%',
    height: '100%',
  },

  freeBadgeContainer: {
    position: 'absolute',
    width: '60%',
    height: '45%',
    top: 5,
    right: 7,
  },
});

export default styles;
