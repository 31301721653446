import { Platform } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  input: Platform.select({
    default: {
      borderBottomColor: '$brandBorderColor',
      borderBottomWidth: 1,
      marginHorizontal: 12,
    },
    ios: {},
  }),
});

export default styles;
