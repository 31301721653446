import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  $placeholderVisible: true,

  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },

  noticeContainer: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    paddingVertical: 12,
  },

  log: {
    flex: 1,
  },

  form: {
    flexShrink: 1,
    backgroundColor: '$primaryBackgroundColor',
  },

  blockedContainer: {
    marginHorizontal: 20,
  },

  blockedText: {
    fontFamily: '$brandFontFamily',
    color: '$rejectColor',
    fontSize: 12,
    marginBottom: 10,
  },

  chatLogPlaceholderText: {
    fontFamily: '$brandFontFamily',
    color: '$secondaryTextColor',
    fontSize: 15,
    textAlign: 'center',
    marginBottom: 10,
  },

  sendGiftButtonTitle: {
    fontFamily: '$brandFontFamily',
    color: '#007aff',
    fontSize: 15,
    textAlign: 'center',
    textDecorationLine: 'underline',
  },
});

export default styles;
