import React from 'react';
import PropTypes from 'prop-types';
import Photo, { PHOTO_TRANSFORMATION_TYPE } from '../../../../components/photo';

export default class UserThumbnail extends React.PureComponent {
  static displayName = 'user.thumbnail.small';

  static propTypes = {
    user: PropTypes.shape({
      id: PropTypes.string,
      thumbnail: PropTypes.string,
      'thumbnail-pending': PropTypes.string,
    }),
    userThumbnail: PropTypes.string,
    placeholder: PropTypes.string,
    transformation: PropTypes.number,
  };

  static defaultProps = {
    user: {},
    placeholder: 'avatar',
    transformation: PHOTO_TRANSFORMATION_TYPE.DETECT_FACE,
  };

  render() {
    const { user, userThumbnail, placeholder, transformation } = this.props;
    const thumbnail =
      typeof userThumbnail === 'undefined'
        ? user['thumbnail-pending'] || user.thumbnail
        : userThumbnail;

    return (
      <Photo
        {...this.props}
        placeholder={placeholder}
        transformation={transformation}
        userId={user?.id}
        basename={thumbnail}
      />
    );
  }
}
