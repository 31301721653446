import React, { useEffect } from 'react';
import { View, ActivityIndicator } from 'react-native';

import styles from './styles';

function PaymentResult() {
  useEffect(() => {
    const threeDsParam =
      window.location.search
        .slice(1)
        .split('&')
        .find(param => param.startsWith('3ds=')) || '';

    window.localStorage.setItem('3ds-receipt', threeDsParam.slice(4));
  }, []);

  return (
    <View style={styles.container}>
      <ActivityIndicator size={40} animating />
    </View>
  );
}

export default PaymentResult;
