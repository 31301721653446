import EStyleSheet from 'react-native-extended-stylesheet';

const inactiveColor = '#9A9A9A';
const activeColor = '$brandColor';
const commonNavItemStyles = {
  paddingHorizontal: 24,
  paddingVertical: 17,
  marginBottom: 2,
  flexDirection: 'row',
  alignItems: 'center',
};
const commonNavItemTextStyles = {
  fontSize: 20,
  fontWeight: 500,
};
const commonNavItemIconStyles = {
  width: 28,
  height: 28,
  marginRight: 24,
};

const styles = EStyleSheet.create({
  container: { backgroundColor: '#f3f3f3', flex: 1 },
  logo: {
    marginTop: 24,
    marginLeft: 24,
    marginBottom: 32,
    width: 70,
    height: 70,
  },
  navItem: {
    ...commonNavItemStyles,
    backgroundColor: '#e5e5e5',
  },
  navItemActive: {
    ...commonNavItemStyles,
    backgroundColor: '$primaryBackgroundColor',
  },
  navIcon: { ...commonNavItemIconStyles, tintColor: inactiveColor },
  navIconActive: { ...commonNavItemIconStyles, tintColor: activeColor },
  navItemText: {
    ...commonNavItemTextStyles,
    color: inactiveColor,
  },
  navItemTextActive: {
    ...commonNavItemTextStyles,
    color: activeColor,
  },
});

export default styles;
