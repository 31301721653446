import React, { useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { Platform, StyleSheet } from 'react-native';

export default function withAspectRatio(Component) {
  function AspectRatio({ style, ...props }) {
    const [componentLayout, setLayout] = useState(null);
    const [newStyle, setNewStyle] = useState([]);
    const firstLayout = useRef(true);

    useEffect(() => {
      const { aspectRatio = 1, ...inputStyle } = StyleSheet.flatten(style);
      const initState = [inputStyle, { aspectRatio }];

      if (componentLayout && firstLayout.current) {
        const { width = 0, height = 0 } = componentLayout;

        if (width === 0 || inputStyle.height) {
          setNewStyle([...initState, { width: height * aspectRatio, height }]);
        } else {
          setNewStyle([...initState, { width, height: width * aspectRatio }]);
        }
        firstLayout.current = false;
      }
    }, [componentLayout, style]);

    useEffect(() => {
      const { aspectRatio = 1, ...inputStyle } = StyleSheet.flatten(style);
      const initState = [inputStyle, { aspectRatio }];

      const resizeHandler = debounce(() => {
        firstLayout.current = true;
        setNewStyle(initState);
      }, 400);

      setNewStyle(initState);
      window.addEventListener('resize', resizeHandler);

      return () => window.removeEventListener('resize', resizeHandler);
    }, [style]);

    return (
      <Component
        {...props}
        onLayout={({ nativeEvent: { layout } }) => setLayout(layout)}
        style={newStyle}
      />
    );
  }

  AspectRatio.propTypes = {
    style: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
  };

  AspectRatio.defaultProps = {
    style: {},
  };

  return Platform.OS === 'android' || Platform.OS === 'ios'
    ? Component
    : AspectRatio;
}
