import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    description: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 10,
        marginBottom: 20,
        marginLeft: 24,
        marginRight: 24,
        textAlign: 'center'
    },
    descriptionText: {
        fontSize: 14,
        '@flavor ytm, udates': {
            fontSize: 12
        },
        color: '$brandPlaceholderColor',
        '@flavor lovinga, udates': {
            color: '$accentTextColor'
        },
        textAlign: 'center',
        fontFamily: '$brandFontFamily'
    },
    descriptionTextDecorate: {
        fontFamily: '$brandFontFamily',
        textDecorationLine: 'underline',
        fontWeight: 'bold'
    }
});

export default styles;
