import React from 'react';
import PropTypes from 'prop-types';
import connect from '@sdv/connect';
import Model from '../model';
import single from '../../../utils/single-execute';

const GET_PROPERTY = 'generated.misc-data.controller.get';

export default function createController(Component, options = {}) {

    const dataType = options.dataType;
    const dataTypePropName = options.dataTypePropName || 'dataType';
    const valuesPropName = options.valuesPropName || 'dictValues';

    class Controller extends React.Component {
        static displayName = 'misc-data.controller';
        static contextTypes = {
            flux: PropTypes.object,
        };

        componentDidMount() {
            if (!Object.keys(this.props[valuesPropName] || {}).length && this.props[GET_PROPERTY]) {
                this.loadData();
            }
        }

        componentDidUpdate(prevProps) {
            if (shouldReconnect(prevProps, this.props)) {
                if (!Object.keys(this.props[valuesPropName] || {}).length && this.props[GET_PROPERTY]) {
                    this.loadData();
                }
            }
        }

        loadData = () => {
            const tag = `${Controller.displayName}.${this.props[dataTypePropName] || dataType}.get`;
            single(tag, this.props[GET_PROPERTY]);
        };

        render() {
            const { ...props } = this.props;

            delete props[GET_PROPERTY];

            return (<Component {...props} />);
        }
    }

    function getModels(flux, props) {
        const models = {};

        if (props[dataTypePropName] || dataType) {
            models.dataModel = flux.get(Model, props[dataTypePropName] || dataType);
        }

        return models;
    }

    function mapStoresToProps(models) {
        const props = {};

        if (models.dataModel) {
            props[valuesPropName] = models.dataModel.store.getState();
        }

        return props;
    }

    function mapStoresToActions(models) {
        const props = {};

        if (models.dataModel) {
            props[GET_PROPERTY] = models.dataModel.actions.get;
        }

        return props;
    }

    function shouldReconnect(props, newProps) {
        return props[dataTypePropName] !== newProps[dataTypePropName];
    }

    return connect(
        getModels,
        mapStoresToProps,
        mapStoresToActions,
        shouldReconnect
    )(Controller);

}
