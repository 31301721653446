import EStyleSheet from 'react-native-extended-stylesheet';

const textStyle = {
    fontFamily: '$brandFontFamily',
    color: '$brandTextColor',
};

const styles = EStyleSheet.create({
    container: {
        width: '100%',
        height: '100%',
        backgroundColor: '$primaryBackgroundColor',
        paddingHorizontal: 40,
    },

    contentContainer: {
        alignItems: 'center',
        justifyContent: 'space-between',
        flexGrow: 1,
    },

    subTitle: {
        ...textStyle,
        fontSize: 14,
    },

    title: {
        ...textStyle,
        fontWeight: 'bold',
        fontSize: 24,
    },

    hint: {
        ...textStyle,
        fontSize: 14,
        opacity: 0.5,
    },

    containerInput: {
        marginBottom: 5,
    },

    input: {
        ...textStyle,
        fontSize: 15,
        borderBottomColor: '$brandBorderColor',
        borderBottomWidth: 2,
        fontWeight: 'bold',
        paddingHorizontal: 0,
        paddingRight: 30,
    },

    inputError: {
        paddingRight: 60,
    },

    button: {
        marginBottom: 40,
    },

    visibilityIndicator: {
        position: 'absolute',
        marginTop: -2,
        right: 5,
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },

    visibilityIndicatorError: {
        right: 35,
    },
});

export default styles;
