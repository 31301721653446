import React from 'react';
import PropTypes from 'prop-types';
import { View, Text } from 'react-native';
import { TimeSpent } from 'dating-mobile/src/components/time/time-spent';
import ProfileView from 'dating-mobile/src/user/common/views/profile';
import Resources from 'dating-mobile/src/resources';

import styles from './styles';

export default class CallConnectingInformationView extends React.Component {
  static displayName = 'call.views.call-connecting-information.view';

  static propTypes = {
    attendeeId: PropTypes.string,
    callStatus: PropTypes.string,
    isVideoCall: PropTypes.bool,
  };

  shouldComponentUpdate(nextProps) {
    const { attendeeId, callStatus, isVideoCall } = this.props;

    return (
      attendeeId !== nextProps.attendeeId ||
      callStatus !== nextProps.callStatus ||
      isVideoCall !== nextProps.isVideoCall
    );
  }

  getMessageText() {
    const { isVideoCall, callStatus } = this.props;

    if (isVideoCall) {
      if (callStatus === 'ringing') {
        return Resources.strings['video-chat-incoming-call-text'];
      }

      if (callStatus === 'connecting') {
        return Resources.strings['video-chat-connecting-to-call-text'];
      }
    }

    if (callStatus === 'ringing') {
      return Resources.strings['audio-call-incoming-text'];
    }

    if (callStatus === 'connecting') {
      return Resources.strings['audio-call-connecting-text'];
    }

    if (callStatus === 'speaking') {
      return <TimeSpent />;
    }

    return null;
  }

  render() {
    const { attendeeId } = this.props;
    const messageText = this.getMessageText();

    return (
      <View style={styles.container}>
        <ProfileView
          usernameStyle={styles.username}
          thumbnailStyle={styles.thumbnail}
          userId={attendeeId}
          ageIsVisible
          hideOnlineIndicator
        />
        {!!messageText && (
          <Text style={styles.connectingText}>{messageText}</Text>
        )}
      </View>
    );
  }
}
