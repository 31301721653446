import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    buttonContainer: {
        flexShrink: 1,
        flexGrow: 0,
        alignSelf: 'flex-start',
        justifyContent: 'flex-start'
    },

    button: {
        height: 28,
        paddingHorizontal: 24,
        fontSize: 14,
        width: 'auto'
    },
    buttonTitle: { fontSize: 14 }
});

export default styles;


