import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    input: {
        width: '100%',
        borderWidth: 1,
        height: 59,
        borderColor: 'transparent',
    },
    placeholder: {
        color: '$brandPlaceholderColor'
    },
});

export default styles;
