import flux from '@sdv/domain/app/flux';
import { singleton } from '@sdv/commons/utils/singleton';
import '@sdv/commons/rx/store';
import { of, combineLatest } from 'rxjs';
import {
  map,
  shareReplay,
  switchMap,
  distinctUntilChanged,
} from 'rxjs/operators';
import ApplicationConfig from 'dating-mobile/src/models/config/model';
import Session from '@sdv/domain/authorization/session';
import UserTags from '@sdv/domain/user/tags';
import { Platform } from 'react-native';

const conditionPrefix = '@';
const conditionPostfix = ')';
const vowelsPrefix = 'vowels(';
const featuresPrefix = 'features(';
const tagsPrefix = 'tags(';
const withoutTagsPrefix = 'without-tags(';
const platformPrefix = 'platform(';
const defaultValue = '@default';

/*

    "base-feature": true,

    "conditional-feature": {
        "@vowels(a,b,c)": 1,
        "@tags(dialogs.messages.promoter)": 2,
        "@vowels(a), @without-tags(membership)": 3,
        "@features(experiments.supermembership)": 4,
        "@platform(android)": 5,
        "@default": 6
    }

 */

export default class Config {
  static shared = singleton(() => new Config());

  constructor() {
    this.userId = Session.shared().userId.pipe(shareReplay(1));

    this.userTags = this.userId.pipe(
      switchMap(userId => {
        if (!userId) {
          return of([]);
        }

        return UserTags.shared().tagsOf(userId);
      }),
      shareReplay(1),
    );

    this.userVowels = this.userId.pipe(
      switchMap(userId => {
        if (!userId) {
          return of([]);
        }

        return UserTags.shared().vowelsOf(userId);
      }),
      shareReplay(1),
    );

    this.userFeatures = this.userId.pipe(
      switchMap(userId => {
        if (!userId) {
          return of([]);
        }

        return UserTags.shared().featuresOf(userId);
      }),
      shareReplay(1),
    );

    this.config = flux
      .get(ApplicationConfig)
      .store.rxState()
      .pipe(shareReplay(1));
  }

  static valueByPath(object, path) {
    let value = object;

    if (path) {
      const components = path.split('.');

      for (let index = 0; index < components.length; index++) {
        const component = components[index];

        if (!value.hasOwnProperty(component)) {
          return undefined;
        }

        value = value[component];
      }
    }

    return value;
  }

  static getValuesFromCondition(condition, name) {
    return condition
      .replace(name, '')
      .replace(conditionPostfix, '')
      .split(',')
      .filter(part => !!part);
  }

  paramWithPath(path) {
    if (typeof path !== 'string') {
      return of(undefined);
    }

    return this.config.pipe(
      switchMap(config => {
        const value = Config.valueByPath(config, path);

        if (
          !value ||
          typeof value !== 'object' ||
          !Object.keys(value).every(key => key.startsWith(conditionPrefix))
        ) {
          return of(value);
        }

        return combineLatest(
          this.userTags,
          this.userVowels,
          this.userFeatures,
        ).pipe(
          map(([userTags, userVowels, userFeatures]) => {
            const successfulConditionKey = Object.keys(value).find(key => {
              if (key === defaultValue) {
                return false;
              }

              const conditions = key
                .replace(/ /g, '')
                .split(conditionPrefix)
                .filter(part => !!part);

              return conditions.every(condition => {
                if (condition.startsWith(tagsPrefix)) {
                  const tags = Config.getValuesFromCondition(
                    condition,
                    tagsPrefix,
                  );

                  if (!tags.every(tag => userTags.indexOf(tag) >= 0)) {
                    return false;
                  }
                } else if (condition.startsWith(withoutTagsPrefix)) {
                  const tags = Config.getValuesFromCondition(
                    condition,
                    withoutTagsPrefix,
                  );

                  if (!tags.every(tag => userTags.indexOf(tag) < 0)) {
                    return false;
                  }
                } else if (condition.startsWith(vowelsPrefix)) {
                  const tags = Config.getValuesFromCondition(
                    condition,
                    vowelsPrefix,
                  );

                  if (tags.findIndex(tag => userVowels.indexOf(tag) >= 0) < 0) {
                    return false;
                  }
                } else if (condition.startsWith(platformPrefix)) {
                  const platforms = Config.getValuesFromCondition(
                    condition,
                    platformPrefix,
                  );

                  if (platforms.indexOf(Platform.OS) < 0) {
                    return false;
                  }
                } else if (condition.startsWith(featuresPrefix)) {
                  const tags = Config.getValuesFromCondition(
                    condition,
                    featuresPrefix,
                  );

                  if (
                    tags.findIndex(tag => userFeatures.indexOf(tag) >= 0) < 0
                  ) {
                    return false;
                  }
                } else {
                  return false;
                }

                return true;
              });
            });

            return value[successfulConditionKey || defaultValue];
          }),
        );
      }),
      distinctUntilChanged(),
    );
  }
}
