import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'react-native';
import styles from './styles';

export default class UserLocationText extends React.PureComponent {
    static displayName = 'user.common.views.user-location.view';
    static propTypes = {
        city: PropTypes.string,
        country: PropTypes.string
    };

    render() {
        let country = this.props.country;
        let city = this.props.city;

        if (city || country) {
            let displayText = '';
            if (city) {
                displayText += city;
            }
            if (country && city) {
                displayText += ', ';
            }
            if (country) {
                displayText += country;
            }
            return (<Text numberOfLines={1} style={styles.location}>{displayText}</Text>);
        }
        else {
            return (<Text style={styles.location} />);
        }
    }
}
