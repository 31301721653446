import InteractiveBalanceRefiller from './interactive-balance-refiller';
import CredentialsUpdatingBalanceRefiller from './credentials-updating-balance-refiller';

const interactiveBalanceRefiller = new InteractiveBalanceRefiller();
const credentialsUpdatingBalanceRefiller = new CredentialsUpdatingBalanceRefiller(
  interactiveBalanceRefiller,
);

export default credentialsUpdatingBalanceRefiller;

export const PAYMENT_REASON = {
  MANUAL: 'manual',
  SEND_MESSAGE: 'send-message',
  DISLIKE_REWIND: 'dislike-rewind',
  SEND_PHOTO: 'send-photo',
  SEND_VIDEO: 'send-video',
  SEND_CHEER: 'send-cheer',
  SEND_LETTER: 'send-letter',
  WATCH_PHOTO: 'watch-photo',
  WATCH_VIDEO: 'watch-video',
  OPEN_LETTER: 'open-letter',
  USE_ADVANCED_SEARCH: 'use-advanced-search',
  OPEN_LIKE: 'open-like',
  VIEW_PRIVATE_PHOTO: 'view-private-photo',
  START_MINGLE: 'start-mingle',
  BOOST_PROFILE: 'boost-profile',
  BOOST_MESSAGE: 'boost-message',
  BECOME_HOST: 'become-host',
  CALL: 'call',
  ONBOARDING: 'onboarding',
  SKIP_SUBSCRIBE: 'skip-subscribe',
  SPECIAL_OFFER: 'special-offer',
};
