import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {},

    errorContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        paddingHorizontal: 10,
        alignItems: 'center',
        justifyContent: 'center',
        '@flavor secretly': {
            position: 'relative',
            width: undefined,
            height: undefined,
            bottom: 40,
        },
    },

    error: {
        fontSize: 14,
        textAlign: 'center',
        color: '$errorColor',
        fontFamily: '$brandFontFamily',
    },

    counter: {
        fontSize: 15,
        color: '$brandTextColor',
        fontWeight: 'bold',
        fontFamily: '$brandFontFamily',
    },

    activityIndicatorContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

export default styles;
