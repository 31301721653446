import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'center',
        borderRadius: 18,
        overflow: 'hidden'
    },

    photo: {
        width: 130,
        height: 130
    },

    unsentPhoto: {
        opacity: 0.5
    }
});

export default styles;
