import React from 'react';
import PropTypes from 'prop-types';
import { render } from 'dating-mobile/src/components/rx-render';
import { BoostExtendNotification } from '@sdv/domain/user/profile-booster/boost-extend-notification';

export default function createController(Component) {
  const Controller = render(
    ({ userId, placeholder, style }, { useObservable }) => {
      if (!userId) {
        return null;
      }

      const endTime = useObservable(
        it => it.discountEndTime,
        BoostExtendNotification.shared(userId),
      );

      if (!endTime) {
        return placeholder || null;
      }

      return (
        <Component endTime={endTime} placeholder={placeholder} style={style} />
      );
    },
  );

  Controller.propTypes = {
    userId: PropTypes.string,
    style: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    placeholder: PropTypes.node,
  };

  Controller.displayName = 'payment.views.boost-discount-timeout.controller';

  return Controller;
}
