function CreateUserReportsStore(id) {

    class UserReportsStore {

        constructor(flux) {
            this.state = {};
        };
    }

    UserReportsStore.displayName = CreateUserReportsStore.getDisplayName(id);

    return UserReportsStore;
}

CreateUserReportsStore.getDisplayName = function(id) {
    return 'user-reports.' + id;
};

export default CreateUserReportsStore;
