import React from 'react';
import PropTypes from 'prop-types';
import StreamModel from '../model';

function createStreamController(Component) {

    class Controller extends React.Component {
        static displayName = 'dialogs.stream.controller';
        static contextTypes = {
            flux: PropTypes.object
        };

        constructor(props, context) {
            super(props);

            this.bus = context.flux.bus;
            this.model = context.flux.get(StreamModel, this.props.id);

            this.state = {
                modelState: this.model.store.getState() || {}
            }
        }

        componentDidMount() {
            this.model.store.listen(this.updateState);
        }

        componentWillUnmount() {
            this.model.store.unlisten(this.updateState);
        }

        componentWillReceiveProps(nextProps, nextContext) {
            if (nextProps.id !== this.props.id) {
                this.model.store.unlisten(this.updateState);
                this.model = this.context.flux.get(StreamModel, nextProps.id);

                this.setState({
                    modelState: this.model.store.getState() || {}
                })
            }
        }

        updateState = (state) => {

            this.setState({
                modelState: state
            })

        };

        muteAudio = (state) => {
            this.model.actions.muteAudio(state);
        };

        muteMicrophone = (state) => {
            this.model.actions.muteMicrophone(state);
        };

        rotateCamera = (state) => {
            this.model.actions.rotateCamera(state);
        };

        render() {
            return (
                <Component
                    {...this.state.modelState}
                    {...this.props}
                    bus={this.bus}
                    muteAudio={this.muteAudio}
                    muteMicrophone={this.muteMicrophone}
                    rotateCamera={this.rotateCamera}
                />
            );

        }

    }

    return Controller;
}

export default createStreamController;
