import React from 'react';
import { Text } from 'react-native';
import PropTypes from 'prop-types';
import styles from './styles';
import testId from 'dating-mobile/src/utils/test-id';
import Resources from 'dating-mobile/src/resources';

export default class TermsAndPrivacyText extends React.PureComponent {
    static displayName = 'components.terms-and-privacy-text.view';
    static propTypes = {
        textStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array]),
        containerStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array]),
        customTextFormat: PropTypes.string,
        targetButtonTitle: PropTypes.string,
        onTermsPress: PropTypes.func,
        onPrivacyPolicyPress: PropTypes.func,
    };

    onTermsPress = () => {
        this.props.onTermsPress && this.props.onTermsPress();
    };

    onPrivacyPolicyPress = () => {
        this.props.onPrivacyPolicyPress && this.props.onPrivacyPolicyPress();
    };

    render() {
        const formatString = this.props.customTextFormat || Resources.strings['policy-prefix'];
        const containerStyle = [styles.description, this.props.containerStyle || {}];
        const textStyle = [styles.descriptionText, this.props.textStyle || {}];

        return (
            <Text style={containerStyle} {...testId('Terms and Privacy')}>
                <Text style={textStyle}>
                    {Resources.strings.formatString(
                        formatString,
                        this.props.targetButtonTitle,
                        <Text
                            style={[textStyle, styles.descriptionTextDecorate]}
                            onPress={this.onTermsPress}
                        >
                            {Resources.strings.terms}
                        </Text>,
                        <Text
                            style={[textStyle, styles.descriptionTextDecorate]}
                            onPress={this.onPrivacyPolicyPress}
                        >
                            {Resources.strings.privacy}
                        </Text>,
                    )}
                </Text>
            </Text>
        );
    }
}
