import connect from '@sdv/connect';
import MediaModel from '../../../models/call.media';
import qs from 'qs';

function getModels(flux, props) {
    const models = {};

    if (
        props.userId &&
        props.attendeeId &&
        (typeof props.cameraAvailable === 'undefined' || props.cameraAvailable)
    ) {
        const modelId = qs.stringify({ user: props.userId, attendee: props.attendeeId });
        models.mediaModel = flux.get(MediaModel, modelId);
    }

    return models;
}

function mapStoresToProps(models) {
    const props = {
        disabled: !models.mediaModel
    };

    if (models.mediaModel) {
        const state = models.mediaModel.store.getState();
        props.localVideoEnabled = !!state.localStream;
        props.remoteVideoEnabled = !!state.remoteStreamEnabled;
    }

    return props;
}

function mapActionsToProps(models) {
    const props = {};

    if (models.mediaModel) {
        props.toggle = () => {
            const localVideoEnabled = !!models.mediaModel.store.getState().localStream;
            models.mediaModel.actions.setVideoEnabled(!localVideoEnabled);
        };
    }

    return props;
}

export default connect(getModels, mapStoresToProps, mapActionsToProps);
