export default {
  $flavor: 'secretly',
  $shadowColor: '#0000004d',
  $secondaryTextColor: '#999999',
  $unselectedColor: '#f7f9fa',
  $femaleAccentColor: '#ff006b',
  $acceptColor: '#0d8fba',
  $rejectColor: '#ff3b30',
  $focusedColor: '#f5f5f5',
  $nearTransparent: 'rgba(0, 0, 0, 0.005)',
  $streamSystemTextColor: '#ffffff',
  $bannerColor: '#686868',
  $streamingCTAGradientColor: [],
  $brandColor: '#006c35',
  $brandTextColor: '#ffffff',
  $primaryBackgroundColor: '#ffffff',
  $secondaryBackgroundColor: '#f0f0f0',
  $brandNotificationsColor: '#ff4640',
  $infoTextColor: '#72a488',
  $brandLinkTextColor: '#72a488',
  $brandBorderColor: '#72a488',
  $chatItemsSeparatorColor: '#ebeced',
  $primaryPlaceholderColor: '#999999',
  $primaryBorderColor: '#ebebeb',
  $brandPlaceholderColor: '#72a488',
  $brandButtonDisabledGradientColor: ['#72a488', '#72a488'],
  $accentButtonDisabledGradientColor: ['#e6d37d', '#e6d37d'],
  $disabledTextColor: '#c3c9ce',
  $brandTitleAlign: 'center',
  $errorColor: 'red',
  $errorTextColor: 'white',
  $screenTitleSize: 22,
  $brandFontFamily: 'System',
  $tabBarTitleVisible: true,
  $tabBarIconInactiveColor: '#9a9a9a',
  $navButtonLightTintColor: 'rgb(0,0,0)',
  $keyboardAppearance: 'light',
  $inputBackgroundColor: 'white',
  $inputTextColor: 'black',
  $accentColor: '#d3ae00',
  $accentTextColor: '#ffffff',
  $primaryTextColor: '#000000',
  $brandStatusBarStyle: 'light-content',
  $primaryStatusBarStyle: 'dark-content',
  $disabledColor: '#c3c9ce',
  $selectedColor: '#006c35',
  $minorAccentColor: '#72a488',
  $minorAccentTextColor: 'white',
};
