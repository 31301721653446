import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'react-native';
import Resources from '../../../resources';
import SearchParams from '../../../models/users.search/controllers/params';
import withIdentityId from '../../../models/identity/controller/id';
import withMiscDataValue from '../../../models/misc-data/controller/value';
import { CRITERIA_OPTIONS } from '../../../models/misc-data/utils';
import withConfigValue from '../../../components/config-value';

class ListHeaderTitle extends React.PureComponent {
    static propTypes = {
        userId: PropTypes.string,
        gender: PropTypes.string,
        city: PropTypes.string,
        country: PropTypes.string,
        emptyPreferredGenderEnabled: PropTypes.bool
    };

    render() {
        if (typeof this.props.gender === 'undefined') {
            return null;
        }

        const genderValues = {
            'fem': Resources.strings['feed-screen-title-female-param'],
            'mal': Resources.strings['feed-screen-title-male-param']
        };

        const genderParam = this.props.gender
            ? genderValues[this.props.gender]
            : (this.props.emptyPreferredGenderEnabled
                ? Resources.strings['feed-screen-title-empty-gender-param']
                : null);

        if (!genderParam) {
            return null;
        }

        const locationParam = this.props.city || this.props.country;

        const titleFormat = locationParam
            ? Resources.strings['feed-screen-with-location-title-format']
            : Resources.strings['feed-screen-no-location-title-format'];

        const title = Resources.strings.formatString(titleFormat, genderParam, locationParam || '');

        return (
            <Text>{title}</Text>
        )
    }
}

const mapUserId = (Component) => withIdentityId(Component, 'userId');

const mapCountryName = (Component) => SearchParams(
    withMiscDataValue(
        Component,
        {
            dataType: CRITERIA_OPTIONS.COUNTRIES,
            valuePropName: 'country'
        }
    ),
    'country',
    undefined,
    'userId',
    'name'
);

const mapCityName = (Component) => SearchParams(Component, 'city', undefined, 'userId', 'city');

const mapGender = (Component) => SearchParams(Component, 'gender', undefined, 'userId', 'gender');

const mapConfig = (Component) => withConfigValue(Component, {
    emptyPreferredGenderEnabled: 'features.empty-preferred-gender-enabled'
});

export default mapUserId(mapGender(mapCityName(mapCountryName(mapConfig(ListHeaderTitle)))));
