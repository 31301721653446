import React from 'react';
import PropTypes from 'prop-types';
import { withNavigation } from 'react-navigation';
import { BackHandler, StyleSheet } from 'react-native';

import ImageButton from 'dating-mobile/src/components/navigation/views/image-button';
import Resources from 'dating-mobile/src/resources';

import styles from './styles';

export default function bindNavigation(Component) {
  class Navigation extends React.Component {
    static displayName = 'stepped-registration.screens.boost.navigation';

    static propTypes = {
      navigation: PropTypes.object.isRequired,
    };

    componentDidMount() {
      this.backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        () => true,
      );
    }

    componentWillUnmount() {
      this.backHandler.remove();
    }

    onComplete = () => {
      const { navigation } = this.props;
      const onComplete = navigation.getParam('onComplete');

      if (onComplete) {
        onComplete();
      }
    };

    render() {
      return <Component {...this.props} onComplete={this.onComplete} />;
    }
  }

  Navigation.navigationOptions = ({ navigationOptions, navigation }) => {
    return {
      gesturesEnabled: false,
      headerTitle: '',
      headerStyle: [navigationOptions.headerStyle, styles.header],
      headerRight: () => (
        <ImageButton
          image={Resources.images.closeIcon()}
          tint={StyleSheet.flatten(styles.headerTitle).color}
          onPress={navigation.getParam('onComplete')}
        />
      ),
    };
  };

  return withNavigation(Navigation);
}
