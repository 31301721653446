import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        width: '100%',
        height: '100%',
        backgroundColor: '$primaryBackgroundColor',
        justifyContent: 'space-between',
        flexGrow: 1,
        paddingHorizontal: 40,
    },
});

export default styles;
