import url from 'url';
import OS from '@sdv/domain/app/os';

import request from '../../../commons/utils/request';

function CreateActions(id) {
  class Actions {
    post = (path, files) => async (dispatch, flux) => {
      const uri = url.format({
        protocol: 'https',
        hostname: flux.api.getBaseHost().replace('//', ''),
        pathname: path,
      });

      try {
        const headers = {
          // TODO Check on IOS
          // 'content-type': 'multipart/form-data',
          accept: 'application/json',
          authorization: flux.api.authorize(),
        };

        if (OS.shared().current !== 'web') {
          headers['user-agent'] = flux.api.augment('user-agent');
        }

        const response = await request(
          uri,
          {
            method: 'POST',
            body: getFormData(files),
            headers,
          },
          event => {
            const progress = Math.round((event.loaded / event.total) * 100);

            dispatch(progress);
          },
        );

        try {
          const json = JSON.parse(response.responseText);
          const assets =
            json.basenames || json.basename ? [json.basename] : json;

          dispatch(assets);
        } catch (error) {
          dispatch(null, error);
        }
      } catch (error) {
        dispatch(null, error);
      }
    };
  }

  Actions.displayName = CreateActions.getDisplayName(id);

  return Actions;
}

CreateActions.getDisplayName = function(id) {
  return `uploader.${id}`;
};

export default CreateActions;

function getFormData(files) {
  const formData = new FormData();

  if (files instanceof Blob) {
    formData.append('blob', files, '___.jpg');
  } else if (files.blob instanceof Blob) {
    formData.append('blob', files.blob, files.name);
  } else if (Array.isArray(files)) {
    files.forEach(file => formData.append(file.name, file));
  } else {
    formData.append(files.name, files);
  }

  return formData;
}
