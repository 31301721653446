import React from 'react';
import { View, Image, Text, AppState } from 'react-native';
import styles from './styles';
import SoundPlayer from '../../../../native-modules/sound-player';
import Photo from '../../../../components/photo';

class Gift extends React.PureComponent {
    static displayName = 'dialogs.stream.gift';

    constructor(props) {
        super(props);
        try {
            if (AppState.currentState === 'active' && !props.muted) {
                SoundPlayer.playSoundFile('donation_received', 'mp3');
            }
        } catch (e) {
            console.log('error playing sound', e);
        }
    }

    render() {
        return (
            <View style={styles.container}>
                <Photo
                    style={styles.donation}
                    path={this.props.donationPath}
                />
                <View style={styles.userContainer}>
                    <Image style={styles.star} source={require('./assets/chatStar.png')}/>
                    <Text style={styles.userName}>{this.props.user.name}</Text>
                    <Image style={styles.star} source={require('./assets/chatStar.png')}/>
                    <Text style={styles.message}>Sent a gift!</Text>
                </View>
            </View>
        )

    }
}

export default Gift;
