import React from 'react';
import PropTypes from 'prop-types';
import { TextInput } from 'react-native';

import styles from './styles';

export const AutoGrowingTextInput = React.forwardRef(
  ({ style, ...props }, ref) => {
    return <TextInput ref={ref} style={[style, styles.input]} {...props} />;
  },
);

AutoGrowingTextInput.propTypes = {
  style: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ]),
};

AutoGrowingTextInput.defaultProps = {
  style: {},
};
