import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import withUserController from 'dating-mobile/src/models/user/controllers';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';
import flux from '@sdv/domain/app/flux';
import UserFeatures from '@sdv/domain/user/tags/features';
import withUserPreferences from 'dating-mobile/src/models/user.preference/controllers';
import oppositeGender from 'dating-mobile/src/utils/opposite-gender';

export default function createController(Component) {
  class Controller extends PureComponent {
    static displayName =
      'stepped-registration.screens.birthday-with-gender.controller';

    static propTypes = {
      updateUser: PropTypes.func.isRequired,
      updatePreference: PropTypes.func.isRequired,
      onComplete: PropTypes.func.isRequired,
      id: PropTypes.string,
    };

    static contextTypes = {
      flux: PropTypes.object,
    };

    onSubmit = async (birthday, gender) => {
      try {
        const { updateUser, updatePreference, onComplete, id } = this.props;

        await Promise.all([
          updateUser({
            birthday,
            gender,
          }),
          updatePreference({
            gender,
            'preferred-gender': oppositeGender(gender),
          }),
        ]);

        flux.get(UserFeatures, id)?.actions?.get();

        if (onComplete) {
          onComplete();
        }
      } catch (e) {
        // TODO:
      }
    };

    render() {
      return <Component {...this.props} onSubmit={this.onSubmit} />;
    }
  }

  return withIdentityId(
    withUserPreferences(withUserController(Controller)),
    'id',
  );
}
