function CreateRolesActions(id) {

    class Actions {

        get = () => async (dispatch, flux) => {
            try {
                const roles = await getRoles(id, flux);

                dispatch(roles);

            } catch(error) {
                dispatch(null, error);
            }

        };

        patch() {

        }

        setInternal(payload) {
            return function (dispatch) {
                dispatch(payload);
            };
        }

        removeInternal(payload) {
            return function (dispatch) {
                dispatch(payload);
            };
        }
    }

    Actions.displayName = CreateRolesActions.getDisplayName(id);

    return Actions
}

CreateRolesActions.getDisplayName = function(id) {
    return `user-roles.${id}`;
};

export default CreateRolesActions;


const getRoles = (id, flux) => {

    return new Promise((resolve, reject) => {

        flux.api.users.roles.get(id, (err, res) => {

            if (err) {
                return reject(err);
            }

            return resolve(res);
        });

    });

};
