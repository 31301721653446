import firebase from 'firebase';

const { performance, remoteConfig, messaging, analytics } = firebase;

const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH,
  REACT_APP_FIREBASE_DATABASE_URL,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
} = process.env;

if (REACT_APP_FIREBASE_API_KEY) {
  firebase.initializeApp({
    apiKey: REACT_APP_FIREBASE_API_KEY,
    authDomain: REACT_APP_FIREBASE_AUTH,
    databaseURL: REACT_APP_FIREBASE_DATABASE_URL,
    projectId: REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: REACT_APP_FIREBASE_APP_ID,
    measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID,
  });
}

function perf() {
  const legacyPerf = performance();
  const newPerf = Object.create(legacyPerf);

  newPerf.newTrace = legacyPerf.trace;

  return newPerf;
}

function config() {
  const legacyConfig = remoteConfig();
  const newConfig = Object.create(legacyConfig);

  newConfig.activateFetched = legacyConfig.activate;
  newConfig.enableDeveloperMode = () => {
    legacyConfig.settings.minimumFetchIntervalMillis = 3600;
  };
  newConfig.getKeysByPrefix = prefix =>
    Object.keys(legacyConfig.getAll()).filter(key => key.includes(prefix));
  newConfig.getValue = async key => {
    const value = await legacyConfig.getValue(key);
    const newValue = Object.create(value);

    // eslint-disable-next-line no-underscore-dangle
    newValue.val = () => value._value;

    return newValue;
  };

  return newConfig;
}

function notifications() {
  return {
    setBadge: () => {},
    removeAllDeliveredNotifications: () => {},
  };
}

export { perf, config, messaging, analytics, notifications };
export default firebase;
