import connect from '@sdv/connect';
import IdentityModel from '@sdv/domain/identity/model';

const IdentityHoc = (Component, propName = 'userId') => {

    function getModels(flux) {

        return {
            identityModel: flux.get(IdentityModel)
        };

    }

    function mapStoresToProps(models) {

        return {
            [propName]: models.identityModel.store.getState().id
        };

    }

    function mapStoresToActions() {
        return {};
    }

    function shouldReconnect() {
        return false;
    }

    return connect(
        getModels,
        mapStoresToProps,
        mapStoresToActions,
        shouldReconnect
    )(Component);

};

export default IdentityHoc;
