import React from 'react';
import PropTypes from 'prop-types';
import Resources from 'dating-mobile/src/resources';
import {
  UserCredentials,
  ChangeEmailResult,
} from '@sdv/domain/user/user-credentials';

export default function withController(Component) {
  class Controller extends React.Component {
    static displayName = 'user.screens.change-email.controller';

    static propTypes = {
      userId: PropTypes.string,
      close: PropTypes.func,
      setHeaderState: PropTypes.func,
    };

    state = {};

    componentDidMount() {
      this.subscribe();

      this.setHeaderState({
        onSaveButtonPress: this.onSaveButtonPressed,
        onCancelButtonPress: this.close,
        saveButtonEnabled: false,
      });
    }

    componentDidUpdate(prevProps) {
      const { userId } = this.props;

      if (userId !== prevProps.userId) {
        this.unsubscribe();
        this.subscribe();
      }
    }

    componentWillUnmount() {
      this.unsubscribe();
    }

    setHeaderState = state => {
      const { setHeaderState } = this.props;

      if (setHeaderState) {
        setHeaderState(state);
      }
    };

    close = () => {
      const { close } = this.props;

      if (close) {
        close();
      }
    };

    onSaveButtonPressed = () => {
      const { email, initialEmail } = this.state;

      if (!this.userCredentials) {
        return;
      }

      if (email === initialEmail) {
        this.close();

        return;
      }

      this.userCredentials.changeEmail(email).subscribe(result => {
        // eslint-disable-next-line
        switch (result) {
          case ChangeEmailResult.success:
            this.close();
            break;
          case ChangeEmailResult.invalidFormat:
          case ChangeEmailResult.notReal:
            this.setState({
              validationError:
                Resources.strings['change-email-invalid-error-message'],
            });
            this.setHeaderState({
              saveButtonEnabled: false,
            });
            break;
          case ChangeEmailResult.alreadyTaken:
            this.setState({
              validationError:
                Resources.strings['change-email-already-take-error-message'],
            });
            this.setHeaderState({
              saveButtonEnabled: false,
            });
            break;
        }
      });
    };

    onValueChanged = email => {
      this.setState({
        email,
        validationError: null,
      });
      this.setHeaderState({
        saveButtonEnabled: true,
      });
    };

    subscribe() {
      const { userId } = this.props;

      if (!userId) {
        return;
      }

      this.userCredentials = new UserCredentials(userId);
      this.subscription = this.userCredentials.email.subscribe(email => {
        this.setState({
          email,
          initialEmail: email,
        });
      });
    }

    unsubscribe() {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }

      this.userCredentials = null;
      this.subscription = null;
    }

    render() {
      const { email, validationError } = this.state;

      return (
        <Component
          {...this.props}
          value={email}
          onValueChange={this.onValueChanged}
          validationError={validationError}
        />
      );
    }
  }

  return Controller;
}
