import { Platform } from 'react-native';
import Style from 'react-native-extended-stylesheet/src/style';

/*
    React-native android can't use fontWeight prop to extract corresponding fonts weights and styles. Make it manually.

    Fonts should be placed into main/assets/fonts and named correspondingly:

    {fontName}_light for '300'
    {fontName} for '400' or 'normal
    {fontName}_medium for '500'
    {fontName}_semibold for '600'
    {fontName}_bold for '700' or 'bold'

    e.g. 'nunito_semibold'

    TODO: add font styles
*/

if (Platform.OS === 'android') {
    (function (calcProps) {

        Style.prototype.calcProps = function (...args) {
            const result = calcProps.apply(this, ...args);
            const fontFamily = this.calculatedProps.fontFamily;
            const fontWeight = this.calculatedProps.fontWeight || 'normal';

            if (fontFamily) {
                let fontWeightName;

                switch (fontWeight) {
                    case '300':
                        fontWeightName = '_light';
                        break;
                    case '400':
                    case 'normal':
                        fontWeightName = '_regular';
                        break;
                    case '500':
                        fontWeightName = '_medium';
                        break;
                    case '600':
                        fontWeightName = '_semibold';
                        break;
                    case '700':
                    case 'bold':
                        fontWeightName = '_bold';
                        break;
                    default: break;
                }

                if (fontWeightName) {
                    this.calculatedProps.fontFamily = fontFamily + `${fontWeightName}`;
                    delete this.calculatedProps.fontWeight;
                }

            }

            return result;
        };

    })(Style.prototype.calcProps);

}
