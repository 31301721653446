import PropTypes from 'prop-types';
import Service from 'dating-mobile/src/services/service';
import { INBOX_MODES } from 'dating-mobile/src/inbox/inbox-modes';
import { NavigationActions, StackActions } from 'react-navigation';
import Navigator from 'dating-mobile/src/routing/navigator';
import * as ROUTES from 'dating-mobile/src/routing/router/constants';
import withConfigValue from 'dating-mobile/src/components/config-value';
import url from 'url';
import deepLinking from './deep-linking';

class DeepLinkedLettersInbox extends Service {
  static displayName = 'service.deeplinking.deep-linked-letters-inbox';

  static contextTypes = {
    flux: PropTypes.object,
  };

  static propTypes = {
    userId: PropTypes.string,
    inboxEnabled: PropTypes.bool,
  };

  componentDidMount() {
    deepLinking.addDeepLinkListener(this.lastDeepLink);
  }

  componentWillUnmount() {
    deepLinking.removeDeepLinkListener(this.lastDeepLink);
  }

  lastDeepLink = uri => {
    if (!this.props.inboxEnabled) {
      return;
    }

    const parsedUrl = url.parse(uri);

    if (!parsedUrl.pathname) {
      return;
    }

    const components = parsedUrl.pathname.split('/');

    let tabName = null;
    const actions = [];

    if (components[1] === 'inbox') {
      tabName = ROUTES.INBOX_TAB;
      if (components[2]) {
        let mode = null;

        switch (components[2]) {
          case 'all':
            mode = INBOX_MODES.ALL;
            break;
          case 'unread':
            mode = INBOX_MODES.UNREAD;
            break;
          case 'read_and_unanswered':
            mode = INBOX_MODES.READ_AND_UNANSWERED;
            break;
        }

        actions.push(
          NavigationActions.navigate({
            routeName: ROUTES.INBOX,
            params: { mode },
          }),
        );
      } else {
        actions.push(
          NavigationActions.navigate({ routeName: ROUTES.INBOX, params: {} }),
        );
      }
    }

    if (tabName) {
      const action = actions.length
        ? StackActions.reset({
            index: actions.length - 1,
            actions,
          })
        : null;

      Navigator.navigate(tabName, {}, action);
    }
  };
}

export default withConfigValue(DeepLinkedLettersInbox, {
  inboxEnabled: 'features.inbox-enabled',
});
