import React, { Fragment } from 'react';
import {
  ActivityIndicator,
  Alert,
  Image,
  Keyboard,
  Linking,
  Platform,
  Text,
  TouchableOpacity,
  View,
  I18nManager,
} from 'react-native';
import PropTypes from 'prop-types';
import { localizePrice } from '@sdv/commons/price-with-code-localization';
import {
  calculatePriceWithoutTax,
  calculateTax,
} from '@sdv/domain/payment/price';
import ConfigValue from 'dating-mobile/src/components/config-value';

import { AutobuyCheckbox } from '../../views/autobuy-checkbox';
import { CreditCardPaymentForm } from '../../views/credit-card-payment-form';
import { CreditCardPicker } from '../../views/credit-card-picker';
import ImageButton from '../../../components/navigation/views/image-button';
import ConfigBuilder from '../../../app/config-builder';
import Resources from '../../../resources';

import styles from './styles';

class CreditsScreen extends React.PureComponent {
  static displayName = 'payment.screens.card.view';

  static propTypes = {
    onCardUpdate: PropTypes.func.isRequired,
    onCardSelect: PropTypes.func.isRequired,
    onBrowserCardSelect: PropTypes.func.isRequired,
    onFormDirty: PropTypes.func.isRequired,
    onPurchasePress: PropTypes.func.isRequired,
    onTermsLinkPressed: PropTypes.func.isRequired,
    purchaseEnabled: PropTypes.bool,
    paymentException: PropTypes.object,
    cardPickerVisible: PropTypes.bool,
    onNewCardPress: PropTypes.func,
    onSelfClose: PropTypes.func,
    autobuySettings: PropTypes.shape({
      enabled: PropTypes.bool,
      available: PropTypes.bool,
      creditsAmount: PropTypes.number,
    }),
    onAutobuyEnable: PropTypes.func,
    currency: PropTypes.string,
    invoice: PropTypes.object,
    isLoading: PropTypes.bool,
  };

  componentDidUpdate() {
    const { paymentException } = this.props;

    if (paymentException) {
      Alert.alert(
        Resources.strings['payment-error-alert-title'],
        Resources.strings['payment-error-alert-message'],
        [
          {
            text: Resources.strings.ok,
          },
        ],
      );
    }
  }

  onPurchasePress = () => {
    const { onPurchasePress } = this.props;

    Keyboard.dismiss();
    if (onPurchasePress) {
      onPurchasePress();
    }
  };

  onLinkPress = () => {
    Linking.openURL(`mailto:${ConfigBuilder.config['customer-support'].email}`);
  };

  renderContent = () => {
    const {
      cardPickerVisible,
      onNewCardPress,
      onCardSelect,
      onCardUpdate,
      onFormDirty,
    } = this.props;

    if (cardPickerVisible) {
      return (
        <CreditCardPicker
          onCardPick={onCardSelect}
          onNewCardPress={onNewCardPress}
        />
      );
    }

    return (
      <CreditCardPaymentForm onDirty={onFormDirty} onInput={onCardUpdate} />
    );
  };

  renderPrice = () => {
    const { invoice, currency, onTermsLinkPressed } = this.props;

    if (!invoice) {
      return null;
    }

    const useCredits = currency && currency === 'credits';
    const { price, creditsAmount, containsMembership } = invoice;
    let localizedPrice = '';

    if (containsMembership) {
      localizedPrice = Resources.strings.formatString(
        Resources.strings[
          price.introductory
            ? 'payment-membership-introductory-price-info'
            : 'payment-membership-price-info'
        ],
        price.introductory || price.localizedIncludingTax,
        price.localizedIncludingTax,
      );
    } else {
      localizedPrice = Resources.strings.formatString(
        Resources.strings[
          useCredits ? 'payment-credit-price-info' : 'payment-coin-price-info'
        ],
        creditsAmount,
        localizePrice(price.currency, calculatePriceWithoutTax(price)),
      );

      if (price.tax !== undefined) {
        const { abbreviation } = price.tax;
        const taxPrice = localizePrice(price.currency, calculateTax(price));

        localizedPrice += Resources.strings.formatString(
          Resources.strings['payment-plus-tax-info'],
          taxPrice,
          abbreviation,
        );
      }
    }

    return (
      <Text style={styles.price}>
        {localizedPrice}{' '}
        {(Boolean(containsMembership) || useCredits) && (
          <Text style={styles.link} onPress={onTermsLinkPressed}>
            {
              Resources.strings[
                'memberships-screen-terms-agreement-terms-link-text'
              ]
            }
          </Text>
        )}
      </Text>
    );
  };

  onAutobuyEnableChanged = enabled => {
    const { onAutobuyEnable } = this.props;

    if (onAutobuyEnable) {
      onAutobuyEnable(enabled);
    }
  };

  render() {
    const {
      autobuySettings,
      purchaseEnabled,
      isLoading,
      invoice,
      onSelfClose,
      onBrowserCardSelect,
    } = this.props;
    const autobuySettingsVisible =
      autobuySettings &&
      autobuySettings.availableDuringPayment &&
      Platform.OS !== 'web';
    const { containsMembership, isPremiumMembership } = invoice;

    return (
      <View style={styles.container}>
        <View style={styles.contentContainer}>
          <View style={styles.content}>
            <Text style={styles.header}>
              {containsMembership
                ? Resources.strings.formatString(
                    Resources.strings['card-payment-title-membership'],
                    isPremiumMembership
                      ? Resources.strings[
                          'package-list-premium-subscription-package-name'
                        ]
                      : Resources.strings[
                          'package-list-basic-subscription-package-name'
                        ],
                  )
                : Resources.strings.formatString(
                    Resources.strings['card-payment-title-diamonds'],
                    invoice.creditsAmount,
                  )}
            </Text>
            {Platform.OS === 'web' && (
              <Fragment>
                <TouchableOpacity
                  style={styles.browserCardButton}
                  onPress={onBrowserCardSelect}
                >
                  <Text style={styles.purchaseButtonText}>
                    {Resources.strings['saved-browser-cards-button-title']}
                  </Text>
                </TouchableOpacity>
                <View>
                  <Text style={styles.formLabel}>
                    {Resources.strings['payment-card-form-label']}
                  </Text>
                </View>
              </Fragment>
            )}

            {this.renderContent()}
            <TouchableOpacity
              onPress={this.onPurchasePress}
              disabled={!purchaseEnabled}
              style={
                purchaseEnabled
                  ? styles.purchaseButton
                  : styles.purchaseButtonDisabled
              }
            >
              <Text style={styles.purchaseButtonText}>
                {containsMembership
                  ? Resources.strings.formatString(
                      Resources.strings['card-payment-button-membership'],
                      isPremiumMembership
                        ? Resources.strings[
                            'package-list-premium-subscription-package-name'
                          ]
                        : Resources.strings[
                            'package-list-basic-subscription-package-name'
                          ],
                    )
                  : Resources.strings['card-payment-button-diamonds']}
              </Text>
            </TouchableOpacity>
          </View>

          {this.renderPrice()}
        </View>

        {autobuySettingsVisible && (
          <AutobuyCheckbox
            layoutStyles={styles.autobuyCheckboxStyles}
            settings={autobuySettings}
            onChecked={this.onAutobuyEnableChanged}
          />
        )}
        <View style={styles.footerWrapper}>
          <View style={styles.paymentProtectionInfo}>
            <Text style={styles.footerText}>
              {Resources.strings['payment-card-secured-text']}
            </Text>
            <View style={styles.paymentProtectionIcons}>
              <Image
                source={Resources.images.visaProtected()}
                style={styles.icon}
                resizeMode="contain"
              />
              <Image
                source={Resources.images.masterCardProtected()}
                style={styles.icon}
                resizeMode="contain"
              />
            </View>
          </View>
          <View style={styles.footer}>
            <Text style={styles.footerText}>
              {!I18nManager.isRTL && (
                <Text>{Resources.strings['customer-support-message-4']}</Text>
              )}
              <Text onPress={this.onLinkPress} style={styles.link}>
                {ConfigBuilder.config['customer-support'].email}
              </Text>
              {I18nManager.isRTL && (
                <Text>{Resources.strings['customer-support-message-4']}</Text>
              )}
            </Text>
          </View>
        </View>

        {isLoading && (
          <TouchableOpacity style={styles.activityIndicatorContainer} disabled>
            <ActivityIndicator animating />
          </TouchableOpacity>
        )}
        {Platform.OS === 'web' && (
          <View style={styles.closeButton}>
            <ImageButton
              image={Resources.images.closeSmallIcon()}
              onPress={onSelfClose}
            />
          </View>
        )}
      </View>
    );
  }
}

export default ConfigValue(CreditsScreen, 'currency.type', 'currency');
