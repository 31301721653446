import EStyleSheet from 'react-native-extended-stylesheet';
import { Platform } from 'react-native';

const styles = EStyleSheet.create({
  container: {
    flexDirection: 'column',
    alignItems: 'stretch',
  },

  extraMargin: {
    marginHorizontal: 30,
  },

  small: {
    ...Platform.select({
      ios: {
        minHeight: 210,
      },
      android: {
        minHeight: 245,
      },
    }),
  },

  big: {
    ...Platform.select({
      ios: {
        minHeight: 245,
      },
      android: {
        minHeight: 280,
      },
    }),
  },

  iconWithoutTint: {
    tintColor: undefined,
  },

  highlightedIcon: {
    backgroundColor: '$accentColor',
  },

  highlightedTitle: {
    color: '$accentColor',
    fontFamily: '$brandFontFamily',
  },

  itemContainer: {
    height: undefined,
    marginBottom: 15,
  },

  itemWithBadgeContainer: {
    height: undefined,
  },

  badgeContainer: {
    justifyContent: 'center',
    marginBottom: 15,
  },

  badge: {
    position: 'absolute',
    left: -50,
    backgroundColor: '#e02020',
    borderRadius: 12,
    width: 44,
    height: 22,
    justifyContent: 'center',
    alignItems: 'center',
  },

  badgeText: {
    fontSize: 13,
    color: '$brandTextColor',
    fontFamily: '$brandFontFamily',
  },

  bold: {
    fontWeight: 'bold',
  },
});

export default styles;
