import React, { PureComponent } from 'react';
import { View, Text, Image } from 'react-native';
import PropTypes from 'prop-types';
import Resources from 'dating-mobile/src/resources';
import { SafeAreaView } from 'react-navigation';
import { GradientButton } from 'dating-mobile/src/components/buttons/gradient';
import { FeaturesList } from './features-list';
import styles from './styles';

export default class CongratulationView extends PureComponent {
    static propTypes = {
        onButtonPress: PropTypes.func,
    };

    render() {
        const { onButtonPress } = this.props;

        return (
            <View style={styles.container}>
                <View style={styles.imageContainer}>
                    <Image source={Resources.images.congratulationsBackground()} />
                </View>
                <SafeAreaView
                    forceInset={{ top: 'always', bottom: 'always' }}
                    style={styles.safeArea}
                >
                    <Text style={styles.title}>
                        {Resources.strings['subscription-congratulation-title']}
                    </Text>

                    <FeaturesList />

                    <GradientButton
                        onPress={onButtonPress}
                        containerStyles={styles.button}
                        text={Resources.strings['subscription-congratulation-button-text']}
                    />
                </SafeAreaView>
            </View>
        );
    }
}
