import withConfigValue from 'dating-mobile/src/components/config-value';
import View from './view';
import withNavigation from './navigation';
import withController from './controller';

export const PurchaseScreen = withNavigation(
  withConfigValue(withController(View), {
    premiumSubscriptionEnabled: 'features.premium-subscription-enabled',
  }),
);
