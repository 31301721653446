import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet } from 'react-native';
import testId from 'dating-mobile/src/utils/test-id';
import ImageButton from 'dating-mobile/src/components/navigation/views/image-button';
import Resources from 'dating-mobile/src/resources';
import RangePicker from '../../../../components/range-picker';
import styles from './styles';

class RangePickerEditing extends React.PureComponent {
  static displayName = 'user.edit.range-picker-editing';

  static propTypes = {
    navigation: PropTypes.object.isRequired,
    onComplete: PropTypes.func,
    allowEmpty: PropTypes.bool,
    minValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    maxValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultMin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultMax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static navigationOptions = ({ navigation, navigationOptions }) => ({
    title: navigation.getParam('title'),
    headerStyle: [navigationOptions.headerStyle, styles.header],
    headerTitleStyle: [navigationOptions.headerTitleStyle, styles.headerTitle],
    headerLeft: () => (
      <ImageButton
        image={Resources.images.backIcon()}
        tint={
          StyleSheet.flatten([
            navigationOptions.headerTitleStyle,
            styles.headerTitle,
          ]).color
        }
        onPress={() => {
          const goBack = navigation.getParam('goBack');

          if (goBack) {
            goBack();
          } else {
            navigation.pop();
          }
        }}
        {...testId('Back button')}
      />
    ),
  });

  constructor(props) {
    super(props);
    this.state = {
      selectedMin: this.getMinValue(),
      selectedMax: this.getMaxValue(),
    };
  }

  componentDidMount() {
    const { navigation } = this.props;

    navigation.setParams({ goBack: this.goBack });
  }

  goBack = () => {
    const { navigation, onComplete } = this.props;
    const { selectedMin, selectedMax } = this.state;

    if (
      selectedMin !== this.getMinValue() ||
      selectedMax !== this.getMaxValue()
    ) {
      const completion = onComplete || navigation.getParam('onComplete');

      completion({ min: selectedMin, max: selectedMax });
    }

    navigation.pop();
  };

  getMinValue = () => {
    const { navigation, minValue } = this.props;

    return minValue || navigation.getParam('minValue');
  };

  getMaxValue = () => {
    const { navigation, maxValue } = this.props;

    return maxValue || navigation.getParam('maxValue');
  };

  getDefaultMinKey = () => {
    const { navigation, defaultMin } = this.props;

    return defaultMin || navigation.getParam('defaultMin');
  };

  getDefaultMaxKey = () => {
    const { navigation, defaultMax } = this.props;

    return defaultMax || navigation.getParam('defaultMax');
  };

  getEmptyAllowed = () => {
    const { navigation, allowEmpty } = this.props;

    return allowEmpty || navigation.getParam('allowEmpty');
  };

  onRangeChange = data => {
    this.setState({ selectedMin: data.min, selectedMax: data.max });
  };

  render() {
    const { selectedMin, selectedMax } = this.state;
    const allowEmpty = this.getEmptyAllowed();
    const min = selectedMin || (allowEmpty ? null : this.getDefaultMinKey());
    const max = selectedMax || (allowEmpty ? null : this.getDefaultMaxKey());

    return (
      <View style={styles.container}>
        <RangePicker
          range={{ min: this.getDefaultMinKey(), max: this.getDefaultMaxKey() }}
          placeholder={{
            from: Resources.strings.from,
            to: Resources.strings.to,
          }}
          selectedMin={min}
          selectedMax={max}
          onUpdateRange={this.onRangeChange}
          allowEmpty={allowEmpty}
          itemStyle={styles.item}
        />
      </View>
    );
  }
}

export default RangePickerEditing;
