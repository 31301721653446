import React from 'react';
import PropTypes from 'prop-types';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import Resources from '../../../resources';
import styles from './styles';

export default class MingleActiveContent extends React.Component {
    static displayName = 'notifications.popup.mingle-active';
    static propTypes = {
        notification: PropTypes.object,
        stopMingleAndDismiss: PropTypes.func.isRequired,
        onReadyForDisplay: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.props.onReadyForDisplay();
    }

    stopMingle = () => {
        this.props.stopMingleAndDismiss();
    };

    render() {
        return (
            <View style={styles.container}>
                <View style={styles.iconContainer}>
                    <Image style={styles.icon} source={Resources.images.letsMingleIcon()} />
                </View>
                <View style={styles.textContainer}>
                    <Text style={styles.text}>{Resources.strings['mingle-active-notification-text']}</Text>
                </View>
                <TouchableOpacity style={styles.stopButton} onPress={this.stopMingle}>
                    <Text style={styles.stopButtonTitle}>{Resources.strings['mingle-active-notification-stop-button-title']}</Text>
                </TouchableOpacity>
            </View>
        );

    }
};
