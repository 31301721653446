import React from 'react';
import PropTypes from 'prop-types';
import { withBlockableUser } from 'dating-mobile/src/user/views/blocked-user/with-blockable-user';

function createController(Component) {

    class Controller extends React.Component {
        static displayName = 'user.profile.screen.controller';
        static propTypes = {
            userId: PropTypes.string.isRequired
        };

        render() {
            return <Component {...this.props}/>
        }
    }

    return withBlockableUser(Controller);
}

export default createController;
