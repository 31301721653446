/* eslint-disable import/no-unresolved */
/* eslint-disable no-undef */
import { shopName } from '@sdv/domain/app/payment/shop-info';
import Api, { toObservable } from '@sdv/domain/api';
import { catchError, mapTo, mergeMap, tap } from 'rxjs/operators';
import {
  ThreeDsBootstrapper,
  ThreeDsGateway,
} from '@sdv/domain/app/payment/card/3ds';
import { of, ReplaySubject } from 'rxjs';
import { Persistence } from '@sdv/domain/app/persistence';
import OS from '@sdv/domain/app/os';
import { AutobuyPayment } from '@sdv/domain/autobuy/autobuy-payment';
import { getThreeDsData } from './three-ds';

/**
 * Credit Card Info
 * @typedef {{ number: string, expDate: string, verification: string, holder: string }} CreditCard
 */

const paymentMethod = 'cards';
const persistenceKey = 'CreditCardPayment';

class CreditCardPaymentImpl {
  /**
   * @param {Object} options
   * @param {string} options.userId
   * @param {Invoice} options.invoice
   * @param {CreditCard} options.card
   * @param {string} options.reason
   * @param {string} options.threeDsRestoreMarker
   */
  constructor(options) {
    this.threeDsGateway = ThreeDsGateway.shared();
    this.threeDsBootstrapper = ThreeDsBootstrapper.shared();

    const init = state => {
      this.userId = state.userId;
      this.invoice = state.invoice;
      this.reason = state.reason;
      this.card = state.card;
      this.receipt = state.receipt;
    };

    init(options);

    this.apiReceipt = new ReplaySubject(1);
    this.result = new ReplaySubject(1);

    const { threeDsRestoreMarker } = options;

    if (threeDsRestoreMarker) {
      // restore state from persistence
      Persistence.shared(persistenceKey, this.userId)
        .load(threeDsRestoreMarker)
        .subscribe(state => {
          if (state) {
            init(state);
            if (state.receipt) {
              this.apiReceipt.next(state.receipt);
            }
          }
        });
    }

    this.apiReceipt
      .pipe(
        tap(receipt => {
          this.receipt = receipt;
        }),
        mergeMap(receipt => this.handleThreeDs(receipt)),
        mergeMap(threeDsResult => {
          if (threeDsResult.success) {
            return this.exchange(threeDsResult.receipt).pipe(
              mapTo({ success: true }),
            );
          }

          return of({ success: false });
        }),
      )
      .subscribe(this.result);
  }

  purchase = () => {
    return this.getReceipt().pipe(
      mergeMap(receipt => {
        this.apiReceipt.next(receipt);

        return this.result;
      }),
      catchError(err => {
        this.result.error(err);

        return this.result;
      }),
    );
  };

  /**
   *
   * @param receipt
   * @return {Observable<{success: boolean, receipt: string}>}
   */
  handleThreeDs(receipt) {
    const threeDsData = getThreeDsData(receipt);

    if (threeDsData) {
      const { Md: threeDsRestoreMarker } = threeDsData;

      return Persistence.shared(persistenceKey, this.userId)
        .store(threeDsRestoreMarker, this.dumpState())
        .pipe(
          mergeMap(() => {
            this.threeDsBootstrapper.restoreMeBy(threeDsRestoreMarker);

            return this.threeDsGateway.handleThreeDs(receipt);
          }),
        );
    }

    return of({ success: true, receipt: receipt.receipt });
  }

  getReceipt() {
    const cardRequisites = buildCardRequisites(this.card);
    const purchase = {
      amount: this.invoice.creditsAmount,
      price: this.invoice.price.includingTax,
      method: paymentMethod,
      shop: shopName,
      sku: this.invoice.sku,
      requisites: cardRequisites,
      'auto-refill': false,
    };

    if (OS.shared().current === 'web') {
      purchase.deviceInfo = {
        timezoneOffsetUtcMinutes: new Date().getTimezoneOffset(),
        locale: window.navigator.language,
        javaEnabled: window.navigator.javaEnabled(),
        javaScriptEnabled: true,
        colorDepth: window.screen.colorDepth,
        height: window.screen.height,
        width: window.screen.width,
      };
    }

    return toObservable(Api.credits.mall.receipts.post, this.userId, purchase);
  }

  exchange(receipt) {
    const receiptToExchange = {
      shop: shopName,
      receipt,
      method: paymentMethod,
    };

    return toObservable(
      Api.credits.mall.exchanges.post,
      this.userId,
      receiptToExchange,
    );
  }

  dumpState() {
    return {
      invoice: this.invoice,
      card: this.card,
      reason: this.reason,
      receipt: this.receipt,
    };
  }
}

/**
 * @private
 * @param {CreditCard| SavedCreditCard} card
 */
function buildCardRequisites(card) {
  if (card.cardId) {
    return {
      'card-id': card.cardId,
    };
  }

  return {
    cardholder: card.holder,
    number: card.number,
    expiration: expirationDate(card.expDate),
    verification: card.verification,
  };
}

export const CreditCardPayment = options => {
  const { autobuySettings } = options;
  const { userId } = options;

  // eslint-disable-next-line no-param-reassign
  delete options.autobuySettings;

  return new AutobuyPayment(
    new CreditCardPaymentImpl(options),
    autobuySettings,
    userId,
  );
};

function expirationDate(expDate) {
  if (typeof expDate === 'object') {
    const { month, year } = expDate;
    const mm = +month;
    let yy = +year;

    if (yy < 100) {
      yy += 2000;
    }
    // eslint-disable-next-line no-param-reassign
    expDate = new Date(Date.UTC(yy, mm - 1, 1)).toISOString();
  }

  return expDate;
}
