import React from 'react';
import PropTypes from 'prop-types';
import { View, FlatList } from 'react-native';
import withConfigValue from 'dating-mobile/src/components/config-value';
import CheerItem from '../cheer-item';
import styles from './styles';

class Cheers extends React.PureComponent {
  static propTypes = {
    cheers: PropTypes.arrayOf(
      PropTypes.shape({
        basename: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
      }),
    ),
    onCheerSelected: PropTypes.func,
    currencyType: PropTypes.string,
  };

  onCheerPressed = cheer => {
    const { onCheerSelected } = this.props;

    if (onCheerSelected) {
      onCheerSelected(cheer);
    }
  };

  renderItem = ({ item }) => {
    const { currencyType } = this.props;

    return (
      <View key={item.basename} style={styles.itemContainer}>
        <CheerItem
          cheer={item}
          onPress={this.onCheerPressed}
          currencyType={currencyType}
        />
      </View>
    );
  };

  itemSeparator = () => {
    return <View style={styles.separator} />;
  };

  render() {
    const { cheers } = this.props;

    return (
      <View style={styles.container}>
        <FlatList
          style={styles.list}
          renderItem={this.renderItem}
          data={cheers || []}
          numColumns={4}
          keyExtractor={item => item.basename}
          ItemSeparatorComponent={this.itemSeparator}
        />
      </View>
    );
  }
}

export default withConfigValue(Cheers, { currencyType: 'currency.type' });
