import React from 'react';
import PropTypes from 'prop-types';
import { stickerPath } from 'dating-mobile/src/resources/remote';

export default function createController(Component) {

    class Controller extends React.Component {
        static displayName = 'dialogs.common.sticker.controller';
        static propTypes = {
            stickerName: PropTypes.string,
            style: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.object,
                PropTypes.array
            ])
        };

        render() {
            const path = this.props.stickerName && stickerPath(this.props.stickerName);

            return (
                <Component
                    {...this.props}
                    path={path}
                    forceRetina={true}
                />
            );
        }
    }

    return Controller;

}
