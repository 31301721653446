import React from 'react';
import PropTypes from 'prop-types';
import { UserBlocking } from '@sdv/domain/user/user-blocking';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';
import { render } from 'dating-mobile/src/components/rx-render';

export function withBlockableUser(Component, options = {}) {
  const userIdPropName = options.userIdPropName || 'userId';

  const displayName = 'user.views.blocked-user.with-blockable-user';
  const CURRENT_USER_PROP_NAME = `generated.${displayName}.current-name`;

  const BlockableUser = render((props, { useObservable }) => {
    const userId = props[CURRENT_USER_PROP_NAME];
    const userBlocking = UserBlocking.shared(userId);
    const blockedUsers = useObservable(it => it.blockedUsersIds, userBlocking);

    const targetId = props[userIdPropName];
    const isBlocked = blockedUsers && blockedUsers.indexOf(targetId) >= 0;

    return <Component {...props} isBlocked={isBlocked} />;
  });

  BlockableUser.displayName = displayName;

  BlockableUser.propTypes = {
    [userIdPropName]: PropTypes.string.isRequired,
    [CURRENT_USER_PROP_NAME]: PropTypes.string.isRequired,
    onPopToRootButtonPress: PropTypes.func,
  };

  return withIdentityId(BlockableUser, CURRENT_USER_PROP_NAME);
}
