import EStyleSheet from 'react-native-extended-stylesheet';
import { Platform } from 'react-native';

const styles = EStyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        marginHorizontal: 10,
        ...Platform.select({
            android: {
                marginTop: 5,
            },
        }),
    },
    headerTitle: {
        color: '$primaryTextColor',
    },
    closeIcon: {
        width: 32,
        height: 32,
    },
});

export default styles;
