import Resources from 'dating-mobile/src/resources';

import aboutEn from './assets/en-US/about.htm';
import aboutAr from './assets/ar/about.htm';

import manageProfileEn from './assets/en-US/manage-profile.htm';
import manageProfileAr from './assets/ar/manage-profile.htm';

import premiumFeaturesEn from './assets/en-US/premium-features.htm';
import premiumFeaturesAr from './assets/ar/premium-features.htm';

import premiumServicesCostEn from './assets/en-US/premium-services-cost.htm';
import premiumServicesCostAr from './assets/ar/premium-services-cost.htm';

import turnOffAnonymityEn from './assets/en-US/turn-off-anonymity.htm';
import turnOffAnonymityAr from './assets/ar/turn-off-anonymity.htm';

export const faqList = [
    {
        id: '1',
        localizedTitle: Resources.strings['faq-title-about'],
        source: {
            'en-US': aboutEn,
            ar: aboutAr,
        },
    },
    {
        id: '2',
        localizedTitle: Resources.strings['faq-title-manage-profile'],
        source: {
            'en-US': manageProfileEn,
            ar: manageProfileAr,
        },
    },
    {
        id: '3',
        localizedTitle: Resources.strings['faq-title-turn-off-anonymity'],
        source: {
            'en-US': turnOffAnonymityEn,
            ar: turnOffAnonymityAr,
        },
    },
    {
        id: '4',
        localizedTitle: Resources.strings['faq-title-premium-features'],
        source: {
            'en-US': premiumFeaturesEn,
            ar: premiumFeaturesAr,
        },
    },
    {
        id: '5',
        localizedTitle: Resources.strings['faq-title-premium-services-cost'],
        source: {
            'en-US': premiumServicesCostEn,
            ar: premiumServicesCostAr,
        },
    },
];
