import { merge, of } from 'rxjs';
import {
  take,
  filter,
  switchMap,
  distinctUntilChanged,
  delay,
} from 'rxjs/operators';
import { Stock } from '@sdv/domain/payment/stock';
import { Config } from '@sdv/domain/app/config';
import { singleton } from '@sdv/commons/utils/singleton';
import { BoostExtendNotification } from '@sdv/domain/user/profile-booster/boost-extend-notification';

export class PackagesPreloader {
  static shared = singleton(userId => new PackagesPreloader(userId));

  constructor(userId) {
    this.userId = userId;

    BoostExtendNotification.shared(userId)
      .discountEndTime.pipe(
        distinctUntilChanged(),
        switchMap(endTime =>
          endTime ? merge(of(null), of(null).pipe(delay(endTime))) : of(null),
        ),
        switchMap(this.getPackages),
      )
      .subscribe(
        () => {},
        () => {},
      );

    Config.shared()
      .preloadStockPackagesEnabled.pipe(
        filter(Boolean),
        switchMap(this.getPackages),
      )
      .subscribe(
        () => {},
        () => {},
      );
  }

  getPackages = () =>
    Stock.shared(this.userId).preloadedPackagesInStock.pipe(take(2));

  preload() {
    this.getPackages().subscribe(
      () => {},
      () => {},
    );
  }
}
