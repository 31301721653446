import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, FlatList, LayoutAnimation } from 'react-native';
import { CreateNewsletterButton } from 'dating-mobile/src/newsletter/create-newsletter-button';
import RoundButton from '../../../../components/buttons/round';
import Button from '../../../../components/buttons/base';
import styles from './styles';
import StreamCategories, {
    getTopicFromIndex,
    getDefaultCategoryForPreset,
    numberOfCategoriesInPreset,
    STREAMS_CATEGORIES,
} from '../stream-categories';
import { SafeAreaView } from 'react-navigation';
import StreamController from '../../../../models/dialogs.stream/controllers/controller';
import Item, { getHeight } from '../streams-list/item';
import IfConfigValue from '../../../../components/config-value/if';
import Resources from '../../../../resources';
import testId from '../../../../utils/test-id';
import FeedSignupBanner from '../feed-signup-banner';
import { StreamsDiscoveryAdsBanner } from '../ads';

const StreamItemComponent = StreamController(Item);

const TOP_VIEW = Object.freeze({
    NONE: 0,
    TITLE: 1,
    CATEGORY_PICKER: 2,
});

const numColumns = 2;

export default class StreamsDiscovery extends React.Component {
    static displayName = 'streams-discovery-list';
    static contextTypes = {
        flux: PropTypes.object,
    };

    static propTypes = {
        popular: PropTypes.array,
        following: PropTypes.array,
        cooperative: PropTypes.array,
        showFollowTop: PropTypes.func,
        showStream: PropTypes.func,
        setHeaderHidden: PropTypes.func,
        setHeaderTitle: PropTypes.func,
        categoriesPreset: PropTypes.number,
        safeAreaInsets: PropTypes.object,
        adsRowNumber: PropTypes.number,
        streamAnounsmentEnabled: PropTypes.bool,
        showNewsletterButton: PropTypes.bool,
    };

    static defaultProps = {
        adsRowNumber: 4,
    };

    constructor(props) {
        super(props);

        this.state = {
            selectedIndex: getDefaultCategoryForPreset(props.categoriesPreset).id,
            topView: TOP_VIEW.NONE,
            scrollEnabled: true,
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            this.props.popular !== nextProps.popular ||
            this.props.following !== nextProps.following ||
            this.props.cooperative !== nextProps.cooperative ||
            this.props.categoriesPreset !== nextProps.categoriesPreset ||
            this.state.itemStyle !== nextState.itemStyle ||
            this.state.selectedIndex !== nextState.selectedIndex ||
            this.state.topView !== nextState.topView ||
            this.state.scrollEnabled !== nextState.scrollEnabled
        );
    }

    componentDidMount() {
        this.props.setHeaderTitle &&
            this.props.setHeaderTitle(
                getDefaultCategoryForPreset(this.props.categoriesPreset).text,
            );
        this.props.setHeaderHidden && this.props.setHeaderHidden(this.showRichCategories());
    }

    onCategorySelected = item => {
        if (this.state.selectedIndex !== item.id) {
            this.props.setHeaderTitle && this.props.setHeaderTitle(item.text);
            this.setState({ selectedIndex: item.id });
            if (this.flatList) {
                this.flatList.scrollToOffset({ offset: 0, animated: false });
            }
        }
    };

    renderItem = ({ item }) => {
        return (
            <View style={styles.itemContainer} key={item}>
                <StreamItemComponent onPress={this.itemPressed} id={item} />
            </View>
        );
    };

    showFollowTop = () => {
        this.props.showFollowTop && this.props.showFollowTop();
    };

    renderPlaceholder = () => {
        if (this.state.selectedIndex === STREAMS_CATEGORIES.FOLLOWING) {
            return (
                <View style={styles.placeholderContainer}>
                    <Text style={styles.placeholderDescriptionText}>
                        {
                            Resources.strings[
                                'stream-list-screen-following-placeholder-description-text'
                            ]
                        }
                    </Text>
                    <Text style={styles.placeholderMotivationText}>
                        {
                            Resources.strings[
                                'stream-list-screen-following-placeholder-motivation-text'
                            ]
                        }
                    </Text>
                    <Button
                        style={styles.placeholderButton}
                        title={
                            Resources.strings[
                                'stream-list-screen-following-placeholder-follow-button-title'
                            ]
                        }
                        onPress={this.showFollowTop}
                        {...testId('Follow top button')}
                    />
                </View>
            );
        } else {
            return null;
        }
    };

    animate = () => {
        const animation = LayoutAnimation.create(
            150,
            LayoutAnimation.Types.linear,
            LayoutAnimation.Properties.opacity,
        );
        LayoutAnimation.configureNext(animation);
    };

    onHeaderLayout = event => {
        this.headerHeight = event.nativeEvent.layout.height;
    };

    onScrollBeginDrag = (event, ...args) => {
        this.startScrollOffset = event.nativeEvent.contentOffset.y;
    };

    onBannerSwipeStart = () => {
        this.setState({ scrollEnabled: false });
    };

    onBannerSwipeEnd = () => {
        this.setState({ scrollEnabled: true });
    };

    onScroll = event => {
        if (!this.showRichCategories()) {
            return;
        }

        const currentOffset = event.nativeEvent.contentOffset.y;

        let newValue;
        const scrollTop = currentOffset < this.startScrollOffset;
        if (scrollTop) {
            if (currentOffset <= 0) {
                newValue = TOP_VIEW.NONE;
            } else {
                newValue = TOP_VIEW.CATEGORY_PICKER;
            }
        } else {
            if (currentOffset > this.headerHeight) {
                newValue = TOP_VIEW.TITLE;
            } else {
                newValue = TOP_VIEW.NONE;
            }
        }

        if (this.state.topView !== newValue) {
            this.animate();
            this.props.setHeaderHidden && this.props.setHeaderHidden(newValue !== TOP_VIEW.TITLE);
            this.setState({ topView: newValue });
        }
    };

    renderHeader = () => {
        if (!this.showRichCategories()) {
            return (
                <View onLayout={this.onHeaderLayout}>
                    <FeedSignupBanner
                        onSwipeStart={this.onBannerSwipeStart}
                        onSwipeEnd={this.onBannerSwipeEnd}
                    />
                </View>
            );
        }

        return (
            <View onLayout={this.onHeaderLayout}>
                <StreamCategories
                    selectedIndex={this.state.selectedIndex}
                    onSelectedItem={this.onCategorySelected}
                    categoriesPreset={this.props.categoriesPreset}
                />
                <FeedSignupBanner
                    onSwipeStart={this.onBannerSwipeStart}
                    onSwipeEnd={this.onBannerSwipeEnd}
                />
            </View>
        );
    };

    itemPressed = item => {
        this.props.showStream && this.props.showStream(item);
    };

    getItemLayout = (data, rowIndex) => {
        if (!this.state.itemStyle) {
            return undefined;
        }

        return {
            length: this.state.itemStyle.height,
            offset: this.state.itemStyle.height * rowIndex,
            index: rowIndex,
        };
    };

    onListLaidOut = event => {
        const { width } = event.nativeEvent.layout;
        if (!width || this.listWidth === width) {
            return;
        }

        this.listWidth = width;

        const itemWidth = (this.listWidth - 2 * styles._list.paddingHorizontal) / 2;
        const itemHeight = getHeight(itemWidth);

        this.setState({
            itemStyle: { width: itemWidth, height: itemHeight },
        });
    };

    showRichCategories = () => {
        return numberOfCategoriesInPreset(this.props.categoriesPreset) > 1;
    };

    getSeparator = ({ leadingItem }) => {
        const index = numColumns * this.props.adsRowNumber;
        const targetPosition = (this.displayData || [])[index];
        const currentPosition = leadingItem[0];

        return targetPosition && currentPosition && targetPosition === currentPosition ? (
            <StreamsDiscoveryAdsBanner />
        ) : null;
    };

    renderEmptyFooter = () => {
        if (!this.props.showNewsletterButton) {
            return null;
        }
        return <View style={styles.emptyFooter} />;
    };

    render() {
        let streamsToShow = [];
        switch (this.state.selectedIndex) {
            case STREAMS_CATEGORIES.POPULAR:
                streamsToShow = this.props.popular.slice();
                break;
            case STREAMS_CATEGORIES.ALL_STREAMS:
                streamsToShow = this.props.allStreams.slice();
                break;
            case STREAMS_CATEGORIES.FOLLOWING:
                streamsToShow = this.props.following.slice();
                break;
            case STREAMS_CATEGORIES.MULTI_GUEST:
                streamsToShow = this.props.cooperative.slice();
                break;
            default:
                const topic = getTopicFromIndex(this.state.selectedIndex);
                if (topic) {
                    streamsToShow = this.props.topicCategories[topic]?.slice() || [];
                }
                break;
        }

        this.displayData = this.state.itemStyle ? streamsToShow : [];
        const shouldShowPlaceholder = streamsToShow.length < 1;

        return (
            <SafeAreaView
                forceInset={this.props.safeAreaInsets || { top: 'always', bottom: 'never' }}
                style={styles.container}
            >
                <FlatList
                    ref={ref => (this.flatList = ref)}
                    style={styles.list}
                    data={this.displayData}
                    onScrollBeginDrag={this.onScrollBeginDrag}
                    onScroll={this.onScroll}
                    renderItem={this.renderItem}
                    scrollEnabled={this.state.scrollEnabled && !shouldShowPlaceholder}
                    numColumns={numColumns}
                    keyExtractor={item => item}
                    ListHeaderComponent={this.renderHeader}
                    onLayout={this.onListLaidOut}
                    getItemLayout={this.getItemLayout}
                    ListEmptyComponent={this.renderPlaceholder}
                    windowSize={3}
                    initialNumToRender={0}
                    ItemSeparatorComponent={this.getSeparator}
                    ListFooterComponent={this.renderEmptyFooter}
                    {...testId('Stream discovery list')}
                />
                {this.props.showNewsletterButton && (
                    <View style={styles.newsletterButton}>
                        <CreateNewsletterButton />
                    </View>
                )}
                {this.showRichCategories() && this.state.topView === TOP_VIEW.CATEGORY_PICKER && (
                    <SafeAreaView style={styles.titleContainer}>{this.renderHeader()}</SafeAreaView>
                )}
                <IfConfigValue
                    path={'markup.stream-list-screen.start-button-position'}
                    equalsTo={'bottom-right'}
                >
                    <RoundButton
                        style={styles.startStreamButton}
                        onPress={this.props.startStream}
                        icon={Resources.images.startStreamIcon()}
                        {...testId('Start stream button')}
                    />
                </IfConfigValue>
            </SafeAreaView>
        );
    }
}
