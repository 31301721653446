import { Dimensions, Platform } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

const deviceWidth = Dimensions.get('window').width;
const isSmallDevice = deviceWidth <= 700;

const styles = EStyleSheet.create({
  modal: Platform.select({
    web: {
      maxWidth: 590,
      width: '100%',
      alignSelf: 'center',
    },
    default: {},
  }),
  container: {
    backgroundColor: '$primaryBackgroundColor',
    borderRadius: 15,
    flexDirection: 'column',
    alignItems: 'center',
    paddingHorizontal: 32,
    paddingVertical: 70,

    ...Platform.select({
      web: {
        alignSelf: 'center',
        maxWidth: isSmallDevice ? deviceWidth - 50 : 590,
        width: '100%',
      },
      default: {},
    }),
  },

  diamondsIcon: {
    marginBottom: 16,
  },

  title: {
    fontFamily: '$brandFontFamily',
    color: '$primaryTextColor',
    fontSize: 24,
    fontWeight: '500',
    marginBottom: 35,
    textAlign: 'center',
  },

  subtitle: {
    fontFamily: '$brandFontFamily',
    color: '$primaryTextColor',
    fontSize: 16,
    marginBottom: 35,
    textAlign: 'center',
  },

  bonusText: {
    fontFamily: '$brandFontFamily',
    color: '$primaryTextColor',
    fontSize: 22,
    fontWeight: '500',
    marginBottom: 35,
    textAlign: 'center',
  },

  button: {
    gradientColor: ['#00c8b6', '#34c342'],
  },

  buttonText: {
    padding: 4,
    fontFamily: '$brandFontFamily',
    color: '$brandTextColor',
    fontSize: 18,
    fontWeight: '500',
  },
});

export default styles;
