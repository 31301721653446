import React from 'react';
import PropTypes from 'prop-types';
import connect from '@sdv/connect';
import MessagesModel, { getId } from '@sdv/domain/dialogs.messages';
import { Config } from '@sdv/domain/app/config';
import TrialChatLimitation from '@sdv/domain/dialogs.messages/trial-chat-limitation';
import { Subject, combineLatest } from 'rxjs';

const MESSAGES_PROPERTY = 'generated.dialogs-messages-reader.messages';
const READ_PROPERTY = 'generated.dialogs-messages-reader.read';

export default function createHoc(Component, options = {}) {

    const userPropName = options.userPropName || 'user';
    const attendeePropName = options.attendeePropName || 'attendee';

    class Reader extends React.Component {
        static displayName = 'dialogs.messages.reader';
        static contextTypes = {
            flux: PropTypes.object
        };
        static propTypes = {
            user: PropTypes.string,
            attendee: PropTypes.string,
            onMessageShow: PropTypes.func
        };

        unprocessedMessages = [];
        shownAndNotReadMessages = [];
        updateReadingStatusAccordingPayment = new Subject();

        componentDidMount() {
            this.subscribe();
        }

        componentWillUnmount() {
            this.unsubscribe();
        }

        subscribe() {
            const trialChatLimitation = TrialChatLimitation.shared(this.props[userPropName], this.props[attendeePropName]);
            this.subscription = combineLatest(trialChatLimitation.unpaidMessages, this.updateReadingStatusAccordingPayment)
                .subscribe(unpaidMessages => {
                    const updatedShownAndNotReadMessages = this.shownAndNotReadMessages;
                    this.shownAndNotReadMessages.forEach(message => {
                        const index = (unpaidMessages || []).indexOf(message.tag);
                        if (index === -1) {
                            this.props[READ_PROPERTY] && this.props[READ_PROPERTY](message.id);
                            const index = updatedShownAndNotReadMessages.indexOf(message);
                            updatedShownAndNotReadMessages.splice(index, 1);
                        }
                    });
                    this.shownAndNotReadMessages = updatedShownAndNotReadMessages;
                });
        }

        unsubscribe() {
            this.subscription && this.subscription.unsubscribe();
        }

        componentDidUpdate(prevProps) {
            if (shouldReconnect(prevProps, this.props)) {
                this.unprocessedMessages = [];
                this.shownAndNotReadMessages = [];
                this.unsubscribe();
                this.subscribe();
            }

            this.unprocessedMessages.forEach(tag => {
                this.onMessageShown(tag);
            });
        }

        onMessageShown = (tag) => {
            const messages = this.props[MESSAGES_PROPERTY] || [];

            const message = messages.find(message => message.tag === tag);

            if (!message) {
                this.unprocessedMessages.push(tag);
                return;
            }
            const index = this.unprocessedMessages.indexOf(tag);
            if (index >= 0) {
                this.unprocessedMessages.splice(index, 1);
            }

            if (message.id && !message.read && message.sender !== this.props[userPropName]) {
                this.shownAndNotReadMessages.push(message);
                this.updateReadingStatusAccordingPayment.next('');
            }

            this.props.onMessageShow && this.props.onMessageShow(tag);
        };

        render() {
            const {
                [MESSAGES_PROPERTY]: messages,
                [READ_PROPERTY]: read,
                forwardedRef,
                ...props
            } = this.props;

            return (
                <Component
                    {...props}
                    ref={forwardedRef}
                    onMessageShow={this.onMessageShown}
                />
            );
        }
    }

    function getModels(flux, props) {

        if (props[userPropName] && props[attendeePropName]) {
            return {
                messagesModel: flux.get(MessagesModel, getId(props[userPropName], props[attendeePropName]))
            };
        }
        return {};

    }

    function mapStoresToProps(models) {
        const props = {};

        if (models.messagesModel) {
            props[MESSAGES_PROPERTY] = models.messagesModel.store.getState().messages;
        }

        return props;
    }

    function mapStoresToActions(models) {
        const props = {};

        if (models.messagesModel) {
            props[READ_PROPERTY] = models.messagesModel.actions.read;
        }

        return props;
    }

    function shouldReconnect(props, newProps) {
        return props[userPropName] !== newProps[userPropName] || props[attendeePropName] !== newProps[attendeePropName];
    }

    const ConnectedComponent = connect(
        getModels,
        mapStoresToProps,
        mapStoresToActions,
        shouldReconnect
    )(Reader);

    return React.forwardRef((props, ref) => {
        return (<ConnectedComponent {...props} forwardedRef={ref} />);
    });

}
