import CredentialsUpdater from 'dating-mobile/src/authentication/utils/credentials-updater';
import Session from '@sdv/domain/authorization/session';
import Config from 'dating-mobile/src/app/config';
import { take, map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

class CredentialsUpdatingBalanceRefiller {
  constructor(balanceRefiller) {
    this.balanceRefiller = balanceRefiller;
  }

  needsUpdateCredentials = () => {
    const demoUserAbilities = Config.shared().paramWithPath(
      'features.demo-user-abilities',
    );

    const { userIsUsingDemoMode } = Session.shared();

    return combineLatest(userIsUsingDemoMode, demoUserAbilities)
      .pipe(
        map(
          ([userIsUsingDemoMode, demoUserAbilities]) =>
            userIsUsingDemoMode && demoUserAbilities.indexOf('purchase') < 0,
        ),
        take(1),
      )
      .toPromise();
  };

  purchaseCallsPack = async (...args) => {
    const needsUpdateCredentials = await this.needsUpdateCredentials();

    needsUpdateCredentials
      ? CredentialsUpdater.updateCredentials(() =>
          this.balanceRefiller.purchaseCallsPack(...args),
        )
      : this.balanceRefiller.purchaseCallsPack(...args);
  };

  refillBalance = async (...args) => {
    const needsUpdateCredentials = await this.needsUpdateCredentials();

    needsUpdateCredentials
      ? CredentialsUpdater.updateCredentials(() =>
          this.balanceRefiller.refillBalance(...args),
        )
      : this.balanceRefiller.refillBalance(...args);
  };

  subscribe = async (...args) => {
    const needsUpdateCredentials = await this.needsUpdateCredentials();

    needsUpdateCredentials
      ? CredentialsUpdater.updateCredentials(() =>
          this.balanceRefiller.subscribe(...args),
        )
      : this.balanceRefiller.subscribe(...args);
  };

  purchaseCredits = async (...args) => {
    const needsUpdateCredentials = await this.needsUpdateCredentials();

    needsUpdateCredentials
      ? CredentialsUpdater.updateCredentials(() =>
          this.balanceRefiller.purchaseCredits(...args),
        )
      : this.balanceRefiller.purchaseCredits(...args);
  };
}

export default CredentialsUpdatingBalanceRefiller;
