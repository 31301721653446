import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $statusBarStyle: '$brandStatusBarStyle',
    $statusBarBackgroundColor: '$brandColor',
    header: {
        backgroundColor: '$brandColor',
        borderBottomColor: '$brandColor',
        shadowColor: '$brandColor',
        shadowRadius: 0,
        shadowOpacity: 0,
        elevation: 0,
        shadowOffset: {
            height: 0,
        }
    },
    container: {
        width: '100%',
        height: '100%',
        backgroundColor: '$brandColor'
    },
    top: {
        alignItems: 'center',
        justifyContent: 'center'

    },
    content: {
        flex: 1,
        paddingHorizontal: 72,
        alignItems: 'center',
        justifyContent: 'center'
    },
    bottom: {
        marginBottom: 64,
        paddingHorizontal: 40,
    },
    title: {
        marginTop: 40,
        fontFamily: '$brandFontFamily',
        color: '$brandTextColor',
        fontSize: 24,
        fontWeight: 'bold'
    },
    button: {
        backgroundColor: '$unselectedColor',
        height: 44,
        borderRadius: 22,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 20,
    },
    buttonTitle: {
        fontSize: 17,
        fontWeight: '600',
        color: '$brandTextColor',
        fontFamily: '$brandFontFamily',
    },

    selectedButtonModifier: {
        backgroundColor: '$minorAccentColor'
    },
    selectedTitleModifier: {
        color: '$accentTextColor'
    }

});

export default styles;
