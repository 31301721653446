import React from 'react';
import PropTypes from 'prop-types';

import createMediaControlledComponent from '.';
import userController from '../../user/controllers';

export const GALLERY_ITEMS = Object.freeze({
    PHOTOS: 0,
    PUBLIC_PHOTOS: 1,
    PRIVATE_PHOTOS: 2,
    VIDEOS: 3,
    PHOTO_ALBUM: 4,
    PRIVATE_PHOTO_ALBUM: 5,
    VIDEO_ALBUM: 6,
    THUMBNAIL: 7,
});

function createControlledComponent(Component) {
    class ControlledComponent extends React.Component {
        static displayName = 'gallery.controller';

        static propTypes = {
            id: PropTypes.string.isRequired,
            basename: PropTypes.string,
            itemsOrder: PropTypes.array,
            media: PropTypes.arrayOf(
                PropTypes.shape({
                    basename: PropTypes.string.isRequired,
                    tags: PropTypes.tags,
                    mediatype: PropTypes.string.isRequired,
                }),
            ),
        };

        mediaState = {};

        componentDidMount() {
            this.updateComponent(this.props);
        }

        componentWillReceiveProps(props) {
            if (this.props.media !== props.media) {
                this.updateComponent(props);
            }
        }

        updateComponent = props => {
            const { id, basename, media } = props;

            let orderedItems = media;
            let initialIndex;
            let thumbnail;

            const userThumbnail = props.user['thumbnail-pending'] || props.user.thumbnail;

            if (media && media.length) {
                orderedItems = [];
                if (this.props.itemsOrder) {
                    if (
                        this.props.itemsOrder.indexOf(GALLERY_ITEMS.THUMBNAIL) > -1 &&
                        userThumbnail
                    ) {
                        thumbnail = media.find(file => file.basename === userThumbnail);
                    }

                    for (let i = 0; i <= this.props.itemsOrder.length; i++) {
                        const type = this.props.itemsOrder[i];
                        let item;

                        switch (type) {
                            case GALLERY_ITEMS.PHOTOS:
                                item = media.filter(file => file.mediatype?.startsWith('image'));
                                if (thumbnail) {
                                    let index = item.indexOf(thumbnail);

                                    if (index > -1) {
                                        item.splice(index, 1);
                                    }
                                }
                                break;
                            case GALLERY_ITEMS.PUBLIC_PHOTOS:
                                item = media.filter(
                                    file =>
                                        file.mediatype?.startsWith('image') &&
                                        (!file.tags || file.tags.indexOf('hidden') < 0),
                                );
                                if (thumbnail) {
                                    let index = item.indexOf(thumbnail);

                                    if (index > -1) {
                                        item.splice(index, 1);
                                    }
                                }
                                break;
                            case GALLERY_ITEMS.PRIVATE_PHOTOS:
                                item = media.filter(
                                    file =>
                                        file.mediatype?.startsWith('image') &&
                                        file.tags &&
                                        file.tags.indexOf('hidden') >= 0,
                                );
                                if (thumbnail) {
                                    let index = item.indexOf(thumbnail);

                                    if (index > -1) {
                                        item.splice(index, 1);
                                    }
                                }
                                break;
                            case GALLERY_ITEMS.VIDEOS:
                                item = media.filter(file => file.mediatype?.startsWith('video'));
                                break;
                            case GALLERY_ITEMS.VIDEO_ALBUM:
                                const videos = media.filter(file =>
                                    file.mediatype?.startsWith('video'),
                                );

                                if (videos.length) {
                                    item = {
                                        album: GALLERY_ITEMS.VIDEO_ALBUM,
                                        blurred: false,
                                        counter: videos.length,
                                        thumbnail: videos[0],
                                    };
                                }
                                break;
                            case GALLERY_ITEMS.PHOTO_ALBUM:
                                const photos = media.filter(
                                    file =>
                                        file.mediatype?.startsWith('image') &&
                                        (!file.tags || file.tags.indexOf('hidden') < 0),
                                );

                                if (photos.length) {
                                    item = {
                                        album: GALLERY_ITEMS.PHOTO_ALBUM,
                                        blurred: false,
                                        counter: photos.length,
                                        thumbnail: photos[0],
                                    };
                                }
                                break;
                            case GALLERY_ITEMS.PRIVATE_PHOTO_ALBUM:
                                const privatePhotos = media.filter(
                                    file =>
                                        file.mediatype?.startsWith('image') &&
                                        file.tags &&
                                        file.tags.indexOf('hidden') >= 0,
                                );

                                if (privatePhotos.length) {
                                    item = {
                                        album: GALLERY_ITEMS.PRIVATE_PHOTO_ALBUM,
                                        blurred: true,
                                        counter: privatePhotos.length,
                                        thumbnail: privatePhotos[0],
                                    };
                                }
                                break;
                            case GALLERY_ITEMS.THUMBNAIL:
                                item = thumbnail;
                                break;
                        }
                        if (Array.isArray(item)) {
                            orderedItems.push(...item);
                        } else if (item) {
                            orderedItems.push(item);
                        }
                    }
                } else {
                    orderedItems.push(...media);
                }
            }

            const hasThumbnailOrigin =
                orderedItems &&
                orderedItems.find(
                    item =>
                        item.tags &&
                        item.tags.includes('thumbnail-origin') &&
                        !item.tags.includes('thumbnail'),
                );

            const resultMedia = hasThumbnailOrigin
                ? orderedItems.filter(item => !item.tags || !item.tags.includes('thumbnail'))
                : orderedItems;

            if (resultMedia && resultMedia.length && basename) {
                initialIndex = resultMedia.findIndex(file => file.basename === basename);

                if (initialIndex < 0) {
                    initialIndex = 0;
                }
            }

            this.mediaState = {
                id,
                initialIndex,
                media: resultMedia,
            };
        };

        render() {
            const { media, ...props } = this.props;

            return <Component {...this.mediaState} {...props} />;
        }
    }

    return ControlledComponent;
}

function createResultComponent(Component) {
    return userController(createMediaControlledComponent(createControlledComponent(Component)));
}

export default createResultComponent;
