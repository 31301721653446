import React from 'react';
import PropTypes from 'prop-types';

function withNavigation(Component) {
  class Navigation extends React.Component {
    static displayName = 'call.screens.incoming-call.navigation';

    static propTypes = {
      navigation: PropTypes.object.isRequired,
    };

    render() {
      const { navigation } = this.props;

      return (
        <Component
          {...this.props}
          attendeeId={navigation.getParam('attendeeId')}
          showVideo={navigation.getParam('showVideo')}
        />
      );
    }
  }

  Navigation.navigationOptions = () => ({
    headerShown: false,
  });

  return Navigation;
}

export default withNavigation;
