import { BaseStore } from '@sdv/model';
import { LETTER_DELIVERY_STATUS } from '@sdv/domain/dialogs.mailbox/outbox/letter-delivery-status';

export default function create(Actions) {

    function createStore(id) {
        class Store extends BaseStore {
            constructor(flux) {
                super();
                const actions = flux.getActions(Actions, id);

                this.state = {
                    letters: []
                };

                this.eventInbox = flux.letterEventInbox;
                this.eventInbox.addOnLetterRejectedListener(this.letterUnpaid);
                this.eventInbox.addOnLetterAddedListener(this.letterAdded);
                this.bindAction(actions.sendLetter, this.letterSent);
            };

            letterUnpaid = ({letterTag}) => {
                let letters = this.state.letters.slice();
                let letterIndex = letters.findIndex(letter => letter.tag === letterTag);

                if (letterIndex >= 0) {
                    const letter = letters[letterIndex];
                    letters[index] = {
                        ...letter,
                        status: LETTER_DELIVERY_STATUS.UNPAID
                    };
                }
                this.setState({letters: letters});
            };

            letterSent = (sentLetter) => {
                if (sentLetter.status !== LETTER_DELIVERY_STATUS.FAILED) {
                    let letters = this.state.letters.slice();

                    let letterIndex = letters.findIndex(letter => letter.tag === sentLetter.tag);

                    if (letterIndex >= 0) {
                        const letter = letters[letterIndex];
                        letters[letterIndex] = {
                            ...letter,
                            status: LETTER_DELIVERY_STATUS.SENDING
                        };
                    } else {
                        const letterWithSendingStatus = {
                            ...sentLetter,
                            status: LETTER_DELIVERY_STATUS.SENDING
                        };
                        letters.push(letterWithSendingStatus);
                    }
                    this.setState({letters: letters});
                } else {
                    const lettersWithoutFailedLetter = this.state.letters.filter(letter => letter.tag !== letter.tag);
                    this.setState({letters: lettersWithoutFailedLetter});
                }
            };

            letterAdded = (addedLetter) => {
                const lettersWithoutAddedLetter = this.state.letters.filter(letter => letter.tag !== addedLetter.tag);
                this.setState({letters: lettersWithoutAddedLetter});
            };
        }
        Store.displayName = createStore.getDisplayName(id);
        return Store;
    }

    createStore.getDisplayName = Actions.getDisplayName;

    return createStore;
}
