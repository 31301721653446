import { createOptimisticActions } from '@sdv/model';

function createTagActions(id) {

    const { userId, tag } = parseId(id);

    class Actions extends createOptimisticActions(['put', 'delete']) {

        get = () => async (dispatch, flux) => {
            flux.api.users.tags[tag].get(userId, (error, response) => {

                if (error) {
                    return dispatch(null, error);
                }

                return dispatch(response);
            });
        };

        put() {

            return super.put(tag, function() {
                this.dispatch(tag);

                this.flux.api.users.tags[tag].put(userId, {}, (error) => {

                    if (error) {
                        return this.revert(error);
                    }

                    return this.commit();
                });

            });
        };

        delete() {
            return super.delete(tag, function() {
                this.dispatch(tag);

                this.flux.api.users.tags[tag].delete(userId, (error) => {

                    if (error) {
                        return this.revert(error);
                    }

                    return this.commit();
                });

            });
        };

    }

    Actions.displayName = createTagActions.getDisplayName(id);
    return Actions;
}

createTagActions.getDisplayName = function(id) {
    return `user-tag.${id}`;
};

export default createTagActions;

export function parseId(id) {
    let keys;

    if (typeof id !== 'string' || (keys = id.split(':')).length < 2 || !keys[0] || !keys[1]) {
        throw new Error('UserTagModel should have id in format "{userId}:{tag}"');
    }

    return {
        userId: keys[0],
        tag: keys[1]
    };
}

export function getId(userId, tag) {
    return `${userId}:${tag}`;
}
