import React from 'react';
import PropTypes from 'prop-types';

function controller(Component) {

    class Controller extends React.Component {
        static displayName = 'dialogs.mingle.end.screen.controller';
        static propTypes = {
            showContacts: PropTypes.func,
            startMingle: PropTypes.func,
            close: PropTypes.func
        };

        onChatsLinkPressed = () => {
            this.props.close && this.props.close();
            this.props.showContacts && this.props.showContacts();
        };

        onTryAgainButtonPressed = () => {
            this.props.close && this.props.close();
            this.props.startMingle && this.props.startMingle();
        };

        render() {
            return (<Component
                {...this.props}
                onChatsLinkPress={this.onChatsLinkPressed}
                onTryAgainButtonPress={this.onTryAgainButtonPressed}
            />);
        }

    }

    return Controller;

}

export default controller;
