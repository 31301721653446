import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        alignSelf: 'flex-end',
        borderRadius: 20,
        paddingHorizontal: 10,
        paddingVertical: 6,
        backgroundColor: '$shadowColor'
    },
    text: {
        fontFamily: '$brandFontFamily',
        fontSize: 12,
    }
});

export default styles;
