import { stringify, parse } from 'qs';
import singleExecute from '../../../utils/single-execute';

const FETCH_COUNT = 50;

function createEventsActions(idSrt) {
  const { userId, type, params = {} } = parseId(idSrt);

  class Actions {
    get = () => (dispatch, flux) => {
      singleExecute(
        `${Actions.displayName}.get`,
        resolve => {
          flux.api.users.events.get(
            { types: `+${type}` },
            (error, result) => {
              if (!error) {
                if (result && result.value) {
                  const numberOfPages =
                    Math.floor(result.value / FETCH_COUNT) + 1;

                  fetchEvents(
                    flux,
                    numberOfPages,
                    0,
                    [],
                    resolve,
                    result.value,
                    params,
                  );
                } else {
                  resolve({ events: [], count: 0 });
                }

                return;
              }
              resolve(null, error);
            },
            { Accept: 'application/json+vnd.sdv.numeric' },
          );
        },
        dispatch,
      );
    };

    patch = payload => dispatch => {
      dispatch(payload);
    };

    stale = id => dispatch => {
      dispatch(id);
    };

    delete = (eventId, onlyActualize) => (dispatch, flux) => {
      if (onlyActualize) {
        return dispatch(eventId);
      }

      flux.api.users.events.delete(userId, eventId, error => {
        if (error) {
          return dispatch(null, error);
        }

        return dispatch(eventId);
      });

      return undefined;
    };
  }

  function fetchEvents(
    flux,
    numberOfPages,
    currentPage,
    events,
    resolve,
    count = 0,
  ) {
    if (currentPage >= numberOfPages) {
      resolve({ events, count });
    } else {
      const queryParams = {
        types: `+${type}`,
        omit: currentPage * FETCH_COUNT,
        select: FETCH_COUNT,
        ...params,
      };

      flux.api.users.events.get(userId, queryParams, (error, result) => {
        if (error) {
          resolve({ events, count }, error);

          return;
        }

        const updatedEvents = events.concat(result || []);

        fetchEvents(
          flux,
          numberOfPages,
          currentPage + 1,
          updatedEvents,
          resolve,
          count,
        );
      });
    }
  }

  Actions.displayName = createEventsActions.getDisplayName(idSrt);

  return Actions;
}

createEventsActions.getDisplayName = function(id) {
  return `user-events.${id}`;
};

export default createEventsActions;

export const EVENT_TYPE = Object.freeze({
  CONTACT: 'message',
  INVITATION: 'invitation',
  USER_LIKES: 'like.user',
  BOOST_DISCOUNT: 'boost.discount',
  BOOST: 'boost',
});

export function parseId(id) {
  if (typeof id !== 'string') {
    throw new Error('Id should be a string');
  }

  const [userId, type, paramsString = '', ...rest] = id.split(':');

  if (!userId || !type || rest.length) {
    throw new Error('EventsModel should have id in format "{userId}:{type}"');
  }

  let params;

  try {
    params = parse(paramsString);
  } catch (e) {
    params = {};
  }

  return {
    userId,
    type,
    params,
  };
}

export function getId(userId, type, params = {}) {
  return `${userId}:${type}:${stringify(params)}`;
}
