import { View } from 'react-native';
import Photo from 'dating-mobile/src/components/photo';
import testId from 'dating-mobile/src/utils/test-id';
import React from 'react';
import UserThumbnail from 'dating-mobile/src/user/common/views/thumbnail';
import OnlineIndicator from 'dating-mobile/src/user/common/views/online-indicator';
import UserName from 'dating-mobile/src/user/common/views/user-name';
import PropTypes from 'prop-types';
import Resources from 'dating-mobile/src/resources';
import { SafeAreaView } from 'react-navigation';
import styles from './styles';

const DEFAULT_SAFE_AREA_INSETS = { horizontal: 'never', vertical: 'never' };

export default class LetterHeaderView extends React.PureComponent {
  static displayName = 'inbox.views.letter-header.view';

  static propTypes = {
    attendeeId: PropTypes.string,
    cover: PropTypes.string,
    showOnlyCover: PropTypes.bool,
    ownLetter: PropTypes.bool,
    safeAreaInsets: PropTypes.shape,
  };

  render() {
    const format = this.props.ownLetter
      ? Resources.strings['inbox-owner-letter-header-title']
      : Resources.strings['inbox-sender-letter-header-title'];

    return (
      <SafeAreaView
        forceInset={this.props.safeAreaInsets || DEFAULT_SAFE_AREA_INSETS}
        style={styles.container}
      >
        <Photo
          style={styles.cover}
          path={
            this.props.cover && `/dialogs/letters/covers/${this.props.cover}`
          }
          resizeMode="cover"
        />
        <View style={styles.coverOverlay} />
        <View style={styles.content}>
          {!this.props.showOnlyCover && (
            <UserThumbnail
              style={styles.avatar}
              userId={this.props.attendeeId}
              round
              {...testId('Letter sender thumbnail')}
            />
          )}
          {!this.props.showOnlyCover && (
            <View style={styles.onlineContainer}>
              <OnlineIndicator userId={this.props.attendeeId} />
            </View>
          )}
          {!this.props.showOnlyCover && (
            <UserName
              userId={this.props.attendeeId}
              style={styles.name}
              format={format}
            />
          )}
        </View>
      </SafeAreaView>
    );
  }
}
