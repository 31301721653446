import React from 'react';
import PropTypes from 'prop-types';
import {Image, Text, View} from "react-native";

import styles from './styles';

export default class IconWithText extends React.PureComponent {
    static displayName = 'components.icon-with-text';
    static propTypes = {
        text: PropTypes.string,
        icon: PropTypes.string,
        containerStyle: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.object,
            PropTypes.array
        ]),
        textStyle: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.object,
            PropTypes.array
        ]),
        iconStyle: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.object,
            PropTypes.array
        ]),
    };

    render() {
        const {icon, text, textStyle, iconStyle, containerStyle} = this.props;
        return (
            <View style={[styles.container, containerStyle]}>
                <Image style={[styles.icon, iconStyle]} source={icon}/>

                <Text style={[styles.text, textStyle]}>
                    {text}
                </Text>
            </View>
        );
    }
}
