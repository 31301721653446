import Color from 'color';
import EStylesSheet from 'react-native-extended-stylesheet';
import { I18nManager, Platform } from 'react-native';
import shouldUseWebLayout from 'dating-mobile/src/utils/web-layout';

const webMaxWidth = shouldUseWebLayout() ? 1000 : 640;

const styles = EStylesSheet.create({
  container: {
    backgroundColor: '$brandColor',
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 20,
    padding: 20,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  imageContainer: {
    width: '100%',
    maxWidth: Platform.OS === 'web' ? webMaxWidth : 'none',
    aspectRatio: Platform.OS === 'web' ? 0.43 : 2.33,
    justifyContent: 'center',
  },
  image: {
    resizeMode: 'contain',
    transform: [
      {
        scaleX: I18nManager.isRTL ? -1 : 1,
      },
    ],
  },
  textContainer: {
    flexShrink: 1,
  },
  textContainerWithBackgroundImage: {
    paddingHorizontal: 20,
  },
  title: {
    color: '$accentTextColor',
    fontFamily: '$brandFontFamily',
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  description: {
    color: '$accentTextColor',
    fontFamily: '$brandFontFamily',
    fontSize: 18,
  },
  overlay: {
    height: '100%',
    width: '100%',
    backgroundColor: () =>
      Color(EStylesSheet.value('$primaryBackgroundColor'))
        .alpha(0.9)
        .rgb()
        .string(),
    position: 'absolute',
    alignSelf: 'center',
    justifyContent: 'center',
  },
});

export default styles;
