import React, { PureComponent } from 'react';
import {
  Image,
  Text,
  TouchableNativeFeedback,
  TouchableOpacity,
  View,
  Platform,
} from 'react-native';
import PropTypes from 'prop-types';
import Resources from 'dating-mobile/src/resources';
import CurrencyBasedText from 'dating-mobile/src/components/currency/currency-based-text';
import { formatPrice } from '@sdv/commons/price-with-code-localization';
import { SupportedMethods } from '@sdv/domain/app/payment/shop-info';

import wreathImg from './assets/wreath.png';

import styles from './styles';

export default class PackageListItem extends PureComponent {
  static displayName = 'payment.views.package-list.item';

  static propTypes = {
    contentStyle: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    containerStyle: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    product: PropTypes.shape({
      creditsAmount: PropTypes.number.isRequired,
      price: PropTypes.shape({
        includingTax: PropTypes.number,
        currency: PropTypes.string,
        localizedIncludingTax: PropTypes.string,
      }),
      benefitPercentage: PropTypes.number,
    }),
    invoices: PropTypes.array,
    amountInMinutes: PropTypes.number,
    onPress: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
  };

  handlePress = () => {
    const { onPress } = this.props;

    if (onPress) {
      onPress();
    }
  };

  render() {
    const {
      product,
      invoices,
      amountInMinutes,
      isDisabled,
      contentStyle,
      containerStyle,
    } = this.props;
    let textAmount = null;

    if (amountInMinutes) {
      textAmount = Resources.strings.formatString(
        Resources.strings['credits-screen-boosting-product-period-format'],
        amountInMinutes,
      );
    } else if (styles.$useTextForCurrency) {
      const creditsFormattedText = Resources.strings.formatString(
        Resources.strings[
          'credits-screen-prices-information-credits-price-format'
        ],
        product.creditsAmount,
      );
      const coinsFormattedText = Resources.strings.formatString(
        Resources.strings[
          'credits-screen-prices-information-coins-price-format'
        ],
        product.creditsAmount,
      );

      textAmount = (
        <CurrencyBasedText
          strings={{
            coins: coinsFormattedText,
            credits: creditsFormattedText,
          }}
        />
      );
    }
    // TODO: Refactor after payment with credit card implementation
    let invoice = product;

    if (invoices && invoices.length > 1 && Platform.OS === 'android') {
      invoice = invoices.find(inv => inv.method === 'google');
    }

    const localizedPrice = formatPrice(
      invoice.price || invoice,
      styles.$forceLocalizePrice,
    );

    const Container = styles.$hasButtonFeedback
      ? TouchableOpacity
      : TouchableNativeFeedback;
    const discount = invoices && invoices[0]?.discount;
    const priceWithoutDiscount = formatPrice(
      invoices && invoices[0]?.priceWithoutDiscount,
      styles.$forceLocalizePrice,
    );

    const showBenefit =
      styles.$showBenefit &&
      !!product.benefitPercentage &&
      !priceWithoutDiscount;

    return (
      <Container onPress={this.handlePress} disabled={!!isDisabled}>
        <View style={[styles.container, containerStyle]}>
          <View style={[styles.content, contentStyle]}>
            <View style={styles.amountContainer}>
              {!textAmount && (
                <Image
                  style={styles.coin}
                  source={Resources.images.coinIcon()}
                />
              )}
              {!textAmount && (
                <Text style={styles.creditsAmount}>
                  {' '}
                  {invoice.creditsAmount}
                </Text>
              )}
              {!!textAmount && (
                <Text
                  style={[
                    styles.amount,
                    invoice?.isTopBoost && styles.amountSmall,
                  ]}
                >
                  {textAmount}
                </Text>
              )}
              {invoice?.isTopBoost && (
                <Image
                  style={styles.wreathImg}
                  source={wreathImg}
                  resizeMode="contain"
                />
              )}
            </View>
            <View style={styles.bonusContainer}>
              {showBenefit && !discount && (
                <View style={styles.benefitContainer}>
                  <Text style={styles.benefit}>
                    {Resources.strings.formatString(
                      Resources.strings[
                        'credits-screen-buy-product-benefit-title'
                      ],
                      product.benefitPercentage,
                    )}
                  </Text>
                </View>
              )}
              {discount && (
                <View style={styles.discountContainer}>
                  <Text style={styles.discount}>
                    {Resources.strings.formatString(
                      Resources.strings['credits-screen-buy-product-discount'],
                      discount,
                    )}
                  </Text>
                </View>
              )}
            </View>
            <View style={styles.priceContainer}>
              <View>
                <View style={styles.priceWrapper}>
                  <Text
                    style={textAmount ? styles.price : styles.priceForCredit}
                    numberOfLines={2}
                  >
                    {localizedPrice}
                  </Text>
                  {invoice?.price?.currency === SupportedMethods.credits && (
                    <Image
                      style={styles.creditImg}
                      source={Resources.images.coinIcon()}
                      resizeMode="contain"
                    />
                  )}
                </View>
                {!!priceWithoutDiscount && (
                  <View style={styles.oldPriceWrapper}>
                    <Text style={styles.oldPrice} numberOfLines={2}>
                      {priceWithoutDiscount}
                    </Text>
                    {invoices[0]?.priceWithoutDiscount?.currency ===
                      SupportedMethods.credits && (
                      <Image
                        style={[styles.creditImg, styles.creditImgDisabled]}
                        source={Resources.images.coinIcon()}
                        resizeMode="contain"
                      />
                    )}
                  </View>
                )}
              </View>
            </View>
          </View>
        </View>
      </Container>
    );
  }
}
