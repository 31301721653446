import React from 'react';
import { Text } from 'react-native';
import Dialog from 'react-native-dialog';

import styles from './styles';

const defaultAlertState = {
  visible: false,
  header: '',
  body: '',
  buttons: [],
  options: {},
};

export default class AlertDialog extends React.Component {
  state = { data: defaultAlertState };

  show = props => {
    this.setState(oldState => ({
      data: { ...oldState.data, ...props, visible: true },
    }));
  };

  close = () => {
    this.setState(() => ({ data: defaultAlertState }));
  };

  render() {
    const { data } = this.state;
    const { visible, options, header, body, buttons } = data;

    return (
      <Dialog.Container
        visible={visible}
        onBackdropPress={() => {
          if (options.cancelable !== false) {
            this.close();
          }
        }}
        animationIn="zoomIn"
        animationOut="zoomOut"
      >
        <Text style={styles.header}>{header}</Text>
        <Text>{body}</Text>
        {buttons.map(({ text, onPress }, i) => (
          <Dialog.Button
            label={text}
            onPress={() => {
              if (onPress) {
                onPress();
              }
              this.close();
            }}
            key={i}
          />
        ))}
      </Dialog.Container>
    );
  }
}
