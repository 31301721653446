import React from 'react';
import { PaymentMethodItemView } from 'dating-mobile/src/payment/views/payment-method-item';
import Resources from 'dating-mobile/src/resources';
import PropTypes from "prop-types";

export class GooglePlayPaymentMethodItem extends React.Component {

    static displayName = 'payment.views.google-button';

    static propTypes = {
        invoice: PropTypes.shape({
            method: PropTypes.string.isRequired,
            price: PropTypes.object
        })
    };

    render() {
        return (<PaymentMethodItemView
            {...this.props}
            icon={Resources.images.googlePlayIcon()}
            title={Resources.strings['google-play-payment-title']}
            price={this.props.invoice.price}
        />);
    }
}
