import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $statusBarStyle: '$primaryStatusBarStyle',
    $statusBarBackgroundColor: '$primaryBackgroundColor',

    header: {
        backgroundColor: '$primaryBackgroundColor',
        borderBottomColor: '$primaryBackgroundColor',
        shadowColor: '$primaryBackgroundColor'
    },

    headerTitle: {
        color: '$primaryTextColor'
    },

    container: {
        backgroundColor: '$primaryBackgroundColor',
        flex: 1
    },

    listContainer: {
        flex: 1,
        alignItems: 'stretch'
    }
});

export default styles;
