import Actions from './actions';

function createPresenceStore(userId) {

    class Store {

        /**
         *
         * @type {{
         *     presence: {
         *         online: bool,
         *         devices: array<object>
         *     }
         * }}
         */
        state = {
            presence: {}
        };

        constructor(flux) {
            const actions = flux.getActions(Actions, userId);

            this.bindAction(actions.get, this.update);
            this.bindAction(actions.actualize, this.update);
        }

        output(state) {
            return { ...state.presence };
        }

        update(presence) {

            if (!presence) {
                return;
            }

            this.setState({
                presence: {
                    devices: [],
                    ...presence
                }
            });

        }

    }

    Store.displayName = createPresenceStore.getDisplayName(userId);

    Store.config = {
        getState(state) {
            return { ...state.presence };
        }
    };

    return Store;
}

createPresenceStore.getDisplayName = function (id) {
    return `user-presence.${id}`;
};

export default createPresenceStore;
