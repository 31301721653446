import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, TouchableOpacity, StatusBar } from 'react-native';
import styles from './styles';
import Resources from '../../../resources';
import Button from '../../../components/buttons/base';
import { USER_GOALS } from '@sdv/domain/user.goals';
import testId from '../../../utils/test-id';

class UserGoalsScreen extends React.PureComponent {
    static displayName = 'authentication.goals.screen.view';
    static propTypes = {
        goals: PropTypes.array,
        submitSelection: PropTypes.func,
    };

    state = {};

    submitButtonDisabled = () => {
        return (this.state.selectedItems || this.props.goals).length === 0;
    };

    submit = () => {
        const items = this.state.selectedItems || this.props.goals || [];
        this.props.submitSelection && this.props.submitSelection(items);
    };

    isSelected = (goal) => {
        const items = this.state.selectedItems || this.props.goals || [];
        return items.indexOf(goal) >= 0;
    };

    select = (goal) => () => {
        let items = (this.state.selectedItems || this.props.goals || []).slice();
        const index = items.indexOf(goal);
        if (index < 0) {
            items.push(goal);
        } else {
            items.splice(index, 1);
        }
        this.setState({ selectedItems: items });
    };

    render() {
        const selectedButton = [styles.button, styles.selectedButtonModifier];
        const selectedTitle = [styles.buttonTitle, styles.selectedTitleModifier];

        const friendshipSelected = this.isSelected(USER_GOALS.FRIENDSHIP);
        const datingSelected = this.isSelected(USER_GOALS.DATING);
        const chatSelected = this.isSelected(USER_GOALS.CHAT);

        return (
            <View style={styles.container}>
                <StatusBar
                    barStyle={styles.$statusBarStyle}
                    backgroundColor={styles.$statusBarBackgroundColor}
                    hidden={false}
                />
                <View style={styles.top}>
                    <Text style={styles.title}>{Resources.strings['user-goals-screen-title']}</Text>
                </View>
                <View style={styles.content}>
                    <TouchableOpacity
                        style={friendshipSelected ? selectedButton : styles.button}
                        onPress={this.select(USER_GOALS.FRIENDSHIP)}
                        {...testId('Friendship goal button')}
                    >
                        <Text style={friendshipSelected ? selectedTitle : styles.buttonTitle}>
                            {Resources.strings['user-goals-friendship']}
                        </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={datingSelected ? selectedButton : styles.button}
                        onPress={this.select(USER_GOALS.DATING)}
                        {...testId('Dating goal button')}
                    >
                        <Text style={datingSelected ? selectedTitle : styles.buttonTitle}>
                            {Resources.strings['user-goals-dating']}
                        </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={chatSelected ? selectedButton : styles.button}
                        onPress={this.select(USER_GOALS.CHAT)}
                        {...testId('Chat goal button')}
                    >
                        <Text style={chatSelected ? selectedTitle : styles.buttonTitle}>
                            {Resources.strings['user-goals-chat']}
                        </Text>
                    </TouchableOpacity>
                </View>
                <View style={styles.bottom}>
                    <Button
                        disabled={this.submitButtonDisabled()}
                        title={Resources.strings['about-yourself-screen-button-title']}
                        onPress={this.submit}
                        {...testId('Next step button')}
                    />
                </View>
            </View>
        );
    }

}

export default UserGoalsScreen;
