import React from 'react';
import connect from '@sdv/connect';
import StreamsModel from 'dating-mobile/src/models/dialogs.streams/model';
import PropTypes from 'prop-types';

function createController(View) {

    class Controller extends React.Component {
        static displayName = 'dialogs.chats.views.streaming-indicator.controller';
        static propTypes = {
            userId: PropTypes.string.isRequired,
            streams: PropTypes.array.isRequired,
        };

        render() {
            const userIsStreaming = this.props.streams.indexOf(this.props.userId) !== -1;
            return (<View {...this.props} visible={userIsStreaming}/>);
        }
    }

    function getModels(flux, props) {
        const models = {
            streamsModel: flux.get(StreamsModel)
        };

        return models;
    }

    function mapStoresToProps(models) {
        const props = {
            streams: models.streamsModel.store.getState().streams
        };
        return props;
    }

    function mapActionsToProps(models) {

        return {};
    }

    return connect(
        getModels,
        mapStoresToProps,
        mapActionsToProps
    )(Controller);
}

export default createController;
