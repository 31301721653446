import EStyleSheet from 'react-native-extended-stylesheet';

const defaultActionButtonStyle = {
    height: 42,
    borderRadius: 24,
};

const defaultFollowButtonStyle = {
    ...defaultActionButtonStyle,
    marginRight: 12,
};

const styles = EStyleSheet.create({
    $statusBarStyle: '$brandStatusBarStyle',
    $statusBarBackgroundColor: '$brandColor',

    header: {
        backgroundColor: '$brandColor',
        borderBottomColor: '$brandColor',
        shadowColor: '$brandColor',
        shadowRadius: 0,
        shadowOpacity: 0,
        elevation: 0,
        shadowOffset: {
            height: 0,
        },
    },
    headerTitle: {
        fontFamily: '$brandFontFamily',
        color: '$brandTextColor',
    },
    container: {
        flex: 1,
        flexDirection: 'column',
        paddingTop: 120,
        backgroundColor: 'transparent',
    },
    scroll: {
        flexGrow: 1,
        backgroundColor: '$brandColor',
        width: '100%',
    },
    content: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
    },
    closeButton: {
        width: 32,
        height: 32,
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 12,
        paddingTop: 8,
    },
    closeImage: {
        '@flavor lovinga': {
            width: 12,
            height: 12,
            tintColor: '$brandTextColor',
        },
    },
    profileLineContainer: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    profile: {
        marginTop: -105,
        marginBottom: 10,
        alignItems: 'center',
        justifyContent: 'center',
    },
    profileName: {
        fontSize: 18,
    },
    statusContainer: {
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: 20,
    },
    buttonsContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginHorizontal: 40,
        marginVertical: 32,
        '@media(min-width: 360)': {
            marginHorizontal: 60,
        },
    },

    actionButtonTitle: {
        fontSize: 17,
    },
    chatButtonContainer: {
        flex: 1,
    },
    chatButton: defaultActionButtonStyle,
    followButtonFollowing: {
        ...defaultFollowButtonStyle,
        flex: undefined,
        width: defaultFollowButtonStyle.height,
    },
    followButtonNotFollowing: {
        ...defaultFollowButtonStyle,
        flex: 1,
        width: undefined,
    },

    followButtonContainer: {
        width: '100%',
    },
    followButtonIcon: {
        tintColor: 'white',
    },
    followButtonTitle: {
        paddingLeft: 4,
        paddingRight: 10,
    },
});

export default styles;
