import EStyleSheet from 'react-native-extended-stylesheet';

const defaultTextStyle = {
    fontSize: 16,
    color: '$primaryTextColor',
    fontFamily: '$brandFontFamily',
};

const styles = EStyleSheet.create({
    container: {
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        ...defaultTextStyle,
        marginBottom: 8,
    },
    timer: {
        ...defaultTextStyle,
        fontWeight: 'bold',
    },
});

export default styles;
