import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
    },
    pickerWithError: {
        paddingRight: 30
    },
    gender: {
        width: '100%',
    },
    genderPickerTitle: {
        '@flavor udates': {
            fontFamily: '$brandFontFamily',
            fontWeight: 'normal',
            color: '$brandPlaceholderColor'
        }
    },
    error: {
        fontSize: 14,
        paddingVertical: 5,
        color: 'red',
        fontFamily: '$brandFontFamily',
    },
});

export default styles;
