import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-native';
import { tap } from 'rxjs/operators';
import { Stock } from '@sdv/domain/payment/stock';
import { render } from 'dating-mobile/src/components/rx-render';
import { Purchase } from 'dating-mobile/src/payment/methods/purchase';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';
import Resources from 'dating-mobile/src/resources';
import flux from '@sdv/domain/app/flux';
import UserTags from '@sdv/domain/user/tags/personal';
import withConfigValue from 'dating-mobile/src/components/config-value';
import withUserBalance from 'dating-mobile/src/models/credits.accounts.balance/controllers/controller';
import { SupportedMethods } from '@sdv/domain/app/payment/shop-info';

const getCallsPacks = (packages = [], skipPremium = false) =>
  packages
    .filter(
      p =>
        p?.[0]?.containsCallsPack &&
        !(skipPremium && p?.[0]?.isPremiumMembership),
    )
    .sort((a, b) => {
      if (a[0].isPremiumMembership && !b[0].isPremiumMembership) {
        return -1;
      }

      if (b[0].isPremiumMembership && !a[0].isPremiumMembership) {
        return 1;
      }

      return 0;
    });

export default function createController(Component) {
  const Controller = render(
    (
      {
        userId,
        onClose,
        reason,
        balance,
        skipPremium,
        unlimitedMessagesWithMembershipEnabled,
      },
      { useObservable, useObservableAction },
    ) => {
      const packages = userId
        ? useObservable(it => it.preloadedPackagesInStock, Stock.shared(userId))
        : undefined;

      const purchase = Purchase.shared();

      const isLoading = useObservable(it => it.isLoading, purchase) || false;

      const callsPacks = getCallsPacks(packages, skipPremium);

      const purchasePackage = useObservableAction(
        ([pack, invoices]) => {
          const options = {};

          if (invoices?.[0]?.price?.currency === SupportedMethods.credits) {
            options.minCreditsAmount =
              balance?.amount && invoices?.[0]?.price?.includingTax
                ? invoices?.[0]?.price?.includingTax - balance?.amount
                : null;
            options.descriptionTitle = options.minCreditsAmount
              ? Resources.strings.formatString(
                  Resources.strings[
                    'calls-packs-screen-purchase-credits-to-call-payment-title'
                  ],
                  options.minCreditsAmount,
                )
              : '';
            options.descriptionHint =
              Resources.strings[
                'calls-packs-screen-purchase-credits-to-call-payment-hint'
              ];
          }

          return purchase.pay(userId, invoices[0], reason, null, options).pipe(
            tap(({ success }) => {
              if (success) {
                flux.get(UserTags, userId)?.actions?.get();

                Alert.alert(
                  Resources.strings['purchase-audio-calls-success-message'],
                  null,
                  [
                    {
                      text: Resources.strings.ok,
                      style: 'cancel',
                      onPress: () => onClose(pack),
                      onDismiss: () => onClose(pack),
                    },
                  ],
                );
              }
            }),
          );
        },
        purchase,
        userId,
      );

      return (
        <Component
          packages={callsPacks}
          isLoading={isLoading}
          onPress={purchasePackage}
          unlimitedMessagesWithMembershipEnabled={
            unlimitedMessagesWithMembershipEnabled
          }
        />
      );
    },
  );

  Controller.displayName = 'payment.screens.calls.controller';

  Controller.propTypes = {
    userId: PropTypes.string,
    onClose: PropTypes.func,
    reason: PropTypes.string,
    skipPremium: PropTypes.bool,
    balance: PropTypes.shape({
      amount: PropTypes.number,
    }),
  };

  return withConfigValue(
    withIdentityId(
      withUserBalance(Controller, {
        userIdPropName: 'userId',
      }),
    ),
    {
      unlimitedMessagesWithMembershipEnabled:
        'features.unlimited-messages-with-membership-enabled',
    },
  );
}
