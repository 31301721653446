import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, TouchableWithoutFeedback, Image } from 'react-native';
import UserThumbnail from '../../../user/common/views/thumbnail';
import styles from './styles';
import Resources from '../../../resources';

export default class IncomingCallNotificationView extends React.Component {
  static displayName = 'notifications.views.incoming-call.view';

  static propTypes = {
    attendee: PropTypes.object,
    notification: PropTypes.object.isRequired,
    onReadyForDisplay: PropTypes.func.isRequired,
    onNotificationPress: PropTypes.func.isRequired,
    dismiss: PropTypes.func.isRequired,
    videoChatEnabled: PropTypes.bool, // TODO: Add another config value
  };

  componentDidMount() {
    const { onReadyForDisplay } = this.props;

    if (onReadyForDisplay) {
      onReadyForDisplay();
    }
  }

  shouldComponentUpdate(nextProps) {
    const { attendee, notification } = this.props;

    return (
      attendee !== nextProps.attendee || notification !== nextProps.notification
    );
  }

  onNotificationPressed = () => {
    const { onNotificationPress, dismiss } = this.props;

    if (onNotificationPress) {
      onNotificationPress();
    }

    if (dismiss) {
      dismiss();
    }
  };

  render() {
    const { videoChatEnabled, attendee } = this.props;
    const username = attendee?.name;
    const age = attendee?.birthday?.age;
    let profileText = '';

    if (username) {
      profileText += username;
    }

    if (age && profileText) {
      profileText += `, ${age}`;
    }

    return (
      <TouchableWithoutFeedback onPress={this.onNotificationPressed}>
        <View style={styles.container}>
          <View style={styles.photoContainer}>
            <UserThumbnail style={styles.photo} userId={attendee?.id} round />
          </View>
          <View style={styles.textContainer}>
            <Text numberOfLines={1} style={styles.username}>
              {profileText}
            </Text>
            <Text style={styles.message} numberOfLines={2}>
              {
                Resources.strings[
                  videoChatEnabled
                    ? 'video-chat-incoming-call-notification-text'
                    : 'audio-call-incoming-notification-text'
                ]
              }
            </Text>
          </View>
          {styles.$hasVideoCameraIcon && (
            <Image
              style={styles.cameraIconStyle}
              source={Resources.images.videoCameraIcon()}
            />
          )}
        </View>
      </TouchableWithoutFeedback>
    );
  }
}
