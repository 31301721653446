import React from 'react';
import PropTypes from 'prop-types';
import { View, Image, TouchableOpacity } from 'react-native';

import CurrencyBasedText from 'dating-mobile/src/components/currency/currency-based-text';
import Photo from '../../../../components/photo';
import withAspectRatio from '../../../../components/aspect-ratio';
import Resources from '../../../../resources';
import testId from '../../../../utils/test-id';

import styles from './styles';

const AspectRatioView = withAspectRatio(View);

class CheerItem extends React.Component {
  static propTypes = {
    cheer: PropTypes.shape({
      basename: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
    }),
    onPress: PropTypes.func,
    currencyType: PropTypes.string,
  };

  shouldComponentUpdate(nextProps) {
    const { onPress, cheer, currencyType } = this.props;
    const currentBasename = cheer?.basename;
    const nextBasename = nextProps.cheer?.basename;
    const currentPrice = cheer?.price;
    const nextPrice = nextProps.cheer?.price;

    return (
      currentBasename !== nextBasename ||
      currentPrice !== nextPrice ||
      onPress !== nextProps.onPress ||
      currencyType !== nextProps.currencyType
    );
  }

  onPressed = () => {
    const { onPress, cheer } = this.props;

    if (onPress) {
      onPress(cheer);
    }
  };

  render() {
    const { cheer, currencyType } = this.props;

    return (
      <TouchableOpacity
        onPress={this.onPressed}
        {...testId('Cheers list item')}
      >
        <View style={styles.container}>
          <AspectRatioView style={styles.imageContainer}>
            <Photo forceRetina style={styles.image} path={cheer.basename} />
            {cheer.isFree() && (
              <View style={styles.freeBadgeContainer}>
                <Image
                  source={Resources.images.freeCheerIcon()}
                  style={styles.freeBadge}
                />
              </View>
            )}
          </AspectRatioView>
          <View style={styles.priceContainer}>
            {currencyType === 'coins' && (
              <Image style={styles.coin} source={Resources.images.coinIcon()} />
            )}
            <CurrencyBasedText
              style={styles.price}
              strings={{
                coins: cheer.price,
                credits: Resources.strings.formatString(
                  Resources.strings['cheer-keyboard-item-credits-format'],
                  cheer.price,
                ),
              }}
            />
          </View>
        </View>
      </TouchableOpacity>
    );
  }
}

export default CheerItem;
