import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Keyboard, ScrollView } from 'react-native';

export class FocusScroll extends PureComponent {
    static propTypes = {
        ...ScrollView.propTypes,
        children: PropTypes.func.isRequired,
    };

    state = {
        keyboardVisible: false,
        inputFocused: false,
    };

    componentDidMount() {
        this.keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', () => {
            this.setState({ keyboardVisible: true }, this.scrollToEnd);
        });
        this.keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', () => {
            this.setState({ keyboardVisible: false });
        });
    }

    componentWillUnmount() {
        this.keyboardDidShowListener.remove();
        this.keyboardDidHideListener.remove();
    }

    scrollToEnd = () => {
        const { keyboardVisible, inputFocused } = this.state;

        if (keyboardVisible && inputFocused && this.scrollView) {
            this.scrollView.scrollToEnd({ animated: true });
        }
    };

    onFocus = () => {
        this.setState({ inputFocused: true }, this.scrollToEnd);
    };

    onBlur = () => {
        this.setState({ inputFocused: false });
    };

    render() {
        const { children, ref, ...props } = this.props;

        return (
            <ScrollView
                {...props}
                ref={scrollView => {
                    this.scrollView = scrollView;

                    if (ref) {
                        ref(scrollView);
                    }
                }}
            >
                {children({
                    onFocus: this.onFocus,
                    onBlur: this.onBlur,
                })}
            </ScrollView>
        );
    }
}
