import Actions from './actions';
import { ReplaySubject } from 'rxjs';
import { first } from 'rxjs/operators';

function createLetterStore(id) {

    class Store {

        state = {
            letter: {}
        };

        constructor(flux) {
            const actions = flux.getActions(Actions, id);
            this.eventInbox = flux.letterEventInbox;
            this.bindAction(actions.get, this.update);
            this.bindAction(actions.markAsRead, this.markAsRead);
            this.letterSubject = new ReplaySubject();
            this.letterSubject.asObservable().subscribe((letter) => this.setState({
                letter: letter
            }));
        };

        output(state) {
            return { ...state.letter };
        }

        markAsRead = () => {
            this.letterSubject
                .pipe(first())
                .subscribe((letter) => {
                    this.eventInbox.markLetterAsRead(letter);
                });
        };

        update = (letter) => {

            if (!letter) {
                return;
            }

            this.letterSubject.next(letter);
        };

    }

    Store.displayName = createLetterStore.getDisplayName(id);

    Store.config = {
        getState(state) {
            return { ...state.letter };
        }
    };

    return Store;
}

createLetterStore.getDisplayName = function (id) {
    return `dialogs-letter.${id}`;
};

export default createLetterStore;
