import CredentialsUpdater from '@sdv/domain/app/credentials-updater';
import Persistence from '@sdv/domain/app/persistence';
import { Subject, combineLatest } from 'rxjs';
import '@sdv/commons/rx/store';
import { singleton } from '@sdv/commons/utils/singleton';
import Session from '@sdv/domain/authorization/session';

const signUpProposalClosedKey = `domain.feed-signup-banner:closed`;

import {
    map,
    switchMap,
    startWith,
    distinctUntilChanged
} from 'rxjs/operators';

class SignUpProposal {
    static shared = singleton(() => new SignUpProposal());

    constructor() {
        this.persistence = Persistence.shared();

        this.shouldReloadData = new Subject();

        this.state = this.shouldReloadData.pipe(
            startWith(undefined),
            switchMap(() =>
                combineLatest(
                    this.persistence.rxValue(signUpProposalClosedKey),
                    Session.shared().userIsUsingDemoMode
                ).pipe(
                    map(([proposalClosed, demoUser]) => {
                        if (!demoUser) {
                            return ReminderState.signedUp;
                        } else {
                            return proposalClosed ? ReminderState.postponed : ReminderState.active;
                        }
                    }),
                    distinctUntilChanged()
            ))
        );
    }

    closeProposal = () => {
        this.persistence.rxStore(signUpProposalClosedKey, 'closed')
            .subscribe(() => {
                this.shouldReloadData.next();
            });
    };

    signUp() {
        this.closeProposal();
        CredentialsUpdater.updateCredentials();
    }

    decline() {
        this.closeProposal();
    }

}

export const ReminderState = Object.freeze({
    active: 'active',
    postponed: 'postponed',
    signedUp: 'signedUp'
});

export default SignUpProposal;
