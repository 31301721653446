import * as ROUTES from '../../router/constants';

export const NAV_ITEMS = {
  SEARCH: {
    icon: 'tabBarFeed',
    text: 'feed-screen-title',
    to: ROUTES.FEED_TAB,
    init: ROUTES.FEED,
  },
  BOOST: {
    icon: 'tabBarPurchase',
    text: 'purchase-screen-title',
    to: ROUTES.PURCHASE_TAB,
    init: ROUTES.PURCHASE,
  },
  CHATS: {
    icon: 'tabBarChat',
    text: 'chats-tab-bar-item-title',
    to: ROUTES.CONTACTS_TAB,
    init: ROUTES.CONTACTS,
  },
  PROFILE: {
    icon: 'tabBarProfile',
    text: 'account-nav-bar-item-title',
    to: ROUTES.ACCOUNT_TAB,
    init: ROUTES.ACCOUNT,
  },
};
