import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        flexDirection: 'column',
        alignItems: 'stretch'
    },

    headerContainer: {
        marginBottom: 20
    },

    listContainer: {
        marginBottom: 22
    },

    list: {
        width: '100%',
        marginHorizontal: -12
    },

    showAllButtonContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },

    showAllButton: {
        marginBottom: 16,
        alignItems: 'center',
        justifyContent: 'center'
    },

    showAllButtonTitle: {
        fontSize: 15,
        color: '#007aff'
    }
});

export default styles;
