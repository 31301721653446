import React from 'react';
import PropTypes from 'prop-types';
import { Image, Text, TouchableWithoutFeedback, View } from 'react-native';
import Resources from '../../../resources';
import styles from './styles';
import testId from 'dating-mobile/src/utils/test-id';

export default class MessageBoostedContent extends React.Component {
    static displayName = 'notifications.popup.message-boosted';
    static propTypes = {
        notification: PropTypes.object,
        openChatAndDismiss: PropTypes.func.isRequired,
        onReadyForDisplay: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.props.onReadyForDisplay();
    }

    openChat = () => {
        const {
            notification: { attendee },
        } = this.props;

        this.props.openChatAndDismiss(attendee);
    };

    render() {
        return (
            <TouchableWithoutFeedback onPress={this.openChat}>
                <View style={styles.container}>
                    <View style={styles.iconContainer}>
                        <Image style={styles.icon} source={Resources.images.logoIcon()} />
                    </View>
                    <View
                        style={styles.textContainer}
                        {...testId(Resources.strings['message-boosted-popup-accessibility-label'])}
                    >
                        <Text style={styles.text}>
                            {Resources.strings['message-boosted-notification-text']}
                        </Text>
                    </View>
                </View>
            </TouchableWithoutFeedback>
        );
    }
}
