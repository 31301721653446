import React from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity, Image } from 'react-native';
import styles from './styles';
import Resources from '../../../../resources';

class VolumeControl extends React.PureComponent {
    static displayName = 'volume.control.view';
    static propTypes = {
        remote: PropTypes.bool,
        microphoneMuted: PropTypes.bool.isRequired,
        audioMuted: PropTypes.bool.isRequired,
        onPress: PropTypes.func.isRequired,
    };

    update = () => {
        if (this.props.onPress) {
            this.props.onPress();
        }
    };

    render() {
        let icon;
        if (!!this.props.remote) {
            icon = this.props.audioMuted ?
                <Image source={require('./assets/soundOff.png')}/> :
                <Image source={require('./assets/soundOn.png')}/>;
        } else {
            icon = this.props.microphoneMuted ?
                <Image source={Resources.images.microphoneMutedIcon()}/> :
                <Image source={Resources.images.microphoneIcon()}/>;
        }

        return (
            <TouchableOpacity onPress={this.update} style={styles.container}>
                {icon}
            </TouchableOpacity>
        );
    }
}

export default VolumeControl;
