/**
 * Хранит текущий ключ сессии
 */
import guid from '@sdv/commons/utils/guid';

let session = null;
const controller = {};

controller.active = false;
controller.cta = null;

controller.new = function() {
  session = guid();
  exports.active = true;
};

controller.get = function() {
  return session;
};

controller.setAnswer = function(newSession) {
  session = newSession;
  exports.active = true;
};

controller.clear = function() {
  session = null;
  exports.active = false;
  exports.cta = null;
};

export default controller;
