import * as events from 'events';
import Service from './modules';

class MediaSoupClient extends events.EventEmitter {
  constructor(mediaSoupNetworking) {
    super();
    this.networking = mediaSoupNetworking;
    this.joinedRooms = {};
    this.handlers = {};
    this.streamStatusHandlers = {};
    this.removeCostreamerInvitationHandlers = {};
    this.streamParametersNeedsUpdate = {};
    this.audioMuted = false;
    this.microphoneMuted = false;
  }

  tracksAdded = id => {
    return payload => {
      this.muteAudio(id, this.audioMuted);

      if (this.microphoneMuted) {
        this.muteMicrophone(id, this.microphoneMuted);
      }
      this.emit('event.tracks.added', id, payload);
    };
  };

  streamStatusUpdated = id => {
    return attached => {
      this.emit('event.costream.invitation.added', id, attached);
    };
  };

  removeCostreamerInvitation = id => {
    return attached => {
      this.emit('event.costream.invitation.removed', id, attached);
    };
  };

  join = id => {
    if (this.joinedRooms[id]) {
      // already connected, reconnect if needed
    } else {
      const service = new Service(this.networking);

      service.join(id, message => message);
      this.handlers[id] = this.tracksAdded(id);
      service.addListener('event.change.stream', this.handlers[id]);

      this.streamStatusHandlers[id] = this.streamStatusUpdated(id);
      this.removeCostreamerInvitationHandlers[
        id
      ] = this.removeCostreamerInvitation(id);
      service.addListener(
        'event.costreamer.invitation.added',
        this.streamStatusHandlers[id],
      );
      service.addListener(
        'event.costreamer.invitation.removed',
        this.removeCostreamerInvitationHandlers[id],
      );

      this.joinedRooms[id] = service;
    }
  };

  leave = id => {
    const service = this.joinedRooms[id];

    if (service) {
      service.leave();
      this.removeListeners(id);
    } else {
      // connection doesn't exists
    }
  };

  startBroadcastPreview = id => {
    if (this.joinedRooms[id]) {
      return;
    }

    const service = new Service(this.networking);

    this.handlers[id] = this.tracksAdded(id);
    this.joinedRooms[id] = service;
    this.startPreviewForRoom = id;
    service.addListener('event.change.stream', this.handlers[id]);
    service.startStreamPreview(id);
  };

  broadcast = id => {
    if (this.joinedRooms[id]) {
      // reconnect if needed
      if (this.startPreviewForRoom === id) {
        const service = this.joinedRooms[id];
        const params = this.streamParametersNeedsUpdate[id];

        service.startLiveStream(params);
        this.streamStatusHandlers[id] = this.streamStatusUpdated(id);
        this.removeCostreamerInvitationHandlers[
          id
        ] = this.removeCostreamerInvitation(id);
        service.addListener(
          'event.costreamer.invitation.added',
          this.streamStatusHandlers[id],
        );
        service.addListener(
          'event.costreamer.invitation.removed',
          this.removeCostreamerInvitationHandlers[id],
        );
      }
    } else {
      const service = new Service(this.networking);

      service.join(id, payload => {
        if (payload.message === 'success') {
          const params = this.streamParametersNeedsUpdate[id];

          service.startBroadcast(params);
        }
      });
      this.handlers[id] = this.tracksAdded(id);
      service.addListener('event.change.stream', this.handlers[id]);

      this.streamStatusHandlers[id] = this.streamStatusUpdated(id);
      this.removeCostreamerInvitationHandlers[
        id
      ] = this.removeCostreamerInvitation(id);

      service.addListener(
        'event.costreamer.invitation.added',
        this.streamStatusHandlers[id],
      );
      service.addListener(
        'event.costreamer.invitation.removed',
        this.removeCostreamerInvitationHandlers[id],
      );

      this.joinedRooms[id] = service;
    }
  };

  stopBroadcast = id => {
    const service = this.joinedRooms[id];

    if (service) {
      service.stopBroadcast();
      this.removeListeners(id);
    }
  };

  removeListeners = id => {
    const service = this.joinedRooms[id];

    if (service) {
      if (this.handlers[id]) {
        service.removeListener('event.change.stream', this.handlers[id]);
      }

      if (this.streamStatusHandlers[id]) {
        service.removeListener(
          'event.costreamer.invitation.added',
          this.streamStatusHandlers[id],
        );
      }

      if (this.removeCostreamerInvitationHandlers[id]) {
        service.removeListener(
          'event.costreamer.invitation.removed',
          this.removeCostreamerInvitationHandlers[id],
        );
      }

      this.joinedRooms[id] = null;
      this.handlers[id] = null;
      this.streamStatusHandlers[id] = null;
      this.removeCostreamerInvitationHandlers[id] = null;
    }
  };

  muteAudio = (id, state) => {
    this.emit('event.audio.muted', state);
    this.audioMuted = state;
    const service = this.joinedRooms[id];

    if (service) {
      service.setMuteState(state);
    } else {
      // connection doesn't exists
    }
  };

  pauseStream = id => {
    const service = this.joinedRooms[id];

    if (service) {
      service.pause();
    }
  };

  resumeStream = id => {
    const service = this.joinedRooms[id];

    if (service) {
      service.resume();
    }
  };

  updateStream = (id, title, cover, topics) => {
    this.streamParametersNeedsUpdate[id] = {
      title,
      cover,
      topics,
    };
  };

  muteMicrophone = (id, state) => {
    this.emit('event.microphone.muted', state);
    this.microphoneMuted = state;
    const service = this.joinedRooms[id];

    if (service) {
      service.setMicrophoneState(state);
    } else {
      // connection doesn't exists
    }
  };

  rotateCamera = id => {
    const service = this.joinedRooms[id];

    if (service) {
      service.rotateCamera();
    } else {
      // connection doesn't exists
    }
  };

  sendAttachStreamRequest = (room, attachId) => {
    const service = this.joinedRooms[room];

    if (service) {
      service.attachCoStreamer(attachId);
    } else {
      // error, such stream doesn't exists
    }
  };

  declineCostream = (room, attached) => {
    const service = this.joinedRooms[room];

    if (service) {
      service.declineCoStreamer(attached);
    } else {
      // error, such stream doesn't exists
    }
  };
}

export default MediaSoupClient;
