import React from 'react';
import PropTypes from 'prop-types';
import {
    View,
    Text,
    TouchableOpacity,
    StatusBar,
    ActivityIndicator,
    Keyboard,
    ScrollView,
} from 'react-native';
import { SafeAreaView } from 'react-navigation';
import styles from './styles';
import ListComponent from '../../views/free-message-consumers';
import MessageSelection from '../../../dialogs/mingle/views/message-selection';
import Resources from '../../../resources';
import Form from '../../../dialogs/common/views/form';
import testId from '../../../utils/test-id';

export default class FreeStartMessagesView extends React.PureComponent {
    static displayName = 'user.free-start-messages.screen.view';

    static propTypes = {
        onMessageSelect: PropTypes.func,
        messages: PropTypes.array,
        consumers: PropTypes.array,
        isLoading: PropTypes.bool,
    };

    state = {
        messageTyping: false,
        listHeight: 0,
    };

    componentDidMount() {
        Keyboard.addListener('keyboardDidShow', this.onKeyboardChanged);
        Keyboard.addListener('keyboardDidHide', this.onKeyboardChanged);
    }

    componentWillUnmount() {
        Keyboard.removeListener('keyboardDidShow', this.onKeyboardChanged);
        Keyboard.removeListener('keyboardDidHide', this.onKeyboardChanged);
    }

    onMessageSelected = message => {
        if (message) {
            this.props.onMessageSelect && this.props.onMessageSelect(message);
        } else {
            this.setState({
                messageTyping: true,
            });
        }
    };

    onChooseMessagePressed = () => {
        this.setState({
            messageTyping: false,
        });
    };

    onListSizeChanged = (width, height) => {
        this.setState({
            listHeight: height,
        });
    };

    onKeyboardChanged = () => {
        this.scrollRef && this.scrollRef.scrollToEnd({ animated: false });
    };

    render() {
        const forceInset = !!this.state.messageTyping
            ? { top: 'never', bottom: 'never' }
            : { top: 'never', bottom: 'always' };

        return (
            <SafeAreaView forceInset={forceInset} style={styles.container}>
                <StatusBar
                    barStyle={styles.$statusBarStyle}
                    backgroundColor={styles.$statusBarBackgroundColor}
                />
                <ScrollView
                    ref={ref => {
                        this.scrollRef = ref;
                    }}
                    alwaysBounceVertical={false}
                    style={styles.scroll}
                    contentContainerStyle={styles.scrollContent}
                >
                    <View />
                    <View style={styles.headerContainer}>
                        <Text style={styles.title} {...testId('Push people nearby screen title')}>
                            {Resources.strings['push-people-nearby-screen-title']}
                        </Text>
                        <Text style={styles.description}>
                            {Resources.strings['push-people-nearby-screen-description']}
                        </Text>
                    </View>
                    <View style={[styles.listContainer, { height: this.state.listHeight }]}>
                        <ListComponent
                            onContentSizeChange={this.onListSizeChanged}
                            users={this.props.consumers}
                        />
                    </View>
                    {!!this.state.messageTyping && (
                        <TouchableOpacity
                            style={styles.chooseMessageButton}
                            onPress={this.onChooseMessagePressed}
                        >
                            <Text style={styles.chooseMessageButtonTitle}>
                                {
                                    Resources.strings[
                                        'start-mingle-screen-choose-message-button-title'
                                    ]
                                }
                            </Text>
                        </TouchableOpacity>
                    )}
                    {!this.state.messageTyping && (
                        <View style={styles.messagesContainer}>
                            <MessageSelection
                                messages={this.props.messages}
                                onMessageSelect={this.onMessageSelected}
                            />
                        </View>
                    )}
                </ScrollView>
                {this.state.messageTyping && (
                    <View>
                        <Form
                            autoFocus
                            controlSafeArea
                            giftsEnabled={false}
                            mediaEnabled={false}
                            onSend={this.onMessageSelected}
                        />
                    </View>
                )}
                {!!this.props.isLoading && (
                    <View style={styles.activityIndicatorContainer}>
                        <ActivityIndicator animating />
                    </View>
                )}
            </SafeAreaView>
        );
    }
}
