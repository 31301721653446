import React from 'react';
import PropTypes from 'prop-types';
import StreamModel from '../../../models/dialogs.stream/model';
import RoomModel from '../../../models/dialogs.room/model';
import { AppState } from 'react-native';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';
import url from 'url';
import MessagesModel, { getId } from '../../../models/dialogs.room/utils/messages-adapter';
import withConfigValue from 'dating-mobile/src/components/config-value';
import StreamConnectionMonitoring from '@sdv/domain/app/streaming/stream-connection';
import { distinctUntilChanged, delay, switchMap, pairwise, filter } from 'rxjs/operators';
import { of, empty } from 'rxjs';

const CONTROLS_STATE = Object.freeze({
    PROFILE: 2,
    PRIVATE_CHAT: 3,
});

const CHANGE_STREAM_DELAY = 2; //sec

function CreateStreamsController(Component) {
    class StreamsScreenController extends React.Component {
        static displayName = 'dialogs.stream.screen.controller';
        static propTypes = {
            id: PropTypes.string,
            userId: PropTypes.string.isRequired,
            showNextStream: PropTypes.func,
        };
        static contextTypes = {
            flux: PropTypes.object,
        };
        appState = AppState.currentState;

        constructor(props, context) {
            super(props);
            this.lastMessage = {};
            this.donationQueue = [];
            this.context = context;

            const id = this.getId();
            this.hostModel = context.flux.get(StreamModel, id);
            this.hostRoomModel = context.flux.get(RoomModel, id);

            this.state = {
                shouldCountdownTimer: true,
                ...this.createHost(id),
            };
        }

        createHost = id => {
            const currentState = this.hostModel.store.getState();
            const data = {
                guest: null,
                donation: null,
                host: {
                    id: id,
                    hasVideo: !!currentState.video,
                    audioMuted: currentState.audioMuted,
                    finished: false,
                    viewers: currentState.viewers,
                    attached: currentState.attached,
                    restream: currentState.restream,
                    microphoneMuted: currentState.microphoneMuted,
                },
                messagesModel: this.context.flux.get(MessagesModel, getId(this.props.userId, id)),
            };

            if (currentState.attached) {
                this.guestModel = this.context.flux.get(StreamModel, currentState.attached);
                this.guestRoomModel = this.context.flux.get(RoomModel, currentState.attached);
                const guestState = this.guestModel.store.getState();
                data.guest = {
                    id: guestState.id,
                    hasVideo: !!guestState.video,
                    audioMuted: guestState.audioMuted,
                    finished: guestState.streaming === 0,
                };
            } else if (currentState.pendingQueue[0] && id === this.props.userId) {
                this.guestModel = this.context.flux.get(StreamModel, currentState.pendingQueue[0]);
                const guestState = this.guestModel.store.getState();
                data.guest = {
                    id: guestState.id,
                    hasVideo: !!guestState.video,
                    audioMuted: guestState.audioMuted,
                    finished: guestState.streaming === 0,
                };
            }

            return data;
        };

        getId = () => {
            return this.updatedHostId || this.props.id || this.props.userId;
        };

        componentDidMount() {
            AppState.addEventListener('change', this.appStateChanged);
            this.hostModel.store.listen(this.updateHost);
            this.hostRoomModel.store.listen(this.updateHostRoom);
            if (this.guestModel) {
                this.guestModel.store.listen(this.updateGuest);
            }
            this.join();

            this.reconnection = StreamConnectionMonitoring.shared()
                .currentState.pipe(
                    pairwise(),
                    filter(([prev, curr]) => prev === false && curr === true),
                )
                .subscribe(() => {
                    this.reconnect();
                });
        }

        componentWillReceiveProps(nextProps) {
            if (nextProps.id !== this.props.id && nextProps.userId === this.props.userId) {
                this.hostModel.store.unlisten(this.updateHost);
                this.hostRoomModel.store.unlisten(this.updateHostRoom);
                if (this.guestModel) {
                    this.guestModel.store.unlisten(this.updateGuest);
                }
                this.leave();

                this.hostModel = this.context.flux.get(StreamModel, nextProps.id);
                this.hostRoomModel = this.context.flux.get(RoomModel, nextProps.id);

                this.setState(this.createHost(nextProps.id));
                this.hostModel.store.listen(this.updateHost);
                this.hostRoomModel.store.listen(this.updateHostRoom);
                if (this.guestModel) {
                    this.guestModel.store.listen(this.updateGuest);
                }
                this.join();
            }
        }

        componentWillUnmount() {
            AppState.removeEventListener('change', this.appStateChanged);
            this.hostModel.store.unlisten(this.updateHost);
            this.hostRoomModel.store.unlisten(this.updateHostRoom);
            if (this.guestModel) {
                this.guestModel.store.unlisten(this.updateGuest);
            }
            if (this.state.host.id === this.props.userId) {
                if (this.state.host.attached) {
                    this.hostModel.actions.decline(this.state.host.attached);
                }
                if (this.state.host.outgoingInvite) {
                    this.hostModel.actions.decline(this.state.host.outgoingInvite);
                }
            }
            this.reconnection && this.reconnection.unsubscribe();
            this.reconnection = null;
            this.leave();
        }

        componentDidUpdate(prevProps, prevState) {
            if (this.props.id !== prevProps.id || this.props.userId !== prevProps.userId) {
                this.setState({
                    messagesModel: this.context.flux.get(
                        MessagesModel,
                        getId(this.props.userId, this.getId()),
                    ),
                });
            }
            if (this.props.userId !== prevProps.userId) {
                if (this.props.userId && this.appState === 'active') {
                    this.leave();
                    this.join();
                } else {
                    this.leave();
                }
            }

            if (!prevState.host.finished && this.state.host.finished && this.props.showNextStream) {
                this.streamSwitchTimer = setTimeout(() => {
                    this.props.showNextStream();
                }, CHANGE_STREAM_DELAY * 1000);
            } else if (
                prevState.host.finished &&
                !this.state.host.finished &&
                this.streamSwitchTimer
            ) {
                clearTimeout(this.streamSwitchTimer);
                this.streamSwitchTimer = null;
            }
        }

        appStateChanged = nextAppState => {
            if (this.appState.match(/inactive|background/) && nextAppState === 'active') {
                this.join();
            }
            if (this.appState.match(/inactive|active/) && nextAppState === 'background') {
                this.pauseStream();
            }
            this.appState = nextAppState;
        };

        pauseStream = () => {
            if (this.props.userId === this.state.host.id) {
                this.stopBroadcasting();
            } else {
                this.hostModel.actions.leave();
            }
            this.pauseGuest();
            this.hostRoomModel.actions.leave();
        };

        pauseGuest = () => {
            if (this.guestModel) {
                this.guestModel.store.unlisten(this.updateGuest);
                this.guestModel.actions.leave();
                if (this.guestRoomModel) {
                    this.guestRoomModel.actions.leave();
                }
            }
        };

        join = () => {
            if (this.props.userId === this.state.host.id) {
                this.startBroadcasting();
            } else {
                this.hostModel.actions.join();
            }
            this.joinGuest();
            this.hostRoomModel.actions.join();
        };

        leave = () => {
            if (this.props.userId === this.state.host.id) {
                this.stopBroadcasting();
            } else {
                this.hostModel.actions.leave();
            }
            this.leaveGuest();
            this.hostRoomModel.actions.leave();
        };

        startBroadcasting = () => {
            this.hostModel.actions.broadcast();
        };

        stopBroadcasting = () => {
            this.hostModel.actions.stopBroadcast();
        };

        reconnect = () => {
            this.leave();
            this.setState(this.createHost(this.getId()), this.join);
        };

        joinGuest = () => {
            if (this.guestModel) {
                this.guestModel.actions.join();
                if (this.guestRoomModel) {
                    this.guestRoomModel.actions.join();
                }
            }
        };

        leaveGuest = () => {
            if (this.guestModel) {
                this.guestModel.store.unlisten(this.updateGuest);
                this.guestModel.actions.leave();
                if (this.guestRoomModel) {
                    this.guestRoomModel.actions.leave();
                }
            }
            this.guestModel = null;
            this.guestRoomModel = null;
        };

        createGuestModel = (id, withRoom) => {
            this.guestModel = this.context.flux.get(StreamModel, id);
            this.guestModel.store.listen(this.updateGuest);
            if (withRoom) {
                this.guestRoomModel = this.context.flux.get(RoomModel, id);
            }
            this.joinGuest();
        };

        startCountdownTimer = () => {
            this.startTimerTimeout && clearInterval(this.startTimerTimeout);
            this.startTimerTimeout = setInterval(() => {
                this.setState({
                    timeBeforeStart: this.state.timeBeforeStart - 1,
                });
                if (this.state.timeBeforeStart === 0) {
                    this.startTimerTimeout && clearInterval(this.startTimerTimeout);
                }
            }, 1000);
        };

        updateHost = modelState => {
            const { ...state } = modelState;
            /*
             * Делаем реконнект, если изменился параметр restream с 0 => 1 или 1 => 0.
             * Из этого следует, нужно сделать reconnect, если сумма предыдущего и текущего состояния равна 1.
             * 1 + 0 === 1
             * 0 + 1 === 1
             * 1 + 1 !== 0
             * 0 + 0 !== 0
             * undefined + 1 === NaN !== 1 --- ловим этот кейс при старте стрима: host.restream === undefined,
             * тк пока не получили информацию о стриме.
             * */
            if (this.state.host.restream + state.restream === 1) {
                return this.reconnect();
            }
            if (state.id !== this.props.userId) {
                state.pendingQueue = [];
            }
            let updatedHost = {
                ...this.state.host,
                hasVideo: !!state.video,
                audioMuted: state.audioMuted,
                finished: state.streaming === 0,
                viewers: state.viewers,
                restream: state.restream,
                microphoneMuted: state.microphoneMuted,
                outgoingInvite: state.outgoingInvite,
            };
            let data = { host: updatedHost };

            const newGuestStream = state.attached;
            if (
                this.getId() === this.props.userId &&
                this.state.shouldCountdownTimer &&
                updatedHost.hasVideo === true
            ) {
                data.timeBeforeStart = 5;
                data.shouldCountdownTimer = false;
                this.startCountdownTimer();
            }

            if (this.state.host.attached !== newGuestStream) {
                if (
                    this.state.host.attached ||
                    this.state.pending ||
                    (this.state.host.outgoingInvite &&
                        this.state.host.outgoingInvite !== newGuestStream)
                ) {
                    this.leaveGuest();
                    data.guest = null;
                }

                if (newGuestStream) {
                    this.createGuestModel(newGuestStream, true);
                    const guestState = this.guestModel.store.getState();
                    data.guest = {
                        id: guestState.id,
                        hasVideo: !!guestState.video,
                        audioMuted: guestState.audioMuted,
                        finished: guestState.streaming === 0,
                    };
                } else if (this.state.pending && this.state.pending === state.pendingQueue[0]) {
                    this.createGuestModel(this.state.pending, false);
                    const guestState = this.guestModel.store.getState();
                    data.guest = {
                        id: guestState.id,
                        hasVideo: !!guestState.video,
                        audioMuted: guestState.audioMuted,
                        finished: guestState.streaming === 0,
                    };
                }
                updatedHost.attached = newGuestStream;
            }

            if (this.state.pending !== state.pendingQueue[0]) {
                if (!updatedHost.attached) {
                    if (this.state.pending) {
                        this.leaveGuest();
                        data.pending = null;
                    }

                    if (state.pendingQueue[0]) {
                        this.createGuestModel(state.pendingQueue[0], false);
                        const guestState = this.guestModel.store.getState();
                        data.guest = {
                            id: state.pendingQueue[0],
                            hasVideo: !!guestState.video,
                            audioMuted: guestState.audioMuted,
                            finished: guestState.streaming === 0,
                        };
                    } else {
                        data.guest = null;
                    }
                }
                data.pending = state.pendingQueue[0];
            }

            if (state.outgoingInvite !== this.state.host.outgoingInvite && !state.attached) {
                if (state.outgoingInvite) {
                    if (this.state.pending) {
                        this.leaveGuest();
                        data.pending = null;
                    }
                    this.createGuestModel(state.outgoingInvite, false);
                    const guestState = this.guestModel.store.getState();
                    data.guest = {
                        id: state.outgoingInvite,
                        hasVideo: !!guestState.video,
                        audioMuted: guestState.audioMuted,
                        finished: guestState.streaming === 0,
                    };
                } else {
                    this.leaveGuest();
                    if (state.pendingQueue[0]) {
                        this.createGuestModel(state.pendingQueue[0], false);
                        const guestState = this.guestModel.store.getState();
                        data.guest = {
                            id: state.pendingQueue[0],
                            hasVideo: !!guestState.video,
                            audioMuted: guestState.audioMuted,
                            finished: guestState.streaming === 0,
                        };
                        data.pending = state.pendingQueue[0];
                    } else {
                        data.guest = null;
                    }
                }
            }
            this.setState(data);
        };

        updateGuest = state => {
            this.setState({
                guest: {
                    ...this.state.guest,
                    hasVideo: !!state.video,
                    finished: state.streaming === 0,
                    audioMuted: state.audioMuted,
                },
            });
        };

        mute = () => {
            if (this.state.host.id !== this.props.userId) {
                const muted = this.state.host.audioMuted;
                this.hostModel.actions.muteAudio(!muted);
                if (this.guestModel) {
                    this.guestModel.actions.muteAudio(!muted);
                }
            } else {
                const muted = this.state.host.microphoneMuted;
                this.hostModel.actions.muteMicrophone(!muted);
            }
        };

        updateHostRoom = state => {
            const lastMessage = state.messages[0];
            let lastMessageUrl = null;
            if (
                lastMessage &&
                lastMessage.meta.reference &&
                lastMessage.meta.reference.length > 0 &&
                lastMessage.meta.room === this.getId()
            ) {
                lastMessageUrl = url.parse(lastMessage.meta.reference);
            }

            const oldMessage = this.lastMessage;
            if (
                lastMessageUrl &&
                lastMessageUrl.protocol === 'cheer:' &&
                lastMessage.sender &&
                lastMessage.recent === true
            ) {
                if (!oldMessage || lastMessage.tag !== oldMessage.tag) {
                    this.lastMessage = lastMessage;
                    this.showGift(lastMessageUrl, lastMessage.sender);
                }
            } else {
                this.lastMessage = {};
            }
            this.setState({
                host: {
                    ...this.state.host,
                    diamonds: state.diamonds,
                },
            });
        };

        showGift = (url, sender) => {
            this.donationQueue.push({ url: url, sender: sender });
            if (!this.state.donation) {
                this.showNextGift();
            }
        };

        showNextGift = () => {
            this.setState({ donation: this.donationQueue[0] });
            this.donationQueue.shift();
            setTimeout(() => {
                if (this.donationQueue.length > 0) {
                    this.showNextGift();
                } else {
                    this.setState({ donation: null });
                }
            }, 5000);
        };

        attachStream = () => {
            if (this.props.id) {
                this.hostModel.store.unlisten(this.updateHost);
                this.hostRoomModel.store.unlisten(this.updateHostRoom);
                if (this.guestModel) {
                    this.guestModel.store.unlisten(this.updateGuest);
                }

                this.guestModel = this.hostModel;
                this.guestRoomModel = this.hostRoomModel;

                this.updatedHostId = this.props.userId;
                this.guestModel.store.listen(this.updateGuest);
                let currentState = this.state;
                currentState.guest = currentState.host;
                this.hostModel = this.context.flux.get(StreamModel, this.props.userId);
                this.hostRoomModel = this.context.flux.get(RoomModel, this.props.userId);
                this.hostModel.store.listen(this.updateHost);
                this.hostRoomModel.store.listen(this.updateHostRoom);
                const modelState = this.hostModel.store.getState();
                currentState.host = {
                    id: this.props.userId,
                    hasVideo: !!modelState.video,
                    audioMuted: modelState.audioMuted,
                    finished: false,
                    viewers: modelState.viewers,
                    attached: modelState.attached,
                    microphoneMuted: modelState.microphoneMuted,
                    outgoingInvite: this.props.id,
                };
                this.setState(currentState);
                this.startBroadcasting();
                this.hostModel.actions.sendStreamAttachmentRequest(this.props.id);
            }
        };

        onStreamAttach = attachedId => {
            this.hostModel.actions.sendStreamAttachmentRequest(attachedId);
        };

        accept = () => {
            this.hostModel.actions.accept(this.state.pending);
        };

        decline = () => {
            this.hostModel.actions.decline(this.state.pending);
        };

        endCoStream = () => {
            if (this.state.host.id === this.props.userId) {
                if (this.state.host.attached) {
                    this.hostModel.actions.decline(this.state.host.attached);
                } else if (this.state.host.outgoingInvite === this.state.guest.id) {
                    this.hostModel.actions.decline(this.state.host.outgoingInvite);
                }
            }
        };

        onShowProfile = () => {
            this.setProfileVisible(true);
        };

        onCloseProfilePressed = () => {
            this.setProfileVisible(false);
        };

        setProfileVisible(visible) {
            let profileVisible = !!visible;
            this.setState({
                controlsState: profileVisible ? CONTROLS_STATE.PROFILE : null,
            });
        }

        onShowPrivateChat = () => {
            this.setPrivateChatVisible(true);
        };

        onClosePrivateChatPressed = () => {
            this.setPrivateChatVisible(false);
        };

        setPrivateChatVisible(visible) {
            let chatVisible = !!visible;
            this.setState({
                controlsState: chatVisible ? CONTROLS_STATE.PRIVATE_CHAT : null,
            });
        }

        render() {
            const hasOutgoingPendingStreamRequest =
                (this.state.guest && this.state.host.outgoingInvite === this.state.guest.id) ||
                false;
            const guestStreamControlButtonsVisible =
                (this.state.guest &&
                    this.state.guest.id === this.state.pending &&
                    this.state.host.id === this.props.userId) ||
                false;

            const userIsStreamer = this.getId() === this.props.userId;

            let state = {};

            state.hostStream = this.state.host;
            state.guestStream = this.state.guest;
            state.id = this.getId();

            if (this.state.timeBeforeStart) {
                state.timeBeforeStart = this.state.timeBeforeStart;
            } else {
                state.donation = this.state.donation;

                switch (this.state.controlsState) {
                    case CONTROLS_STATE.PROFILE:
                        state.profileVisible = true;
                        state.profileId = this.getId();
                        state.onCloseProfilePress = this.onCloseProfilePressed;
                        state.onChatPress = this.onShowPrivateChat;
                        break;
                    case CONTROLS_STATE.PRIVATE_CHAT:
                        state.onClosePrivateChatPress = this.onClosePrivateChatPressed;
                        state.privateChatVisible = true;
                        break;
                }

                let showBottomControls =
                    !this.state.host.finished &&
                    ((userIsStreamer && this.state.host.hasVideo) || !userIsStreamer);

                state.showPublicChatLog =
                    showBottomControls && (!this.state.donation || userIsStreamer);
                state.sendDonationVisible = showBottomControls && !userIsStreamer;
                state.showPublicChatInput = showBottomControls && !userIsStreamer;

                if (this.props.privateChatPopupEnabled) {
                    state.onChatPress = this.onShowPrivateChat;
                }

                if (this.state.guest && !this.state.guest.finished) {
                    state.coStreamVisible = true;
                    if (userIsStreamer) {
                        const hasOutgoingPendingStreamRequest =
                            (this.state.guest &&
                                this.state.host.outgoingInvite === this.state.guest.id) ||
                            false;
                        const guestStreamControlButtonsVisible =
                            (this.state.guest &&
                                this.state.guest.id === this.state.pending &&
                                this.state.host.id === this.props.userId) ||
                            false;
                        if (guestStreamControlButtonsVisible) {
                            state.coStreamInviteVisible = true;
                            state.coStreamDeclineInvite = this.decline;
                            state.coStreamAcceptInvite = this.accept;
                        } else {
                            state.coStreamActiveControlsVisible = true;
                            state.coStreamActiveIsRequesting = hasOutgoingPendingStreamRequest;
                            state.endActiveCoStream = this.endCoStream;
                        }
                    }
                }
            }

            let { showProfile, ...props } = this.props;
            return (
                <Component
                    {...props}
                    {...state}
                    showProfile={this.onShowProfile}
                    id={this.getId()}
                    endCoStream={this.endCoStream}
                    attachStream={this.attachStream}
                    onStreamAttach={this.onStreamAttach}
                    userId={this.props.userId}
                    mute={this.mute}
                    messagesModel={this.state.messagesModel}
                    guestStreamControlButtonsVisible={guestStreamControlButtonsVisible}
                    hasOutgoingPendingStreamRequest={hasOutgoingPendingStreamRequest}
                    timeBeforeStart={this.state.timeBeforeStart}
                />
            );
        }
    }

    return withConfigValue(withIdentityId(StreamsScreenController), {
        privateChatPopupEnabled: 'stream-screen.private-chat-popup-enabled',
    });
}

export default CreateStreamsController;
