import React from 'react';
import PropTypes from 'prop-types';
import withConfigValue from '../../../components/config-value';

function createController(Component) {

    class Controller extends React.Component {
        static displayName = 'streaming.streams-topics-picking-bonus.controller';
        static propTypes = {
            bonusAmount: PropTypes.number,
            onComplete: PropTypes.func,
        };

        onPress = () => {
            // TODO: perform request to gain credits
            this.props.onComplete && this.props.onComplete();
        };

        render() {

            return (
                <Component
                    {...this.props}
                    onPress={this.onPress}
                />
            );

        }

    }

    return withConfigValue(Controller, { bonusAmount: 'bonuses.topics-picking-amount' });
}

export default createController;
