import React from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity, Image } from 'react-native';
import styles from './styles';
import Resources from '../../../../resources';
import { WINK_TYPES } from '@sdv/domain/dialogs.wink';
import withIdentityId from '../../../../models/identity/controller/id';
import withCanWink from '../../../../models/dialogs.wink/controller/can-wink';
import withWink from '../../../../models/dialogs.wink/controller/wink';

class WinkButton extends React.Component {
    static displayName = 'feed.view.list.item.wink-button';
    static propTypes = {
        id: PropTypes.string,
        isContact: PropTypes.bool,
        canWink: PropTypes.bool,
        openChat: PropTypes.func,
        wink: PropTypes.func
    };

    shouldComponentUpdate(nextProps) {
        return this.props.canWink !== nextProps.canWink || this.props.isContact !== nextProps.isContact;
    }

    onPressed = () => {
        const canSendWink = !this.props.isContact && this.props.canWink;
        if (canSendWink) {
            this.props.wink && this.props.wink(this.props.id, WINK_TYPES.SEND_INVITE);
        } else {
            this.props.openChat && this.props.openChat();
        }
    };

    render() {
        if (typeof this.props.canWink === 'undefined' || typeof this.props.isContact === 'undefined') {
            return null;
        }

        const canSendWink = !this.props.isContact && this.props.canWink;
        const icon = canSendWink
            ? Resources.images.winkIcon()
            : Resources.images.startChatIcon();

        const containerStyle = canSendWink
            ? styles.buttonContainer
            : styles.alreadyWinkedButtonContainer;

        return (
            <TouchableOpacity style={containerStyle} onPress={this.onPressed}>
                <Image source={icon} style={styles.buttonIcon} />
            </TouchableOpacity>
        )
    }
}

export default withIdentityId(
    withCanWink(
        withWink(
            WinkButton,
            {
                userIdPropName: 'identity',
                winkActionPropName: 'wink'
            }
        ),
        {
            winkType: WINK_TYPES.SEND_INVITE,
            userIdPropName: 'identity',
            attendeeIdPropName: 'id',
            canWinkPropName: 'canWink'
        }
    ),
    'identity'
);
