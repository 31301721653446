import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { View, Platform } from 'react-native';
import { WebView } from 'react-native-webview';

import styles from './styles';

export default class ScreenView extends PureComponent {
  static displayName = 'faq.screens.faq-details.view';

  static propTypes = {
    topic: PropTypes.object,
  };

  render() {
    const { topic } = this.props;

    return (
      <View style={styles.container}>
        <WebView
          style={styles.webView}
          source={
            Platform.OS === 'web'
              ? { uri: topic.localizedSource }
              : topic.localizedSource
          }
        />
      </View>
    );
  }
}
