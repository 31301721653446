import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 44,
    },
    image: {
        width: 24,
        height: 24,
        marginRight: 10,
    },
    diamondsView: {
        flexDirection: 'row',
        marginRight: 20,
        justifyContent: 'center',
        alignItems: 'center',
    },
    nameText: {
        fontFamily: '$brandFontFamily',
        fontSize: 16,
        color: '$primaryTextColor',
        fontWeight: 'bold',
    },
    diamondsCounterText: {
        fontFamily: '$brandFontFamily',
        color: '$primaryTextColor',
        fontSize: 16,
        marginRight: 5,
        fontWeight: '300',
    }
});

export default styles;
