import { createOptimisticActions } from '@sdv/model';

const TIMEOUT = 5000;

class AdminConfigActions extends createOptimisticActions(['patch', 'put']) {
    static displayName = 'config/page/admin';

    set(config) {
        return config;
    }

    patch(config) {

        return super.patch(config, function (config) {

            this.dispatch(config);
            setTimeout(() => this.commit(), TIMEOUT);

        });

    }

    put(config) {

        return super.put(config, function (config) {

            this.dispatch(config);
            setTimeout(() => this.revert(), TIMEOUT);

        });

    }

}

export default AdminConfigActions;
