import React from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity } from 'react-native';
import Photo from '../../../../../components/photo';
import styles from './styles';

export default class ChatRequestsItem extends React.Component {
    static displayName = 'dialogs.chats.chat-requests.item';
    static propTypes = {
        user: PropTypes.object,
        onPress: PropTypes.func
    };

    shouldComponentUpdate(nextProps) {
        const user = this.props.user || {};
        const nextUser = nextProps.user || {};
        return user.thumbnail !== nextUser.thumbnail || user.id !== nextUser.id;
    }

    onPressed = () => {
        this.props.onPress && this.props.onPress(this.props.user);
    };

    render() {
        const user = this.props.user || {};
        return (
            <TouchableOpacity onPress={this.onPressed}>
                <Photo
                    style={styles.photo}
                    userId={user.id}
                    basename={user.thumbnail}
                    placeholder={'avatar'}
                />
            </TouchableOpacity>
        );
    }
}
