import { map, distinctUntilChanged } from 'rxjs/operators';
import { singleton } from '@sdv/commons/utils/singleton';
import UserTags from '@sdv/domain/user/tags';
import { USER_TAGS } from '@sdv/domain/user/tags/user-tags';

export class UserIsCustomer {
  static shared = singleton(userId => new UserIsCustomer(userId));

  constructor(userId) {
    this.subscriptionRequiredForBoost = UserTags.shared()
      .tagsOf(userId)
      .pipe(
        map(
          tags =>
            !tags.includes(USER_TAGS.CUSTOMER) &&
            !tags.includes(USER_TAGS.CUSTOMER_EXPERIMENT),
        ),
        distinctUntilChanged(),
      );

    this.isCustomer = UserTags.shared()
      .tagsOf(userId)
      .pipe(
        map(tags => tags.includes(USER_TAGS.CUSTOMER)),
        distinctUntilChanged(),
      );
  }
}
