import UserActions from './actions';

function CreateRolesStore(id) {
    let externalRoles = [],
        internalRoles = [];

    class Store {

        constructor(flux) {
            const actions = flux.getActions(UserActions, id);

            this.state = {};

            this.bindAction(actions.get, this.replace);
            this.bindAction(actions.setInternal, this.setInternal);
            this.bindAction(actions.removeInternal, this.removeInternal);

            this.publicMethods = {
                isFilled() {
                    return Object.keys(this.state).length > 0;
                }
            };

        }

        replace(roles) {

            if (!Array.isArray(roles)) {
                return;
            }

            externalRoles = roles;

            this.setState({
                roles: [...externalRoles, ...internalRoles]
            });

        }

        setInternal(role) {
            const roles = (this.state.roles || []).slice();

            if (!roles.includes(role)) {
                internalRoles = internalRoles.concat(role);
            }

            this.setState({
                roles: [...externalRoles, ...internalRoles]
            });

        }

        removeInternal(role) {

            internalRoles = internalRoles.filter(item => item !== role);

            this.setState({
                roles: [...externalRoles, ...internalRoles]
            });

        }

    }

    Store.displayName = CreateRolesStore.getDisplayName(id);

    return Store;
}

CreateRolesStore.getDisplayName = function (id) {
    return `user-roles.${id}`;
};

export default CreateRolesStore;
