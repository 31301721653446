function CreateStreamFollowersActions(id) {
    class StreamFollowersActions {
        get = () => (dispatch, flux) => {
            flux.api.users.taggers.favorite.get(id, (err, res) => {
                if (res) {
                    dispatch(res.count || 0);
                } else {
                    dispatch(null);
                }
            });
        }
    }

    StreamFollowersActions.displayName = CreateStreamFollowersActions.getDisplayName(id);
    return StreamFollowersActions;
}

CreateStreamFollowersActions.getDisplayName = function(id) {
    return `dialogs-stream-followers.${id}`
};

export default CreateStreamFollowersActions;
