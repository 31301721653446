import { combineLatest } from 'rxjs';
import { map, filter, take } from 'rxjs/operators';
import StreamsList from '@sdv/domain/streaming/streams-list';
import StreamsTopics from '@sdv/domain/streams-topics';

const STREAMS_TO_PICK = 9;

export class StreamsAdviser {
    constructor(userId) {
        const streamsList = new StreamsList();
        const streamsTopics = StreamsTopics.shared(userId);

        this.streams = combineLatest(streamsList.subscription, streamsTopics.selectedTopics).pipe(
            filter(([{ streamsLoaded }]) => streamsLoaded),
            map(([{ streams, topicCategories }, topicsOfUser]) => {
                if (!topicsOfUser || !topicsOfUser.length) {
                    return streams;
                }

                const topics = Object.keys(topicCategories);

                return streams
                    .map((id, index, arr) => ({
                        id,
                        popularity: arr.length - index,
                        weight: topics
                            .filter(topic => topicCategories[topic].includes(id))
                            .reduce(
                                (acc, topic) => (topicsOfUser.includes(topic) ? acc + 1 : acc),
                                0,
                            ),
                    }))
                    .sort((streamA, streamB) => {
                        if (streamA.weight !== streamB.weight) {
                            return streamB.weight - streamA.weight;
                        }

                        return streamB.popularity - streamA.popularity;
                    })
                    .map(({ id }) => id);
            }),
            map(streams => streams.slice(0, STREAMS_TO_PICK)),
            take(1),
        );
    }
}
