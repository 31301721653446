import EStyleSheet from 'react-native-extended-stylesheet';
import { I18nManager, Platform } from 'react-native';

const styles = EStyleSheet.create({
  $hasButtonFeedback: true,

  '@media android': {
    $hasButtonFeedback: false,
  },

  container: {
    margin: 10,
    paddingTop: 35,
    paddingBottom: 24,
    borderRadius: 15,
    width: 160,
    flexDirection: 'column',
    backgroundColor: '$primaryBackgroundColor',
    borderWidth: 5,
    borderColor: 'transparent',
    shadowColor: 'black',
    shadowRadius: 4,
    shadowOpacity: 0.15,
    shadowOffset: { width: 0, height: 0 },
    '@media android': {
      elevation: 3,
    },
  },

  containerSelected: {
    borderColor: '$accentColor',
    shadowRadius: 0,
    shadowOpacity: 0,
    '@media android': {
      elevation: 0,
    },
  },

  containerDiscounted: Platform.select({ web: { paddingBottom: 19 } }),

  checkImage: {
    position: 'absolute',
    right: 0,
    top: 0,
    margin: 5,
  },

  discountBadge: {
    position: 'absolute',
    left: -15,
    top: -15,
    justifyContent: 'center',
    alignItems: 'center',
  },

  discountBadgeText: {
    position: 'absolute',
    transform: [{ rotate: I18nManager.isRTL ? '20deg' : '-20deg' }],
    fontSize: 14,
    fontFamily: '$brandFontFamily',
    color: '$brandTextColor',
    fontWeight: '500',
  },

  packageType: {
    fontSize: 22,
    fontFamily: '$brandFontFamily',
    color: '$primaryTextColor',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 16,
  },

  priceContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
  },

  price: {
    fontSize: 16,
    fontFamily: '$brandFontFamily',
    color: '$primaryTextColor',
    textAlign: 'center',
  },

  creditImg: {
    width: 20,
    marginLeft: 4,
  },

  introductoryPrice: {
    fontSize: 14,
    fontFamily: '$brandFontFamily',
    color: '$primaryTextColor',
    textAlign: 'center',
    marginBottom: 6,
  },

  priceSeparator: {
    fontSize: 11,
    fontFamily: '$brandFontFamily',
    color: '$primaryTextColor',
    textAlign: 'center',
    marginBottom: 5,
  },

  badgeContainer: {
    backgroundColor: '$accentColor',
    borderRadius: 12,
    padding: 2,
    marginHorizontal: 23,
    marginTop: 16,
  },

  badge: {
    fontSize: 13,
    fontFamily: '$brandFontFamily',
    color: '$brandTextColor',
    textAlign: 'center',
  },

  activatedBadge: {
    fontSize: 13,
    fontFamily: '$brandFontFamily',
    color: '#007aff',
    textAlign: 'center',
    fontWeight: '600',
    marginTop: 16,
  },
});

export default styles;
