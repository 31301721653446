import { singleton } from '@sdv/commons/utils/singleton';
import Resources from 'dating-mobile/src/resources';

const KEY = 'LANGUAGE';

export default class Language {
  static shared = singleton(() => new Language());

  constructor() {
    let savedLang = window.localStorage.getItem(KEY);

    if (savedLang) {
      Resources.strings.setLanguage(savedLang);
    } else {
      savedLang = Resources.strings.getLanguage();
      window.localStorage.setItem(KEY, savedLang);
    }

    this.current = savedLang;
  }

  setLanguage(lang) {
    Resources.strings.setLanguage(lang);
    window.localStorage.setItem(KEY, lang);
    this.current = lang;
  }
}
