import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'stretch'
    },

    list: {
        flex: 1,
    },

    itemSeparator: {
        margin: 10
    }
});

export default styles;
