import { singleton } from '@sdv/commons/utils/singleton';
// @ts-ignore
import MediaModel from 'dating-mobile/src/models/user.media/model';
// @ts-ignore
import flux from '@sdv/domain/app/flux';
// @ts-ignore
import UserModel from '../model';

export class UserThumbnailUpdating {
  static shared = singleton(userId => new UserThumbnailUpdating(userId));

  userId = '';

  media: any;

  user: any;

  constructor(userId: string) {
    this.userId = userId;
    this.media = flux.get(MediaModel, this.userId);
    this.user = flux.get(UserModel, this.userId);
  }

  setAsThumbnail = (basename: string): Promise<any> => {
    return new Promise<void>((resolve, reject) => {
      this.media.actions.removePhotoTag(basename, 'hidden', () => {
        this.media.actions.addPhotoTag(basename, 'thumbnail', (error: any) => {
          if (error) {
            reject(error);
          }

          this.user.actions.actualize({ 'thumbnail-pending': basename });
          resolve();
        });
      });
    }).then(() => {
      // TODO: when Singular starts supports promo users, it could be problem, as promoters can set several thumbnails.
      const mediaState = this.media.store.getState();
      const oldThumbnails = mediaState.media.filter(
        (item: any) =>
          item.basename !== basename &&
          item.tags &&
          item.tags.indexOf('thumbnail') >= 0,
      );

      return Promise.all(
        oldThumbnails.map(
          (item: any) =>
            new Promise<void>((resolve, reject) => {
              this.media.actions.removePhotoTag(
                item.basename,
                'thumbnail',
                (error: any) => {
                  if (error) {
                    reject(error);
                  }

                  resolve();
                },
              );
            }),
        ),
      );
    });
  };
}
