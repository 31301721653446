import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $statusBarStyle: '$primaryStatusBarStyle',
    $statusBarBackgroundColor: '$primaryBackgroundColor',

    container: {
        flex: 1,
        backgroundColor: '$primaryBackgroundColor',
    },
    diamondsView: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-evenly',
        width: '100%',
        height: 154,
        borderBottomWidth: 1,
        borderBottomColor: '$brandBorderColor'
    },
    fansText: {
        marginTop: 10,
        marginBottom: 7,
        alignSelf: 'center',
        textAlign: 'center',
        fontFamily: '$brandFontFamily',
        color: '$infoTextColor',
        fontSize: 14
    },
});

export default styles;
