import { defer, of } from 'rxjs';
import { singleton } from '@sdv/commons/utils/singleton';
import AppConfig from 'dating-mobile/src/app/config';
import Resources from 'dating-mobile/src/resources';

export class Config {
  static shared = singleton(() => new Config());

  constructor() {
    this.defaultProfileBoostTtl = AppConfig.shared().paramWithPath(
      'boost-profile.default-ttl',
    );

    this.preferencesLimitations = AppConfig.shared().paramWithPath('age-range');

    this.boostExtendOfferDuration = AppConfig.shared().paramWithPath(
      'boost-profile.extend-offer-duration',
    );

    this.boostExtendNotificationEnabled = AppConfig.shared().paramWithPath(
      'features.boost-extend-notification-enabled',
    );

    this.countryTaxes = AppConfig.shared().paramWithPath('countryTaxes');

    this.anonymousChatEnabled = AppConfig.shared().paramWithPath(
      'features.anonymous-using-enabled',
    );

    this.trialMessagesEnabled = AppConfig.shared().paramWithPath(
      'features.trial-messages-enabled',
    );

    this.feedAdsEnabled = AppConfig.shared().paramWithPath(
      'features.feed-ads-enabled',
    );

    this.streamsDiscoveryAdsEnabled = AppConfig.shared().paramWithPath(
      'features.streams-discovery-ads-enabled',
    );

    this.google = AppConfig.shared().paramWithPath('google');

    this.updateLocationOnNewSession = AppConfig.shared().paramWithPath(
      'features.update-location-on-new-session',
    );

    this.locationDetectionEnabled = AppConfig.shared().paramWithPath(
      'features.location-detection-enabled',
    );

    this.apiMonitoringEnabled = AppConfig.shared().paramWithPath(
      'features.api-monitoring-enabled',
    );

    this.userLanguage = defer(() => of(Resources.strings.getLanguage()));

    this.maleAnimators = AppConfig.shared().paramWithPath('animatorsMale');
    this.femaleAnimators = AppConfig.shared().paramWithPath('animatorsFemale');

    this.geoLocationAliasingEnabled = AppConfig.shared().paramWithPath(
      'features.geo-location-aliasing-enabled',
    );

    this.freeCheers = AppConfig.shared().paramWithPath('free-cheers.items');

    this.preloadStockPackagesEnabled = AppConfig.shared().paramWithPath(
      'features.preload-stock-packages-enabled',
    );

    this.unlimitedMessagesWithMembershipEnabled = AppConfig.shared().paramWithPath(
      'features.unlimited-messages-with-membership-enabled',
    );

    this.creditsForRegistration = AppConfig.shared().paramWithPath(
      'features.credits-for-registration',
    );
  }
}
