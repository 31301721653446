import React from 'react';
import PropTypes from 'prop-types';
import { Image, View, Text } from 'react-native';
import styles from './styles';
import Resources from 'dating-mobile/src/resources';
import configValue from 'dating-mobile/src/components/config-value';

class OnlineIndicator extends React.PureComponent {
    static displayName = 'component.online-indicator';
    static propTypes = {
        type: PropTypes.string,
        onlineValue: PropTypes.string,
        offlineValue: PropTypes.string,
        style: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.object,
            PropTypes.array
        ]),
        online: PropTypes.any,
        tags: PropTypes.array,
        devices: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string
        }))
    };

    render() {
        if (this.props.type === 'text') {
            const text = this.props.online
                ? (this.props.onlineValue || Resources.strings.online)
                : (this.props.offlineValue || Resources.strings.offline);
            const style = [this.props.online ? styles.onlineText : styles.offlineText, this.props.style];
            return (<Text {...this.props} style={style}>{text}</Text>);
        }

        const indicatorStyle = [
            styles.onlineIndicator,
            this.props.online ? styles.onlineModifier : styles.offlineModifier,
            this.props.style
        ];

        const iconIndicatorStyle = [
            styles.icon,
            this.props.online ? styles.iconOnlineModifier : styles.iconOfflineModifier,
            this.props.style
        ];

        const tags = this.props.tags || [];
        const devices = this.props.devices || [];

        const isMobileClient = devices.findIndex(item => item.name === 'mobileapp') >= 0 ||
            (!this.props.online && tags.indexOf('presence.mobileapp') >= 0);

        let indicator = isMobileClient
            ? (
                <Image
                    style={iconIndicatorStyle}
                    source={require('./assets/mobile_online_indicator_icon.png')}
                />
            )
            : (<View style={indicatorStyle} />);

        if (this.props.videoChatEnabled) {
            const cameraExist = devices.find(item => item.name === 'cam');
            const onlineWithCamera = this.props.online && !!cameraExist;

            indicator = onlineWithCamera
                ? <Image source={Resources.images.onlineWithCameraIcon()} style={this.props.style} />
                : indicator;
        }

        return indicator;
    }
}

export default configValue(OnlineIndicator, {
    videoChatEnabled: 'features.video-chat-enabled'
});
