import Actions from './actions';

function createAddressesStore(id = 'default') {

    class Store {

        /**
         *
         * @type {{
         *     emails: {
         *         ['email']: bool
         *     }
         * }}
         */
        state = {
            emails: {}
        };

        constructor(flux) {
            const actions = flux.getActions(Actions, id);
            this.bindAction(actions.validate, this.validate);
        };

        validate = (payload) => {
            if (!payload) {
                return;
            }

            this.setState({
                emails: {
                    ...this.state.emails,
                    [payload.email]: payload.valid
                }
            });
        };

    }

    Store.displayName = createAddressesStore.getDisplayName(id);

    return Store;
}

createAddressesStore.getDisplayName = function (id) {
    return `electronicmail-addresses.${id}`;
};

export default createAddressesStore;
