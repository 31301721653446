import MailboxStore from '../base/store';
import Actions from './actions';
import displayName from './display-name';

function createStore(id) {

    class Store extends MailboxStore {

        /**
         * @param {Alt} flux
         */
        constructor(flux) {
            super(flux, flux.getActions(Actions, id));

            this.eventInbox = flux.letterEventInbox;
            this.eventInbox.addOnLetterFromNonContactAddedListener(this.onLetterFromNonContactAdded.bind(this));
            this.eventInbox.addOnLetterChainAddedListener(this.onLetterChainAdded.bind(this));
        }

        onLetterChainAdded(chain) {
            if (this.letters.has(chain.payload.id)) {
                this.letters.delete(chain.payload.id);
                this.updateModel();
            }
        }

        onLetterFromNonContactAdded(letter) {
            if (letter.recipient === id) {
                this.addNewLetterOrderedByTime(letter);
                this.updateModel();
            }
        }

        onNewLetterAdded(letter) {
            if (letter.recipient === id && letter.introductory) {
                this.addNewLetterOrderedByTime(letter);
                this.updateModel();
            }
        }
    }

    Store.displayName = displayName(id);

    return Store;
}

createStore.getDisplayName = displayName;

export default createStore;
