import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    scroll: {
        flexGrow: 1
    },

    content: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start'
    }
});

export default styles;
