import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, TouchableOpacity, ActivityIndicator } from 'react-native';

import withImagePicker from 'dating-mobile/src/components/image-picker';
import userMedia from 'dating-mobile/src/models/user.media/controller';

import styles from './styles';

class PhotoAddView extends React.PureComponent {
    static displayName = 'user.media.add';

    static propTypes = {
        id: PropTypes.string.isRequired,
        style: PropTypes.object,
        loadingTint: PropTypes.string,
        addPhoto: PropTypes.func,
        addVideo: PropTypes.func,
        onLayout: PropTypes.func,
        onLoadingStart: PropTypes.func,
        onLoadingEnd: PropTypes.func,
    };

    state = {
        error: false,
        isLoading: false,
    };

    componentWillReceiveProps(props) {
        const uri = props.source && props.source.uri;

        this.uri = uri;
        const currentUri = this.props.source && this.props.source.uri;

        if (uri && uri !== currentUri) {
            this.addMedia(props.source).then();
        }
    }

    getErrorComponent() {
        return this.state.error ? (
            <View style={styles.errorContainer}>
                <Text style={styles.error}>{this.state.error}</Text>
            </View>
        ) : null;
    }

    async addMedia(source) {
        const { onLoadingStart, onLoadingEnd } = this.props;

        this.setState({ error: null, isLoading: true });

        if (onLoadingStart) {
            onLoadingStart();
        }

        try {
            const { mediaType, ...sourceForUpload } = source;

            switch (mediaType) {
                case 'photo':
                    await this.props.addPhoto(sourceForUpload);
                    break;
                case 'video':
                    await this.props.addVideo(sourceForUpload);
                    break;
            }
            this.setState({ isLoading: false });
        } catch (error) {
            this.setState({ error: error.message, isLoading: false });
        }

        if (onLoadingEnd) {
            onLoadingEnd();
        }
    }

    select = () => {
        this.props.select && this.props.select();
    };

    selectNewMedia = () => {
        this.props.takeNewMediaWithPermissionsRequest &&
            this.props.takeNewMediaWithPermissionsRequest();
    };

    selectFromGallery = () => {
        this.props.takeFromGalleryWithPermissionsRequest &&
            this.props.takeFromGalleryWithPermissionsRequest();
    };

    render() {
        return (
            <View style={styles.container}>
                <View style={this.state.isLoading ? { opacity: 0 } : {}}>
                    <TouchableOpacity onLayout={this.props.onLayout} onPress={this.select} style={this.props.style || {}}>
                        {this.props.children}
                    </TouchableOpacity>
                </View>

                {this.getErrorComponent()}

                {this.state.isLoading && (
                    <View style={styles.activityIndicatorContainer}>
                        <ActivityIndicator color={this.props.loadingTint} />
                        {!!this.props.progress && (
                            <Text
                                style={[
                                    styles.counter,
                                    this.props.loadingTint ? { color: this.props.loadingTint } : {},
                                ]}
                            >
                                {this.props.progress} %
                            </Text>
                        )}
                    </View>
                )}
            </View>
        );
    }
}

export default userMedia(withImagePicker(PhotoAddView));
