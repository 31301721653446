import MailboxActions from '../base/actions';
import displayName from './display-name';


function createActions(userId) {
    class Actions extends MailboxActions {

        constructor() {
            super(displayName(userId), userId, {
                read: false,
                replied: null,
                introductory: false
            });
        }
    }

    Actions.displayName = displayName(userId);

    return Actions;
}

createActions.getDisplayName = displayName;

export default createActions;
