import EStyleSheet from 'react-native-extended-stylesheet';

const textShadow = {
    textShadowColor: '$shadowColor',
    textShadowOffset: {
        width: -1,
        height: 1,
    },
    textShadowRadius: 5,
};

const styles = EStyleSheet.create({
    container: {
        flexShrink: 1,
    },
    image: {
        width: '100%',
        flexShrink: 1,
    },
    text: {
        ...textShadow,
        position: 'absolute',
        bottom: 5,
        fontFamily: '$brandFontFamily',
        color: '$brandColor',
        fontSize: 14,
        fontWeight: 'bold',
        left: 10,
    },
    overlay: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        position: 'absolute',
        backgroundColor: '#ffffff80',
        justifyContent: 'center',
        alignItems: 'center',
    },
    iconContainer: {
        height: 26,
        width: 26,
        backgroundColor: '$accentColor',
        borderRadius: 13,
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon: {
        tintColor: '$brandColor',
    },
});

export default styles;
