import EStyleSheet from 'react-native-extended-stylesheet';

const textStyle = {
    fontFamily: '$brandFontFamily',
    color: '$brandTextColor',
};

const styles = EStyleSheet.create({
    container: {
        width: '100%',
        height: '100%',
        backgroundColor: '$primaryBackgroundColor',
        justifyContent: 'space-between',
        flexGrow: 1,
        paddingHorizontal: 40,
    },

    subTitle: {
        ...textStyle,
        fontSize: 14,
    },

    title: {
        ...textStyle,
        fontWeight: 'bold',
        fontSize: 24,
        marginBottom: 15,
    },

    hint: {
        ...textStyle,
        fontSize: 14,
    },

    icon: {
        alignSelf: 'center',
    },

    buttonsContainer: {
        marginBottom: 40,
        alignItems: 'center',
    },

    turnOnButton: {
        marginBottom: 25,
    },

    turnOnButtonText: {
        fontWeight: 'normal',
    },

    notNowButton: {
        fontFamily: '$brandFontFamily',
        color: '$disabledTextColor',
        fontWeight: 'bold',
    },
});

export default styles;
