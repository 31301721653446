import React from 'react';
import PropTypes from 'prop-types';

import PaidResourceModel, { RESOURCE_ACCESS_STATUS, getId as getPaidResourceModelId } from '../../paid-resource/model';
import identityId from '../../identity/controller/id';
import hasTag from '../../user.tags/controllers/has-tag';

import Navigator from 'dating-mobile/src/routing/navigator';
import BalanceRefiller, { PAYMENT_REASON } from '../../../payment/utils/balance-refiller';
import { userPhotoPath, userVideoPath } from '../../../resources/remote';

function createControlledComponent(Component) {

    class ControlledComponent extends React.Component {
        static displayName = 'gallery.content.controller';
        static contextTypes = {
            flux: PropTypes.object
        };
        static propTypes = {
            id: PropTypes.string.isRequired,
            identity: PropTypes.string,
            media: PropTypes.shape({
                basename: PropTypes.string.isRequired,
                tags: PropTypes.tags,
                mediatype: PropTypes.string.isRequired
            }).isRequired,
            membershipIsInactive: PropTypes.bool
        };

        mounted = false;

        constructor(props, context) {
            super(props, context);

            this.componentWillReceiveProps(props);
        }

        componentDidMount() {
            if (this.paidResourceModel) {
                this.paidResourceModel.store.listen(this.onResourceAccessStatusChanged);
                if (this.modelState.status === RESOURCE_ACCESS_STATUS.UNKNOWN || this.modelState.status === RESOURCE_ACCESS_STATUS.PAYMENT_REQUIRED) {
                    this.paidResourceModel.actions.access();
                }
            }
            this.mounted = true;
        }

        componentWillUnmount() {
            if (this.paidResourceModel) {
                this.paidResourceModel.store.unlisten(this.onResourceAccessStatusChanged);
            }
            this.mounted = false;
        }

        componentWillReceiveProps(nextProps) {
            const isPrivate = nextProps.media.tags && nextProps.media.tags.indexOf('hidden') >= 0;
            const isImage = nextProps.media.mediatype?.startsWith('image');
            const resourcePath = isImage
                ? userPhotoPath(nextProps.id, nextProps.media.basename, isPrivate)
                : userVideoPath(nextProps.id, nextProps.media.basename);

            if (
                !nextProps.identity ||
                this.props.identity !== nextProps.identity ||
                nextProps.id === nextProps.identity ||
                (this.modelState && this.modelState.resourcePath !== resourcePath)
            ) {
                if (this.paidResourceModel) {
                    this.paidResourceModel.store.unlisten(this.onResourceAccessStatusChanged);
                    this.paidResourceModel = null;
                }
            }

            if (
                typeof nextProps.membershipIsInactive === 'boolean' &&
                typeof this.props.membershipIsInactive === 'boolean' &&
                this.props.membershipIsInactive !== nextProps.membershipIsInactive
            ) {
                this.paidResourceModel && this.paidResourceModel.actions.access();
            }

            const isPaid = nextProps.id !== nextProps.identity && (!isImage || isPrivate);
            let status;

            if (isPaid) {
                if (!this.paidResourceModel) {
                    this.paidResourceModel = this.context.flux.get(PaidResourceModel, getPaidResourceModelId(nextProps.identity, resourcePath));
                    if (this.mounted) {
                        this.paidResourceModel.store.listen(this.onResourceAccessStatusChanged);
                        const resourceStatus = this.paidResourceModel.store.getState().status;
                        if (resourceStatus === RESOURCE_ACCESS_STATUS.PAYMENT_REQUIRED || resourceStatus === RESOURCE_ACCESS_STATUS.UNKNOWN) {
                            this.paidResourceModel.actions.access();
                        }
                    }
                }
                status = this.paidResourceModel.store.getState().status;
            } else {
                status = nextProps.identity ? RESOURCE_ACCESS_STATUS.AVAILABLE : RESOURCE_ACCESS_STATUS.UNKNOWN
            }

            const available = status === RESOURCE_ACCESS_STATUS.AVAILABLE;
            let path;
            if (status !== RESOURCE_ACCESS_STATUS.UNKNOWN) {
                path = isImage
                    ? userPhotoPath(nextProps.id, nextProps.media.basename, isPrivate, !available)
                    : userVideoPath(nextProps.id, nextProps.media.basename, true);
            } else {
                path = null;
            }

            this.modelState = {
                resourcePath: resourcePath,
                path: path,
                status: status
            };
        }

        onPlayButtonPressed = () => {
            if (!this.props.media.mediatype?.startsWith('video') ||
                (this.modelState.status !== RESOURCE_ACCESS_STATUS.AVAILABLE &&
                this.modelState.status !== RESOURCE_ACCESS_STATUS.PAYMENT_REQUIRED)) {
                return;
            }

            const navigate = () => {
                Navigator.showVideo({
                    path: this.modelState.resourcePath
                });
            };

            if (this.modelState.status === RESOURCE_ACCESS_STATUS.AVAILABLE) {
                navigate();
            } else {
                this.paidResourceModel.actions.buy((error) => {
                    if (error) {
                        if (error.status === 402) {
                            BalanceRefiller.refillBalance(PAYMENT_REASON.WATCH_VIDEO, this.onPlayButtonPressed);
                        }
                        return;
                    }

                    navigate();
                });
            }
        };

        onResourceAccessStatusChanged = (state) => {

            if (this.modelState.status !== state.status) {
                const available = state.status === RESOURCE_ACCESS_STATUS.AVAILABLE;

                const isPrivate = this.props.media.tags && this.props.media.tags.indexOf('hidden') >= 0;

                const path = this.props.media.mediatype?.startsWith('image')
                    ? userPhotoPath(this.props.id, this.props.media.basename, isPrivate, !available)
                    : userVideoPath(this.props.id, this.props.media.basename, true);

                this.modelState = {
                    ...this.modelState,
                    status: state.status,
                    path: path
                };
                this.mounted && this.forceUpdate();
            }

        };

        onSubscribeButtonPressed = () => {
            BalanceRefiller.subscribe(PAYMENT_REASON.VIEW_PRIVATE_PHOTO);
        };

        render() {
            return (
                <Component
                    {...this.props}
                    {...this.modelState}
                    onPlayButtonPress={this.onPlayButtonPressed}
                    onSubscribeButtonPress={this.onSubscribeButtonPressed}
                />
            )
        }

    }

    return identityId(
        hasTag(
            ControlledComponent,
            {
                tag: 'membership',
                userIdPropName: 'identity',
                hasTagPropName: 'membershipIsInactive'
            }
        ),
        'identity'
    );
}

export default createControlledComponent;
