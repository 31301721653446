/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';

import User from '../model';

const UserHocController = Component => {
  class UserComponent extends React.Component {
    static contextTypes = {
      flux: PropTypes.object,
    };

    static propTypes = {
      id: PropTypes.string,
    };

    constructor(props, context) {
      super(props);
      this.flux = context.flux;
      this.bus = context.bus;
      this.model = this.flux.get(User, props.id);
      this.state = this.model.store.getState() || { roles: [] };
    }

    componentDidMount() {
      this.model.store.listen(this.updateState);
      if (this.props.id && !this.model.store.isFilled())
        this.model.actions.get();
    }

    componentDidUpdate(prevProps) {
      if (prevProps.id !== this.props.id) {
        this.model.store.unlisten(this.updateState);
        this.resetState(() => {
          this.model = this.flux.get(User, this.props.id);
          this.model.store.listen(this.updateState);
          this.setState(this.model.store.getState() || { roles: [] }, () => {
            if (this.props.id && !this.model.store.isFilled())
              this.model.actions.get();
          });
        });
      }
    }

    componentWillUnmount() {
      this.model.store.unlisten(this.updateState);
    }

    updateState = newState => {
      this.setState(newState);
    };

    render() {
      return (
        <Component
          key={this.props.id}
          {...this.props}
          roles={this.state.roles}
        />
      );
    }
  }

  UserComponent.displayName = 'user.roles.controller';

  return UserComponent;
};

export default UserHocController;
