import { combineLatest, Subject, merge } from 'rxjs';
import { distinctUntilChanged, map, mapTo, switchMap } from 'rxjs/operators';
import { singleton } from '@sdv/commons/utils/singleton';
// eslint-disable-next-line import/no-unresolved
import { Persistence } from '@sdv/domain/app/persistence';
import { Config } from '@sdv/domain/app/config';
import { CurrentScreen } from 'dating-mobile/src/services/functional/current-screen';
import { FEED } from 'dating-mobile/src/routing/router/constants';

export class RegistrationBonus {
  static shared = singleton(userId => new RegistrationBonus(userId));

  constructor(userId) {
    const allowedScreens = [FEED];
    const bonusShowedKey = 'bonus-showed';
    const persistence = Persistence.shared('registration-bonus', userId);

    this.bonusShowed = new Subject();

    const bonusShowed = merge(
      persistence.load(bonusShowedKey),
      this.bonusShowed.pipe(
        mapTo(true),
        switchMap(showed =>
          persistence.store(bonusShowedKey, showed).pipe(map(() => showed)),
        ),
      ),
    );

    const isUserOnAllowedScreen = CurrentScreen.shared().screen.pipe(
      map(screen => allowedScreens.includes(screen)),
      distinctUntilChanged(),
    );

    this.creditsBonus = Config.shared().creditsForRegistration;

    this.shouldShowBonus = combineLatest(
      isUserOnAllowedScreen,
      bonusShowed,
      this.creditsBonus,
    ).pipe(
      map(
        ([userOnAllowedScreen, isBonusShowed, credits]) =>
          userOnAllowedScreen && !isBonusShowed && !!credits,
      ),
      distinctUntilChanged(),
    );
  }

  userBonusShowed = () => this.bonusShowed.next(true);
}
