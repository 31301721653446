/**
 * Приводит названия полей объекта к camelCase.
 */
//TODO кажется что функции convertFromApi и format выполняют одну и туже задачу
import type from '@sdv/commons/utils/type';
import { diff as diffArrays } from './compare';

function camelCase(str) {
    let newStr = str[0].toLowerCase() + str.slice(1); // 'UserId' to 'userId'
    return newStr.replace(/(-\w)/g, function (str) { return str[1].toUpperCase(); }); // 'user-id' to 'userId'
}

function mapObj(obj, fn) {

    if (!type.isObject(obj)) {
        return obj;
    }

    Object.keys(obj).forEach(function (key) {
        let pair = [key, obj[key]],
            newPair = fn.apply(null, pair);

        delete obj[key];

        obj[newPair[0]] = mapObj(newPair[1], fn);
    });

    return obj;
}

function format(obj) {

    return mapObj(obj, function (key, value) {
        return [camelCase(key), value];
    });

}

function rename(data, format) {
    let newObject,
        key;

    if (!Array.isArray(data) && !type.isObject(data)) {
        return data;
    }

    newObject = Array.isArray(data)? []: {};

    for (key in data) {

        if (data.hasOwnProperty(key)) {

            if (Array.isArray(data)) {
                newObject.push(rename(data[key], format));
            } else {
                newObject[format(key)] = rename(data[key], format);
            }

        }

    }

    return newObject;
}

function convertToApi(data) {

    return rename(JSON.parse(JSON.stringify(data)), function (value) {

        return value.split('').reduce(function (result, symbol, index) {

            if (symbol.toLowerCase() !== symbol && index !== 0) {
                return result + '-' + symbol.toLowerCase();
            }

            return result + symbol.toLowerCase();
        }, '');

    });

}

function convertFromApi(data) {

    return rename(data, function (value) {

        return value.split('').reduce(function (result, symbol, index) {

            if (value[index - 1] === '-') {
                return result;
            }

            if (symbol === '-' && value[index + 1]) {
                return result + value[index + 1].toUpperCase();
            }

            return result + symbol;
        }, '');

    });

}


function diff(prev, now) {
    let changes = {},
        prop,
        temp;

    if (!prev && now) {
        return now;
    }

    if (typeof now !== 'object' || now === null) {
        return now === prev ? null : now;
    }

    for (prop in now) {

        if (now.hasOwnProperty(prop) && (!prev || prev[prop] !== now[prop])) {
            temp = null;

            if (prev[prop] === undefined) {
                changes[prop] = now[prop];
            } else if (typeof now[prop] === 'object' && !Array.isArray(now[prop])) {
                temp = diff(prev[prop], now[prop]);
                temp && (changes[prop] = temp);
            } else {

                if (Array.isArray(now[prop]) && (prev[prop].length || now[prop].length)) {
                    temp = diffArrays(prev[prop], now[prop]);
                }

                (!Array.isArray(now[prop]) || temp) && (changes[prop] = now[prop]);

            }

        }

    }

    for (prop in changes) {

        if (changes.hasOwnProperty(prop)) {
            return changes;
        }

    }

    return null;
}

function mixin() {
    let args = Array.prototype.slice.call(arguments),
        ret = {},
        item;

    if (args.length === 0) {
        return null;
    }

    while (args.length) {
        item = args.shift() || {};

        for (let key in item) {
            ret[key] = item[key];
        }

    }

    return ret;
}

export { format, diff, mixin, convertToApi, convertFromApi };
