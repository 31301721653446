import { makeNavigationItem } from 'dating-mobile/src/components/navigation-item';
import Resources from 'dating-mobile/src/resources';
import { CHANGE_EMAIL } from 'dating-mobile/src/routing/router/constants';

export const ChangeEmailNavigationItem = makeNavigationItem({
  route: CHANGE_EMAIL,
  routeParamsFromProps: props => ({
    userId: props.userId,
  }),
  title: Resources.strings['change-email-navigation-item-title'],
});
