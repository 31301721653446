import EStylesSheet from 'react-native-extended-stylesheet';
import { I18nManager, Platform } from 'react-native';

const isWeb = Platform.OS === 'web';

const styles = EStylesSheet.create({
  imageContainer: {
    width: '100%',
    aspectRatio: isWeb ? 0.25 : 3.955,
    justifyContent: 'center',
  },

  image: {
    transform: [
      {
        scaleX: I18nManager.isRTL ? -1 : 1,
      },
    ],
  },

  textContainer: {
    paddingHorizontal: 16,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },

  subtitle: {
    color: '$accentTextColor',
    fontFamily: '$brandFontFamily',
    fontSize: 12,
    marginTop: 5,
    minWidth: 200,
    textAlign: 'center',
  },

  button: {
    backgroundColor: '#ff623e',
    paddingHorizontal: 20,
    minWidth: 200,
    height: 40,
  },

  buttonText: {
    color: '$accentTextColor',
    fontFamily: '$brandFontFamily',
    fontSize: 17,
    fontWeight: 'normal',
  },

  background: {
    backgroundColor: '#fff',
    position: 'absolute',
    top: 0,
    width: '100%',
    height: isWeb ? 25 : 20,
    zIndex: -1,
  },

  gradient: {
    position: 'relative',
    top: isWeb ? -25 : -16,

    width: '100%',
    height: 40,
    zIndex: -1,
  },
});

export default styles;
