import RoomModel from '../../model';
import { parseId } from './actions';
import { MESSAGE_TYPES, DELIVERY_STATUS, parseContent } from '../../../common.messages/model';

function createMessagesStore(id) {

    const params = parseId(id);

    class MessagesStore {

        constructor(flux) {
            this.model = flux.get(RoomModel, params.room);
            this.model.store.listen(this.updateState);
            this.state = {
                messages: this.adaptMessages(this.model.store.getState())
            };
        }

        updateState = (state) => {
            this.state.messages = this.adaptMessages(state);
            this.emitChange();
        };

        adaptMessages = (state) => {
            const fans = state.cheers.reduce((map, cheer) => {
                map[cheer.user] = cheer;
                return map;
            }, {});
            return state.messages.map(message => {
                const item = {
                    tag: message.tag,
                    sender: message.sender,
                    recipient: message.recipient,
                    outgoing: message.sender === params.identity,
                    timestamp: message.timestamp,
                    placeholder: message.text,
                    status: DELIVERY_STATUS.DELIVERED,
                    read: true,
                    starred: message.sender === params.room || !!fans[message.sender]
                };
                const { type, content } = parseContent(message.text, message.meta, [
                    MESSAGE_TYPES.TEXT,
                    MESSAGE_TYPES.CHEER,
                    MESSAGE_TYPES.FAVORITE,
                    MESSAGE_TYPES.NUDITY_WARNING,
                    MESSAGE_TYPES.DELETED,
                    MESSAGE_TYPES.SYSTEM,
                ]);
                item.type = type;
                item.content = content;
                return item;
            });
        };

    }

    MessagesStore.displayName = createMessagesStore.getDisplayName(id);

    return MessagesStore;
}

createMessagesStore.getDisplayName = function(id) {
    return `dialogs-rooms-messages-adapter.${id}`;
};

export default createMessagesStore;
