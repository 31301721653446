import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  shareButtonContainer: {
    alignSelf: 'center',
    width: undefined,
    minWidth: 170,
    marginBottom: 24,
  },

  shareButton: {
    paddingVertical: 5,
    paddingHorizontal: 20,
    gradientColor: ['#00c8b6', '#34c342'],
  },

  shareButtonTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

  shareButtonText: {
    padding: 4,
    fontFamily: '$brandFontFamily',
    color: '$brandTextColor',
    fontSize: 16,
    fontWeight: '500',
    textAlign: 'center',
  },

  shareIcon: {
    marginHorizontal: 5,
  },
});

export default styles;
