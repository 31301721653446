import { merge } from 'rxjs';
import { map, distinctUntilChanged, filter, switchMap, take, mapTo, tap } from 'rxjs/operators';
import { singleton } from '@sdv/commons/utils/singleton';
import flux from '@sdv/domain/app/flux';
import { UserBlocking } from '@sdv/domain/user/user-blocking';
import UserTagsModel, { getId } from 'dating-mobile/src/models/user.tags.targeted/model';
import Messages from '@sdv/domain/dialogs.messages';
import { TargetedTags } from '@sdv/domain/user/tags/user-tags';

export class DialogApproval {
    static shared = singleton((user, attendee) => new DialogApproval(user, attendee));

    constructor(user, attendee) {
        this.user = user;
        this.attendee = attendee;
        this.userTagsModel = flux.get(UserTagsModel, getId(user, attendee));
        this.messagesModel = flux.get(Messages, getId(user, attendee));

        const dialogIsApproved = this.userTagsModel.store.rxState().pipe(
            map(state => !state[TargetedTags.ChatUnapproved]),
            distinctUntilChanged(),
        );

        const hasOutgoingMessages = dialogIsApproved.pipe(
            filter(approved => !approved),
            switchMap(() => this.messagesModel.store.rxState()),
            filter(({ messages }) => messages.some(({ sender }) => sender === user)),
            take(1),
            mapTo(true),
            tap(() => this.continueChat()),
        );

        this.dialogIsApproved = merge(dialogIsApproved, hasOutgoingMessages).pipe(
            distinctUntilChanged(),
        );
    }

    continueChat() {
        this.userTagsModel.actions.delete(TargetedTags.ChatUnapproved);
    }

    reportUser() {
        UserBlocking.shared(this.user)
            .blockUser(this.attendee)
            .subscribe();
    }
}
