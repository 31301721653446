import { get, set, last } from 'lodash';
import type from '@sdv/commons/utils/type';
import { singleton } from '@sdv/commons/utils/singleton';
import { ReplaySubject } from 'rxjs';
import { filter, take, scan, map, distinctUntilChanged, startWith } from 'rxjs/operators';
import { Config } from '@sdv/domain/app/config';

import api from './index';

const MAX_CONSECUTIVE_ERRORS = 5;

export class ApiMonitoring {
    static shared = singleton(() => new ApiMonitoring());

    constructor() {
        this.errors = new ReplaySubject(MAX_CONSECUTIVE_ERRORS);

        this.hasBadConnectionToServer = this.errors.pipe(
            scan((acc, error) => (error ? acc + 1 : 0), 0),
            map(consecutiveErrors => consecutiveErrors >= MAX_CONSECUTIVE_ERRORS),
            startWith(false),
            distinctUntilChanged(),
        );

        Config.shared()
            .apiMonitoringEnabled.pipe(
                filter(Boolean),
                take(1),
            )
            .subscribe(this.decorateApi);
    }

    decorateApi = () => {
        this.functionsPathsRecursiveSearch(api).forEach(path => this.decorate(api, path));
    };

    functionsPathsRecursiveSearch(object, result = [], path = []) {
        if (!type.isObject(object)) {
            return result;
        }

        return Object.entries(object).reduce((acc, [key, value]) => {
            const currentPath = [...path, key];

            if (type.isFunction(value)) {
                if (Object.keys(value).length) {
                    return this.functionsPathsRecursiveSearch(
                        value,
                        [...acc, currentPath],
                        currentPath,
                    );
                }

                return [...acc, currentPath];
            }

            if (type.isObject(value)) {
                return this.functionsPathsRecursiveSearch(value, acc, currentPath);
            }

            return acc;
        }, result);
    }

    decorate(object, path) {
        const fn = get(object, path);
        const { errors } = this;

        return type.isFunction(fn)
            ? set(
                  object,
                  path,
                  Object.assign(function(...args) {
                      const cb = last(args);

                      if (type.isFunction(cb)) {
                          return fn.call(
                              this,
                              ...args.slice(0, -1),
                              Object.defineProperty(
                                  function(...cbArgs) {
                                      errors.next(cbArgs[0]);

                                      return cb.apply(this, cbArgs);
                                  },
                                  'length',
                                  { value: cb.length },
                              ),
                          );
                      }

                      return fn.apply(this, args);
                  }, fn),
              )
            : object;
    }
}
