import React from 'react';
import Resources from 'dating-mobile/src/resources';
import PropTypes from 'prop-types';
import Badge from '../badge';
import '@sdv/commons/rx/store';

export default class UserVipBadge extends React.PureComponent {
  static displayName = 'user.common.views.user-badges.user-vip-badge';

  static propTypes = {
    isVisible: PropTypes.bool.isRequired,
    isPremium: PropTypes.bool,
  };

  render() {
    const { isVisible, isPremium } = this.props;
    const icon = isPremium
      ? Resources.images.superVipBadgeIcon()
      : Resources.images.vipBadgeIcon();

    return isVisible ? (
      <Badge
        icon={icon}
        text={Resources.strings['user-badge-vip-member-title']}
      />
    ) : null;
  }
}
