import Actions from './actions';

import type from '@sdv/commons/utils/type';
import merge from 'merge/merge';

function CreateStore(id) {
  class Store {
    constructor(flux) {
      const actions = flux.getActions(Actions, id);
      this.state = { files: [] };
      this.bindAction(actions.post, this.actualize);
    }

    actualize(payload) {
      if (type.isNumber(payload)) {
        return this.setState({
          files: [],
          progress: payload,
        });
      }

      return this.setState({
        files: payload || [],
        progress: null,
      });
    }
  }

  Store.post = function(oldState, newState) {
    return merge(oldState, newState);
  };

  Store.displayName = CreateStore.getDisplayName(id);

  return Store;
}

CreateStore.getDisplayName = id => {
  return `uploader.${id}`;
};

export default CreateStore;
