import React from 'react';
import PropTypes from 'prop-types';
import { ScrollView } from 'react-native';
import { SafeAreaView } from 'react-navigation';
import IfConfigValue from 'dating-mobile/src/components/config-value/if';
import { CustomerSupportNavigationItem } from 'dating-mobile/src/user/views/customer-support-navigation-item';
import { HideUserFromFeedSwitchItem } from 'dating-mobile/src/user/views/user-visibility-in-feed';
import { ChangeEmailNavigationItem } from 'dating-mobile/src/user/views/change-email-navigation-item';
import { ChangePasswordNavigationItem } from 'dating-mobile/src/user/views/change-password-navigation-item';
import { RestorePurchasesNavigationItem } from 'dating-mobile/src/user/views/restore-purchases-navigation-item';
import { BlockListNavigationItem } from 'dating-mobile/src/user/views/block-list-navigation-item';
import Resources from 'dating-mobile/src/resources';
import styles from './styles';
import testId from '../../../utils/test-id';
import NavigationItem from '../../../components/navigation-item';

export default class AccountSettings extends React.Component {
  static displayName = 'user.screens.account-settings.view';

  static propTypes = {
    userId: PropTypes.string,
    onLogoutButtonPress: PropTypes.func,
  };

  render() {
    const { userId, onLogoutButtonPress } = this.props;

    return (
      <SafeAreaView
        forceInset={styles.$safeAreaInsets}
        style={styles.container}
      >
        <ScrollView style={styles.scroll}>
          <IfConfigValue
            path="features.anonymous-using-enabled"
            equalsTo={true}
          >
            <HideUserFromFeedSwitchItem userId={userId} />
          </IfConfigValue>
          <ChangeEmailNavigationItem userId={userId} />
          <ChangePasswordNavigationItem userId={userId} />
          <BlockListNavigationItem userId={userId} />
          <CustomerSupportNavigationItem
            pinIcon={Resources.images.supportIcon()}
            icon={undefined}
          />
          <RestorePurchasesNavigationItem
            pinIcon={Resources.images.restorePurchasesIcon()}
            pinVisible
            icon={undefined}
          />
          <NavigationItem
            title={Resources.strings['account-screen-logout-button-title']}
            onPress={onLogoutButtonPress}
            {...testId('Logout button')}
          />
        </ScrollView>
      </SafeAreaView>
    );
  }
}
