import { Platform } from 'react-native';

export default function testId(id) {
    return Platform.select({
        'ios': {
            testID: id
        },
        'android': {
            accessibilityLabel: id
        }
    });
}