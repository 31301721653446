import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
    },

    list: {
        flex: 1,
        paddingHorizontal: 7,
        '@flavor tubit': {
            paddingHorizontal: 0,
            marginHorizontal: -1,
        },
    },

    placeholderContainer: {
        flex: 1,
        alignItems: 'stretch',
        flexDirection: 'column',
        paddingHorizontal: 40,
    },

    placeholderDescriptionText: {
        color: '$primaryTextColor',
        marginTop: 60,
        fontSize: 16,
        textAlign: 'center',
        fontWeight: '500',
        fontFamily: '$brandFontFamily',
        '@flavor lovinga': {
            fontWeight: 'normal',
        },
    },

    placeholderMotivationText: {
        alignSelf: 'center',
        color: '$primaryTextColor',
        textAlign: 'center',
        fontSize: 24,
        marginTop: 72,
        lineHeight: 28,
        fontWeight: 'bold',
        fontFamily: '$brandFontFamily',
    },

    placeholderButton: {
        marginTop: 24,
    },

    titleContainer: {
        position: 'absolute',
        width: '100%',
        top: 0,
        backgroundColor: '$primaryBackgroundColor',
        flexDirection: 'column',
        alignItems: 'stretch',
        paddingHorizontal: 7,
        '@flavor tubit': {
            paddingHorizontal: 0,
        },
    },

    startStreamButton: {
        position: 'absolute',
        bottom: 16,
        right: 16,
    },

    itemContainer: {
        width: '50%',
    },
    emptyFooter: {
        height: 74,
    },
    newsletterButton: {
        bottom: 0,
        position: 'absolute',
        alignSelf: 'center',
    },
});

export default styles;
