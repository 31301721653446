import React from 'react';
import PropTypes from 'prop-types';
import EventEmitter from 'eventemitter3';
import {
  FlatList,
  Platform,
  Text,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { Subscription } from 'rxjs';
import equal from 'fast-deep-equal';
import { connectActionSheet } from '@expo/react-native-action-sheet';
import { TARGETED_TAGS } from '@sdv/domain/user/tags/user-tags';
import { MessagesReSender } from 'dating-mobile/src/services/functional/dialogs-messages-re-sender';
import Message from '../message';
import styles from './styles';
import FastAnswerPanel from './fast-answer';
import PaymentRequired from './payment-required';
import BoostMessage from './boost-message';
import CurrencyBasedText from '../../../../components/currency/currency-based-text';
import Resources from '../../../../resources';
import {
  DELIVERY_STATUS,
  MESSAGE_TYPES,
} from '../../../../models/common.messages/model';
import { ReceivedThroughBoostBanner } from './received-through-boost-banner';

const HEADER_TYPE = 'generated.messages.log.header';
const FOOTER_TYPE = 'generated.messages.log.footer';
const HEADER_KEY = 'generated.messages.log.header.key';
const SEPARATOR_HEIGHT = 20;

function keyExtractor(item) {
  if (item.type === HEADER_TYPE) {
    return HEADER_TYPE;
  }

  if (item.type === FOOTER_TYPE) {
    return FOOTER_TYPE;
  }

  return String(item.tag);
}

function getMessages(failedMessages = {}, allMessages = [], attendee) {
  if (!attendee) {
    return allMessages;
  }

  const failedMessagesToRecipient = Object.values(failedMessages).filter(
    ({ recipient }) => recipient === attendee,
  );

  if (failedMessagesToRecipient.length) {
    const uniqMessages = allMessages.filter(({ tag }) => !failedMessages[tag]);

    return [...failedMessagesToRecipient, ...uniqMessages].sort(
      (a, b) => b.timestamp - a.timestamp,
    );
  }

  return allMessages;
}

class Log extends React.Component {
  static displayName = 'messages.log';

  static propTypes = {
    bus: PropTypes.object,
    onPress: PropTypes.func,
    MessageComponent: PropTypes.object,
    MessageSeparatorComponent: PropTypes.object,
    messages: PropTypes.array,
    placeholder: PropTypes.node,
    allMessagesLoaded: PropTypes.bool,
    paymentRequired: PropTypes.bool,
    subscribeRequired: PropTypes.bool,
    fastAnswerVariants: PropTypes.arrayOf(PropTypes.string),
    onMessageShow: PropTypes.func,
    boostingMessagesTags: PropTypes.arrayOf(PropTypes.string),
    boostMessage: PropTypes.func,
    boostMessagePrice: PropTypes.number,
    tags: PropTypes.object,
    anonymousUsingEnabled: PropTypes.bool,
    isPaidChat: PropTypes.bool,
    attendee: PropTypes.string,
    user: PropTypes.string,
    quickRepliesVisible: PropTypes.bool,
    creditsEnabledForCheers: PropTypes.bool,
    onQuickRepliesClose: PropTypes.func,
    maxFreeMessagesPerDay: PropTypes.number,
    reSendDelay: PropTypes.number,
    cheersSoundInChatEnabled: PropTypes.bool,
    showActionSheetWithOptions: PropTypes.func,
  };

  refreshTimers = {};

  itemHeights = [];

  constructor(props) {
    super(props);

    this.bus = props.bus || new EventEmitter();
    this.lastOutgoingMessageIndex = -1;
    this.state = {
      messages: [],
      listRevision: 0,
      fastAnswerVariants: props.fastAnswerVariants,
    };
  }

  componentDidMount() {
    const { messages, attendee } = this.props;

    /*
            Bug: FlatList is twitching on scroll when using ListHeaderComponent prop.
            http://issues.local/browse/RN-569

            Adding header as message fixes the problem.
     */
    this.setState({
      messages: [
        { type: HEADER_TYPE },
        ...getMessages(this.failedMessages, messages || [], attendee),
        { type: FOOTER_TYPE },
      ],
    });

    this.bus.addListener('command.messages.log.scroll-down', this.scrollDown);
    this.subscribe();

    if (Platform.OS === 'web' && this.listRef != null) {
      this.listRef
        .getScrollableNode()
        .addEventListener('wheel', this.invertedWheelEvent);

      this.listRef.setNativeProps({
        style: {
          transform: 'translate3d(0,0,0) scaleY(-1)',
        },
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const state = {};
    const {
      messages,
      boostMessagePrice,
      boostingMessagesTags,
      paymentRequired,
      subscribeRequired,
      fastAnswerVariants,
      user,
      attendee,
      quickRepliesVisible,
    } = this.props;
    const combinedMessages = getMessages(
      this.failedMessages,
      nextProps.messages || [],
      attendee,
    );
    const lastOutgoingMessageIndex = combinedMessages.findIndex(
      item => item.outgoing && item.status === DELIVERY_STATUS.DELIVERED,
    );
    const { listRevision } = this.state;

    if (messages !== nextProps.messages) {
      state.messages = [
        { type: HEADER_TYPE },
        ...combinedMessages,
        { type: FOOTER_TYPE },
      ];
    }

    if (
      boostMessagePrice !== nextProps.boostMessagePrice ||
      !equal(boostingMessagesTags, nextProps.boostingMessagesTags) ||
      this.lastOutgoingMessageIndex !== lastOutgoingMessageIndex ||
      paymentRequired !== nextProps.paymentRequired ||
      subscribeRequired !== nextProps.subscribeRequired ||
      !equal(fastAnswerVariants, nextProps.fastAnswerVariants) ||
      quickRepliesVisible !== nextProps.quickRepliesVisible
    ) {
      state.listRevision = listRevision + 1;
      this.lastOutgoingMessageIndex = lastOutgoingMessageIndex;
    }

    this.setState(state);

    if (user !== nextProps.user || attendee !== nextProps.attendee) {
      this.unsubscribe();
      this.subscribe();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      placeholder,
      quickRepliesVisible,
      allMessagesLoaded,
      boostMessagePrice,
      boostingMessagesTags,
      paymentRequired,
      subscribeRequired,
      fastAnswerVariants,
      MessageComponent,
      MessageSeparatorComponent,
      creditsEnabledForCheers,
    } = this.props;

    const { messages, listRevision } = this.state;

    return (
      placeholder !== nextProps.placeholder ||
      quickRepliesVisible !== nextProps.quickRepliesVisible ||
      allMessagesLoaded !== nextProps.allMessagesLoaded ||
      messages !== nextState.messages ||
      listRevision !== nextState.listRevision ||
      paymentRequired !== nextProps.paymentRequired ||
      subscribeRequired !== nextProps.subscribeRequired ||
      boostMessagePrice !== nextProps.boostMessagePrice ||
      MessageComponent !== nextProps.MessageComponent ||
      MessageSeparatorComponent !== nextProps.MessageSeparatorComponent ||
      creditsEnabledForCheers !== nextProps.creditsEnabledForCheers ||
      !equal(boostingMessagesTags, nextProps.boostingMessagesTags) ||
      !equal(fastAnswerVariants, nextProps.fastAnswerVariants)
    );
  }

  componentDidUpdate(prevProps) {
    const { fastAnswerVariants } = this.props;

    if (!equal(fastAnswerVariants, prevProps.fastAnswerVariants)) {
      // eslint-disable-next-line
      this.setState({
        fastAnswerVariants,
      });
    }
  }

  componentWillUnmount() {
    this.bus.removeListener(
      'command.messages.log.scroll-down',
      this.scrollDown,
    );

    this.unsubscribe();

    Object.values(this.refreshTimers).forEach(timeoutId =>
      clearTimeout(timeoutId),
    );
    this.refreshTimers = {};

    if (Platform.OS === 'web') {
      this.listRef
        .getScrollableNode()
        .removeEventListener('wheel', this.invertedWheelEvent);
    }
  }

  getLastUnpaidMessage() {
    const { messages } = this.state;

    return messages.find(
      item => item.outgoing && item.status === DELIVERY_STATUS.UNPAID,
    );
  }

  paymentRequiredPurchaseButtonTitle = () => {
    return (
      <CurrencyBasedText
        strings={{
          coins:
            Resources.strings[
              'chat-screen-purchase-coins-to-send-messages-button-title'
            ],
          credits:
            Resources.strings[
              'chat-screen-purchase-credits-to-send-messages-button-title'
            ],
        }}
      />
    );
  };

  paymentRequiredMotivationText = () => {
    const { creditsEnabledForCheers } = this.props;
    const lastUnpaidMessage = this.getLastUnpaidMessage();

    if (
      creditsEnabledForCheers &&
      lastUnpaidMessage?.type === MESSAGE_TYPES.CHEER
    ) {
      return (
        <CurrencyBasedText
          strings={{
            credits:
              Resources.strings[
                'chat-screen-purchase-credits-to-send-gifts-text'
              ],
          }}
        />
      );
    }

    return (
      <CurrencyBasedText
        strings={{
          coins:
            Resources.strings[
              'chat-screen-purchase-coins-to-send-messages-text'
            ],
          credits:
            Resources.strings[
              'chat-screen-purchase-credits-to-send-messages-text'
            ],
        }}
      />
    );
  };

  sendText = text => {
    this.bus.emit('command.messages.log.send-text', text);
    this.setState({ fastAnswerVariants: null });
  };

  cellRendererComponent = props => {
    const { children, ...otherProps } = props;
    const { onPress } = this.props;

    return (
      <TouchableWithoutFeedback {...otherProps} onPress={onPress}>
        <View
          style={
            props.item.type === FOOTER_TYPE ? styles.footerWrapper : undefined
          }
        >
          {children}
        </View>
      </TouchableWithoutFeedback>
    );
  };

  itemSeparatorComponent = () => {
    const { MessageSeparatorComponent } = this.props;

    if (MessageSeparatorComponent) {
      return <MessageSeparatorComponent style={styles.separator} />;
    }

    return <View style={styles.separator} />;
  };

  onMessagePress = item => {
    const { onPress, showActionSheetWithOptions } = this.props;

    if (this.isMessageMenuOpened) {
      return;
    }

    const isNotDelivered = this.isMessageNotDelivered(item);

    if (isNotDelivered) {
      this.isMessageMenuOpened = true;

      showActionSheetWithOptions(
        {
          options: [
            Resources.strings['chat-screen-message-re-send'],
            Resources.strings.delete,
            Resources.strings.cancel,
          ],
          destructiveButtonIndex: 1,
          cancelButtonIndex: 2,
          showSeparators: Platform.OS === 'web',
        },
        buttonIndex => {
          this.isMessageMenuOpened = false;

          if (buttonIndex === 0) {
            if (item.type === MESSAGE_TYPES.TEXT) {
              if (item.content) {
                this.bus.emit('command.messages.log.send-text', item.content);
                MessagesReSender.shared(item.sender).deleteFailedMessage(item);
              }
            } else if (item.type === MESSAGE_TYPES.STICKER) {
              if (item.content?.basename) {
                this.bus.emit('command.messages.log.send-sticker', {
                  basename: item.content.basename,
                });
                MessagesReSender.shared(item.sender).deleteFailedMessage(item);
              }
            }
          } else if (buttonIndex === 1) {
            MessagesReSender.shared(item.sender).deleteFailedMessage(item);
          }
        },
      );
    }

    if (onPress) {
      onPress(item);
    }
  };

  renderItem = ({ item, index }) => {
    const {
      MessageComponent = Message,
      boostingMessagesTags,
      bus,
      onMessageShow,
      attendee,
      isPaidChat,
      boostMessagePrice,
      boostMessage,
      cheersSoundInChatEnabled,
    } = this.props;

    if (item.type === HEADER_TYPE) {
      return this.listHeaderComponent();
    }

    if (item.type === FOOTER_TYPE) {
      return this.listFooterComponent(index);
    }

    const Separator = this.itemSeparatorComponent;

    const canBeBoosted =
      Array.isArray(boostingMessagesTags) &&
      boostingMessagesTags.indexOf(item.tag) >= 0;

    const isNotDelivered = this.isMessageNotDelivered(item);

    const viewProps = {
      key: item.tag,
      style: styles.item,
    };

    if (Platform.OS === 'web') {
      viewProps.onLayout = object => {
        this.itemHeights[index] = object.nativeEvent.layout.height;
      };
    }

    return (
      <View {...viewProps}>
        <MessageComponent
          bus={bus}
          item={item}
          onPress={this.onMessagePress}
          lastOutgoingMessage={index - 1 === this.lastOutgoingMessageIndex}
          onShow={onMessageShow}
          attendee={attendee}
          isPaidChat={isPaidChat}
          isNotDelivered={isNotDelivered}
          cheersSoundInChatEnabled={cheersSoundInChatEnabled}
        />
        {canBeBoosted && <Separator />}
        {canBeBoosted && (
          <BoostMessage
            recipient={item.recipient}
            tag={item.tag}
            price={boostMessagePrice}
            onBoostMessagePress={boostMessage}
          />
        )}
      </View>
    );
  };

  onEndReached = () => {
    this.bus.emit('event.messages.log.end-reached');
  };

  onPurchaseButtonPressed = () => {
    const { creditsEnabledForCheers } = this.props;
    const lastUnpaidMessage = this.getLastUnpaidMessage();

    if (
      creditsEnabledForCheers &&
      lastUnpaidMessage?.type === MESSAGE_TYPES.CHEER
    ) {
      this.bus.emit(
        'command.messages.log.purchase-credits-for-cheer',
        lastUnpaidMessage.content?.price, // Should we use content.amount?
      );
    } else {
      this.bus.emit('command.messages.log.purchase');
    }
  };

  onSubscribeButtonPressed = () => {
    this.bus.emit('command.messages.log.subscribe');
  };

  scrollDown = () => {
    if (this.listRef) {
      const { messages } = this.props;

      if (messages.length) {
        this.listRef.scrollToOffset({ offset: 0, animated: true });
      }
    }
  };

  invertedWheelEvent = e => {
    this.listRef.getScrollableNode().scrollTop -= e.deltaY;

    e.preventDefault();
  };

  getItemLayout = (data, index) => {
    const length = this.itemHeights[index] || 0;
    const offset = this.itemHeights
      .slice(0, index)
      .reduce((a, c) => a + c + SEPARATOR_HEIGHT, 0);

    return { length, offset, index };
  };

  subscribe() {
    const { user, attendee } = this.props;

    if (!user || !attendee) {
      return;
    }

    this.disposeBag = new Subscription();
    this.disposeBag.add(
      MessagesReSender.shared(user).failedMessages.subscribe(failedMessages => {
        const { messages } = this.props;
        let { listRevision } = this.state;

        this.failedMessages = failedMessages;

        const combinedMessages = getMessages(
          failedMessages,
          messages,
          attendee,
        );

        const lastOutgoingMessageIndex = combinedMessages.findIndex(
          item => item.outgoing && item.status === DELIVERY_STATUS.DELIVERED,
        );

        if (this.lastOutgoingMessageIndex !== lastOutgoingMessageIndex) {
          listRevision++;
          this.lastOutgoingMessageIndex = lastOutgoingMessageIndex;
        }

        this.setState({
          listRevision,
          messages: [
            { type: HEADER_TYPE },
            ...combinedMessages,
            { type: FOOTER_TYPE },
          ],
        });
      }),
    );
  }

  unsubscribe() {
    // eslint-disable-next-line babel/no-unused-expressions
    this.disposeBag?.unsubscribe();
  }

  isMessageNotDelivered(message) {
    const { reSendDelay } = this.props;
    const isValidType =
      message?.type === MESSAGE_TYPES.TEXT ||
      message?.type === MESSAGE_TYPES.STICKER;
    const isNotDelivered =
      message?.outgoing &&
      (message.status === DELIVERY_STATUS.SENDING ||
        message.status === DELIVERY_STATUS.FAILED);
    const now = Date.now();
    const deliveryTimeoutHasOccurred =
      message && message.timestamp + reSendDelay < now;

    if (message?.tag && message?.outgoing && isValidType) {
      if (this.refreshTimers[message.tag]) {
        clearTimeout(this.refreshTimers[message.tag]);
        delete this.refreshTimers[message.tag];
      }

      if (isNotDelivered && !deliveryTimeoutHasOccurred) {
        this.refreshTimers[message.tag] = setTimeout(() => {
          this.setState(state => ({
            listRevision: state.listRevision + 1,
          }));
          delete this.refreshTimers[message.tag];
        }, message.timestamp + reSendDelay - now);
      }
    }

    return isValidType && isNotDelivered && deliveryTimeoutHasOccurred;
  }

  listHeaderComponent() {
    const {
      quickRepliesVisible,
      onQuickRepliesClose,
      paymentRequired,
      subscribeRequired,
      onPress,
      maxFreeMessagesPerDay,
    } = this.props;
    const { fastAnswerVariants } = this.state;
    const Separator = this.itemSeparatorComponent;

    const children = [];

    if (fastAnswerVariants && fastAnswerVariants.length) {
      children.push(
        <FastAnswerPanel
          isVisible={quickRepliesVisible}
          onClose={onQuickRepliesClose}
          key="fast-answer"
          variants={fastAnswerVariants}
          send={this.sendText}
        />,
      );
    }

    if (paymentRequired) {
      if (children.length) {
        children.push(<Separator key="payment-required-separator" />);
      }

      children.push(
        <PaymentRequired
          key="payment-required"
          motivationText={this.paymentRequiredMotivationText}
          purchaseButtonTitle={this.paymentRequiredPurchaseButtonTitle}
          onPurchaseButtonPress={this.onPurchaseButtonPressed}
        />,
      );
    }

    if (subscribeRequired) {
      if (children.length) {
        children.push(<Separator key="subscribe-required-separator" />);
      }

      children.push(
        <PaymentRequired
          key="subscribe-required"
          motivationText={
            <Text>
              <Text style={styles.textBold}>{Resources.strings.subscribe}</Text>
              {Resources.strings['chat-screen-subscribe-to-send-messages-text']}
            </Text>
          }
          purchaseButtonTitle={
            Resources.strings[
              'chat-screen-subscribe-to-send-messages-button-title'
            ]
          }
          onPurchaseButtonPress={this.onSubscribeButtonPressed}
          hint={
            <Text style={styles.paymentRequiredHint}>
              {Resources.strings.formatString(
                Resources.strings[
                  'chat-screen-subscribe-to-send-messages-hint'
                ],
                maxFreeMessagesPerDay,
              )}
            </Text>
          }
        />,
      );
    }

    if (!children.length) {
      return null;
    }

    return (
      <TouchableWithoutFeedback onPress={onPress} key={HEADER_KEY}>
        <View style={styles.header}>{children}</View>
      </TouchableWithoutFeedback>
    );
  }

  listFooterComponent(index) {
    const { anonymousUsingEnabled, tags = [] } = this.props;
    const messageReceivedThroughBoost =
      (tags && tags[TARGETED_TAGS.BOOST_INITIATED]) || false;

    const children = [];

    if (anonymousUsingEnabled && messageReceivedThroughBoost) {
      children.push(<ReceivedThroughBoostBanner />);
    }

    const viewProps = {
      style: styles.footer,
    };

    if (Platform.OS === 'web') {
      viewProps.onLayout = object => {
        this.itemHeights[index] = object.nativeEvent.layout.height;
      };
    }

    return <View {...viewProps}>{children}</View>;
  }

  render() {
    const { allMessagesLoaded, placeholder, onPress } = this.props;
    const { messages, listRevision } = this.state;

    const listProps = {
      contentContainerStyle: styles.contentContainer,
      style: styles.list,
      data: messages,
      onEndReached: this.onEndReached,
      onEndReachedThreshold: 0.7,
      renderItem: this.renderItem,
      ref: ref => {
        this.listRef = ref;
      },
      keyExtractor,
      CellRendererComponent: this.cellRendererComponent,
      ItemSeparatorComponent: this.itemSeparatorComponent,
      showsVerticalScrollIndicator: false,
      windowSize: 10,
      extraData: listRevision,
    };

    if (Platform.OS === 'web') {
      listProps.getItemLayout = this.getItemLayout;
    }

    return (
      <View style={styles.container}>
        <View style={styles.listContainer}>
          <FlatList {...listProps} />
        </View>
        {// Empty message list always has header as a cell (for payment required cell, etc).
        allMessagesLoaded && placeholder && messages.length === 2 && (
          <TouchableWithoutFeedback onPress={onPress}>
            <View style={styles.placeholderContainer}>
              {typeof placeholder === 'string' ? (
                <Text style={styles.placeholder}>{placeholder}</Text>
              ) : (
                placeholder
              )}
            </View>
          </TouchableWithoutFeedback>
        )}
      </View>
    );
  }
}

export default connectActionSheet(Log);
