import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { View, Image, Text, TouchableOpacity } from 'react-native';
import testId from 'dating-mobile/src/utils/test-id';
import { NavigationActions } from 'react-navigation';

import controller from 'dating-mobile/src/payment/views/subscription-agreement/controller';
import Resources from '../../../resources';
import Navigator from '../../navigator';
import { NAV_ITEMS } from './constants';

import styles from './styles';

const navItems = Object.entries(NAV_ITEMS);

NavigationPanel.propTypes = {
  onTermsLinkPress: PropTypes.func.isRequired,
  onPolicyLinkPress: PropTypes.func.isRequired,
};

function NavigationPanel({ onTermsLinkPress, onPolicyLinkPress }) {
  const [active, setActive] = useState(0);
  const [additionalItemsVisible, setAdditionalItemsVisible] = useState(false);

  useEffect(() => {
    Navigator.listen(handleNavigatorUpdate);

    return () => Navigator.unlisten(handleNavigatorUpdate);
  }, []);

  function handleNavigatorUpdate(routing) {
    const [, , to] = routing.stack;
    const activeItem = navItems.find(([, item]) => to && to.includes(item.to));

    if (activeItem) {
      setActive(activeItem[0]);
    }
  }

  function renderItem(isActive, { key, onPress, text, icon, iconStyle = {} }) {
    const itemStyles = isActive
      ? {
          wrap: styles.navItemActive,
          text: styles.navItemTextActive,
          icon: styles.navIconActive,
        }
      : {
          wrap: styles.navItem,
          text: styles.navItemText,
          icon: styles.navIcon,
        };

    return (
      <TouchableOpacity style={itemStyles.wrap} onPress={onPress} key={key}>
        {icon && (
          <Image
            style={[itemStyles.icon, iconStyle]}
            source={Resources.images[icon]()}
          />
        )}
        <Text style={itemStyles.text}>{Resources.strings[text]}</Text>
      </TouchableOpacity>
    );
  }

  return (
    <View style={styles.container}>
      <Image
        style={styles.logo}
        source={Resources.images.logoIcon()}
        {...testId('Background')}
      />
      {navItems.map(([key, { icon, text, to, init }]) => {
        return renderItem(key === active, {
          onPress: () =>
            Navigator.navigate(
              to,
              null,
              NavigationActions.navigate({ routeName: init }),
            ),
          text,
          icon,
          key,
        });
      })}

      {additionalItemsVisible
        ? [
            renderItem(false, {
              onPress: onTermsLinkPress,
              text: 'memberships-screen-terms-agreement-terms-link-text',
              key: 'terms',
            }),
            renderItem(false, {
              onPress: onPolicyLinkPress,
              text: 'memberships-screen-terms-agreement-policy-link-text',
              key: 'policy',
            }),
          ]
        : renderItem(false, {
            onPress: () => setAdditionalItemsVisible(true),
            text: 'more-nav-bar-item-title',
            icon: 'reportIcon',
            key: 'more',
            iconStyle: { height: 24, width: 24 },
          })}
    </View>
  );
}

export default controller(NavigationPanel);
