import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  header: {
    fontWeight: 600,
    marginBottom: 20,
  },
});

export default styles;
