import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        width: '100%',
    },
    contentContainer: {
        marginLeft: 16,
        flex: 1,
        '@flavor dma': {
            borderBottomWidth: 1,
            borderBottomColor: '$brandBorderColor',
            paddingBottom: 17,
        },
    },
    contentContainerPlaceholder: {
        borderBottomWidth: 1,
        borderBottomColor: '$brandBorderColor',
        '@flavor dma': {
            paddingBottom: 0,
        },
        '@flavor secretly': {
            borderBottomColor: '$primaryBorderColor',
        },
    },
    title: {
        fontFamily: '$brandFontFamily',
        fontSize: 24,
        fontWeight: '700',
        color: '$brandTextColor',
        '@flavor secretly': {
            color: '$primaryTextColor',
        },
    },
    text: {
        marginTop: 16,
        fontFamily: '$brandFontFamily',
        fontSize: 17,
        color: '$brandTextColor',
        textAlign: 'left',
        '@flavor secretly': {
            color: '$primaryTextColor',
        },
    },
    textPlaceholder: {
        marginTop: 35,
        marginBottom: 17,
        color: '$brandPlaceholderColor',
        '@flavor secretly': {
            color: '$secondaryTextColor',
        },
    },
});

export default styles;
