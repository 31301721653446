import { singleton } from '../../commons/utils/singleton';
import api, { toObservable } from '@sdv/domain/api';
import { map } from 'rxjs/operators';

export class CreditCardsRepo {

    static shared = singleton(userId => new CreditCardsRepo(userId));

    constructor(userId) {
        this.userId = userId;
    }

    /**
     * @return {Observable<SavedCreditCard[]>}
     */
    cards = () => {
        return toObservable(
            api.exo.cards.get,
            this.userId
        ).pipe(
            map(jsonList => {
                return jsonList.map(this.mapJsonToSavedCreditCard);
            })
        );
    };

    mapJsonToSavedCreditCard = (json) => {
        return {
            cardId: json.id,
            number: json.name,
            isLastUsed: json.selected
        };
    };
}

/**
 * @typedef {
 * {
 *  cardId: string,
 *  number: string,
 *  isLastUsed: boolean
 * }
 * } SavedCreditCard
 */
