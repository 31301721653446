import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    text: {
        fontFamily: '$brandFontFamily',
        fontSize: 14,
        color: 'white'
    }
});

export default styles;
