import Navigator from 'dating-mobile/src/routing/navigator';

export default {
  alert: (header = '', body = '', buttons = [], options = {}) =>
    Navigator.showAlert({
      header,
      body,
      buttons,
      options,
    }),
};
