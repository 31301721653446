import { createOptimisticActions } from '@sdv/model';
import Actions from '../../actions/page/admin';

class AdminConfigStore extends createOptimisticActions(['patch', 'put']) {
    static displayName = 'config/page/admin';

    constructor(flux) {
        let actions = flux.getActions(Actions);
        super(actions);
        this.bindAction(actions.set, this.set);
    }

    set = (data) => {
        this.state.data = data;
        this.setState(this.state);
    };

}

export default AdminConfigStore;
