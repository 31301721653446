import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Resources from 'dating-mobile/src/resources';

import withUserController from 'dating-mobile/src/models/user/controllers';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';

import Preferences from '../../utils/preferences';
import { RELATIONSHIP_PRIORITY, PREFERENCE_TYPE } from '../../utils/preferences-types';

const imagesMap = {
    fem: {
        sexual: require('./assets/fem/sexual.jpg'),
        spendingTime: require('./assets/fem/spending.jpg'),
    },
    mal: {
        sexual: require('./assets/mal/sexual.jpg'),
        spendingTime: require('./assets/mal/spending.jpg'),
    },
};

function getOptions(gender) {
    return [
        {
            id: RELATIONSHIP_PRIORITY.sexual,
            image: imagesMap[gender].sexual,
            text: Resources.strings['onboarding-screen-more-important-option-sexual-text'],
        },
        {
            id: RELATIONSHIP_PRIORITY.spendingTime,
            image: imagesMap[gender].spendingTime,
            text: Resources.strings['onboarding-screen-more-important-option-spending-text'],
        },
    ];
}

export default function createController(Component) {
    class Controller extends PureComponent {
        static displayName = 'stepped-registration.screens.relationship-priority.controller';

        static propTypes = {
            onComplete: PropTypes.func.isRequired,
            user: PropTypes.object,
            id: PropTypes.string.isRequired,
        };

        state = {
            selected: [],
            gender: null,
            options: null,
        };

        static getDerivedStateFromProps(props, state) {
            const { gender } = props.user;

            if (gender && state.gender !== gender) {
                return {
                    gender,
                    options: getOptions(gender),
                };
            }

            return null;
        }

        handleToggle = id => {
            const { selected } = this.state;

            const isSelected = selected.includes(id);

            if (isSelected) {
                this.setState({
                    selected: selected.filter(option => option !== id),
                });
            } else {
                this.setState({
                    selected: [...selected, id],
                });
            }
        };

        submit = () => {
            const { selected } = this.state;
            const { onComplete, id } = this.props;

            if (selected && selected.length) {
                Preferences.shared(id)
                    .savePreference(PREFERENCE_TYPE.relationshipPriority, selected)
                    .subscribe(onComplete, onComplete);
            }
        };

        render() {
            const { selected, options } = this.state;

            if (!options) {
                return null;
            }

            return (
                <Component
                    selected={selected}
                    options={options}
                    onToggle={this.handleToggle}
                    onButtonPress={this.submit}
                />
            );
        }
    }

    return withIdentityId(withUserController(Controller), 'id');
}
