import React from "react";
import PropTypes from "prop-types";
import { contacts, chatRequests } from 'dating-mobile/src/models/user.events/controllers/events.js';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id.js';

export default function createController(Component) {
    const displayName = "dialogs.messages.views.unread-badge-button.controller";
    class UnreadBadgeController extends React.PureComponent {
        static propTypes = {
            contacts: PropTypes.array,
            chatRequests: PropTypes.array,
            attendeeId: PropTypes.string,
        };

        render() {
            const { contacts, chatRequests, ...props } = this.props;
            const chatItems = (contacts || []).concat((chatRequests || []));

            const counterBadge = chatItems.reduce((acc, item) => {
                if (this.props.attendeeId) {
                    return item['user-id'] === this.props.attendeeId ? item.stack : acc
                }

                if (item.type !== 'message') {
                    return acc;
                }

                return acc + item.stack
            }, 0);

            return (<Component
                {...props}
                badge={counterBadge > 0 ? String(counterBadge) : null}
            />);
        }
    }

    return withIdentityId(
        chatRequests(
            contacts(
                UnreadBadgeController
            )
        )
    );
}
