import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $backgroudGradientColors: ['#fe7151', '#5a74f4'],

    button: {
        height: 50,
        borderRadius: 25,
        marginLeft: 16,
        marginRight: 16,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    image: {
        marginLeft: 26,
        width: 22,
        height: 15,
    },
    title: {
        marginLeft: 8,
        marginRight: 26,
        fontSize: 13,
        fontFamily: '$brandFontFamily',
        fontWeight: 'bold',
        color: '$primaryTextColor',
    },

    container: {
        height: 74,
        justifyContent: 'center',
        alignItems: 'center',
    },
});

export default styles;
