import AppsFlyer from 'react-native-appsflyer';
import Session from '@sdv/domain/authorization/session';
import { filter, shareReplay, switchMap, take } from 'rxjs/operators';
import flux from '@sdv/domain/app/flux';
import UserModel from '@sdv/domain/user/model';
import '@sdv/commons/rx/store';

export default class AppsFlyerTracker {
  static trackEvent(eventName, eventValues, successC, errorC) {
    if (
      eventName === 'First Purchase' ||
      eventName === 'Credit Purchase' ||
      eventName === 'af_complete_registration'
    ) {
      AppsFlyer.trackEvent(eventName, eventValues, successC, errorC);

      this.userId = Session.shared().userId.pipe(
        filter(Boolean),
        shareReplay(1),
      );

      this.subscription = this.userId
        .pipe(
          switchMap(userId => {
            const userModel = flux.get(UserModel, userId);

            return userModel.store.rxState();
          }),
          filter(user => !!user.gender),
          take(1),
        )
        .subscribe(user => {
          // eslint-disable-next-line
          switch (user.gender) {
            case 'mal':
              AppsFlyer.trackEvent(
                `${eventName}_male`,
                eventValues,
                successC,
                errorC,
              );
              break;
            case 'fem':
              AppsFlyer.trackEvent(
                `${eventName}_female`,
                eventValues,
                successC,
                errorC,
              );
              break;
          }
        });
    } else {
      AppsFlyer.trackEvent(eventName, eventValues, successC, errorC);
    }
  }

  static trackAppLaunch() {
    AppsFlyer.trackAppLaunch();
  }

  static setCustomerUserId(identity, successC) {
    AppsFlyer.setCustomerUserId(identity, successC);
  }
}
