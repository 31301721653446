import React, { PureComponent } from 'react';
import { View } from "react-native";

import Resources from 'dating-mobile/src/resources';
import Button from 'dating-mobile/src/components/buttons/base';

import styles from "./styles";


class AuthenticateNavigationButton extends PureComponent {
    static displayName = 'user.views.authenticate-navigation-button';

    render() {
        return (
            <View style={styles.buttonContainer}>
                <Button
                    title={Resources.strings['account-screen-sing-up-button-title']}
                    style={styles.button}
                    titleStyle={styles.buttonTitle}
                />
            </View>
        )
    }

}

export default AuthenticateNavigationButton;

