import React from 'react';
import PropTypes from 'prop-types';
import { View, ScrollView, Text, StatusBar } from 'react-native';
import { SafeAreaView } from 'react-navigation';
import { PurchaseBoostScreen } from 'dating-mobile/src/payment/screens/boost';
import { PurchaseMembershipScreen } from 'dating-mobile/src/payment/screens/memberships';
import Resources from 'dating-mobile/src/resources';

import styles from './styles';

export default class PurchaseScreen extends React.Component {
  static displayName = 'payment.screens.purchase.view';

  static propTypes = {
    navigation: PropTypes.object,
    canSubscribe: PropTypes.bool,
    isFocused: PropTypes.bool,
    canBoost: PropTypes.bool,
    onComplete: PropTypes.func,
    premiumSubscriptionEnabled: PropTypes.bool,
  };

  componentDidUpdate(prevProps) {
    const { navigation, canBoost, isFocused } = this.props;

    if (canBoost !== prevProps.canBoost || prevProps.isFocused !== isFocused) {
      navigation.setParams({ canBoost });
    }
  }

  render() {
    const {
      canBoost,
      canSubscribe,
      onComplete,
      premiumSubscriptionEnabled,
    } = this.props;

    return [
      <StatusBar
        key="status-bar"
        barStyle={styles.$statusBarStyle}
        backgroundColor={styles.$statusBarBackgroundColor}
      />,
      <SafeAreaView
        key="view"
        forceInset={styles.$safeAreaInsets}
        style={styles.container}
      >
        <ScrollView
          style={styles.scroll}
          contentContainerStyle={styles.scrollContent}
        >
          {canBoost && (
            <View>
              <View style={styles.partHeaderContainer}>
                <Text style={styles.partHeader}>
                  {Resources.strings['purchase-screen-credits-section-title']}
                </Text>
              </View>
              <PurchaseBoostScreen
                style={styles.credits}
                onComplete={onComplete}
              />
            </View>
          )}
          {!canSubscribe && (
            <View>
              {canBoost && (
                <View style={styles.partHeaderContainer}>
                  <Text style={styles.partHeader}>
                    {
                      Resources.strings[
                        premiumSubscriptionEnabled
                          ? 'purchase-screen-premium-memberships-section-title'
                          : 'purchase-screen-memberships-section-title'
                      ]
                    }
                  </Text>
                </View>
              )}
              <PurchaseMembershipScreen
                style={styles.memberships}
                safeAreaInsets={styles.$emptySafeAreaInsets}
                onComplete={onComplete}
              />
            </View>
          )}
        </ScrollView>
      </SafeAreaView>,
    ];
  }
}
