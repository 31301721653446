import React from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity, Platform } from 'react-native';
import { connectActionSheet } from '@expo/react-native-action-sheet';

import Photo from '../../../../components/photo';
import Resources from '../../../../resources';

import styles from './styles';

class ProfileMedia extends React.PureComponent {
  static displayName = 'user.media.view';

  static propTypes = {
    id: PropTypes.string.isRequired,
    basename: PropTypes.string.isRequired,
    showActionSheetWithOptions: PropTypes.func,
    setAsThumbnail: PropTypes.func,
    deletePhoto: PropTypes.func,
    deleteVideo: PropTypes.func,
    media: PropTypes.array,
    couldBeDeleted: PropTypes.bool,
    round: PropTypes.bool,
    style: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  };

  showControls = () => {
    const {
      showActionSheetWithOptions,
      media,
      basename,
      setAsThumbnail,
      deletePhoto,
      deleteVideo,
    } = this.props;
    const item = media.find(it => it.basename === basename);

    if (!item) {
      return;
    }
    const isPhoto = item.mediatype?.startsWith('image');

    const showSetAsThumbnail = isPhoto && item.tags.indexOf('thumbnail') === -1;
    const options = [
      Resources.strings['gallery-screen-delete-button-title'],
      Resources.strings.cancel,
    ];

    if (showSetAsThumbnail) {
      options.unshift(
        Resources.strings['gallery-screen-set-as-thumbnail-button-title'],
      );
    }
    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex: options.length - 1,
        destructiveButtonIndex: options.length - 2,
        showSeparators: Platform.OS === 'web',
      },
      buttonIndex => {
        if (showSetAsThumbnail && buttonIndex === 0) {
          if (setAsThumbnail) setAsThumbnail(item.basename);
        } else if (buttonIndex === options.length - 2) {
          if (isPhoto) {
            if (deletePhoto) deletePhoto(item.basename);
          } else if (deleteVideo) {
            deleteVideo(item.basename);
          }
        }
      },
    );
  };

  async delete() {
    const { deletePhoto, basename } = this.props;

    try {
      await deletePhoto(basename);
    } catch (error) {
      // eslint-disable-next-line react/no-unused-state
      this.setState({ error: error.message });
    }
  }

  render() {
    const { basename, id, couldBeDeleted, round, style } = this.props;
    const image = (
      <Photo
        style={style || styles.media}
        userId={id}
        basename={basename}
        round={typeof round !== 'undefined' ? round : true}
      />
    );

    if (couldBeDeleted) {
      return (
        <TouchableOpacity onPress={this.showControls}>{image}</TouchableOpacity>
      );
    }

    return image;
  }
}

export default connectActionSheet(ProfileMedia);
