import React from 'react';
import { FlatList, Image, ScrollView, StatusBar, Text, TouchableOpacity, View } from 'react-native';
import styles from './styles';
import PropTypes from 'prop-types';
import LetterAttachmentController from "../../../models/common.messages/controllers/letter-attachments-controller";
import Photo from "dating-mobile/src/components/photo";
import testId from "../../../utils/test-id";
import Resources from "../../../resources";
import LetterHeaderView from 'dating-mobile/src/inbox/views/letter-header';
import TextWithSmiles from 'dating-mobile/src/dialogs/common/views/text-with-smiles';

export default class LetterReadingView extends React.Component {
    static displayName = 'inbox.screens.letter-reading.view';
    static propTypes = {
        ownLetter: PropTypes.bool,
        recipient: PropTypes.string,
        sender: PropTypes.string,
        letter: PropTypes.string,
        introductory: PropTypes.bool,
        onReplyButtonPress: PropTypes.func,
        onLetterRead: PropTypes.func
    };

    onReplyButtonPressed = () => {
        this.props.onReplyButtonPress && this.props.onReplyButtonPress();
    };

    componentDidMount() {
        this.props.onLetterRead && this.props.onLetterRead();
    }

    renderItem = ({ item }) => {
        const Component = LetterAttachmentController((props) => {
            return (
                <TouchableOpacity onPress={props.openResource} style={styles.attachmentContainer}>
                    <Photo
                        key={item.basename}
                        path={props.path}
                        style={styles.attachmentPreview}
                        {...testId('Attachment preview')}
                    />
                    {
                        props.status === 1 && (
                            <Image source={Resources.images.lockIcon()} {...testId('Unlock attachment button')}/>
                        )
                    }
                    {
                        props.status === 0 && props.mediatype?.startsWith('video') && (
                            <Image source={Resources.images.playVideoIcon()} {...testId('Play video button')}/>
                        )
                    }
                </TouchableOpacity>
            )
        });
        return (
            <Component
                basename={item.basename}
                mediatype={item.mediatype}
                reference={item.reference}
                sender={this.props.sender}
                recipient={this.props.recipient}
            />
        )
    };

    render() {
        const hasAttachments = this.props.meta &&
            this.props.meta.attachments &&
            this.props.meta.attachments.length > 0;

        const attendee = this.props.ownLetter ? this.props.recipient : this.props.sender;

        return (
            <View style={styles.container}>
                <StatusBar
                    barStyle={styles.$statusBarStyle}
                    backgroundColor={styles.$statusBarBackgroundColor}
                />
                <ScrollView alwaysBounceVertical={false} style={styles.scrollView}>
                    <LetterHeaderView
                        safeAreaInsets={{top: 'always', bottom: 'never'}}
                        attendeeId={attendee}
                        cover={this.props.cover}
                        ownLetter={this.props.ownLetter}
                    />
                    <View style={styles.subjectContainer}>
                        <TextWithSmiles text={this.props.subject} style={styles.subject} {...testId(Resources.strings['letter-subject-accessibility-label'])} />
                    </View>
                    <View style={styles.textContainer}>
                        <TextWithSmiles text={this.props.text} style={styles.text} {...testId(Resources.strings['letter-text-accessibility-label'])} />
                    </View>
                    {
                        hasAttachments && (
                            <View style={styles.attachments}>
                                <FlatList
                                    style={styles.attachmentsList}
                                    data={this.props.meta.attachments}
                                    renderItem={this.renderItem}
                                    horizontal={true}
                                    numColumns={1}
                                    alwaysBounceHorizontal={false}
                                />
                            </View>
                        )
                    }
                    {
                        !this.props.ownLetter && (
                            <TouchableOpacity onPress={this.onReplyButtonPressed} style={styles.replyButtonContainer}>
                                <Text style={styles.replyButton} {...testId('Reply button')}>
                                    {Resources.strings['inbox-letter-reading-screen-reply-button-title']}
                                </Text>
                            </TouchableOpacity>
                        )
                    }
                </ScrollView>
            </View>
        )
    }
}
