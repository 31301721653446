import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import PropTypes from 'prop-types';
import styles from './styles';
import Resources from '../../../../resources';
import { localizePrice } from '@sdv/commons/price-with-code-localization';

export default class ProductItem extends React.Component {
    static displayName = 'payment.memberships-list.item';
    static propTypes = {
        discount: PropTypes.string,
        isDisabled: PropTypes.bool,
        product: PropTypes.array,
    };

    shouldComponentUpdate(nextProps) {
        const currentProduct = this.props.product || {};
        const nextProduct = nextProps.product || {};

        return (
            this.props.discount !== nextProps.discount ||
            currentProduct.creditsAmount !== nextProduct.creditsAmount ||
            currentProduct.title !== nextProduct.title ||
            currentProduct.price !== nextProduct.price
        );
    }

    onPressed = () => {
        this.props.onPress && this.props.onPress(this.props.product);
    };

    render() {
        const { price } = this.props.product[0] || {};
        const hasDiscount = !!this.props.discount;

        let localizedPrice;

        if (
            styles.$forceLocalizePrice &&
            price.localizedCurrency &&
            price.localizedWithoutCurrency
        ) {
            localizedPrice = localizePrice(price.localizedCurrency, price.localizedWithoutCurrency);
        } else if (price.localizedIncludingTax !== undefined) {
            localizedPrice = price.localizedIncludingTax;
        } else {
            localizedPrice = localizePrice(price.currency, price.includingTax);
        }

        return (
            <TouchableOpacity onPress={this.onPressed} disabled={this.props.isDisabled}>
                <View style={styles.container}>
                    <View style={styles.content}>
                        <Text style={styles.title}>
                            {Resources.strings.formatString(
                                Resources.strings['memberships-screen-membership-title-format'],
                                localizedPrice || '',
                            )}
                        </Text>
                        <Text style={styles.price}>
                            {Resources.strings.formatString(
                                Resources.strings['memberships-screen-membership-price-format'],
                                localizedPrice || '',
                            )}
                        </Text>
                    </View>
                    {hasDiscount && (
                        <View style={styles.discountContainer}>
                            <Text style={styles.discount}>{this.props.discount}</Text>
                        </View>
                    )}
                </View>
            </TouchableOpacity>
        );
    }
}
