import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { View, Text, Image, TouchableOpacity } from 'react-native';

import Resources from 'dating-mobile/src/resources';
import testId from 'dating-mobile/src/utils/test-id';
import Button from 'dating-mobile/src/components/buttons/base';

import styles from './styles';

export default class ScreenView extends PureComponent {
    static displayName = 'stepped-registration.screens.geolocation.view';

    static propTypes = {
        turnOnGeolocation: PropTypes.func,
        skip: PropTypes.func,
    };

    render() {
        const { turnOnGeolocation, skip } = this.props;

        return (
            <View style={styles.container}>
                <View>
                    <Text style={styles.subTitle}>
                        {Resources.strings['onboarding-screen-title']}
                    </Text>
                    <Text style={styles.title}>
                        {Resources.strings['onboarding-screen-geolocation-title']}
                    </Text>
                    <Text style={styles.hint}>
                        {Resources.strings['onboarding-screen-geolocation-hint']}
                    </Text>
                </View>
                <Image style={styles.icon} source={Resources.images.geoLocationIcon()} />
                <View style={styles.buttonsContainer}>
                    <Button
                        title={Resources.strings['onboarding-screen-geolocation-turn-on']}
                        onPress={turnOnGeolocation}
                        style={styles.turnOnButton}
                        titleStyle={styles.turnOnButtonText}
                        {...testId('Turn on geolocation button')}
                    />
                    <TouchableOpacity onPress={skip} hitSlop={styles.notNowButtonHitSlop}>
                        <Text style={styles.notNowButton}>
                            {Resources.strings['onboarding-screen-not-now-button-title']}
                        </Text>
                    </TouchableOpacity>
                </View>
            </View>
        );
    }
}
