import React from 'react';
import PropTypes from 'prop-types';
import { withNavigation } from 'react-navigation';
import RoundButton from 'dating-mobile/src/components/buttons/round';
import Resources from 'dating-mobile/src/resources';
import testId from 'dating-mobile/src/utils/test-id';
import Button from 'dating-mobile/src/components/buttons/base';

import styles from './styles';

class ChatButton extends React.PureComponent {
  static displayName = 'dialogs.messages.views.chat-button.view';

  static propTypes = {
    id: PropTypes.string,
    viewStyle: PropTypes.string,
    onPress: PropTypes.func,
    style: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    titleStyle: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    noIcon: PropTypes.bool,
  };

  render() {
    const { viewStyle, style, titleStyle, noIcon, onPress } = this.props;

    if (viewStyle === 'text') {
      const buttonStyle = style
        ? [styles.startChatButton, style]
        : styles.startStreamButton;
      const buttonTitleStyle = titleStyle
        ? [styles.startChatButtonTitle, titleStyle]
        : styles.startChatButtonTitle;

      return (
        <Button
          style={buttonStyle}
          iconStyle={styles.startChatButtonIcon}
          titleStyle={buttonTitleStyle}
          onPress={onPress}
          title={Resources.strings['streamer-profile-screen-chat-button-title']}
          icon={noIcon || Resources.images.tabBarChatDisabled()}
        />
      );
    }
    const buttonStyle = style ? [styles.chatButton, style] : styles.chatButton;

    return (
      <RoundButton
        onPress={onPress}
        icon={Resources.images.startChatIcon()}
        style={buttonStyle}
        {...testId('Start chat button')}
      />
    );
  }
}

export default withNavigation(ChatButton);
