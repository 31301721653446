import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import withConfigValue from 'dating-mobile/src/components/config-value';
import withMediaLoader from 'dating-mobile/src/models/user.media/controller/media-loader';
import withUserController from 'dating-mobile/src/models/user/controllers';
import Resources from 'dating-mobile/src/resources';
import { PURCHASE } from 'dating-mobile/src/routing/router/constants';
import { Config } from '@sdv/domain/app/config';
import { UserIsCustomer } from '@sdv/domain/user/user-is-customer';
import { Gender } from '@sdv/domain/user/gender';
import { withNavigation } from 'react-navigation';

const getImageByUserId = (id, gender) => {
  if (gender === Gender.Female) {
    return Resources.images.boostBannerIconFemale();
  }

  return id && Number.parseInt(id.slice(0, -2), 10) % 2 === 0
    ? Resources.images.boostBannerIconGreen()
    : Resources.images.boostBannerIconPurple();
};

export default function controller(Component) {
  class Controller extends PureComponent {
    static displayName = 'feed.views.boost-profile-banner.controller';

    static propTypes = {
      user: PropTypes.object,
      id: PropTypes.string,
      boostCost: PropTypes.number,
      selectMedia: PropTypes.func,
      thumbnailRequiredForBoost: PropTypes.bool,
      isLoading: PropTypes.bool,
      style: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.object,
        PropTypes.array,
      ]),
      navigation: PropTypes.object,
    };

    state = {
      subscriptionRequired: false,
    };

    componentDidMount() {
      this.subscribe();
    }

    componentDidUpdate(prevProps) {
      const { id } = this.props;

      if (id !== prevProps.id) {
        this.unsubscribe();
        this.subscribe();
      }
    }

    componentWillUnmount() {
      this.unsubscribe();
    }

    onBoostButtonPressed = async () => {
      try {
        const {
          thumbnailRequiredForBoost,
          selectMedia,
          user,
          navigation,
        } = this.props;
        const thumbnail = user && (user['thumbnail-pending'] || user.thumbnail);

        if (thumbnailRequiredForBoost && !thumbnail) {
          await selectMedia();
        }

        navigation.navigate(PURCHASE);
      } catch (error) {
        // TODO: Handle errors when requirements are specified
      }
    };

    subscribe() {
      const { id } = this.props;

      if (!id) {
        return;
      }

      this.disposeBag = new Subscription();

      this.disposeBag.add(
        Config.shared()
          .anonymousChatEnabled.pipe(
            filter(Boolean),
            switchMap(
              () => UserIsCustomer.shared(id).subscriptionRequiredForBoost,
            ),
          )
          .subscribe(subscriptionRequired =>
            this.setState({ subscriptionRequired }),
          ),
      );
    }

    unsubscribe() {
      if (this.disposeBag) {
        this.disposeBag.unsubscribe();
      }
    }

    render() {
      const { style, boostCost, isLoading, id, user } = this.props;
      const { subscriptionRequired } = this.state;

      if (!user || !user.gender) {
        return null;
      }

      return (
        <Component
          useImageInBackground={user?.gender !== Gender.Female}
          subscriptionRequired={subscriptionRequired}
          style={style}
          boostCost={boostCost}
          isLoading={isLoading}
          onPress={this.onBoostButtonPressed}
          image={getImageByUserId(id, user?.gender)}
        />
      );
    }
  }

  return withNavigation(
    withUserController(
      withMediaLoader(
        withConfigValue(Controller, {
          boostUsingPaidResource: 'features.boost-using-paid-resource',
          boostCost: 'boost-profile.price',
          thumbnailRequiredForBoost: 'features.thumbnail-required-for-boost',
        }),
        { title: Resources.strings['boost-profile-banner-media-loader-title'] },
      ),
    ),
  );
}
