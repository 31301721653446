import {createOptimisticActions} from '@sdv/model';

function createInterestsActions(id) {

    class Actions extends createOptimisticActions(['put']) {

        get = () => (dispatch, flux) => {
            flux.api.users.interests.get(id, (error, result) => {
                if (result) {
                    dispatch({
                        interests: result
                    });
                } else {
                    dispatch(null, error);
                }
            });
        };

        put(interests) {

            return super.put(interests, function (interests) {

                this.dispatch({
                    interests: interests
                });

                this.flux.api.users.interests.put(id, interests, (error) => {
                    if (error) {
                        return this.revert(error);
                    }
                    return this.commit();
                });

            });

        };
    }

    Actions.displayName = createInterestsActions.getDisplayName(id);

    return Actions;
}

createInterestsActions.getDisplayName = function (id) {
    return `user-interests.${id}`;
};

export default createInterestsActions;
