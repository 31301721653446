import React from 'react';
import { TouchableOpacity, Image, Text, View } from 'react-native';
import PropTypes from 'prop-types';
import Resources from '../../../../resources';
import styles from './styles';
import LinearGradient from 'react-native-linear-gradient';

export default class TopicsPickingItem extends React.PureComponent {
    static displayName = 'streaming.views.topics-picking.item';
    static propTypes = {
        displayText: PropTypes.string,
        topic: PropTypes.string,
        selected: PropTypes.bool,
        disabled: PropTypes.bool,
        onPress: PropTypes.func,
        containerStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array]),
        textStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array]),
    };

    render() {
        const { textStyle, containerStyle, disabled } = this.props;

        const imageSource = this.props.selected
            ? Resources.images.streamsTopicsCheckmarkIcon()
            : Resources.images.addTopicIcon();

        const content = [
            <Image source={imageSource} style={[styles.icon, disabled && styles.disabled]} />,
            <Text style={[styles.text, textStyle, disabled && styles.disabled]}>
                {this.props.displayText}
            </Text>,
        ];

        const container = this.props.selected ? (
            <View style={styles.content}>{content}</View>
        ) : (
            <LinearGradient colors={styles.$gradientColors} style={styles.content}>
                {content}
            </LinearGradient>
        );

        return (
            <TouchableOpacity
                onPress={this.onPress}
                style={[
                    styles.container,
                    this.props.selected ? styles.selectedModifier : {},
                    containerStyle,
                ]}
            >
                {container}
            </TouchableOpacity>
        );
    }

    onPress = () => {
        this.props.onPress && this.props.onPress(this.props.topic);
    };
}
