function createActions(id) {
    class Actions {
        interrupt = () => (dispatch) => {
            dispatch();
        };
    }

    Actions.displayName = createActions.getDisplayName(id);
    return Actions;
}

createActions.getDisplayName = function(id) {
    return `call-interrupting.${id}`;
};

export default createActions;
