import React from 'react';
import { TextInput, View, Platform } from 'react-native';
import PropTypes from 'prop-types';

import Error from '../validation-error';

import styles from './styles';

class Input extends React.PureComponent {
  setRef = ref => {
    this.textInput = ref;
    const { forwardRef } = this.props;

    if (forwardRef) forwardRef(ref);
  };

  /**
   * Workaround for «Second tapping to TextInput after keyboard is closed via back
   * button/submit doesn't bring up keyboard» (https://github.com/facebook/react-native/issues/19366)
   * @see http://issues.local/browse/NP-359 for more details
   * TODO: Add a general workaround for all TextInputs
   */
  onSubmitEditing = event => {
    const { onSubmitEditing } = this.props;

    if (Platform.OS === 'android' && this.textInput) {
      this.textInput.blur();
    }

    if (onSubmitEditing) onSubmitEditing(event);
  };

  render() {
    const { placeholderTextColor, containerStyle, style, error } = this.props;

    return (
      <View style={[styles.container, containerStyle]}>
        <TextInput
          {...this.props}
          ref={this.setRef}
          onSubmitEditing={this.onSubmitEditing}
          style={[styles.input, style]}
          placeholderTextColor={
            placeholderTextColor || styles.placeholder.color
          }
        />
        <Error error={error || ''} />
      </View>
    );
  }
}

const styleType = PropTypes.oneOfType([PropTypes.number, PropTypes.object]);

Input.propTypes = {
  onSubmitEditing: PropTypes.func,
  forwardRef: PropTypes.func,
  placeholderTextColor: PropTypes.string,
  containerStyle: styleType,
  style: styleType,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default Input;
