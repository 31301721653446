import {
  filter,
  map,
  distinctUntilChanged,
  startWith,
  debounceTime,
} from 'rxjs/operators';
import { singleton } from '@sdv/commons/utils/singleton';
import { fromEventPattern } from 'rxjs';
import Navigator from 'dating-mobile/src/routing/navigator';

export class CurrentScreen {
  static shared = singleton(() => new CurrentScreen());

  constructor() {
    this.stack = fromEventPattern(Navigator.listen, Navigator.unlisten).pipe(
      map(({ stack }) => stack),
      startWith(Navigator.currentStack()),
      filter(Boolean),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
    );

    this.screen = this.stack.pipe(
      map(stack => stack[stack.length - 1]),
      distinctUntilChanged(),
      debounceTime(10), // prevent handling transitional screens
    );
  }
}
