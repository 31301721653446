import React from 'react';
import { View } from 'react-native';
import styles from './styles';

export default class ButtonGroup extends React.Component {
    static propTypes = View.propTypes;

    render() {
        const { children, style, ...props } = this.props;

        return (
            <View {...props} style={[styles.container, style || {}]}>
                {children}
            </View>
        );
    }

}
