import React, { Component } from 'react';
import {
  View,
  Image,
  StatusBar,
  Text,
  ActivityIndicator,
  Alert,
  ScrollView,
  TouchableOpacity,
  Picker,
  Platform,
} from 'react-native';
import PropTypes from 'prop-types';
import { SafeAreaView } from 'react-navigation';
import Swiper from 'react-native-swiper';
import {
  AppleButton,
  appleAuth,
} from '@invertase/react-native-apple-authentication';
import TermsAndPrivacyText from 'dating-mobile/src/components/terms-and-privacy-text';
import { AppleAuthButton } from 'dating-mobile/src/authentication/views/apple-auth-button';
import shouldUseWebLayout from 'dating-mobile/src/utils/web-layout';
import Language from '@sdv/domain/app/language';

import Resources from '../../../resources';
import testId from '../../../utils/test-id';
import Photo from '../../../components/photo';
import SafePicker from '../../../components/safe-picker';
import Button from '../../../components/buttons/base';
import IfConfigValue from '../../../components/config-value/if';
import withConfigValue from '../../../components/config-value';

import styles from './styles';

const LANGUAGE = {
  'en-US': { value: 'en-US', label: 'En', key: 'en' },
  ar: { value: 'ar', label: 'عربي', key: 'ar' },
};

class Initial extends Component {
  static contextTypes = {
    flux: PropTypes.object,
  };

  static propTypes = {
    showsPaginationIndicator: PropTypes.bool,
    appleAuthEnabled: PropTypes.bool,
    googleAuthEnabled: PropTypes.bool,
  };

  constructor(props, context) {
    super(props);
    this.flux = context.flux;
    this.bus = context.flux.bus;
    this.lang = Language.shared();

    this.state = {
      isLoading: false,
      lang: this.lang.current,
    };
  }

  onLanguageChange = lang => {
    this.lang.setLanguage(lang);
    this.setState(oldState => ({ ...oldState, lang }));
  };

  handleSignIn = () => {
    this.bus.emit('startScreen_signInButtonTap');
  };

  handleSignUp = () => {
    this.bus.emit('startScreen_signUpButtonTap');
  };

  onAuthViaOAuthStarted = () => {
    this.setState({
      isLoading: true,
    });
  };

  onAuthViaOAuthFinished = error => {
    this.setState({
      isLoading: false,
    });

    if (error) {
      if (error.status === 400 && error.src === 'email') {
        Alert.alert(
          Resources.strings['change-email-already-take-error-message'],
          null,
          {
            text: Resources.strings.ok,
          },
          {
            text: Resources.strings.cancel,
            style: 'cancel',
          },
        );
      } else if (error.code !== '1000' && error.code !== '1001') {
        Alert.alert(
          Resources.strings['default-error-message'],
          null,
          {
            text: Resources.strings.ok,
          },
          {
            text: Resources.strings.cancel,
            style: 'cancel',
          },
        );
      }
    }
  };

  followLink(type) {
    const { lang } = this.state;

    const LINKS = {
      PRIVACY: `https://zillapp.io/privacy-${LANGUAGE[lang].key}.html`,
      TERMS: `https://zillapp.io/terms-${LANGUAGE[lang].key}.html`,
      CONTACTS: `https://zillapp.io/contacts.html`,
      GOOGLE:
        'https://play.google.com/store/apps/details?id=chat.zill&referrer=af_tranid%3DAkgBRqAofVbvXpGvlkFiug%26shortlink%3Dzillappio%26pid%3DLP%26af_web_id%3D671c5d3b-7b2f-4d13-9b4c-ea64306919d1-o',
      APPLE: 'https://apps.apple.com/SA/app/id1461449583?mt=8',
    };

    window.open(LINKS[type], '_blank');
  }

  renderAuthButtons() {
    const { appleAuthEnabled, googleAuthEnabled } = this.props;
    const appleAuthButtonVisible = appleAuth.isSupported && appleAuthEnabled;
    const googleAuthButtonVisible =
      googleAuthEnabled && styles.$googleSignUpButtonVisible;
    const oauthButtonVisible =
      appleAuthButtonVisible || googleAuthButtonVisible;

    const buttons = [
      <Button
        key="sign-up-with-email"
        title={
          Resources.strings[
            oauthButtonVisible
              ? 'initial-screen-sign-up-with-email-button-title'
              : 'initial-screen-sign-up-default-button-title'
          ]
        }
        titleStyle={oauthButtonVisible ? styles.buttonBig : null}
        onPress={this.handleSignUp}
        {...testId('Sign up button')}
      />,
    ];

    if (appleAuthButtonVisible) {
      buttons.push(
        <View key="sign-up-via-apple" style={styles.appleSignInButtonContainer}>
          <Text style={styles.oauthButtonsSeparator}>
            {Resources.strings.or}
          </Text>
          <AppleAuthButton
            cornerRadius={25}
            style={styles.appleSignInButton}
            buttonStyle={AppleButton.Style.WHITE}
            buttonType={AppleButton.Type.CONTINUE}
            onAuthStarted={this.onAuthViaOAuthStarted}
            onAuthFinished={this.onAuthViaOAuthFinished}
          />
        </View>,
      );
    }

    return buttons;
  }

  renderSignInButton() {
    return (
      <Button
        style={styles.signInButton}
        titleStyle={styles.signInButtonTitle}
        title={Resources.strings['initial-screen-sign-in']}
        onPress={this.handleSignIn}
        {...testId('Sign in button')}
      />
    );
  }

  render() {
    const {
      showsPaginationIndicator,
      appleAuthEnabled,
      googleAuthEnabled,
    } = this.props;
    const { isLoading, lang } = this.state;
    const slideForceInset = { top: 'always' };
    const appleAuthButtonVisible = appleAuth.isSupported && appleAuthEnabled;
    const googleAuthButtonVisible =
      googleAuthEnabled && styles.$googleSignUpButtonVisible;
    const oauthButtonVisible =
      appleAuthButtonVisible || googleAuthButtonVisible;
    const alreadyMemberBlockVisible =
      styles.$alreadyMemberBlockVisible && !oauthButtonVisible;
    const isDesktopLayout = shouldUseWebLayout();

    return (
      <View style={styles.container}>
        <StatusBar barStyle="light-content" />

        <IfConfigValue
          path="markup.initial-screen.background"
          equalsTo="onboarding"
        >
          {status =>
            status && (
              <View style={styles.swiperContainer}>
                <Swiper
                  autoplay
                  autoplayTimeout={styles.$autoplayTimeout}
                  showsButtons={false}
                  showsPagination={showsPaginationIndicator}
                  paginationStyle={styles.pagination}
                  activeDotColor="white"
                >
                  <View style={styles.slide}>
                    <Photo
                      style={styles.imageStyle}
                      source={Resources.images.onboardingFirst()}
                    />
                    <SafeAreaView
                      forceInset={slideForceInset}
                      style={styles.slideContent}
                    >
                      <Text style={styles.slideTitle}>
                        {
                          Resources.strings[
                            'initial-screen-onboarding-title-first'
                          ]
                        }
                      </Text>
                    </SafeAreaView>
                  </View>
                  <View style={styles.slide}>
                    <Photo
                      style={styles.imageStyle}
                      source={Resources.images.onboardingSecond()}
                    />
                    <SafeAreaView
                      forceInset={slideForceInset}
                      style={styles.slideContent}
                    >
                      <Text style={styles.slideTitle}>
                        {
                          Resources.strings[
                            'initial-screen-onboarding-title-second'
                          ]
                        }
                      </Text>
                      <Text style={styles.slideDescription}>
                        {
                          Resources.strings[
                            'initial-screen-onboarding-description-second'
                          ]
                        }
                      </Text>
                      <View style={styles.imageContainer}>
                        <Photo
                          style={styles.slideInfoImage}
                          source={Resources.images[
                            Resources.strings['onboarding-chat-image']
                          ]()}
                          resizeMode="contain"
                        />
                      </View>
                      <View style={styles.expander} />
                    </SafeAreaView>
                  </View>
                  <View style={styles.slide}>
                    <Photo
                      style={styles.imageStyle}
                      source={Resources.images.onboardingSecond()}
                    />
                    <SafeAreaView
                      forceInset={slideForceInset}
                      style={styles.slideContent}
                    >
                      <Text style={styles.slideTitle}>
                        {
                          Resources.strings[
                            'initial-screen-onboarding-title-third'
                          ]
                        }
                      </Text>
                      <Text style={styles.slideDescription}>
                        {
                          Resources.strings[
                            'initial-screen-onboarding-description-third'
                          ]
                        }
                      </Text>
                      <View style={styles.imageContainer}>
                        <Photo
                          style={styles.slideInfoImage}
                          source={Resources.images.onboardingRocket()}
                          resizeMode="contain"
                        />
                      </View>
                      <View style={styles.expander} />
                    </SafeAreaView>
                  </View>
                </Swiper>
              </View>
            )
          }
        </IfConfigValue>

        {isDesktopLayout || (
          <IfConfigValue
            path="markup.initial-screen.background"
            equalsTo="splash"
          >
            <Image
              resizeMode="cover"
              source={Resources.images.splash()}
              style={styles.slides}
            />
          </IfConfigValue>
        )}

        <SafeAreaView
          forceInset={{ top: 'always', bottom: 'always' }}
          style={styles.safeArea}
        >
          <ScrollView
            style={styles.scrollViewContainer}
            contentContainerStyle={styles.scrollViewContentContainer}
            showsVerticalScrollIndicator={false}
            alwaysBounceVertical={false}
          >
            <View style={styles.topContainer}>
              {isDesktopLayout ? (
                <View style={styles.header}>
                  <Text style={styles.headerHome}>
                    {Resources.strings['initial-screen-header-home']}
                  </Text>
                  <TouchableOpacity onPress={() => this.followLink('PRIVACY')}>
                    <Text style={styles.headerLink}>
                      {Resources.strings['privacy-policy']}
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => this.followLink('TERMS')}>
                    <Text style={styles.headerLink}>
                      {
                        Resources.strings[
                          'memberships-screen-terms-agreement-terms-link-text'
                        ]
                      }
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => this.followLink('CONTACTS')}>
                    <Text style={styles.headerLink}>
                      {Resources.strings['initial-screen-header-contacts']}
                    </Text>
                  </TouchableOpacity>
                  <SafePicker
                    title={LANGUAGE[lang].label}
                    titleStyle={styles.languagePickerTitle}
                  >
                    <Picker
                      selectedValue={lang}
                      style={styles.languagePicker}
                      onValueChange={this.onLanguageChange}
                    >
                      <Picker.Item
                        label={LANGUAGE['en-US'].label}
                        value={LANGUAGE['en-US'].value}
                      />
                      <Picker.Item
                        label={LANGUAGE.ar.label}
                        value={LANGUAGE.ar.value}
                      />
                    </Picker>
                  </SafePicker>
                </View>
              ) : (
                <>
                  {!alreadyMemberBlockVisible && this.renderSignInButton()}
                  <IfConfigValue
                    path="markup.initial-screen.logo-position"
                    equalsTo="top-left"
                  >
                    {status =>
                      status && (
                        <Image
                          style={styles.logo}
                          source={Resources.images.logo()}
                          {...testId('Logo')}
                        />
                      )
                    }
                  </IfConfigValue>
                </>
              )}
            </View>
            <View style={styles.logoContainer}>
              {isDesktopLayout ? (
                <Image
                  style={styles.logo}
                  source={
                    Platform.OS === 'web'
                      ? Resources.images.logoIconWithText()
                      : Resources.images.logoIcon()
                  }
                  resizeMode="contain"
                  {...testId('Logo')}
                />
              ) : (
                <IfConfigValue
                  path="markup.initial-screen.logo-position"
                  equalsTo="center"
                >
                  {status =>
                    status && (
                      <Image
                        style={styles.logo}
                        source={Resources.images.initialScreenLogo()}
                        {...testId('Logo')}
                      />
                    )
                  }
                </IfConfigValue>
              )}

              {!!Resources.strings['initial-screen-title'] && (
                <Text style={styles.title} {...testId('Initial screen title')}>
                  {Resources.strings['initial-screen-title']}
                </Text>
              )}
            </View>

            <View style={styles.signUpButtonsContainer}>
              {this.renderAuthButtons()}
              {oauthButtonVisible && (
                <TermsAndPrivacyText textStyle={styles.termsAndPrivacyText} />
              )}
            </View>
            {isDesktopLayout
              ? this.renderSignInButton()
              : alreadyMemberBlockVisible && (
                  <View style={styles.alreadyMemberBlock}>
                    <Text style={styles.alreadyMemberQuestion}>
                      {
                        Resources.strings[
                          'initial-screen-already-member-question'
                        ]
                      }
                    </Text>
                    {this.renderSignInButton()}
                  </View>
                )}
            {isDesktopLayout && (
              <>
                <View style={styles.footer}>
                  <View style={styles.storesWrapper}>
                    <TouchableOpacity onPress={() => this.followLink('GOOGLE')}>
                      <Image
                        style={styles.store}
                        source={Resources.images.playMarket()}
                        {...testId('Google Market')}
                      />
                    </TouchableOpacity>

                    <TouchableOpacity onPress={() => this.followLink('APPLE')}>
                      <Image
                        style={styles.store}
                        source={Resources.images.appleStore()}
                        {...testId('Apple Market')}
                      />
                    </TouchableOpacity>
                  </View>
                  <Text style={styles.copyrightText}>
                    {Resources.strings['initial-screen-footer-copyright']}
                  </Text>
                </View>
              </>
            )}
          </ScrollView>
        </SafeAreaView>
        <ActivityIndicator
          style={styles.activityIndicator}
          animating={isLoading}
        />
      </View>
    );
  }
}

export default withConfigValue(Initial, {
  showsPaginationIndicator: 'markup.initial-screen.show-pagination',
  appleAuthEnabled: 'features.apple-auth-enabled',
  googleAuthEnabled: 'features.google-auth-enabled',
});
