import React from 'react';
import Resources from '../../../../../resources';
import Photo, { PHOTO_TRANSFORMATION_TYPE } from '../../../../../components/photo';
import { GALLERY_ITEMS } from '../../../../../models/user.media/controller/gallery-controller';
import { Text, TouchableOpacity, View } from 'react-native';
import styles from './styles';
import PropTypes from "prop-types";

export default class AlbumItem extends React.Component {
    static displayName = 'user.profile.views.photo-gallery.album-item';
    static propTypes = {
        path: PropTypes.string,
        item: PropTypes.shape({
            album: PropTypes.number,
            counter: PropTypes.number,
            thumbnail: PropTypes.object,
            blurred: PropTypes.bool
        }),
        style: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.object,
            PropTypes.array
        ]),
        onPress: PropTypes.func
    };

    shouldComponentUpdate(nextProps) {
        const album = this.props.item && this.props.item.album;
        const counter = this.props.item && this.props.item.counter;
        const blurred = this.props.item && this.props.item.blurred;
        const nextAlbum = nextProps.item && nextProps.item.album;
        const nextCounter = nextProps.item && nextProps.item.counter;
        const nextBlurred = nextProps.item && nextProps.item.blurred;
        return this.props.path !== nextProps.path ||
            album !== nextAlbum ||
            counter !== nextCounter ||
            blurred !== nextBlurred ||
            this.props.style !== nextProps.style;
    }

    onPressed = () => {
        this.props.onPress && this.props.onPress(this.props.item && this.props.item.thumbnail);
    };

    render() {
        const item = this.props.item || {};

        let displayText = '';

        if (item.counter) {
            let textFormat;
            switch (item.album) {
                case GALLERY_ITEMS.VIDEO_ALBUM:
                    textFormat = Resources.strings['gallery-item-video-album-title-format'];
                    break;
                case GALLERY_ITEMS.PRIVATE_PHOTO_ALBUM:
                    textFormat = Resources.strings['gallery-item-private-photos-title-format'];
                    break;
                case GALLERY_ITEMS.PHOTO_ALBUM:
                    textFormat = Resources.strings['gallery-item-photos-title-format'];
                    break;
            }
            displayText = Resources.strings.formatString(textFormat, item.counter);
        }

        const containerStyle = this.props.style
            ? [styles.container, this.props.style]
            : styles.container;

        return (
            <TouchableOpacity style={styles.container} onPress={this.onPressed}>
                <Photo
                    style={styles.photo}
                    path={this.props.path}
                    transformation={PHOTO_TRANSFORMATION_TYPE.DETECT_FACE}
                    blurRadius={item.blurred ? 7 : 0}
                    accessibilityLabel={this.props.accessibilityLabel}
                    testID={this.props.testID} 
                />
                <View style={styles.overlay}>
                    <Text style={styles.title}>{displayText}</Text>
                </View>
            </TouchableOpacity>
        )
    }
};
