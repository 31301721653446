import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $tabBarActiveColor: '$selectedColor',
    $tabBarInactiveColor: '$tabBarIconInactiveColor',

    container: {
        flexDirection: 'row',
        height: 32
    },

    icon: {
        width: 24,
        height: 24,
        alignSelf: 'center'
    },

    activeIcon: {
        tintColor: '$tabBarActiveColor'
    },

    inactiveIcon: {
        tintColor: '$tabBarInactiveColor'
    },

    indicator: {
        marginLeft: -12,
        width: 10,
        height: 10,
        borderRadius: 5,
        borderWidth: 2,
        borderColor: 'white',
        backgroundColor: '$accentColor'
    }
});

export default styles;
