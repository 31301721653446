import React from 'react';
import Resources from 'dating-mobile/src/resources';
import ImageButton from 'dating-mobile/src/components/navigation/views/image-button';
import styles from './styles';
import { StyleSheet } from 'react-native';

export default function navigation(Component) {
  class Navigation extends React.PureComponent {
    static displayName = 'user.screens.customer-support.navigation';

    render() {
      return <Component {...this.props} />;
    }
  }

  Navigation.navigationOptions = ({ navigationOptions }) => ({
    title: Resources.strings['customer-support-screen-title'],
    headerStyle: [navigationOptions.headerStyle, styles.header],
    headerTitleStyle: [navigationOptions.headerTitleStyle, styles.headerTitle],
    headerBackImage: () => (
      <ImageButton
        image={Resources.images.backIcon()}
        tint={StyleSheet.flatten(styles.tint).color}
      />
    ),
  });

  return Navigation;
}
