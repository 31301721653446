import withConfigValue from 'dating-mobile/src/components/config-value';
import withUserMedia from '../../../../models/user.media/controller/gallery-controller';
import SmallGallery from './view';

export default withUserMedia(
    withConfigValue(
        SmallGallery,
        {
            separateAddPhotoButton: 'markup.edit-profile-screen.separate-add-photo-button-enabled',
            videoUploadingEnabled: 'features.video-uploading-enabled'
        }
    )
);
