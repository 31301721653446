import EStyleSheet from 'react-native-extended-stylesheet';

const defaultOnlineIndicatorInTextStyle = {
  marginTop: 1,
  fontSize: 12,
  fontFamily: '$brandFontFamily',
};

const styles = EStyleSheet.create({
  $showOnlineIndicatorUnderName: true,
  $onlineIndicatorVisible: false,
  $userGenderVisible: true,
  $showAge: false,

  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'visible',
  },

  thumbnailContainer: {
    overflow: 'visible',
  },

  // Workaround: Images borderRadius + BackgroundColor doesn't work on Android properly
  thumbnailWrapper: {
    overflow: 'hidden',
    borderRadius: 20,
  },

  thumbnail: {
    width: 32,
    height: 32,
  },

  nameContainer: {
    flexShrink: 1,
    marginLeft: 12,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },

  name: {
    flexShrink: 1,
    fontSize: 17,
    fontFamily: '$brandFontFamily',
    fontWeight: '600',
    color: '$primaryTextColor',
  },

  onlineIndicatorIcon: {
    position: 'absolute',
    bottom: 0,
    right: -2,
  },

  onlineIndicatorText: defaultOnlineIndicatorInTextStyle,

  typingInIndicator: {
    ...defaultOnlineIndicatorInTextStyle,
    color: '$primaryTextColor',
  },

  typingInName: {
    fontSize: 16,
    fontWeight: 'normal',
    color: '$infoTextColor',
  },

  gender: {
    fontSize: 14,
    color: '$primaryTextColor',
    fontFamily: '$brandFontFamily',
  },
});

export default styles;
