import React, { PureComponent } from 'react';
import { View, Text } from 'react-native';
import PropTypes from 'prop-types';

import styles from './styles';
import Photo from 'dating-mobile/src/components/photo';
import Resources from 'dating-mobile/src/resources';

class FindMatchView extends PureComponent {
    static displayName = 'stepped-registration.screens.find-match.view';
    static propTypes = {
        thumbnail: PropTypes.string,
    };

    render() {
        const { id, thumbnail } = this.props;

        return (
            <View style={styles.container}>
                <View style={styles.iconContainer}>
                    <Photo
                        style={styles.animatedIcon}
                        source={Resources.images.findMatchesIconAnimated()}
                    />
                    <Photo key={thumbnail} userId={id} basename={thumbnail} style={styles.icon} />
                </View>
                <Text style={styles.text}>Finding potential matches for you...</Text>
            </View>
        );
    }
}

export default FindMatchView;
