import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { View, Image, Text } from 'react-native';
import styles from './styles';

export default class FeatureItem extends PureComponent {
    static propTypes = {
        icon: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
    };

    render() {
        const { icon, title, description } = this.props;

        return (
            <View style={styles.container}>
                <Image resizeMode={'contain'} style={styles.image} source={icon} />
                <View style={styles.textContainer}>
                    <Text style={styles.title}>{title}</Text>
                    <Text style={styles.description}>{description}</Text>
                </View>
            </View>
        );
    }
}
