import React from 'react';
import { View, Platform, BackHandler, StatusBar } from 'react-native';
import Video from '..';
import styles from './styles';

// import MediaControls, { PLAYER_STATES } from 'react-native-media-controls';

const MediaControls = {};
const PLAYER_STATES = {};

export default class VideoDialog extends React.PureComponent {
  state = {
    props: null,
    currentTime: 0,
    duration: 0,
    isFullScreen: true,
    isLoading: true,
    paused: false,
    playerState: PLAYER_STATES.PLAYING,
  };

  componentDidMount() {
    BackHandler.addEventListener(
      'hardwareBackPress',
      this.onBackButtonPressAndroid,
    );
  }

  componentWillUnmount() {
    BackHandler.removeEventListener(
      'hardwareBackPress',
      this.onBackButtonPressAndroid,
    );
  }

  onBackButtonPressAndroid = () => {
    if (this.state.props) {
      this.close();

      return true;
    } else {
      return false;
    }
  };

  show = props => {
    StatusBar.setHidden(true);

    this.setState({
      props: props,
    });
  };

  onLoadStarted = () => {
    this.videoView && this.videoView.presentFullscreenPlayer();
    this.setState({ isLoading: true });
  };

  onSeek = seek => {
    this.setState({ isLoading: true }, () => {
      this.videoView && this.videoView.seek(seek);
    });
  };

  onPaused = playerState => {
    this.setState({
      paused: !this.state.paused,
      playerState,
    });
  };

  onReplay = () => {
    this.setState(
      {
        currentTime: 0,
        playerState: PLAYER_STATES.PLAYING,
        isLoading: true,
      },
      () => {
        this.videoView && this.videoView.seek(0);
      },
    );
  };

  onProgress = data => {
    if (this.state.duration) {
      if (data.currentTime < this.state.duration) {
        this.setState({
          isLoading: false,
          playerState: PLAYER_STATES.PLAYING,
          currentTime: data.currentTime,
        });
      } else {
        this.setState({
          isLoading: false,
          playerState: PLAYER_STATES.ENDED,
        });
      }
    }
  };

  onLoad = data => {
    this.setState({ duration: data.duration });
  };

  close = () => {
    if (this.videoView) {
      this.videoView.dismissFullscreenPlayer();
    } else {
      this.onFullScreenPlayerDismissed();
    }
  };

  onFullScreenPlayerDismissed = () => {
    this.setState(
      {
        props: null,
        currentTime: 0,
        duration: 0,
        isLoading: true,
        paused: false,
        playerState: PLAYER_STATES.PLAYING,
      },
      () => {
        /*
                TODO
                Пока react-native-video не будет возвращать первоначальный стиль StatusBar
                после выхода из полноэкранного режима
            */
        StatusBar.setHidden(false);
        StatusBar.setBarStyle(styles.$statusBarStyle);
        StatusBar.setBackgroundColor(styles.$statusBarBackgroundColor);
      },
    );
  };

  render() {
    const { ...state } = this.state;

    return (
      !!state.props &&
      !!Object.keys(state.props).length && (
        <View style={styles.container}>
          <Video
            {...state.props}
            onLoad={this.onLoad}
            onLoadStart={this.onLoadStarted}
            onProgress={this.onProgress}
            paused={this.state.paused}
            style={styles.video}
            ref={ref => (this.videoView = ref)}
            onFullscreenPlayerWillDismiss={this.onFullScreenPlayerDismissed}
          />
          {Platform.OS === 'android' && (
            <MediaControls
              duration={this.state.duration}
              isLoading={this.state.isLoading}
              isFullScreen={this.state.isFullScreen}
              mainColor={styles.$controlsColor}
              onFullScreen={this.close}
              onPaused={this.onPaused}
              onReplay={this.onReplay}
              onSeek={this.onSeek}
              onSeeking={this.onSeeking}
              playerState={this.state.playerState}
              progress={this.state.currentTime}
            />
          )}
        </View>
      )
    );
  }
}
