import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withUserController from 'dating-mobile/src/models/user/controllers';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';
import { Purchase } from '../../methods/purchase';
import { Alert } from 'react-native';
import Resources from '../../../resources';
import { PAYMENT_REASON } from 'dating-mobile/src/platform/balance-refiller';

export default function bindController(Component) {
    class Controller extends PureComponent {
        static propTypes = {
            id: PropTypes.string,
            user: PropTypes.object,
            onContinue: PropTypes.func,
            registrationInProgress: PropTypes.func,
        };

        state = { isLoading: false };

        onPurchaseItem = invoice => {
            const { id, registrationInProgress } = this.props;

            this.setState({ isLoading: true });

            Purchase.shared()
                .pay(
                    id,
                    invoice,
                    registrationInProgress ? PAYMENT_REASON.ONBOARDING : PAYMENT_REASON.MANUAL,
                )
                .subscribe(({ success }) => {
                    this.setState({ isLoading: false });
                    if (success) {
                        this.onPaymentCompleted();
                    }
                });
        };

        onPaymentCompleted = () => {
            const { onContinue } = this.props;

            Alert.alert(
                Resources.strings['memberships-screen-success-alert-title'],
                Resources.strings['memberships-screen-success-alert-message'],
                [
                    {
                        text: Resources.strings.ok,
                        style: 'cancel',
                        onPress: () => {
                            onContinue();
                        },
                        onDismiss: () => {
                            onContinue();
                        },
                    },
                ],
            );
        };

        render() {
            const { user } = this.props;
            const { isLoading } = this.state;

            if (!user || !user.gender) {
                return null;
            }

            return (
                <Component
                    {...this.props}
                    gender={user.gender}
                    onContinuePress={this.onPurchaseItem}
                    isLoading={isLoading}
                />
            );
        }
    }

    return withIdentityId(withUserController(Controller), 'id');
}
