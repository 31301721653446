import React from 'react';
import { RxController } from 'dating-mobile/src/components/rx-controller';
import PropTypes from 'prop-types';
import { CreditCardsRepo } from '@sdv/domain/payment/credit-cards-repo';
import { distinctUntilChanged, first, map, switchMap } from 'rxjs/operators';
import creditCardType from 'credit-card-type';

import withIdentityId from '../../../models/identity/controller/id';

export default function createController(Component) {
  class CreditCardPicker extends RxController {
    static propTypes = {
      userId: PropTypes.string.isRequired,
      onCardPick: PropTypes.func.isRequired,
      onNewCardPress: PropTypes.func.isRequired,
    };

    constructor(props) {
      super(props);
      this.state = { selected: null, cards: [] };

      this.cards = this.rxProps.pipe(
        map(it => it.userId),
        distinctUntilChanged(),
        switchMap(userId => {
          return CreditCardsRepo.shared(userId).cards();
        }),
      );
    }

    componentDidMount() {
      super.componentDidMount();

      this.addSubscription(
        this.cards.pipe(first()).subscribe(
          cards => {
            const names = cards.map(card => {
              const cardTypes = creditCardType(card.number);
              const [best] = cardTypes;

              return { ...card, type: best?.type };
            });

            this.setState(oldState => ({ ...oldState, cards: names }));
          },
          () => {},
        ),
      );
    }

    componentDidUpdate(prevProps, prevState) {
      super.componentDidUpdate();
      const { cards } = this.state;

      if (cards !== prevState.cards) {
        this.addSubscription(
          this.cards
            .pipe(
              map(list => list.find(card => card.isLastUsed)),
              distinctUntilChanged(),
            )
            .subscribe(card => {
              if (card) {
                const cardIndex = cards.findIndex(
                  ({ number }) => number === card?.number,
                );

                this.props.onCardPick(card);
                this.setState({
                  selected: cardIndex,
                });
              } else if (cards.length) {
                this.props.onCardPick(cards[0]);
                this.setState({
                  selected: 0,
                });
              }
            }),
        );
      }
    }

    onSelect = index => {
      const { cards } = this.state;

      if (index === cards.length) {
        this.props.onNewCardPress();
      } else {
        const { type, ...card } = cards[index];

        this.props.onCardPick(card);
        this.setState({
          selected: index,
        });
      }
    };

    render() {
      return (
        <Component
          onSelect={this.onSelect}
          selected={this.state.selected}
          cards={this.state.cards}
        />
      );
    }
  }

  return withIdentityId(CreditCardPicker);
}
