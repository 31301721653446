import React from 'react';
import PropTypes from 'prop-types';
import { withNavigation } from 'react-navigation';
import { BackHandler } from 'react-native';

export default function bindNavigation(Component) {
  class Navigation extends React.Component {
    static displayName = 'stepped-registration.controllers.navigation';

    static propTypes = {
      navigation: PropTypes.object.isRequired,
    };

    componentDidMount() {
      this.backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        () => true,
      );
    }

    componentWillUnmount() {
      this.backHandler.remove();
    }

    onComplete = () => {
      const { navigation } = this.props;
      const onComplete = navigation.getParam('onComplete');

      if (onComplete) {
        onComplete();
      }
    };

    render() {
      return <Component {...this.props} onComplete={this.onComplete} />;
    }
  }

  Navigation.navigationOptions = () => {
    return {
      headerShown: false,
    };
  };

  return withNavigation(Navigation);
}
