import PropTypes from 'prop-types';
import { Alert } from 'react-native';
import url from 'url';
import withPermissions from 'dating-mobile/src/components/permissions';
import CallStatusModel from 'dating-mobile/src/models/call.status';
import CallingModel from 'dating-mobile/src/models/call.calling';
import UserModel from 'dating-mobile/src/models/user/model';
import PresenceModel from '@sdv/domain/user.presence';
import qs from 'qs';
import OpenAppSettings from 'react-native-app-settings';
import Resources from 'dating-mobile/src/resources';
import Service from 'dating-mobile/src/services/service';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';
import { CallKeeper } from 'dating-mobile/src/call/services/call-keeper';
import deepLinking from './deep-linking';
import withConfigValue from '../../../components/config-value';

class CallDeeplinkingService extends Service {
  static displayName = 'services.functional.deep-linked-call';

  static contextTypes = {
    flux: PropTypes.object,
  };

  static propTypes = {
    userId: PropTypes.string,
    cameraStatus: PropTypes.string,
    videoChatEnabled: PropTypes.bool,
    audioCallsEnabled: PropTypes.bool,
  };

  componentDidMount() {
    deepLinking.addDeepLinkListener(this.lastDeepLink);
  }

  componentWillUnmount() {
    deepLinking.removeDeepLinkListener(this.lastDeepLink);
  }

  lastDeepLink = async (uri, payload) => {
    if (!this.props.videoChatEnabled && !this.props.audioCallsEnabled) {
      return;
    }

    const parsedUrl = url.parse(uri);

    if (!parsedUrl.pathname) {
      return;
    }

    const components = parsedUrl.pathname.split('/');

    if (
      components[1] !== 'dialogs' ||
      (components[3] !== 'video-chat' && components[3] !== 'call')
    ) {
      return;
    }

    const attendeeId = components[2];

    if (components[4] === 'incoming') {
      if (!payload || !payload.mediaMessageUri || !attendeeId) {
        return;
      }

      await CallKeeper.shared().initIncomingCall(
        payload.mediaMessageUri,
        attendeeId,
      );

      if (payload.shouldAnswerCall) {
        await CallKeeper.shared().onAnswerCall();
      }

      return;
    }

    if (components[4] === 'outgoing') {
      this.dial(this.props.userId, attendeeId);
    }
  };

  dial = (user, attendee) => {
    const callStatusModel = this.context.flux.get(CallStatusModel, user);

    if (callStatusModel.store.getState().status !== 'idle') {
      return;
    }

    const presenceModel = this.context.flux.get(PresenceModel, attendee);

    presenceModel.actions.get(() => {
      const attendeePresence = presenceModel.store.getState();

      const attendeeHasCamera = (
        (attendeePresence && attendeePresence.devices) ||
        []
      ).find(item => item.name === 'cam');
      const showVideo = this.props.videoChatEnabled; // TODO: Add another config value

      const dial = () => {
        const modelId = qs.stringify({ user, attendee });
        const callingModel = this.context.flux.get(CallingModel, modelId);

        callingModel.actions.dial({
          audioOnly: !showVideo,
          requestRemoteVideo: attendeeHasCamera,
        });
      };

      const shouldShowAudioPermissionsAlert =
        this.props.microphoneStatus === 'denied' ||
        this.props.microphoneStatus === 'restricted';

      if (!showVideo && shouldShowAudioPermissionsAlert) {
        this.showPermissionsAlert(true, () => OpenAppSettings.open());
      } else if (showVideo && !attendeeHasCamera) {
        const userModel = this.context.flux(UserModel, attendee);

        userModel.actions.get(() => {
          const attendeeName = userModel.store.getState().name;

          this.showOneWayCallAlert(attendeeName, () => {
            const userForbidCamera =
              this.props.cameraStatus === 'denied' ||
              this.props.cameraStatus === 'restricted';

            if (userForbidCamera) {
              this.showPermissionsAlert(false, () => OpenAppSettings.open());
            } else {
              dial();
            }
          });
        });
      } else {
        dial();
      }
    });
  };

  showOneWayCallAlert = (attendeeName, callback) => {
    Alert.alert(
      Resources.strings.formatString(
        Resources.strings['call-one-way-camera-start-alert-message'],
        attendeeName,
      ),
      null,
      [
        {
          text:
            Resources.strings[
              'call-one-way-camera-start-alert-share-action-title'
            ],
          onPress: callback,
        },
        {
          text: Resources.strings.cancel,
          style: 'cancel',
        },
      ],
      { cancelable: false },
    );
  };

  showPermissionsAlert = (audioOnly, confirmCallback, rejectCallback) => {
    Alert.alert(
      Resources.strings[
        audioOnly
          ? 'audio-call-without-permissions-alert-message'
          : 'call-without-permissions-alert-message'
      ],
      null,
      [
        {
          text:
            Resources.strings[
              'call-without-permissions-alert-open-settings-action-title'
            ],
          onPress: confirmCallback,
        },
        {
          text: Resources.strings.cancel,
          style: 'cancel',
          onPress: rejectCallback,
        },
      ],
      { cancelable: false },
    );
  };
}

export default withPermissions(
  withConfigValue(withIdentityId(CallDeeplinkingService), {
    videoChatEnabled: 'features.video-chat-enabled',
    audioCallsEnabled: 'features.audio-calls-enabled',
  }),
  {
    resultProps: {
      cameraStatus: 'camera',
      microphoneStatus: 'microphone',
    },
    returnStatus: true,
  },
);
