import React from 'react';
import PropTypes from 'prop-types';
import { View, StatusBar } from 'react-native';
import { withNavigationFocus } from 'react-navigation';
import Session from '@sdv/domain/authorization/session';
import * as ROUTES from 'dating-mobile/src/routing/router/constants';
import StreamsDiscoveryComponent from '../views/streams-discovery';
import withStreamsDiscovery from '../controllers/streams-discovery-controller';

import styles from './styles';
import {
  CATEGORIES_PRESET,
  numberOfCategoriesInPreset,
} from '../views/stream-categories';

const StreamsDiscovery = withNavigationFocus(
  withStreamsDiscovery(StreamsDiscoveryComponent),
);

export default class LiveStreamsDiscoveryScreen extends React.Component {
  static navigationOptions = ({ navigation, navigationOptions }) => ({
    headerShown: !(navigation.getParam('navigationBarHidden') !== false),
    headerTransparent:
      numberOfCategoriesInPreset(navigation.getParam('categoriesPreset')) > 1,
    headerStyle: [navigationOptions.headerStyle, styles.header],
    headerTitleStyle: [navigationOptions.headerTitleStyle, styles.headerTitle],
    title: navigation.getParam('title') || '',
  });

  static propTypes = {
    navigation: PropTypes.object.isRequired,
  };

  static contextTypes = {
    flux: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.canShowStream = true;
  }

  state = { isModerator: false };

  componentDidMount() {
    const { navigation } = this.props;

    this.subscription = Session.shared().userIsModerator.subscribe(
      isModerator => {
        this.setState({ isModerator });
      },
      () => {},
    );

    this.statusBarListener = navigation.addListener('didFocus', () => {
      this.canShowStream = true;
      StatusBar.setBarStyle(styles.$statusBarStyle);
      StatusBar.setBackgroundColor(styles.$statusBarBackgroundColor);
    });
  }

  componentWillUnmount() {
    if (this.subscription) this.subscription.unsubscribe();
    this.statusBarListener.remove();
  }

  showFollowTop = params => {
    const { navigation } = this.props;

    navigation.navigate(ROUTES.FOLLOW_TOP, params);
  };

  showStream = itemId => {
    const { navigation } = this.props;

    // Click on second stream while first is opening cause react-navigation problems and crash. Prevent it.
    if (this.canShowStream) {
      this.canShowStream = false;
      navigation.navigate(ROUTES.STREAM, { id: itemId });
    }
  };

  startStream = () => {
    const { navigation } = this.props;

    navigation.navigate(ROUTES.START_STREAM_MODAL);
  };

  setNavigationBarTitle = title => {
    const { navigation } = this.props;

    if (navigation.getParam('title') !== title) {
      navigation.setParams({ title });
    }
  };

  setNavigationBarHidden = hidden => {
    const { navigation } = this.props;

    if (navigation.getParam('navigationBarHidden') !== hidden) {
      setTimeout(() => {
        navigation.setParams({ navigationBarHidden: hidden });
      }, 0);
    }
  };

  render() {
    const { navigation } = this.props;
    const { isModerator } = this.state;
    let categoriesPreset = navigation.getParam('categoriesPreset');
    const followingInSeparateTab = navigation.getParam(
      'followingInSeparateTab',
    );

    if (!categoriesPreset) {
      if (followingInSeparateTab) {
        categoriesPreset = isModerator
          ? CATEGORIES_PRESET.NO_FOLLOWING_LIST_FOR_MODERATOR
          : CATEGORIES_PRESET.NO_FOLLOWING_LIST;
      } else {
        categoriesPreset = isModerator
          ? CATEGORIES_PRESET.FOR_MODERATOR
          : CATEGORIES_PRESET.FULL_LIST;
      }
    }

    return (
      <View style={styles.container}>
        <StreamsDiscovery
          showFollowTop={this.showFollowTop}
          showStream={this.showStream}
          startStream={this.startStream}
          setHeaderHidden={this.setNavigationBarHidden}
          setHeaderTitle={this.setNavigationBarTitle}
          categoriesPreset={categoriesPreset}
          safeAreaInsets={navigation.getParam('safeAreaInsets')}
        />
      </View>
    );
  }
}
