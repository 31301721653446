import React from 'react';
import PropTypes from 'prop-types';
import {
  View,
  Text,
  Image,
  TouchableWithoutFeedback,
  TouchableOpacity,
} from 'react-native';
import MessageText from 'dating-mobile/src/dialogs/common/views/message-text';
import testId from 'dating-mobile/src/utils/test-id';
import Resources from 'dating-mobile/src/resources';
import SoundPlayer from 'dating-mobile/src/native-modules/sound-player';
import Photo from '../../../components/photo';
import IfConfigValue from '../../../components/config-value/if';
import withUser from '../../../models/user/controllers';
import withConfigValue from '../../../components/config-value';

import styles from './style';

import contactIcon from './assets/icon_contact.png';
import giftIcon from './assets/gift.png';

class InvitationContent extends React.Component {
  static displayName = 'notifications.popup.chat-invitation.view';

  static propTypes = {
    id: PropTypes.string,
    user: PropTypes.object,
    notification: PropTypes.object.isRequired,
    fastAnswer: PropTypes.object,
    fastAnswerDisplayTime: PropTypes.number,
    openChatAndDismiss: PropTypes.func.isRequired,
    onReadyForDisplay: PropTypes.func.isRequired,
  };

  ready = false;

  componentDidMount() {
    const {
      user,
      onReadyForDisplay,
      fastAnswer,
      fastAnswerDisplayTime,
    } = this.props;

    if (this.isCheer()) {
      SoundPlayer.playSoundFile('gift_received', 'mp3', false);
    }

    if (user?.name) {
      this.ready = true;
      onReadyForDisplay(fastAnswer ? fastAnswerDisplayTime : undefined);
    }
  }

  componentDidUpdate() {
    const {
      user,
      onReadyForDisplay,
      fastAnswer,
      fastAnswerDisplayTime,
    } = this.props;

    if (!this.ready && user?.name) {
      this.ready = true;
      onReadyForDisplay(fastAnswer ? fastAnswerDisplayTime : undefined);
    }
  }

  openChat = () => {
    const { id, openChatAndDismiss } = this.props;

    if (!id) {
      return;
    }

    openChatAndDismiss(id);
  };

  isCheer() {
    const { notification } = this.props;

    return notification?.payload?.meta?.reference?.startsWith('cheer');
  }

  render() {
    const { id, user, notification } = this.props;
    const basename = user?.thumbnail || user?.['thumbnail-pending'];
    const username = user?.name;
    const age = user?.birthday?.age;

    let profileText = '';

    if (username) {
      profileText += username;
    }

    if (age && profileText) {
      profileText += `, ${age} `;
    }

    const isContact = notification?.type === 'message';
    const contactIconStyle = isContact ? { opacity: 1 } : { opacity: 0 };

    return (
      <TouchableWithoutFeedback
        onPress={this.openChat}
        {...testId('Chat message notification')}
      >
        <View style={styles.container}>
          <View style={styles.photoContainer}>
            <Photo
              style={styles.photo}
              userId={id}
              basename={basename}
              round
              placeholder="avatar"
              {...testId(
                Resources.strings['user-thumbnail-accessibility-label'],
              )}
            />
            <IfConfigValue
              path="features.chat-requests-enabled"
              equalsTo={true}
            >
              <Image
                style={[styles.contactIcon, contactIconStyle]}
                source={contactIcon}
                {...testId(
                  Resources.strings['contact-indicator-accessibility-label'],
                )}
              />
            </IfConfigValue>
          </View>
          <View style={styles.textContainer}>
            <Text
              numberOfLines={1}
              style={styles.username}
              {...testId(Resources.strings['user-name-accessibility-label'])}
            >
              {profileText}
            </Text>
            <MessageText
              textStyle={styles.message}
              numberOfLines={2}
              data={notification}
              {...testId(Resources.strings['message-text-accessibility-label'])}
            />
          </View>
          {this.isCheer() && (
            <Image style={styles.giftIcon} source={giftIcon} />
          )}
          {styles.$replyButtonVisible && (
            <TouchableOpacity
              style={styles.replyButton}
              onPress={this.openChat}
            >
              <Text style={styles.replyButtonTitle}>
                {Resources.strings['chat-notification-reply-button-title']}
              </Text>
            </TouchableOpacity>
          )}
        </View>
      </TouchableWithoutFeedback>
    );
  }
}

const InvitationContentComponent = withUser(
  withConfigValue(InvitationContent, {
    fastAnswerDisplayTime: 'notifications.display-time.fast-answer',
  }),
);

// eslint-disable-next-line react/no-multi-comp
class Invitation extends React.Component {
  render() {
    // eslint-disable-next-line react/prop-types
    const { notification } = this.props;
    const userId = notification?.['user-id'];

    return <InvitationContentComponent id={userId} {...this.props} />;
  }
}

export default Invitation;
