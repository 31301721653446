import React from 'react';
import { Image, Text } from 'react-native';
import PropTypes from 'prop-types';
import Resources from 'dating-mobile/src/resources';
import { INBOX_MODES_TITLES } from 'dating-mobile/src/inbox/inbox-modes';
import { HeaderTitle } from 'react-navigation-stack';
import styles from './styles';

export default class InboxModelHeaderTitle extends React.PureComponent {
  static displayName = 'inbox.views.title-button.view';

  static propTypes = {
    selectedMode: PropTypes.string,
    tap: PropTypes.func,
    selected: PropTypes.bool,
  };

  onPress = () => {
    const { tap } = this.props;

    if (tap) {
      tap();
    }
  };

  render() {
    const { selected, selectedMode } = this.props;
    const arrowStyle = selected ? styles.arrowToTop : styles.arrowToBottom;
    const title = INBOX_MODES_TITLES[selectedMode] || '';

    return (
      <HeaderTitle {...this.props} style={styles.header}>
        <Text {...this.props} onPress={this.onPress}>
          <Text numberOfLines={1}>{title}&nbsp;</Text>
          <Image
            style={arrowStyle}
            source={Resources.images.navigationArrowIcon()}
          />
        </Text>
      </HeaderTitle>
    );
  }
}
