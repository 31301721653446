import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  container: {
    height: 50,
    width: '100%',
    position: 'relative',
    paddingBottom: 10,
    borderWidth: 1,
    borderColor: 'grey',
    borderRadius: 5,
  },

  dropContainer: {
    position: 'absolute',
    borderWidth: 1,
    borderColor: 'grey',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    top: 45,
    left: -1,
    backgroundColor: 'white',
    zIndex: 9999,
    width: '100.5%',
  },
});

export default styles;
