import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import { View, StatusBar, ActivityIndicator } from 'react-native';
import { SafeAreaView } from 'react-navigation';
import Cheers from '../views/cheers';
import Can from '../../../models/user.ability/controller';

export default class ScreenView extends React.Component {
    static displayName = 'dialogs.cheers.screen.view';
    static propTypes = {
        onCheerSelected: PropTypes.func.isRequired,
        isSending: PropTypes.bool
    };

    render() {
        return (
            <SafeAreaView forceInset={{ top: 'never', bottom: 'always' }} style={styles.container}>
                <StatusBar
                    barStyle={styles.$statusBarStyle}
                    backgroundColor={styles.$statusBarBackgroundColor}
                    hidden={false}
                />
                <View style={styles.cheersContainer}>
                    <Can do={'create'} of={'message'}>
                        {
                            (status) => (
                                <Cheers onCheerSelected={(cheer) => this.props.onCheerSelected(status, cheer)} />
                            )
                        }
                    </Can>
                </View>
                <View style={styles.activityIndicatorContainer} pointerEvents={'box-none'}>
                    <ActivityIndicator animating={this.props.isSending} />
                </View>
            </SafeAreaView>
        );
    }

}