export const get = async key => localStorage.getItem(key);

export const set = async (key, value) => localStorage.setItem(key, value);

export const clear = async key => localStorage.removeItem(key);

export default {
  get,
  set,
  clear,
};
