import BalanceRefiller, {
  PAYMENT_REASON,
} from '@sdv/domain/app/balance-refiller';
import { Observable, Subscription, of, defer, bindCallback, merge } from 'rxjs';
import {
  map,
  shareReplay,
  switchMap,
  timeoutWith,
  delay,
} from 'rxjs/operators';
import flux from '@sdv/domain/app/flux';
import EventsModel, {
  EVENT_TYPE,
  getId,
} from 'dating-mobile/src/models/user.events/model';
import { ProfileBoostingResult } from './result';
import ServerDate from '../../api/date';

const MILLISECONDS_IN_SECOND = 1000;

const isBoostEndTimeValid = boostEndTime => {
  if (!boostEndTime) {
    return false;
  }

  const timeParsed = boostEndTime.valueOf();

  return !Number.isNaN(timeParsed) && timeParsed > Date.now();
};

export class ProductProfileBooster {
  constructor(userId) {
    this.disposeBag = new Subscription();

    const boostEvent = defer(() => {
      const model = flux.get(EventsModel, getId(userId, EVENT_TYPE.BOOST));

      model.actions.get();

      return model.store.rxState();
    }).pipe(
      map(({ events: [event] }) => event),
      shareReplay(1),
    );

    const serverDate = bindCallback(ServerDate(flux.api).now);
    // eslint-disable-next-line no-unused-vars
    const timeDelta = serverDate().pipe(
      timeoutWith(3000, of(null)),
      map(date => (date ? Date.now() - date : 0)),
    );

    this.endTime = boostEvent.pipe(
      map(event =>
        event
          ? new Date(
              new Date(event.freshed).valueOf() +
                event.payload?.ttl * MILLISECONDS_IN_SECOND,
            )
          : null,
      ),
      switchMap(endTime =>
        isBoostEndTimeValid(endTime)
          ? merge(of(endTime), of(null).pipe(delay(endTime)))
          : of(null),
      ),
    );
  }

  boost() {
    const purchasedProduct = new Observable(observer => {
      BalanceRefiller.refillBalance(
        PAYMENT_REASON.BOOST_PROFILE,
        product => {
          observer.next(product);
          observer.complete();
        },
        () => {
          observer.next(null);
          observer.complete();
        },
      );
    });

    // TODO:
    return purchasedProduct.pipe(map(() => ProfileBoostingResult.success));
  }
}
