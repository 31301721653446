import React from 'react';
import PropTypes from 'prop-types';
import { View, Image } from 'react-native';

import Resources from 'dating-mobile/src/resources';
import IfConfigValue from 'dating-mobile/src/components/config-value/if';
import PushNotificationsIndication from '@sdv/domain/notifications/disabled-push-notifications-indicator';
import NotificationBadge from 'dating-mobile/src/components/notification-badge';

import styles from './styles';

class AccountTabBarItem extends React.Component {
    static displayName = 'components.navigation.account-tab-bar-item';

    static propTypes = {
        focused: PropTypes.bool.isRequired,
        boostProfileEnabled: PropTypes.bool,
        userIsBoosted: PropTypes.bool,
        badge: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    };

    constructor(props) {
        super(props);
        this.state = {
            disabledPushNotificationsIndicated: false,
        };
    }

    componentDidMount() {
        this.disabledPushNotificationsIndicatedSubscription = PushNotificationsIndication.shared().disabledPushNotificationsIndicated.subscribe(
            disabledPushNotificationsIndicated => {
                this.setState({
                    disabledPushNotificationsIndicated,
                });
            },
        );
    }

    componentWillUnmount() {
        if (this.disabledPushNotificationsIndicatedSubscription) {
            this.disabledPushNotificationsIndicatedSubscription.unsubscribe();
        }
    }

    renderPushNotificationsIndicator = () => {
        const { boostProfileEnabled, userIsBoosted } = this.props;
        const { disabledPushNotificationsIndicated } = this.state;
        const boostProfileIndicatorVisible = boostProfileEnabled && userIsBoosted;

        return !boostProfileIndicatorVisible && disabledPushNotificationsIndicated ? (
            <IfConfigValue
                key="push-notifications-indicator"
                path="features.push-notifications-settings-enabled"
                equalsTo={true}
            >
                <NotificationBadge style={styles.pushNotificationsIndicator} />
            </IfConfigValue>
        ) : null;
    };

    renderCounter = () => {
        const { badge } = this.props;

        return badge ? (
            <IfConfigValue
                key="counter"
                path="features.authentication-reminding-enabled"
                equalsTo={true}
            >
                <NotificationBadge style={styles.textContainer} text={badge} />
            </IfConfigValue>
        ) : null;
    };

    renderIndicators = () => {
        return [this.renderPushNotificationsIndicator(), this.renderCounter()];
    };

    render() {
        const { focused, boostProfileEnabled, userIsBoosted } = this.props;
        const image = focused
            ? Resources.images.tabBarProfile()
            : Resources.images.tabBarProfileDisabled();
        const imageStyle = [styles.image, focused ? styles.iconActive : styles.iconInactive];

        const boostProfileIndicatorVisible = boostProfileEnabled && userIsBoosted;

        return (
            <View style={styles.container}>
                <Image style={imageStyle} source={image} />
                {boostProfileIndicatorVisible && (
                    <NotificationBadge style={styles.boostProfileIndicator} />
                )}
                {this.renderIndicators()}
            </View>
        );
    }
}

export default AccountTabBarItem;
