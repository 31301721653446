import React from 'react';
import PropTypes from 'prop-types';
import { View, FlatList } from 'react-native';
import styles from './styles';
import Item, { getHeight } from './item';

export default class FreeStartMessagesList extends React.PureComponent {
    static displayName = 'user.free-start-messages.list-view';
    static propTypes = {
        users: PropTypes.array,
        onContentSizeChange: PropTypes.func,
        ListFooterComponent: PropTypes.func,
        ListHeaderComponent: PropTypes.func,
        listStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.number
        ]),
        extraData: PropTypes.any
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    renderItem = ({ item, index }) => {
        if (!this.state.itemStyle) {
            return null;
        }
        const numberOfColumns = styles.$numberOfColumns;
        const rowIndex = index % numberOfColumns;
        const isMiddleItem = (numberOfColumns - rowIndex - 1) === rowIndex;

        return (
            <View style={this.state.itemStyle}>
                <Item
                    {...item}
                    isMiddleItem={isMiddleItem}
                />
            </View>
        )
    };

    onListLaidOut = (event) => {
        const { width } = event.nativeEvent.layout;

        if (!width || this.listWidth === width) {
            return;
        }

        this.listWidth = width;

        const itemWidth = (this.listWidth - styles._list.paddingLeft - styles._list.paddingRight) / styles.$numberOfColumns;
        const itemHeight = getHeight(itemWidth);

        this.setState({
            itemStyle: { width: itemWidth, height: itemHeight }
        });
    };

    getItemLayout = (data, rowIndex) => {
        if (!this.state.itemStyle) {
            return undefined;
        }

        return { length: this.state.itemStyle.height, offset: this.state.itemStyle.height * rowIndex, index: rowIndex };
    };

    renderHeader = () => {
        const ListHeaderComponent = this.props.ListHeaderComponent;
        return (
            <View style={styles.headerContainer}>
                {
                    !!ListHeaderComponent && (
                        <ListHeaderComponent />
                    )
                }
                <View style={styles.header} />
            </View>
        )
    };

    render() {
        const displayData = this.state.itemStyle ? this.props.users : [];
        return (
            <View style={styles.container}>
                <FlatList
                    onLayout={this.onListLaidOut}
                    onContentSizeChange={this.props.onContentSizeChange}
                    getItemLayout={this.getItemLayout}
                    ListHeaderComponent={this.renderHeader}
                    ListFooterComponent={this.props.ListFooterComponent}
                    style={styles.list}
                    data={displayData}
                    renderItem={this.renderItem}
                    keyExtractor={item => item.id}
                    alwaysBounceVertical={false}
                    numColumns={styles.$numberOfColumns}
                    extraData={this.props.extraData}
                />
            </View>
        );
    }
}
