import React, { useState } from 'react';
import { FlatList, View } from 'react-native';
import PropTypes from 'prop-types';

import DropDownItem from './item';

import styles from './styles';

function DropDown({ items, onSelect, value, placeholder }) {
  const selected = items[value] || { value: placeholder };
  const [isOpened, setIsOpened] = useState(false);

  function toggleDropDown() {
    setIsOpened(oldState => !oldState);
  }

  return (
    <View style={styles.container}>
      <DropDownItem
        {...selected}
        isOpened={isOpened}
        root
        onPress={toggleDropDown}
      />

      {isOpened && (
        <View style={styles.dropContainer}>
          <FlatList
            data={items}
            renderItem={({ item, index }) => {
              return (
                <DropDownItem
                  onPress={() => {
                    onSelect(index, items[index]);
                    toggleDropDown();
                  }}
                  selected={index === value}
                  {...item}
                />
              );
            }}
            keyExtractor={item => String(item.key)}
          />
        </View>
      )}
    </View>
  );
}

DropDown.propTypes = {
  items: PropTypes.array,
  onSelect: PropTypes.func,
  value: PropTypes.number,
  placeholder: PropTypes.string,
};

export default DropDown;
