import actions, { MEDIA_TYPE as INTERNAL_MEDIA_TYPE } from './actions';
import store from './store';
import { getId as internalGetId, parseId as internalParseId } from '@sdv/domain/dialogs.messages';

export default {
    store: store,
    actions: actions
};

export const MEDIA_TYPE = INTERNAL_MEDIA_TYPE;

export const getId = (...args) => internalGetId(...args);

export const parseId = (...args) => internalParseId(...args);
