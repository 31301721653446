import url from 'url';
import qs from 'qs';
import { NavigationActions, StackActions } from 'react-navigation';
import * as ROUTES from 'dating-mobile/src/routing/router/constants';
import Identity from '@sdv/domain/identity/model';
import flux from '@sdv/domain/app/flux';
import Navigator from './navigator';

function getScreens(parsedUrl) {
  if (parsedUrl.hostname) {
    // Handle universal links by the following pattern: /app/*
    return parsedUrl.pathname.replace(/^\/app\//, '/').split('/');
  }

  return parsedUrl.pathname.split('/');
}

export function navigateUnauthorized(deeplink) {
  const parsedUrl = url.parse(deeplink);

  if (!parsedUrl.pathname) {
    return false;
  }

  const screens = getScreens(parsedUrl);

  if (screens[1] === 'reset-password' && screens[2] && screens[3]) {
    Navigator.navigate(ROUTES.CREATE_NEW_PASSWORD, {
      email: screens[2],
      token: screens[3],
    });

    return true;
  }

  return false;
}

export default function navigate(deeplink) {
  const parsedUrl = url.parse(deeplink);
  const query = qs.parse(parsedUrl.query);

  if (!parsedUrl.pathname) {
    return;
  }

  const screens = getScreens(parsedUrl);
  const actions = [];

  let tabName = null;
  let modalRoute = null;

  if (screens[1] === 'people') {
    tabName = ROUTES.FEED_TAB;
    actions.push(
      NavigationActions.navigate({ routeName: ROUTES.FEED, params: {} }),
    );
    if (screens[2]) {
      actions.push(
        NavigationActions.navigate({
          routeName: ROUTES.PROFILE,
          params: { id: screens[2] },
        }),
      );
      if (screens[3] === 'photos') {
        const basename = screens[4] || undefined;

        actions.push(
          NavigationActions.navigate({
            routeName: ROUTES.GALLERY,
            params: { id: screens[2], basename },
          }),
        );
      }
    }
  } else if (screens[1] === 'purchase') {
    if (screens[2] === 'membership') {
      modalRoute = {
        routeName: ROUTES.MEMBERSHIP_MODAL,
        params: { cancelButtonVisible: true },
      };
    } else {
      tabName = ROUTES.PURCHASE_TAB;
      actions.push(
        NavigationActions.navigate({
          routeName: ROUTES.PURCHASE,
        }),
      );
    }
  } else if (screens[1] === 'favorites') {
    tabName = ROUTES.CONTACTS_TAB;
    actions.push(
      NavigationActions.navigate({ routeName: ROUTES.CONTACTS, params: {} }),
    );
  } else if (screens[1] === 'invitations') {
    tabName = ROUTES.CONTACTS_TAB;
    actions.push(
      ...[
        NavigationActions.navigate({ routeName: ROUTES.CONTACTS }),
        NavigationActions.navigate({ routeName: ROUTES.CHAT_REQUESTS }),
      ],
    );
  } else if (screens[1] === 'live-streams') {
    tabName = ROUTES.LIVE_STREAMS_DISCOVERY_TAB;
    actions.push(
      NavigationActions.navigate({ routeName: ROUTES.DISCOVERY, params: {} }),
    );
    if (screens[2]) {
      actions.push(
        NavigationActions.navigate({
          routeName: ROUTES.STREAM,
          params: { id: screens[2] },
        }),
      );
    }
  } else if (screens[1] === 'rate-app') {
    modalRoute = { routeName: ROUTES.RATE_APP_MODAL };
  } else if (screens[1] === 'profile' && screens[2]) {
    modalRoute = {
      routeName: ROUTES.PROFILE,
      params: { id: screens[2], preview: true },
    };
  } else if (screens[1] === 'chat' || screens[1] === 'invite') {
    tabName = ROUTES.CONTACTS_TAB;
    actions.push(
      NavigationActions.navigate({ routeName: ROUTES.CONTACTS, params: {} }),
    );
    if (screens[2]) {
      actions.push(
        NavigationActions.navigate({
          routeName: ROUTES.CHAT,
          params: { id: screens[2], invitationCode: screens[3] },
        }),
      );
    }
  } else if (screens[1] === 'my-profile') {
    tabName = ROUTES.ACCOUNT_TAB;
  } else if (screens[1] === 'purchase-top-boost') {
    modalRoute = { routeName: ROUTES.SPECIAL_OFFER_MODAL };
  } else if (query.action === 'invite' && query.userId && query.code) {
    const { id } = flux.get(Identity).store.getState();

    if (id !== query.userId) {
      tabName = ROUTES.CONTACTS_TAB;
      actions.push(
        NavigationActions.navigate({ routeName: ROUTES.CONTACTS, params: {} }),
      );
      actions.push(
        NavigationActions.navigate({
          routeName: ROUTES.CHAT,
          params: { id: query.userId, invitationCode: query.code },
        }),
      );
    }
  }

  if (tabName) {
    const action = actions.length
      ? StackActions.reset({
          index: actions.length - 1,
          actions,
        })
      : null;

    Navigator.navigate(tabName, {}, action);
  } else if (modalRoute) {
    Navigator.navigate(modalRoute.routeName, modalRoute.params);
  }
}
