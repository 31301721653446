import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    travels: {
        fontFamily: '$brandFontFamily',
        fontSize: 12,
        marginTop: 8,
        color: '$secondaryTextColor'
    }
});

export default styles;
