import EStyleSheet from 'react-native-extended-stylesheet';

const defaultTextStyle = {
  fontSize: 24,
  color: '$brandColor',
  fontFamily: '$brandFontFamily',
};

const styles = EStyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    ...defaultTextStyle,
  },
  timer: {
    ...defaultTextStyle,
  },
});

export default styles;
