import React from 'react';
import PropTypes from 'prop-types';
import { BackHandler, StyleSheet } from 'react-native';
import { withNavigationFocus } from 'react-navigation';
import Resources from 'dating-mobile/src/resources';
import ImageButton from 'dating-mobile/src/components/navigation/views/image-button';

import styles from './styles';

export default function bindNavigation(Component) {
  class Navigation extends React.Component {
    static displayName = 'payment.screens.purchase.navigation';

    static propTypes = {
      navigation: PropTypes.object.isRequired,
    };

    componentDidMount() {
      BackHandler.addEventListener('hardwareBackPress', this.onComplete);
    }

    componentWillUnmount() {
      BackHandler.removeEventListener('hardwareBackPress', this.onComplete);
    }

    onComplete = () => {
      const { navigation } = this.props;
      const onComplete = navigation.getParam('onComplete');

      if (onComplete) onComplete();
    };

    onCloseButtonPressed = () => {
      const { navigation } = this.props;

      this.onComplete();
      navigation.pop();
    };

    render() {
      const { navigation } = this.props;
      const reason = navigation.getParam('reason');
      const cancelButtonVisible = navigation.getParam('cancelButtonVisible');

      return (
        <Component
          {...this.props}
          reason={reason}
          onComplete={this.onComplete}
          cancelButtonVisible={cancelButtonVisible}
          onCloseButtonPress={this.onCloseButtonPressed}
        />
      );
    }
  }

  Navigation.navigationOptions = ({ navigation }) => {
    function onCloseButtonPress() {
      const onComplete = navigation.getParam('onComplete');

      if (onComplete) onComplete();
      navigation.pop();
    }
    const canBoost = navigation.getParam('canBoost');

    return {
      headerTitle:
        Resources.strings[
          canBoost
            ? 'purchase-screen-title'
            : 'purchase-screen-subscription-title'
        ],
      headerStyle: styles.header,
      headerTitleStyle: styles.headerTitle,
      headerLeft: () =>
        navigation.getParam('cancelButtonVisible') ? (
          <ImageButton
            image={Resources.images.backIcon()}
            tint={StyleSheet.flatten(styles.headerTitle).color}
            onPress={onCloseButtonPress}
          />
        ) : null,
    };
  };

  return withNavigationFocus(Navigation);
}
