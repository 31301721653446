import React, { ComponentType } from 'react';
import { Platform } from 'react-native';
import {
  displayTextPerPeriod,
  getTrialDisplayDate,
  // @ts-ignore
} from 'dating-mobile/src/payment/utils/trial-pirce-formatting';
import Resources from 'dating-mobile/src/resources';

import { ControllerProps, ViewProps } from './types';

export const withController = (Component: ComponentType<ViewProps>) => {
  const Controller = (props: ControllerProps) => {
    const { invoice } = props;

    if (!invoice) {
      return null;
    }

    const ios = Platform.OS === 'ios';
    const hasPrice = !!invoice && !!invoice.price;
    const localizedIncludingTaxPrice =
      hasPrice && invoice.price.localizedIncludingTax;
    const trialPeriod = hasPrice && invoice.price.trialPeriod;
    const android = Platform.OS === 'android';
    const subscriptionPeriodNumber =
      hasPrice && invoice.price.subscriptionPeriodNumber;
    const subscriptionPeriodUnit =
      hasPrice && invoice.price.subscriptionPeriodUnit;
    const localizedIncludingTax =
      hasPrice && invoice.price.localizedIncludingTax;
    const trialDisplay =
      invoice && trialPeriod && getTrialDisplayDate(trialPeriod);

    const displayPrice = invoice
      ? displayTextPerPeriod(
          localizedIncludingTaxPrice,
          subscriptionPeriodNumber,
          subscriptionPeriodUnit,
          Resources.strings['subscription-trial-description-date-format'],
        )
      : null;

    const preparedProps = {
      ios,
      hasPrice,
      trialPeriod,
      android,
      subscriptionPeriodNumber,
      subscriptionPeriodUnit,
      localizedIncludingTax,
      trialDisplay,
      displayPrice,
    };

    return <Component {...props} {...preparedProps} />;
  };

  Controller.displayName =
    'payment.views.membership-subscription-agreement.controller';

  return Controller;
};
