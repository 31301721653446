import { ImageUploader } from '@sdv/domain/app/image-uploader';
import { singleton } from '@sdv/commons/utils/singleton';

export class AliasPhotosUploader {
    static shared = singleton(() => new AliasPhotosUploader());

    uploadAliasPhoto = (file, userId, attendeeId) => {
        const imageUploader = ImageUploader.shared();

        return imageUploader.uploadImage(
            file,
            `/users/${userId}/aliases/users/${attendeeId}/photos`,
        );
    };

    getAliasPhotoPath = (userId, attendeeId, basename) => {
        return `/users/${userId}/aliases/users/${attendeeId}/photos/${basename}`;
    };
}
