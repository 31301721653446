import EStyleSheet from 'react-native-extended-stylesheet';

const actionButtonStyle = {
  backgroundColor: 'transparent',
  margin: 5,
  width: 40,
  height: 40,
};

const styles = EStyleSheet.create({
  $statusBarStyle: '$primaryStatusBarStyle',
  $statusBarBackgroundColor: '$primaryBackgroundColor',
  $localStreamDefaultAlignRight: false,

  audioCallContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
  },

  overlay: {
    backgroundColor: '$brandColor',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },

  audioCallInformation: {
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },

  audioCallButtonsContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 20,
    marginBottom: 40,
  },

  controlButtonsContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginBottom: 80,
  },

  cancelButton: {
    width: 72,
    height: 72,
    backgroundColor: undefined,
  },

  header: {
    backgroundColor: '$primaryBackgroundColor',
    borderBottomColor: '$primaryBackgroundColor',
    shadowColor: '$primaryBackgroundColor',
    shadowRadius: 0,
    shadowOpacity: 0,
    elevation: 0,
    shadowOffset: {
      height: 0,
    },
  },

  headerTitle: {
    color: '$primaryTextColor',
    fontFamily: '$brandFontFamily',
  },

  container: {
    backgroundColor: '$primaryBackgroundColor',
    flex: 1,
  },

  remoteStreamContainer: {
    flex: 1,
  },

  remoteStreamSubContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },

  localStreamContainer: {
    position: 'absolute',
    flexDirection: vars =>
      vars.$localStreamDefaultAlignRight ? 'row-reverse' : 'row',
    top: 10,
    left: vars => (vars.$localStreamDefaultAlignRight ? undefined : 10),
    right: vars => (vars.$localStreamDefaultAlignRight ? 10 : undefined),
    width: 100,
    height: 100,
    justifyContent: 'center',
  },

  localStreamTouchable: {
    flex: 1,
  },

  localStream: {
    borderRadius: 13,
    overflow: 'hidden',
  },

  remoteStream: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },

  tip: {
    position: 'absolute',
    justifyContent: 'center',
    alignSelf: 'center',
  },

  tipIcon: {
    tintColor: 'white',
  },

  actionButtonsContainer: {
    position: 'absolute',
    left: 5,
    top: 5,
    right: 5,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  actionButtonsGroup: {
    flexDirection: 'row',
  },

  actionButtonHiddenContainer: {
    opacity: 0,
  },

  actionButton: actionButtonStyle,

  actionButtonOverRemoteStream: {
    ...actionButtonStyle,
  },

  actionButtonIcon: {
    tintColor: 'black',
  },

  actionButtonIconOverRemoteStream: {
    tintColor: 'white',
  },

  lastMessage: {
    backgroundColor: 'transparent',
    position: 'absolute',
    bottom: 10,
    left: 10,
    right: 10,
  },
});

export default styles;
