import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        flexDirection: 'row',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: '$minorAccentColor',
        //TODO: implement a better solution
        borderRadius: 10,
        '@flavor lovinga': {
            borderRadius: 24
        },
        '@flavor dma': {
            borderRadius: 20
        },
        '@flavor tubit': {
            borderRadius: 0
        }
    },

    photoContainer: {
        marginLeft: 16,
        alignItems: 'center',
        justifyContent: 'center',
    },

    photo: {
        width: 60,
        height: 60,
        borderRadius: 30,
        '@flavor lovinga': {
            width: 68,
            height: 68,
            borderRadius: 34
        }
    },

    textContainer: {
        flex: 1,
        paddingLeft: 16,
        paddingRight: 16,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },

    text: {
        color: '$minorAccentTextColor',
        fontFamily: '$brandFontFamily',
        fontSize: 17
    }
});

export default styles;
