import React from 'react';
import PropTypes from 'prop-types';
import SmilesModel from '../model';

function createControlledComponent(Component) {

    class ControlledComponent extends React.PureComponent {
        static displayName = 'dialogs.smiles.controller';
        static contextTypes = {
            flux: PropTypes.object.isRequired
        };

        constructor(props, context) {
            super(props, context);

            this.flux = context.flux;
            this.model = this.flux.get(SmilesModel);
            this.state = this.model.store.getState();
        }

        componentDidMount() {
            this.model.store.listen(this.onModelStateUpdated);
            !this.isStateFilled() && this.model.actions.get();
        }

        componentWillUnmount() {
            this.model.store.unlisten(this.onModelStateUpdated);
        }

        onModelStateUpdated = (state) => {
            this.setState(state);
        };

        render() {
            return (
                <Component
                    {...this.props}
                    {...this.state}
                />
            )
        }
    }

    return ControlledComponent;

}

export default createControlledComponent;
