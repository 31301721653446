import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet } from 'react-native';
import Resources from 'dating-mobile/src/resources';
import ImageButton from 'dating-mobile/src/components/navigation/views/image-button';
import * as ROUTES from 'dating-mobile/src/routing/router/constants';

import { customerSupportId } from './controller';

import styles from './styles';

export default function bindNavigation(Component) {
  class Navigation extends PureComponent {
    static displayName = 'faq.screens.faq-categories.navigation';

    static propTypes = {
      navigation: PropTypes.object.isRequired,
    };

    static navigationOptions = () => ({
      title: Resources.strings['faq-categories-title'],
      headerStyle: styles.header,
      headerTitleStyle: styles.headerTitle,
      headerBackImage: () => (
        <ImageButton
          image={Resources.images.backIcon()}
          tint={StyleSheet.flatten(styles.headerTitle).color}
        />
      ),
    });

    goToDetails = id => {
      const { navigation } = this.props;
      const route =
        id === customerSupportId ? ROUTES.CUSTOMER_SUPPORT : ROUTES.FAQ_DETAILS;

      navigation.navigate(route, { id });
    };

    render() {
      return <Component goToDetails={this.goToDetails} />;
    }
  }

  return Navigation;
}
