import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { View, Text } from 'react-native';
import * as Animatable from 'react-native-animatable';

import styles from './styles';

// Animation Definition Schema
const flashOnce = {
    0: {
        opacity: 1,
        scale: 1,
    },
    0.1: {
        opacity: 0,
    },
    0.99: {
        opacity: 0,
        scale: 1.2,
    },
    1: {
        opacity: 1,
        scale: 1,
    },
};

// Register Animation Schema
Animatable.initializeRegistryWithDefinitions({
    flashOnce,
});

class AnimatedTypeItText extends PureComponent {
    static propTypes = {
        interval: PropTypes.number,
        isAnimate: PropTypes.bool,
        duration: PropTypes.number,
        text: PropTypes.string,
        styles: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array]),
    };

    static defaultProps = {
        interval: 10000, // animation repeat interval
        duration: 100, // single letter animation time
        isAnimate: true, // single letter animation time
    };

    letters = []; // instances Animatable.Text

    componentDidMount() {
        const { interval, isAnimate } = this.props;

        if (isAnimate) {
            this.animationInterval = setInterval(this.startAnimation, interval);
        }
    }

    componentWillUnmount() {
        const { isAnimate } = this.props;

        if (isAnimate) {
            clearInterval(this.animationInterval);
        }
    }

    startAnimation = () => {
        const { duration } = this.props;
        this.letters.map((l, i) => l.flashOnce(300 + i * duration)); // start animation for each letter
    };

    renderItems = () => {
        const { isAnimate } = this.props;

        this.letters = [];

        return isAnimate ? (
            this.props.text.split('').map((letter, index) => {
                return (
                    <Animatable.Text
                        useNativeDriver
                        ref={element => element && this.letters.push(element)}
                        style={[styles.text, this.props.styles]}
                        key={index}
                    >
                        {letter}
                    </Animatable.Text>
                );
            })
        ) : (
            <Text style={[styles.text, this.props.styles]}>{this.props.text}</Text>
        );
    };

    render() {
        return <View style={styles.container}>{this.renderItems()}</View>;
    }
}

export default AnimatedTypeItText;
