import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Text, View } from 'react-native';

import Resources from 'dating-mobile/src/resources';
import { Timer } from 'dating-mobile/src/components/time/timer';
import { formatTimeTextual } from 'dating-mobile/src/components/time/formatters';

import styles from './styles';

export default class BoostTimeout extends PureComponent {
  static displayName = 'feed.views.boost-timeout.view';

  static propTypes = {
    endTime: PropTypes.instanceOf(Date),
    style: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
  };

  render() {
    const { endTime, style } = this.props;

    return (
      <View style={[styles.container, style]}>
        <Text style={styles.title}>
          {Resources.strings['feed-screen-boost-timeout-title']}
        </Text>
        <Timer
          endTime={endTime}
          style={styles.timer}
          formatter={formatTimeTextual}
        />
      </View>
    );
  }
}
