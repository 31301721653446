import React from 'react';
import PropTypes from 'prop-types';
import identityId from '../../../models/identity/controller/id';
import userGoals from '../../../models/user.goals/controllers';

function controller(Component) {

    class Controller extends React.Component {
        static displayName = 'authentication.goals.screen.controller';
        static propTypes = {
            userId: PropTypes.string,
            complete: PropTypes.func.isRequired,
        };

        constructor(props) {
            super(props);
        }

        submitSelection = (selectedItems) => {
            if (selectedItems.length > 0) {
                this.props.setGoals && this.props.setGoals(selectedItems);
            }

            this.props.complete && this.props.complete();
        };

        render() {
            const { ...props } = this.props;
            return (
                <Component
                    {...props}
                    submitSelection={this.submitSelection}
                />
            );
        }
    }


    return identityId(userGoals(Controller));
}

export default controller;
