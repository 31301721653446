import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({

    $statusBarStyle: '$brandStatusBarStyle',
    $statusBarBackgroundColor: '$brandColor',

    header: {
        backgroundColor: '$primaryBackgroundColor',
        borderBottomColor: '$primaryBackgroundColor',
        shadowColor: '$primaryBackgroundColor',
        shadowRadius: 0,
        shadowOpacity: 0,
        elevation: 0,
        shadowOffset: {
            height: 0,
        }
    },

    headerTitle: {
        fontFamily: '$brandFontFamily',
        color: '$primaryTextColor',
    },

    container: {
        backgroundColor: '$brandColor',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'stretch'
    },

    payPalView: {
        flex: 1,
        backgroundColor: '$primaryBackgroundColor'
    },

});

export default styles;
