import React from 'react';
import PropTypes from 'prop-types';
import { Animated, View } from 'react-native';
import { SafeAreaView } from 'react-navigation';
import GestureRecognizer from 'react-native-swipe-gestures';
import { NotificationType } from '@sdv/domain/notifications/types';
import Navigator from 'dating-mobile/src/routing/navigator';
import * as ROUTES from 'dating-mobile/src/routing/router/constants';
import { StreamAnnouncementIsSentNotification } from 'dating-mobile/src/notifications/views/stream-announcement-notification';
import InvitationContent from '../chat-invitation';
import IncomingCallContent from '../incoming-call';
import WinkContent from '../wink';
import CheerContent from '../cheer';
import LetterContent from '../letter-sent';
import MingleEndContent from '../mingle-end';
import MingleActiveContent from '../mingle-active';
import UnansweredMessageContent from '../unanswered-message';
import MessageBoosted from '../message-boosted';
import FastAnswerPanel from '../fast-answer';
import popupNotificationController from '../../controller';
import styles from './styles';
import ProfileBoostedContent from '../profile-boosted';
import withConfigValue from '../../../components/config-value';

const Header = View;

class PopupNotification extends React.Component {
  static displayName = 'popup-notification';

  static propTypes = {
    defaultDisplayTime: PropTypes.number,
    onNotificationDismiss: PropTypes.func.isRequired,
    notification: PropTypes.object,
    stopMingle: PropTypes.func,
  };

  state = {
    isShown: false,
    top: new Animated.Value(0),
    opacity: new Animated.Value(0),
  };

  dismissTimer = null;

  showNotification = time => {
    const { defaultDisplayTime } = this.props;
    const { isShown, top, opacity } = this.state;
    const timeout = time || defaultDisplayTime;

    if (this.dismissTimer) {
      clearTimeout(this.dismissTimer);
      this.dismissTimer = null;
    }

    this.dismissTimer = setTimeout(() => {
      this.hideNotification();
    }, timeout);

    if (isShown) {
      return;
    }

    this.setState(
      {
        isShown: true,
      },
      () => {
        Animated.parallel([
          Animated.timing(top, {
            toValue: styles.$underHeader ? Header.HEIGHT : 0,
            duration: 500,
          }),
          Animated.timing(opacity, {
            toValue: 1,
            duration: 500,
          }),
        ]).start();
      },
    );
  };

  hideNotification = () => {
    if (this.dismissTimer) {
      clearTimeout(this.dismissTimer);
      this.dismissTimer = null;
    }

    const { isShown } = this.state;

    if (!isShown) {
      return;
    }

    this.setState(
      {
        isShown: false,
      },
      () => {
        const { top, opacity } = this.state;

        Animated.parallel([
          Animated.timing(top, {
            toValue: -styles.$height,
            duration: 250,
          }),
          Animated.timing(opacity, {
            toValue: 0,
            duration: 250,
          }),
        ]).start(() => {
          const { onNotificationDismiss } = this.props;

          onNotificationDismiss();
        });
      },
    );
  };

  openChatAndDismiss = id => {
    Navigator.navigate(ROUTES.CHAT, {
      id,
    });

    this.hideNotification();
  };

  openMingleResultsAndDismiss = () => {
    Navigator.navigate(ROUTES.MINGLE_END_MODAL);

    this.hideNotification();
  };

  stopMingleAndDismiss = () => {
    const { stopMingle } = this.props;

    if (stopMingle) {
      stopMingle();
    }

    this.hideNotification();
  };

  render() {
    const { notification } = this.props;
    const { top, opacity } = this.state;
    const style = {
      top,
      opacity,
    };

    let content = null;
    let fastAnswer = null;

    if (notification) {
      switch (notification.type || '') {
        case NotificationType.MessageEvent:
          content = (
            <InvitationContent
              notification={notification.payload}
              fastAnswer={notification.fastAnswer}
              onReadyForDisplay={this.showNotification}
              openChatAndDismiss={this.openChatAndDismiss}
            />
          );
          break;
        case NotificationType.Wink:
          content = (
            <WinkContent
              notification={notification.payload}
              onReadyForDisplay={this.showNotification}
              openChatAndDismiss={this.openChatAndDismiss}
            />
          );
          break;
        case NotificationType.Cheer:
          content = (
            <CheerContent
              notification={notification.payload}
              onReadyForDisplay={this.showNotification}
              openChatAndDismiss={this.openChatAndDismiss}
            />
          );
          break;
        case NotificationType.Letter:
          content = (
            <LetterContent
              notification={notification.payload}
              onReadyForDisplay={this.showNotification}
              openChatAndDismiss={this.openChatAndDismiss}
            />
          );
          break;
        case NotificationType.MingleEnd:
          content = (
            <MingleEndContent
              notification={notification.payload}
              onReadyForDisplay={this.showNotification}
              openMingleResultsAndDismiss={this.openMingleResultsAndDismiss}
            />
          );
          break;
        case NotificationType.MingleActive:
          content = (
            <MingleActiveContent
              notification={notification.payload}
              onReadyForDisplay={this.showNotification}
              stopMingleAndDismiss={this.stopMingleAndDismiss}
            />
          );
          break;
        case NotificationType.ProfileBoosted:
          content = (
            <ProfileBoostedContent onReadyForDisplay={this.showNotification} />
          );
          break;
        case NotificationType.UnansweredMessage:
          content = (
            <UnansweredMessageContent
              notification={notification.payload}
              onReadyForDisplay={this.showNotification}
              openChatAndDismiss={this.openChatAndDismiss}
            />
          );
          break;
        case NotificationType.MessageBoosted:
          content = (
            <MessageBoosted
              notification={notification.payload}
              onReadyForDisplay={this.showNotification}
              openChatAndDismiss={this.openChatAndDismiss}
            />
          );
          break;
        case NotificationType.IncomingCall:
          content = (
            <IncomingCallContent
              notification={notification.payload}
              onReadyForDisplay={this.showNotification}
              dismiss={this.hideNotification}
            />
          );
          break;
        case NotificationType.StreamAnnouncementIsSent:
          content = (
            <StreamAnnouncementIsSentNotification
              notification={notification.payload}
              onReadyForDisplay={this.showNotification}
              dismiss={this.hideNotification}
            />
          );
          break;
      }

      if (notification.fastAnswer) {
        fastAnswer = (
          <FastAnswerPanel
            attendeeId={notification.fastAnswer.recipientId}
            variants={notification.fastAnswer.variants}
            dismiss={this.hideNotification}
          />
        );
      }
    }

    const matchNotificationStyleModifier =
      notification?.type === 'match' || notification?.type === 'like-user'
        ? styles.matchNotificationStyleModifier
        : {};

    return notification ? (
      <SafeAreaView
        style={[styles.container, style]}
        forceInset={{ top: 'always', bottom: 'never' }}
      >
        <GestureRecognizer onSwipeUp={this.hideNotification}>
          <View style={[styles.content, matchNotificationStyleModifier]}>
            {content}
            {styles.$hasBottomLine && (
              <View style={styles.bottomLineContainer}>
                <View style={styles.bottomLine} />
              </View>
            )}
          </View>
        </GestureRecognizer>
        {fastAnswer}
      </SafeAreaView>
    ) : null;
  }
}

export default withConfigValue(popupNotificationController(PopupNotification), {
  defaultDisplayTime: 'notifications.display-time.default',
});
