import { Platform } from 'react-native';
// import * as InAppUtils from 'react-native-iap';
import type from '@sdv/commons/utils/type';
import Persistence from '@sdv/domain/app/persistence';

import { PURCHASE_STEPS } from '../constants';

const persistenceScope = 'credits-mall';
const failedPurchasesPersistenceKey = 'failed-purchases';

const InAppUtils = {};

function createMallActions(id) {
  class MallActions {
    get = () => async (dispatch, flux) => {
      try {
        await openConnection();

        const availableProducts = await getAvailableProducts(flux, id);
        const storeProducts = [];

        if (Platform.OS === 'ios') {
          storeProducts.push(
            ...(await InAppUtils.getProducts(
              availableProducts.map(product => product.sku),
            )),
          );
        } else {
          const subscriptions = availableProducts.filter(
            product => product.meta && product.meta.Subscription,
          );
          const nonSubscriptions = availableProducts.filter(
            product => !product.meta || !product.meta.Subscription,
          );

          if (subscriptions.length) {
            storeProducts.push(
              ...(await InAppUtils.getSubscriptions(
                subscriptions.map(product => product.sku),
              )),
            );
          }
          if (nonSubscriptions.length) {
            storeProducts.push(
              ...(await InAppUtils.getProducts(
                nonSubscriptions.map(product => product.sku),
              )),
            );
          }
        }

        const storeProductsBySku = storeProducts.reduce(
          (acc, product) => ({
            ...acc,
            [product.productId]: product,
          }),
          {},
        );

        const products = availableProducts
          .filter(product => storeProductsBySku[product.sku])
          .map(product => {
            const storeProduct = storeProductsBySku[product.sku];

            return {
              ...product,
              title: Platform.select({
                ios: storeProduct.title,
                // Android adds extra info to product title
                android: storeProduct.description,
              }),
              localizedPrice: storeProduct.localizedPrice,
              skuType: storeProduct.type,
              price: storeProduct.price,
              currency: storeProduct.currency,
            };
          });

        dispatch({
          products,
        });
      } catch (error) {
        dispatch(null, error);
      } finally {
        closeConnection();
      }
    };

    purchase = (product, reason, exchangeInterceptor, failedPurchase) => async (
      dispatch,
      flux,
    ) => {
      let step;
      let purchaseDetails;
      let purchaseId;
      let receipt;

      try {
        if (Platform.OS === 'web') {
          const webPurchaseId = Date.now();

          dispatch({
            product,
            reason,
            purchaseDetails: { transactionId: webPurchaseId },
            purchaseId: webPurchaseId,
          });

          return;
        }

        if (failedPurchase) {
          if (
            failedPurchase.step === PURCHASE_STEPS.PURCHASE_PRODUCT &&
            failedPurchase.purchaseDetails
          ) {
            step = PURCHASE_STEPS.PURCHASE_PRODUCT;
            purchaseDetails = await purchaseProduct(product);
            purchaseId = purchaseDetails?.transactionId || Date.now();

            if (type.isFunction(exchangeInterceptor)) {
              await exchangeInterceptor();
            }

            step = PURCHASE_STEPS.CREATE_RECEIPT;
            receipt = await getReceipt(flux, id, product, purchaseDetails);

            step = PURCHASE_STEPS.EXCHANGE_RECEIPT;
            await exchangeReceipt(flux, id, receipt);

            step = PURCHASE_STEPS.CONSUME_PURCHASE;
            await consumePurchase(product, purchaseDetails);

            dispatch({
              product,
              reason,
              purchaseDetails,
              purchaseId,
            });

            return;
          }

          if (
            failedPurchase.step === PURCHASE_STEPS.CREATE_RECEIPT &&
            failedPurchase.purchaseDetails
          ) {
            step = PURCHASE_STEPS.CREATE_RECEIPT;
            ({ purchaseDetails } = failedPurchase);
            receipt = await getReceipt(flux, id, product, purchaseDetails);
            purchaseId = purchaseDetails?.transactionId || Date.now();

            step = PURCHASE_STEPS.EXCHANGE_RECEIPT;
            await exchangeReceipt(flux, id, receipt);

            step = PURCHASE_STEPS.CONSUME_PURCHASE;
            await consumePurchase(product, purchaseDetails);

            dispatch({
              product,
              reason,
              purchaseDetails,
              purchaseId,
            });

            return;
          }

          if (
            failedPurchase.step === PURCHASE_STEPS.EXCHANGE_RECEIPT &&
            failedPurchase.purchaseDetails &&
            failedPurchase.receipt
          ) {
            step = PURCHASE_STEPS.EXCHANGE_RECEIPT;
            ({ purchaseDetails, receipt } = failedPurchase);
            purchaseId = purchaseDetails?.transactionId || Date.now();
            await exchangeReceipt(flux, id, receipt);

            step = PURCHASE_STEPS.CONSUME_PURCHASE;
            await consumePurchase(product, purchaseDetails);

            dispatch({
              product,
              reason,
              purchaseDetails,
              purchaseId,
            });

            return;
          }

          if (
            failedPurchase.step === PURCHASE_STEPS.CONSUME_PURCHASE &&
            failedPurchase.purchaseDetails
          ) {
            step = PURCHASE_STEPS.CONSUME_PURCHASE;
            ({ purchaseDetails } = failedPurchase);
            purchaseId = purchaseDetails?.transactionId || Date.now();
            await consumePurchase(product, purchaseDetails);

            dispatch({
              product,
              reason,
              purchaseDetails,
              purchaseId,
            });

            return;
          }
        }

        step = PURCHASE_STEPS.OPEN_CONNECTION;
        await openConnection();

        step = PURCHASE_STEPS.PURCHASE_PRODUCT;
        purchaseDetails = await purchaseProduct(product);
        purchaseId = purchaseDetails?.transactionId || Date.now();

        // TODO: Add step for interceptor
        if (type.isFunction(exchangeInterceptor)) {
          await exchangeInterceptor();
        }

        step = PURCHASE_STEPS.CREATE_RECEIPT;
        receipt = await getReceipt(flux, id, product, purchaseDetails);

        step = PURCHASE_STEPS.EXCHANGE_RECEIPT;
        await exchangeReceipt(flux, id, receipt);

        step = PURCHASE_STEPS.CONSUME_PURCHASE;
        await consumePurchase(product, purchaseDetails);

        dispatch({
          product,
          reason,
          purchaseDetails,
          purchaseId,
        });
      } catch (error) {
        // TODO: DO we need handle PURCHASE_STEPS.CONSUME_PURCHASE?
        if (
          step === PURCHASE_STEPS.CREATE_RECEIPT ||
          step === PURCHASE_STEPS.EXCHANGE_RECEIPT
        ) {
          saveFailedPurchase(
            id,
            product,
            reason,
            step,
            purchaseDetails,
            receipt,
            purchaseId,
          );
        }

        dispatch(
          {
            step,
            product,
            purchaseDetails,
            purchaseId,
            errorMessage: error.message || 'Purchase error',
          },
          { error, purchaseDetails, receipt, step },
        );
      } finally {
        closeConnection();
      }
    };

    restorePurchases = () => async (dispatch, flux) => {
      const persistence = await Persistence.shared(persistenceScope, id);
      const failedPurchasesRaw = await persistence.value(
        failedPurchasesPersistenceKey,
      );
      const failedPurchases = failedPurchasesRaw
        ? JSON.parse(failedPurchasesRaw)
        : {};

      try {
        await openConnection();

        // await InAppUtils.getAvailablePurchases(); // TODO: What should I do with this?

        const restoredPurchases = await Promise.all(
          Object.entries(failedPurchases).map(
            async ([purchaseId, purchase]) => {
              if (!purchase) {
                return null;
              }

              let { receipt, step } = purchase;
              const { purchaseDetails, product, reason } = purchase;

              try {
                if (step === PURCHASE_STEPS.CREATE_RECEIPT) {
                  receipt = await getReceipt(
                    flux,
                    id,
                    product,
                    purchaseDetails,
                  );
                }

                step = PURCHASE_STEPS.EXCHANGE_RECEIPT;
                await exchangeReceipt(flux, id, receipt);

                step = PURCHASE_STEPS.CONSUME_PURCHASE;
                await consumePurchase(product, purchaseDetails);

                removeFailedPurchase(id, purchaseId);

                return {
                  product,
                  reason,
                  purchaseDetails,
                  purchaseId,
                };
              } catch (error) {
                // TODO: DO we need handle PURCHASE_STEPS.CONSUME_PURCHASE?
                if (
                  step !== purchase.step &&
                  (step === PURCHASE_STEPS.CREATE_RECEIPT ||
                    step === PURCHASE_STEPS.EXCHANGE_RECEIPT)
                ) {
                  saveFailedPurchase(
                    id,
                    product,
                    reason,
                    step,
                    purchaseDetails,
                    receipt,
                    purchaseId,
                  );
                }

                return null;
              }
            },
          ),
        );

        dispatch(restoredPurchases.filter(Boolean));
      } catch (error) {
        dispatch(null, error);
      } finally {
        closeConnection();
      }
    };
  }

  MallActions.displayName = createMallActions.getDisplayName(id);

  consumeAllPurchases();

  return MallActions;
}

createMallActions.getDisplayName = function(id) {
  return `credits-mall.${id}`;
};

export default createMallActions;

const shop = Platform.select({
  ios: 'ios',
  android: 'android',
});

const exchangeMethod = Platform.select({
  ios: 'apple',
  android: 'google',
});

const getAvailableProducts = (flux, id) => {
  return new Promise((resolve, reject) => {
    flux.api.credits.mall.credits.get(id, { shop }, function(error, products) {
      if (error) {
        return reject(error);
      }

      return resolve(products);
    });
  });
};

const createReceipt = (flux, id, product, purchase) => {
  return new Promise((resolve, reject) => {
    const receipt = {
      amount: product.amount,
      price: product.price,
      method: exchangeMethod,
      shop,
      sku: product.sku,
      requisites: {
        signature: purchase.signatureAndroid,
        receipt: purchase.dataAndroid,
      },
    };

    flux.api.credits.mall.receipts.post(receipt, function(error, res) {
      if (error) {
        return reject(error);
      }

      return resolve(res.receipt);
    });
  });
};

const exchangeReceipt = (flux, id, receipt) => {
  return new Promise((resolve, reject) => {
    flux.api.credits.mall.exchanges.post(
      id,
      { shop, receipt, method: exchangeMethod },
      function(error) {
        if (error) {
          return reject(error);
        }

        return resolve();
      },
    );
  });
};

const saveFailedPurchase = async (
  id,
  product,
  reason,
  step,
  purchaseDetails,
  receipt,
  purchaseId,
) => {
  try {
    const persistence = await Persistence.shared(persistenceScope, id);
    const failedPurchasesRaw = await persistence.value(
      failedPurchasesPersistenceKey,
    );
    const failedPurchases = failedPurchasesRaw
      ? JSON.parse(failedPurchasesRaw)
      : {};

    if (purchaseId) {
      await persistence.store(
        failedPurchasesPersistenceKey,
        JSON.stringify({
          ...failedPurchases,
          [purchaseId]: {
            purchaseDetails,
            product,
            receipt,
            reason,
            step,
          },
        }),
      );
    }
  } catch (e) {
    // TODO
  }
};

const removeFailedPurchase = async (id, purchaseId) => {
  try {
    const persistence = await Persistence.shared(persistenceScope, id);
    const failedPurchasesRaw = await persistence.value(
      failedPurchasesPersistenceKey,
    );
    const failedPurchases = failedPurchasesRaw
      ? JSON.parse(failedPurchasesRaw)
      : {};

    await persistence.store(
      failedPurchasesPersistenceKey,
      JSON.stringify({
        ...failedPurchases,
        [purchaseId]: undefined,
      }),
    );
  } catch (e) {
    // TODO
  }
};

const getReceipt = async (flux, id, product, purchase) => {
  if (Platform.OS === 'android') {
    if (product.meta && product.meta.Subscription) {
      return createReceipt(flux, id, product, purchase);
    }

    return JSON.stringify({
      signature: purchase.signatureAndroid,
      receipt: purchase.dataAndroid,
    });
  }

  return purchase.transactionReceipt;
};

let preparePromise;

const openConnection = () => {
  // InAppUtils.openConnections = (InAppUtils.openConnections || 0) + 1;
  //
  // if (InAppUtils.openConnections <= 1) {
  //   preparePromise = InAppUtils.initConnection();
  // }
  //
  // return preparePromise;
};

const closeConnection = () => {
  InAppUtils.openConnections = InAppUtils.openConnections
    ? InAppUtils.openConnections - 1
    : 0;

  return Promise.resolve();
};

const consumeAllPurchases = async () => {
  try {
    await openConnection();

    if (Platform.OS === 'android') {
      await InAppUtils.flushFailedPurchasesCachedAsPendingAndroid();
    }
  } finally {
    closeConnection();
  }
};

const purchaseProduct = async product =>
  new Promise(async (resolve, reject) => {
    try {
      if (Platform.OS === 'ios') {
        await InAppUtils.clearTransactionIOS();

        if (product.skuType.toLowerCase() === 'inapp') {
          InAppUtils.requestPurchase(product.sku, false);
        } else {
          InAppUtils.requestSubscription(product.sku, false);
        }
      } else if (Platform.OS === 'android') {
        if (product.skuType.toLowerCase() === 'inapp') {
          InAppUtils.requestPurchase(product.sku, false);
        } else if (product.isPremiumMembership) {
          // TODO: Get this sku from available packages packages.filter(it => !it.isPremiumMembership)
          const basicSubscriptionSkus = [
            'com.anonymous.android.membership.basic',
          ];
          const purchases = await InAppUtils.getAvailablePurchases();
          const basicSubscription = purchases.find(
            ({ productId, autoRenewingAndroid }) =>
              basicSubscriptionSkus.includes(productId) && autoRenewingAndroid,
          );

          if (basicSubscription) {
            InAppUtils.requestSubscription(
              product.sku,
              false,
              basicSubscription.productId,
            );
          } else {
            InAppUtils.requestSubscription(product.sku, false);
          }
        } else {
          InAppUtils.requestSubscription(product.sku, false);
        }
      }

      const purchaseUpdateSubscription = InAppUtils.purchaseUpdatedListener(
        purchase => {
          if (purchase.productId === product.sku) {
            purchaseUpdateSubscription?.remove();
            purchaseErrorSubscription?.remove();
            resolve(purchase);
          }
        },
      );

      const purchaseErrorSubscription = InAppUtils.purchaseErrorListener(
        error => {
          purchaseUpdateSubscription?.remove();
          purchaseErrorSubscription?.remove();
          reject(error);
        },
      );
    } catch (error) {
      reject(error);
    }
  });

const consumePurchase = async (product, purchase) => {
  return InAppUtils.finishTransaction(
    purchase,
    product.skuType.toLowerCase() === 'inapp',
  );
};
