import EStyleSheet from 'react-native-extended-stylesheet';
import { I18nManager, Platform } from 'react-native';

const styles = EStyleSheet.create({
  $inputKeyboardAppearance: '$keyboardAppearance',
  $inputSelectionColor: '$accentColor',

  container: {
    flexShrink: 1,
    flexDirection: 'column',
  },

  inputAndExtraControlContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  extraControlContainer: {
    marginRight: 8,
  },

  activeBackground: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
  },

  content: {
    flexDirection: 'row',
    alignItems: 'center',
    margin: 8,
    marginLeft: 16,
    borderRadius: 20,
    backgroundColor: 'transparent',
    flexShrink: 1,
  },

  contentInStream: {
    backgroundColor: 'transparent',
  },

  contentInDialog: {},

  inputContainer: {
    flex: 1,
    height: 40,
    borderRadius: 20,
    backgroundColor: '$inputBackgroundColor',
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '$primaryPlaceholderColor',
  },

  inputContainerInStream: {
    backgroundColor: '$shadowColor',
    borderWidth: 0,
  },

  inputContainerInDialog: {},

  input: {
    flex: 1,
    fontSize: 14,
    color: '$inputTextColor',
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 0,
    paddingBottom: 0,
    fontFamily: '$brandFontFamily',
    textAlign: I18nManager.isRTL ? 'right' : 'left',
    // max 6 lines
    ...Platform.select({
      ios: {
        maxHeight: 110,
      },
      android: {
        maxHeight: 100,
      },
    }),
  },

  inputWithShadow: {
    color: 'white',
  },

  inputWithMedia: {
    marginLeft: 0,
  },

  sendButton: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    marginLeft: 0,
    marginRight: 6,
  },

  sendButtonIcon: {
    tintColor: '$primaryPlaceholderColor',
    transform: [
      {
        scaleX: I18nManager.isRTL ? -1 : 1,
      },
    ],
  },

  sendButtonIconActive: {
    tintColor: '$inputSelectionColor',
  },

  actionButtonsContainer: {
    marginLeft: -8,
    marginRight: 8,
    flexDirection: 'row',
  },

  actionButton: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
  },

  cameraIcon: {
    marginTop: 4,
  },

  giftsButtonIcon: {},

  giftsButtonIconActive: {},

  emojiButtonIcon: {
    tintColor: '$primaryPlaceholderColor',
  },

  emojiButtonIconActive: {
    tintColor: '$minorAccentColor',
  },

  resourceKeyboardSeparator: {
    backgroundColor: '$brandBorderColor',
    width: '100%',
    height: 0,
  },

  writeLetterButton: {
    width: 40,
    height: 40,
  },

  placeholderContainer: {
    top: 0,
    left: 15,
    bottom: 0,
    position: 'absolute',
    justifyContent: 'center',
  },

  placeholder: {
    color: '$primaryPlaceholderColor',
  },
});

export default styles;
