import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

function bindNavigation(Component) {
  class Navigation extends PureComponent {
    static propTypes = {
      navigation: PropTypes.object.isRequired,
    };

    handleButtonPress = () => {
      const { navigation } = this.props;
      const onComplete = navigation.getParam('onComplete');

      if (onComplete) {
        onComplete();
      }
    };

    render() {
      return (
        <Component onButtonPress={this.handleButtonPress} {...this.props} />
      );
    }
  }

  Navigation.navigationOptions = () => {
    return {
      headerShown: false,
    };
  };

  return Navigation;
}

export default bindNavigation;
