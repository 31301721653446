import React from 'react';
import PropTypes from 'prop-types';
import connect from '@sdv/connect';
import NotificationsModel from '../../models/notifications/model';
import InvitationModel from '@sdv/domain/dialogs.automation.productions.invitation';
import identityId from '../../models/identity/controller/id';

function createNotificationsPopupController(Component) {

    class Controller extends React.PureComponent {
        static displayName = 'notifications-popup.controller';
        static contextTypes = {
            flux: PropTypes.object,
        };
        static propTypes = {
            id: PropTypes.string,
            notification: PropTypes.object,
            dismissNotification: PropTypes.func,
            stopMingle: PropTypes.func
        };

        stopMingle = () => {
            this.props.stopMingle && this.props.stopMingle();
        };

        onNotificationDismissed = () => {
            this.props.dismissNotification && this.props.dismissNotification();
        };

        render() {
            return (
                <Component
                    {...this.props}
                    notification={this.props.notification}
                    onNotificationDismiss={this.onNotificationDismissed}
                    stopMingle={this.stopMingle}
                />
            )
        }

    }

    let ConnectedController = connect(
        (flux, props) => props.id
            ? {
                notificationsModel: flux.get(NotificationsModel, props.id),
                invitationModel: flux.get(InvitationModel, props.id)
            }
            : {},
        (models) => ({
            ...(
                models.notificationsModel
                    ? {
                        notification: (
                            models.notificationsModel.store.getState().notification &&
                            models.notificationsModel.store.getState().notification.payload) ||
                            null
                    }
                    : {}
            )
        }),
        (models) => ({
            ...(
                models.invitationModel
                    ? {
                        stopMingle: models.invitationModel.actions.delete
                    }
                    : {}
            ),
            ...(
                models.notificationsModel
                    ? {
                        dismissNotification: models.notificationsModel.actions.dismiss
                    }
                    : {}
            )
        }),
        (props, newProps) => props.id !== newProps.id
    )(Controller);

    return identityId(ConnectedController, 'id');
}

export default createNotificationsPopupController;
