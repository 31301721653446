import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withNavigationFocus } from 'react-navigation';
import { timeout } from 'rxjs/operators';
import PushNotificationPermissions from '@sdv/domain/app/push-notifications/permissions';

export default function createController(Component) {
    class Controller extends PureComponent {
        static displayName = 'stepped-registration.screens.notifications.controller';

        static contextTypes = {
            flux: PropTypes.object,
        };

        static propTypes = {
            onComplete: PropTypes.func.isRequired,
            isFocused: PropTypes.bool,
        };

        componentDidUpdate(prevProps) {
            const { isFocused } = this.props;

            if (isFocused !== prevProps.isFocused && !isFocused) {
                this.unsubscribe();
            }
        }

        componentWillUnmount() {
            this.unsubscribe();
        }

        turnOnNotifications = () => {
            const { onComplete } = this.props;

            this.subscription = PushNotificationPermissions.shared()
                .requestNotificationsPermission.pipe(timeout(3000))
                .subscribe(onComplete, onComplete);
        };

        unsubscribe() {
            if (this.subscription) {
                this.subscription.unsubscribe();
                this.subscription = null;
            }
        }

        render() {
            const { onComplete } = this.props;

            return (
                <Component
                    {...this.props}
                    turnOnNotifications={this.turnOnNotifications}
                    skip={onComplete}
                />
            );
        }
    }

    return withNavigationFocus(Controller);
}
