
const CALLBACKS = {};

export default function singleExecute(tag, execute, callback, singleFire) {

    if (Array.isArray(CALLBACKS[tag])) {

        if (!singleFire && typeof callback === 'function') {
            CALLBACKS[tag].push(callback);
        }

        return;
    }

    CALLBACKS[tag] = [];

    if (typeof callback === 'function') {
        CALLBACKS[tag].push(callback);
    }

    execute((...args) => {

        CALLBACKS[tag].forEach(callback => {
            callback(...args);
        });
        delete CALLBACKS[tag];

    });

}
