import React from 'react';
import { FlatList, Text, TouchableOpacity, View } from 'react-native';
import PropTypes from 'prop-types';
import styles from './styles';
import { INBOX_MODES, INBOX_MODES_TITLES } from 'dating-mobile/src/inbox/inbox-modes';

export default class InboxModelSelectorView extends React.PureComponent {
    static displayName = "inbox.views.selector.view";
    static propTypes = {
        selectedMode: PropTypes.string,
        onChange: PropTypes.func,
        isExpanded: PropTypes.bool
    };

    onPress = (mode) => () => {
        this.props.onChange && this.props.onChange(mode)
    };

    getItem = ({item}) => {
        const selected = item === this.props.selectedMode;
        const itemTextStyle = selected ? styles.selectedModeTitle : styles.modeTitle;
        const title = INBOX_MODES_TITLES[item] || '';

        return (
            <TouchableOpacity key={item} onPress={this.onPress(item)}>
                <View style={styles.itemContainer}>
                    <Text numberOfLines={1} style={itemTextStyle}>{title}</Text>
                    <View style={styles.separator}/>
                </View>
            </TouchableOpacity>
        )
    };

    keyExtractor = (item) => {
        return item;
    };

    render() {
        return (
            <FlatList
                style={styles.list}
                numColumns={1}
                renderItem={this.getItem}
                keyExtractor={this.keyExtractor}
                containerStyle={styles.modes}
                data={[INBOX_MODES.ALL, INBOX_MODES.UNREAD , INBOX_MODES.READ_AND_UNANSWERED]}
                scrollEnabled={false}
            />
        )
    }
}
