import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({

    container: {
        width: 96,
        height: 96,
        overflow: 'hidden',
        '@flavor lovinga, udates': {
            borderRadius: 15,
            marginRight: 8
        }
    },

    photo: {
        width: '100%',
        height: '100%'
    },

    overlay: {
        position: 'absolute',
        backgroundColor: 'rgba(0,0,0,0.5)',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    },

    title: {
        color: 'white',
        fontWeight: 'bold',
        fontFamily: '$brandFontFamily',
        fontSize: 14,
        textAlign: 'center',
    }
});

export default styles;
