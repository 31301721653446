import Actions from './actions';
import { BaseStore } from '@sdv/model';

export default function createStore(userId) {

    class Store extends BaseStore {

        constructor(flux) {
            super();
            const actions = flux.getActions(Actions, userId);
            this.state = {
                unreadLettersAmount: 0
            };
            this.bindAction(actions.actualize, this.actualize);

            this.eventInbox = flux.letterEventInbox;
            this.eventInbox.addOnLetterReadListener(this.letterRead);
            this.eventInbox.addOnLetterAddedListener(this.letterAdded);
        }

        actualize = (unreadLettersAmount) => {
            if (typeof unreadLettersAmount !== 'undefined' && unreadLettersAmount) {
                this.setState({ unreadLettersAmount: unreadLettersAmount });
            }
        };

        letterRead = () => {
            let newUnreadLettersAmount = this.state.unreadLettersAmount - 1;

            if (newUnreadLettersAmount < 0) {
                newUnreadLettersAmount = 0;
            }

            this.setState({
                unreadLettersAmount: newUnreadLettersAmount
            });
        };

        letterAdded = (letter) => {
            if (letter.sender === userId) {
                return;
            }
            this.setState({
                unreadLettersAmount: this.state.unreadLettersAmount + 1
            });
        };
    }

    Store.displayName = createStore.getDisplayName(userId);

    return Store;
}

createStore.getDisplayName = function (id) {
    return `unread-letter-counter.${id}`;
};
