import React from 'react';
import { Platform, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import ImageButton from 'dating-mobile/src/components/navigation/views/image-button';
import Resources from 'dating-mobile/src/resources';
import { TERMS } from 'dating-mobile/src/routing/router/constants';
import { Subject } from 'rxjs';
import styles from './styles';

function bindNavigation(Component) {
  class Navigation extends React.Component {
    static displayName = 'payment.screens.card.navigation';

    static propTypes = {
      navigation: PropTypes.object.isRequired,
    };

    constructor(props) {
      super(props);
      this.backButtonPressed = new Subject();
    }

    componentDidMount() {
      this.setNavigationBarState({
        onBackButtonPress: () => this.backButtonPressed.next(''),
      });
    }

    close = (closeModal, self) => {
      const { navigation } = this.props;
      const onClose = navigation.getParam('onClose');

      if (closeModal) {
        navigation.goBack();
      }

      navigation.pop();

      if (self && onClose) {
        onClose();
      }
    };

    /**
     * @private
     * @param state
     */
    setNavigationBarState = state => {
      const { navigation } = this.props;

      navigation.setParams(state);
    };

    openLink = (uri, title) => {
      const { navigation } = this.props;

      navigation.navigate(TERMS, {
        uri,
        title,
      });
    };

    render() {
      const { navigation } = this.props;

      return (
        <Component
          {...this.props}
          invoice={navigation.getParam('invoice')}
          reason={navigation.getParam('reason')}
          onComplete={navigation.getParam('onComplete')}
          close={this.close}
          openLink={this.openLink}
          setNavigationBarState={this.setNavigationBarState}
          backButtonPressed={this.backButtonPressed}
        />
      );
    }
  }

  Navigation.navigationOptions = ({ navigation }) => ({
    headerTitle: Resources.strings['credit-card-screen-title'],
    headerLeft: () => (
      <ImageButton
        image={Resources.images.backIcon()}
        tint={StyleSheet.flatten(styles.headerTitle).color}
        onPress={navigation.getParam('onBackButtonPress')}
      />
    ),
  });

  return Platform.OS === 'web' ? Component : Navigation;
}

export default bindNavigation;
