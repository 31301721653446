import View from './view';
import withUser from 'dating-mobile/src/models/user/controllers';

export default withUser(
    View,
    {
        userIdPropName: 'id',
        resultPropName: 'user'
    }
);;
