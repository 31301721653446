import React from 'react';
import PropTypes from 'prop-types';
import CheersModel from '@sdv/domain/cheers';
import connect from '@sdv/connect';
import { FreeCheersRepository } from '@sdv/domain/cheers/free-cheers/free-cheers-repository';
import { Cheer } from '@sdv/domain/cheers/types/cheer';
import withConfigValue from 'dating-mobile/src/components/config-value';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';

function createCheersController(Component, setPropName, defaultSet = 'common') {
  function getSet(props) {
    const { devaluationRatio } = props;

    if (!devaluationRatio) {
      return undefined;
    }

    const set = setPropName ? props[setPropName] : defaultSet;

    if (!set) {
      return undefined;
    }

    return `${set};price-multiplier=${devaluationRatio}`;
  }

  class Controller extends React.Component {
    state = {};

    static displayName = 'cheers.controller';

    static propTypes = {
      userId: PropTypes.string,
      cheers: PropTypes.array,
      setFromConfig: PropTypes.string,
      cheersFromConfig: PropTypes.array,
      get: PropTypes.func,
      chatGiftsEnabled: PropTypes.bool,
      freeCheersAvaliable: PropTypes.bool,
    };

    componentDidMount() {
      const { get, freeCheersAvaliable, userId } = this.props;

      if (get) {
        get();
      }

      if (freeCheersAvaliable && userId) {
        this.freeCheersSubscription = FreeCheersRepository.shared(userId)
          .getAvailableCheers()
          .subscribe(cheers => {
            this.setState({ freeCheers: cheers });
          });
      }
    }

    componentWillReceiveProps(props) {
      if (props.chatGiftsEnabled) {
        this.set = getSet(props);
        this.setState({
          cheers: (props.cheers || []).map(basename =>
            Controller.getCheer(basename, this.set),
          ),
        });
      } else {
        this.set = 'config';
        this.setState({ cheers: props.cheersFromConfig || [] });
      }
    }

    componentWillUnmount() {
      if (this.freeCheersSubscription) {
        this.freeCheersSubscription.unsubscribe();
      }
    }

    static getCheer = (basename, set) => {
      return new Cheer(`/cheers/${set}/${basename}`);
    };

    render() {
      const { freeCheers = [], cheers = [] } = this.state;
      const allCheers = freeCheers.slice().concat(cheers);

      return <Component {...this.props} cheers={allCheers} set={this.set} />;
    }
  }

  return connect(
    (flux, props) => {
      const set = getSet(props);

      if (!set || !props.chatGiftsEnabled) {
        return {};
      }

      return {
        cheersModel: flux.get(CheersModel, set),
      };
    },

    models => {
      if (!models.cheersModel) {
        return {};
      }

      return models.cheersModel.store.getState();
    },

    models => {
      if (!models.cheersModel) {
        return {};
      }

      return {
        get: models.cheersModel.actions.get,
      };
    },

    (prevProps, nextProps) => {
      return getSet(prevProps) !== getSet(nextProps);
    },
  )(Controller);
}

export default Component =>
  withConfigValue(
    withIdentityId(createCheersController(Component, 'setFromConfig')),
    {
      devaluationRatio: 'currency.devaluation-ratio',
      chatGiftsEnabled: 'features.chat-gifts-enabled',
      setFromConfig: 'cheers.set',
      cheersFromConfig: 'cheers.items',
    },
  );
