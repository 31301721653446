import { SYSTEM_MESSAGES } from '@sdv/domain/streaming/system-messages';

export function MessagesActionsError(code) {
  const instance = new Error(`MessagesActionsError (CODE: ${code})`);

  instance.name = 'MessagesActionsError';
  instance.code = code;
  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(instance, MessagesActionsError.prototype);
  } else {
    // eslint-disable-next-line no-proto
    instance.__proto__ = MessagesActionsError.prototype;
  }
  if (Error.captureStackTrace) {
    Error.captureStackTrace(instance, MessagesActionsError);
  }

  return instance;
}

MessagesActionsError.prototype = Object.create(Error.prototype);
if (Object.setPrototypeOf) {
  Object.setPrototypeOf(MessagesActionsError, Error);
} else {
  // eslint-disable-next-line no-proto
  MessagesActionsError.__proto__ = Error;
}

MessagesActionsError.CODES = Object.freeze({
  PARTIAL_LOAD: 1,
  PAYMENT_REQUIRED: 2,
});

export const MESSAGE_TYPES = Object.freeze({
  TEXT: 0,
  CHEER: 1,
  PHOTO: 2,
  VIDEO: 3,
  LETTER: 4,
  STICKER: 5,
  FAVORITE: 6,
  NUDITY_WARNING: 7,
  DELETED: 8,
  SYSTEM: 9,
});

export const DELIVERY_STATUS = Object.freeze({
  UNKNOWN: 0,
  FAILED: 1,
  SENDING: 2,
  DELIVERED: 3,
  UNPAID: 4,
});

export function parseContent(text, meta, supportedTypes) {
  let type = null;
  let content = null;

  if (meta && meta.reference) {
    let keys = meta.reference.split(':///');

    if (keys.length === 1) {
      keys = meta.reference.split(':');
    }

    const value = keys.length > 1 ? keys.slice(1).join('') : undefined;

    switch (keys[0]) {
      case 'photo':
        type = MESSAGE_TYPES.PHOTO;
        content = {
          basename: value,
        };
        break;
      case 'video':
        type = MESSAGE_TYPES.VIDEO;
        content = {
          basename: value,
        };
        break;
      case 'sticker':
        type = MESSAGE_TYPES.STICKER;
        content = {
          basename: value,
        };
        break;
      case 'cheer': {
        type = MESSAGE_TYPES.CHEER;

        let { price } = meta;
        let amount = meta.transfer;

        const parts = value.split(';');

        if (!price) {
          const priceIndex = parts.findIndex(part => part.startsWith('price='));

          if (priceIndex >= 0) {
            price = parseInt(parts[priceIndex].replace('price=', ''), 10);
          }
        }
        if (!amount) {
          const amountIndex = parts.findIndex(part =>
            part.startsWith('diamonds='),
          );

          if (amountIndex >= 0) {
            amount = parseInt(parts[amountIndex].replace('diamonds=', ''), 10);
          }
        }
        content = {
          basename: value,
          price,
          amount,
        };
        break;
      }
      case 'text':
        switch (value) {
          case 'favorites/added':
            type = MESSAGE_TYPES.SYSTEM;
            content = {
              type: SYSTEM_MESSAGES.FOLLOW_MESSAGE,
            };
            break;
          case 'local/nudity-warning':
            type = MESSAGE_TYPES.NUDITY_WARNING;
            content = {};
            break;
          case 'local/deleted':
            type = MESSAGE_TYPES.DELETED;
            content = {};
            break;
          case 'stream/joined':
            type = MESSAGE_TYPES.SYSTEM;
            content = {
              type: SYSTEM_MESSAGES.JOIN_MESSAGE,
            };
            break;
        }
        break;
    }
  }

  if (meta && meta.localSource && meta.localSource.type) {
    if (meta.localSource.type.startsWith('image')) {
      type = MESSAGE_TYPES.PHOTO;
      content = {
        filePath: meta.localSource.uri,
        progress: meta.progress || 0,
      };
    } else if (meta.localSource.type.startsWith('video')) {
      type = MESSAGE_TYPES.VIDEO;
      content = {
        filePath: meta.localSource.uri,
        preview: meta.localSource.previewUri,
        progress: meta.progress || 0,
      };
    }
  }

  if (meta && meta.letter) {
    type = MESSAGE_TYPES.LETTER;
    content = {
      id: meta.letter,
      cover: meta.cover,
      attachments: meta.attachments,
      introductory: meta.introductory,
    };
  }

  if (meta && meta.system) {
    type = MESSAGE_TYPES.SYSTEM;
    content = {
      ...meta.system,
      targetId: meta.room,
    };
  }

  const typeIsNotSupported =
    type && supportedTypes && supportedTypes.indexOf(type) < 0;
  const textIsSupported =
    !supportedTypes || supportedTypes.indexOf(MESSAGE_TYPES.TEXT) >= 0;

  if ((!type || typeIsNotSupported) && textIsSupported) {
    type = MESSAGE_TYPES.TEXT;
    content = text;
  }

  return {
    type,
    content,
  };
}
