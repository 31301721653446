import React from 'react';
import PropTypes from 'prop-types';
import StreamsTopics from '@sdv/domain/streams-topics';

function createTopicsPickingController(Component) {
    class Controller extends React.Component {
        static displayName = 'streaming.views.topics-picking.controller';

        static propTypes = {
            userId: PropTypes.string,
        };

        constructor(props) {
            super(props);
            this.state = { topics: [], selectedTopics: [] };
        }

        componentWillReceiveProps(nextProps) {
            if (this.props.userId !== nextProps.userId) {
                this.subscribeToStreamsTopics(nextProps.userId);
            }
        }

        componentDidMount() {
            this.subscribeToStreamsTopics(this.props.userId);
        }

        subscribeToStreamsTopics = id => {
            if (this.streamTopicsSubscription) {
                this.streamTopicsSubscription.unsubscribe();
            }
            if (this.selectedTopicsSubscription) {
                this.selectedTopicsSubscription.unsubscribe();
            }
            this.streamsTopics = StreamsTopics.shared(id);
            this.streamTopicsSubscription = this.streamsTopics.availableTopics.subscribe(topics => {
                this.setState({ topics: topics });
            });
            this.selectedTopicsSubscription = this.streamsTopics.selectedTopics.subscribe(
                selectedTopics => {
                    this.setState({ selectedTopics: selectedTopics });
                },
            );
        };

        componentWillUnmount() {
            this.streamTopicsSubscription.unsubscribe();
            this.selectedTopicsSubscription.unsubscribe();
        }

        onItemPress = topic => {
            this.streamsTopics.selectTopic(topic);
        };

        render() {
            return (
                <Component
                    {...this.props}
                    topics={this.state.topics}
                    selectedTopics={this.state.selectedTopics}
                    onItemPress={this.onItemPress}
                />
            );
        }
    }

    return Controller;
}

export default createTopicsPickingController;
