import React from 'react';
import PropTypes from 'prop-types';
import { View, TouchableOpacity, Text } from 'react-native';
import Modal from 'react-native-modal';
import Resources from 'dating-mobile/src/resources';

import styles from './styles';

class FastAnswerPanel extends React.Component {
  static propTypes = {
    variants: PropTypes.arrayOf(PropTypes.string).isRequired,
    send: PropTypes.func,
    onClose: PropTypes.func,
    isVisible: PropTypes.bool,
  };

  sendText = text => {
    const { send } = this.props;

    if (send) {
      send(text);
    }
  };

  renderItem = (item, index) => {
    const containerStyle = [
      styles.itemContainer,
      index !== 0 ? styles.itemSeparator : {},
    ];

    return (
      <TouchableOpacity
        key={item}
        style={containerStyle}
        onPress={() => this.sendText(item)}
      >
        <View style={styles.itemContent}>
          <Text style={styles.message}>{item}</Text>
        </View>
      </TouchableOpacity>
    );
  };

  render() {
    const { variants, isVisible, onClose } = this.props;

    return (
      <Modal
        style={styles.modalContainer}
        backdropOpacity={0.32}
        isVisible={isVisible}
        onBackdropPress={onClose}
        onBackButtonPress={onClose}
      >
        <View style={styles.container}>
          <View style={styles.titleContainer}>
            <Text style={styles.title}>
              {Resources.strings['message-quick-replay-title']}
            </Text>
            <Text style={styles.subtitle}>
              {Resources.strings['message-quick-replay-subtitle']}
            </Text>
          </View>
          {variants.map(this.renderItem)}
        </View>
      </Modal>
    );
  }
}

export default FastAnswerPanel;
