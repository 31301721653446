import React, { PureComponent } from 'react';
import { Image, Text, View } from 'react-native';
import Resources from 'dating-mobile/src/resources';

import styles from './styles';

export class ReceivedThroughBoostBanner extends PureComponent {
    static displayName = 'dialogs.common.views.log.received-through-boost-banner';

    render() {
        return (
            <View style={styles.container}>
                <View style={styles.imageContainer}>
                    <Image
                        resizeMode="contain"
                        style={styles.image}
                        source={Resources.images.boostIcon()}
                    />
                </View>
                <Text style={styles.text}>
                    {Resources.strings['message-received-through-boost-title']}
                </Text>
            </View>
        );
    }
}
