import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        flex: 1
    },
    scrollView: {
        flex: 1,
        backgroundColor: '$primaryBackgroundColor',
    },
    hiddenRow: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        backgroundColor: 'red',
        '@flavor lovinga, tubit, udates': {
            backgroundColor: '$accentColor'
        }
    },
    deleteButton: {
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        width: 80,
        '@flavor lovinga, udates': {
            width: 96
        }
    },
    deleteButtonTitle: {
        color: 'white',
        fontFamily: '$brandFontFamily',
        fontSize: 14,
        '@flavor lovinga, tubit, udates': {
            color: '$accentTextColor'
        },
        '@flavor tubit': {
            fontWeight: '500'
        }
    }
});

export default styles;
