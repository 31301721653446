import Navigator from 'dating-mobile/src/routing/navigator';
// @ts-ignore
import { NEWSLETTER_MODAL } from 'dating-mobile/src/routing/router/constants';
import { Alert } from 'react-native';
import Resources from '../resources';

class NewsletterSending {
  userId: string;

  constructor(userId: string) {
    this.userId = userId;
  }

  show() {
    const completion = (success: boolean, error: { status: number }) => {
      if (!success && error.status === 429) {
        Alert.alert(Resources.strings['newsletter-limit-popup-text'], '', [
          { text: Resources.strings['ok'], style: 'cancel' },
        ]);
      }
    };

    Navigator.navigate(NEWSLETTER_MODAL, {
      userId: this.userId,
      completion,
    });
  }
}

export { NewsletterSending };
