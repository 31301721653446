import flux from '@sdv/domain/app/flux';
import { Buffer } from 'buffer';
import { of, bindNodeCallback, throwError } from 'rxjs';
import { timeout, switchMap } from 'rxjs/operators';

export const EmailValidationError = 'email-validation-error';

export class EmailValidation {
    validateEmail = email => {
        const encryptedCredentials = Buffer.from(email).toString('base64');
        const validate = bindNodeCallback(flux.api.electronicmail.addresses.get);

        return validate(encryptedCredentials).pipe(
            switchMap(response => {
                if (!response.valid) {
                    return throwError(new Error(EmailValidationError));
                }

                return of(response);
            }),
            timeout(3000),
        );
    };
}
