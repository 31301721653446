import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, TouchableOpacity } from 'react-native';
import oppositeGender from 'dating-mobile/src/utils/opposite-gender';
import * as ROUTES from 'dating-mobile/src/routing/router/constants';
import { USER_GOALS } from '@sdv/domain/user.goals';
import UserFeatures from '@sdv/domain/user/tags/features';
import flux from '@sdv/domain/app/flux';
import {
  ProfileParameterItem as Item,
  ProfileDynamicParameterItem as ParametrizedItem,
} from '../profile-parameter-item';
import AboutMe from '../about-me-text';
import Resources from '../../../../resources';
import getDisplayDate from '../../../../utils/date-time-formatting';
import styles from './styles';
import TravelActivitiesComponent from '../../../common/views/interests-view';
import { CRITERIA_OPTIONS } from '../../../../models/misc-data/utils';
import IfConfigValue from '../../../../components/config-value/if';

import configValue from '../../../../components/config-value';
import userController from '../../../../models/user/controllers';
import userInterestsController from '../../../../models/user.interests/controller';
import userPreferences from '../../../../models/user.preference/controllers';
import userGoals from '../../../../models/user.goals/controllers';
import withHasSpecialTag from '../../../../models/user.tag/controllers/has-tag';
import CheckMarkItem from '../../../../components/check-mark-item';
import withHasTag from '../../../../models/user.tags/controllers/has-tag';
import BalanceRefiller, {
  PAYMENT_REASON,
} from '../../../../payment/utils/balance-refiller';

const FIELDS = Object.freeze({
  NAME: 'name',
  GENDER: 'gender',
  GOALS: 'goals',
  BIRTHDAY: 'birthday',
  PREFERRED_GENDER: 'preferred-gender',
  AGE: 'age',
  HOUSING: 'housing',
  ABOUT: 'about',
  WORK: 'occupation',
  EDUCATION: 'education',
  LANGUAGE: 'languages',
  RELATIONSHIP: 'relationship',
  KIDS: 'kids',
  SMOKE: 'smoke',
  DRINK: 'drink',
  HEIGHT: 'height',
  BODY_TYPE: 'bodytype',
  EYES: 'eye',
  HAIR: 'hair',
  INTERESTS: 'interests',
  LOCATION: 'location',
  PREFERENCES_ABOUT: 'preferences-about',
});

class ProfileParametersView extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string,
    userInterests: PropTypes.array,
    interestsEnabled: PropTypes.bool,
    updateInterests: PropTypes.func,
    updateUser: PropTypes.func,
    updatePreference: PropTypes.func,
    userNameMaxLength: PropTypes.number,
    genderEditingEnabled: PropTypes.bool,
    user: PropTypes.object,
    minAge: PropTypes.string,
    maxAge: PropTypes.string,
    emptyPreferredGenderEnabled: PropTypes.bool,
    preference: PropTypes.object,
    setHost: PropTypes.func,
    setGoals: PropTypes.func,
    goals: PropTypes.array,
    membershipIsInactive: PropTypes.bool,
    navigation: PropTypes.object,
    host: PropTypes.object,
  };

  onPress = (type, title, value) => {
    const {
      id,
      userNameMaxLength,
      user,
      userInterests,
      minAge,
      maxAge,
      emptyPreferredGenderEnabled,
      preference,
      setHost,
      goals,
      membershipIsInactive,
      navigation,
    } = this.props;
    const data = { props: { title, value } };
    let patchFunc = this.patch;

    switch (type) {
      case FIELDS.NAME:
        data.screen = ROUTES.TEXT_EDITING;
        data.props = {
          ...data.props,
          maxLength: userNameMaxLength,
        };
        patchFunc = newData => {
          if (
            newData &&
            typeof newData[FIELDS.NAME] === 'string' &&
            newData[FIELDS.NAME].trim()
          ) {
            this.patch({
              ...newData,
              [FIELDS.NAME]: newData[FIELDS.NAME].trim(),
            });
          }
        };
        break;
      case FIELDS.GENDER:
        data.screen = ROUTES.SELECTION_EDITING;
        data.props = {
          ...data.props,
          allowUnselection: false,
          availableValues: {
            fem: Resources.strings.woman,
            mal: Resources.strings.man,
          },
          selectedItems: [user.gender],
        };
        patchFunc = async newData => {
          try {
            await Promise.all([
              this.patch(newData),
              this.patchPreferences({
                ...newData,
                [FIELDS.PREFERRED_GENDER]: oppositeGender(newData.gender),
              }),
            ]);

            const features = flux.get(UserFeatures, id);

            if (features) features.actions.get();
          } catch (e) {
            // TODO
          }
        };
        break;
      case FIELDS.BIRTHDAY:
        data.screen = ROUTES.DATE_EDITING;
        data.props.minDate = new Date().dateWithYearOffset(-minAge);
        break;
      case FIELDS.PREFERRED_GENDER:
        data.screen = ROUTES.SELECTION_EDITING;
        data.props = {
          ...data.props,
          allowUnselection: emptyPreferredGenderEnabled,
          multiSelection: emptyPreferredGenderEnabled,
          availableValues: {
            fem: Resources.strings.woman,
            mal: Resources.strings.man,
          },
          selectedItems:
            !preference['preferred-gender'] && emptyPreferredGenderEnabled
              ? ['fem', 'mal']
              : [preference['preferred-gender']],
        };
        patchFunc = this.patchPreferences;
        break;
      case FIELDS.HOUSING:
        // eslint-disable-next-line no-case-declarations
        const action = () => setHost(!value);

        if (membershipIsInactive) {
          this.subscribe(action);
        } else {
          action();
        }

        return;
      case FIELDS.ABOUT:
        data.screen = ROUTES.TEXT_EDITING;
        data.props = {
          ...data.props,
          maxLength: 1024,
          placeholder:
            Resources.strings['edit-profile-screen-about-text-placeholder'],
        };
        break;
      case FIELDS.PREFERENCES_ABOUT:
        data.screen = ROUTES.TEXT_EDITING;
        data.props = {
          ...data.props,
          maxLength: 1024,
          placeholder: Resources.strings['edit-profile-about-partner-title'],
        };
        patchFunc = this.patchPreferencesAbout;
        break;
      case FIELDS.WORK:
        data.screen = ROUTES.TEXT_EDITING;
        break;
      case FIELDS.EDUCATION:
        data.screen = ROUTES.SELECTION_EDITING;
        data.props = {
          ...data.props,
          dataType: CRITERIA_OPTIONS.EDUCATIONS,
          selectedItems: [user.education],
        };
        break;
      case FIELDS.LANGUAGE:
        data.screen = ROUTES.SELECTION_EDITING;
        data.props = {
          ...data.props,
          dataType: CRITERIA_OPTIONS.LANGUAGES,
          selectedItems: user.languages,
          multiSelection: true,
        };
        break;
      case FIELDS.RELATIONSHIP:
        data.screen = ROUTES.SELECTION_EDITING;
        data.props = {
          ...data.props,
          dataType: CRITERIA_OPTIONS.RELATIONSHIPS,
          selectedItems: [user.relationship],
        };
        break;
      case FIELDS.KIDS:
        data.screen = ROUTES.SELECTION_EDITING;
        data.props = {
          ...data.props,
          availableValues: {
            false: Resources.strings.no,
            true: Resources.strings.yes,
          },
          selectedItems: [user.kids !== undefined ? user.kids.toString() : ''],
        };
        break;
      case FIELDS.SMOKE:
        data.screen = ROUTES.SELECTION_EDITING;
        data.props = {
          ...data.props,
          dataType: CRITERIA_OPTIONS.SMOKE,
          selectedItems: [user.smoke],
        };
        break;
      case FIELDS.DRINK:
        data.screen = ROUTES.SELECTION_EDITING;
        data.props = {
          ...data.props,
          dataType: CRITERIA_OPTIONS.DRINKING,
          selectedItems: [user.drink],
        };
        break;
      case FIELDS.BODY_TYPE:
        data.screen = ROUTES.SELECTION_EDITING;
        data.props = {
          ...data.props,
          dataType: CRITERIA_OPTIONS.BODY_TYPE,
          selectedItems: [user.bodytype],
        };
        break;
      case FIELDS.EYES:
        data.screen = ROUTES.SELECTION_EDITING;
        data.props = {
          ...data.props,
          dataType: CRITERIA_OPTIONS.EYES,
          selectedItems: [user.eye],
        };
        break;
      case FIELDS.HAIR:
        data.screen = ROUTES.SELECTION_EDITING;
        data.props = {
          ...data.props,
          dataType: CRITERIA_OPTIONS.HAIRS,
          selectedItems: [user.hair],
        };
        break;
      case FIELDS.INTERESTS:
        data.screen = ROUTES.INTERESTS_EDITING;
        data.props = {
          ...data.props,
          dataType: CRITERIA_OPTIONS.INTERESTS,
          selectedItems: userInterests,
        };
        patchFunc = this.patchInterests;
        break;
      case FIELDS.AGE:
        data.screen = ROUTES.RANGE_PICKER_EDITING;
        data.props.minValue = preference.minage;
        data.props.maxValue = preference.maxage;
        data.props.defaultMin = minAge;
        data.props.defaultMax = maxAge;
        patchFunc = newData => {
          this.patchPreferences({
            minage: newData[FIELDS.AGE].min,
            maxage: newData[FIELDS.AGE].max,
          });
        };
        break;
      case FIELDS.HEIGHT:
        data.screen = ROUTES.PICKER_EDITING;
        data.props.min = 90;
        data.props.max = 240;
        data.props.placeholder =
          Resources.strings['edit-profile-screen-height-title'];
        data.props.value = user.height;
        break;
      case FIELDS.GOALS:
        data.screen = ROUTES.SELECTION_EDITING;
        data.props = {
          ...data.props,
          availableValues: Object.values(USER_GOALS).reduce((result, goal) => {
            // eslint-disable-next-line no-param-reassign
            result[goal] = Resources.strings.goalName(goal);

            return result;
          }, {}),
          multiSelection: true,
          allowUnselection: true,
          selectedItems: goals,
        };
        patchFunc = this.patchGoals;
        break;
      case FIELDS.LOCATION:
        data.screen = ROUTES.LOCATION_EDITING;
        patchFunc = this.patchLocation;
        break;
      default:
        return;
    }
    data.props.onComplete = selectedValue => {
      const dict = {};

      if (type === FIELDS.PREFERRED_GENDER && Array.isArray(selectedValue)) {
        if (!selectedValue.length || selectedValue.length > 1) {
          dict[type] = null;
        } else {
          [dict[type]] = selectedValue;
        }
      } else {
        dict[type] = selectedValue;
      }

      patchFunc(dict);
    };

    navigation.navigate(data.screen, data.props);
  };

  patchLocation = data => {
    if (!data.location) {
      return;
    }
    const { city, country } = data.location;
    const changes = {
      latitude: `${data.location.lat}`,
      longitude: `${data.location.lon}`,
    };

    if (city) changes.city = city;
    if (country) changes.country = country;

    this.patch(changes);
  };

  patchGoals = ({ goals }) => {
    const { setGoals } = this.props;

    if (goals && setGoals) setGoals(goals);
  };

  patchPreferencesAbout = data => {
    const { updatePreference } = this.props;

    if (updatePreference && data['preferences-about'] !== undefined) {
      updatePreference({ about: data['preferences-about'] });
    }
  };

  patch = async data => {
    const { updateUser } = this.props;

    if (updateUser) {
      return updateUser(data);
    }

    return null;
  };

  patchPreferences = async data => {
    const { updatePreference } = this.props;

    if (updatePreference) return updatePreference(data);

    return null;
  };

  patchInterests = data => {
    const { updateInterests } = this.props;

    if (updateInterests) updateInterests(data.interests);
  };

  subscribe = (...args) => {
    BalanceRefiller.subscribe(PAYMENT_REASON.BECOME_HOST, ...args);
  };

  aboutPartnerPressed = () => {
    const { preference } = this.props;

    this.onPress(
      FIELDS.PREFERENCES_ABOUT,
      Resources.strings['edit-profile-about-partner-editing-title'],
      (preference && preference.about) || '',
    );
  };

  render() {
    const {
      genderEditingEnabled,
      user: {
        birthday,
        gender,
        kids,
        height,
        name,
        about,
        country,
        city,
        occupation,
        education,
        languages,
        relationship,
        smoke,
        drink,
        bodytype,
        eye,
        hair,
      },
      userInterests,
      interestsEnabled,
      preference,
      emptyPreferredGenderEnabled,
      goals,
      host,
    } = this.props;

    const genderDisplayText = Resources.strings.genderName(gender);
    const birthdayDisplayText =
      birthday && getDisplayDate(birthday['birth-date'], true);

    let lookingForText = Resources.strings.genderName(
      preference['preferred-gender'],
    );

    if (!lookingForText && emptyPreferredGenderEnabled) {
      lookingForText = Resources.strings['preferred-gender-empty-value-name'];
    }
    const maxAge = preference.maxage;
    const minAge = preference.minage;
    let ageText = '';

    if (minAge && maxAge) {
      ageText = `${minAge} — ${maxAge}`;
    }

    let kidsText = '';

    if (kids !== undefined && kids !== '') {
      if (typeof kids === 'string') {
        kidsText =
          kids === 'true' ? Resources.strings.yes : Resources.strings.no;
      } else {
        kidsText = kids ? Resources.strings.yes : Resources.strings.no;
      }
    }

    let heightDisplayText = '';

    const goalsText = (goals || [])
      .map(goal => Resources.strings.goalName(goal))
      .join(', ');

    const preferencesAboutText = preference && preference.about;
    const preferencesAboutPlaceholder = !preferencesAboutText;

    if (height) {
      heightDisplayText = Resources.strings.formatString(
        Resources.strings['edit-profile-screen-height-value-format'],
        height,
      );
    }

    const interestsView =
      userInterests.length > 0 ? (
        <TravelActivitiesComponent
          dataType="interests"
          userInterests={userInterests}
          itemTitleStyle={styles.interestTitle}
        />
      ) : (
        <Item
          title={Resources.strings['edit-profile-screen-add-interests-title']}
          titleStyle={styles.interestsButton}
          value=""
          type={FIELDS.INTERESTS}
          onPress={this.onPress}
        />
      );

    return (
      <View style={styles.container}>
        <IfConfigValue
          path="markup.edit-profile-screen.personal-information-separate-block-enabled"
          equalsTo={true}
        >
          <Text style={styles.infoTitle}>
            {
              Resources.strings[
                'account-screen-personal-information-block-title'
              ]
            }
          </Text>
        </IfConfigValue>
        <Item
          title={Resources.strings.name}
          value={name}
          type={FIELDS.NAME}
          onPress={this.onPress}
        />
        <Item
          title={Resources.strings.gender}
          value={genderDisplayText}
          type={FIELDS.GENDER}
          onPress={this.onPress}
          disabled={!genderEditingEnabled}
        />
        <Item
          title={Resources.strings['edit-profile-screen-birthday-title']}
          value={birthdayDisplayText}
          type={FIELDS.BIRTHDAY}
          onPress={this.onPress}
        />
        <IfConfigValue path="features.anonymous-using-enabled" equalsTo={true}>
          <Text style={styles.birthdayInfo}>
            {Resources.strings['edit-profile-screen-anonymous-birthday-info']}
          </Text>
        </IfConfigValue>
        <IfConfigValue path="features.anonymous-using-enabled" equalsTo={false}>
          <IfConfigValue
            path="markup.edit-profile-screen.looking-for-separate-block-enabled"
            equalsTo={true}
          >
            <Text style={styles.infoTitle}>
              {Resources.strings['account-screen-looking-for-block-title']}
            </Text>
          </IfConfigValue>
          <Item
            title={Resources.strings['edit-profile-screen-looking-for-title']}
            value={lookingForText}
            type={FIELDS.PREFERRED_GENDER}
            onPress={this.onPress}
          />
          <Item
            title={Resources.strings['edit-profile-screen-age-range-title']}
            value={ageText}
            type={FIELDS.AGE}
            onPress={this.onPress}
          />
          {styles.$lookingForDescriptionVisible && (
            <TouchableOpacity
              style={styles.lookingForDescriptionContainer}
              onPress={this.aboutPartnerPressed}
            >
              <Text
                style={
                  preferencesAboutPlaceholder
                    ? styles.lookingForTitlePlaceholder
                    : styles.lookingForTitle
                }
              >
                {preferencesAboutText ||
                  Resources.strings['edit-profile-about-partner-title']}
              </Text>
            </TouchableOpacity>
          )}

          <IfConfigValue path="features.goals-enabled" equalsTo={true}>
            <Item
              title={Resources.strings['edit-profile-screen-user-goals-title']}
              value={goalsText}
              type={FIELDS.GOALS}
              onPress={this.onPress}
            />
          </IfConfigValue>
          <IfConfigValue path="features.housekeeping-enabled" equalsTo={true}>
            <CheckMarkItem
              title={Resources.strings['edit-profile-screen-housing']}
              selected={host || false}
              onSelectionChange={() =>
                this.onPress(
                  FIELDS.HOUSING,
                  Resources.strings['edit-profile-screen-housing'],
                  host,
                )
              }
            />
          </IfConfigValue>
        </IfConfigValue>
        <View style={styles.aboutMeContainer}>
          <AboutMe
            text={about}
            onPress={() =>
              this.onPress(
                FIELDS.ABOUT,
                Resources.strings['edit-profile-screen-about-title'],
                about,
              )
            }
          />
        </View>
        <IfConfigValue path="features.anonymous-using-enabled" equalsTo={false}>
          {!!interestsEnabled && [
            <Text key="profile-interests-title" style={styles.infoTitle}>
              {Resources.strings['profile-interests-title']}
            </Text>,
            <TouchableOpacity
              key="profile-interests-view"
              onPress={() =>
                this.onPress(
                  FIELDS.INTERESTS,
                  Resources.strings['profile-interests-title'],
                  userInterests,
                )
              }
            >
              {interestsView}
              <View style={styles.separator} />
            </TouchableOpacity>,
          ]}

          <Text style={styles.infoTitle}>{Resources.strings.info}</Text>
          <IfConfigValue
            path="markup.edit-profile-screen.can-edit-location"
            equalsTo={true}
          >
            {city && country ? (
              <ParametrizedItem
                title={Resources.strings['edit-profile-screen-location-title']}
                dataType={CRITERIA_OPTIONS.COUNTRIES}
                type={FIELDS.LOCATION}
                value={country}
                valueFormat={city ? `${city}, {0}` : '{0}'}
                onPress={this.onPress}
              />
            ) : (
              <Item
                title={Resources.strings['edit-profile-screen-location-title']}
                value={city || ''}
                type={FIELDS.LOCATION}
                onPress={this.onPress}
              />
            )}
          </IfConfigValue>
          <Item
            title={Resources.strings['edit-profile-screen-work-title']}
            value={occupation || ''}
            type={FIELDS.WORK}
            onPress={this.onPress}
          />
          <ParametrizedItem
            title={Resources.strings['edit-profile-screen-education-title']}
            dataType="educations-v2"
            value={education || ''}
            type={FIELDS.EDUCATION}
            onPress={this.onPress}
          />
          <ParametrizedItem
            title={Resources.strings['edit-profile-screen-languages-title']}
            dataType="languages"
            value={languages || ''}
            type={FIELDS.LANGUAGE}
            onPress={this.onPress}
          />
          <ParametrizedItem
            title={Resources.strings['edit-profile-screen-relationship-title']}
            dataType="relationships"
            value={relationship || ''}
            type={FIELDS.RELATIONSHIP}
            onPress={this.onPress}
          />
          <Item
            title={Resources.strings['edit-profile-screen-kids-title']}
            value={kidsText}
            type={FIELDS.KIDS}
            onPress={this.onPress}
          />
          <ParametrizedItem
            title={Resources.strings['edit-profile-screen-smoke-title']}
            dataType="smoke"
            value={smoke || ''}
            type={FIELDS.SMOKE}
            onPress={this.onPress}
          />
          <ParametrizedItem
            title={Resources.strings['edit-profile-screen-drink-title']}
            dataType="drinking-v2"
            value={drink || ''}
            type={FIELDS.DRINK}
            onPress={this.onPress}
          />
          <Item
            title={Resources.strings['edit-profile-screen-height-title']}
            value={heightDisplayText}
            type={FIELDS.HEIGHT}
            onPress={this.onPress}
          />
          <ParametrizedItem
            title={Resources.strings['edit-profile-screen-bodytype-title']}
            dataType="bodytype"
            value={bodytype || ''}
            type={FIELDS.BODY_TYPE}
            onPress={this.onPress}
          />
          <ParametrizedItem
            title={Resources.strings['edit-profile-screen-eyes-title']}
            dataType="eyes"
            value={eye || ''}
            type={FIELDS.EYES}
            onPress={this.onPress}
          />
          <ParametrizedItem
            title={Resources.strings['edit-profile-screen-hair-title']}
            dataType="hairs"
            value={hair || ''}
            type={FIELDS.HAIR}
            onPress={this.onPress}
          />
        </IfConfigValue>
      </View>
    );
  }
}

export default configValue(
  userInterestsController(
    userPreferences(
      userController(
        withHasTag(
          userGoals(
            withHasSpecialTag(ProfileParametersView, {
              userIdPropName: 'id',
              tag: 'users.housing',
              existTagPropName: 'host',
              setActionPropName: 'setHost',
            }),
            {
              userIdPropName: 'id',
            },
          ),
          {
            tag: 'membership',
            userIdPropName: 'id',
            hasTagPropName: 'membershipIsInactive',
          },
        ),
      ),
    ),
  ),
  {
    minAge: 'age-range.min-age',
    maxAge: 'age-range.max-age',
    emptyPreferredGenderEnabled: 'features.empty-preferred-gender-enabled',
    interestsEnabled: 'features.interests-enabled',
    userNameMaxLength: 'validation.user-name.max-length',
    genderEditingEnabled: 'features.gender-editing-enabled',
  },
);
