import { combineLatest } from 'rxjs';
import {
  map,
  shareReplay,
  switchMap,
  tap,
  distinctUntilChanged,
  filter,
} from 'rxjs/operators';
import UserModel from '@sdv/domain/user/model';
import flux from '@sdv/domain/app/flux';
import { UserAliasesRepository } from '@sdv/domain/app/user/user-aliases-repository';
import IdentityModel from '@sdv/domain/identity/model';
import { singleton } from '@sdv/commons/utils/singleton';

class UserProfileAliases {
  static shared = singleton(() => new UserProfileAliases());

  constructor() {
    this.currentUserId = flux
      .get(IdentityModel)
      .store.rxState()
      .pipe(
        map(identity => identity?.id),
        filter(Boolean),
        distinctUntilChanged(),
        tap(id => {
          this.userAliasesRepository = UserAliasesRepository.shared(id);
        }),
        shareReplay(1),
      );
  }

  getAliasedUser = attendeeId => {
    const attendeeIdModel = flux.get(UserModel, attendeeId);

    if (!attendeeIdModel.store.isFilled()) {
      attendeeIdModel.actions.get();
    }

    return this.currentUserId.pipe(
      switchMap(() => {
        return combineLatest(
          this.userAliasesRepository.getAlias(attendeeId),
          attendeeIdModel.store.rxState(),
        );
      }),
      map(([aliases, user]) => {
        return { ...user, ...aliases };
      }),
    );
  };

  getAlias(attendeeId) {
    return this.currentUserId.pipe(
      switchMap(id => UserAliasesRepository.shared(id).getAlias(attendeeId)),
    );
  }

  getAllAliases() {
    return this.currentUserId.pipe(
      switchMap(id => UserAliasesRepository.shared(id).getAllAliases()),
    );
  }
}

export { UserProfileAliases };
