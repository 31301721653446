import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
    },

    title: {
        color: '$brandTextColor',
        fontFamily: '$brandFontFamily',
        fontSize: 16,
        '@media(max-width: 320)': {
            fontSize: 14
        },
        '@flavor dma': {
            fontSize: 18,
            color: '$infoTextColor'
        }
    },

    androidPickerContainer: {
        opacity: 0,
        position: 'absolute',
        width: '100%',
        height: '100%'
    },

    iosPickerContainer: {
        height: 220,
        flexDirection: 'column',
        alignItems: 'stretch',
        borderRadius: 8,
        backgroundColor: 'white',
        shadowRadius: 8,
        shadowOpacity: 0.15,
        shadowOffset: { width: 0, height: 0 },
        elevation: 8
    },

    placeholder: {
        color: '$brandPlaceholderColor'
    }

});

export default styles;
