import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $height: 68,
    container: {
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 16,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderTopWidth: 1,
        borderTopColor: '$brandBorderColor',
    },
    description: {
        fontSize: 12,
        fontFamily: '$brandFontFamily',
        color: '$brandTextColor',
        fontWeight: '500',
        flexShrink: 1,
        marginRight: 36,
    },
    buttonTitle: {
        fontFamily: '$brandFontFamily',
        color: '$brandTextColor',
        fontSize: 14,
        fontWeight: 'bold'
    },
    buttonContainer: {
        paddingHorizontal: 20,
        alignItems: 'center',
        height: 28,
        borderRadius: 14,
        width: 'auto'
    },
    bottomLine: {
        position: 'absolute',
        width: 20,
        height: 2,
        backgroundColor: '$disabledColor',
        bottom: 6
    },
    content: {
        alignItems: 'center'
    }
});

export default styles;
