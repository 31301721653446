import React from 'react';
import { Text, View, Image, TouchableWithoutFeedback } from 'react-native';
import PropTypes from 'prop-types';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';
import NewUserIndicator from 'dating-mobile/src/user/common/views/new-user-indicator';
import SystemMessage from 'dating-mobile/src/dialogs/common/views/message/system';
import DrawWhenUserReady from 'dating-mobile/src/user/common/views/draw-when-user-ready';
import Photo from '../../../../components/photo';
import MessageBase from '../../../common/views/message';
import UserController from '../../../../models/user/controllers';
import { MESSAGE_TYPES } from '../../../../models/common.messages/model';
import styles from './styles';
import Resources from '../../../../resources';

function StarIcon() {
    return (
        <Text>
            {/* eslint-disable-next-line global-require */}
            <Image style={styles.star} source={require('./assets/star_icon.png')} />
            <Text>&nbsp;</Text>
        </Text>
    );
}

class UserNameComponent extends React.Component {
    static displayName = 'dialogs.stream.message.user-name';

    static propTypes = {
        starred: PropTypes.bool,
        user: PropTypes.shape({
            name: PropTypes.string,
        }),
    };

    shouldComponentUpdate(props) {
        const { user } = this.props;

        return props.user.name !== user.name;
    }

    render() {
        const { starred, user } = this.props;
        const name = user.name ? `${user.name}: ` : null;

        if (!name) {
            return null;
        }

        return (
            <Text style={styles.userName}>
                {!!starred && <StarIcon />}
                {name}
                {!!starred && <StarIcon />}
            </Text>
        );
    }
}

const UserName = UserController(UserNameComponent);

class Message extends MessageBase {
    static displayName = 'dialogs.stream.message';

    static propTypes = {
        bus: PropTypes.object,
        userId: PropTypes.string,
        item: PropTypes.shape({
            tag: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            sender: PropTypes.string.isRequired,
            recipient: PropTypes.string.isRequired,
            type: PropTypes.number.isRequired,
            placeholder: PropTypes.string,
            read: PropTypes.bool,
            timestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            starred: PropTypes.bool,
        }),
        onShow: PropTypes.func,
    };

    componentDidMount() {
        this.props.onShow && this.props.onShow(this.props.item.tag);
    }

    onLongPress = () => {
        this.props.bus &&
            this.props.bus.emit('event.messages.message.long-press', this.props.item.tag);
    };

    render() {
        const { item } = this.props;

        const backgroundColorStyle = [MESSAGE_TYPES.FAVORITE, MESSAGE_TYPES.SYSTEM].includes(
            item.type,
        )
            ? { backgroundColor: 'rgba(0, 0, 0, 0)' }
            : { backgroundColor: 'rgba(0, 0, 0, 0.3)' };
        const text = item.outgoing ? `${item.placeholder} ` : item.placeholder;

        return (
            <TouchableWithoutFeedback onLongPress={this.onLongPress} onPress={this.props.onPress}>
                <View style={styles.container}>
                    <View style={styles.bubbleContainer}>
                        {item.type === MESSAGE_TYPES.SYSTEM && <SystemMessage {...item} />}
                        <View style={[styles.bubble, backgroundColorStyle]}>
                            {item.type === MESSAGE_TYPES.CHEER && (
                                <DrawWhenUserReady userId={this.props.item.sender}>
                                    <Text style={styles.text}>
                                        {this.props.userId === this.props.item.recipient && (
                                            <NewUserIndicator userId={this.props.item.sender} />
                                        )}
                                        <UserName
                                            id={this.props.item.sender}
                                            starred={this.props.item.starred}
                                            style={styles.name}
                                        />
                                        <Text>Sent a gift!</Text>
                                    </Text>
                                </DrawWhenUserReady>
                            )}
                            {item.type === MESSAGE_TYPES.NUDITY_WARNING && (
                                <Text style={styles.info}>
                                    {Resources.strings['stream-message-ban-warning-text']}
                                </Text>
                            )}
                            {item.type === MESSAGE_TYPES.DELETED && (
                                <Text style={styles.deleted}>
                                    {Resources.strings.streamMessageDeletedText}
                                </Text>
                            )}
                            {item.type === MESSAGE_TYPES.TEXT && (
                                <DrawWhenUserReady userId={this.props.item.sender}>
                                    <Text style={styles.text}>
                                        {this.props.userId === this.props.item.recipient && (
                                            <NewUserIndicator userId={this.props.item.sender} />
                                        )}
                                        <UserName
                                            id={this.props.item.sender}
                                            starred={this.props.item.starred}
                                            style={styles.name}
                                        />
                                        <Text>{text}</Text>
                                    </Text>
                                </DrawWhenUserReady>
                            )}
                        </View>
                        {item.type === MESSAGE_TYPES.CHEER && (
                            <View style={styles.cheerContainer}>
                                <Photo style={styles.cheer} path={item.content.basename} />
                            </View>
                        )}
                    </View>

                    {item.type === MESSAGE_TYPES.CHEER && (
                        <View style={{ width: 45, height: 40 }} />
                    )}
                </View>
            </TouchableWithoutFeedback>
        );
    }
}

export default withIdentityId(Message);
