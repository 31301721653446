import React from 'react';
import connect from '@sdv/connect';
import AllLettersMailbox from '@sdv/domain/dialogs.mailbox/all-letters-mailbox';
import PropTypes from 'prop-types';

function createController(View) {
  class Controller extends React.Component {
    static displayName = 'inbox.views.all-letters.controller';

    static propTypes = {
      letterChains: PropTypes.array.isRequired,
      getLetters: PropTypes.func.isRequired,
      endReached: PropTypes.bool,
      userId: PropTypes.string.isRequired,
      deleteChain: PropTypes.func.isRequired,
    };

    componentDidMount() {
      this.loadMore();
    }

    componentWillReceiveProps(nextProps) {
      if (this.props.userId !== nextProps.userID) {
        this.loadMore();
      }
    }

    loadMore = () => {
      if (!this.endReached) {
        this.props.getLetters && this.props.getLetters();
      }
    };

    deleteChain = chainId => {
      this.props.deleteChain && this.props.deleteChain(chainId);
    };

    render() {
      const props = { ...this.props };
      delete props['getLetters'];
      delete props['deleteChain'];
      return (
        <View
          {...props}
          loadMore={this.loadMore}
          deleteChain={this.deleteChain}
        />
      );
    }
  }

  function getModels(flux, props) {
    const models = {};

    if (props.userId) {
      models.mailbox = flux.get(AllLettersMailbox, props.userId);
    }

    return models;
  }

  function mapStoresToProps(models) {
    const state = models.mailbox ? models.mailbox.store.getState() : {};
    const props = {};
    props.letterChains = state.letterChains;
    props.endReached = state.endReached;
    return props;
  }

  function mapActionsToProps(models) {
    const props = {};

    if (models.mailbox) {
      props.getLetters = models.mailbox.actions.getLetters;
      props.deleteChain = models.mailbox.actions.deleteChain;
    }

    return props;
  }

  function shouldReconnect(props, nextProps) {
    return props.userId !== nextProps.userId;
  }

  return connect(
    getModels,
    mapStoresToProps,
    mapActionsToProps,
    shouldReconnect,
  )(Controller);
}

export default createController;
