const SPECIAL_CHARACTERS = ['/', '\\', '|', '{', '}', '[', ']', '(', ')', '*', '+', '.', '?', '^', '$'];

export function escapeRegExpSpecialCharacters(string) {
    let result = string;
    SPECIAL_CHARACTERS.forEach(character => {
        const pattern = `\\${character}`;
        const regExp = new RegExp(pattern, 'g');
        result = result.replace(regExp, pattern);
    });
    return result;
}
