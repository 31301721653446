import qs from 'qs';
import { BaseStore } from '@sdv/model';
import Actions from './actions';
import callsApi from '../call.api/api';

function createStore(id) {
  const { attendee } = qs.parse(id);

  class Store extends BaseStore {
    constructor(flux) {
      super();

      this.state = {
        localStream: null,
        remoteStream: null,
        localStreamEnabled: false,
        remoteStreamEnabled: false,
        audioMuted: false,
        speakerphoneOn: false,
      };

      const actions = flux.getActions(Actions, id);

      this.bindAction(actions.rotateCamera, this.rotateCamera);
      this.bindAction(actions.setVideoEnabled, this.setVideoEnabled);
      this.bindAction(actions.setAudioMuted, this.setAudioMuted);
      this.bindAction(actions.setSpeakerphoneOn, this.setSpeakerphoneOn);

      callsApi.addListener(
        'event.call.stream.local.attached',
        this.attachLocalStream,
      );
      callsApi.addListener(
        'event.call.stream.remote.attached',
        this.attachRemoteStream,
      );
      callsApi.addListener('event.chat.media.state.changed', this.updateState);
      callsApi.addListener('event.chat.media.destroyed', this.onCallEnd);
      callsApi.addListener('command.chat.media.stop', this.onCallEnd);
      callsApi.addListener('command.invite.remove', this.onCallEnd);
    }

    output(state) {
      return {
        localStream: state.localStreamEnabled ? state.localStream : null,
        remoteStream: state.remoteStreamEnabled ? state.remoteStream : null,
        audioMuted: state.audioMuted,
        speakerphoneOn: state.speakerphoneOn,
      };
    }

    attachRemoteStream = (streamAttendee, stream) => {
      if (attendee === streamAttendee) {
        this.setState({
          remoteStream: stream,
        });
      }
    };

    attachLocalStream = (streamAttendee, stream) => {
      if (attendee === streamAttendee) {
        callsApi.emit(
          'command.chat.media.audio.toggle',
          attendee,
          !this.state.audioMuted,
        );
        this.setState({
          localStream: stream,
        });
      }
    };

    updateState = state => {
      if (attendee !== state.attendee) {
        return;
      }

      this.setState({
        localStreamEnabled: state.state.out || state.pending.out,
        remoteStreamEnabled: state.state.in || state.pending.in,
      });
    };

    rotateCamera = () => {
      callsApi.emit('command.chat.media.video.rotate', attendee);
    };

    setVideoEnabled = enabled => {
      callsApi.emit('command.chat.media.video.toggle', attendee, enabled);
    };

    setAudioMuted = audioMuted => {
      callsApi.emit('command.chat.media.audio.toggle', attendee, !audioMuted);
      this.setState({
        audioMuted,
      });
    };

    setSpeakerphoneOn = speakerphoneOn => {
      callsApi.emit(
        'command.chat.media.audio.speakerphone.toggle',
        speakerphoneOn,
      );
      this.setState({
        speakerphoneOn,
      });
    };

    onCallEnd = () => {
      this.setState({
        audioMuted: false,
        speakerphoneOn: false,
      });
    };
  }

  Store.displayName = createStore.getDisplayName(id);

  Store.config = {
    getState(state) {
      return {
        localStream: state.localStreamEnabled ? state.localStream : null,
        remoteStream: state.remoteStreamEnabled ? state.remoteStream : null,
        remoteStreamEnabled: state.remoteStreamEnabled,
        audioMuted: state.audioMuted,
        speakerphoneOn: state.speakerphoneOn,
      };
    },
  };

  return Store;
}

createStore.getDisplayName = function(id) {
  return `call-media.${id}`;
};

export default createStore;
