import EStyleSheet from 'react-native-extended-stylesheet';

const textStyle = {
    fontFamily: '$brandFontFamily',
    color: '$brandTextColor',
};

const styles = EStyleSheet.create({
    container: {
        width: '100%',
        height: '100%',
        backgroundColor: '$primaryBackgroundColor',
        justifyContent: 'space-between',
        flexGrow: 1,
        paddingHorizontal: 40,
    },
    image: {
        width: '100%',
        flexShrink: 1,
    },
    subTitle: {
        ...textStyle,
        fontSize: 14,
    },
    title: {
        ...textStyle,
        fontWeight: 'bold',
        fontSize: 26,
        marginBottom: 36,
    },
    button: {
        marginBottom: 40,
    },
});

export default styles;
