import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Text, View } from 'react-native';

import Resources from 'dating-mobile/src/resources';
import { formatTimeNumericLong } from 'dating-mobile/src/components/time/formatters';

import styles from './styles';

export default class BoostDiscountTimeout extends PureComponent {
  static displayName = 'payment.views.boost-discount-timeout.view';

  static propTypes = {
    remainingTime: PropTypes.number,
    placeholder: PropTypes.object,
    style: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
  };

  render() {
    const { remainingTime, placeholder, style } = this.props;

    if (!remainingTime) {
      return placeholder || null;
    }

    return (
      <View style={[styles.container, style]}>
        <Text style={styles.title}>
          {Resources.strings['boost-screen-discount-timeout-title']}
        </Text>
        <Text style={styles.timer}>{formatTimeNumericLong(remainingTime)}</Text>
      </View>
    );
  }
}
