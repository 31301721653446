import { AppState } from 'react-native';
import { Observable } from 'rxjs';
import { map, shareReplay, distinctUntilChanged } from 'rxjs/operators';
import { singleton } from '@sdv/commons/utils/singleton';

class StateBasedAppFocus {
    static shared = singleton(() => new StateBasedAppFocus());

    constructor() {
        const appState = new Observable(observer => {
            observer.next(AppState.currentState);
            const stateChange = (state) => {
                observer.next(state);
            };
            AppState.addEventListener('change', stateChange);
            const dispose = () => {
                AppState.removeEventListener('change', stateChange);
            };
            return dispose;
        });

        this.focused = appState.pipe(
            map(state => state === 'active'),
            distinctUntilChanged(),
            shareReplay(1)
        );
    }
}

export default StateBasedAppFocus;
