import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({

    header: {
        backgroundColor: '$primaryBackgroundColor',
        borderBottomColor: '$primaryBackgroundColor',
        shadowColor: '$primaryBackgroundColor',
        shadowRadius: 0,
        shadowOpacity: 0,
        elevation: 0,
        shadowOffset: {
            height: 0,
        }
    },

    headerTitle: {
        fontFamily: '$brandFontFamily',
        color: '$primaryTextColor',
        width: 250,
        fontWeight: 'bold',
        fontSize: 18,
    },

    container: {
        flex: 1,
        backgroundColor: '$primaryBackgroundColor',
        justifyContent: 'center',
        alignItems: 'center'
    },

    list: {
        width: '100%',
        marginTop: 10,
        paddingLeft: 9,
        paddingRight: 9
    },

    contentContainer: {
        justifyContent: 'center',
        flex: 1,
    },

    itemContainer: {
        width: '33.3%'
    },

    itemSeparator: {
        height: 8
    },

    footer: {
        width: '100%',
        paddingVertical: 24,
        paddingHorizontal: 40
    }

});

export default styles;
