import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-native';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import withUserController from 'dating-mobile/src/models/user/controllers';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';
import { Stock } from '@sdv/domain/payment/stock';
import Resources from 'dating-mobile/src/resources';
import { Purchase } from 'dating-mobile/src/payment/methods/purchase';
import UserTagsModel from '@sdv/domain/user/tags/personal';
import flux from '@sdv/domain/app/flux';
import { PAYMENT_REASON } from '@sdv/domain/app/balance-refiller';
import withUserBalance from 'dating-mobile/src/models/credits.accounts.balance/controllers/controller';
import { SupportedMethods } from '@sdv/domain/app/payment/shop-info';

export default function createController(Component) {
  class Controller extends PureComponent {
    static displayName = 'stepped-registration.screens.boost.controller';

    static propTypes = {
      userId: PropTypes.string,
      user: PropTypes.object,
      onComplete: PropTypes.func,
      balance: PropTypes.shape({
        amount: PropTypes.number,
      }),
    };

    state = {
      isLoading: false,
      invoice: null,
    };

    componentDidMount() {
      this.subscribe();
    }

    componentDidUpdate(prevProps) {
      const { userId } = this.props;

      if (userId !== prevProps.userId) {
        this.unsubscribe();
        this.subscribe();
      }
    }

    componentWillUnmount() {
      this.unsubscribe();
    }

    onSubmit = () => {
      const { userId, balance } = this.props;
      const { invoice } = this.state;

      if (invoice) {
        this.setState({
          isLoading: true,
        });

        const options = {};

        if (invoice?.price?.currency === SupportedMethods.credits) {
          options.minCreditsAmount =
            balance?.amount && invoice?.price?.includingTax
              ? invoice?.price?.includingTax - balance?.amount
              : null;
          options.descriptionTitle = options.minCreditsAmount
            ? Resources.strings.formatString(
                Resources.strings[
                  'onboarding-screen-purchase-credits-to-boost-profile-payment-title'
                ],
                options.minCreditsAmount,
              )
            : '';
          options.descriptionHint = null;
        }

        const subscription = Purchase.shared()
          .pay(userId, invoice, PAYMENT_REASON.ONBOARDING, null, options)
          .subscribe(({ success }) => {
            this.setState({
              isLoading: false,
            });

            if (success) {
              flux.get(UserTagsModel, userId)?.actions?.get();
              this.onPaymentCompleted();
            }
          });

        if (this.disposeBag) {
          this.disposeBag.add(subscription);
        }
      }
    };

    onPaymentCompleted() {
      const { onComplete } = this.props;

      Alert.alert(Resources.strings['purchase-boost-success-message'], null, [
        {
          text: Resources.strings.ok,
          style: 'cancel',
          onPress: onComplete,
          onDismiss: onComplete,
        },
      ]);
    }

    subscribe() {
      const { userId } = this.props;

      if (userId) {
        this.setState({
          isLoading: true,
        });

        const subscription = Stock.shared(userId)
          .preloadedPackagesInStock.pipe(
            map(
              products =>
                products?.find(p => p?.[1]?.[0]?.boostTime === 1800)?.[1]?.[0],
            ),
          )
          .subscribe(invoice => {
            this.setState({
              invoice,
              isLoading: false,
            });
          });

        this.disposeBag = new Subscription();
        this.disposeBag.add(subscription);
      }
    }

    unsubscribe() {
      if (this.disposeBag) {
        this.disposeBag.unsubscribe();
        this.disposeBag = null;
      }
    }

    render() {
      const { isLoading, invoice } = this.state;
      const { user } = this.props;

      return (
        <Component
          onSubmit={this.onSubmit}
          isLoading={isLoading}
          invoice={invoice}
          gender={user.gender}
        />
      );
    }
  }

  return withIdentityId(
    withUserBalance(
      withUserController(Controller, { userIdPropName: 'userId' }),
      { userIdPropName: 'userId' },
    ),
    'userId',
  );
}
