import { createOptimisticStore } from '@sdv/model';

function createStore(id, channel, Actions) {

    class Store extends createOptimisticStore(['patch']){

        static displayName = `notifications-subscriptions-${channel}.${id}`;

        constructor(flux) {
            let actions = flux.getActions(Actions, id);

            super(actions);

            this.bindAction(actions.get, this.get);
        }

        get (payload) {
            this.state.data = payload;
            this.setState(this.state);
        };

    }

    return Store;
}

export default createStore;
