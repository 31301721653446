import EStyleSheet from 'react-native-extended-stylesheet';
import { I18nManager } from 'react-native';

const styles = EStyleSheet.create({
    container: {
        width: 44,
        height: 44,
        justifyContent: 'center',
        alignItems: 'center',
    },

    image: {
        transform: [
            {
                scaleX: I18nManager.isRTL ? -1 : 1,
            },
        ],
    },

    disabled: {},
});

export default styles;
