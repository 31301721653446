import flux from '@sdv/domain/app/flux';
import { bindCallback, of, throwError } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export default flux.api;

export function toObservable(f, ...args) {
    return bindCallback(f)(...args).pipe(
        mergeMap(args => {
            const [error, data] = args;
            if (error) {
                return throwError(error);
            } else {
                return of(data);
            }
        })
    );
}
