import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import styles from './styles';
import Photo, { PHOTO_TRANSFORMATION_TYPE } from '../../../../components/photo';
import withAspectRatio from '../../../../components/aspect-ratio';
import UserHocController from '../../../../models/user/controllers';

const AspectRatioView = withAspectRatio(View);

class FreeStartMessageListItem extends React.PureComponent {
    static displayName = 'user.free-start-messages.views.list-item';
    static propTypes = {
        isMiddleItem: PropTypes.bool,
    };

    render() {
        const containerStyle = [styles.container, this.props.isMiddleItem ? styles.middleItemModifier : {}];
        return (
            <View style={containerStyle}>
                <AspectRatioView style={styles.content}>
                <Photo
                    style={styles.image}
                    userId={this.props.id}
                    basename={this.props.thumbnail}
                    round={true}
                    transformation={PHOTO_TRANSFORMATION_TYPE.DETECT_FACE}
                    placeholder={'avatar'}
                />
                </AspectRatioView>
            </View>
    );
    }

}
export default UserHocController(FreeStartMessageListItem);

export function getHeight(itemWidth) {
    return itemWidth;
}
