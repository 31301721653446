import { combineLatest, defer, of } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import equal from 'fast-deep-equal';
import flux from '@sdv/domain/app/flux';
import Config from 'dating-mobile/src/models/config/model';
import StreamsModel from 'dating-mobile/src/models/dialogs.streams/model';

export default class StreamsWithCustomTop {
    constructor() {
        const streamsModel = flux.get(StreamsModel);

        const topStreamers = flux
            .get(Config)
            .store.rxState()
            .pipe(map(state => state['top-streamers']));

        const streams = streamsModel.store
            .rxState()
            .pipe(distinctUntilChanged((prev, next) => equal(prev, next)));

        const getStreams = defer(() => of(streamsModel.actions.get()));

        this.subscription = combineLatest(streams, topStreamers, getStreams).pipe(
            map(([nextStreams, nextTopStreamers]) => {
                const updatedStreams = [...nextStreams.streams];

                updatedStreams.sort((a, b) => {
                    return -(nextTopStreamers.indexOf(a) - nextTopStreamers.indexOf(b));
                });

                return {
                    ...nextStreams,
                    streams: updatedStreams,
                };
            }),
            distinctUntilChanged(),
        );
    }
}
