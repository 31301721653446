import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, Image} from 'react-native';
import styles from './styles';
import Resources from '../../../resources';
import Button from '../../../components/buttons/base';

class StreamsTopicsPickingBonusView extends React.PureComponent {
    static propTypes = {
        bonusAmount: PropTypes.number,
        onPress: PropTypes.func,
    };
    static displayName = 'streaming.streams-topics-picking-bonus.view';

    render() {
        return (
            <View style={styles.container}>
                <View style={styles.content}>
                <Text style={styles.topTitle}>{Resources.strings['streams-topics-picking-bonus-screen-title']}</Text>
                <View style={styles.amountContainer}>
                    <Text style={styles.amountText}>{this.props.bonusAmount || ''}</Text>
                    <Image source={Resources.images.coinIconBig()} style={styles.coinIcon}/>
                </View>
                <Text style={styles.description}>{Resources.strings['streams-topics-picking-bonus-screen-description']}</Text>
                </View>
                <View style={styles.buttonContainer}>
                    <Button
                        title={Resources.strings['streams-topics-picking-complete-button-text']}
                        onPress={this.props.onPress}
                    />
                </View>
            </View>
        )
    }

}

export default StreamsTopicsPickingBonusView;
