import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { View, Text, TouchableOpacity } from 'react-native';

import styles from './styles';

export class FaqItem extends PureComponent {
    static displayName = 'faq.views.faq-item';

    static propTypes = {
        localizedTitle: PropTypes.string,
        goToDetails: PropTypes.func,
        id: PropTypes.string,
        hideArrow: PropTypes.bool,
    };

    goToDetails = () => {
        const { goToDetails, id } = this.props;

        goToDetails(id);
    };

    render() {
        const { localizedTitle, hideArrow } = this.props;

        return (
            <TouchableOpacity style={styles.container} onPress={this.goToDetails}>
                <Text style={styles.title}>{localizedTitle}</Text>
                {!hideArrow && <View style={styles.arrow} />}
            </TouchableOpacity>
        );
    }
}
