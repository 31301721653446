import React from 'react';
import { Picker, View, I18nManager, Platform } from 'react-native';
import PropTypes from 'prop-types';
import styles from './styles';

const isAndroid = Platform.OS === 'android';

class RangePicker extends React.Component {
  static displayName = 'component.range.picker';

  static propTypes = {
    onUpdateRange: PropTypes.func.isRequired,
    placeholder: PropTypes.object,
    range: PropTypes.object,
    separator: PropTypes.element,
    selectedMin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectedMax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    allowEmpty: PropTypes.bool,
    measure: PropTypes.string,
    itemStyle: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.number,
      PropTypes.array,
    ]),
  };

  handlers = {};

  constructor(props) {
    super(props);

    ['min', 'max'].forEach(key => {
      this.handlers[key] = this.handleChange(key);
    });

    this.state = {
      forceStyle: {},
      min: parseInt(props.selectedMin, 10) || (props.allowEmpty ? null : 0),
      max: parseInt(props.selectedMax, 10) || (props.allowEmpty ? null : 0),
    };
  }

  // Хак для исправления бага нативного пикера в старых андроидах с RTL локализацией
  // http://issues.local/browse/NP-286
  componentDidMount() {
    if (Platform.OS === 'android' && I18nManager.isRTL) {
      setTimeout(() =>
        this.setState({
          forceStyle: { width: '48%' },
        }),
      );
    }
  }

  componentWillReceiveProps(props) {
    this.setState(oldState => ({
      min: parseInt(props.selectedMin, 10) || oldState.min,
      max: parseInt(props.selectedMax, 10) || oldState.max,
    }));
  }

  static getItems(data) {
    return (
      <Picker.Item
        key={`${data.label}-${data.value}`}
        label={data.label}
        value={data.value}
      />
    );
  }

  handleChange = key => value => {
    const { allowEmpty, onUpdateRange } = this.props;
    let state = {};

    if (value === null) {
      if (!allowEmpty) {
        return;
      }

      state[key] = value;
    } else {
      const { min, max } = this.state;

      state[key] = value;

      if (key === 'min' && parseInt(value, 10) > parseInt(max, 10)) {
        state = { ...state, ...{ max: value } };
      } else if (key === 'max' && parseInt(value, 10) < parseInt(min, 10)) {
        state = { ...state, ...{ min: value } };
      }
    }

    this.setState(state, () => {
      onUpdateRange(this.state);
    });
  };

  generateAgeRange(min, max) {
    const { measure } = this.props;

    // eslint-disable-next-line prefer-spread
    return Array.apply(null, { length: max + 1 })
      .map(Number.call, Number)
      .splice(min, max)
      .map(value => ({
        label: `${value} ${measure || ''}`,
        value,
      }));
  }

  render() {
    const {
      onUpdateRange,
      selectedMin,
      selectedMax,
      itemStyle,
      placeholder,
      range,
      separator,
      ...props
    } = this.props;
    const { forceStyle, min, max } = this.state;
    const items = this.generateAgeRange(range.min, range.max).map(
      RangePicker.getItems,
    );

    return (
      <View style={styles.container}>
        <Picker
          {...props}
          style={[styles.picker, forceStyle]}
          itemStyle={[styles.item, itemStyle || {}]}
          selectedValue={min}
          onValueChange={this.handlers.min}
        >
          <Picker.Item label={placeholder.from} value={null} />
          {items}
        </Picker>
        {separator}
        <Picker
          {...props}
          style={[styles.picker, forceStyle]}
          itemStyle={[styles.item, itemStyle || {}]}
          selectedValue={max}
          onValueChange={this.handlers.max}
        >
          {!isAndroid ? items : []}
          <Picker.Item label={placeholder.to} value={null} />
          {isAndroid ? items : []}
        </Picker>
      </View>
    );
  }
}

export default RangePicker;
