import React, { ComponentType } from 'react';
import { render } from 'dating-mobile/src/components/rx-render';
// @ts-ignore
import { UserBlocking } from '@sdv/domain/user/user-blocking';
import { Chat, ViewProps, ControllerProps } from './types';

export default function bindController(Component: ComponentType<ViewProps>) {
  const Controller = render<ControllerProps>(
    (props, { useObservable, useDistinct, useObservableAction }) => {
      if (!props.userId) {
        return null;
      }

      const userBlocking = UserBlocking.shared(props.userId);

      const blockedUsers = useObservable<{ events: Chat[] }, any[]>(
        it => it.blockedUsersAdapted,
        userBlocking,
      );

      const onChatSelect = useDistinct(
        startChat => (chat: Chat) => {
          startChat({ id: chat['user-id'] });
        },
        props.startChat,
      );

      const onUnblockPress = useObservableAction(
        (chat: Chat) => userBlocking.unblockUser(chat['user-id']),
        userBlocking,
      );

      if (!blockedUsers) {
        return null;
      }

      return (
        <Component
          chats={blockedUsers.events}
          onChatSelect={onChatSelect}
          onUnblockPress={onUnblockPress}
        />
      );
    },
  );

  Controller.displayName = 'screens.block-list.controller';

  return Controller;
}
