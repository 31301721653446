import React from 'react';
import { StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import CurrentlyBroadcastingComponent from './view';
import styles from './styles';
import ImageButton from '../../../components/navigation/views/image-button';
import Resources from '../../../resources';
import Controller from './controller';

const CurrentlyBroadcastingView = Controller(CurrentlyBroadcastingComponent);

class CurrentlyBroadcastingScreen extends React.PureComponent {
  static displayName = 'dialogs.streams.currently-broadcasting.screen';

  static propTypes = {
    navigation: PropTypes.object.isRequired,
  };

  static navigationOptions = ({ navigation }) => ({
    title: Resources.strings['currently-broadcasting-screen-title'],
    headerStyle: styles.header,
    headerTitleStyle: styles.headerTitle,
    headerLeft: () => (
      <ImageButton
        image={Resources.images.backIcon()}
        onPress={() => navigation.dismiss()}
        tint={StyleSheet.flatten(styles.headerTitle).color}
      />
    ),
  });

  back = () => {
    const { navigation } = this.props;

    navigation.dismiss();
  };

  onAttach = id => {
    const { navigation } = this.props;
    const func = navigation.getParam('onAttach');

    if (func) {
      func(id);
    }
  };

  render() {
    return (
      <CurrentlyBroadcastingView
        goBack={this.back}
        attachCoStreamer={this.onAttach}
      />
    );
  }
}

export default CurrentlyBroadcastingScreen;
