import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, Image } from 'react-native';
import styles from './styles';
import Resources from '../../../resources';
import withAspectRatio from '../../../components/aspect-ratio';
import createControlledComponent from '../../../models/credits.accounts.balance/controllers/controller';
import testId from '../../../utils/test-id';

const AspectRatioImage = withAspectRatio(Image);

class UserBalance extends React.PureComponent {
  static displayName = 'payment.user-balance';

  static propTypes = {
    balance: PropTypes.shape({
      amount: PropTypes.number,
    }),
  };

  render() {
    const { balance } = this.props;

    const amount = typeof balance?.amount === 'number' ? balance.amount : '';

    return (
      <View style={styles.container}>
        <Text style={styles.amount} {...testId('Amount label')}>
          {amount}
        </Text>
        <AspectRatioImage
          style={styles.coin}
          source={Resources.images.coinIcon()}
          {...testId('Coin icon')}
        />
      </View>
    );
  }
}

export default createControlledComponent(UserBalance);
