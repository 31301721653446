import React, { PureComponent } from 'react';
import { View, Text, ImageBackground, ActivityIndicator, ScrollView, Platform, StyleSheet } from 'react-native';
import ImageButton from 'dating-mobile/src/components/navigation/views/image-button';
import PropTypes from 'prop-types';
import Resources from 'dating-mobile/src/resources';
import { SafeAreaView } from 'react-navigation';
import { GradientButton } from 'dating-mobile/src/components/buttons/gradient';
import {
    displayTextPerPeriod,
    getLengthOfTrial,
} from 'dating-mobile/src/payment/utils/trial-pirce-formatting';
import { SubscriptionAgreement } from 'dating-mobile/src/payment/views/subscription-agreement';
import { FeaturesList } from './features-list';
import styles from './styles';

export default class SkipSubscribeViewView extends PureComponent {
    static propTypes = {
        onSubscribePress: PropTypes.func,
        onClosePress: PropTypes.func,
        isLoading: PropTypes.bool,
        price: PropTypes.object,
    };

    getTrialsPeriodDisplayText = trialPeriod => {
        if (trialPeriod.days) {
            return Resources.strings.formatString(
                Resources.strings['skip-subscribe-days-trial-text'],
                trialPeriod.days,
            );
        }
        if (trialPeriod.months) {
            return Resources.strings.formatString(
                Resources.strings['skip-subscribe-month-trial-text'],
                trialPeriod.months,
            );
        }
        if (trialPeriod.years) {
            return Resources.strings.formatString(
                Resources.strings['skip-subscribe-year-trial-text'],
                trialPeriod.years,
            );
        }

        return Resources.strings['skip-subscribe-trial-text'];
    };

    getTrialPriceText = (price, number, unit) => {
        const format = Resources.strings['skip-subscription-price-per-time-format'];

        return displayTextPerPeriod(price, number, unit, format);
    };

    render() {
        const { onSubscribePress, onClosePress, price, isLoading } = this.props;

        const buttonTitle = price ? this.getTrialsPeriodDisplayText(price.trialPeriod) : null;
        const buttonSubtitle = price
            ? this.getTrialPriceText(
                  price.localizedIncludingTax,
                  price.subscriptionPeriodNumber,
                  price.subscriptionPeriodUnit,
              )
            : null;

        return (
            <View style={styles.container}>
                <SafeAreaView
                    forceInset={{ top: 'always', bottom: 'always' }}
                    style={styles.safeArea}
                >
                    <ScrollView
                        contentContainerStyle={styles.scrollContainer}
                        showsVerticalScrollIndicator={false}
                        alwaysBounceVertical={false}
                    >
                        <View style={styles.activityIndicatorContainer} pointerEvents="box-none">
                            <ActivityIndicator animating={isLoading} />
                        </View>
                        <View style={styles.topContainer}>
                            <View />
                            <View />
                            <ImageButton
                                image={Resources.images.closeIcon()}
                                onPress={onClosePress}
                                style={styles.closeIcon}
                                tint={StyleSheet.flatten(styles.closeIcon).tintColor}
                            />
                        </View>

                        <View style={styles.contentContainer}>
                            <ImageBackground
                                resizeMode="stretch"
                                style={styles.titleBackground}
                                source={Resources.images.skipSubscribeTitleBg()}
                            >
                                <View style={styles.titleContainer}>
                                    <Text style={styles.title}>
                                        {Resources.strings['skip-subscribe-title']}
                                    </Text>
                                    <Text style={styles.subTitle}>
                                        {Resources.strings['skip-subscribe-subtitle']}
                                    </Text>
                                </View>
                            </ImageBackground>

                            <FeaturesList />

                            <GradientButton
                                onPress={onSubscribePress}
                                containerStyles={styles.buttonContainer}
                            >
                                <View style={styles.buttonContentContainer}>
                                    <Text style={styles.buttonTitle}>{buttonTitle}</Text>
                                    <Text style={styles.buttonDescription}>{buttonSubtitle}</Text>
                                    <View style={styles.arrow} />
                                </View>
                            </GradientButton>
                            <View style={styles.termsDescriptionContainer}>
                                <ScrollView
                                    showsVerticalScrollIndicator={false}
                                    alwaysBounceVertical={false}
                                >
                                    <SubscriptionAgreement>
                                        {Platform.OS === 'ios' && !!price && !!price.trialPeriod && (
                                            <Text>
                                                <Text>
                                                    {
                                                        Resources.strings[
                                                            'subscription-free-trial-title'
                                                        ]
                                                    }
                                                </Text>
                                                <Text>{getLengthOfTrial(price.trialPeriod)}</Text>
                                                <Text>
                                                    {Resources.strings.formatString(
                                                        Resources.strings[
                                                            'subscription-free-trial-price'
                                                        ],
                                                        price.localizedIncludingTax,
                                                    )}
                                                </Text>
                                            </Text>
                                        )}
                                    </SubscriptionAgreement>
                                </ScrollView>
                            </View>
                        </View>
                    </ScrollView>
                </SafeAreaView>
            </View>
        );
    }
}
