import React from 'react';
import { render } from 'dating-mobile/src/components/rx-render';

import { StreamsDiscoveryAdsViewModel } from './view-model';

export default function createController(Component) {
    const Controller = render((props, { useDistinct, useObservable }) => {
        const streamsDiscoveryAdsViewModel = useDistinct(() => new StreamsDiscoveryAdsViewModel());
        const currentAd = useObservable(it => it.currentAd, streamsDiscoveryAdsViewModel);

        if (!currentAd) {
            return null;
        }

        return <Component source={currentAd} onPress={streamsDiscoveryAdsViewModel.openAd} />;
    });

    Controller.displayName = 'broadcasts.discovery.views.ads.controller';

    return Controller;
}
