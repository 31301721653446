import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet } from 'react-native';
import testId from 'dating-mobile/src/utils/test-id';
import { EditTextForm } from 'dating-mobile/src/components/forms/edit-text';
import ImageButton from 'dating-mobile/src/components/navigation/views/image-button';
import Resources from 'dating-mobile/src/resources';

import styles from './styles';

class TextValueEditing extends React.PureComponent {
  static displayName = 'user.edit.text-value-editing';

  static propTypes = {
    navigation: PropTypes.object.isRequired,
    onComplete: PropTypes.func,
    title: PropTypes.string,
    value: PropTypes.string,
    maxLength: PropTypes.number,
    placeholder: PropTypes.string,
  };

  static navigationOptions = ({ navigation, navigationOptions }) => ({
    title: navigation.getParam('title'),
    headerStyle: [navigationOptions.headerStyle, styles.header],
    headerTitleStyle: [navigationOptions.headerTitleStyle, styles.headerTitle],
    headerLeft: () => (
      <ImageButton
        image={Resources.images.backIcon()}
        tint={
          StyleSheet.flatten([
            navigationOptions.headerTitleStyle,
            styles.headerTitle,
          ]).color
        }
        onPress={() => {
          const goBack = navigation.getParam('goBack');

          if (goBack) {
            goBack();
          } else {
            navigation.pop();
          }
        }}
        {...testId('Back button')}
      />
    ),
  });

  state = {};

  componentDidMount() {
    const { navigation } = this.props;

    this.setState({ text: this.getDefaultValue() });
    navigation.setParams({ goBack: this.goBack });
  }

  goBack = () => {
    const { navigation, onComplete } = this.props;
    const { text } = this.state;
    const completion = onComplete || navigation.getParam('onComplete');

    if (text !== this.getDefaultValue()) {
      completion(text);
    }

    navigation.pop();
  };

  getDefaultValue = () => {
    const { navigation, value } = this.props;

    return value || navigation.getParam('value');
  };

  getMaxLength = () => {
    const { navigation, maxLength } = this.props;

    return maxLength || navigation.getParam('maxLength');
  };

  getPlaceholderTitle = () => {
    const { navigation, title } = this.props;
    const placeholderTitle = title || navigation.getParam('title') || '';
    const placeholder = this.getPlaceholder();

    return (
      placeholder ||
      Resources.strings.formatString(
        Resources.strings['text-value-editing-placeholder-format'],
        placeholderTitle.toLowerCase(),
      ) ||
      ''
    );
  };

  getPlaceholder = () => {
    const { navigation, placeholder } = this.props;

    return placeholder || navigation.getParam('placeholder');
  };

  textChanged = text => {
    this.setState({ text });
  };

  render() {
    const { text } = this.state;

    return (
      <EditTextForm
        multiline
        placeholder={this.getPlaceholderTitle()}
        onValueChange={this.textChanged}
        value={text}
        maxLength={this.getMaxLength()}
      />
    );
  }
}

export default TextValueEditing;
