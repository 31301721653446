import { mapJsonToLetter } from './letter-repo';

const EVENT_LETTER_ADDED = 'event.dialogs.letters.added';
const EVENT_LETTER_READ = 'event.dialogs.letters.read';
const EVENT_LETTER_REJECTED = 'letter:rejected';
const EVENT_LETTER_CHAIN_ADDED = 'event.users.events.added';
const EVENT_LETTER_CHAIN_REMOVED = 'event.users.events.removed';

export default class LetterEventInbox {
    constructor(flux) {
        this.api = flux.api;
        this.events = flux.events;
        this.onLetterAddedListeners = [];
        this.onLetterFromNonContactAddedListeners = [];
        this.onLetterRejectedListeners = [];
        this.onLetterReadListeners = [];
        this.onLetterChainAddedListeners = [];
        this.onLetterChainRemovedListeners = [];
        this.events.addListener(EVENT_LETTER_ADDED, this.onLetterAdded);
        this.events.addListener(EVENT_LETTER_READ, this.onLetterRead);
        this.events.addListener(EVENT_LETTER_REJECTED, this.onLetterRejected);
        this.events.addListener(EVENT_LETTER_CHAIN_ADDED, this.onUserEventsAdded);
        this.events.addListener(EVENT_LETTER_CHAIN_REMOVED, this.onUserEventsRemoved);
    }

    addOnLetterFromNonContactAddedListener(listener) {
        LetterEventInbox.addListenerTo(listener, this.onLetterFromNonContactAddedListeners);
    }

    addOnLetterChainAddedListener(listener) {
        LetterEventInbox.addListenerTo(listener, this.onLetterChainAddedListeners);
    }

    addOnLetterChainRemovedListener(listener) {
        LetterEventInbox.addListenerTo(listener, this.onLetterChainRemovedListeners);
    }

    addOnLetterAddedListener(listener) {
        LetterEventInbox.addListenerTo(listener, this.onLetterAddedListeners);
    }

    addOnLetterRejectedListener(listener) {
        LetterEventInbox.addListenerTo(listener, this.onLetterRejectedListeners);
    }

    addOnLetterReadListener(listener) {
        LetterEventInbox.addListenerTo(listener, this.onLetterReadListeners);
    }

    removeOnLetterFromNonContactAddedListener(listener) {
        this.onLetterFromNonContactAddedListeners = this.onLetterFromNonContactAddedListeners.filter(
            item => item !== listener,
        );
    }

    removeOnLetterAddedListener(listener) {
        this.onLetterAddedListeners = this.onLetterAddedListeners.filter(item => item !== listener);
    }

    removeOnLetterRejectedListener(listener) {
        this.onLetterRejectedListeners = this.onLetterRejectedListeners.filter(
            item => item !== listener,
        );
    }

    removeOnLetterReadListener(listener) {
        this.onLetterReadListeners = this.onLetterReadListeners.filter(item => item !== listener);
    }

    /**
     * @private
     */
    static addListenerTo(listener, dest) {
        if (dest.indexOf(listener) < 0) {
            dest.push(listener);
        }
    }

    /**
     * @private
     */
    onLetterAdded = event => {
        const letter = mapAddedEvent(event);
        this.onLetterAddedListeners.forEach(listener => listener(letter));
    };

    /**
     * @private
     */
    onLetterRead = event => {
        const letterIdentifier = mapReadEvent(event);
        const resource = letterIdentifier.introductory
            ? this.api.dialogs.letters.introductory.get
            : this.api.dialogs.letters.get;
        resource(
            letterIdentifier.recipient,
            letterIdentifier.sender,
            letterIdentifier.id,
            (error, letter) => {
                if (letter) {
                    this.onLetterReadListeners.forEach(listener => listener(letter));
                }
            },
        );
    };

    /**
     * @private
     */
    onLetterRejected = event => {
        this.onLetterRejectedListeners.forEach(listener => listener(event));
    };

    /**
     * @private
     */
    onUserEventsAdded = event => {
        if (event.type === 'letter') {
            this.onLetterChainAddedListeners.forEach(listener => listener(event));
        }
        if (event.type === 'introductory') {
            const letter = mapJsonToLetter(event.payload);
            this.onLetterFromNonContactAddedListeners.forEach(listener => listener(letter));
        }
    };

    /**
     * @private
     */
    onUserEventsRemoved = event => {
        if (event.type === 'letter') {
            this.onLetterChainRemovedListeners.forEach(listener => listener(event));
        }
    };

    markLetterAsRead = letter => {
        this.onLetterReadListeners.forEach(listener => listener(letter));
    };
}

const mapAddedEvent = event => {
    return mapJsonToLetter(event.letter || event);
};

const mapReadEvent = event => {
    return {
        id: event['letter-id'],
        sender: event['sender-id'],
        recipient: event['recipient-id'],
        introductory: !!event['introductory'],
    };
};
