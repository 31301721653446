import Resources from 'dating-mobile/src/resources';

export const INBOX_MODES = Object.freeze({
    ALL: 'all',
    READ_AND_UNANSWERED: 'read-and-unanswered',
    UNREAD: 'unread'
});

export const INBOX_MODES_TITLES = Object.freeze({
    [INBOX_MODES.ALL]: Resources.strings['inbox-all-emails-mode-title'],
    [INBOX_MODES.READ_AND_UNANSWERED]: Resources.strings['inbox-read-and-unanswered-mode-title'],
    [INBOX_MODES.UNREAD]: Resources.strings['inbox-unread-mode-title']
});
