import { Linking } from 'react-native';

export default async function openSupport(userId, userName, whatsAppNumber) {
    const url = compose(userId, userName, whatsAppNumber);
    await openUrl(url);
}

function compose(userId, userName, whatsAppNumber) {
    const text = `Hi! This is ${userName} (ID:${userId}).\u0020`;
    return `https://api.whatsapp.com/send?phone=${whatsAppNumber}&text=${text}`;
}


async function openUrl(url) {
    try {
        await Linking.openURL(url);
    } catch (error) {
        console.log(error);
    }
}
