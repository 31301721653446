import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $statusBarStyle: '$brandStatusBarStyle',
    $statusBarBackgroundColor: '$brandColor',
    $safeAreaInset: {
        top: 'never',
        bottom: 'always'
    },

    header: {
        backgroundColor: '$brandColor',
        borderBottomColor: '$brandColor',
        shadowColor: '$brandColor',
        shadowRadius: 0,
        shadowOpacity: 0,
        elevation: 0,
        shadowOffset: {
            height: 0,
        }
    },

    headerTitle: {
        color: '$brandTextColor',
        fontFamily: '$brandFontFamily'
    },

    container: {
        backgroundColor: '$brandColor',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'stretch'
    },

    scroll: {
        flexGrow: 1
    },

    scrollContent: {
        flexGrow: 1,
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'space-between'
    },

    informationContainer: {
        marginTop: 40,
        flexDirection: 'column',
        alignItems: 'stretch',
        paddingHorizontal: 40
    },

    informationHeader: {
        fontSize: 24,
        fontWeight: 'bold',
        color: '$brandTextColor',
        fontFamily: '$brandFontFamily',
        textAlign: 'center'
    },

    informationDescription: {
        marginTop: 5,
        fontSize: 16,
        color: '$brandTextColor',
        fontFamily: '$brandFontFamily',
        textAlign: 'center'
    },

    photosContainer: {
        marginTop: 24,
    },

    grabCoinsButtonContainer: {
        marginTop: 24,
        marginBottom: 64,
        paddingHorizontal: 40
    }

});

export default styles;
