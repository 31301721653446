import { createOptimisticActions } from '@sdv/model';
import { PreviewTags } from '@sdv/domain/user/tags/user-tags';

function createUserActions(userId) {
  class Actions extends createOptimisticActions(['patch']) {
    get = () => async (dispatch, flux) => {
      const resource =
        flux.api.variable('user-id') === userId
          ? flux.api.users.private
          : flux.api.users;

      resource.get(userId, (error, user) => {
        if (error) {
          dispatch(null, error);

          return;
        }

        dispatch(userWithVip(userWithUpdatedBirthday(user)));
      });
    };

    patch(user) {
      return super.patch(user, function(newUser) {
        const { ...updatedUser } = newUser;

        if (updatedUser.name) {
          updatedUser.name = updatedUser.name.trim();
        }
        this.dispatch(userWithVip(userWithUpdatedBirthday(updatedUser)));

        this.flux.api.users.patch(userId, updatedUser, error => {
          if (error) {
            return this.revert(error);
          }

          return this.commit();
        });
      });
    }

    actualize = user => dispatch => {
      dispatch(user);
    };
  }

  Actions.displayName = createUserActions.getDisplayName(userId);

  return Actions;
}

createUserActions.getDisplayName = function(id) {
  return `user.${id}`;
};

export default createUserActions;

const userWithUpdatedBirthday = user => {
  const ageDiffInMs = Date.now() - new Date(user.birthday).getTime();
  const ageDate = new Date(ageDiffInMs);
  const age = Math.abs(ageDate.getUTCFullYear() - 1970);

  return {
    ...user,
    ...(user.birthday
      ? {
          birthday: {
            age,
            'birth-date': user.birthday,
          },
        }
      : {}),
  };
};

const userWithVip = user => {
  return {
    ...user,
    ...(user.tags
      ? {
          vip:
            user.tags.includes(PreviewTags.VipAccount) ||
            user.tags.includes(PreviewTags.VipBasic),
          premiumVip: user.tags.includes(PreviewTags.VipPremium),
        }
      : {}),
  };
};
