/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, Image, TouchableOpacity, Platform } from 'react-native';
import { connectActionSheet } from '@expo/react-native-action-sheet';

import TextInput from 'dating-mobile/src/components/text-input';
import oppositeGender from 'dating-mobile/src/utils/opposite-gender';
import InputRow from 'dating-mobile/src/components/input-row';
import withMiscData from 'dating-mobile/src/models/misc-data/controller';
import { CRITERIA_OPTIONS } from 'dating-mobile/src/models/misc-data/utils';
import { ProfileVisibilitySwitcher } from 'dating-mobile/src/user/views/user-visibility-in-feed';
import { LOCATION_EDITING } from 'dating-mobile/src/routing/router/constants';

import userController from '../../../../models/user/controllers';
import userMedia from '../../../../models/user.media/controller';
import userPreferences from '../../../../models/user.preference/controllers';

import UserEdit from '../../../common/views/edit/gender-birthday';
import UserPreferenceAdd from '../../../common/views/add-preference';
import MediaAdd from '../add-media';
import MediaView from '../media';

import Resources from '../../../../resources';
import IfConfigValue from '../../../../components/config-value/if';
import configValue from '../../../../components/config-value';
import testId from '../../../../utils/test-id';
import Button from '../../../../components/buttons/base';

import styles from './styles';

class EditUser extends React.Component {
  static displayName = 'user.edit.complex.view';

  static propTypes = {
    id: PropTypes.string.isRequired,
    user: PropTypes.object,
    preference: PropTypes.object,
    navigation: PropTypes.object,
    emptyPreferredGenderEnabled: PropTypes.bool,
    anonymousUsingEnabled: PropTypes.bool,
    choosePreferencesSeparatelyEnabled: PropTypes.bool,
    thumbnailRequired: PropTypes.bool,
    birthdayFirst: PropTypes.bool,
    deletePhoto: PropTypes.func,
    updatePreference: PropTypes.func,
    updateUser: PropTypes.func,
    setSearchParams: PropTypes.func,
    onSubmit: PropTypes.func,
    allowGenderPicking: PropTypes.bool,
    defaultMinAge: PropTypes.number,
    defaultMaxAge: PropTypes.number,
    searchNearbyEnabled: PropTypes.bool,
    countries: PropTypes.object,
    showActionSheetWithOptions: PropTypes.func,
  };

  state = {
    gender: null,
    birthday: null,
    'preferred-gender': null,
    minage: null,
    maxage: null,
    error: {},
    location: {},
    aboutYourselfText: null,
  };

  componentDidMount() {
    this.componentDidUpdate();
  }

  componentDidUpdate() {
    const state = {};
    const {
      gender,
      aboutYourselfText,
      birthday,
      minage,
      maxage,
      location,
    } = this.state;
    const { user, preference, defaultMinAge, defaultMaxAge } = this.props;

    if (!gender && user && user.gender) {
      state.gender = user.gender;
    }
    if (!aboutYourselfText && user && user.about) {
      state.aboutYourselfText = user.about || '';
    }
    if (
      !this.state['preferred-gender'] &&
      preference &&
      preference['preferred-gender']
    ) {
      state['preferred-gender'] = preference['preferred-gender'];
    }
    if (!birthday && user && user.birthday && user.birthday['birth-date']) {
      state.birthday = user.birthday['birth-date'];
    }
    if (!minage && preference) {
      state.minage = preference.minage || defaultMinAge;
    }
    if (!maxage && preference) {
      state.maxage = preference.maxage || defaultMaxAge;
    }
    if (!location.city && user && user.city && user.city !== location.city) {
      state.location = {
        city: user.city,
        country: user.country,
      };
    }
    if (Object.keys(state).length) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(state);
    }
  }

  getLocation() {
    const newLocation = {};
    const { location } = this.state;

    const { city, country, lat, lon } = location;

    if (city) {
      newLocation.city = city;
    }

    if (country) {
      newLocation.country = country;
    }

    if (lat) {
      newLocation.latitude = lat;
    }

    if (lon) {
      newLocation.longitude = lon;
    }

    return newLocation;
  }

  getLocationText() {
    const {
      location: { city, country },
    } = this.state;
    const { countries } = this.props;

    if (city) {
      const countryParsed = country && countries && countries[country];

      return countryParsed ? `${city}, ${countryParsed}` : city;
    }

    return null;
  }

  update = (label, state) => {
    const { allowGenderPicking, emptyPreferredGenderEnabled } = this.props;

    if (!allowGenderPicking && state.gender && !emptyPreferredGenderEnabled) {
      // eslint-disable-next-line no-param-reassign
      state['preferred-gender'] = oppositeGender(state.gender);
    }
    this.setState(state);
  };

  submit = async () => {
    try {
      const isValid = await this.validate(this.state);
      const {
        updatePreference,
        choosePreferencesSeparatelyEnabled,
        updateUser,
        onSubmit,
      } = this.props;
      const {
        gender,
        aboutYourselfText,
        birthday,
        minage,
        maxage,
      } = this.state;

      if (isValid) {
        updateUser({
          about: aboutYourselfText,
          gender,
          birthday,
          ...this.getLocation(),
        });
        if (!choosePreferencesSeparatelyEnabled) {
          updatePreference({
            minage,
            maxage,
            gender,
            'preferred-gender': this.state['preferred-gender'],
          }).then(() => {
            this.setSearchParams();
          });
        } else {
          updatePreference({
            gender: this.state.gender,
          }).then(() => {
            this.setSearchParams();
          });
        }
        onSubmit();
      }
    } catch (error) {
      console.log(error);
    }
  };

  setSearchParams = () => {
    const params = {};
    const { setSearchParams } = this.props;
    const { gender, minage, maxage } = this.state;

    if (this.state['preferred-gender']) {
      params.gender = this.state['preferred-gender'];
    }
    if (gender) {
      params['preferences.gender'] = gender;
    }
    if (minage) {
      params.minage = minage;
    }
    if (maxage) {
      params.maxage = maxage;
    }
    if (setSearchParams) setSearchParams(params);
  };

  onThumbnailPressed = () => {
    const { showActionSheetWithOptions, deletePhoto } = this.props;
    const thumbnail =
      this.props.user &&
      (this.props.user['thumbnail-pending'] || this.props.user.thumbnail);

    if (!thumbnail) {
      return;
    }

    showActionSheetWithOptions(
      {
        options: [
          Resources.strings['about-yourself-screen-retake-button-title'],
          Resources.strings.cancel,
        ],
        cancelButtonIndex: 1,
        showSeparators: Platform.OS === 'web',
      },
      buttonIndex => {
        if (buttonIndex === 0 && deletePhoto) {
          deletePhoto(thumbnail).then(() => {
            if (this.mediaAddRef) this.mediaAddRef.select();
          });
        }
      },
    );
  };

  disabled = () => {
    const {
      user,
      thumbnailRequired,
      searchNearbyEnabled,
      anonymousUsingEnabled,
      emptyPreferredGenderEnabled,
      choosePreferencesSeparatelyEnabled,
    } = this.props;
    const {
      location,
      birthday,
      gender,
      aboutYourselfText,
      minage,
      maxage,
    } = this.state;
    const thumbnail = user && (user['thumbnail-pending'] || user.thumbnail);

    if (thumbnailRequired && !thumbnail) {
      return true;
    }

    if (searchNearbyEnabled && !location.city) {
      return true;
    }

    if (anonymousUsingEnabled) {
      return !(
        birthday &&
        gender &&
        aboutYourselfText &&
        aboutYourselfText.length > 0
      );
    }

    if (!choosePreferencesSeparatelyEnabled) {
      return !(
        birthday &&
        gender &&
        (this.state['preferred-gender'] || emptyPreferredGenderEnabled) &&
        minage &&
        maxage
      );
    }

    return !(birthday && gender);
  };

  editLocation = () => {
    const { navigation } = this.props;

    navigation.navigate(LOCATION_EDITING, {
      title: Resources.strings['about-yourself-screen-location-item-title'],
      value: this.getLocationText(),
      onlyCities: true,
      onComplete: location => this.setState({ location }),
    });
  };

  editAboutYourself = text => {
    this.setState({ aboutYourselfText: text });
  };

  validate() {
    const {
      anonymousUsingEnabled,
      choosePreferencesSeparatelyEnabled,
      emptyPreferredGenderEnabled,
    } = this.props;
    const { error } = this.state;

    return new Promise(resolve => {
      const keys = ['gender', 'birthday'];

      if (!choosePreferencesSeparatelyEnabled && !anonymousUsingEnabled) {
        keys.push('minage');
        keys.push('maxage');
        keys.push('preferred-gender');
      }
      const errorState = keys.reduce((errors, key) => {
        if (
          !this.state[key] &&
          (key !== 'preferred-gender' || !emptyPreferredGenderEnabled)
        ) {
          // eslint-disable-next-line no-param-reassign
          errors[key] = !this.state[key] ? 'Could not be empty' : false;
        }

        return errors;
      }, {});

      this.setState(
        {
          error: errorState,
        },
        () => {
          const isValid = !Object.keys(error).some(key => error[key]);

          return resolve(isValid);
        },
      );
    });
  }

  render() {
    const {
      id,
      user,
      preference,
      birthdayFirst,
      choosePreferencesSeparatelyEnabled,
    } = this.props;
    const { error, location, aboutYourselfText } = this.state;
    const thumbnail = user && (user['thumbnail-pending'] || user.thumbnail);
    const addThumbnailContainerStyle = thumbnail
      ? styles.addThumbnailContainerHidden
      : undefined;
    const locationPickerStyle = location.city
      ? [styles.locationPicker, styles.locationPickerFilled]
      : styles.locationPicker;
    const locationPickerText =
      this.getLocationText() ||
      Resources.strings['about-yourself-screen-location-item-title'];

    return (
      <View style={{ flex: 1 }}>
        <View style={styles.container}>
          <IfConfigValue
            path="markup.about-yourself-screen.has-title"
            equalsTo={true}
          >
            <Text style={styles.title}>Tell about yourself</Text>
          </IfConfigValue>

          <View style={styles.thumbnailContainer}>
            <View
              style={addThumbnailContainerStyle}
              {...testId('Add thumbnail button')}
            >
              <MediaAdd
                id={id}
                mediaType="photo"
                ref={ref => {
                  this.mediaAddRef = ref;
                }}
              >
                <View style={styles.addThumbnail}>
                  <Image
                    style={styles.thumbnailIcon}
                    source={Resources.images.cameraBigIcon()}
                  />
                  {styles.$showAddThumbnailText && (
                    <Text style={styles.addThumbnailText}>
                      {
                        Resources.strings[
                          'about-yourself-screen-add-thumbnail-text'
                        ]
                      }
                    </Text>
                  )}
                </View>
              </MediaAdd>
            </View>
            {!!thumbnail && (
              <TouchableOpacity onPress={this.onThumbnailPressed}>
                <MediaView
                  basename={thumbnail}
                  id={id}
                  round={false}
                  style={styles.thumbnail}
                />
              </TouchableOpacity>
            )}
          </View>
          <View style={styles.editContainer}>
            <IfConfigValue
              path="features.anonymous-using-enabled"
              equalsTo={true}
            >
              <InputRow separator>
                <TextInput
                  style={styles.aboutYourself}
                  value={aboutYourselfText}
                  placeholder={
                    Resources.strings['about-yourself-screen-text-about-title']
                  }
                  onChangeText={this.editAboutYourself}
                />
              </InputRow>
            </IfConfigValue>
            <UserEdit
              id={id}
              onUpdate={this.update}
              user={user}
              birthdayFirst={birthdayFirst}
              error={{ gender: error.gender, birthday: error.birthday }}
            />
            <IfConfigValue
              path="features.anonymous-using-enabled"
              equalsTo={false}
            >
              {this.props.searchNearbyEnabled && (
                <InputRow
                  label={
                    Resources.strings[
                      'about-yourself-screen-location-item-title'
                    ]
                  }
                  separator
                >
                  <TouchableOpacity
                    onPress={this.editLocation}
                    style={styles.locationPickerContainer}
                  >
                    <Text style={locationPickerStyle} numberOfLines={1}>
                      {locationPickerText}
                    </Text>
                  </TouchableOpacity>
                </InputRow>
              )}
              {!choosePreferencesSeparatelyEnabled && (
                <UserPreferenceAdd
                  id={id}
                  preference={preference}
                  onUpdatePreferences={this.update}
                  error={error['preferred-gender']}
                  allowGenderPicking={this.props.allowGenderPicking}
                  allowAgeRangePicking={styles.$showAgeRangePicking}
                  emptyPreferredGenderEnabled={
                    this.props.emptyPreferredGenderEnabled
                  }
                  defaultMinAge={this.props.defaultMinAge}
                  defaultMaxAge={this.props.defaultMaxAge}
                />
              )}
            </IfConfigValue>

            <IfConfigValue
              path="features.profile-visibility-switcher-enabled"
              equalsTo={true}
            >
              <View style={styles.profileVisibleContainer}>
                <ProfileVisibilitySwitcher userId={this.props.id} />
              </View>
            </IfConfigValue>
          </View>
        </View>

        <Button
          disabled={this.disabled()}
          title={Resources.strings['about-yourself-screen-button-title']}
          onPress={this.submit}
          {...testId('Next step button')}
        />
      </View>
    );
  }
}

export default userPreferences(
  userController(
    userMedia(
      configValue(
        withMiscData(connectActionSheet(EditUser), {
          dataType: CRITERIA_OPTIONS.COUNTRIES,
          valuesPropName: 'countries',
        }),
        {
          birthdayFirst: 'markup.about-yourself-screen.birthday-first',
          choosePreferencesSeparatelyEnabled:
            'features.choose-preferences-separately-during-registration-enabled',
          emptyPreferredGenderEnabled:
            'features.empty-preferred-gender-enabled',
          thumbnailRequired:
            'features.thumbnail-required-during-registration-enabled',
          allowGenderPicking:
            'features.homo-genders-during-registration-enabled',
          defaultMinAge: 'preferences.default-min-age',
          defaultMaxAge: 'preferences.default-max-age',
          searchNearbyEnabled: 'features.search-nearby-enabled',
          anonymousUsingEnabled: 'features.anonymous-using-enabled',
        },
      ),
    ),
  ),
);
