import EStyleSheet from 'react-native-extended-stylesheet';

const buttonContainer = {
    width: 36,
    height: 36,
    borderRadius: 18,
    backgroundColor: '$minorAccentColor',
    justifyContent: 'center',
    alignItems: 'center'
};

const styles = EStyleSheet.create({
    buttonContainer: buttonContainer,

    alreadyWinkedButtonContainer: {
        ...buttonContainer,
        backgroundColor: '$accentColor'
    },

    buttonIcon: {
        width: 20,
        height: 20
    }
});

export default styles;
