import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $categoriesUppercase: false,
    '@flavor tubit': {
        $categoriesUppercase: true
    },

    container: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 36,
        '@flavor tubit': {
            flexDirection: 'column',
            justifyContent: 'flex-end',
            height: 20,
        }
    },

    selectedContainer: {
        backgroundColor: '$accentColor',
        '@flavor tubit': {
            backgroundColor: 'transparent',
        }
    },

    unselectedContainer: {
        backgroundColor: '$unselectedColor',
        '@flavor tubit': {
            backgroundColor: 'transparent',
        }
    },

    title: {
        width: '100%',
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 14,
        fontFamily: '$brandFontFamily',
        '@flavor tubit': {
            marginHorizontal: 12
        }
    },

    unselectedTitle: {
        color: '$primaryTextColor',
        '@flavor tubit': {
            fontSize: 14
        },
    },

    selectedTitle: {
        color: '$accentTextColor',
        '@flavor tubit': {
            color: '$accentColor',
            fontSize: 18,
        }
    }

});

export default styles;

