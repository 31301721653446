import React from 'react';
import PropTypes from 'prop-types';
import EventEmitter from 'eventemitter3';
import { MessagesActionsError, MESSAGE_TYPES, DELIVERY_STATUS } from '../model';

class FormController extends React.Component {
    static displayName = 'messages.form-controller';
    static contextTypes = {};
    static propTypes = {
        bus: PropTypes.object,
        model: PropTypes.object,
        refillBalanceForText: PropTypes.func,
        refillBalanceForCheer: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.bus = this.props.bus || new EventEmitter();
    }

    componentDidMount() {
        this.bus.addListener('command.messages.form.send-text', this.sendText);
        this.bus.addListener('command.messages.form.send-sticker', this.sendSticker);
        this.bus.addListener('command.messages.form.send-cheer', this.sendCheer);
        this.props.model && this.props.model.store.listen(this.modelUpdates);
    }

    componentWillUnmount() {
        this.bus.removeListener('command.messages.form.send-text', this.sendText);
        this.bus.removeListener('command.messages.form.send-sticker', this.sendSticker);
        this.bus.removeListener('command.messages.form.send-cheer', this.sendCheer);
        this.props.model && this.props.model.store.unlisten(this.modelUpdates);
    }

    componentDidUpdate(prevProps) {
        const modelId = prevProps.model && prevProps.model.store.displayName;
        const newModelId = this.props.model && this.props.model.store.displayName;
        if (modelId !== newModelId) {
            prevProps.model && prevProps.model.store.unlisten(this.modelUpdates);
            this.props.model && this.props.model.store.listen(this.modelUpdates);
        }
    }

    modelUpdates = state => {
        const lastMessage = state.messages.length ? state.messages[0] : null;
        if (lastMessage && lastMessage.status === DELIVERY_STATUS.UNPAID) {
            this.bus && this.bus.emit('command.messages.form.dismiss-keyboard');
        }
    };

    sendText = (text, tag) => {
        if (typeof text === 'string' && text.length) {
            this.props.model &&
                this.props.model.actions.send(
                    MESSAGE_TYPES.TEXT,
                    text,
                    tag,
                    this.props.instant,
                    error => {
                        if (
                            error instanceof MessagesActionsError &&
                            error.code === MessagesActionsError.CODES.PAYMENT_REQUIRED
                        ) {
                            this.props.refillBalanceForText &&
                                this.props.refillBalanceForText(() => {
                                    this.sendText(text, error.tag);
                                });
                        }
                    },
                );
        }
    };

    sendSticker = (sticker, tag) => {
        this.props.model &&
            this.props.model.actions.send(
                MESSAGE_TYPES.STICKER,
                sticker,
                tag,
                this.props.instant,
                error => {
                    if (
                        error instanceof MessagesActionsError &&
                        error.code === MessagesActionsError.CODES.PAYMENT_REQUIRED
                    ) {
                        this.props.refillBalanceForText &&
                            this.props.refillBalanceForText(() => {
                                this.sendSticker(sticker, error.tag);
                            });
                    }
                },
            );
    };

    sendCheer = (cheer, tag) => {
        this.props.model &&
            this.props.model.actions.send(
                MESSAGE_TYPES.CHEER,
                cheer,
                tag,
                this.props.instant,
                error => {
                    if (
                        error instanceof MessagesActionsError &&
                        error.code === MessagesActionsError.CODES.PAYMENT_REQUIRED
                    ) {
                        this.props.refillBalanceForCheer &&
                            this.props.refillBalanceForCheer(() => {
                                this.sendCheer(cheer, error.tag);
                            });
                    }
                },
            );
    };

    render() {
        const { children } = this.props;

        return React.Children.map(children, child => React.cloneElement(child, { bus: this.bus }));
    }
}

export default FormController;
