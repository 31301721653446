import React, { Fragment } from 'react';
import { Text } from 'react-native';
import PropTypes from 'prop-types';

import TextInput from '../text-input';
import Resources from '../../resources';

import styles from './styles';

export class MonthYearInput extends React.PureComponent {
  static displayName = 'components.month-year-input';

  static propTypes = {
    onChangeText: PropTypes.func,
    onMonthBlur: PropTypes.func,
    onYearBlur: PropTypes.func,
    errorMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    errorYear: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  };

  constructor(props) {
    super(props);
    this.state = { month: '', year: '' };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      const { onChangeText } = this.props;
      const { month, year } = this.state;

      if (onChangeText) {
        onChangeText({ month, year });
      }
    }
  }

  onMonthChange = text => {
    const month = text.replace(/[^0-9]/g, '');

    this.setState(
      oldState => ({ ...oldState, month }),
      () => {
        if (month.length >= 2) {
          this.yearInput.focus();
        }
      },
    );
  };

  onYearChange = text => {
    const year = text.replace(/[^0-9]/g, '');

    this.setState(oldState => ({ ...oldState, year }));
  };

  render() {
    const {
      onChangeText,
      onMonthBlur,
      onYearBlur,
      errorMonth,
      errorYear,
      ...inputProps
    } = this.props;
    const { month, year } = this.state;

    return (
      <Fragment>
        <TextInput
          {...inputProps}
          onChangeText={this.onMonthChange}
          value={month}
          autoComplete="cc-exp-month"
          placeholder={
            Resources.strings['card-payment-form-exp-month-placeholder']
          }
          type="number"
          onBlur={onMonthBlur}
          error={errorMonth}
        />
        <Text style={styles.label}>/</Text>
        <TextInput
          {...inputProps}
          forwardRef={ref => {
            this.yearInput = ref;
          }}
          type="number"
          onChangeText={this.onYearChange}
          onBlur={onYearBlur}
          value={year}
          autoComplete="cc-exp-year"
          placeholder={
            Resources.strings['card-payment-form-exp-year-placeholder']
          }
          error={errorYear}
        />
      </Fragment>
    );
  }
}
