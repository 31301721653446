import Actions from './actions';

function createDevicePermissionStore(id = 'default') {
    class Store {

        constructor(flux) {
            const actions = flux.getActions(Actions, id);
            this.state = {
                pushNotificationsEnabled: undefined
            };
            this.bindAction(actions.updatePushNotification, this.updatePushNotification);
        }

        updatePushNotification = (isEnabled) => {
            this.setState({ pushNotificationsEnabled: isEnabled });
        };

    }

    Store.displayName = createDevicePermissionStore.getDisplayName(id);

    return Store;
}

createDevicePermissionStore.getDisplayName = function (id) {
    return `device-permissions.${id}`;
};

export default createDevicePermissionStore;
