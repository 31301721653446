import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import InboxTitle from 'dating-mobile/src/inbox/views/header-title';
import { INBOX_MODES } from 'dating-mobile/src/inbox/inbox-modes';
import styles from './styles';

function withNavigation(Component) {
  class Navigation extends React.Component {
    static displayName = 'inbox.screens.navigation';

    static propTypes = {
      mode: PropTypes.string,
      navigation: PropTypes.object.isRequired,
    };

    constructor(props) {
      super(props);
      const mode =
        (props.navigation && props.navigation.getParam('mode')) ||
        INBOX_MODES.ALL;

      this.state = {
        selectedMode: mode,
        isExpanded: false,
      };
      props.navigation.setParams({
        selectedMode: mode,
        isExpanded: false,
      });
    }

    componentDidMount() {
      const { navigation } = this.props;

      navigation.setParams({
        inboxTitleTapped: this.inboxTitleTapped,
      });
    }

    inboxTitleTapped = () => {
      const { navigation } = this.props;
      const { isExpanded } = this.state;

      this.setState({
        isExpanded: !isExpanded,
      });
      navigation.setParams({
        isExpanded: !isExpanded,
      });
    };

    inboxModeChange = mode => {
      const { navigation } = this.props;

      this.setState({
        selectedMode: mode,
        isExpanded: false,
      });
      navigation.setParams({
        selectedMode: mode,
        isExpanded: false,
      });
    };

    dismissSelector = () => {
      const { navigation } = this.props;

      this.setState({
        isExpanded: false,
      });
      navigation.setParams({
        isExpanded: false,
      });
    };

    render() {
      const { isExpanded, selectedMode } = this.state;

      return (
        <Component
          {...this.props}
          selectedMode={selectedMode}
          isSelectorVisible={isExpanded}
          inboxModeChange={this.inboxModeChange}
          dismissSelector={this.dismissSelector}
        />
      );
    }
  }

  Navigation.navigationOptions = ({ navigation }) => ({
    headerStyle: styles.header,
    headerTitleStyle: styles.headerTitle,
    headerTitle: props => (
      <InboxTitle
        {...props}
        selectedMode={navigation.getParam('selectedMode')}
        tap={navigation.getParam('inboxTitleTapped')}
        selected={navigation.getParam('isExpanded')}
      />
    ),
    headerLeft: () => <View />,
    headerRight: () => <View />,
  });

  return Navigation;
}

export default withNavigation;
