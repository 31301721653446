/* eslint-disable no-param-reassign */
/* eslint-disable import/named */
import { Platform } from 'react-native';
import { take } from 'rxjs/operators';
import MessagesModel, { parseId } from '@sdv/domain/dialogs.messages';
import ObjectStorageModel from '@sdv/domain/object.storage/model';
import { ChatAnonymity } from '@sdv/domain/chat/chat-anonymity';
import PaidResourceModel, {
  RESOURCE_ACCESS_STATUS,
  getId as getPaidResourceModelId,
} from '../../../models/paid-resource/model';
import DialogMediaActions from '../../../models/dialogs.usermedia/model/actions';
import VideoThumbnail from '../../../native-modules/video-thumbnail';
import { dialogPhotoPath, dialogVideoPath } from '../../../resources/remote';

export default class DialogsMediaLoader {
  constructor(flux) {
    this.flux = flux;
    this.activeUploads = [];

    DialogMediaActions.addListener('created', this.processModel);
  }

  processModel = id => {
    const model = this.flux.get(MessagesModel, id);

    model.store.listen(state => this.modelStateChanged(id, state));
  };

  modelStateChanged = (id, state) => {
    const { identity, attendee } = parseId(id);
    const chatAnonymity = new ChatAnonymity(identity, attendee);
    const activeUploadTags = this.activeUploads
      .filter(upload => upload.modelId === id)
      .map(upload => upload.message.tag);
    const pendingMessages = state.messages.filter(message => {
      return (
        message.meta &&
        message.meta.localSource &&
        message.meta.localSource.type &&
        (message.meta.localSource.type.startsWith('image') ||
          message.meta.localSource.type.startsWith('video')) &&
        !message.status &&
        activeUploadTags.indexOf(message.tag) < 0
      );
    });

    pendingMessages.forEach(message => {
      const {
        meta: { localSource },
        sender,
        recipient,
        tag,
      } = message;
      const upload = {
        modelId: id,
        message,
        objectStorageId: `dialogs-user-media:${sender}:${recipient}:${tag}`,
      };

      this.activeUploads.push(upload);

      if (localSource.type.startsWith('video')) {
        VideoThumbnail.get(
          Platform.OS === 'web' ? localSource.blob : localSource.uri,
        ).then(preview => {
          localSource.previewUri = preview.path;
          this.flux.get(MessagesModel, id).actions.patch(message);
        });
      }

      let writingAsAnonymous;

      const objectStorageModel = this.flux.get(
        ObjectStorageModel,
        upload.objectStorageId,
      );

      upload.disposeToken = objectStorageModel.store.listen(newState =>
        this.uploadStateChanged(upload, newState, writingAsAnonymous),
      );

      chatAnonymity.writingAsAnonymous.pipe(take(1)).subscribe(anonymous => {
        writingAsAnonymous = anonymous;
        objectStorageModel.actions.post(
          `/dialogs/usermedia/${
            writingAsAnonymous ? 'avatars/' : ''
          }${recipient}/${sender}`,
          upload.message.meta.localSource,
        );
      });
    });
  };

  uploadFailed = upload => {
    delete upload.message.meta.progress;
    upload.message.status = -1;
    upload.disposeToken();
    this.flux.get(MessagesModel, upload.modelId).actions.patch(upload.message);
    this.activeUploads = this.activeUploads.filter(item => item !== upload);
  };

  uploadStateChanged = (upload, state, writingAsAnonymous) => {
    if (state.files && state.files.length) {
      const basename = state.files[0];

      upload.disposeToken();
      delete upload.objectStorageId;
      delete upload.disposeToken;

      let remotePath;
      let reference;

      if (upload.message.meta.localSource.type.startsWith('image')) {
        remotePath = dialogPhotoPath(
          upload.message.sender,
          upload.message.recipient,
          basename,
          false,
          writingAsAnonymous,
        );
        reference = `photo:///${basename}`;
      } else {
        remotePath = dialogVideoPath(
          upload.message.sender,
          upload.message.recipient,
          basename,
          false,
          writingAsAnonymous,
        );
        reference = `video:///${basename}`;
      }

      upload.reference = reference;
      upload.paidResourceModelId = getPaidResourceModelId(
        upload.message.sender,
        remotePath,
      );

      const paidResourceModel = this.flux.get(
        PaidResourceModel,
        upload.paidResourceModelId,
      );

      upload.disposeToken = paidResourceModel.store.listen(newState =>
        this.accessStatusChanged(upload, newState),
      );
      paidResourceModel.actions.access();

      return;
    }

    if (typeof state.progress === 'number') {
      upload.message.meta.progress = ((state.progress || 0) / 10) * 9;
      this.flux
        .get(MessagesModel, upload.modelId)
        .actions.patch(upload.message);

      return;
    }

    this.uploadFailed(upload);
  };

  accessStatusChanged = (upload, state) => {
    switch (state.status) {
      case RESOURCE_ACCESS_STATUS.AVAILABLE:
        this.flux
          .get(MessagesModel, upload.modelId)
          .actions.send(undefined, upload.reference, upload.message.tag);
        upload.disposeToken();
        this.activeUploads = this.activeUploads.filter(item => item !== upload);
        break;
      case RESOURCE_ACCESS_STATUS.PROCESSING:
      case RESOURCE_ACCESS_STATUS.UNKNOWN:
        setTimeout(() => {
          this.flux
            .get(PaidResourceModel, upload.paidResourceModelId)
            .actions.access();
        }, Math.min((state.retryAfter || new Date()).getTime() - new Date().getTime(), 3000));
        break;
      default:
        this.uploadFailed(upload);
        break;
    }
  };
}
