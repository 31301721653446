import { Image, TouchableOpacity, View } from 'react-native';
import styles from './styles';
import React from 'react';
import PropTypes from 'prop-types';
import Resources from 'dating-mobile/src/resources';
import Photo from 'dating-mobile/src/components/photo';
import * as Progress from 'react-native-progress';

export default class MediaAttachment extends React.PureComponent {
    static displayName = 'inbox.views.media-attachment.view';
    static propTypes = {
        resource: PropTypes.object,
        progress: PropTypes.number,
        onRemovePress: PropTypes.func,
        openResource: PropTypes.func
    };

    onRemovePress = () => {
        this.props.onRemovePress && this.props.onRemovePress(this.props.resource);
    };

    onAttachmentTapped = () => {
        this.props.openResource && this.props.openResource();
    };

    render() {
        let resource;
        let path;

        if (this.props.resource.local) {
            resource = this.props.resource;
        } else {
            path = this.props.resource.preview;
        }

        return (
            <View style={styles.container}>
                <TouchableOpacity onPress={this.onAttachmentTapped} style={styles.attachment}>
                    <Photo
                        source={resource}
                        path={path}
                        style={styles.attachmentPreview}
                    />
                    {
                        this.props.resource.mediaType.startsWith('video') && (
                            <View style={styles.overlay}>
                                <Image style={styles.playIcon} source={Resources.images.playVideoIcon()}/>
                            </View>
                        )
                    }
                </TouchableOpacity>
                <TouchableOpacity style={styles.deleteButton} onPress={this.onRemovePress}>
                    <Image source={Resources.images.deleteAttachIcon()}/>
                </TouchableOpacity>
                {
                    !!this.props.progress && (
                        <View style={styles.progress}>
                            <Progress.Pie
                                size={styles._progress.width}
                                progress={this.props.progress / 100}
                                color={styles._progress.color}
                            />
                        </View>
                    )
                }
            </View>
        );
    }
}
