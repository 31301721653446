import flux from '@sdv/domain/app/flux';
import { Observable } from 'rxjs';
import ObjectStorage from '@sdv/domain/object.storage/model';
import { singleton } from '@sdv/commons/utils/singleton';

export const IMAGE_UPLOAD_STATUS = Object.freeze({
    UPLOADING: 'uploading',
    COMPLETED: 'completed',
    FAILED: 'failed',
});

export class ImageUploader {
    static shared = singleton(() => new ImageUploader());

    uploadImage = (source, path) => {
        return new Observable(observer => {
            const storage = flux.get(ObjectStorage, path);

            const update = state => {
                if (state.files && state.files.length) {
                    const basename = state.files[0];
                    storage.store.unlisten(update);
                    observer.next({ status: IMAGE_UPLOAD_STATUS.COMPLETED, basename: basename });
                    observer.complete();
                    return;
                }

                if (typeof state.progress === 'number') {
                    observer.next({
                        status: IMAGE_UPLOAD_STATUS.UPLOADING,
                        progress: state.progress,
                    });
                    return;
                }

                storage.store.unlisten(update);
                observer.error(new Error('Uploading failed'));
            };
            storage.store.listen(update);
            storage.actions.post(path, source, error => {
                if (error) {
                    storage.store.unlisten(update);
                    observer.error(error);
                }
            });
        });
    };
}
