import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Image,
  ScrollView,
  StatusBar,
  TouchableOpacity,
  View,
} from 'react-native';
import { SafeAreaView, withNavigationFocus } from 'react-navigation';
import LinearGradient from 'react-native-linear-gradient';
import { WINK_TYPES } from '@sdv/domain/dialogs.wink';
import { GALLERY } from 'dating-mobile/src/routing/router/constants';
import styles from './styles';

import user from '../../../models/user/controllers';
import userPresence from '../../../models/user.presence/controllers';
import ProfileHeader from '../views/user-profile-header';
import Resources from '../../../resources';
import ProfileDescriptionComponent from '../views/profile-description';
import GalleryWithController from '../../gallery/views/gallery';
import IfConfigValue from '../../../components/config-value/if';
import { GALLERY_ITEMS } from '../../../models/user.media/controller/gallery-controller';
import identityId from '../../../models/identity/controller/id';
import winks from '../../../models/dialogs.wink/controller/wink';
import { contacts as contactsEvents } from '../../../models/user.events/controllers/events';
import withHasSpecialTag from '../../../models/user.tag/controllers/has-tag';
import withConfigValue from '../../../components/config-value';
import ProfileActionButtonsView from '../views/action-buttons';
import withAspectRatio from '../../../components/aspect-ratio';

const ProfileView = user(userPresence(ProfileHeader));
const ProfileDescriptionView = user(userPresence(ProfileDescriptionComponent));
const AspectRatioView = withAspectRatio(View);

class Profile extends Component {
  static displayName = 'profile.screen';

  static contextTypes = {
    flux: PropTypes.object,
  };

  static propTypes = {
    navigation: PropTypes.object,
    contactsLoaded: PropTypes.bool,
    isFocused: PropTypes.bool,
    contacts: PropTypes.array,
    wink: PropTypes.func,
    privatePhotosEnabled: PropTypes.bool,
    userId: PropTypes.string,
    winkDelay: PropTypes.number,
    id: PropTypes.string,
    userLikesWinkEnabled: PropTypes.bool,
    userProfileExamineWinkEnabled: PropTypes.bool,
    isPreview: PropTypes.bool,
  };

  constructor(props, context) {
    super(props);

    this.bus = context.flux.bus;
    this.offset = 0;
    this.userLiked = false;
  }

  componentDidMount() {
    const {
      navigation,
      contactsLoaded,
      contacts,
      userLikesWinkEnabled,
      userProfileExamineWinkEnabled,
      wink,
      winkDelay,
    } = this.props;

    navigation.setParams({ getId: this.getId() });
    if (
      contactsLoaded &&
      !this.userLiked &&
      contacts.findIndex(contact => contact['user-id'] === this.getId()) < 0 &&
      userLikesWinkEnabled
    ) {
      this.likeUser();
    }

    if (userProfileExamineWinkEnabled) {
      this.winkTimeout = setTimeout(() => {
        if (wink) wink(this.getId(), 'users.examined');
      }, winkDelay);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { contactsLoaded, userLikesWinkEnabled } = this.props;

    if (
      nextProps.contactsLoaded &&
      !contactsLoaded &&
      !this.userLiked &&
      nextProps.contacts.findIndex(
        contact => contact['user-id'] === this.getId(),
      ) < 0 &&
      userLikesWinkEnabled
    ) {
      this.likeUser();
    }
  }

  componentDidUpdate() {
    const { navigation, isFocused } = this.props;

    if (navigation.getParam('isFocused') !== isFocused) {
      navigation.setParams({ isFocused });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.winkTimeout);
  }

  likeUser = () => {
    const { wink } = this.props;

    this.userLiked = true;
    if (wink) wink(this.getId(), WINK_TYPES.LIKE_USER);
  };

  onClose = () => {
    const { navigation } = this.props;

    navigation.pop();
  };

  getId = () => {
    const { navigation, id } = this.props;

    return (
      id ||
      navigation.getParam('id') ||
      navigation.dangerouslyGetParent().getParam('id')
    );
  };

  onThumbnailPressed = basename => {
    const { navigation } = this.props;

    navigation.navigate(GALLERY, {
      id: this.getId(),
      basename,
    });
  };

  openMedia = item => {
    const { isPreview, navigation } = this.props;

    navigation.navigate(GALLERY, {
      id: this.getId(),
      basename: item.basename,
      preview: isPreview,
    });
  };

  onScrollBegin = event => {
    this.offset = event.nativeEvent.contentOffset.y;
  };

  onScroll = hideOnScroll => event => {
    if (hideOnScroll) {
      const currentOffset = event.nativeEvent.contentOffset.y;

      this.setNavigationBarHidden(currentOffset > this.offset);
    }
  };

  setNavigationBarHidden = hidden => {
    const { navigation } = this.props;

    if (navigation.getParam('navigationBarHidden') !== hidden) {
      navigation.setParams({ navigationBarHidden: hidden });
    }
  };

  render() {
    const { isPreview, navigation, privatePhotosEnabled, userId } = this.props;
    const id = this.getId();
    const showCustomCloseButton = navigation.getParam('showCustomCloseButton');
    const initialBasename = navigation.getParam('initialBasename');

    const itemsOrder = privatePhotosEnabled
      ? [
          GALLERY_ITEMS.THUMBNAIL,
          GALLERY_ITEMS.PUBLIC_PHOTOS,
          GALLERY_ITEMS.PRIVATE_PHOTOS,
          GALLERY_ITEMS.VIDEOS,
        ]
      : [
          GALLERY_ITEMS.THUMBNAIL,
          GALLERY_ITEMS.PUBLIC_PHOTOS,
          GALLERY_ITEMS.VIDEOS,
        ];

    return [
      <View key="profile-screen-content" style={styles.container}>
        <IfConfigValue
          path="markup.profile-screen.status-bar-hidden"
          equalsTo={true}
        >
          {status => (
            <StatusBar
              barStyle={styles.$statusBarStyle}
              backgroundColor={styles.$statusBarBackgroundColor}
              hidden={status}
            />
          )}
        </IfConfigValue>
        <IfConfigValue
          path="markup.profile-screen.hide-navigation-on-scroll"
          equalsTo={true}
        >
          {status => (
            <ScrollView
              style={styles.scrollView}
              alwaysBounceVertical={false}
              showsVerticalScrollIndicator={false}
              onScroll={this.onScroll(status)}
              onScrollBeginDrag={this.onScrollBegin}
              scrollEventThrottle={16}
              contentContainerStyle={styles.scrollViewContent}
            >
              <IfConfigValue
                path="markup.profile-screen.header"
                equalsTo="profile"
              >
                <ProfileView
                  id={id}
                  onThumbnailPress={this.onThumbnailPressed}
                />
              </IfConfigValue>
              <IfConfigValue
                path="markup.profile-screen.header"
                equalsTo="gallery"
              >
                <AspectRatioView style={styles.galleryContainer}>
                  <GalleryWithController
                    id={id}
                    fullscreen={false}
                    onMediaSelected={this.openMedia}
                    itemsOrder={itemsOrder}
                    showPlaceholder={styles.$showGalleryPlaceholder}
                    initialBasename={initialBasename}
                  />
                </AspectRatioView>
              </IfConfigValue>
              <IfConfigValue
                path="features.housekeeping-enabled"
                equalsTo={true}
              >
                <UserTagBasedHostView id={id} />
              </IfConfigValue>

              <SafeAreaView forceInset={{ top: 'never', bottom: 'always' }}>
                <ProfileDescriptionView
                  isPreview={isPreview}
                  openMedia={this.openMedia}
                  id={id}
                  userId={userId}
                />
              </SafeAreaView>
              <View style={styles.footer} />
            </ScrollView>
          )}
        </IfConfigValue>
        {!isPreview && (
          <LinearGradient
            colors={styles.$bottomContainerGradientColors}
            style={styles.bottomContainer}
            pointerEvents="box-none"
          >
            <SafeAreaView
              forceInset={{ top: 'never', bottom: 'always' }}
              style={styles.actionButtonsContainer}
            >
              <ProfileActionButtonsView userId={userId} attendeeId={id} />
            </SafeAreaView>
          </LinearGradient>
        )}
        {!!showCustomCloseButton && (
          <SafeAreaView
            forceInset={{ top: 'always', bottom: 'never' }}
            style={styles.customCloseButtonContainer}
          >
            <TouchableOpacity style={styles.closeButton} onPress={this.onClose}>
              <Image source={Resources.images.closeIcon()} />
            </TouchableOpacity>
          </SafeAreaView>
        )}
      </View>,
    ];
  }
}

// eslint-disable-next-line react/no-multi-comp
class HostView extends React.Component {
  static propTypes = {
    host: PropTypes.string,
  };

  shouldComponentUpdate(nextProps) {
    const { host } = this.props;

    return host !== nextProps.host;
  }

  render() {
    const { host } = this.props;

    return host ? (
      <View style={styles.housingIconContainer}>
        <Image source={Resources.images.housingIcon()} />
      </View>
    ) : null;
  }
}

const UserTagBasedHostView = withHasSpecialTag(HostView, {
  tag: 'users.housing',
  existTagPropName: 'host',
  userIdPropName: 'id',
});

const ProfileScreen = withNavigationFocus(
  withConfigValue(identityId(contactsEvents(winks(Profile))), {
    winkDelay: 'wink-delay.profile',
    userLikesWinkEnabled: 'features.user-likes-wink-enabled',
    userProfileExamineWinkEnabled: 'features.user-profile-examine-wink-enabled',
    privatePhotosEnabled: 'features.private-photos-enabled',
  }),
);

export default ProfileScreen;
