import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        backgroundColor: '$bannerColor',
        width: '100%',
        padding: 12,
    },

    title: {
        color: '$brandTextColor',
        textAlign: 'center',
        width: '100%',
        fontSize: 14
    },

    buttonsContainer: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        height: 28,
        marginTop: 12,
        width: '100%',
    },

    report: {
        color: '$brandTextColor',
        backgroundColor: 'transparent',
        borderRadius: 16,
        borderColor: '$brandTextColor',
        borderWidth: 1,
        width: 164,
        height: '100%',
    },

    reportTitle: {
        color: '$brandTextColor',
        fontSize: 12
    },

    continue: {
        backgroundColor: '$accentTextColor',
        borderRadius: 16,
        width: 164,
        height: '100%',
    },

    continueTitle: {
        color: '$inputTextColor',
        fontSize: 12
    }
});

export default styles;
