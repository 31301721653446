import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  $itemLevelMargin: 0,
  $listIsHorizontal: false,

  container: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'center',
    overflow: 'visible',
    paddingHorizontal: 20,
  },

  packages: {
    flex: 1,
    overflow: 'visible',
  },

  packagesSeparator: {},

  item: {
    '@media android': {
      paddingHorizontal: 5,
    },
  },

  centerItem: {},
});

export default styles;
