import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import { Image, View } from 'react-native';
import Resources from 'dating-mobile/src/resources';
import NotificationBadge from 'dating-mobile/src/components/notification-badge';

export default class ChatRequestsTabBatItem extends React.Component {
    static displayName = 'routing.views.chat-requests-tab-bar-item.view';
    static propTypes = {
        showIndicator: PropTypes.bool,
        focused: PropTypes.bool
    };

    render() {
        const imageStyle = [styles.icon, this.props.focused ? styles.activeIcon : styles.inactiveIcon];

        const source = this.props.focused
            ? Resources.images.tabBarChatRequests()
            : Resources.images.tabBarChatRequestsDisabled();

        return (
            <View style={styles.container}>
                <Image style={imageStyle} source={source}/>
                {
                    !!this.props.showIndicator && <NotificationBadge style={styles.indicator} />
                }
            </View>
        );
    }
}
