import React from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity, View, Text, Image } from 'react-native';
import styles from './styles';

export default class TravelTip extends React.Component {
    static displayName = 'tips.views.tips.item';
    static propTypes = {
        tip: PropTypes.shape({
            title: PropTypes.title,
            background: PropTypes.oneOfType([
                PropTypes.object,
                PropTypes.number
            ])
        }).isRequired,
        onPress: PropTypes.func
    };

    onPress = () => {
        this.props.onPress && this.props.onPress(this.props.tip);
    };

    shouldComponentUpdate(nextProps) {
        return this.props.tip.title !== nextProps.tip.title && this.props.tip.background !== nextProps.tip.background;
    }

    render() {
        return (
            <View style={styles.container}>
                <TouchableOpacity style={styles.touchableContent} onPress={this.onPress}>
                    <View style={styles.content}>
                        <Image
                            style={styles.background}
                            source={this.props.tip.background}
                            resizeMode={'cover'}
                        />
                        <View style={styles.titleContainer}>
                            <Text style={styles.title}>{this.props.tip.title || ''}</Text>
                        </View>
                    </View>
                </TouchableOpacity>
            </View>
        );
    }

}
