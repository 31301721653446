import React from 'react';
import PropTypes from 'prop-types';
import withConfigValue from 'dating-mobile/src/components/config-value';
import withUser from 'dating-mobile/src/models/user/controllers';
import openWhatsAppSupport from './whats-app-support';
import openEmailSupport from './email-support';
import openFacebookSupport from './facebook-support';

export default function createController(View) {
  class Controller extends React.Component {
    static displayName = 'user.screens.customer-support.controller';

    static propTypes = {
      userId: PropTypes.string.isRequired,
      facebook: PropTypes.string,
      whatsapp: PropTypes.string,
      email: PropTypes.string,
      user: PropTypes.object.isRequired,
    };

    /**
     * @private
     * @return {Promise<void>}
     */
    sendEmail = async () => {
      await openEmailSupport(this.props.userId, this.props.email);
    };

    /**
     * @private
     * @return {Promise<void>}
     */
    sendWhatsApp = async () => {
      await openWhatsAppSupport(
        this.props.userId,
        this.props.user.name,
        this.props.whatsapp,
      );
    };

    /**
     * @private
     * @return {Promise<void>}
     */
    openFacebookChat = async () => {
      await openFacebookSupport(this.props.facebook);
    };

    render() {
      return (
        <View
          hasFacebook={!!this.props.facebook}
          hasEmail={!!this.props.email}
          whatsapp={this.props.whatsapp}
          onEmailClick={this.sendEmail}
          onWhatsAppClick={this.sendWhatsApp}
          onFacebookClick={this.openFacebookChat}
        />
      );
    }
  }

  return withConfigValue(withUser(Controller, { userIdPropName: 'userId' }), {
    facebook: 'customer-support.facebook',
    whatsapp: 'customer-support.whatsApp',
    email: 'customer-support.email',
  });
}
