import React from 'react';
import { AppState } from 'react-native';
import Permissions from 'react-native-permissions';

export default function withPermissions(Component, options = {}) {
  const resultProps = options.resultProps ? options.resultProps : options;
  const returnStatus = options.returnStatus || false;

  class WithPermissions extends React.Component {
    static displayName = 'components.permissions';

    state = {
      permissions: {},
    };

    componentDidMount() {
      this.isComponentMounted = true;
      AppState.addEventListener('change', this.appStateChanged);
      this.appStateChanged(AppState.currentState);
    }

    componentWillUnmount() {
      this.isComponentMounted = false;
      AppState.removeEventListener('change', this.appStateChanged);
    }

    appStateChanged = nextAppState => {
      if (
        (!this.appState || this.appState !== 'active') &&
        nextAppState === 'active'
      ) {
        Permissions.checkMultiple(Object.values(resultProps)).then(response => {
          const permissions = Object.entries(resultProps).reduce(
            (acc, [propName, permission]) => ({
              ...acc,
              [propName]: returnStatus
                ? response[permission]
                : response[permission] === 'authorized',
            }),
            {},
          );

          if (this.isComponentMounted) {
            this.setState({
              permissions,
            });
          }
        });
      }
      this.appState = nextAppState;
    };

    requestPermission = permission => {
      return Permissions.request(permission).then(response => {
        const { permissions } = this.state;
        const [propName] =
          Object.entries(resultProps).find(
            ([, permissionName]) => permission === permissionName,
          ) || [];

        if (propName) {
          this.setState({
            permissions: {
              ...permissions,
              [propName]: returnStatus ? response : response === 'authorized',
            },
          });
        }

        return response;
      });
    };

    render() {
      const { permissions } = this.state;

      return (
        <Component
          {...this.props}
          {...permissions}
          requestPermission={this.requestPermission}
        />
      );
    }
  }

  return WithPermissions;
}
