import React from 'react';
import { View, Image, ActivityIndicator, StatusBar } from 'react-native';
import Resources from 'dating-mobile/src/resources';
import styles from './styles';

export default class AppLoading extends React.Component {
  render() {
    return (
      <View style={styles.container}>
        <StatusBar barStyle="light-content" />
        <Image
          resizeMode="cover"
          source={Resources.images.splash()}
          style={styles.image}
        />
        <View style={styles.activityIndicatorContainer}>
          <ActivityIndicator animating />
        </View>
      </View>
    );
  }
}
