import api, { toObservable } from '@sdv/domain/api';

export class UserLocation {
    constructor(userId) {

        this.currentLocation = () => {
            return toObservable(api.location.get, userId);
        };

    }
}
