import MessagesActions, { parseId } from './actions';

function createMessageStore(id) {
  class MessagesStore {
    constructor(flux) {
      const actions = flux.getActions(MessagesActions, id);

      this.state = {
        messages: [],
        messagesLoaded: false,
      };

      this.bindAction(actions.get, this.update);
      this.bindAction(actions.read, this.read);
      this.bindAction(actions.delete, this.delete);
      this.bindAction(actions.setReadForOutgoing, this.updateReadForOutgoing);
      this.bindAction(actions.patch, message => {
        if (message) {
          this.update([message]);
        }
      });
      this.bindAction(actions.send, message => {
        if (message) {
          this.update([message]);
        }
      });
    }

    update(updatedMessages) {
      if (!updatedMessages) {
        return;
      }

      if (!updatedMessages.length) {
        if (!this.state.messagesLoaded) {
          this.setState({
            messagesLoaded: true,
          });
        }

        return;
      }

      const messages = this.state.messages.slice();

      const messagesIndexesByTag = messages.reduce(
        (map, message, index) => ({
          ...map,
          [message.tag]: index,
        }),
        {},
      );

      updatedMessages.forEach(message => {
        if (!message.tag) {
          return;
        }

        const index = messagesIndexesByTag[message.tag];

        if (index >= 0) {
          messages[index] = message;
        } else {
          messages.push(message);
          messagesIndexesByTag[message.tag] = messages.length - 1;
        }
      });

      messages.sort((left, right) => right.timestamp - left.timestamp);

      let isBeginOfPaidMessaging = false;

      messages
        .slice()
        .reverse()
        .forEach(message => {
          const { identity } = parseId(id);

          if (
            message.sender !== identity &&
            message.meta &&
            message.meta.tariffication &&
            message.meta.tariffication.final
          ) {
            isBeginOfPaidMessaging = true;
          }
          // eslint-disable-next-line no-param-reassign
          message.needPay = isBeginOfPaidMessaging;
        });

      this.setState({
        messages,
        messagesLoaded: true,
      });
    }

    read(messageId) {
      const messages = this.state.messages.slice();

      let index;

      for (index = 0; index < messages.length; index++) {
        if (String(messages[index].id) === String(messageId)) {
          messages[index].read = true;
          break;
        }
      }

      this.setState({
        messages,
      });
    }

    updateReadForOutgoing(messageTag) {
      const messages = this.state.messages.slice();

      let index;

      for (index = 0; index < messages.length; index++) {
        if (String(messages[index].tag) === String(messageTag)) {
          messages[index].read = true;
          break;
        }
      }

      this.setState({
        messages,
      });
    }

    delete(tag) {
      this.setState({
        messages: this.state.messages.filter(message => message.tag !== tag),
      });
    }
  }

  MessagesStore.displayName = createMessageStore.getDisplayName(id);

  return MessagesStore;
}

createMessageStore.getDisplayName = function(id) {
  return `dialogs-messages.${id}`;
};

export default createMessageStore;
