import React from 'react';
import PropTypes from 'prop-types';
import { ActivityIndicator, StatusBar, StyleSheet, View } from 'react-native';

import testId from 'dating-mobile/src/utils/test-id';
import withConfigValue from 'dating-mobile/src/components/config-value';
import Resources from 'dating-mobile/src/resources';
import flux from '@sdv/domain/app/flux';

import UserModel from '../../../models/user/model';
import identityController from '../../../models/identity/controller';
import EndpointModal from '../../../components/endpoint-switcher/modal';
import authController from '../../../models/identity/controller/auth';
import emailValidation from '../../../models/electronicmail.addresses/controller';
import SignUp from '../../views/auth/sing-up';
import ImageButton from '../../../components/navigation/views/image-button';
import createScreen from './hoc';

import styles from './styles';

const SignUpComponent = authController(SignUp);

const UNAUTHORIZED = 401;

class SignUpView extends React.Component {
  static displayName = 'authentication.signup.screen';

  static propTypes = {
    signUp: PropTypes.func.isRequired,
    signIn: PropTypes.func,
    validate: PropTypes.func,
    validatedEmails: PropTypes.object,
    isLoading: PropTypes.bool,
    tryToSignIn: PropTypes.bool,
    userNameMaxLength: PropTypes.number,
  };

  state = {
    error: null,
    isPending: false,
  };

  onSubmitButtonPressed = async (label, payload) => {
    const {
      tryToSignIn,
      signIn,
      validatedEmails,
      signUp,
      validate,
    } = this.props;

    this.setState({ error: null, isPending: true });

    const { email, password, name } = payload;

    if (tryToSignIn) {
      try {
        await signIn(email, password);

        return;
      } catch {
        // TODO:
      }
    }

    const signUpUser = async () => {
      if (validatedEmails && validatedEmails[email] === false) {
        this.setState({
          error: Resources.strings['sign-up-screen-email-validation-error'],
        });
      } else {
        try {
          const { id } = await signUp(email, password);

          flux.get(UserModel, id).actions.patch({ name }); // TODO: Handle errors
        } catch (error) {
          if (error.status === UNAUTHORIZED) {
            await signUpUser();
          } else {
            this.setState({ error: error.message });
          }
        }
      }
    };

    try {
      if (validatedEmails && validatedEmails[email]) {
        await signUpUser();
      } else {
        await new Promise((resolve, reject) =>
          validate(email, error => (error ? reject(error) : resolve())),
        );
        await signUpUser();
      }
    } catch (e) {
      // TODO
    }

    this.setState({ isPending: false });
  };

  render() {
    const { userNameMaxLength, isLoading } = this.props;
    const { error, isPending } = this.state;

    return (
      <View style={styles.content}>
        <StatusBar
          barStyle={styles.$statusBarStyle}
          backgroundColor={styles.$statusBarBackgroundColor}
        />
        <SignUpComponent
          userNameMaxLength={userNameMaxLength}
          isRealEmail={false}
          error={error}
          isPending={isPending}
          onSubmit={this.onSubmitButtonPressed}
          title={Resources.strings['sign-up-screen-title']}
          buttonTitle={Resources.strings['sign-up-screen-sign-up-button-title']}
        />
        {!!isLoading && (
          <View style={styles.activityIndicatorContainer}>
            <ActivityIndicator animating />
          </View>
        )}
        <EndpointModal />
      </View>
    );
  }
}

const SignUpScreen = createScreen(
  emailValidation(
    identityController(
      withConfigValue(SignUpView, {
        userNameMaxLength: 'validation.user-name.max-length',
      }),
    ),
  ),
);

SignUpScreen.navigationOptions = () => ({
  headerStyle: styles.header,
  headerBackImage: () => (
    <ImageButton
      image={Resources.images.backIcon()}
      tint={StyleSheet.flatten(styles.tint).color}
      {...testId(
        Resources.strings['navigation-bar-back-button-accessibility-label'],
      )}
    />
  ),
});

export default SignUpScreen;
