export class Cheer {
    basename: string;

    preview: string;

    price: number;

    diamonds: number;

    pack: string;

    constructor(cheerStr: string) {
        let parts = cheerStr.split('/');

        this.basename = cheerStr;
        this.preview = cheerStr;
        this.pack = parts[1].split(';')[0] || '';
        // @ts-ignore
        parts = parts.pop().split(';');
        this.price = Number(parts[1].split('=').pop());
        this.diamonds = Number(parts[2].split('=').pop());
    }

    isFree(): boolean {
        return this.price == 0 && this.diamonds == 0;
    }
}
