import { Buffer } from 'buffer';
import { staticSmiles } from 'dating-mobile/src/dialogs/common/views/smile/static-smiles';
import { escapeRegExpSpecialCharacters } from '../../../utils/regexp';
import Actions from './actions';

class SmilesStore {
    static displayName = 'dialogs-smiles';

    constructor(flux) {
        const actions = flux.getActions(Actions);

        this.state = {};
        this.bindAction(actions.get, this.get);
    }

    get = payload => {
        if (!payload || typeof payload.length !== 'number' || !payload.length) {
            return;
        }

        const smiles = payload.map(basename => {
            const parts = basename.split('-');
            const pattern = Buffer.from(parts.pop(), 'base64').toString('utf8');
            const isAnimated = parts.indexOf('anim') >= 0;

            return {
                basename,
                pattern,
                isAnimated,
            };
        });

        let pattern =
            Object.keys(staticSmiles)
                .map(escapeRegExpSpecialCharacters)
                .join('|') + '|';

        smiles.forEach((smile, index) => {
            pattern += escapeRegExpSpecialCharacters(smile.pattern);
            if (index !== smiles.length - 1) {
                pattern += '|';
            }
        });

        this.setState({
            smiles,
            pattern,
        });
    };
}

export default SmilesStore;
