import React from 'react';
import PropTypes from 'prop-types';
import Resources from 'dating-mobile/src/resources';
import { UserCredentials, ChangePasswordResult } from '@sdv/domain/user/user-credentials';

export default function withController(Component) {
    class Controller extends React.Component {
        static displayName = 'user.screens.change-password.controller';
        static propTypes = {
            userId: PropTypes.string,
            close: PropTypes.func,
            setHeaderState: PropTypes.func
        };

        state = {
            password: 'password'
        };

        componentDidMount() {
            this.subscribe();

            this.setHeaderState({
                onSaveButtonPress: this.onSaveButtonPressed,
                onCancelButtonPress: this.close,
                saveButtonEnabled: false
            });
        }

        componentDidUpdate(prevProps) {
            if (this.props.userId !== prevProps.userId) {
                this.unsubscribe();
                this.subscribe();
            }
        }

        componentWillUnmount() {
            this.unsubscribe();
        }

        subscribe() {
            if (!this.props.userId) {
                return;
            }

            this.userCredentials = new UserCredentials(this.props.userId);
        }

        unsubscribe() {
            this.userCredentials = null;
        }

        setHeaderState = (state) => {
            this.props.setHeaderState && this.props.setHeaderState(state);
        };

        close = () => {
            this.props.close && this.props.close();
        };

        onSaveButtonPressed = () => {
            if (!this.userCredentials) {
                return;
            }

            this.userCredentials.changePassword(this.state.password)
                .subscribe(result => {
                    switch (result) {
                        case ChangePasswordResult.success:
                            this.close();
                            break;
                        case ChangePasswordResult.invalidFormat:
                            this.setState({
                                validationError: Resources.strings['change-password-invalid-error-message']
                            });
                            this.setHeaderState({
                                saveButtonEnabled: false
                            });
                            break;
                    }
                });
        };

        onValueChanged = (password) => {
            this.setState({
                password: password,
                validationError: null
            });
            this.setHeaderState({
                saveButtonEnabled: true
            });
        };

        render() {
            return (
                <Component
                    {...this.props}
                    value={this.state.password}
                    onValueChange={this.onValueChanged}
                    validationError={this.state.validationError}
                />
            );
        }
    }

    return Controller;
}
