import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Platform, UIManager } from 'react-native';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';

import flux from '@sdv/domain/app/flux';
import { UserNotifying } from '@sdv/domain/notifications';
import { ProfileBooster } from '@sdv/domain/user/profile-booster';
import Session from '@sdv/domain/authorization/session';
import { BoostExtendNotification } from '@sdv/domain/user/profile-booster/boost-extend-notification';
import { PackagesPreloader } from 'dating-mobile/src/payment/utils/packages-preloader';
import { CallKeeper } from 'dating-mobile/src/call/services/call-keeper';
import { MessagesReSender } from 'dating-mobile/src/services/functional/dialogs-messages-re-sender';
import StreamConnection from '../services/stream-connection';
import RoomsProvider from '../services/functional/rooms/rooms-provider';
import MediaSoupClient from '../services/functional/streaming';
import DialogsMediaLoader from '../services/functional/messages/media-loader';
import ConfigModel from '../models/config/model';
import ConfigProviderService from '../services/functional/config-provider';
import ExceptionsService from '../services/tracking/exceptions';
import { AudioCallPrepare } from '../services/functional/audio-call-prepare';
import AppRouter from '../routing/router';

class Context extends Component {
  static childContextTypes = {
    flux: PropTypes.object.isRequired,
  };

  static propTypes = {
    flux: PropTypes.object,
    children: PropTypes.node,
  };

  getChildContext() {
    const { flux: fluxProp } = this.props;

    return {
      flux: fluxProp,
    };
  }

  render() {
    const { children } = this.props;

    return children;
  }
}

// eslint-disable-next-line react/no-multi-comp
export default class DatingApp extends Component {
  state = { config: null };

  services = [];

  constructor(props) {
    super(props);
    this.configModel = flux.get(ConfigModel);
  }

  componentDidMount() {
    if (
      Platform.OS === 'android' &&
      UIManager.setLayoutAnimationEnabledExperimental
    ) {
      UIManager.setLayoutAnimationEnabledExperimental(true);
    }

    if (Object.keys(this.getConfig()).length) {
      this.bootstrap();
    } else {
      this.configModel.store.listen(this.update);
    }
  }

  componentWillUnmount() {
    this.configModel.store.unlisten(this.update);
  }

  getConfig = () => {
    return this.configModel.store.getState();
  };

  update = () => {
    this.configModel.store.unlisten(this.update);
    this.bootstrap();
  };

  bootstrap = async () => {
    const config = this.getConfig();

    if (config.features['streams-enabled']) {
      flux.streamConnection = new StreamConnection(flux);
      flux.mediasoupClient = new MediaSoupClient(flux.streamConnection);
    }

    this.setState({ config }, this.setupServices);
  };

  setupServices = () => {
    this.services.push(CallKeeper.shared());
    this.services.push(new DialogsMediaLoader(flux));
    this.services.push(new RoomsProvider(flux));
    this.services.push(
      Session.shared().userId.subscribe(userId => {
        if (userId) {
          ProfileBooster.shared(userId);
          BoostExtendNotification.shared(userId);
          UserNotifying.shared(userId);
          PackagesPreloader.shared(userId);
          AudioCallPrepare.shared(userId);
          MessagesReSender.shared(userId);
        }
      }),
    );
  };

  render() {
    const { config } = this.state;

    return (
      <ActionSheetProvider>
        <Context flux={flux}>
          <AppRouter config={config} />
          <ConfigProviderService />
          <ExceptionsService />
        </Context>
      </ActionSheetProvider>
    );
  }
}
