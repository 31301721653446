import React from 'react';
import PropTypes from 'prop-types';
import withConfigValue from 'dating-mobile/src/components/config-value';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';
import withLetter from 'dating-mobile/src/models/dialogs.letter/controller';
import withHasTag from 'dating-mobile/src/models/user.tags/controllers/has-tag';

export default function createController(Component) {

    class Controller extends React.Component {
        static displayName = 'index.screens.letter-reading.controller';
        static propTypes = {
            userId: PropTypes.string,
            sender: PropTypes.string,
            isPromoter: PropTypes.bool,
            inboxEnabled: PropTypes.bool,
            dismiss: PropTypes.func,
            replyToLetter: PropTypes.func,
            readLetter: PropTypes.func
        };

        onLetterRead = () => {
            if (!this.props.isPromoter && this.props.sender !== this.props.userId) {
                this.props.readLetter && this.props.readLetter();
            }
        };

        onReplyButtonPressed = () => {
            if (this.props.inboxEnabled) {
                this.props.replyToLetter && this.props.replyToLetter();
            } else {
                this.props.dismiss && this.props.dismiss();
            }
        };

        render() {
            return (
                <Component
                    {...this.props}
                    ownLetter={this.props.sender === this.props.userId}
                    onLetterRead={this.onLetterRead}
                    onReplyButtonPress={this.onReplyButtonPressed}
                />
            );
        }
    }

    return withConfigValue(
        withIdentityId(
            withHasTag(
                withLetter(
                    Controller,
                    {
                        onLetterViewedPropName: 'readLetter'
                    }
                ),
                {
                    tag: 'dialogs.messages.promoter',
                    hasTagPropName: 'isPromoter'
                }
            )
        ),
        {
            inboxEnabled: 'features.inbox-enabled'
        }
    );

}
