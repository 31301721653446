import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        width: '100%',
        height: 72,
        '@flavor dma': {
            height: 92
        }
    },

    list: {
        flex: 1
    },

    itemSeparator: {
        width: 16
    },

    header: {
        width: 40
    },

    footer: {
        width: 40
    }
});

export default styles;
