import React, { PureComponent } from 'react';
import {
  Image,
  Text,
  TouchableNativeFeedback,
  TouchableOpacity,
  View,
} from 'react-native';
import PropTypes from 'prop-types';
import Resources from 'dating-mobile/src/resources';
import {
  getPeriodText,
  formatPeriod,
} from 'dating-mobile/src/payment/utils/trial-pirce-formatting';
import { formatPrice } from '@sdv/commons/price-with-code-localization';
import { SupportedMethods } from '@sdv/domain/app/payment/shop-info';
import withAutoScale from 'dating-mobile/src/components/auto-scale-image';

import checkImage from './assets/check.png';
import discountBadge from './assets/discount_badge.png';

import styles from './styles';

const ScaledImage = withAutoScale(Image);

export class PackageListItem extends PureComponent {
  static displayName = 'payment.views.package-list-horizontal.item';

  static propTypes = {
    selected: PropTypes.bool,
    product: PropTypes.object,
    invoices: PropTypes.array,
    onPress: PropTypes.func,
    premiumMembershipIsActive: PropTypes.bool,
    membershipIsActive: PropTypes.bool,
    callsPackIsActive: PropTypes.bool,
    premiumMembershipDiscountUsed: PropTypes.bool,
  };

  getTitle() {
    const { product } = this.props;

    if (product.containsMembership && product.isPremiumMembership) {
      return Resources.strings[
        'package-list-premium-subscription-package-name'
      ];
    }

    if (product.containsCallsPack) {
      return Resources.strings['package-list-calls-package-name'];
    }

    if (product.containsMembership && !product.isPremiumMembership) {
      return Resources.strings['package-list-basic-subscription-package-name'];
    }

    return '';
  }

  getBadge() {
    const {
      product,
      premiumMembershipIsActive,
      membershipIsActive,
      callsPackIsActive,
    } = this.props;

    const introductoryPrice = this.getIntroductoryPrice();

    if (introductoryPrice) {
      return null;
    }

    if (product.containsMembership && product.isPremiumMembership) {
      if (premiumMembershipIsActive) {
        return (
          <Text style={styles.activatedBadge}>
            {Resources.strings['account-screen-status-activated']}
          </Text>
        );
      }

      return (
        <View style={styles.badgeContainer}>
          <Text style={styles.badge}>
            {Resources.strings['package-list-best-offer-badge-title']}
          </Text>
        </View>
      );
    }

    if (product.containsCallsPack) {
      if (callsPackIsActive) {
        return (
          <Text style={styles.activatedBadge}>
            {Resources.strings['account-screen-status-activated']}
          </Text>
        );
      }

      return (
        <View style={styles.badgeContainer}>
          <Text style={styles.badge}>
            {Resources.strings['package-list-one-feature-badge-title']}
          </Text>
        </View>
      );
    }

    if (product.containsMembership && !product.isPremiumMembership) {
      if (membershipIsActive) {
        return (
          <Text style={styles.activatedBadge}>
            {Resources.strings['account-screen-status-activated']}
          </Text>
        );
      }

      return (
        <View style={styles.badgeContainer}>
          <Text style={styles.badge}>
            {Resources.strings['package-list-popular-badge-title']}
          </Text>
        </View>
      );
    }

    return null;
  }

  getIntroductoryPrice() {
    const { invoices, premiumMembershipDiscountUsed } = this.props;

    return premiumMembershipDiscountUsed
      ? null
      : invoices?.[0]?.price?.introductory;
  }

  render() {
    const { selected, product, invoices, onPress } = this.props;
    const subscriptionUnit = product.price?.subscriptionPeriodUnit;
    const subscriptionPeriod = product.price?.subscriptionPeriodNumber;
    const { numberToDisplay, unitToDisplay } = getPeriodText(
      subscriptionPeriod,
      subscriptionUnit,
    );
    const localizedPrice = formatPrice(invoices?.[0]?.price, false);
    const introductoryPrice = this.getIntroductoryPrice();
    const discount = invoices?.[0]?.discount;
    const shouldDisplayDiscountBadge = Boolean(introductoryPrice && discount);

    const Container = styles.$hasButtonFeedback
      ? TouchableOpacity
      : TouchableNativeFeedback;

    return (
      <Container onPress={onPress}>
        <View
          style={[
            styles.container,
            selected && styles.containerSelected,
            shouldDisplayDiscountBadge && styles.containerDiscounted,
          ]}
        >
          {selected && <Image source={checkImage} style={styles.checkImage} />}
          {shouldDisplayDiscountBadge && (
            <View style={styles.discountBadge}>
              <ScaledImage source={discountBadge} />
              <Text style={styles.discountBadgeText}>
                {Resources.strings.formatString(
                  Resources.strings['credits-screen-buy-product-discount'],
                  discount,
                )}
              </Text>
            </View>
          )}
          <Text style={styles.packageType}>{this.getTitle()}</Text>
          {!!introductoryPrice && (
            <View>
              <Text style={styles.introductoryPrice}>
                {product.containsMembership
                  ? Resources.strings.formatString(
                      Resources.strings[
                        'package-list-membership-introductory-price-format'
                      ],
                      introductoryPrice,
                      formatPeriod(subscriptionPeriod, subscriptionUnit),
                    )
                  : introductoryPrice}
              </Text>
              <Text style={styles.priceSeparator}>
                {
                  Resources.strings[
                    'package-list-membership-introductory-price-separator'
                  ]
                }
              </Text>
            </View>
          )}
          <View style={styles.priceContainer}>
            <Text style={styles.price}>
              {product.containsMembership
                ? Resources.strings.formatString(
                    Resources.strings['package-list-membership-price-format'],
                    localizedPrice,
                    numberToDisplay,
                    unitToDisplay,
                  )
                : localizedPrice}
            </Text>
            {invoices?.[0]?.price?.currency === SupportedMethods.credits && (
              <Image
                style={styles.creditImg}
                source={Resources.images.coinIcon()}
                resizeMode="contain"
              />
            )}
          </View>
          {this.getBadge()}
        </View>
      </Container>
    );
  }
}
