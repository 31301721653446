import React from 'react';
import PropTypes from 'prop-types';
import withUser from 'dating-mobile/src/models/user/controllers';
import withPresence from 'dating-mobile/src/models/user.presence/controllers';

export default function createController(Component) {

    const displayName = 'user.common.views.online-indicator.controller';

    const userProperty = `generated.${displayName}.user`;

    class Controller extends React.Component {
        static displayName = displayName;
        static propTypes = {
            userId: PropTypes.string
        };

        render() {
            const { [userProperty]: user, ...props } = this.props;

            return <Component {...this.props} tags={user && user.tags} />
        }
    }

    return withPresence(
        withUser(
            Controller,
            {
                userIdPropName: 'userId',
                resultPropName: userProperty
            }
        ),
        {
            userIdPropName: 'userId',
            resultPropName: '.'
        }
    );
};
