import React from 'react';
import PropTypes from 'prop-types';
import { UserTags } from '@sdv/domain/user/tags/user-tags';
import withHasTag from '../../../models/user.tags/controllers/has-tag';
import withConfigValue from '../../../components/config-value';
import withIdentityId from '../../../models/identity/controller/id';

function controller(Component) {
  class Controller extends React.Component {
    static displayName = 'payment.initial.screen.controller';

    static propTypes = {
      id: PropTypes.string,
      userIsCustomer: PropTypes.bool,
      membershipEnabled: PropTypes.bool,
      discountWithMembershipEnabled: PropTypes.bool,
      combinedPaymentFormEnabled: PropTypes.bool,
      canSubscribe: PropTypes.bool,
      membershipRequired: PropTypes.bool,
      creditsRequired: PropTypes.bool,
      callsPackRequired: PropTypes.bool,
      purchase: PropTypes.func,
      purchaseMembership: PropTypes.func,
      purchaseCredits: PropTypes.func,
      purchaseCallsPack: PropTypes.func,
      premiumSubscriptionEnabled: PropTypes.bool,
      premiumMembershipIsActive: PropTypes.bool,
    };

    redirected = false;

    componentDidMount() {
      this.tryRedirect();
    }

    componentDidUpdate() {
      this.tryRedirect();
    }

    tryRedirect() {
      const {
        id,
        combinedPaymentFormEnabled,
        membershipEnabled,
        discountWithMembershipEnabled,
        canSubscribe,
        userIsCustomer,
        callsPackRequired,
        purchaseCallsPack,
        creditsRequired,
        purchaseCredits,
        membershipRequired,
        purchaseMembership,
        purchase,
        premiumSubscriptionEnabled,
        premiumMembershipIsActive,
      } = this.props;

      if (this.redirected) {
        return;
      }

      if (
        !id ||
        typeof combinedPaymentFormEnabled === 'undefined' ||
        typeof membershipEnabled === 'undefined' ||
        typeof discountWithMembershipEnabled === 'undefined' ||
        typeof canSubscribe === 'undefined' ||
        typeof userIsCustomer === 'undefined'
      ) {
        return;
      }

      this.redirected = true;

      if (callsPackRequired) {
        if (purchaseCallsPack) {
          purchaseCallsPack({
            ableToNavigateToMembership: false,
          });
        }

        return;
      }

      if (creditsRequired) {
        if (purchaseCredits) {
          purchaseCredits({
            ableToNavigateToMembership: false,
          });
        }

        return;
      }

      if (membershipEnabled && canSubscribe && membershipRequired) {
        if (purchaseMembership) {
          purchaseMembership({
            ableToNavigateToCredits: false,
          });
        }

        return;
      }

      if (
        premiumSubscriptionEnabled &&
        !premiumMembershipIsActive &&
        membershipRequired // TODO: Check premiumSubscriptionRequired instead
      ) {
        if (purchaseMembership) {
          purchaseMembership({
            ableToNavigateToCredits: false,
          });
        }

        return;
      }

      if (combinedPaymentFormEnabled) {
        if (purchase) {
          purchase();
        }

        return;
      }

      if (!membershipEnabled) {
        if (purchaseCredits) {
          purchaseCredits({
            ableToNavigateToMembership: false,
          });
        }

        return;
      }

      if (canSubscribe && !userIsCustomer && discountWithMembershipEnabled) {
        if (purchaseMembership) {
          purchaseMembership({
            ableToNavigateToCredits: true,
          });
        }

        return;
      }

      if (purchaseCredits) {
        purchaseCredits({
          ableToNavigateToMembership: true,
        });
      }
    }

    render() {
      return <Component {...this.props} />;
    }
  }

  return withConfigValue(
    withIdentityId(
      withHasTag(
        withHasTag(
          withHasTag(Controller, {
            tag: UserTags.Customer,
            userIdPropName: 'id',
            hasTagPropName: 'userIsCustomer',
          }),
          {
            tag: UserTags.Membership,
            userIdPropName: 'id',
            hasTagPropName: 'canSubscribe',
          },
        ),
        {
          tag: UserTags.PremiumMembership,
          userIdPropName: 'id',
          hasTagPropName: 'premiumMembershipIsActive',
        },
      ),
      'id',
    ),
    {
      membershipEnabled: 'features.membership-enabled',
      discountWithMembershipEnabled:
        'features.discount-with-membership-enabled',
      combinedPaymentFormEnabled: 'features.combined-payment-form-enabled',
      premiumSubscriptionEnabled: 'features.premium-subscription-enabled',
    },
  );
}

export default controller;
