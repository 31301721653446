import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ActivityIndicator, ScrollView, Text, View, Image } from 'react-native';
import { SafeAreaView } from 'react-navigation';
import Resources from 'dating-mobile/src/resources';
import Button from 'dating-mobile/src/components/buttons/base';
import { PackageList } from 'dating-mobile/src/payment/views/package-list-horizontal';
import { SubscriptionAgreement } from 'dating-mobile/src/payment/views/subscription-agreement';
import { PremiumSubscriptionBenefits } from 'dating-mobile/src/payment/views/membership-benefits/premium-subscription';
import { BasicSubscriptionBenefits } from 'dating-mobile/src/payment/views/membership-benefits/basic-subscription';
import { formatPrice } from '@sdv/commons/price-with-code-localization';
import { SupportedMethods } from '@sdv/domain/app/payment/shop-info';

import callImage from './assets/call.png';

import styles from './styles';

export default class PaymentTypeList extends PureComponent {
  static displayName = 'payment.screens.calls.view';

  static propTypes = {
    packages: PropTypes.array,
    onPress: PropTypes.func,
    isLoading: PropTypes.bool,
    unlimitedMessagesWithMembershipEnabled: PropTypes.bool,
  };

  state = {
    selectedPackageIndex: 0,
  };

  componentWillReceiveProps(nextProps) {
    const { packages } = this.props;

    if (nextProps.packages?.length !== packages?.length) {
      this.setState({
        selectedPackageIndex: 0,
      });
    }
  }

  getSelectedPackage() {
    const { selectedPackageIndex } = this.state;
    const { packages } = this.props;

    return packages?.[selectedPackageIndex]?.[1]?.[0];
  }

  getTitle() {
    const { packages } = this.props;

    if (packages?.length === 1) {
      return (
        <View style={styles.titleContainer}>
          <Text style={styles.title}>
            {Resources.strings['calls-packs-screen-single-package-title']}
          </Text>
          <Text style={styles.subtitle}>
            {Resources.strings['calls-packs-screen-single-package-subtitle']}
          </Text>
        </View>
      );
    }

    return (
      <View style={styles.titleContainer}>
        <Text style={styles.title}>
          {Resources.strings['calls-packs-screen-title']}
        </Text>
        <Text style={styles.subtitle}>
          {Resources.strings['calls-packs-screen-subtitle']}
        </Text>
      </View>
    );
  }

  getBenefits() {
    const { packages, unlimitedMessagesWithMembershipEnabled } = this.props;
    const selectedPackage = this.getSelectedPackage();

    if (!selectedPackage) {
      return null;
    }

    if (selectedPackage?.isPremiumMembership) {
      return <PremiumSubscriptionBenefits />;
    }

    if (selectedPackage?.containsCallsPack) {
      return (
        <View
          style={
            unlimitedMessagesWithMembershipEnabled
              ? styles.benefitsLarge
              : styles.benefitsSmall
          }
        >
          <Image
            source={callImage}
            style={[styles.image, packages?.length === 1 && styles.imageBig]}
          />
          <Text style={styles.description}>
            {Resources.strings['calls-packs-screen-description']}
          </Text>
        </View>
      );
    }

    return <BasicSubscriptionBenefits />;
  }

  selectPackage = selectedPackageIndex => {
    const { isLoading } = this.props;

    if (!isLoading) {
      this.setState({
        selectedPackageIndex,
      });
    }
  };

  purchasePackage = () => {
    const { selectedPackageIndex } = this.state;
    const { packages, onPress } = this.props;
    const pack = packages?.[selectedPackageIndex];

    if (pack && onPress) {
      onPress(pack);
    }
  };

  render() {
    const { packages, isLoading } = this.props;
    const { selectedPackageIndex } = this.state;
    const selectedPackage = this.getSelectedPackage();
    const hasSelectedPackage = !!selectedPackage;
    const localizedPrice = formatPrice(selectedPackage?.price, false);
    const buttonDisabled = isLoading || !hasSelectedPackage;

    return (
      <SafeAreaView
        forceInset={{ top: 'always', bottom: 'always' }}
        style={styles.container}
      >
        <ScrollView
          style={styles.scrollContainer}
          alwaysBounceVertical={false}
          showsVerticalScrollIndicator={false}
        >
          {this.getTitle()}
          <View style={styles.benefitsContainer}>{this.getBenefits()}</View>
          {!!packages?.length && packages?.length !== 1 && (
            <PackageList
              packages={packages}
              selectedPackageIndex={selectedPackageIndex}
              style={styles.packageList}
              onPackagePress={this.selectPackage}
            />
          )}
          {!packages?.length && !isLoading && (
            <View style={styles.packageList}>
              <ActivityIndicator animating />
            </View>
          )}
          <Button
            disabledGradientColor={styles.disabledButton.gradientColor}
            style={styles.button}
            disabled={buttonDisabled}
            onPress={this.purchasePackage}
          >
            {packages?.length === 1 && localizedPrice ? (
              <View style={styles.buttonContent}>
                <Text
                  style={[
                    styles.buttonTitle,
                    buttonDisabled && styles.disabledModifier,
                  ]}
                >
                  {Resources.strings.formatString(
                    Resources.strings['get-for-price'],
                    localizedPrice,
                  )}
                </Text>
                {selectedPackage?.price?.currency ===
                  SupportedMethods.credits && (
                  <Image
                    style={styles.creditImg}
                    source={Resources.images.coinIcon()}
                    resizeMode="contain"
                  />
                )}
              </View>
            ) : (
              <Text
                style={[
                  styles.buttonTitle,
                  buttonDisabled && styles.disabledModifier,
                ]}
              >
                {Resources.strings.get}
              </Text>
            )}
          </Button>
          {selectedPackage?.containsMembership && (
            <View style={styles.agreementContainer}>
              <SubscriptionAgreement />
            </View>
          )}
          {!!isLoading && (
            <View style={styles.activityIndicatorContainer}>
              <ActivityIndicator animating />
            </View>
          )}
        </ScrollView>
      </SafeAreaView>
    );
  }
}
