import React from 'react';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import PropTypes from 'prop-types';

import Resources from '../../resources';
import withAutoScale from '../auto-scale-image';

import styles from './styles';

const AutoScaledImage = withAutoScale(Image);

export default class CheckMarkItem extends React.Component {
  static displayName = 'components.check-mark-item';

  static propTypes = {
    title: PropTypes.string.isRequired,
    selected: PropTypes.bool,
    onSelectionChange: PropTypes.func,
  };

  shouldComponentUpdate(nextProps) {
    const { title, selected } = this.props;

    return title !== nextProps.title || selected !== nextProps.selected;
  }

  onPressed = () => {
    const { onSelectionChange } = this.props;

    if (onSelectionChange) onSelectionChange();
  };

  render() {
    const { title, selected } = this.props;
    const backgroundColor = selected
      ? styles.selectedIcon
      : styles.unselectedIcon;

    return (
      <TouchableOpacity style={styles.container} onPress={this.onPressed}>
        <View style={styles.contentContainer}>
          <View style={[styles.selectionIcon, backgroundColor]}>
            {selected && (
              <AutoScaledImage source={Resources.images.checkMarkIcon()} />
            )}
          </View>
          <View style={styles.titleContainer}>
            <Text numberOfLines={1} style={styles.title}>
              {title}
            </Text>
          </View>
        </View>
      </TouchableOpacity>
    );
  }
}
