import withFavoriteController from 'dating-mobile/src/user/common/views/favorite-button/controller';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';
import View from './view';

export default withIdentityId(
    withFavoriteController(
        View
    ),
    'holderId'
);
