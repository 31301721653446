import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    center: {
        justifyContent: 'center',
        alignItems: 'center',
    },

    text: {
        color: 'white',
        fontFamily: '$brandFontFamily',
        fontSize: 11,
        textAlign: 'center',
    },
});

export default styles;
