import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        backgroundColor: '$brandColor',
        width: '100%',
        height: '100%'
    },
    content: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    topTitle: {
        color: '$brandTextColor',
        fontFamily: '$brandFontFamily',
        fontSize: 24,
        fontWeight: 'bold'
    },
    amountContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 27,
        marginBottom: 90,
    },
    amountText: {
        color: '$brandTextColor',
        fontFamily: '$brandFontFamily',
        fontSize: 40,
        fontWeight: 'bold',
        marginRight: 12,
    },
    coinIcon: {
        width: 36,
        height: 36,
    },
    description: {
        fontFamily: '$brandFontFamily',
        color: '$brandTextColor',
        fontSize: 17,
        marginHorizontal: 62,
        textAlign: 'center'
    },
    buttonContainer: {
        marginBottom: 48,
        paddingHorizontal: 40,
    }
});

export default styles;
