import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet } from 'react-native';
import Resources from 'dating-mobile/src/resources';
import ImageButton from 'dating-mobile/src/components/navigation/views/image-button';

import styles from './styles';

export default function bindNavigation(Component) {
  class Navigation extends PureComponent {
    static displayName = 'faq.screens.faq-details.navigation';

    static propTypes = {
      navigation: PropTypes.object.isRequired,
    };

    static navigationOptions = () => ({
      headerTitle: '',
      headerStyle: styles.header,
      headerTitleStyle: styles.headerTitle,
      headerBackImage: () => (
        <ImageButton
          image={Resources.images.backIcon()}
          tint={StyleSheet.flatten(styles.headerTitle).color}
        />
      ),
    });

    render() {
      const { navigation } = this.props;

      return <Component id={navigation.getParam('id')} />;
    }
  }

  return Navigation;
}
