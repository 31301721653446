import React from 'react';
import { withNavigation } from 'react-navigation';
import letterReader from '@sdv/domain/dialogs.mailbox/letter-reading';
import PropTypes from 'prop-types';

export default function bindNavigation(Component) {

    class Navigation extends React.Component {
        static displayName = 'inbox.views.letters-list.navigation';

        static contextTypes = {
            flux: PropTypes.object
        };

        constructor(props, context) {
            super(props);
            this.readLetter = letterReader(context.flux);
        }

        render() {
            return (
                <Component
                    {...this.props}
                    readLetter={this.readLetter}
                />
            );
        }
    }

    return withNavigation(Navigation);
}
