import Actions from './actions';
import { BaseStore } from '@sdv/model';
import ObjectStorageModel from '../object.storage/model';
import qs from 'qs';
import { ChatAnonymity } from '@sdv/domain/chat/chat-anonymity';
import { take } from 'rxjs/operators';

export default function createStore(id) {
    const { senderId, recipientId } = qs.parse(id);
    const chatAnonymity = new ChatAnonymity(senderId, recipientId);

    class Store extends BaseStore {
        constructor(flux) {
            super();

            this.flux = flux;

            const actions = flux.getActions(Actions, id);
            this.bindAction(actions.upload, this.upload);
            this.bindAction(actions.cancelUploading, this.cancelUploading);

            this.state = {
                data: {
                    uploads: {},
                },
            };
        }

        upload = media => {
            const { uploads } = this.state.data;
            const key = media.uri;

            if (uploads[key] !== undefined || (uploads[key] && uploads[key].status === 'failed')) {
                return;
            }

            const transfer = {
                status: 'uploading',
                progress: 0,
            };

            uploads[key] = transfer;

            this.state.data = { uploads: uploads };
            this.emitChange();

            const objectStorageId = `dialogs-user-media:${senderId}:${recipientId}:${media.uri}`;
            const objectStorageModel = this.flux.get(ObjectStorageModel, objectStorageId);

            let dispose;
            let writingAsAnonymous;
            dispose = objectStorageModel.store.listen(state =>
                this.uploadStateChanged(media, state, dispose, writingAsAnonymous),
            );

            chatAnonymity.writingAsAnonymous.pipe(take(1)).subscribe(anonymous => {
                writingAsAnonymous = anonymous;
                objectStorageModel.actions.post(
                    `/dialogs/usermedia/${
                        writingAsAnonymous ? 'avatars/' : ''
                    }${recipientId}/${senderId}`,
                    media,
                );
            });
        };

        uploadStateChanged = (media, state, dispose, writingAsAnonymous) => {
            const { uploads } = this.state.data;
            const key = media.uri;

            let transfer;

            if (state.files && state.files.length) {
                dispose();

                const basename = state.files[0];

                const remotePath = `/dialogs/usermedia/${
                    writingAsAnonymous ? 'avatars/' : ''
                }${recipientId}/${senderId}/${basename}`;
                let previewPath;
                if (media.type.startsWith('image')) {
                    previewPath = remotePath;
                } else {
                    previewPath = `/dialogs/usermedia/${
                        writingAsAnonymous ? 'avatars/' : ''
                    }${recipientId}/${senderId}/frame/${basename}`;
                }

                transfer = {
                    status: 'complete',
                    localPath: media.uri,
                    result: {
                        uri: remotePath,
                        preview: previewPath,
                        basename: basename,
                        mediaType: media.type,
                    },
                };
            } else if (typeof state.progress === 'number') {
                transfer = {
                    status: 'uploading',
                    progress: ((state.progress || 0) / 10) * 9,
                };
            } else {
                dispose();

                transfer = {
                    status: 'failed',
                };
            }

            uploads[key] = transfer;

            this.state.data = { uploads: uploads };
            this.emitChange();
        };

        cancelUploading = media => {
            const { uploads } = this.state.data;
            const key = media.uri;
            uploads[key] = undefined;
            this.state.data = { uploads: uploads };
            this.emitChange();
        };

        output(state) {
            return { ...state.data };
        }
    }

    Store.config = {
        getState(state) {
            return { ...state.data };
        },
    };

    Store.displayName = createStore.getDisplayName(id);

    return Store;
}

createStore.getDisplayName = function(id) {
    return `dialogs-media-uploader.${id}`;
};
