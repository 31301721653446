import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'stretch'
    },

    content: {
        flex: 1
    },

    photo: {
        width: '100%',
        height: '100%'
    },

    descriptionContainer: {
        position: 'absolute',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        paddingLeft: 45,
        paddingRight: 45,
        '@flavor lovinga, udates': {
            paddingLeft: 60,
            paddingRight: 60
        }
    },

    descriptionHeader: {
        color: 'white',
        fontSize: 33,
        fontFamily: '$brandFontFamily',
        textAlign: 'center',
        marginBottom: 23,
        '@flavor lovinga, udates': {
            fontSize: 24,
            fontWeight: 'bold',
            marginBottom: 10
        }
    },

    descriptionIcon: {
        marginBottom: 12
    },

    description: {
        color: 'white',
        fontSize: 17,
        fontFamily: '$brandFontFamily',
        textAlign: 'center',
        '@flavor lovinga, udates': {
            fontSize: 16
        }
    },

    subscribeButton: {
        marginTop: 20,
        backgroundColor: '#ffffff40'
    },

    activityIndicator: {
        position: 'absolute'
    }
});

export default styles;
