import { from, combineLatest, BehaviorSubject, Subscription } from 'rxjs';
import {
  filter,
  map,
  distinctUntilChanged,
  switchMap,
  scan,
  mergeMap,
  startWith,
  debounceTime,
} from 'rxjs/operators';
import { singleton } from '@sdv/commons/utils/singleton';
import flux from '@sdv/domain/app/flux';
import { Persistence } from '@sdv/domain/app/persistence';
import UserEventsModel, {
  EVENT_TYPE,
  getId as getUserEventsModelId,
} from 'dating-mobile/src/models/user.events/model';
import MessagesModel, {
  getId as getMessagesModelId,
} from 'dating-mobile/src/models/dialogs.messages/utils/messages-adapter';
import {
  DELIVERY_STATUS,
  MESSAGE_TYPES,
} from 'dating-mobile/src/models/common.messages/model';

const persistenceScope = 'messages-re-sender';
const failedMessagesPersistenceKey = 'failed-messages';

export class MessagesReSender {
  static shared = singleton(userId => new MessagesReSender(userId));

  constructor(userId) {
    const persistence = Persistence.shared(persistenceScope, userId);

    this.failedMessages = new BehaviorSubject({});
    this.deletedMessages = new BehaviorSubject({});
    this.disposeBag = new Subscription();

    const userEventsModel = flux.get(
      UserEventsModel,
      getUserEventsModelId(userId, EVENT_TYPE.CONTACT),
    );

    const contacts = userEventsModel.store.rxState().pipe(
      distinctUntilChanged(),
      map(({ events }) =>
        events?.map(event => event?.['user-id']).filter(Boolean),
      ),
      filter(Boolean),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      scan((prev, curr) => curr.filter(id => !prev.includes(id)), []),
      switchMap(ids => from(ids)),
    );

    const allMessages = contacts.pipe(
      mergeMap(contactId =>
        flux
          .get(MessagesModel, getMessagesModelId(userId, contactId))
          .store.rxState(),
      ),
      map(({ messages }) => messages),
      filter(Boolean),
    );

    // TODO: Do not filter by message type
    const lastMessage = allMessages.pipe(
      map(messages => messages?.[0]),
      filter(Boolean),
      distinctUntilChanged(),
      filter(
        ({ outgoing, type }) =>
          outgoing &&
          (type === MESSAGE_TYPES.TEXT || type === MESSAGE_TYPES.STICKER),
      ),
    );

    const deliveredMessages = lastMessage.pipe(
      filter(message => message.status === DELIVERY_STATUS.DELIVERED),
    );

    const pendingMessages = lastMessage.pipe(
      filter(message => message.status === DELIVERY_STATUS.SENDING),
    );

    const failedByTimeoutMessages = combineLatest([
      deliveredMessages.pipe(startWith(undefined)),
      pendingMessages.pipe(startWith(undefined)),
    ]).pipe(
      scan(
        (acc, [deliveredMessage, pendingMessage]) => ({
          ...acc,
          ...(pendingMessage?.tag && !acc[pendingMessage.tag]
            ? { [pendingMessage.tag]: pendingMessage }
            : {}),
          ...(deliveredMessage?.tag ? { [deliveredMessage.tag]: true } : {}),
        }),
        {},
      ),
      debounceTime(5000),
      map(messages =>
        Object.values(messages).filter(message => message !== true),
      ),
      startWith([]),
      distinctUntilChanged((a, b) => a?.length === b?.length),
    );

    const failedByStatusMessages = lastMessage.pipe(
      filter(message => message.status === DELIVERY_STATUS.FAILED),
      scan(
        (acc, message) =>
          !message || acc.includes(message) ? acc : [...acc, message],
        [],
      ),
      startWith([]),
      distinctUntilChanged((a, b) => a?.length === b?.length),
    );

    const savedFailedMessages = persistence
      .load(failedMessagesPersistenceKey)
      .pipe(map(messages => Object.values(messages || {})));

    const failedMessages = savedFailedMessages.pipe(
      switchMap(saved =>
        combineLatest([
          failedByTimeoutMessages,
          failedByStatusMessages,
          this.deletedMessages,
        ]).pipe(
          map(([failedByTimeout, failedByStatus, deleted]) =>
            [...saved, ...failedByTimeout, ...failedByStatus].reduce(
              (acc, message) =>
                deleted[message.tag] ? acc : { ...acc, [message.tag]: message },
              {},
            ),
          ),
          distinctUntilChanged(
            (a, b) => JSON.stringify(a) === JSON.stringify(b),
          ),
        ),
      ),
    );

    const deliveredMessagesMap = allMessages.pipe(
      scan(([, prev], curr) => [prev, curr], [[], []]),
      filter(
        ([prev, curr]) =>
          prev?.[prev?.length - 1]?.tag !== curr?.[curr?.length - 1]?.tag,
      ),
      map(([, curr]) => curr),
      scan(
        (acc, messages) => ({
          ...acc,
          ...messages
            .filter(
              ({ outgoing, status }) =>
                outgoing && status === DELIVERY_STATUS.DELIVERED,
            )
            .reduce(
              (messagesMap, message) => ({
                ...messagesMap,
                [message?.tag]: true,
              }),
              {},
            ),
        }),
        {},
      ),
    );

    this.disposeBag.add(
      failedMessages.subscribe(val => this.failedMessages.next(val)),
    );

    this.disposeBag.add(
      this.failedMessages.subscribe(messages => {
        persistence.store(failedMessagesPersistenceKey, messages).subscribe();
      }),
    );

    this.disposeBag.add(
      deliveredMessagesMap.subscribe(delivered => {
        const deliveredMessagesKeys = Object.keys(
          this.failedMessages.getValue() || {},
        ).filter(key => delivered[key]);

        if (deliveredMessagesKeys.length) {
          const messagesToDelete = deliveredMessagesKeys.reduce(
            (acc, key) => ({
              ...acc,
              [key]: true,
            }),
            {},
          );

          const prevDeletedMessages = this.deletedMessages.getValue() || {};
          const currDeletedMessages = {
            ...prevDeletedMessages,
            ...messagesToDelete,
          };

          if (
            Object.keys(prevDeletedMessages).length !==
            Object.keys(currDeletedMessages).length
          ) {
            this.deletedMessages.next(currDeletedMessages);
          }
        }
      }),
    );
  }

  deleteFailedMessage(message) {
    if (message?.outgoing) {
      const prevDeletedMessages = this.deletedMessages.getValue() || {};

      if (!prevDeletedMessages[message.tag]) {
        this.deletedMessages.next({
          ...prevDeletedMessages,
          [message.tag]: true,
        });
        flux
          .get(
            MessagesModel,
            getMessagesModelId(message.sender, message.recipient),
          )
          .actions.delete(message.tag);
      }
    }
  }

  destroy() {
    this.disposeBag?.unsubscribe();
    this.disposeBag = null;
  }
}
