import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    text: {
        fontFamily: '$brandFontFamily',
        fontSize: 16
    }
});

export default styles;
