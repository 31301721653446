import React from 'react';
import { render } from 'dating-mobile/src/components/rx-render';
import { Newsletter } from '@sdv/domain/newsletter/newsletter';
import { Props as ResultProps } from './view';

const withIdentityId = require('dating-mobile/src/models/identity/controller/id').default;

type ComponentProps = {
    userId: string;
    navigation: any;
};

export default function withController<P extends object>(
    Component: React.ComponentType<ResultProps>,
): React.ComponentType<P & ComponentProps> {
    const Controller = render<P & ComponentProps>((props: ComponentProps, { useDistinct }) => {
        const { userId, navigation } = props;
        const completion: (success: boolean, error: { status: number } | undefined) => void = navigation.getParam('completion');
        const newsletter = useDistinct(id => new Newsletter(id), userId);

        const close = () => {
            navigation.pop();
        };
        const sendAndClose = (text: string) => {
            newsletter.send(text).subscribe(() => {
                completion(true, undefined);
            }, error => {
                completion(false, error);
            });
            close();
        };

        return <Component {...props} sendText={sendAndClose} close={close} />;
    });

    Controller.displayName = 'newsletter.controller';

    return withIdentityId(Controller);
}
