import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { SafeAreaView } from 'react-navigation';

import BalanceComponent from './balance';
import CheersComponent from '../../../cheers/views/cheers';

import IdentityController from '../../../../models/identity/controller';
import CheersController from '../../../cheers/controllers/cheers';
import BalanceController from '../../../../models/credits.accounts.balance/controllers/controller';

import styles from './styles';

const Balance = IdentityController(props => {
    const id = (props.identity && props.identity.id) || '';
    const Component = BalanceController(BalanceComponent);
    return <Component id={id} />;
});

const Cheers = CheersController(CheersComponent);

export default class CheersKeyboardComponent extends React.PureComponent {
    static propTypes = {
        onCheerSelected: PropTypes.func,
        freeCheersAvaliable: PropTypes.bool,
    };

    render() {
        return (
            <SafeAreaView forceInset={{ top: 'never', bottom: 'always' }} style={styles.container}>
                <Balance />
                <View style={styles.cheers}>
                    <Cheers
                        onCheerSelected={this.props.onCheerSelected}
                        freeCheersAvaliable={this.props.freeCheersAvaliable}
                    />
                </View>
            </SafeAreaView>
        );
    }
}
