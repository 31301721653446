import EStyleSheet from 'react-native-extended-stylesheet';

const image = {
    width: 20,
    height: 20,
    marginRight: 5,
    resizeMode: 'contain',
    '@flavor lovinga, udates': {
        width: 24,
        height: 24
    },
}

const styles = EStyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center'
    },

    unreadAttachmentImage: {
        ...image,
        '@flavor dma': {
            tintColor: '$primaryTextColor'
        }
    },

    attachmentImage: {
        ...image,
        '@flavor dma, lovinga': {
            tintColor: '$disabledTextColor'
        }
    },

    textWithAttachment: {
        '@flavor lovinga': {
            color: '$disabledTextColor'
        }
    },

    unreadTextWithAttachment: {
        '@flavor lovinga': {
            color: '$primaryTextColor'
        }
    },

    cheerImage: {
        ...image
    },

    matchTextContainer: {
        display: 'flex',
        flexDirection: 'row',
    },

    matchText: {
        '@flavor lovinga': {
            color: '$disabledTextColor',
        },
    },

    accentedText: {
        color: '$accentColor',
    },
});

export default styles;
