import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $tabBarActiveTintColor: '$selectedColor',
    $tabBarInactiveTintColor: '$tabBarIconInactiveColor',

    tabBarIconContainer: {
        height: 32,
        justifyContent: 'center',
        alignItems: 'center',
    },

    tabBarIcon: {
        width: 24,
        height: 24,
    },

    tabBarIconActive: {
        tintColor: '$tabBarActiveTintColor',
    },

    tabBarIconInactive: {
        tintColor: '$tabBarInactiveTintColor',
    },

    tabBarSubscriptionIcon: {
        width: 18,
        height: 20,
    },

    topTabBarLabelStyle: {
        textAlign: 'center',
        backgroundColor: 'transparent',
        fontSize: 11,
        marginBottom: 1.5,
        fontFamily: '$brandFontFamily',
    },

    tabBarLabelActive: {
        color: '$tabBarActiveTintColor',
    },

    tabBarLabelInactive: {
        color: '$tabBarInactiveTintColor',
    },
});

export default styles;
