import React from 'react';
import { Alert, Platform } from 'react-native';
import PropTypes from 'prop-types';
import RoomModel from '../model';
import IdentityModel from '@sdv/domain/identity/model';
import EventEmitter from 'eventemitter3';
import Resources from '../../../resources';
import User from '../../user/model';

export default class MessagesBlockerController extends React.Component {
  static displayName = 'dialogs.room.messages.blocker';
  static contextTypes = {
    flux: PropTypes.object,
  };
  static propTypes = {
    room: PropTypes.string.isRequired,
    bus: PropTypes.object,
  };

  constructor(props, context) {
    super(props);

    this.flux = context.flux;
    this.bus = props.bus || new EventEmitter();
  }

  componentDidMount() {
    this.bus.addListener('event.messages.message.long-press', this.onLongPress);
  }

  componentWillUnmount() {
    this.bus.removeListener(
      'event.messages.message.long-press',
      this.onLongPress,
    );
  }

  onLongPress = tag => {
    if (!this.props.room) {
      return;
    }

    const identityModel = this.flux.get(IdentityModel);
    const identity = identityModel.store.getState().id;
    if (!identity || identity !== this.props.room) {
      return;
    }

    const roomModel = this.flux.get(RoomModel, this.props.room);
    const message = roomModel.store
      .getState()
      .messages.find(message => message.tag === tag);
    if (
      !message ||
      message.sender === 'system' ||
      message.sender === this.props.room
    ) {
      return;
    }
    const name = this.flux.get(User, message.sender).store.getState().name;
    const title = Resources.strings.formatString(
      Resources.strings.streamBlockUserAlertTitleFormat,
      name,
    );

    if (Platform.OS === 'ios') {
      // return ActionSheetIOS.showActionSheetWithOptions(
      //     {
      //         title: title,
      //         options: [Resources.strings['cancel'], Resources.strings['block']],
      //         cancelButtonIndex: 0,
      //     },
      //     buttonIndex => {
      //         if (buttonIndex === 1) {
      //             roomModel.actions.block(message.sender);
      //         }
      //     },
      // );
    } else {
      Alert.alert(
        title,
        null,
        [
          {
            text: Resources.strings['block'],
            onPress: () => roomModel.actions.block(message.sender),
          },
          {
            text: Resources.strings['cancel'],
            style: 'cancel',
          },
        ],
        {
          cancelable: false,
        },
      );
    }
  };

  render() {
    const { children } = this.props;

    return React.Children.map(children, child =>
      React.cloneElement(child, { bus: this.bus }),
    );
  }
}
