import { Platform } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import shouldUseWebLayout from 'dating-mobile/src/utils/web-layout';

const styles = EStyleSheet.create({
  $signUpGoogleButtonEnabled: true,
  $googleSignInButtonVisible: true,
  $signUpTitleEnabled: true,
  $showPasswordRecoverLinkAtBottom: false,
  $signUpTermsVisible: true,
  $signUpAgeTermsVisible: false,
  container: {
    width: '100%',
    height: '100%',
    paddingHorizontal: shouldUseWebLayout() ? '20%' : 0,
  },
  contentContainer: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexGrow: 1,
    paddingHorizontal: 30,
  },
  title: {
    marginTop: 20,
    marginBottom: 20,
    fontSize: '$screenTitleSize',
    color: '$brandTextColor',
    fontFamily: '$brandFontFamily',
    width: '100%',
    textAlign: 'center',
    fontWeight: 'normal',
  },
  inputs: {
    alignItems: 'center',
    width: '100%',
    ...Platform.select({
      web: {
        justifyContent: 'flex-start',
      },
      default: {
        flex: 1,
        justifyContent: 'center',
      },
    }),
  },
  containerInput: {
    marginTop: 60,
  },
  input: {
    borderBottomColor: '$brandBorderColor',
    borderBottomWidth: 1,
    fontFamily: '$brandFontFamily',
    color: '$brandTextColor',
    textAlign: 'center',
    fontWeight: 'normal',
    fontSize: 16,
    height: 40,
  },
  bottom: {
    width: '100%',
    alignItems: 'center',
    ...Platform.select({
      web: {},
      default: {
        flex: 1,
      },
    }),
    flexDirection: 'column-reverse',
    justifyContent: 'flex-start',
  },
  description: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 20,
    marginLeft: 24,
    marginRight: 24,
    textAlign: 'center',
  },
  descriptionText: {
    fontSize: 14,
    color: '$brandPlaceholderColor',
    textAlign: 'center',
    fontFamily: '$brandFontFamily',
  },
  forgotPasswordText: {
    fontSize: 14,
    color: '$brandLinkTextColor',
    textAlign: 'center',
    fontFamily: '$brandFontFamily',
    textDecorationLine: 'underline',
    marginTop: 40,
  },
  descriptionTextDecorate: {
    fontFamily: '$brandFontFamily',
    textDecorationLine: 'underline',
    fontWeight: 'bold',
  },
  error: {
    fontSize: 12,
    color: '$errorColor',
    padding: 5,
    fontFamily: '$brandFontFamily',
    textAlign: 'center',
  },
  modal: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  webView: {
    flex: 1,
    width: '100%',
  },
  close: {
    padding: 5,
  },
  googleSignInButton: {
    marginTop: 20,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  oauthButtonsSeparator: {
    fontSize: 14,
    color: '$brandPlaceholderColor',
    textAlign: 'center',
    fontFamily: '$brandFontFamily',
    marginTop: 10,
  },
  appleSignInButtonContainer: {
    width: '100%',
  },
  appleSignInButton: {
    width: '100%',
    height: 54,
    marginTop: 10,
  },
  bottomView: {
    marginTop: 20,
  },
  ageAgreement: {
    paddingTop: 10,
    paddingRight: 0,
    paddingLeft: 5,
  },
  buttonBig: {
    fontSize: 21,
    fontWeight: '600',
  },
});

export default styles;
