import React from 'react';
import PropTypes from 'prop-types';
import { render } from 'dating-mobile/src/components/rx-render';
import { OnboardingProgress } from 'dating-mobile/src/authentication/utils/onboarding-progress';

export default function controller(Component) {
    const Controller = render((props, { useObservable }) => {
        const progress = useObservable(it => it.progress, OnboardingProgress.shared());

        if (progress === null || progress === undefined) {
            return <Component {...props} visible={false} />;
        }

        return <Component {...props} visible currentProgress={progress} />;
    });

    Controller.displayName = 'authentication.views.onboarding-progress-bar.controller';

    Controller.propTypes = {
        userId: PropTypes.string,
    };

    return Controller;
}
