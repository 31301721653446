import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    text: {
        color: '$primaryTextColor',
        fontSize: 10,
        '@flavor lovinga': {
            color: '$minorAccentTextColor'
        }
    },
    icon: {
        marginRight: 5,
    }
});

export default styles;
