import { map } from 'rxjs/operators';
import { CrossAppAds } from '@sdv/domain/ads/cross-app-ads';
import Resources from 'dating-mobile/src/resources';
import { Linking, Platform } from 'react-native';

export class FeedAdsViewModel {
    constructor() {
        this.currentAd = CrossAppAds.shared().feedAds.pipe(
            map(type => {
                switch (type) {
                    case CrossAppAds.feedAdsType.uni:
                        return Resources.images.datingPromoteCommon();
                    case CrossAppAds.feedAdsType.mal:
                        return Resources.images.datingPromoteMale();
                    case CrossAppAds.feedAdsType.fem:
                        return Resources.images.datingPromoteFemale();
                }
            }),
        );
    }

    // TODO: Перенести в domain/ads/cross-app-ads
    openAd() {
        if (Platform.OS === 'ios') {
            Linking.openURL('https://dating.com');
        } else {
            Linking.openURL('https://dating.onelink.me/4121184611/lovingaban');
        }
    }
}
