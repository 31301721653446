import createActions from './actions';
import createStore from './store';

export default function (id, channel) {
    let Actions = createActions(id, channel);

    return {
        store: createStore(id, channel, Actions),
        actions: Actions
    };

}