import {createOptimisticActions} from '@sdv/model';

function createActions(id, channel) {

    class Actions extends createOptimisticActions(['patch']) {

        static displayName = `notifications-subscriptions-${channel}.${id}`;

        get = () => async (dispatch, flux) => {

            let result;

            try {
                result = await getSubscriptions(id, channel, flux);
            } catch (err) {
                return dispatch(null, err);
            }

            dispatch(result['subscriptions']);

        };


        patch(subscriptions) {
            return super.patch(subscriptions, function (subscriptions) {
                this.dispatch(subscriptions);


                this.flux.api.notifications.subscriptions.patch(`${channel}/${id}`, subscriptions, (err, res) => {

                    if (err) {
                        return this.revert(err);
                    }

                    return this.commit();
                });

            })
        };
    }

    return Actions;
}

export default createActions;


const getSubscriptions = (id, channel, flux) => {

    return new Promise((resolve, reject) => {

        flux.api.notifications.subscriptions.get(`${channel}/${id}`, (err, res) => {

            if (err) {
                return reject(err);
            }

            return resolve(res);
        }, {
            Accept: 'application/vnd.subscriptions.v2+json'
        });

    });

};