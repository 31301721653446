import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $showDateLabel: false,
    '@flavor dma, udates': {
        $showDateLabel: true,
    },

    container: {
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    picker: {
        color: '$brandTextColor',
        width: '100%',
        fontFamily: '$brandFontFamily',
        fontSize: 16,
        alignSelf: 'center',
        '@flavor lovinga': {
            textAlign: 'center',
        },
        '@flavor dma, udates': {
            fontSize: 18,
            fontWeight: '500',
            width: undefined,
        },
    },
    modal: {
        height: 220,
        width: '100%',
        backgroundColor: 'white',
        borderRadius: 8,
    },
    universalDatePickerModal: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    placeholder: {
        color: '$brandPlaceholderColor',
        '@flavor lovinga': {
            textAlign: 'center',
        },
        '@flavor dma, udates': {
            color: '$brandTextColor',
        },
    },
    textContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '100%',
    },
    dateText: {
        '@flavor dma': {
            fontFamily: '$brandFontFamily',
            fontSize: 18,
            color: '$infoTextColor',
        },
        '@flavor udates': {
            fontFamily: '$brandFontFamily',
            fontSize: 18,
            color: '$brandPlaceholderColor',
        },
    },
});

export default styles;
