import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        position: 'absolute',
        top: 0,
        height: 44,
        width: '100%',
        flexDirection: 'row',
        '@media android': {
            height: 64,
        },
    },
    invisible: {
        position: 'absolute',
        top: 0,
        height: 0,
        width: '100%',
        flexDirection: 'row',
    },
});

export default styles;
