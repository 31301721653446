import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  header: {
    backgroundColor: '$primaryBackgroundColor',
    borderBottomColor: '$primaryBackgroundColor',
    shadowColor: '$primaryBackgroundColor',
  },

  headerTitle: {
    color: '$primaryTextColor',
  },

  container: {
    flex: 1,
    alignItems: 'stretch',
    backgroundColor: '$primaryBackgroundColor',
  },

  inputText: {
    fontSize: 17,
    borderBottomColor: '$primaryBorderColor',
    borderBottomWidth: 1,
    fontFamily: '$brandFontFamily',
    fontWeight: '600',
    color: '$primaryTextColor',
  },

  input: {
    paddingHorizontal: 20,
  },

  placeholder: {
    color: '$primaryPlaceholderColor',
  },
});

export default styles;
