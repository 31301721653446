import Service from '../../service';
import PropTypes from 'prop-types';
import MessagesModel, { getId } from '@sdv/domain/dialogs.messages';
import IdentityModel from '@sdv/domain/identity/model';
import ServerDate from '@sdv/domain/api/date';

const MILLIS_IN_SECOND = 1000;

export default class MessagesProvider extends Service {
    static displayName = 'service.dialogs-messages-provider';
    static contextTypes = {
        flux: PropTypes.object
    };

    componentDidMount() {
        this.context.flux.events.addListener('message:message:v2', this.onMessageReceived);
        this.context.flux.events.addListener('event.dialogs.messages.seen', this.onMessageSeen);
    }

    componentWillUnmount() {
        this.context.flux.events.removeListener('message:message:v2', this.onMessageReceived);
        this.context.flux.events.removeListener('event.dialogs.messages.seen', this.onMessageSeen);
    }

    onMessageSeen = (message) => {
        if (!message) {
            return;
        }

        const identityModel = this.context.flux.get(IdentityModel);
        const identity = identityModel.store.getState();

        if (!identity.id) {
            return;
        }

        const attendee = message.sender === identity.id ? message.recipient : message.sender;
        const { store, actions } = this.context.flux.get(MessagesModel, getId(identity.id, attendee));
        const unreadMessages = (store.getState().messages || []).filter((message) => !message.read);

        unreadMessages.forEach((message) => {
            actions.setReadForOutgoing(message.tag);
        });
    };

    onMessageReceived = (message) => {
        if (!message) {
            return;
        }

        const identityModel = this.context.flux.get(IdentityModel);
        const identity = identityModel.store.getState();
        if (!identity.id) {
            return;
        }

        const attendee = message.sender === identity.id ? message.recipient : message.sender;
        const messageModel = this.context.flux.get(MessagesModel, getId(identity.id, attendee));

        if (message.timestamp) {
            message.timestamp = message.timestamp * MILLIS_IN_SECOND;
            messageModel.actions.patch(message);
        } else {
            const serverDate = ServerDate(this.context.flux.api);

            serverDate.now((date) => {
                message.timestamp = date;
                messageModel.actions.patch(message);
            });
        }
    };

}
