import React from 'react';
import PropTypes from 'prop-types';
import { withNavigationFocus } from 'react-navigation';
import Config from 'dating-mobile/src/app/config';
import * as ROUTES from 'dating-mobile/src/routing/router/constants';
import { routeForRegistrationStep } from '../../utils/registration-step-route';
import { routeForRegistrationStep as steppedRouteForRegistrationStep } from '../../utils/stepped-registration-route';

import authRouting from '../../controllers/auth-routing';
import controller from './controller';
import View from './view';

const ScreenView = withNavigationFocus(authRouting(controller(View)));

class Screen extends React.Component {
  static displayName = 'authentication.auth-loading.screen';

  static propTypes = {
    navigation: PropTypes.object.isRequired,
  };

  static navigationOptions = () => ({
    headerShown: false,
  });

  componentDidMount() {
    this.disposeBag = Config.shared()
      .paramWithPath('features.stepped-registration-enabled')
      .subscribe(result => {
        this.steppedRegistrationEnabled = result;
      });
  }

  componentWillUnmount() {
    if (this.disposeBag) this.disposeBag.unsubscribe();
  }

  showApp = () => {
    const { navigation } = this.props;
    const initScreen = navigation.getParam('initScreen');
    const initParams = navigation.getParam('initParams');

    navigation.navigate(initScreen || ROUTES.APP, initParams);
  };

  showRegistrationStep = (step, params) => {
    const { navigation } = this.props;
    const route = this.steppedRegistrationEnabled
      ? steppedRouteForRegistrationStep(step)
      : routeForRegistrationStep(step);

    navigation.navigate(route, params);
  };

  showAuth = () => {
    const { navigation } = this.props;
    const initScreen = navigation.getParam('initScreen');
    const initParams = navigation.getParam('initParams');
    const shouldUseInitScreen =
      initScreen === ROUTES.REGISTER || initScreen === ROUTES.LOGIN;

    navigation.navigate(
      shouldUseInitScreen ? initScreen : ROUTES.AUTH,
      initParams,
    );
  };

  render() {
    return (
      <ScreenView
        showApp={this.showApp}
        showRegistrationStep={this.showRegistrationStep}
        showAuth={this.showAuth}
      />
    );
  }
}

export default Screen;
