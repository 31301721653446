import React from 'react';
import Resources from 'dating-mobile/src/resources';
import { Image, Text, TouchableWithoutFeedback, View } from 'react-native';
import styles from './style';

type Props = {
    notification: { id: string };
    onReadyForDisplay: (time: number | undefined) => void;
    dismiss: () => void;
};

class StreamAnnouncementContent extends React.Component<Props> {
    componentDidMount(): void {
        const { onReadyForDisplay } = this.props;
        onReadyForDisplay(undefined);
    }

    componentDidUpdate(): void {
        const { onReadyForDisplay } = this.props;
        onReadyForDisplay(undefined);
    }

    render(): any {
        return (
            <TouchableWithoutFeedback onPress={this.props.dismiss}>
                <View style={styles.container}>
                    <Image
                        style={styles.image}
                        source={Resources.images.notificationStreamAnnouncementIsSentIcon()}
                    />
                    <Text numberOfLines={1} style={styles.text}>
                        {Resources.strings['notification-stream-announcement-is-sent-text']}
                    </Text>
                </View>
            </TouchableWithoutFeedback>
        );
    }
}

export const StreamAnnouncementIsSentNotification = (props: Props) => {
    const { notification, onReadyForDisplay, dismiss } = props;

    return (
        <StreamAnnouncementContent
            notification={notification}
            onReadyForDisplay={onReadyForDisplay}
            dismiss={dismiss}
        />
    );
};
