import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({

    thumbnail: {

    },

    container: {
        margin: 6,
        flexDirection: 'column',
        alignItems: 'center'
    },

    imageContainer: {
        width: '100%',
        aspectRatio: 1
    },

    image: {
        flex: 1
    },

    selectionMark: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: 24,
        height: 24
    },

    name: {
        fontSize: 14,
        fontWeight: 'bold',
        color: '$primaryTextColor',
        marginTop: 7,
        marginBottom: 7,
        fontFamily: '$brandFontFamily'
    }

});

export default styles;

