import React, { PureComponent } from 'react';
import PropTypes from 'prop-types'

class DrawWhenReady extends PureComponent {
    static displayName = 'components.draw-when-ready';
    static propTypes = {
        children: PropTypes.node,
        isReady: PropTypes.bool
    };

    render() {
        const { isReady , children } = this.props;

        return isReady ? children : null
    }
}

export default DrawWhenReady
