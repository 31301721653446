import EStyleSheet from 'react-native-extended-stylesheet';
import shouldUseWebLayout from 'dating-mobile/src/utils/web-layout';

const styles = EStyleSheet.create({
  $showHint: false,
  '@flavor secretly': {
    $showHint: true,
  },
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 30,
    paddingHorizontal: shouldUseWebLayout() ? '20%' : 30,
    '@flavor secretly': {
      paddingBottom: 50,
    },
  },
  title: {
    width: '100%',
    fontSize: '$screenTitleSize',
    textAlign: '$brandTitleAlign',
    color: '$brandTextColor',
    fontFamily: '$brandFontFamily',
    marginBottom: 50,
    fontWeight: 'bold',
    '@flavor ytm': {
      marginBottom: 20,
    },
    '@flavor secretly': {
      fontWeight: 'normal',
      marginBottom: 100,
      marginTop: 20,
    },
  },
  inputs: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    marginBottom: 10,
  },
  inputContainer: { width: '100%' },
  input: {
    width: '100%',
    fontSize: 17,
    borderBottomColor: '$brandBorderColor',
    borderBottomWidth: 1,
    fontFamily: '$brandFontFamily',
    fontWeight: '600',
    color: '$brandTextColor',
    textAlign: 'center',
    '@flavor ytm': {
      textAlign: 'left',
    },
    '@flavor secretly': {
      fontWeight: 'normal',
      fontSize: 16,
      height: 40,
    },
  },
  error: {
    fontSize: 12,
    color: '$errorColor',
    padding: 5,
    fontFamily: '$brandFontFamily',
    textAlign: 'center',
  },
  hint: {
    fontSize: 14,
    color: '$brandLinkTextColor',
    textAlign: 'center',
    fontFamily: '$brandFontFamily',
    marginTop: 30,
  },
});

export default styles;
