import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Text, Image, TouchableOpacity, Alert } from 'react-native';
import Resources from 'dating-mobile/src/resources';

import blockImg from './assets/block.png';

import styles from './styles';

export default class BlockUserButton extends PureComponent {
  static displayName = 'call.views.block-user-button.view';

  static propTypes = {
    blockUser: PropTypes.func,
    onBlock: PropTypes.func,
  };

  blockUser = () => {
    const { blockUser, onBlock } = this.props;

    Alert.alert(
      Resources.strings['block-user-alert-title'],
      Resources.strings['block-user-alert-message'],
      [
        {
          text: Resources.strings.block,
          onPress: () => {
            if (blockUser) {
              blockUser();
            }

            if (onBlock) {
              onBlock();
            }
          },
        },
        {
          text: Resources.strings.cancel,
          style: 'cancel',
        },
      ],
    );
  };

  render() {
    return (
      <TouchableOpacity style={styles.container} onPress={this.blockUser}>
        <Image source={blockImg} />
        <Text style={styles.text}>
          {Resources.strings['audio-call-block-user-text']}
        </Text>
      </TouchableOpacity>
    );
  }
}
