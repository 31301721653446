import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $statusBarStyle: '$primaryStatusBarStyle',
    $statusBarBackgroundColor: '$primaryBackgroundColor',

    header: {
        backgroundColor: '$primaryBackgroundColor',
        borderBottomColor: '$primaryBackgroundColor',
        shadowColor: '$primaryBackgroundColor',
        shadowRadius: 0,
        shadowOpacity: 0,
        elevation: 0,
        shadowOffset: {
            height: 0,
        }
    },

    headerTitle: {
        fontFamily: '$brandFontFamily',
        color: '$primaryTextColor',
    },

    container: {
        flex: 1,
        backgroundColor: '$primaryBackgroundColor',
    },

    scrollView: {
        flexGrow: 1
    },

    content: {
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'flex-start',
    },

    title: {
        color: '$primaryTextColor',
    },

    titleContainer: {
        borderBottomColor: '$primaryBorderColor'
    }

});

export default styles;
