import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({

    webView: {
        flex: 1
    }

});

export default styles;
