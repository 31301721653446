import React from 'react';
import Config from 'dating-mobile/src/app/config';
import FreeCreditsNotification from '@sdv/domain/notifications/free-credits-notification';
import { combineLatest } from "rxjs";
import PropTypes from "prop-types";

export default function controller(Component) {
    class Controller extends React.Component {
        static displayName = 'feed.views.free-credits-banner.controller';
        static propTypes = {
            userId: PropTypes.string.isRequired
        };

        state = {
            visible: false,
            freeCreditsAmount: 0
        };

        componentDidMount() {
            this.subscribe();
        }

        componentWillUpdate(nextProps) {
            if (nextProps.userId && nextProps.userId !== this.props.userId) {
                this.unsubscribe();
                this.subscribe();
            }
        }

        subscribe() {
            this.freeCreditsNotification = FreeCreditsNotification.shared(this.props.userId);
            const freeCreditsAmount = Config.shared().paramWithPath('bonuses.credits-for-registration');
            this.subscription = combineLatest(this.freeCreditsNotification.notify, freeCreditsAmount)
                .subscribe(([notify, freeCreditsAmount]) => {
                    this.setState({
                        visible: notify && freeCreditsAmount !== null,
                        freeCreditsAmount: freeCreditsAmount
                    })
                })
        }

        unsubscribe() {
            this.subscription.unsubscribe();
        }

        onClosePressed = () => {
            this.freeCreditsNotification.acknowledge();
        };

        render() {
            return (<Component
                {...this.state}
                onClosePress={this.onClosePressed}
            />);
        }
    }

    return Controller;
}
