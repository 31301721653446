import React from 'react';
import PropTypes from 'prop-types';
import { Stock } from '@sdv/domain/payment/stock';
import { Purchase } from 'dating-mobile/src/payment/methods/purchase';
import withHasTag from 'dating-mobile/src/models/user.tags/controllers/has-tag';
import withConfigValue from 'dating-mobile/src/components/config-value';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';
import { Alert } from 'react-native';
import Resources from 'dating-mobile/src/resources';
import { RxController } from 'dating-mobile/src/components/rx-controller';
import { TERMS } from 'dating-mobile/src/routing/router/constants';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';

const getCreditsPackages = (packages = [], minCreditsAmount = 0) =>
  packages
    .filter(p => p?.[0]?.creditsAmount)
    .filter(p => p[0].creditsAmount >= (minCreditsAmount || 0))
    .sort((a, b) => a[0].creditsAmount - b[0].creditsAmount);

function controller(Component) {
  class Controller extends RxController {
    static displayName = 'payment.screens.credits.controller';

    static propTypes = {
      id: PropTypes.string,
      currency: PropTypes.string,
      discountWithMembershipEnabled: PropTypes.bool,
      canSubscribe: PropTypes.bool,
      ableToNavigateToMembership: PropTypes.bool,
      openLink: PropTypes.func,
      openMembership: PropTypes.func,
      onPackagePress: PropTypes.func.isRequired,
      reason: PropTypes.string.isRequired,
      isFocused: PropTypes.bool,
      descriptionTitle: PropTypes.string,
      minCreditsAmount: PropTypes.number,
      descriptionHint: PropTypes.bool,
    };

    static contextTypes = {
      flux: PropTypes.object,
    };

    constructor(props, context) {
      super(props, context);
      this.state = {
        packages: [],
        isLoading: false,
      };
    }

    componentDidMount() {
      const { setNavigationBarState, minCreditsAmount } = this.props;

      super.componentDidMount();

      if (setNavigationBarState) {
        setNavigationBarState({
          onCloseButtonPress: this.onCloseButtonPressed,
        });
      }

      this.setState({
        isLoading: true,
      });

      this.addSubscription(
        this.rxProps
          .pipe(
            distinctUntilChanged(
              (prev, cur) =>
                prev.isFocused === cur.isFocused && prev.id === cur.id,
            ),
            filter(it => it.isFocused && it.id),
            map(it => it.id),
            switchMap(id => Stock.shared(id).preloadedPackagesInStock),
            map(it => getCreditsPackages(it, minCreditsAmount)),
          )
          .subscribe(packages => {
            this.setState({
              packages,
              isLoading: false,
            });
          }, this.onPackagesLoadingError),
      );
    }

    // TODO: Добавить реализацию экрана ошибки после выяснения требований
    onPackagesLoadingError = () => {};

    openLink = (title, uri) => {
      const { openLink } = this.props;

      if (openLink) {
        openLink(TERMS, {
          title,
          uri,
        });
      }
    };

    onLinkPress = (title, uri) => {
      const { openLink } = this.props;

      if (openLink) {
        openLink(title, uri);
      }
    };

    onPackagePress = packWithInvoices => {
      const { id, reason } = this.props;
      const [pack, invoices] = packWithInvoices;

      // TODO: Remove commented lines after payment via card implementation completed.
      // if (invoices.length > 1) {
      //     this.openPaymentTypeListScreen(invoices);
      // } else if (invoices.length === 1) {
      this.setState({
        isLoading: true,
      });
      this.addSubscription(
        Purchase.shared()
          .pay(id, invoices[0], reason)
          .subscribe(({ success }) => {
            this.setState({
              isLoading: false,
            });

            if (success) {
              this.onPaymentCompleted(pack);
            }
          }),
      );
      // }
    };

    // openPaymentTypeListScreen = (invoices) => {
    //     this.props.onPackagePress && this.props.onPackagePress(invoices, this.onPaymentCompleted);
    // };

    onPaymentCompleted = pack => {
      Alert.alert(
        Resources.strings['credits-screen-success-alert-title'],
        Resources.strings['credits-screen-success-alert-message'],
        [
          {
            text: Resources.strings.ok,
            style: 'cancel',
            onPress: () => {
              this.onPaymentClosed(pack);
            },
            onDismiss: () => {
              this.onPaymentClosed(pack);
            },
          },
        ],
      );
    };

    onPaymentClosed = product => {
      const { close } = this.props;

      if (close) {
        close(true, product);
      }
    };

    onGetDiscountButtonPressed = () => {
      const { openMembership } = this.props;

      if (openMembership) {
        openMembership();
      }
    };

    onCloseButtonPressed = () => {
      const { close } = this.props;

      if (close) {
        close();
      }
    };

    render() {
      const {
        id,
        style,
        discountWithMembershipEnabled,
        ableToNavigateToMembership,
        canSubscribe,
        descriptionTitle,
        descriptionHint,
      } = this.props;
      const { isLoading, packages } = this.state;
      const getDiscountButtonVisible =
        discountWithMembershipEnabled &&
        ableToNavigateToMembership &&
        canSubscribe;

      return (
        <Component
          id={id}
          style={style}
          getDiscountButtonVisible={getDiscountButtonVisible}
          onLinkPress={this.onLinkPress}
          onGetDiscountButtonPress={this.onGetDiscountButtonPressed}
          onPackagePress={this.onPackagePress}
          isLoading={isLoading}
          packages={packages}
          descriptionTitle={descriptionTitle}
          descriptionHint={descriptionHint}
        />
      );
    }
  }

  return withConfigValue(
    withIdentityId(
      withHasTag(Controller, {
        tag: 'membership',
        userIdPropName: 'id',
        hasTagPropName: 'canSubscribe',
      }),
      'id',
    ),
    {
      currency: 'currency.type',
      discountWithMembershipEnabled:
        'features.discount-with-membership-enabled',
    },
  );
}

export default controller;
