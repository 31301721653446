import React from 'react';
import connect from '@sdv/connect';
import notificationsModel from '@sdv/domain/notifications.subscriptions';

function getModels(flux, props) {
    const models = {};

    if (props.userId) {
        let model = notificationsModel(props.userId, 'cloudmessages');
        models.notificationsModel = flux.get(model, props.userId);
    }

    return models;
}

function mapStoresToProps(models) {
    const props = {};

    if (models.notificationsModel) {
        const state = models.notificationsModel.store.getState();
        props.favoriteMessageEnabled = !!state['favorite-message'];
        props.messagesDigestEnabled = !!state['messages-digest'];
        props.birthdaysDigestEnabled = !!state['birthdays-digest'];
    }

    return props;
}

function mapActionsToProps(models) {
    const props = {};

    if (models.notificationsModel) {
        props.favoriteMessageToggle = () => {
            const state = models.notificationsModel.store.getState();
            const value = state['favorite-message'] ? 0 : 1;
            models.notificationsModel.actions.patch({'favorite-message': value});
        };
        props.messagesDigestToggle = () => {
            const state = models.notificationsModel.store.getState();
            const value = state['messages-digest'] ? 0 : 1;
            models.notificationsModel.actions.patch({'messages-digest': value});
        };
        props.birthdaysDigestToggle = () => {
            const state = models.notificationsModel.store.getState();
            const value = state['birthdays-digest'] ? 0 : 1;
            models.notificationsModel.actions.patch({'birthdays-digest': value});
        };

        props.getSettings = models.notificationsModel.actions.get;
    }

    return props;
}

export default connect(
    getModels,
    mapStoresToProps,
    mapActionsToProps
);
