// @ts-ignore
import { UserNotifying } from '@sdv/domain/notifications/user-notifying';
// @ts-ignore
import { NOTIFICATION_PRIORITY } from '@sdv/domain/notifications/local';
import { NotificationType } from '../notifications/types';

export class NewsletterSendingNotifier {
  // eslint-disable-next-line class-methods-use-this
  notifyUser(userId: string) {
    const userNotifier = UserNotifying.shared(userId);

    const notification = {
      type: NotificationType.StreamAnnouncementIsSent,
      payload: {},
    };

    userNotifier.notifyUser(notification, NOTIFICATION_PRIORITY.NORMAL);
  }
}
