import React from 'react';
import PropTypes from 'prop-types';
import { View, ScrollView, StatusBar } from 'react-native';
import testId from 'dating-mobile/src/utils/test-id';
import Photo, { PHOTO_TRANSFORMATION_TYPE } from '../../../../components/photo';
import ImageButton from '../../../../components/navigation/views/image-button';
import Resources from '../../../../resources';
import styles from './styles';

export default class PhotoScreen extends React.PureComponent {
  static propTypes = {
    navigation: PropTypes.object.isRequired,
  };

  static navigationOptions = ({ navigation }) => ({
    title: '',
    headerTransparent: true,
    headerStyle: undefined,
    headerTitleStyle: styles.headerTitle,
    headerLeft: () => (
      <ImageButton
        image={Resources.images.backIcon()}
        onPress={() => navigation.pop()}
        {...testId(
          Resources.strings['navigation-bar-back-button-accessibility-label'],
        )}
      />
    ),
    headerTintColor: styles.headerTitle.color,
  });

  state = {};

  onScrollViewLaidOut = event => {
    const { width, height } = event.nativeEvent.layout;
    const { width: widthState, height: heightState } = this.state;

    if (!width || !height || (widthState === width && heightState === height)) {
      return;
    }

    this.setState({
      width,
      height,
    });
  };

  render() {
    const { navigation } = this.props;
    const { width, height } = this.state;
    const source = navigation.getParam('source');
    const path = navigation.getParam('path');
    const photoSizeStyle = width && height ? { width, height } : {};

    return (
      <View style={styles.container}>
        <StatusBar
          hidden={false}
          barStyle={styles.$statusBarStyle}
          backgroundColor={styles.$statusBarBackgroundColor}
        />
        <ScrollView
          style={styles.scrollView}
          maximumZoomScale={3}
          minimumZoomScale={1}
          onLayout={this.onScrollViewLaidOut}
        >
          <Photo
            source={source}
            path={path}
            style={[styles.photo, photoSizeStyle]}
            transformation={PHOTO_TRANSFORMATION_TYPE.KEEP_ASPECT_RATIO}
            resizeMode="contain"
            {...testId(
              Resources.strings['photo-full-size-accessibility-label'],
            )}
          />
        </ScrollView>
      </View>
    );
  }
}
