import React from 'react';
import { Image, TouchableOpacity, View, Platform } from 'react-native';
import PropTypes from 'prop-types';
import { createSwitchNavigator, createAppContainer } from 'react-navigation';
import { createBrowserApp } from '@react-navigation/web';
import { createBottomTabNavigator } from 'react-navigation-tabs';

import TipsListScreen from 'dating-mobile/src/tips/screens/tips';
import TipScreen from 'dating-mobile/src/tips/screens/tip';
import StartScreenContainer from 'dating-mobile/src/authentication/screens/initial';
import { FeedScreen } from 'dating-mobile/src/feed/screens/list';
import FilterScreen from 'dating-mobile/src/feed/screens/filter';
import AdvancedFiltersScreen from 'dating-mobile/src/feed/screens/advanced-filters';
import { MyChatsScreen } from 'dating-mobile/src/dialogs/chats/screens/my-chats';
import { AccountScreen } from 'dating-mobile/src/user/screens/account';
import ProfileEditScreen from 'dating-mobile/src/user/edit/screens/profile-editing';
import ProfileScreen from 'dating-mobile/src/user/profile/screen';
import GalleryScreen from 'dating-mobile/src/user/gallery/screens/gallery';
import RegistrationTravelsScreen from 'dating-mobile/src/user/travels/screens/registration';
import ProfileTravelsScreen from 'dating-mobile/src/user/travels/screens/profile';
import RegistrationUpdateUserScreen from 'dating-mobile/src/user/edit/screens/profile-editing/complex';
import RegistrationGenderPickingScreen from 'dating-mobile/src/user/edit/screens/gender-picking';
import StreamScreen from 'dating-mobile/src/streaming/screens/streams-pager';
import CurrentlyBroadcastingScreen from 'dating-mobile/src/dialogs/currently-broadcasting/screen';
import DialogsMessageScreen from 'dating-mobile/src/dialogs/messages/screen';
import AuthLoadingScreen from 'dating-mobile/src/authentication/screens/auth-loading';
import SignUpScreen from 'dating-mobile/src/authentication/screens/identity/sign-up';
import SignInScreen from 'dating-mobile/src/authentication/screens/identity/sign-in';
import SignUpUpdateScreen from 'dating-mobile/src/authentication/screens/identity/update';
import PasswordRecoverScreen from 'dating-mobile/src/authentication/screens/identity/password-recover';
import LiveStreamsDiscoveryContainer from 'dating-mobile/src/broadcasts/discovery/screen';
import StreamInfoScreen from 'dating-mobile/src/dialogs/stream.info/screens/diamonds';
import FollowTopScreen from 'dating-mobile/src/broadcasts/follow-top/screens';
import TermsScreen from 'dating-mobile/src/terms/screen';
import PhotoScreen from 'dating-mobile/src/user/gallery/screens/photo';
import TextEditingScreen from 'dating-mobile/src/user/edit/screens/text-value-editing';
import SelectionEditingScreen from 'dating-mobile/src/user/edit/screens/selection-value-editing';
import InterestsEditingScreen from 'dating-mobile/src/user/edit/screens/interests-editing';
import DateEditingScreen from 'dating-mobile/src/user/edit/screens/date-value-editing';
import RangePickerEditingScreen from 'dating-mobile/src/user/edit/screens/range-picker-editing';
import PickerEditingScreen from 'dating-mobile/src/user/edit/screens/picker-editing';
import LocationEditingScreen from 'dating-mobile/src/user/edit/screens/location-editing';
import RateAppScreen from 'dating-mobile/src/rate-application/screen';
import Resources from 'dating-mobile/src/resources';
import ReadLetterScreen from 'dating-mobile/src/inbox/screens/letter-reading';
import ChatsTabBarItem from 'dating-mobile/src/routing/views/chats-tab-bar-item';
import AccountTabBarItem from 'dating-mobile/src/routing/views/account-tab-bar-item';
import ChatRequestsTabBarItem from 'dating-mobile/src/routing/views/chat-requests-tab-bar-item';
import { PurchaseTabBarItem } from 'dating-mobile/src/routing/views/purchase-tab-bar-item';
import ChatRequestsScreen from 'dating-mobile/src/dialogs/chats/screens/chat-requests';
import Can from 'dating-mobile/src/models/user.ability/controller';
import CredentialsUpdater from 'dating-mobile/src/authentication/utils/credentials-updater';
import shouldUseWebLayout from 'dating-mobile/src/utils/web-layout';
import CheersScreen from 'dating-mobile/src/dialogs/cheers/screen';
import { PurchaseMembershipScreen } from 'dating-mobile/src/payment/screens/memberships';
import { PurchaseCreditsScreen } from 'dating-mobile/src/payment/screens/credits';
import { PurchaseScreen } from 'dating-mobile/src/payment/screens/purchase';
import PaymentLoadingScreen from 'dating-mobile/src/payment/screens/initial';
import PaymentProcessing from 'dating-mobile/src/payment/screens/processing/loading';
import PaymentResult from 'dating-mobile/src/payment/screens/processing/result';
import StartMingleScreen from 'dating-mobile/src/dialogs/mingle/screens/start';
import MingleEndScreen from 'dating-mobile/src/dialogs/mingle/screens/end';
import UserGoalsPickingScreen from 'dating-mobile/src/authentication/screens/goals';
import UploadPrivatePhotosScreen from 'dating-mobile/src/authentication/screens/upload-private-photos';
import FreeStartMessagesScreen from 'dating-mobile/src/authentication/screens/free-start-messages';
import OngoingCallScreen from 'dating-mobile/src/call/screens/ongoing-call';
import IncomingCallScreen from 'dating-mobile/src/call/screens/incoming-call';
import ConnectingToCallScreen from 'dating-mobile/src/call/screens/connecting-to-call';
import { CATEGORIES_PRESET } from 'dating-mobile/src/broadcasts/discovery/views/stream-categories';
import InboxScreen from 'dating-mobile/src/inbox/screens/inbox';
import FollowingTabBarItem from 'dating-mobile/src/routing/views/following-tab-bar-item';
import { NotificationsSettingsScreen } from 'dating-mobile/src/user/screens/notifications-settings';
import WriteLetterScreen from 'dating-mobile/src/inbox/screens/letter-writing';
import InboxTabBarItem from 'dating-mobile/src/routing/views/inbox-tab-bar-item';
import TurnOnNotificationsScreen from 'dating-mobile/src/notifications/views/turn-on-push-notifications';
import DisabledPushNotificationIndicator from '@sdv/domain/notifications/disabled-push-notifications-indicator';
import { CustomerSupportScreen } from 'dating-mobile/src/user/screens/customer-support';
import StreamsTopicsPickingBonusScreen from 'dating-mobile/src/streaming/screens/streams-topics-picking-bonus';
import StreamsTopicsPickingScreen from 'dating-mobile/src/streaming/screens/streams-topics-picking';
import { AccountSettingsScreen } from 'dating-mobile/src/user/screens/account-settings';
import { ChangeEmailScreen } from 'dating-mobile/src/user/screens/change-email';
import { ChangePasswordScreen } from 'dating-mobile/src/user/screens/change-password';
import { PaymentMethodList } from 'dating-mobile/src/payment/screens/payment-methods';
import { CreditCardPaymentScreen } from 'dating-mobile/src/payment/screens/card';
import { PayPalScreen } from 'dating-mobile/src/payment/screens/paypal';
import { ThreeDsScreen } from 'dating-mobile/src/payment/screens/processing/three-ds';
import { StreamTopicsSelection } from 'dating-mobile/src/dialogs/stream/screens/stream-topics-selection';
import { StreamDescriptionPreset } from 'dating-mobile/src/dialogs/stream/screens/stream-description-preset';
import { SubscribeScreen } from 'dating-mobile/src/payment/screens/subscribe';
import { CongratulationScreen } from 'dating-mobile/src/payment/screens/congratulations';
import { SkipSubscribeScreen } from 'dating-mobile/src/payment/screens/skip-subscribe';
import { SteppedRegistrationEmailScreen } from 'dating-mobile/src/stepped-registration/screens/email';
import { SteppedRegistrationNameScreen } from 'dating-mobile/src/stepped-registration/screens/name';
import { SteppedRegistrationPasswordScreen } from 'dating-mobile/src/stepped-registration/screens/password';
import { SteppedRegistrationGenderScreen } from 'dating-mobile/src/stepped-registration/screens/gender';
import { SteppedRegistrationBirthdayScreen } from 'dating-mobile/src/stepped-registration/screens/birthday';
import {
  SteppedRegistrationThumbnailScreen,
  MandatoryThumbnailScreen,
} from 'dating-mobile/src/stepped-registration/screens/photo-uploading-screen';
import { SteppedRegistrationGeolocationScreen } from 'dating-mobile/src/stepped-registration/screens/geolocation';
import { SteppedRegistrationNotificationsScreen } from 'dating-mobile/src/stepped-registration/screens/notifications';
import { SteppedRegistrationStartSettingPreferencesScreen } from 'dating-mobile/src/stepped-registration/screens/start-setting-preferences';
import { SteppedRegistrationRelationshipPriorityScreen } from 'dating-mobile/src/stepped-registration/screens/relationship-priority';
import { SteppedRegistrationUserGoalsScreen } from 'dating-mobile/src/stepped-registration/screens/user-goals';
import { SteppedRegistrationPersonalityScreen } from 'dating-mobile/src/stepped-registration/screens/personality';
import { SteppedRegistrationBodyTypeScreen } from 'dating-mobile/src/stepped-registration/screens/body-type';
import { SteppedRegistrationPreferredAgeScreen } from 'dating-mobile/src/stepped-registration/screens/preferred-age';
import { SteppedRegistrationBirthdayWithGenderScreen } from 'dating-mobile/src/stepped-registration/screens/birthday-with-gender';
import { SteppedRegistrationPhotoWithBioScreen } from 'dating-mobile/src/stepped-registration/screens/photo-with-bio';
import { SteppedRegistrationSubscriptionScreen } from 'dating-mobile/src/stepped-registration/screens/subscription';
import { SteppedRegistrationBoostScreen } from 'dating-mobile/src/stepped-registration/screens/boost';
import { FaqCategoriesScreen } from 'dating-mobile/src/faq/screens/faq-categories';
import { FaqDetailsScreen } from 'dating-mobile/src/faq/screens/faq-details';
import { SearchMatchesScreen } from 'dating-mobile/src/stepped-registration/screens/find-match';
import { NewsletterScreen } from 'dating-mobile/src/newsletter';
import { SetNewPasswordScreen } from 'dating-mobile/src/authentication/screens/identity/set-new-password';
import { TopTabBar } from 'dating-mobile/src/routing/views/top-tab-bar-component/view';
import { SpecialOfferScreen } from 'dating-mobile/src/payment/screens/special-offer';
import { PurchaseCallsPackScreen } from 'dating-mobile/src/payment/screens/calls';
import { BlockListScreen } from 'dating-mobile/src/screens/block-list';
import * as ROUTES from './constants';
import Navigator from '../navigator';
import { createStackNavigator, createCardInterpolatedStyles } from './stack';
import styles from './styles';

export class Router extends React.PureComponent {
  static displayName = 'routing.router';

  static propTypes = {
    config: PropTypes.object,
  };

  contactsStack = () => {
    const { config } = this.props;
    const likesListEnabled = config.features['likes-list-enabled'];

    const ChatsStack = createStackNavigator(
      {
        [ROUTES.CONTACTS]: MyChatsScreen,
        [ROUTES.CHAT_REQUESTS]: ChatRequestsScreen,
        [ROUTES.CHAT]: DialogsMessageScreen,
        [ROUTES.LETTER]: ReadLetterScreen,
        [ROUTES.WRITE_LETTER]: WriteLetterScreen,
        [ROUTES.PROFILE]: ProfileScreen,
        [ROUTES.GALLERY]: GalleryScreen,
      },
      {
        initialRouteParams: {
          likesListEnabled,
        },
      },
    );

    let contactsStack;

    if (likesListEnabled) {
      contactsStack = createBottomTabNavigator(
        {
          [ROUTES.CHATS_STACK]: {
            screen: ChatsStack,
            navigationOptions: {
              tabBarLabel: Resources.strings['my-chats-screen-title'],
            },
          },
        },
        {
          tabBarComponent: TopTabBar,
          tabBarOptions: {
            style: styles.topTabBarStyle,
            labelStyle: styles.topTabBarLabelStyle,
            activeTintColor: styles.$topTabBarAccentColor,
            inactiveTintColor: styles.$topTabBarInactiveColor,
            indicatorStyle: styles.topTabBarIndicatorStyle,
          },
          swipeEnabled: false,
        },
      );
    } else {
      contactsStack = ChatsStack;
    }

    contactsStack.navigationOptions = ({ navigation }) => {
      const { routes } = navigation.state.routes[navigation.state.index];
      const tabBarVisible = !routes || routes.length <= 1;

      return {
        title: Resources.strings['chats-tab-bar-item-title'],
        tabBarIcon: ({ focused }) => {
          return (
            <ChatsTabBarItem
              focused={focused}
              withoutCounter={likesListEnabled}
            />
          );
        },
        tabBarVisible:
          !shouldUseWebLayout() &&
          (likesListEnabled ? tabBarVisible : navigation.state.index === 0),
      };
    };

    return contactsStack;
  };

  accountStack = () => {
    const AccountStack = createStackNavigator({
      [ROUTES.ACCOUNT]: AccountScreen,
      [ROUTES.PROFILE_EDIT]: ProfileEditScreen,
      [ROUTES.GALLERY]: GalleryScreen,
      [ROUTES.PROFILE_TRAVELS_SCREEN]: ProfileTravelsScreen,
      [ROUTES.TEXT_EDITING]: TextEditingScreen,
      [ROUTES.SELECTION_EDITING]: SelectionEditingScreen,
      [ROUTES.DATE_EDITING]: DateEditingScreen,
      [ROUTES.INTERESTS_EDITING]: InterestsEditingScreen,
      [ROUTES.RANGE_PICKER_EDITING]: RangePickerEditingScreen,
      [ROUTES.PICKER_EDITING]: PickerEditingScreen,
      [ROUTES.LOCATION_EDITING]: LocationEditingScreen,
      [ROUTES.NOTIFICATIONS_SETTINGS]: NotificationsSettingsScreen,
      [ROUTES.ACCOUNT_SETTINGS]: AccountSettingsScreen,
      [ROUTES.CUSTOMER_SUPPORT]: CustomerSupportScreen,
      [ROUTES.CHANGE_EMAIL]: ChangeEmailScreen,
      [ROUTES.CHANGE_PASSWORD]: ChangePasswordScreen,
      [ROUTES.FAQ_CATEGORIES]: FaqCategoriesScreen,
      [ROUTES.FAQ_DETAILS]: FaqDetailsScreen,
      [ROUTES.BLOCK_LIST]: BlockListScreen,
      [ROUTES.CHAT]: DialogsMessageScreen,
      [ROUTES.PROFILE]: ProfileScreen,
    });

    AccountStack.navigationOptions = ({ navigation }) => ({
      title: Resources.strings['account-tab-bar-item-title'],
      tabBarIcon: ({ focused }) => {
        return <AccountTabBarItem focused={focused} />;
      },
      tabBarVisible: !shouldUseWebLayout() && navigation.state.index === 0,
    });

    return AccountStack;
  };

  streamingFeedStack = () => {
    const { config } = this.props;
    const followingInSeparateTab =
      config.markup['following-streams-separate-tab-enabled'];
    const streamStack = this.createStreamStack(null, followingInSeparateTab, {
      top: 'always',
      bottom: 'never',
    });

    streamStack.navigationOptions = ({ navigation }) => ({
      tabBarIcon: ({ focused }) => {
        const image = focused
          ? Resources.images.tabBarStreams()
          : Resources.images.tabBarStreamsDisabled();
        const style = [
          styles.tabBarIcon,
          focused ? styles.tabBarIconActive : styles.tabBarIconInactive,
        ];

        return <Image style={style} source={image} />;
      },
      tabBarVisible: !shouldUseWebLayout() && navigation.state.index === 0,
    });

    return streamStack;
  };

  followingStreamingFeedStack = () => {
    const streamStack = this.createStreamStack(
      CATEGORIES_PRESET.FOLLOWING,
      null,
      {
        top: 'never',
        bottom: 'never',
      },
    );

    streamStack.navigationOptions = ({ navigation }) => ({
      tabBarIcon: ({ focused }) => {
        return <FollowingTabBarItem focused={focused} />;
      },
      tabBarVisible: !shouldUseWebLayout() && navigation.state.index === 0,
    });

    return streamStack;
  };

  feedStack = () => {
    const isWebLayout = shouldUseWebLayout();
    const stack = {
      [ROUTES.FEED]: {
        screen: FeedScreen,
        navigationOptions: isWebLayout
          ? {
              cardStyleInterpolator: (...args) =>
                createCardInterpolatedStyles(args, {
                  cardStyle: styles.shortCard,
                  containerStyle: {
                    backgroundColor: '#ffffff',
                  },
                }),
            }
          : {},
      },
      [ROUTES.FILTER]: FilterScreen,
      [ROUTES.ADVANCED_FILTERS]: AdvancedFiltersScreen,
      [ROUTES.SELECTION_EDITING]: SelectionEditingScreen,
      [ROUTES.RANGE_PICKER_EDITING]: RangePickerEditingScreen,
      [ROUTES.LOCATION_EDITING]: LocationEditingScreen,
      [ROUTES.INTERESTS_EDITING]: InterestsEditingScreen,
      [ROUTES.PROFILE]: {
        screen: ProfileScreen,
        navigationOptions: isWebLayout
          ? {
              cardStyleInterpolator: (...args) =>
                createCardInterpolatedStyles(args, {
                  cardStyle: styles.shortCard,
                  containerStyle: { backgroundColor: '#f8f8f8' },
                }),
            }
          : {},
      },
      [ROUTES.WRITE_LETTER]: WriteLetterScreen,
      [ROUTES.GALLERY]: GalleryScreen,
      [ROUTES.CHAT]: DialogsMessageScreen,
      [ROUTES.START_MINGLE]: StartMingleScreen,
    };
    const FeedStack = createStackNavigator(stack);

    FeedStack.navigationOptions = ({ navigation }) => ({
      title: Resources.strings['feed-screen-title'],
      tabBarIcon: ({ focused }) => {
        const image = focused
          ? Resources.images.tabBarFeed()
          : Resources.images.tabBarFeedDisabled();
        const style = [
          styles.tabBarIcon,
          focused ? styles.tabBarIconActive : styles.tabBarIconInactive,
        ];

        return <Image style={style} source={image} />;
      },
      tabBarVisible: !shouldUseWebLayout() && navigation.state.index === 0,
    });

    return FeedStack;
  };

  startStreamTabStack = () => {
    const stack = {
      [ROUTES.EMPTY]: () => null,
    };
    const StartStreamStack = createStackNavigator(stack);

    StartStreamStack.navigationOptions = () => ({
      tabBarButtonComponent: props => {
        return (
          <View style={styles.startStreamButtonContainer}>
            <View
              pointerEvents="none"
              style={styles.startStreamButtonShadowContainer}
            >
              <Image
                style={styles.startStreamButtonShadow}
                source={Resources.images.startStreamShadow()}
              />
            </View>

            <Can do="start" of="stream">
              {status => (
                <TouchableOpacity
                  onPress={
                    status
                      ? props.onPress
                      : () =>
                          CredentialsUpdater.updateCredentials(props.onPress)
                  }
                >
                  <Image
                    style={styles.startStreamButtonImage}
                    source={Resources.images.startStream()}
                  />
                </TouchableOpacity>
              )}
            </Can>
          </View>
        );
      },
      tabBarVisible: false,
    });

    return StartStreamStack;
  };

  startStreamModalStack = () => {
    const { config } = this.props;
    const stack = {
      [ROUTES.STREAM_DESCRIPTION_PRESET]: StreamDescriptionPreset,
      [ROUTES.STREAM]: {
        screen: StreamScreen,
        navigationOptions: {
          headerShown: false,
        },
      },
      [ROUTES.STREAM_TOPICS_SELECTION]: StreamTopicsSelection,
      [ROUTES.STREAM_INFO]: StreamInfoScreen,
      [ROUTES.FOLLOW_TOP]: FollowTopScreen,
      [ROUTES.CURRENTRLY_BROADCASTING]: CurrentlyBroadcastingScreen,
    };

    return createStackNavigator(stack, {
      initialRouteName:
        config['available-stream-topics'].length > 0
          ? ROUTES.STREAM_DESCRIPTION_PRESET
          : ROUTES.STREAM,
    });
  };

  chatRequestsTab = () => {
    const ContactsStack = createStackNavigator(
      {
        [ROUTES.CHAT_REQUESTS]: ChatRequestsScreen,
        [ROUTES.CHAT]: DialogsMessageScreen,
        [ROUTES.LETTER]: ReadLetterScreen,
        [ROUTES.PROFILE]: ProfileScreen,
        [ROUTES.GALLERY]: GalleryScreen,
      },
      {
        initialRouteParams: {
          safeAreaInsets: { top: 'never', bottom: 'never' },
        },
      },
    );

    ContactsStack.navigationOptions = ({ navigation }) => ({
      tabBarIcon: ({ focused }) => {
        return <ChatRequestsTabBarItem focused={focused} />;
      },
      tabBarVisible: !shouldUseWebLayout() && navigation.state.index === 0,
    });

    return ContactsStack;
  };

  tipsTabStack = () => {
    const stack = createStackNavigator({
      [ROUTES.TIPS_LIST]: TipsListScreen,
      [ROUTES.TIP]: TipScreen,
    });

    stack.navigationOptions = ({ navigation }) => ({
      title: Resources.strings['tips-screen-title'],
      tabBarIcon: ({ focused }) => {
        const image = focused
          ? Resources.images.tabBarTips()
          : Resources.images.tabBarTipsDisabled();
        const style = [
          styles.tabBarIcon,
          focused ? styles.tabBarIconActive : styles.tabBarIconInactive,
        ];

        return <Image source={image} style={style} />;
      },
      tabBarVisible: !shouldUseWebLayout() && navigation.state.index === 0,
    });

    return stack;
  };

  purchaseTabStack = () => {
    const stack = this.paymentStack(ROUTES.PURCHASE);

    stack.navigationOptions = ({ navigation }) => ({
      tabBarButtonComponent: props => (
        <PurchaseTabBarItem {...props} isFocused={navigation.isFocused()} />
      ),
      tabBarVisible: !shouldUseWebLayout() && navigation.state.index === 0,
    });

    return stack;
  };

  inboxStack = () => {
    const stack = createStackNavigator({
      [ROUTES.INBOX]: InboxScreen,
      [ROUTES.LETTER]: ReadLetterScreen,
      [ROUTES.WRITE_LETTER]: WriteLetterScreen,
    });

    stack.navigationOptions = ({ navigation }) => ({
      title: Resources.strings['inbox-tab-bar-item-title'],
      tabBarIcon: ({ focused }) => {
        return <InboxTabBarItem focused={focused} />;
      },
      tabBarVisible: !shouldUseWebLayout() && navigation.state.index === 0,
    });

    return stack;
  };

  appStack = () => {
    const { config } = this.props;
    const stack = {};

    if (config.features['streams-enabled']) {
      stack[ROUTES.LIVE_STREAMS_DISCOVERY_TAB] = this.streamingFeedStack();

      if (config.markup['following-streams-separate-tab-enabled']) {
        stack[
          ROUTES.FOLLOWING_LIVE_STREAMS_DISCOVERY_TAB
        ] = this.followingStreamingFeedStack();
      }
      if (config.markup['bottom-tab-bar']['has-start-stream']) {
        stack[ROUTES.START_STREAM_TAB] = this.startStreamTabStack();
      }
    }
    if (config.features['feed-enabled']) {
      stack[ROUTES.FEED_TAB] = this.feedStack();
    }
    if (config.markup['chat-requests-separate-tab-enabled']) {
      stack[ROUTES.CHAT_REQUESTS_TAB] = this.chatRequestsTab();
    }
    if (config.features['chats-enabled']) {
      stack[ROUTES.CONTACTS_TAB] = this.contactsStack();
    }

    if (config.features['inbox-enabled']) {
      stack[ROUTES.INBOX_TAB] = this.inboxStack();
    }

    if (config.features['tips-enabled']) {
      stack[ROUTES.DATING_TIPS_TAB] = this.tipsTabStack();
    }

    if (config.features['combined-payment-form-enabled']) {
      stack[ROUTES.PURCHASE_TAB] = this.purchaseTabStack();
    }

    stack[ROUTES.ACCOUNT_TAB] = this.accountStack();

    return createBottomTabNavigator(stack, {
      lazy: false,
      tabBarOptions: {
        showLabel: styles.$tabBarShowLabel,
        inactiveTintColor: styles.$tabBarInactiveTintColor,
        activeTintColor: styles.$tabBarActiveTintColor,
        style: styles.tabBar,
      },
      defaultNavigationOptions: {
        tabBarOnPress: ({ navigation, defaultHandler }) => {
          if (navigation.state.key === [ROUTES.START_STREAM_TAB]) {
            navigation.navigate([ROUTES.START_STREAM_MODAL]);
          } else if (navigation.state.key === [ROUTES.ACCOUNT_TAB]) {
            navigation.navigate([ROUTES.ACCOUNT]);
            DisabledPushNotificationIndicator.shared().press();
          } else {
            defaultHandler();
          }
        },
      },
    });
  };

  authStack = () => {
    const { config } = this.props;
    const options = { defaultNavigationOptions: {} };

    if (shouldUseWebLayout()) {
      options.defaultNavigationOptions = {
        cardStyleInterpolator: (...args) =>
          createCardInterpolatedStyles(args, {
            cardStyle: { width: '50%', alignSelf: 'flex-end' },
          }),
      };
    }

    if (config.features['stepped-registration-enabled']) {
      return createStackNavigator(
        {
          [ROUTES.START]: StartScreenContainer,
          [ROUTES.LOGIN]: SignInScreen,
          [ROUTES.REGISTER]: SteppedRegistrationEmailScreen,
          [ROUTES.ENTER_PASSWORD]: SteppedRegistrationPasswordScreen,
          [ROUTES.ENTER_NAME]: SteppedRegistrationNameScreen,
          [ROUTES.ENTER_GENDER]: SteppedRegistrationGenderScreen,
          [ROUTES.ENTER_BIRTHDAY]: SteppedRegistrationBirthdayScreen,
          [ROUTES.ENTER_THUMBNAIL]: SteppedRegistrationThumbnailScreen,
          [ROUTES.TURN_ON_GEOLOCATION]: SteppedRegistrationGeolocationScreen,
          [ROUTES.TURN_ON_NOTIFICATIONS]: SteppedRegistrationNotificationsScreen,
          [ROUTES.START_SETTING_PREFERENCES]: SteppedRegistrationStartSettingPreferencesScreen,
          [ROUTES.SPECIFY_RELATIONSHIP_PRIORITY]: SteppedRegistrationRelationshipPriorityScreen,
          [ROUTES.CHOOSE_USER_GOALS]: SteppedRegistrationUserGoalsScreen,
          [ROUTES.CHOOSE_PERSONALITY]: SteppedRegistrationPersonalityScreen,
          [ROUTES.CHOOSE_BODY_TYPE]: SteppedRegistrationBodyTypeScreen,
          [ROUTES.CHOOSE_PREFERRED_AGE]: SteppedRegistrationPreferredAgeScreen,
          [ROUTES.PASSWORD_RECOVER]: PasswordRecoverScreen,
          [ROUTES.CREATE_NEW_PASSWORD]: SetNewPasswordScreen,
          [ROUTES.SEARCH_MATCHES]: SearchMatchesScreen,
          [ROUTES.SUBSCRIBE]: SubscribeScreen,
          [ROUTES.CONGRATULATION]: CongratulationScreen,
          [ROUTES.SKIP_SUBSCRIBE]: SkipSubscribeScreen,
          [ROUTES.FREE_START_MESSAGES]: FreeStartMessagesScreen,
        },
        options,
      );
    }
    options.defaultNavigationOptions.headerTitle = '';

    return createStackNavigator(
      {
        [ROUTES.START]: StartScreenContainer,
        [ROUTES.LOGIN]: SignInScreen,
        [ROUTES.REGISTER]: SignUpScreen,
        [ROUTES.PASSWORD_RECOVER]: PasswordRecoverScreen,
        [ROUTES.CREATE_NEW_PASSWORD]: SetNewPasswordScreen,
        [ROUTES.COMPLETE_REGISTRATION]: {
          screen: this.completeRegistrationStack(),
          navigationOptions: {
            headerShown: false,
            gestureEnabled: false,
          },
        },
      },
      options,
    );
  };

  rootStack = () => {
    return createSwitchNavigator(
      {
        [ROUTES.AUTH_LOADING]: AuthLoadingScreen,
        [ROUTES.AUTH]: this.authStack(),
        [ROUTES.APP]: this.appStack(),
        [ROUTES.PAYMENT_PROCESSING]: this.paymentProcessingStack(),
      },
      {
        initialRouteName: ROUTES.AUTH_LOADING,
      },
    );
  };

  authUpdateStack = () => {
    return createStackNavigator(
      {
        [ROUTES.LOGIN]: SignInScreen,
        [ROUTES.REGISTER]: SignUpUpdateScreen,
        [ROUTES.COMPLETE_REGISTRATION]: {
          screen: this.completeRegistrationStack(),
          navigationOptions: {
            headerShown: false,
            gestureEnabled: false,
          },
        },
        [ROUTES.PASSWORD_RECOVER]: PasswordRecoverScreen,
        [ROUTES.CREATE_NEW_PASSWORD]: SetNewPasswordScreen,
      },
      {
        initialRouteName: ROUTES.REGISTER,
        initialRouteParams: {
          isRoot: true,
        },
      },
    );
  };

  paymentProcessingStack = () => {
    return createStackNavigator(
      {
        [ROUTES.LOADING]: PaymentProcessing,
        [ROUTES.RESULT]: {
          screen: PaymentResult,
          navigationOptions: {
            cardStyleInterpolator: (...args) =>
              createCardInterpolatedStyles(args, {
                cardStyle: { maxWidth: '100%' },
              }),
          },
        },
        [ROUTES.THREE_DS]: ThreeDsScreen,
      },
      {
        initialRouteName: ROUTES.LOADING,
        defaultNavigationOptions: {
          headerShown: false,
          cardStyleInterpolator: (...args) =>
            createCardInterpolatedStyles(args, {
              cardStyle: styles.paymentProcessingCard,
              containerStyle: styles.paymentProcessingContainer,
            }),
        },
      },
    );
  };

  completeRegistrationStack = () => {
    const options = {
      headerTitle: '',
    };

    if (shouldUseWebLayout()) {
      options.cardStyleInterpolator = (...args) =>
        createCardInterpolatedStyles(args, {
          cardStyle: {
            maxWidth: 'auto',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            transform: 0,
            marginBottom: 0,
          },
        });
    }

    return createStackNavigator(
      {
        [ROUTES.REGISTRATION_TRAVELS_SCREEN]: RegistrationTravelsScreen,
        [ROUTES.REGISTRATION_UPDATE_USER_SCREEN]: {
          screen: RegistrationUpdateUserScreen,
          navigationOptions: {
            gestureEnabled: false,
          },
        },
        [ROUTES.BITHDAY_WITH_GENDER]: SteppedRegistrationBirthdayWithGenderScreen,
        [ROUTES.PHOTO_WITH_BIO]: SteppedRegistrationPhotoWithBioScreen,
        [ROUTES.SUBSCRIPTION]: SteppedRegistrationSubscriptionScreen,
        [ROUTES.BOOST]: SteppedRegistrationBoostScreen,
        [ROUTES.LOCATION_EDITING]: LocationEditingScreen,
        [ROUTES.REGISTRATION_GENDER_PICKING]: {
          screen: RegistrationGenderPickingScreen,
          navigationOptions: {
            gestureEnabled: false,
          },
        },
        [ROUTES.USER_GOALS_PICKING]: {
          screen: UserGoalsPickingScreen,
          navigationOptions: {
            gestureEnabled: false,
          },
        },
        [ROUTES.UPLOAD_PRIVATE_PHOTOS]: {
          screen: UploadPrivatePhotosScreen,
          navigationOptions: {
            gestureEnabled: false,
          },
        },
        [ROUTES.FREE_START_MESSAGES]: {
          screen: FreeStartMessagesScreen,
          navigationOptions: {
            gestureEnabled: false,
          },
        },
      },
      {
        initialRouteName: ROUTES.REGISTRATION_TRAVELS_SCREEN,
        defaultNavigationOptions: options,
      },
    );
  };

  paymentStack = initialRouteName => {
    const { config } = this.props;

    const Membership = config.features['stepped-registration-enabled']
      ? SubscribeScreen
      : PurchaseMembershipScreen;

    return createStackNavigator(
      {
        [ROUTES.PURCHASE]: PurchaseScreen,
        [ROUTES.CREDITS]: PurchaseCreditsScreen,
        [ROUTES.MEMBERSHIP]: Membership,
        [ROUTES.PAYMENT_METHOD_LIST]: PaymentMethodList,
        [ROUTES.CREDIT_CARD]: CreditCardPaymentScreen,
        [ROUTES.CALLS_PACK]: PurchaseCallsPackScreen,
        [ROUTES.PAY_PAL]: PayPalScreen,
        [ROUTES.SPECIAL_OFFER]: SpecialOfferScreen,
      },
      {
        initialRouteName,
      },
    );
  };

  appContainer = () => {
    const createApp =
      Platform.OS === 'web' ? createBrowserApp : createAppContainer;
    const navigationOptions = {
      gestureEnabled: false,
    };

    return createApp(
      createStackNavigator(
        {
          [ROUTES.ROOT]: {
            screen: this.rootStack(),
          },
          [ROUTES.PAYMENT_MODAL]: {
            screen: PaymentLoadingScreen,
            navigationOptions,
          },
          [ROUTES.PURCHASE_MODAL]: {
            screen: this.paymentStack(ROUTES.PURCHASE),
            navigationOptions,
          },
          [ROUTES.CREDITS_MODAL]: {
            screen: this.paymentStack(ROUTES.CREDITS),
            navigationOptions,
          },
          [ROUTES.MEMBERSHIP_MODAL]: {
            screen: this.paymentStack(ROUTES.MEMBERSHIP),
            navigationOptions,
          },
          [ROUTES.SPECIAL_OFFER_MODAL]: {
            screen: this.paymentStack(ROUTES.SPECIAL_OFFER),
            navigationOptions,
          },
          [ROUTES.CONGRATULATION_MODAL]: CongratulationScreen,
          [ROUTES.DETAILED_BENEFITS_MEMBERSHIP_MODAL]: {
            screen: this.paymentStack(ROUTES.MEMBERSHIP),
            navigationOptions,
          },
          [ROUTES.CALLS_PACK_MODAL]: {
            screen: this.paymentStack(ROUTES.CALLS_PACK),
            navigationOptions,
          },
          [ROUTES.AUTH_UPDATE_MODAL]: {
            screen: this.authUpdateStack(),
            navigationOptions,
          },
          [ROUTES.TERMS]: {
            screen: createStackNavigator({
              [ROUTES.TERMS]: TermsScreen,
            }),
            navigationOptions,
          },
          [ROUTES.PHOTO]: {
            screen: createStackNavigator({
              [ROUTES.PHOTO]: PhotoScreen,
            }),
            navigationOptions,
          },
          [ROUTES.START_STREAM_MODAL]: {
            screen: this.startStreamModalStack(),
            navigationOptions,
          },
          [ROUTES.CHEERS_MODAL]: {
            screen: createStackNavigator({
              [ROUTES.CHEERS]: CheersScreen,
            }),
            navigationOptions,
          },
          [ROUTES.MINGLE_END_MODAL]: {
            screen: createStackNavigator({
              [ROUTES.CHEERS]: MingleEndScreen,
            }),
            navigationOptions,
          },
          [ROUTES.AUDIO_VIDEO_CALL_MODAL]: {
            screen: createSwitchNavigator({
              [ROUTES.INCOMING_CALL_SWITCH]: createStackNavigator({
                [ROUTES.INCOMING_CALL]: IncomingCallScreen,
              }),
              [ROUTES.ONCOMING_CALL_SWITCH]: createStackNavigator({
                [ROUTES.ONGOING_CALL]: OngoingCallScreen,
                [ROUTES.LETTER]: ReadLetterScreen,
                [ROUTES.WRITE_LETTER]: WriteLetterScreen,
                [ROUTES.PHOTO]: PhotoScreen,
              }),
              [ROUTES.CONNECTING_TO_CALL_SWITCH]: createStackNavigator({
                [ROUTES.CONNECTING_TO_CALL]: ConnectingToCallScreen,
              }),
            }),
            navigationOptions,
          },
          [ROUTES.TURN_ON_NOTIFICATIONS_MODAL]: {
            screen: createStackNavigator({
              [ROUTES.TURN_ON_NOTIFICATIONS_SCREEN]: TurnOnNotificationsScreen,
            }),
            navigationOptions,
          },
          [ROUTES.RATE_APP_MODAL]: {
            screen: RateAppScreen,
            navigationOptions,
          },
          [ROUTES.STREAMS_TOPICS_PICKING_MODAL]: {
            screen: createStackNavigator({
              [ROUTES.STREAMS_TOPICS_PICKING]: StreamsTopicsPickingScreen,
              [ROUTES.STREAMS_TOPICS_PICKING_BONUS]: StreamsTopicsPickingBonusScreen,
            }),
            navigationOptions,
          },
          [ROUTES.ENTER_MANDATORY_THUMBNAIL]: {
            screen: MandatoryThumbnailScreen,
          },
          [ROUTES.NEWSLETTER_MODAL]: {
            screen: NewsletterScreen,
            navigationOptions,
          },
        },
        {
          initialRouteName: ROUTES.ROOT,
          mode: 'modal',
          headerMode: 'none',
        },
      ),
    );
  };

  createStreamStack(categoriesPreset, followingInSeparateTab, safeAreaInsets) {
    const stack = {
      [ROUTES.DISCOVERY]: LiveStreamsDiscoveryContainer,

      [ROUTES.STREAM]: {
        screen: StreamScreen,
        navigationOptions: {
          headerShown: false,
        },
      },
      [ROUTES.STREAM_INFO]: StreamInfoScreen,
      [ROUTES.FOLLOW_TOP]: FollowTopScreen,
    };

    return createStackNavigator(stack, {
      initialRouteParams: {
        categoriesPreset,
        followingInSeparateTab,
        safeAreaInsets,
      },
    });
  }

  render() {
    const AppContainer = this.appContainer();

    return (
      <AppContainer
        onNavigationStateChange={Navigator.onNavigationStateChange}
        ref={navigationRef => Navigator.setNavigator(navigationRef)}
      />
    );
  }
}

export default Router;
