import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        width: '100%',
        paddingVertical: 8,
        paddingHorizontal: 7,
    },

    content: {
        width: '100%',
        flexDirection: 'column',
    },

    thumbnailContainer: {
        width: '100%',
        aspectRatio: 1,
        borderRadius: 20,
        overflow: 'hidden',
    },

    thumbnail: {
        width: '100%',
        height: '100%',
    },

    infoContainer: {
        width: '100%',
        flexDirection: 'column',
        alignItems: 'stretch',
        height: 46,
    },

    name: {
        color: '$primaryTextColor',
        fontSize: 14,
        marginTop: 8,
        marginBottom: 3,
        fontFamily: '$brandFontFamily',
    },

    viewersContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },

    viewersIcon: {
        marginRight: 3,
    },

    viewersCountText: {
        color: '$infoTextColor',
        fontSize: 9,
        fontWeight: 'bold',
        fontFamily: '$brandFontFamily',
    },
});

export default styles;
