import MessagesModel, { getId, parseId } from '@sdv/domain/dialogs.messages';
import EventEmitter from 'eventemitter3';
import guid from '@sdv/commons/utils/guid';

const _bus = new EventEmitter();

function createUserMediaActions(id) {
    const { identity, attendee } = parseId(id);

    class Actions {
        constructor() {
            _bus.emit('created', id);
        }

        send = (source, tag) => (dispatch, flux) => {
            const messagesModel = flux.get(MessagesModel, id);
            messagesModel.actions.patch({
                tag: tag || `${identity}_${guid()}`,
                sender: identity,
                recipient: attendee,
                text: 'Sent a media.',
                timestamp: new Date().getTime(),
                meta: {
                    localSource: source,
                },
            });

            dispatch(null);
        };
    }

    Actions.displayName = createUserMediaActions.getDisplayName(id);

    return Actions;
}

createUserMediaActions.getDisplayName = function(id) {
    return `dialogs.usermedia.${id}`;
};

createUserMediaActions.addListener = function(...args) {
    _bus.addListener(...args);
};

createUserMediaActions.remoteListener = function(...args) {
    _bus.remoteListener(...args);
};

export default createUserMediaActions;

export const MEDIA_TYPE = Object.freeze({
    PHOTO: 0,
    VIDEO: 1,
});
