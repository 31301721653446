import React from 'react';
import PropTypes from 'prop-types';
import CredentialsUpdater from 'dating-mobile/src/authentication/utils/credentials-updater';

export default function controller(Component) {
  class Controller extends React.Component {
    static displayName =
      'user.views.restore-purchases-navigation-item.controller';

    static propTypes = {
      restorePurchases: PropTypes.func,
      isRestoring: PropTypes.bool,
    };

    onItemPressed = status => {
      const { restorePurchases } = this.props;

      if (restorePurchases) {
        if (status) {
          restorePurchases();
        } else {
          CredentialsUpdater.updateCredentials(restorePurchases);
        }
      }
    };

    render() {
      const { isRestoring } = this.props;

      return (
        <Component
          {...this.props}
          isIndicatorVisible={isRestoring}
          onItemPress={this.onItemPressed}
        />
      );
    }
  }

  return Controller;
}
