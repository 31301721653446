import React from 'react';
import withSmiles from 'dating-mobile/src/models/dialogs.smiles/controller';

export default function createController(Component) {

    class Controller extends React.Component {
        static displayName = 'dialogs.common.views.smiles-keyboard.controller';

        constructor(props) {
            super(props);

            this.state = {
                smilePacks: this.smilePacks()
            };
        }

        componentDidUpdate(prevProps) {
            if (this.props.smiles !== prevProps.smiles) {
                this.setState({
                    smilePacks: this.smilePacks()
                });
            }
        }

        smilePacks = () => {
            const animatedSmiles = [];
            const baseSmiles = [];
            for (let smile of (this.props.smiles || [])) {
                if (smile.isAnimated) {
                    animatedSmiles.push(smile);
                } else {
                    baseSmiles.push(smile);
                }
            }

            const packs = [];
            if (animatedSmiles.length) {
                packs.push({
                    name: 'animated',
                    icon: animatedSmiles[0].basename,
                    smiles: animatedSmiles
                });
            }
            if (baseSmiles.length) {
                packs.push({
                    name: 'base',
                    icon: baseSmiles[0].basename,
                    smiles: baseSmiles
                });
            }
            return packs;
        };

        render() {
            return (
                <Component
                    {...this.props}
                    {...this.state}
                />
            );
        }

    }

    return withSmiles(Controller);

}
