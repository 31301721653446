import { singleton } from '@sdv/commons/utils/singleton';
import { Observable } from 'rxjs';
import api from '@sdv/api-zill';
// @ts-ignore
import OS from '@sdv/domain/app/os';
import { Config, Endpoints } from './types/config';

const { toObservable } = require('./to-observable');

export class Api {
  static shared = singleton(() => new Api());

  public api!: api;

  private _hostname!: string;

  private _endpoints!: Endpoints;

  get hostname(): string {
    return this._hostname;
  }

  private _host!: string;

  get host(): string {
    return this._host;
  }

  get endpoints(): Endpoints {
    return this._endpoints;
  }

  private _userAgent!: string;

  get userAgent(): string {
    return this._userAgent;
  }

  configure(config: Config) {
    const {
      host,
      sharding,
      productName,
      appVersion,
      currentDevice,
      httpEngine,
      endpoints,
    } = config;

    this.api = api.create(host);
    this._hostname = host.split('//').pop() || host;
    this._host = this._hostname;
    this._endpoints = endpoints;

    const prefixes = sharding.prefixes || [];

    for (let i = 0; i < prefixes.length; i++) {
      const prefix = prefixes[i];

      if (this._hostname.startsWith(prefix)) {
        this._host = this._hostname.replace(`${prefix}.`, '');
      }
    }

    if (OS.shared().current !== 'web') {
      this._userAgent = `Dating/1.0.0 ${productName}/${appVersion} (${currentDevice}) ${httpEngine}`;
      this.api.augment('user-agent', this._userAgent);
    }
  }

  sendMessageToFavorites(text: string, userId: string): Observable<void> {
    // @ts-ignore
    return toObservable(this.api.dialogs.messages.favorite.post, userId, {
      text,
    });
  }
}
