import React from 'react';
import { View, Text, Alert, ScrollView } from 'react-native';
import PropTypes from 'prop-types';
import {
  AppleButton,
  appleAuth,
} from '@invertase/react-native-apple-authentication';
import TermsAndPrivacyText from 'dating-mobile/src/components/terms-and-privacy-text';
import { AppleAuthButton } from 'dating-mobile/src/authentication/views/apple-auth-button';
import testId from '../../../utils/test-id';
import Button from '../../../components/buttons/base';
import TextInput from '../../../components/text-input';
import Resources from '../../../resources';
import configValue from '../../../components/config-value';

import styles from './styles';

const MIN_PASSWORD_LENGTH = 4;

class SignIn extends React.Component {
  static displayName = 'identity.signin.from';

  static contextTypes = {
    flux: PropTypes.object,
  };

  static propTypes = {
    error: PropTypes.object,
    email: PropTypes.string,
    password: PropTypes.string,
    recoverPassword: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
    handlerChange: PropTypes.func.isRequired,
    passwordRecoverEnabled: PropTypes.bool,
    appleAuthEnabled: PropTypes.bool,
    googleAuthEnabled: PropTypes.bool,
    isPending: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.handleChange = {};
    this.validate = {};

    ['email', 'password'].forEach(name => {
      this.validate[name] = props.validate(name);
      this.handleChange[name] = props.handlerChange(name);
    });
  }

  shouldComponentUpdate(props) {
    return [
      'email',
      'password',
      'error',
      'passwordRecoverEnabled',
      'isPending',
    ].some(key => this.isUpdated(props, key));
  }

  getRecoverPasswordLink() {
    const { passwordRecoverEnabled, recoverPassword } = this.props;

    if (!passwordRecoverEnabled) {
      return null;
    }

    return (
      <Text
        style={styles.forgotPasswordText}
        onPress={recoverPassword}
        {...testId('Recover password button')}
      >
        {Resources.strings['sign-in-forgot-password']}
      </Text>
    );
  }

  // TODO: Add isLoading
  onAuthViaOAuthStarted = () => {};

  onAuthViaOAuthFinished = error => {
    if (error && error.code !== '1000' && error.code !== '1001') {
      Alert.alert(
        Resources.strings['default-error-message'],
        null,
        {
          text: Resources.strings.ok,
        },
        {
          text: Resources.strings.cancel,
          style: 'cancel',
        },
      );
    }
  };

  focusPasswordInput = () => {
    if (this.passwordInput?.textInput) this.passwordInput.textInput.focus();
  };

  onSubmit = () => {
    const { onSubmit, isPending } = this.props;

    if (!this.isEmpty() && !isPending) {
      onSubmit();
    }
  };

  isUpdated(props, name) {
    return props[name] !== this.props[name]; // eslint-disable-line
  }

  isEmpty() {
    const { email, password } = this.props;

    return !(email && password && password.length >= MIN_PASSWORD_LENGTH);
  }

  render() {
    const {
      error,
      appleAuthEnabled,
      googleAuthEnabled,
      email,
      password,
      isPending,
    } = this.props;
    const recoverPasswordLink = this.getRecoverPasswordLink();
    const appleAuthButtonVisible = appleAuth.isSupported && appleAuthEnabled;
    const googleAuthButtonVisible =
      googleAuthEnabled && styles.$googleSignInButtonVisible;
    const oauthButtonVisible =
      appleAuthButtonVisible || googleAuthButtonVisible;

    return (
      <ScrollView
        keyboardShouldPersistTaps="handled"
        alwaysBounceVertical={false}
        style={styles.container}
        contentContainerStyle={styles.contentContainer}
      >
        <View style={styles.inputs}>
          <Text style={styles.title} {...testId('Sign in screen title')}>
            {Resources.strings['sign-in-screen-title']}
          </Text>
          <TextInput
            style={styles.input}
            containerStyle={styles.containerInput}
            placeholder={
              Resources.strings['sign-in-screen-email-input-placeholder']
            }
            value={email}
            autoCapitalize="none"
            textContentType="username"
            returnKeyType="next"
            keyboardType="email-address"
            onChange={this.handleChange.email}
            onBlur={this.validate.email}
            error={error.email || error.server}
            onSubmitEditing={this.focusPasswordInput}
            blurOnSubmit={false}
            {...testId('Email input')}
          />

          <TextInput
            forwardRef={input => {
              this.passwordInput = input;
            }}
            style={styles.input}
            containerStyle={styles.containerInput}
            placeholder={
              Resources.strings['sign-in-screen-password-input-placeholder']
            }
            value={password}
            autoCapitalize="none"
            secureTextEntry
            textContentType="password"
            returnKeyType="done"
            onChange={this.handleChange.password}
            error={error.password || error.server}
            onBlur={this.validate.password}
            onSubmitEditing={this.onSubmit}
            {...testId('Password input')}
          />

          {!styles.$showPasswordRecoverLinkAtBottom && recoverPasswordLink}
        </View>

        <View style={styles.bottom}>
          <View style={styles.description}>
            {styles.$showPasswordRecoverLinkAtBottom && recoverPasswordLink}
          </View>
          {oauthButtonVisible && (
            <TermsAndPrivacyText
              customTextFormat={Resources.strings['policy-prefix-sign-in']}
            />
          )}
          {appleAuthButtonVisible && (
            <View style={styles.appleSignInButtonContainer}>
              <Text style={styles.oauthButtonsSeparator}>
                {Resources.strings.or}
              </Text>
              <AppleAuthButton
                cornerRadius={25}
                style={styles.appleSignInButton}
                buttonStyle={AppleButton.Style.WHITE}
                buttonType={AppleButton.Type.SIGN_IN}
                onAuthStarted={this.onAuthViaOAuthStarted}
                onAuthFinished={this.onAuthViaOAuthFinished}
              />
            </View>
          )}
          <Button
            disabled={this.isEmpty() || isPending}
            title={Resources.strings['sign-in-screen-sign-in-button-title']}
            titleStyle={oauthButtonVisible ? styles.buttonBig : null}
            onPress={this.onSubmit}
            {...testId('Sign in button')}
          />
          <View style={styles.bottomView} />
        </View>
      </ScrollView>
    );
  }
}

export default configValue(SignIn, {
  passwordRecoverEnabled: 'features.password-recover-enabled',
  appleAuthEnabled: 'features.apple-auth-enabled',
  googleAuthEnabled: 'features.google-auth-enabled',
});
