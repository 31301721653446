import url from 'url';
import { Observable, from, of } from 'rxjs';
import { pluck, switchMap, withLatestFrom, filter, map, take } from 'rxjs/operators';
import flux from '@sdv/domain/app/flux';
import User from '@sdv/domain/user/model';
import { Permissions } from '@sdv/domain/app/permissions';
import { Config } from '@sdv/domain/app/config';
import Session from '@sdv/domain/authorization/session';
import { singleton } from '@sdv/commons/utils/singleton';
import request from '@sdv/commons/utils/request';
import equal from 'fast-deep-equal';
import Geolocation from '@sdv/domain/app/geolocation';

const googleGeoCodeApi = {
    protocol: 'https',
    hostname: 'maps.googleapis.com',
    pathname: 'maps/api/geocode/json',
};

const SUCCESS_STATUS = 200;

const DEFAULT_LOCATION = {
    latitude: 'auto',
    longitude: 'auto',
};

export function isDefaultLocation(location) {
    return equal(location, DEFAULT_LOCATION);
}

export class LocationUpdater {
    static shared = singleton(() => new LocationUpdater());

    updateLocation() {
        return Config.shared()
            .locationDetectionEnabled.pipe(
                filter(Boolean),
                switchMap(() => Permissions.shared().hasLocationPermission()),
                switchMap(hasPermission => {
                    if (!hasPermission) {
                        return of(DEFAULT_LOCATION);
                    }

                    return this.getCurrentPosition({
                        maximumAge: 60 * 60 * 1000,
                        enableHighAccuracy: false,
                    }).pipe(
                        switchMap(location =>
                            this.getGeocodeCoordinates(
                                location.coords.latitude,
                                location.coords.longitude,
                                'locality|country',
                            ),
                        ),
                        pluck('0'),
                        filter(Boolean),
                        map(location => this.formatLocation(location)),
                    );
                }),
                withLatestFrom(Session.shared().userId),
                filter(([, userId]) => userId),
                map(([location, userId]) => flux.get(User, userId).actions.patch(location)),
                take(1),
            )
            .subscribe(() => {}, () => {});
    }

    getCurrentPosition(options) {
        return new Observable(subscriber => {
            Geolocation.getCurrentPosition(
                location => {
                    subscriber.next(location);
                    subscriber.complete();
                },
                error => {
                    subscriber.error(error);
                },
                options,
            );
        });
    }

    formatLocation(location) {
        const formatted = {
            latitude: `${location.geometry.location.lat}`,
            longitude: `${location.geometry.location.lng}`,
        };

        (location.address_components || []).forEach(component => {
            if (Array.isArray(component.types)) {
                if (component.types.includes('country')) {
                    formatted.country = component.short_name;
                }

                if (component.types.includes('locality')) {
                    formatted.city = component.long_name;
                }
            }
        });

        return formatted;
    }

    getGeocodeCoordinates(latitude, longitude, types) {
        return Config.shared().google.pipe(
            pluck('apiKey'),
            switchMap(key => {
                const query = {
                    key,
                    language: 'en',
                    ...{ latlng: `${latitude},${longitude}` },
                    ...(types ? { result_type: types } : {}),
                };

                return from(
                    request(
                        url.format({
                            ...googleGeoCodeApi,
                            query,
                        }),
                    ),
                );
            }),
            map(({ responseText, status }) => {
                if (status !== SUCCESS_STATUS) {
                    throw new Error(responseText);
                }

                const response = JSON.parse(responseText);

                if (response.error_message) {
                    throw new Error(response.error_message);
                }

                return response.results || [];
            }),
            take(1),
        );
    }
}
