import { combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import geolib from 'geolib';
import pick from 'lodash/pick';
import flux from '@sdv/domain/app/flux';
import User from '@sdv/domain/user/model';
import IdentityModel from '@sdv/domain/identity/model';
import { UserGeoAliasRepository } from '@sdv/domain/user.aliases/user-geo-alias-repository';
import { isDefaultLocation } from './location-updater';

export class AliasedDistanceToUser {
    constructor(userId) {
        const switchMapWithAliasedLocation = id =>
            switchMap(user =>
                UserGeoAliasRepository.shared(id).aliases.pipe(
                    map(aliases => {
                        const animatorAliases = aliases[userId];

                        return animatorAliases
                            ? {
                                  ...user,
                                  ...animatorAliases,
                              }
                            : user;
                    }),
                ),
            );

        // @ts-ignore
        this.distance = flux
            .get(IdentityModel)
            .store.rxState()
            .pipe(
                switchMap(({ id }) =>
                    combineLatest(
                        flux.get(User, id).store.rxState(),
                        flux
                            .get(User, userId)
                            .store.rxState()
                            .pipe(switchMapWithAliasedLocation(id)),
                    ),
                ),
                map(users => users.map(user => pick(user, ['distance', 'latitude', 'longitude']))),
                filter(locations =>
                    locations.every(
                        location =>
                            location.distance ||
                            (location.latitude &&
                                location.longitude &&
                                !isDefaultLocation(location)),
                    ),
                ),
                map(([current, target]) => {
                    const distance = target.distance
                        ? (Math.round(target.distance / 1000) + 5)
                        : geolib.convertUnit('km', geolib.getDistance(current, target), 0);

                    return distance === 0 ? 1 : distance;
                }),
                distinctUntilChanged(),
            );
    }
}
