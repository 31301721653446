import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({

    container: {
        paddingBottom: 12,
        paddingRight: 12
    },

    content: {
        height: 32,
        borderRadius: 16,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '$brandTextColor'
    },

    title: {
        marginLeft: 16,
        fontSize: 13,
        fontFamily: '$brandFontFamily',
        color: '$brandColor'
    },

    deleteButton: {
        width: 32,
        height: 32,
        alignItems: 'center',
        justifyContent: 'center'
    },

    deleteButtonTitle: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '$brandColor'
    }

});

export default styles;
