import EStyleSheet from 'react-native-extended-stylesheet';
import shouldUseWebLayout from 'dating-mobile/src/utils/web-layout';

const isDesktopLayout = shouldUseWebLayout();

const textStyle = {
  fontFamily: '$brandFontFamily',
  color: '$primaryPlaceholderColor',
  fontSize: 12,
};

const styles = EStyleSheet.create({
  $errorColor: '#d0021b',

  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },

  list: {
    width: '100%',
    height: isDesktopLayout ? '90vh' : '100%',
    paddingTop: 2,
  },

  listEmptyContainer: {
    marginTop: 100,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },

  contentContainer: {
    paddingHorizontal: 12,
  },

  smallBannerContainer: {
    paddingHorizontal: 12,
    top: 0,
    left: 0,
    position: 'absolute',
    zIndex: 1,
    width: '100%',
  },

  footerContainer: {
    alignItems: 'center',
    marginTop: 5,
    marginBottom: 10,
  },

  noExactMatch: {
    ...textStyle,
    fontSize: 14,
    textAlign: 'center',
    marginBottom: 16,
    paddingHorizontal: 28,
  },

  partialMatchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 24,
    marginTop: 16,
  },

  line: {
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: '$primaryBorderColor',
  },

  partialMatchText: {
    ...textStyle,
    fontSize: 14,
    paddingHorizontal: 34,
  },

  footerTitle: {
    ...textStyle,
  },

  tryAgainText: {
    ...textStyle,
    fontSize: 18,
    marginBottom: 50,
  },

  tryAgainButton: {
    backgroundColor: '$errorColor',
    maxWidth: 260,
  },

  banner: {
    marginBottom: 20,
    alignItems: 'center',
  },

  item: {
    width: '100%',
    height: isDesktopLayout ? 110 : 82,
    paddingBottom: 0,
    marginBottom: 8,
  },
});

export default styles;
