import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import StreamTutorialNotifier from '@sdv/domain/streaming/stream-using-tutorial';

export default function createStreamTutorialController(Component) {
    return class StreamTutorialController extends PureComponent {
        static displayName = 'dialogs.stream.views.stream-tutorial-overlay.controller';

        static propTypes = {
            userId: PropTypes.string.isRequired,
            currentStreamId: PropTypes.string.isRequired,
        };

        state = {
            showHint: false,
        };

        componentDidMount() {
            this.subscribe();
        }

        componentWillUnmount() {
            this.unsubscribe();
        }

        componentDidUpdate(prevProps) {
            if (this.props.userId !== prevProps.userId) {
                this.unsubscribe();
                this.subscribe();
            }
        }

        subscribe() {
            const { currentStreamId, userId } = this.props;
            if (currentStreamId && userId !== currentStreamId) {
                const streamTutorialNotifier = new StreamTutorialNotifier(this.props.userId);
                this.subscription = streamTutorialNotifier.shouldShowStreamSwipingTutorial.subscribe(
                    () => this.setState({ showHint: true }),
                );
            }
        }

        unsubscribe() {
            if (this.subscription) {
                this.subscription.unsubscribe();
                this.subscription = null;
            }
        }

        onHintPress = () => {
            this.setState({ showHint: false });
            this.unsubscribe();
        };

        render() {
            return (
                <Component
                    {...this.props}
                    showHint={this.state.showHint}
                    onHintPress={this.onHintPress}
                />
            );
        }
    };
}
