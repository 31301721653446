export enum NotificationType {
    MessageEvent = 'message-event',
    Wink = 'wink',
    Cheer = 'cheer',
    Letter = 'letter',
    MingleEnd = 'mingle-end',
    MingleActive = 'mingle-active',
    ProfileBoosted = 'profile-boosted',
    UnansweredMessage = 'unanswered-message',
    MessageBoosted = 'message-boosted',
    IncomingCall = 'incoming-call',
    StreamAnnouncementIsSent = 'stream-announcement-is-sent',
}
