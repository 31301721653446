import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'center',
        overflow: 'hidden'
    },

    bubble: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        borderRadius: 15,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.3)'
    },
});

export default styles;
