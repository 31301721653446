import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    newUserIcon: {
        width: 55,
        height: 14,
    },
});

export default styles;
