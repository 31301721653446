import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { SYSTEM_MESSAGES } from '@sdv/domain/streaming/system-messages';
import * as components from './components'
import styles from './styles';

const SYSTEM_MESSAGES_MAPPER = {
    [SYSTEM_MESSAGES.FOLLOW_BUTTON]: components.FavoriteWithThumbnailButton,
    [SYSTEM_MESSAGES.FOLLOW_MESSAGE]: components.FollowMessage,
    [SYSTEM_MESSAGES.JOIN_MESSAGE]: components.JoinStreamMessage,
};

const MESSAGES_WITH_BUBBLE = [SYSTEM_MESSAGES.FOLLOW_MESSAGE, SYSTEM_MESSAGES.JOIN_MESSAGE];

class SystemContent extends Component {
    static displayName = 'common.views.message.system';
    static propTypes = {
        content: PropTypes.object,
    };

    getProps = () => {
        const { recipient, sender, ...props } = this.props;

        return {
            ...props,
            targetId: recipient,
            userId: sender,
        }
    };

    getComponent = () => {
        const { type } = this.props.content;

        return SYSTEM_MESSAGES_MAPPER[type] || null
    };

    renderComponent = () => {
        const Component = this.getComponent();

        return Component ? <Component {...this.getProps()} /> : null
    };

    render() {
        const { content: { type } } = this.props;

        return (
            <View style={[styles.container, MESSAGES_WITH_BUBBLE.includes(type) && styles.bubble]}>
               {this.renderComponent()}
            </View>
        );
    }
}

export default SystemContent;
