import React from 'react';
import PropTypes from 'prop-types';
import { withNavigation, withNavigationFocus } from 'react-navigation';
import { StyleSheet } from 'react-native';
import Resources from 'dating-mobile/src/resources';
import * as ROUTES from 'dating-mobile/src/routing/router/constants';

import ImageButton from '../../../components/navigation/views/image-button';

import styles from './styles';

function bindNavigation(Component) {
  class Navigation extends React.Component {
    static displayName = 'payment.screens.boost.navigation';

    static propTypes = {
      navigation: PropTypes.object.isRequired,
      isFocused: PropTypes.bool,
    };

    componentDidMount() {
      this.setNavigationBarState({
        onCloseButtonPress: this.close,
      });
    }

    propOrNavigationParamWithName = name => {
      const { navigation, [name]: prop } = this.props;

      return typeof prop !== 'undefined' ? prop : navigation.getParam(name);
    };

    openLink = (title, uri) => {
      const { navigation } = this.props;

      navigation.navigate(ROUTES.TERMS, {
        title,
        uri,
      });
    };

    openPaymentTypeList = (invoices, onComplete) => {
      const { navigation } = this.props;

      navigation.navigate(ROUTES.PAYMENT_METHOD_LIST, {
        reason: this.propOrNavigationParamWithName('reason'),
        invoices,
        onComplete,
      });
    };

    close = closeModal => {
      const { navigation } = this.props;

      if (closeModal) {
        navigation.popToTop();
      }

      navigation.pop();
    };

    openMembership = () => {
      const { navigation } = this.props;

      navigation.navigate(ROUTES.MEMBERSHIP, {
        reason: this.propOrNavigationParamWithName('reason'),
        ableToNavigateToCredits: true,
        onComplete: this.propOrNavigationParamWithName('onComplete'),
      });
    };

    /**
     * @private
     * @param state
     */
    setNavigationBarState = state => {
      const { navigation } = this.props;

      navigation.setParams(state);
    };

    render() {
      const { isFocused } = this.props;

      return (
        <Component
          {...this.props}
          isFocused={isFocused}
          reason={this.propOrNavigationParamWithName('reason')}
          onComplete={this.propOrNavigationParamWithName('onComplete')}
          ableToNavigateToMembership={this.propOrNavigationParamWithName(
            'ableToNavigateToMembership',
          )}
          style={this.propOrNavigationParamWithName('style')}
          close={this.close}
          openLink={this.openLink}
          openMembership={this.openMembership}
          onPackagePress={this.openPaymentTypeList}
          setNavigationBarState={this.setNavigationBarState}
        />
      );
    }
  }

  const Result = withNavigationFocus(withNavigation(Navigation));

  Result.navigationOptions = ({ navigation, navigationOptions }) => {
    const options = {
      headerTitle: '',
      headerStyle: [navigationOptions.headerStyle, styles.header],
      headerTitleStyle: [
        navigationOptions.headerTitleStyle,
        styles.headerTitle,
      ],
    };

    if (navigation.getParam('cancelButtonVisible')) {
      options.headerLeft = () => (
        <ImageButton
          image={Resources.images.backIcon()}
          tint={StyleSheet.flatten(styles.headerTitle).color}
          onPress={navigation.getParam('onCloseButtonPress')}
        />
      );
    }

    return options;
  };

  return Result;
}

export default bindNavigation;
