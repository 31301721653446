import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    chatButton: {
        '@flavor ytm': {
            backgroundColor: '#009f83',
        },
        '@flavor udates': {
            backgroundColor: '$minorAccentColor',
        },
        '@flavor dma': {
            backgroundColor: '$minorAccentColor',
        },
        '@flavor secretly': {
            backgroundColor: '$brandColor',
        },
    },

    startChatButton: {
        backgroundColor: 'white',
        borderColor: '$accentColor',
        '@flavor lovinga': {
            borderWidth: 1,
            backgroundColor: 'transparent',
        },
    },
    startChatButtonIcon: {
        tintColor: '$accentColor',
        width: 24,
        height: 24,
        '@flavor lovinga': {
            tintColor: '$inputTextColor',
            width: 16,
            height: 16,
        },
    },
    startChatButtonTitle: {
        color: '$accentColor',
        '@flavor lovinga': {
            color: '$inputTextColor',
        },
    },
});

export default styles;
