import React from 'react';
import PropTypes from 'prop-types';
import withUser from 'dating-mobile/src/models/user/controllers';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';
import Preferences from '../../utils/preferences';
import { PREFERENCE_TYPE } from '../../utils/preferences-types';

export default function createController(Component) {
    class Controller extends React.Component {
        static displayName = 'stepped-registration.screens.find-match.controller';

        static propTypes = {
            user: PropTypes.object,
            onComplete: PropTypes.func.isRequired,
            id: PropTypes.string.isRequired,
        };

        componentDidMount() {
            const { onComplete, id } = this.props;

            setTimeout(() => {
                Preferences.shared(id)
                    .savePreference(PREFERENCE_TYPE.userGoals, true)
                    .subscribe(onComplete, onComplete);
            }, 5000);
        }

        render() {
            const { user } = this.props;
            const thumbnail = user && (user['thumbnail-pending'] || user.thumbnail);

            return <Component {...this.props} thumbnail={thumbnail} />;
        }
    }

    return withIdentityId(withUser(Controller), 'id');
}
