import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Image,
  View,
  Keyboard,
  TouchableOpacity,
  Platform,
  LayoutAnimation,
} from 'react-native';
import { SafeAreaView } from 'react-navigation';
import KeyboardSpacer from 'react-native-keyboard-spacer';
import { AutoGrowingTextInput } from 'react-native-autogrow-textinput';
import AnimatedTypeItText from 'dating-mobile/src/components/animated-typeit-text';
import withAutoScale from 'dating-mobile/src/components/auto-scale-image';
import WriteLetterButton, {
  WRITE_LETTER_BUTTON_TYPE,
} from 'dating-mobile/src/inbox/views/write-letter-button';
import ResourceKeyboard from '../resource-keyboard';
import styles from './styles';
import UserRoleCan from '../../../../models/user.ability/controller';
import CredentialsUpdater from '../../../../authentication/utils/credentials-updater';
import Resources from '../../../../resources';
import testId from '../../../../utils/test-id';
import { KEYBOARD_TYPES } from '../resource-keyboard/view';

const ScaledImage = withAutoScale(Image);

const KEYBOARD_SHOWN_EVENT_NAME =
  Platform.OS === 'android' ? 'keyboardDidShow' : 'keyboardWillShow';
const KEYBOARD_HIDDEN_EVENT_NAME =
  Platform.OS === 'android' ? 'keyboardDidHide' : 'keyboardWillHide';

export const RESOURCE_KEYBOARD_TYPE = Object.freeze({
  CHEERS: 1,
  EMOJI: 2,
});

export const KEYBOARD_TYPE = Object.freeze(
  Object.assign(
    {
      TEXT: 0,
    },
    RESOURCE_KEYBOARD_TYPE,
  ),
);

export const FORM_INPUT_STYLING_MODE = Object.freeze({
  DIALOG: 'dialog', // default
  STREAM: 'stream',
});

export default class FormView extends React.PureComponent {
  static displayName = 'dialogs.common.views.form.view';

  textInputRef = React.createRef();

  static propTypes = {
    inputStylingMode: PropTypes.oneOf([
      FORM_INPUT_STYLING_MODE.STREAM,
      FORM_INPUT_STYLING_MODE.DIALOG,
    ]),
    autoFocus: PropTypes.bool,
    mediaEnabled: PropTypes.bool,
    giftsEnabled: PropTypes.bool,
    stickersEnabled: PropTypes.bool,
    smilesEnabled: PropTypes.bool,
    inboxEnabled: PropTypes.bool,
    controlSafeArea: PropTypes.bool,
    useSeparateButtonForMediaAttach: PropTypes.bool,
    onKeyboardChange: PropTypes.func,
    onTextChange: PropTypes.func,
    onHeightChange: PropTypes.func,
    onSend: PropTypes.func,
    forbidMediaFromCamera: PropTypes.bool,
    InputExtraControl: PropTypes.elementType,
    textMessageMaxLength: PropTypes.number,
    bus: PropTypes.object,
    attendee: PropTypes.string,
    textInputPlaceholderSelector: PropTypes.func,
    freeCheersAvaliable: PropTypes.bool,
    customStyles: PropTypes.shape({
      content: PropTypes.object,
      inputContainer: PropTypes.object,
      sendButton: PropTypes.object,
      sendButtonIcon: PropTypes.object,
      placeholderContainer: PropTypes.object,
      placeholder: PropTypes.object,
      input: PropTypes.object,
    }),
  };

  static contextTypes = {
    flux: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      text: '',
      keyboardVisible: false,
      resourceKeyboard: null,
      pendingResourceKeyboard: null,
    };
  }

  componentDidMount() {
    const { bus } = this.props;

    Keyboard.addListener(KEYBOARD_SHOWN_EVENT_NAME, this.onKeyboardShown);
    Keyboard.addListener(KEYBOARD_HIDDEN_EVENT_NAME, this.onKeyboardHidden);

    if (bus) {
      bus.addListener(
        'command.messages.form.dismiss-keyboard',
        this.dismissKeyboard,
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { bus } = this.props;

    if (bus !== prevProps.bus) {
      if (prevProps.bus) {
        prevProps.bus.removeListener(
          'command.messages.form.dismiss-keyboard',
          this.dismissKeyboard,
        );
      }
      if (bus) {
        bus.addListener(
          'command.messages.form.dismiss-keyboard',
          this.dismissKeyboard,
        );
      }
    }
  }

  componentWillUnmount() {
    const { bus } = this.props;

    Keyboard.removeListener(KEYBOARD_SHOWN_EVENT_NAME, this.onKeyboardShown);
    Keyboard.removeListener(KEYBOARD_HIDDEN_EVENT_NAME, this.onKeyboardHidden);

    if (bus) {
      bus.removeListener(
        'command.messages.form.dismiss-keyboard',
        this.dismissKeyboard,
      );
    }
  }

  onViewLayout = event => {
    const { resourceKeyboard, keyboardVisible } = this.state;
    const { onHeightChange } = this.props;
    const { height } = event.nativeEvent.layout;

    if (onHeightChange) {
      onHeightChange({
        height,
        keyboardVisible: resourceKeyboard !== null || keyboardVisible,
      });
    }
  };

  onResourceKeyboardTypeSelect = type => {
    this.setState({
      resourceKeyboardType: type,
    });
  };

  getInputPlaceholder() {
    const { textInputPlaceholderSelector } = this.props;
    const { resourceKeyboard, resourceKeyboardType } = this.state;

    if (!textInputPlaceholderSelector) {
      return Resources.strings['default-dialogs-input-form-placeholder'];
    }

    let itemType = 'message';

    if (resourceKeyboard === RESOURCE_KEYBOARD_TYPE.EMOJI) {
      switch (resourceKeyboardType) {
        case KEYBOARD_TYPES.STICKERS:
          itemType = 'sticker';
          break;
        case KEYBOARD_TYPES.SMILES:
          itemType = 'smile';
          break;
      }
    }

    const placeholder = textInputPlaceholderSelector(itemType);

    return (
      placeholder || Resources.strings['default-dialogs-input-form-placeholder']
    );
  }

  updateState = state => {
    const { resourceKeyboard } = this.state;

    if (
      (!resourceKeyboard && state.resourceKeyboard) ||
      (Platform.OS === 'ios' && (resourceKeyboard || !state.resourceKeyboard))
    ) {
      const animation = LayoutAnimation.create(
        150,
        LayoutAnimation.Types.linear,
        LayoutAnimation.Properties.opacity,
      );

      LayoutAnimation.configureNext(animation);
    }

    this.setState(state, () => {
      const {
        keyboardVisible,
        pendingResourceKeyboard,
        resourceKeyboard: resourceKeyboardNew,
      } = this.state;
      const { onKeyboardChange } = this.props;
      const keyboardType = keyboardVisible
        ? KEYBOARD_TYPE.TEXT
        : resourceKeyboardNew || pendingResourceKeyboard;

      if (onKeyboardChange) {
        onKeyboardChange(keyboardType);
      }
    });
  };

  /* public */
  showResourceKeyboard = keyboard => {
    const { keyboardVisible } = this.state;

    if (!keyboardVisible) {
      this.updateState({
        resourceKeyboard: keyboard,
      });
    } else {
      this.updateState({
        pendingResourceKeyboard: keyboard,
      });
      Keyboard.dismiss();
    }
  };

  /* public */
  dismissKeyboard = () => {
    this.updateState({
      resourceKeyboard: null,
      pendingResourceKeyboard: null,
    });
    Keyboard.dismiss();
  };

  onKeyboardShown = () => {
    this.updateState({
      keyboardVisible: true,
      resourceKeyboard: null,
      pendingResourceKeyboard: null,
    });
  };

  onKeyboardHidden = () => {
    const { pendingResourceKeyboard } = this.state;

    this.updateState({
      keyboardVisible: false,
      pendingResourceKeyboard: null,
      resourceKeyboard: pendingResourceKeyboard,
    });
  };

  trySendMessage = status => () => {
    const send = () => {
      if (!this.canSend()) {
        return;
      }

      setTimeout(() => {
        const { bus, onSend } = this.props;
        const { text } = this.state;

        if (!this.canSend()) {
          return;
        }

        if (onSend) {
          onSend(text);
        }

        bus?.emit('command.messages.form.send-text', text);

        this.updateState({
          text: '',
        });
      }, 100);
    };

    return status ? send() : CredentialsUpdater.updateCredentials(send);
  };

  trySendCheer = status => cheer => {
    const send = () => {
      const { bus } = this.props;

      bus?.emit('command.messages.form.send-cheer', cheer);
    };

    return status ? send() : CredentialsUpdater.updateCredentials(send);
  };

  trySendSticker = status => sticker => {
    const send = () => {
      const { bus, onSend } = this.props;

      if (onSend) {
        onSend(sticker);
      }

      bus?.emit('command.messages.form.send-sticker', {
        basename: sticker,
      });
    };

    return status ? send() : CredentialsUpdater.updateCredentials(send);
  };

  addSmileToText = smile => {
    const { text } = this.state;
    let newText = `${smile.pattern} `;

    if (text.trim() && !text.endsWith(' ')) {
      newText = ` ${newText}`;
    }

    this.setState({
      text: text + newText,
    });
  };

  trySendMedia = status => () => {
    const send = () => {
      const { bus } = this.props;

      bus?.emit('command.messages.form.send-media');
    };

    return status ? send() : CredentialsUpdater.updateCredentials(send);
  };

  trySendNewMedia = status => () => {
    const send = () => {
      const { bus } = this.props;

      bus?.emit('command.messages.form.send-new-media');
    };

    return status ? send() : CredentialsUpdater.updateCredentials(send);
  };

  trySendLibraryMedia = status => () => {
    const send = () => {
      const { bus } = this.props;

      bus?.emit('command.messages.form.send-library-media');
    };

    return status ? send() : CredentialsUpdater.updateCredentials(send);
  };

  onCheersButtonPressed = () => {
    const { pendingResourceKeyboard, resourceKeyboard } = this.state;

    if (
      pendingResourceKeyboard === RESOURCE_KEYBOARD_TYPE.CHEERS ||
      resourceKeyboard === RESOURCE_KEYBOARD_TYPE.CHEERS
    ) {
      const inputRef =
        Platform.OS === 'web'
          ? this.textInputRef.current
          : this.textInputRef.current.getRef();

      inputRef.focus();
    } else {
      this.showResourceKeyboard(RESOURCE_KEYBOARD_TYPE.CHEERS);
    }
  };

  onEmojiButtonPressed = () => {
    const { pendingResourceKeyboard, resourceKeyboard } = this.state;

    if (
      pendingResourceKeyboard === RESOURCE_KEYBOARD_TYPE.EMOJI ||
      resourceKeyboard === RESOURCE_KEYBOARD_TYPE.EMOJI
    ) {
      const inputRef =
        Platform.OS === 'web'
          ? this.textInputRef.current
          : this.textInputRef.current.getRef();

      inputRef.focus();
    } else {
      this.showResourceKeyboard(RESOURCE_KEYBOARD_TYPE.EMOJI);
    }
  };

  onTextChanged = text => {
    const { onTextChange } = this.props;

    if (text && text[text.length - 1] === '\n') {
      this.updateState({
        text: text.substring(0, text.length - 1),
      });
      this.trySendMessage(true)();
    } else {
      this.updateState({
        text,
      });
    }

    if (onTextChange) {
      onTextChange();
    }
  };

  canSend() {
    const { text } = this.state;

    return !!text.trim();
  }

  renderAttachMediaButtons() {
    const {
      forbidMediaFromCamera,
      useSeparateButtonForMediaAttach,
    } = this.props;

    return (
      <UserRoleCan do="create" of="message">
        {status => [
          !forbidMediaFromCamera ? (
            <TouchableOpacity
              key="send-media-button"
              style={styles.actionButton}
              onPress={
                useSeparateButtonForMediaAttach
                  ? this.trySendNewMedia(status)
                  : this.trySendMedia(status)
              }
              {...testId('Send media button')}
            >
              <ScaledImage
                style={styles.cameraIcon}
                source={Resources.images.cameraIcon()}
              />
            </TouchableOpacity>
          ) : null,
          useSeparateButtonForMediaAttach || !!forbidMediaFromCamera ? (
            <TouchableOpacity
              key="send-gallery-button"
              style={styles.actionButton}
              onPress={this.trySendLibraryMedia(status)}
              {...testId('Send gallery button')}
            >
              <ScaledImage source={Resources.images.galleryIcon()} />
            </TouchableOpacity>
          ) : null,
        ]}
      </UserRoleCan>
    );
  }

  render() {
    const {
      InputExtraControl,
      textMessageMaxLength,
      freeCheersAvaliable,
      attendee,
      textInputPlaceholderSelector,
      autoFocus,
      controlSafeArea,
      stickersEnabled,
      smilesEnabled,
      mediaEnabled,
      giftsEnabled,
      inboxEnabled,
      customStyles = {},
      inputStylingMode = FORM_INPUT_STYLING_MODE.DIALOG,
    } = this.props;
    const { resourceKeyboard, keyboardVisible, text } = this.state;

    const resourceKeyboardVisible = Object.values(
      RESOURCE_KEYBOARD_TYPE,
    ).includes(resourceKeyboard);

    const showKeyboard = resourceKeyboardVisible || keyboardVisible;

    const canSend = this.canSend();

    const sendIcon = canSend
      ? Resources.images.sendIcon()
      : Resources.images.sendDisabledIcon();

    const sendButtonIconStyle = [
      [styles.sendButtonIcon, customStyles.sendButtonIcon],
      canSend ? styles.sendButtonIconActive : {},
    ];

    const giftsKeyboardActive =
      resourceKeyboard === RESOURCE_KEYBOARD_TYPE.CHEERS;

    const giftsButtonIcon = giftsKeyboardActive
      ? Resources.images.giftOpenedIcon()
      : Resources.images.sendGiftIcon();

    const giftsButtonIconStyle = giftsKeyboardActive
      ? styles.giftsButtonIconActive
      : styles.giftsButtonIcon;

    const emojiKeyboardActive =
      resourceKeyboard === RESOURCE_KEYBOARD_TYPE.EMOJI;

    const emojiButtonIconStyle = emojiKeyboardActive
      ? styles.emojiButtonIconActive
      : styles.emojiButtonIcon;

    const resourceKeyboardStyle =
      emojiKeyboardActive || giftsKeyboardActive
        ? {}
        : { height: 0, overflow: 'hidden' };

    let contentStyle;
    let inputContainerStyle;

    switch (inputStylingMode) {
      case FORM_INPUT_STYLING_MODE.STREAM:
        contentStyle = [styles.content, styles.contentInStream];
        inputContainerStyle = [
          styles.inputContainer,
          styles.inputContainerInStream,
        ];
        break;
      case FORM_INPUT_STYLING_MODE.DIALOG:
        contentStyle = [styles.content, styles.contentInDialog];
        inputContainerStyle = [
          styles.inputContainer,
          styles.inputContainerInDialog,
        ];
        break;
      default:
        contentStyle = [styles.content];
        inputContainerStyle = [styles.inputContainer];
        break;
    }

    const inputStyle = [
      styles.input,
      mediaEnabled ? styles.inputWithMedia : {},
      inputStylingMode === FORM_INPUT_STYLING_MODE.STREAM
        ? styles.inputWithShadow
        : {},
    ];

    return (
      <View style={styles.container} onLayout={this.onViewLayout}>
        {showKeyboard && <View style={styles.activeBackground} />}
        <View style={styles.inputAndExtraControlContainer}>
          <View style={[contentStyle, customStyles.content]}>
            <View style={styles.actionButtonsContainer}>
              {!!mediaEnabled && this.renderAttachMediaButtons()}
              {!!giftsEnabled && (
                <TouchableOpacity
                  style={styles.actionButton}
                  onPress={this.onCheersButtonPressed}
                  {...testId('Send gift button')}
                >
                  <ScaledImage
                    source={giftsButtonIcon}
                    style={giftsButtonIconStyle}
                  />
                </TouchableOpacity>
              )}
              {!!inboxEnabled && (
                <WriteLetterButton
                  style={styles.writeLetterButton}
                  type={WRITE_LETTER_BUTTON_TYPE.ICON}
                  userId={attendee}
                />
              )}
            </View>
            <View style={[inputContainerStyle, customStyles.inputContainer]}>
              <UserRoleCan do="create" of="message">
                {status => (
                  <Fragment>
                    <View
                      style={[
                        styles.placeholderContainer,
                        customStyles.placeholderContainer,
                      ]}
                    >
                      {text.length === 0 && (
                        <AnimatedTypeItText
                          isAnimate={!textInputPlaceholderSelector}
                          styles={[
                            styles.placeholder,
                            customStyles.placeholder,
                          ]}
                          text={this.getInputPlaceholder()}
                        />
                      )}
                    </View>

                    <AutoGrowingTextInput
                      maxLength={textMessageMaxLength}
                      ref={this.textInputRef}
                      autoFocus={autoFocus}
                      selectionColor={styles.$inputSelectionColor}
                      style={[inputStyle, customStyles.input]}
                      onChangeText={this.onTextChanged}
                      onSubmitEditing={this.trySendMessage(status)}
                      value={text}
                      keyboardAppearance={styles.$inputKeyboardAppearance}
                      returnKeyType="send"
                      {...testId('Text input')}
                    />
                  </Fragment>
                )}
              </UserRoleCan>
              {!!(stickersEnabled || smilesEnabled) && (
                <TouchableOpacity
                  style={styles.actionButton}
                  onPress={this.onEmojiButtonPressed}
                  {...testId('Send emoji button')}
                >
                  <ScaledImage
                    style={emojiButtonIconStyle}
                    source={Resources.images.sendSmileIcon()}
                  />
                </TouchableOpacity>
              )}
            </View>

            <UserRoleCan do="create" of="message">
              {status => (
                <TouchableOpacity
                  style={[styles.sendButton, customStyles.sendButton]}
                  onPress={this.trySendMessage(status)}
                  disabled={!canSend}
                  {...testId('Send button')}
                >
                  <ScaledImage source={sendIcon} style={sendButtonIconStyle} />
                </TouchableOpacity>
              )}
            </UserRoleCan>
          </View>
          {InputExtraControl && (
            <View style={styles.extraControlContainer}>
              <InputExtraControl />
            </View>
          )}
        </View>

        {resourceKeyboardVisible && (
          <View style={styles.resourceKeyboardSeparator} />
        )}
        <View style={resourceKeyboardStyle}>
          <UserRoleCan do="create" of="message">
            {status => (
              <ResourceKeyboard
                smilesEnabled={!giftsKeyboardActive && smilesEnabled}
                stickersEnabled={!giftsKeyboardActive && stickersEnabled}
                giftsEnabled={giftsKeyboardActive}
                onStickerSelect={this.trySendSticker(status)}
                onSmileSelect={this.addSmileToText}
                onGiftSelect={this.trySendCheer(status)}
                onTypeSelect={this.onResourceKeyboardTypeSelect}
                freeCheersAvaliable={freeCheersAvaliable}
              />
            )}
          </UserRoleCan>
        </View>
        {Platform.OS === 'ios' && <KeyboardSpacer />}
        {Platform.OS === 'ios' && !!controlSafeArea && !showKeyboard && (
          <SafeAreaView forceInset={{ bottom: 'always', top: 'never' }} />
        )}
      </View>
    );
  }
}
