import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({

    photo: {
        width: '100%',
        height: '100%',
        borderRadius: 64
    }

});

export default styles;
