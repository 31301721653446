import React from 'react';
import { Image, Text, View } from 'react-native';
import Resources from 'dating-mobile/src/resources';
import styles from './styles';
import WriteLetterButton, { WRITE_LETTER_BUTTON_TYPE } from "dating-mobile/src/inbox/views/write-letter-button";
import PropTypes from 'prop-types';

export default class SendEmailView extends React.Component {
    static displayName = 'user.profile.views.profile-description.send-email.view';
    static propTypes = {
        userId: PropTypes.string
    };

    render() {
        return (
            <View style={styles.container}>
                <Image style={styles.image} source={Resources.images.sendEmailBackground()}/>
                <Text style={styles.title}>
                    {Resources.strings['profile-screen-send-email-description']}
                </Text>
                <View style={styles.writeLetterButton}>
                    <WriteLetterButton
                        type={WRITE_LETTER_BUTTON_TYPE.LONG}
                        userId={this.props.userId}
                    />
                </View>
            </View>
        );
    }
}
