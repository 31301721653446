import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import MembershipItem from './item';
import styles from './styles';

class MembershipsList extends React.Component {
    static displayName = 'payment.memberships-list';

    static propTypes = {
        products: PropTypes.array,
        topProductDiscount: PropTypes.any,
        onProductSelect: PropTypes.func,
        isLoading: PropTypes.bool,
    };

    renderItems = () => {
        const products = this.props.products || [];
        const result = [];

        for (let index = 0; index < products.length; index++) {
            const product = products[index];

            if (index > 0) {
                result.push(<View key={`separator-${index}`} style={styles.itemSeparator} />);
            }
            const discount = index === 0 ? this.props.topProductDiscount : undefined;

            result.push(
                <MembershipItem
                    key={`membership-item-${index}`}
                    product={product}
                    discount={discount}
                    onPress={this.props.onProductSelect}
                    isDisabled={this.props.isLoading}
                />,
            );
        }

        return result;
    };

    render() {
        return <View style={styles.container}>{this.renderItems()}</View>;
    }
}

export default MembershipsList;
