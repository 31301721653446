import Actions from './actions';

class ProductConfigStore {
    static displayName = 'config/product';

    constructor(flux) {
        const actions = flux.getActions(Actions);
        this.state = {};
        this.bindAction(actions.set, this.set);
        this.bindAction(actions.setHost, this.setHost);
        this.bindAction(actions.setEndpoint, this.setEndpoint);
        this.bindAction(actions.setFeature, this.setFeature);
    }

    set = (data) => {
        this.setState({ ...data });
    };

    setHost = (host) => {
        this.setState({
            host: host
        });
    };

    setEndpoint = (payload) => {
        if (!payload) {
            return;
        }

        this.setState({
            endpoints: {
                ...(this.state.endpoints || {}),
                [payload.key]: payload.value
            }
        });
    };

    setFeature = (payload) => {
        if (!payload) {
            return;
        }

        this.setState({
            features: {
                ...(this.state.features || {}),
                [payload.key]: payload.value
            }
        });
    };
}

export default ProductConfigStore;
