import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { View, Text, TouchableOpacity, ImageBackground } from 'react-native';

import Resources from 'dating-mobile/src/resources';
import { AnimatedAppearing } from 'dating-mobile/src/components/animated-appearing';
import withAspectRatio from 'dating-mobile/src/components/aspect-ratio';

import styles from './styles';

const AspectRatioImage = withAspectRatio(ImageBackground);

export default class PremiumSubscription70Banner extends PureComponent {
  static displayName =
    'components.purchase-promotion-banner.premium-subscription-70.view';

  static propTypes = {
    onPress: PropTypes.func,
    style: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    image: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    placeholder: PropTypes.object,
    hidden: PropTypes.bool,
    introductoryInvoice: PropTypes.object,
  };

  render() {
    const {
      style,
      onPress,
      image,
      hidden,
      placeholder,
      introductoryInvoice,
    } = this.props;

    const showPlaceholder = hidden;

    return (
      <View>
        <AnimatedAppearing
          useNativeDriver
          animationDuration={500}
          appearingAnimationName="fadeIn"
          disappearingAnimationName="fadeOut"
          isVisible={showPlaceholder}
          style={{ position: showPlaceholder ? 'relative' : 'absolute' }}
        >
          {placeholder}
        </AnimatedAppearing>
        <AnimatedAppearing
          useNativeDriver
          animationDuration={500}
          appearingAnimationName="fadeIn"
          disappearingAnimationName="fadeOut"
          isVisible={!showPlaceholder}
          style={{ position: !showPlaceholder ? 'relative' : 'absolute' }}
        >
          <View style={[styles.container, style]}>
            <TouchableOpacity style={{ width: '100%' }} onPress={onPress}>
              <AspectRatioImage
                style={styles.imageContainer}
                imageStyle={styles.image}
                source={image}
              >
                <View style={styles.textContainer}>
                  <Text style={styles.title}>
                    {
                      Resources.strings[
                        'purchase-promotion-banner-premium-subscription-70-title'
                      ]
                    }
                  </Text>
                  <Text style={styles.subtitle}>
                    {Resources.strings.formatString(
                      Resources.strings[
                        'purchase-promotion-banner-premium-subscription-70-subtitle'
                      ],
                      introductoryInvoice?.discount || '',
                    )}
                  </Text>
                  <Text style={styles.description}>
                    {
                      Resources.strings[
                        'purchase-promotion-banner-premium-subscription-70-description'
                      ]
                    }
                  </Text>
                </View>
              </AspectRatioImage>
            </TouchableOpacity>
          </View>
        </AnimatedAppearing>
      </View>
    );
  }
}
