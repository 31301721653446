import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $statusBarStyle: '$brandStatusBarStyle',
    $statusBarBackgroundColor: '$brandColor',

    header: {
        backgroundColor: '$brandColor',
        borderBottomColor: '$brandColor',
        shadowColor: '$brandColor',
        shadowRadius: 0,
        shadowOpacity: 0,
        elevation: 0,
        shadowOffset: {
            height: 0,
        }
    },
    headerTextColor: {
        color: '$brandTextColor',
    },
    safeArea: {
        flex: 1,
        backgroundColor: '$brandColor',
    },
    scroll: {
        flexGrow: 1,
    },
    scrollContent: {
        flexGrow: 1
    },
    registrationContainer: {
        flex: 1,
        alignItems: 'center',
        paddingHorizontal: 30,
        paddingBottom: 30,
        '@flavor lovinga': {
            paddingHorizontal: 40,
            paddingBottom: 64
        }
    },
    profileContainer: {
        paddingHorizontal: 20,
        '@flavor lovinga': {
            paddingHorizontal: 0,
            paddingLeft: 16
        }
    },
    title: {
        marginTop: 20,
        marginBottom: 20,
        fontSize: '$screenTitleSize',
        textAlign: '$brandTitleAlign',
        color: '$brandTextColor',
        fontWeight: 'bold',
        fontFamily: '$brandFontFamily',
        width: '100%'
    },
    subTitle: {
        marginTop: 20,
        marginBottom: 20,
        fontSize: 18,
        textAlign: '$brandTitleAlign',
        color: '$brandTextColor',
        fontWeight: 'bold',
        fontFamily: '$brandFontFamily',
    },
    profileInputContainer: {
        marginHorizontal: -20,
        '@flavor lovinga': {
            marginHorizontal: 0,
            marginLeft: -16
        }
    },
    registrationInputContainer: {
        flex: 1,
        minHeight: 300,
        marginBottom: 20,
        marginHorizontal: -30,
        '@flavor lovinga': {
            marginHorizontal: -40
        }
    },
    profileInput: {
        paddingHorizontal: 20,
        '@flavor lovinga': {
            paddingHorizontal: 0,
            paddingLeft: 16
        }
    },
    registrationInput: {
        paddingHorizontal: 30
    },
    inputText: {
        fontSize: 17,
        borderBottomColor: '$brandBorderColor',
        borderBottomWidth: 1,
        fontFamily: '$brandFontFamily',
        fontWeight: '600',
        color: '$brandTextColor',
    },
    item: {
        paddingVertical: 5,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    list: {
        fontSize: 16,
        color: '$brandTextColor',
        flex: 0.8
    },
    image: {
        width: 10,
        height: 10
    },
});

export default styles;
