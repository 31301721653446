import React from 'react';
import {Image, Text, TouchableOpacity, View} from 'react-native';
import PropTypes from 'prop-types';
import Resources from 'dating-mobile/src/resources';
import styles from './styles';
import Button from 'dating-mobile/src/components/buttons/base';

export default class TurnOnNotificationsView extends React.Component {
    static displayName = 'notifications.views.turn-on-push-notifications.view';
    static propTypes = {
        notNowPress: PropTypes.func,
        turnOnNotificationPress: PropTypes.func
    };

    notNowPressed = () => {
        this.props.notNowPress && this.props.notNowPress();
    };

    turnOnNotificationPressed = () => {
        this.props.turnOnNotificationPress && this.props.turnOnNotificationPress();
    };

    render() {
        return (
            <View style={styles.container}>
                <Image
                    style={styles.background}
                    resizeMode={'cover'}
                    source={Resources.images.userRequestBackground()}
                />
                <View style={styles.collageContainer}>
                    <Image style={styles.avatarsCollage} source={Resources.images.avatarsCollage()} />
                </View>
                <View style={styles.itemsContainer}>
                    <Text style={styles.descriptionText}>
                        {Resources.strings['turn-on-notifications-screen-description-text']}
                    </Text>
                    <Button
                        onPress={this.turnOnNotificationPressed}
                        title={Resources.strings['turn-on-notifications-screen-turn-on-button-title']}
                    />
                    <TouchableOpacity onPress={this.notNowPressed}>
                        <Text style={styles.notNowButtonText}>
                            {Resources.strings['turn-on-notifications-screen-not-now-button-title']}
                        </Text>
                    </TouchableOpacity>
                </View>
            </View>
        );
    }
}
