import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import { View } from 'react-native';

export default class UnreadLettersIndicator extends React.PureComponent {
    static displayName = 'inbox.views.unread-letters-indicator.view';
    static propTypes = {
        showIndicator: PropTypes.bool,
        style: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.object,
            PropTypes.array
        ])
    };

    render() {
        return !!this.props.showIndicator && <View style={[styles.indicator, this.props.style]} />;
    }
}
