import { of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { singleton } from '@sdv/commons/utils/singleton';
import { Config } from '@sdv/domain/app/config';
import { Stock } from '@sdv/domain/payment/stock';
import Session from '@sdv/domain/authorization/session';
import { FaqRepository } from './faq-repository';

export class LocalizedFaqRepository {
  static shared = singleton(() => new LocalizedFaqRepository());

  static fallbackPrice = '$5.99';

  constructor() {
    this.subscriptionPrice = Session.shared().userId.pipe(
      filter(Boolean),
      switchMap(id => Stock.shared(id).preloadedPackagesInStock),
      map(
        packages =>
          packages.filter(product => product[0].containsMembership)?.[0]?.[0]
            ?.price?.localizedIncludingTax ||
          LocalizedFaqRepository.fallbackPrice,
      ),
    );
  }

  getTopicsPreview() {
    return Config.shared().userLanguage.pipe(
      switchMap(lang => FaqRepository.shared().getTopicsPreview(lang)),
    );
  }

  getTopic(id) {
    return Config.shared().userLanguage.pipe(
      switchMap(lang => FaqRepository.shared().getTopic(id, lang)),
      switchMap(topic => {
        if (topic.id === '5') {
          return this.subscriptionPrice.pipe(
            map(subscriptionPrice => ({
              ...topic,
              localizedSource: {
                ...topic.localizedSource,
                html: topic.localizedSource.html.replace(
                  new RegExp('{subscriptionPrice}', 'g'),
                  subscriptionPrice,
                ),
              },
            })),
          );
        }

        return of(topic);
      }),
    );
  }
}
