import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $safeAreaInsets: {
        top: 'never',
        bottom: 'always',
    },

    header: {
        '@flavor secretly': {
            backgroundColor: '$primaryBackgroundColor',
            borderBottomColor: '$primaryBackgroundColor',
            shadowColor: '$primaryBackgroundColor',
        },
    },

    headerTitle: {
        '@flavor secretly': {
            color: '$primaryTextColor',
        },
    },

    container: {
        flex: 1,
        backgroundColor: '$brandColor',
        '@flavor secretly': {
            backgroundColor: '$primaryBackgroundColor',
        },
    },

    scroll: {
        flexGrow: 1,
    },
});

export default styles;
