import MessagesModel from '@sdv/domain/dialogs.messages';
import { MessagesActionsError as AdapterActionsError, MESSAGE_TYPES } from '../../../common.messages/model';
import { MessagesActionsError } from '@sdv/domain/dialogs.messages/actions';
import LetterOutboxModel from "@sdv/domain/dialogs.mailbox/outbox";

function createMessagesActions(id) {

    class MessagesActions {
        get = (count = 30, skip = 0) => (dispatch, flux) => {
            let model = flux.get(MessagesModel, id);
            model.actions.get(count, skip, function (error) {
                if (error instanceof MessagesActionsError) {
                    switch (error.code) {
                        case MessagesActionsError.CODES.PARTIAL_LOAD:
                            dispatch(null, new AdapterActionsError(AdapterActionsError.CODES.PARTIAL_LOAD));
                            return;
                    }
                }
                dispatch(null, error);
            });
        };

        send = (messageType, content, tag, instant) => (dispatch, flux) => {
            let model = flux.get(MessagesModel, id);
            switch (messageType) {
                case MESSAGE_TYPES.TEXT:
                    model.actions.send(content, undefined, tag, instant, (error) => { dispatch(null, error) });
                    break;
                case MESSAGE_TYPES.PHOTO:
                    model.actions.send(undefined, `photo:///${content.basename}`, tag, instant, (error) => { dispatch(null, error) });
                    break;
                case MESSAGE_TYPES.VIDEO:
                    model.actions.send(undefined, `video:///${content.basename}`, tag, instant, (error) => { dispatch(null, error) });
                    break;
                case MESSAGE_TYPES.CHEER:
                    model.actions.send(undefined, `cheer:${content.basename}`, tag, instant,(error) => { dispatch(null, error) });
                    break;
                case MESSAGE_TYPES.STICKER:
                    model.actions.send(undefined, `sticker:///${content.basename}`, tag, instant, (error) => { dispatch(null, error) });
                    break;
                case MESSAGE_TYPES.LETTER:
                    const letterOutboxModel = flux.get(LetterOutboxModel, id);
                    const letters = letterOutboxModel.store.getState().letters;
                    const unpaidLetter = letters.filter(letter => letter.tag === tag)[0];
                    if (unpaidLetter) {
                        letterOutboxModel.actions.sendLetter(unpaidLetter, (error) => { dispatch(null, error) });
                    }
                    break;
            }
        };

        delete = (tag) => (dispatch, flux) => {
            let model = flux.get(MessagesModel, id);
            model.actions.delete(tag, (error) => { dispatch(null, error) });
        }
    }

    MessagesActions.displayName = createMessagesActions.getDisplayName(id);

    return MessagesActions;
}

createMessagesActions.getDisplayName = function(id) {
    return `dialogs-messages-adapter.${id}`;
};

export default createMessagesActions;
