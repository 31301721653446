import React from 'react';
import PropTypes from 'prop-types';
import { UserPrivacy } from '@sdv/domain/user/user-privacy';
import { render } from 'dating-mobile/src/components/rx-render';

export default function withController(Component) {
  const Controller = render(
    (props, { useDistinct, useObservable, useObservableAction }) => {
      if (!props.userId) {
        return null;
      }

      const userPrivacy = useDistinct(
        userId => UserPrivacy.shared(userId),
        props.userId,
      );
      const userHidden = useObservable(it => it.userHidden, userPrivacy);
      const setPresentInFeed = useObservableAction(
        value => userPrivacy.setPresentInFeed(value),
        userPrivacy,
      );

      return (
        <Component
          {...props}
          value={userHidden}
          onValueChange={setPresentInFeed}
        />
      );
    },
  );

  Controller.propTypes = {
    userId: PropTypes.string,
  };

  Controller.displayName =
    'user.views.hide-user-from-feed-switch-item.controller';

  return Controller;
}
