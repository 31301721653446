import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Image, View } from 'react-native';
import Resources from 'dating-mobile/src/resources';
import shouldUseWebLayout from 'dating-mobile/src/utils/web-layout';

import styles from './styles';

export default class VipIndicator extends PureComponent {
  static displayName = 'vip-indicator';

  static propTypes = {
    size: PropTypes.number,
    padding: PropTypes.number,
    borderRadius: PropTypes.number,
    isPremium: PropTypes.bool,
  };

  render() {
    const {
      isPremium,
      size = styles.$defaultIconSize,
      padding = size * (isPremium ? 0.15 : 0.25), // 25%
      borderRadius = size * 0.5, // 50%
    } = this.props;

    const icon = isPremium
      ? Resources.images.superVipIcon(shouldUseWebLayout() ? '@3x' : '')
      : Resources.images.vipIcon(shouldUseWebLayout() ? '@3x' : '');

    return (
      <View
        style={[
          styles.iconContainer,
          {
            padding,
            borderRadius,
            width: size,
            height: size,
          },
        ]}
      >
        <Image source={icon} style={styles.icon} resizeMode="contain" />
      </View>
    );
  }
}
