import React from 'react';
import PropTypes from 'prop-types';
import connect from '@sdv/connect';

import Model, { getId } from '../model';

const GET_PROPERTY = 'generated.user.tags.targeted.controller.get';

export default function createController(Component, options = {}) {

    const userIdPropName = options.userIdPropName || 'holder';
    const targetIdPropName = options.targetIdPropName || 'target';
    const resultPropName = options.resultPropName || 'tags';
    const tagsLoadedPropName = options.tagsLoadedPropName || 'tagsLoaded';
    const addTagPropName = options.addTagPropName || 'addTag';
    const deleteTagPropName = options.deleteTagPropName || 'deleteTag';

    class Controller extends React.Component {
        static displayName = 'user.tags.targeted.controller';
        static propTypes = {
            [userIdPropName]: PropTypes.string,
            [targetIdPropName]: PropTypes.string,
            [resultPropName]: PropTypes.object,
            [tagsLoadedPropName]: PropTypes.bool,
            [GET_PROPERTY]: PropTypes.func,
            [addTagPropName]: PropTypes.func,
            [deleteTagPropName]: PropTypes.func
        };
        static contextTypes = {
            flux: PropTypes.object
        };

        componentDidMount() {
            this.props[GET_PROPERTY] && this.props[GET_PROPERTY]();
        }

        componentDidUpdate(prevProps) {
            if (shouldReconnect(prevProps, this.props)) {
                this.props[GET_PROPERTY] && this.props[GET_PROPERTY]();
            }
        }

        render() {
            const { ...props } = this.props;

            delete props[GET_PROPERTY];

            return (
                <Component
                    {...props}
                />
            );
        }
    }

    function getModels(flux, props) {
        const models = {};

        if (props[userIdPropName] && props[targetIdPropName]) {
            models.tagsModel = flux.get(Model, getId(props[userIdPropName], props[targetIdPropName]));
        }

        return models;
    }

    function mapModelsToProps(models) {
        return {
            [resultPropName]: models.tagsModel
                ? models.tagsModel.store.getState()
                : {},
            [tagsLoadedPropName]: models.tagsModel && models.tagsModel.store.isFilled()
        };
    }

    function mapModelsToActions(models) {
        const props = {};

        if (models.tagsModel) {
            props[GET_PROPERTY] = models.tagsModel.actions.get;
            props[addTagPropName] = models.tagsModel.actions.patch;
            props[deleteTagPropName] = models.tagsModel.actions.delete;
        }

        return props;
    }

    function shouldReconnect(props, newProps) {
        return props[userIdPropName] !== newProps[userIdPropName] || props[targetIdPropName] !== newProps[targetIdPropName];
    }

    return connect(
        getModels,
        mapModelsToProps,
        mapModelsToActions,
        shouldReconnect
    )(Controller);

};
