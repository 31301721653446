import React from 'react';
import PropTypes from 'prop-types';
import UserEventsModel, {
  EVENT_TYPE,
  getId,
} from 'dating-mobile/src/models/user.events/model';

export default function controller(Component) {
  class Controller extends React.Component {
    static displayName =
      'user.common.views.user-badges.user-conversations-badge.controller';

    static propTypes = {
      userId: PropTypes.string.isRequired,
    };

    static contextTypes = {
      flux: PropTypes.object,
    };

    state = {
      count: 0,
    };

    componentDidMount() {
      this.subscribe();
    }

    componentDidUpdate(prevProps) {
      const { userId } = this.props;

      if (userId !== prevProps.userId) {
        this.unsubscribe();
        this.subscribe();
      }
    }

    componentWillUnmount() {
      this.unsubscribe();
    }

    subscribe() {
      const { userId } = this.props;
      const { flux } = this.context;

      if (userId) {
        const userContactEventsModel = flux.get(
          UserEventsModel,
          getId(userId, EVENT_TYPE.CONTACT),
        );

        this.subscription = userContactEventsModel.store
          .rxState()
          .subscribe(userContactEvents =>
            this.setState({ count: userContactEvents.count }),
          );
      }
    }

    unsubscribe() {
      this.subscription?.unsubscribe();
      this.subscription = null;
    }

    render() {
      const { count } = this.state;

      return <Component {...this.props} count={count} />;
    }
  }

  return Controller;
}
