import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        width: 110,
        height: 110,
        marginLeft: 10,
        marginTop: 10
    },

    attachment: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },

    deleteButton: {
        position: 'absolute',
        right: 0,
        top: 0
    },

    attachmentPreview: {
        position: 'absolute',
        borderRadius: 15,
        width: 100,
        height: 100,
        top: 5,
        left: 5,
        backgroundColor: '$disabledColor'
    },

    progress: {
        position: 'absolute',
        bottom: 10,
        right: 10,
        width: 20,
        color: 'white'
    },

    overlay: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },

    playIcon: {
        width: 56,
        height: 56
    }
});

export default styles;
