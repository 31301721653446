import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FlatList, View } from 'react-native';

import { PackageListItem } from './item';
import styles from './styles';

class PackageList extends Component {
  static displayName = 'payment.views.package-list';

  static propTypes = {
    packages: PropTypes.array,
    // eslint-disable-next-line react/forbid-prop-types
    extraData: PropTypes.any,
    FooterComponent: PropTypes.func,
    onPackagePress: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    itemStyle: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
  };

  renderItem = ({ item, index }) => {
    const { packages, isLoading, onPackagePress, itemStyle } = this.props;

    let packageItemStyle = {
      marginHorizontal: index * styles.$itemLevelMargin,
    };

    if (styles.$listIsHorizontal) {
      packageItemStyle.width = `${100 / Math.max(packages.length, 1)}%`;
    }

    const isCenterItem =
      packages.length % 2 === 1 && index === Math.floor(packages.length / 2);

    packageItemStyle = [styles.item, packageItemStyle];

    return (
      <View style={packageItemStyle}>
        <PackageListItem
          contentStyle={isCenterItem ? styles.centerItem : null}
          containerStyle={itemStyle}
          product={item[0]}
          invoices={item[1]}
          onPress={() => onPackagePress(item)}
          isDisabled={isLoading}
        />
      </View>
    );
  };

  render() {
    const { packages, extraData, FooterComponent } = this.props;

    const numberOfColumns =
      !styles.$listIsHorizontal || packages.length < 2
        ? undefined
        : packages.length;

    return (
      <View style={styles.container}>
        <FlatList
          style={styles.packages}
          numColumns={numberOfColumns}
          key={`packages${numberOfColumns || ''}`}
          data={packages}
          ItemSeparatorComponent={() => (
            <View style={styles.packagesSeparator} />
          )}
          renderItem={this.renderItem}
          alwaysBounceVertical={false}
          keyExtractor={item => item[1] && item[1][0] && item[1][0].sku}
          extraData={extraData}
          ListFooterComponent={FooterComponent}
        />
      </View>
    );
  }
}

export default PackageList;
