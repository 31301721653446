import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },

  thumbnail: {
    width: 120,
    height: 120,
  },

  user: {
    marginTop: 14,
    flexDirection: 'row',
    alignItems: 'center',
  },

  name: {
    fontSize: 24,
    color: '$brandTextColor',
    fontWeight: 'bold',
    fontFamily: '$brandFontFamily',
    alignSelf: 'flex-start',
    marginRight: 5,
  },

  boostContainer: {
    backgroundColor: '$accentColor',
    width: 86,
    height: 24,
    borderRadius: 12,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: -20,
  },

  boostText: {
    color: '$accentTextColor',
    fontFamily: '$brandFontFamily',
    fontSize: 13,
    fontWeight: 'bold',
  },

  userIdText: {
    color: '$secondaryTextColor',
    fontFamily: '$brandFontFamily',
    fontSize: 12,
    marginTop: 8,
  },
});

export default styles;
