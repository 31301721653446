import Actions from './actions';

function createUserTaggedStore(id) {

    class Store {

        constructor(flux) {
            const actions = flux.getActions(Actions, id);
            this.state = {};
            this.bindAction(actions.get, this.update);
            this.bindAction(actions.actualize, this.actualize);
            this.publicMethods = {
                isFilled() {
                    return Object.keys(this.state).length > 0;
                }
            };
        }

        update = (payload) => {
            if (!payload) { return; }
            this.setState({
                users: payload
            });
        };

        actualize(payload) {

            if (!payload || !this.state.users) {
                return;
            }

            let users = this.state.users.slice();
            if (payload.staled) {
                users = users.filter(user => user !== payload.staled);
            }

            if (payload.new) {
                users.push(payload.new);
            }

            this.setState({
                users: users
            });

        }

    }

    Store.displayName = createUserTaggedStore.getDisplayName(id);
    return Store;
}

createUserTaggedStore.getDisplayName = function (id) {
    return `user-tagged.${id}`;
};

export default createUserTaggedStore;
