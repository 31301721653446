export default function loadScript(url, callback) {
  const script = window.document.createElement('script');

  script.type = 'text/javascript';

  if (script.readyState) {
    script.onreadystatechange = function handlReadyStateChange() {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null;

        if (callback) {
          callback();
        }
      }
    };
  } else if (callback) {
    script.onload = callback;
  }

  script.src = url;
  window.document.getElementsByTagName('head')[0].appendChild(script);
}
