import React from 'react';
import { Animated, Text, TouchableOpacity, View } from 'react-native';
import PropTypes from 'prop-types';
import styles from './styles';
import Resources from 'dating-mobile/src/resources';
import RoundButton from 'dating-mobile/src/components/buttons/round';

export default class ChatBadge extends React.PureComponent {
    static displayName = 'dialogs.messages.views.unread-badge-button.view';
    static propTypes = {
        badge: PropTypes.string,
        onPress: PropTypes.func,
    };

    animatedPositionY = new Animated.Value(0);
    animateConf = {
        transform: [
            {
                translateY: this.animatedPositionY,
            },
        ],
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.props.badge !== prevProps.badge) {
            this.animateButtonsBounce();
        }
    }

    animateButtonsBounce = () => {
        Animated.sequence([
            Animated.timing(this.animatedPositionY, { toValue: -10, duration: 100 }),
            Animated.timing(this.animatedPositionY, { toValue: 0, duration: 150 }),
        ]).start();
    };

    onPress = () => {
        this.props.onPress && this.props.onPress();
    };

    render() {
        return (
            <TouchableOpacity style={styles.container} onPress={this.onPress}>
                <Animated.View style={this.animateConf}>
                    <RoundButton
                        icon={Resources.images.tabBarChatDisabled()}
                        style={styles.button}
                    />
                </Animated.View>
                {this.props.badge && (
                    <View style={styles.badgeContainer}>
                        <Text style={styles.badge}>{this.props.badge}</Text>
                    </View>
                )}
            </TouchableOpacity>
        );
    }
}
