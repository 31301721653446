import React from 'react';
import PropTypes from "prop-types";
import { Can } from '@casl/react';
import ability from './ability';

class CanComponent extends React.PureComponent {
    static displayName: 'user.ability.can';
    static contextTypes = {
        flux: PropTypes.object
    };
    state = {};

    constructor(props, context) {
        super(props);
    }

    render() {
        const { children, user, roles, ...canArgs } = {...this.props};
        user.roles = roles;

        return (
            <OriginalCanRewrite {...canArgs} ability={ability(user)}>
                {(...args) => renderChildren(children, ...args)}
            </OriginalCanRewrite>
        );
    }

}

export default CanComponent;

class OriginalCanRewrite extends Can {

    check(props = null) {
        const params = props || this.props;

        const [action, field] = (params.I || params.do).split(/\s+/);
        const subject = params.of || params.a || params.this || params.on;
        const can = params.not ? 'cannot' : 'can';
        const status = this.state.ability[can](action, subject, field);
        let message = null;

        try {
            this.state.ability.throwUnlessCan(action, subject, field);
        }catch(error) {
            message = error.message;
        }

        return {
            status: status,
            message: message
        };
    }

    render() {
        const { children } = this.props;
        const { status, message } = this.state.allowed;

        return renderChildren(children, status, message);
    }
}

function renderChildren(children, status, message) {
    if (typeof children === 'function') {
        return children(status, message)
    } else if(status) {
        return React.Children.only(children);
    } else {
        return null;
    }
}