import React from 'react';
import PropTypes from 'prop-types';
import equal from 'fast-deep-equal';
import { Text, View } from 'react-native';
import { PurchasePromotionBanner } from 'dating-mobile/src/components/purchase-promotion-banner';
import styles from './styles';
import Resources from '../../../../../resources';
import ChatRequests from '../../chat-requests';

class ListHeader extends React.Component {
  static displayName = 'dialogs.my-chats.screen.header';

  static propTypes = {
    invitations: PropTypes.array,
    onChatSelect: PropTypes.func,
    onShowAllRequestsButtonPress: PropTypes.func,
    userId: PropTypes.string,
  };

  shouldComponentUpdate(nextProps) {
    const { invitations } = this.props;

    return !equal(invitations, nextProps.invitations);
  }

  onChatSelected = event => {
    const { onChatSelect } = this.props;

    if (onChatSelect) {
      onChatSelect(event);
    }
  };

  onShowAllRequestsButtonPressed = () => {
    const { onShowAllRequestsButtonPress } = this.props;

    if (onShowAllRequestsButtonPress) {
      onShowAllRequestsButtonPress();
    }
  };

  render() {
    const { invitations, userId } = this.props;

    if (!invitations?.length) {
      return (
        <View style={styles.listHeaderContainer}>
          <PurchasePromotionBanner
            userId={userId}
            hidePremiumSubscriptionBanner
          />
        </View>
      );
    }

    return (
      <View style={styles.listHeaderContainer}>
        <ChatRequests
          chatRequests={invitations}
          onChatRequestSelect={this.onChatSelected}
          onShowAllRequestsButtonPress={this.onShowAllRequestsButtonPressed}
        />
        <Text style={styles.contactsListTitle}>
          {Resources.strings['my-chats-screen-contacts-list-title']}
        </Text>
        <PurchasePromotionBanner
          userId={userId}
          hidePremiumSubscriptionBanner
        />
      </View>
    );
  }
}

export default ListHeader;
