import React from 'react';

import withIdentityId from 'dating-mobile/src/models/identity/controller/id';
import StreamDescriptionEditingViewModel from '@sdv/domain/StreamDescriptionEditingViewModel';

export default function createStreamControlsController(Component) {
    class StreamControlsController extends React.Component {
        constructor(props) {
            super(props);
            this.viewModel = StreamDescriptionEditingViewModel.shared(props.userId);
            this.state = {
                audioMuted: undefined,
            };
        }

        componentDidMount() {
            this.viewModel.microphoneMuted.subscribe(microphoneMuted => {
                this.setState({
                    microphoneMuted,
                });
            });
        }

        handleSwitchMicrophoneMute = () => {
            this.viewModel.switchMicrophoneMute();
        };

        handleRotateCamera = () => {
            this.viewModel.rotateCamera();
        };

        handleClose = () => {
            this.viewModel.close();
        };

        render() {
            return (
                <Component
                    onSwitchMicrophoneMute={this.handleSwitchMicrophoneMute}
                    onRotateCamera={this.handleRotateCamera}
                    onClose={this.handleClose}
                    {...this.state}
                />
            );
        }
    }

    return withIdentityId(StreamControlsController);
}
