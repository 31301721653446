import EStyleSheet from 'react-native-extended-stylesheet';
import { I18nManager } from 'react-native';

const styles = EStyleSheet.create({
    $inputKeyboardAppearance: '$keyboardAppearance',
    $inputSelectionColor: '$accentColor',
    $inputPlaceholderColor: '$primaryPlaceholderColor',

    $statusBarStyle: 'light-content',
    $statusBarBackgroundColor: 'black',

    headerTitle: {
        color: 'white',
        fontSize: 18,
        fontWeight: 'normal',
        fontFamily: '$brandFontFamily'
    },
    container: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'flex-start'
    },
    subjectInput: {
        height: 60,
        width: '100%',
        fontSize: 16,
        color: '$inputTextColor',
        paddingLeft: 16,
        paddingRight: 16,
        fontFamily: '$brandFontFamily',
        backgroundColor: 'white'
    },
    textInputContainer: {
        flex: 1,
        backgroundColor: 'white'
    },
    textInput: {
        paddingTop: 20,
        fontSize: 16,
        color: '$inputTextColor',
        paddingLeft: 16,
        paddingRight: 16,
        fontFamily: '$brandFontFamily',
    },
    sendButton: {
        alignItems: 'center',
        justifyContent: 'center',
        width: 40,
        height: 40,
        marginRight: 12
    },
    actionButton: {
        alignItems: 'center',
        justifyContent: 'center',
        width: 40,
        height: 40,
        marginLeft: 4
    },
    actionButtonsContainer: {
        height: 54,
        width: '100%',
        backgroundColor: 'white',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'flex-start',
        paddingLeft: 4
    },
    leftActionButtonsContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'flex-start'
    },
    rightActionButtonsContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'flex-end'
    },
    sendButtonIcon: {
        tintColor: '$disabledColor',
        transform: [
            {
                scaleX: I18nManager.isRTL ? -1 : 1
            }
        ]
    },
    sendButtonIconActive: {
        tintColor: '$accentColor'
    },
    separator: {
        height: 1,
        backgroundColor: '$primaryBorderColor'
    },
    resourceKeyboardSeparator: {
        backgroundColor: '$brandBorderColor',
        width: '100%',
        height: 1,
        '@flavor ytm': {
            height: 0
        }
    },

    actionIconStyle: {
        '@flavor dma': {
            tintColor: '$brandPlaceholderColor'
        }
    },
});

export default styles;
