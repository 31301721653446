import { Linking } from 'react-native';

export default async function openSupport(facebook) {
    const url = compose(facebook);

    await openUrl(url);
};

function compose(facebook) {
    return `http://m.me/${facebook}`;
}

async function openUrl(url) {
    try {
        await Linking.openURL(url);
    } catch (error) {
        console.log(error);
    }
}

