import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    header: {
        backgroundColor: '$primaryBackgroundColor',
        borderBottomWidth: 0,
        shadowRadius: 0,
        shadowOpacity: 0,
        elevation: 0,
        shadowOffset: {
            height: 0,
        },
    },

    headerTitle: {
        color: '$primaryTextColor',
    },

    container: {
        flex: 1,
        backgroundColor: '$primaryBackgroundColor',
    },
});

export default styles;
