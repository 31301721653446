import React from 'react';
import { StyleSheet, Platform } from 'react-native';
import { withNavigation, withNavigationFocus } from 'react-navigation';
import PropTypes from 'prop-types';
import Resources from 'dating-mobile/src/resources';
import ImageButton from 'dating-mobile/src/components/navigation/views/image-button';
import * as ROUTES from 'dating-mobile/src/routing/router/constants';
import styles from './styles';

export default function bindNavigation(Component) {
  class Navigation extends React.Component {
    static displayName = 'payment.screens.memberships.navigation';

    static propTypes = {
      navigation: PropTypes.object.isRequired,
      isFocused: PropTypes.bool,
    };

    propOrNavigationParamWithName = name => {
      const { navigation, [name]: prop } = this.props;

      return typeof prop !== 'undefined' ? prop : navigation.getParam(name);
    };

    close = closeModal => {
      const { navigation } = this.props;
      const shouldPopToTopOnPaymentCompleted = this.propOrNavigationParamWithName(
        'shouldPopToTopOnPaymentCompleted',
      );
      const shouldPopToTop =
        typeof shouldPopToTopOnPaymentCompleted === 'undefined'
          ? true
          : shouldPopToTopOnPaymentCompleted;
      const isWeb = Platform.OS === 'web';

      if (isWeb) {
        navigation.navigate(ROUTES.CHAT);
      } else if (closeModal && shouldPopToTop) {
        navigation.popToTop();
      } else {
        navigation.pop();
      }
    };

    setNavigationBarState = state => {
      const { navigation } = this.props;

      navigation.setParams(state);
    };

    continueWithoutSubscribingPressed = () => {
      const { navigation } = this.props;

      navigation.navigate(ROUTES.CREDITS, {
        ableToNavigateToMembership: true,
        reason: this.propOrNavigationParamWithName('reason'),
        onComplete: this.propOrNavigationParamWithName('onComplete'),
      });
    };

    openPaymentTypeListScreen = (invoices, onComplete) => {
      const { navigation } = this.props;

      navigation.navigate(ROUTES.PAYMENT_METHOD_LIST, {
        reason: this.propOrNavigationParamWithName('reason'),
        invoices,
        onComplete,
      });
    };

    render() {
      const { isFocused } = this.props;

      return (
        <Component
          isFocused={isFocused}
          reason={this.propOrNavigationParamWithName('reason')}
          safeAreaInsets={this.propOrNavigationParamWithName('safeAreaInsets')}
          close={this.close}
          setNavigationBarState={this.setNavigationBarState}
          onComplete={this.propOrNavigationParamWithName('onComplete')}
          ableToNavigateToCredits={this.propOrNavigationParamWithName(
            'ableToNavigateToCredits',
          )}
          style={this.propOrNavigationParamWithName('style')}
          continueWithoutSubscribingPress={
            this.continueWithoutSubscribingPressed
          }
          onPackageClick={this.openPaymentTypeListScreen}
        />
      );
    }
  }

  const Result = withNavigationFocus(withNavigation(Navigation));

  Result.navigationOptions = ({ navigation, navigationOptions }) => {
    const options = {
      headerTitle: '',
      headerStyle: [navigationOptions.headerStyle, styles.header],
      headerTitleStyle: [
        navigationOptions.headerTitleStyle,
        styles.headerTitle,
      ],
    };

    if (navigation.getParam('cancelButtonVisible')) {
      options.headerLeft = () => (
        <ImageButton
          image={Resources.images.backIcon()}
          tint={StyleSheet.flatten(styles.headerTitle).color}
          onPress={navigation.getParam('onCloseButtonPress')}
        />
      );
    }

    return options;
  };

  return Result;
}
