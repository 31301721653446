import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        flex: 1,
    },

    modalContainer: {
        flex: 1,
        padding: 8,
        justifyContent: 'center',
        marginVertical: 10,
    },

    button: {
        backgroundColor: 'white',
        borderRadius: 8,
        marginVertical: 10,
        shadowRadius: 8,
        shadowOpacity: 0.15,
        shadowOffset: { width: 0, height: 0 },
        elevation: 8
    },

    buttonText: {
        color: '#0069d5',
        fontFamily: 'System'
    },

    safeArea: {
        flex: 1
    }
});

export default styles;
