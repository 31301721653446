import React from 'react';
import PropTypes from 'prop-types';
import RoundButton from '../../../components/buttons/round';
import Resources from '../../../resources';
import { View } from 'react-native';
import styles from './styles';

export default class RotateVideoButton extends React.PureComponent {
    static displayName = 'call.rotate-video-button';
    static propTypes = {
        disabled: PropTypes.bool,
        rotate: PropTypes.func,
        hideWhenDisabled: PropTypes.bool
    };

    render() {
        if (this.props.disabled && this.props.hideWhenDisabled) {
            return null;
        }

        const containerStyle = this.props.disabled ? styles.disabled : undefined;

        return (
            <View style={containerStyle}>
                <RoundButton
                    {...this.props}
                    icon={Resources.images.rotateCameraIcon()}
                    disabled={this.props.disabled}
                    onPress={this.props.rotate}
                />
            </View>
        );
    }
}
