import React from 'react';
import PropTypes from 'prop-types';
import IdentityId from '../../../models/identity/controller/id';
import Send from '../../../models/dialogs.messages/controllers/send';
import { MessagesActionsError } from '@sdv/domain/dialogs.messages/actions';

function createScreenView(Component) {

    class Controller extends React.Component {
        static displayName = 'dialogs.cheers.screen.controller';
        static propTypes = {
            identityId: PropTypes.string,
            send: PropTypes.func,
            userId: PropTypes.string.isRequired,
            close: PropTypes.func.isRequired,
            refillBalance: PropTypes.func.isRequired,
            updateCredentials: PropTypes.func.isRequired
        };

        state = { isSending: false };

        onCheerSelected = (status, cheer) => {
            status ? this.sendCheer(cheer) : this.props.updateCredentials(() => this.sendCheer(cheer));
        };

        sendCheer = (cheer, tag) => {
            if (!cheer || !this.props.send || this.state.isSending) {
                return;
            }

            this.setState({
                isSending: true
            }, () => {
                this.props.send(
                    undefined,
                    `cheer:${cheer.basename}`,
                    tag,
                    (error) => {
                        this.setState({
                            isSending: false
                        }, () => {
                            if (error) {
                                if (error instanceof MessagesActionsError && error.code === MessagesActionsError.CODES.PAYMENT_REQUIRED) {
                                    this.props.refillBalance && this.props.refillBalance(() => this.sendCheer(cheer, error.tag));
                                }
                                return;
                            }

                            this.props.close && this.props.close();
                        });
                    }
                );
            });
        };

        render() {
            return (<Component {...this.props} {...this.state} onCheerSelected={this.onCheerSelected} />);
        }
    }

    return Controller;

}

export default (Component) => IdentityId(Send(createScreenView(Component), 'send', 'identityId', 'userId', true), 'identityId');
