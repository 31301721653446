import { bindNodeCallback, of, defer, from } from 'rxjs';
import { switchMap, mapTo, catchError } from 'rxjs/operators';
import flux from '@sdv/domain/app/flux';
import { shopName } from '@sdv/domain/app/payment/shop-info';
import BalanceRefiller from '@sdv/domain/app/balance-refiller';

export class CreditsPayment {
  constructor(id, invoice, reason, options = {}) {
    this.id = id;
    this.invoice = invoice;
    this.reason = reason;
    this.options = options;
  }

  // TODO: Add analytics
  purchase = () => {
    const createReceipt = bindNodeCallback(flux.api.credits.mall.receipts.post);
    const exchangeReceipt = bindNodeCallback(
      flux.api.credits.mall.exchanges.post,
    );
    const purchaseCredits = defer(() =>
      from(
        new Promise((resolve, reject) =>
          BalanceRefiller.purchaseCredits(
            this.reason,
            resolve,
            reject,
            this.options,
          ),
        ),
      ),
    );

    return createReceipt({
      sku: this.invoice.sku,
      method: this.invoice.method,
      price: this.invoice.price.includingTax,
      amount: this.invoice.creditsAmount,
      shop: shopName,
    }).pipe(
      switchMap(({ receipt }) =>
        exchangeReceipt(this.id, {
          receipt,
          shop: shopName,
          method: this.invoice.method,
        }),
      ),
      mapTo({ success: true }),
      catchError(error => {
        if (error?.code === 'NoCredits') {
          return purchaseCredits.pipe(
            switchMap(() =>
              createReceipt({
                sku: this.invoice.sku,
                method: this.invoice.method,
                price: this.invoice.price.includingTax,
                amount: this.invoice.creditsAmount,
                shop: shopName,
              }),
            ),
            switchMap(({ receipt }) =>
              exchangeReceipt(this.id, {
                receipt,
                shop: shopName,
                method: this.invoice.method,
              }),
            ),
            mapTo({ success: true }),
            catchError(err =>
              of({ error: err || new Error('Canceled'), success: false }),
            ),
          );
        }

        return of({ error, success: false });
      }),
    );
  };
}
