import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { View, Text, Picker, Platform } from 'react-native';

import { GENDER } from '@sdv/domain/user/gender';
import DatePicker from 'dating-mobile/src/components/date-picker';
import Resources from 'dating-mobile/src/resources';
import testId from 'dating-mobile/src/utils/test-id';
import Button from 'dating-mobile/src/components/buttons/base';
import InputRow from 'dating-mobile/src/components/input-row';
import SafePicker from 'dating-mobile/src/components/safe-picker';
import TermsAndPrivacyText from 'dating-mobile/src/components/terms-and-privacy-text';

import styles from './styles';

const placeholder = 'placeholder';

export default class ScreenView extends PureComponent {
    static displayName = 'stepped-registration.screens.screens.birthday-with-gender.view';

    static propTypes = {
        onSubmit: PropTypes.func,
        minAge: PropTypes.number,
    };

    static defaultProps = {
        minAge: 18,
    };

    state = {
        date: null,
        gender: null,
    };

    onDateChange = date => this.setState({ date });

    onGenderChange = gender => {
        this.setState({ gender: gender === placeholder ? null : gender });
    };

    getMaxDate() {
        const { minAge } = this.props;

        return new Date().dateWithYearOffset(-minAge);
    }

    getGenderTitle() {
        const { gender } = this.state;

        if (!gender) {
            return Resources.strings['about-yourself-screen-gender-placeholder'];
        }

        return Resources.strings[
            gender === GENDER.male ? 'gender-picker-man-item-text' : 'gender-picker-woman-item-text'
        ];
    }

    onSubmit = () => {
        const { onSubmit } = this.props;
        const { date, gender } = this.state;

        if (onSubmit) {
            onSubmit(date, gender);
        }
    };

    onGenderPickerClose = () => {
        const { gender } = this.state;

        if (!gender) {
            this.setState({ gender: GENDER.male });
        }
    };

    render() {
        const { date, gender } = this.state;

        return (
            <View style={styles.container}>
                <Text style={styles.title}>{Resources.strings['sign-up-screen-title']}</Text>
                <View>
                    <InputRow separator style={styles.datePickerInputRow}>
                        <DatePicker
                            useUniversalDatePicker
                            modal
                            style={styles.dateTitle}
                            containerStyle={styles.dateContainer}
                            date={date}
                            maxDate={this.getMaxDate()}
                            onSelect={this.onDateChange}
                            placeholderText={
                                Resources.strings['about-yourself-screen-date-of-birth-text']
                            }
                        />
                    </InputRow>
                    <InputRow separator>
                        <SafePicker
                            title={this.getGenderTitle()}
                            isPlaceholder={!gender}
                            onDone={this.onGenderPickerClose}
                            style={styles.genderPicker}
                            titleStyle={styles.genderPickerTitle}
                            buttonContainerStyle={styles.genderPickerButtonContainerStyle}
                        >
                            <Picker style={styles.genderInnerPicker} selectedValue={gender} onValueChange={this.onGenderChange}>
                                {Platform.OS !== 'ios' && (
                                    <Picker.Item
                                        label={Resources.strings.gender}
                                        value={placeholder}
                                    />
                                )}
                                <Picker.Item
                                    label={Resources.strings['gender-picker-man-item-text']}
                                    value={GENDER.male}
                                />
                                <Picker.Item
                                    label={Resources.strings['gender-picker-woman-item-text']}
                                    value={GENDER.female}
                                />
                            </Picker>
                        </SafePicker>
                    </InputRow>
                    <Text style={styles.privacyHint}>
                        {Resources.strings['onboarding-screen-privacy-hint']}
                    </Text>
                </View>
                <View style={styles.nextButtonContainer}>
                    <Button
                        disabled={!date || !gender}
                        title={Resources.strings.next}
                        onPress={this.onSubmit}
                        style={styles.nextButton}
                        {...testId('Next button')}
                    />
                    <TermsAndPrivacyText />
                </View>
            </View>
        );
    }
}
