import React from 'react';
import PropTypes from 'prop-types';
import connect from '@sdv/connect';
import identityId from '../../../models/identity/controller/id';
import userMedia from '../../../models/user.media/controller';
import configValue from '../../../components/config-value';
import CodesModel, { CODE_TYPE } from '../../../models/codes/model';
import { userPhotoPath } from '../../../resources/remote';
import Resources from '../../../resources';

function controller(Component) {

    class Controller extends React.Component {
        static displayName = 'user.upload-private-photos.controller';
        static propTypes = {
            id: PropTypes.string,
            freeStartMessagesEnabled: PropTypes.bool,
            addPhoto: PropTypes.func.isRequired,
            deletePhoto: PropTypes.func.isRequired,
            media: PropTypes.array.isRequired,
            togglePrivate: PropTypes.func.isRequired,
            progress: PropTypes.number,
            maxNumberOfPhotos: PropTypes.number,
            creditsPerPhoto: PropTypes.number,
            currency: PropTypes.number,
            devaluationRatio: PropTypes.number,
            setNavigationBarState: PropTypes.func,
            complete: PropTypes.func,
            activateCode: PropTypes.func
        };

        state = {
            isUploading: false
        };

        componentDidMount() {
            this.props.setNavigationBarState && this.props.setNavigationBarState({
                onSkipButtonPress: this.onSkipButtonPressed
            });
        }

        onPhotoDeleted = (path) => {
            if (!this.props.id) {
                return;
            }

            const media = this.props.media
                .find(media => userPhotoPath(this.props.id, media.basename, true, false) === path);

            if (media) {
                this.props.deletePhoto(media.basename);
            }
        };

        onPhotoSelected = (file) => {
            if (!this.props.id) {
                return;
            }

            this.setState({
                isUploading: true
            }, async () => {
                try {
                    const basename = await this.props.addPhoto(file);
                    await this.props.togglePrivate(basename);
                } catch {
                }

                this.setState({
                    isUploading: false
                })
            });

        };

        onGrabButtonPressed = () => {
            if (!this.props.activateCode) {
                return;
            }

            this.props.activateCode(CODE_TYPE.START_PRIVATE_PHOTOS, () => {
                this.goNext()
            });
        };

        onSkipButtonPressed = () => {
            this.goNext();
        };

        goNext = () => {
            this.props.complete && this.props.complete();
        };

        render() {
            const photos = (this.props.media || [])
                .filter(media => this.props.id && media.tags && media.tags.indexOf('hidden') >= 0)
                .map(media => userPhotoPath(this.props.id, media.basename, true, false));

            const canAddPhoto = this.props.maxNumberOfPhotos && photos.length < this.props.maxNumberOfPhotos;

            const header = this.props.maxNumberOfPhotos && photos.length === this.props.maxNumberOfPhotos
                ? Resources.strings['upload-private-photos-screen-information-header-finished-text']
                : Resources.strings['upload-private-photos-screen-information-header-text'];

            const description = photos.length
                ? (this.props.maxNumberOfPhotos && photos.length === this.props.maxNumberOfPhotos
                    ? Resources.strings['upload-private-photos-screen-information-description-finished-text']
                    : Resources.strings['upload-private-photos-screen-information-description-in-progress-text'])
                : Resources.strings['upload-private-photos-screen-information-description-text'];

            const grabButtonTitle = this.props.currency
                ? (
                    photos.length && this.props.devaluationRatio && this.props.creditsPerPhoto
                        ? Resources.strings.formatString(
                            {
                                coins: Resources.strings['upload-private-photos-screen-grab-coins-button-title-format'],
                                credits: Resources.strings['upload-private-photos-screen-grab-credits-button-title-format']
                            }[this.props.currency],
                            photos.length * this.props.devaluationRatio * this.props.creditsPerPhoto
                        )
                        : {
                            coins: Resources.strings['upload-private-photos-screen-grab-coins-button-title'],
                            credits: Resources.strings['upload-private-photos-screen-grab-credits-button-title']
                        }[this.props.currency]
                )
                : '';

            return (<Component
                {...this.props}
                header={header}
                description={description}
                photos={photos}
                canAddPhoto={canAddPhoto}
                isUploading={this.state.isUploading}
                progress={this.props.progress}
                onPhotoDelete={this.onPhotoDeleted}
                onPhotoSelect={this.onPhotoSelected}
                grabButtonTitle={grabButtonTitle}
                grabButtonDisabled={!photos.length}
                onGrabButtonPress={this.onGrabButtonPressed}
            />);
        }

    }

    const ConnectedController = connect(
        (flux, props) => props.id
            ? { codesModel: flux.get(CodesModel, props.id) }
            : {},
        () => {},
        (models) => models.codesModel
            ? { activateCode: models.codesModel.actions.activate }
            : {},
        (prevProps, newProps) => prevProps.id !== newProps.id
    )(Controller);

    return identityId(
        userMedia(
            configValue(
                ConnectedController,
                {
                    maxNumberOfPhotos: 'promo.max-number-of-private-photos',
                    creditsPerPhoto: 'promo.credits-amount-per-private-photo',
                    devaluationRatio: 'currency.devaluation-ratio',
                    currency: 'currency.type',
                    freeStartMessagesEnabled: 'features.send-free-start-messages-enabled'
                }
            )
        ),
        'id'
    );

}

export default controller;
