import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Resources from 'dating-mobile/src/resources';
import UserName from 'dating-mobile/src/user/common/views/user-name';
import { View, Text } from 'react-native';
import styles from './styles';

class JoinStreamMessage extends PureComponent {
    static displayName = 'dialogs.common.views.message.system.components.join-stream-message';
    static propTypes = {
        userId: PropTypes.string,
    };

    render() {
        return (
            <View style={styles.container}>
                <Text style={styles.text} >
                    {
                        <UserName
                            userId={this.props.userId}
                            format={Resources.strings["stream-join-message"]}
                        />
                    }
                </Text>
            </View>

        );
    }
}

export default JoinStreamMessage;
