import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    counterText: {
        color: '$primaryTextColor',
        fontFamily: '$brandFontFamily',
        fontWeight: 'bold',
        fontSize: 24,
        textAlign: 'center'
    },
    descriptionText: {
        color: '$infoTextColor',
        fontFamily: '$brandFontFamily',
        textAlign: 'center',
        fontSize: 14,
    }

});

export default styles;
