import React from 'react';
import PropTypes from 'prop-types';

import './index.css';

PieProgress.propTypes = {
  size: PropTypes.number,
  progress: PropTypes.number,
  color: PropTypes.string,
};

PieProgress.defaultProps = {
  color: '#007AFF',
  size: 40,
};

function PieProgress({ size, progress, color }) {
  return (
    <svg width={size} viewBox="0 0 64 64" className="circle">
      <circle
        stroke={color}
        strokeWidth={size / 15}
        strokeDasharray="0"
        fill="none"
        cx="50%"
        cy="50%"
        r="50%"
      />
      <circle
        className="pie"
        strokeWidth={32}
        stroke={color}
        fill="none"
        r="25%"
        cx="50%"
        cy="50%"
        style={{ strokeDasharray: `${progress * 100} 100` }}
      ></circle>
    </svg>
  );
}

export default PieProgress;
