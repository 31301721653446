import Actions from './actions';

class StickersStore {
    static displayName = 'dialogs-stickers';

    constructor(flux) {
        const actions = flux.getActions(Actions);
        this.state = {};
        this.bindAction(actions.get, this.get);
    }

    get = (payload) => {
        if (!payload || typeof payload.length !== 'number') {
            return;
        }

        const stickerPacks = [];
        payload.forEach((rawSticker) => {
            const packName = rawSticker.split('-')[0];
            if (packName) {
                let stickerPack = stickerPacks.find(stickerPack => stickerPack.name === packName);
                if (!stickerPack) {
                    stickerPack = {name: packName, stickers: []};
                    stickerPacks.push(stickerPack);
                }
                stickerPack.stickers.push({basename: rawSticker})
            }
        });

        this.setState({stickerPacks: stickerPacks});
    };
}

export default StickersStore;
