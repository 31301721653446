import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        width: '100%',
        marginBottom: 20,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingRight: 20,
        paddingLeft: 20,
    },
    icon: {
        width: 24,
        height: 24,
    },
    text: {
        textAlign: 'left',
        fontSize: 13,
    },
    termsContainer: {
        marginLeft: 10,
        marginRight: 10,
        marginTop: 0,
        marginBottom: 0,
    },
});

export default styles;
