import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        width: '100%',
        height: '100%',
        backgroundColor: '$brandColor',
    },
    closeButton: {
        top: 10,
        width: 32,
        height: 32,
        alignSelf: 'flex-end',
    },
    scrollView: {
        flexDirection: 'column',
    },
    contentContainer: {
        flexGrow: 1,
    },
    groupStreamMessageIcon: {
        width: 72,
        height: 72,
    },
    groupStreamMessageIconContainer: {
        paddingHorizontal: 54,
        justifyContent: 'center',
        alignItems: 'center',
    },
    titleDescription: {
        fontSize: 24,
        color: '$brandTextColor',
        textAlign: 'center',
        fontFamily: '$brandFontFamily',
        fontWeight: 'bold',
    },
    titleDescriptionContainer: {
        paddingHorizontal: 54,
    },
    subtitleDescription: {
        fontSize: 14,
        fontFamily: '$brandFontFamily',
        color: '$infoTextColor',
        textAlign: 'center',
    },
    subtitleDescriptionContainer: {
        paddingHorizontal: 54,
        justifyContent: 'center',
        alignItems: 'center',
    },
    form: {
        position: 'absolute',
        bottom: 0,
    },
    separatorView: {
        flex: 1,
    },
    topView: {
        flex: 3,
    },
    bottomView: {
        flex: 5,
    },
});

export default styles;
