import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  ActivityIndicator,
  ImageBackground,
  ScrollView,
  Text,
  View,
} from 'react-native';
import Resources from 'dating-mobile/src/resources';
import ImageButton from 'dating-mobile/src/components/navigation/views/image-button';
import { SubscribePlans } from 'dating-mobile/src/payment/views/subscribe-plans';
import { GradientButton } from 'dating-mobile/src/components/buttons/gradient';
import { MembershipSubscriptionAgreement } from 'dating-mobile/src/payment/views/membership-subscription-agreement';

import styles from './styles';

const descriptionMap = {
  fem: Resources.strings['subscription-description-fem'],
  mal: Resources.strings['subscription-description-mal'],
};

function getRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}

const min = 700;
const max = 2000;

const numberOfUsers = getRandomNumber(min, max);

export default class PaymentTypeList extends PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
    onContinuePress: PropTypes.func,
    gender: PropTypes.string,
    isLoading: PropTypes.bool,
    registrationInProgress: PropTypes.bool,
  };

  state = {
    invoice: null,
  };

  onContinuePress = () => {
    const { onContinuePress } = this.props;
    const { invoice } = this.state;

    onContinuePress(invoice);
  };

  onSelectPlan = invoice => {
    this.setState({ invoice });
  };

  render() {
    const { onClose, gender, isLoading, registrationInProgress } = this.props;

    const { invoice } = this.state;
    const showActivityIndicator = isLoading || !invoice;

    return (
      <ImageBackground
        source={Resources.images.splash()}
        style={{ width: '100%', height: '100%' }}
      >
        <View style={styles.closeIconContainer}>
          <ImageButton
            image={Resources.images.closeIcon()}
            onPress={onClose}
            style={styles.closeIcon}
          />
        </View>
        <ScrollView
          style={styles.container}
          contentContainerStyle={styles.contentContainer}
          showsVerticalScrollIndicator={false}
          alwaysBounceVertical={false}
        >
          <Text style={styles.title}>
            {registrationInProgress
              ? numberOfUsers
              : Resources.strings['subscription-title']}
          </Text>
          <Text style={styles.description}>
            {registrationInProgress
              ? descriptionMap[gender]
              : Resources.strings['subscription-description']}
          </Text>

          <View style={styles.plansContainer}>
            <SubscribePlans onSelectPlan={this.onSelectPlan} />
          </View>
          {!invoice && <View style={styles.placeholder} />}
          {!!invoice && (
            <View style={styles.buttonContainer}>
              <GradientButton
                onPress={this.onContinuePress}
                containerStyles={styles.button}
                text={Resources.strings['subscription-button-text']}
              />
            </View>
          )}
          <MembershipSubscriptionAgreement invoice={invoice} />
        </ScrollView>
        <View
          style={styles.activityIndicatorContainer}
          pointerEvents="box-none"
        >
          <ActivityIndicator animating={showActivityIndicator} />
        </View>
      </ImageBackground>
    );
  }
}
