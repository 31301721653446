import React from 'react';
import PropTypes from 'prop-types';
import { Purchase } from 'dating-mobile/src/payment/methods/purchase';
import { RxController } from 'dating-mobile/src/components/rx-controller';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';


export default function bindController(View) {

    class Controller extends RxController {

        static displayName = 'payment.screens.payment-methods.controller';

        static propTypes = {
            onComplete: PropTypes.func.isRequired,
            reason: PropTypes.string.isRequired,
            invoices: PropTypes.array,
            id: PropTypes.string.isRequired
        };

        constructor(props) {
            super(props);
            this.state = {
                paymentInProgress: false
            };
        }

        componentDidMount() {
            super.componentDidMount();
            this.props.setNavigationBarState && this.props.setNavigationBarState({
                onCloseButtonPress: this.onCloseButtonPressed
            });
            this.setState({
                paymentInProgress: false
            });
        }

        onCloseButtonPressed = () => {
            this.props.close && this.props.close();
            this.props.onComplete && this.props.onComplete();
        };

        handleInvoiceClick = (invoice) => {
            if (this.state.paymentInProgress) {
                return;
            }
            this.setState({
                paymentInProgress: true
            });
            this.addSubscription(Purchase.shared().pay(this.props.id, invoice, this.props.reason)
                .subscribe((purchaseResult) => {
                    if (!!purchaseResult.success) {
                        this.onPaymentCompleted(invoice);
                    }
                    this.setState({
                        paymentInProgress: false
                    });
                }, e => {
                    this.setState({
                        paymentInProgress: false
                    });
                })
            );
        };

        onPaymentCompleted = (pack) => {
            this.props.onComplete && this.props.onComplete(pack);
            this.props.close && this.props.close(true);
        };

        render() {
            return (<View
                invoices={this.props.invoices}
                onInvoiceClick={this.handleInvoiceClick}
                paymentInProgress={this.state.paymentInProgress}
            />);
        }
    }

    return withIdentityId(Controller, 'id');
};
