import Room from './room';
import * as events from 'events';
import RealtimeDialogsStreamsPipeline from './realtime/networking';

class Service extends events.EventEmitter {
    constructor(realtime) {
        super();
        this.realtime = realtime;
        this.room = null;
    }

    createRoom(roomId) {
        if (!roomId) {
            roomId = undefined;
        }

        this.room = new Room(roomId, new RealtimeDialogsStreamsPipeline(this.realtime));
        this.room.addListener('event.stream.local.changed', this.change);
        this.room.addListener('event.stream.remote.changed', this.change);
        this.room.addListener('event.costreamer.invitation.added', this.addIncomingInvitation);
        this.room.addListener('event.costreamer.invitation.removed', this.removeOutgoingInvitation);
    }

    join(roomId, callback) {
        this.createRoom(roomId);
        this.room.join(callback);
    }

    leave() {
        if (this.room) {
            this.room.removeListener('event.stream.local.changed', this.change);
            this.room.removeListener('event.stream.remote.changed', this.change);
            this.room.removeListener(
                'event.costreamer.invitation.added',
                this.addIncomingInvitation,
            );
            this.room.removeListener(
                'event.costreamer.invitation.removed',
                this.removeOutgoingInvitation,
            );
            this.room.leave();
            this.change(null);
        }
    }

    pause() {
        if (this.room) {
            this.room.pause();
        }
    }

    resume() {
        if (this.room) {
            this.room.resume();
        }
    }

    startStreamPreview(id) {
        this.createRoom(id);
        if (this.room) {
            this.room.startStreamPreview();
        }
    }

    updateRoomParameters = params => {
        if (!params) {
            return;
        }
        const { title, topics, cover } = params;
        this.room.updateRoomTitle(title);
        this.room.updateRoomCover(cover);
        this.room.updateRoomTopics(topics);
    };

    startLiveStream(params) {
        this.room.join(payload => {
            if (payload.message === 'success') {
                this.room.startLiveStream();
                this.updateRoomParameters(params);
            }
        });
    }

    startBroadcast(params) {
        if (this.room) {
            this.updateRoomParameters(params);
            this.room.startStream();
        }
    }

    stopBroadcast() {
        if (this.room) {
            this.room.removeListener('event.stream.local.changed', this.change);
            this.room.removeListener('event.stream.remote.changed', this.change);
            this.room.removeListener(
                'event.costreamer.invitation.added',
                this.addIncomingInvitation,
            );
            this.room.removeListener(
                'event.costreamer.invitation.removed',
                this.removeOutgoingInvitation,
            );

            this.room.stopStream();
            this.change(null);
        }
    }

    setMuteState(state) {
        if (this.room) {
            this.room.setMuteState(state);
        }
    }

    setMicrophoneState(state) {
        if (this.room) {
            this.room.toggleAudio(state);
        }
    }

    rotateCamera() {
        if (this.room) {
            this.room.switchCam();
        }
    }
    change = stream => {
        this.emit('event.change.stream', { payload: stream });
    };

    addIncomingInvitation = id => {
        this.emit('event.costreamer.invitation.added', id);
    };

    removeOutgoingInvitation = id => {
        this.emit('event.costreamer.invitation.removed', id);
    };

    attachCoStreamer = id => {
        if (this.room) {
            this.room.attachCoStreamer(id);
        }
    };

    declineCoStreamer = id => {
        if (this.room) {
            this.room.declineCoStreamer(id);
        }
    };
}

export default Service;
