import EStyleSheet from 'react-native-extended-stylesheet';
import { I18nManager } from 'react-native';

const styles = EStyleSheet.create({
    container: {
        marginLeft: 8,
        marginRight: 15,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
        width: '100% - 150',
    },
    infoContainer: {
        '@flavor secretly': {
            justifyContent: 'space-between',
            paddingBottom: 16,
        },
        flexDirection: 'column',
        flexShrink: 1,
    },
    userInfo: {
        fontSize: 14,
        color: '$primaryTextColor',
        flex: 1,
        '@flavor secretly': {
            flex: undefined,
            flexShrink: 1,
        },
    },
    name: {
        fontFamily: '$brandFontFamily',
        fontSize: 22,
        fontWeight: 'bold',
        color: '$primaryTextColor',
        '@flavor secretly': {
            fontSize: 18,
        },
        flexShrink: 1,
    },
    travel: {
        fontFamily: '$brandFontFamily',
        fontSize: 14,
        color: '$infoTextColor',
    },
    disclosure: {
        transform: [
            {
                scaleX: I18nManager.isRTL ? -1 : 1,
            },
        ],
    },
    locateIcon: {
        height: 16,
        width: 16,
    },
});

export default styles;
