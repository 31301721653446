import React from 'react';
import {View, Text, FlatList, StatusBar} from 'react-native';
import FanListItem from './fan-list-item';
import userController from '../../../../models/user/controllers';
import styles from './styles';
import StreamStatsCounterView from './stats';
import PropTypes from "prop-types";
import testId from '../../../../utils/test-id';

const ListItem = userController(FanListItem);

class StreamInfoView extends React.Component {
    static displayName = 'dialogs.stream.info.view';
    static contextTypes = {
        flux: PropTypes.object
    };

    constructor(props, context) {
        super(props);
        this.flux = context.flux;
        this.state = {};
    }

    onContentSizeChanged = (contentWidth, contentHeight) => {
        this.setState({ contentHeight: contentHeight })
    };

    onLayout = (event) => {
        const { width, height } = event.nativeEvent.layout;

        if (!width || !height || (this.state.width === width && this.state.height === height)) {
            return;
        }

        this.setState({ height: height });
    };

    renderHeader = () => {
        return (<Text style={styles.fansText}>Fans</Text>);
    };

    render() {
        let scrollEnabled = false;
        if (this.state.height && this.state.contentHeight) {
            scrollEnabled = this.state.contentHeight > this.state.height;
        }

        return (
            <View style={styles.container}>
                <StatusBar
                    barStyle={styles.$statusBarStyle}
                    backgroundColor={styles.$statusBarBackgroundColor}
                    hidden={false}
                    translucent={false}
                />
                <View style={styles.diamondsView}>
                    <StreamStatsCounterView
                        text={'This Stream'}
                        value={this.streamDiamondsCount()}
                        images={[require('./assets/diamond.png')]}
                        {...testId('Diamonds counter')}
                    />
                    <StreamStatsCounterView
                        text={'All-Time'}
                        value={this.props.diamonds}
                        images={[require('./assets/diamond.png'), require('./assets/diamondShaded.png')]}
                        {...testId('All time diamonds counter')}
                    />
                </View>
                <View style={{flex: 1}}>
                    <FlatList
                        data={this.props.cheers}
                        renderItem={this.getItem}
                        numColumns={1}
                        keyExtractor={item => item.user}
                        ListHeaderComponent={this.renderHeader}
                        onContentSizeChange={this.onContentSizeChanged}
                        onLayout={this.onLayout}
                        scrollEnabled={scrollEnabled}
                    />
                </View>
            </View>
        )
    }

    streamDiamondsCount = () => {
        return this.props.cheers.reduce((a, b) => b.amount + a, 0);
    };

    getItem = ({item}) => {
        return <ListItem {...item} id={item.user} {...testId('Fans list item')}/>;
    };
}


export default StreamInfoView;

