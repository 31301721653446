import React from 'react';
import PropTypes from 'prop-types';
import ImageButton from 'dating-mobile/src/components/navigation/views/image-button';
import Resources from 'dating-mobile/src/resources';
import styles from './styles';
import { StyleSheet } from 'react-native';

function bindNavigation(Component) {
  class Navigation extends React.Component {
    static displayName = 'broadcasts.follow-top.screens.navigation';

    static propTypes = {
      navigation: PropTypes.object.isRequired,
    };

    onComplete = (...args) => {
      const { navigation } = this.props;
      const completion = navigation.getParam('onComplete');

      navigation.pop();

      if (completion) {
        completion(...args);
      }
    };

    render() {
      return <Component {...this.props} onComplete={this.onComplete} />;
    }
  }

  Navigation.navigationOptions = ({ navigation }) => {
    const options = {
      title: 'Follow top broadcasters',
      headerStyle: styles.header,
      headerTitleStyle: styles.headerTitle,
      headerLeft: () => (
        <ImageButton
          image={Resources.images.backIcon()}
          onPress={() => navigation.pop()}
          tint={StyleSheet.flatten(styles.headerTitle).color}
        />
      ),
    };

    if (navigation.getParam('hideBackButton')) {
      options.headerLeft = () => null;
    }

    return options;
  };

  return Navigation;
}

export default bindNavigation;
