import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        backgroundColor: 'black',
        width: '100%'
    },
    content: {
        width: '100%',
        height: 120,
        justifyContent: 'center',
        alignItems: 'center'
    },
    coverOverlay: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        backgroundColor: '$shadowColor'
    },
    cover: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        top: 0
    },
    avatar: {
        width: 55,
        height: 55,
        marginBottom: 5
    },
    onlineContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        left: 22,
        top: -17
    },
    name: {
        fontWeight: 'normal',
        color: 'white',
        fontFamily: '$brandFontFamily',
        textShadowColor: 'rgba(0, 0, 0, 0.75)',
        textShadowOffset: { width: -1, height: 1 },
        textShadowRadius: 5
    }
});

export default styles;
