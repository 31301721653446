import React from 'react';
import PropTypes from 'prop-types';

function createHideOnScroll(ScrollComponent) {

    class HideOnScroll extends React.Component {
        static displayName = 'hide-on-scroll';
        static propTypes = Object.assign({ setHidden: PropTypes.func }, ScrollComponent.propTypes);

        onScrollBeginDrag = (event, ...args) => {
            this.offset = event.nativeEvent.contentOffset.y;
            this.props.onScrollBeginDrag && this.props.onScrollBeginDrag(event, ...args);
        };

        onScroll = (event, ...args) => {
            const currentOffset = event.nativeEvent.contentOffset.y;
            this.props.setHidden && this.props.setHidden(currentOffset > this.offset);
            this.props.onScroll && this.props.onScroll(event, ...args);
        };

        render() {
            const { forwardedRef, ...props } = this.props;

            delete props.setHidden;

            return (<ScrollComponent
                ref={forwardedRef}
                { ...props }
                onScroll={this.onScroll}
                onScrollBeginDrag={this.onScrollBeginDrag}
            />);
        }

    }

    return React.forwardRef((props, ref) => {
        return (<HideOnScroll {...props} forwardedRef={ref} />);
    });

}

export default createHideOnScroll;
