import withConfigValue from 'dating-mobile/src/components/config-value';
import createMediaContentController from 'dating-mobile/src/models/common.messages/controllers/media-content-controller';
import View from './view';

export default withConfigValue(createMediaContentController(View), {
    showPriceForUnpaidPhoto: 'markup.chat-screen.show-price-for-unpaid-photo',
    currency: 'currency.type',
    devaluationRatio: 'currency.devaluation-ratio',
    openPaidPhotoPrice: 'pricing.open-paid-photo',
});
