import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  DatePickerAndroid,
  DatePickerIOS,
  Platform,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import UniversalDatePicker from 'react-native-date-picker';
import Dialog from 'react-native-dialog';
import Resources from 'dating-mobile/src/resources';

import getDisplayDate from '../../utils/date-time-formatting';
import CustomActionSheet from '../action-sheet';

import styles from './styles';

class DatePicker extends React.Component {
  static displayName = 'component.datepicker';

  static propTypes = {
    date: PropTypes.object,
    modal: PropTypes.bool,
    onCancel: PropTypes.func,
    placeholderText: PropTypes.string,
    containerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
    useUniversalDatePicker: PropTypes.bool,
  };

  static defaultProps = {
    useUniversalDatePicker: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      current: props.date,
      error: null,
      modalVisible: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!this.state.current && this.props.date !== prevProps.date) {
      this.setState({ current: this.props.date });
    }
  }

  componentDidMount() {
    if (!this.props.modal && Platform.OS === 'android') {
      this.androidPicker();
    }
  }

  useUniversalDatePicker() {
    const { useUniversalDatePicker } = this.props;

    return Platform.OS === 'web' || useUniversalDatePicker;
  }

  androidPicker = async () => {
    const useUniversalDatePicker = this.useUniversalDatePicker();

    if (useUniversalDatePicker) {
      this.toggleModal();

      return;
    }

    try {
      const dates = this.getDates(this.state.current);

      const { action, year, month, day } = await DatePickerAndroid.open(dates);

      if (action !== DatePickerAndroid.dismissedAction) {
        const utcDate = DatePicker.toUTC(year, month, day);

        this.setState(
          {
            date: utcDate,
            current: new Date(year, month, day),
          },
          () => this.props.onSelect(utcDate),
        );
      } else if (this.props.onCancel) {
        this.props.onCancel();
      }
    } catch ({ code, message }) {
      this.setState({ error: message });
    }
  };

  setDate = date => {
    date = this.getDates(date).date;
    const utcDate = DatePicker.toUTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
    );

    this.setState(
      {
        date: utcDate,
        current: date,
      },
      () => this.props.onSelect(utcDate),
    );
  };

  toggleModal = () => {
    this.setState(state => ({
      modalVisible: !state.modalVisible,
      prevDate: !state.modalVisible ? state.current : state.prevDate,
      prevUtcDate: !state.modalVisible ? state.date : state.prevUtcDate,
    }));
  };

  static toUTC(year, month, day) {
    return new Date(Date.UTC(year, month, day));
  }

  getDates(date) {
    const { minDate, maxDate } = this.props;
    const useUniversalDatePicker = this.useUniversalDatePicker();
    const today = new Date();
    const current = date || today;
    const result = { date: current };

    if (useUniversalDatePicker) {
      result.maximumDate = maxDate;
      result.minimumDate = minDate;
    } else {
      result.maxDate = maxDate;
      result.minDate = minDate;
    }

    if (minDate && minDate.getTime() - current.getTime() < 0) {
      result.date = minDate;

      if (useUniversalDatePicker) {
        result.minimumDate = maxDate;
      } else {
        result.minDate = maxDate;
      }
    }

    if (maxDate && maxDate.getTime() - current.getTime() < 0) {
      result.date = maxDate;

      if (useUniversalDatePicker) {
        result.maximumDate = maxDate;
      } else {
        result.maxDate = maxDate;
      }
    }

    return result;
  }

  onDone = () => {
    if (!this.state.date && this.props.maxDate) {
      this.setDate(this.props.maxDate);
    }

    this.toggleModal();
  };

  onCancel = () => {
    const { prevDate, prevUtcDate } = this.state;

    this.setState(
      {
        date: prevUtcDate,
        current: prevDate,
      },
      () => this.props.onSelect(prevUtcDate),
    );

    this.toggleModal();
  };

  render() {
    const { modal, style, containerStyle, placeholderText } = this.props;
    const useUniversalDatePicker = this.useUniversalDatePicker();
    const { modalVisible, current } = this.state;
    const dates = this.getDates(current);
    const displayDate =
      (current && getDisplayDate(dates.date, true, true)) || '';
    const dateString = styles.$showDateLabel
      ? placeholderText || ''
      : current
      ? getDisplayDate(dates.date, true, true)
      : placeholderText || '';

    const titleStyle = [
      styles.picker,
      style || {},
      !current ? styles.placeholder : {},
    ];

    const containerStyleRes = modal ? containerStyle || {} : styles.container;

    if (Platform.OS !== 'ios') {
      if (!useUniversalDatePicker && !modal) {
        return <View />;
      }

      if (modal) {
        return (
          <Fragment>
            <TouchableOpacity
              style={[styles.textContainer, containerStyleRes]}
              onPress={this.androidPicker}
            >
              <Text style={titleStyle}>{dateString}</Text>
              {styles.$showDateLabel && (
                <Text style={styles.dateText}>{displayDate}</Text>
              )}
            </TouchableOpacity>
            <Dialog.Container
              visible={modalVisible}
              animationIn="zoomIn"
              animationOut="zoomOut"
            >
              <UniversalDatePicker
                {...dates}
                mode="date"
                onDateChange={this.setDate}
              />
              <Dialog.Button
                label={Resources.strings.cancel}
                onPress={this.onCancel}
              />
              <Dialog.Button
                label={Resources.strings.ok}
                onPress={this.onDone}
              />
            </Dialog.Container>
          </Fragment>
        );
      }
    }

    const Picker = useUniversalDatePicker ? UniversalDatePicker : DatePickerIOS;

    const picker = (
      <View
        style={[
          styles.modal,
          useUniversalDatePicker ? styles.universalDatePickerModal : null,
        ]}
      >
        <Picker {...dates} mode="date" onDateChange={this.setDate} />
      </View>
    );

    return (
      <View style={containerStyleRes}>
        {modal && (
          <TouchableOpacity
            style={styles.textContainer}
            onPress={this.toggleModal}
          >
            <Text style={titleStyle}>{dateString}</Text>
            {styles.$showDateLabel && (
              <Text style={styles.dateText}>{displayDate}</Text>
            )}
          </TouchableOpacity>
        )}
        {modal ? (
          <CustomActionSheet
            modalVisible={modalVisible}
            onCancel={this.toggleModal}
            onDone={this.onDone}
          >
            {picker}
          </CustomActionSheet>
        ) : (
          picker
        )}
      </View>
    );
  }
}

export default DatePicker;
