import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Platform, StyleSheet, Text } from 'react-native';
import { HeaderTitle } from 'react-navigation-stack';
import IfConfigValue from 'dating-mobile/src/components/config-value/if';
import ImageButton from 'dating-mobile/src/components/navigation/views/image-button';
import * as ROUTES from 'dating-mobile/src/routing/router/constants';
import Resources from '../../../resources';
import ListHeaderTitle from '../../views/list-header-title';

import styles from './styles';

export default function witNavigation(Component) {
  class Navigation extends PureComponent {
    static displayName = 'feed.screens.list.navigation';

    static propTypes = {
      navigation: PropTypes.object,
    };

    static navigationOptions = ({ navigation, navigationOptions }) => ({
      headerShown: !navigation.getParam('navigationBarHidden'),
      headerTitle: props => (
        <HeaderTitle {...props}>
          <IfConfigValue
            path="markup.feed-screen.header-with-location-info"
            equalsTo={true}
          >
            <ListHeaderTitle />
          </IfConfigValue>
          <IfConfigValue
            path="markup.feed-screen.header-with-location-info"
            equalsTo={false}
          >
            <Text>
              {Resources.strings['feed-screen-no-location-title-format']}
            </Text>
          </IfConfigValue>
        </HeaderTitle>
      ),
      headerStyle: [navigationOptions.headerStyle, styles.header],
      headerTransparent: Platform.OS === 'android',
      headerTitleStyle: [
        navigationOptions.headerTitleStyle,
        styles.headerTitle,
      ],
      headerRight: () => (
        <IfConfigValue
          path="markup.feed-screen.search-button-position"
          equalsTo="navigation"
        >
          <ImageButton
            image={Resources.images.filterIcon()}
            onPress={navigation.getParam('onFilterButtonPress')}
            tint={StyleSheet.flatten(styles.headerTitle).color}
          />
        </IfConfigValue>
      ),
    });

    setNavigationBarState = state => {
      const { navigation } = this.props;

      navigation.setParams(state);
    };

    openProfile = id => {
      const { navigation } = this.props;

      navigation.navigate(ROUTES.PROFILE, { id });
    };

    openFilter = () => {
      const { navigation } = this.props;

      navigation.navigate(ROUTES.FILTER);
    };

    render() {
      return (
        <Component
          setNavigationBarState={this.setNavigationBarState}
          openProfile={this.openProfile}
          openFilter={this.openFilter}
        />
      );
    }
  }

  return Navigation;
}
