import { PixelRatio } from 'react-native';
import { getAssetByID } from 'react-native-web/src/modules/AssetRegistry';
import XMLHttpRequest2 from 'xhr2';

const dataUriPattern = /^data:/;
const svgDataUriPattern = /^(data:image\/svg\+xml;utf8,)(.*)/;

export class ImageUriCache {
  static maximumEntries = 256;

  static entries = {};

  static createCacheId(source) {
    return JSON.stringify(ImageLoader.resolveSource(source));
  }

  static has(source) {
    const { entries } = ImageUriCache;
    const cacheId = ImageUriCache.createCacheId(source);

    return Boolean(entries[cacheId]);
  }

  static get(source) {
    const { entries } = ImageUriCache;
    const cacheId = ImageUriCache.createCacheId(source);

    return entries[cacheId];
  }

  static add(source, displayImageUri) {
    const { entries } = ImageUriCache;
    const lastUsedTimestamp = Date.now();
    const cacheId = ImageUriCache.createCacheId(source);

    if (entries[cacheId]) {
      entries[cacheId].lastUsedTimestamp = lastUsedTimestamp;
      entries[cacheId].refCount += 1;
    } else {
      entries[cacheId] = {
        lastUsedTimestamp,
        refCount: 1,
        displayImageUri:
          displayImageUri || ImageLoader.resolveSource(source).uri,
      };
    }
  }

  static remove(source) {
    const { entries } = ImageUriCache;
    const cacheId = ImageUriCache.createCacheId(source);

    if (entries[cacheId]) {
      entries[cacheId].refCount -= 1;
    }
    // Free up entries when the cache is "full"
    ImageUriCache.cleanUpIfNeeded();
  }

  static cleanUpIfNeeded() {
    const { entries } = ImageUriCache;
    const cacheIds = Object.keys(entries);

    if (cacheIds.length + 1 > ImageUriCache.maximumEntries) {
      let leastRecentlyUsedKey;
      let leastRecentlyUsedEntry;

      cacheIds.forEach(cacheId => {
        const entry = entries[cacheId];

        if (
          (!leastRecentlyUsedEntry ||
            entry.lastUsedTimestamp <
              leastRecentlyUsedEntry.lastUsedTimestamp) &&
          entry.refCount === 0
        ) {
          leastRecentlyUsedKey = cacheId;
          leastRecentlyUsedEntry = entry;
        }
      });

      if (leastRecentlyUsedKey) {
        delete entries[leastRecentlyUsedKey];
      }
    }
  }
}

let id = 0;
const requests = {};

const ImageLoader = {
  abort(requestId) {
    let image = requests[`${requestId}`];

    if (image) {
      image.onerror = null;
      image.onload = null;
      image = null;
      delete requests[`${requestId}`];
    }
  },
  getSize(uri, success, failure) {
    let complete = false;
    const interval = setInterval(callback, 16);
    const requestId = ImageLoader.load({ uri }, callback, errorCallback);

    function callback() {
      const image = requests[`${requestId}`];

      if (image) {
        const { naturalHeight, naturalWidth } = image;

        if (naturalHeight && naturalWidth) {
          success(naturalWidth, naturalHeight);
          complete = true;
        }
      }
      if (complete) {
        ImageLoader.abort(requestId);
        clearInterval(interval);
      }
    }

    function errorCallback() {
      if (typeof failure === 'function') {
        failure();
      }
      ImageLoader.abort(requestId);
      clearInterval(interval);
    }
  },
  load(source, onLoad, onError, onProgress) {
    const {
      uri,
      method = 'GET',
      headers = {},
      body,
    } = ImageLoader.resolveSource(source);

    const image = new window.Image();

    id += 1;
    image.onerror = onError;
    image.onload = e => {
      // avoid blocking the main thread
      const onDecode = () => onLoad({ nativeEvent: e }, image.src);

      if (typeof image.decode === 'function') {
        // Safari currently throws exceptions when decoding svgs.
        // We want to catch that error and allow the load handler
        // to be forwarded to the onLoad handler in this case
        image.decode().then(onDecode, onDecode);
      } else {
        setTimeout(onDecode, 0);
      }
    };

    requests[`${id}`] = image;

    // If the important source properties are empty, return the image directly
    if (!source || !uri) {
      return id;
    }

    // If the image is a dataUri, display it directly via image
    const isDataUri = dataUriPattern.test(uri);

    if (isDataUri) {
      image.src = uri;

      return id;
    }

    // If the image can be retrieved via GET, we can fallback to image loading method
    if (method === 'GET' && !Object.keys(headers).length) {
      image.src = uri;

      return id;
    }

    // Load image via XHR
    const request = new XMLHttpRequest2();

    request.open(method, uri);
    request.responseType = 'blob';
    request.withCredentials = true;
    request.onerror = () => {
      // Fall back to image (e.g. for CORS issues)
      // image.src = uri;
    };

    // Add request headers
    // eslint-disable-next-line no-restricted-syntax
    for (const [name, value] of Object.entries(headers)) {
      request.setRequestHeader(name, value);
    }

    // When the request finished loading, pass it on to the image
    request.onload = () => {
      image.src = window.URL.createObjectURL(request.response);
    };

    // Track progress
    request.onprogress = onProgress;

    // Send the request
    request.send(body);

    return id;
  },
  prefetch(source) {
    return new Promise((resolve, reject) => {
      const resolvedSource = ImageLoader.resolveSource(source);

      ImageLoader.load(
        resolvedSource,
        () => {
          // Add the uri to the cache so it can be immediately displayed when used
          // but also immediately remove it to correctly reflect that it has no active references
          ImageUriCache.add(resolvedSource);
          ImageUriCache.remove(resolvedSource);
          resolve();
        },
        reject,
      );
    });
  },
  resolveSource(source) {
    let resolvedSource = {};

    if (typeof source === 'number') {
      // get the URI from the packager
      const asset = getAssetByID(source);
      let scale = asset.scales[0];

      if (asset.scales.length > 1) {
        const preferredScale = PixelRatio.get();
        // Get the scale which is closest to the preferred scale

        scale = asset.scales.reduce((prev, curr) =>
          Math.abs(curr - preferredScale) < Math.abs(prev - preferredScale)
            ? curr
            : prev,
        );
      }
      const scaleSuffix = scale !== 1 ? `@${scale}x` : '';

      resolvedSource.uri = asset
        ? `${asset.httpServerLocation}/${asset.name}${scaleSuffix}.${asset.type}`
        : '';
    } else if (typeof source === 'string') {
      resolvedSource.uri = source;
    } else if (Array.isArray(source)) {
      resolvedSource = {
        ...resolvedSource,
        ...source[0],
      };
    } else if (typeof source === 'object' && source !== null) {
      resolvedSource = Object.create(
        Object.getPrototypeOf(source),
        Object.getOwnPropertyDescriptors(source),
      );
    }

    if (resolvedSource.uri) {
      const match = resolvedSource.uri.match(svgDataUriPattern);
      // inline SVG markup may contain characters (e.g., #, ") that need to be escaped

      if (match) {
        const [, prefix, svg] = match;
        const encodedSvg = encodeURIComponent(svg);

        resolvedSource.uri = `${prefix}${encodedSvg}`;
      }
    }

    return resolvedSource;
  },
  queryCache(uris) {
    const result = {};

    uris.forEach(u => {
      if (ImageUriCache.has(u)) {
        result[u] = 'disk/memory';
      }
    });

    return Promise.resolve(result);
  },
};

export default ImageLoader;
