import user from '../../../../models/user/controllers';
import PropTypes from 'prop-types';
import { Text, View } from 'react-native';
import styles from './styles';
import Photo from '../../../../components/photo';
import React from 'react';
import testId from '../../../../utils/test-id';
import Resources from '../../../../resources'

class BackgroundView extends React.PureComponent {
    static displayName = 'dialogs.stream.views.connecting';
    static propTypes = {
        connectingTextVisible: PropTypes.bool,
    };

    render() {
        const basename = this.props.user && this.props.user.thumbnail;

        return (
            <View style={styles.connectingScreenContainer}>
                <Photo
                    key={basename}
                    userId={this.props.user.id}
                    basename={basename}
                    size={'.50x50'}
                    blurRadius={3}
                    style={styles.connectingBackground}
                    {...testId('Streamer photo')}
                />
                <View style={styles.connectingOverlay}/>
                {
                    !!this.props.connectingTextVisible && (
                        <Text style={styles.connectingText}>{Resources.strings['stream-screen-connecting-title']}</Text>
                    )
                }
            </View>
        );
    }

}

export default user(BackgroundView);
