import Api from '@sdv/api-zill';
import { composeEmail, isDemoEmail } from '@sdv/domain/utils/demo';
import guid from '@sdv/commons/utils/guid';

function getToken(xhr) {
  return xhr.getResponseHeader('X-Token');
}

function createIdentityActions(id = 'default') {
  class Actions {
    auth = token => (dispatch, flux) => {
      flux.api.authorize.token(token);

      flux.api.identity.get((err, identity) => {
        if (err) {
          dispatch(null, err);

          return;
        }

        dispatch({
          ...identity,
          token,
          authorizationMethod: 'signin:token',
          demo: isDemoEmail(identity.email, flux.api.getBaseHost()),
        });
      });
    };

    updatePassword = password => (dispatch, flux) => {
      flux.api.identity.patch({ password }, err => {
        if (err) {
          return dispatch(null, err);
        }

        return dispatch({
          password,
        });
      });
    };

    signUp = (email, password) => (dispatch, flux) => {
      const currentIdentity = flux.getStore(Actions.displayName).getState();
      const isDemo = currentIdentity.id && currentIdentity.demo;
      const trimmedEmail = email.trim();

      if (isDemo) {
        flux.api.identity.patch({ email: trimmedEmail, password }, err => {
          if (err) {
            return dispatch(null, err);
          }

          return dispatch({
            email: trimmedEmail,
            password,
            authorizationMethod: 'signup:password',
            demo: false,
          });
        });
      } else {
        flux.api.authorize.basic(trimmedEmail, password);

        flux.api.identity.register(
          { email: trimmedEmail, password },
          (err, identity, xhr) => {
            if (err) {
              dispatch(null, err);

              return;
            }

            dispatch({
              ...identity,
              token: getToken(xhr),
              authorizationMethod: 'signup:password',
              demo: false,
            });
          },
        );
      }
    };

    signUpAsAnonymous = () => (dispatch, flux) => {
      const username = composeEmail(flux.api.getBaseHost());
      const password = guid();

      flux.api.authorize.basic(username, password);

      flux.api.identity.register(
        { email: username, password },
        (err, identity, xhr) => {
          if (err) {
            dispatch(null, err);

            return;
          }

          dispatch({
            ...identity,
            token: getToken(xhr),
            authorizationMethod: 'signup:password',
            demo: true,
          });
        },
      );
    };

    loginUser = (email, password) => (dispatch, flux) => {
      const trimmedEmail = email.trim();
      const api = Api.create(flux.api.getBaseHost());

      api.authorize.basic(trimmedEmail, password);

      api.identity.get((err, identity, xhr) => {
        if (err) {
          return dispatch(null, err);
        }

        const token = getToken(xhr);

        flux.api.authorize.token(token);
        flux.api.variable('user-id', identity.id);

        return dispatch({
          ...identity,
          token,
          authorizationMethod: 'signin:password',
          demo: false,
        });
      });
    };

    signOut = () => (dispatch, flux) => {
      flux.api.unauthorize();
      dispatch(null);
    };

    signUpViaOAuth2 = (system, token, email) => (dispatch, flux) => {
      flux.api.authorize[system](token);

      flux.api.identity.get((err, identity, xhr) => {
        if (!err) {
          return dispatch({
            ...identity,
            token: getToken(xhr),
            authorizationMethod: `signin:${system}`,
            demo: false,
          });
        }

        const currentIdentity = flux.getStore(Actions.displayName).getState();
        const isDemo = currentIdentity.id && currentIdentity.demo;
        const trimmedEmail = email.trim();
        const password = `${+`${Date.now()}${Math.floor(
          Math.random() * 9000 + 1000,
        )}`}`;

        if (isDemo) {
          flux.api.authorize.token(currentIdentity.token);

          flux.api.identity.patch({ email: trimmedEmail, password }, error => {
            if (error) {
              return dispatch(null, error);
            }

            return dispatch({
              email: trimmedEmail,
              password,
              authorizationMethod: `signup:${system}`,
              demo: false,
            });
          });
        } else {
          flux.api.authorize.basic(trimmedEmail, password);

          flux.api.identity.register(
            { email: trimmedEmail, password },
            (error, newIdentity, registerXhr) => {
              if (error) {
                dispatch(null, error);

                return;
              }

              dispatch({
                ...newIdentity,
                token: getToken(registerXhr),
                authorizationMethod: `signup:${system}`,
                demo: false,
              });
            },
          );
        }

        return null;
      });
    };

    signInViaOAuth2 = (system, oauthToken) => (dispatch, flux) => {
      flux.api.authorize[system](oauthToken);

      flux.api.identity.get((err, identity, xhr) => {
        if (err) {
          return dispatch(null, err);
        }

        const token = getToken(xhr);

        flux.api.authorize.token(token);

        return dispatch({
          ...identity,
          token,
          authorizationMethod: `signin:${system}`,
          demo: false,
        });
      });
    };

    patch = data => (dispatch, flux) => {
      const { ...changes } = data;

      if (typeof changes.email === 'string') {
        changes.email = changes.email.trim();
      }
      flux.api.identity.patch(changes, err => {
        if (err) {
          return dispatch(null, err);
        }

        return dispatch(changes);
      });
    };

    recover = email => (dispatch, flux) => {
      flux.api.reminders.password.post({ type: 'password', email }, err => {
        if (err) {
          return dispatch(null, err);
        }

        return dispatch();
      });
    };
  }

  Actions.displayName = createIdentityActions.getDisplayName(id);

  return Actions;
}

createIdentityActions.getDisplayName = id => `identity.${id}`;

export default createIdentityActions;
