import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $statusBarStyle: 'light-content',
    $statusBarBackgroundColor: 'white',

    headerTitle: {
        fontFamily: '$brandFontFamily',
        color: 'white'
    },
    container: {
        flex: 1,
        backgroundColor: '$primaryBackgroundColor'
    },
    textContainer: {
        padding: 10
    },
    text: {
        fontFamily: '$brandFontFamily',
        color: '$primaryTextColor'
    },
    subjectContainer: {
        paddingTop: 10,
        paddingHorizontal: 10
    },
    subject: {
        fontFamily: '$brandFontFamily',
        color: '$primaryTextColor',
        fontWeight: '600'
    },
    attachmentContainer: {
        width: 110,
        height: 110,
        overflow: 'hidden',
        margin: 5,
        borderRadius: 8,
        justifyContent: 'center',
        alignItems: 'center'
    },
    attachmentPreview: {
        width: 110,
        height: 110,
        position: 'absolute'
    },
    scrollView: {
        flexGrow: 1
    },
    attachments: {
        width: '100%',
        height: 125,
        marginBottom: 10
    },
    attachmentsList: {
        width: '100%',
        height: 110
    },
    replyButtonContainer: {
        height: 50,
        marginLeft: 20,
        marginRight: 20,
        backgroundColor: '$accentColor',
        borderRadius: 25,
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 10
    },
    replyButton: {
        color: '$accentTextColor',
        fontWeight: '600',
        fontFamily: '$brandFontFamily',
        fontSize: 16
    }
});

export default styles;
