import React from 'react';
import { Platform } from 'react-native';
import PropTypes from 'prop-types';
import connect from '@sdv/connect';
import CredentialsUpdater from 'dating-mobile/src/authentication/utils/credentials-updater';
import { userAppliedFilter } from 'dating-mobile/src/services/tracking/filter-tracker';
import { UserTags } from '@sdv/domain/user/tags/user-tags';
import flux from '@sdv/domain/app/flux';
import IdentityId from '../../../models/identity/controller/id';
import UserController from '../../../models/user/controllers';
import PreferencesController from '../../../models/user.preference/controllers';
import ConfigValue from '../../../components/config-value';
import HasTag from '../../../models/user.tags/controllers/has-tag';
import {
  FILTER_TYPES,
  FILTERS,
  KIDS_VALUES,
  PREFERRED_GENDER_VALUES,
} from '../../utils/filters';
import { CRITERIA_OPTIONS } from '../../../models/misc-data/utils';
import UsersSearchModel from '../../../models/users.search/model';
import oppositeGender from '../../../utils/opposite-gender';

function controller(Component) {
  class Controller extends React.Component {
    static displayName = 'feed.filter.screen.controller';

    static propTypes = {
      isFocused: PropTypes.bool,
      id: PropTypes.string,
      membershipIsInactive: PropTypes.bool,
      preference: PropTypes.object,
      user: PropTypes.object,
      initialState: PropTypes.object,
      travelsEnabled: PropTypes.bool,
      searchNearbyEnabled: PropTypes.bool,
      locationChangeInSearchEnabled: PropTypes.bool,
      videoChatEnabled: PropTypes.bool,
      emptyPreferredGenderEnabled: PropTypes.bool,
      advancedSearchOnlyWithMembershipEnabled: PropTypes.bool,
      travelDestinationSearchOnlyWithMembershipEnabled: PropTypes.bool,
      defaultMinAge: PropTypes.number,
      defaultMaxAge: PropTypes.number,
      allowedMaxAge: PropTypes.number,
      allowedMinAge: PropTypes.number,
      editFilter: PropTypes.func.isRequired,
      editAdvancedFilters: PropTypes.func,
      subscribe: PropTypes.func,
      close: PropTypes.func,
      setNavigationBarState: PropTypes.func,
      onComplete: PropTypes.func,
      setSearchParams: PropTypes.func,
      canUseAdvancedSearch: PropTypes.bool,
      searchOnlyWithMembershipEnabled: PropTypes.bool,
      params: PropTypes.object,
      premiumMembershipIsActive: PropTypes.bool,
      premiumSubscriptionEnabled: PropTypes.bool,
      filtersEnabledOnlyWithPremiumSubscription: PropTypes.array,
    };

    constructor(props) {
      super(props);

      this.state = props.initialState || {};
    }

    componentDidMount() {
      const { setNavigationBarState } = this.props;

      if (setNavigationBarState) {
        setNavigationBarState({
          onResetButtonPress: this.onResetButtonPressed,
          onResetAdvancedFiltersButtonPress: this
            .onResetAdvancedFiltersButtonPressed,
          onSearchButtonPress: this.onSearchButtonPressed,
          onCloseButtonPress: this.onCloseButtonPressed,
        });
      }
    }

    onCloseButtonPressed = () => {
      const { close, onComplete } = this.props;

      if (close) {
        close();
      }

      if (onComplete) {
        onComplete(this.getParams());
      }
    };

    onItemPressed = (type, title, value) => {
      const { canUseAdvancedSearch } = this.props;

      if (canUseAdvancedSearch || this.getAdvancedFilters().indexOf(type) < 0) {
        this.tryEditFilter(type, title, value);
      } else {
        CredentialsUpdater.updateCredentials(() =>
          this.tryEditFilter(type, title, value),
        );
      }
    };

    tryEditFilter = (type, title, value) => {
      const {
        membershipIsInactive,
        advancedSearchOnlyWithMembershipEnabled,
        travelDestinationSearchOnlyWithMembershipEnabled,
        subscribe,
      } = this.props;

      if (
        membershipIsInactive &&
        ((advancedSearchOnlyWithMembershipEnabled &&
          this.getAdvancedFilters().indexOf(type) >= 0) ||
          (travelDestinationSearchOnlyWithMembershipEnabled &&
            type === FILTERS.TRAVEL_DESTINATION))
      ) {
        if (subscribe) {
          subscribe(() => {
            this.editFilter(type, title, value);
          });
        }
      } else {
        this.editFilter(type, title, value);
      }
    };

    onFilterEditComplete = (type, selectedValue) => {
      const { id } = this.props;
      let newValue = selectedValue;

      if (type === FILTERS.GENDER && Array.isArray(selectedValue)) {
        if (!selectedValue.length || selectedValue.length > 1) {
          newValue = null;
        } else {
          [newValue] = selectedValue;
        }
      }

      if (Platform.OS === 'web') {
        flux.api.annals.add(id, 'feed-filter-changed', {
          timestamp: new Date(),
          type,
          value: JSON.stringify(newValue),
        });
      }

      this.setState({
        [type]: newValue,
      });
    };

    editFilter = (type, title, value) => {
      let filterType = null;
      const props = { title, value };
      const currentValue = this.getValue(type);
      const {
        emptyPreferredGenderEnabled,
        allowedMinAge,
        allowedMaxAge,
        editFilter,
      } = this.props;

      switch (type) {
        case FILTERS.GENDER:
          filterType = FILTER_TYPES.SELECTION;
          props.allowUnselection = emptyPreferredGenderEnabled;
          props.multiSelection = emptyPreferredGenderEnabled;
          props.availableValues = PREFERRED_GENDER_VALUES;
          props.selectedItems =
            !currentValue && emptyPreferredGenderEnabled
              ? Object.keys(PREFERRED_GENDER_VALUES)
              : [currentValue];
          break;
        case FILTERS.AGE:
          filterType = FILTER_TYPES.RANGE;
          props.minValue = currentValue.min;
          props.maxValue = currentValue.max;
          props.defaultMin = allowedMinAge;
          props.defaultMax = allowedMaxAge;
          break;
        case FILTERS.LOCATION:
          filterType = FILTER_TYPES.LOCATION;
          props.allowClear = true;
          break;
        case FILTERS.TRAVEL_DESTINATION:
          filterType = FILTER_TYPES.LOCATION;
          props.allowClear = true;
          break;
        case FILTERS.EDUCATION:
          filterType = FILTER_TYPES.SELECTION;
          props.dataType = CRITERIA_OPTIONS.EDUCATIONS;
          props.selectedItems = currentValue;
          props.multiSelection = true;
          break;
        case FILTERS.LANGUAGE:
          filterType = FILTER_TYPES.SELECTION;
          props.dataType = CRITERIA_OPTIONS.LANGUAGES;
          props.selectedItems = currentValue;
          props.multiSelection = true;
          break;
        case FILTERS.RELATIONSHIP:
          filterType = FILTER_TYPES.SELECTION;
          props.dataType = CRITERIA_OPTIONS.RELATIONSHIPS;
          props.selectedItems = currentValue;
          props.multiSelection = true;
          break;
        case FILTERS.KIDS:
          filterType = FILTER_TYPES.SELECTION;
          props.multiSelection = true;
          props.availableValues = KIDS_VALUES;
          props.selectedItems = currentValue;
          break;
        case FILTERS.SMOKE:
          filterType = FILTER_TYPES.SELECTION;
          props.dataType = CRITERIA_OPTIONS.SMOKE_SEARCH;
          props.selectedItems = currentValue;
          props.multiSelection = true;
          break;
        case FILTERS.DRINK:
          filterType = FILTER_TYPES.SELECTION;
          props.dataType = CRITERIA_OPTIONS.DRINKING_SEARCH;
          props.selectedItems = currentValue;
          props.multiSelection = true;
          break;
        case FILTERS.BODY_TYPE:
          filterType = FILTER_TYPES.SELECTION;
          props.dataType = CRITERIA_OPTIONS.BODY_TYPE;
          props.selectedItems = currentValue;
          props.multiSelection = true;
          break;
        case FILTERS.EYES:
          filterType = FILTER_TYPES.SELECTION;
          props.dataType = CRITERIA_OPTIONS.EYES_SEARCH;
          props.selectedItems = currentValue;
          props.multiSelection = true;
          break;
        case FILTERS.HAIR:
          filterType = FILTER_TYPES.SELECTION;
          props.dataType = CRITERIA_OPTIONS.HAIRS_SEARCH;
          props.selectedItems = currentValue;
          props.multiSelection = true;
          break;
        case FILTERS.INTERESTS:
          filterType = FILTER_TYPES.INTERESTS;
          props.dataType = CRITERIA_OPTIONS.INTERESTS;
          props.selectedItems = currentValue;
          break;
        case FILTERS.HEIGHT:
          filterType = FILTER_TYPES.RANGE;
          props.minValue = currentValue.min;
          props.maxValue = currentValue.max;
          props.defaultMin = 90;
          props.defaultMax = 240;
          props.allowEmpty = true;
          break;
        case FILTERS.VIDEO_CHAT:
          this.setState({ [type]: !currentValue });

          return;
        default:
          break;
      }

      props.onComplete = selectedValue =>
        this.onFilterEditComplete(type, selectedValue);

      if (filterType) {
        editFilter(filterType, props);
      }
    };

    getValue = filter => {
      const { params = {}, defaultMinAge, defaultMaxAge } = this.props;
      const {
        [FILTERS.GENDER]: gender,
        [FILTERS.AGE]: age,
        [FILTERS.LOCATION]: location,
        [FILTERS.TRAVEL_DESTINATION]: travelDestination,
        [FILTERS.EDUCATION]: education,
        [FILTERS.LANGUAGE]: language,
        [FILTERS.RELATIONSHIP]: relationship,
        [FILTERS.KIDS]: kids,
        [FILTERS.SMOKE]: smoke,
        [FILTERS.DRINK]: drink,
        [FILTERS.HEIGHT]: height,
        [FILTERS.BODY_TYPE]: bodyType,
        [FILTERS.EYES]: eyes,
        [FILTERS.HAIR]: hair,
        [FILTERS.INTERESTS]: interests,
        [FILTERS.VIDEO_CHAT]: videoChat,
      } = this.state;

      switch (filter) {
        case FILTERS.GENDER:
          return typeof gender !== 'undefined' ? gender : params.gender;
        case FILTERS.AGE:
          return (
            age || {
              min: params.minage || defaultMinAge,
              max: params.maxage || defaultMaxAge,
            }
          );
        case FILTERS.LOCATION:
          return (
            location || {
              city: params.city,
              country: params.country,
            }
          );
        case FILTERS.TRAVEL_DESTINATION:
          return (
            travelDestination || {
              city: params.travelcity,
              country: params.travelcountry,
            }
          );
        case FILTERS.EDUCATION:
          return (
            education ||
            (params.educations || '').split('+').filter(item => !!item)
          );
        case FILTERS.LANGUAGE:
          return (
            language ||
            (params.languages || '').split('+').filter(item => !!item)
          );
        case FILTERS.RELATIONSHIP:
          return (
            relationship ||
            (params.relationships || '').split('+').filter(item => !!item)
          );
        case FILTERS.KIDS:
          return typeof kids !== 'undefined'
            ? kids
            : params.children && [params.children.toString()];
        case FILTERS.SMOKE:
          return (
            smoke || (params.smoke || '').split('+').filter(item => !!item)
          );
        case FILTERS.DRINK:
          return (
            drink || (params.drink || '').split('+').filter(item => !!item)
          );
        case FILTERS.HEIGHT:
          return (
            height || {
              min: params.minheight,
              max: params.maxheight,
            }
          );
        case FILTERS.BODY_TYPE:
          return (
            bodyType ||
            (params['body-types'] || '').split('+').filter(item => !!item)
          );
        case FILTERS.EYES:
          return eyes || (params.eyes || '').split('+').filter(item => !!item);
        case FILTERS.HAIR:
          return hair || (params.hairs || '').split('+').filter(item => !!item);
        case FILTERS.INTERESTS:
          return (
            interests ||
            (params.interests || '').split('+').filter(item => !!item)
          );
        case FILTERS.VIDEO_CHAT:
          return typeof videoChat !== 'undefined' ? videoChat : !!params.camera;
        default:
          return undefined;
      }
    };

    getDefaultValue = filter => {
      const {
        user = {},
        preference = {},
        emptyPreferredGenderEnabled,
        defaultMaxAge,
        defaultMinAge,
      } = this.props;

      switch (filter) {
        case FILTERS.GENDER:
          return preference['preferred-gender'] || emptyPreferredGenderEnabled
            ? preference['preferred-gender'] || null
            : oppositeGender(user.gender || 'mal');
        case FILTERS.AGE:
          return {
            max: preference.maxage || defaultMaxAge,
            min: preference.minage || defaultMinAge,
          };
        case FILTERS.LOCATION:
        case FILTERS.TRAVEL_DESTINATION:
        case FILTERS.HEIGHT:
          return {};
        case FILTERS.EDUCATION:
        case FILTERS.LANGUAGE:
        case FILTERS.RELATIONSHIP:
        case FILTERS.SMOKE:
        case FILTERS.DRINK:
        case FILTERS.BODY_TYPE:
        case FILTERS.EYES:
        case FILTERS.HAIR:
        case FILTERS.INTERESTS:
        case FILTERS.KIDS:
          return [];
        case FILTERS.VIDEO_CHAT:
          return false;
        default:
          return undefined;
      }
    };

    getMandatoryFilters = () => {
      const {
        locationChangeInSearchEnabled,
        travelsEnabled,
        videoChatEnabled,
      } = this.props;

      return [
        FILTERS.GENDER,
        FILTERS.AGE,
        ...(locationChangeInSearchEnabled ? [FILTERS.LOCATION] : []),
        ...(travelsEnabled ? [FILTERS.TRAVEL_DESTINATION] : []),
        ...(videoChatEnabled ? [FILTERS.VIDEO_CHAT] : []),
      ];
    };

    getAdvancedFilters = () => {
      const mandatoryFilters = this.getMandatoryFilters();

      mandatoryFilters.push(FILTERS.TRAVEL_DESTINATION, FILTERS.VIDEO_CHAT);

      return Object.values(FILTERS).filter(
        filter => mandatoryFilters.indexOf(filter) < 0,
      );
    };

    getParams = () => {
      return Object.values(FILTERS).reduce(
        (acc, key) => ({
          ...acc,
          [key]: this.getValue(key),
        }),
        {},
      );
    };

    onRemoveAdvancedFilterValueButtonPressed = (filter, value) => {
      switch (filter) {
        // TODO: fix FILTERS.AGE, FILTERS.GENDER, FILTERS.VIDEO_CHAT
        case FILTERS.AGE:
        case FILTERS.GENDER:
        case FILTERS.VIDEO_CHAT:
          this.setState({
            [filter]: undefined,
          });
          break;
        case FILTERS.KIDS:
          this.setState({
            [filter]: null,
          });
          break;

        case FILTERS.HEIGHT:
        case FILTERS.LOCATION:
        case FILTERS.TRAVEL_DESTINATION:
          this.setState({
            [filter]: {},
          });
          break;
        case FILTERS.EDUCATION:
        case FILTERS.LANGUAGE:
        case FILTERS.RELATIONSHIP:
        case FILTERS.SMOKE:
        case FILTERS.DRINK:
        case FILTERS.BODY_TYPE:
        case FILTERS.EYES:
        case FILTERS.HAIR:
        case FILTERS.INTERESTS:
          this.setState({
            [filter]: this.getValue(filter).filter(item => item !== value),
          });
          break;
      }
    };

    onSearchButtonPressed = () => {
      const {
        searchOnlyWithMembershipEnabled,
        membershipIsInactive,
        subscribe,
        premiumSubscriptionEnabled,
        filtersEnabledOnlyWithPremiumSubscription,
        premiumMembershipIsActive,
      } = this.props;

      if (this.isSubscribing) {
        return;
      }

      // TODO: tmp
      userAppliedFilter.next(true);

      if (
        premiumSubscriptionEnabled &&
        filtersEnabledOnlyWithPremiumSubscription
      ) {
        const premiumSubscriptionRequired = filtersEnabledOnlyWithPremiumSubscription.some(
          filter => !this.isDefaultValue(filter),
        );

        if (!premiumMembershipIsActive && premiumSubscriptionRequired) {
          if (subscribe) {
            this.isSubscribing = true;

            subscribe(
              product => {
                if (product?.isPremiumMembership) {
                  this.closeAndApplyFilter();
                }

                this.isSubscribing = false;
              },
              () => {
                this.isSubscribing = false;
              },
              { premiumSubscriptionRequired: true },
            );
          }

          return;
        }
      }

      if (searchOnlyWithMembershipEnabled && membershipIsInactive) {
        if (subscribe) {
          this.isSubscribing = true;

          subscribe(
            () => {
              this.closeAndApplyFilter();
              this.isSubscribing = false;
            },
            () => {
              this.isSubscribing = false;
            },
          );
        }

        return;
      }

      this.closeAndApplyFilter();
    };

    closeAndApplyFilter = () => {
      const { isFocused, close, onComplete } = this.props;

      this.applyParams();

      if (isFocused) {
        if (close) {
          close();
        }
      }

      if (onComplete) {
        onComplete(this.getParams());
      }
    };

    onResetButtonPressed = () => {
      const state = Object.values(FILTERS).reduce(
        (acc, filter) => ({
          ...acc,
          [filter]: this.getDefaultValue(filter),
        }),
        {},
      );

      this.setState(state);
    };

    onResetAdvancedFiltersButtonPressed = () => {
      const state = this.getAdvancedFilters().reduce(
        (acc, filter) => ({
          ...acc,
          [filter]: this.getDefaultValue(filter),
        }),
        {},
      );

      this.setState(state);
    };

    onAddAdvancedFilterButtonPressed = () => {
      const {
        advancedSearchOnlyWithMembershipEnabled,
        membershipIsInactive,
        subscribe,
        editAdvancedFilters,
      } = this.props;

      if (advancedSearchOnlyWithMembershipEnabled && membershipIsInactive) {
        if (subscribe) {
          subscribe(() => {
            this.onAddAdvancedFilterButtonPressed();
          });
        }
      } else if (editAdvancedFilters) {
        editAdvancedFilters({
          params: this.getParams(),
          onComplete: state => {
            this.setState(state);
          },
        });
      }
    };

    // TODO: Check the default values of all filter types
    isDefaultValue(filterType) {
      const { params = {}, defaultMinAge, defaultMaxAge } = this.props;
      const { [filterType]: value } = this.getParams();

      switch (filterType) {
        case FILTERS.GENDER:
          return value === params.gender;
        case FILTERS.AGE:
          return (
            value?.min === (params.minage || defaultMinAge) &&
            value?.max === (params.maxage || defaultMaxAge)
          );
        case FILTERS.LOCATION:
          return (
            value?.city === params.city && value?.country === params.country
          );
        default:
          return false;
      }
    }

    applyParams() {
      const { setSearchParams } = this.props;

      if (setSearchParams && Object.keys(this.state).length) {
        const params = this.getParams();

        const queryParams = Object.keys(params).reduce((acc, filter) => {
          const query = { ...acc };
          const value = params[filter];
          const arrayValue =
            Array.isArray(value) && value.length
              ? `+${value.sort().join('+')}`
              : undefined;

          switch (filter) {
            case FILTERS.EDUCATION:
              query.educations = arrayValue;
              break;
            case FILTERS.LANGUAGE:
              query.languages = arrayValue;
              break;
            case FILTERS.RELATIONSHIP:
              query.relationships = arrayValue;
              break;
            case FILTERS.SMOKE:
              query.smoke = arrayValue;
              break;
            case FILTERS.DRINK:
              query.drink = arrayValue;
              break;
            case FILTERS.BODY_TYPE:
              query['body-types'] = arrayValue;
              break;
            case FILTERS.EYES:
              query.eyes = arrayValue;
              break;
            case FILTERS.HAIR:
              query.hairs = arrayValue;
              break;
            case FILTERS.INTERESTS:
              query.interests = arrayValue;
              break;
            case FILTERS.HEIGHT:
              query.minheight = value.min || undefined;
              query.maxheight = value.max || undefined;
              break;
            case FILTERS.KIDS:
              query.children =
                value && value.length === 1 ? value[0] : undefined;
              break;
            case FILTERS.GENDER:
              query.gender = value;
              break;
            case FILTERS.AGE:
              query.minage = value.min || undefined;
              query.maxage = value.max || undefined;
              break;
            case FILTERS.LOCATION:
              query.city = value.city || undefined;
              query.country = value.country || undefined;
              break;
            case FILTERS.TRAVEL_DESTINATION:
              query.travelcity = value.city || undefined;
              query.travelcountry = value.country || undefined;
              break;
            case FILTERS.VIDEO_CHAT:
              query.camera = value || undefined;
              break;
          }

          return query;
        }, {});

        setSearchParams(queryParams);
      }
    }

    render() {
      return (
        <Component
          {...this.props}
          params={this.getParams()}
          mandatoryFilters={this.getMandatoryFilters()}
          advancedFilters={this.getAdvancedFilters()}
          onSearchButtonPress={this.onSearchButtonPressed}
          onFilterEditComplete={this.onFilterEditComplete}
          onItemPress={this.onItemPressed}
          onAddAdvancedFilterButtonPress={this.onAddAdvancedFilterButtonPressed}
          onResetAdvancedFiltersButtonPress={
            this.onResetAdvancedFiltersButtonPressed
          }
          onRemoveAdvancedFilterValueButtonPress={
            this.onRemoveAdvancedFilterValueButtonPressed
          }
        />
      );
    }
  }

  return connect(
    (fluxStore, props) =>
      props.id
        ? { searchModel: fluxStore.get(UsersSearchModel, props.id) }
        : {},
    models =>
      models.searchModel
        ? { params: models.searchModel.store.getState().params }
        : {},
    models =>
      models.searchModel
        ? { setSearchParams: models.searchModel.actions.setParams }
        : {},
    (props, newProps) => props.id !== newProps.id,
  )(Controller);
}

export default Component => {
  return IdentityId(
    HasTag(
      HasTag(
        UserController(
          PreferencesController(
            ConfigValue(pureController(Component), {
              advancedSearchOnlyWithMembershipEnabled:
                'features.advanced-search-only-with-membership-enabled',
              travelDestinationSearchOnlyWithMembershipEnabled:
                'features.travel-destination-search-only-with-membership-enabled',
            }),
          ),
        ),
        {
          tag: UserTags.PremiumMembership,
          userIdPropName: 'id',
          hasTagPropName: 'premiumMembershipIsActive',
        },
      ),
      {
        tag: UserTags.Membership,
        userIdPropName: 'id',
        hasTagPropName: 'membershipIsInactive',
      },
    ),
    'id',
  );
};

export function pureController(Component) {
  return ConfigValue(controller(Component), {
    emptyPreferredGenderEnabled: 'features.empty-preferred-gender-enabled',
    searchNearbyEnabled: 'features.search-nearby-enabled',
    locationChangeInSearchEnabled: 'features.location-change-in-search-enabled',
    travelsEnabled: 'features.travels-enabled',
    defaultMinAge: 'preferences.default-min-age',
    defaultMaxAge: 'preferences.default-max-age',
    allowedMinAge: 'age-range.min-age',
    allowedMaxAge: 'age-range.max-age',
    videoChatEnabled: 'features.video-chat-enabled',
    premiumSubscriptionEnabled: 'features.premium-subscription-enabled',
    filtersEnabledOnlyWithPremiumSubscription:
      'features.filters-enabled-only-with-premium-subscription',
    searchOnlyWithMembershipEnabled:
      'features.search-only-with-membership-enabled',
  });
}
