import React from 'react';
import PropTypes from 'prop-types';
import StreamsModel from 'dating-mobile/src/models/dialogs.streams/model';
import '@sdv/commons/rx/store';
import flux from '@sdv/domain/app/flux';
import { map } from 'rxjs/operators';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';

function createController(Component) {

    class Controller extends React.Component {
        static displayName = 'streaming.screens.streams-pager.controller';
        static propTypes = {
            id: PropTypes.string.isRequired,
            userId: PropTypes.string.isRequired,
            showStream: PropTypes.func
        };

        constructor(props) {
            super(props);
            this.state = { next: undefined, prev: undefined }
        }

        componentDidMount() {
            flux.get(StreamsModel).store.rxState()
                .pipe(
                    map(state => state.streams)
                )
                .subscribe(streams => { this.updateStreams(streams) });
        }

        updateStreams = (streams) => {
            this.streams = streams;
            let currentStreamIndex = (streams || []).indexOf(this.props.id);
            if (currentStreamIndex < 0 && streams.length > 0) {
                currentStreamIndex = 0;
            }
            if (currentStreamIndex >= 0) {
                let prevElement;
                if (currentStreamIndex === 0) {
                    prevElement = streams[streams.length - 1];
                } else {
                    prevElement = streams[currentStreamIndex - 1];
                }

                const nextElement = streams[(currentStreamIndex + 1) % streams.length];
                this.setState({ prev: prevElement, next: nextElement });
            }
            else {
                this.setState({ prev: undefined, next: undefined });
            }
        };

        componentDidUpdate(prevProps) {
            if (prevProps.id !== this.props.id) {
                this.updateStreams(this.streams);
            }
        }

        render() {

            return (
                <Component
                    {...this.props}
                    next={this.state.next}
                    prev={this.state.prev}
                    swipeEnabled={!!this.props.id && !!this.props.userId && this.props.userId !== this.props.id && !!this.state.prev && !!this.state.next}
                />
            );

        }

    }

    return withIdentityId(Controller);
}

export default createController;
