import React from 'react';
import PropTypes from 'prop-types';
import { Image, Text, TouchableWithoutFeedback, View } from 'react-native';
import Resources from '../../../resources';
import styles from './styles';

import userController from '../../../models/user/controllers';

class WinkContent extends React.Component {
    static displayName = 'notifications.popup.wink';
    static propTypes = {
        user: PropTypes.object,
        notification: PropTypes.object,
        openChatAndDismiss: PropTypes.func.isRequired,
        onReadyForDisplay: PropTypes.func.isRequired,
    };

    ready = false;

    componentDidMount() {
        if (this.props.user && this.props.user.name) {
            this.ready = true;
            this.props.onReadyForDisplay();
        }
    }

    componentDidUpdate() {
        if (!this.ready && this.props.user && this.props.user.name) {
            this.ready = true;
            this.props.onReadyForDisplay();
        }
    }

    openChat = () => {
        if (!this.props.id) {
            return;
        }

        this.props.openChatAndDismiss(this.props.id);
    };

    render() {
        const username = this.props.user && this.props.user.name;
        const text = (username && Resources.strings.formatString(Resources.strings['wink-notification-text'], username)) || '';

        return (
            <TouchableWithoutFeedback onPress={this.openChat}>
                <View style={styles.container}>
                    <View style={styles.winkIconContainer}>
                        <Image style={styles.winkIcon} source={Resources.images.winkIcon()} />
                    </View>
                    <View style={styles.textContainer}>
                        <Text style={styles.text}>{text}</Text>
                    </View>
                </View>
            </TouchableWithoutFeedback>
        );

    }
}

const WinkContentComponent = userController(WinkContent);

class Wink extends React.Component {
    render() {
        const userId = (this.props.notification && this.props.notification.recipient) || undefined;
        return (<WinkContentComponent id={userId} {...this.props} />);
    }
}

export default Wink;
