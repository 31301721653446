import React from 'react';
import PropTypes from 'prop-types';
import connect from '@sdv/connect';

import Model from '@sdv/domain/credits.account.balance/model';

const displayName = 'credits-accounts-balance.controller';

const GET_PROPERTY = `generated.${displayName}.get`;

export default function createController(Component, options = {}) {
    const userIdPropName = options.userIdPropName || 'id';
    const resultPropName = options.resultPropName || 'balance';

    class Controller extends React.Component {
        static displayName = displayName;
        static propTypes = {
            [userIdPropName]: PropTypes.string,
            [resultPropName]: PropTypes.object,
            [GET_PROPERTY]: PropTypes.func,
        };
        static contextTypes = {
            flux: PropTypes.object,
        };

        componentDidMount() {
            if (!this.props[resultPropName] || this.props[resultPropName].diamonds === undefined) {
                this.props[GET_PROPERTY] && this.props[GET_PROPERTY]();
            }
        }

        componentDidUpdate(prevProps) {
            if (shouldReconnect(prevProps, this.props)) {
                if (
                    !this.props[resultPropName] ||
                    this.props[resultPropName].diamonds === undefined
                ) {
                    this.props[GET_PROPERTY] && this.props[GET_PROPERTY]();
                }
            }
        }

        render() {
            const { ...props } = this.props;

            delete props[GET_PROPERTY];

            return <Component {...props} />;
        }
    }

    function getModels(flux, props) {
        const models = {};

        if (props[userIdPropName]) {
            models.balanceModel = flux.get(Model, props[userIdPropName]);
        }

        return models;
    }

    function mapModelsToProps(models) {
        const props = {};

        if (models.balanceModel) {
            props[resultPropName] = models.balanceModel.store.getState();
        }

        return props;
    }

    function mapModelsToActions(models) {
        const props = {};

        if (models.balanceModel) {
            props[GET_PROPERTY] = models.balanceModel.actions.get;
        }

        return props;
    }

    function shouldReconnect(props, newProps) {
        return props[userIdPropName] !== newProps[userIdPropName];
    }

    return connect(
        getModels,
        mapModelsToProps,
        mapModelsToActions,
        shouldReconnect,
    )(Controller);
}
