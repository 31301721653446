import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, TouchableWithoutFeedback, Image } from 'react-native';
import testId from 'dating-mobile/src/utils/test-id';
import MediaContent from './media-content';
import LetterContent from './letter-content';
import StickerContent from './sticker-content';
import CheerContent from './cheer-content';
import TextContent from './text-content';
import {
  DELIVERY_STATUS,
  MESSAGE_TYPES,
} from '../../../../models/common.messages/model';
import Resources from '../../../../resources';
import getDisplayDate from '../../../../utils/date-time-formatting';
import UserThumbnail from '../../../../user/common/views/thumbnail';

import styles from './styles';

class Message extends React.PureComponent {
  static displayName = 'messages.message';

  static propTypes = {
    bus: PropTypes.object,
    item: PropTypes.shape({
      tag: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      outgoing: PropTypes.bool.isRequired,
      sender: PropTypes.string.isRequired,
      recipient: PropTypes.string.isRequired,
      type: PropTypes.number.isRequired,
      placeholder: PropTypes.string,
      read: PropTypes.bool,
      timestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      status: PropTypes.number,
    }),
    onShow: PropTypes.func,
    onPress: PropTypes.func,
    attendee: PropTypes.string,
    isPaidChat: PropTypes.bool,
    lastOutgoingMessage: PropTypes.bool,
    isNotDelivered: PropTypes.bool,
    cheersSoundInChatEnabled: PropTypes.bool,
  };

  state = { timeVisible: false };

  componentDidMount() {
    const { onShow, item } = this.props;

    if (item.status === DELIVERY_STATUS.DELIVERED && onShow) {
      onShow(item.tag);
    }
  }

  onPress = () => {
    const { timeVisible } = this.state;
    const { onPress, item } = this.props;

    if (!styles.$deliveryTimeAlwaysVisible) {
      const newTimeVisible = !timeVisible;

      if (newTimeVisible) {
        setTimeout(() => {
          if (timeVisible) {
            this.setState({ timeVisible: false });
          }
        }, 5000);
      }
      this.setState({ timeVisible: newTimeVisible });
    }

    if (onPress) {
      onPress(item);
    }
  };

  getDeliveryStatusComponent() {
    const { item, lastOutgoingMessage } = this.props;

    if (!lastOutgoingMessage || item.status !== DELIVERY_STATUS.DELIVERED) {
      return null;
    }

    return (
      <View style={styles.delivery}>
        {item.read && (
          <UserThumbnail
            {...testId(
              Resources.strings['message-read-indicator-accessibility-label'],
            )}
            forceRetina
            style={styles.userThumbnail}
            userId={item.recipient}
            round
          />
        )}
        {!item.read && (
          <Text
            style={styles.deliveryText}
            {...testId(
              Resources.strings[
                'message-delivered-indicator-accessibility-label'
              ],
            )}
          >
            &#10003;
          </Text>
        )}
      </View>
    );
  }

  open = func => {
    func();
    this.onPress();
  };

  render() {
    const {
      item,
      bus,
      attendee,
      isPaidChat,
      isNotDelivered,
      cheersSoundInChatEnabled,
    } = this.props;
    const { timeVisible } = this.state;

    const containerStyle = item.outgoing
      ? styles.containerOutgoing
      : styles.containerIncoming;
    const contentStyle = item.outgoing
      ? styles.contentOutgoing
      : styles.contentIncoming;
    const timeIsVisible =
      (timeVisible || styles.$deliveryTimeAlwaysVisible) && !item.autoMessage;

    return (
      <View
        style={[
          containerStyle,
          item.autoReply ? styles.containerAutoReply : null,
        ]}
      >
        <TouchableWithoutFeedback onPress={this.onPress}>
          <View style={containerStyle}>
            <View style={contentStyle}>
              {isNotDelivered && (
                <Image
                  style={styles.attentionIcon}
                  source={Resources.images.attention()}
                  resizeMode="contain"
                />
              )}
              {item.type === MESSAGE_TYPES.TEXT && (
                <TextContent
                  message={item}
                  bus={bus}
                  isNotDelivered={isNotDelivered}
                />
              )}
              {item.type === MESSAGE_TYPES.PHOTO && (
                <MediaContent
                  message={item}
                  bus={bus}
                  openFunc={this.open}
                  attendee={attendee}
                  isPaidChat={isPaidChat}
                />
              )}
              {item.type === MESSAGE_TYPES.VIDEO && (
                <MediaContent
                  message={item}
                  bus={bus}
                  openFunc={this.open}
                  attendee={attendee}
                />
              )}
              {item.type === MESSAGE_TYPES.STICKER && (
                <StickerContent
                  message={item}
                  isNotDelivered={isNotDelivered}
                />
              )}
              {item.type === MESSAGE_TYPES.LETTER && (
                <LetterContent message={item} bus={bus} openFunc={this.open} />
              )}
              {item.type === MESSAGE_TYPES.CHEER && (
                <CheerContent
                  message={item}
                  cheersSoundInChatEnabled={cheersSoundInChatEnabled}
                />
              )}
              {this.getDeliveryStatusComponent()}
            </View>
            {item.status === DELIVERY_STATUS.DELIVERED && timeIsVisible && (
              <Text
                style={styles.date}
                {...testId(
                  Resources.strings['message-sending-time-accessibility-label'],
                )}
              >
                {getDisplayDate(item.timestamp)}
              </Text>
            )}
            {item.status === DELIVERY_STATUS.SENDING ||
              (item.status === DELIVERY_STATUS.UNPAID && timeIsVisible && (
                <Text style={styles.date}>
                  {Resources.strings['chat-screen-message-sending-status-text']}
                </Text>
              ))}
            {isNotDelivered && (
              <Text style={styles.notDeliveredLabel}>
                {Resources.strings['chat-screen-message-not-delivered-label']}
              </Text>
            )}
          </View>
        </TouchableWithoutFeedback>
      </View>
    );
  }
}

export default Message;
