import EStyleSheet from 'react-native-extended-stylesheet';

export const styles = EStyleSheet.create({
    termsDescriptionContainer: {
        marginHorizontal: 40,
        height: 80,
        '@media android': {
            marginHorizontal: 20,
        },
    },
});
