import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Image, TouchableOpacity } from 'react-native';
import withAspectRatio from 'dating-mobile/src/components/aspect-ratio';

import styles from './styles';

const AspectRatioTouchable = withAspectRatio(TouchableOpacity);

export default class StreamsDiscoveryAdsBanner extends PureComponent {
    static displayName = 'broadcasts.discovery.views.ads.view';

    static propTypes = {
        source: PropTypes.any,
        onPress: PropTypes.func,
    };

    render() {
        const { source, onPress } = this.props;

        return (
            <AspectRatioTouchable style={styles.container} onPress={onPress}>
                <Image style={styles.banner} source={source} />
            </AspectRatioTouchable>
        );
    }
}
