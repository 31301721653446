import EStyleSheet from 'react-native-extended-stylesheet';
import { I18nManager } from 'react-native';

const styles = EStyleSheet.create({
    container: {
        paddingVertical: 20,
        borderBottomWidth: 1,
        borderBottomColor: '$primaryBorderColor',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    title: {
        fontSize: 16,
        fontWeight: '600',
        color: '$primaryTextColor',
        fontFamily: '$brandFontFamily',
        flexShrink: 1,
    },

    arrow: {
        width: 10,
        height: 10,
        borderColor: '$primaryBorderColor',
        borderBottomWidth: 2,
        borderRightWidth: 2,
        transform: [{ rotate: I18nManager.isRTL ? '45deg' : '-45deg' }],
        marginHorizontal: 15,
    },
});

export default styles;
