import React from 'react';
import PropTypes from 'prop-types';
import { ActivityIndicator, StatusBar, StyleSheet, View } from 'react-native';

import identityController from '../../../models/identity/controller';
import authController from '../../../models/identity/controller/auth';
import SignInView from '../../views/auth/sing-in';
import createScreen from './hoc';
import Resources from '../../../resources';
import ImageButton from '../../../components/navigation/views/image-button';
import EndpointModal from '../../../components/endpoint-switcher/modal';
import { PASSWORD_RECOVER } from '../../../routing/router/constants';

import styles from './styles';

const SignInComponent = authController(SignInView);

class SignIn extends React.PureComponent {
  static displayName = 'authentication.signin.screen';

  static propTypes = {
    signIn: PropTypes.func.isRequired,
    navigation: PropTypes.object,
    isLoading: PropTypes.bool,
    error: PropTypes.object,
  };

  state = {
    isPending: false,
  };

  signIn = async (label, payload) => {
    const { signIn } = this.props;
    const { email, password } = payload;

    this.setState({ isPending: true });

    try {
      await signIn(email, password);
    } catch (error) {
      // TODO
    }

    this.setState({ isPending: false });
  };

  recoverPassword = () => {
    const { navigation } = this.props;

    navigation.navigate(PASSWORD_RECOVER);
  };

  render() {
    const { isLoading, error } = this.props;
    const { isPending } = this.state;

    return (
      <View style={styles.content}>
        <StatusBar
          barStyle={styles.$statusBarStyle}
          backgroundColor={styles.$statusBarBackgroundColor}
        />
        <SignInComponent
          error={error}
          isPending={isPending}
          onSubmit={this.signIn}
          recoverPassword={this.recoverPassword}
        />
        {!!isLoading && (
          <View style={styles.activityIndicatorContainer}>
            <ActivityIndicator animating />
          </View>
        )}
        <EndpointModal />
      </View>
    );
  }
}

const SignInScreen = createScreen(identityController(SignIn));

SignInScreen.navigationOptions = () => ({
  headerStyle: styles.header,
  headerBackImage: () => (
    <ImageButton
      image={Resources.images.backIcon()}
      tint={StyleSheet.flatten(styles.tint).color}
    />
  ),
});

export default SignInScreen;
