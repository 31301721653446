import { cloneNotNull } from '@sdv/commons/utils/object';

class MailboxActions {

    /**
     * Mailbox Actions
     * @param {string} storeId Id of according store
     * @param {string} userId Id of user
     * @param options actions options
     * @param {boolean} [options.read] deal with read letters
     * @param {boolean} [options.replied|null] deal with answered letters
     * @param {boolean} [options.introductory] deal with intro letters
     * @param {string} [options.direction = in] mailbox direction (in or out)
     * @param {string} [options.participant] attendee id
     */
    constructor(storeId, userId, options) {
        this.options = { direction: 'in', ...options };
        this.storeId = storeId;
        this.userId = userId;
    }

    /**
     * Get Letters from Api
     * @param {number} [select=20] How many of letters to get
     * @param {boolean} [reload=false] True if need to reload letters from start
     */
    getLetters = (select = 20, reload = false) => (dispatch, flux) => {
        const state = flux.getStore(this.storeId).getState();

        const lettersLength = !!this.options.introductory
            ? state.letters.filter(letter => letter.introductory).length
            : state.letters.length;

        const params = {
            ...state.params,
            select: select + 1,
            omit: reload ? 0 : lettersLength, ...this.options
        };
        const query = Object.assign({}, cloneNotNull(params));
        flux.api.dialogs.letters.list(this.userId, query, (error, response) => {

            if (!error) {
                const letters = response ? response.letters || [] : [];
                return dispatch({
                    endReached: letters.length <= select,
                    letters: letters.slice(0, select)
                });
            } else {
                return dispatch(null, error);
            }
        });

    };
}

export default MailboxActions;
