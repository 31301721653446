import qs from 'qs';

export default function (id) {
    return `dialogs-mailbox-one-attendee.${id}`;
};

export function buildId(userId, attendeeId) {
    return qs.stringify({userId: userId, attendeeId: attendeeId})
}

export function parseId(id) {
    return qs.parse(id);
}
