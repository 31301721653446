import EventEmitter from 'eventemitter3';
import qs from 'qs';
import flux from '@sdv/domain/app/flux';
import IdentityModel from '@sdv/domain/identity/model';
import PresenceModel from '@sdv/domain/user.presence';
import BalanceRefiller, {
  PAYMENT_REASON,
} from '@sdv/domain/app/balance-refiller';
import CallingModel from 'dating-mobile/src/models/call.calling';
import ConfigModel from '../config/model';
import Gate from './legacy/gate';

class CallsApi extends EventEmitter {
  isReady = false;

  isPaymentModalOpened = false;

  constructor() {
    super();
    this.identityModel = flux.get(IdentityModel);
    this.configModel = flux.get(ConfigModel);
    this.identityModel.store.listen(this.updateUserId);
    this.configModel.store.listen(this.updateConfig);
    this.userId = this.identityModel.store.getState().id;
    this.config = this.configModel.store.getState();

    this.updateGate();

    flux.events.addListener('event.dialogs.media.messages.added', event => {
      this.emit('event.dialogs.media.messages.added', event);
    });

    this.addListener('event.call.payment.required', ({ recipient, sender }) => {
      if (this.isPaymentModalOpened) {
        return;
      }

      this.isPaymentModalOpened = true;

      BalanceRefiller.purchaseCallsPack(
        PAYMENT_REASON.CALL,
        () => {
          const modelId = qs.stringify({ user: sender, attendee: recipient });
          const callingModel = flux.get(CallingModel, modelId);

          // TODO
          callingModel?.actions?.dial({ audioOnly: true });

          this.isPaymentModalOpened = false;
        },
        () => {
          this.isPaymentModalOpened = false;
        },
      );
    });

    this.addListener('event.presence.updated', (userId, presence) => {
      const model = flux.get(PresenceModel, userId);

      model.actions.actualize(presence);
    });
  }

  updateUserId = state => {
    this.userId = state.id;

    this.updateGate();
  };

  updateConfig = state => {
    this.config = state;

    this.updateGate();
  };

  updateGate() {
    if (
      this.userId === this.prevUserId &&
      JSON.stringify(this.config?.callConfig) ===
        JSON.stringify(this.prevConfig?.callConfig)
    ) {
      return;
    }

    if (this.gate) {
      this.gate.removeAllListeners();
      this.gate = null;
      this.isReady = false;
    }

    if (this.userId && this.config?.callConfig) {
      this.gate = new Gate(
        this.userId,
        flux,
        { media: this.config.callConfig },
        this,
      );

      this.isReady = true;
      this.emit('call.api.ready');
    }

    this.prevUserId = this.userId;
    this.prevConfig = this.config;
  }
}

const callsApi = new CallsApi();

export default callsApi;
