import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    textStyle: {
        fontFamily: '$brandFontFamily',
        color: '$brandTextColor',
        fontSize: 17
    }
});

export default styles;
