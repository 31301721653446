function createMediaActions(id) {

    class Actions {

        actualize = (media) => (dispatch) => {
            setTimeout(function () {
                dispatch(media);
            }, 0);
        };

        addPhotoTag = (basename, tag) => (dispatch, flux) => {

            flux.api.users.photos.tags.put(id, basename, tag, (error) => {

                if (error) {
                    return dispatch(null, error);
                }

                return dispatch({
                    tag: tag,
                    basename: basename
                });
            });

        };

        removePhotoTag = (basename, tag) => (dispatch, flux) => {

            flux.api.users.photos.tags.delete(id, basename, tag, (error) => {

                if (error) {
                    return dispatch(null, error);
                }

                return dispatch({
                    tag: tag,
                    basename: basename
                });
            });

        };

        putPhotos = (basenames) => (dispatch, flux) => {

            flux.api.users.photos.put(id, basenames, (error) => {

                if (error) {
                    return dispatch(null, error);
                }

                return dispatch(basenames);
            });

        };

        putVideos = (basenames) => (dispatch, flux) => {

            flux.api.users.videos.put(id, basenames, (error) => {

                if (error) {
                    return dispatch(null, error);
                }

                return dispatch(basenames);
            });

        };

        get = () => async (dispatch, flux) => {

            const headers = { accept: 'application/vnd.sdv.filetags+json' };

            const photoResource = flux.api.variable('user-id') === id
                ? flux.api.users.photos.origin
                : flux.api.users.photos;

            const videoResource = flux.api.variable('user-id') === id
                ? flux.api.users.videos.origin
                : flux.api.users.videos;

            try {
                const photos = await (new Promise((resolve, reject) => {
                    photoResource.list(id, (error, response) => {
                        if (error) {
                            return reject(error);
                        }
                        return resolve(response.files);
                    }, headers);
                }));

                const videos = await (new Promise((resolve, reject) => {
                    videoResource.list(id, (error, response) => {
                        if (error) {
                            return reject(error);
                        }
                        return resolve(response.files);
                    }, headers);
                }));

                dispatch(photos.concat(videos).filter(file => !file.tags || file.tags.indexOf('vr') < 0));
            } catch (error) {
                dispatch(null, error);
            }

        }

    }

    Actions.displayName = createMediaActions.getDisplayName(id);

    return Actions;
}

createMediaActions.getDisplayName = function (id) {
    return `user-media.${id}`;
};

export default createMediaActions;
