import React from 'react';
import PropTypes from 'prop-types';
import { TimeInterval } from '@sdv/commons/utils/time-interval';

export default function controller(Component) {
  class Controller extends React.Component {
    static displayName = 'payment.views.package-list.item.controller';

    static propTypes = {
      product: PropTypes.shape({
        creditsAmount: PropTypes.number.isRequired,
        price: PropTypes.shape({
          includingTax: PropTypes.number,
          currency: PropTypes.string,
          localizedIncludingTax: PropTypes.string,
        }),
      }),
      invoices: PropTypes.arrayOf(
        PropTypes.shape({
          sku: PropTypes.string.isRequired,
        }),
      ),
    };

    render() {
      const { invoices } = this.props;
      const boostTime = invoices?.[0]?.boostTime;
      const amountInMinutes = boostTime
        ? Math.floor((boostTime * TimeInterval.second) / TimeInterval.minute)
        : null;

      return <Component {...this.props} amountInMinutes={amountInMinutes} />;
    }
  }

  return Controller;
}
