import React from 'react';
import { Image, View, Text, Platform } from 'react-native';
import PropTypes from 'prop-types';
import { NavigationActions } from 'react-navigation';
import { MyChatsMinimized } from 'dating-mobile/src/dialogs/chats/screens/my-chats';
import AppLoading from 'dating-mobile/src/bootstrap/views/app-loading';
import VideoDialog from 'dating-mobile/src/components/video/dialog';
import AlertDialog from 'dating-mobile/src/components/alert';
import Resources from 'dating-mobile/src/resources';
import NotificationPopup from 'dating-mobile/src/notifications/views/popup';
import NavigationPanel from 'dating-mobile/src/routing/views/navigation-panel';
import Specials from 'dating-mobile/src/routing/views/specials';
import Services from 'dating-mobile/src/services';
import shouldUseWebLayout from 'dating-mobile/src/utils/web-layout';
import testId from 'dating-mobile/src/utils/test-id';
import { OnboardingProgressBar } from 'dating-mobile/src/authentication/views/onboarding-progress-bar';
import { RateApplicationModal } from 'dating-mobile/src/rate-application/modal';
import { RegistrationBonusModal } from 'dating-mobile/src/stepped-registration/views/registration-bonus-modal';
import CardPayment from 'dating-mobile/src/dialogs/payment/card';
import UrlService from 'dating-mobile/src/services/url';

import * as ROUTES from './constants';
import Navigator from '../navigator';
import RouterComponent from './router';

import styles from './styles';

export class Router extends React.PureComponent {
  static displayName = 'routing.router';

  static propTypes = {
    config: PropTypes.object,
  };

  state = {
    isBackgroundVisible: false,
    isNavPanelVisible: false,
  };

  componentDidMount() {
    if (Platform.OS === 'web') {
      if (shouldUseWebLayout()) {
        Navigator.listen(this.handleNavigatorUpdate);
      }
      const params = UrlService.parseQuery(window.location.search);

      if (params.afid) {
        window.localStorage.setItem('afid_info', JSON.stringify(params));
      }
      Navigator.addOnNavigatorReadyListener(this.onNavigatorReady);
    }
  }

  componentWillUnmount() {
    if (shouldUseWebLayout()) {
      Navigator.unlisten(this.handleNavigatorUpdate);
    }

    if (Platform.OS === 'web')
      Navigator.removeOnNavigatorReadyListener(this.onNavigatorReady);
  }

  onNavigatorReady() {
    const { pathname } = window.location;
    const originalStack = pathname.slice(pathname.lastIndexOf('/') + 1);
    const initParams = Navigator.getParams();
    const shouldNotNavigate =
      pathname.includes(ROUTES.MODAL) ||
      pathname.includes(ROUTES.AUTH_LOADING) ||
      pathname.includes('email-confirmation');

    if (!pathname.includes(ROUTES.PAYMENT_PROCESSING)) {
      Navigator.navigate(ROUTES.AUTH_LOADING, {
        initScreen: shouldNotNavigate ? undefined : originalStack,
        initParams: shouldNotNavigate ? undefined : initParams,
      });
    }
  }

  handleNavigatorUpdate = ({ stack, previousStack }) => {
    const [, currentStack] = stack;
    const prevStack = previousStack[1] || previousStack[0];
    const isAuthStack = currentStack?.includes(ROUTES.AUTH);
    const isBackgroundVisible =
      Boolean(currentStack) &&
      (currentStack === ROUTES.AUTH ||
        (Boolean(prevStack) &&
          prevStack.includes(ROUTES.AUTH) &&
          currentStack === ROUTES.AUTH));

    if (currentStack === prevStack || stack[0] === stack[1]) return;

    this.setState(() => ({
      isBackgroundVisible,
      isNavPanelVisible: !isAuthStack && !isBackgroundVisible,
    }));
  };

  openTerms(uri, title) {
    Navigator.navigate(ROUTES.TERMS, {
      title,
      uri,
    });
  }

  startChat(params) {
    Navigator.navigate(
      ROUTES.CONTACTS_TAB,
      null,
      NavigationActions.navigate({ routeName: ROUTES.CHAT, params }),
    );
  }

  render() {
    const { config } = this.props;
    const { isBackgroundVisible, isNavPanelVisible } = this.state;

    let routing = <AppLoading />;

    if (config) {
      const appContainer = <RouterComponent config={config} />;
      const app = shouldUseWebLayout() ? (
        <View style={styles.appContainer}>{appContainer}</View>
      ) : (
        appContainer
      );

      routing = (
        <View style={styles.rootContainer}>
          {isNavPanelVisible && <NavigationPanel openLink={this.openTerms} />}
          {app}
          {isNavPanelVisible && (
            <View style={styles.navPanelWrapper}>
              <Specials />
              <MyChatsMinimized
                startChat={this.startChat}
                isFocused
                forceShow={false}
              />
            </View>
          )}

          {isBackgroundVisible && (
            <View style={styles.authBackgroundContainer}>
              <Image
                resizeMode="cover"
                source={Resources.images.splash()}
                style={styles.authBackgroundImage}
                {...testId('Background')}
              />

              <View style={styles.splashTextContainer}>
                <Text style={styles.splashText}>
                  {Resources.strings['initial-screen-splash-web-title']}
                </Text>
              </View>
            </View>
          )}
          <VideoDialog
            ref={videoDialogRef => Navigator.setVideoDialog(videoDialogRef)}
          />
          <CardPayment
            ref={cardPaymentRef => Navigator.setCardPayment(cardPaymentRef)}
          />
          <NotificationPopup />
          <RateApplicationModal />
          <RegistrationBonusModal />
          <Services />
          <AlertDialog
            ref={alertDialogRef => Navigator.setAlertDialog(alertDialogRef)}
          />
          <OnboardingProgressBar style={styles.onboardingBar} />
        </View>
      );
    }

    return routing;
  }
}

export default Router;
