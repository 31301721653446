import { of, merge, defer, bindCallback } from 'rxjs';
import {
  switchMap,
  filter,
  delay,
  startWith,
  distinctUntilChanged,
  tap,
  withLatestFrom,
  shareReplay,
  map,
  take,
  timeoutWith,
  mapTo,
} from 'rxjs/operators';
import { Config } from '@sdv/domain/app/config';
import AppFocus from '@sdv/domain/app/application/focus';
import { Persistence } from '@sdv/domain/app/persistence';
import { singleton } from '@sdv/commons/utils/singleton';
import flux from '@sdv/domain/app/flux';
import ServerDate from '@sdv/domain/api/date';
import EventsModel, {
  getId,
  EVENT_TYPE,
} from 'dating-mobile/src/models/user.events/model';
import MallModel from 'dating-mobile/src/models/credits.mall/model';

import { ProfileBooster } from './index';
import UserTagsModel from '../tags';
import { UserTags } from '../tags/user-tags';
import { Stock } from '../../payment/stock';

const boostActivatedKey = 'boostActivated';
const boostPurchasedKey = 'boostPurchased';
const MILLISECONDS_IN_SECOND = 1000;

const isDiscountEndTimeValid = discountEndTime => {
  if (!discountEndTime) {
    return false;
  }

  const timeParsed = discountEndTime.valueOf();

  return !Number.isNaN(timeParsed) && timeParsed > Date.now();
};

export class BoostExtendNotification {
  static shared = singleton(userId => new BoostExtendNotification(userId));

  constructor(userId) {
    const persistence = new Persistence('boost-extend-notification', userId);
    const { endTime } = ProfileBooster.shared(userId);
    const boostExtendNotificationEnabled = Config.shared().boostExtendNotificationEnabled.pipe(
      filter(Boolean),
    );

    const boostActivated = merge(
      persistence.load(boostActivatedKey).pipe(filter(Boolean)),
      endTime.pipe(
        map(Boolean),
        tap(active => persistence.store(boostActivatedKey, active).subscribe()),
        filter(Boolean),
      ),
    );

    const boostDeactivated = endTime.pipe(filter(value => !value));

    const applicationIsFocused = AppFocus.shared().focused.pipe(
      filter(focused => focused),
      distinctUntilChanged(),
    );

    const shouldStartNotifying = boostExtendNotificationEnabled.pipe(
      switchMap(() => boostActivated),
      switchMap(() => boostDeactivated),
      switchMap(() => applicationIsFocused),
      shareReplay(1),
    );

    this.extendOfferActive = merge(
      shouldStartNotifying.pipe(
        withLatestFrom(Config.shared().boostExtendOfferDuration),
        switchMap(([, boostExtendOfferDuration]) => {
          return of(false).pipe(
            delay(boostExtendOfferDuration),
            startWith(true),
          );
        }),
        startWith(false),
        distinctUntilChanged(),
      ),
      boostActivated.pipe(map(activated => !activated)),
    );

    const boostDiscountEvent = defer(() => {
      const model = flux.get(
        EventsModel,
        getId(userId, EVENT_TYPE.BOOST_DISCOUNT),
      );

      model.actions.get();

      return model.store.rxState();
    }).pipe(
      map(({ events: [event] }) => event),
      shareReplay(1),
    );

    const serverDate = bindCallback(ServerDate(flux.api).now);
    const timeDelta = serverDate().pipe(
      timeoutWith(3000, of(null)),
      map(date => (date ? Date.now() - date : 0)),
    );

    this.discountEndTime = boostDiscountEvent.pipe(
      map(event =>
        event
          ? new Date(
              new Date(event.freshed).valueOf() +
                event.payload?.ttl * MILLISECONDS_IN_SECOND,
            )
          : null,
      ),
      map(time => (isDiscountEndTimeValid(time) ? time : null)),
    );

    this.discountInvoice = boostDiscountEvent.pipe(
      switchMap(event =>
        event
          ? Stock.shared(userId).preloadedPackagesInStock.pipe(
              take(2),
              map(packages =>
                packages
                  .map(([, invoices]) => invoices && invoices[0])
                  .filter(Boolean)
                  .find(it => it.sku === event.payload?.sku),
              ),
            )
          : of(null),
      ),
    );

    this.userIsBoosted = UserTagsModel.shared()
      .tagsOf(userId)
      .pipe(
        map(tags => tags.indexOf(UserTags.Boosted) >= 0),
        distinctUntilChanged(),
      );

    this.isBoostPurchased = merge(
      persistence.load(boostPurchasedKey).pipe(map(Boolean)),
      flux
        .get(MallModel, userId)
        .store.rxState()
        .pipe(
          filter(({ lastPurchase }) => lastPurchase?.product?.boostTime),
          mapTo(true),
          tap(purchased =>
            persistence.store(boostPurchasedKey, purchased).subscribe(),
          ),
        ),
    ).pipe(distinctUntilChanged(), shareReplay(1));
  }
}
