import React from 'react';
import PropTypes from 'prop-types';
import { Image, TouchableOpacity, View, StyleSheet } from 'react-native';
import styles from './styles';
import withIdentityId from 'dating-mobile/src/models/identity/controller';
import Profile from 'dating-mobile/src/user/common/views/profile';
import Resources from 'dating-mobile/src/resources';
import ReportButton from 'dating-mobile/src/components/report-button';
import StreamStatus from '../stream-status';
import withConfigValue from 'dating-mobile/src/components/config-value';
import ChatButton from 'dating-mobile/src/dialogs/messages/views/chat-button/view';
import FollowButton from 'dating-mobile/src/user/common/views/favorite-labeled-button';
import { streamResource } from '@sdv/domain/resources/remote';

class StreamProfile extends React.PureComponent {
    static displayName = 'dialogs.stream.info.user-profile';
    static propTypes = {
        identity: PropTypes.string,
        streamerId: PropTypes.string,
        onChatPress: PropTypes.func,
        onClose: PropTypes.func,
        privateChatPopupEnabled: PropTypes.bool,
    };

    getIdentity = () => {
        return this.props.identity && this.props.identity.id;
    };

    getStreamer = () => {
        return this.props.streamerId;
    };

    onClosePressed = () => {
        this.props.onClose && this.props.onClose();
    };

    onChatPress = () => {
        this.props.onChatPress && this.props.onChatPress();
    };

    render() {
        const streamer = this.getStreamer();
        const identity = this.getIdentity();

        let showViewerButtons = !!streamer && !!identity && identity !== streamer;
        return (
            <View style={styles.container}>
                <View style={styles.scroll}>
                    <View style={styles.profileLineContainer}>
                        <ReportButton
                            id={streamer}
                            tint={StyleSheet.flatten(styles._headerTitle).color}
                            showIcon={true}
                            reportedContent={streamResource(streamer)}
                        />

                        <TouchableOpacity style={styles.closeButton} onPress={this.onClosePressed}>
                            <Image
                                style={styles.closeImage}
                                source={Resources.images.closeIcon()}
                            />
                        </TouchableOpacity>
                    </View>
                    <View style={styles.statusContainer}>
                        <View style={styles.profile} pointerEvents={'box-none'}>
                            <Profile
                                ageIsVisible={false}
                                hideOnlineIndicator={true}
                                userId={streamer}
                                usernameStyle={styles.profileName}
                            />
                        </View>
                        <StreamStatus id={streamer} />
                        {showViewerButtons && (
                            <View style={styles.buttonsContainer}>
                                <FollowButton
                                    holderId={identity}
                                    targetId={streamer}
                                    titleStyle={styles.actionButtonTitle}
                                    followingStyle={styles.followButtonFollowing}
                                    notFollowingStyle={styles.followButtonNotFollowing}
                                />
                                {!!this.props.privateChatPopupEnabled && (
                                    <View style={styles.chatButtonContainer}>
                                        <ChatButton
                                            onPress={this.onChatPress}
                                            style={styles.chatButton}
                                            titleStyle={styles.actionButtonTitle}
                                            viewStyle={'text'}
                                        />
                                    </View>
                                )}
                            </View>
                        )}
                    </View>
                </View>
            </View>
        );
    }
}

const StreamerInfo = withConfigValue(withIdentityId(StreamProfile), {
    privateChatPopupEnabled: 'stream-screen.private-chat-popup-enabled',
});

export default StreamerInfo;
