import { Platform } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  arrowStyle: {
    width: 12,
    height: 8,
    tintColor: '$secondaryBackgroundColor',
    marginLeft: 8,
    transform: [
      {
        rotate: '0deg',
      },
      {
        translateY: -2,
      },
    ],
  },

  container: {
    height: 70,

    width: Platform.select({
      web: '50%',
      default: '70%',
    }),
    minWidth: 260,
    marginBottom: 150,
  },

  iconStyle: {
    height: 12,
    width: 12,
    marginRight: 6,
    tintColor: 'grey',
  },
  cardIconStyle: {
    height: 20,
    width: 20,
  },
});

export default styles;
