function createActions(id) {
  class Actions {
    rotateCamera = () => dispatch => {
      dispatch();
    };

    setVideoEnabled = enabled => dispatch => {
      dispatch(enabled);
    };

    setAudioMuted = muted => dispatch => {
      dispatch(muted);
    };

    setSpeakerphoneOn = speakerphoneOn => dispatch => {
      dispatch(speakerphoneOn);
    };
  }

  Actions.displayName = createActions.getDisplayName(id);

  return Actions;
}

createActions.getDisplayName = function(id) {
  return `call-media.${id}`;
};

export default createActions;
