import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $statusBarStyle: '$brandStatusBarStyle',
    $statusBarBackgroundColor: '$brandColor',

    header: {
        backgroundColor: '$brandColor',
        borderBottomColor: '$brandColor',
        shadowColor: '$brandColor',
        shadowRadius: 0,
        shadowOpacity: 0,
        elevation: 0,
        shadowOffset: {
            height: 0,
        }
    },
    title: {
        color: '$brandTextColor',
        fontFamily: '$brandFontFamily',
        fontWeight: 'bold',
        fontSize: 24,
        textAlign: 'center',
        marginTop: 40,
    },
    container: {
        width: '100%',
        height: '100%',
        backgroundColor: '$brandColor',
    },
    buttonsContainer: {
        flex: 1,
        width: '100%',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'center'
    },
    button: {
        width: 72,
        height: 72,
        borderRadius: 36,
        marginTop: 85,
        marginLeft: 12,
        marginRight: 12,
        alignItems: 'center',
        justifyContent: 'center'
    },
    nextButton: {
        marginBottom: 64,
        paddingLeft: 40,
        paddingRight: 40,
    },
    buttonImage: {

    },
    selectedMaleImageContainer: {
        backgroundColor: '$minorAccentColor'
    },
    selectedFemaleImageContainer: {
        backgroundColor: '$femaleAccentColor'
    },
    unselectedImageContainer: {
        backgroundColor: '$unselectedColor'
    },
    selectedImage: {
        tintColor: 'white'
    },
    unselectedImage: {
        tintColor: '$disabledTextColor'
    }
});

export default styles;
