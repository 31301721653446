import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';
import { DialogApproval } from '@sdv/domain/dialogs/dialog-approval';

export default function createController(Component) {
    class DialogApprovalBannerController extends PureComponent {
        static displayName = 'dialogs.messages.views.dialog-approval-banner.controller';

        static propTypes = {
            userId: PropTypes.string,
            attendeeId: PropTypes.string,
            onCloseDialog: PropTypes.func,
        };

        state = { dialogIsApproved: true };

        componentDidMount() {
            this.subscribe();
        }

        componentDidUpdate(prevProps) {
            const { userId, attendeeId } = this.props;

            if (userId !== prevProps.userId || attendeeId !== prevProps.attendeeId) {
                this.unsubscribe();
                this.subscribe();
            }
        }

        componentWillUnmount() {
            this.unsubscribe();
        }

        onContinuePress = () => {
            if (this.dialogApproval) {
                this.dialogApproval.continueChat();
            }
        };

        onReportPress = () => {
            const { onCloseDialog } = this.props;

            if (this.dialogApproval) {
                this.dialogApproval.reportUser();
            }

            if (onCloseDialog) {
                onCloseDialog();
            }
        };

        unsubscribe() {
            if (this.approvalSubscription) {
                this.approvalSubscription.unsubscribe();
            }
        }

        subscribe() {
            const { userId, attendeeId } = this.props;

            if (!userId || !attendeeId) {
                return;
            }

            this.dialogApproval = DialogApproval.shared(userId, attendeeId);
            this.approvalSubscription = this.dialogApproval.dialogIsApproved.subscribe(
                dialogIsApproved => this.setState({ dialogIsApproved }),
            );
        }

        render() {
            const { dialogIsApproved } = this.state;

            if (dialogIsApproved) {
                return null;
            }

            return (
                <Component
                    onContinuePress={this.onContinuePress}
                    onReportPress={this.onReportPress}
                />
            );
        }
    }

    return withIdentityId(DialogApprovalBannerController);
}
