import EStyleSheet from 'react-native-extended-stylesheet';
import shouldUseWebLayout from 'dating-mobile/src/utils/web-layout';

const isWebLayout = shouldUseWebLayout();
const indicatorSize = shouldUseWebLayout() ? 16 : 12;

const styles = EStyleSheet.create({
  onlineIndicator: {
    width: indicatorSize,
    height: indicatorSize,
    marginTop: isWebLayout ? 5 : 0,
    borderRadius: isWebLayout ? 8 : 6,
    borderWidth: 1,
    borderColor: 'white',
    '@flavor dma, udates': {
      borderWidth: 0,
    },
  },

  onlineModifier: {
    backgroundColor: '$accentColor',
    '@flavor dma, udates': {
      backgroundColor: '$minorAccentColor',
    },
    '@flavor secretly': {
      backgroundColor: '$brandColor',
    },
  },

  offlineModifier: {
    backgroundColor: 'gray',
    '@flavor udates': {
      backgroundColor: 'transparent',
    },
  },

  icon: {
    width: 15,
    height: 19,
    marginTop: isWebLayout ? 3 : 0,
  },

  iconOnlineModifier: {
    tintColor: '$accentColor',
    '@flavor dma, udates': {
      tintColor: '$minorAccentColor',
    },
  },

  iconOfflineModifier: {
    tintColor: 'gray',
    '@flavor udates': {
      tintColor: 'transparent',
    },
  },

  onlineText: {
    color: '#07a084',
  },

  offlineText: {
    color: '$secondaryTextColor',
    '@flavor udates': {
      color: 'transparent',
    },
  },
});

export default styles;
