import React from 'react';
import PropTypes from 'prop-types';
import { Text, View, Image } from 'react-native';
import Photo, { PHOTO_TRANSFORMATION_TYPE } from 'dating-mobile/src/components/photo';
import withAspectRatio from 'dating-mobile/src/components/aspect-ratio';
import styles from './styles';
import Resources from 'dating-mobile/src/resources';

const AspectRatioView = withAspectRatio(View);

class StreamerPreview extends React.PureComponent {
    static displayName = 'streamer.preview';
    static propTypes = {
        user: PropTypes.shape({
            id: PropTypes.string,
            thumbnail: PropTypes.string,
            'thumbnail-pending': PropTypes.string,
            name: PropTypes.string,
            viewers: PropTypes.number,
        }),
    };

    constructor(props) {
        super(props);
    }

    render() {
        let { ...props } = this.props.user;
        const basename = props['thumbnail-pending'] || props.thumbnail;

        return (
            <View style={styles.container}>
                <View style={styles.content}>
                    <AspectRatioView style={styles.thumbnailContainer}>
                        <Photo
                            style={styles.thumbnail}
                            userId={props.id}
                            basename={basename}
                            ransformation={PHOTO_TRANSFORMATION_TYPE.DETECT_FACE}
                            placeholder={'avatar'}
                            resizeMode={'contain'}
                        />
                    </AspectRatioView>
                    <View style={styles.infoContainer}>
                        <Text style={styles.name} numberOfLines={1}>
                            {props.name}
                        </Text>
                        <View style={styles.viewersContainer}>
                            <Image
                                style={styles.viewersIcon}
                                source={Resources.images.viewersIcon()}
                            />
                            <Text style={styles.viewersCountText}>{this.props.viewers}</Text>
                        </View>
                    </View>
                </View>
            </View>
        );
    }
}

export function getHeight(width) {
    const thumbnailHeight = width / styles._thumbnailContainer.aspectRatio;
    let height = thumbnailHeight + styles._container.paddingVertical * 2;
    if (styles._infoContainer.position !== 'absolute') {
        height += styles._infoContainer.height;
    }
    return height;
}

export default StreamerPreview;
