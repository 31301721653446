import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    video: {
        flex: 1,
        backgroundColor: '#010101'
    }
});

export default styles;
