import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        backgroundColor: 'red',
        width: '100%',
        paddingTop: 100,
        marginTop: -100,
        paddingBottom: 5,
    },
    textContainer: {
        flex: 1,
    },
    closeImage: {
        width: 12,
        height: 12,
    },
    closeImageContainer: {
        marginHorizontal: 5,
        alignSelf: 'flex-end',
        width: 30,
        height: 30,
        alignItems: 'center',
        justifyContent: 'center',
    },
    descriptionText: {
        marginTop: 0,
        fontFamily: '$brandFontFamily',
        fontWeight: '500',
        fontSize: 12,
        color: 'white',
        textAlign: 'center',
    },
});

export default styles;
