import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import url from 'url';
import ImagePicker from 'react-native-image-crop-picker';

import ObjectStorage from '@sdv/domain/object.storage/model';
import { userPhotoPath } from 'dating-mobile/src/resources/remote';
import withConfigValue from 'dating-mobile/src/components/config-value';
import UserModel from '@sdv/domain/user/model';
import Resources from 'dating-mobile/src/resources';
import MediaModel from '../model';
import { TAGS } from './media-tags';

const PICKER_CANCEL_ERROR_KEY = 'E_PICKER_CANCELLED';

const findByBaseName = (media, basename) =>
  media?.find(item => item.basename === basename);

function createControlledComponent(Component, options = {}) {
  const cropperOptions = {
    width: 512,
    height: 512,
    cropperCircleOverlay: true,
    cropperToolbarTitle: Resources.strings['set-thumbnail-title'],
    cropperChooseText: Resources.strings.choose,
    cropperCancelText: Resources.strings.cancel,
    ...options.cropperOptions,
  };

  class ControlledComponent extends PureComponent {
    static displayName = 'user.media.controller';

    static contextTypes = {
      flux: PropTypes.object,
    };

    static propTypes = {
      id: PropTypes.string,
      forwardedRef: PropTypes.func,
      thumbnailCroppingEnabled: PropTypes.bool,
      host: PropTypes.string,
    };

    static objectStorageId = userId => {
      return `user-media:${userId}`;
    };

    state = {};

    componentDidMount() {
      this.subscribe();
    }

    componentDidUpdate(prevProps) {
      const { id } = this.props;

      if (prevProps.id !== id) {
        this.unsubscribe();
        this.subscribe();
      }
    }

    componentWillUnmount() {
      this.unsubscribe();
    }

    onMediaModelStateChanged = state => {
      this.setState(state);
    };

    getMediaByName(basename) {
      const { media } = this.state;

      return findByBaseName(media, basename);
    }

    getAllMediaByTag(tag) {
      const { media } = this.state;

      return media ? media.filter(item => item.tags?.includes(tag)) : [];
    }

    togglePrivate = async basename => {
      const item = this.getMediaByName(basename);

      if (!item) {
        throw new Error('Media not found');
      }

      if (item.tags?.includes(TAGS.hidden)) {
        await this.removeTag(basename, TAGS.hidden);
      } else {
        await this.addTag(basename, TAGS.hidden);
      }
    };

    addTag = (basename, tag) => {
      return new Promise((resolve, reject) => {
        const item = this.getMediaByName(basename);

        if (!item) {
          reject(new Error('Media not found'));

          return;
        }

        this.media.actions.addPhotoTag(basename, tag, error => {
          if (error) {
            reject(error);

            return;
          }

          resolve();
        });
      });
    };

    removeTag = (basename, tag) => {
      return new Promise((resolve, reject) => {
        const item = this.getMediaByName(basename);

        if (!item) {
          reject(new Error('Media not found'));

          return;
        }

        this.media.actions.removePhotoTag(basename, tag, error => {
          if (error) {
            reject(error);

            return;
          }

          resolve();
        });
      });
    };

    setAsThumbnailBase = async basename => {
      await this.removeTag(basename, TAGS.hidden);
      await this.addTag(basename, TAGS.thumbnail);

      this.user.actions.actualize({ 'thumbnail-pending': basename });

      const { media } = this.state;

      // TODO: when Singular starts supports promo users, it could be problem, as promoters can set several thumbnails.
      const oldThumbnails = media
        ? media.filter(
            item =>
              item.basename !== basename && item.tags?.includes(TAGS.thumbnail),
          )
        : [];

      return Promise.all(
        oldThumbnails.map(item =>
          this.removeTag(item.basename, TAGS.thumbnail),
        ),
      );
    };

    setAsThumbnail = async basename => {
      const { thumbnailCroppingEnabled, id, host } = this.props;

      if (thumbnailCroppingEnabled) {
        const thumbnails = this.getAllMediaByTag(TAGS.thumbnail);
        const thumbnailsOrigin = this.getAllMediaByTag(TAGS.thumbnailOrigin);
        const isThumbnailOrigin = findByBaseName(thumbnailsOrigin, basename);

        const { width, height, mime, path } = await ImagePicker.openCropper({
          ...cropperOptions,
          path: url.resolve(host, userPhotoPath(id, basename)),
        });

        if (thumbnailsOrigin.length && thumbnails.length) {
          await Promise.all(
            thumbnails.map(thumbnail =>
              this.deletePhotosBase([thumbnail.basename]),
            ),
          );
        }

        const newThumbnailBasename = await this.addPhotoBase({
          width,
          height,
          type: mime,
          uri: `file://${path}`,
        });

        const thumbnailsOriginToClean = thumbnailsOrigin.filter(
          item => item.basename !== basename,
        );

        if (thumbnailsOriginToClean.length) {
          await Promise.all(
            thumbnailsOriginToClean.map(thumbnailOrigin =>
              this.removeTag(thumbnailOrigin.basename, TAGS.thumbnailOrigin),
            ),
          );
        }

        if (!isThumbnailOrigin) {
          await this.addTag(basename, TAGS.thumbnailOrigin);
        }

        return this.setAsThumbnailBase(newThumbnailBasename);
      }

      return this.setAsThumbnailBase(basename);
    };

    deletePhotosBase = names => {
      return new Promise((resolve, reject) => {
        const { media } = this.state;

        const basenames = media
          ? media
              .filter(
                file =>
                  file.mediatype?.startsWith('image') &&
                  !names.includes(file.basename),
              )
              .map(file => file.basename)
          : [];

        this.media.actions.putPhotos(basenames, error => {
          if (error) {
            return reject(new Error(error));
          }

          return resolve(basenames);
        });
      });
    };

    deletePhoto = async basename => {
      const thumbnails = this.getAllMediaByTag(TAGS.thumbnail);
      const thumbnailsOrigin = this.getAllMediaByTag(TAGS.thumbnailOrigin);

      const isThumbnail = findByBaseName(thumbnails, basename);
      const isThumbnailOrigin = findByBaseName(thumbnailsOrigin, basename);

      const basenames = await this.deletePhotosBase([
        basename,
        ...(isThumbnail ? thumbnailsOrigin.map(it => it.basename) : []), // Delete thumbnail-origin if photo is thumbnail
        ...(isThumbnailOrigin ? thumbnails.map(it => it.basename) : []), // Delete thumbnail if photo is thumbnail-origin
      ]);

      // TODO: remove this code from this controller in future
      if (isThumbnail || isThumbnailOrigin) {
        const newThumbnail = basenames[0];

        if (newThumbnail) {
          this.media.actions.addPhotoTag(newThumbnail, TAGS.thumbnail);
          this.user.actions.actualize({
            'thumbnail-pending': newThumbnail,
          });
        } else {
          this.user.actions.actualize({
            thumbnail: null,
            'thumbnail-pending': null,
          });
        }
      }
    };

    deleteVideo = basename => {
      return new Promise((resolve, reject) => {
        const { media } = this.state;
        const basenames = media
          ? media
              .filter(
                file =>
                  file.mediatype?.startsWith('video') &&
                  file.basename !== basename,
              )
              .map(file => file.basename)
          : [];

        this.media.actions.putVideos(basenames, error => {
          if (error) {
            return reject(new Error(error));
          }

          return resolve();
        });
      });
    };

    addPhotoBase = (file, skipProgress = false) => {
      return new Promise((resolve, reject) => {
        const update = state => {
          if (state.files && state.files.length) {
            const basename = state.files[0];

            this.media.actions.actualize(
              {
                basename,
                tags: [],
                mediatype: 'image/*',
              },
              () => {
                const { media } = this.state;

                // TODO: remove this code from this controller in future
                if (
                  !media ||
                  !media.filter(
                    item =>
                      item.basename !== basename &&
                      item.mediatype?.startsWith('image'),
                  ).length
                ) {
                  this.media.actions.addPhotoTag(basename, TAGS.thumbnail);
                  this.user.actions.actualize({
                    'thumbnail-pending': basename,
                  });
                }

                if (!skipProgress) {
                  this.setState({
                    progress: undefined,
                  });
                }

                resolve(basename);
              },
            );

            this.storage.store.unlisten(update);

            return;
          }

          if (typeof state.progress === 'number') {
            if (!skipProgress) {
              this.setState({
                progress: state.progress,
              });
            }

            return;
          }

          this.storage.store.unlisten(update);

          reject(new Error('Uploading failed'));
        };

        this.storage.store.listen(update);

        const { id } = this.props;

        this.storage.actions.post(`/users/${id}/photos`, file, error => {
          if (error) {
            this.storage.store.unlisten(update);
            reject(error);
          }
        });
      });
    };

    addPhoto = async file => {
      const { thumbnailCroppingEnabled } = this.props;
      const thumbnails = this.getAllMediaByTag(TAGS.thumbnail);

      if (thumbnailCroppingEnabled && !thumbnails.length) {
        try {
          const { width, height, mime, path } = await ImagePicker.openCropper({
            ...cropperOptions,
            type: file.type,
            path: file.uri,
          });

          await this.addPhotoBase(
            {
              width,
              height,
              name: file.name,
              type: mime,
              blob: file.blob,
              uri: `file://${path}`,
            },
            true,
          );

          const basename = await this.addPhotoBase(file);

          await this.addTag(basename, TAGS.thumbnailOrigin);
          await this.removeTag(basename, TAGS.thumbnail); // TODO: Temporary solution. Should be fixed on the backend

          return basename;
        } catch (e) {
          if (e.code === PICKER_CANCEL_ERROR_KEY) {
            return null;
          }

          throw e;
        }
      }

      const basename = await this.addPhotoBase(file);

      if (thumbnailCroppingEnabled && thumbnails.length) {
        await this.removeTag(basename, TAGS.thumbnail); // TODO: Temporary solution. Should be fixed on the backend
        await this.removeTag(basename, TAGS.thumbnailOrigin); // TODO: Temporary solution. Should be fixed on the backend
      }

      return basename;
    };

    addVideo = file => {
      return new Promise((resolve, reject) => {
        const update = state => {
          if (state.files && state.files.length) {
            const basename = state.files[0];

            this.media.actions.actualize({
              basename,
              tags: [],
              mediatype: 'video/*',
            });

            this.setState({
              progress: undefined,
            });

            resolve(basename);

            return;
          }

          if (typeof state.progress === 'number') {
            this.setState({
              progress: state.progress,
            });

            return;
          }

          this.storage.store.unlisten(update);

          reject(new Error('Uploading failed'));
        };

        this.storage.store.listen(update);

        const { id } = this.props;

        this.storage.actions.post(`/users/${id}/videos`, file, error => {
          if (error) {
            this.storage.store.unlisten(update);
            reject(error);
          }
        });
      });
    };

    subscribe() {
      const { id } = this.props;
      const { flux } = this.context;

      if (id) {
        this.media = flux.get(MediaModel, id);
        this.user = flux.get(UserModel, id);
        this.storage = flux.get(
          ObjectStorage,
          ControlledComponent.objectStorageId(id),
        );

        this.setState(this.media.store.getState(), () => {
          if (!this.isStateFilled()) {
            this.media.actions.get();
          }
        });

        this.media.store.listen(this.onMediaModelStateChanged);
        // TODO: remove this code from this controller in future
        this.user.actions.get();
      }
    }

    unsubscribe() {
      this.media?.store.unlisten(this.onMediaModelStateChanged);
    }

    isStateFilled() {
      const { media } = this.state;

      return !!media && !!media.length;
    }

    render() {
      const { forwardedRef, ...props } = this.props;
      const { media, progress } = this.state;

      return (
        <Component
          {...props}
          ref={forwardedRef}
          progress={progress}
          media={media}
          addPhoto={this.addPhoto}
          addVideo={this.addVideo}
          deletePhoto={this.deletePhoto}
          deleteVideo={this.deleteVideo}
          setAsThumbnail={this.setAsThumbnail}
          togglePrivate={this.togglePrivate}
          addTag={this.addTag}
        />
      );
    }
  }

  const ResultComponent = withConfigValue(ControlledComponent, {
    thumbnailCroppingEnabled: 'features.thumbnail-cropping-enabled',
    host: 'host',
  });

  // eslint-disable-next-line react/no-multi-comp
  return React.forwardRef((props, ref) => {
    return <ResultComponent {...props} forwardedRef={ref} />;
  });
}

export default createControlledComponent;
