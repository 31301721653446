import React from 'react';
import { Image, Text, View } from 'react-native';
import styles from './styles';

export interface Props {
    mainText: string;
    secondaryText: string;
    image: any;
}

export const EventsListPlaceholder = (props: Props) => {
    const { mainText, secondaryText, image } = props;

    return (
        <View style={styles.placeholderContainer}>
            <Image source={image} style={styles.image} />
            <Text style={styles.mainText}>{mainText}</Text>
            <Text style={styles.descriptionText} numberOfLines={3}>
                {secondaryText}
            </Text>
        </View>
    );
};
