// eslint-disable-next-line import/no-unresolved
import Persistence from '@sdv/domain/app/persistence';
import BalanceActions from './actions';

function createBalanceStore(id) {
  const totalSpentKey = `credits.account.balance-totalSpent.${id}`;

  class BalanceStore {
    constructor(flux) {
      const actions = flux.getActions(BalanceActions, id);

      this.state = {};

      this.bindAction(actions.get, this.update);
      this.bindAction(actions.update, this.update);

      Persistence.shared()
        .value(totalSpentKey)
        .then(storedValue => {
          if (typeof this.state.totalSpent === 'undefined' && storedValue) {
            this.setState({
              totalSpent: (storedValue && parseInt(storedValue, 10)) || 0,
            });
          }
        });
    }

    update = async data => {
      if (!data) return;
      const { balance, change } = data;

      if (typeof balance !== 'number') {
        return;
      }

      const newState = {
        amount: balance,
      };

      if (typeof change === 'number' && change < 0) {
        let { totalSpent } = this.state;

        if (typeof totalSpent === 'undefined') {
          totalSpent = 0;
          try {
            const storedValue = await Persistence.shared().value(totalSpentKey);

            totalSpent = (storedValue && parseInt(storedValue, 10)) || 0;
            // eslint-disable-next-line no-empty
          } catch {}
        }

        totalSpent += Math.abs(change);
        Persistence.shared()
          .store(totalSpentKey, String(totalSpent))
          .then();

        newState.totalSpent = totalSpent;
      }

      this.setState(newState);
    };
  }

  BalanceStore.displayName = createBalanceStore.getDisplayName(id);

  return BalanceStore;
}

createBalanceStore.getDisplayName = id => {
  return `credits-accounts-balance.${id}`;
};

export default createBalanceStore;
