import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '$disabledColor',
        height: 50,
        borderRadius: 25,
        padding: 4,
        fontFamily: '$brandFontFamily',
        width: '100%',
        overflow: 'hidden',
        gradientColor: '$streamingCTAGradientColor'
    },
    infoContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        height: '100%',
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 15,
        paddingRight: 17,
        width: '100%',
    },

    photo: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        '@flavor tubit': {
            opacity: 0.25
        }
    },
    photoFade: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(86, 86, 86, 0.8)',
        '@flavor tubit': {
            backgroundColor: 'transparent'
        }
    },

    descriptionContainer: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'flex-start',
        fontFamily: '$brandFontFamily',
    },
    liveBadgeContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: 'red',
        height: 20,
        borderRadius: 10,
        paddingLeft: 10,
        paddingRight: 10,
        fontFamily: '$brandFontFamily',
        marginLeft: 8,
        '@flavor lovinga': {
            backgroundColor: '$accentColor'
        },
        '@flavor tubit': {
            backgroundColor: '$accentColor'
        }
    },
    titleText: {
        fontWeight: 'bold',
        color: 'white',
        fontFamily: '$brandFontFamily',
        fontSize: 14
    },
    descriptionText: {
        color: 'white',
        fontFamily: '$brandFontFamily',
        fontSize: 14
    },
    liveBadgeText: {
        fontSize: 11,
        color: 'white',
        fontFamily: '$brandFontFamily',
        fontWeight: 'bold',
    },
    liveBadgeIcon: {
        width: 14,
        height: 14,
        marginRight: 2
    },
});

export default styles;
