import React from 'react';
import PropTypes from 'prop-types';
import { stickerPath } from '../../../resources/remote';

function createController(Component) {

    class Controller extends React.Component {
        static displayName = 'common.messages.message.sticker.controller';
        static propTypes = {
            message: PropTypes.shape({
                content: PropTypes.oneOfType([
                    PropTypes.shape({
                        basename: PropTypes.string.isRequired
                    })
                ]).isRequired
            })
        };

        render() {
            const path = stickerPath(this.props.message.content.basename);

            return (
                <Component
                    {...this.props}
                    path={path}
                />
            )
        }
    }

    return Controller;

}

export default createController;
