import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import testId from 'dating-mobile/src/utils/test-id';
import Resources from 'dating-mobile/src/resources';
import Photo from 'dating-mobile/src/components/photo';
import StickerContentController from 'dating-mobile/src/models/common.messages/controllers/sticker-content-controller';

import styles from './styles';

class StickerContent extends React.Component {
  static displayName = 'common.messages.message.sticker-content';

  static propTypes = {
    path: PropTypes.string,
    isNotDelivered: PropTypes.bool,
  };

  shouldComponentUpdate(nextProps) {
    const { path, isNotDelivered } = this.props;

    return (
      path !== nextProps.path || isNotDelivered !== nextProps.isNotDelivered
    );
  }

  render() {
    const { path, isNotDelivered } = this.props;
    const photoStyle = [styles.photo, isNotDelivered ? styles.unsentPhoto : {}];

    return (
      <View style={styles.container}>
        <Photo
          style={photoStyle}
          path={path}
          resizeMode="contain"
          forceRetina
          {...testId(Resources.strings['message-sticker-accessibility-label'])}
        />
      </View>
    );
  }
}

export default StickerContentController(StickerContent);
