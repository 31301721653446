import React from 'react';
import { UserProfileAliases } from '@sdv/domain/user/user-profile-aliases';

const withAliases = Component => {
    class Controller extends React.Component {
        constructor(props) {
            super(props);
            this.userProfileAliases = UserProfileAliases.shared();
        }

        componentDidMount() {
            this.subscribtion = this.userProfileAliases
                .getAliasedUser(this.props.userId)
                .subscribe(user => {
                    this.setState({ user });
                });
        }

        componentWillUnmount() {
            this.subscribtion && this.subscribtion.unsubscribe();
        }

        render() {
            return <Component {...this.props} {...this.state} />;
        }
    }

    return Controller;
};

export { withAliases };
