import flux from '@sdv/domain/app/flux';
import IdentityModel from '@sdv/domain/identity/model';
import { UserAliasesRepository } from '@sdv/domain/app/user/user-aliases-repository';
import { AliasPhotosUploader } from '@sdv/domain/user/user-alias-photos-uploader';
import { IMAGE_UPLOAD_STATUS } from '@sdv/domain/app/image-uploader';

import '@sdv/commons/rx/store';
import { Subject } from 'rxjs';
import {
  distinctUntilChanged,
  withLatestFrom,
  shareReplay,
  switchMap,
  filter,
  tap,
  map,
} from 'rxjs/operators';

class UserProfileAliasing {
  constructor() {
    this.userId = flux
      .get(IdentityModel)
      .store.rxState()
      .pipe(
        map(({ id }) => id),
        filter(Boolean),
        distinctUntilChanged(),
        tap(id => {
          this.userAliasesRepository = UserAliasesRepository.shared(id);
        }),
        shareReplay(1),
      );

    this.photoUploader = AliasPhotosUploader.shared();

    this.shouldSetThumbnailAlias = new Subject();

    this.shouldSetThumbnailAlias
      .pipe(
        withLatestFrom(this.userId),
        switchMap(([{ attendeeId, source }, identityId]) => {
          return this.photoUploader
            .uploadAliasPhoto(source, identityId, attendeeId)
            .pipe(
              filter(upload => upload.status === IMAGE_UPLOAD_STATUS.COMPLETED),
              map(upload => ({ attendeeId, basename: upload.basename })),
            );
        }),
      )
      .subscribe(
        ({ basename, attendeeId }) => {
          this.userAliasesRepository.setThumbnailAlias(basename, attendeeId);
        },
        () => null,
      );

    this.shouldSetNameAlias = new Subject();

    this.shouldSetNameAlias
      .pipe(withLatestFrom(this.userId))
      .subscribe(([data, userId]) => {
        this.userAliasesRepository.setNameAlias(
          data.name,
          data.attendeeId,
          userId,
        );
      });
  }

  setNameAlias = (name, attendeeId) => {
    this.shouldSetNameAlias.next({ name, attendeeId });
  };

  setThumbnailAlias = (source, attendeeId) => {
    this.shouldSetThumbnailAlias.next({ source, attendeeId });
  };
}

export { UserProfileAliasing };
