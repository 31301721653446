import React from 'react';
import Service from '../service';
import IdentityTrackingService from './identity';
import AppLaunchTrackingService from './app-launch';
import PurchasesTrackingService from './purchases';
import SecondLoginTrackingService from './second-login';
import RegistrationTrackingService from './registration';
import { OnboardingTrackingService } from './onboarding';
import { BoostBannerTracker } from './boost-banner-tracker';
import { CurrentScreenTracker } from './current-screen';
import { PushPermissionTracker } from './push-permission';
import { RateAppTracker } from './rate-app-tracker';
import { CallTracker } from './call-tracker';
import { FilterTracker } from './filter-tracker';
import { PaidMessagesTracker } from './paid-messages';
import { LocationPermissionTracker } from './location-permission';

export default class TrackingService extends Service {
  static displayName = 'service.tracking';

  render() {
    return [
      <IdentityTrackingService key="identity" />,
      <AppLaunchTrackingService key="app-launch" />,
      <PurchasesTrackingService key="purchases" />,
      <SecondLoginTrackingService key="second-login" />,
      <RegistrationTrackingService key="registration" />,
      <OnboardingTrackingService key="onboarding" />,
      <BoostBannerTracker key="boost-banner" />,
      <CurrentScreenTracker key="current-screen" />,
      <PushPermissionTracker key="push-permission" />,
      <LocationPermissionTracker key="location-permission" />,
      <RateAppTracker key="rate-app" />,
      <CallTracker key="audio-call" />,
      <FilterTracker key="filter" />,
      <PaidMessagesTracker key="paid-messages" />,
    ];
  }
}
