import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'black',
    zIndex: 9999,
  },

  video: {
    marginTop: 40,
    width: '60%',
  },

  closeButton: {
    position: 'absolute',
    top: 20,
    right: 20,
  },
});

export default styles;
