import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet } from 'react-native';
import ImageButton from 'dating-mobile/src/components/navigation/views/image-button';
import Resources from 'dating-mobile/src/resources';

import styles from './styles';

function createNavigation(Component) {
  class Navigation extends PureComponent {
    static displayName = 'payment.screens.calls.navigation';

    static propTypes = {
      navigation: PropTypes.object.isRequired,
    };

    static navigationOptions = ({ navigation }) => {
      return {
        headerTitle: '',
        headerStyle: styles.header,
        headerTitleStyle: styles.headerTitle,
        headerLeft: () =>
          navigation.getParam('cancelButtonVisible') ? (
            <ImageButton
              image={Resources.images.backIcon()}
              tint={StyleSheet.flatten(styles.headerTitle).color}
              onPress={navigation.getParam('onCloseButtonPress')}
            />
          ) : null,
      };
    };

    componentDidMount() {
      const { navigation } = this.props;

      navigation.setParams({
        onCloseButtonPress: () => this.onClose(),
      });
    }

    onClose = pack => {
      const { navigation } = this.props;
      const onComplete = navigation.getParam('onComplete');

      if (onComplete) {
        onComplete(pack);
      }

      // TODO:
      navigation.pop();
    };

    render() {
      const { navigation } = this.props;

      return (
        <Component
          onClose={this.onClose}
          reason={navigation.getParam('reason')}
          skipPremium={navigation.getParam('skipPremium')}
        />
      );
    }
  }

  return Navigation;
}

export default createNavigation;
