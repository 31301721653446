import { AppState } from 'react-native';
import { analytics as createFirebaseTracker } from 'react-native-firebase';
import AppsFlyerTracker from 'dating-mobile/src/services/tracking/apps-flyer-tracker';
import { Persistence } from '@sdv/domain/app/persistence';
import { switchMap, tap, filter } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import flux from '@sdv/domain/app/flux';
import Service from '../service';

const appLaunchTrackedToAnnalsKey = 'tracked-to-annals';

export default class AppLaunchTrackingService extends Service {
  static displayName = 'service.tracking.app-launch';

  constructor(props) {
    super(props);

    const persistence = new Persistence('app-launch');

    this.appOpened = new BehaviorSubject(undefined);
    this.appOpened
      .pipe(
        switchMap(() => {
          return persistence.load(appLaunchTrackedToAnnalsKey);
        }),
        filter(Boolean),
        tap(() => {
          this.trackToAnnals();
        }),
        switchMap(() => {
          persistence.store(appLaunchTrackedToAnnalsKey, true);
        }),
      )
      .subscribe();
  }

  trackToAnnals = () => {
    flux.api.annals.appsflyer.add({
      event: 'app_laucnhed',
    });
  };

  componentDidMount() {
    AppState.addEventListener('change', this.onAppStateChanged);
    this.onAppStateChanged(this.appState).then();
  }

  componentWillUnmount() {
    AppState.removeEventListener('change', this.onAppStateChanged);
  }

  onAppStateChanged = async nextAppState => {
    if (
      (!this.appState || this.appState !== 'active') &&
      nextAppState === 'active'
    ) {
      this.appOpened.next(undefined);
      createFirebaseTracker().logEvent('app_open');
      AppsFlyerTracker.trackAppLaunch();
      AppsFlyerTracker.trackEvent(
        'app_open_remarketing',
        {},
        () => {},
        () => {},
      );
    }
    this.appState = nextAppState;
  };
}
