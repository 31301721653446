import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ActivityIndicator, ScrollView, Text, View, Image } from 'react-native';
import { SafeAreaView } from 'react-navigation';
import { formatPrice } from '@sdv/commons/price-with-code-localization';
import { TimeInterval } from '@sdv/commons/utils/time-interval';
import Resources from 'dating-mobile/src/resources';
import Button from 'dating-mobile/src/components/buttons/base';

import styles from './styles';

import offerImage from './assets/offer.jpg';

const DEFAULT_DISCOUNT = 50;
const DEFAULT_BOOST_TIME = 30;

const getBoostTime = ttl =>
  ttl
    ? Math.floor((ttl * TimeInterval.second) / TimeInterval.minute)
    : DEFAULT_BOOST_TIME;

export default class PaymentTypeList extends PureComponent {
  static displayName = 'payment.screens.special-offer.view';

  static propTypes = {
    invoice: PropTypes.object,
    onPress: PropTypes.func,
    isLoading: PropTypes.bool,
  };

  render() {
    const { invoice, onPress, isLoading } = this.props;
    const localizedPrice = formatPrice(invoice?.price, false);
    const priceWithoutDiscount = formatPrice(
      invoice?.priceWithoutDiscount,
      false,
    );
    const hasDiscount = !invoice || !!invoice.discount;

    return (
      <SafeAreaView
        forceInset={{ top: 'always', bottom: 'always' }}
        style={styles.container}
      >
        <ScrollView
          alwaysBounceVertical={false}
          showsVerticalScrollIndicator={false}
        >
          {hasDiscount ? (
            <Text style={styles.title}>
              <Text style={styles.titleText}>
                {Resources.strings['special-offer-screen-title']}
              </Text>
              {'\n'}
              <Text style={styles.discountText}>
                {Resources.strings.formatString(
                  Resources.strings['special-offer-screen-discount'],
                  invoice?.discount || DEFAULT_DISCOUNT,
                )}
              </Text>
            </Text>
          ) : (
            <Text style={styles.discountExpiredTitle}>
              {Resources.strings['special-offer-screen-discount-expired-title']}
            </Text>
          )}
          <Image source={offerImage} style={styles.image} />
          <Text style={styles.subtitle}>
            {Resources.strings['special-offer-screen-subtitle']}
          </Text>
          <Text style={styles.description}>
            {Resources.strings['special-offer-screen-description']}
          </Text>
          {invoice ? (
            <Button
              disabled={isLoading}
              onPress={onPress}
              style={styles.button}
              titleStyle={styles.buttonTitleContainer}
              title={
                <Text>
                  <Text style={styles.buttonTitle}>
                    {Resources.strings.formatString(
                      Resources.strings['special-offer-screen-button-title'],
                      getBoostTime(invoice?.boostTime),
                    )}
                  </Text>
                  {'\n'}
                  <Text style={styles.priceContainer}>
                    {!!priceWithoutDiscount && (
                      <Text
                        style={[
                          styles.oldPrice,
                          isLoading && styles.disabledText,
                        ]}
                      >
                        {priceWithoutDiscount}
                      </Text>
                    )}
                    <Text
                      style={[
                        styles.currentPrice,
                        isLoading && styles.disabledText,
                      ]}
                    >
                      {' '}
                      {localizedPrice}
                    </Text>
                  </Text>
                </Text>
              }
            />
          ) : (
            <ActivityIndicator animating />
          )}
          {!!isLoading && (
            <View style={styles.activityIndicatorContainer}>
              <ActivityIndicator animating />
            </View>
          )}
        </ScrollView>
      </SafeAreaView>
    );
  }
}
