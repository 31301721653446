import React from 'react';
import { Image, Text, View } from 'react-native';
import styles from './styles';
import Resources from 'dating-mobile/src/resources/index.js';
import PropTypes from 'prop-types';

export default class StreamingIndicator extends React.Component {
    static displayName = 'streaming.indicator.view';

    static propTypes = {
        visible: PropTypes.bool.isRequired,
    };

    shouldComponentUpdate(nextProps) {
        return this.props.visible !== nextProps.visible;
    }

    render() {
        return this.props.visible && (
            <View style={styles.container}>
                {styles.$showBadgeIcon &&
                <Image
                    style={styles.liveBadgeIcon}
                    source={Resources.images.liveBadgeIcon()}
                />}
                <Text style={styles.text}>{Resources.strings['live']}</Text>
            </View>
        );
    }
}
