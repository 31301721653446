import { map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { AutobuySettingsRepo } from './autobuy-settings-repo';

export class AutobuyPayment {
    constructor(originalPayment, autobuySettings, userId) {
        this.originalPayment = originalPayment;
        this.autobuySettings = autobuySettings;
        this.userId = userId;
    }

    purchase = () => {
        return this.originalPayment.purchase().pipe(
            switchMap(
                result => {
                    if (result.success && this.autobuySettings && this.autobuySettings.enabled) {
                        return AutobuySettingsRepo.shared(this.userId)
                            .update(this.autobuySettings)
                            .pipe(
                                map(() => result)
                            );
                    } else {
                        return of(result);
                    }
                }
            )
        );
    };
}
