import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        height: 60,
        width: '100%'
    },
    contentContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '100%',
        flex: 1,
        borderBottomWidth: 1,
        borderBottomColor: '$brandBorderColor',
        marginLeft: 20,
        marginRight: 20,
        '@flavor lovinga': {
            marginLeft: 16,
            marginRight: 0
        }
    },
    selectionIcon: {
        width: 22,
        height: 22,
        borderRadius: 11,
        alignItems: 'center',
        justifyContent: 'center',
    },
    selectedIcon: {
        backgroundColor: '#0096ff',
    },
    unselectedIcon: {
        borderColor: '$disabledColor',
        borderWidth: 1
    },
    titleContainer: {
        marginLeft: 17,
        justifyContent: 'center',
        flex: 1,
        width: '100%',
        height: '100%',
    },
    title: {
        fontFamily: '$brandFontFamily',
        fontSize: 17,
        fontWeight: '600',
        color: '$brandTextColor'
    }
});

export default styles;
