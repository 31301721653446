import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({

    container: {
        flexDirection: 'column',
        alignItems: 'stretch',
        paddingTop: 24,
        paddingHorizontal: 20,
        '@flavor lovinga': {
            paddingHorizontal: 16
        }
    },

    options: {
        flexDirection: 'row',
        flexWrap: 'wrap'
    },

    deleteAllOption: {
        marginBottom: 12,
        height: 32,
        borderRadius: 16,
        paddingHorizontal: 16,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#7a70b7'
    },

    deleteAllOptionTitle: {
        fontSize: 13,
        fontFamily: '$brandFontFamily',
        color: 'white'
    },

    addOptionButton: {
        alignSelf: 'center',
        marginTop: 6,
        marginBottom: 30
    },

    addOptionButtonTitle: {
        fontSize: 17,
        fontFamily: '$brandFontFamily',
        color: '$infoTextColor',
        textAlign: 'center'
    }

});

export default styles;
