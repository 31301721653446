import UserActions from './actions';
import merge from 'merge/merge';
import { createOptimisticStore } from '@sdv/model';

function createUserStore(userId) {

    class Store extends createOptimisticStore(['patch']) {

        constructor(flux) {
            const actions = flux.getActions(UserActions, userId);
            super(actions);

            this.bindAction(actions.get, this.replace);
            this.bindAction(actions.actualize, this.actualize);
        }

        replace(payload) {

            if (!payload) {
                return;
            }

            this.state.data = payload;
            this.setState(this.state);

        }

        actualize(payload) {

            if (!payload) {
                return;
            }

            this.state.data = merge.recursive(true, this.state.data, payload);
            this.setState(this.state);

        }

    }

    Store.displayName = createUserStore.getDisplayName(userId);

    return Store;
}

createUserStore.getDisplayName = function (id) {
    return `user.${id}`;
};

export default createUserStore;
