import React from 'react';
import { View, Image, TouchableOpacity } from 'react-native';
import Resources from 'dating-mobile/src/resources';
import PropTypes from 'prop-types';
import { types as CardType } from 'credit-card-type';

import DropDown from '../../../components/drop-down';

import styles from './styles';

export default class PickerView extends React.Component {
  static displayName = 'payment.views.credit-card-picker.view';

  static propTypes = {
    cards: PropTypes.arrayOf(
      PropTypes.shape({ type: PropTypes.string, number: PropTypes.string }),
    ),
    selected: PropTypes.number,
    onSelect: PropTypes.func,
  };

  shouldComponentUpdate(nextProps) {
    const { cards, selected } = this.props;

    return cards !== nextProps.cards || selected !== nextProps.selected;
  }

  getCardIcon(type) {
    switch (type) {
      case CardType.VISA:
        return Resources.images.cardVisaIcon();
      case CardType.AMERICAN_EXPRESS:
        return Resources.images.cardAmexIcon();
      case CardType.DISCOVER:
        return Resources.images.cardDiscoverIcon();
      case CardType.JCB:
        return Resources.images.cardJcbIcon();
      case CardType.MASTERCARD:
        return Resources.images.cardMastercardIcon();
      default:
        if (type) {
          return Resources.images.creditCardIcon();
        }
        break;
    }

    return undefined;
  }

  render() {
    const { cards, selected, onSelect } = this.props;
    const dropdownOptions = [
      ...cards.map(({ number, type }, i) => ({
        key: i,
        value: number,
        rightIcon: () =>
          i === selected ? (
            <TouchableOpacity>
              <Image
                source={Resources.images.closeIcon()}
                resizeMode="contain"
                style={styles.iconStyle}
              />
            </TouchableOpacity>
          ) : (
            undefined
          ),
        leftIcon: () => (
          <Image
            style={styles.cardIconStyle}
            source={this.getCardIcon(type)}
            resizeMode="contain"
          />
        ),
      })),
      {
        value: Resources.strings['saved-card-list-add-card-menu-button-title'],
        key: cards.length,
      },
    ];

    return (
      <View style={styles.container}>
        <DropDown
          items={dropdownOptions}
          value={selected}
          onSelect={onSelect}
        />
      </View>
    );
  }
}
