import React from 'react';
import PropTypes from 'prop-types';
import { StatusBar } from 'react-native';
import { WebView } from 'react-native-webview';
import { SafeAreaView } from 'react-navigation';
import styles from './styles';

export default class ScreenView extends React.Component {
  static displayName = 'tips.tip.view';

  static propTypes = {
    source: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  };

  render() {
    const { source } = this.props;

    return (
      <SafeAreaView forceInset={styles.$safeAreaInset} style={styles.container}>
        <StatusBar
          barStyle={styles.$statusBarStyle}
          backgroundColor={styles.$statusBarBackgroundColor}
        />

        <WebView style={styles.webView} source={source} />
      </SafeAreaView>
    );
  }
}
