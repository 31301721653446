export const staticSmiles = {
    '*Angel*': () => require('./assets/static/e7fad8889e2d.webp'),
    '*Angry-Devil*': () => require('./assets/static/ece419111a97.webp'),
    '*Angry-Face*': () => require('./assets/static/d23179d197cb.webp'),
    '*Ashamed-Face*': () => require('./assets/static/a98ada18ca95.webp'),
    '*Baby*': () => require('./assets/static/885e6b065fd1.webp'),
    '*Banana*': () => require('./assets/static/b3bead6ea8f4.webp'),
    '*Bell*': () => require('./assets/static/e041f86a78a7.webp'),
    '*Blushing-Face*': () => require('./assets/static/f0059e54f2ee.webp'),
    '*Boy*': () => require('./assets/static/0f1444b441ac.webp'),
    '*Cactus*': () => require('./assets/static/5732a1a785bc.webp'),
    '*Cake*': () => require('./assets/static/a7a8fee0114c.webp'),
    '*Cat*': () => require('./assets/static/f3ac21ce4602.webp'),
    '*Cat-In-Love*': () => require('./assets/static/9ecbe0fcda43.webp'),
    '*Cheesecake*': () => require('./assets/static/ef81aed04f33.webp'),
    '*Crying-Face*': () => require('./assets/static/15ee5e5b80c8.webp'),
    '*Cupcake*': () => require('./assets/static/1264cfc6e25f.webp'),
    '*Dancing-Man*': () => require('./assets/static/95511e677c1a.webp'),
    '*Dancing-Woman*': () => require('./assets/static/ecf4d35ca4ff.webp'),
    '*Dizzy*': () => require('./assets/static/5d87ca636607.webp'),
    '*Dog*': () => require('./assets/static/8a35eaf423f7.webp'),
    '*Donate*': () => require('./assets/static/699818433f75.webp'),
    '*Ear*': () => require('./assets/static/0a27dfcd21d1.webp'),
    '*Eyes*': () => require('./assets/static/aebc51f95619.webp'),
    '*Face-with-Halo*': () => require('./assets/static/7b84dd5d87ec.webp'),
    '*Face-with-Sunglasses*': () => require('./assets/static/19f27f2881fd.webp'),
    '*Face-with-Tongue*': () => require('./assets/static/672402a07964.webp'),
    '*Family*': () => require('./assets/static/db0ca78d64bb.webp'),
    '*Finger-Down*': () => require('./assets/static/dac271cd720a.webp'),
    '*Finger-Left*': () => require('./assets/static/2a0332f913b0.webp'),
    '*Finger-Right*': () => require('./assets/static/a9c9b835a095.webp'),
    '*Finger-Up*': () => require('./assets/static/582bba37ea0b.webp'),
    '*Fire*': () => require('./assets/static/9db96422c6e7.webp'),
    '*Flushed-Face*': () => require('./assets/static/96278e454856.webp'),
    '*Footprint*': () => require('./assets/static/084c93360dca.webp'),
    '*Frowning-Face*': () => require('./assets/static/d32fb7f1eee5.webp'),
    '*Funny*': () => require('./assets/static/82f5e231d4bc.webp'),
    '*Girl*': () => require('./assets/static/e46cd6cbff3f.webp'),
    '*Glare*': () => require('./assets/static/67e18b8396e6.webp'),
    '*Grape*': () => require('./assets/static/4ab25400e9e8.webp'),
    '*Grinning-Face*': () => require('./assets/static/b3980b603c54.webp'),
    '*Heart-Shaped-Eyes*': () => require('./assets/static/d9c1b933d06c.webp'),
    '*Heart-with-Arrow*': () => require('./assets/static/e4fe7a473e5a.webp'),
    '*Heart-with-Ribbon*': () => require('./assets/static/89d30c9c3b87.webp'),
    '*Kissing-Face*': () => require('./assets/static/eb33ecf65929.webp'),
    '*Kissing-Faces*': () => require('./assets/static/edee848ad6f0.webp'),
    '*Koala*': () => require('./assets/static/2ed2ab9069a3.webp'),
    '*Landy-Bug*': () => require('./assets/static/cbad54b50ca1.webp'),
    '*Laughing-Face*': () => require('./assets/static/da0a2d6ec91c.webp'),
    '*Leaf*': () => require('./assets/static/93c7b1ee4f04.webp'),
    '*Lemon*': () => require('./assets/static/a9cd603445e3.webp'),
    '*Lemonade*': () => require('./assets/static/f97af2397f0a.webp'),
    '*Lips*': () => require('./assets/static/5a5a2155fff3.webp'),
    '*Love-Letter*': () => require('./assets/static/18c407ddfa02.webp'),
    '*Man*': () => require('./assets/static/1a614d42e784.webp'),
    '*Man-Shoe*': () => require('./assets/static/40b9a45d7944.webp'),
    '*Maple-Leaf*': () => require('./assets/static/f8f2e8799693.webp'),
    '*Martini*': () => require('./assets/static/dc0c40ee1c9a.webp'),
    '*Monkey*': () => require('./assets/static/9f972f14faf4.webp'),
    '*Moon*': () => require('./assets/static/803d05398d21.webp'),
    '*Mountains*': () => require('./assets/static/795966fd327a.webp'),
    '*Mouse*': () => require('./assets/static/522bfe88a34f.webp'),
    '*Mushroom*': () => require('./assets/static/9955284aed1d.webp'),
    '*Nevrous-Face*': () => require('./assets/static/7244b7bb7d48.webp'),
    '*No-Hear-Monkey*': () => require('./assets/static/e1085bcaa519.webp'),
    '*No-See-Monkey*': () => require('./assets/static/aea221f63788.webp'),
    '*No-Speak-Monkey*': () => require('./assets/static/91a903c562cc.webp'),
    '*Nose*': () => require('./assets/static/e319af7c0d6a.webp'),
    '*OK-Hand*': () => require('./assets/static/43b5ee8e9d7a.webp'),
    '*Orange*': () => require('./assets/static/baac057d1547.webp'),
    '*Panda*': () => require('./assets/static/2ed1f6574668.webp'),
    '*Piggy*': () => require('./assets/static/e7542b6a0a95.webp'),
    '*Police-Officer*': () => require('./assets/static/66529b56aa1d.webp'),
    '*Prince*': () => require('./assets/static/0b25068fe849.webp'),
    '*Princess*': () => require('./assets/static/a771ff0f268b.webp'),
    '*Rain*': () => require('./assets/static/508fc8da0575.webp'),
    '*Raised-Hand*': () => require('./assets/static/2af77bb918c8.webp'),
    '*Raspberry*': () => require('./assets/static/ead118dfa424.webp'),
    '*Red-Devil*': () => require('./assets/static/626a26aeadb8.webp'),
    '*Red-Heart*': () => require('./assets/static/ee515cd868dc.webp'),
    '*Red-Rose*': () => require('./assets/static/ec1421f6e1ad.webp'),
    '*Red-Wine*': () => require('./assets/static/d39c73c55957.webp'),
    '*Roe*': () => require('./assets/static/cebdd1a2e075.webp'),
    '*Screaming-Face*': () => require('./assets/static/6019b6752510.webp'),
    '*Sleeping-Face*': () => require('./assets/static/f7d6e53c2c5f.webp'),
    '*Smiling-Cat*': () => require('./assets/static/aea8520cc939.webp'),
    '*Smiling-Devil*': () => require('./assets/static/f5aedb4b00ec.webp'),
    '*Smiling-Face*': () => require('./assets/static/e22cf33db35a.webp'),
    '*Snooze*': () => require('./assets/static/fd108bd01c7c.webp'),
    '*Speech-Ballon*': () => require('./assets/static/27806f41e769.webp'),
    '*Strawberry*': () => require('./assets/static/da5da358f80c.webp'),
    '*Sun-Glasses*': () => require('./assets/static/2b35ae497e01.webp'),
    '*Sunflower*': () => require('./assets/static/d40c945171e7.webp'),
    '*Sunshine*': () => require('./assets/static/5021e5436e9e.webp'),
    '*Teddy*': () => require('./assets/static/3638479d315d.webp'),
    '*Tender-kiss*': () => require('./assets/static/3f9e5d887430.webp'),
    '*Thought-Ballon*': () => require('./assets/static/7a3ad08cda5a.webp'),
    '*Thumbs-Down*': () => require('./assets/static/607be5ac2710.webp'),
    '*Thumbs-Up*': () => require('./assets/static/7c6379b341d6.webp'),
    '*Tiger*': () => require('./assets/static/fe25019ecc69.webp'),
    '*Tongue*': () => require('./assets/static/1bf66a7a219b.webp'),
    '*Tulip*': () => require('./assets/static/58109cb2249b.webp'),
    '*Two-Hearts*': () => require('./assets/static/49d55ecc35df.webp'),
    '*Victory-Hand*': () => require('./assets/static/ba8116e567cc.webp'),
    '*Watch*': () => require('./assets/static/dc60e10004b5.webp'),
    '*Watermelon*': () => require('./assets/static/bbade64b64c7.webp'),
    '*White-Wine*': () => require('./assets/static/2683ede9e97a.webp'),
    '*Winking-Face*': () => require('./assets/static/ec64c41920b9.webp'),
    '*Woman*': () => require('./assets/static/31a6e74c8474.webp'),
    '*Woman-Shoe*': () => require('./assets/static/6939dd51c635.webp'),
};

export const getStaticSmile = key => staticSmiles[key] && staticSmiles[key]();
