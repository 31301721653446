import React from 'react';
import PropTypes from 'prop-types';
import { View, StatusBar } from 'react-native';
import { CreateNewsletterButton } from 'dating-mobile/src/newsletter/create-newsletter-button';

import ChatsList from '../../views/chats-list';
import ListHeader from '../../views/chats-list/header';

import styles from './styles';

export default class ScreenView extends React.Component {
  static displayName = 'dialogs.chats.my-chats.screen.view';

  static propTypes = {
    navigation: PropTypes.object,
    identity: PropTypes.string,
    streams: PropTypes.array,
    invitations: PropTypes.array,
    contacts: PropTypes.array,
    isFocused: PropTypes.bool,
    minimized: PropTypes.bool,
    onShowAllRequestsButtonPress: PropTypes.func,
    onChatSelect: PropTypes.func,
    onDeleteChatButtonPress: PropTypes.func,
    showNewsletterButton: PropTypes.bool,
  };

  componentWillReceiveProps(nextProps) {
    const { isFocused } = this.props;

    if (!isFocused && nextProps.isFocused) {
      StatusBar.setBarStyle(styles.$statusBarStyle);
      StatusBar.setBackgroundColor(styles.$statusBarBackgroundColor);
    }
  }

  onDeleteChatButtonPressed = event => {
    const { onDeleteChatButtonPress } = this.props;

    if (onDeleteChatButtonPress) onDeleteChatButtonPress(event);
  };

  onChatSelected = event => {
    const { onChatSelect } = this.props;

    if (onChatSelect) onChatSelect(event);
  };

  renderHeader = () => {
    const {
      showNewsletterButton,
      minimized,
      invitations,
      onChatSelect,
      identity,
      onShowAllRequestsButtonPress,
    } = this.props;

    if (showNewsletterButton) {
      return <CreateNewsletterButton />;
    }

    if (minimized) {
      return null;
    }

    return (
      <ListHeader
        invitations={invitations}
        onChatSelect={onChatSelect}
        onShowAllRequestsButtonPress={onShowAllRequestsButtonPress}
        userId={identity}
      />
    );
  };

  render() {
    const {
      navigation,
      streams,
      invitations,
      contacts,
      isFocused,
      minimized,
    } = this.props;

    return (
      <View style={styles.container}>
        <StatusBar
          barStyle={styles.$statusBarStyle}
          backgroundColor={styles.$statusBarBackgroundColor}
        />
        <View style={styles.listContainer}>
          <ChatsList
            minimized={minimized}
            navigation={navigation}
            isFocused={isFocused}
            streams={streams}
            chats={contacts}
            onDeleteChatButtonPress={this.onDeleteChatButtonPressed}
            onChatSelect={this.onChatSelected}
            ListHeaderComponent={this.renderHeader}
            extraData={invitations}
          />
        </View>
      </View>
    );
  }
}
