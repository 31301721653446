import actions from './actions';
import store from './store';

export default {
    actions,
    store,
};

export const NOTIFICATION_PRIORITY = Object.freeze({
    NORMAL: 1,
    HIGH: 2,
});
