import { Platform, Dimensions } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

const deviceHeight = Dimensions.get('window').height;
const isDeviceSmall =
  deviceHeight < 1000 && Dimensions.get('window').width < 650;

const listHeight = Platform.select({
  web: { maxHeight: isDeviceSmall ? deviceHeight * 0.65 : 'auto' },
  default: {},
});

const styles = EStyleSheet.create({
  container: {
    flex: 1,
  },
  // After changing listContainer padding, you should fix message item maxWidth
  listContainer: {
    flex: 1,
    paddingVertical: 10,
    paddingHorizontal: 20,
  },

  list: {
    flex: 1,
    transform: [
      {
        scaleY: -1,
      },
    ],
  },

  contentContainer: {
    flexGrow: 1,
    ...listHeight,
  },

  separator: {
    height: 20,
  },

  loading: {
    alignSelf: 'center',
  },

  item: {
    flexDirection: 'column',
    alignItems: 'stretch',
    transform: [
      {
        scaleY: -1,
      },
    ],
  },

  header: {
    flexDirection: 'column',
    alignItems: 'stretch',
    transform: [
      {
        scaleY: -1,
      },
    ],
    marginBottom: 20,
  },

  footer: {
    flexDirection: 'column',
    alignItems: 'stretch',
    transform: [
      {
        scaleY: -1,
      },
    ],
  },

  footerWrapper: {
    flexGrow: 1,
    justifyContent: 'flex-end',
  },

  placeholderContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    alignItems: 'stretch',
    justifyContent: 'center',
    padding: 40,
  },

  placeholder: {
    alignSelf: 'center',
    textAlign: 'center',
    fontFamily: '$brandFontFamily',
    color: '$infoTextColor',
    fontSize: 14,
  },

  paymentRequiredHint: {
    fontFamily: '$brandFontFamily',
    color: '$secondaryTextColor',
    fontSize: 10,
  },

  textBold: {
    fontWeight: 'bold',
  },
});

export default styles;
