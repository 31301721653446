import React from 'react';
import { View, Image, ActivityIndicator, Text } from 'react-native';
import Resources from 'dating-mobile/src/resources';
import testId from 'dating-mobile/src/utils/test-id';

import styles from './styles';

function PaymentProcessing() {
  return (
    <View style={styles.container}>
      <View style={styles.contentWrapper}>
        <Image
          style={styles.logo}
          source={Resources.images.logoIconWithText()}
          resizeMode="contain"
          {...testId('Logo')}
        />
        <View style={styles.loaderWrapper}>
          <ActivityIndicator size={40} animating />
          <Text style={styles.loaderText}>
            {Resources.strings['card-payment-processing']}
          </Text>
        </View>
      </View>
    </View>
  );
}

export default PaymentProcessing;
