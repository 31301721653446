import withConfigValue from 'dating-mobile/src/components/config-value';
import View from './view';

export default withConfigValue(View, {
  useSeparateButtonForMediaAttach:
    'markup.chat-screen.separate-buttons-for-media-attach',
  giftsEnabled: 'features.chat-gifts-enabled',
  stickersEnabled: 'features.stickers-enabled',
  smilesEnabled: 'features.smiles-enabled',
  mediaInChatEnabled: 'features.media-in-chat-enabled',
  inboxEnabled: 'features.inbox-enabled',
  currency: 'currency.type',
  devaluationRatio: 'currency.devaluation-ratio',
  sendMessageNonDevaluatedPrice: 'pricing.send-message-price',
  sendSmileNonDevaluatedPrice: 'pricing.send-smile-price',
  sendStickerNonDevaluatedPrice: 'pricing.send-sticker-price',
  sendMessageToStreamerNonDevaluatedPrice:
    'pricing.send-message-to-streamer-price',
  showPricingPlaceholdersForStreamers:
    'chat.show-placeholder-with-price-for-streamer',
  showPricingPlaceholders: 'chat.show-placeholders-with-price',
  textMessageMaxLength: 'chat.text-message-max-length',
  creditsEnabledForFeatures: 'features.credits-enabled-for-features',
  randomChatPlaceholdersEnabled: 'features.random-chat-placeholders-enabled',
});
