export class CreditCardValidity {
  /**
   *
   * @param {CreditCard} card
   */
  validate(card) {
    const invalidFields = [];

    if (this.isNumberNotValid(card.number)) {
      invalidFields.push('number');
    }

    if (this.isVerificationsNotValid(card.verification)) {
      invalidFields.push('verification');
    }

    if (this.isExpMonthNotValid(card.expDate?.month)) {
      invalidFields.push('expDate.month');
    }

    if (this.isExpYearNotValid(card.expDate?.year)) {
      invalidFields.push('expDate.year');
    }

    if (this.isExpDateNotValid(card.expDate)) {
      invalidFields.push('expDate');
    }

    if (this.isHolderNotValid(card.holder)) {
      invalidFields.push('holder');
    }

    return invalidFields;
  }

  /**
   *@private
   */
  isNumberNotValid(number) {
    return !(
      number &&
      number.length > 15 &&
      this.isValidByLuhnAlgorithm([...number])
    );
  }

  isValidByLuhnAlgorithm(digits) {
    let sum = 0;

    for (let i = 0; i < digits.length; i++) {
      // eslint-disable-next-line radix
      let cardNum = parseInt(digits[i]);

      if ((digits.length - i) % 2 === 0) {
        cardNum *= 2;
        if (cardNum > 9) {
          cardNum -= 9;
        }
      }
      sum += cardNum;
    }

    return sum % 10 === 0;
  }

  /**
   *@private
   */
  isVerificationsNotValid(verification) {
    return !/^\d{3}$/.test(verification);
  }

  /**
   *@private
   */
  isHolderNotValid(holder) {
    return !(holder && holder.length);
  }

  /**
   *@private
   */
  isExpMonthNotValid(month) {
    return Boolean(!month || +month < 1 || +month > 12);
  }

  /**
   *@private
   */
  isExpYearNotValid(year) {
    return Boolean(!year || year.length > 2);
  }

  /**
   *@private
   */
  isExpDateNotValid(expDate) {
    if (!expDate) return true;

    const month = +expDate.month;
    const year = +expDate.year;
    const now = new Date();
    const expInMonth = (2000 + year) * 12 + month;
    const nowInMonth = now.getFullYear() * 12 + now.getMonth() + 1;
    const isExpValid = nowInMonth <= expInMonth;

    return !isExpValid;
  }
}
