import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import Resources from 'dating-mobile/src/resources';

import styles from './styles';

export default class CheckboxItem extends Component {
    static propTypes = {
        onPress: PropTypes.func,
        image: PropTypes.string,
        text: PropTypes.string,
        isActive: PropTypes.bool,
    };

    renderOverlay = () => {
        const { isActive } = this.props;

        return isActive ? (
            <View style={styles.overlay}>
                <View style={styles.iconContainer}>
                    <Image
                        resizeMode="contain"
                        source={Resources.images.acceptIcon()}
                        style={styles.icon}
                    />
                </View>
            </View>
        ) : null;
    };

    render() {
        const { image, onPress, text } = this.props;

        return (
            <TouchableOpacity onPress={onPress} style={styles.container}>
                <Image source={image} style={styles.image} />
                <Text style={styles.text}>{text}</Text>
                {this.renderOverlay()}
            </TouchableOpacity>
        );
    }
}
