import UserName from './view';
import withUserController from 'dating-mobile/src/models/user/controllers';
import { withAliases } from 'dating-mobile/src/models/user/controllers/withAliases';

export default withUserController(UserName, {
    userIdPropName: 'userId',
});

const UserNameAliased = withAliases(UserName);

export { UserNameAliased };
