import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import OnlineIndicator from 'dating-mobile/src/user/common/views/online-indicator';
import UserName from 'dating-mobile/src/user/common/views/user-name';
import SwitcherConfigValue from 'dating-mobile/src/components/config-value/switcher';

import styles from '../styles';

class NameComponent extends React.PureComponent {
    static displayName = 'user.profile.views.profile-description.name';

    static propTypes = {
        id: PropTypes.string,
        titleStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.array]),
        accessoryView: PropTypes.object,
    };

    render() {
        return (
            <View style={[styles.bubbleContainer, this.props.style || {}]}>
                <View style={styles.bubbleTitleContainer}>
                    <View style={styles.nameContainer}>
                        <SwitcherConfigValue path="features.anonymous-using-enabled">
                            {value => (
                                <UserName
                                    showAge={!value}
                                    style={[styles.bubbleTitle, this.props.titleStyle]}
                                    userId={this.props.id}
                                />
                            )}
                        </SwitcherConfigValue>
                        <View style={styles.onlineIndicatorContainer}>
                            <OnlineIndicator userId={this.props.id} />
                        </View>
                    </View>
                    {this.props.accessoryView}
                </View>
            </View>
        );
    }
}

export default NameComponent;
