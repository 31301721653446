import React from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity } from 'react-native';
import UserHocController from '../../../../../models/user/controllers';
import StreamerPreview, { getHeight as getPreviewHeight } from '../streamer-preview';
import styles from './styles';
import testId from '../../../../../utils/test-id';

const ProfileView = UserHocController(StreamerPreview);

export default class StreamListItem extends React.PureComponent {
    static displayName = 'stream-list-item';
    static contextTypes = {
        flux: PropTypes.object,
    };
    static propTypes = {
        id: PropTypes.string.isRequired,
        viewers: PropTypes.number,
        title: PropTypes.string,
        tags: PropTypes.array,
        onPress: PropTypes.func,
    };

    constructor(props, context) {
        super(props);
        this.bus = context.flux.bus;
    }

    itemSelected = () => {
        this.props.onPress && this.props.onPress(this.props.id);
    };

    render() {
        return (
            <TouchableOpacity
                style={styles.container}
                onPress={this.itemSelected}
                {...testId('Streamer list item')}
            >
                <ProfileView
                    key={this.props.id}
                    id={this.props.id}
                    viewers={this.props.viewers}
                    title={this.props.title}
                    tags={this.props.tags}
                    cover={this.props.cover}
                />
            </TouchableOpacity>
        );
    }
}

export function getHeight(itemWidth) {
    return getPreviewHeight(itemWidth);
}
