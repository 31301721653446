import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    closeIconContainer: {
        position: 'absolute',
        top: 32,
        right: 26,
        zIndex: 1,
    },

    closeIcon: {
        width: 32,
        height: 32,
        opacity: 0.2,
    },

    container: {
        width: '100%',
        height: '100%',
    },

    contentContainer: {
        alignItems: 'center',
        justifyContent: 'flex-end',
        flexGrow: 1,
        marginBottom: 20,
    },

    title: {
        fontSize: 40,
        fontFamily: '$brandFontFamily',
        fontWeight: 'bold',
        color: '#ffffff',
        marginBottom: 5,
        marginHorizontal: 40,
    },

    description: {
        fontSize: 14,
        fontFamily: '$brandFontFamily',
        color: '#ffffff',
        marginBottom: 20,
        maxWidth: 180,
        textAlign: 'center',
        marginHorizontal: 40,
    },

    plansContainer: {
        width: '100%',
        marginBottom: 30,
        alignSelf: 'stretch',
        paddingHorizontal: 20,
    },

    placeholder: {
        width: '100%',
        height: 294,
    },

    buttonContainer: {
        width: '100%',
        paddingHorizontal: 40,
    },

    button: {
        marginBottom: 30,
    },

    termsDescription: {
        fontSize: 12,
        color: '#ffffff',
        fontFamily: '$brandFontFamily',
    },

    activityIndicatorContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },

    termsDescriptionContainer: {
        marginHorizontal: 40,
        height: 80,
        '@media android': {
            marginHorizontal: 20,
        },
    },
});

export default styles;
