import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({

    container: {
        height: 44,
        justifyContent: 'center',
        alignItems: 'center'
    },

    text: {
        color: 'white',
        fontSize: 16,
        marginRight: 15,
        marginLeft: 15,
        fontWeight: '500',
        fontFamily: '$brandFontFamily',
        '@flavor lovinga': {
            fontWeight: 'normal'
        }
    },

    disabled: {
        color: '$brandPlaceholderColor'
    }

});

export default styles;
