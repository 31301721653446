import { resolve } from 'url';

export function userPhotoPath(userId, basename, isPrivate, isPreview) {
  if (!userId || !basename) {
    console.warn(
      `userPhotoPath can't be resolved (userId:${userId}, basename:${basename})`,
    );

    return undefined;
  }

  const previewModifier = isPreview ? '/preview' : '';

  return `/users/${userId}/photos${
    isPrivate ? '/private' : ''
  }${previewModifier}/${basename}`;
}

export function userVideoPath(userId, basename, isPreview) {
  if (!userId || !basename) {
    console.warn(
      `userVideoPath can't be resolved (userId:${userId}, basename:${basename})`,
    );

    return undefined;
  }

  const previewModifier = isPreview ? '/preview' : '';

  return `/users/${userId}/videos${previewModifier}/${basename}`;
}

export function dialogPhotoPath(
  senderId,
  recipientId,
  basename,
  isPreview,
  writingAsAnonymous,
) {
  if (!senderId || !recipientId || !basename) {
    console.warn(
      "dialogPhotoPath can't be resolved " +
        `(senderId:${senderId}, recipientId:${recipientId}), basename:${basename}`,
    );

    return undefined;
  }

  const anonymousModifier = writingAsAnonymous ? 'avatars/' : '';
  const previewModifier = isPreview ? '/preview' : '';

  return resolve(
    `/dialogs/usermedia/${anonymousModifier}${recipientId}/${senderId}${previewModifier}/`,
    basename,
  );
}

export function dialogVideoPath(
  senderId,
  recipientId,
  basename,
  isPreview,
  writingAsAnonymous,
) {
  if (!senderId || !recipientId || !basename) {
    console.warn(
      "dialogVideoPath can't be resolved " +
        `(senderId:${senderId}, recipientId:${recipientId}), basename:${basename}`,
    );

    return undefined;
  }

  const anonymousModifier = writingAsAnonymous ? 'avatars/' : '';
  const previewModifier = isPreview ? '/frame' : '';

  return resolve(
    `/dialogs/usermedia/${anonymousModifier}${recipientId}/${senderId}${previewModifier}/`,
    basename,
  );
}

export function stickerPath(basename) {
  if (!basename) {
    console.warn(`stickerPath can't be resolved (basename:${basename}`);

    return undefined;
  }

  return `/dialogs/stickers/${basename}`;
}

export function smilePath(basename) {
  if (!basename) {
    console.warn(`smilePath can't be resolved (basename:${basename}`);

    return undefined;
  }

  return `/dialogs/smiles/${basename}`;
}

export function getLetterPath(senderId, recipientId, letterId, isIntroductory) {
  if (!senderId || !recipientId || !letterId) {
    console.warn(
      "letterPath can't be resolved " +
        `(senderId:${senderId}, recipientId:${recipientId}), letterId:${letterId}`,
    );

    return undefined;
  }

  const introductoryModifier = isIntroductory ? '/introductory' : '';

  return `/dialogs/letters${introductoryModifier}/${recipientId}/${senderId}/${letterId}`;
}

export function dialogResource(userId, attendeeId) {
  return `/dialogs/messages/${userId}:${attendeeId}`;
}

export function streamResource(streamId) {
  return `/live-streams/${streamId}`;
}

export function profileResource(userId) {
  return `/users/${userId}`;
}

export function profileMediaResource(media, userId) {
  const isPrivate = media.tags?.indexOf('hidden') >= 0;
  const isImage = media.mediatype?.startsWith('image');
  const resource = isImage
    ? userPhotoPath(userId, media.basename, isPrivate)
    : userVideoPath(userId, media.basename);

  return resource;
}
