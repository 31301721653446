import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { PURCHASE } from 'dating-mobile/src/routing/router/constants';

import { BoostExtendNotification } from '@sdv/domain/user/profile-booster/boost-extend-notification';

export default function createController(Component) {
  return class Controller extends PureComponent {
    static displayName =
      'components.purchase-promotion-banner.boost-extend-woman.controller';

    static propTypes = {
      userId: PropTypes.string.isRequired,
      navigation: PropTypes.object,
    };

    state = {
      isActive: false,
    };

    componentDidMount() {
      this.subscribe();
    }

    componentDidUpdate(prevProps) {
      const { userId } = this.props;

      if (userId !== prevProps.userId) {
        this.unsubscribe();
        this.subscribe();
      }
    }

    componentWillUnmount() {
      this.unsubscribe();
    }

    boost = () => {
      const { navigation } = this.props;

      navigation.navigate(PURCHASE);
    };

    subscribe() {
      const { userId } = this.props;
      const notification = BoostExtendNotification.shared(userId);

      this.subscription = notification.extendOfferActive.subscribe(isActive =>
        this.setState({ isActive }),
      );
    }

    unsubscribe() {
      if (this.subscription) {
        this.subscription.unsubscribe();
        this.subscription = null;
      }
    }

    render() {
      const { isActive } = this.state;

      if (!isActive) {
        return null;
      }

      return <Component onExtendPress={this.boost} />;
    }
  };
}
