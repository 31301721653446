import React from 'react';
import PropTypes from 'prop-types';
import EventEmitter from 'eventemitter3';
import MallModel from '../model';

function createMallController(Component) {
  class MallController extends React.Component {
    static contextTypes = {
      flux: PropTypes.object,
    };

    static propTypes = {
      id: PropTypes.string,
      bus: PropTypes.object,
      reason: PropTypes.string,
      exchangeInterceptor: PropTypes.func,
    };

    state = {
      products: [],
      isLoading: false,
      isRestoring: false,
    };

    constructor(props, context) {
      super(props, context);
      this.flux = context.flux;
      this.bus = props.bus || new EventEmitter();
    }

    componentDidMount() {
      this.subscribe();
      this.bus.addListener('command.payment.purchase', this.buyProduct);
    }

    componentDidUpdate(prevProps) {
      const { id } = this.props;

      if (id !== prevProps.id) {
        this.unsubscribe();
        this.subscribe();
      }
    }

    componentWillUnmount() {
      this.unsubscribe();
      this.bus.removeListener('command.payment.purchase', this.buyProduct);
    }

    update = state => {
      this.setState({
        products: state.products,
        isLoading: false,
      });
    };

    buyProduct = product => {
      return new Promise((resolve, reject) => {
        this.setState(
          {
            isLoading: true,
          },
          () => {
            const { reason, exchangeInterceptor } = this.props;

            this.model.actions.purchase(
              product,
              reason,
              exchangeInterceptor,
              error => {
                this.setState(
                  {
                    isLoading: false,
                  },
                  () => {
                    if (!error) {
                      this.bus.emit('event.payment.completed', product);
                      this.bus.emit('command.payment.close');

                      return resolve();
                    }

                    return reject(error);
                  },
                );
              },
            );
          },
        );
      });
    };

    restorePurchases = () => {
      return new Promise((resolve, reject) => {
        this.setState(
          {
            isRestoring: true,
          },
          () => {
            this.model.actions.restorePurchases(error => {
              this.setState(
                {
                  isRestoring: false,
                },
                () => {
                  if (!error) {
                    return resolve();
                  }

                  return reject(error);
                },
              );
            });
          },
        );
      });
    };

    subscribe() {
      const { id } = this.props;

      if (!id) {
        return;
      }

      this.model = this.flux.get(MallModel, id);

      const modelState = this.model.store.getState();

      this.setState({
        products: modelState.products,
        isLoading: modelState.products.length === 0,
      });

      this.model.store.listen(this.update);
      this.model.actions.get();
    }

    unsubscribe() {
      if (this.model) {
        this.model.store.unlisten(this.update);
      }

      this.model = null;
    }

    render() {
      return (
        <Component
          {...this.props}
          {...this.state}
          buyProduct={this.buyProduct}
          restorePurchases={this.restorePurchases}
          bus={this.bus}
        />
      );
    }
  }

  return MallController;
}

export default createMallController;
