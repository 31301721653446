import Actions from './actions';
import { createOptimisticStore } from '@sdv/model';

function createPreferenceStore(id) {

    class Store extends createOptimisticStore(['put']) {

        constructor(flux) {
            const actions = flux.getActions(Actions, id);
            super(actions);

            this.bindAction(actions.get, this.update);
        }

        update(preferences) {

            if (!preferences) {
                return;
            }

            this.state.data = preferences;
            this.setState(this.state);

        }

    }

    Store.displayName = createPreferenceStore.getDisplayName(id);

    return Store;
}

createPreferenceStore.getDisplayName = function (id) {
    return `user-preferences.${id}`;
};

export default createPreferenceStore;
