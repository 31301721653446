import React from 'react';
import PropTypes from 'prop-types';
import { View, Image, Text, TouchableOpacity } from 'react-native';
import Resources from 'dating-mobile/src/resources';
import styles from './styles';

export default class MessageItem extends React.Component {
    static displayName = 'dialogs.mingle.message-selection.message-item';

    static propTypes = {
        message: PropTypes.string,
        onPress: PropTypes.func,
        customStyles: PropTypes.shape({
            bubble: PropTypes.object,
            text: PropTypes.object,
            sendIcon: PropTypes.object,
            triangle: PropTypes.object,
        }),
    };

    shouldComponentUpdate(nextProps) {
        return this.props.message !== nextProps.message;
    }

    onPressed = () => {
        this.props.onPress && this.props.onPress(this.props.message);
    };

    render() {
        const { customStyles = {} } = this.props;

        return (
            <TouchableOpacity style={styles.container} onPress={this.onPressed}>
                <View style={styles.content}>
                    <View style={[styles.bubble, customStyles.bubble]}>
                        <Text style={[styles.text, customStyles.text]}>
                            {this.props.message || ''}
                        </Text>
                        <Image
                            style={[styles.sendIcon, customStyles.sendIcon]}
                            source={Resources.images.sendIcon()}
                        />
                    </View>
                    <Image
                        style={[styles.triangle, customStyles.triangle]}
                        source={require('./assets/triangle_icon.png')}
                    />
                </View>
            </TouchableOpacity>
        );
    }
}
