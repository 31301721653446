import EStyleSheet from 'react-native-extended-stylesheet';

const indicator = {
    marginLeft: -10,
    width: 10,
    height: 10,
    borderRadius: 5,
    borderWidth: 2,
    borderColor: 'white'
};

const styles = EStyleSheet.create({
    container: {
        flexDirection: 'row',
        height: 32
    },
    image: {
        width: 24,
        height: 24,
        alignSelf: 'center',
    },
    iconActive: {
        tintColor: '$selectedColor'
    },

    iconInactive: {
        tintColor: '$tabBarIconInactiveColor'
    },
    boostProfileIndicator: {
        ...indicator,
        backgroundColor: '$brandNotificationsColor',
    },
    pushNotificationsIndicator: {
        ...indicator,
        backgroundColor: '$accentColor'
    },
    textContainer: {
        marginTop: -2,
        marginLeft: -12,
        backgroundColor: '$brandNotificationsColor',
        width: 16,
        height: 16,
        borderRadius: 10,
        borderWidth: 2,
        borderColor: 'white',
        justifyContent: 'center',
        alignItems: 'center',
        '@flavor tubit': {
            borderWidth: 0,
        }
    },
});

export default styles;
