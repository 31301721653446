function createDevicePermissionsActions(id = 'default') {
    class Actions {

        updatePushNotification = (isEnabled) => (dispatch) => {
            dispatch(isEnabled);
        };

    }

    Actions.displayName = createDevicePermissionsActions.getDisplayName(id);
    return Actions;
}

createDevicePermissionsActions.getDisplayName = function (id) {
    return `device-permissions.${id}`;
};

export default createDevicePermissionsActions;
