import EStyleSheet from 'react-native-extended-stylesheet';
// @ts-ignore
import shouldUseWebLayout from 'dating-mobile/src/utils/web-layout';

const styles = EStyleSheet.create({
  $tabBarShowLabel: '$tabBarTitleVisible',
  $tabBarActiveTintColor: '$selectedColor',
  $tabBarInactiveTintColor: '$tabBarIconInactiveColor',

  $topTabBarAccentColor: '$accentColor',
  $topTabBarInactiveColor: '$brandTextColor',

  defaultHeader: {
    backgroundColor: '$brandColor',
    borderBottomColor: '$brandColor',
    shadowColor: '$brandColor',
    shadowRadius: 0,
    shadowOpacity: 0,
    elevation: 0,
    shadowOffset: {
      height: 0,
    },
  },

  defaultHeaderTitle: {
    fontFamily: '$brandFontFamily',
    color: '$brandTextColor',
  },

  appContainer: { flex: 2 },
  rootContainer: {
    flex: 1,
    flexDirection: shouldUseWebLayout() ? 'row' : 'column',
  },
  navPanelWrapper: {
    flex: 1,
    padding: 24,
    backgroundColor: '#f3f3f3',
  },

  tabBar: {
    borderTopWidth: 0,
    backgroundColor: '$primaryBackgroundColor',
  },

  tabBarIcon: {
    width: 24,
    height: 24,
  },

  tabBarIconActive: {
    tintColor: '$tabBarActiveTintColor',
  },

  tabBarIconInactive: {
    tintColor: '$tabBarInactiveTintColor',
  },

  startStreamButtonContainer: {
    marginTop: -16,
    overflow: 'visible',
  },

  startStreamButtonImage: {
    width: 72,
    height: 72,
  },
  startStreamButtonShadowContainer: {
    position: 'absolute',
    left: '50%',
    marginLeft: -150,
    bottom: -100,
  },
  startStreamButtonShadow: {
    width: 300,
    height: 184,
  },

  cardContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: '#f3f3f3',
  },

  shortCard: {
    maxWidth: 700,
    width: '100%',
    alignSelf: 'center',
  },
  onboardingBar: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    paddingHorizontal: 40,
  },

  authBackgroundContainer: {
    position: 'absolute',
    width: '50%',
    height: '100%',
  },
  authBackgroundImage: {
    width: '100%',
    height: '100%',
  },
  splashTextContainer: {
    paddingTop: 2,
    paddingRight: 15,
    paddingBottom: 4,
    paddingLeft: 8,
    backgroundColor: 'black',
    position: 'absolute',
    left: 20,
    bottom: 60,
  },
  splashText: {
    fontSize: 32,
    fontWeight: 'bold',
    color: '$brandTextColor',
  },

  topTabBarLabelStyle: {
    fontFamily: '$brandFontFamily',
    fontSize: 17,
    fontWeight: 'bold',
  },
  topTabBarIndicatorStyle: {
    height: 2,
    backgroundColor: '$accentColor',
  },
  topTabBarStyle: {
    paddingTop: 22,
    backgroundColor: '$brandColor',
    borderBottomColor: '$brandColor',
    shadowColor: '$brandColor',
  },

  paymentProcessingCard: {
    maxWidth: 800,
    width: '100%',
    maxHeight: 700,
    alignSelf: 'center',
    shadowColor: '$primaryTextColor',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },
  paymentProcessingContainer: {
    justifyContent: 'center',
    backgroundColor: '$primaryBackgroundColor',
  },
});

export default styles;
