import React, { PureComponent } from 'react';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import PropTypes from 'prop-types';
import styles from './styles';
import { localizePrice } from '@sdv/commons/price-with-code-localization';
import { calculateTax, calculatePriceWithoutTax } from '@sdv/domain/payment/price';
import Resources from 'dating-mobile/src/resources';

export class PaymentMethodItemView extends PureComponent {
    static displayName = 'payment.screens.payment-methods.item.view';

    static propTypes = {
        title: PropTypes.number.isRequired,
        price: PropTypes.shape({
            includingTax: PropTypes.number,
            currency: PropTypes.string,
            localizedIncludingTax: PropTypes.string,
        }),
        onPress: PropTypes.func.isRequired,
        icon: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
    };

    handlePress = () => {
        this.props.onPress && this.props.onPress();
    };

    renderPrice = () => {
        const { price } = this.props;

        let localizedPrice;
        let localizedTax;

        if (
            styles.$forceLocalizePrice &&
            price.localizedCurrency &&
            price.localizedWithoutCurrency
        ) {
            localizedPrice = localizePrice(price.localizedCurrency, price.localizedWithoutCurrency);
        } else if (price.localizedIncludingTax !== undefined) {
            localizedPrice = price.localizedIncludingTax;
        } else {
            localizedPrice = localizePrice(price.currency, calculatePriceWithoutTax(price));

            if (price.tax !== undefined) {
                const taxPrice = localizePrice(price.currency, calculateTax(price));

                localizedTax = Resources.strings.formatString(
                    Resources.strings['payment-plus-tax-info'],
                    taxPrice,
                    price.tax.abbreviation,
                );
            }
        }

        return localizedTax === undefined ? (
            <Text style={styles.price}>{localizedPrice}</Text>
        ) : (
            <View style={styles.priceWithTax}>
                <Text style={styles.price}>{localizedPrice}</Text>
                <Text style={styles.tax}>{localizedTax}</Text>
            </View>
        );
    };

    render() {
        return (
            <TouchableOpacity onPress={this.handlePress}>
                <View style={styles.container}>
                    <View style={styles.content}>
                        <View style={styles.methodContainer}>
                            {this.props.icon && (
                                <Image
                                    style={styles.icon}
                                    source={this.props.icon}
                                    resizeMode={'contain'}
                                />
                            )}
                            <Text style={styles.method}>{this.props.title}</Text>
                        </View>
                        {this.renderPrice()}
                    </View>
                </View>
            </TouchableOpacity>
        );
    }
}
