import React from 'react';
import { StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import ImageButton from 'dating-mobile/src/components/navigation/views/image-button';
import Resources from 'dating-mobile/src/resources';
import Navigator from 'dating-mobile/src/routing/navigator';
import * as ROUTES from 'dating-mobile/src/routing/router/constants';
import styles from './styles';

function withNavigation(Component) {
  class Navigation extends React.Component {
    static displayName = 'inbox.screens.letter-reading.navigation';

    static propTypes = {
      navigation: PropTypes.object.isRequired,
    };

    componentDidMount() {
      const { navigation } = this.props;

      navigation.setParams({ dismiss: this.dismiss });
    }

    replyToLetter = () => {
      const { navigation } = this.props;

      navigation.navigate(ROUTES.WRITE_LETTER, {
        recipientId: navigation.getParam('sender'),
      });
    };

    close = () => {
      const { navigation } = this.props;

      navigation.pop();
    };

    dismiss = () => {
      const { navigation } = this.props;
      const stack = Navigator.currentStack();

      if (navigation) {
        if (stack.indexOf(ROUTES.CHAT) >= 0) {
          navigation.navigate(ROUTES.CHAT, {
            id: navigation.getParam('recipientId'),
          });
        } else {
          navigation.pop();
        }
      }
    };

    render() {
      const { navigation } = this.props;

      return (
        <Component
          {...this.props}
          recipient={navigation.getParam('recipient')}
          sender={navigation.getParam('sender')}
          letter={navigation.getParam('letter')}
          introductory={navigation.getParam('introductory')}
          replyToLetter={this.replyToLetter}
          dismiss={this.dismiss}
        />
      );
    }
  }

  Navigation.navigationOptions = ({ navigation }) => ({
    header: undefined,
    headerStyle: undefined,
    headerTransparent: true,
    headerTintColor: styles.headerTitle,
    headerLeft: () => (
      <ImageButton
        image={Resources.images.backIcon()}
        tint={StyleSheet.flatten(styles.headerTitle).color}
        onPress={() => navigation.getParam('dismiss')()}
      />
    ),
  });

  return Navigation;
}

export default withNavigation;
