import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $statusBarStyle: '$primaryStatusBarStyle',
    $statusBarBackgroundColor: '$primaryBackgroundColor',
    $safeAreaInset: {
        top: 'never',
        bottom: 'always'
    },

    header: {
        backgroundColor: '$primaryBackgroundColor',
        borderBottomColor: '$primaryBackgroundColor',
        shadowColor: '$primaryBackgroundColor'
    },

    headerTitle: {
        color: '$primaryTextColor'
    },

    container: {
        backgroundColor: '$primaryBackgroundColor',
        flex: 1,
    },

    webView: {
        flex: 1,
        backgroundColor: '$primaryBackgroundColor'
    }

});

export default styles;
