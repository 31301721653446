import React, { PureComponent } from 'react';
import { View, Text, Switch } from 'react-native';
import PropTypes from 'prop-types';
import Resources from 'dating-mobile/src/resources';

import styles from './styles';

export default class ProfileVisibilitySwitcher extends PureComponent {
  static displayName =
    'user.views.user-visibility-in-feed.views.visibility-switcher';

  static propTypes = {
    value: PropTypes.bool,
    onValueChange: PropTypes.func,
    disabled: PropTypes.bool,
  };

  getDescription = () => {
    const { value } = this.props;

    return Resources.strings[
      `profile-visibility-switch-description-${value ? 'yes' : 'no'}`
    ];
  };

  render() {
    const { value, onValueChange, disabled } = this.props;

    return (
      <View style={styles.container}>
        <View>
          <View style={styles.controlBlock}>
            <Text style={styles.label}>
              {Resources.strings['profile-visibility-switch-label']}
            </Text>
            <Switch
              disabled={disabled}
              style={styles.switch}
              value={value}
              onValueChange={onValueChange}
            />
          </View>
        </View>
        <View style={styles.descriptionBlock}>
          <Text style={styles.description}>{this.getDescription()}</Text>
        </View>
      </View>
    );
  }
}
