import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

  amount: {
    fontSize: 18,
    fontWeight: '700',
    color: '$primaryTextColor',
    '@media ios': {
      fontWeight: '600',
      fontFamily: '$brandFontFamily',
    },
  },

  coin: {
    marginLeft: 4,
    width: 24,
    aspectRatio: 1,
  },
});

export default styles;
