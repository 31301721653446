import React from 'react';
import PropTypes from 'prop-types';
import withStreams from 'dating-mobile/src/models/dialogs.streams/controllers/controller';
import withUserController from 'dating-mobile/src/models/user/controllers';
import { STREAM } from 'dating-mobile/src/routing/router/constants';
import { withNavigation } from 'react-navigation';

export default function createController(Component) {
  class StreamingCtaController extends React.PureComponent {
    static displayName = 'dialogs.messages.views.streaming-cta.controller';

    static contextTypes = {};

    static propTypes = {
      navigation: PropTypes.object,
      attendeeId: PropTypes.string.isRequired,
      streams: PropTypes.array,
      user: PropTypes.shape({
        name: PropTypes.string,
      }),
    };

    onPress = () => {
      const { navigation, attendeeId } = this.props;

      navigation.navigate(STREAM, { id: attendeeId });
    };

    render() {
      const { streams, attendeeId, user } = this.props;
      const streamIndex = streams.indexOf(attendeeId);
      const userIsStreaming = streamIndex !== -1;
      const userName = user.name;

      return (
        <Component
          {...this.props}
          userName={userName}
          isVisible={userIsStreaming}
          onPress={this.onPress}
        />
      );
    }
  }

  return withNavigation(
    withUserController(withStreams(StreamingCtaController), {
      userIdPropName: 'attendeeId',
    }),
  );
}
