import React from 'react';
import {Text, View, TouchableOpacity, FlatList, Dimensions} from 'react-native';
import PropTypes from 'prop-types';
import styles from './styles';

import Dicts from '../../../../models/misc-data/controller';
import Photo from "../../../../components/photo";

const { width } = Dimensions.get('window');
const SCREEN_PADDING = 45;

class InterestsView extends React.Component {
    static displayName = 'feed.criteria.interests';
    static propTypes = {
        onPressItem: PropTypes.func.isRequired,
        dictValues: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            [props.dataType]: this.props.selected || []
        };
    }

    toggleSelect = (value) => () => {
        const dict = this.props.dataType;

        this.setState((state) => ({
            [dict]: !state[dict].includes(value)
                ? state[dict].concat([value])
                : state[dict].filter((current) => current !== value)
        }), () => this.props.onPressItem(this.state[dict]));
    };

    getItem = ({item}) => {
        const selected = this.props.selected || [];
        const style = [
            (selected.includes(item.value) ? styles.interestSelected : styles.interest),
            { width: Math.ceil((width - SCREEN_PADDING)/3)}
        ];

        return (
            <TouchableOpacity key={item.value} onPress={this.toggleSelect(item.value)}>
                <View style={style}>
                    <Photo style={styles.interestImage} forceRetina={true} path={`/interests/${item.value}`}/>
                    <Text style={styles.interestText}>{item.title}</Text>
                </View>
            </TouchableOpacity>
        )
    };

    keyExtractor = (item) => {
        return item.value;
    };

    static getItems(dicts) {
        return dicts ? Object.keys(dicts).reduce((options, key) => {
            return options.concat({ title: dicts[key], value: key.toLowerCase() });
        }, []) : [];
    }

    render() {
        const {...props} = this.props;

        return (
            <FlatList
                numColumns={3}
                renderItem={this.getItem}
                keyExtractor={this.keyExtractor}
                containerStyle={styles.interests}
                data={InterestsView.getItems(props.dictValues)}
            />
        )
    }
}

export default Dicts(InterestsView);
