import {
  filter,
  map,
  distinctUntilChanged,
  switchMap,
  take,
} from 'rxjs/operators';
import { singleton } from '@sdv/commons/utils/singleton';
import UserTags from '@sdv/domain/user/tags';
import { UserTags as Tags } from '@sdv/domain/user/tags/user-tags';

export class AudioCallPrepare {
  static shared = singleton(userId => new AudioCallPrepare(userId));

  constructor(userId) {
    UserTags.shared()
      .tagsOf(userId)
      .pipe(
        map(tags => !tags.includes(Tags.AudioCallReady)),
        filter(Boolean),
        distinctUntilChanged(),
        switchMap(() =>
          UserTags.shared().setTagEnabledForUser(
            Tags.AudioCallReady,
            true,
            userId,
          ),
        ),
        take(1),
      )
      .subscribe(
        () => {},
        () => {},
      );
  }
}
