// TODO: Split this massive file into smaller components
import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, Image } from 'react-native';
import SendEmailViewComponent from 'dating-mobile/src/user/profile/views/profile-description/send-email';
import withUserMedia from 'dating-mobile/src/models/user.media/controller/gallery-controller';
import { profileResource } from '@sdv/domain/resources/remote';
import { DistanceToUserView } from 'dating-mobile/src/user/common/views/distance-to-user';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';
import { withLocationAlias } from 'dating-mobile/src/user/aliases';
import { PreviewTags } from '@sdv/domain/user/tags/user-tags';
import styles from './styles';
import UserInterestsController from '../../../../models/user.interests/controller';
import DictValuesController from '../../../../models/misc-data/controller';
import getDisplayDate from '../../../../utils/date-time-formatting';
import configValue from '../../../../components/config-value';
import Resources from '../../../../resources';
import ReportButton from '../../../../components/report-button';
import IfConfigValue from '../../../../components/config-value/if';
import TravelActivitiesComponent from '../../../common/views/interests-view';
import UserGoalsView from './user-goals';
import LookingForDescriptionComponent from './looking-for-description';
import { GALLERY_ITEMS } from '../../../../models/user.media/controller/gallery-controller';
import GalleryView from '../photo-gallery';
import UserNameComponent from './name';

function getLiveInDisplayText(props) {
  let text = null;
  let country = null;

  if (props.city) {
    text = props.city;
  }
  if (props.country && props.dictValues[props.country]) {
    country = props.dictValues[props.country];
    if (text) {
      text += `, ${country}`;
    } else {
      text = country;
    }
  }

  return text;
}

const getItem = (name, value) => {
  return name && value ? (
    <View key={`${name}-${value}`} style={styles.itemContainer}>
      <Text numberOfLines={1}>
        <Text style={styles.itemFieldName}>{name}</Text>
        <Text style={styles.itemFieldValue}>{value}</Text>
      </Text>
    </View>
  ) : null;
};

const BubbleView = Component => {
  class Bubble extends React.Component {
    static propTypes = {
      title: PropTypes.string,
      accessoryView: PropTypes.object,
      showUnderline: PropTypes.bool,
      titleStyle: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
        PropTypes.array,
      ]),
    };

    render() {
      const { title, titleStyle, accessoryView, showUnderline } = this.props;
      const titleExist = title?.length > 0;

      return (
        <View style={styles.bubbleContainer}>
          {!!titleExist && (
            <View style={styles.bubbleTitleContainer}>
              <Text style={[styles.bubbleTitle, titleStyle]}>{title}</Text>
              {accessoryView}
            </View>
          )}
          <Component {...this.props} />
          {styles.canHaveUnderline && !!showUnderline && (
            <View style={styles.underline} />
          )}
        </View>
      );
    }
  }

  return Bubble;
};

const TravelDestinationsContent = props => {
  const content = [];

  if (props.travels) {
    for (let i = 0; i < props.travels.length; i++) {
      const item = props.travels[i];
      const countryDisplayName = item.country
        ? props.dictValues[item.country]
        : null;
      let text = '';

      if (item.city) {
        text = item.city;
      }
      if (item.city && countryDisplayName) {
        text += ', ';
      }
      if (countryDisplayName) {
        text += countryDisplayName;
      }
      content.push(<Text style={styles.travelDestination}>{text}</Text>);
    }
  }

  return content;
};

const TravelDestinationContentWithDict = DictValuesController(
  TravelDestinationsContent,
);

const TravelDestinationsView = BubbleView(TravelDestinationContentWithDict);

const FewWordsContent = ({ aboutText }) => {
  return <Text style={styles.fewWordsText}>{aboutText}</Text>;
};

FewWordsContent.propTypes = {
  aboutText: PropTypes.string,
};

const GenderComponent = ({ value }) =>
  getItem('Gender — ', value === 'mal' ? 'Male' : 'Female');

const LiveInComponent = props => {
  return getItem('Live in — ', getLiveInDisplayText(props));
};

const LiveInView = DictValuesController(LiveInComponent);

const EducationComponent = ({ dictValues, education }) =>
  getItem('Education — ', dictValues[education]);

const EducationView = DictValuesController(EducationComponent);

const LanguagesComponent = ({ languages, dictValues }) => {
  let text = '';

  for (let i = 0; i < languages.length; i++) {
    const data = languages[i];
    const displayText = dictValues[data];

    if (displayText) {
      if (text) {
        text += ', ';
      }
      text += displayText;
    }
  }

  return getItem(Resources.strings['profile-languages-description'], text);
};

const LanguagesView = DictValuesController(LanguagesComponent);

const RelationshipComponent = ({ dictValues, relationships }) =>
  getItem('Relationship — ', dictValues[relationships]);

const RelationshipView = DictValuesController(RelationshipComponent);

const SmokeComponent = ({ dictValues, smoke }) =>
  getItem('Smoke — ', dictValues[smoke]);

const SmokeView = DictValuesController(SmokeComponent);

const DrinkComponent = ({ dictValues, drink }) =>
  getItem('Drink — ', dictValues[drink]);

const DrinkView = DictValuesController(DrinkComponent);

const BodyTypeComponent = ({ dictValues, bodyType }) =>
  getItem('Body type — ', dictValues[bodyType]);

const BodyTypeView = DictValuesController(BodyTypeComponent);

const EyesComponent = ({ dictValues, eyes }) =>
  getItem('Eyes — ', dictValues[eyes]);

const EyesView = DictValuesController(EyesComponent);

const HairComponent = ({ dictValues, hair }) =>
  getItem('Hair — ', dictValues[hair]);

const HairView = DictValuesController(HairComponent);

const InfoComponent = props => {
  const { hasBasicDataInInfo, userData, hasLocationDataInInfo } = props;

  const content = [];

  if (hasBasicDataInInfo) {
    if (userData.name) {
      content.push(getItem('Name — ', userData.name));
    }

    if (userData.gender) {
      content.push(<GenderComponent key="gender" value={userData.gender} />);
    }

    if (userData.birthday && userData.birthday['birth-date']) {
      content.push(
        getItem(
          'Birthday — ',
          getDisplayDate(userData.birthday['birth-date'], true),
        ),
      );
    }
  }

  if (hasLocationDataInInfo) {
    if (userData.city || userData.country) {
      content.push(
        <LiveInView
          key="countries"
          city={userData.city}
          country={userData.country}
          dataType="countries"
        />,
      );
    }
  }

  if (userData.occupation) {
    content.push(getItem('Work as — ', userData.occupation));
  }

  if (userData.education) {
    content.push(
      <EducationView
        key="educations-v2"
        education={userData.education}
        dataType="educations-v2"
      />,
    );
  }

  if (userData.languages && userData.languages.length > 0) {
    content.push(
      <LanguagesView
        key="languages"
        languages={userData.languages}
        dataType="languages"
      />,
    );
  }

  if (userData.relationship) {
    content.push(
      <RelationshipView
        key="relationships"
        relationships={userData.relationship}
        dataType="relationships"
      />,
    );
  }

  if (userData.kids !== undefined) {
    const text = userData.kids ? 'Yes' : 'No';

    content.push(getItem('Have kids — ', text));
  }

  if (userData.smoke) {
    content.push(
      <SmokeView key="smoke" smoke={userData.smoke} dataType="smoke" />,
    );
  }

  if (userData.drink) {
    content.push(
      <DrinkView
        key="drinking-v2"
        drink={userData.drink}
        dataType="drinking-v2"
      />,
    );
  }

  if (userData.height) {
    const text = `${userData.height} cm`;

    content.push(getItem('Height — ', text));
  }

  if (userData.bodytype) {
    content.push(
      <BodyTypeView
        key="bodytype"
        bodyType={userData.bodytype}
        dataType="bodytype"
      />,
    );
  }

  if (userData.eye) {
    content.push(<EyesView key="eyes" eyes={userData.eye} dataType="eyes" />);
  }

  if (userData.hair) {
    content.push(
      <HairView key="hairs" hair={userData.hair} dataType="hairs" />,
    );
  }

  const Component = () => (
    <View style={styles.aboutMeContainer}>{content}</View>
  );

  const BubbledComponent = BubbleView(Component);

  return content.length > 0 ? <BubbledComponent {...props} /> : null;
};

InfoComponent.propTypes = {
  hasBasicDataInInfo: PropTypes.bool,
  hasLocationDataInInfo: PropTypes.bool,
  userData: PropTypes.object,
};

const TravelActivitiesDictValuesHoc = BubbleView(TravelActivitiesComponent);

const TravelActivitiesView = UserInterestsController(props => {
  if (props.userInterests && props.userInterests.length > 0) {
    return <TravelActivitiesDictValuesHoc {...props} />;
  }

  return null;
});

const BasicProfileComponent = props => {
  const { geoLocationAliasingEnabled, id } = props;
  const textToDraw = getLiveInDisplayText(props);

  return (
    <Text style={styles.liveInText}>
      <DistanceToUserView
        prefix={
          <Image
            source={Resources.images.geoLocationMarkIcon()}
            resizeMode="cover"
            style={styles.locateIcon}
          />
        }
        userId={id}
        suffix={Resources.strings['distance-to-user-text-separator']}
        locationAliasingEnabled={geoLocationAliasingEnabled}
      />
      <Text>{textToDraw}</Text>
    </Text>
  );
};

BasicProfileComponent.propTypes = {
  id: PropTypes.string,
  geoLocationAliasingEnabled: PropTypes.bool,
};

const BasicProfileInfoView = DictValuesController(
  BubbleView(withIdentityId(withLocationAlias(BasicProfileComponent))),
);

const FewWordsView = BubbleView(FewWordsContent);
const SendEmailView = BubbleView(SendEmailViewComponent);
const LookingForDescriptionView = BubbleView(LookingForDescriptionComponent);
const MediaInfoView = BubbleView(GalleryView);

// eslint-disable-next-line react/no-multi-comp
class ProfileDescriptionView extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string,
    userId: PropTypes.string,
    user: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      tags: PropTypes.arrayOf(PropTypes.string),
      meta: PropTypes.shape({
        travels: PropTypes.array,
      }),
    }),
    hasBasicDataInInfo: PropTypes.bool,
    hasLocationDataInInfo: PropTypes.bool,
    travelsEnabled: PropTypes.bool,
    inboxEnabled: PropTypes.bool,
    openMedia: PropTypes.func,
    presence: PropTypes.object,
    onBlock: PropTypes.func,
    isPreview: PropTypes.bool,
    privatePhotosEnabled: PropTypes.bool,
    hasInterests: PropTypes.bool,
    media: PropTypes.array,
  };

  render() {
    const {
      id,
      userId,
      onBlock,
      isPreview,
      user,
      hasBasicDataInInfo,
      hasLocationDataInInfo,
      travelsEnabled,
      presence,
      privatePhotosEnabled,
      openMedia,
      hasInterests,
      media = [],
    } = this.props;
    const isOwnProfile = id === userId;

    let textToDraw;
    const reportView =
      isPreview || isOwnProfile ? null : (
        <View>
          <ReportButton
            showIcon
            id={id}
            allowBlock
            onBlock={onBlock}
            reportedContent={profileResource(id)}
          />
        </View>
      );

    if (user?.name) {
      textToDraw = user.name;
    }

    if (textToDraw && user?.birthday?.age) {
      textToDraw = `${textToDraw}, ${user.birthday.age}`;
    }

    const about = user?.about;
    const aboutHidden =
      !isOwnProfile && user?.tags?.includes(PreviewTags.AboutHidden);
    const travels = user?.meta?.travels;

    const aboutMeSection =
      about && !aboutHidden ? (
        <FewWordsView
          showUnderline
          title={Resources.strings['profile-about-me-title']}
          titleStyle={styles.fewWordsTitle}
          aboutText={about}
        />
      ) : null;

    const interestsView = (
      <TravelActivitiesView
        showUnderline
        dataType="interests"
        id={id}
        title={Resources.strings['profile-interests-title']}
      />
    );

    const infoView = (
      <InfoComponent
        showUnderline={false}
        hasBasicDataInInfo={hasBasicDataInInfo}
        hasLocationDataInInfo={hasLocationDataInInfo}
        userData={user}
        title={Resources.strings['profile-info-title']}
      />
    );

    let travelDestinations = null;

    if (travelsEnabled && travels && travels.length > 0) {
      travelDestinations = (
        <TravelDestinationsView
          travels={travels}
          title={Resources.strings['profile-travel-title']}
          dataType="countries"
        />
      );
    }

    const userOnline = !!presence?.online;

    const hasVideo = !!media.find(it => it.mediatype?.startsWith('video'));
    const hasPhoto = !!media.find(it => it.mediatype?.startsWith('image'));

    const itemsOrder = privatePhotosEnabled
      ? [
          GALLERY_ITEMS.VIDEOS,
          GALLERY_ITEMS.PUBLIC_PHOTOS,
          GALLERY_ITEMS.PRIVATE_PHOTOS,
        ]
      : [GALLERY_ITEMS.VIDEOS, GALLERY_ITEMS.PUBLIC_PHOTOS];

    const photosItemsOrder = privatePhotosEnabled
      ? [GALLERY_ITEMS.PUBLIC_PHOTOS, GALLERY_ITEMS.PRIVATE_PHOTOS]
      : [GALLERY_ITEMS.PUBLIC_PHOTOS];

    return (
      <View style={styles.container}>
        <IfConfigValue
          path="markup.profile-screen.gallery-type"
          equalsTo="unified-gallery-at-top"
        >
          <View style={styles.gallery}>
            <GalleryView id={id} open={openMedia} itemsOrder={itemsOrder} />
          </View>
        </IfConfigValue>
        <IfConfigValue
          path="markup.profile-screen.name-field-type"
          equalsTo="name-with-location"
        >
          <BasicProfileInfoView
            {...(user || {})}
            accessoryView={reportView}
            dataType="countries"
            title={textToDraw}
            titleStyle={styles.nameTitle}
            showUnderline={false}
            identityId={id}
          />
        </IfConfigValue>
        <IfConfigValue
          path="markup.profile-screen.name-field-type"
          equalsTo="only-name"
        >
          <UserNameComponent
            style={styles.nameComponent}
            id={id}
            accessoryView={reportView}
            titleStyle={styles.nameTitle}
          />
        </IfConfigValue>
        <IfConfigValue path="features.goals-enabled" equalsTo={true}>
          <View style={styles.userGoalsContainer}>
            <UserGoalsView userId={id} />
          </View>
        </IfConfigValue>
        <IfConfigValue
          path="markup.profile-screen.has-looking-for-section"
          equalsTo={true}
        >
          <LookingForDescriptionView
            ownerId={id}
            userId={userId}
            title={
              styles.$showLookingForTitle &&
              Resources.strings['profile-screen-looking-for-title']
            }
            showUnderline
          />
        </IfConfigValue>
        {aboutMeSection}
        <IfConfigValue
          path="markup.profile-screen.gallery-type"
          equalsTo="separated-gallery-in-description"
        >
          {hasPhoto && (
            <MediaInfoView
              title={Resources.strings['profile-screen-photos-title']}
              id={id}
              editingEnabled={false}
              itemsOrder={photosItemsOrder}
              open={openMedia}
              showUnderline
            />
          )}
          {hasVideo && (
            <MediaInfoView
              title={Resources.strings['profile-screen-videos-title']}
              id={id}
              editingEnabled={false}
              itemsOrder={[GALLERY_ITEMS.VIDEOS]}
              open={openMedia}
              showUnderline
            />
          )}
        </IfConfigValue>
        {travelDestinations}
        {!!userOnline && (
          <IfConfigValue path="features.inbox-enabled" equalsTo={true}>
            <SendEmailView userId={user.id} />
          </IfConfigValue>
        )}
        <IfConfigValue path="features.anonymous-using-enabled" equalsTo={false}>
          {!!hasInterests && interestsView}
          {infoView}
        </IfConfigValue>
      </View>
    );
  }
}

export default configValue(withUserMedia(ProfileDescriptionView), {
  travelsEnabled: 'features.travels-enabled',
  hasBasicDataInInfo: 'markup.profile-screen.basic-profile-data-in-info',
  hasLocationDataInInfo: 'markup.profile-screen.has-location-data-in-info',
  privatePhotosEnabled: 'features.private-photos-enabled',
  hasInterests: 'features.interests-enabled',
});
