import Qs from 'qs';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  StyleSheet,
  View,
  ActivityIndicator,
  unstable_createElement as createElement,
} from 'react-native';

export class WebView extends Component {
  static defaultProps = {
    scrollEnabled: true,
  };

  static propTypes = {
    source: PropTypes.object,
    newWindow: PropTypes.bool,
    onMessage: PropTypes.func,
    injectedJavaScript: PropTypes.string,
    title: PropTypes.string,
    onLoad: PropTypes.func,
    scrollEnabled: PropTypes.bool,
    style: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  };

  state = { html: null };

  constructor(props) {
    super(props);
    this.handleSource(props.source, props.newWindow);
  }

  componentDidMount() {
    const { onMessage } = this.props;

    if (onMessage) {
      window.addEventListener('message', this.onMessage, true);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { source } = this.props;

    if (
      source.uri !== nextProps.source.uri ||
      source.method !== nextProps.source.method ||
      source.body !== nextProps.source.body
    ) {
      this.handleSource(nextProps.source, nextProps.newWindow);
    }
  }

  componentWillUnmount() {
    const { onMessage } = this.props;

    if (onMessage) {
      window.removeEventListener('message', this.onMessage, true);
    }
  }

  setRef = ref => {
    this.frameRef = ref;
  };

  handleSource = (source, newWindow) => {
    if (!source.method) return;

    if (newWindow) {
      this.handleSourceInNewWindow(source, newWindow);
    } else {
      this.handleSourceInIFrame(source);
    }
  };

  handleSourceInIFrame = source => {
    const { uri, html, ...options } = source;

    if (html) {
      this.setState({ html: `<base href="${source.baseUrl}" />${html}` });
    } else {
      const baseUrl = uri.substr(0, uri.lastIndexOf('/') + 1);

      fetch(uri, options)
        .then(response => response.text())
        .then(newHtml =>
          this.setState({ html: `<base href="${baseUrl}" />${newHtml}` }),
        );
    }
  };

  handleSourceInNewWindow = (source, newWindow) => {
    if (source.method === 'POST') {
      const contentType = source.headers['Content-Type'];
      let body = '';

      if (
        contentType &&
        contentType.includes('application/x-www-form-urlencoded')
      ) {
        body = Qs.parse(source.body);
      } else {
        console.warn(
          '[WebView] When opening a new window, this content-type is not supported yet, please make a PR!',
          contentType,
        );

        return;
      }

      window.open(
        // eslint-disable-next-line global-require
        `${require('./postMock.html')}?${Qs.stringify({
          uri: source.uri,
          body: JSON.stringify(body),
        })}`,
        newWindow.name || 'webview',
        newWindow.features || undefined,
      );
    } else {
      console.warn(
        '[WebView] When opening a new window, this method is not supported yet, please make a PR!',
        source.method,
      );
    }
  };

  onMessage = nativeEvent => {
    const { onMessage } = this.props;

    onMessage({ nativeEvent });
  };

  postMessage = (message, origin) => {
    this.frameRef.contentWindow.postMessage(message, origin);
  };

  handleInjectedJavaScript = html => {
    const { injectedJavaScript } = this.props;

    if (injectedJavaScript) {
      if (html) {
        return html.replace(
          '</body>',
          `<script>${injectedJavaScript}</script></body>`,
        );
      }

      return html;
    }

    return html;
  };

  render() {
    const { newWindow } = this.props;

    if (newWindow) {
      return (
        <View style={styles.loadingContainer}>
          <ActivityIndicator />
        </View>
      );
    }

    const { title, source, onLoad, scrollEnabled, style } = this.props;
    const { html } = this.state;
    const styleObj = StyleSheet.flatten(style);

    return createElement('iframe', {
      title,
      ref: this.setRef,
      src: !source.method ? source.uri : undefined,
      srcDoc: this.handleInjectedJavaScript(html || source.html),
      width: styleObj && styleObj.width,
      height: styleObj && styleObj.height,
      style: StyleSheet.flatten([
        styles.iframe,
        scrollEnabled && styles.noScroll,
        style,
      ]),
      allowFullScreen: true,
      allowpaymentrequest: 'true',
      frameBorder: '0',
      seamless: true,
      onLoad,
    });
  }
}

export default WebView;

const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  iframe: {
    width: '100%',
    height: '100%',
    borderWidth: 0,
  },
  noScroll: {
    overflow: 'hidden',
  },
});
