import Resources from 'dating-mobile/src/resources';

const itemVariants = {
    message: 'price-per-message',
    smile: 'price-per-smile',
    sticker: 'price-per-sticker',
};

function getTextInputPricingPlaceholder(props, itemType) {
    if (!itemType) {
        return null;
    }

    const prices = {
        message: props.sendMessageNonDevaluatedPrice,
        smile: props.sendSmileNonDevaluatedPrice,
        sticker: props.sendStickerNonDevaluatedPrice,
    };

    const nonDevaluatedPrice =
        props.isStreamer && props.sendMessageToStreamerNonDevaluatedPrice
            ? props.sendMessageToStreamerNonDevaluatedPrice
            : prices[itemType];
    const devaluatedPricePlural = getDevaluatedPricePlural(props, nonDevaluatedPrice);

    if (!devaluatedPricePlural) {
        return null;
    }

    const itemVariantTextId = itemVariants[itemType];

    return Resources.strings.formatString(
        Resources.strings[itemVariantTextId],
        devaluatedPricePlural,
    );
}

export function getDevaluatedPricePlural(props, nonDevaluatedPrice) {
    const { currency, devaluationRatio } = props;

    if (!currency || !devaluationRatio || !nonDevaluatedPrice) {
        return null;
    }

    const price = nonDevaluatedPrice * devaluationRatio;
    return Resources.strings.formatPrice(currency, price);
}

export function getTextInputPricingPlaceholderSelector(props) {
    return getTextInputPricingPlaceholder.bind(null, props);
}
