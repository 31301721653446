import React from 'react';
import View from './view';
import withNavigation from './navigation';

export default View;

export function makeNavigationItem(parameters) {
    return (props) => React.createElement(withNavigation(View), {
        pinVisible: true,
        ...parameters,
        ...props
    });
}
