import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $statusBarStyle: '$brandStatusBarStyle',
    $statusBarBackgroundColor: '$brandColor',

    tint: {
        color: '$brandTextColor'
    },

    container: {
        flex: 1,
        backgroundColor: '$brandColor',
        alignItems: 'center'
    },
});

export default styles;
