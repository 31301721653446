import React, { memo } from 'react';
// @ts-ignore
import withConfigValue from 'dating-mobile/src/components/config-value';
import { useLocationAliasData } from '../hooks';

type Props = {
    userId: string;
    identityId: string;
    geoLocationAliasingEnabled: boolean;
};

export const withLocationAlias = (
    Component: React.JSXElementConstructor<any>,
): React.JSXElementConstructor<Props> => {
    const Controller = memo((props: Props) => {
        const { userId, identityId, geoLocationAliasingEnabled } = props;

        if (!geoLocationAliasingEnabled) {
            return <Component {...props} />;
        }

        const { loading, locationAlias } = useLocationAliasData(userId, identityId);

        if (loading) {
            return null;
        }

        const mergedProps = locationAlias ? { ...props, ...locationAlias } : props;

        return <Component {...mergedProps} />;
    });

    return withConfigValue(Controller, {
        geoLocationAliasingEnabled: 'features.geo-location-aliasing-enabled',
    });
};
