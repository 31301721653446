/* eslint-disable import/no-unresolved */
import { combineLatest, Subject, merge } from 'rxjs';
import { distinctUntilChanged, map, mapTo, switchMap } from 'rxjs/operators';
import { singleton } from '@sdv/commons/utils/singleton';
import { Persistence } from '@sdv/domain/app/persistence';
import OS from '@sdv/domain/app/os';
import flux from '@sdv/domain/app/flux';
import UserTags from '@sdv/domain/user/tags';
import { UserTags as Tags } from '@sdv/domain/user/tags/user-tags';
import { CurrentScreen } from 'dating-mobile/src/services/functional/current-screen';
import { FEED, CONTACTS } from 'dating-mobile/src/routing/router/constants';

export const REVIEW_STATUS = {
  DECLINED: 0,
  REVIEW_SENT: 1,
  FEEDBACK_SENT: 2,
};

export class UserReviewCollecting {
  static shared = singleton(userId => new UserReviewCollecting(userId));

  constructor(userId) {
    const allowedScreens = [FEED, CONTACTS];
    const reviewRequestedKey = 'user-review-collecting.reviewRequested';
    const persistence = Persistence.shared('review', userId);

    this.reviewRequestShowed = new Subject();

    this.userId = userId;

    const reviewRequested = merge(
      persistence.load(reviewRequestedKey),
      this.reviewRequestShowed.pipe(
        mapTo(true),
        switchMap(showed =>
          persistence.store(reviewRequestedKey, showed).pipe(map(() => showed)),
        ),
      ),
    );

    const isUserOnAllowedScreen = CurrentScreen.shared().screen.pipe(
      map(screen => allowedScreens.includes(screen)),
      distinctUntilChanged(),
    );

    const isReadyForRequest = UserTags.shared()
      .tagsOf(userId)
      .pipe(
        map(tags => tags.includes(Tags.FeedbackSent)),
        distinctUntilChanged(),
      );

    this.shouldShowReviewRequest = combineLatest(
      isUserOnAllowedScreen,
      isReadyForRequest,
      reviewRequested,
    ).pipe(
      map(
        ([userOnAllowedScreen, showReviewRequest, isReviewRequested]) =>
          userOnAllowedScreen &&
          showReviewRequest &&
          !isReviewRequested &&
          OS.shared().current !== 'web',
      ),
      distinctUntilChanged(),
    );
  }

  userReviewCollected(status) {
    this.reviewRequestShowed.next(status);
  }

  sendFeedback(feedback) {
    if (this.userId) {
      flux.api.annals.add(this.userId, 'rate-app-feedback-sent', {
        feedback,
      });
    }
  }
}
