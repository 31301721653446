import React from 'react';
import PropTypes from 'prop-types';
import { findNodeHandle, Platform, StyleSheet, View } from 'react-native';
import * as Animatable from 'react-native-animatable';
import TextWithSmiles from 'dating-mobile/src/dialogs/common/views/text-with-smiles';
import Resources from 'dating-mobile/src/resources';
import testId from 'dating-mobile/src/utils/test-id';
import LinearGradient from 'react-native-linear-gradient';
// import { BlurView } from '@react-native-community/blur';
import CallButton from 'dating-mobile/src/call/views/video-call-button';
import withTextContent from '../../../../../models/common.messages/controllers/text-content-controller';
import withConfigValue from '../../../../../components/config-value';
import styles from './styles';

const BlurView = View;

class TextContent extends React.Component {
  static displayName = 'common.messages.message.text-content';

  static propTypes = {
    blurred: PropTypes.bool,
    textContainsOnlySmiles: PropTypes.bool,
    linksEnabled: PropTypes.bool,
    appLinkDomain: PropTypes.string,
    deepLinkScheme: PropTypes.string,
    isNotDelivered: PropTypes.bool,
    message: PropTypes.shape({
      status: PropTypes.number,
      outgoing: PropTypes.bool.isRequired,
      content: PropTypes.string,
      sender: PropTypes.string.isRequired,
      recipient: PropTypes.string.isRequired,
      tag: PropTypes.string.isRequired,
    }),
  };

  constructor(props) {
    super(props);
    this.state = { blurredRef: null };
  }

  shouldComponentUpdate(nextProps) {
    const {
      message,
      blurred,
      textContainsOnlySmiles,
      isNotDelivered,
    } = this.props;

    return (
      message.content !== nextProps.message.content ||
      message.outgoing !== nextProps.message.content ||
      blurred !== nextProps.blurred ||
      isNotDelivered !== nextProps.isNotDelivered ||
      textContainsOnlySmiles !== nextProps.textContainsOnlySmiles
    );
  }

  onViewLayout = () => {
    this.setState({
      blurredRef: findNodeHandle(this.viewRef),
    });
  };

  getBubbleStyle() {
    const { message, textContainsOnlySmiles, isNotDelivered } = this.props;
    const videoInvite = !message.outgoing && message.tag.startsWith('call-');

    if (message.autoReply) {
      return styles.bubbleAutoReply;
    }

    if (message.autoMessage) {
      return styles.bubbleAutoMessage;
    }

    if (textContainsOnlySmiles && !videoInvite) {
      return isNotDelivered
        ? styles.bubbleUnsentWithOnlySmiles
        : styles.bubbleWithOnlySmiles;
    }

    if (!message.outgoing) {
      return styles.bubbleIncoming;
    }

    return isNotDelivered ? styles.bubbleOutgoingUnsent : styles.bubbleOutgoing;
  }

  getTextStyle() {
    const { message } = this.props;

    if (message.autoReply) {
      return styles.textAutoReply;
    }

    if (message.autoMessage) {
      return styles.textAutoMessage;
    }

    if (message.outgoing) {
      return styles.textOutgoing;
    }

    return styles.textIncoming;
  }

  render() {
    const {
      message,
      blurred,
      linksEnabled,
      appLinkDomain,
      deepLinkScheme,
    } = this.props;
    const { blurredRef } = this.state;
    const bubbleStyle = this.getBubbleStyle();
    const videoInvite = !message.outgoing && message.tag.startsWith('call-');
    const { gradientColor } = StyleSheet.flatten(bubbleStyle);

    const textStyle = [
      this.getTextStyle(),
      blurred && Platform.OS === 'android' ? styles.textBlurred : {},
    ];

    const content = [
      <TextWithSmiles
        key="text"
        linksEnabled={linksEnabled}
        appLinkDomain={appLinkDomain}
        deepLinkScheme={deepLinkScheme}
        ref={viewRef => {
          this.viewRef = viewRef;
        }}
        onLayout={this.onViewLayout}
        text={message.content}
        smileSize={20}
        onlySmilesTextSmileSize={40}
        style={textStyle}
        {...testId(Resources.strings['message-text-accessibility-label'])}
      />,
      videoInvite && (
        <CallButton
          key="call-button"
          userId={message.recipient}
          attendeeId={message.sender}
          disableWhenAttendeeWithoutCamera
          hideWhenDisabled
          style={styles.videoCallButton}
          iconStyle={styles.videoCallIcon}
        />
      ),
      blurred && (blurredRef || Platform.OS === 'ios') && (
        <BlurView
          key="blur"
          style={styles.absolute}
          viewRef={blurredRef}
          blurType="light"
          blurAmount={3}
        />
      ),
    ];

    return gradientColor ? (
      <LinearGradient colors={gradientColor} style={bubbleStyle}>
        {content}
      </LinearGradient>
    ) : (
      <Animatable.View
        useNativeDriver
        animation={message.autoReply ? 'fadeIn' : null}
        style={bubbleStyle}
      >
        {content}
      </Animatable.View>
    );
  }
}

export default withConfigValue(withTextContent(TextContent), {
  trialMessagesEnabled: 'features.trial-messages-enabled',
  linksEnabled: 'features.links-in-chat-enabled',
  appLinkDomain: 'app-link-associated-domain',
  deepLinkScheme: 'deep-link-scheme',
});
