import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  View,
  Text,
  TouchableOpacity,
  ImageBackground,
  Image,
} from 'react-native';

import Resources from 'dating-mobile/src/resources';
import Button from 'dating-mobile/src/components/buttons/base';
import { formatTimeNumericLong } from 'dating-mobile/src/components/time/formatters';
import withAspectRatio from 'dating-mobile/src/components/aspect-ratio';
import { AnimatedAppearing } from 'dating-mobile/src/components/animated-appearing';
import { formatPrice } from '@sdv/commons/price-with-code-localization';
import { SupportedMethods } from '@sdv/domain/app/payment/shop-info';

import styles from './styles';

const AspectRatioImage = withAspectRatio(ImageBackground);

export default class BoostExtendBannerMan extends PureComponent {
  static displayName =
    'components.purchase-promotion-banner.boost-extend-man.view';

  static propTypes = {
    onPress: PropTypes.func,
    style: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    image: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.string,
    ]),
    boostIsActive: PropTypes.bool,
    isBoostPurchased: PropTypes.bool,
    remainingTime: PropTypes.number,
    invoice: PropTypes.object,
    placeholder: PropTypes.object,
    hidden: PropTypes.bool,
  };

  getTitle() {
    const { boostIsActive, isBoostPurchased } = this.props;

    if (!isBoostPurchased) {
      return Resources.strings[
        'purchase-promotion-banner-boost-initial-offer-man-title'
      ];
    }

    if (boostIsActive) {
      return Resources.strings[
        'purchase-promotion-banner-boost-extend-man-title'
      ];
    }

    return Resources.strings['purchase-promotion-banner-boost-ended-man-title'];
  }

  getButtonText() {
    const { boostIsActive, isBoostPurchased, invoice } = this.props;

    if (!isBoostPurchased) {
      return (
        <Text style={styles.buttonText}>
          {Resources.strings.formatString(
            Resources.strings[
              'purchase-promotion-banner-boost-initial-offer-man-button-text'
            ],
            invoice?.discount || '',
          )}
        </Text>
      );
    }

    return (
      <View style={styles.buttonContent}>
        <Text style={styles.buttonText}>
          {
            Resources.strings[
              boostIsActive
                ? 'purchase-promotion-banner-boost-extend-man-button-text'
                : 'purchase-promotion-banner-boost-ended-man-button-text'
            ]
          }{' '}
          <Text style={styles.oldPrice}>
            {formatPrice(invoice?.priceWithoutDiscount || '')}
          </Text>{' '}
          {formatPrice(invoice?.price || '')}
        </Text>
        {invoice?.price?.currency === SupportedMethods.credits && (
          <Image
            style={styles.creditImg}
            source={Resources.images.coinIcon()}
            resizeMode="contain"
          />
        )}
      </View>
    );
  }

  getHint() {
    const { boostIsActive, isBoostPurchased, invoice } = this.props;

    if (!isBoostPurchased) {
      return Resources.strings[
        'purchase-promotion-banner-boost-initial-offer-man-hint'
      ];
    }

    return Resources.strings.formatString(
      Resources.strings[
        boostIsActive
          ? 'purchase-promotion-banner-boost-extend-man-hint'
          : 'purchase-promotion-banner-boost-ended-man-hint'
      ],
      invoice?.discount || '',
    );
  }

  render() {
    const {
      style,
      onPress,
      image,
      invoice,
      remainingTime,
      placeholder,
      hidden,
    } = this.props;

    const showPlaceholder = !remainingTime || !invoice || hidden;

    return (
      <View>
        <AnimatedAppearing
          useNativeDriver
          animationDuration={500}
          appearingAnimationName="fadeIn"
          disappearingAnimationName="fadeOut"
          isVisible={showPlaceholder}
          style={{ position: showPlaceholder ? 'relative' : 'absolute' }}
        >
          {placeholder}
        </AnimatedAppearing>
        <AnimatedAppearing
          useNativeDriver
          animationDuration={500}
          appearingAnimationName="fadeIn"
          disappearingAnimationName="fadeOut"
          isVisible={!showPlaceholder}
          style={{ position: !showPlaceholder ? 'relative' : 'absolute' }}
        >
          <View style={style}>
            <TouchableOpacity style={styles.container} onPress={onPress}>
              <AspectRatioImage
                style={styles.imageContainer}
                imageStyle={styles.image}
                source={image}
              >
                <View style={styles.textContainer}>
                  <Text style={styles.title}>{this.getTitle()}</Text>
                  <Text style={styles.timer}>
                    {formatTimeNumericLong(remainingTime)}
                  </Text>
                  <View>
                    <Button style={styles.button}>
                      {this.getButtonText()}
                    </Button>
                  </View>
                </View>
              </AspectRatioImage>
            </TouchableOpacity>
            <Text style={styles.hint}>{this.getHint()}</Text>
          </View>
        </AnimatedAppearing>
      </View>
    );
  }
}
