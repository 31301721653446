import { Subject, Subscription, merge, of } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  tap,
  switchMap,
  mapTo,
} from 'rxjs/operators';
import AppsFlyerTracker from 'dating-mobile/src/services/tracking/apps-flyer-tracker';
import { Persistence } from '@sdv/domain/app/persistence';
import { CurrentScreen } from 'dating-mobile/src/services/functional/current-screen';
import { FILTER } from 'dating-mobile/src/routing/router/constants';

import Service from '../service';

// TODO: TMP Hack
export const userAppliedFilter = new Subject();

export class FilterTracker extends Service {
  static displayName = 'service.tracking.filter-tracker';

  componentDidMount() {
    this.subscribe();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  subscribe() {
    const isFilterScreenVisitedKey = 'filter-tracker.isFilterScreenVisited';
    const persistence = Persistence.shared();
    // TODO: Add userId dependency
    const storedIsFilterScreenVisited = persistence.load(
      isFilterScreenVisitedKey,
    );
    const filterScreenVisited = new Subject();
    const isFilterScreenVisited = merge(
      storedIsFilterScreenVisited,
      filterScreenVisited,
    );

    const userVisitedFilterScreenForTheFirstTimeSubscription = isFilterScreenVisited
      .pipe(
        distinctUntilChanged(),
        switchMap(visited => {
          if (visited) {
            return of(false);
          }

          return CurrentScreen.shared().screen.pipe(
            filter(screen => screen === FILTER),
            switchMap(() => persistence.store(isFilterScreenVisitedKey, true)),
            mapTo(true),
          );
        }),
        filter(Boolean),
        tap(() => filterScreenVisited.next(true)),
      )
      .subscribe(() => this.trackEvent('first_view_filter'));

    // TODO: use this code instead
    // BalanceRefiller.balanceRefiller.refillBalanceReason.pipe(
    //   filter(reason => reason === PAYMENT_REASON.USE_ADVANCED_SEARCH),
    // )
    const userAppliedFilterSubscription = userAppliedFilter.subscribe(() =>
      this.trackEvent('apply_filter'),
    );

    this.disposeBag = new Subscription();
    this.disposeBag.add(userVisitedFilterScreenForTheFirstTimeSubscription);
    this.disposeBag.add(userAppliedFilterSubscription);
  }

  unsubscribe() {
    if (this.disposeBag) {
      this.disposeBag.unsubscribe();
    }
  }

  trackEvent(event, values = {}) {
    AppsFlyerTracker.trackEvent(
      event,
      values,
      () => {},
      () => {},
    );
  }
}
