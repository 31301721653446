import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        flexGrow: 1,
        backgroundColor: '#ffffff',
    },
    topContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexShrink: 0,
        paddingHorizontal: 26,
        marginBottom: 30,
    },
    safeArea: {
        flexGrow: 1,
    },
    scrollContainer: {
        flexGrow: 1,
    },
    closeIcon: {
        width: 32,
        height: 32,
        tintColor: '#d8d8d8',
    },
    contentContainer: {
        flexGrow: 1,
        paddingHorizontal: 42,
    },
    titleBackground: {
        width: '100%',
        alignItems: 'center',
        marginBottom: 64,
    },
    titleContainer: {
        paddingTop: 12,
        paddingBottom: 20,
    },
    title: {
        fontWeight: 'bold',
        fontSize: 30,
        fontFamily: '$brandFontFamily',
        color: '#ffffff',
    },
    subTitle: {
        fontSize: 14,
        fontFamily: '$brandFontFamily',
        color: '#ffffff',
        alignSelf: 'flex-end',
        fontWeight: 'bold',
    },
    buttonContainer: {
        flexGrow: 1,
        justifyContent: 'flex-end',
        marginBottom: 30,
    },
    buttonContentContainer: {},
    buttonArrow: {
        position: 'absolute',
    },
    buttonTitle: {
        fontSize: 16,
        fontWeight: 'bold',
        fontFamily: '$brandFontFamily',
        color: '#ffffff',
        textAlign: 'center',
    },
    buttonDescription: {
        fontSize: 10,
        fontFamily: '$brandFontFamily',
        color: '#ffffff',
        textAlign: 'center',
    },
    termsDescriptionContainer: {
        height: 80,
        marginBottom: 20,
    },

    activityIndicatorContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    arrow: {
        right: 20,
        top: '50%',
        position: 'absolute',
        width: 16,
        height: 16,
        marginTop: -8,
        borderColor: '#ffffff',
        borderBottomWidth: 3,
        borderRightWidth: 3,
        transform: [{ rotate: '-45deg' }],
    },
});

export default styles;
