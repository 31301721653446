import RoomModel from '../../../models/dialogs.room/model';
import StreamModel from '../../../models/dialogs.stream/model';

export default class RoomsProvider {
  constructor(flux) {
    this.flux = flux;
    this.streamConnection = flux.streamConnection;
    this.streamConnection?.addListener(
      'document.dialogs.room',
      this.roomUpdated,
    );
    this.streamConnection?.addListener(
      'event.dialogs.rooms.updated',
      this.roomUpdated,
    );
    this.streamConnection?.addListener(
      'event.dialogs.rooms.messages.added',
      this.messageAdded,
    );
    this.streamConnection?.addListener(
      'event.dialogs.rooms.users.muted',
      this.muteUser,
    );
  }

  roomUpdated = data => {
    if (data.payload.id) {
      this.flux.get(RoomModel, data.payload.id).actions.set(data.payload);
    }
  };

  messageAdded = data => {
    const { payload } = data;

    if (payload.message && payload.message.meta && payload.message.meta.room) {
      const streamState = this.flux
        .get(StreamModel, payload.room)
        .store.getState();

      if (streamState.attached) {
        this.flux
          .get(RoomModel, streamState.attached)
          .actions.updateMessage(payload.message);
      }
      this.flux
        .get(RoomModel, payload.message.meta.room)
        .actions.updateMessage(payload.message);
    }
  };

  muteUser = data => {
    if (data && data.payload && data.payload.id && data.payload.room) {
      this.flux
        .get(RoomModel, data.payload.room)
        .actions.removeMessagesFromUser(data.payload.id);
    }
  };
}
