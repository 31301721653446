import React from 'react';
import PropTypes from 'prop-types';
import withUserController from './index';

export default function createController(Component, options = {}) {
    const userIdPropName = options.userIdPropName || 'id';
    const resultPropName = 'streamer';

    class Controller extends React.Component {
        static displayName = 'user.controllers.with-is-streamer';

        static propTypes = {
            [userIdPropName]: PropTypes.string,
        };

        render() {
            const { ...props } = this.props;
            const { [resultPropName]: { tags = [] } = {} } = props;
            const isStreamer = tags.findIndex(tag => tag === 'dialogs.messages.promoter') !== -1;

            delete props[resultPropName];

            return <Component {...props} isStreamer={isStreamer} />;
        }
    }

    return withUserController(Controller, { ...options, userIdPropName, resultPropName });
}
