import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({

    interestsContainer: {
        marginBottom: 16,
        flexWrap: 'wrap',
        flexDirection: 'row'
    },
    interestsItemContainer: {
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: 4,
        height: 125,
        width: '33%'
    },
    interestItemImage: {
        width: 80,
        height: 80,
        marginBottom: 8
    },
    interestItemText: {
        fontSize: 15,
        textAlign: 'center',
        color: '$primaryTextColor',
        '@flavor dma': {
            fontSize: 16
        }
    }
});

export default styles;
