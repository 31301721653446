import XMLHttpRequest from 'xhr2';

export default function request(url, options = {}, onProgress) {

    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();

        xhr.open(options.method || 'get', url, true);

        for (let key in options.headers || {}) {
            if (options.headers.hasOwnProperty(key)) {
                xhr.setRequestHeader(key, options.headers[key]);
            }
        }

        xhr.onload = e => resolve(e.target);
        xhr.onerror = reject;

        if (xhr.upload && onProgress) {
            const handler = (event) => {
                onProgress(event);

                if (!event.lengthComputable) {
                    xhr.upload.removeEventListener('progress', handler);
                }

            };
            xhr.upload.addEventListener('progress', handler, false);
        }

        xhr.send(options.body);
    });

}
