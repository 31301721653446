import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $statusBarStyle: '$primaryStatusBarStyle',
    $statusBarBackgroundColor: '$primaryBackgroundColor',

    container: {
        flexDirection: 'column',
        backgroundColor: '$primaryBackgroundColor',
        height: '100%',
    },
    headerContainer: {
        flexDirection: 'row',
        padding: 8,
        alignItems: 'center',
        justifyContent: 'space-between',
    },

    username: {
        position: 'absolute',
        left: 0,
        right: 0,
        fontSize: 15,
        fontWeight: 'bold',
        color: '$primaryTextColor',
        textAlign: 'center',
    },

    followButton: {},

    closeButton: {
        width: 32,
        height: 32,
        alignItems: 'center',
        justifyContent: 'center',
    },

    closeButtonImage: {
        '@flavor lovinga': {
            tintColor: '$navButtonLightTintColor',
        },
    },

    log: {
        height: '100%',
    },

    form: {
        backgroundColor: '$primaryBackgroundColor',
    },
});

export default styles;
