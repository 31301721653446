import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    icon: {
        tintColor: '$brandTextColor'
    },

    pin: {
        tintColor: '$brandTextColor'
    },

    title: {
        flex: 1,
        color: '$brandTextColor',
        fontSize: 17,
        fontWeight: '600',
        fontFamily: '$brandFontFamily'
    },

    activityIndicator: {
        marginRight: 20
    }
});

export default styles;
