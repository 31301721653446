import { Observable, bindNodeCallback, BehaviorSubject, of } from 'rxjs';
import { map, switchMap, shareReplay, catchError } from 'rxjs/operators';
import { singleton } from '@sdv/commons/utils/singleton';
import flux from '@sdv/domain/app/flux';
import Tags, { getId } from '@sdv/domain/user/tags/targeted';
import UserReportModel from '@sdv/domain/user.report/model';
import UserEventsModel, {
  getId as getEventModelId,
  EVENT_TYPE,
} from 'dating-mobile/src/models/user.events/model';

export class UserBlocking {
  static shared = singleton(userId => new UserBlocking(userId));

  constructor(user) {
    const getBlockedUsers = bindNodeCallback(flux.api.users.blocked.get);

    const contactsModel = flux.get(
      UserEventsModel,
      getEventModelId(user, EVENT_TYPE.CONTACT),
    );

    this.refreshBlockedUsersSubject = new BehaviorSubject(null);

    this.blockedUsers = this.refreshBlockedUsersSubject.pipe(
      switchMap(() => getBlockedUsers(user)),
      catchError(() => of([])),
      shareReplay(1),
    );

    this.blockedUsersAdapted = this.blockedUsers.pipe(
      map(users => ({
        events: users.map(({ thumbnail, name, id }) => ({
          'user-details': {
            thumbnail,
            name,
            id,
          },
          'user-id': id,
        })),
      })),
    );

    this.blockedUsersIds = this.blockedUsers.pipe(
      map(users => users.map(({ id }) => id)),
    );

    this.blockUser = id => {
      return new Observable(completable => {
        flux.get(Tags, getId(user, id)).actions.patch('blocked', error => {
          if (error) {
            if (error.status === 409) {
              completable.complete();
            } else {
              completable.error(error);
            }
          } else {
            completable.complete();
          }

          this.refreshBlockedUsers();
          contactsModel.actions.get();
        });
      });
    };

    this.unblockUser = id => {
      return new Observable(completable => {
        flux.get(Tags, getId(user, id)).actions.delete('blocked', error => {
          if (error) {
            if (error.status === 409) {
              completable.complete();
            } else {
              completable.error(error);
            }
          } else {
            completable.complete();
          }

          this.refreshBlockedUsers();
          contactsModel.actions.get();
        });
      });
    };

    this.reportUser = (id, content) => {
      return new Observable(completable => {
        flux.get(UserReportModel, user).actions.report(id, content, error => {
          if (error) {
            completable.error(error);
          } else {
            completable.complete();
          }
        });
      });
    };

    // TODO: Do not pass isBlocked to the function, get this info internally
    this.deleteChat = id => {
      return new Observable(completable => {
        try {
          const { events } = contactsModel.store.getState() || {};

          if (events) {
            const event = events.find(e => e['user-id'] === id);

            if (event && event['event-id'] && event['event-id'] !== -1) {
              contactsModel.actions.delete(event['event-id'], error => {
                if (error) {
                  completable.error(error);
                } else {
                  completable.complete();
                }
              });
            } else {
              completable.complete();
            }
          } else {
            completable.complete();
          }
        } catch (error) {
          completable.error(error);
        }
      });
    };
  }

  refreshBlockedUsers = () => {
    this.refreshBlockedUsersSubject.next(null);
  };
}
