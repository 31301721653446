import React from 'react';
import PropTypes from 'prop-types';
import { FlatList, View } from 'react-native';
import ChainItem from './chain-item';
import styles from './styles';
import { InboxFilter } from '@sdv/domain/dialogs.mailbox/inbox';
import LettersList from 'dating-mobile/src/inbox/views/letters-list';
import Resources from 'dating-mobile/src/resources';

export default class LetterChainListView extends React.Component {
    static displayName = 'inbox.views.all-letters.view';

    static propTypes = {
        letterChains: PropTypes.array.isRequired,
        loadMore: PropTypes.func.isRequired,
        userId: PropTypes.string.isRequired,
        onEmptyList: PropTypes.func,
        onLetterSelect: PropTypes.func,
        deleteChain: PropTypes.func
    };

    state = {
        introductoryListEmpty: false
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            scroll: true,
        };
    }

    onRefresh = () => {
        this.props.loadMore && this.props.loadMore();
    };

    getData = () => {
        return this.props.letters;
    };

    onLetterSelected = (letter) => {
        this.props.onLetterSelect && this.props.onLetterSelect(letter);
    };

    renderLetter = ({ item }) => {
        return (
            <ChainItem
                key={String(item.chainId)}
                id={item.attendeeId}
                data={item}
                userId={this.props.userId}
                onSelect={this.onLetterSelected}
                deleteChain={this.props.deleteChain}
                onScrollEnabled={this.onScrollEnabled}
            />
        );
    };

    introductoryListIsEmpty = (isEmpty) => {
        this.setState({ introductoryListEmpty: isEmpty });
    };

    onScrollEnabled = (enabled) => {
        this.setState({ scroll: enabled });
    };

    renderIntroductory = () => {
        return (
            <View style={styles.introductoryLettersSection}>
                <LettersList
                    useDynamicLoading={false}
                    defaultCount={2}
                    loadMoreCount={10}
                    loadMoreButtonTitleFormat={Resources.strings['inbox-load-more-introductory-letters-button-title']}
                    style={styles.list}
                    userId={this.props.userId}
                    filter={InboxFilter.allIntroductory}
                    onEmptyList={this.introductoryListIsEmpty}
                    onScrollEnabled={this.onScrollEnabled}
                />
                <View style={styles.separatorBetweenLetterSections}/>
            </View>
        );
    };

    render() {
        this.props.onEmptyList &&
        this.props.onEmptyList(
            (!this.props.letterChains || !this.props.letterChains.length) && this.state.introductoryListEmpty
        );

        return (
            <View style={{width: '100%'}}>
                <FlatList
                    data={this.props.letterChains}
                    keyExtractor={item => 'chain' + item.chainId}
                    ListHeaderComponent={this.renderIntroductory}
                    style={styles.list}
                    renderItem={this.renderLetter}
                    onEndReachedThreshold={1}
                    onEndReached={this.onRefresh}
                    scrollEnabled={this.state.scroll}
                />
            </View>);
    }
};
