import React from 'react';
import PropTypes from 'prop-types';
import { WebView } from 'react-native-webview';
import styles from './styles';

export default class SmartWebView extends React.Component {
    static displayName = 'components.webview.view';
    static propTypes = {
        url: PropTypes.string,
        onUrlChange: PropTypes.string,
    };

    onLoadStart = onLoadStartEvent => {
        const url = onLoadStartEvent.nativeEvent.url;
        if (url !== this.url) {
            this.url = url;
            this.props.onUrlChange && this.props.onUrlChange(url);
        }
    };

    render() {
        let source;
        if (this.props.url) {
            source = { uri: this.props.url };
        } else if (this.props.request) {
            source = {
                method: this.props.request.method,
                baseUrl: this.props.request.baseUrl,
                html: this.props.request.html,
            };
        }

        return (
            <WebView
                javaScriptEnabled={true}
                style={styles.webView}
                source={source}
                onLoadStart={this.onLoadStart}
            />
        );
    }
}
