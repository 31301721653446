import Actions from './actions';

function createFeaturesStore(id = 'default') {
  class Store {
    constructor(flux) {
      const actions = flux.getActions(Actions, id);

      this.state = {};

      this.bindAction(actions.get, this.updateTags);
    }

    updateTags(features) {
      if (!features) {
        return;
      }

      this.setState({
        features,
      });
    }
  }

  Store.displayName = createFeaturesStore.getDisplayName(id);

  return Store;
}

createFeaturesStore.getDisplayName = function(id) {
  return `user-tags-features.${id}`;
};

export default createFeaturesStore;
