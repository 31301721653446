import React, {Component} from 'react';
import {Image, Text, View, TouchableWithoutFeedback} from 'react-native';
import styles from './styles';
import Photo, { PHOTO_TRANSFORMATION_TYPE } from '../../../../components/photo';
import withAspectRatio from '../../../../components/aspect-ratio';
import testId from '../../../../utils/test-id';

const AspectRatioView = withAspectRatio(View);

class LivestreamFollowTopListItem extends React.PureComponent {
    static displayName = 'livestream-follow-top-list-item';

    pressed = () => {
        this.props.onPress(this.props.id);
    };

    render() {
        let { ...props } = this.props.user;

        const basename = props.thumbnail || props['thumbnail-pending'];

        const selected = this.props.selected ? 1 : 0;

        return (
            <TouchableWithoutFeedback onPress={this.pressed}>
                <View style={styles.container} {...testId('Follow top streamers list item')}>
                    <AspectRatioView style={styles.imageContainer}>
                        <Photo
                            style={styles.image}
                            userId={props.id}
                            basename={basename}
                            round={true}
                            transformation={PHOTO_TRANSFORMATION_TYPE.DETECT_FACE}
                            {...testId('Streamer thumbnail')}
                        />
                        <Image
                            style={[styles.selectionMark, {opacity: selected}]}
                            source={require('./assets/follow_icon.png')}
                            {...testId('Follow icon')}
                        />
                    </AspectRatioView>
                    <Text style={styles.name} numberOfLines={1} {...testId('Streamer name label')}>&nbsp;{props.name}&nbsp;</Text>
                </View>
            </TouchableWithoutFeedback>
        )

    }
}

export default LivestreamFollowTopListItem;
