import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  $statusBarStyle: '$brandStatusBarStyle',
  $statusBarBackgroundColorComplex: '$brandColor',

  header: {
    backgroundColor: '$primaryBackgroundColor',
    borderBottomColor: '$primaryBackgroundColor',
    shadowColor: '$primaryBackgroundColor',
  },

  headerTitle: {
    color: '$primaryTextColor',
  },

  container: {
    flex: 1,
    backgroundColor: '$primaryBackgroundColor',
  },

  scroll: {
    flexGrow: 1,
  },

  scrollContent: {
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'stretch',
    paddingTop: 20,
    paddingHorizontal: 30,
    paddingBottom: 30,
  },

  photos: {
    marginTop: 13,
    paddingLeft: 20,
    marginBottom: 24,
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '$primaryBorderColor',
  },
  property: {
    marginLeft: 30,
  },
  safeArea: {
    flex: 1,
    backgroundColor: '$primaryBackgroundColor',
  },
  safeAreaComplex: {
    backgroundColor: '$brandColor',
  },
  tintColor: {
    color: '$primaryTextColor',
  },
  editingContainer: {
    width: '100%',
    marginTop: 24,
  },
  galleryContainer: {
    width: '100%',
    marginTop: 16,
  },
});

export default styles;
