import React from 'react';
import PropTypes from 'prop-types';
import { PixelRatio, Platform, Image } from 'react-native';
// import FastImage from 'react-native-fast-image';
import equal from 'fast-deep-equal';
import Resources from 'dating-mobile/src/resources';
import { userPhotoPath } from 'dating-mobile/src/resources/remote';
import ConfigModel from 'dating-mobile/src/models/config/model';
import sharding from 'dating-mobile/src/utils/sharding';
import { PHOTO_TRANSFORMATION_TYPE, PHOTO_PLACEHOLDER_TYPE } from './constants';

import styles from './styles';

const FastImage = Image;

class Photo extends React.PureComponent {
  static displayName = 'components.photo.view';

  static propTypes = {
    userId: PropTypes.string,
    basename: PropTypes.string,
    path: PropTypes.string,
    transformation: PropTypes.number,
    round: PropTypes.bool,
    size: PropTypes.string,
    forceRetina: PropTypes.bool,
    withoutAuth: PropTypes.bool,
    placeholder: PropTypes.string,
    private: PropTypes.bool,
    preview: PropTypes.bool,
    crop: PropTypes.object,
    host: PropTypes.string,
    shardsCount: PropTypes.number,
    initialRetryTimeout: PropTypes.number,
    retryMultiplier: PropTypes.number,
    maxRetries: PropTypes.number,
    shardPrefixes: PropTypes.string,
    onError: PropTypes.func,
    style: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
  };

  static defaultProps = {
    initialRetryTimeout: 1000,
    retryMultiplier: 2,
    maxRetries: 1,
  };

  static contextTypes = {
    flux: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    this.configModel = context.flux.get(ConfigModel);
    const config = this.configModel.store.getState();

    this.state = {
      host: config.host,
      shardsCount: (config.sharding || {}).shards,
      shardPrefixes: (config.sharding || {}).prefixes,
      retryCounter: 0,
      useFallback: false,
      retryTimeout: props.initialRetryTimeout,
    };
  }

  componentDidMount() {
    this.configModel.store.listen(this.onConfigUpdated);
  }

  componentWillReceiveProps(nextProps) {
    if (
      !equal(
        this.getSourceFromProps(this.props),
        this.getSourceFromProps(nextProps),
      )
    ) {
      this.setState({
        retryCounter: 0,
        useFallback: false,
        retryTimeout: nextProps.initialRetryTimeout,
      });
    }
  }

  componentWillUnmount() {
    this.configModel.store.unlisten(this.onConfigUpdated);

    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  getStyle() {
    const { userId, style, round } = this.props;
    const { width } = this.state;

    return [
      {
        borderRadius: round && width ? Math.floor(width / 2) : undefined,
        backgroundColor: userId
          ? styles.$avatarBackgroundColors[
              parseInt(userId, 10) % styles.$avatarBackgroundColors.length
            ] || undefined
          : undefined,
      },
      style,
    ];
  }

  getSourceFromProps = props => {
    const { width, height, host, shardsCount, shardPrefixes } = this.state;
    const { withoutAuth, forceRetina } = this.props;

    if (props.source) {
      return props.source;
    }

    if (!host || !(props.size || (width && height))) {
      return undefined;
    }

    let { path } = props;

    if (!path && props.userId && props.basename) {
      path = userPhotoPath(
        props.userId,
        props.basename,
        props.private,
        props.preview,
      );
    }

    if (!path) {
      return undefined;
    }

    let transformation = '';

    switch (+props.transformation) {
      case PHOTO_TRANSFORMATION_TYPE.DETECT_FACE:
        transformation = '.thumb-fd';
        break;
      case PHOTO_TRANSFORMATION_TYPE.KEEP_ASPECT_RATIO:
        transformation = '.gallery';
        break;
    }

    let { size } = props;

    if (!size) {
      const actualWidth = forceRetina
        ? PixelRatio.getPixelSizeForLayoutSize(Math.ceil(width))
        : Math.ceil(width);
      const actualHeight = forceRetina
        ? PixelRatio.getPixelSizeForLayoutSize(Math.ceil(height))
        : Math.ceil(height);

      size = `.${actualWidth}x${actualHeight}`;
    }

    const headers =
      Platform.OS === 'web'
        ? {}
        : {
            Authorization: this.context.flux.api.authorize(),
            'User-Agent': this.context.flux.api.augment('user-agent'),
          };
    const queryParam = withoutAuth
      ? ''
      : `?authorization=${this.context.flux.api.authorize()}`;
    const uri = `${sharding(
      `${host}${path}${size}${transformation}`,
      shardsCount,
      shardPrefixes,
    )}${queryParam}`;

    return {
      uri,
      headers,
      scale: 1.0,
    };
  };

  onConfigUpdated = config => {
    this.setState({
      host: config.host,
      shardsCount: (config.sharding || {}).shards,
      shardPrefixes: (config.sharding || {}).prefixes,
    });
  };

  onViewLaidOut = event => {
    const { width, height } = event.nativeEvent.layout;

    if (
      !width ||
      !height ||
      (this.state.width === width && this.state.height === height)
    ) {
      return;
    }

    this.setState({
      width,
      height,
    });
  };

  onError = (...args) => {
    const { onError, maxRetries, retryMultiplier } = this.props;
    const { retryCounter, retryTimeout } = this.state;

    this.setState({
      useFallback: retryCounter >= maxRetries,
    });

    if (onError) {
      onError(...args);
    }

    // HACK
    if (retryTimeout && retryCounter < maxRetries) {
      this.timeoutId = setTimeout(() => {
        this.timeoutId = null;

        this.setState(state => ({
          retryCounter: state.retryCounter + 1,
          retryTimeout: state.retryTimeout * retryMultiplier,
        }));
      }, retryTimeout);
    }
  };

  render() {
    const { forwardedRef, maxRetries, ...props } = this.props;
    const { width, retryCounter, useFallback } = this.state;

    props.source = this.getSourceFromProps(props);

    props.style = [
      props.round && width
        ? {
            borderRadius: Math.floor(width / 2),
          }
        : {},
      props.style || {},
    ];

    if (props.round && !width) {
      props.defaultSource = undefined;
      props.source = undefined;
    } else {
      props.defaultSource = props.defaultSource
        ? props.defaultSource
        : props.placeholder === PHOTO_PLACEHOLDER_TYPE.AVATAR
        ? Resources.images.defaultAvatar()
        : undefined;
      props.source =
        props.source && !useFallback ? props.source : props.defaultSource;
    }

    let ImageComponent;

    if (Platform.OS === 'ios') {
      ImageComponent = props.fallback ? Image : FastImage;
    } else {
      ImageComponent = Image;
    }

    return (
      <ImageComponent
        {...props}
        key={retryCounter}
        style={this.getStyle()}
        ref={forwardedRef}
        onError={this.onError}
        onLayout={this.onViewLaidOut}
      />
    );
  }
}

export default React.forwardRef((props, ref) => {
  return <Photo {...props} forwardedRef={ref} />;
});
