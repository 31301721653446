import Resources from '../../resources';

export const FILTER_TYPES = Object.freeze({
    SELECTION: 1,
    RANGE: 2,
    LOCATION: 3,
    INTERESTS: 4
});

export const FILTERS = Object.freeze({
    GENDER: 'gender',
    AGE: 'age',
    LOCATION: 'location',
    EDUCATION: 'education',
    LANGUAGE: 'language',
    RELATIONSHIP: 'relationship',
    KIDS: 'kids',
    SMOKE: 'smoke',
    DRINK: 'drink',
    HEIGHT: 'height',
    BODY_TYPE: 'body-type',
    EYES: 'eye',
    HAIR: 'hair',
    INTERESTS: 'interests',
    TRAVEL_DESTINATION: 'travel-destination',
    VIDEO_CHAT: 'video_chat'
});

export const PREFERRED_GENDER_VALUES = Object.freeze({
    'fem': Resources.strings['filter-screen-preferred-gender-item-female-value-title'],
    'mal': Resources.strings['filter-screen-preferred-gender-item-male-value-title']
});

export const KIDS_VALUES = Object.freeze({
    'true': Resources.strings['filter-screen-kids-have-kids-item-title'],
    'false': Resources.strings['filter-screen-kids-no-kids-item-title']
});
