import React from 'react';
import PropTypes from 'prop-types';
import { Text, TouchableOpacity, View } from 'react-native';
import { FILTERS } from '../../utils/filters';
import { CRITERIA_OPTIONS } from '../../../models/misc-data/utils';
import withMiscDataValue from '../../../models/misc-data/controller/value';
import PureOption from './option';
import styles from './styles';
import testId from 'dating-mobile/src/utils/test-id';
import Resources from 'dating-mobile/src/resources';

const Option = withMiscDataValue(
    PureOption,
    {
        keyPropName: 'dataTypeKey',
        dataTypePropName: 'dataType',
        valuePropName: 'dataValue'
    }
);

class Options extends React.Component {
    static displayName = 'feed.filter.screen.options';
    static propTypes = {
        params: PropTypes.object,
        onOptionPress: PropTypes.func,
        onDeleteOptionPress: PropTypes.func,
        onAddPress: PropTypes.func,
        onResetPress: PropTypes.func
    };

    dataTypeForFilter = (field) => {
        switch (field) {
            case FILTERS.LOCATION:
            case FILTERS.TRAVEL_DESTINATION:
                return CRITERIA_OPTIONS.COUNTRIES;
            case FILTERS.EDUCATION:
                return CRITERIA_OPTIONS.EDUCATIONS;
            case FILTERS.RELATIONSHIP:
                return CRITERIA_OPTIONS.RELATIONSHIPS;
            case FILTERS.KIDS:
                return CRITERIA_OPTIONS.KIDS_SEARCH;
            case FILTERS.SMOKE:
                return CRITERIA_OPTIONS.SMOKE_SEARCH;
            case FILTERS.DRINK:
                return CRITERIA_OPTIONS.DRINKING_SEARCH;
            case FILTERS.BODY_TYPE:
                return CRITERIA_OPTIONS.BODY_TYPE;
            case FILTERS.EYES:
                return CRITERIA_OPTIONS.EYES_SEARCH;
            case FILTERS.HAIR:
                return CRITERIA_OPTIONS.HAIRS_SEARCH;
            case FILTERS.INTERESTS:
                return CRITERIA_OPTIONS.INTERESTS;
            case FILTERS.LANGUAGE:
                return CRITERIA_OPTIONS.LANGUAGES;
            default:
                return undefined;
        }
    };

    renderItems = () => {
        const params = this.props.params || {};

        const values = Object.keys(params).reduce((values, filter) => {
            const value = params[filter];
            if (typeof value === 'undefined' || value === null) {
                return values;
            }

            if (Array.isArray(value)) {
                values.push(...value.map(item => ({ filter: filter, value: item })));
            } else if (typeof value === 'object') {
                if (Object.keys(value).filter(key => !!value[key]).length) {
                    values.push({ filter: filter, value: value });
                }
            } else {
                values.push({ filter: filter, value: value });
            }
            return values;
        }, []);

        const items = values.map((value, index) => {

            const dataType = this.dataTypeForFilter(value.filter);
            const dataName = value.filter === FILTERS.LOCATION || value.filter === FILTERS.TRAVEL_DESTINATION
                ? value.value.country
                : value.value;
            return (
                <Option
                    key={`${value.filter}-${index}`}
                    filter={value.filter}
                    dataType={dataType}
                    dataTypeKey={dataName}
                    value={value.value}
                    onPress={this.props.onOptionPress}
                    onDeletePress={this.props.onDeleteOptionPress}
                />);

        });

        if (items.length >= 3) {
            items.push((
                <TouchableOpacity key={'delete-all'} style={styles.deleteAllOption} onPress={this.props.onResetPress}>
                    <Text style={styles.deleteAllOptionTitle}>
                        {Resources.strings['filter-screen-delete-all-options-button-title']}
                    </Text>
                </TouchableOpacity>
            ))
        }

        return items;
    };

    render() {
        return (
            <View style={styles.container}>
                <View style={styles.options}>
                    {this.renderItems()}
                </View>
                <TouchableOpacity
                    style={styles.addOptionButton}
                    onPress={this.props.onAddPress}
                    {...testId(Resources.strings['add-more-options-accessibility-label'])}
                >
                    <Text style={styles.addOptionButtonTitle}>
                        {Resources.strings['filter-screen-add-more-options-button-title']}
                    </Text>
                </TouchableOpacity>
            </View>
        )
    }

}

export default Options;
