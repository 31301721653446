import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import { Image, Text, View } from 'react-native';
import Resources from 'dating-mobile/src/resources';
import NotificationBadge from 'dating-mobile/src/components/notification-badge';

export default class FollowingTabBarItem extends React.PureComponent {
    static displayName = 'tab-bar.following-badge';
    static propTypes = {
        focused: PropTypes.bool.isRequired,
        badgeText: PropTypes.string
    };

    render() {
        const imageStyle = [styles.icon, this.props.focused ? styles.activeIcon : styles.inactiveIcon];
        const source = this.props.focused ? Resources.images.tabBarFollowedStreams() : Resources.images.tabBarFollowedStreamsDisabled();

        return (
            <View style={styles.container}>
                <Image style={imageStyle} source={source}/>
                {
                    this.props.badgeText && (
                        <NotificationBadge
                            style={styles.textContainer}
                            text={this.props.badgeText}
                        />
                    )
                }
            </View>
        );
    }
}
