import EventEmitter from 'eventemitter3';
import { AUTH_UPDATE_MODAL } from 'dating-mobile/src/routing/router/constants';
import Navigator from '../../routing/navigator';

function updateCredentials(updateCallback, cancelCallback) {
  const bus = new EventEmitter();

  function unmount() {
    bus.removeListener('event.credentials.update.completed', updated);
    bus.removeListener('event.credentials.update.cancelled', cancelled);
  }

  function updated(...args) {
    if (typeof updateCallback === 'function') {
      updateCallback(...args);
    }

    unmount();
  }

  function cancelled(...args) {
    if (typeof cancelCallback === 'function') {
      cancelCallback(...args);
    }

    unmount();
  }

  bus.addListener('event.credentials.update.completed', updated);
  bus.addListener('event.credentials.update.cancelled', cancelled);

  Navigator.navigate(AUTH_UPDATE_MODAL, { bus });
}

export default {
  updateCredentials,
};
