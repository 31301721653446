import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import BalanceRefiller, {
  PAYMENT_REASON,
} from '@sdv/domain/app/balance-refiller';

import extendBannerIconFirst from '../assets/extend_banner_icon_first.png';
import extendBannerIconSecond from '../assets/extend_banner_icon_second.png';
import extendBannerIconThird from '../assets/extend_banner_icon_third.png';

const getImageByUserId = id => {
  const modulo = id && Number.parseInt(id.slice(0, -2), 10) % 3;

  if (modulo === 2) {
    return extendBannerIconFirst;
  }

  if (modulo === 1) {
    return extendBannerIconSecond;
  }

  return extendBannerIconThird;
};

export default function createController(Component) {
  return class Controller extends PureComponent {
    static displayName =
      'components.purchase-promotion-banner.premium-subscription.controller';

    static propTypes = {
      userId: PropTypes.string,
      style: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.object,
        PropTypes.array,
      ]),
      placeholder: PropTypes.object,
      hidden: PropTypes.bool,
      introductoryInvoice: PropTypes.object,
    };

    purchase = () => {
      BalanceRefiller.subscribe(PAYMENT_REASON.MANUAL);
    };

    render() {
      const {
        userId,
        style,
        placeholder,
        hidden,
        introductoryInvoice,
      } = this.props;

      return (
        <Component
          style={style}
          image={getImageByUserId(userId)}
          onPress={this.purchase}
          placeholder={placeholder}
          hidden={hidden}
          introductoryInvoice={introductoryInvoice}
        />
      );
    }
  };
}
