import React from 'react';
import PropTypes from 'prop-types';
import RoundButton from '../../../components/buttons/round';
import Resources from '../../../resources';
import {Alert, View} from 'react-native';
import styles from './styles';

class ToggleVideoButton extends React.PureComponent {
    static displayName = 'call.toggle-video-button';
    static propTypes = {
        localVideoEnabled: PropTypes.bool,
        remoteVideoEnabled: PropTypes.bool,
        iconShowState: PropTypes.bool,
        showAlert: PropTypes.bool,
        toggle: PropTypes.func,
        disabled: PropTypes.bool,
        hideWhenDisabled: PropTypes.bool
    };

    onPressed = () => {
        if (this.props.showAlert && this.props.localVideoEnabled && !this.props.remoteVideoEnabled) {
            Alert.alert(
                Resources.strings['call-one-way-camera-stop-alert-title'],
                Resources.strings['call-one-way-camera-stop-alert-message'],
                [
                    {
                        text: Resources.strings.ok,
                        onPress: this.props.toggle
                    },
                    {
                        text: Resources.strings.cancel,
                        style: 'cancel'
                    }
                ]
            );
        } else {
            this.props.toggle && this.props.toggle();
        }
    };

    render() {
        if (this.props.disabled && this.props.hideWhenDisabled) {
            return null;
        }

        const { forwardedRef, ...props } = this.props;

        const icon = (this.props.iconShowState
            ? this.props.localVideoEnabled
            : !this.props.localVideoEnabled)
                ? Resources.images.videoCameraIcon()
                : Resources.images.cameraOffIcon();

        const containerStyle = this.props.disabled ? styles.disabled : undefined;

        return (
            <View style={containerStyle}>
                <RoundButton
                    {...props}
                    ref={forwardedRef}
                    icon={icon}
                    iconStyle={[styles.icon, this.props.iconStyle]}
                    disabled={this.props.disabled}
                    onPress={this.onPressed}
                />
            </View>
        );
    }
}

export default React.forwardRef(
    (props, ref) => React.createElement(ToggleVideoButton, { forwardedRef: ref, ...props })
);
