import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        flexDirection: 'row',
    },
    photo: {
        height: 80,
        marginLeft: 12,
        alignItems: 'center',
        justifyContent: 'center'
    },
    counter: {
        fontSize: 15,
        color: 'white',
        fontWeight: 'bold',
        fontFamily: '$brandFontFamily',
    },
    addMedia: {
        marginLeft: 12,
    }
});

export default styles;
