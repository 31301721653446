import React from 'react';
import CredentialsUpdater from "dating-mobile/src//authentication/utils/credentials-updater";
import withIsAnonymous from 'dating-mobile/src/models/user.roles/controller/is-anonymous';

export default function createController(Component) {
    class Controller extends React.Component {
        static displayName = 'user.views.authenticate-navigation-item.controller';

        render() {
            return (<Component {...this.props} onPress={CredentialsUpdater.updateCredentials} />);
        }
    }

    return withIsAnonymous(Controller);
}
