import React from 'react';
import PropTypes from 'prop-types';
import connect from '@sdv/connect';
import MessagesModel, {
  getId,
  DELIVERY_STATUS,
} from '@sdv/domain/dialogs.messages';
import lohi from '@sdv/commons/utils/lohi';
import PaidResourceModel, {
  getId as getPaidResourceModelId,
  RESOURCE_ACCESS_STATUS,
} from '@sdv/domain/paid-resource';
import NotificationsModel, {
  NOTIFICATION_PRIORITY,
} from '@sdv/domain/notifications/local';

const MESSAGES_PROPERTY = 'generated.dialogs-messages-booster.messages';
const STATUS_PROPERTY = 'generated.dialogs-messages-booster.status';
const GET_STATUS_PROPERTY = 'generated.dialogs-messages-booster.get-status';
const BOOST_PROPERTY = 'generated.dialogs-messages-booster.boost';
const NOTIFY_PROPERTY = 'generated.dialogs-messages-booster.notify';

export default function createHoc(Component, options = {}) {
  const enabledPropName = options.enabledPropName || 'boostEnabled';
  const userPropName = options.userPropName || 'user';
  const attendeePropName = options.attendeePropName || 'attendee';
  const boostPropName = options.boostPropName || 'boost';
  const messagesTagsPropName = options.messagesTagsPropName || 'messagesTags';
  const refillBalancePropName =
    options.refillBalancePropName || 'refillBalance';

  class Booster extends React.Component {
    static displayName = 'dialogs.messages.booster';

    static contextTypes = {
      flux: PropTypes.object,
    };

    componentDidMount() {
      const {
        [enabledPropName]: enabled,
        [STATUS_PROPERTY]: status,
        [GET_STATUS_PROPERTY]: getStatus,
      } = this.props;

      if (enabled && status === RESOURCE_ACCESS_STATUS.UNKNOWN && getStatus) {
        getStatus();
      }
    }

    boost = () => {
      const {
        [refillBalancePropName]: refillBalance,
        [attendeePropName]: attendee,
        [BOOST_PROPERTY]: boost,
        [NOTIFY_PROPERTY]: notify,
      } = this.props;

      if (boost) {
        boost(error => {
          if (error) {
            if (error.status === 402 && refillBalance) {
              refillBalance(this.boost);
            }

            return;
          }

          if (notify) {
            notify(
              {
                type: 'message-boosted',
                payload: {
                  attendee,
                },
              },
              NOTIFICATION_PRIORITY.NORMAL,
            );
          }
        });
      }
    };

    render() {
      const {
        [STATUS_PROPERTY]: status,
        [MESSAGES_PROPERTY]: messages,
        [GET_STATUS_PROPERTY]: getStatus,
        [BOOST_PROPERTY]: boost,
        [NOTIFY_PROPERTY]: notify,
        forwardedRef,
        ...props
      } = this.props;

      const messagesTags = [];

      if (
        props[enabledPropName] !== false &&
        messages &&
        status === RESOURCE_ACCESS_STATUS.PAYMENT_REQUIRED &&
        !messages.find(message => message.sender !== props[userPropName])
      ) {
        const lastDeliveredMessage = messages.find(
          message =>
            message.sender === props[userPropName] &&
            message.status === DELIVERY_STATUS.DELIVERED,
        );

        if (lastDeliveredMessage) {
          messagesTags.push(lastDeliveredMessage.tag);
        }
      }

      return (
        <Component
          {...props}
          ref={forwardedRef}
          {...{
            [messagesTagsPropName]: messagesTags,
            [boostPropName]: this.boost,
          }}
        />
      );
    }
  }

  function getModels(flux, props) {
    if (props[userPropName] && props[attendeePropName]) {
      const pair = lohi(props[userPropName], props[attendeePropName]);

      return {
        messagesModel: flux.get(
          MessagesModel,
          getId(props[userPropName], props[attendeePropName]),
        ),
        boostModel: flux.get(
          PaidResourceModel,
          getPaidResourceModelId(
            props[userPropName],
            `/dialogs/messages/${pair.lower}:${pair.higher}/boosted`,
          ),
        ),
        notificationsModel: flux.get(NotificationsModel, props[userPropName]),
      };
    }

    return {};
  }

  function mapStoresToProps(models) {
    const props = {};

    if (models.messagesModel) {
      props[MESSAGES_PROPERTY] = models.messagesModel.store.getState().messages;
    }

    if (models.boostModel) {
      props[STATUS_PROPERTY] = models.boostModel.store.getState().status;
    }

    return props;
  }

  function mapStoresToActions(models) {
    const props = {};

    if (models.boostModel) {
      props[GET_STATUS_PROPERTY] = models.boostModel.actions.access;
      props[BOOST_PROPERTY] = models.boostModel.actions.buy;
    }

    if (models.notificationsModel) {
      props[NOTIFY_PROPERTY] = models.notificationsModel.actions.notify;
    }

    return props;
  }

  function shouldReconnect(props, newProps) {
    return (
      props[userPropName] !== newProps[userPropName] ||
      props[attendeePropName] !== newProps[attendeePropName]
    );
  }

  const ConnectedComponent = connect(
    getModels,
    mapStoresToProps,
    mapStoresToActions,
    shouldReconnect,
  )(Booster);

  // eslint-disable-next-line react/no-multi-comp
  return React.forwardRef((props, ref) => {
    return <ConnectedComponent {...props} forwardedRef={ref} />;
  });
}
