import React from 'react';
import PropTypes from 'prop-types';
import { Text, View, TouchableOpacity } from 'react-native';
import Resources from '../../../../resources';
import styles from './styles';

class AboutMe extends React.Component {
    static displayName = 'profile.edit.about-me';
    static propTypes = {
        text: PropTypes.string,
        onPress: PropTypes.func
    };

    shouldComponentUpdate(nextProps) {
        return this.props.text !== nextProps.text;
    }

    onPressed = () => {
        this.props.onPress && this.props.onPress();
    };

    render() {
        const aboutText = this.props.text || Resources.strings['edit-profile-screen-about-text-placeholder'];
        const contentContainerStyle = [styles.contentContainer, !this.props.text ? styles.contentContainerPlaceholder : {}];
        const textStyle = [styles.text, !this.props.text ? styles.textPlaceholder: {}];

        return (
            <View style={styles.container}>
                <View style={contentContainerStyle}>
                    <Text style={styles.title}>{Resources.strings['edit-profile-screen-about-title']}</Text>
                    <TouchableOpacity onPress={this.onPressed}>
                        <Text style={textStyle}>{aboutText}</Text>
                    </TouchableOpacity>
                </View>
            </View>
        );

    }
}

export default AboutMe;
