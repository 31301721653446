import EStyleSheet from 'react-native-extended-stylesheet';
import merge from 'merge/merge';

const defaultSwitchItem = {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    borderColor: '$accentColor',
    borderLeftWidth: 1,
    '@flavor tubit': {
        borderLeftWidth: 0,
        gradientColor: '$brandButtonDisabledGradientColor'
    }
};

const defaultSwitchItemTitle = {
    fontFamily: '$brandFontFamily',
    fontSize: 12,
    color: '$primaryTextColor',
    '@flavor tubit': {
        fontSize: 14
    }
};

const styles = EStyleSheet.create({
    container: {
        flexDirection: 'column',
        backgroundColor: '$primaryBackgroundColor'
    },

    switcherContainer: {
        width: '100%',
        paddingHorizontal: 10,
        '@flavor tubit': {
            paddingHorizontal: 20,
            marginBottom: 10
        }
    },

    switcher: {
        flexDirection: 'row',
        width: '100%',
        height: 40,
        borderColor: '$accentColor',
        borderWidth: 1,
        borderRadius: 10,
        overflow: 'hidden',
        '@flavor tubit': {
            marginTop: 14,
            borderWidth: 0,
            borderRadius: 18,
            height: 36
        }
    },

    switchItem: defaultSwitchItem,

    selectedSwitchItem: merge.recursive(true, defaultSwitchItem, {
        backgroundColor: '$accentColor',
        '@flavor tubit': {
            borderLeftWidth: 0,
            gradientColor: undefined
        }
    }),

    switchItemTitle: defaultSwitchItemTitle,

    selectedSwitchItemTitle: merge.recursive(true, defaultSwitchItemTitle, {
        color: '$accentTextColor'
    }),

    keyboard: {

    }
});

export default styles;
