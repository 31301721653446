import React from 'react';
import PropTypes from 'prop-types';
import withIdentityId from '../../../models/identity/controller/id';
import withSmiles from 'dating-mobile/src/models/dialogs.smiles/controller';
import { render } from 'dating-mobile/src/components/rx-render';
import TrialChatLimitation from '@sdv/domain/dialogs.messages/trial-chat-limitation';

function createController(Component) {

    const Controller = render((props, { useDistinct, useObservable }) => {
        const onlySmilesRegexp = useDistinct(pattern => new RegExp(`^(${pattern}|\\s)+$`), props.pattern);
        const attendee = props.id === props.message.recipient ? props.message.sender : props.message.recipient;
        const trialChatLimitation = useDistinct(
            (userId, attendeeId) => TrialChatLimitation.shared(userId, attendeeId),
            props.id,
            attendee
        );
        const unpaidMessages = useObservable(it => it.unpaidMessages, trialChatLimitation) || [];
        const blurred = unpaidMessages.indexOf(props.message.tag) >= 0;

        const textContainsOnlySmiles = props.message.content &&
            onlySmilesRegexp &&
            onlySmilesRegexp.test(props.message.content);

        return (
            <Component
                {...props}
                blurred={blurred}
                textContainsOnlySmiles={textContainsOnlySmiles}
            />
        );

    });

    Controller.propTypes = {
        trialMessagesEnabled: PropTypes.bool,
        userIsCustomer: PropTypes.bool,
        pattern: PropTypes.string,
        message: PropTypes.shape({
            tag: PropTypes.string.isRequired,
        }).isRequired
    };

    Controller.displayName = 'common.messages.message.text.controller';

    return withIdentityId(withSmiles(Controller), 'id');
}

export default createController;
