import React from 'react';
import Resources from 'dating-mobile/src/resources';
import { EditTextForm } from 'dating-mobile/src/components/forms/edit-text';

export default class ChangeEmailScreen extends React.Component {
    static displayName = 'user.screens.change-password.view';

    render() {
        return (
            <EditTextForm
                autoCapitalize={'none'}
                secureTextEntry={true}
                placeholder={Resources.strings.password}
                {...this.props}
            />
        );
    }
}
