import React from 'react';
import PropTypes from 'prop-types';
import { Image, View, Text } from 'react-native';
import TextInput from 'dating-mobile/src/components/text-input/index';
import { GapsMaskedInput } from 'dating-mobile/src/components/gaps-masked-input';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, first, map } from 'rxjs/operators';
import { CreditCardValidity } from '@sdv/domain/payment/credit-card/credit-card-validity';
import Resources from 'dating-mobile/src/resources/index';
import { memo } from '@sdv/commons/utils/memo';
import creditCardType, { types as CardType } from 'credit-card-type';
import { MonthYearInput } from 'dating-mobile/src/components/month-year-input';
import styles from './styles';

export class CreditCardPaymentForm extends React.Component {
  static displayName = 'payment.views.credit-card-payment-form';

  static propTypes = {
    onInput: PropTypes.func.isRequired,
    onDirty: PropTypes.func.isRequired,
  };

  dirty = false;

  constructor(props) {
    super(props);
    this.validity = new CreditCardValidity();
    this.state = {
      errorFields: {},
    };

    this.inputFields = {
      number: new BehaviorSubject(''),
      expDate: new BehaviorSubject(''),
      verification: new BehaviorSubject(''),
      holder: new BehaviorSubject(''),
    };

    this.focusLost = {};

    this.creditCard = combineLatest(
      this.inputFields.number,
      this.inputFields.expDate,
      this.inputFields.verification,
      this.inputFields.holder,
      (number, expDate, verification, holder) => {
        return {
          number,
          expDate,
          verification,
          holder,
        };
      },
    );

    this.creditCard
      .pipe(
        map(card => {
          if (this.validity.validate(card).length === 0) {
            return card;
          }

          return null;
        }),
      )
      .subscribe(card => {
        props.onInput(card);
      });

    this.creditCardType = this.creditCard.pipe(
      map(card => {
        if (!card.number) {
          return '';
        }
        const cardTypes = creditCardType(card.number);
        const [best] = cardTypes;

        return best;
      }),
    );

    this.creditCardType
      .pipe(
        map(bestMatch => {
          return (bestMatch && bestMatch.type) || '';
        }),
        map(type => this.getCardIcon(type)),
      )
      .subscribe(
        icon => this.setState({ cardIcon: icon }),
        () => {},
      );

    const defaultGaps = [4, 8, 12];

    this.creditCardType
      .pipe(
        map(bestMatch => {
          return (bestMatch && bestMatch.gaps) || defaultGaps;
        }),
        distinctUntilChanged(),
      )
      .subscribe(
        gaps => {
          return this.setState({ cardGaps: gaps });
        },
        () => {},
      );
  }

  getCardIcon(type) {
    let cardIcon;

    switch (type) {
      case CardType.VISA:
        cardIcon = Resources.images.cardVisaIcon();
        break;
      case CardType.AMERICAN_EXPRESS:
        cardIcon = Resources.images.cardAmexIcon();
        break;
      case CardType.DISCOVER:
        cardIcon = Resources.images.cardDiscoverIcon();
        break;
      case CardType.JCB:
        cardIcon = Resources.images.cardJcbIcon();
        break;
      case CardType.MASTERCARD:
        cardIcon = Resources.images.cardMastercardIcon();
        break;
      default:
        if (type) {
          cardIcon = Resources.images.creditCardIcon();
        }
        break;
    }

    return cardIcon;
  }

  handleChange = (key, text) => {
    if (!this.dirty) {
      const { onDirty } = this.props;

      this.dirty = true;
      onDirty();
    }

    this.inputFields[key].next(text);

    if (this.focusLost[key]) {
      this.validate([key]);
    }
  };

  handleExpChange = text => this.handleChange('expDate', text);

  validate = keys => {
    keys.forEach(key => {
      this.focusLost[key] = true;
    });

    this.creditCard.pipe(first()).subscribe(card => {
      const { errorFields } = this.state;
      const errors = this.validity.validate(card);

      keys.forEach(key => {
        errorFields[key] = errors.includes(key);
      });

      this.setState({
        errorFields,
      });
    });
  };

  errorMessageFor(key) {
    const { errorFields } = this.state;
    const isNotValid = errorFields[key];
    let message = false;

    if (isNotValid) {
      switch (key) {
        case 'number':
          message = Resources.strings['card-payment-form-card-number-error'];
          break;
        case 'expDate.month':
        case 'expDate.year':
        case 'expDate':
          message = Resources.strings['card-payment-form-exp-date-error'];
          break;
        case 'verification':
          message = Resources.strings['card-payment-form-cvv-error'];
          break;
        case 'holder':
          message = Resources.strings['card-payment-form-holder-error'];
          break;
      }
    }

    return message;
  }

  render() {
    const { cardGaps, cardIcon } = this.state;

    return (
      <View style={styles.form}>
        <View style={styles.cardNumberRow}>
          <GapsMaskedInput
            style={styles.cardNumber}
            placeholder={
              Resources.strings['card-payment-form-card-number-placeholder']
            }
            autoCapitalize="none"
            keyboardType="number-pad"
            onChangeText={memo(this, text => this.handleChange('number', text))}
            onBlur={memo(this, () => this.validate(['number']))}
            error={this.errorMessageFor('number')}
            autoComplete="cc-number"
            importantForAutofill="yes"
            gaps={cardGaps}
          />

          <View style={styles.cardLogoContainer}>
            <Image
              source={cardIcon}
              resizeMode="contain"
              style={styles.cardLogo}
            />
          </View>
        </View>

        <View style={styles.expAndCVVRow}>
          <MonthYearInput
            style={styles.expDateInput}
            containerStyle={styles.expDateContainer}
            autoCapitalize="none"
            keyboardType="number-pad"
            onChangeText={text => this.handleExpChange(text)}
            onMonthBlur={memo(this, () => this.validate(['expDate.month']))}
            onYearBlur={memo(this, () =>
              this.validate(['expDate.year', 'expDate']),
            )}
            errorMonth={
              this.errorMessageFor('expDate.month') ||
              this.errorMessageFor('expDate')
            }
            errorYear={
              this.errorMessageFor('expDate.year') ||
              this.errorMessageFor('expDate')
            }
            importantForAutofill="yes"
          />
          <Text style={styles.cvvLabel}>
            {Resources.strings['card-payment-form-cvv-placeholder']}
          </Text>
          <TextInput
            style={styles.cvvInput}
            containerStyle={styles.cvvInputContainer}
            autoCapitalize="none"
            keyboardType="number-pad"
            onChangeText={memo(this, text =>
              this.handleChange('verification', text),
            )}
            onBlur={memo(this, () => this.validate(['verification']))}
            error={this.errorMessageFor('verification')}
            autoComplete="cc-csc"
            importantForAutofill="yes"
          />
        </View>

        <TextInput
          style={styles.nameInput}
          placeholder={
            Resources.strings['card-payment-form-holder-placeholder']
          }
          autoCapitalize="none"
          keyboardType="default"
          onChangeText={memo(this, text => this.handleChange('holder', text))}
          onBlur={memo(this, () => this.validate(['holder']))}
          error={this.errorMessageFor('holder')}
          autoComplete="name"
          importantForAutofill="yes"
        />
      </View>
    );
  }
}
