import React from 'react';
import PropTypes from 'prop-types';
import StickersModel from '../model';
import connect from '@sdv/connect';

const GET_PROPERTY = 'generated.dialogs.stickers.controller.get';

function createStickerPacksController(Component) {

    class Controller extends React.Component {
        static displayName = 'dialogs-stickers.controller';
        static propTypes = {
            stickerPacks: PropTypes.array,
            [GET_PROPERTY]: PropTypes.func
        };

        componentDidMount() {
            !this.props.stickerPacks && this.props[GET_PROPERTY]();
        }

        render() {
            const { ...props } = this.props;

            delete props[GET_PROPERTY];

            return (<Component {...props} />)
        }
    }

    return connect(
        (flux) => {
            return {
                stickerModel: flux.get(StickersModel)
            }
        },

        (models) => {
            return models.stickerModel.store.getState();
        },

        (models) => {
            return {
                [GET_PROPERTY]: models.stickerModel.actions.get
            };
        }
    )(Controller);
}

export default createStickerPacksController;
