import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({

    container: {
        width: '100%'
    },

    list: {
        width: '100%'
    },

    separator: {
        width: 16
    },

    header: {
        width: 60
    },

    footer: {
        width: 60
    },

});

export default styles;
