import React from 'react';
import { StyleSheet } from 'react-native';
import { withNavigationFocus } from 'react-navigation';

import Resources from 'dating-mobile/src/resources';
import Can from 'dating-mobile/src/models/user.ability/controller';
import testId from 'dating-mobile/src/utils/test-id';
import * as ROUTES from 'dating-mobile/src/routing/router/constants';

import PropTypes from 'prop-types';
import ImageButton from '../../../components/navigation/views/image-button';
import TextButton from '../../../components/navigation/views/text-button';
import IfConfigValue from '../../../components/config-value/if';
import BalanceRefiller, {
  PAYMENT_REASON,
} from '../../../payment/utils/balance-refiller';
import { FILTER_TYPES } from '../../utils/filters';
import Controller from './controller';
import View from './view';

import styles from './styles';

const ScreenView = withNavigationFocus(Controller(View));

class Screen extends React.Component {
  static displayName = 'feed.filter.screen';

  static propTypes = {
    navigation: PropTypes.object.isRequired,
  };

  static navigationOptions = ({ navigation, navigationOptions }) => {
    return {
      title: Resources.strings['filter-screen-title'],
      headerStyle: [navigationOptions.headerStyle, styles.header],
      headerTitleStyle: [
        navigationOptions.headerTitleStyle,
        styles.headerTitle,
      ],
      headerLeft: () => (
        <ImageButton
          image={Resources.images.backIcon()}
          onPress={() => navigation.pop()}
          {...testId('Back button')}
          tint={
            StyleSheet.flatten([
              navigationOptions.headerTitleStyle,
              styles.headerTitle,
            ]).color
          }
        />
      ),
      headerRight: () => (
        <IfConfigValue
          path="markup.filter-screen.has-reset-button"
          equalsTo={true}
        >
          <TextButton
            title={Resources.strings['filter-screen-reset-button-title']}
            onPress={navigation.getParam('onResetButtonPress')}
            tint={
              StyleSheet.flatten([
                navigationOptions.headerTitleStyle,
                styles.headerTitle,
              ]).color
            }
          />
        </IfConfigValue>
      ),
    };
  };

  editFilter = (filterType, props) => {
    const { navigation } = this.props;

    switch (filterType) {
      case FILTER_TYPES.SELECTION:
        navigation.navigate(ROUTES.SELECTION_EDITING, props);
        break;
      case FILTER_TYPES.RANGE:
        navigation.navigate(ROUTES.RANGE_PICKER_EDITING, props);
        break;
      case FILTER_TYPES.LOCATION:
        navigation.navigate(ROUTES.LOCATION_EDITING, props);
        break;
      case FILTER_TYPES.INTERESTS:
        navigation.navigate(ROUTES.INTERESTS_EDITING, props);
        break;
    }
  };

  editAdvancedFilters = props => {
    const { navigation } = this.props;

    navigation.navigate(ROUTES.ADVANCED_FILTERS, props);
  };

  subscribe = (...args) => {
    BalanceRefiller.subscribe(PAYMENT_REASON.USE_ADVANCED_SEARCH, ...args);
  };

  close = () => {
    const { navigation } = this.props;

    navigation.pop();
  };

  setNavigationBarState = state => {
    const { navigation } = this.props;

    navigation.setParams(state);
  };

  render() {
    const { navigation } = this.props;

    return (
      <Can do="use" of="advanced search">
        {status => (
          <ScreenView
            canUseAdvancedSearch={status}
            setNavigationBarState={this.setNavigationBarState}
            editFilter={this.editFilter}
            editAdvancedFilters={this.editAdvancedFilters}
            close={this.close}
            subscribe={this.subscribe}
            onResetButtonPress={navigation.getParam('onResetButtonPress')}
          />
        )}
      </Can>
    );
  }
}

export default Screen;
