import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    indicator: {
        width: 10,
        height: 10,
        borderRadius: 5,
        borderWidth: 2,
        borderColor: 'white',
        backgroundColor: '$brandColor'
    }
});

export default styles;
