import View from './view';
import withController from './controller';
import styles from './styles';

const Screen = withController(View);

Screen.navigationOptions = () => ({
  title: null,
  headerStyle: styles.header,
  headerLeft: () => null,
  headerRight: () => null,
});

export default Screen;
