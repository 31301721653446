import RoomModel from '../../model';
import { MESSAGE_TYPES, MessagesActionsError } from '../../../common.messages/model';
import DialogMessagesModel, { getId as getDialogsMessagesModelId } from '@sdv/domain/dialogs.messages';
import { DELIVERY_STATUS } from '@sdv/domain/dialogs.messages/actions';
import { generateTag } from '@sdv/domain/streaming/room/actions';

function createMessagesActions(id) {

    const params = parseId(id);

    class MessagesActions {
        get = () => (dispatch) => {
            dispatch(null, new MessagesActionsError(MessagesActionsError.CODES.PARTIAL_LOAD));
        };

        send = (messageType, content, tag) => (dispatch, flux) => {
            let model = flux.get(RoomModel, params.room);
            switch (messageType) {
                case MESSAGE_TYPES.TEXT:
                    model.actions.send(params.identity, { text: content, tag: tag }, (error) => { dispatch(null, error) });
                    break;
                case MESSAGE_TYPES.CHEER:
                    const shouldSentToChat = content.basename.startsWith('/cheers/');

                    if (shouldSentToChat) {
                        const dialogsMessagesModel = flux.get(DialogMessagesModel, getDialogsMessagesModelId(params.identity, params.room));

                        const messageTag = tag || generateTag(params.identity);

                        const subscription = flux.events.addListener('message:message:v2', (payload) => {
                            if (!payload || payload.tag !== messageTag) {
                                return;
                            }

                            subscription.remove();

                            switch (payload.status) {
                                case DELIVERY_STATUS.DELIVERED:
                                    model.actions.send(params.identity, {
                                        text: 'Sent a gift!',
                                        meta: {
                                            reference: `cheer:${content.basename}`,
                                            price: content.price,
                                            transfer: content.amount
                                        }
                                    }, (error) => {
                                        dispatch(null, error);
                                    });
                                    break;
                                case DELIVERY_STATUS.UNPAID:
                                    const error = new MessagesActionsError(MessagesActionsError.CODES.PAYMENT_REQUIRED);
                                    error.tag = messageTag;
                                    dispatch(null, error);
                                    break;
                                default:
                                    dispatch(null, new Error('Unknown status code'));
                                    break;
                            }
                        });

                        dialogsMessagesModel.actions.send(undefined, `cheer:${content.basename}`, messageTag, (error) => {
                            if (error) {
                                subscription.remove();
                                dispatch(null, error);
                                return;
                            }

                            flux.events.pollEvent();
                        });
                    } else {
                        flux.api.credits.accounts.transfers['+'].post(params.room, params.identity, { amount: content.price }, function (error) {
                            if (error) {
                                if (error.hasOwnProperty('status') && error.status === 402) {
                                    return dispatch(null, new MessagesActionsError(MessagesActionsError.CODES.PAYMENT_REQUIRED));
                                }
                                return dispatch(null, error);
                            }

                            model.actions.send(params.identity, {
                                text: 'Sent a gift!',
                                meta: {
                                    reference: `cheer:${content.basename}`,
                                    price: content.price,
                                    transfer: content.amount
                                }
                            }, (error) => {
                                dispatch(null, error);
                            });
                        });
                    }
            }
        };


        delete = (tag) => (dispatch, flux) => {
            // Can't delete room messages for now.
        }

    }

    MessagesActions.displayName = createMessagesActions.getDisplayName(id);

    return MessagesActions;
}

createMessagesActions.getDisplayName = function(id) {
    return `dialogs-rooms-messages-adapter.${id}`;
};

export default createMessagesActions;

export function parseId(id) {
    let keys;

    if (typeof id !== 'string' || (keys = id.split(':room')).length !== 2) {
        throw new Error('MessagesActions should have id in format "{identity}:room{attendee}"');
    }

    return {
        identity: keys[0],
        room: keys[1]
    };
}

export function getId(identity, room) {
    return `${identity}:room${room}`;
}
