import View from './view';
import withUserPresence from 'dating-mobile/src/models/user.presence/controllers';
import withConfigValue from 'dating-mobile/src/components/config-value';

export default withUserPresence(
    withConfigValue(
        View,
        {
            inboxEnabled: 'features.inbox-enabled',
            videoUploadingEnabled: 'features.video-uploading-enabled'
        }
    ),
    {
        userIdPropName: 'attendeeId'
    }
);
