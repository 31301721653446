import flux from '@sdv/domain/app/flux';

export class RegistrationPreferencesTracker {
    constructor(preferencesInterceptor) {
        preferencesInterceptor.preferencesDidSet.subscribe(data => {
            flux.api.annals.add(data.userId, 'registration-preferences', {
                screen: data.screen,
                selectedValue: data.selectedValue,
            });
        });
    }
}
