import { singleton } from '@sdv/commons/utils/singleton';
import flux from '@sdv/domain/app/flux';

import NotificationsModel from '../local';

export class UserNotifying {
  static shared = singleton(userId => new UserNotifying(userId));

  constructor(userId) {
    this.userId = userId;
  }

  notifyUser(notification, priority) {
    flux
      .get(NotificationsModel, this.userId)
      .actions.notify(notification, priority);
  }
}
