import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, Image } from 'react-native';
import styles from './styles';

class BenefitItem extends React.PureComponent {
  static displayName = 'payment.membership-benefits.item';

  static propTypes = {
    containerStyle: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    icon: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.number,
      PropTypes.string,
    ]),
    iconStyle: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    iconContainerStyle: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    titleStyle: PropTypes.object,
    title: PropTypes.node,
  };

  render() {
    const {
      containerStyle,
      iconStyle,
      iconContainerStyle,
      titleStyle,
      icon,
      title,
    } = this.props;

    return (
      <View style={[styles.container, containerStyle]}>
        <View style={[styles.iconContainer, iconContainerStyle]}>
          <Image
            source={icon}
            style={[styles.icon, iconStyle]}
            resizeMode="contain"
          />
        </View>
        <View style={styles.titleContainer}>
          <Text style={[styles.title, titleStyle]}>{title || ''}</Text>
        </View>
      </View>
    );
  }
}

export default BenefitItem;
