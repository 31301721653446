import React, { PureComponent } from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import { SubscribePlanItem } from 'dating-mobile/src/payment/views/subscribe-plan-card';

import styles from './styles';

export default class SubscribePlans extends PureComponent {
    static propTypes = {
        plans: PropTypes.object,
        onSelectPlan: PropTypes.func,
        currentPlan: PropTypes.number,
        customStyles: PropTypes.shape({
            item: PropTypes.object,
        }),
    };

    render() {
        const { plans, onSelectPlan, currentPlan, customStyles = {} } = this.props;

        return (
            <View style={styles.container}>
                {plans.map(plan => (
                    <SubscribePlanItem
                        {...plan}
                        isActive={plan.id === currentPlan}
                        onPress={onSelectPlan}
                        customStyles={customStyles.item}
                    />
                ))}
            </View>
        );
    }
}
