import PropTypes from 'prop-types';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';
import Service from 'dating-mobile/src/services/service';
import { NavigationActions, StackActions } from 'react-navigation';
import Navigator from 'dating-mobile/src/routing/navigator';
import * as ROUTES from 'dating-mobile/src/routing/router/constants';
import withConfigValue from 'dating-mobile/src/components/config-value';
import url from 'url';
import deepLinking from './deep-linking';

class DeepLinkedChatting extends Service {
  static displayName = 'service.deeplinking.deep-linked-chatting';

  static contextTypes = {
    flux: PropTypes.object,
  };

  static propTypes = {
    userId: PropTypes.string,
    chatsEnabled: PropTypes.bool,
    likesListEnabled: PropTypes.bool,
  };

  componentDidMount() {
    deepLinking.addDeepLinkListener(this.lastDeepLink);
  }

  componentWillUnmount() {
    deepLinking.removeDeepLinkListener(this.lastDeepLink);
  }

  lastDeepLink = (uri, payload) => {
    const { chatsEnabled, likesListEnabled, userId } = this.props;

    if (!chatsEnabled || payload?.mediaMessageUri) {
      return;
    }

    const parsedUrl = url.parse(uri, true);

    if (!parsedUrl.pathname) {
      return;
    }

    const components = parsedUrl.pathname.split('/');
    const { scenario } = parsedUrl.query;

    let tabName = null;
    const actions = [];

    if (components[1] === 'dialogs' && components[2]) {
      if (components[3] === 'letters' && components[4] && userId) {
        tabName = ROUTES.INBOX_TAB;
        actions.push(NavigationActions.navigate({ routeName: ROUTES.INBOX }));
        if (components[5] && components[4] === 'introductory') {
          actions.push(
            NavigationActions.navigate({
              routeName: ROUTES.LETTER,
              params: {
                recipient: userId,
                sender: components[2],
                letter: components[5],
                introductory: true,
              },
            }),
          );
        } else {
          actions.push(
            NavigationActions.navigate({
              routeName: ROUTES.LETTER,
              params: {
                recipient: userId,
                sender: components[2],
                letter: components[4],
                introductory: false,
              },
            }),
          );
        }
      } else {
        tabName = ROUTES.CONTACTS_TAB;
        actions.push(
          NavigationActions.navigate({
            routeName: ROUTES.CONTACTS,
            params: { forceShow: true },
          }),
          NavigationActions.navigate({
            routeName: ROUTES.CHAT,
            params: { id: components[2], likesListEnabled },
          }),
        );
        if (scenario === 'introductory') {
          const body = {
            code: 'introductory',
            'sender-id': components[2],
          };

          this.context.flux.api.codes.introductory.post(userId, body);
        }
      }
    }

    if (tabName) {
      const action = actions.length
        ? StackActions.reset({
            index: actions.length - 1,
            actions,
          })
        : null;

      Navigator.navigate(tabName, {}, action);
    }
  };
}

export default withConfigValue(withIdentityId(DeepLinkedChatting), {
  chatsEnabled: 'features.chats-enabled',
  likesListEnabled: 'features.likes-list-enabled',
});
