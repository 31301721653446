import React from 'react';
import { Text } from 'react-native';
import PropTypes from 'prop-types';
import ConfigValue from '../config-value';

class CurrencyBasedText extends React.PureComponent {
    static displayName = 'component.currency-based-text';
    static propTypes = {
        currency: PropTypes.string,
        strings: PropTypes.object,
    };

    render() {
        const text =
            this.props.currency &&
            this.props.strings &&
            String(this.props.strings[this.props.currency]);

        return <Text {...this.props}>{text || ''}</Text>;
    }
}

export default ConfigValue(CurrencyBasedText, 'currency.type', 'currency');
