import React, { PureComponent } from 'react';
import { StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import ImageButton from 'dating-mobile/src/components/navigation/views/image-button';
import Resources from 'dating-mobile/src/resources';
import { PURCHASE_TAB } from 'dating-mobile/src/routing/router/constants';

import styles from './styles';

function createNavigation(Component) {
  class Navigation extends PureComponent {
    static displayName = 'payment.screens.special-offer.navigation';

    static propTypes = {
      navigation: PropTypes.object.isRequired,
    };

    static navigationOptions = ({ navigation }) => {
      return {
        headerTitle: '',
        headerStyle: styles.header,
        headerTitleStyle: styles.headerTitle,
        headerLeft: () => null,
        headerRight: () => (
          <ImageButton
            image={Resources.images.closeIcon()}
            tint={StyleSheet.flatten(styles.headerTitle).color}
            onPress={() => navigation.navigate(PURCHASE_TAB)}
          />
        ),
      };
    };

    onClose = () => {
      const { navigation } = this.props;

      navigation.navigate(PURCHASE_TAB);
    };

    render() {
      return <Component onClose={this.onClose} />;
    }
  }

  return Navigation;
}

export default createNavigation;
