import React from 'react';
import { FlatList, View, Text, TouchableOpacity, Image } from 'react-native';
import PropTypes from 'prop-types';
import withAutoScale from 'dating-mobile/src/components/auto-scale-image';
import Resources from 'dating-mobile/src/resources';

import styles from './styles';

const AutoScaledImage = withAutoScale(Image);

class SelectionValueEditingView extends React.PureComponent {
  static displayName = 'user.edit.selection-value-editing-view';

  static propTypes = {
    availableValues: PropTypes.object.isRequired,
    selectedItems: PropTypes.array.isRequired,
    multiSelection: PropTypes.bool,
    onSelectionChanged: PropTypes.func,
    allowUnselection: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = { selectedItems: props.selectedItems || [] };
  }

  itemPressed = item => () => {
    const { multiSelection, allowUnselection, onSelectionChanged } = this.props;
    const { selectedItems } = this.state;
    let newSelectedItems = selectedItems.slice();
    const index = newSelectedItems.indexOf(item.code);

    if (multiSelection) {
      if (index >= 0) {
        newSelectedItems.splice(index, 1);
      } else {
        newSelectedItems.push(item.code);
      }
    } else if (index >= 0) {
      if (allowUnselection) {
        newSelectedItems = [];
      }
    } else {
      newSelectedItems = [item.code];
    }

    this.setState({ selectedItems: newSelectedItems });

    if (onSelectionChanged) {
      onSelectionChanged(newSelectedItems);
    }
  };

  renderItem = ({ item }) => {
    const { multiSelection } = this.props;
    const { selectedItems } = this.state;
    const isSelected = selectedItems.indexOf(item.code) >= 0;
    const backgroundColor =
      isSelected && !!multiSelection
        ? styles.selectedIcon
        : styles.unselectedIcon;

    return (
      <TouchableOpacity
        style={styles.itemContainer}
        onPress={this.itemPressed(item)}
      >
        <View style={[styles.selectionIcon, backgroundColor]}>
          {isSelected && !multiSelection && <View style={styles.dotIcon} />}
          {isSelected && !!multiSelection && (
            <AutoScaledImage source={Resources.images.checkMarkIcon()} />
          )}
        </View>
        <View style={styles.textContainer}>
          <Text style={styles.text}>{item.displayValue}</Text>
        </View>
      </TouchableOpacity>
    );
  };

  render() {
    const { availableValues } = this.props;
    const { selectedItems } = this.state;
    const data = [];
    const allKeys = Object.keys(availableValues);

    for (let i = 0; i < allKeys.length; i++) {
      const key = allKeys[i];
      const displayValue = availableValues[key] && availableValues[key].trim();
      const dict = { code: key, displayValue };

      data.push(dict);
    }

    return (
      <View style={styles.container}>
        <FlatList
          style={styles.flatList}
          data={data}
          renderItem={this.renderItem}
          alwaysBounceVertical={false}
          extraData={selectedItems}
          keyExtractor={({ code }) => code}
        />
      </View>
    );
  }
}

export default SelectionValueEditingView;
