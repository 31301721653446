import { makeNavigationItem } from 'dating-mobile/src/components/navigation-item';
import Resources from 'dating-mobile/src/resources';
import { CHANGE_PASSWORD } from 'dating-mobile/src/routing/router/constants';

export const ChangePasswordNavigationItem = makeNavigationItem({
  route: CHANGE_PASSWORD,
  routeParamsFromProps: props => ({
    userId: props.userId,
  }),
  title: Resources.strings['change-password-navigation-item-title'],
});
