import React from 'react';
import { StyleSheet } from 'react-native';
import PropTypes from 'prop-types';

import ImageButton from 'dating-mobile/src/components/navigation/views/image-button';
import Resources from 'dating-mobile/src/resources';
import styles from './styles';

function bindNavigation(Component) {
  class Navigation extends React.Component {
    static displayName = 'dialogs.stream.screens.topic-selector.navigation';

    static propTypes = {
      navigation: PropTypes.object.isRequired,
    };

    goBack = () => {
      const { navigation } = this.props;

      navigation.goBack();
    };

    render() {
      return <Component goBack={this.goBack} {...this.props} />;
    }
  }

  Navigation.navigationOptions = ({ navigation }) => ({
    title: Resources.strings['stream-topics-selection-tags'],
    headerStyle: styles.header,
    headerTitleStyle: styles.headerTitle,
    headerTransparent: true,
    headerLeft: () => (
      <ImageButton
        image={Resources.images.backIcon()}
        onPress={() => navigation.pop()}
        tint={StyleSheet.flatten(styles.headerTitle).color}
      />
    ),
  });

  return Navigation;
}

export default bindNavigation;
