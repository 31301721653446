import connect from '@sdv/connect';
import MediaModel from '../../../models/call.media';
import qs from 'qs';
import MessagesModel, { getId } from '../../../models/dialogs.messages/utils/messages-adapter';

function getModels(flux, props) {
    const models = {};

    if (props.userId && props.attendeeId) {
        const modelId = qs.stringify({ user: props.userId, attendee: props.attendeeId });
        models.mediaModel = flux.get(MediaModel, modelId);
        models.messagesModel = flux.get(MessagesModel, getId(props.userId, props.attendeeId));
    }

    return models;
}

function mapStoresToProps(models) {
    const props = {};

    if (models.mediaModel) {
        const state = models.mediaModel.store.getState();
        props.hasLocalVideo = !!state.localStream;
        props.hasRemoteVideo = !!state.remoteStream;
    }

    if (models.messagesModel) {
        const state = models.messagesModel.store.getState();
        props.lastMessage = state.messages[0];
    }

    return props;
}

export default connect(
    getModels,
    mapStoresToProps
);
