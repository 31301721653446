import { from } from 'rxjs';
import flux from '@sdv/domain/app/flux';
import MallModel from 'dating-mobile/src/models/credits.mall/model';
import { mapInvoiceToProduct } from './product';

export class AppStorePayment {
  /**
   *
   * @param {{membership:bool, sku: string, amount: number, price: number}} invoice
   * @param {string} reason
   * @param {Promise<void>} exchangeInterceptor
   */
  constructor(id, invoice, reason, exchangeInterceptor) {
    this.invoice = invoice;
    this.reason = reason;
    this.exchangeInterceptor = exchangeInterceptor;
    this.model = flux.get(MallModel, id);
  }

  purchase = () => {
    const product = mapInvoiceToProduct(this.invoice);

    return from(
      new Promise(resolve => {
        this.model.actions.purchase(
          product,
          this.reason,
          this.exchangeInterceptor,
          error => {
            if (error) {
              this.model.actions.purchase(
                product,
                this.reason,
                this.exchangeInterceptor,
                error,
                err => {
                  return resolve({ error: err, success: !err });
                },
              );
            } else {
              resolve({ error, success: !error });
            }
          },
        );
      }),
    );
  };
}
