import React from 'react';
import PayPalView from '../view';
import { SafeAreaView } from "react-navigation";
import styles from "./styles";
import { StatusBar } from "react-native";

class PayPalPaymentScreen extends React.PureComponent {
    static displayName = 'components.webview.screen.view';

    render() {
        return (
            <SafeAreaView style={styles.container}>
                <StatusBar
                    barStyle={styles.$statusBarStyle}
                    backgroundColor={styles.$statusBarBackgroundColor}
                />

                <PayPalView
                    {...this.props}
                    style={styles.payPalView}
                />
            </SafeAreaView>
        );
    }
}

export default PayPalPaymentScreen;
