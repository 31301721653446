import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import { Image, View } from 'react-native';
import Resources from 'dating-mobile/src/resources';
import UnreadLettersIndicator from 'dating-mobile/src/inbox/views/unread-letters-indicator';

export default class InboxTabBatItem extends React.PureComponent {
    static displayName = 'routing.views.inbox-tab-bar-item.view';
    static propTypes = {
        showIndicator: PropTypes.bool,
        focused: PropTypes.bool,
        userId: PropTypes.string
    };

    render() {
        const imageStyle = [styles.icon, this.props.focused ? styles.activeIcon : styles.inactiveIcon];

        const source = this.props.focused
            ? Resources.images.tabBarInbox()
            : Resources.images.tabBarInboxDisabled();

        return (
            <View style={styles.container}>
                <Image style={imageStyle} source={source}/>
                <UnreadLettersIndicator userId={this.props.userId} style={styles.unreadLetterIndicator}/>
            </View>
        );
    }
}
