import EStyleSheet from 'react-native-extended-stylesheet';
import { I18nManager } from 'react-native';

const styles = EStyleSheet.create({
  $pinVisible: true,

  item: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },

  iconContainer: {
    width: 56,
    alignItems: 'center',
  },

  icon: {
    tintColor: '$primaryTextColor',
    transform: [
      {
        scaleX: I18nManager.isRTL ? -1 : 1,
      },
    ],
  },

  titleContainer: {
    flex: 1,
    paddingVertical: 20,
    paddingRight: 16,
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '$primaryBorderColor',
    alignItems: 'center',
  },

  titleContainerWithoutIconModifier: {
    marginLeft: 20,
  },

  titleWrapper: {
    flexShrink: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },

  titleWrapperWithoutValueModifier: {
    flex: 1,
  },

  pinContainer: {
    marginLeft: vars => (vars.$pinVisible ? 12 : 0),
    width: vars => (vars.$pinVisible ? undefined : 0),
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
  },

  pin: {
    tintColor: '$primaryTextColor',
    transform: [
      {
        scaleX: I18nManager.isRTL ? -1 : 1,
      },
    ],
  },

  expander: {
    width: 20,
  },

  hint: {
    color: '$secondaryTextColor',
    fontSize: 12,
    fontFamily: '$brandFontFamily',
    marginTop: 10,
  },

  title: {
    fontSize: 17,
    fontWeight: '600',
    color: '$primaryTextColor',
    fontFamily: '$brandFontFamily',
  },

  value: {
    minWidth: 50,
    flex: 1,
    fontFamily: '$brandFontFamily',
    fontSize: 17,
    fontWeight: '600',
    color: '$primaryTextColor',
    flexShrink: 1,
    textAlign: 'right',
  },

  valueDisabled: {
    color: '$secondaryTextColor',
  },
});

export default styles;

EStyleSheet.subscribe('build', () => {
  styles.titleContainerWithoutIcon = [
    styles.titleContainer,
    styles.titleContainerWithoutIconModifier,
  ];
  styles.titleWrapperWithoutValue = [
    styles.titleWrapper,
    styles.titleWrapperWithoutValueModifier,
  ];
});
