import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $tabBarActiveColor: '$selectedColor',
    $tabBarInactiveColor: '$tabBarIconInactiveColor',

    container: {
        flexDirection: 'row',
        height: 32
    },

    icon: {
        width: 24,
        height: 24,
        alignSelf: 'center'
    },

    activeIcon: {
        tintColor: '$tabBarActiveColor'
    },

    inactiveIcon: {
        tintColor: '$tabBarInactiveColor'
    },

    textContainer: {
        marginLeft: -8,
        backgroundColor: '$brandNotificationsColor',
        width: 16,
        height: 16,
        borderRadius: 10,
        borderWidth: 2,
        borderColor: 'white',
        justifyContent: 'center',
        alignItems: 'center',
        '@flavor tubit': {
            borderWidth: 0,
        }
    },
});

export default styles;
