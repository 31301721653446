import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingHorizontal: 30,
        paddingBottom: 50,
    },
    expiredLinkContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 30,
        paddingBottom: 50,
    },
    title: {
        width: '100%',
        fontSize: '$screenTitleSize',
        textAlign: '$brandTitleAlign',
        color: '$brandTextColor',
        fontFamily: '$brandFontFamily',
        marginBottom: 100,
        marginTop: 20,
    },
    expiredLinkTitle: {
        width: '100%',
        fontSize: 18,
        textAlign: '$brandTitleAlign',
        color: '$brandTextColor',
        fontFamily: '$brandFontFamily',
        marginBottom: 60,
    },
    inputs: {
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        marginBottom: 10,
    },
    inputContainer: {
        marginBottom: 50,
    },
    input: {
        borderBottomColor: '$brandBorderColor',
        borderBottomWidth: 1,
        fontFamily: '$brandFontFamily',
        color: '$brandTextColor',
        textAlign: 'center',
        fontSize: 16,
        height: 40,
    },
});

export default styles;
