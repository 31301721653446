import React from 'react';
import PropTypes from 'prop-types';
import StreamComponent from './view';
import StreamScreenController from './controller';

const StreamView = StreamScreenController(StreamComponent);

class StreamScreen extends React.PureComponent {
    static displayName = 'dialogs.stream.screen';
    static propTypes = {
        id: PropTypes.string.isRequired,
        goBack: PropTypes.func,
        showDiamonds: PropTypes.func,
        showProfile: PropTypes.func,
        showCurrentlyBroadcasting: PropTypes.func,
        showNextStream: PropTypes.func,
    };

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <StreamView
                id={this.props.id}
                goBack={this.props.goBack}
                showDiamonds={this.props.showDiamonds}
                showProfile={this.props.showProfile}
                showCurrentlyBroadcasting={this.props.showCurrentlyBroadcasting}
                showNextStream={this.props.showNextStream}
            />
        );
    }
}

export default StreamScreen;
