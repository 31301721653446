import React from 'react';
import PropTypes from 'prop-types';
import { View, Text } from 'react-native';
import styles from '../styles';

import UserController from '../../../../../models/user/controllers';
import TextInput from '../../../../../components/text-input';
import InputRow from "../../../../../components/input-row";

class ProfileEditName extends React.PureComponent {
    static displayName = 'user.edit.name';
    static propTypes = {
        user: PropTypes.object.isRequired
    };

    state = {
        error: {
            name: false,
        }
    };

    handlersChange = {};

    constructor(props) {
        super(props);

        ['name'].forEach((key) => {
            this.handlersChange[key] = this.handleChange(key);
        });

        const {name} = this.props.user || {name: null};

        this.state = {...this.state, ...{name}}
    }

    componentWillReceiveProps(props) {
        const { name } = props.user;

        if (name && !this.state.name) {
            this.setState({ name: name })
        }
    }

    handleChange = (key) => async (value) => {
        const text = value.nativeEvent.text;

        try {
            await this.toggleError(key, !text.length, 'Name is required.');
        } catch (e) {
            return;
        }

        this.update(key, text);
    };

    saveName = async () => {
        this.setState({ error: {name: false} });

        try {
            await this.props.updateUser({name: this.state.name});
        } catch (error) {
            this.setState({ error: { name: error.message } });
        }
    };

    update = (key, value) => {
        this.setState({
            [key]: value
        })
    };

    toggleError(key, state, text) {
        const callback = state ? Promise.resolve : Promise.reject;

        if (this.state.error[key] !== state) {
            return this.setState({ error: { [key]: text } }, callback);
        }

        return Promise.resolve();
    }

    render() {

        return (
            <View style={styles.container}>
                <InputRow separator={true} error={this.state.error.name}>
                    <TextInput
                        style={styles.name}
                        placeholder='Name or Nickname'
                        value={this.state.name}
                        onChange={this.handlersChange.name}
                        onBlur={this.saveName}
                    />
                </InputRow>
            </View>
        );
    }
}

export default UserController(ProfileEditName);
