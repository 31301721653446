export default [
    {
        id: '1',
        title: 'Life on the Road: How to Travel for Free',
        background: {
            uri: 'https://triptogetherblogger.files.wordpress.com/2017/04/shutterstock_443812612.jpg?w=500'
        },
        source: require('../assets/travel/free.htm')
    },
    {
        id: '2',
        title: 'What to Do on a \"Staycation\" - Become a Tourist in Your Neighborhood!',
        background: {
            uri: 'https://triptogetherblogger.files.wordpress.com/2017/04/shutterstock_290048066.jpg?w=500'
        },
        source: require('../assets/travel/staycation.htm')
    },
    {
        id: '3',
        title: 'Travel Like a Local: Embrace the Culture of Local Markets',
        background: {
            uri: 'https://triptogetherblogger.files.wordpress.com/2017/04/ghbik.jpg?w=500'
        },
        source: require('../assets/travel/local.htm')
    },
    {
        id: '4',
        title: 'Top 5 Ideas for Extreme Traveling',
        background: {
            uri: 'https://triptogetherblogger.files.wordpress.com/2016/07/sandb.jpg?w=500'
        },
        source: require('../assets/travel/extreme.htm')
    },
    {
        id: '5',
        title: 'Top 5 Places for a Girls’ Getaway Vacation',
        background: {
            uri: 'https://triptogetherblogger.files.wordpress.com/2016/08/vacat.jpg?w=500'
        },
        source: require('../assets/travel/girls.htm')
    },
    {
        id: '6',
        title: 'YourTravelMates’s Top Romantic Destinations for February',
        background: {
            uri: 'https://triptogetherblogger.files.wordpress.com/2016/02/shutterstock_3348815.jpg?w=500'
        },
        source: require('../assets/travel/romantic.htm')
    },
    {
        id: '7',
        title: '5 Kinds of Annoying Travel Partners',
        background: {
            uri: 'https://triptogetherblogger.files.wordpress.com/2016/04/fake.jpg?w=500'
        },
        source: require('../assets/travel/annoying.htm')
    },
    {
        id: '8',
        title: '20 Trips to Take in Your 20s',
        background: {
            uri: 'https://triptogetherblogger.files.wordpress.com/2016/04/shutterstock_93125854.jpg?w=500'
        },
        source: require('../assets/travel/20s.htm')
    },
    {
        id: '9',
        title: 'Top 10 Destinations to Visit in December',
        background: {
            uri: 'https://triptogetherblogger.files.wordpress.com/2015/12/1.jpg?w=500'
        },
        source: require('../assets/travel/december.htm')
    },
    {
        id: '10',
        title: 'To Boldly Go: Nasa Teases New Travel Destinations',
        background: {
            uri: 'https://triptogetherblogger.files.wordpress.com/2017/04/shutterstock_586562156.jpg?w=500'
        },
        source: require('../assets/travel/nasa.htm')
    }
];
