export const MODAL = 'modal';

// APP STACK
export const ROOT = 'root';
export const PAYMENT_MODAL = `payment-${MODAL}`;
export const PURCHASE_MODAL = `purchase-${MODAL}`;
export const CREDITS_MODAL = `credits-${MODAL}`;
export const MEMBERSHIP_MODAL = `membership-${MODAL}`;
export const SPECIAL_OFFER_MODAL = `special-offer-${MODAL}`;
export const CONGRATULATION_MODAL = `congratulation-${MODAL}`;
export const DETAILED_BENEFITS_MEMBERSHIP_MODAL = `detailed-benefits-membership-${MODAL}`;
export const CALLS_PACK_MODAL = `calls-pack-${MODAL}`;
export const AUTH_UPDATE_MODAL = `auth-update-${MODAL}`;
export const TERMS = 'terms';
export const PHOTO = 'photo';
export const START_STREAM_MODAL = `start-stream-${MODAL}`;
export const CHEERS_MODAL = `cheers-${MODAL}`;
export const CHEERS = 'cheers';
export const MINGLE_END_MODAL = `mingle-end-${MODAL}`;
export const AUDIO_VIDEO_CALL_MODAL = `audio-video-call-${MODAL}`;
export const INCOMING_CALL_SWITCH = 'incoming-call-switch';
export const INCOMING_CALL = 'incoming-call';
export const ONGOING_CALL_SWITCH = 'ongoing-call-switch';
export const ONGOING_CALL = 'ongoing-call';
export const CONNECTING_TO_CALL_SWITCH = 'connecting-to-call-switch';
export const CONNECTING_TO_CALL = 'connecting-to-call';
export const TURN_ON_NOTIFICATIONS_MODAL = `turn-on-notifications-${MODAL}`;
export const TURN_ON_NOTIFICATIONS_SCREEN = 'turn-on-notifications-screen';
export const RATE_APP_MODAL = `rate-app-${MODAL}`;
export const STREAMS_TOPICS_PICKING_MODAL = `streams-topics-picking-${MODAL}`;
export const STREAMS_TOPICS_PICKING = 'streams-topics-picking';
export const STREAMS_TOPICS_PICKING_BONUS = 'streams-topics-picking-bonus';
export const ENTER_MANDATORY_THUMBNAIL = 'enter-mandatory-thumbnail';
export const NEWSLETTER_MODAL = `newsletter-${MODAL}`;

// ROOT_STACK
export const AUTH_LOADING = 'auth-loading';
export const AUTH = 'auth';
export const APP = 'app';
export const PAYMENT_PROCESSING = 'payment-processing';

// PAYMENT_PROCESSING_STACK
export const THREE_DS = 'three-ds';
export const LOADING = 'loading';
export const RESULT = 'result';

// CONTACTS_STACK
export const CONTACTS = 'contacts';
export const CHAT_REQUESTS = 'chat-requests';
export const CHAT = 'chat';
export const LETTER = 'letter';
export const WRITE_LETTER = 'write-letter';
export const PROFILE = 'profile';
export const GALLERY = 'gallery';
export const CHATS_STACK = 'chats-stack';

// ACCOUNT_STACK
export const ACCOUNT = 'account';
export const PROFILE_EDIT = 'profile-edit';
export const PROFILE_TRAVELS_SCREEN = 'profile-travels-screen';
export const TEXT_EDITING = 'text-editing';
export const SELECTION_EDITING = 'selection-editing';
export const DATE_EDITING = 'date-editing';
export const INTERESTS_EDITING = 'interests-editing';
export const RANGE_PICKER_EDITING = 'range-picker-editing';
export const PICKER_EDITING = 'picker-editing';
export const LOCATION_EDITING = 'location-editing';
export const NOTIFICATIONS_SETTINGS = 'notifications-settings';
export const ACCOUNT_SETTINGS = 'account-settings';
export const CUSTOMER_SUPPORT = 'customer-support';
export const CHANGE_EMAIL = 'change-email';
export const CHANGE_PASSWORD = 'change-password';
export const FAQ_CATEGORIES = 'faq-categories';
export const FAQ_DETAILS = 'faq-details';
export const BLOCK_LIST = 'block-list';

// FEED_STACK
export const FEED = 'feed';
export const FILTER = 'filter';
export const ADVANCED_FILTERS = 'advanced-filters';
export const START_MINGLE = 'start-mingle';

// START_STREAM_TAB_STACK
export const EMPTY = 'empty';

// START_STREAM_MODAL_STACK
export const STREAM_DESCRIPTION_PRESET = 'stream-description-preset';
export const STREAM = 'stream';
export const STREAM_TOPICS_SELECTION = 'stream-topics-selection';
export const STREAM_INFO = 'stream-info';
export const FOLLOW_TOP = 'follow-top';
export const CURRENTRLY_BROADCASTING = 'currently-broadcasting';
export const STREAM_PROFILE_INFO = 'stream-profile-info';

// TIPS_TAB_STACK
export const TIPS_LIST = 'tips-list';
export const TIP = 'tip';

// INBOX_STACK
export const INBOX = 'inbox';

// APP_STACK
export const LIVE_STREAMS_DISCOVERY_TAB = 'live-streams-discovery-tab';
export const FOLLOWING_LIVE_STREAMS_DISCOVERY_TAB =
  'following-live-streams-discovery-tab';
export const START_STREAM_TAB = 'start-stream-tab';
export const FEED_TAB = 'feed-tab';
export const CHAT_REQUESTS_TAB = 'chat-requests-tab';
export const CONTACTS_TAB = 'contacts-tab';
export const INBOX_TAB = 'inbox-tab';
export const DATING_TIPS_TAB = 'dating-tips-tab';
export const PURCHASE_TAB = 'purchase-tab';
export const ACCOUNT_TAB = 'account-tab';

// AUTH_STACK
export const START = 'start';
export const LOGIN = 'login';
export const REGISTER = 'register';
export const ENTER_PASSWORD = 'enter-password';
export const ENTER_NAME = 'enter-name';
export const ENTER_GENDER = 'enter-gender';
export const ENTER_BIRTHDAY = 'enter-birthday';
export const ENTER_THUMBNAIL = 'enter-thumbnail';
export const TURN_ON_GEOLOCATION = 'turn-on-geolocation';
export const TURN_ON_NOTIFICATIONS = 'turn-on-notifications';
export const START_SETTING_PREFERENCES = 'start-setting-preferences';
export const SPECIFY_RELATIONSHIP_PRIORITY = 'specify-relationship-priority';
export const CHOOSE_USER_GOALS = 'choose-user-goals';
export const CHOOSE_PERSONALITY = 'choose-personality';
export const CHOOSE_BODY_TYPE = 'choose-body-type';
export const CHOOSE_PREFERRED_AGE = 'choose-preferred-age';
export const PASSWORD_RECOVER = 'password-recover';
export const CREATE_NEW_PASSWORD = 'create-new-password';
export const SEARCH_MATCHES = 'search-matches';
export const SUBSCRIBE = 'subscribe';
export const CONGRATULATION = 'congratulation';
export const SKIP_SUBSCRIBE = 'skip-subscribe';
export const FREE_START_MESSAGES = 'free-start-messages';
export const COMPLETE_REGISTRATION = 'complete-registration';

// COMPLETE_REGISTRATION_STACK
export const REGISTRATION_TRAVELS_SCREEN = 'registration-travels-screen';
export const REGISTRATION_UPDATE_USER_SCREEN =
  'registration-update-user-screen';
export const BITHDAY_WITH_GENDER = 'birthday-with-gender';
export const PHOTO_WITH_BIO = 'photo-with-bio';
export const SUBSCRIPTION = 'subscription';
export const BOOST = 'boost';
export const REGISTRATION_GENDER_PICKING = 'registration-gender-picking';
export const USER_GOALS_PICKING = 'user-goals-picking';
export const UPLOAD_PRIVATE_PHOTOS = 'upload-private-photos';

// PAYMENT_STACK
export const PURCHASE = 'purchase';
export const CREDITS = 'credits';
export const MEMBERSHIP = 'membership';
export const PAYMENT_METHOD_LIST = 'payment-method-list';
export const CREDIT_CARD = 'credit-card';
export const CALLS_PACK = 'calls-pack';
export const PAY_PAL = 'pay-pal';
export const SPECIAL_OFFER = 'special-offer';

// CREATE_STREAM_STACK
export const DISCOVERY = 'discovery';
