import React from 'react';
import PropTypes from 'prop-types';
import { ActivityIndicator, Image, StatusBar, Text, View } from 'react-native';
import Resources from 'dating-mobile/src/resources';
import Button from 'dating-mobile/src/components/buttons/base';
import styles from './styles';
import PackageList from '../../views/package-list';
import Balance from '../../views/user-balance';
import Description from '../../views/description';

class CreditsScreen extends React.PureComponent {
  static displayName = 'payment.screens.credits.view';

  static propTypes = {
    id: PropTypes.string,
    getDiscountButtonVisible: PropTypes.bool,
    onLinkPress: PropTypes.func,
    onGetDiscountButtonPress: PropTypes.func,
    packages: PropTypes.array.isRequired,
    onPackagePress: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    style: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.number,
      PropTypes.array,
    ]),
    descriptionTitle: PropTypes.string,
    descriptionHint: PropTypes.string,
  };

  static defaultProps = {
    descriptionHint: Resources.strings['credits-screen-balance-hint'],
  };

  renderFooter = () => {
    const {
      getDiscountButtonVisible,
      onGetDiscountButtonPress,
      descriptionHint,
      isLoading,
    } = this.props;

    if (isLoading) {
      return null;
    }

    if (getDiscountButtonVisible) {
      return (
        <View style={styles.getDiscount}>
          <Button
            style={styles.getDiscountButton}
            titleStyle={styles.getDiscountButtonTitle}
            title={
              Resources.strings[
                'memberships-screen-continue-with-membership-text'
              ]
            }
            onPress={onGetDiscountButtonPress}
          />
        </View>
      );
    }

    if (descriptionHint) {
      return <Text style={styles.hint}>{descriptionHint}</Text>;
    }

    return null;
  };

  render() {
    const {
      style,
      id,
      onLinkPress,
      packages,
      getDiscountButtonVisible,
      isLoading,
      onPackagePress,
      descriptionTitle,
    } = this.props;

    const containerStyle = style ? [styles.container, style] : styles.container;

    return (
      <View style={containerStyle}>
        <StatusBar
          barStyle={styles.$statusBarStyle}
          backgroundColor={styles.$statusBarBackgroundColor}
        />
        <View style={styles.descriptionContainer}>
          {styles.$descriptionIconVisible && (
            <Image
              source={Resources.images.coinIconBig()}
              style={styles.descriptionIcon}
            />
          )}
          {styles.$descriptionTitleVisible && (
            <Text style={styles.descriptionTitle}>
              {descriptionTitle ||
                Resources.strings['credits-screen-top-text-title']}
            </Text>
          )}
          {styles.$descriptionVisible && (
            <View style={styles.description}>
              <Description openLink={onLinkPress} />
            </View>
          )}
        </View>
        {styles.$balanceVisible && (
          <View style={styles.balanceContainer}>
            <Text style={styles.balanceTitle}>
              {Resources.strings['credits-screen-balance-title']}{' '}
            </Text>
            <Balance id={id} />
          </View>
        )}
        <View style={styles.products}>
          <PackageList
            packages={packages}
            itemStyle={styles.packageItem}
            id={id}
            extraData={getDiscountButtonVisible}
            FooterComponent={this.renderFooter}
            isLoading={isLoading}
            onPackagePress={onPackagePress}
          />
        </View>
        {!!isLoading && (
          <View style={styles.activityIndicatorContainer}>
            <ActivityIndicator animating />
          </View>
        )}
      </View>
    );
  }
}

export default CreditsScreen;
