import React from 'react';
import PropTypes from 'prop-types';
import { perf } from 'react-native-firebase';
import { Stock } from '@sdv/domain/payment/stock';
import { Purchase } from 'dating-mobile/src/payment/methods/purchase';
import withHasTag from 'dating-mobile/src/models/user.tags/controllers/has-tag';
import withConfigValue from 'dating-mobile/src/components/config-value';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';
import { Alert } from 'react-native';
import Resources from 'dating-mobile/src/resources';
import { map } from 'rxjs/operators';
import withMediaLoader from 'dating-mobile/src/models/user.media/controller/media-loader';
import withUserController from 'dating-mobile/src/models/user/controllers';
import { TERMS } from 'dating-mobile/src/routing/router/constants';
import { USER_TAGS } from '@sdv/domain/user/tags/user-tags';
import flux from '@sdv/domain/app/flux';
import UserTags from '@sdv/domain/user/tags/personal';
import EventsModel, {
  getId,
  EVENT_TYPE,
} from 'dating-mobile/src/models/user.events/model';
import withUserBalance from 'dating-mobile/src/models/credits.accounts.balance/controllers/controller';
import { SupportedMethods } from '@sdv/domain/app/payment/shop-info';

const getBoostPackages = (packages = []) =>
  packages
    .filter(p => p?.[0]?.boostTime)
    .sort((a, b) => a[0].boostTime - b[0].boostTime);

function controller(Component) {
  class Controller extends React.Component {
    static displayName = 'payment.screens.boost.controller';

    static propTypes = {
      id: PropTypes.string,
      style: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
      discountWithMembershipEnabled: PropTypes.bool,
      canSubscribe: PropTypes.bool,
      ableToNavigateToMembership: PropTypes.bool,
      openLink: PropTypes.func,
      openMembership: PropTypes.func,
      setNavigationBarState: PropTypes.func,
      onComplete: PropTypes.func,
      onPackagePress: PropTypes.func.isRequired,
      reason: PropTypes.string,
      selectMedia: PropTypes.func,
      close: PropTypes.func,
      thumbnailRequiredForBoost: PropTypes.bool,
      isLoading: PropTypes.bool,
      user: PropTypes.object,
      isFocused: PropTypes.bool,
      balance: PropTypes.shape({
        amount: PropTypes.number,
      }),
    };

    static contextTypes = {
      flux: PropTypes.object,
    };

    state = {
      packages: [],
      isLoading: false,
    };

    trace = null;

    componentDidMount() {
      const { setNavigationBarState, isFocused, id } = this.props;

      this.setState({ isLoading: true });

      if (setNavigationBarState) {
        setNavigationBarState({
          onCloseButtonPress: this.onCloseButtonPressed,
        });
      }

      if (isFocused && id) {
        this.getPackages();
      }
    }

    componentDidUpdate(prevProps) {
      const { isFocused, id } = this.props;

      if (prevProps.isFocused !== isFocused) {
        if (isFocused) {
          this.startTrace();
        } else {
          this.stopTrace('cancelled');
        }
      }

      if (
        (prevProps.isFocused !== isFocused || prevProps.id !== id) &&
        isFocused &&
        id
      ) {
        this.getPackages();
      }
    }

    onPackagesLoadingError() {
      this.stopTrace('error');
    }

    onLinkPress = (title, uri) => {
      const { openLink } = this.props;

      if (openLink) {
        openLink(title, uri);
      }
    };

    onPackagePress = async packWithInvoices => {
      try {
        const {
          thumbnailRequiredForBoost,
          selectMedia,
          user,
          id,
          reason,
          balance,
        } = this.props;
        const thumbnail = user && (user['thumbnail-pending'] || user.thumbnail);

        if (thumbnailRequiredForBoost && !thumbnail) {
          await selectMedia();
        }

        const [pack, invoices] = packWithInvoices;

        if (invoices.length > 1) {
          this.openPaymentTypeListScreen(invoices);
        } else if (invoices.length === 1) {
          this.setState({
            isLoading: true,
          });

          const options = {};

          if (invoices?.[0]?.price?.currency === SupportedMethods.credits) {
            options.minCreditsAmount =
              balance?.amount && invoices?.[0]?.price?.includingTax
                ? invoices?.[0]?.price?.includingTax - balance?.amount
                : null;
            options.descriptionTitle = options.minCreditsAmount
              ? Resources.strings.formatString(
                  Resources.strings[
                    'boost-screen-purchase-credits-to-boost-profile-payment-title'
                  ],
                  options.minCreditsAmount,
                )
              : '';
            options.descriptionHint = null;
          }

          this.addSubscription(
            Purchase.shared()
              .pay(id, invoices[0], reason, null, options)
              .subscribe(
                ({ success }) => {
                  this.setState({
                    isLoading: false,
                  });

                  if (success) {
                    // Refresh user tags and boost events manually
                    const tags = flux.get(UserTags, id);
                    const boostDiscountEvent = flux.get(
                      EventsModel,
                      getId(id, EVENT_TYPE.BOOST_DISCOUNT),
                    );
                    const boostEvent = flux.get(
                      EventsModel,
                      getId(id, EVENT_TYPE.BOOST),
                    );

                    if (tags) tags.actions.get();
                    if (boostDiscountEvent) boostDiscountEvent.actions.get();
                    if (boostEvent) boostEvent.actions.get();

                    this.onPaymentCompleted(pack);
                  }
                },
                () => {
                  this.setState({
                    isLoading: false,
                  });
                },
              ),
          );
        }
      } catch (error) {
        // TODO: Handle errors when requirements are specified
      }
    };

    onPaymentClosed(product) {
      const { close, onComplete } = this.props;

      if (close) {
        close(true);
      }

      if (onComplete) {
        onComplete(product);
      }
    }

    onPaymentCompleted = pack => {
      Alert.alert(Resources.strings['purchase-boost-success-message'], null, [
        {
          text: Resources.strings.ok,
          style: 'cancel',
          onPress: () => {
            this.onPaymentClosed(pack);
          },
          onDismiss: () => {
            this.onPaymentClosed(pack);
          },
        },
      ]);
    };

    getPackages() {
      const { id } = this.props;

      Stock.shared(id)
        .preloadedPackagesInStock.pipe(map(it => getBoostPackages(it)))
        .subscribe(packages => {
          this.setState({ packages, isLoading: false });
          this.stopTrace('success');
        }, this.onPackagesLoadingError);
    }

    openPaymentTypeListScreen = invoices => {
      const { onPackagePress } = this.props;

      if (onPackagePress) {
        onPackagePress(invoices, this.onPaymentCompleted);
      }
    };

    openLink = (title, uri) => {
      const { openLink } = this.props;

      openLink(TERMS, {
        title,
        uri,
      });
    };

    onGetDiscountButtonPressed = () => {
      const { openMembership } = this.props;

      if (openMembership) {
        openMembership();
      }
    };

    onCloseButtonPressed = () => {
      const { close, onComplete } = this.props;

      if (close) {
        close();
      }

      if (onComplete) {
        onComplete();
      }
    };

    async startTrace() {
      this.trace = perf().newTrace('membership_packages_show');
      this.trace.start();
    }

    async stopTrace(status) {
      if (this.trace) {
        this.trace.putAttribute('status', status);
        await this.trace.stop();
        this.trace = null;
      }
    }

    render() {
      const {
        id,
        discountWithMembershipEnabled,
        ableToNavigateToMembership,
        canSubscribe,
        isLoading,
        style,
      } = this.props;
      const { isLoading: isLoadingState, packages } = this.state;

      const getDiscountButtonVisible =
        discountWithMembershipEnabled &&
        ableToNavigateToMembership &&
        canSubscribe;

      return (
        <Component
          id={id}
          packages={packages}
          getDiscountButtonVisible={getDiscountButtonVisible}
          onLinkPress={this.onLinkPress}
          onGetDiscountButtonPress={this.onGetDiscountButtonPressed}
          onPackagePress={this.onPackagePress}
          isLoading={isLoading || isLoadingState}
          style={style}
        />
      );
    }
  }

  return withConfigValue(
    withIdentityId(
      withUserBalance(
        withUserController(
          withMediaLoader(
            withHasTag(
              withHasTag(Controller, {
                tag: USER_TAGS.MEMBERSHIP,
                userIdPropName: 'id',
                hasTagPropName: 'canSubscribe',
              }),
              {
                tag: USER_TAGS.BOOSTED,
                userIdPropName: 'id',
                hasTagPropName: 'isBoosted',
              },
            ),
            {
              title:
                Resources.strings['boost-profile-banner-media-loader-title'],
            },
          ),
        ),
        { userIdPropName: 'id' },
      ),
      'id',
    ),
    {
      discountWithMembershipEnabled:
        'features.discount-with-membership-enabled',
      thumbnailRequiredForBoost: 'features.thumbnail-required-for-boost',
    },
  );
}

export default controller;
