import { Persistence } from '@sdv/domain/app/persistence';

export class PersistentPreferencesSaving {
    savePreference(key, value) {
        const scope = 'stepper-registration.preferences';
        const persistence = Persistence.shared(scope);

        return persistence.store(key, value);
    }
}
