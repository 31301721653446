import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  StatusBar,
  ScrollView,
  View,
  Text,
  TouchableOpacity,
  Image,
} from 'react-native';
import { SafeAreaView } from 'react-navigation';
import Button from 'dating-mobile/src/components/buttons/base';
import LinearGradient from 'react-native-linear-gradient';
import styles from './styles';
import Resources from '../../../resources';
import FilterItem from '../../views/filter-item';
import Options from '../../views/options';
import IfConfigValue from '../../../components/config-value/if';
import { FILTERS } from '../../utils/filters';

class ScreenView extends PureComponent {
  static displayName = 'feed.filter.screen.view';

  static propTypes = {
    params: PropTypes.object,
    mandatoryFilters: PropTypes.array,
    advancedFilters: PropTypes.array,
    emptyPreferredGenderEnabled: PropTypes.bool,
    onItemPress: PropTypes.func.isRequired,
    onFilterEditComplete: PropTypes.func.isRequired,
    allowedMaxAge: PropTypes.number,
    allowedMinAge: PropTypes.number,
    onAddAdvancedFilterButtonPress: PropTypes.func,
    onResetAdvancedFiltersButtonPress: PropTypes.func,
    onResetButtonPress: PropTypes.func,
    onSearchButtonPress: PropTypes.func,
    onRemoveAdvancedFilterValueButtonPress: PropTypes.func,
  };

  static defaultProps = {
    params: {},
    advancedFilters: [],
    mandatoryFilters: [],
  };

  constructor(props) {
    super(props);
    this.state = { dropdownOpened: false };
  }

  componentWillUnmount() {
    const { onSearchButtonPress } = this.props;

    if (!styles.$searchButtonEnabled && onSearchButtonPress) {
      onSearchButtonPress();
    }
  }

  onOptionPressed = (filter, value) => {
    const { onItemPress } = this.props;
    let title;

    switch (filter) {
      case FILTERS.GENDER:
        title = Resources.strings['filter-screen-preferred-gender-item-title'];
        break;
      case FILTERS.AGE:
        title = Resources.strings['filter-screen-age-range-item-title'];
        break;
      case FILTERS.LOCATION:
        title = Resources.strings['filter-screen-location-item-title'];
        break;
      case FILTERS.EDUCATION:
        title = Resources.strings['filter-screen-education-item-title'];
        break;
      case FILTERS.LANGUAGE:
        title = Resources.strings['filter-screen-language-item-title'];
        break;
      case FILTERS.RELATIONSHIP:
        title = Resources.strings['filter-screen-relationship-item-title'];
        break;
      case FILTERS.KIDS:
        title = Resources.strings['filter-screen-kids-item-title'];
        break;
      case FILTERS.SMOKE:
        title = Resources.strings['filter-screen-smoke-item-title'];
        break;
      case FILTERS.DRINK:
        title = Resources.strings['filter-screen-drink-item-title'];
        break;
      case FILTERS.HEIGHT:
        title = Resources.strings['filter-screen-height-item-title'];
        break;
      case FILTERS.EYES:
        title = Resources.strings['filter-screen-eyes-item-title'];
        break;
      case FILTERS.HAIR:
        title = Resources.strings['filter-screen-hair-item-title'];
        break;
      case FILTERS.INTERESTS:
        title = Resources.strings['filter-screen-interests-item-title'];
        break;
      case FILTERS.TRAVEL_DESTINATION:
        title =
          Resources.strings['filter-screen-travel-destination-item-title'];
        break;
      case FILTERS.BODY_TYPE:
        title = Resources.strings['filter-screen-body-type-item-title'];
        break;
    }

    if (onItemPress) {
      onItemPress(filter, title, value);
    }
  };

  onDropdownButtonPressed = () => {
    const { dropdownOpened } = this.state;

    this.setState({ dropdownOpened: !dropdownOpened });
  };

  getEmptyFilterValue(filter) {
    const { emptyPreferredGenderEnabled, params } = this.props;

    if (
      filter === FILTERS.GENDER &&
      !params[filter] &&
      emptyPreferredGenderEnabled
    ) {
      return Resources.strings['preferred-gender-empty-value-name'];
    }

    return undefined;
  }

  render() {
    const {
      allowedMaxAge,
      allowedMinAge,
      onSearchButtonPress,
      onItemPress,
      onFilterEditComplete,
      mandatoryFilters,
      onAddAdvancedFilterButtonPress,
      onResetAdvancedFiltersButtonPress,
      onRemoveAdvancedFilterValueButtonPress,
      onResetButtonPress,
      params,
      advancedFilters,
    } = this.props;

    const advancedParams = advancedFilters.reduce(
      (result, filter) => ({
        ...result,
        [filter]: params[filter],
      }),
      {},
    );

    const { dropdownOpened } = this.state;

    return (
      <SafeAreaView
        forceInset={{ top: 'never', bottom: 'always' }}
        style={styles.container}
      >
        <StatusBar
          barStyle={styles.$statusBarStyle}
          backgroundColor={styles.$statusBarBackgroundColor}
        />

        <ScrollView
          style={styles.scrollView}
          contentContainerStyle={styles.content}
        >
          {mandatoryFilters.map(filter => (
            <FilterItem
              key={filter}
              filter={filter}
              value={params[filter]}
              allowedMinAge={allowedMinAge}
              allowedMaxAge={allowedMaxAge}
              onFilterEditComplete={({ min, max }) =>
                onFilterEditComplete(filter, { min: +min, max: +max })
              }
              onPress={onItemPress}
              emptyValue={this.getEmptyFilterValue(filter)}
            />
          ))}
          <IfConfigValue
            path="markup.filter-screen.advanced-filters-presentation"
            equalsTo="screen-dropdown"
          >
            <View style={styles.advancedParametersSection}>
              <TouchableOpacity
                style={styles.advancedParametersButtonContainer}
                onPress={this.onDropdownButtonPressed}
              >
                <Text style={styles.advancedParametersButtonText}>
                  {
                    Resources.strings[
                      'filter-screen-advanced-parameters-button-text'
                    ]
                  }
                </Text>
                <Image
                  source={
                    dropdownOpened
                      ? Resources.images.arrowUpIcon()
                      : Resources.images.arrowDownIcon()
                  }
                  style={styles.arrowImage}
                />
              </TouchableOpacity>
              {!!dropdownOpened &&
                advancedFilters.map(filter => (
                  <FilterItem
                    key={filter}
                    filter={filter}
                    value={params[filter]}
                    onPress={onItemPress}
                  />
                ))}
              {!!dropdownOpened && styles.$searchButtonEnabled && (
                <View style={styles.bottomSpacing} />
              )}
            </View>
          </IfConfigValue>
          <IfConfigValue
            path="markup.filter-screen.advanced-filters-presentation"
            equalsTo="screen"
          >
            <View style={styles.advancedParametersSection}>
              <Text style={styles.advancedParametersSectionHeader}>
                {
                  Resources.strings[
                    'filter-screen-advanced-parameters-section-header'
                  ]
                }
              </Text>
              {advancedFilters.map(filter => (
                <FilterItem
                  key={filter}
                  filter={filter}
                  value={params[filter]}
                  onPress={onItemPress}
                />
              ))}
              {styles.$searchButtonEnabled && (
                <View style={styles.bottomSpacing} />
              )}
            </View>
          </IfConfigValue>
          <IfConfigValue
            path="markup.filter-screen.advanced-filters-presentation"
            equalsTo="modal"
          >
            <Options
              params={advancedParams}
              onAddPress={onAddAdvancedFilterButtonPress}
              onResetPress={onResetAdvancedFiltersButtonPress}
              onOptionPress={this.onOptionPressed}
              onDeleteOptionPress={onRemoveAdvancedFilterValueButtonPress}
            />
          </IfConfigValue>
          <IfConfigValue
            path="markup.filter-screen.bottom-reset-all-parameters-button"
            equalsTo={true}
          >
            <TouchableOpacity
              style={styles.bottomResetButton}
              onPress={onResetButtonPress}
            >
              <Text style={styles.bottomResetButtonTitle}>
                {
                  Resources.strings[
                    'filter-screen-reset-all-parameters-button-title'
                  ]
                }
              </Text>
            </TouchableOpacity>
          </IfConfigValue>
        </ScrollView>
        <LinearGradient
          pointerEvents="none"
          colors={styles.$bottomContainerGradientColors}
          style={styles.bottomContainer}
        />
        {styles.$searchButtonEnabled && (
          <Button
            onPress={onSearchButtonPress}
            style={styles.searchButton}
            titleStyle={styles.searchTitle}
            title={Resources.strings['filter-screen-search-button-title']}
          />
        )}
      </SafeAreaView>
    );
  }
}

export default ScreenView;
