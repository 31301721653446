import React from 'react';
import PropTypes from 'prop-types';
import { Image, Platform } from 'react-native';

export default function withAutoScale(Component) {
  function ScaledImage({ source, style, ...props }) {
    return (
      <Component
        {...props}
        source={source}
        style={[style, { width: source.width, height: source.height }]}
      />
    );
  }

  ScaledImage.propTypes = {
    source: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    style: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
  };

  ScaledImage.defaultProps = {
    style: {},
  };

  return Platform.OS === 'android' || Platform.OS === 'ios'
    ? Image
    : ScaledImage;
}
