const GOOGLE_PLACE_TYPES = {
        CITY: ['locality', 'administrative_area_level_3'],
        COUNTRY: ['country'],
        STATE: ['administrative_area_level_1']
    },
    PLACE_TYPES = {
        CITY: 0,
        COUNTRY: 1,
        OTHER: 2
    };

const UNUSUAL = [
    {
        name: 'Tokyo',
        place: {
            city: 'Tokyo',
            state: '',
            type: PLACE_TYPES.CITY
        }
    },
    {
        name: 'Cologne',
        place: {
            city: 'Cologne',
            state: '',
            type: PLACE_TYPES.CITY
        }
    }
];

UNUSUAL.makeField = function (fieldValue) {
    
    return function () {
        return fieldValue;
    };

};

class Place {

    constructor(place) {
        this.place = place;

        const longName = place.address_components[0].long_name;

        let unusualPlace = UNUSUAL.filter(function (x) { return x.name === longName; });
        unusualPlace = unusualPlace.length ? unusualPlace[0] : null;

        if (unusualPlace) {

            for (var field in unusualPlace.place) {
                
                if (unusualPlace.place.hasOwnProperty(field)) {
                    this[field] = UNUSUAL.makeField(unusualPlace.place[field]);
                }

            }

        }

    }

    name() {

        return [
            this.city('long') || '$',
            this.state() && this.state('long') !== this.city('long') ? this.state('long') : '$',
            this.type() === PLACE_TYPES.COUNTRY ? this.country('long') : '$'
        ].join(', ').replace(/\$, /g, '')
            .replace(/, \$$/g, '');

    }

    type() {
        const placeType = this.place.address_components[0].types[0];

        if (GOOGLE_PLACE_TYPES.CITY.indexOf(placeType) >= 0) {
            return PLACE_TYPES.CITY;
        }

        if (GOOGLE_PLACE_TYPES.COUNTRY.indexOf(placeType) >= 0) {
            return PLACE_TYPES.COUNTRY;
        }

        return PLACE_TYPES.OTHER;
    }

    components(type, len) {
        let res;

        !len && (len = 'short');

        res = this.place.address_components.filter(function (component) {
            return component.types.indexOf(type) !== -1;
        })[0];

        return res ? res[`${len}_name`] : null;
    }

    country(len) {
        return this.components(GOOGLE_PLACE_TYPES.COUNTRY[0], len || 'short');
    }

    state(len) {
        return this.components(GOOGLE_PLACE_TYPES.STATE[0], len || 'short');
    }

    city(len) {
        len = len || 'long';

        return GOOGLE_PLACE_TYPES.CITY.reduce((city, type) => {
            return city || this.components(type, len);
        }, '');

    }

    lon() {
        return this.place.geometry.location.lng;
    }

    lat() {
        return this.place.geometry.location.lat;
    }

    place_id() {
        return this.place.place_id;
    }

}

export default Place;

function getPlaceFromGeocodeData(data) {
    const FIELDS = ['place_id', 'name', 'type', 'city', 'state', 'country', 'lon', 'lat'];

    if (!data || !data.geometry) {
        return {};
    }

    var place = new Place(data),
        result = {};

    FIELDS.forEach(function (field) {
        result[field] = place[field]();
    });

    return result;
}

export { getPlaceFromGeocodeData };
