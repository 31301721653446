import UnreadModel from './unread';
import ReadAndUnansweredModel from './read-unanswered';
import AllIntroductoryModel from './all-introductory';
import UnreadIntroductoryModel from './unread-introductory';
import OneAttendeeModel, { buildId } from './one-attendee';

export const InboxFilter = Object.freeze({
    allIntroductory: 1,
    unreadIntroductory: 2,
    unread: 3,
    readAndUnanswered: 4,
    oneAttendee: 5
});

export default function (filter) {
    switch (filter) {
        case InboxFilter.allIntroductory:
            return AllIntroductoryModel;
        case InboxFilter.readAndUnanswered:
            return ReadAndUnansweredModel;
        case InboxFilter.unread:
            return UnreadModel;
        case InboxFilter.unreadIntroductory:
            return UnreadIntroductoryModel;
        case InboxFilter.oneAttendee:
            return OneAttendeeModel;
        default:
            return undefined;
    }
}

export function createIdForFilter(filter, userId, attendeId) {
    if (filter === InboxFilter.oneAttendee) {
        return buildId(userId, attendeId);
    } else {
        return userId;
    }
}
