import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { View, Image, Text } from 'react-native';
import WithGradient from 'dating-mobile/src/components/with-gradient';
import styles from './styles';

export default class FeatureItem extends PureComponent {
    static propTypes = {
        icon: PropTypes.string,
        label: PropTypes.string,
    };

    render() {
        const { icon, label } = this.props;

        return (
            <View style={styles.container}>
                <Image style={styles.image} source={icon} />

                <Text style={styles.label}>{label}</Text>
            </View>
        );
    }
}
