import React from 'react';
import PropTypes from 'prop-types';
import withConfigValue from './index';
import equal from 'fast-deep-equal';

class If extends React.Component {
    static displayName = 'config.value.if';
    static propTypes = {
        path: PropTypes.string,
        value: PropTypes.any,
        equalsTo: PropTypes.any,
        contains: PropTypes.any
    };

    render() {
        const { children } = this.props;

        let status;
        if (typeof this.props.equalsTo !== 'undefined') {
            status = equal(this.props.value, this.props.equalsTo)
        } else if (typeof this.props.contains !== 'undefined') {
            status = Array.isArray(this.props.value) && this.props.value.findIndex(item => equal(item, this.props.contains)) >= 0;
        }

        if (typeof children === 'function') {
            return children(status)
        } else if (status) {
            return children;
        } else {
            return null;
        }
    }
}

export default withConfigValue(If);
