import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $statusBarStyle: '$primaryStatusBarStyle',
    $statusBarBackgroundColor: '$primaryBackgroundColor',

    $followButtonVisible: true,
    '@flavor lovinga': {
        $followButtonVisible: false,
    },

    $reportButtonVisible: false,
    '@flavor lovinga, tubit, secretly': {
        $reportButtonVisible: true,
    },

    header: {
        backgroundColor: '$primaryBackgroundColor',
        borderBottomColor: '$primaryBackgroundColor',
        shadowColor: '$primaryBackgroundColor',
        shadowRadius: 0,
        shadowOpacity: 0,
        elevation: 0,
        shadowOffset: {
            height: 0,
        },
    },

    headerTitle: {
        fontFamily: '$brandFontFamily',
        color: '$primaryTextColor',
    },

    headerLeftContainer: {
        flex: 1,
        flexShrink: 1,
        position: 'relative',
    },

    headerRightContainer: {
        position: 'relative',
    },

    container: {
        flex: 1,
        flexDirection: 'column',
        backgroundColor: '$primaryBackgroundColor',
    },

    streamingCtaContainer: {
        width: '100%',
        marginVertical: 8,
        paddingHorizontal: 16,
    },

    log: {
        flex: 1,
    },

    form: {
        backgroundColor: '$primaryBackgroundColor',
    },

    input: {},

    safeArea: {
        width: '100%',
        backgroundColor: '$primaryBackgroundColor',
    },

    chatContainer: {
        width: '100%',
        height: 120,
        borderBottomWidth: 1,
        borderBottomColor: '$brandBorderColor',
    },

    chatTitle: {
        flex: 1,
        flexShrink: 1,
        marginHorizontal: 8,
    },

    chatGalleryFooter: {
        width: 16,
    },

    videoCallIcon: {
        tintColor: '$acceptColor',
    },

    videoCallButton: {
        backgroundColor: 'transparent',
    },
});

export default styles;
