import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'flex-start',
        backgroundColor: '$primaryBackgroundColor'
    },
    content: {
        flexGrow: 1,
        flexShrink: 0,
    }
});

export default styles;
