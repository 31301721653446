import React from 'react';
import PropTypes from 'prop-types';
import connect from '@sdv/connect';
import single from '../../../utils/single-execute';

import Model from '../model';

const GET_PROPERTY = 'generated.user.controller.get';
const PATCH_PROPERTY = 'generated.user.controller.patch';

export default function createController(Component, options = {}) {

    const userIdPropName = options.userIdPropName || 'id';
    const resultPropName = options.resultPropName || 'user';

    class Controller extends React.Component {
        static displayName = 'user.controller';
        static propTypes = {
            [userIdPropName]: PropTypes.string,
            [resultPropName]: PropTypes.object,
            [GET_PROPERTY]: PropTypes.func,
            [PATCH_PROPERTY]: PropTypes.func
        };
        static contextTypes = {
            flux: PropTypes.object
        };

        componentDidMount() {
            if (this.props[GET_PROPERTY]) {
                this.loadData();
            }
        }

        componentDidUpdate(prevProps) {
            if (shouldReconnect(prevProps, this.props)) {
                if (this.props[GET_PROPERTY]) {
                    this.loadData();
                }
            }
        }

        loadData = () => {
            const tag = `${Controller.displayName}.${this.props[userIdPropName]}.get`;
            single(tag, this.props[GET_PROPERTY]);
        };

        patch = (payload) => {
            const user = Object.keys(payload).reduce((user, key) => {
                if (payload[key] !== null) {
                    user[key] = payload[key];
                }
                return user;
            }, {});

            return new Promise((resolve, reject) => {

                if (this.props[PATCH_PROPERTY]) {
                    this.props[PATCH_PROPERTY](user, (error) => {
                        if (error) {
                            return reject(new Error(`Error occurred during update user data ${Object.keys(user)}`));
                        }

                        return resolve();
                    });
                } else {
                    reject('User id is not defined');
                }

            })
        };

        addTrip = (trip) => {
            const trips = (this.props[resultPropName] && this.props[resultPropName].meta && this.props[resultPropName].meta.travels) || [];
            const filteredTrips = trips.filter(item => trip.country !== item.country || trip.city !== item.city);
            filteredTrips.push(trip);
            this.patch({
                meta: {
                    travels: filteredTrips
                }
            });
        };

        deleteTrip = (trip) => {
            const trips = (this.props[resultPropName] && this.props[resultPropName].meta && this.props[resultPropName].meta.travels) || [];
            const filteredTrips = trips.filter(item => trip.country !== item.country || trip.city !== item.city);
            this.patch({
                meta: {
                    travels: filteredTrips
                }
            });
        };

        render() {
            const { ...props } = this.props;

            delete props[GET_PROPERTY];
            delete props[PATCH_PROPERTY];

            return (
                <Component
                    {...props}
                    updateUser={this.patch}
                    addTrip={this.addTrip}
                    deleteTrip={this.deleteTrip}
                />
            );
        }

    }

    function getModels(flux, props) {
        const models = {};

        if (props[userIdPropName]) {
            models.userModel = flux.get(Model, props[userIdPropName]);
        }

        return models;
    }

    function mapModelsToProps(models) {
        return {
            [resultPropName]: models.userModel
                ? models.userModel.store.getState()
                : {}
        };
    }

    function mapModelsToActions(models) {
        const props = {};

        if (models.userModel) {
            props[GET_PROPERTY] = models.userModel.actions.get;
            props[PATCH_PROPERTY] = models.userModel.actions.patch;
        }

        return props;
    }

    function shouldReconnect(props, newProps) {
        return props[userIdPropName] !== newProps[userIdPropName];
    }

    return connect(
        getModels,
        mapModelsToProps,
        mapModelsToActions,
        shouldReconnect
    )(Controller);

};
