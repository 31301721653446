import React from 'react';
import PropTypes from 'prop-types';
import { StatusBar, ScrollView } from 'react-native';
import { SafeAreaView } from 'react-navigation';
import styles from './styles';
import FilterItem from '../../views/filter-item';

class ScreenView extends React.PureComponent {
    static displayName = 'feed.advanced-filters.screen.view';
    static propTypes = {
        id: PropTypes.string,
        params: PropTypes.object,
        advancedFilters: PropTypes.array,
        onItemPress: PropTypes.func.isRequired
    };

    render() {
        const params = this.props.params || {};

        return (
            <SafeAreaView forceInset={{ top: 'never', bottom: 'always' }} style={styles.container}>
                <StatusBar
                    barStyle={styles.$statusBarStyle}
                    backgroundColor={styles.$statusBarBackgroundColor}
                />

                <ScrollView style={styles.scrollView} contentContainerStyle={styles.content}>
                    {
                        (this.props.advancedFilters || [])
                            .map(filter => (
                                <FilterItem
                                    key={filter}
                                    filter={filter}
                                    value={params[filter]}
                                    onPress={this.props.onItemPress}
                                />
                            ))
                    }
                </ScrollView>

            </SafeAreaView>
        );
    }
}

export default ScreenView;
