
const intervals = {
    second: 1000
};

intervals.minute = intervals.second * 60;
intervals.hour = intervals.minute * 60;
intervals.day = intervals.hour * 24;
intervals.week = intervals.day * 7;

export const TimeInterval = Object.freeze(intervals);
