import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withConfigValue from 'dating-mobile/src/components/config-value';
import Resources from 'dating-mobile/src/resources';

function controller(Component) {
    class Controller extends PureComponent {
        static displayName = 'payment.views.subscription-agreement.controller';

        static propTypes = {
            openLink: PropTypes.func,
            privacyPolicyLink: PropTypes.string,
            termsLink: PropTypes.string,
            useLocalizedLinks: PropTypes.bool,
        };

        onTermsLinkPressed = () => {
            const { useLocalizedLinks, termsLink, openLink } = this.props;

            const link =
                (useLocalizedLinks && termsLink && Resources.strings[termsLink]) || termsLink;

            if (openLink && link) {
                openLink(
                    link,
                    Resources.strings['memberships-screen-terms-agreement-terms-link-text'],
                );
            }
        };

        onPolicyLinkPressed = () => {
            const { useLocalizedLinks, privacyPolicyLink, openLink } = this.props;

            const link =
                (useLocalizedLinks && privacyPolicyLink && Resources.strings[privacyPolicyLink]) ||
                privacyPolicyLink;

            if (openLink && link) {
                openLink(
                    link,
                    Resources.strings['memberships-screen-terms-agreement-policy-link-text'],
                );
            }
        };

        render() {
            return (
                <Component
                    {...this.props}
                    onTermsLinkPress={this.onTermsLinkPressed}
                    onPolicyLinkPress={this.onPolicyLinkPressed}
                />
            );
        }
    }

    return Controller;
}

export default Component =>
    withConfigValue(
        withConfigValue(
            withConfigValue(controller(Component), 'links.terms', 'termsLink'),
            'links.privacy-policy',
            'privacyPolicyLink',
        ),
        'links.use-localized-links',
        'useLocalizedLinks',
    );
