import React from 'react';
import PropTypes from 'prop-types';
import {
  FlatList,
  Image,
  Text,
  View,
  I18nManager,
  Platform,
} from 'react-native';
import testId from 'dating-mobile/src/utils/test-id';
import UserThumbnail from 'dating-mobile/src/user/common/views/thumbnail';
import Resources from '../../../../resources';
import styles from './styles';
import MediaAdd from '../../../edit/views/add-media';
import GalleryItem from './item';
import AlbumItem from './album-item';
import withAspectRatio from '../../../../components/aspect-ratio';
import withAutoScale from '../../../../components/auto-scale-image';

const AspectRatioMedia = withAspectRatio(MediaAdd);
const AutoScaleImage = withAutoScale(Image);

// Hack
const inverted = Platform.OS === 'android' && I18nManager.isRTL;

export default class SmallGallery extends React.Component {
  static displayName = 'user.profile.views.photo-gallery';

  static propTypes = {
    id: PropTypes.string.isRequired,
    editingEnabled: PropTypes.bool,
    separateAddPhotoButton: PropTypes.bool,
    rightToLeft: PropTypes.bool,
    HeaderComponent: PropTypes.func,
    FooterComponent: PropTypes.func,
    select: PropTypes.func,
    showPlaceholder: PropTypes.bool,
    media: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.shape({
          basename: PropTypes.string.isRequired,
          tags: PropTypes.tags,
          mediatype: PropTypes.string.isRequired,
        }),
        PropTypes.shape({
          album: PropTypes.number.isRequired,
          counter: PropTypes.number.isRequired,
          thumbnail: PropTypes.object.isRequired,
        }),
      ]),
    ),
    open: PropTypes.func,
    videoUploadingEnabled: PropTypes.bool,
  };

  static defaultProps = {
    rightToLeft: false,
    media: [],
  };

  static extractKey(item) {
    if (typeof item.album !== 'undefined') {
      const basename = item.thumbnail && item.thumbnail.basename;

      return `album-${item.album}-${basename}`;
    }

    return item.basename;
  }

  shouldComponentUpdate(nextProps) {
    const { media } = this.props;

    return media !== nextProps.media;
  }

  getHeaderComponent() {
    const {
      HeaderComponent,
      editingEnabled,
      separateAddPhotoButton,
    } = this.props;

    if (HeaderComponent) {
      return HeaderComponent;
    }

    if (editingEnabled && !separateAddPhotoButton) {
      return this.renderHeader;
    }

    return null;
  }

  renderHeader = () => {
    const { videoUploadingEnabled, id } = this.props;
    const mediaType = videoUploadingEnabled ? 'mixed' : 'photo';

    return (
      <AspectRatioMedia
        id={id}
        mediaType={mediaType}
        style={styles.addPhotoBackground}
      >
        <AutoScaleImage
          source={Resources.images.cameraBigIcon()}
          {...testId(Resources.strings['add-media-button-accessibility-label'])}
        />
      </AspectRatioMedia>
    );
  };

  renderItem = ({ item, index }) => {
    const { media, id, open } = this.props;
    const start = index === 0;
    const end = index === media.length - 1;
    const firstItem = inverted ? end : start;
    const lastItem = inverted ? start : end;

    const style =
      firstItem || lastItem
        ? [
            firstItem ? styles.firstItemContainer : {},
            lastItem ? styles.lastItemContainer : {},
          ]
        : undefined;

    return typeof item.album !== 'undefined' ? (
      <AlbumItem
        key={SmallGallery.extractKey(item)}
        id={id}
        media={item.thumbnail}
        item={item}
        onPress={open}
        style={style}
      />
    ) : (
      <GalleryItem
        key={SmallGallery.extractKey(item)}
        id={id}
        media={item}
        onPress={open}
        style={[style, styles.itemContainer]}
        {...testId(Resources.strings['gallery-item-accessibility-label'])}
      />
    );
  };

  placeholder = () => {
    const { showPlaceholder, id } = this.props;

    return showPlaceholder ? (
      <UserThumbnail
        userId={id}
        style={styles.thumbnailPlaceholder}
        round={false}
      />
    ) : null;
  };

  render() {
    const {
      rightToLeft,
      FooterComponent,
      media,
      separateAddPhotoButton,
      editingEnabled,
      id,
    } = this.props;

    const HeaderComponent = this.getHeaderComponent();
    const header = inverted ? FooterComponent : HeaderComponent;
    const footer = inverted ? HeaderComponent : FooterComponent;

    return (
      <View style={styles.container}>
        <FlatList
          style={styles.collection}
          data={media}
          renderItem={this.renderItem}
          horizontal
          numColumns={1}
          alwaysBounceHorizontal={false}
          keyExtractor={SmallGallery.extractKey}
          ListHeaderComponent={header}
          ListFooterComponent={footer}
          ListEmptyComponent={this.placeholder}
          windowSize={5}
          showsHorizontalScrollIndicator={false}
          inverted={inverted ? !rightToLeft : rightToLeft}
        />
        {!!separateAddPhotoButton && !!editingEnabled && (
          <MediaAdd style={styles.buttonContainer} id={id} mediaType="mixed">
            <Text style={styles.buttonText}>
              {Resources.strings['gallery-view-add-media-button-title']}
            </Text>
          </MediaAdd>
        )}
      </View>
    );
  }
}
