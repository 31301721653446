import React from 'react';

React.Component.prototype.isStateFilled = function () {

    const keys = Object.keys(this.state);

    return keys.length && !keys.every(key => this.state[key] === undefined);

};

React.Component.prototype.resetState = function (callback) {

    this.setState(state => {

        return Object.keys(state).reduce((newState, key) => {
            newState[key] = undefined;
            return newState;
        }, {});

    }, callback);

};
