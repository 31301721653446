
function createPresenceActions(userId) {

    class Actions {

        get = () => (dispatch, flux) => {

            flux.api.presence.get(userId, (error, result) => {

                if (error) {
                    // толерантность к ошибкам presence при загрузке пользователя
                    return dispatch({ online: false });
                }

                return dispatch(result);
            });

        };

        actualize = (presence) => (dispatch, flux) => {

            dispatch(presence);

        };

    }

    Actions.displayName = createPresenceActions.getDisplayName(userId);

    return Actions;
}

createPresenceActions.getDisplayName = function(id) {
    return `user-presence.${id}`;
};

export default createPresenceActions;
