import { Ability, AbilityBuilder } from '@casl/ability';
import flux from '@sdv/domain/app/flux';
import ConfigModel from 'dating-mobile/src/models/config/model';

function userPermissions(user) {
    const { can: allow, cannot: forbid, rules } = AbilityBuilder.extract();

    if ((user.roles || []).includes('demo')) {
        forbid('update', 'profile').because('You are not authorized');
        forbid('create', 'purchase').because('You are not authorized');

        const demoUserAbilities = flux.get(ConfigModel).store.getState().features['demo-user-abilities'];
        if (demoUserAbilities.indexOf('chat') >= 0) {
            allow('create', 'message');
        } else {
            forbid('create', 'message').because('You are not authorized');
        }

        forbid('start', 'stream').because('You are not authorized');
        forbid('use', 'advanced search').because('You are not authorized');
        forbid('start', 'mingle').because('You are not authorized');
        forbid('restore', 'purchase').because('You are not authorized');
    } else {
        allow('update', 'profile');
        allow('create', 'purchase');
        allow('create', 'message');
        allow('start', 'stream');
        allow('use', 'advanced search');
        allow('start', 'mingle');
        allow('restore', 'purchase');
    }

    return new Ability(rules);
}

export default userPermissions;
