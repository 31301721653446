import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        flexDirection: 'column',
        alignItems: 'center',
        height: 160
    },
    image: {
        position: 'absolute'
    },
    title: {
        marginLeft: 21,
        marginRight: 21,
        marginTop: 21,
        fontSize: 15,
        fontWeight: 'bold',
        fontFamily: '$brandFontFamily',
        textAlign: 'center'
    },
    writeLetterButton: {
        width: '100%',
        paddingLeft: 31,
        paddingRight: 31,
        paddingTop: 16
    }
});

export default styles;
