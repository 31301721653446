import React from 'react';
import { render } from 'dating-mobile/src/components/rx-render';
import { LocalizedFaqRepository } from '@sdv/domain/faq/localized-faq-repository';
import Resources from 'dating-mobile/src/resources';

export const customerSupportId = 'customer-support';

export default function createController(Component) {
    const Categories = render((props, { useObservable }) => {
        const topicsPreview = useObservable(
            it => it.getTopicsPreview(),
            LocalizedFaqRepository.shared(),
        );

        if (!topicsPreview) {
            return null;
        }

        return (
            <Component
                {...props}
                topics={[
                    ...topicsPreview,
                    {
                        id: customerSupportId,
                        localizedTitle: Resources.strings['customer-support-navigation-item-title'],
                        hideArrow: true,
                    },
                ]}
            />
        );
    });

    Categories.displayName = 'faq.screens.faq-categories.controller';

    return Categories;
}
