import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

  balance: {
    fontSize: 14,
    color: '#c5c5c5',
    fontFamily: '$brandFontFamily',
  },

  coin: {
    marginLeft: 4,
    width: 20,
    height: 20,
  },
});

export default styles;
