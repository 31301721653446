import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-native';
import { tap } from 'rxjs/operators';
import { Stock } from '@sdv/domain/payment/stock';
import { PAYMENT_REASON } from '@sdv/domain/app/balance-refiller';
import { render } from 'dating-mobile/src/components/rx-render';
import { Purchase } from 'dating-mobile/src/payment/methods/purchase';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';
import Resources from 'dating-mobile/src/resources';
import flux from '@sdv/domain/app/flux';
import UserTagsModel from '@sdv/domain/user/tags/personal';

const getTopBoostPackage = (packages = []) =>
  packages.find(p => p?.[0]?.isTopBoost)?.[1]?.[0];

export default function createController(Component) {
  const Controller = render(
    ({ userId, onClose }, { useObservable, useObservableAction }) => {
      const packages = userId
        ? useObservable(it => it.preloadedPackagesInStock, Stock.shared(userId))
        : undefined;

      const purchase = Purchase.shared();

      const isLoading = useObservable(it => it.isLoading, purchase) || false;

      const invoice = getTopBoostPackage(packages);

      const purchasePackage = useObservableAction(
        () =>
          purchase.pay(userId, invoice, PAYMENT_REASON.SPECIAL_OFFER).pipe(
            tap(({ success }) => {
              if (success) {
                flux.get(UserTagsModel, userId)?.actions?.get();
                Alert.alert(
                  Resources.strings['purchase-boost-success-message'],
                  null,
                  [
                    {
                      text: Resources.strings.ok,
                      style: 'cancel',
                      onPress: onClose,
                      onDismiss: onClose,
                    },
                  ],
                );
              }
            }),
          ),
        purchase,
        userId,
        invoice,
      );

      return (
        <Component
          invoice={invoice}
          isLoading={isLoading}
          onPress={purchasePackage}
        />
      );
    },
  );

  Controller.displayName = 'payment.screens.special-offer.controller';

  Controller.propTypes = {
    userId: PropTypes.string,
    onClose: PropTypes.func,
  };

  return withIdentityId(Controller);
}
