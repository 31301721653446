import Actions from './actions';

function createTagsStore(userId) {
  class Store {
    /**
     *
     * @type {{
     *     tags: array<string>
     * }}
     */
    state = {};

    constructor(flux) {
      const actions = flux.getActions(Actions, userId);

      this.bindAction(actions.get, this.update);
      this.bindAction(actions.actualize, this.actualize);
      this.bindAction(actions.put, this.add);
      this.bindAction(actions.delete, this.delete);
    }

    add(tag) {
      if (!tag) {
        return;
      }

      const tags = this.state.tags?.slice() || [];

      if (tags.indexOf(tag) < 0) {
        tags.push(tag);
      }

      this.setState({ tags });
    }

    delete(tag) {
      if (!tag) {
        return;
      }

      const tags = this.state.tags?.slice() || [];
      const index = tags.indexOf(tag);

      if (index >= 0) {
        tags.splice(index, 1);
      }

      this.setState({ tags });
    }

    update(tags) {
      if (!tags) {
        return;
      }

      this.setState({
        tags,
      });
    }

    actualize(payload) {
      if (!payload || !this.state.tags) {
        return;
      }

      const tags = this.state.tags.filter(
        tag => payload.staled.indexOf(tag) < 0,
      );

      tags.push(...payload.new);

      this.setState({
        tags,
      });
    }
  }

  Store.displayName = createTagsStore.getDisplayName(userId);

  return Store;
}

createTagsStore.getDisplayName = function(id) {
  return `user-tags.${id}`;
};

export default createTagsStore;
