import { merge, Subscription } from 'rxjs';
import {
  filter,
  switchMap,
  take,
  scan,
  mapTo,
  withLatestFrom,
  map,
} from 'rxjs/operators';
import { analytics as createFirebaseTracker } from 'react-native-firebase';
import AppsFlyerTracker from 'dating-mobile/src/services/tracking/apps-flyer-tracker';
import { STEPPED_REGISTRATION_STEP } from 'dating-mobile/src/authentication/utils/registration-steps';
import { ROUTES_MAPPING } from 'dating-mobile/src/authentication/utils/stepped-registration-route';
import AppConfig from 'dating-mobile/src/app/config';
import flux from '@sdv/domain/app/flux';
import UserModel from '@sdv/domain/user/model';
import Session from '@sdv/domain/authorization/session';
import { Platform } from 'react-native';
import {
  mediaTakenFromGallery,
  mediaTakenFromCamera,
} from 'dating-mobile/src/components/image-picker';
import { Permissions } from '@sdv/domain/app/permissions';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';
import { CurrentScreen } from 'dating-mobile/src/services/functional/current-screen';

import Service from '../service';

const steppedRegistrationEnabled = AppConfig.shared()
  .paramWithPath('features.stepped-registration-enabled')
  .pipe(filter(Boolean));

export const EVENTS = {
  thumbnailPicked: 'onboarding_thumbnail_picked',
  geolocationEnabled: 'onboarding_geolocation_enabled',
  notificationsEnabled: 'onboarding_notifications_enabled',
  secondStepStarted: 'onboarding_second_step_started',
};

export const THUMBNAIL_SOURCE = {
  gallery: 'gallery',
  camera: 'camera',
};

class Tracking extends Service {
  static displayName = 'service.tracking.onboarding';

  componentDidMount() {
    this.subscribe();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  subscribe() {
    const secondStepStartedSubscription = this.secondStepStarted().subscribe(
      () => {
        this.trackEvent(EVENTS.secondStepStarted);
      },
      () => {},
    );

    const thumbnailPickedSubscription = this.thumbnailPicked().subscribe(
      source => {
        this.trackEvent(EVENTS.thumbnailPicked, { source });
      },
      () => {},
    );

    const geolocationEnabledSubscription = this.geolocationEnabled().subscribe(
      enabled => {
        this.trackEvent(EVENTS.geolocationEnabled, { enabled: !!enabled });
      },
      () => {},
    );

    this.disposeBag = new Subscription();
    this.disposeBag.add(secondStepStartedSubscription);
    this.disposeBag.add(thumbnailPickedSubscription);
    this.disposeBag.add(geolocationEnabledSubscription);
    if (Platform.OS === 'ios') {
      const notificationsEnabledSubscription = this.notificationsEnabled().subscribe(
        enabled => {
          this.trackEvent(EVENTS.notificationsEnabled, { enabled: !!enabled });
        },
        () => {},
      );

      this.disposeBag.add(notificationsEnabledSubscription);
    }
  }

  unsubscribe() {
    if (this.disposeBag) {
      this.disposeBag.unsubscribe();
    }
  }

  secondStepStarted() {
    const userGoalsRoute = ROUTES_MAPPING[STEPPED_REGISTRATION_STEP.USER_GOALS];

    return steppedRegistrationEnabled.pipe(
      switchMap(() => CurrentScreen.shared().stack),
      filter(stack => stack.includes(userGoalsRoute)),
      take(1),
    );
  }

  thumbnailPicked() {
    return steppedRegistrationEnabled.pipe(
      switchMap(() =>
        Session.shared().userId.pipe(
          filter(Boolean),
          switchMap(userId =>
            flux
              .get(UserModel, userId)
              .store.rxState()
              .pipe(
                scan(([, prev], curr) => [prev, curr], []),
                filter(([prev, curr]) => {
                  const prevThumbnail =
                    prev && (prev['thumbnail-pending'] || prev.thumbnail);
                  const currThumbnail =
                    curr && (curr['thumbnail-pending'] || curr.thumbnail);

                  return currThumbnail !== prevThumbnail;
                }),
              ),
          ),
        ),
      ),
      withLatestFrom(
        merge(
          mediaTakenFromGallery.pipe(mapTo(THUMBNAIL_SOURCE.gallery)),
          mediaTakenFromCamera.pipe(mapTo(THUMBNAIL_SOURCE.camera)),
        ),
      ),
      map(([, source]) => source),
      take(1),
    );
  }

  geolocationEnabled() {
    const nextRoute =
      Platform.OS === 'ios'
        ? ROUTES_MAPPING[STEPPED_REGISTRATION_STEP.NOTIFICATIONS]
        : ROUTES_MAPPING[STEPPED_REGISTRATION_STEP.START_SETTING_PREFERENCES];

    return steppedRegistrationEnabled.pipe(
      switchMap(() => CurrentScreen.shared().stack),
      filter(stack => stack.includes(nextRoute)),
      switchMap(() => Permissions.shared().hasLocationPermission()),
      take(1),
    );
  }

  notificationsEnabled() {
    const nextRoute =
      ROUTES_MAPPING[STEPPED_REGISTRATION_STEP.START_SETTING_PREFERENCES];

    return steppedRegistrationEnabled.pipe(
      switchMap(() => CurrentScreen.shared().stack),
      filter(stack => stack.includes(nextRoute)),
      switchMap(() => Permissions.shared().hasNotificationsPermission()),
      take(1),
    );
  }

  trackEvent(event, values = {}) {
    const { id } = this.props;

    if (id) {
      createFirebaseTracker().logEvent(event, values);
      AppsFlyerTracker.trackEvent(
        event,
        values,
        () => {},
        () => {},
      );
      flux.api.annals.add(id, event, values);
    }
  }
}

export const OnboardingTrackingService = withIdentityId(Tracking, 'id');
