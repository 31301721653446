import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    bubbleContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: 10,
        '@flavor tubit': {
            marginTop: 4,
        },
    },
    bubble: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        borderRadius: 15,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
        alignItems: 'center',
    },

    text: {
        fontSize: 14,
        color: 'white',
        fontFamily: '$brandFontFamily',
    },

    info: {
        fontSize: 14,
        fontWeight: 'bold',
        color: 'white',
        fontFamily: '$brandFontFamily',
        '@flavor lovinga': {
            fontWeight: 'normal'
        }
    },

    deleted: {
        fontSize: 14,
        color: '$infoTextColor',
        fontFamily: '$brandFontFamily',
    },

    star: {
        height: 10,
        width: 10,
    },
    userName: {
        borderColor: 'white',
        borderWidth: 1,
        color: '$accentColor',
        fontFamily: '$brandFontFamily',
        fontWeight: 'bold',
        fontSize: 14,
        lineHeight: 16,
        '@flavor lovinga': {
            fontWeight: 'normal'
        }
    },

    name: {
        color: '#fff',
        fontSize: 19,
        fontFamily: '$brandFontFamily',
        fontWeight: '700',
        '@flavor lovinga': {
            fontSize: 17
        }
    },

    cheer: {
        width: 40,
        height: 40,
    },
    cheerContainer: {
        paddingLeft: 5,
        width: 65,
    },
    favorite: {
        color: 'white',
        fontFamily: '$brandFontFamily',
        fontWeight: 'bold',
        fontSize: 14,
    }
});

export default styles;
