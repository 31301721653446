import React from 'react';
import PropTypes from 'prop-types';
import { Image, Text, TouchableWithoutFeedback, View } from 'react-native';
import Resources from '../../../resources';
import styles from './styles';

export default class MingleEndContent extends React.Component {
    static displayName = 'notifications.popup.mingle-end';
    static propTypes = {
        notification: PropTypes.object,
        openMingleResultsAndDismiss: PropTypes.func.isRequired,
        onReadyForDisplay: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.props.onReadyForDisplay();
    }

    openMingleResults = () => {
        this.props.openMingleResultsAndDismiss();
    };

    render() {
        return (
            <TouchableWithoutFeedback onPress={this.openMingleResults}>
                <View style={styles.container}>
                    <View style={styles.iconContainer}>
                        <Image style={styles.icon} source={Resources.images.letsMingleIcon()} />
                    </View>
                    <View style={styles.textContainer}>
                        <Text style={styles.text}>{Resources.strings['mingle-end-notification-text']}</Text>
                    </View>
                </View>
            </TouchableWithoutFeedback>
        );

    }
};
