import { Platform } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  description: {
    fontSize: 12,
    fontFamily: '$brandFontFamily',
    textAlign: 'center',
    color: '$disabledColor',
    '@media android': {
      fontSize: 11,
    },
    '@flavor ytm': {
      color: '$foregroundColor',
      fontSize: 10,
      opacity: 0.75,
    },
    '@flavor dma': {
      fontSize: 10,
      color: '$infoTextColor',
    },
    ...Platform.select({ web: { whiteSpace: 'normal' } }),
  },

  link: {
    color: '$brandLinkTextColor',
    '@flavor ytm': {
      color: '$foregroundColor',
      textDecorationLine: 'underline',
    },
    '@flavor dma': {
      color: '$infoTextColor',
      textDecorationLine: 'underline',
    },
  },
});

export default styles;
