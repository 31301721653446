import EStyleSheet from 'react-native-extended-stylesheet';
import { Platform } from 'react-native';

const styles = EStyleSheet.create({
    $controlsColor: '$accentColor',
    $statusBarStyle: '$primaryStatusBarStyle',
    $statusBarBackgroundColor: '$primaryBackgroundColor',

    container: {
        position: 'absolute',
        top: 0,
        left: 0,
        overflow: 'hidden',
        width: Platform.select({
            'ios': 0,
            'android': '100%',
            'web': '100%',
        }),
        height: Platform.select({
            'ios': 0,
            'android': '100%',
            'web': '100%',
        })
    },

    video: {
        width: '100%',
        height: '100%'
    }
});

export default styles;