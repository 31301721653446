import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as ROUTES from 'dating-mobile/src/routing/router/constants';
import { CreditCardPayment } from '@sdv/domain/payment/credit-card/credit-card-payment';
import flux from '@sdv/domain/app/flux';

function controller(Component) {
  function ProcessingController(props) {
    const { navigation } = props;
    const isFocused = navigation.isFocused();
    const auth = flux.api.authorize();

    useEffect(() => {
      const paymentInfo = window.localStorage.getItem('payment');

      if (paymentInfo && auth && isFocused) {
        const info = JSON.parse(paymentInfo);

        window.localStorage.removeItem('payment');
        new CreditCardPayment(info).purchase().subscribe(onFinish, onFinish);
      } else {
        navigation.navigate(ROUTES.ROOT);
      }
    }, [auth, isFocused]);

    function onFinish(error = {}) {
      window.localStorage.setItem('payment-result', JSON.stringify(error));

      window.close();
    }

    return <Component {...props} />;
  }

  ProcessingController.propTypes = {
    navigation: PropTypes.object,
  };

  return ProcessingController;
}

export default controller;
