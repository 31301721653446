function createVowelsActions(id = 'default') {

    class Actions {

        get = () => (dispatch, flux) => {

            flux.api.users.tags.vowels.get(id, (error, existedTags) => {
                if (error) {
                    dispatch(null, error);
                    return;
                }

                dispatch(existedTags);
            });

        };

    }

    Actions.displayName = createVowelsActions.getDisplayName(id);

    return Actions;
}

createVowelsActions.getDisplayName = function (id) {
    return `user-tags-vowels.${id}`;
};

export default createVowelsActions;
