import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';

import FeatureItem from '../feature-item';
import styles from './styles';

export default class FeaturesList extends PureComponent {
    static propTypes = {
        features: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                icon: PropTypes.string,
                label: PropTypes.string,
            }),
        ),
    };

    render() {
        const { features } = this.props;

        return (
            <View style={styles.container}>
                {features.map(feature => (
                    <FeatureItem key={feature.id} {...feature} />
                ))}
            </View>
        );
    }
}
