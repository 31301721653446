import React, { PureComponent, ComponentType } from 'react';
// @ts-ignore
import { NavigationParams, NavigationScreenProps } from 'react-navigation';
import { StyleSheet } from 'react-native';
import Resources from 'dating-mobile/src/resources';
// @ts-ignore
import ImageButton from 'dating-mobile/src/components/navigation/views/image-button';
// @ts-ignore
import { CHAT } from 'dating-mobile/src/routing/router/constants';
// @ts-ignore
import testId from 'dating-mobile/src/utils/test-id';

import { ControllerProps } from './types';
import styles from './styles';

export default function bindNavigation(
  Component: ComponentType<ControllerProps>,
) {
  class Navigation extends PureComponent<NavigationScreenProps> {
    static displayName = 'screens.block-list.navigation';

    static navigationOptions = ({
      navigation,
      navigationOptions,
    }: NavigationScreenProps) => ({
      headerTitle: Resources.strings['block-list-title'],
      headerStyle: [navigationOptions.headerStyle, styles.header],
      headerTitleStyle: [
        navigationOptions.headerTitleStyle,
        styles.headerTitle,
      ],
      headerLeft: () => (
        <ImageButton
          image={Resources.images.backIcon()}
          tint={
            StyleSheet.flatten([
              navigationOptions.headerTitleStyle,
              styles.headerTitle,
            ]).color
          }
          onPress={() => navigation.goBack()}
          {...testId('Back button')}
        />
      ),
    });

    startChat = (params: NavigationParams) => {
      const { navigation } = this.props;

      navigation.navigate(CHAT, params);
    };

    render() {
      const { navigation } = this.props;
      const userId = navigation.getParam('userId');

      return <Component userId={userId} startChat={this.startChat} />;
    }
  }

  return Navigation;
}
