import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        paddingHorizontal: 40,
        flexDirection: 'row',
        alignItems: 'center',
        height: 64,
        borderRadius: 32,
        backgroundColor: '$minorAccentColor',
        '@flavor dma': {
            height: 84,
            borderRadius: 20
        }
    },

    title: {
        fontSize: 17,
        color: '$minorAccentTextColor',
        fontFamily: '$brandFontFamily',
        '@flavor dma': {
            fontSize: 20,
            fontWeight: 'bold'
        }
    }
});

export default styles;
