import Actions from './actions';
import { BaseStore } from '@sdv/model';

function createCheersStore(set) {

    class Store extends BaseStore {
        static displayName = createCheersStore.getDisplayName(set);

        constructor(flux) {
            super();
            const actions = flux.getActions(Actions, set);
            this.bindAction(actions.get, this.update);
        }

        update = (cheers) => {
            this.setState(cheers);
        }
    }

    Store.config = {
        setState(oldState, newState) {
            return newState;
        }
    };

    return Store;
}

createCheersStore.getDisplayName = function (set) {
    return `cheers.${set}`;
};

export default createCheersStore;
