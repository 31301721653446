import React from 'react';
import PropTypes from 'prop-types';
import IdentityHoc from '../../../models/identity/controller/id';
import StreamsController from '../../../models/dialogs.streams/controllers/controller';

function CreateCurrentlyBroadcastingController(Component) {
    class CurrentlyBroadcastingController extends React.Component {
        static displayName = 'dialogs.stream.currently-broadcasting.controller';
        static propTypes = {
            streams: PropTypes.array.isRequired,
            cooperativeStreams: PropTypes.array.isRequired,
            userId: PropTypes.string.isRequired,
            attachCoStreamer: PropTypes.func.isRequired,
            goBack: PropTypes.func.isRequired,
        };

        buttonTapEnabled = true;

        broadcastTogetherButtonPressed = (id) => () => {
            if (this.buttonTapEnabled) {
                this.buttonTapEnabled = false;
                this.props.attachCoStreamer(id);
                this.props.goBack();
            }
        };

        render() {
            const streamsToShow = this.props.streams.filter(streamId => this.props.userId !== streamId && this.props.cooperativeStreams.indexOf(streamId) === -1);

            return (
                <Component
                    {...this.props}
                    {...this.state}
                    streams={streamsToShow}
                    onBroadcastTogetherButtonPress={this.broadcastTogetherButtonPressed}
                />
            );

        }
    }

    return IdentityHoc(StreamsController(CurrentlyBroadcastingController));
}

export default CreateCurrentlyBroadcastingController;
