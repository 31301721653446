import React from 'react';
import PropTypes from 'prop-types';
import { BackHandler, StyleSheet } from 'react-native';
import styles from './styles';
import TextButton from '../../../components/navigation/views/text-button';
import IfConfigValue from '../../../components/config-value/if';
import Resources from '../../../resources';

import Controller from './controller';
import View from './view';

const ScreenView = Controller(View);

class Screen extends React.Component {
  static displayName = 'user.upload-private-photos.screen';

  static propTypes = {
    navigation: PropTypes.object.isRequired,
  };

  static navigationOptions = ({ navigation }) => ({
    headerTitle: '',
    headerStyle: styles.header,
    headerTitleStyle: styles.headerTitle,
    headerLeft: () => null,
    headerRight: () => (
      <IfConfigValue
        path="markup.upload-private-photos-screen.has-skip-button"
        equalsTo={true}
      >
        <TextButton
          title={
            Resources.strings['upload-private-photos-screen-skip-button-title']
          }
          tint={StyleSheet.flatten(styles.headerTitle).color}
          onPress={navigation.getParam('onSkipButtonPress')}
        />
      </IfConfigValue>
    ),
  });

  componentDidMount() {
    this.backButtonHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      () => {
        return true;
      },
    );
  }

  componentWillUnmount() {
    this.backButtonHandler.remove();
  }

  setNavigationBarState = state => {
    const { navigation } = this.props;

    navigation.setParams(state);
  };

  complete = () => {
    const { navigation } = this.props;
    const onComplete =
      navigation.getParam('onComplete') || (() => navigation.pop());

    onComplete();
  };

  render() {
    return (
      <ScreenView
        setNavigationBarState={this.setNavigationBarState}
        complete={this.complete}
      />
    );
  }
}

export default Screen;
