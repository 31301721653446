import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    header: {
        backgroundColor: '$primaryBackgroundColor',
        borderBottomColor: '$primaryBackgroundColor',
        shadowColor: '$primaryBackgroundColor',
        shadowRadius: 0,
        shadowOpacity: 0,
        elevation: 0,
        shadowOffset: {
            height: 0,
        },
    },

    headerTitle: {
        fontFamily: '$brandFontFamily',
        color: '$primaryTextColor'
    }
});

export default styles;
