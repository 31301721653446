// TODO:
const credentialsKey = 'CREDENTIALS';

export const getGenericPassword = async () =>
  JSON.parse(localStorage.getItem(credentialsKey) || null);

export const setGenericPassword = async (username, password) =>
  localStorage.setItem(credentialsKey, JSON.stringify({ username, password }));

export const resetGenericPassword = async () =>
  localStorage.removeItem(credentialsKey);

export default {
  getGenericPassword,
  setGenericPassword,
  resetGenericPassword,
};
