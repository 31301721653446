import React, { PureComponent } from 'react';
import {StackNavigator} from 'dating-mobile/src/components/stack-navigator';
import Resources from 'dating-mobile/src/resources';
import UserName from 'dating-mobile/src/user/common/views/user-name';
import { MyChats } from 'dating-mobile/src/dialogs/chats/screens/my-chats';
import ChatLogWithInput from 'dating-mobile/src/dialogs/messages/views/chat-log-with-input';

export default class ChatPopupRouter extends PureComponent {

    routes = {
        goToListChats: (props) => (
            <MyChats
                navigationTitle={Resources.strings['my-chats-screen-title']}
                startChat={props.routes.goToChat}
                isFocused
            />
        ),
        goToChat: (props) => (
            <ChatLogWithInput
                navigationTitle={<UserName userId={props.id}/>}
                user={this.props.userId}
                attendee={props.id}
                onKeyboardChange={this.onKeyboardChange}
            />
        )
    };

    componentWillUnmount() {
        this.props.onKeyboardVisible(false)
    }

    onKeyboardChange = (type) => {
        this.props.onKeyboardVisible(type !== undefined && type !== null)
    };

    render() {
        return (
            <StackNavigator
                onClose={this.props.onClose}
                initial={this.routes.goToListChats}
                routes={this.routes}
            />
        );
    }
}
