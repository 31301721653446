import { singleton } from '@sdv/commons/utils/singleton';

export class ThreeDsBootstrapper {

    static shared = singleton(() => new ThreeDsBootstrapper());

    /**
     *
     * @param md
     * @return void
     */
    restoreMeBy(md) {
        // Not implemented for RN. RN doesn't redirect anywhere outside the app. No need to recover now.
    }
}
