import React from 'react';
import qs from 'qs';
import flux from '@sdv/domain/app/flux';
import { render } from 'dating-mobile/src/components/rx-render';
import CallingModel from 'dating-mobile/src/models/call.calling';

export default function createController(Component) {
  const Controller = render((props, { useDistinct }) => {
    if (!props.userId || !props.attendeeId) {
      return null;
    }

    const modelId = useDistinct(
      (user, attendee) => qs.stringify({ user, attendee }),
      props.userId,
      props.attendeeId,
    );

    const model = useDistinct(id => flux.get(CallingModel, id), modelId);
    const dial = useDistinct(
      it => () => it?.actions?.dial({ audioOnly: true }),
      model,
    );

    if (!model) {
      return null;
    }

    return <Component {...props} dial={dial} />;
  });

  Controller.displayName = 'call.view.audio-call-button.controller';

  return Controller;
}
