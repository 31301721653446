import React from 'react';
import PropTypes from 'prop-types';
import VideoView from 'react-native-video';
import testId from 'dating-mobile/src/utils/test-id';
import Resources from "dating-mobile/src/resources";

import ConfigModel from '../../models/config/model';

class Video extends React.PureComponent {
    static displayName = 'photo';
    static contextTypes = {
        flux: PropTypes.object
    };
    static propTypes = {
        path: PropTypes.string,
        style: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.object,
            PropTypes.array
        ])
    };

    constructor(props, context) {
        super(props, context);

        this.flux = context.flux;
        this.configModel = this.flux.get(ConfigModel);
        this.state = {
            host: this.configModel.store.getState().host
        }
    }

    componentDidMount() {
        this.configModel.store.listen(this.onConfigUpdated);
    }

    componentWillUnmount() {
        this.configModel.store.unlisten(this.onConfigUpdated);
    }

    onConfigUpdated = (config) => {
        this.setState({
            host: config.host
        });
    };

    render() {

        const { forwardedRef, ...props } = this.props;
        const { ...state } = this.state;
        if (!props.source && state.host) {
            let path = props.path;

            if (path) {
                const token = encodeURIComponent(this.flux.api.authorize());
                props.source = {
                    uri: `${state.host}${path}?Authorization=${token}`,
                    isNetwork: true
                };
            }
        }

        const style = [
            props.style || {}
        ];

        return (<VideoView {...props} style={style} ref={forwardedRef} {...testId(Resources.strings['video-player-accessibility-label'])}/>);

    }

}

export default React.forwardRef((props, ref) => {
    return (<Video {...props} forwardedRef={ref} />);
});
