import React from 'react';
import PropTypes from 'prop-types';
import connect from '@sdv/connect';
import Model from '../model';

const GET_PROPERTY = 'generated.user.interests.controller.get';
const PUT_PROPERTY = 'generated.user.interests.controller.put';

export default function createController(Component, options = {}) {

    const userIdPropName = options.userIdPropName || 'id';
    const resultPropName = options.resultPropName || 'userInterests';

    class Controller extends React.Component {
        static displayName = 'user.interests.controller';
        static propTypes = {
            [userIdPropName]: PropTypes.string,
            [resultPropName]: PropTypes.array,
            [GET_PROPERTY]: PropTypes.func,
            [PUT_PROPERTY]: PropTypes.func
        };
        static contextTypes = {
            flux: PropTypes.object
        };

        componentDidMount() {
            this.props[GET_PROPERTY] && this.props[GET_PROPERTY]();
        }

        componentDidUpdate(prevProps) {
            if (shouldReconnect(prevProps, this.props)) {
                this.props[GET_PROPERTY] && this.props[GET_PROPERTY]();
            }
        }

        update = (payload) => {
            return new Promise((resolve, reject) => {
                if (!this.props[PUT_PROPERTY]) {
                    reject('User id is not defined');
                    return;
                }

                this.props[PUT_PROPERTY](payload, (error) => {
                    if (error) {
                        return reject(error);
                    }

                    return resolve();
                });
            });
        };

        render() {
            const { ...props } = this.props;

            delete props[GET_PROPERTY];
            delete props[PUT_PROPERTY];

            return (
                <Component
                    {...props}
                    updateInterests={this.update}
                />
            );
        }
    }

    function getModels(flux, props) {
        const models = {};

        if (props[userIdPropName]) {
            models.interestsModel = flux.get(Model, props[userIdPropName]);
        }

        return models;
    }

    function mapModelsToProps(models) {
        return {
            [resultPropName]: models.interestsModel
                ? models.interestsModel.store.getState().interests || []
                : []
        };
    }

    function mapModelsToActions(models) {
        const props = {};

        if (models.interestsModel) {
            props[GET_PROPERTY] = models.interestsModel.actions.get;
            props[PUT_PROPERTY] = models.interestsModel.actions.put;
        }

        return props;
    }

    function shouldReconnect(props, newProps) {
        return props[userIdPropName] !== newProps[userIdPropName];
    }

    return connect(
        getModels,
        mapModelsToProps,
        mapModelsToActions,
        shouldReconnect
    )(Controller);

};
