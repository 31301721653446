import View from './view';
import withNavigation from './navigation';
import withConfigValue from 'dating-mobile/src/components/config-value';

export default withConfigValue(
    withNavigation(View),
    {
        privacyPolicyLink: 'links.privacy-policy',
        termsLink: 'links.terms',
        useLocalizedLinks: 'links.use-localized-links',
    }
);
