export default {
  get: uri => {
    return new Promise(res => {
      const root = window.document.getElementById('root');
      const video = window.document.createElement('video');
      const canvas = window.document.createElement('canvas');
      const context = canvas.getContext('2d');
      const newUrl = URL.createObjectURL(uri);

      video.src = newUrl;
      video.hidden = true;
      canvas.hidden = true;
      video.preload = 'metadata';
      video.muted = true;
      video.playsInline = true;
      video.play();

      root.appendChild(video);
      root.appendChild(canvas);

      video.addEventListener('loadeddata', () => {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        video.pause();

        URL.revokeObjectURL(newUrl);

        root.removeChild(video);
        root.removeChild(canvas);

        res({
          width: video.videoWidth,
          height: video.videoHeight,
          path: canvas.toDataURL(),
        });
      });
    });
  },
};
