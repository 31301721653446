import React from 'react';
import PropTypes from 'prop-types';
import { Image, TouchableOpacity, View, Alert } from 'react-native';
import Resources from 'dating-mobile/src/resources';
import TermsAndPrivacyText from 'dating-mobile/src/components/terms-and-privacy-text';
import styles from './styles';

class AgeAgreementText extends React.PureComponent {
    static propTypes = {
        onEnabledChanged: PropTypes.func,
    };
    static displayName = 'components.age-agreement-text';

    state = { enabled: true };

    onPress = () => {
        this.setState({ enabled: !this.state.enabled }, () => {
            if (this.props.onEnabledChanged) {
                this.props.onEnabledChanged(this.state.enabled);
            }
        });
    };

    render() {
        const icon = this.state.enabled
            ? Resources.images.checkboxFilled()
            : Resources.images.checkboxEmpty();

        const containerStyle = [
            styles.container,
            this.props.containerStyle ? this.props.containerStyle : {},
        ];

        return (
            <View style={containerStyle}>
                <TouchableOpacity onPress={this.onPress}>
                    <Image source={icon} style={styles.icon} />
                </TouchableOpacity>
                <TermsAndPrivacyText
                    textStyle={styles.text}
                    containerStyle={styles.termsContainer}
                    customTextFormat={Resources.strings['signup-terms-with-age-format']}
                    targetButtonTitle={null}
                />
            </View>
        );
    }
}

export { AgeAgreementText };
