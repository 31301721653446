export const BODY_TYPE = {
    slim: 'sli',
    average: 'ave',
    muscular: 'mus',
    fewExtraPounds: 'few',
};

export const PERSONALITY = {
    laidback: 'laidback',
    serious: 'serious',
};

export const RELATIONSHIP_PRIORITY = {
    sexual: 'sexual',
    spendingTime: 'spending-time',
};

export const USER_GOALS = {
    fun: 'fun',
    date: 'date',
    longTerm: 'long-term',
};

export const PREFERRED_AGE = {
    young: 'young',
    adult: 'adult',
};

export const PREFERENCE_TYPE = {
    bodyType: 'body-type',
    personality: 'personality',
    userGoals: 'user-goals',
    relationshipPriority: 'relationship-priority',
    preferredAge: 'preferred-age',
    findPerfectMatch: 'find-perfect-match',
};
