import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, Image } from 'react-native';
import styles from './styles';
import Resources from '../../../resources';

class ProfileBoostedNotificationView extends React.PureComponent {
    static displayName = 'notifications.popup.profile-boosted';
    static PropTypes = {
        onReadyForDisplay: PropTypes.func,
    };

    componentDidMount() {
        this.props.onReadyForDisplay && this.props.onReadyForDisplay();
    }

    render() {
        return (
            <View style={styles.container}>
                <View style={styles.imageContainer}>
                    <Image source={Resources.images.logoIcon()}/>
                </View>
                <View style={styles.textContainer}>
                    <Text style={styles.text}>{Resources.strings['profile-boosted-notification-text']}</Text>
                </View>
            </View>
        );

    }

}

export default ProfileBoostedNotificationView;
