import React, { useCallback, useEffect, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { View, Text, StyleSheet, Alert } from 'react-native';
import Modal from 'react-native-modal';
import { fromEvent } from 'file-selector';
import PropTypes from 'prop-types';
import { Subject } from 'rxjs';

import Button from '../buttons/base';
import Resources from '../../resources';

import styles from './styles';

const inputStyle = {
  display: 'none',
};

function getFileType(prefix, fileName) {
  const partsArray = fileName.split('.');

  return `${prefix}/${(
    partsArray[partsArray.length - 1] || '*'
  ).toLowerCase()}`;
}

export const mediaTakenFromGallery = new Subject();
export const mediaTakenFromCamera = new Subject();

export default function createImagePicker(Component, opt = {}) {
  function Picker({
    forwardedRef,
    cameraHeight,
    cameraWidth,
    mediaType,
    ...props
  }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [source, setSource] = useState(null);
    const [imageData, setImageData] = useState(null);
    const [selectedVariant, setSelectedVariant] = useState('');
    const [canvasProps, setCanvasProps] = useState({
      height: cameraHeight,
      width: cameraWidth,
    });
    const inputRef = useRef(null);
    const editorRef = useRef(null);
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const streamRef = useRef(null);
    const recorder = useRef(null);
    const resultVideo = useRef([]);
    const actualMediaType = mediaType || opt.mediaType || 'photo';
    let accept = 'image/*, video/*';
    let modalTitle = Resources.strings['media-picker-title'];

    if (actualMediaType === 'video') {
      modalTitle = Resources.strings['video-picker-title'];
    } else if (actualMediaType === 'photo') {
      modalTitle = Resources.strings['photo-picker-title'];
    }

    if (selectedVariant === 'open/image') {
      accept = 'image/*';
    } else if (selectedVariant === 'open/video') {
      accept = 'video/*';
    }

    useEffect(() => {
      return () => {
        if (imageData) {
          URL.revokeObjectURL(imageData.uri);
        }
      };
    }, [imageData]);

    const onChange = async e => {
      try {
        const [file] = await fromEvent(e);

        const img = new window.Image();
        const fileUrl = URL.createObjectURL(file);
        const { name } = file;

        img.onload = () => {
          if (opt.noEdit) {
            const type = getFileType('image', name);

            saveFile({
              blob: file,
              name,
              mediaType: 'photo',
              type,
              height: img.height,
              width: img.width,
            });
          } else {
            setImageData({
              uri: fileUrl,
              name,
            });
            setSelectedVariant('editor');
          }
        };
        img.onerror = () => {
          const type = getFileType('video', name);

          saveFile({
            blob: file,
            name,
            mediaType: 'video',
            type,
          });
        };
        img.src = fileUrl;
      } catch (error) {
        Alert.alert(
          Resources.strings['network-error-alert-title'],
          Resources.strings['default-error-message'],
          [
            {
              text: Resources.strings.ok,
            },
          ],
        );
      }
    };

    const openFileDialog = () => {
      if (inputRef.current) {
        inputRef.current.value = null;
        inputRef.current.click();
      }
    };

    function saveFile(data) {
      setSource({
        uri: 'blob',
        ...data,
      });
      closeModal();
    }

    function clearStream() {
      if (streamRef.current) {
        recorder.current = null;
        resultVideo.current = [];
        streamRef.current.getTracks().forEach(track => {
          track.stop();
        });
      }
    }

    function prepareCamera(type) {
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: type === 'video' })
        .then(stream => {
          setSelectedVariant(`take/${type}`);
          videoRef.current.srcObject = stream;
          streamRef.current = stream;
        });
    }

    function proccessVideo() {
      const recordedBlob = new Blob(resultVideo.current, { type: 'video/mp4' });
      const name = 'new_video.mp4';

      saveFile({
        blob: recordedBlob,
        name,
        mediaType: 'video',
        type: 'video/mp4',
      });
    }

    function startRecordVideo() {
      recorder.current = new window.MediaRecorder(streamRef.current);
      recorder.current.ondataavailable = event => {
        resultVideo.current.push(event.data);
      };

      recorder.current.start(40);
      setIsRecording(true);
    }

    function stopRecordVideo() {
      recorder.current.stop();
      setIsRecording(false);
      const recordedBlob = new Blob(resultVideo.current, { type: 'video/mp4' });

      setSelectedVariant('preview/video');
      videoRef.current.srcObject = null;
      videoRef.current.src = URL.createObjectURL(recordedBlob);
    }

    function processEditedImage() {
      const imageCanvas = editorRef.current.getImage();

      imageCanvas.toBlob(blob => {
        const name = `${imageData.name.split('.')[0]}.jpeg` || 'new_photo.jpeg';

        saveFile({
          blob,
          name,
          width: 500,
          height: 500,
          type: 'image/jpeg',
          mediaType: 'photo',
        });
      }, 'image/jpeg');
    }

    function takePicture() {
      const context = canvasRef.current.getContext('2d', { alpha: false });

      if (videoRef.current) {
        context.drawImage(
          videoRef.current,
          0,
          0,
          videoRef.current.offsetWidth,
          videoRef.current.offsetHeight,
        );

        if (opt.noEdit) {
          canvasRef.current.toBlob(blob => {
            const name = 'new_photo.jpeg';

            saveFile({
              blob,
              name,
              type: 'image/jpeg',
              mediaType: 'photo',
              width: videoRef.current.offsetWidth,
              height: videoRef.current.offsetHeight,
            });
          }, 'image/jpeg');
        } else {
          setImageData({
            uri: canvasRef.current.toDataURL('image/jpeg'),
            name: 'new_picture.jpeg',
          });
          setSelectedVariant('editor');
          clearStream();
        }
      }
    }

    const openModal = useCallback(() => {
      setIsModalOpen(true);
    }, []);

    function closeModal() {
      clearStream();
      setSelectedVariant('');
      setIsModalOpen(oldIsModalOpen => !oldIsModalOpen);
    }

    function getButtonProps() {
      let buttonOnClick;
      let buttonTitle;

      if (selectedVariant === 'take/video') {
        buttonOnClick = isRecording ? stopRecordVideo : startRecordVideo;
        buttonTitle = isRecording
          ? Resources.strings['media-picker-stop-recording-video-button-title']
          : Resources.strings['media-picker-record-video-button-title'];
      } else if (selectedVariant === 'preview/video') {
        buttonOnClick = proccessVideo;
        buttonTitle = Resources.strings.save;
      } else {
        buttonOnClick = takePicture;
        buttonTitle = Resources.strings['media-picker-take-photo-button-title'];
      }

      return {
        onPress: buttonOnClick,
        title: buttonTitle,
      };
    }

    function getModalButtons(type) {
      return [
        <Button
          key={`take-${type}`}
          style={styles.button}
          title={Resources.strings[`media-picker-take-${type}-button-title`]}
          onPress={() => {
            prepareCamera(type);
            mediaTakenFromCamera.next();
          }}
        />,
        <Button
          key={`choose-${type}`}
          style={styles.button}
          title={Resources.strings[`media-picker-choose-${type}-button-title`]}
          onPress={() => {
            setSelectedVariant(`open/${type === 'video' ? type : 'image'}`);
            mediaTakenFromGallery.next();
          }}
        />,
      ];
    }

    function getModalContent() {
      switch (selectedVariant) {
        case 'open/image':
        case 'open/video':
          return (
            <>
              <input
                type="file"
                style={inputStyle}
                tabIndex={-1}
                autoComplete="off"
                accept={accept}
                multiple={false}
                ref={inputRef}
                onChange={onChange}
              />
              <Button
                title={Resources.strings['media-picker-drop-zone-button-title']}
                style={styles.button}
                onPress={openFileDialog}
              />
            </>
          );
        case 'editor':
          return (
            <View style={styles.cameraAndEditor}>
              <AvatarEditor
                ref={editorRef}
                image={imageData.uri}
                border={50}
                borderRadius={250}
                color={[255, 255, 255, 0.6]}
                scale={1.2}
                rotate={0}
              />

              <Button
                title={Resources.strings.save}
                style={styles.button}
                onPress={processEditedImage}
              />
            </View>
          );
        case 'take/photo':
        case 'take/video':
        case 'preview/video':
          return (
            <View style={styles.cameraAndEditor}>
              <video
                style={StyleSheet.flatten(styles.video)}
                ref={videoRef}
                muted={selectedVariant.includes('photo')}
                controls={selectedVariant.includes('preview')}
                playsInline
                autoPlay
                onPlay={() =>
                  setCanvasProps({
                    height: videoRef.current.offsetHeight,
                    width: videoRef.current.offsetWidth,
                  })
                }
              />
              <canvas
                {...canvasProps}
                style={{
                  display: 'none',
                }}
                ref={canvasRef}
                id="canvas"
              />

              <Button style={styles.button} {...getButtonProps()} />
            </View>
          );
        default:
          return (
            <>
              {getModalButtons('photo')}
              {actualMediaType === 'mixed' && getModalButtons('video')}
            </>
          );
      }
    }

    Picker.propTypes = {
      cameraHeight: PropTypes.string,
      cameraWidth: PropTypes.string,
      forwardedRef: PropTypes.object,
      mediaType: PropTypes.string,
    };

    Picker.defaultProps = {
      cameraHeight: '480',
      cameraWidth: '640',
    };

    return (
      <>
        <Component
          {...props}
          ref={forwardedRef}
          source={source}
          select={openModal}
        />
        <Modal style={styles.modal} isVisible={isModalOpen}>
          <View style={styles.container}>
            <View style={styles.modalTitleWrapper}>
              <Text style={styles.modalTitle}>{modalTitle}</Text>
            </View>
            {getModalContent()}

            <Button
              style={styles.button}
              title={Resources.strings.cancel}
              onPress={closeModal}
            />
          </View>
        </Modal>
      </>
    );
  }

  return React.forwardRef((props, ref) => {
    return <Picker {...props} forwardedRef={ref} />;
  });
}
