import React from 'react';
import { StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import TextButton from 'dating-mobile/src/components/navigation/views/text-button';
import ImageButton from 'dating-mobile/src/components/navigation/views/image-button';
import Resources from 'dating-mobile/src/resources';
import { APP, AUTH, LOGIN } from 'dating-mobile/src/routing/router/constants';
import styles from './styles';
import View from './sign-up';
import authRouting from '../../controllers/auth-routing';
import { routeForRegistrationStep } from '../../utils/registration-step-route';

const Screen = authRouting(View);

class UpdateUserScreen extends React.Component {
  static displayName = 'authentication.update-identity.screen';

  static contextTypes = {
    flux: PropTypes.object,
  };

  static propTypes = {
    navigation: PropTypes.object.isRequired,
  };

  showApp = () => {
    const { navigation } = this.props;

    navigation.navigate(APP);
  };

  showRegistrationStep = (step, params) => {
    const { navigation } = this.props;
    const route = routeForRegistrationStep(step);

    navigation.navigate(route, params);
  };

  showAuth = () => {
    const { navigation } = this.props;

    navigation.navigate(AUTH);
  };

  render() {
    const { navigation } = this.props;

    return (
      <Screen
        navigation={navigation}
        showApp={this.showApp}
        showRegistrationStep={this.showRegistrationStep}
        showAuth={this.showAuth}
        tryToSignIn
      />
    );
  }
}

UpdateUserScreen.navigationOptions = ({ navigation }) => ({
  headerStyle: styles.header,
  headerLeft: () => (
    <ImageButton
      image={Resources.images.backIcon()}
      tint={StyleSheet.flatten(styles.tint).color}
      onPress={() => navigation.pop()}
    />
  ),
  headerRight: () => (
    <TextButton
      title="Sign in"
      tint={StyleSheet.flatten(styles.tint).color}
      onPress={() => navigation.navigate(LOGIN)}
    />
  ),
});

export default UpdateUserScreen;
