import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Platform, View } from 'react-native';
import Resources from 'dating-mobile/src/resources';
import withConfigValue from 'dating-mobile/src/components/config-value';

import BenefitItem from './item';

import styles from './styles';

class MembershipBenefits extends PureComponent {
  static displayName = 'payment.membership-benefits.basic-subscription';

  static propTypes = {
    unlimitedMessagesWithMembershipEnabled: PropTypes.bool,
    style: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.number,
      PropTypes.array,
    ]),
  };

  render() {
    const { style, unlimitedMessagesWithMembershipEnabled } = this.props;

    return (
      <View
        style={[
          styles.container,
          styles.extraMargin,
          unlimitedMessagesWithMembershipEnabled ? styles.big : styles.small,
          style,
        ]}
      >
        <BenefitItem
          title={
            Resources.strings[
              'memberships-screen-basic-filter-benefit-description'
            ]
          }
          icon={Resources.images.filterIcon()}
          containerStyle={styles.itemContainer}
        />
        {!!unlimitedMessagesWithMembershipEnabled && (
          <BenefitItem
            title={
              Resources.strings[
                'memberships-screen-unlimited-messages-benefit-description'
              ]
            }
            icon={Resources.images.newChatsIcon()}
            iconStyle={styles.iconWithoutTint}
            containerStyle={styles.itemContainer}
          />
        )}
        <BenefitItem
          title={
            Resources.strings[
              'memberships-screen-basic-special-badge-benefit-description'
            ]
          }
          icon={Resources.images.cupIcon()}
          containerStyle={styles.itemContainer}
        />
        <BenefitItem
          title={Resources.strings.formatString(
            Resources.strings[
              'memberships-screen-basic-more-profiles-views-benefit-description'
            ],
            2,
          )}
          icon={Resources.images.eyeIcon()}
          containerStyle={styles.itemContainer}
        />
        <BenefitItem
          title={
            Resources.strings['memberships-screen-basic-no-location-search']
          }
          icon={Resources.images.noBenefit()}
          iconStyle={styles.iconWithoutTint}
          containerStyle={styles.itemContainer}
        />
        {Platform.OS !== 'web' && (
          <BenefitItem
            title={Resources.strings['memberships-screen-basic-no-calls']}
            icon={Resources.images.noBenefit()}
            iconStyle={styles.iconWithoutTint}
            containerStyle={styles.itemContainer}
          />
        )}
      </View>
    );
  }
}

export const BasicSubscriptionBenefits = withConfigValue(MembershipBenefits, {
  unlimitedMessagesWithMembershipEnabled:
    'features.unlimited-messages-with-membership-enabled',
});
