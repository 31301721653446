import { singleton } from '../../commons/utils/singleton';
import api, { toObservable } from '@sdv/domain/api';
import { map } from 'rxjs/operators';

export class AutobuySettingsRepo {

    static shared = singleton(userId => new AutobuySettingsRepo(userId));

    constructor(userId) {
        this.userId = userId;
    }

    /**
     *
     * @return {Observable<AutobuySettings>}
     */
    currentSettings() {
        return toObservable(
            api.credits.accounts.settings.get,
            this.userId
        ).pipe(
            map(json => this.mapToAutobuySettings(json))
        );
    }

    /**
     * @param {Invoice} invoice
     * @return {Observable<AutobuySettings>}
     */
    proposedSettings(invoice) {
        return this.currentSettings().pipe(
            map(settings => {
                const proposed = Object.assign({}, settings);
                proposed.availableDuringPayment = !invoice.containsMembership || (invoice.containsMembership && !settings.enabled);
                proposed.creditsAmount = invoice.creditsAmount;
                return proposed;
            })
        );
    }

    /**
     * @private
     * @param json
     * @return {AutobuySettings?}
     */
    mapToAutobuySettings(json) {
        if (json) {
            return ({
                enabled: json.automation.enabled,
                available: json.automation.available,
                creditsAmount: json.automation.amount
            });
        } else {
            return ({});
        }
    }

    /**
     *
     * @param {AutobuySettings} settings
     * @return {Observable}
     */
    update(settings) {
        return toObservable(
            api.credits.accounts.settings.put,
            this.userId,
            this.mapAutobuySettingsToJson(settings)
        );
    }

    /**
     * @private
     */
    mapAutobuySettingsToJson(settings) {
        return {
            automation: {
                amount: settings.creditsAmount,
                enabled: settings.enabled
            }
        };
    }
}

/**
 * AutobuySettings
 * @typedef {
 * {
 *  enabled: bool,
 *  available: bool,
 *  creditsAmount: int,
 * }
 * } AutobuySettings
 */
