import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        width: '100%',
        height: '100%',
        backgroundColor: 'black'
    },

    thumbnail: {
        width: '100%',
        height: '100%'
    },

    overlay: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        backgroundColor: '$shadowColor'
    },

    textContainer: {
        position: 'absolute',
        width: '100%',
        top: 170,
        paddingLeft: 40,
        paddingRight: 40,
        flexDirection: 'column',
        alignItems: 'stretch',
        '@flavor lovinga': {
            top: 216
        }
    },

    text: {
        fontFamily: '$brandFontFamily',
        fontWeight: 'bold',
        fontSize: 24,
        color: 'white',
        textAlign: 'center',
    },

    followText: {
        marginTop: 25,
        fontFamily: '$brandFontFamily',
        fontSize: 14,
        textAlign: 'center',
        color: '$infoTextColor',
        fontWeight: '400',
        '@flavor lovinga': {
            color: 'white',
            fontWeight: 'normal'
        }
    },

    backToStreamsContainer: {
        position: 'absolute',
        bottom: 104,
        alignItems: 'center',
        paddingLeft: 40,
        paddingRight: 40,
        width: '100%'
    },

    backToStreamsText: {
        textAlign: 'center',
        color: 'white',
        fontFamily: '$brandFontFamily',
        fontSize: 16,
        marginBottom: 24,
        fontWeight: '500',
        '@flavor lovinga': {
            fontWeight: 'normal'
        }
    }
});

export default styles;
