import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  $statusBarStyle: '$primaryStatusBarStyle',
  $statusBarBackgroundColor: '$primaryBackgroundColor',
  $hasBackgroundBlurred: false,

  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  callConnectionInformation: {
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonsContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 20,
    marginBottom: 40,
  },
  streamButtonsContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginBottom: 80,
  },
  cancelButton: {
    width: 72,
    height: 72,
    backgroundColor: undefined,
  },
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  overlay: {
    backgroundColor: '$brandColor',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
});

export default styles;
