import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    content: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    underline: {
        position: 'absolute',
        width: '100%',
        bottom: 0,
    },
    counterContainer: {
        width: 20,
        height: 20,
        borderRadius: 10,
        backgroundColor: '$accentColor',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 5,
    },
    counterText: {
        fontFamily: '$brandFontFamily',
        fontWeight: 'bold',
        fontSize: 12,
        color: '$accentTextColor',
    },
    spacer: {
        width: 23,
    },
});

export default styles;
