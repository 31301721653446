import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withNavigation } from 'react-navigation';
import { TERMS } from 'dating-mobile/src/routing/router/constants';

export default function bindNavigation(Component) {
  class Navigation extends PureComponent {
    static propTypes = {
      navigation: PropTypes.object,
    };

    openLink = (uri, title) => {
      const { navigation } = this.props;

      navigation.navigate(TERMS, {
        uri,
        title,
      });
    };

    render() {
      return <Component openLink={this.openLink} {...this.props} />;
    }
  }

  return withNavigation(Navigation);
}
