import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  $placeholderColor: '$primaryPlaceholderColor',

  closeIconContainer: {
    margin: 20,
    alignSelf: 'flex-end',
  },

  closeIcon: {
    tintColor: '$primaryTextColor',
    width: 15,
    height: 15,
  },

  container: {
    backgroundColor: '$primaryBackgroundColor',
    borderRadius: 15,
    paddingBottom: 50,
  },

  wrapper: {
    flexDirection: 'column',
    alignItems: 'center',
    paddingHorizontal: 32,
  },

  starsIcon: {
    marginBottom: 26,
  },

  title: {
    fontFamily: '$brandFontFamily',
    color: '$primaryTextColor',
    fontSize: 24,
    fontWeight: '500',
    marginBottom: 32,
    textAlign: 'center',
  },

  feedbackTitle: {
    fontFamily: '$brandFontFamily',
    color: '$primaryTextColor',
    fontSize: 22,
    marginBottom: 42,
    textAlign: 'center',
  },

  feedbackInput: {
    fontSize: 16,
    color: '$inputTextColor',
    padding: 16,
    width: '100%',
    height: 150,
    fontFamily: '$brandFontFamily',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '$primaryBorderColor',
    marginBottom: 48,
    textAlignVertical: 'top',
  },

  buttonContainer: {
    width: 230,
    marginBottom: 26,
  },

  button: {
    gradientColor: ['#00c8b6', '#34c342'],
  },

  buttonText: {
    padding: 4,
    fontFamily: '$brandFontFamily',
    color: '$brandTextColor',
    fontSize: 18,
    fontWeight: '500',
  },

  askMeLaterText: {
    fontFamily: '$brandFontFamily',
    color: '#249aff',
    fontSize: 18,
  },
});

export default styles;
