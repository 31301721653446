import React from 'react';
// @ts-ignore
import ImageButton from 'dating-mobile/src/components/navigation/views/image-button';
import Resources from 'dating-mobile/src/resources';
// @ts-ignore
import shouldUseWebLayout from 'dating-mobile/src/utils/web-layout';
// @ts-ignore
import testId from 'dating-mobile/src/utils/test-id';
import {
  CreateNavigatorConfig,
  NavigationRoute,
  NavigationRouteConfigMap,
  NavigationStackRouterConfig,
} from 'react-navigation';
import {
  createStackNavigator as createBaseStackNavigator,
  NavigationStackConfig,
  NavigationStackOptions,
  NavigationStackProp,
  CardStyleInterpolators,
  StackCardInterpolationProps,
  StackCardInterpolatedStyle,
} from 'react-navigation-stack';

import styles from './styles';

export function createCardInterpolatedStyles(
  args: [StackCardInterpolationProps],
  newStyles: StackCardInterpolatedStyle = {},
) {
  const defaultStyles = CardStyleInterpolators.forRevealFromBottomAndroid(
    ...args,
  );

  return {
    cardStyle: [defaultStyles.cardStyle, newStyles.cardStyle],
    containerStyle: [defaultStyles.containerStyle, newStyles.containerStyle],
    overlayStyle: [defaultStyles.overlayStyle, newStyles.overlayStyle],
    shadowStyle: [defaultStyles.shadowStyle, newStyles.shadowStyle],
  };
}

type options = CreateNavigatorConfig<
  NavigationStackConfig,
  NavigationStackRouterConfig,
  NavigationStackOptions,
  NavigationStackProp<NavigationRoute, any>
>;

export function createStackNavigator(
  stack: NavigationRouteConfigMap<
    NavigationStackOptions,
    NavigationStackProp<NavigationRoute, any>
  >,
  options: options = {},
) {
  const { defaultNavigationOptions = {}, ...otherOptions } = options;
  const newOptions: options = {
    defaultNavigationOptions: {
      headerBackTitleVisible: false,
      headerTruncatedBackTitle: undefined,
      headerStyle: styles.defaultHeader,
      headerTitleStyle: styles.defaultHeaderTitle,
      headerTintColor: styles.defaultHeaderTitle.color,
      // eslint-disable-next-line react/prop-types
      headerBackImage: ({ tintColor }) => (
        <ImageButton
          image={Resources.images.backIcon()}
          tint={tintColor}
          {...testId(
            Resources.strings['navigation-bar-back-button-accessibility-label'],
          )}
        />
      ),
      ...(shouldUseWebLayout()
        ? {
            cardStyleInterpolator: (...args) =>
              createCardInterpolatedStyles(args, {
                containerStyle: styles.cardContainer,
              }),
          }
        : {}),
      ...defaultNavigationOptions,
    },
    ...otherOptions,
  };

  return createBaseStackNavigator(stack, newOptions);
}
