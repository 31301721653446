import React from 'react';
import PropTypes from 'prop-types';
import RoundButton from 'dating-mobile/src/components/buttons/round';
import Resources from "dating-mobile/src/resources";

export default class CheerButton extends React.Component {
    static displayName = 'dialogs.cheers.views.cheers-button.view';
    static propTypes = {
        onPress: PropTypes.func
    };

    render() {
        return (
            <RoundButton
                { ...this.props }
                icon={Resources.images.sendGiftIcon()}
                onPress={this.buttonPressed}
            />
        );
    }

    buttonPressed = () => {
        this.props.onPress && this.props.onPress();
    }
}
