import React from 'react';
import { View, StatusBar } from 'react-native';
import AppLoading from '../../../bootstrap/views/app-loading';
import styles from './styles';

export default class ScreenView extends React.Component {
    static displayName = 'authentication.auth-loading.screen.controller';

    shouldComponentUpdate() {
        return false;
    }

    render() {
        return (
            <View style={styles.container}>
                <StatusBar
                    barStyle={styles.$statusBarStyle}
                />
                <AppLoading />
            </View>
        );
    }
}
