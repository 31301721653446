import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: '$primaryBackgroundColor',
    borderRadius: 20,
    alignItems: 'center',
    shadowRadius: 5,
    shadowOpacity: 0.15,
    shadowOffset: { width: 0, height: 0 },
    elevation: 5,
    margin: 10,
  },

  text: {
    textAlign: 'center',
    alignSelf: 'stretch',
    fontFamily: '$brandFontFamily',
    color: '$primaryTextColor',
    fontSize: 18,
    marginHorizontal: 16,
    marginVertical: 24,
  },

  button: {
    height: 40,
    borderRadius: 20,
    marginBottom: 32,
    paddingHorizontal: 37,
    backgroundColor: '$accentColor',
    justifyContent: 'center',
    alignItems: 'center',
  },

  marginBottomSmall: {
    marginBottom: 16,
  },

  buttonText: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: '$brandFontFamily',
    color: '$accentTextColor',
  },
});

export default styles;
