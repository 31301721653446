import React from 'react';
import PropTypes from 'prop-types';
import { render } from 'dating-mobile/src/components/rx-render';
import { RegistrationBonus } from '@sdv/domain/registration-bonus';

export default function createController(Component) {
  const Controller = render(({ userId }, { useObservable }) => {
    if (!userId) {
      return null;
    }

    const registrationBonus = RegistrationBonus.shared(userId);

    const showModal = useObservable(
      it => it.shouldShowBonus,
      registrationBonus,
    );

    const creditsBonus = useObservable(
      it => it.creditsBonus,
      registrationBonus,
    );

    return (
      <Component
        isVisible={showModal}
        bonus={creditsBonus}
        onClose={registrationBonus.userBonusShowed}
      />
    );
  });

  Controller.displayName =
    'stepped-registration.views.registration-bonus-modal.controller';

  Controller.propTypes = {
    userId: PropTypes.string,
  };

  return Controller;
}
