import UsersSearchModel from 'dating-mobile/src/models/users.search/model';
import connect from '@sdv/connect';

export default function createController(Component, options={}) {
    const setSearchParamsPropName = options.setSearchParamsPropName || 'setSearchParams';

    function getModels(flux, props) {
        const models = {};

        if (props.id) {
            models.feedModel = flux.get(UsersSearchModel, props.id);
        }

        return models;
    }

    function mapModelsToProps() {
        return {};
    }

    function mapModelsToActions(models) {
        const props = {};

        if (models.feedModel) {
            props[setSearchParamsPropName] = models.feedModel.actions.setParams;
        }

        return props;
    }

    function shouldReconnect(props, nextProps) {
        return props.id !== nextProps.id;
    }

    return connect(
        getModels,
        mapModelsToProps,
        mapModelsToActions,
        shouldReconnect
    )(Component);

}


