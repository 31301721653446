import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  View,
  ActivityIndicator,
  StatusBar,
  Text,
  ScrollView,
  Image,
} from 'react-native';

import Resources from 'dating-mobile/src/resources';
import { GradientButton } from 'dating-mobile/src/components/buttons/gradient';
import { formatPrice } from '@sdv/commons/price-with-code-localization';
import { TimeInterval } from '@sdv/commons/utils/time-interval';
import { Gender } from '@sdv/domain/user/gender';
import { SupportedMethods } from '@sdv/domain/app/payment/shop-info';

import starImg from './assets/star.png';
import girl1Img from './assets/man/girl_1.jpg';
import girl2Img from './assets/man/girl_2.jpg';
import girl3Img from './assets/man/girl_3.jpg';
import guy1Img from './assets/woman/guy_1.jpg';
import guy2Img from './assets/woman/guy_2.jpg';
import guy3Img from './assets/woman/guy_3.jpg';

import styles from './styles';

const IMAGES_MAP = {
  man: {
    first: girl1Img,
    second: girl2Img,
    third: girl3Img,
  },
  woman: {
    first: guy1Img,
    second: guy2Img,
    third: guy3Img,
  },
};

const DEFAULT_BOOST_TIME = 30;

const getBoostTime = ttl =>
  ttl
    ? Math.floor((ttl * TimeInterval.second) / TimeInterval.minute)
    : DEFAULT_BOOST_TIME;

export default class ScreenView extends PureComponent {
  static displayName = 'stepped-registration.screens.boost.view';

  static propTypes = {
    onSubmit: PropTypes.func,
    isLoading: PropTypes.bool,
    invoice: PropTypes.object,
    gender: PropTypes.string,
  };

  render() {
    const { isLoading, onSubmit, invoice, gender } = this.props;
    const images = gender === Gender.Male ? IMAGES_MAP.man : IMAGES_MAP.woman;
    const localizedPrice = formatPrice(invoice?.price, false);
    const priceWithoutDiscount = formatPrice(
      invoice?.priceWithoutDiscount,
      false,
    );

    return (
      <ScrollView
        style={styles.scrollView}
        contentContainerStyle={styles.content}
        alwaysBounceVertical={false}
      >
        <StatusBar
          barStyle={styles.$statusBarStyle}
          backgroundColor={styles.$statusBarBackgroundColor}
        />
        <View>
          <Image
            resizeMode="contain"
            style={styles.firstStarImage}
            source={starImg}
          />
          <Text style={styles.title}>
            {Resources.strings['onboarding-screen-boost-title']}
          </Text>
          <Text style={styles.subTitle}>
            {
              Resources.strings[
                Gender.Male
                  ? 'onboarding-screen-boost-subtitle-male'
                  : 'onboarding-screen-boost-subtitle-female'
              ]
            }
          </Text>
          <Image
            resizeMode="contain"
            style={styles.secondStarImage}
            source={starImg}
          />
        </View>
        <View style={styles.imagesContainer}>
          <Image
            resizeMode="contain"
            style={styles.image}
            source={images.first}
          />
          <Image
            resizeMode="contain"
            style={styles.image}
            source={images.second}
          />
          <Image
            resizeMode="contain"
            style={styles.image}
            source={images.third}
          />
        </View>
        <View style={styles.buttonWrapper}>
          {!!invoice?.discount && (
            <Text style={styles.discountText}>
              {Resources.strings.formatString(
                Resources.strings['onboarding-screen-boost-discount'],
                invoice.discount,
              )}
            </Text>
          )}
          {!!invoice && (
            <GradientButton
              disabled={isLoading}
              onPress={onSubmit}
              buttonStyles={styles.button}
            >
              <Text style={styles.buttonTitle}>
                {Resources.strings.formatString(
                  Resources.strings['onboarding-screen-boost-button-title'],
                  getBoostTime(invoice?.boostTime),
                )}
              </Text>
              <View style={styles.buttonContent}>
                <Text style={styles.priceContainer}>
                  {!!priceWithoutDiscount && (
                    <Text
                      style={[
                        styles.oldPrice,
                        isLoading && styles.disabledText,
                      ]}
                    >
                      {priceWithoutDiscount}
                    </Text>
                  )}
                  <Text
                    style={[
                      styles.currentPrice,
                      isLoading && styles.disabledText,
                    ]}
                  >
                    {' '}
                    {localizedPrice}
                  </Text>
                </Text>
                {invoice?.price?.currency === SupportedMethods.credits && (
                  <Image
                    style={styles.creditImg}
                    source={Resources.images.coinIcon()}
                    resizeMode="contain"
                  />
                )}
              </View>
            </GradientButton>
          )}
          <Image
            resizeMode="contain"
            style={styles.thirdStarImage}
            source={starImg}
          />
          <Image
            resizeMode="contain"
            style={styles.fourthStarImage}
            source={starImg}
          />
          <Image
            resizeMode="contain"
            style={styles.fifthStarImage}
            source={starImg}
          />
        </View>
        {isLoading && (
          <View style={styles.activityIndicatorContainer}>
            <ActivityIndicator animating />
          </View>
        )}
      </ScrollView>
    );
  }
}
