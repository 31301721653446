function getDisplayDate(timestamp, withYear = false, forceDate = false) {
  if (!timestamp) {
    return null;
  }

  const a = new Date(timestamp);

  if (!forceDate && a.toDateString() === new Date().toDateString()) {
    return `${(a.getHours() < 10 ? '0' : '') + a.getHours()}:${(a.getMinutes() <
    10
      ? '0'
      : '') + a.getMinutes()}`;
  }

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const date = a.getDate();
  const month = months[a.getMonth()];

  if (withYear === true) {
    const year = a.getFullYear();

    return `${month} ${date}, ${year}`;
  }

  return `${date} ${month}`;
}

export default getDisplayDate;
