import { combineLatest, Subject, Subscription, of } from 'rxjs';
import { filter, distinctUntilChanged, switchMap, mapTo } from 'rxjs/operators';
import Session from '@sdv/domain/authorization/session';
import flux from '@sdv/domain/app/flux';
import { Config } from '@sdv/domain/app/config';
import BalanceRefiller, {
  PAYMENT_REASON,
} from '@sdv/domain/app/balance-refiller';
import { Persistence } from '@sdv/domain/app/persistence';
import Service from '../service';

// TODO: TMP Hack
export const subscriptionRequiredForMessages = new Subject();

export class PaidMessagesTracker extends Service {
  static displayName = 'service.tracking.payed-messages';

  componentDidMount() {
    this.subscribe();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  subscribe() {
    const isUserInExperimentKey = 'isUserInExperiment';

    const shouldTrackUserExperiment = Session.shared().userId.pipe(
      filter(Boolean),
      switchMap(id => {
        const persistence = Persistence.shared('paid-messages-tracker', id);

        return persistence.load(isUserInExperimentKey).pipe(
          switchMap(isUserInExperiment => {
            if (isUserInExperiment) {
              return of(false);
            }

            return persistence
              .store(isUserInExperimentKey, true)
              .pipe(mapTo(true));
          }),
        );
      }),
      filter(Boolean),
    );

    const payedMessagesSubscription = combineLatest(
      Session.shared().userId,
      Config.shared().unlimitedMessagesWithMembershipEnabled,
      shouldTrackUserExperiment,
    ).subscribe(
      ([id, userIn]) => {
        if (id) {
          this.trackPayedMessagesUserIn(id, userIn);
        }
      },
      () => {},
    );

    const subscriptionRequiredSubscription = combineLatest(
      Session.shared().userId,
      subscriptionRequiredForMessages.pipe(distinctUntilChanged()),
    ).subscribe(
      ([id, subscriptionRequired]) => {
        if (id && subscriptionRequired) {
          this.trackPaymentBannerShowed(id);
        }
      },
      () => {},
    );

    const paymentScreenShowedSubscription = combineLatest(
      Session.shared().userId,
      BalanceRefiller.balanceRefiller.refillBalanceReason.pipe(
        filter(reason => reason === PAYMENT_REASON.SEND_MESSAGE),
      ),
    ).subscribe(
      ([id]) => {
        if (id) {
          this.trackSubscribeButtonTapped(id);
        }
      },
      () => {},
    );

    this.disposeBag = new Subscription();
    this.disposeBag.add(payedMessagesSubscription);
    this.disposeBag.add(subscriptionRequiredSubscription);
    this.disposeBag.add(paymentScreenShowedSubscription);
  }

  unsubscribe() {
    if (this.disposeBag) {
      this.disposeBag.unsubscribe();
    }
  }

  trackPayedMessagesUserIn(userId, userIn) {
    flux.api.annals.add(userId, 'payed-messages-experiment-user-in', {
      userIn,
    });
  }

  trackPaymentBannerShowed(userId) {
    flux.api.annals.add(
      userId,
      'payed-messages-experiment-payment-banner-showed',
      { timestamp: new Date() },
    );
  }

  trackSubscribeButtonTapped(userId) {
    flux.api.annals.add(
      userId,
      'payed-messages-experiment-subscribe-button-tapped',
      { timestamp: new Date() },
    );
  }
}
