import React from 'react';
import withUser from 'dating-mobile/src/models/user/controllers';
import PropTypes from 'prop-types';

export default function createUserIsReadyController(Component) {

    class Controller extends React.PureComponent {
        static displayName = 'user.common.views.draw-when-user-ready.controller';
        static propTypes = {
            user: PropTypes.object,
        };

        static getIsHasId(obj) {
            return Boolean(obj && obj.id)
        }

        render() {
            const { user, ...props } = this.props;
            return (<Component
                {...props}
                isReady={Controller.getIsHasId(user)}
            />);
        }
    }

    return withUser(
        Controller,
        { userIdPropName: 'userId' }
    );
}
