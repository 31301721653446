import React from 'react';
import { Text, View } from 'react-native';
import styles from './styles';
import Photo from '../../../../components/photo';
import DictValuesController from '../../../../models/misc-data/controller';

const InterestsView = (props) => {
    let content = [];

    if (props.userInterests) {

        const itemTitleStyle = [styles.interestItemText, props.itemTitleStyle || {}];

        for (let i = 0; i < props.userInterests.length; i++) {
            let item = props.userInterests[i];
            let text = props.dictValues[item];

            content.push(<View key={String(item)} style={styles.interestsItemContainer}>
                <Photo style={styles.interestItemImage} forceRetina={true} path={`/interests/${item}`}/>
                <Text style={itemTitleStyle}>{text}</Text>
            </View>);

        }

    }

    return (
        <View style={styles.interestsContainer}>
            {content}
        </View>
    );

};

export default DictValuesController(InterestsView);
