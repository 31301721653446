import connect from '@sdv/connect';
import UnreadLettersCounterModel from '@sdv/domain/dialogs.mailbox/unread-letters-counter';
import qs from 'qs';
import PropTypes from 'prop-types';
import React from "react";

export default function createController(Component) {
    class Controller extends React.Component {
        static displayName = 'inbox.views.unread-letters-indicator.controller';
        static propTypes = {
            actualizeIndicator: PropTypes.func,
            userId: PropTypes.string
        };
        static contextTypes = {
            flux: PropTypes.object
        };

        componentDidMount() {
            this.props.actualizeIndicator && this.props.actualizeIndicator();
        }

        componentWillReceiveProps(nextProps) {
            if (this.props.userId !== nextProps.userId) {
                this.props.actualizeIndicator && this.props.actualizeIndicator();
            }
        }

        render() {
            return (
                <Component
                    {...this.props}
                />
            );
        }
    }

    function getModels(flux, props) {
        const models = {};

        if (props.userId) {
            models.unreadLettersCounterModel = flux.get(UnreadLettersCounterModel, props.userId);
        }

        return models;
    }

    function mapStoresToProps(models) {
        const props = {};

        if (models.unreadLettersCounterModel) {
            props.showIndicator = models.unreadLettersCounterModel.store.getState().unreadLettersAmount > 0;
        }

        return props;
    }

    function mapActionsToProps(models) {
        const props = {};

        if (models.unreadLettersCounterModel) {
            props.actualizeIndicator = models.unreadLettersCounterModel.actions.actualize;
        }

        return props;
    }

    return connect(getModels, mapStoresToProps, mapActionsToProps)(Controller);
}
