import React, { PureComponent } from 'react';
import { TouchableOpacity, Text, Image, View, StyleSheet } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import PropTypes from 'prop-types';
import styles from './styles';

class Button extends PureComponent {
  static propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    icon: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    disabled: PropTypes.bool,
    style: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    iconStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    titleStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    onPress: PropTypes.func,
    onLayout: PropTypes.func,
    textProps: PropTypes.object,
    disabledGradientColor: PropTypes.array,
    children: PropTypes.node,
  };

  render() {
    const { children, textProps = {}, ...props } = this.props;
    const disabled = props.disabled !== undefined && !!props.disabled;
    const externalStyle = props.style || {};

    const contentStyle = disabled
      ? styles.disabledContent
      : [styles.container, styles.activeContent, externalStyle];

    const textStyle = [styles.title, disabled ? styles.disabledModifier : {}];

    const content = children || [
      props.icon && (
        <Image
          key="icon"
          style={[styles.icon, props.iconStyle || {}]}
          source={props.icon}
        />
      ),
      <Text
        key="text"
        style={[textStyle, props.titleStyle || {}]}
        {...textProps}
      >
        {props.title}
      </Text>,
    ];

    const container = props.onPress ? (
      <TouchableOpacity
        accessibilityLabel={props.accessibilityLabel}
        testID={props.testID}
        onPress={props.onPress}
        disabled={disabled}
        style={contentStyle}
        onLayout={props.onLayout}
      >
        {content}
      </TouchableOpacity>
    ) : (
      <View
        accessibilityLabel={props.accessibilityLabel}
        testID={props.testID}
        style={contentStyle}
        onLayout={props.onLayout}
      >
        {content}
      </View>
    );

    return disabled ? (
      <LinearGradient
        colors={
          props.disabledGradientColor ||
          StyleSheet.flatten(styles.disabledGradient).gradientColor
        }
        style={[styles.container, externalStyle]}
      >
        {container}
      </LinearGradient>
    ) : (
      container
    );
  }
}

export default Button;
