import React from 'react';
import PropTypes from 'prop-types';
import withConfigValue from 'dating-mobile/src/components/config-value';
import withHasTag from 'dating-mobile/src/models/user.tags/controllers/has-tag';
import withDevicePermissions from 'dating-mobile/src/models/device.permissions/controller';
import { UserTags } from '@sdv/domain/user/tags/user-tags';
import VersionNumber from 'react-native-version-number';
import isTestBuild from 'dating-mobile/src/utils/is-test-build';

export default function createController(Component) {
  class Controller extends React.Component {
    static displayName = 'user.screens.account.controller';

    static propTypes = {
      userId: PropTypes.string,
      membershipIsInactive: PropTypes.bool,
      purchaseCredits: PropTypes.func,
      subscribe: PropTypes.func,
    };

    static contextTypes = {
      flux: PropTypes.object,
    };

    getAppVersion = () => {
      const { appVersion, buildVersion } = VersionNumber;

      return isTestBuild
        ? `Build version: ${appVersion} (${buildVersion})`
        : '';
    };

    render() {
      const { userId } = this.props;

      if (!userId) {
        return null;
      }

      return <Component {...this.props} appVersion={this.getAppVersion()} />;
    }
  }

  return withConfigValue(
    withHasTag(
      withHasTag(
        withHasTag(
          withHasTag(withDevicePermissions(Controller), {
            tag: UserTags.Boosted,
            userIdPropName: 'userId',
            hasTagPropName: 'userIsBoosted',
          }),
          {
            tag: UserTags.Membership,
            userIdPropName: 'userId',
            hasTagPropName: 'membershipIsInactive',
          },
        ),
        {
          tag: UserTags.PremiumMembership,
          userIdPropName: 'userId',
          hasTagPropName: 'premiumMembershipIsActive',
        },
      ),
      {
        tag: UserTags.CallsPackIsActive,
        userIdPropName: 'userId',
        hasTagPropName: 'callsPackIsActive',
      },
    ),
    {
      boostProfileEnabled: 'features.boost-profile-enabled',
      restorePurchasesEnabled: 'features.restore-purchases-enabled',
      premiumSubscriptionEnabled: 'features.premium-subscription-enabled',
      callsPackEnabled: 'features.calls-pack-enabled',
      creditsEnabledForFeatures: 'features.credits-enabled-for-features',
    },
  );
}
