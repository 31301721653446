import EventEmitter from 'eventemitter3';
import IdentityModel from '@sdv/domain/identity/model';
import OS from '@sdv/domain/app/os';

const TIMEOUT = 10000;
const RECONNECT_INTERVAL = 5000;
const EVENT_NAME = 'event';

class MessageEmitter {
  constructor(source) {
    this.source = source;
  }

  addListener(callback) {
    this.source.addListener(EVENT_NAME, callback);
  }

  removeListener(callback) {
    this.source.remoteListener(EVENT_NAME, callback);
  }

  removeAllListeners() {
    this.source.removeAllListeners(EVENT_NAME);
  }
}

function cancelable(fn) {
  let prevCallback = null;

  return (...args) => {
    const callback = args.pop();

    if (prevCallback) {
      const err = new Error('Request has been canceled');

      err.canceled = true;
      prevCallback.canceled = true;
      prevCallback(err);
    }

    prevCallback = callback;

    return fn(...args, (err, res) => {
      if (!callback.canceled) {
        callback(err, res);
        prevCallback = null;
      }
    });
  };
}

class ReconnectableWebSocketConnection {
  isOpened = false;

  isClosedManually = false;

  messages = null;

  onClose = null;

  onOpen = null;

  retry = 0;

  constructor(makeUrl, flux, userAgent, maxRetry, fallback) {
    this.makeUrl = makeUrl;
    this.getShards = cancelable(flux.api.shards.get.bind(flux.api.shards));
    this.useAgent = userAgent;
    this.maxRetry = maxRetry;
    this.fallback = fallback;

    this.eventEmitter = new EventEmitter();
    this.messages = new MessageEmitter(this.eventEmitter);

    this.model = flux.get(IdentityModel);
    this.model.store.listen(this.update);
    this.update(this.model.store.getState());
  }

  close = () => {
    this.closeWebSocket();
    this.model.store.unlisten(this.update);
    this.messages.removeAllListeners();
    this.isClosedManually = true;
  };

  update = state => {
    if (state.id !== this.identity) {
      this.identity = state.id;

      if (this.isOpened) {
        this.closeWebSocket();
      }

      if (this.identity) {
        this.connect(this.identity);
      }
    }
  };

  checkConnection = () => {
    this.timeoutId = null;

    if (this.isOpened || this.isClosedManually) {
      return;
    }

    this.closeWebSocket();

    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.timer = null;
    this.cancelled = true;

    this.connect(this.identity);
  };

  connect(userId, lastFailedShard) {
    if (this.isOpened || this.isClosedManually) {
      return;
    }

    if (this.maxRetry && ++this.retry > this.maxRetry) {
      if (this.fallback) {
        this.fallback();
      }

      return;
    }

    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.timeoutId = setTimeout(this.checkConnection, TIMEOUT);
    this.cancelled = false;

    this.getShards(userId, { exclude: lastFailedShard }, (err, result) => {
      const { key, shard } = result || {};

      if (this.isClosedManually || this.isOpened || this.cancelled) {
        return;
      }

      if (err) {
        if (!err.canceled) {
          this.reconnect(userId, shard);
        }

        return;
      }

      // Prevents a race condition in case if previous connection was started,
      // but still not established by this time and 'onopen' event was not triggered
      if (this.ws) {
        return;
      }

      const url = this.makeUrl(key, shard);

      this.ws = new WebSocket(url, null, {
        headers:
          OS.shared().current !== 'web' ? { 'user-agent': this.useAgent } : {},
      });

      this.ws.onopen = () => {
        this.isOpened = true;
        this.retry = 0;

        if (this.onOpen) {
          this.onOpen();
        }
      };

      this.ws.onmessage = event => {
        this.eventEmitter.emit(EVENT_NAME, event);
      };

      this.ws.onerror = () => {
        if (this.isOpened && this.onClose) {
          this.onClose();
        }

        this.isOpened = false;
      };

      this.ws.onclose = () => {
        if (this.isOpened && this.onClose) {
          this.onClose();
        }

        this.isOpened = false;
        this.reconnect(userId, shard);
      };
    });
  }

  send(message) {
    if (this.isOpened) {
      this.ws.send(message);
    }

    return this.isOpened;
  }

  reconnect(userId, shard) {
    this.closeWebSocket();

    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      this.connect(userId, shard);
    }, RECONNECT_INTERVAL);
  }

  closeWebSocket() {
    if (this.ws) {
      if (this.timer) {
        clearTimeout(this.timer);
      }

      this.timer = null;
      this.ws.onopen = undefined;
      this.ws.onmessage = undefined;
      this.ws.onerror = undefined;
      this.ws.onclose = undefined;
      this.ws.close();
      this.ws = null;

      // We should call 'onClose' callback for the correct reconnection process.
      // this.isOpened === true means that onOpen method was called, so we have to call onClose as well.
      if (this.isOpened && this.onClose) {
        this.onClose();
      }

      this.isOpened = false;
    }
  }
}

export default ReconnectableWebSocketConnection;
