import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        backgroundColor: '$brandColor',
        flex: 1,
        justifyContent: 'center',
        flexDirection: 'column'
    },
    background: {
        position: 'absolute',
        width: '100%',
        height: '100%'
    },
    collageContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    itemsContainer: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingHorizontal: 40
    },
    descriptionText: {
        color: '$brandTextColor',
        fontSize: 26,
        fontWeight: 'bold',
        fontFamily: '$brandFontFamily',
        textAlign: 'center',
        marginBottom: 88
    },
    notNowButtonText: {
        color: '$brandTextColor',
        fontSize: 18,
        fontWeight: 'normal',
        fontFamily: '$brandFontFamily',
        textAlign: 'center',
        marginTop: 20,
        marginBottom: 40,
        '@flavor lovinga': {
            color: '$minorAccentColor'
        }
    }
});

export default styles;
