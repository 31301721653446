import React from 'react';
import { Text, View } from 'react-native';
import PropTypes from 'prop-types';
import type from '@sdv/commons/utils/type';
import TermsAndPrivacyText from 'dating-mobile/src/components/terms-and-privacy-text';
import { AgeAgreementText } from 'dating-mobile/src/components/age-agreement-text';

// TODO перенести конфиг в screen
import Resources from '../../../resources';
import Button from '../../../components/buttons/base';
import TextInput from '../../../components/text-input';
import testId from '../../../utils/test-id';
import shouldUseWebLayout from '../../../utils/web-layout';
import { FocusScroll } from '../focus-scroll';

import styles from './styles';

const getError = error => {
  if (type.isString(error) || !error) {
    return error;
  }

  if (error.desc) {
    return error.desc;
  }

  return error.message;
};

class SignUp extends React.Component {
  static displayName = 'identity.signup.from';

  static contextTypes = {
    flux: PropTypes.object,
  };

  state = {
    uri: null,
  };

  static propTypes = {
    error: PropTypes.object,
    email: PropTypes.string,
    password: PropTypes.string,
    name: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
    handlerChange: PropTypes.func.isRequired,
    userNameMaxLength: PropTypes.number,
    buttonTitle: PropTypes.string,
    title: PropTypes.string,
    isPending: PropTypes.bool,
  };

  static defaultProps = {
    buttonTitle:
      Resources.strings['initial-screen-sign-up-default-button-title'],
    title: Resources.strings['sign-up-screen-title'],
  };

  constructor(props) {
    super(props);
    this.mandatoryFields = ['name', 'email', 'password'];
    this.handleChange = {};
    this.validate = {};
    this.state = { termsAgreed: true };
    this.mandatoryFields.forEach(name => {
      this.validate[name] = props.validate(name);
      this.handleChange[name] = props.handlerChange(name);
    });
  }

  shouldComponentUpdate(props, state) {
    return (
      [...this.mandatoryFields, 'error', 'isPending'].some(key =>
        this.isUpdated(props, key),
      ) ||
      ['uri'].some(key => {
        const { [key]: stateVar } = this.state;

        return state[key] !== stateVar;
      })
    );
  }

  onTermsAgreed = termsAgreed => {
    this.setState({ termsAgreed }, () => {
      this.forceUpdate();
    });
  };

  focusEmailInput = () => {
    if (this.emailInput) this.emailInput.focus();
  };

  focusPasswordInput = () => {
    if (this.passwordInput) this.passwordInput.focus();
  };

  onSubmit = () => {
    const { onSubmit, isPending } = this.props;

    if (!this.isEmpty() && !isPending) {
      onSubmit();
    }
  };

  isEmpty() {
    const { termsAgreed } = this.state;

    const hasEmptyFields = this.mandatoryFields.some(key => {
      const { [key]: prop } = this.props;

      return !prop || !prop.length;
    });

    return hasEmptyFields || !termsAgreed;
  }

  isUpdated(props, name) {
    const { [name]: prop } = this.props;

    return props[name] !== prop;
  }

  render() {
    const {
      error,
      userNameMaxLength,
      buttonTitle,
      title,
      isPending,
      name,
      email,
      password,
    } = this.props;
    const serverError = getError(error.server);

    return (
      <FocusScroll
        keyboardShouldPersistTaps="handled"
        alwaysBounceVertical={false}
        style={styles.container}
        contentContainerStyle={styles.contentContainer}
      >
        {({ onFocus, onBlur }) => (
          <React.Fragment>
            <View style={styles.inputs}>
              {styles.$signUpTitleEnabled && !!title && (
                <Text style={styles.title} {...testId('Sign up screen title')}>
                  {title}
                </Text>
              )}
              <TextInput
                style={styles.input}
                containerStyle={styles.containerInput}
                placeholder={
                  Resources.strings['sign-up-screen-name-input-placeholder']
                }
                value={name}
                textContentType="name"
                returnKeyType="next"
                autoCapitalize="words"
                onChange={this.handleChange.name}
                onBlur={this.validate.name}
                error={error.name}
                maxLength={userNameMaxLength}
                onSubmitEditing={this.focusEmailInput}
                blurOnSubmit={false}
                {...testId('Name input')}
              />
              <TextInput
                forwardRef={input => {
                  this.emailInput = input;
                }}
                style={styles.input}
                containerStyle={styles.containerInput}
                placeholder={
                  Resources.strings['sign-up-screen-email-input-placeholder']
                }
                keyboardType="email-address"
                value={email}
                autoCapitalize="none"
                textContentType="username"
                returnKeyType="next"
                onChange={this.handleChange.email}
                onBlur={this.validate.email}
                error={error.email || serverError}
                onSubmitEditing={this.focusPasswordInput}
                blurOnSubmit={false}
                {...testId('Email input')}
              />
              <TextInput
                forwardRef={input => {
                  this.passwordInput = input;
                }}
                style={styles.input}
                containerStyle={styles.containerInput}
                placeholder={
                  Resources.strings['sign-up-screen-password-input-placeholder']
                }
                value={password}
                autoCapitalize="none"
                secureTextEntry
                textContentType="password"
                returnKeyType="done"
                error={error.password}
                onChange={this.handleChange.password}
                onFocus={onFocus}
                onBlur={() => {
                  onBlur();
                  this.validate.password();
                }}
                onSubmitEditing={this.onSubmit}
                {...testId('Password input')}
              />
            </View>

            <View style={styles.bottom}>
              {!!styles.$signUpTermsVisible && (
                <TermsAndPrivacyText
                  halfWidth={shouldUseWebLayout()}
                  targetButtonTitle={buttonTitle}
                />
              )}
              {!!styles.$signUpAgeTermsVisible && (
                <AgeAgreementText
                  onEnabledChanged={this.onTermsAgreed}
                  containerStyle={styles.ageAgreement}
                />
              )}
              <Button
                disabled={this.isEmpty() || isPending}
                title={buttonTitle}
                onPress={this.onSubmit}
                {...testId('Sign up button')}
              />
              <View style={styles.bottomView} />
            </View>
          </React.Fragment>
        )}
      </FocusScroll>
    );
  }
}

export default SignUp;
