import Actions, { generateTag } from './actions';

function CreateRoomStore(id) {
    class Store  {
        constructor(flux) {
            const actions = flux.getActions(Actions, id);
            this.state = {
                messages: [],
                cheers: [],
                diamonds: 0
            };
            this.bindAction(actions.set, this.set);
            this.bindAction(actions.updateMessage, this.updateMessage);
            this.bindAction(actions.send, this.updateMessage);
            this.bindAction(actions.sendSystemMessage, this.updateSystemMessage);
            this.bindAction(actions.changeStatus, this.updateStatus);
            this.bindAction(actions.removeMessagesFromUser, this.removeMessagesFromUser);
        };

        set = (payload) => {
            if (!payload) {
                return;
            }

            const messages = payload.messages || [];
            messages.push({
                sender: 'system',
                recipient: id,
                public: true,
                status: 1,
                timestamp: (new Date()).getTime(),
                tag: generateTag('system'),
                text: '',
                meta: {
                    room: id,
                    reference: 'text:///local/nudity-warning'
                },
            });
            messages.reverse();
            payload.messages = messages;

            this.setState({...this.state, ...payload});
        };

        updateSystemMessage = (content) => {
            const message = {
                sender: 'system',
                recipient: id,
                public: true,
                status: 1,
                timestamp: (new Date()).getTime(),
                tag: generateTag('system'),
                text: '',
                meta: {
                    room: id,
                    system: content,
                },
            };

            this.setState({
                messages: [message, ...this.state.messages]
            })
        };

        updateMessage = (updatedMessage) => {
            if (!updatedMessage) {
                return;
            }

            const state = this.state,
                messages = state.messages.slice(),
                cheers = state.cheers.slice();
            let diamonds = state.diamonds;

            const messageIndex = messages.findIndex(message => message.tag === updatedMessage.tag);
            if (messageIndex >= 0) {
                messages.splice(messageIndex, 1);
            } else {
                updatedMessage.recent = true;
            }
            messages.unshift(updatedMessage);

            if (messageIndex < 0 && updatedMessage.meta && updatedMessage.meta.transfer) {
                let cheerIndex = cheers.findIndex(cheer => cheer.user === updatedMessage.sender);
                if (cheerIndex >= 0) {
                    let cheer = cheers[cheerIndex];
                    cheer.amount += updatedMessage.meta.transfer;
                } else {
                    cheers.push({
                        amount: updatedMessage.meta.transfer,
                        user: updatedMessage.sender
                    });
                }
                diamonds += updatedMessage.meta.transfer;
            }

            this.setState({
                messages: messages,
                cheers: cheers,
                diamonds: diamonds
            });
        };

        updateStatus = (status) => {
            if (typeof status !== 'string') {
                return;
            }

            this.setState({
                status: status
            })
        };

        removeMessagesFromUser = (user) => {
            if (typeof user !== 'string') {
                return;
            }

            const messages = this.state.messages.slice();
            let changed = false;

            for (let index = 0; index < messages.length; index++) {
                const oldMessage = messages[index];
                if (oldMessage.sender !== user) {
                    continue;
                }

                changed = true;

                const { ...message } = oldMessage;
                message.sender = 'system';
                message.text = '';
                message.meta = {
                    room: id,
                    reference: 'text:///local/deleted'
                };

                messages[index] = message;
            }

            if (changed) {
                this.setState({
                    messages: messages
                });
            }
        };
    }

    Store.displayName = CreateRoomStore.getDisplayName(id);
    return Store;
}

CreateRoomStore.getDisplayName = function (id) {
    return `dialogs-room.${id}`;
};

export default CreateRoomStore;
