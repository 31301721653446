import React from 'react';
import Can from 'dating-mobile/src/models/user.ability/controller';

export default function canCreateMessage(Component) {

    class CanCreateMessage extends React.Component {
        static displayName = 'dialogs.chats.my-chats.can-create-message';

        render() {
            return (
                <Can do={'create'} of={'message'}>
                    {
                        status => (
                            <Component
                                {...this.props}
                                canChat={status}
                            />
                        )
                    }
                </Can>
            );
        }
    }

    return CanCreateMessage
}
