import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $tabBarActiveColor: '$selectedColor',
    $tabBarInactiveColor: '$tabBarIconInactiveColor',

    container: {
        flexDirection: 'row',
        height: 32
    },

    icon: {
        width: 24,
        height: 24,
        alignSelf: 'center'
    },

    activeIcon: {
        tintColor: '$tabBarActiveColor'
    },

    inactiveIcon: {
        tintColor: '$tabBarInactiveColor'
    },

    unreadLetterIndicator: {
        marginLeft: -10,
        marginTop: 3
    }
});

export default styles;
