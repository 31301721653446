import React from 'react';
import Resources from 'dating-mobile/src/resources';
import SwitchItem from 'dating-mobile/src/components/switch-item';

export default class EditEmailNavigationItem extends React.PureComponent {
    static displayName = 'user.views.user-visibility-in-feed.views.hide-user-from-feed-switch-item';

    render() {
        return (
            <SwitchItem
                title={Resources.strings['hide-user-from-feed-switch-item-title']}
                {...this.props}
            />
        );
    }
}
