import React from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity } from 'react-native';
import styles from './styles';
import Smile from '../../smile';

export default class SmilesItem extends React.Component {
    static displayName = 'dialogs.common.views.smiles.item';
    static propTypes = {
        smile: PropTypes.shape({
            basename: PropTypes.string.isRequired
        }),
        onPress: PropTypes.func
    };

    shouldComponentUpdate(nextProps) {
        const smile = this.props.smile || {};
        const nextSmile = nextProps.smile || {};
        return smile.basename !== nextSmile.basename;
    }

    onPressed = () => {
        this.props.onPress && this.props.onPress(this.props.smile);
    };

    render() {
        const smile = this.props.smile || {};

        return (
            <TouchableOpacity style={styles.container} onPress={this.onPressed}>
                <Smile smileName={smile.basename} style={styles.smile} />
            </TouchableOpacity>
        );
    }
}

export function getWidth() {
    return styles._container.width + 2 * styles._container.margin;
}
