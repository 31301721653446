import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $statusBarStyle: '$primaryStatusBarStyle',
    $statusBarBackgroundColor: '$primaryBackgroundColor',

    header: {
        backgroundColor: '$primaryBackgroundColor',
        borderBottomColor: '$primaryBackgroundColor',
        shadowColor: '$primaryBackgroundColor',
        shadowRadius: 0,
        shadowOpacity: 0,
        elevation: 0,
        shadowOffset: {
            height: 0,
        }
    },

    headerTitle: {
        color: '$primaryTextColor',
        fontFamily: '$brandFontFamily'
    },

    container: {
        backgroundColor: '$primaryBackgroundColor',
        flex: 1,
    },

    scroll: {
        flexGrow: 1
    },

    scrollContent: {
        flexGrow: 1,
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'space-between'
    },

    description: {
        marginTop: 24,
        marginHorizontal: 40,
        fontSize: 24,
        textAlign: 'center',
        fontWeight: 'bold',
        color: '$primaryTextColor',
        fontFamily: '$brandFontFamily',
        '@flavor dma': {
            fontSize: 28,
            marginTop: 20
        }
    },

    preferredControlsContainer: {
        marginTop: 24,
        marginHorizontal: 40,
        flexDirection: 'column',
        alignItems: 'center'
    },

    preferredGenderTitle: {
        fontSize: 20,
        textAlign: 'center',
        color: '$primaryTextColor',
        fontFamily: '$brandFontFamily',
    },

    preferredGenderControlsContainer: {
        marginTop: 24,
        flexDirection: 'row'
    },

    genderButton: {
        marginHorizontal: 12,
        backgroundColor: '$unselectedColor',
        width: 72,
        height: 72,
        borderRadius: 36,
        alignItems: 'center',
        justifyContent: 'center'
    },

    selectedMaleGenderButton: {
        backgroundColor: '$minorAccentColor',
        '@flavor dma': {
            backgroundColor: '$brandLinkTextColor'
        }
    },

    selectedFemaleGenderButton: {
        backgroundColor: '$femaleAccentColor'
    },

    genderButtonIcon: {
        tintColor: '$disabledTextColor'
    },

    selectedGenderButtonIcon: {
        tintColor: 'white'
    },

    preferredAgeTitle: {
        marginTop: 37,
        fontSize: 20,
        textAlign: 'center',
        color: '$primaryTextColor',
        fontFamily: '$brandFontFamily'
    },

    preferredAgeControlsContainer: {
        marginTop: 24,
        flexDirection: 'row'
    },

    ageButton: {
        marginHorizontal: 12,
        backgroundColor: '$infoTextColor',
        width: 72,
        height: 72,
        borderRadius: 36,
        alignItems: 'center',
        justifyContent: 'center'
    },

    ageButtonTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: '$brandFontFamily',
        color: 'white'
    },

    messagesContainer: {
        marginTop: 24,
        height: 72,
        marginBottom: 16,
        '@flavor dma': {
            height: 92,
            marginBottom: 32
        }
    },

    chooseMessageButton: {
        alignSelf: 'center',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: 'white',
        marginTop: 24,
        height: 48,
        borderRadius: 24,
        marginBottom: 16,
        paddingHorizontal: 22,
        shadowRadius: 8,
        shadowOpacity: 0.15,
        shadowOffset: { width: 0, height: 0 },
        elevation: 8,
        '@flavor dma': {
            borderRadius: 16
        }
    },

    chooseMessageButtonTitle: {
        fontSize: 16,
        fontFamily: '$brandFontFamily',
        color: '$infoTextColor',
        '@flavor dma': {
            color: '$brandTextColor',
            fontWeight: 'bold'
        }
    },

    activityIndicatorContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    }

});

export default styles;
