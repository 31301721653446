import * as events from 'events';

class RealtimeDialogsStreamsPipeline extends events.EventEmitter {
    constructor(realtime) {
        super();
        this.realtime = realtime;
    }

    attachCoStreamer(room, attached) {
        this.realtime.notify('command.dialogs.streams.attachment.add', {
            room: room,
            attached: attached,
        });
    }

    detachCoStreamer(room, attached) {
        this.realtime.notify('command.dialogs.streams.attachment.remove', {
            room: room,
            attached: attached,
        });
    }

    setRoomTitle(room, title, success, failure) {
        this.realtime.sendRequest(
            'command.dialogs.streams.title.set',
            {
                id: room,
                title: title,
            },
            success,
            failure,
        );
    }

    setRoomCover(room, cover, success, failure) {
        this.realtime.sendRequest(
            'command.dialogs.streams.cover.set',
            {
                id: room,
                cover: cover,
            },
            success,
            failure,
        );
    }

    setRoomTopics(room, topics, success, failure) {
        this.realtime.sendRequest(
            'command.dialogs.streams.tags.set',
            {
                id: room,
                tags: topics,
            },
            success,
            failure,
        );
    }

    sendMediasoupRequest(room, session, request, success, error) {
        let payload = {};

        payload.room = room;
        payload.session = session;
        payload.message = request;

        this.realtime.sendRequest('command.dialogs.streams.request', payload, success, error);
    }

    sendMediasoupNotification(room, session, message) {
        let payload = {};

        payload.room = room;
        payload.session = session;
        payload.message = message;

        this.realtime.notify('command.dialogs.streams.notify', payload);
    }

    getStream(room) {
        let payload = {};

        payload.id = room;

        this.realtime.notify('command.dialogs.streams.get', payload);
    }

    patchStream(room, payload) {
        this.realtime.notify('command.dialogs.rooms.patch', { ...payload, id: room });
    }
}

export default RealtimeDialogsStreamsPipeline;
