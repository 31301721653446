import { Platform } from 'react-native';

export const COMPLETED_STEP = -1;

export const INITIAL_STEP = 0;

export const REGISTRATION_STEP = Object.freeze({
  TRAVEL_DESTINATION: 1,
  UPDATE_USER: 2,
  USER_GOALS: 3,
  CHOOSE_PREFERENCES: 4,
  UPLOAD_PRIVATE_PHOTOS: 5,
  SEND_START_MESSAGES: 6,
  TOPICS_PICKING: 7,
  TOPICS_PICKING_BONUS: 8,
  BIRTHDAY_WITH_GENDER: 9,
  PHOTO_WITH_BIO: 10,
  SUBSCRIPTION: 11,
  BOOST: 12,
});

export const STEPPED_REGISTRATION_STEP = Object.freeze({
  PASSWORD: 1,
  NAME: 2,
  GENDER: 3,
  BIRTHDAY: 4,
  PHOTO: 5,
  GEOLOCATION: 6,
  NOTIFICATIONS: 7,
  START_SETTING_PREFERENCES: 8,
  USER_GOALS: 9,
  PREFERRED_PERSONALITY: 10,
  PREFERRED_AGE: 11,
  BODY_TYPE: 12,
  RELATIONSHIP_PRIORITY: 13,
  FIND_MATCHES: 14,
  SUBSCRIBE: 15,
  SEND_START_MESSAGES: 16,
});

export const ORDERED_STEPS = [
  STEPPED_REGISTRATION_STEP.PASSWORD,
  STEPPED_REGISTRATION_STEP.NAME,
  STEPPED_REGISTRATION_STEP.GENDER,
  STEPPED_REGISTRATION_STEP.BIRTHDAY,
  STEPPED_REGISTRATION_STEP.PHOTO,
  STEPPED_REGISTRATION_STEP.GEOLOCATION,
  STEPPED_REGISTRATION_STEP.NOTIFICATIONS,
  STEPPED_REGISTRATION_STEP.START_SETTING_PREFERENCES,
  STEPPED_REGISTRATION_STEP.USER_GOALS,
  STEPPED_REGISTRATION_STEP.PREFERRED_PERSONALITY,
  STEPPED_REGISTRATION_STEP.PREFERRED_AGE,
  STEPPED_REGISTRATION_STEP.BODY_TYPE,
  STEPPED_REGISTRATION_STEP.RELATIONSHIP_PRIORITY,
  STEPPED_REGISTRATION_STEP.FIND_MATCHES,
  STEPPED_REGISTRATION_STEP.SUBSCRIBE,
  STEPPED_REGISTRATION_STEP.SEND_START_MESSAGES,
];

export const getOrderedSteps = () => {
  return platformAdaptedSteps(ORDERED_STEPS);
};

export const getOauthOrderedSteps = () => {
  const steps = [
    STEPPED_REGISTRATION_STEP.GENDER,
    STEPPED_REGISTRATION_STEP.GEOLOCATION,
    STEPPED_REGISTRATION_STEP.NOTIFICATIONS,
    STEPPED_REGISTRATION_STEP.START_SETTING_PREFERENCES,
    STEPPED_REGISTRATION_STEP.USER_GOALS,
    STEPPED_REGISTRATION_STEP.PREFERRED_PERSONALITY,
    STEPPED_REGISTRATION_STEP.PREFERRED_AGE,
    STEPPED_REGISTRATION_STEP.BODY_TYPE,
    STEPPED_REGISTRATION_STEP.RELATIONSHIP_PRIORITY,
    STEPPED_REGISTRATION_STEP.FIND_MATCHES,
    STEPPED_REGISTRATION_STEP.SUBSCRIBE,
    STEPPED_REGISTRATION_STEP.SEND_START_MESSAGES,
  ];

  return platformAdaptedSteps(steps);
};

const platformAdaptedSteps = steps => {
  return Platform.OS === 'ios'
    ? steps
    : steps.filter(item => item !== STEPPED_REGISTRATION_STEP.NOTIFICATIONS);
};
