import React from 'react';
import PropTypes from 'prop-types';
import connect from '@sdv/connect';
import Model, { getId, EVENT_TYPE } from '../model';
import single from '../../../utils/single-execute';

const GET_PROPERTY = 'generated.user-events.events.get';

export default function createController(Component, options = {}) {
  const { type } = options;
  const userIdPropName = options.userIdPropName || 'userId';
  const eventsPropName = options.eventsPropName || 'events';
  const eventsLoadedPropName =
    options.eventsLoadedPropName || 'allEventsLoaded';

  class Events extends React.Component {
    static displayName = 'user-events.events';

    static propTypes = {
      [userIdPropName]: PropTypes.string,
      [eventsPropName]: PropTypes.array,
      [eventsLoadedPropName]: PropTypes.bool,
      [GET_PROPERTY]: PropTypes.func,
    };

    componentDidMount() {
      if (
        this.props[eventsLoadedPropName] === false &&
        this.props[GET_PROPERTY]
      ) {
        this.loadData();
      }
    }

    componentDidUpdate(prevProps) {
      if (shouldReconnect(prevProps, this.props)) {
        if (
          this.props[eventsLoadedPropName] === false &&
          this.props[GET_PROPERTY]
        ) {
          this.loadData();
        }
      }
    }

    loadData = () => {
      const tag = `${Events.displayName}.${
        this.props[userIdPropName]
      }:${type}.get`;

      single(tag, this.props[GET_PROPERTY]);
    };

    render() {
      const { ...props } = this.props;

      delete props[GET_PROPERTY];

      return <Component {...props} />;
    }
  }

  function getModels(flux, props) {
    const models = {};

    if (props[userIdPropName] && type) {
      models.eventsModel = flux.get(Model, getId(props[userIdPropName], type));
    }

    return models;
  }

  function mapStoresToProps(models) {
    const props = {};

    if (models.eventsModel) {
      const state = models.eventsModel.store.getState();

      props[eventsPropName] = state.events;
      props[eventsLoadedPropName] = state.allEventsLoaded;
    }

    return props;
  }

  function mapStoresToActions(models) {
    const props = {};

    if (models.eventsModel) {
      props[GET_PROPERTY] = models.eventsModel.actions.get;
    }

    return props;
  }

  function shouldReconnect(props, newProps) {
    return props[userIdPropName] !== newProps[userIdPropName];
  }

  return connect(
    getModels,
    mapStoresToProps,
    mapStoresToActions,
    shouldReconnect,
  )(Events);
}

export const contacts = (Component, options) =>
  createController(Component, {
    eventsPropName: 'contacts',
    eventsLoadedPropName: 'contactsLoaded',
    ...(options || {}),
    type: EVENT_TYPE.CONTACT,
  });

export const chatRequests = (Component, options) =>
  createController(Component, {
    eventsPropName: 'chatRequests',
    eventsLoadedPropName: 'chatRequestsLoaded',
    ...(options || {}),
    type: EVENT_TYPE.INVITATION,
  });

export const likes = (Component, options) =>
  createController(Component, {
    eventsPropName: 'likes',
    eventsLoadedPropName: 'likesLoaded',
    ...(options || {}),
    type: EVENT_TYPE.USER_LIKES,
  });

export const boostDiscount = (Component, options) =>
  createController(Component, {
    eventsPropName: 'boostDiscount',
    eventsLoadedPropName: 'boostDiscountLoaded',
    ...(options || {}),
    type: EVENT_TYPE.BOOST_DISCOUNT,
  });
