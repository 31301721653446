import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StatusBar } from 'react-native';
import { SafeAreaView } from 'react-navigation';
import FeedList from 'dating-mobile/src/feed/views/list';
import IfConfigValue from 'dating-mobile/src/components/config-value/if';

import styles from './styles';

export default class ScreenView extends PureComponent {
  static displayName = 'feed.screens.list.controller';

  static propTypes = {
    id: PropTypes.string.isRequired,
    isFocused: PropTypes.bool,
    users: PropTypes.array,
    navigationBarHidden: PropTypes.bool,
    setHeaderHidden: PropTypes.func,
    onItemPress: PropTypes.func,
    showHeader: PropTypes.bool,
    showFooter: PropTypes.bool,
    reload: PropTypes.func,
    isLoading: PropTypes.bool,
    hasError: PropTypes.bool,
    partialMatchLength: PropTypes.number,
  };

  static setBarStyle() {
    StatusBar.setBarStyle(styles.$statusBarStyle);
    StatusBar.setBackgroundColor(styles.$statusBarBackgroundColor);
  }

  componentDidMount() {
    const { isFocused } = this.props;

    this.setHeaderHidden(false);

    if (isFocused) {
      ScreenView.setBarStyle();
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isFocused } = this.props;

    if (!isFocused && nextProps.isFocused) {
      ScreenView.setBarStyle();
    }
  }

  setHeaderHidden = hidden => {
    const { setHeaderHidden } = this.props;

    if (setHeaderHidden) {
      setHeaderHidden(hidden);
    }
  };

  onItemPress = id => {
    const { onItemPress } = this.props;

    if (onItemPress) {
      onItemPress(id);
    }
  };

  render() {
    const {
      navigationBarHidden,
      showHeader,
      showFooter,
      id,
      users,
      isLoading,
      reload,
      hasError,
      partialMatchLength,
    } = this.props;
    const topInset = navigationBarHidden ? 'always' : 'never';

    return (
      <SafeAreaView
        forceInset={{ top: topInset, bottom: 'never' }}
        style={styles.container}
      >
        <IfConfigValue
          path="markup.feed-screen.hide-navigation-on-scroll"
          equalsTo={true}
        >
          {status => (
            <FeedList
              showFooter={showFooter}
              showHeader={showHeader}
              userId={id}
              users={users}
              setHeaderHidden={status ? this.setHeaderHidden : undefined}
              onItemPress={this.onItemPress}
              isLoading={isLoading}
              reload={reload}
              hasError={hasError}
              partialMatchLength={partialMatchLength}
            />
          )}
        </IfConfigValue>
      </SafeAreaView>
    );
  }
}
