import navigate, { navigateUnauthorized } from 'dating-mobile/src/routing/deeplinking';
import DeeplinkingService from './base';

export default class RoutingDeeplinkingService extends DeeplinkingService {
    static displayName = 'services.functional.deeplinking.routing';

    navigate(uri, userId) {
        return navigate(uri, userId);
    }

    navigateUnauthorized(uri, payload) {
        return navigateUnauthorized(uri, payload);
    }
}
