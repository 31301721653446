import React from 'react';
import PropTypes from 'prop-types';
import Service from '../../service';
import SmilesModel from '../../../models/dialogs.smiles/model';

export default class SmilesLoader extends Service {
    static displayName = 'service.smiles-loader';
    static contextTypes = {
        flux: PropTypes.object
    };

    retryTimer = null;

    constructor(props, context) {
        super(props);
        this.flux = context.flux;
        this.model = this.flux.get(SmilesModel);
    }

    componentDidMount() {
        this.model.store.listen(this.onSmilesChanged);
        this.fillState();
    }

    componentWillUnmount() {
        this.model.store.unlisten(this.onSmilesChanged);
        if (this.retryTimer) {
            clearTimeout(this.retryTimer);
        }
    }

    fillState = () => {
        this.model.actions.get(error => {
            if (error) {
                this.retryTimer = setTimeout(() => { this.fillState() }, 3000);
            }
        });
    };

    onSmilesChanged = (state) => {
        if (!state.smiles) {
            return;
        }

        // state.smiles.forEach(smile => this.flux.api.dialogs.smiles.get(smile.basename));
    };
}
