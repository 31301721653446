//  Модуль создания одного инстанса source
import Transport from '.';

let transport;

function get(api, bus) {
  if (transport) {
    return transport;
  }

  transport = new Transport(api, bus);

  return transport;
}

export default get;
