import React, { PureComponent } from 'react';

// TODO NEED IMPLEMENT CONTROLLER
export default function bindController(Component) {
    class CongratulationController extends PureComponent {
        static propTypes = {};

        render() {
            return <Component {...this.props} />;
        }
    }

    return CongratulationController;
}
