import api, { toObservable } from '@sdv/domain/api';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, first, flatMap, map, mapTo } from 'rxjs/operators';
import { getPayPalRedirectUrls, PayPalBootstrapper, PayPalGateway } from '@sdv/domain/app/payment/paypal';
import { Persistence } from '@sdv/domain/app/persistence';
import { shopName } from '@sdv/domain/app/payment/shop-info';

const persistenceKey = 'PayPalPayment';

export class PayPalPayment {

    /**
     *
     * @param params {{userId: string, invoice: object, savedReceiptToken: string}}
     */
    constructor(params) {
        this.payPalGateway = PayPalGateway.shared();
        this.payPalBootstrapper = PayPalBootstrapper.shared();
        this.invoice = params.invoice;
        this.userId = params.userId;
        this.result = new BehaviorSubject();
        this.receiptToken = new BehaviorSubject();

        const savedReceiptToken = params.savedReceiptToken;
        if (savedReceiptToken) {
            //restore state from persistence
            Persistence.shared(persistenceKey, this.userId).load(savedReceiptToken)
                .subscribe(
                    state => {
                        if (state) {
                            this.invoice = state.invoice;
                        }
                        this.receiptToken.next(savedReceiptToken);
                    }
                );
        }

        this.receiptToken
            .pipe(
                filter(token => !!token),
                flatMap(token => this.payPalGateway.requestPurchase(token)),
                first(),
                flatMap(purchaseResult => {
                    if (purchaseResult.success) {
                        return this.exchange(purchaseResult.token);
                    } else {
                        return of(false);
                    }
                }),
                catchError(error => of(false))
            )
            .subscribe(this.result);
    }

    /**
     *
     * @return {Observable<boolean>}
     */
    purchase() {
        return this.getPaypalReceiptToken()
            .pipe(
                flatMap(token => {
                    return Persistence.shared(persistenceKey, this.userId)
                        .store(token, this.dumpState())
                        .pipe(mapTo(token));
                }),
                flatMap(token => {
                    this.receiptToken.next(token);
                    this.payPalBootstrapper.restoreMeBy(token);
                    this.payPalGateway.requestPurchase(token);
                    return this.result;
                }),
                filter(evt => evt !== undefined),
                map(result => {
                    return { success: result };
                })
            );
    }

    dumpState() {
        return { invoice: this.invoice };
    }

    getPaypalReceiptToken() {
        const redirectUrls = getPayPalRedirectUrls();

        const receiptRequest = {
            amount: this.invoice.creditsAmount,
            price: this.invoice.price.includingTax,
            method: this.invoice.method,
            shop: shopName,
            sku: this.invoice.sku,
            requisites: {
                returnUrl: redirectUrls.success,
                cancelUrl: redirectUrls.cancel
            }
        };

        const getPayPalReceipt = this.callReceipt(receiptRequest)
            .pipe(
                map(receiptResult => receiptResult.receipt),
                catchError(error => of(false))
            );
        return getPayPalReceipt;
    }

    callReceipt(receiptRequest) {
        return toObservable(
            api.credits.mall.receipts.post,
            this.userId,
            receiptRequest
        );
    }

    exchange(payPalReceipt) {
        const receipt = {
            shop: shopName,
            method: this.invoice.method,
            receipt: payPalReceipt,
        };

        return this.callExchange(receipt).pipe(
            map(any => true),
            catchError(error => of(false))
        );
    }

    callExchange(receiptRequest) {
        return toObservable(
            api.credits.mall.exchanges.post,
            this.userId,
            receiptRequest
        );
    }
}
