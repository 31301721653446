import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        width: '100%',
        height: '100%',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    imageContainer: {
        marginVertical: 14,
        marginHorizontal: 16,
        width: 68,
        height: 68,
        borderRadius: 34,
        backgroundColor: '$accentColor',
        alignItems: 'center',
        justifyContent: 'center',
        '@flavor secretly': {
            overflow: 'hidden',
            alignSelf: 'center'
        },
    },
    textContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    text: {
        color: '$brandTextColor',
        fontSize: 17,
        fontFamily: '$brandFontFamily',
        '@flavor secretly': {
            color: '$primaryTextColor',
            marginRight: 15,
        },
    }
});

export default styles;
