import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.css';

export default function Weekday({ weekday, className, localeUtils, locale }) {
  const weekdayName = localeUtils.formatWeekdayLong(weekday, locale);

  return (
    <div className={className} role="columnheader">
      <abbr title={weekdayName}>{weekdayName.slice(0, 3)}</abbr>
    </div>
  );
}

Weekday.propTypes = {
  weekday: PropTypes.number,
  className: PropTypes.string,
  localeUtils: PropTypes.object,
  locale: PropTypes.string,
};
