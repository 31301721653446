import React from 'react';
import PropTypes from 'prop-types';
import { View, Image, TouchableOpacity } from 'react-native';
import styles from './styles';
import withAutoScale from '../../../auto-scale-image';

const ScaledImage = withAutoScale(Image);

export default class ImageButton extends React.PureComponent {
    static propTypes = {
        image: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.string]),
        tint: PropTypes.string,
        onPress: PropTypes.func,
        style: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array]),
    };

    render() {
        const touchable = !!this.props.onPress;

        const imageStyle = [styles.image, this.props.tint ? { tintColor: this.props.tint } : {}];
        const container = (
            <View style={[styles.container, this.props.style]}>
                <ScaledImage style={imageStyle} source={this.props.image} />
            </View>
        );

        return touchable ? (
            <TouchableOpacity
                onPress={this.props.onPress}
                accessibilityLabel={this.props.accessibilityLabel}
                testID={this.props.testID}
            >
                {container}
            </TouchableOpacity>
        ) : (
            container
        );
    }
}
