import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        backgroundColor: '$minorAccentColor',
        borderRadius: 20,
        alignItems: 'center'
    },

    text: {
        textAlign: 'center',
        alignSelf: 'stretch',
        color: '$minorAccentTextColor',
        fontSize: 17,
        fontFamily: '$brandFontFamily',
        marginHorizontal: 16,
        marginVertical: 24
    },

    button: {
        height: 40,
        borderRadius: 20,
        marginBottom: 32,
        paddingHorizontal: 37,
        backgroundColor: 'white',
        justifyContent: 'center',
        alignItems: 'center'
    },

    buttonTitle: {
        fontSize: 17,
        fontWeight: 'bold',
        fontFamily: '$brandFontFamily',
        color: '$minorAccentColor'
    },

    priceContainer: {
        position: 'absolute',
        left: 16,
        bottom: 8,
        flexDirection: 'row',
        alignItems: 'center'
    },

    coin: {
        width: 10,
        height: 10
    },

    price: {
        fontSize: 12,
        fontFamily: '$brandFontFamily',
        color: '$minorAccentTextColor',
        marginLeft: 4
    }
});

export default styles;
