import React from 'react';
import { Text, View } from 'react-native';
import PropTypes from 'prop-types';
import styles from './styles';
import Button from 'dating-mobile/src/components/buttons/base';

export default class PlaceholderView extends React.Component {
    static displayName = 'components.placeholder.view';
    static propTypes = {
        onButtonPress: PropTypes.func.isRequired,
        bodyText: PropTypes.string,
        buttonText: PropTypes.string
    };

    onButtonPress = () => {
        this.props.onButtonPress && this.props.onButtonPress();
    };

    shouldComponentUpdate(nextProps) {
        return this.props.bodyText !== nextProps.bodyText || this.props.buttonText !== nextProps.buttonText;
    };

    render() {
        return (
            <View style={styles.container}>
                <Text style={styles.text}>
                    {this.props.bodyText}
                </Text>
                <Button
                    onPress={this.onButtonPress}
                    title={this.props.buttonText}
                />
            </View>
        );
    }
}
