import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $numberOfColumns: 3,

    container: {
        flex: 1
    },

    headerContainer: {
        flexDirection: 'column',
        alignItems: 'stretch'
    },

    header: {
        height: 40
    },

    list: {
        flex: 1,
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 6,
        paddingBottom: 3
    }
});

export default styles;
