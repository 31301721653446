import React, { useState } from 'react';
import DayPicker from 'react-day-picker';
import PropTypes from 'prop-types';

import YearMonthForm from './YearMonthForm';
import Weekday from './Weekday';
import Day from './Day';

import 'react-day-picker/lib/style.css';
import './styles.css';

export default function DatePicker({
  onDateChange,
  maximumDate,
  minimumDate,
  date,
}) {
  const [month, setMonth] = useState(date);

  function renderDay(day, modifiers) {
    return <Day day={day} modifiers={modifiers} />;
  }

  return (
    <DayPicker
      month={month}
      canChangeMonth={false}
      fromMonth={minimumDate}
      toMonth={maximumDate}
      onDayClick={onDateChange}
      disabledDays={{
        after: maximumDate,
      }}
      weekdayElement={Weekday}
      renderDay={renderDay}
      selectedDays={date}
      captionElement={({ date: currentDate, localeUtils }) => (
        <YearMonthForm
          date={currentDate}
          fromMonth={minimumDate}
          toMonth={maximumDate}
          localeUtils={localeUtils}
          onChange={setMonth}
        />
      )}
    />
  );
}

DatePicker.propTypes = {
  date: PropTypes.instanceOf(Date),
  minimumDate: PropTypes.instanceOf(Date),
  maximumDate: PropTypes.instanceOf(Date),
  onDateChange: PropTypes.func,
};
