import { Platform } from 'react-native';
import RNCallKeep from 'react-native-callkeep';
import InCallManager from 'react-native-incall-manager';
import { of, combineLatest, defer, TimeoutError } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  skip,
  timeout,
} from 'rxjs/operators';
import qs from 'qs';
import url from 'url';
import guid from 'uuid/v4';
import { singleton } from '@sdv/commons/utils/singleton';
import flux from '@sdv/domain/app/flux';
import OS from '@sdv/domain/app/os';
import UserTagsModel, { getId } from '@sdv/domain/user/tags/targeted';
import request from '@sdv/commons/utils/request';
import ConfigBuilder from 'dating-mobile/src/app/config-builder';
import CallStatus from 'dating-mobile/src/models/call.status';
import CallAnsweringModel from 'dating-mobile/src/models/call.answering';
import { TAGS } from 'dating-mobile/src/models/users.targeted.tagged/model';
import InterruptingModel from 'dating-mobile/src/models/call.interrupting';
import callApi from 'dating-mobile/src/models/call.api/api';
import Session from '@sdv/domain/authorization/session';

export class CallKeeper {
  static INIT_TIMEOUT = 15000;

  static shared = singleton(() => new CallKeeper());

  static async isCallKeepAvailable() {
    if (Platform.OS === 'ios') {
      return parseInt(Platform.Version, 10) >= 10;
    }

    if (Platform.OS === 'android') {
      // TODO: Check android.permission.CALL_PHONE permission
      // const hasPhoneAccount = await RNCallKeep.hasPhoneAccount();
      // const hasDefaultPhoneAccount = await RNCallKeep.hasDefaultPhoneAccount({
      //   alertTitle: 'Default not set',
      //   alertDescription: 'Please set the default phone account'
      // });

      return Platform.Version >= 23 && RNCallKeep.supportConnectionService();
    }

    return false;
  }

  isInCallManagerActive = false;

  isInitialized = false;

  currentCallId = null;

  loadingTags = {};

  constructor() {
    this.userId = Session.shared().userId;

    this.callStatus = this.userId.pipe(
      filter(Boolean),
      switchMap(userId => flux.get(CallStatus, userId).store.rxState()),
      distinctUntilChanged(),
    );

    this.callStatusString = this.callStatus.pipe(
      map(({ status }) => status),
      filter(Boolean),
      distinctUntilChanged(),
    );

    this.attendeeId = this.callStatus.pipe(
      map(({ status, invite, attendee }) => {
        if (status === 'ringing') {
          return invite.sender;
        }

        if (status === 'speaking' || status === 'connecting') {
          return attendee;
        }

        return null;
      }),
      distinctUntilChanged(),
    );

    const isContact = combineLatest(this.userId, this.attendeeId).pipe(
      switchMap(([userId, attendeeId]) => {
        if (!userId || !attendeeId) {
          return of(null);
        }

        return defer(() => {
          const modelId = getId(userId, attendeeId);
          const userTags = flux.get(UserTagsModel, modelId);

          // TODO: Reformat somehow
          if (!userTags.store.isFilled() && !this.loadingTags[modelId]) {
            this.loadingTags[modelId] = true;

            userTags.actions.get(() => {
              this.loadingTags[modelId] = false;
            });
          }

          return userTags.store.rxState();
        });
      }),
      map(state => (state ? !!state[TAGS.CONTACT] : null)),
    );

    const canShowIncomingCall = this.callStatusString.pipe(
      map(status => status === 'ringing'),
      switchMap(isRinging => (isRinging ? isContact : of(null))),
      filter(it => it !== null),
    );

    this.shouldShowInvite = canShowIncomingCall.pipe(
      filter(canShow => !canShow),
      switchMap(() => this.attendeeId.pipe(take(1))),
    );

    this.shouldShowIncomingCall = canShowIncomingCall.pipe(
      filter(Boolean),
      switchMap(() => this.attendeeId.pipe(take(1))),
    );

    this.shouldShowSpeaking = this.callStatusString.pipe(
      filter(status => status === 'speaking'),
      switchMap(() => this.attendeeId.pipe(take(1))),
    );

    this.shouldShowConnectingToCall = this.callStatusString.pipe(
      filter(status => status === 'connecting'),
      switchMap(() => this.attendeeId.pipe(take(1))),
    );

    this.shouldEndCall = this.callStatusString.pipe(
      filter(status => status === 'idle'),
      skip(1),
    );

    // We don't use android native caller for now
    if (Platform.OS === 'ios') {
      this.init();
    }
  }

  // TODO: Check after logout
  async init() {
    if (this.isInitialized) {
      return;
    }

    const options = {
      ios: {
        appName: ConfigBuilder.config.productName,
        // imageName: 'sim_icon',
        supportsVideo: false, // TODO
        includesCallsInRecents: false, // TODO
        maximumCallGroups: '1',
        maximumCallsPerCallGroup: '1',
      },
      android: {
        alertTitle: 'Permissions Required',
        alertDescription:
          'This application needs to access your phone calling accounts to make calls',
        cancelButton: 'Cancel',
        okButton: 'ok',
        // imageName: 'sim_icon',
      },
    };

    try {
      await RNCallKeep.setup(options);
      RNCallKeep.setAvailable(true);
    } catch (e) {
      console.error('Initialize CallKeep error:', e.message);
    }

    RNCallKeep.addEventListener(
      'didReceiveStartCallAction',
      this.didReceiveStartCallAction,
    );
    RNCallKeep.addEventListener('answerCall', this.onAnswerCall);
    RNCallKeep.addEventListener('endCall', this.onEndCall);
    RNCallKeep.addEventListener(
      'didDisplayIncomingCall',
      this.onIncomingCallDisplayed,
    );
    RNCallKeep.addEventListener(
      'didPerformSetMutedCallAction',
      this.onToggleMute,
    );
    RNCallKeep.addEventListener('didToggleHoldCallAction', this.onToggleHold);
    RNCallKeep.addEventListener(
      'didActivateAudioSession',
      this.audioSessionActivated,
    );

    this.isInitialized = true;
  }

  destroy() {
    if (!this.isInitialized) {
      return;
    }

    this.stopInCallManager();

    RNCallKeep.removeEventListener(
      'didReceiveStartCallAction',
      this.didReceiveStartCallAction,
    );
    RNCallKeep.removeEventListener('answerCall', this.onAnswerCall);
    RNCallKeep.removeEventListener('endCall', this.onEndCall);
    RNCallKeep.removeEventListener(
      'didDisplayIncomingCall',
      this.onIncomingCallDisplayed,
    );
    RNCallKeep.removeEventListener(
      'didPerformSetMutedCallAction',
      this.onToggleMute,
    );
    RNCallKeep.removeEventListener(
      'didToggleHoldCallAction',
      this.onToggleHold,
    );
    RNCallKeep.removeEventListener(
      'didActivateAudioSession',
      this.audioSessionActivated,
    );

    this.isInitialized = false;
  }

  getCallIdFromAttendeeId(attendeeId) {
    if (!attendeeId) {
      return null;
    }

    const match = attendeeId
      .padEnd(32, '0')
      .match(/(.{8})(.{4})(.{4})(.{4})(.{12})/);

    if (!match) {
      return null;
    }

    const components = match.slice(1, 6);

    if (components.length !== 5) {
      return null;
    }

    return components.join('-');
  }

  getCurrentCallId(attendeeId) {
    if (!this.currentCallId) {
      this.currentCallId = this.getCallIdFromAttendeeId(attendeeId) || guid();
    }

    return this.currentCallId;
  }

  onAnswerCall = async () => {
    if (this.initCallPromise) {
      await this.initCallPromise;
    }

    combineLatest(this.userId, this.attendeeId)
      .pipe(take(1))
      .subscribe(([userId, attendeeId]) => {
        if (attendeeId && userId) {
          const modelId = qs.stringify({
            user: userId,
            attendee: attendeeId,
          });
          const callAnswering = flux.get(CallAnsweringModel, modelId);

          callAnswering.actions.answer();
        }
      });
  };

  onEndCall = async () => {
    if (this.initCallPromise) {
      await this.initCallPromise;
    }

    combineLatest(this.callStatusString, this.userId, this.attendeeId)
      .pipe(take(1))
      .subscribe(([status, userId, attendeeId]) => {
        if (status && status !== 'idle' && userId && attendeeId) {
          if (status === 'ringing') {
            const modelId = qs.stringify({
              user: userId,
              attendee: attendeeId,
            });
            const callAnswering = flux.get(CallAnsweringModel, modelId);

            callAnswering.actions.reject();
          } else {
            const modelId = qs.stringify({
              user: userId,
              attendee: attendeeId,
            });
            const interruptingModel = flux.get(InterruptingModel, modelId);

            interruptingModel.actions.interrupt();
          }
        }
      });

    this.currentCallId = null;
  };

  onIncomingCallDisplayed = async ({
    fromPushKit,
    payload,
    // callUUID,
    // error,
    // handle,
    // localizedCallerName,
    // hasVideo,
  }) => {
    if (
      fromPushKit === '1' &&
      payload &&
      payload.mediaMessageUri &&
      payload.senderId
    ) {
      this.initIncomingCall(payload.mediaMessageUri, payload.senderId);
    }
  };

  onToggleMute = (/* { muted } */) => {
    // const attendeeId = this.getAttendeeId();
    //
    // if (attendeeId) {
    //   const modelId = qs.stringify({ user: this.userId, attendee: attendeeId });
    //   const mediaModel = flux.get(MediaModel, modelId);
    //
    //   mediaModel.actions.setAudioMuted(muted);
    // }
  };

  // TODO: Call from the native caller app
  didReceiveStartCallAction = (/* { handle, callUUID, name } */) => {
    // Get this event after the system decides you can start a call
    // You can now start a call from within your app
  };

  onToggleHold = (/* { hold } */) => {
    // Called when the system or user holds a call
  };

  audioSessionActivated = () => {
    // you might want to do following things when receiving this event:
    // - Start playing ringback if it is an outgoing call
  };

  startInCallManager({ showVideo = false } = {}) {
    if (!this.isInCallManagerActive) {
      this.isInCallManagerActive = true;
      InCallManager.start({ media: showVideo ? 'video' : 'audio' });
    }
  }

  // Public
  async initIncomingCall(mediaMessageUri, attendeeId) {
    try {
      if (
        !this.currentCallId &&
        !this.initCallPromise &&
        mediaMessageUri &&
        attendeeId
      ) {
        this.currentCallId = this.getCallIdFromAttendeeId(attendeeId);
        this.initCallPromise = this.initCall(mediaMessageUri, attendeeId);

        await this.initCallPromise;

        this.initCallPromise = null;
      }
    } catch (e) {
      this.initCallPromise = null;
      this.endCall();
    }
  }

  // Private
  async initCall(mediaMessageUri) {
    // Make sure that the user is authorized before the calling flux.api.authorize()
    await new Promise((resolve, reject) =>
      this.userId
        .pipe(filter(Boolean), take(1), timeout(CallKeeper.INIT_TIMEOUT))
        .subscribe(resolve, reject),
    );

    const uri = url.format({
      protocol: 'https',
      hostname: flux.api.getBaseHost().replace('//', ''),
      pathname: mediaMessageUri,
    });

    const headers = {
      Authorization: flux.api.authorize(),
    };

    if (OS.shared().current !== 'web') {
      headers['User-Agent'] = flux.api.augment('user-agent');
    }

    const response = await request(uri, {
      method: 'GET',
      headers,
    });

    // TODO: Add retry logic
    if (response.status === 200 || response.status === 201) {
      if (!callApi.isReady) {
        await Promise.race([
          new Promise(resolve => callApi.once('call.api.ready', resolve)),
          new Promise((resolve, reject) =>
            setTimeout(reject, CallKeeper.INIT_TIMEOUT, new TimeoutError()),
          ),
        ]);
      }

      callApi.emit(
        'event.dialogs.media.messages.added',
        JSON.parse(response.responseText),
      );

      // Wait for a call status change
      await new Promise((resolve, reject) =>
        this.callStatusString
          .pipe(
            filter(status => status !== 'idle'),
            take(1),
            timeout(CallKeeper.INIT_TIMEOUT),
          )
          .subscribe(resolve, reject),
      );
    } else {
      throw new Error('Unable to initialize the call');
    }
  }

  stopInCallManager() {
    if (this.isInCallManagerActive) {
      this.isInCallManagerActive = false;
      InCallManager.stop();
    }
  }

  endCall() {
    if (this.currentCallId) {
      try {
        RNCallKeep.endCall(this.currentCallId);
        this.currentCallId = null;
      } catch (e) {
        this.currentCallId = null;
        throw e;
      }
    }
  }

  startCall({ attendeeId, callerName, showVideo }) {
    RNCallKeep.startCall(
      this.getCurrentCallId(attendeeId),
      attendeeId.toString(10),
      callerName,
      'generic',
      showVideo,
    );
  }

  displayIncomingCall({ attendeeId, callerName, showVideo }) {
    RNCallKeep.displayIncomingCall(
      this.getCurrentCallId(attendeeId),
      attendeeId.toString(10),
      callerName,
      'generic',
      showVideo,
    );
  }

  setCurrentCallActive({ attendeeId }) {
    RNCallKeep.setCurrentCallActive(this.getCurrentCallId(attendeeId));
  }
}
