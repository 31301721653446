import React from 'react';
import PropTypes from 'prop-types';
import { FlatList, View, ScrollView } from 'react-native';
import StreamCategoryItem from './item';
import styles from './styles';
import Resources from '../../../../resources';
import flux from '@sdv/domain/app/flux';
import ConfigModel from 'dating-mobile/src/models/config/model';

export const STREAMS_CATEGORIES = Object.freeze({
    POPULAR: 0,
    ALL_STREAMS: 1,
    FOLLOWING: 2,
    MULTI_GUEST: 3,
    TAGS_CATEGORY: 4,
});

export const CATEGORIES_PRESET = Object.freeze({
    FULL_LIST: 0,
    NO_FOLLOWING_LIST: 1,
    FOLLOWING: 2,
    FOR_MODERATOR: 3,
    NO_FOLLOWING_LIST_FOR_MODERATOR: 4,
});

const categoryPopular = {
    id: STREAMS_CATEGORIES.POPULAR,
    text: Resources.strings['stream-list-screen-popular-category-title'],
};
const categoryFollowing = {
    id: STREAMS_CATEGORIES.FOLLOWING,
    text: Resources.strings['stream-list-screen-following-category-title'],
};
const categoryMultiGuest = {
    id: STREAMS_CATEGORIES.MULTI_GUEST,
    text: Resources.strings['stream-list-screen-multiguest-category-title'],
};

const categoryAllStreams = {
    id: STREAMS_CATEGORIES.ALL_STREAMS,
    text: Resources.strings['stream-list-screen-allstreams-category-title'],
};

const categoryTags = {
    id: STREAMS_CATEGORIES.TAGS_CATEGORY,
    text: '',
};

export function getTopicFromIndex(index) {
    return index && index.split(':')[1];
}

function createIndexForTopicCategory(topic) {
    return `tag_generated:${topic}`;
}

const categoriesVariants = {
    [CATEGORIES_PRESET.FULL_LIST]: [
        categoryPopular,
        categoryFollowing,
        categoryMultiGuest,
        categoryTags,
    ],
    [CATEGORIES_PRESET.FOR_MODERATOR]: [
        categoryPopular,
        categoryAllStreams,
        categoryFollowing,
        categoryMultiGuest,
        categoryTags,
    ],
    [CATEGORIES_PRESET.NO_FOLLOWING_LIST]: [
        categoryPopular, categoryMultiGuest,
        categoryTags,
    ],
    [CATEGORIES_PRESET.NO_FOLLOWING_LIST_FOR_MODERATOR]: [
        categoryPopular,
        categoryAllStreams,
        categoryMultiGuest,
        categoryTags,
    ],
    [CATEGORIES_PRESET.FOLLOWING]: [categoryFollowing],
};

export function numberOfCategoriesInPreset(preset) {
    const categoriesVariant = categoriesVariants[preset];
    if (categoriesVariant) {
        return categoriesVariant.length;
    } else {
        return 0;
    }
}

export function getDefaultCategoryForPreset(preset) {
    const categoriesVariant = categoriesVariants[preset];
    if (categoriesVariant && categoriesVariant[0]) {
        return categoriesVariant[0];
    } else {
        return categoryPopular;
    }
}

export default class StreamCategoriesView extends React.Component {
    static displayName = 'broadcasts.discovery.views.stream-categories';
    static propTypes = {
        showFollowing: PropTypes.bool,
        onSelectedItem: PropTypes.func,
        categoriesPreset: PropTypes.number,
    };

    constructor(props) {
        super(props);

        this.initCategories(props);
    }

    componentWillReceiveProps(nextProps) {
        this.initCategories(nextProps);
    }

    initCategories(properties) {
        let categoriesPreset = properties.categoriesPreset;

        const categoriesVariant = categoriesVariants[categoriesPreset];
        if (categoriesVariant) {
            const tagsCategoryIndex = categoriesVariant.findIndex(
                category => category.id === STREAMS_CATEGORIES.TAGS_CATEGORY,
            );

            if (tagsCategoryIndex >= 0) {
                const tags = flux
                    .get(ConfigModel)
                    .store.getState()
                    ['available-stream-topics'].map(topic => {
                        return {
                            id: createIndexForTopicCategory(topic),
                            tag: topic,
                            text: Resources.strings['streams-topics-list'][topic],
                        };
                    });
                this.data = categoriesVariant.splice(tagsCategoryIndex, 1, ...tags);
            } else {
                this.data = categoriesVariant;
            }
        } else {
            this.data = [];
        }
    }

    renderItem = ({ item }) => {
        const selected = item.id === this.props.selectedIndex;
        return (
            <View key={String(item.id)} style={styles.itemContainer}>
                <StreamCategoryItem
                    {...item}
                    selected={selected}
                    onPress={() => this.props.onSelectedItem && this.props.onSelectedItem(item)}
                />
            </View>
        );
    };

    render() {
        if (!this.data.length) {
            return null;
        }

        return (
            <View style={styles.container}>
                <FlatList
                    horizontal={styles.$listHorizontal}
                    numColumns={styles.$listHorizontal ? undefined : this.data.length}
                    key={'categories' + (styles.$listHorizontal ? '' : this.data.length)}
                    style={styles.list}
                    data={this.data}
                    extraData={this.props}
                    renderItem={this.renderItem}
                    keyExtractor={item => String(item.id)}
                    alwaysBounceVertical={false}
                    alwaysBounceHorizontal={false}
                    nestedScrollEnabled={true}
                />
            </View>
        );
    }
}
