import React from 'react';
import PropTypes from 'prop-types';
import { render } from 'dating-mobile/src/components/rx-render';
import { LocalizedFaqRepository } from '@sdv/domain/faq/localized-faq-repository';

export default function createController(Component) {
    const Details = render((props, { useObservable }) => {
        if (!props.id) {
            return null;
        }

        const topic = useObservable(
            (it, id) => it.getTopic(id),
            LocalizedFaqRepository.shared(),
            props.id,
        );

        if (!topic) {
            return null;
        }

        return <Component {...props} topic={topic} />;
    });

    Details.displayName = 'faq.screens.faq-details.controller';

    Details.propTypes = {
        id: PropTypes.string.isRequired,
    };

    return Details;
}
