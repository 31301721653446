import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import { View, Text, FlatList, TouchableOpacity } from 'react-native';
import user from '../../../../models/user/controllers';
import testId from 'dating-mobile/src/utils/test-id';
import Resources from '../../../../resources';

import ChatRequestItem from './item';
import Header from './header';

class ChatRequestsComponent extends React.Component {
    static displayName = 'dialogs.chats.chat-requests';
    static propTypes = {
        chatRequests: PropTypes.array,
        onChatRequestSelect: PropTypes.func,
        onShowAllRequestsButtonPress: PropTypes.func
    };

    shouldComponentUpdate(nextProps) {
        return this.props.chatRequests !== nextProps.chatRequests;
    }

    renderItem = ({ item }) => {
        const { ['user-details']: userDetails, ...data } = item;
        const userId = data['user-id'];

        return (
            <ChatRequestItem
                key={userId}
                id={userId}
                user={userDetails}
                onPress={this.onUserSelected}
                {...testId('Chat requests list item')}
            />
        );
    };

    onUserSelected = (user) => {
        const userId = user && user.id;
        const chatRequest = (this.props.chatRequests || []).find(event => event['user-id'] === userId);
        this.props.onChatRequestSelect && this.props.onChatRequestSelect(chatRequest);
    };

    onShowAllRequestsButtonPressed = () => {
        this.props.onShowAllRequestsButtonPress && this.props.onShowAllRequestsButtonPress();
    };

    render() {
        const chatRequests = this.props.chatRequests || [];

        if (!chatRequests.length) {
            return null;
        }

        return (
            <View style={styles.container}>
                <View style={styles.headerContainer}>
                    <Header
                        firstUserId={(chatRequests[0] || {})['user-id']}
                        secondUserId={(chatRequests[1] || {})['user-id']}
                        totalCount={chatRequests.length}
                    />
                </View>
                <View style={styles.listContainer}>
                    <FlatList
                        style={styles.list}
                        data={this.props.chatRequests}
                        renderItem={this.renderItem}
                        horizontal={true}
                        alwaysBounceHorizontal={false}
                        showsHorizontalScrollIndicator={false}
                        keyExtractor={item => item['user-id']}
                        windowSize={10}
                    />
                </View>
                <View style={styles.showAllButtonContainer}>
                    <TouchableOpacity
                        style={styles.showAllButton}
                        onPress={this.onShowAllRequestsButtonPressed}
                        {...testId('Show all button')}
                    >
                        <Text style={styles.showAllButtonTitle}>
                            {Resources.strings['my-chats-screen-show-all-chat-requests-button-title']}
                        </Text>
                    </TouchableOpacity>
                </View>

            </View>
        );
    };
}

export default ChatRequestsComponent;
