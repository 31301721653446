import React, { useMemo } from 'react';

import { Text } from 'react-native';
import { PhotoUploadingView, UserThumbnailUploader } from '../../views';
import { UploaderConfig } from '../../views/user-thumbnail-uploader';
import styles from './styles';
import Resources from '../../../resources';

type Props = {
    onComplete: Function;
    waitUploading: boolean;
};

export const ScreenView = React.memo((props: Props) => {
    const { onComplete, waitUploading } = props;
    const uploaderConfig = useMemo<UploaderConfig>(
        () => ({
            onComplete: () => {
                setTimeout(() => {
                    onComplete();
                }, 1000);
            },
            waitUploading,
        }),
        [onComplete],
    );

    const uploader = useMemo(
        () =>
            React.forwardRef((photoPickerProps: Props, ref) => (
                <UserThumbnailUploader
                    ref={ref}
                    {...photoPickerProps}
                    uploaderConfig={uploaderConfig}
                />
            )),
        [],
    );

    const title = useMemo(() => {
        if (waitUploading) {
            return (
                <>
                    <Text style={[styles.title, styles.modalMarginTop]}>
                        {Resources.strings['onboarding-screen-mandatory-photo-title']}
                    </Text>
                    <Text style={styles.subTitle}>
                        {Resources.strings['onboarding-screen-mandatory-photo-subtitle']}
                    </Text>
                </>
            );
        }

        return (
            <>
                <Text style={styles.miniTitle}>{Resources.strings['onboarding-screen-title']}</Text>
                <Text style={styles.title}>
                    {Resources.strings['onboarding-screen-photo-title']}
                </Text>
            </>
        );
    }, [waitUploading]);

    return <PhotoUploadingView PhotoPicker={uploader} title={title} />;
});

ScreenView.displayName = 'stepped-registration.screens.photo.view';
