import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import Swiper from 'react-native-swiper';
import equal from 'fast-deep-equal';
import UserThumbnail from 'dating-mobile/src/user/common/views/thumbnail/view';
import Content from './content';
import styles from './styles';

import GalleryController from '../../../../models/user.media/controller/gallery-controller';

export class Gallery extends React.Component {
  static displayName = 'gallery';

  static propTypes = {
    id: PropTypes.string.isRequired,
    initialIndex: PropTypes.number,
    fullscreen: PropTypes.bool,
    media: PropTypes.arrayOf(
      PropTypes.shape({
        basename: PropTypes.string.isRequired,
        tags: PropTypes.tags,
        mediatype: PropTypes.string.isRequired,
      }),
    ),
    onMediaSelected: PropTypes.func,
    onVisibleItemChanged: PropTypes.func,
    showPlaceholder: PropTypes.bool,
    isLoading: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    const items = this.props.media || [];

    if (
      !this.initialIndexSet &&
      typeof props.initialIndex === 'number' &&
      props.initialIndex >= 0
    ) {
      this.selectedIndex = props.initialIndex + (items.length > 1 ? 1 : 0);
      this.initialIndexSet = true;
    }

    if (!this.selectedIndex && items.length > 1) {
      this.selectedIndex = 1;
    }
  }

  shouldComponentUpdate(nextProps) {
    const { isLoading } = this.props;

    if (isLoading && nextProps.isLoading) {
      return false;
    }

    return (
      !equal(this.props.media, nextProps.media) ||
      isLoading !== nextProps.isLoading
    );
  }

  componentWillReceiveProps(props) {
    const currentItems = this.props.media || [];
    const newItems = props.media || [];

    if (typeof this.selectedIndex === 'number') {
      if (!newItems.length) {
        this.selectedIndex = undefined;
        this.initialIndexSet = false;
      } else if (currentItems.length > 1 && newItems.length === 1) {
        this.selectedIndex = 0;
      } else if (currentItems.length <= 1 && newItems.length > 1) {
        this.selectedIndex += 1;
      }
    }

    if (
      !this.initialIndexSet &&
      typeof props.initialIndex === 'number' &&
      props.initialIndex >= 0
    ) {
      this.selectedIndex = props.initialIndex + (newItems.length > 1 ? 1 : 0);
      this.initialIndexSet = true;
    }

    if (!this.selectedIndex && newItems.length > 1) {
      this.selectedIndex = 1;
    }
  }

  onMediaSelected = media => {
    this.props.onMediaSelected && this.props.onMediaSelected(media);
  };

  renderPagination = index => {
    const media = this.props.media || [];
    let realIndex = index;

    if (media.length > 1) {
      if (realIndex === 0) {
        realIndex = media.length - 1;
        this.setPageAsync(media.length);
      } else if (realIndex === media.length + 1) {
        realIndex = 0;
        this.setPageAsync(1);
      } else {
        realIndex -= 1;
      }
    }
    this.selectedIndex = index;
    this.props.onVisibleItemChanged &&
      this.props.onVisibleItemChanged(
        realIndex,
        media.length,
        media[realIndex],
      );

    return null;
  };

  setPageAsync = index => {
    this.pageTimer && clearTimeout(this.pageTimer);

    this.pageTimer = setTimeout(() => this.setPage(index), 100);
  };

  setPage = index => {
    if (!this.swiperRef) {
      return;
    }

    this.swiperRef.scrollBy(index - this.swiperRef.state.index, false);
  };

  renderItems = () => {
    const items = this.props.media || [];

    const numberOfPrivatePhotos = items.filter(
      item =>
        item.mediatype?.startsWith('image') &&
        item.tags &&
        item.tags.indexOf('hidden') >= 0,
    ).length;

    const view = (key, media, hidden) => {
      return (
        <View key={key} style={[styles.item, hidden ? { opacity: 0 } : {}]}>
          <Content
            id={this.props.id}
            media={media}
            numberOfPrivatePhotos={numberOfPrivatePhotos}
            fullscreen={this.props.fullscreen}
            onSelected={() => this.onMediaSelected(media)}
          />
        </View>
      );
    };

    const children = items.map(media => view(media.basename, media));

    if (items.length > 1) {
      children.push(view(`${items[0].basename}-loop`, items[0], true));
      children.unshift(
        view(
          `${items[items.length - 1].basename}-loop`,
          items[items.length - 1],
          true,
        ),
      );
    }

    return children;
  };

  render() {
    return (
      <View style={styles.container}>
        {!!this.props.media && !!this.props.media.length ? (
          <Swiper
            ref={ref => (this.swiperRef = ref)}
            loadMinimal
            loadMinimalSize={2}
            loop={false}
            renderPagination={this.renderPagination}
            index={this.selectedIndex}
          >
            {this.renderItems()}
          </Swiper>
        ) : (
          !!this.props.showPlaceholder &&
          this.props.media && (
            <UserThumbnail
              userId={this.props.id}
              style={styles.thumbnailPlaceholder}
              round={false}
            />
          )
        )}
      </View>
    );
  }
}

export default GalleryController(Gallery);
