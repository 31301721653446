import EStyleSheet from 'react-native-extended-stylesheet';

const container = {
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    flexDirection: 'row',
    height: 80,
    backgroundColor: '$primaryBackgroundColor',
    '@flavor lovinga, _': {
        height: 96
    }
};

const username = {
    fontFamily: '$brandFontFamily',
    fontWeight: 'normal',
    fontSize: 17,
    marginRight: 10,
    color: '$primaryTextColor',
    '@flavor lovinga, _': {
        fontSize: 18
    }
};

const letterSubjectStyle = {
    fontSize: 16,
    fontWeight: '300',
    color: '$secondaryTextColor',
    fontFamily: '$brandFontFamily',
    '@flavor lovinga, _': {
        fontWeight: 'normal',
        color: '$disabledTextColor'
    }
};

const styles = EStyleSheet.create({
    touchable: {
        width: '100%'
    },
    unreadContainer: container,
    readContainer: {
        ...container,
        backgroundColor: '#EEEEEE',
        '@flavor lovinga': {
            backgroundColor: '$primaryBackgroundColor'
        }
    },
    contentContainer: {
        flex: 1,
        height: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        '@flavor lovinga': {
            borderBottomWidth: 1,
            borderColor: '$brandBorderColor'
        }
    },
    content: {
        flex: 1,
        flexDirection: 'column'
    },
    letterSubjectContainer: {
        flex: 1
    },
    letterSubjectStyle: letterSubjectStyle,
    unreadLetterSubjectStyle: {
        ...letterSubjectStyle,
        fontFamily: '$brandFontFamily',
        fontWeight: '600',
        color: '$primaryTextColor',
        '@flavor lovinga': {
            fontWeight: 'bold'
        }
    },
    avatarContainer: {
        marginLeft: 16,
        marginRight: 10,
        alignItems: 'flex-end',
        justifyContent: 'center',
        flexDirection: 'row',
        '@flavor lovinga': {
            marginRight: 16
        }
    },
    avatar: {
        width: 55,
        height: 55,
        borderRadius: 27,
        '@flavor lovinga': {
            width: 68,
            height: 68,
            borderRadius: 34
        }
    },
    online: {
        marginLeft: -12,
        bottom: 0
    },
    isContact: {
        left: 0,
        top: 0,
        width: 16,
        height: 16,
        position: 'absolute',
    },
    usernameContainer: {
        marginRight: 14,
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
        '@flavor lovinga': {
            marginRight: 16
        }
    },
    username: username,
    unreadUsername: {
        ...username,
        fontFamily: '$brandFontFamily',
        fontWeight: '600',
        '@flavor lovinga': {
            fontWeight: 'bold'
        }
    },
    letterInfoContainer: {
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row'
    },
    introTextContainer: {
        marginRight: 14,
        marginLeft: 10,
        height: 20,
        width: 50,
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '$accentColor',
        '@flavor lovinga': {
            marginRight: 16,
            backgroundColor: '$accentColor'
        }
    },
    introText: {
        fontFamily: '$brandFontFamily',
        fontSize: 11,
        fontWeight: '500',
        color: '$accentTextColor',
        '@flavor lovinga': {
            fontSize: 12,
            fontWeight: 'bold',
            color: '$accentTextColor'
        }
    },
    unreadTimestamp: {
        marginLeft: 10,
        fontFamily: '$brandFontFamily',
        color: '$secondaryTextColor',
        fontSize: 10,
        '@flavor lovinga': {
            fontSize: 14,
            color: '$disabledTextColor'
        }
    },
    userInfoContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },

    letterText: {
        fontSize: 16,
        fontWeight: '300',
        color: '$secondaryTextColor',
        fontFamily: '$brandFontFamily',
        marginRight: 40
    },

    unreadMessageCount: {
        marginRight: 14,
        marginLeft: 10,
        marginBottom: 6,
        width: 20,
        height: 20,
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '$brandColor',
        '@flavor lovinga': {
            marginRight: 16,
            backgroundColor: '$accentColor'
        },
        '@flavor tubit': {
            backgroundColor: '$accentColor'
        },
        '@flavor dma': {
            width: 24,
            height: 24,
            borderRadius: 12,
            backgroundColor: '$accentColor'
        }
    },

    unreadMessageCountText: {
        fontFamily: '$brandFontFamily',
        fontSize: 11,
        fontWeight: '500',
        color: '$brandTextColor',
        '@flavor lovinga': {
            fontSize: 12,
            fontWeight: 'bold',
            color: '$accentTextColor'
        },
        '@flavor dma': {
            fontSize: 14,
            fontWeight: 'bold',
            color: '$accentTextColor'
        }
    },
});

export default styles;
