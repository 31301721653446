import React from 'react';
import { render } from 'dating-mobile/src/components/rx-render';

import { FeedAdsViewModel } from './view-model';

export default function createController(Component) {
    const Controller = render((props, { useDistinct, useObservable }) => {
        const feedAdsViewModel = useDistinct(() => new FeedAdsViewModel());
        const currentAd = useObservable(it => it.currentAd, feedAdsViewModel);

        if (!currentAd) {
            return null;
        }

        return <Component source={currentAd} onPress={feedAdsViewModel.openAd} />;
    });

    Controller.displayName = 'feed.views.ads.controller';

    return Controller;
}
