import React from 'react';
import PropTypes from 'prop-types';
import connect from '@sdv/connect';
import InvitationModel from '@sdv/domain/dialogs.automation.productions.invitation';
import identityId from '../../../../models/identity/controller/id';
import preferences from '../../../../models/user.preference/controllers';
import configValue from '../../../../components/config-value';
import hasTag from '../../../../models/user.tags/controllers/has-tag';
import Resources from '../../../../resources';
import { randomValuesFromArray } from '../../../../utils/random';

function controller(Component) {

    class Controller extends React.Component {
        static displayName = 'dialogs.mingle.start.screen.controller';
        static propTypes = {
            membershipIsInactive: PropTypes.bool,
            preference: PropTypes.object,
            defaultMinAge: PropTypes.number,
            defaultMaxAge: PropTypes.number,
            numberOfMessages: PropTypes.number,
            emptyPreferredGenderEnabled: PropTypes.bool,
            updatePreference: PropTypes.func,
            startMingle: PropTypes.func,
            close: PropTypes.func,
            subscribe: PropTypes.func,
            refillBalance: PropTypes.func
        };
        static contextTypes = {
            flux: PropTypes.object
        };

        state = {};

        constructor(props) {
            super(props);

            this.messages = randomValuesFromArray(Resources.strings['mingle-messages'], props.numberOfMessages || 0);
        }

        componentWillReceiveProps(nextProps) {
            if (this.props.numberOfMessages !== nextProps.numberOfMessages) {
                this.messages = randomValuesFromArray(Resources.strings['mingle-messages'], nextProps.numberOfMessages || 0);
            }
        }

        gender = () => {
            return typeof this.state.gender !== 'undefined'
                ? this.state.gender
                : (this.props.preference && this.props.preference['preferred-gender']);
        };

        onMaleButtonPressed = () => {
            const gender = this.gender();

            if (gender === 'mal') {
                return;
            }

            this.setState({
                gender: gender
                    ? (this.props.emptyPreferredGenderEnabled ? null : 'mal')
                    : (this.props.emptyPreferredGenderEnabled && gender === null ? 'fem' : 'mal')
            });
        };

        onFemaleButtonPressed = () => {
            const gender = this.gender();

            if (gender === 'fem') {
                return;
            }

            this.setState({
                gender: gender
                    ? (this.props.emptyPreferredGenderEnabled ? null : 'fem')
                    : (this.props.emptyPreferredGenderEnabled && gender === null ? 'mal' : 'fem')
            });
        };

        onMinAgeSelected = (age) => {
            this.setState({
                minage: age,
                maxage: Math.max(this.state.maxage, age)
            });
        };

        onMaxAgeSelected = (age) => {
            this.setState({
                minage: Math.min(this.state.minage, age),
                maxage: age
            });
        };

        onMessageSelected = (message) => {
            if (!message || !this.props.updatePreference || !this.props.startMingle) {
                return;
            }

            this.setState({
                isLoading: true
            }, async () => {
                try {
                    const preference = this.props.preference;

                    const newPreferences = {
                        minage: this.state.minage || (preference && preference.minage) || this.props.defaultMinAge,
                        maxage: this.state.maxage || (preference && preference.maxage) || this.props.defaultMaxAge
                    };

                    const gender = this.gender();

                    if (!gender || this.props.emptyPreferredGenderEnabled) {
                        newPreferences['preferred-gender'] = gender || null;
                    }

                    await this.props.updatePreference(newPreferences);

                    await this.startMingle(message);

                    this.props.close && this.props.close();
                } catch (error) {
                }

                this.setState({
                    isLoading: false
                });
            });
        };

        startMingle = (message) => {
            return new Promise((resolve, reject) => {
                this.props.startMingle({ text: message }, (error) => {
                    if (error) {
                        reject(error);
                    } else {
                        resolve();
                    }
                });
            }).catch(error => {
                if (error.status === 402) {
                    if (this.props.membershipIsInactive === false) {
                        if (this.props.refillBalance) {
                            return new Promise((resolve, reject) => {
                                this.props.refillBalance(resolve, reject);
                            });
                        }
                    } else if (this.props.subscribe) {
                        return new Promise((resolve, reject) => {
                            this.props.subscribe(resolve, reject);
                        });
                    }
                }
                return Promise.reject(error);
            });
        };

        render() {
            const { preference, ...props } = this.props;

            const gender = this.gender();
            const maleButtonSelected = gender === 'mal' || (gender === null && this.props.emptyPreferredGenderEnabled);
            const femaleButtonSelected = gender === 'fem' || (gender === null && this.props.emptyPreferredGenderEnabled);
            const minAge = this.state.minage || (preference && preference.minage) || this.props.defaultMinAge;
            const maxAge = this.state.maxage || (preference && preference.maxage) || this.props.defaultMaxAge;

            return (<Component
                {...props}
                isLoading={this.state.isLoading}
                isMaleButtonSelected={maleButtonSelected}
                isFemaleButtonSelected={femaleButtonSelected}
                minAge={minAge}
                maxAge={maxAge}
                messages={this.messages}
                onMaleButtonPress={this.onMaleButtonPressed}
                onFemaleButtonPress={this.onFemaleButtonPressed}
                onMinAgeSelect={this.onMinAgeSelected}
                onMaxAgeSelect={this.onMaxAgeSelected}
                onMessageSelect={this.onMessageSelected}
            />);
        }

    }

    let ConnectedController = connect(
        (flux, props) => props.id
            ? { invitationModel: flux.get(InvitationModel, props.id) }
            : {},
        () => {},
        (models) => models.invitationModel
            ? { startMingle: models.invitationModel.actions.put }
            : {},
        (props, newProps) => props.id !== newProps.id
    )(Controller);

    return identityId(
        preferences(
            hasTag(
                configValue(
                    ConnectedController,
                    {
                        defaultMinAge: 'preferences.default-min-age',
                        defaultMaxAge: 'preferences.default-max-age',
                        numberOfMessages: 'mingle.number-of-messages',
                        minAgeConstraint: 'age-range.min-age',
                        maxAgeConstraint: 'age-range.max-age',
                        emptyPreferredGenderEnabled: 'features.empty-preferred-gender-enabled'
                    }
                ),
                {
                    tag: 'membership',
                    userIdPropName: 'id',
                    hasTagPropName: 'membershipIsInactive'
                }
            ),
        ),
        'id'
    );

}

export default controller;
