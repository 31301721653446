import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({

    container: {
        width: 96,
        height: 96,
        overflow: 'hidden',
        '@flavor lovinga, udates': {
            borderRadius: 15,
            marginRight: 8
        },
        '@flavor dma': {
            width: 136,
            height: 136,
            marginLeft: 12,
            borderRadius: 20,
        }
    },

    content: {
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },

    photo: {
        width: '100%',
        height: '100%'
    },

    icon: {
        position: 'absolute',
        width: 48,
        height: 48
    }

});

export default styles;
