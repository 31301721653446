import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import api from '@sdv/domain/api';
import { singleton } from '@sdv/commons/utils/singleton';

// PATCH fails when trying to set object with digits-only key. Back-end bug.
// As temporary solution, add additional symbol to key.

const additionalSymbol = 'a';

class UserAliasesRepository {
    static shared = singleton(userId => new UserAliasesRepository(userId));

    constructor(userId) {
        this.userId = userId;

        this.aliases = new BehaviorSubject({});

        api.users.aliases.users.get(userId, (err, response) => {
            if (!err) {
                this.aliases.next(response);
            }
        });
    }

    getAlias = attendeeId => {
        return this.aliases.pipe(
            map(data => {
                return data[additionalSymbol + attendeeId] || {};
            }),
        );
    };

    getAllAliases = () => {
        return this.aliases.pipe(
            map(aliases =>
                Object.entries(aliases).reduce(
                    (acc, [key, value]) => ({
                        ...acc,
                        [key.replace(/^a/, '')]: value,
                    }),
                    {},
                ),
            ),
        );
    };

    setThumbnailAlias = (thumbnail, attendeeId) => {
        this.setAlias(attendeeId, { thumbnail });
    };

    setNameAlias = (name, attendeeId) => {
        this.setAlias(attendeeId, { name });
    };

    setAlias = (attendeeId, aliasData) => {
        const aliases = this.aliases.value;
        const updatedKey = additionalSymbol + attendeeId;

        aliases[updatedKey] = {
            ...(aliases[updatedKey] || {}),
            ...aliasData,
        };

        const data = { [updatedKey]: aliases[updatedKey] };

        api.users.aliases.users.patch(this.userId, data, () => {
            this.aliases.next(aliases);
        });
    };
}

export { UserAliasesRepository };
