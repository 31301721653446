import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

export default function YearMonthForm(props) {
  const { date, fromMonth, toMonth, localeUtils, onChange } = props;

  const months = localeUtils.getMonths();
  const years = [];

  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }

  function handleChange(e) {
    const { year, month } = e.target.form;

    onChange(new Date(year.value, month.value));
  }

  return (
    <form className="DayPicker-Caption">
      <select
        className="select margin-right-20"
        name="month"
        onChange={handleChange}
        value={date.getMonth()}
      >
        {months.map((month, i) => (
          <option key={month} value={i}>
            {month}
          </option>
        ))}
      </select>
      <select
        className="select"
        name="year"
        onChange={handleChange}
        value={date.getFullYear()}
      >
        {years.map(year => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </form>
  );
}

YearMonthForm.propTypes = {
  date: PropTypes.instanceOf(Date),
  fromMonth: PropTypes.instanceOf(Date),
  toMonth: PropTypes.instanceOf(Date),
  localeUtils: PropTypes.object,
  onChange: PropTypes.func,
};

YearMonthForm.defaultProps = {
  fromMonth: new Date(new Date().getFullYear() - 150, 0),
  toMonth: new Date(),
};
