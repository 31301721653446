import React from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity, View, Text, Image } from 'react-native';
import styles from './styles';
import testId from '../../../../../utils/test-id';

export default class StreamCategoryItem extends React.Component {
    static propTypes = {
        id: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.number.isRequired,
        ]),
        text: PropTypes.string.isRequired,
        backgroundColor: PropTypes.string,
        selected: PropTypes.bool,
        onPress: PropTypes.func
    };

    onPress = () => {
        this.props.onPress && this.props.onPress();
    };

    render() {
        const containerStyle = [
            styles.container,
            this.props.selected ? styles.selectedContainer : styles.unselectedContainer
        ];

        const titleStyle = [
            styles.title,
            this.props.selected ? styles.selectedTitle : styles.unselectedTitle
        ];

        let text = this.props.text;
        if(this.props.text && styles.$categoriesUppercase){
            text = text.toUpperCase();
        }

        return (
            <TouchableOpacity onPress={this.onPress} {...testId('Stream category item button')}>
                <View style={containerStyle}>
                    <Text style={titleStyle}>{text}</Text>
                </View>
            </TouchableOpacity>
        )
    }
}
