export enum Gender {
  Male = 'mal',
  Female = 'fem',
}

/**
 * @deprecated, use Gender
 */
export const GENDER = {
  male: Gender.Male,
  female: Gender.Female,
};
