import MailboxStore from '../base/store';
import Actions from './actions';
import displayName, { parseId } from './display-name';

function createStore(id) {

    let { userId, attendeeId } = parseId(id);

    class OneAttendeeMailboxStore extends MailboxStore {

        /**
         * @param {Alt} flux
         */
        constructor(flux) {
            super(flux, flux.getActions(Actions, id));
            this.attendee = parseId(id).attendee;
        }

        onNewLetterAdded(letter) {
            if (letter.sender === attendeeId || letter.recipient === attendeeId) {
                this.letters.set(letter.id, letter);

                this.updateModel();
            }
        }
    }

    OneAttendeeMailboxStore.displayName = displayName(id);

    return OneAttendeeMailboxStore;
}

createStore.getDisplayName = displayName;

export default createStore;
