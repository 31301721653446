/* eslint-disable import/named */
import React from 'react';
import PropTypes from 'prop-types';
import IdentityModel from '@sdv/domain/identity/model';
import url from 'url';
import { ChatAnonymity } from '@sdv/domain/chat/chat-anonymity';
import { take } from 'rxjs/operators';
import Navigator from 'dating-mobile/src/routing/navigator';
import { PHOTO } from 'dating-mobile/src/routing/router/constants';
import { dialogPhotoPath, dialogVideoPath } from '../../../resources/remote';
import PaidResourceModel, {
  getId as getPaidResourceModelId,
  RESOURCE_ACCESS_STATUS,
} from '../../paid-resource/model';
import BalanceRefiller, {
  PAYMENT_REASON,
} from '../../../payment/utils/balance-refiller';

function CreateLetterAttachmentsController(Component) {
  class Controller extends React.Component {
    static displayName = 'common.messages.letter.attachments.controller';

    static propTypes = {
      basename: PropTypes.string.isRequired,
      mediatype: PropTypes.string.isRequired,
      reference: PropTypes.string.isRequired,
      recipient: PropTypes.string.isRequired,
      sender: PropTypes.string.isRequired,
    };

    static contextTypes = {
      flux: PropTypes.object.isRequired,
    };

    constructor(props, context) {
      super(props);
      this.flux = context.flux;
      this.identityModel = this.flux.get(IdentityModel);
    }

    componentDidMount() {
      const { sender, recipient } = this.props;
      const identity = this.identityModel.store.getState().id;
      const attendeeId = identity === sender ? recipient : sender;
      const chatAnonymity = new ChatAnonymity(identity, attendeeId);

      this.identityModel.store.listen(this.identityUpdated);

      this.subscription = chatAnonymity.writingAsAnonymous
        .pipe(take(1))
        .subscribe(writingAsAnonymous => {
          this.setState({ writingAsAnonymous }, () =>
            this.updateAttachments(identity),
          );
        });
    }

    componentWillUnmount() {
      this.identityModel.store.unlisten(this.identityUpdated);
      if (this.paidResourceModel) {
        this.paidResourceModel.store.unlisten(
          this.onResourceAccessStatusChanged,
        );
      }
      if (this.subscription) {
        this.subscription.unsubscribe();
        this.subscription = null;
      }
    }

    updateAttachments = identity => {
      const { writingAsAnonymous } = this.state;
      const { reference, basename, sender, mediatype, recipient } = this.props;
      const parsedReference = url.parse(reference);
      const isCheerAttachment =
        parsedReference.pathname &&
        parsedReference.pathname.split('/')[1] === 'cheers';

      let state;

      if (isCheerAttachment) {
        const path = `${reference}/${basename}`;

        state = {
          status: RESOURCE_ACCESS_STATUS.AVAILABLE,
          path,
          identity,
          resourcePath: path,
        };
      } else {
        const outgoing = identity === sender;
        const isPhotoAttachment = mediatype?.startsWith('image');
        const getPath = isPhotoAttachment ? dialogPhotoPath : dialogVideoPath;
        const resourcePath = getPath(
          sender,
          recipient,
          basename,
          false,
          writingAsAnonymous,
        );

        if (!outgoing) {
          if (this.paidResourceModel) {
            this.paidResourceModel.store.unlisten(
              this.onResourceAccessStatusChanged,
            );
          }
          this.paidResourceModel = this.flux.get(
            PaidResourceModel,
            getPaidResourceModelId(identity, resourcePath),
          );
          this.paidResourceModel.store.listen(
            this.onResourceAccessStatusChanged,
          );

          const currentStatus = this.paidResourceModel.store.getState().status;

          const available = currentStatus === RESOURCE_ACCESS_STATUS.AVAILABLE;

          if (currentStatus === RESOURCE_ACCESS_STATUS.UNKNOWN) {
            this.paidResourceModel.actions.access();
          }

          const path = getPath(
            sender,
            recipient,
            basename,
            isPhotoAttachment ? !available : true,
            writingAsAnonymous,
          );

          state = {
            status: currentStatus,
            path,
            identity,
            resourcePath,
          };
        } else {
          const path = getPath(
            sender,
            recipient,
            basename,
            !isPhotoAttachment,
            writingAsAnonymous,
          );

          state = {
            status: RESOURCE_ACCESS_STATUS.AVAILABLE,
            path,
            identity,
            resourcePath,
          };
        }
      }

      this.setState(state);
    };

    identityUpdated = state => {
      const { identity, writingAsAnonymous } = this.state;

      if (state.id === identity) {
        return;
      }
      this.updateAttachments(state.id, writingAsAnonymous);
    };

    onResourceAccessStatusChanged = state => {
      const { writingAsAnonymous, status } = this.state;
      const { mediatype, sender, recipient, basename } = this.props;

      if (status !== state.status) {
        const available = state.status === RESOURCE_ACCESS_STATUS.AVAILABLE;
        const isPhotoAttachment = mediatype?.startsWith('image');
        const getPath = isPhotoAttachment ? dialogPhotoPath : dialogVideoPath;
        const path = getPath(
          sender,
          recipient,
          basename,
          isPhotoAttachment ? !available : true,
          writingAsAnonymous,
        );

        this.setState({
          status: state.status,
          path,
        });
      }
    };

    openResource = () => {
      const { resourcePath, status } = this.state;
      const { mediatype } = this.props;

      if (
        status !== RESOURCE_ACCESS_STATUS.AVAILABLE &&
        status !== RESOURCE_ACCESS_STATUS.PAYMENT_REQUIRED
      ) {
        return;
      }

      const isPhotoAttachment = mediatype?.startsWith('image');

      const navigate = () => {
        if (isPhotoAttachment) {
          Navigator.navigate(PHOTO, {
            path: resourcePath,
          });
        } else {
          Navigator.showVideo({
            path: resourcePath,
          });
        }
      };

      if (status === RESOURCE_ACCESS_STATUS.AVAILABLE) {
        navigate();
      } else {
        this.paidResourceModel.actions.buy(error => {
          if (error) {
            if (error.status === 402) {
              const reason = isPhotoAttachment
                ? PAYMENT_REASON.WATCH_PHOTO
                : PAYMENT_REASON.WATCH_VIDEO;

              BalanceRefiller.refillBalance(reason, this.openResource);
            }

            return;
          }

          navigate();
        });
      }
    };

    render() {
      const { identity } = this.state;

      if (!identity) {
        return null;
      }

      return (
        <Component
          {...this.state}
          {...this.props}
          openResource={this.openResource}
        />
      );
    }
  }

  return Controller;
}

export default CreateLetterAttachmentsController;
