import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import withAuthController from 'dating-mobile/src/models/identity/controller/auth';
import withUserController from 'dating-mobile/src/models/user/controllers';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';
import Resources from 'dating-mobile/src/resources';

export default function createController(Component) {
    const WrappedComponent = withAuthController(Component);

    class Controller extends PureComponent {
        static displayName = 'stepped-registration.screens.name.controller';

        static propTypes = {
            updateUser: PropTypes.func.isRequired,
            onComplete: PropTypes.func.isRequired,
        };

        static contextTypes = {
            flux: PropTypes.object,
        };

        state = {
            error: null,
        };

        onSubmit = async (componentName, credentials) => {
            const { updateUser, onComplete } = this.props;

            if (credentials && credentials.name) {
                try {
                    await updateUser({
                        name: credentials.name,
                    });

                    this.setState({ error: null });
                    onComplete();
                } catch (error) {
                    const errorMessage =
                        typeof error === 'string'
                            ? error
                            : error.message ||
                              error.desc ||
                              Resources.strings['error-message-name'];

                    this.setState({
                        error: errorMessage,
                    });
                }
            }
        };

        render() {
            const { error } = this.state;

            return <WrappedComponent {...this.props} error={error} onSubmit={this.onSubmit} />;
        }
    }

    return withIdentityId(withUserController(Controller), 'id');
}
