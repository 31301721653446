import React from 'react';
import Service from '../service';
import SmilesLoaderService from './smiles/loader';
import PushNotificationsService from './push-notifications';
import UserEventsProvider from './user-events-provider';
import NotificationsProvider from './notifications-provider';
import UserTagsProvider from './user-tags-provider';
import LocationProvider from './location-provider';
import InvitationProvider from './dialogs-automation-productions-invitation-provider';
import EventsReloader from './events-reloader';
import CreditsAccountsBalanceProvider from './credits-accounts-balance-provider';
import DialogsMessagesProvider from './dialogs-messages-provider';
import CallPresenter from './call-presenter';
import TypingProvider from './dialogs-messages-incoming-typing';
import DevicesModifying from './devices-modifiying';
import FingerprintSender from './fingerprint-sender';
import RoutingDeeplinkingService from './deeplinking/routing';
import OutgoingLettersResender from './paid-letters-resender';
import DeepLinkedCall from './deeplinking/deep-linked-call';
import DeepLinkedLettersInbox from './deeplinking/deep-linked-letters-inbox';
import DeepLinkedChatting from './deeplinking/deep-linked-chatting';

export default class FunctionalServices extends Service {
    static displayName = 'service.functional';

    render() {
        return [
            <SmilesLoaderService key="smiles-loader" />,
            <NotificationsProvider key="notifications-provider" />,
            <TypingProvider key="typing-provider" />,
            <UserEventsProvider key="user-events-provider" />,
            <PushNotificationsService key="push-notifications" />,
            <UserTagsProvider key="user-tags-provider" />,
            <LocationProvider key="location-provider" />,
            <InvitationProvider key="dialogs-automation-productions-invitation-provider" />,
            <EventsReloader key="events-reloaded" />,
            <CreditsAccountsBalanceProvider key="credits-accounts-balance-provider" />,
            <DialogsMessagesProvider key="dialogs-messages-provider" />,
            <CallPresenter key="call-presenter" />,
            <DevicesModifying key="devices-modifying" />,
            <FingerprintSender key="fingerprint-sender" />,
            <RoutingDeeplinkingService key="routing-deeplinking" />,
            <OutgoingLettersResender key="outgoing-letters-resender" />,
            <DeepLinkedCall key="deep-linked-call" />,
            <DeepLinkedLettersInbox key="deep-linked-letters-inbox" />,
            <DeepLinkedChatting key="deep-linked-chatting" />,
        ];
    }
}
