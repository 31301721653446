import { BehaviorSubject, Subject, combineLatest, merge } from 'rxjs';
import {
    filter,
    map,
    switchMap,
    withLatestFrom,
    mapTo,
    distinctUntilChanged,
} from 'rxjs/operators';
import Persistence from '@sdv/domain/app/persistence';
import { singleton } from '@sdv/commons/utils/singleton';
import Navigator from 'dating-mobile/src/routing/navigator';
import Resources from 'dating-mobile/src/resources';
import flux from '@sdv/domain/app/flux';
import StreamModel from '../streaming/stream';
import { startStream } from '@sdv/domain/app/streaming/stream-starting';
import { IMAGE_UPLOAD_STATUS } from '@sdv/domain/app/image-uploader';
import { StreamCoverUploader } from '../streaming/stream-cover-uploader';

const keys = {
    topics: 'stream-description-preset.topics',
    title: 'stream-description-preset.title',
    cover: 'stream-description-preset.cover',
};

const MAX_COUNT_TOPICS = 2;

class StreamDescriptionEditingViewModel {
    static shared = singleton(streamId => new StreamDescriptionEditingViewModel(streamId));

    constructor(streamId) {
        const persistence = Persistence.shared();

        this.streamId = streamId;

        this.topics = new BehaviorSubject([]);
        this.title = new BehaviorSubject('');
        this.cover = new BehaviorSubject('');

        // TODO значение из хранилища может прилететь позже, чем изменения сделанные стримером
        persistence
            .rxValue(keys.title)
            .pipe(filter(value => value))
            .subscribe(value => {
                this.title.next(value);
            });

        persistence
            .rxValue(keys.cover)
            .pipe(filter(value => value))
            .subscribe(value => {
                this.cover.next(value);
            });

        persistence
            .rxValue(keys.topics)
            .pipe(filter(value => value))
            .subscribe(value => {
                const topics = JSON.parse(value);
                this.topics.next(topics);
            });

        this.saveTopicsButtonPressed = new Subject();
        this.goLiveButtonPressed = new Subject();
        this.topicPressed = new Subject();
        this.coverSelected = new Subject();
        this.changeMicrophoneMute = new Subject();

        const coverUploader = new StreamCoverUploader();

        this.coverSelected
            .pipe(
                filter(source => source),
                switchMap(source => coverUploader.uploadStreamCover(source, streamId)),
                filter(upload => upload.status === IMAGE_UPLOAD_STATUS.COMPLETED),
                map(upload => coverUploader.getCoverPath(streamId, upload.basename)),
            )
            .subscribe(path => this.cover.next(path), () => {});

        this.saveTopicsButtonPressed
            .pipe(
                withLatestFrom(this.topics),
                filter(([_, topics]) => topics.length > 0),
            )
            .subscribe(() => {
                Navigator.pop();
            });

        this.goLiveButtonPressed
            .pipe(
                withLatestFrom(this.topics),
                filter(([_, topics]) => topics.length > 0),
                withLatestFrom(this.cover, this.title, this.topics),
            )
            .subscribe(([, cover, title, topics]) => {
                flux.get(StreamModel, this.streamId).actions.updateStream(title, cover, topics);

                persistence.store(keys.topics, JSON.stringify(topics));
                persistence.store(keys.title, title);
                persistence.store(keys.cover, cover);

                Navigator.pop();
                startStream();
            });

        this.canSelectTopics = this.topics.pipe(map(topics => topics.length < MAX_COUNT_TOPICS));

        this.topicPressed
            .pipe(withLatestFrom(combineLatest(this.title, this.topics)))
            .subscribe(([value, [title, array]]) => {
                const isExist = array.includes(value);

                this.title.next(
                    this.prepareTitle(
                        title,
                        Resources.strings['streams-topics-list'][value],
                        !isExist,
                    ),
                );
                this.topics.next(this.prepareTopics(array, value));
            });

        this.hintVisible = merge(
            this.topics.pipe(mapTo(false)),
            this.goLiveButtonPressed.pipe(
                withLatestFrom(this.topics),
                map(([, topics]) => !topics || !topics.length),
            ),
        );

        this.streamModel = flux.get(StreamModel, this.streamId);

        this.microphoneMuted = this.streamModel.store.rxState().pipe(
            map(state => state.microphoneMuted),
            distinctUntilChanged(),
        );

        this.changeMicrophoneMute
            .pipe(withLatestFrom(this.microphoneMuted))
            .subscribe(([, isMuted]) => {
                this.streamModel.actions.muteMicrophone(!isMuted);
            });
    }

    startStreamPreview = () => {
        flux.get(StreamModel, this.streamId).actions.startPreview(this.streamId);
    };

    rotateCamera = () => {
        this.streamModel.actions.rotateCamera();
    };

    switchMicrophoneMute = () => {
        this.changeMicrophoneMute.next();
    };

    close = () => {
        Navigator.pop();
    };

    prepareTitle = (current, text, needAdd) => {
        return needAdd
            ? (current + ' ' + text).trim()
            : current
                  .replace(text, '')
                  .trim()
                  .replace(/\s\s+/g, ' ');
    };

    prepareTopics = (arr, value) => {
        const indexValue = arr.indexOf(value);
        if (~indexValue) {
            return arr.filter((item, index) => index !== indexValue);
        } else {
            return [...arr, value];
        }
    };
}

export default StreamDescriptionEditingViewModel;
