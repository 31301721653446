import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $titleVisible: true,
    $topDescriptionBlockVisible: false,
    '@flavor dma': {
        $titleVisible: false,
        $topDescriptionBlockVisible: true
    },

    $statusBarStyle: '$brandStatusBarStyle',
    $statusBarBackgroundColor: '$brandColor',

    container: {
        flex: 1,
        backgroundColor: '$brandColor',
    },

    headerTitle: {
        color: '$brandTextColor'
    },

    invoices: {

        flex: 1,
        marginTop: 16,
        marginLeft: 40,
        marginRight: 40,
    },

    invoiceSeparator: {
        margin: 10
    },

    activityIndicatorContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    },
});

export default styles;
