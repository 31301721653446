import React from 'react';
import { StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import ImageButton from 'dating-mobile/src/components/navigation/views/image-button';
import Resources from 'dating-mobile/src/resources';
import styles from './styles';

function bindNavigation(Component) {
  class Navigation extends React.Component {
    static displayName = 'payment.screens.payment-methods.navigation';

    static propTypes = {
      navigation: PropTypes.object.isRequired,
    };

    close = () => {
      const { navigation } = this.props;

      navigation.pop();
    };

    setNavigationBarState = state => {
      const { navigation } = this.props;

      navigation.setParams(state);
    };

    render() {
      const { navigation } = this.props;

      return (
        <Component
          {...this.props}
          reason={navigation.getParam('reason')}
          onComplete={navigation.getParam('onComplete')}
          close={this.close}
          invoices={navigation.getParam('invoices')}
          setNavigationBarState={this.setNavigationBarState}
        />
      );
    }
  }

  Navigation.navigationOptions = ({ navigation }) => {
    return {
      headerTitle: Resources.strings['payment-methods-screen-title'],
      headerLeft: () => (
        <ImageButton
          image={Resources.images.backIcon()}
          tint={StyleSheet.flatten(styles.headerTitle).color}
          onPress={() => navigation.goBack()}
        />
      ),
    };
  };

  return Navigation;
}

export default bindNavigation;
