import React from 'react';
import connect from '@sdv/connect';
import TypingModel, { getId } from '../../user.events.typing/model';

export default function createController(Component, options = {}) {
    const attendeeIsTypingPropName = options.attendeeIsTypingPropName || 'typing';
    const userPropName = options.userPropName || 'user';
    const attendeePropName = options.attendeePropName || 'attendee';

    class IncomingTyping extends React.Component {
        static displayName = 'user-events-typing.controller';

        render() {
            const {
                forwardedRef,
                ...props
            } = this.props;

            return (
                <Component
                    {...props}
                    ref={forwardedRef}
                />
            );
        }
    }

    function getModels(flux, props) {
        if (props[userPropName] && props[attendeePropName]) {
            return {
                typingModel: flux.get(TypingModel, getId(props[userPropName], props[attendeePropName]))
            };
        }
        return {};
    }

    function mapStoresToProps(models) {
        const props = {};

        if (models.typingModel) {
            props[attendeeIsTypingPropName] = models.typingModel.store.getState().incoming;
        }

        return props;
    }

    function shouldReconnect(props, newProps) {
        return props[userPropName] !== newProps[userPropName] || props[attendeePropName] !== newProps[attendeePropName];
    }

    const IncomingTypingComponent = connect(
        getModels,
        mapStoresToProps,
        () => {},
        shouldReconnect
    )(IncomingTyping);

    return React.forwardRef((props, ref) => {
        return (<IncomingTypingComponent {...props} forwardedRef={ref} />);
    });
}
