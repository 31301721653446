import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $forceLocalizePrice: false,

    '@flavor secretly': {
        $forceLocalizePrice: true,
    },

    container: {
        height: 50,
        flexDirection: 'column',
        backgroundColor: '$accentColor',
        borderRadius: 25,
        overflow: 'visible',
        '@flavor dma': {
            backgroundColor: '$minorAccentColor',
            borderRadius: 20,
            height: 90,
        },
    },

    discountContainer: {
        position: 'absolute',
        right: 25,
        top: -17,
        backgroundColor: '#18dab6',
        height: 26,
        borderRadius: 13,
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 16,
    },

    discount: {
        fontSize: 14,
        fontWeight: 'bold',
        fontFamily: '$brandFontFamily',
        color: 'white',
    },

    content: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        '@flavor ytm, secretly': {
            justifyContent: 'center',
        },
        '@flavor dma': {
            flexDirection: 'column',
            justifyContent: 'center',
        },
    },

    title: {
        marginLeft: 32,
        fontSize: 16,
        color: '$accentTextColor',
        fontWeight: 'bold',
        fontFamily: '$brandFontFamily',
        '@flavor dma': {
            marginLeft: 0,
            fontSize: 22,
            marginBottom: 5,
        },
    },

    price: {
        marginRight: 32,
        fontSize: 16,
        color: '$accentTextColor',
        fontWeight: '500',
        fontFamily: '$brandFontFamily',
        '@flavor lovinga': {
            fontWeight: 'bold',
        },
        '@flavor dma': {
            fontWeight: 'normal',
            marginRight: 0,
        },
    },
});

export default styles;
