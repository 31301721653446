import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ActivityIndicator, Image, StatusBar, Text, View } from 'react-native';
import Resources from 'dating-mobile/src/resources';
import Button from 'dating-mobile/src/components/buttons/base';
import { BoostTimeout } from 'dating-mobile/src/feed/views/boost-timeout';
import IfConfigValue from 'dating-mobile/src/components/config-value/if';
import PackageList from '../../views/package-list';
import Balance from '../../views/user-balance';
import Description from '../../views/description';
import { BoostDiscountTimeout } from '../../views/boost-discount-timeout';
import styles from './styles';

class BoostScreen extends PureComponent {
  static displayName = 'payment.screens.boost.view';

  static propTypes = {
    id: PropTypes.string,
    getDiscountButtonVisible: PropTypes.bool,
    onLinkPress: PropTypes.func,
    onGetDiscountButtonPress: PropTypes.func,
    packages: PropTypes.array.isRequired,
    onPackagePress: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    style: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.number,
      PropTypes.array,
    ]),
  };

  renderFooter = () => {
    const { getDiscountButtonVisible, onGetDiscountButtonPress } = this.props;

    return (
      !!getDiscountButtonVisible && (
        <View style={styles.getDiscount}>
          <Button
            style={styles.getDiscountButton}
            titleStyle={styles.getDiscountButtonTitle}
            title={
              Resources.strings[
                'memberships-screen-continue-with-membership-text'
              ]
            }
            onPress={onGetDiscountButtonPress}
          />
        </View>
      )
    );
  };

  render() {
    const {
      style,
      onLinkPress,
      id,
      packages,
      getDiscountButtonVisible,
      isLoading,
      onPackagePress,
    } = this.props;
    const containerStyle = style ? [styles.container, style] : styles.container;

    return (
      <View style={containerStyle}>
        <StatusBar
          barStyle={styles.$statusBarStyle}
          backgroundColor={styles.$statusBarBackgroundColor}
        />
        {styles.$balanceVisible && (
          <View style={styles.balanceContainer}>
            <Text style={styles.balanceTitle}>
              {Resources.strings['memberships-screen-balance-title']}
            </Text>
            <Balance id={id} />
          </View>
        )}
        <View style={styles.descriptionContainer}>
          {styles.$descriptionIconVisible && (
            <Image
              source={Resources.images.coinIconBig()}
              style={styles.descriptionIcon}
            />
          )}
          {styles.$descriptionTitleVisible && (
            <Text style={styles.descriptionTitle}>
              {Resources.strings['boost-screen-top-text-title']}
            </Text>
          )}
          <View style={styles.description}>
            <Description openLink={onLinkPress} />
          </View>
        </View>
        <View style={styles.products}>
          <IfConfigValue path="features.boost-profile-enabled" equalsTo={true}>
            <BoostTimeout
              style={styles.boostTimeout}
              userId={id}
              placeholder={
                <BoostDiscountTimeout
                  style={styles.boostDiscountTimeout}
                  userId={id}
                />
              }
            />
          </IfConfigValue>
          <PackageList
            packages={packages}
            id={id}
            extraData={getDiscountButtonVisible}
            FooterComponent={this.renderFooter}
            isLoading={isLoading}
            onPackagePress={onPackagePress}
          />
          {!isLoading && !(packages || packages.length) && (
            <View style={styles.placeholderContainer}>
              <Text style={styles.placeholder}>
                {Resources.strings['boost-screen-placeholder']}
              </Text>
            </View>
          )}
        </View>
        {!!isLoading && (
          <View style={styles.activityIndicatorContainer}>
            <ActivityIndicator animating />
          </View>
        )}
      </View>
    );
  }
}

export default BoostScreen;
