import React from 'react';
import PropTypes from 'prop-types';
import Service from '../../service';
import BalanceRefiller, { PAYMENT_REASON } from 'dating-mobile/src/payment/utils/balance-refiller';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';
import LetterOutboxModel from "@sdv/domain/dialogs.mailbox/outbox";

class PaidLettersResender extends Service {
    static displayName = 'service.paid-letters-resender';
    static contextTypes = {
        flux: PropTypes.object
    };
    static propTypes = {
        userId: PropTypes.string
    };

    constructor(props, context) {
        super(props);

        this.letterEventInbox = context.flux.letterEventInbox;

    }

    componentDidMount() {
        this.letterEventInbox.addOnLetterRejectedListener(this.letterUnpaid);
    }

    componentWillUnmount() {
        this.letterEventInbox.removeOnLetterRejectedListener(this.letterUnpaid);
    }

    letterUnpaid = ({tag}) => {
        //TODO: fix issue with paywall appears faster than dismiss happens
        setTimeout(() => {
            let letterOutboxModel = null;
            if (this.props.userId) {
                letterOutboxModel = this.context.flux.get(LetterOutboxModel, this.props.userId);
            }
            if (letterOutboxModel) {
                const letters = letterOutboxModel.store.getState().letters;
                const unpaidLetter = letters.filter(letter => letter.tag === tag)[0];
                if (unpaidLetter) {
                    BalanceRefiller.refillBalance(
                        PAYMENT_REASON.SEND_LETTER,
                        () => {
                            letterOutboxModel.actions.sendLetter(unpaidLetter);
                        }
                    );
                }
            }
        }, 3000);

    };
}

export default withIdentityId(PaidLettersResender);
