import React from 'react';
import { View, FlatList, StatusBar } from 'react-native';
import PropTypes from 'prop-types';
import styles from './styles';
import Item from '../views/list-item';

class CurrentlyBroadcastingView extends React.PureComponent {
    static displayName = 'dialogs.stream.currently-broadcasting.view';
    static propTypes = {
        streams: PropTypes.array.isRequired,
        attachCoStreamer: PropTypes.func,
        onBroadcastTogetherButtonPress: PropTypes.func.isRequired,
    };

    renderItem = ({ item }) => {
        return (
            <View style={styles.itemContainer}>
                <Item id={item} onBroadcastTogetherButtonPress={this.props.onBroadcastTogetherButtonPress(item)}/>
            </View>
        )
    };

    render() {

        return (
            <View style={styles.container}>
                <StatusBar
                    barStyle={styles.$statusBarStyle}
                    backgroundColor={styles.$statusBarBackgroundColor}
                    translucent={false}
                />
                <FlatList
                    style={styles.flatList}
                    data={this.props.streams}
                    renderItem={this.renderItem}
                    alwaysBounceVertical={false}
                />
            </View>
        )
    }

}

export default CurrentlyBroadcastingView;
