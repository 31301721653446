import { Subject } from 'rxjs';
import Navigator from 'dating-mobile/src/routing/navigator';
import { PAYMENT_MODAL } from 'dating-mobile/src/routing/router/constants';

class InteractiveBalanceRefiller {
  refillBalanceReason = new Subject();

  refillBalance = (reason, refillCallback, cancelCallback, params) => {
    this.showPaymentScreen(
      {
        reason,
        params,
        cancelButtonVisible: true,
        ableToNavigateToMembership: true,
      },
      refillCallback,
      cancelCallback,
    );
  };

  purchaseCallsPack = (reason, successCallback, cancelCallback, params) => {
    this.showPaymentScreen(
      {
        reason,
        params,
        cancelButtonVisible: true,
        callsPackRequired: true,
      },
      successCallback,
      cancelCallback,
    );
  };

  subscribe = (reason, subscribeCallback, cancelCallback, params) => {
    this.showPaymentScreen(
      {
        reason,
        params,
        cancelButtonVisible: true,
        membershipRequired: true,
      },
      subscribeCallback,
      cancelCallback,
    );
  };

  purchaseCredits = (reason, purchaseCallback, cancelCallback, params) => {
    this.showPaymentScreen(
      {
        reason,
        params,
        cancelButtonVisible: true,
        creditsRequired: true,
      },
      purchaseCallback,
      cancelCallback,
    );
  };

  showPaymentScreen = (params, purchaseCallback, cancelCallback) => {
    this.refillBalanceReason.next(params.reason);

    Navigator.navigate(PAYMENT_MODAL, {
      ...params,
      onComplete: product => {
        if (product) {
          if (typeof purchaseCallback === 'function') {
            purchaseCallback(product);
          }
        } else if (typeof cancelCallback === 'function') {
          cancelCallback();
        }
      },
    });
  };
}

export default InteractiveBalanceRefiller;

export const PAYMENT_REASON = {
  MANUAL: 'manual',
  SEND_MESSAGE: 'send-message',
  SEND_PHOTO: 'send-photo',
  SEND_VIDEO: 'send-video',
  SEND_CHEER: 'send-cheer',
  SEND_LETTER: 'send-letter',
  WATCH_PHOTO: 'watch-photo',
  WATCH_VIDEO: 'watch-video',
  OPEN_LETTER: 'open-letter',
  USE_ADVANCED_SEARCH: 'use-advanced-search',
  VIEW_PRIVATE_PHOTO: 'view-private-photo',
  START_MINGLE: 'start-mingle',
  BOOST_PROFILE: 'boost-profile',
  BOOST_MESSAGE: 'boost-message',
  BECOME_HOST: 'become-host',
  CALL: 'call',
  ONBOARDING: 'onboarding',
};
