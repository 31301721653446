/* eslint-disable import/named */
import React from 'react';
import PropTypes from 'prop-types';
import equal from 'fast-deep-equal';
import { take } from 'rxjs/operators';
import { ChatAnonymity } from '@sdv/domain/chat/chat-anonymity';
import IdentityModel from '@sdv/domain/identity/model';
import { PHOTO } from 'dating-mobile/src/routing/router/constants';
import Navigator from 'dating-mobile/src/routing/navigator';
import { DELIVERY_STATUS, MESSAGE_TYPES } from '../model';
import PaidResourceModel, {
  RESOURCE_ACCESS_STATUS,
  getId as getPaidResourceModelId,
} from '../../paid-resource/model';
import { dialogPhotoPath, dialogVideoPath } from '../../../resources/remote';
import BalanceRefiller, {
  PAYMENT_REASON,
} from '../../../payment/utils/balance-refiller';

function createMediaContentControlledComponent(Component) {
  class ControllerComponent extends React.Component {
    static displayName = 'common.messages.message.media.controller';

    static propTypes = {
      bus: PropTypes.object,
      message: PropTypes.shape({
        outgoing: PropTypes.bool.isRequired,
        sender: PropTypes.string.isRequired,
        recipient: PropTypes.string.isRequired,
        type: PropTypes.number.isRequired,
        content: PropTypes.oneOfType([
          PropTypes.shape({
            basename: PropTypes.string.isRequired,
          }),
          PropTypes.shape({
            filePath: PropTypes.string.isRequired,
            preview: PropTypes.string.isRequired,
            progress: PropTypes.number.isRequired,
          }),
        ]).isRequired,
      }),
      attendee: PropTypes.string,
    };

    static contextTypes = {
      flux: PropTypes.object.isRequired,
    };

    mounted = false;

    constructor(props, context) {
      super(props, context);

      this.flux = context.flux;
      this.identityModel = this.flux.get(IdentityModel);
      this.state = {};
    }

    componentDidMount() {
      const { attendee, message } = this.props;
      const { status } = this.state;
      const { sender } = message || {};
      const identity = this.identityModel.store.getState().id;
      const isSender = identity === sender;
      const chatAnonymity = new ChatAnonymity(
        identity,
        isSender ? attendee : sender,
      );

      this.subscription = chatAnonymity.writingAsAnonymous
        .pipe(take(1))
        .subscribe(writingAsAnonymous => {
          this.setState({ writingAsAnonymous }, () =>
            this.updateMessage(this.props),
          );
        });

      if (this.paidResourceModel) {
        this.paidResourceModel.store.listen(this.onResourceAccessStatusChanged);
        if (status === RESOURCE_ACCESS_STATUS.UNKNOWN) {
          this.paidResourceModel.actions.access();
        }
      }
      this.mounted = true;
    }

    componentWillReceiveProps(nextProps) {
      if (!equal(this.props, nextProps)) {
        this.updateMessage(nextProps);
      }
    }

    shouldComponentUpdate(nextProps, nextState) {
      return !!this.state && !equal(this.state, nextState);
    }

    componentWillUnmount() {
      if (this.paidResourceModel) {
        this.paidResourceModel.store.unlisten(
          this.onResourceAccessStatusChanged,
        );
      }
      this.mounted = false;

      if (this.subscription) {
        this.subscription.unsubscribe();
        this.subscription = null;
      }
    }

    getParticipants(props) {
      let sender;
      let recipient;
      const { attendee, message = {} } = props;
      const identity = this.identityModel.store.getState().id;

      if (identity === message.sender) {
        sender = identity;
        recipient = attendee;
      } else {
        ({ sender } = message);
        recipient = identity;
      }

      return [sender, recipient];
    }

    onResourceAccessStatusChanged = state => {
      const { status } = this.state;

      if (status !== state.status) {
        const available = state.status === RESOURCE_ACCESS_STATUS.AVAILABLE;

        const { message } = this.props;
        const { writingAsAnonymous } = this.state || {};
        const [sender, recipient] = this.getParticipants(this.props);

        const path =
          message.type === MESSAGE_TYPES.PHOTO
            ? dialogPhotoPath(
                sender,
                recipient,
                message.content.basename,
                !available,
                writingAsAnonymous,
              )
            : dialogVideoPath(
                sender,
                recipient,
                message.content.basename,
                true,
                writingAsAnonymous,
              );

        this.setState({
          status: state.status,
          path,
        });
      }
    };

    openResource = () => {
      const { status, resourcePath, type } = this.state;

      if (
        (status !== RESOURCE_ACCESS_STATUS.AVAILABLE &&
          status !== RESOURCE_ACCESS_STATUS.PAYMENT_REQUIRED) ||
        !resourcePath
      ) {
        return;
      }

      const isPhoto = type === MESSAGE_TYPES.PHOTO;

      const navigate = () => {
        if (isPhoto) {
          Navigator.navigate(PHOTO, {
            path: resourcePath,
          });
        } else {
          Navigator.showVideo({
            path: resourcePath,
          });
        }
      };

      if (status === RESOURCE_ACCESS_STATUS.AVAILABLE) {
        navigate();
      } else {
        this.paidResourceModel.actions.buy(error => {
          if (error) {
            if (error.status === 402) {
              const reason = isPhoto
                ? PAYMENT_REASON.WATCH_PHOTO
                : PAYMENT_REASON.WATCH_VIDEO;

              BalanceRefiller.refillBalance(reason, this.openResource);
            }

            return;
          }

          navigate();
        });
      }
    };

    resend = () => {
      const {
        bus,
        message: { tag },
      } = this.props;

      if (bus) bus.emit('command.messages.log.resend-media', tag);
    };

    updateMessage(props) {
      const { writingAsAnonymous, resourcePath } = this.state || {};
      const { message } = props;
      const identity = message.outgoing ? message.sender : message.recipient;
      const [sender, recipient] = this.getParticipants(props);

      let state;

      if (message.content && message.content.filePath) {
        state = {
          type: message.type,
          source: {
            uri:
              message.type === MESSAGE_TYPES.PHOTO
                ? message.content.filePath
                : message.content.preview,
          },
          resourcePath: null,
          path: null,
          status: RESOURCE_ACCESS_STATUS.AVAILABLE,
          progress: message.content.progress,
          canResend: message.status === DELIVERY_STATUS.FAILED,
          messageDelivered: message.status === DELIVERY_STATUS.DELIVERED,
        };
      } else if (message.content && message.content.basename) {
        const getPath = MESSAGE_TYPES.PHOTO ? dialogPhotoPath : dialogVideoPath;
        const newResourcePath = getPath(
          sender,
          recipient,
          message.content.basename,
          false,
          writingAsAnonymous,
        );

        let status = RESOURCE_ACCESS_STATUS.AVAILABLE;

        if (!message.outgoing) {
          if (!this.state || resourcePath !== newResourcePath) {
            if (this.paidResourceModel) {
              this.paidResourceModel.store.unlisten(
                this.onResourceAccessStatusChanged,
              );
            }
            this.paidResourceModel = this.flux.get(
              PaidResourceModel,
              getPaidResourceModelId(identity, newResourcePath),
            );
            if (this.mounted) {
              this.paidResourceModel.store.listen(
                this.onResourceAccessStatusChanged,
              );
            }
          }

          status = this.paidResourceModel.store.getState().status;
          if (this.mounted && status === RESOURCE_ACCESS_STATUS.UNKNOWN) {
            this.paidResourceModel.actions.access();
          }
        }

        const available = status === RESOURCE_ACCESS_STATUS.AVAILABLE;
        let path;

        if (status !== RESOURCE_ACCESS_STATUS.UNKNOWN) {
          path =
            message.type === MESSAGE_TYPES.PHOTO
              ? dialogPhotoPath(
                  sender,
                  recipient,
                  message.content.basename,
                  !available,
                  writingAsAnonymous,
                )
              : dialogVideoPath(
                  sender,
                  recipient,
                  message.content.basename,
                  true,
                  writingAsAnonymous,
                );
        } else {
          path = null;
        }

        state = {
          type: message.type,
          source: null,
          resourcePath: newResourcePath,
          path,
          status,
          progress: null,
          canResend: false,
          messageDelivered: message.status === DELIVERY_STATUS.DELIVERED,
        };
      }

      this.setState(state);
    }

    render() {
      return (
        <Component
          {...this.state}
          {...this.props}
          ref={ref => {
            this.component = ref;
          }}
          openResource={this.openResource}
          resend={this.resend}
        />
      );
    }
  }

  return ControllerComponent;
}

export default createMediaContentControlledComponent;
