import EStyleSheet from 'react-native-extended-stylesheet';
import shouldUseWebLayout from 'dating-mobile/src/utils/web-layout';

const isDesktopLayout = shouldUseWebLayout();

const styles = EStyleSheet.create({
  $photoRounded: false,
  $infoContainerGradientColors: ['transparent', 'transparent'],
  $showLocation: false,

  itemContainer: {},

  item: {
    backgroundColor: 'white',
    overflow: 'hidden',
    height: '100%',
    width: '100%',
    alignItems: 'stretch',
    borderRadius: 20,
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: '$primaryBorderColor',
  },

  imageContainer: {
    height: '100%',
    width: isDesktopLayout ? 110 : 90,
  },

  image: {
    width: '100%',
    height: '100%',
    borderRadius: 20,
  },

  descriptionContainer: {
    paddingLeft: 20,
    paddingRight: 40,
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    ...(isDesktopLayout
      ? { paddingTop: 16, paddingBottom: 24 }
      : { paddingVertical: 10, justifyContent: 'space-between' }),
  },

  name: {
    fontFamily: '$brandFontFamily',
    fontWeight: isDesktopLayout ? 'bold' : 'normal',
    fontSize: isDesktopLayout ? 18 : 16,
    color: 'black',
    marginBottom: 0,
  },

  about: {
    maxWidth: isDesktopLayout ? '90%' : '100%',
    fontSize: isDesktopLayout ? 16 : 14,
  },

  textContainerCenter: {
    flex: 1,
    justifyContent: 'center',
  },

  onlineContainer: {
    marginLeft: 20,
  },

  winkButton: {
    position: 'absolute',
    bottom: 33,
  },

  vipIndicator: {
    position: 'absolute',
    top: 30,
    right: 8,
  },

  reportButtonContainer: {
    zIndex: 1,
    position: 'absolute',
    top: 0,
    right: 6,
  },

  reportButtonContainerCenter: {
    top: 20,
  },

  reportButton: {
    transform: [{ rotate: '90deg' }],
  },
});

export default styles;
