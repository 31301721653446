import React, { PureComponent } from 'react';
import PropTypes from 'prop-types'
import { View } from 'react-native';
import Header from './views/header'

import styles from './styles';

export class StackNavigator extends PureComponent {
    static displayName = 'components.stack-navigator';
    static propTypes = {
        routes: PropTypes.object,
        initial: PropTypes.object,
        onClose: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.routes = this.prepareRoutes();
        this.state = {
            stack: [
                props.initial({
                    routes: this.getRoutes()
                })
            ]
        };
    }

    getRoutes = () => {
        return this.routes
    };

    prepareRoutes = () => {
        const { routes } = this.props;

        return Object.keys(routes)
            .reduce((acc, routeName) => {
                acc[routeName] = (data) => this.push(
                    routes[routeName]({
                        routes: this.getRoutes(),
                        ...data
                    })
                );

                return acc;
            }, {});
    };


    push = (item) => {
        this.setState({
            stack: [item, ...this.state.stack]
        })
    };

    handleBackClick = () => {
        const { stack: [_, ...stack]} = this.state;

        this.setState({
            stack,
        })
    };

    getComponentFromStack = () => {
        const { stack } = this.state;

        if (stack.length > 0) {
            const [current] = stack;

            return {
                Component: current,
                title: current.props.navigationTitle
            }
        }

        return null
    };

    render() {
        const {Component, title} = this.getComponentFromStack();

        return (
            <View style={styles.container}>
                <Header
                    showClose
                    showBack={this.state.stack.length > 1}
                    title={title}
                    onBackPress={this.handleBackClick}
                    onClosePress={this.props.onClose}
                />
                <View style={styles.content}>
                    {Component}
                </View>
            </View>
        );
    }
}