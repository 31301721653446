import Model from './model';
import withAddLetterNotification from "./decorator/add-or-remove-letter-to-messages";

let actions = withAddLetterNotification(Model.actions);
actions.getDisplayName = function (id) {
    return `outgoing-letters.${id}`;
};

export default {
    actions: actions,
    store: Model.store(actions)
};

export { LETTER_DELIVERY_STATUS } from './letter-delivery-status';

export { newLetter } from './model/actions';
