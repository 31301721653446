import EStyleSheet from 'react-native-extended-stylesheet';

const textStyle = {
  color: '$primaryTextColor',
  fontFamily: '$brandFontFamily',
};

export const styles = EStyleSheet.create({
  container: {
    paddingHorizontal: 25,
    paddingBottom: 15,
    borderBottomWidth: 1,
    borderBottomColor: '$primaryBorderColor',
    flexDirection: 'row',
    alignItems: 'center',
  },

  // Workaround: Images borderRadius + BackgroundColor doesn't work on Android properly
  thumbnailWrapper: {
    overflow: 'hidden',
    borderRadius: 67,
    marginRight: 15,
  },

  thumbnail: {
    width: 134,
    height: 134,
  },

  userDetails: {
    flexShrink: 1,
  },

  userName: {
    ...textStyle,
    fontSize: 18,
    marginBottom: 8,
  },

  userAbout: {
    ...textStyle,
    fontSize: 15,
  },
});

export default styles;
