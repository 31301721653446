import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity, Image } from 'react-native';
import testId from 'dating-mobile/src/utils/test-id';
import Resources from 'dating-mobile/src/resources';

import styles from './styles';

export default class CheersButton extends PureComponent {
  static displayName = 'dialogs.stream.views.cheers-button.view';

  static propTypes = {
    animate: PropTypes.bool,
    onPress: PropTypes.func,
    style: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.number,
      PropTypes.array,
    ]),
  };

  getGiftIcon() {
    const { animate } = this.props;

    return animate && !!styles.$animationEnabled
      ? Resources.images.giftBigIconAnimated()
      : Resources.images.giftBigIcon();
  }

  render() {
    const { style, onPress } = this.props;

    return (
      <TouchableOpacity
        style={[styles.cheersButtonContainer, style]}
        onPress={onPress}
        {...testId('Open gift keyboard button')}
      >
        <Image style={styles.cheersButtonIcon} source={this.getGiftIcon()} />
      </TouchableOpacity>
    );
  }
}
