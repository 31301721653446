import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    list: {
        width: '100%',
        marginBottom: 4
    },

    loadChainButtonContainer: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingBottom: 8,
        backgroundColor: '#fff'
    },

    loadChainButton: {
        width: 300,
        height: 36,
        borderRadius: 18,
        backgroundColor: '#fafafa',
        alignItems: 'center',
        justifyContent: 'center',
    },

    loadChainButtonText: {
        fontSize: 13,
        fontFamily: '$brandFontFamily',
        fontWeight: 'normal',
    },

    verticalContainer: {
        flexDirection: 'column'
    },

    deleteContainer: {
        width: 88,
        alignItems: 'center',
        backgroundColor: 'red',
        justifyContent: 'center',
        '@flavor lovinga': {
            backgroundColor: '$accentColor'
        }
    },

    deleteText: {
        color: 'white',
        textAlign: 'center',
        fontFamily: '$brandFontFamily',
        fontSize: 14,
        '@flavor lovinga': {
            color: '$accentTextColor'
        }
    },

    swipeContainer: {
        flex: 1,
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    }
});

export default styles;
