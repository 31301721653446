import { combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import flux from '@sdv/domain/app/flux';
import { UserBlocking } from '@sdv/domain/user/user-blocking';
import IdentityModel from '@sdv/domain/identity/model';
import StreamsWithCustomTop from './streams-with-custom-top';

export default class StreamsList {
  constructor() {
    const streamsWithCustomTop = new StreamsWithCustomTop();
    const blockedUsers = flux
      .get(IdentityModel)
      .store.rxState()
      .pipe(switchMap(({ id }) => UserBlocking.shared(id).blockedUsersIds));

    this.subscription = combineLatest(
      streamsWithCustomTop.subscription,
      blockedUsers,
    ).pipe(
      map(([streams, nextBlockedUsers]) => ({
        ...streams,
        streams: streams.streams.filter(id => !nextBlockedUsers.includes(id)),
        cooperativeStreams: streams.cooperativeStreams.filter(
          id => !nextBlockedUsers.includes(id),
        ),
        topicCategories: Object.entries(streams.topicCategories).reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]: value.filter(id => !nextBlockedUsers.includes(id)),
          }),
          {},
        ),
      })),
    );
  }
}
