import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  listHeaderContainer: {
    flexDirection: 'column',
    alignItems: 'stretch',
    paddingHorizontal: 12,
    paddingTop: 2,
    paddingBottom: 12,
  },

  listHeaderTitle: {
    fontFamily: '$brandFontFamily',
    fontSize: 32,
    fontWeight: 'bold',
    marginTop: 6,
    marginBottom: 16,
  },

  contactsListTitle: {
    marginBottom: 12,
    marginTop: 16,
    fontSize: 18,
    fontFamily: '$brandFontFamily',
    fontWeight: 'bold',
  },
});

export default styles;
