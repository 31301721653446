import { combineLatest, Subscription } from 'rxjs';
import PushNotificationsPermissions from '@sdv/domain/app/push-notifications/permissions';
import Session from '@sdv/domain/authorization/session';
import flux from '@sdv/domain/app/flux';

import Service from '../service';

export class PushPermissionTracker extends Service {
  static displayName = 'service.tracking.push-permission-tracker';

  componentDidMount() {
    this.subscribe();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  subscribe() {
    const pushEnabledSubscription = combineLatest(
      Session.shared().userId,
      PushNotificationsPermissions.shared().pushNotificationEnabled,
    ).subscribe(
      ([userId, enabled]) => this.tack(userId, enabled),
      () => {},
    );

    this.disposeBag = new Subscription();
    this.disposeBag.add(pushEnabledSubscription);
  }

  unsubscribe() {
    if (this.disposeBag) {
      this.disposeBag.unsubscribe();
    }
  }

  tack(userId, enabled) {
    if (userId) {
      flux.api.annals.add(userId, 'push-notifications-disable', { enabled });
    }
  }
}
