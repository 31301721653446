import React from 'react';
import { StyleSheet } from 'react-native';
import PropTypes from 'prop-types';

import Resources from 'dating-mobile/src/resources';
import ImageButton from 'dating-mobile/src/components/navigation/views/image-button';

import View from './view';
import styles from './styles';

export default class Screen extends React.Component {
  static displayName = 'tips.tip.screen';

  static propTypes = {
    navigation: PropTypes.object.isRequired,
  };

  static navigationOptions = ({ navigation, navigationOptions }) => ({
    headerTitle: '',
    headerStyle: [navigationOptions.headerStyle, styles.header],
    headerTitleStyle: [navigationOptions.headerTitleStyle, styles.headerTitle],
    headerLeft: () => (
      <ImageButton
        image={Resources.images.backIcon()}
        tint={StyleSheet.flatten(styles.headerTitle).color}
        onPress={() => navigation.pop()}
      />
    ),
  });

  render() {
    const { navigation } = this.props;
    const source = navigation.getParam('source');

    return <View source={source} />;
  }
}
