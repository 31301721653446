import React, { PureComponent } from 'react';
import { Image, Text, View } from 'react-native';
import PropTypes from 'prop-types';
import Resources from 'dating-mobile/src/resources';
import { GradientButton } from 'dating-mobile/src/components/buttons/gradient';

import styles from './styles';

export default class ShareButton extends PureComponent {
  static displayName = 'user.views.share-button.view';

  static propTypes = {
    onPress: PropTypes.func,
  };

  render() {
    const { onPress } = this.props;

    return (
      <GradientButton
        onPress={onPress}
        containerStyles={styles.shareButtonContainer}
        buttonStyles={styles.shareButton}
        buttonTextStyles={styles.shareButtonText}
      >
        <View style={styles.shareButtonTextContainer}>
          <Image
            source={Resources.images.shareIcon()}
            style={styles.shareIcon}
          />
          <Text style={styles.shareButtonText}>
            {Resources.strings['account-screen-share-button-title']}
          </Text>
        </View>
      </GradientButton>
    );
  }
}
