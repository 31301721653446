/* eslint-disable no-underscore-dangle */
import PushNotificationManualAuthorization from '@sdv/domain/app/push-notifications/authorization';
import Persistence from '@sdv/domain/app/persistence';
import OS from '@sdv/domain/app/os';
import PushNotificationsPermissions from '@sdv/domain/app/push-notifications/permissions';
import { Subject, ReplaySubject } from 'rxjs';
import { singleton } from '@sdv/commons/utils/singleton';

const turnOnNotificationScreenShownKey = `domain.turnOnNotificationScreen:shown`;

class DisabledPushNotificationsIndicator {
  static shared = singleton(() => new DisabledPushNotificationsIndicator());

  constructor() {
    this.disabledPushNotificationsIndicated = new ReplaySubject();
    this.pressed = new Subject();
    PushNotificationsPermissions.shared().pushNotificationEnabled.subscribe(
      async pushNotificationEnabled => {
        this._pushNotificationEnabled = pushNotificationEnabled;
        const turnOnNotificationsShowedUp = await Persistence.shared().value(
          turnOnNotificationScreenShownKey,
        );
        const disabledPushNotificationsIndicated = turnOnNotificationsShowedUp
          ? false
          : !pushNotificationEnabled;

        this.disabledPushNotificationsIndicated.next(
          disabledPushNotificationsIndicated,
        );
      },
    );
  }

  press = async () => {
    const turnOnNotificationsShowedUp = await Persistence.shared().value(
      turnOnNotificationScreenShownKey,
    );

    if (!this._pushNotificationEnabled && !turnOnNotificationsShowedUp) {
      if (OS.shared().current !== 'web')
        PushNotificationManualAuthorization.shared().askPushPermissions();

      await Persistence.shared().store(
        turnOnNotificationScreenShownKey,
        'shown',
      );
      this.disabledPushNotificationsIndicated.next(false);
    }
  };
}

export default DisabledPushNotificationsIndicator;
