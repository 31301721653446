import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { View, Text } from 'react-native';

import styles from './styles';

class Row extends PureComponent {
    static propTypes = {
        label: PropTypes.string,
        children: PropTypes.object,
        error: PropTypes.string,
        separator: PropTypes.bool,
        style: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array]),
    };

    render() {
        const { label, children, error, separator, style } = this.props;
        const row = React.Children.count(children) > 1 || label;

        return (
            <View style={[styles.container, style]}>
                <View style={[styles.property, row ? styles.row : {}]}>
                    {!!label && <Text style={styles.label}>{label}</Text>}
                    {children}
                </View>
                {error && <Text style={styles.error}>{error}</Text>}
                {separator && <View style={styles.separator} />}
            </View>
        );
    }
}

export default Row;
