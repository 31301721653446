import React from 'react';
import PropTypes from 'prop-types';
import { View, StatusBar, Text } from 'react-native';
import { SafeAreaView } from 'react-navigation';
import styles from './styles';
import ChatsList from '../../views/chats-list';

const defaultSafeAreaInsets = { top: 'never', bottom: 'always' };

export default class ScreenView extends React.Component {
    static displayName = 'dialogs.chats.chat-requests.screen.view';
    static propTypes = {
        streams: PropTypes.array,
        invitations: PropTypes.array,
        onChatSelect: PropTypes.func,
        onDeleteChatButtonPress: PropTypes.func,
        safeAreaInsets: PropTypes.object,
    };

    onChatSelected = (event) => {
        this.props.onChatSelect && this.props.onChatSelect(event);
    };

    onDeleteChatButtonPressed = (event) => {
        this.props.onDeleteChatButtonPress && this.props.onDeleteChatButtonPress(event);
    };

    render() {
        return (
            <SafeAreaView forceInset={this.props.safeAreaInsets || defaultSafeAreaInsets} style={styles.container}>
                <StatusBar
                    barStyle={styles.$statusBarStyle}
                    backgroundColor={styles.$statusBarBackgroundColor}
                />
                <View style={styles.listContainer}>
                    <ChatsList
                        streams={this.props.streams}
                        chats={this.props.invitations}
                        onDeleteChatButtonPress={this.onDeleteChatButtonPressed}
                        onChatSelect={this.onChatSelected}
                    />
                </View>
            </SafeAreaView>
        );
    }
}
