import Actions from './actions';

function updateUsers(payload, prevUsers, revision) {
    if (!payload || revision > payload.revision) {
        return null;
    }

    let users = prevUsers;

    if (!payload.omit) {
        users = payload.users;
    } else if (payload.users.length) {
        users = users.slice();

        const userIndexes = users.reduce((map, user, index) => {
            map[user.id] = index;
            return map;
        }, {});

        payload.users.forEach(user => {
            const index = userIndexes[user.id];

            if (typeof index !== 'undefined') {
                users[index] = user;
            } else {
                userIndexes[user.id] = users.length;
                users.push(user);
            }
        });
    }

    return users;
}

function CreateFeedStore(id = 'default') {

    class FeedStore {
        static displayName = CreateFeedStore.getDisplayName(id);

        constructor(flux) {
            const actions = flux.getActions(Actions, id);

            this.state = {
                users: [],
                endReached: false,
                params: {
                    q: 1,
                    k: 1,
                    seed: 1,
                    sort: 7,
                    filter: 'photos'
                },
                fallbackParams: {},
                omit: 0,
                revision: 0,
                usersGroups: [],
                groupsEndReached: false,
                omitGroups: [],
            };

            this.bindAction(actions.search, this.update);
            this.bindAction(actions.setParams, this.updateParams);
            this.bindAction(actions.searchWithCustomDistribution, this.updateGroups);
        };

        update = (payload) => {
            const users = updateUsers(payload, this.state.users, this.state.revision);

            if (!users) {
                return;
            }

            this.setState({
                users: users,
                omit: payload.omit + payload.select,
                endReached: payload.users.length < payload.select
            });

        };

        updateGroups = (payload) => {
            const users = updateUsers(payload, this.state.usersGroups, this.state.revision);

            if (!users) {
                return;
            }

            this.setState({
                usersGroups: users,
                omitGroups: payload.omit.map((omit, index) => omit + payload.select[index]),
                groupsEndReached: payload.select.some((select, index) => payload.usersLengths[index] < select),
            });

        };

        updateParams = (payload) => {

            if (!payload) {
                return;
            }

            const changes = {};

            if (payload.params) {
                const newParams = { ...this.state.params, ...payload.params };
                changes.params = Object.keys(newParams).reduce((params, key) => {
                    const value = newParams[key];
                    if (typeof value !== 'undefined') {
                        params[key] = value;
                    }
                    return params;
                }, {});
            }

            if (payload.fallbackParams) {
                const newFallbackParams = { ...this.state.fallbackParams, ...payload.fallbackParams };
                changes.fallbackParams = Object.keys(newFallbackParams).reduce((params, key) => {
                    const value = newFallbackParams[key];
                    if (typeof value !== 'undefined') {
                        params[key] = value;
                    }
                    return params;
                }, {});
            }

            if (Object.keys(changes).length) {
                changes.revision = this.state.revision + 1;
                this.setState(changes);
            }

        };
    }

    return FeedStore;
}

CreateFeedStore.getDisplayName = function(id) {
    return `feed.${id}`;
};

export default CreateFeedStore;