import connect from '@sdv/connect';
import CallStatusModel from '../../../models/call.status';

function getModels(flux, props) {
  const models = {};

  if (props.userId) {
    models.callOperator = flux.get(CallStatusModel, props.userId);
  }

  return models;
}

function mapStoresToProps(models) {
  const props = {};

  if (models.callOperator) {
    const { status } = models.callOperator.store.getState();

    props.callStatus = status;
  }

  return props;
}

export default connect(getModels, mapStoresToProps);
