import IdentityModel from '@sdv/domain/identity/model';
import { getLetterPath } from '@sdv/domain/resources/remote';
import PaidResourceModel, { getId as getPaidResourceModelId, RESOURCE_ACCESS_STATUS } from '@sdv/domain/paid-resource';
import BalanceRefiller, { PAYMENT_REASON } from '@sdv/domain/app/balance-refiller';


export default function (flux, wrappedReading) {
    const identityModel = flux.get(IdentityModel);
    const currentUserId = identityModel.store.getState().id;
    return function readLetter(letter) {
        const letterPath = getLetterPath(letter.sender, letter.recipient, letter.id, letter.introductory);
        const paidResourceModel = flux.get(PaidResourceModel, getPaidResourceModelId(currentUserId, letterPath));
        let paidResourceStatus = paidResourceModel.store.getState().status;

        function repeat() {
            readLetter(letter);
        }

        function buyIfNeed() {
            if (paidResourceStatus === RESOURCE_ACCESS_STATUS.AVAILABLE) {
                wrappedReading(letter);
            } else {
                paidResourceModel.actions.buy((error) => {
                    if (error) {
                        if (error.status === 402) {
                            BalanceRefiller.refillBalance(PAYMENT_REASON.OPEN_LETTER, repeat);
                        }
                        return;
                    }
                    wrappedReading(letter);
                });
            }
            paidResourceModel.store.unlisten(onResourceAccessStatusChanged);
        }

        function onResourceAccessStatusChanged(state) {
            if (paidResourceStatus !== state.status) {
                paidResourceStatus = state.status;
                buyIfNeed();
            }
        }

        paidResourceModel.store.listen(onResourceAccessStatusChanged);

        if (paidResourceStatus === RESOURCE_ACCESS_STATUS.UNKNOWN) {
            paidResourceModel.actions.access();
        } else {
            buyIfNeed();
        }
    };
}
