import React from 'react';
import PropTypes from 'prop-types';
import { View, ScrollView, StatusBar, TouchableOpacity, Image, Text, Picker, ActivityIndicator, Keyboard } from 'react-native';
import { SafeAreaView } from 'react-navigation';
import styles from './styles';
import Resources from '../../../../resources';
import MessageSelection from '../../views/message-selection';
import Form from '../../../common/views/form';
import equal from 'fast-deep-equal';

import SafePicker from "../../../../components/safe-picker";

export default class ScreenView extends React.Component {
    static displayName = 'dialogs.mingle.start.screen.view';
    static propTypes = {
        isLoading: PropTypes.bool,
        isMaleButtonSelected: PropTypes.bool,
        isFemaleButtonSelected: PropTypes.bool,
        minAge: PropTypes.number,
        maxAge: PropTypes.number,
        minAgeConstraint: PropTypes.number,
        maxAgeConstraint: PropTypes.number,
        messages: PropTypes.arrayOf(PropTypes.string),
        onMaleButtonPress: PropTypes.func,
        onFemaleButtonPress: PropTypes.func,
        onMinAgeSelect: PropTypes.func,
        onMaxAgeSelect: PropTypes.func,
        onMessageSelect: PropTypes.func
    };

    state = {
        messageTyping: false
    };

    componentDidMount() {
        Keyboard.addListener('keyboardDidShow', this.onKeyboardChanged);
        Keyboard.addListener('keyboardDidHide', this.onKeyboardChanged);
    }

    componentWillUnmount() {
        Keyboard.removeListener('keyboardDidShow', this.onKeyboardChanged);
        Keyboard.removeListener('keyboardDidHide', this.onKeyboardChanged);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.props.isLoading !== nextProps.isLoading ||
            this.props.isMaleButtonSelected !== nextProps.isMaleButtonSelected ||
            this.props.isFemaleButtonSelected !== nextProps.isFemaleButtonSelected ||
            this.props.minAge !== nextProps.minAge ||
            this.props.maxAge !== nextProps.maxAge ||
            this.props.minAgeConstraint !== nextProps.minAgeConstraint ||
            this.props.maxAgeConstraint !== nextProps.maxAgeConstraint ||
            !equal(this.props.messages, nextProps.messages) ||
            this.state.messageTyping !== nextState.messageTyping;
    }

    onMaleButtonPressed = () => {
        this.props.onMaleButtonPress && this.props.onMaleButtonPress();
    };

    onFemaleButtonPressed = () => {
        this.props.onFemaleButtonPress && this.props.onFemaleButtonPress();
    };

    onMinAgeSelected = (...args) => {
        this.props.onMinAgeSelect && this.props.onMinAgeSelect(...args);
    };

    onMaxAgeSelected = (...args) => {
        this.props.onMaxAgeSelect && this.props.onMaxAgeSelect(...args);
    };

    onMessageSelected = (message) => {
        if (message) {
            this.props.onMessageSelect && this.props.onMessageSelect(message);
        } else {
            this.setState({
                messageTyping: true
            });
        }
    };

    onChooseMessagePressed = () => {
        this.setState({
            messageTyping: false
        });
    };

    onKeyboardChanged = () => {
        this.scrollRef.scrollToEnd({ animated: false });
    };

    renderAgePickerItems = () => {
        if (!this.props.minAgeConstraint || !this.props.maxAgeConstraint) {
            return [];
        }

        const min = this.props.minAgeConstraint;
        const max = this.props.maxAgeConstraint;

        return Array.apply(null, { length: max + 1 })
            .map(Number.call, Number)
            .splice(min, max)
            .map((value) => (
                <Picker.Item key={`${value}`} label={`${value}`} value={value} />
            ));
    };

    render() {
        const maleGenderButtonStyle = [
            styles.genderButton,
            this.props.isMaleButtonSelected ? styles.selectedMaleGenderButton : {}
        ];

        const maleGenderButtonIconStyle = [
            styles.genderButtonIcon,
            this.props.isMaleButtonSelected ? styles.selectedGenderButtonIcon : {}
        ];

        const femaleGenderButtonStyle = [
            styles.genderButton,
            this.props.isFemaleButtonSelected ? styles.selectedFemaleGenderButton : {}
        ];

        const femaleGenderButtonIconStyle = [
            styles.genderButtonIcon,
            this.props.isFemaleButtonSelected ? styles.selectedGenderButtonIcon : {}
        ];

        const forceInset = {
            top: 'never',
            bottom: this.state.messageTyping ? 'never' : 'always'
        };

        return (
            <SafeAreaView forceInset={forceInset} style={styles.container}>
                <StatusBar
                    barStyle={styles.$statusBarStyle}
                    backgroundColor={styles.$statusBarBackgroundColor}
                />
                <ScrollView
                    ref={ref => this.scrollRef = ref}
                    horizontal={false}
                    style={styles.scroll}
                    contentContainerStyle={styles.scrollContent}
                    alwaysBounceVertical={false}
                    keyboardShouldPersistTaps={'handled'}
                >
                    <Text style={styles.description}>{Resources.strings['start-mingle-screen-description']}</Text>
                    <View style={styles.preferredControlsContainer}>
                        <Text style={styles.preferredGenderTitle}>
                            {Resources.strings['start-mingle-screen-preferred-gender-selection-header-text']}
                        </Text>
                        <View style={styles.preferredGenderControlsContainer}>
                            <TouchableOpacity style={maleGenderButtonStyle} onPress={this.onMaleButtonPressed}>
                                <Image style={maleGenderButtonIconStyle} source={Resources.images.guyIcon()} />
                            </TouchableOpacity>
                            <TouchableOpacity style={femaleGenderButtonStyle} onPress={this.onFemaleButtonPressed}>
                                <Image style={femaleGenderButtonIconStyle} source={Resources.images.girlIcon()} />
                            </TouchableOpacity>
                        </View>
                        <Text style={styles.preferredAgeTitle}>
                            {Resources.strings['start-mingle-screen-preferred-age-selection-header-text']}
                        </Text>
                        <View style={styles.preferredAgeControlsContainer}>
                            <SafePicker
                                title={String(this.props.minAge || '')}
                                style={styles.ageButton}
                                titleStyle={styles.ageButtonTitle}
                            >
                                <Picker
                                    selectedValue={this.props.minAge}
                                    onValueChange={this.onMinAgeSelected}
                                >
                                    {this.renderAgePickerItems()}
                                </Picker>
                            </SafePicker>
                            <SafePicker
                                title={String(this.props.maxAge || '')}
                                style={styles.ageButton}
                                titleStyle={styles.ageButtonTitle}
                            >
                                <Picker
                                    selectedValue={this.props.maxAge}
                                    onValueChange={this.onMaxAgeSelected}
                                >
                                    {this.renderAgePickerItems()}
                                </Picker>
                            </SafePicker>
                        </View>
                    </View>
                    {
                        !this.state.messageTyping && (
                            <View style={styles.messagesContainer}>
                                <MessageSelection messages={this.props.messages} onMessageSelect={this.onMessageSelected} />
                            </View>
                        )
                    }
                    {
                        this.state.messageTyping && (
                            <TouchableOpacity style={styles.chooseMessageButton} onPress={this.onChooseMessagePressed}>
                                <Text style={styles.chooseMessageButtonTitle}>
                                    {Resources.strings['start-mingle-screen-choose-message-button-title']}
                                </Text>
                            </TouchableOpacity>
                        )
                    }
                {
                    this.state.messageTyping && (
                        <Form
                            autoFocus={true}
                            giftsEnabled={false}
                            mediaEnabled={false}
                            controlSafeArea={true}
                            onSend={this.onMessageSelected}
                        />
                    )
                }
                {
                    !!this.props.isLoading && (
                        <View style={styles.activityIndicatorContainer}>
                            <ActivityIndicator animating={true} />
                        </View>
                    )
                }
                </ScrollView>
            </SafeAreaView>
        );
    }
}
