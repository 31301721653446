import { singleton } from '@sdv/commons/utils/singleton';
import MessagesModel, { getId } from '@sdv/domain/dialogs.messages';
import flux from '@sdv/domain/app/flux';
import { Config } from '@sdv/domain/app/config';
import TagsModel from '@sdv/domain/user/tags/personal';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

export default class TrialChatLimitation {
    static shared = singleton((userId, attendeeId) => new TrialChatLimitation(userId, attendeeId));

    constructor(userId, attendeeId) {
        const trialMessagesEnabled = Config.shared().trialMessagesEnabled;
        const messagesModel = flux.get(MessagesModel, getId(userId, attendeeId));
        const tagsModel = flux.get(TagsModel, userId);
        const userIsCustomer = tagsModel.store.rxState().pipe(
            map(state => (state.tags || []).indexOf('customer') >= 0)
        );

        this.unpaidMessages = combineLatest(messagesModel.store.rxState(), trialMessagesEnabled, userIsCustomer).pipe(
            map(([state, trialMessagesEnabled, userIsCustomer]) => {
                return state.messages
                    .filter(message => {
                        return trialMessagesEnabled &&
                            message.needPay &&
                            !userIsCustomer &&
                            message.sender === attendeeId;
                    })
                    .map(message => message.tag)
            })
        )
    }
}
