import React from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import SwitchItem from 'dating-mobile/src/components/switch-item';
import styles from './styles';
import withConfigValue from 'dating-mobile/src/components/config-value';
import Resources from 'dating-mobile/src/resources';

export class AutobuyCheckboxView extends React.Component {
    static displayName = 'payment.views.autobuy-checkbox';

    static propTypes = {
        settings: PropTypes.shape({
            enabled: PropTypes.bool,
            available: PropTypes.bool,
            creditsAmount: PropTypes.number,
        }),
        onChecked: PropTypes.func,
        layoutStyles: PropTypes.object,
        currency: PropTypes.string
    };

    constructor(props) {
        super(props);
        this.state = {
            enabled: props.settings.enabled
        };
    }

    handleSwitchItemValueChanged = (newValue) => {
        this.setState({
            enabled: newValue
        });

        this.props.onChecked && this.props.onChecked(newValue);
    };

    render() {

        const titleTemplate = this.props.currency && {
            coins: Resources.strings['autobuy-checkbox-description-coins'],
            credits: Resources.strings['autobuy-checkbox-description-credits']
        }[this.props.currency];

        const title = Resources.strings.formatString(titleTemplate, this.props.settings.creditsAmount);

        return (
            <View style={this.props.layoutStyles}>
                <SwitchItem
                    value={this.state.enabled}
                    title={title}
                    {...this.props}
                    onValueChange={this.handleSwitchItemValueChanged}
                    titleContainerStyle={styles.switchItem}
                    titleStyle={styles.switchItemTitle}
                    titleNumberOfLines={2}
                />
            </View>
        );
    }
}

export const AutobuyCheckbox = withConfigValue(AutobuyCheckboxView, 'currency.type', 'currency');
