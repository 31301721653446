import React from 'react';
import { Subscription } from 'rxjs';
import '@sdv/commons/rx/subscribe';

//TODO: rewrite with hooks after upgrading React to version "16.8"

function arraysEqual(lhs, rhs) {
    return lhs.length === rhs.length && lhs.every((value, index) => value === rhs[index])
}

export function render(renderFunc) {

    return class RxRender extends React.Component {
        static displayName = 'components.rx-render';

        constructor(props) {
            super(props);
            this.state = {};
            this.usedHooks = [];
            this.currentHookIndex = -1;
        }

        componentWillUnmount() {
            this.usedHooks.forEach(hook => hook.subscription && hook.subscription.unsubscribe());
        }

        nextHook = () => {
            this.currentHookIndex += 1;
            if (this.currentHookIndex >= this.usedHooks.length) {
                this.usedHooks.push({});
            }
            return this.usedHooks[this.currentHookIndex];
        };

        useDistinct = (calculate, ...args) => {
            const hook = this.nextHook();
            if (hook.args === undefined || !arraysEqual(hook.args, args)) {
                hook.args = args;
                hook.cachedResult = calculate(...args);
            }
            return hook.cachedResult;
        };

        useObservable = (buildSource, ...args) => {
            const hook = this.nextHook();
            if (hook.args === undefined || !arraysEqual(hook.args, args)) {
                hook.args = args;

                hook.subscription && hook.subscription.unsubscribe();
                hook.latestValue = undefined;

                // Check to detect if current call is not finished yet and no re-rendering is needed.
                let finishedSubscribing = false;
                const source = buildSource(...args);
                hook.subscription = source.safeSubscribe(value => {
                    hook.latestValue = value;
                    if (finishedSubscribing) {
                        this.forceUpdate();
                    }
                });
                finishedSubscribing = true;
            }
            return hook.latestValue;
        };

        useObservableAction = (buildSource, ...args) => {
            const hook = this.nextHook();
            if (hook.args === undefined || !arraysEqual(hook.args, args)) {
                hook.args = args;

                hook.subscription && hook.subscription.unsubscribe();
                hook.subscription = new Subscription();

                hook.cachedAction = (...params) => {
                    hook.subscription.add(buildSource(...params).safeSubscribe());
                };
            }
            return hook.cachedAction;
        };

        render() {
            this.currentHookIndex = -1;
            return renderFunc(this.props, {
                useDistinct: this.useDistinct,
                useObservable: this.useObservable,
                useObservableAction: this.useObservableAction
            });
        }
    }

}
