import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        flexDirection: 'row-reverse',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flexShrink: 1,
    },

    name: {
        color: '#fff',
        fontSize: 19,
        fontFamily: '$brandFontFamily',
        fontWeight: '700',
        '@flavor lovinga': {
            fontSize: 17
        },
        '@flavor tubit': {
            fontSize: 13,
        }
    },

    user: {
        backgroundColor: '$shadowColor',
        borderRadius: 20,
        paddingLeft: 45,
        height: 40,
        alignItems: 'center',
        flexShrink: 1,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        paddingRight: 5,
    },
    userInfo: {
        flexShrink: 1,
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    },
    followButton: {
        fontSize: 14,
        marginLeft: 4,
    },
});

export default styles;
