import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    placeholderContainer: {
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    image: {
        marginBottom: 34,
    },
    mainText: {
        color: '$brandTextColor',
        fontFamily: '$brandFontFamily',
        fontSize: 21,
    },
    descriptionText: {
        color: '$brandTextColor',
        fontFamily: '$brandFontFamily',
        width: '60%',
        textAlign: 'center',
        marginTop: 15,
    },
});

export default styles;
