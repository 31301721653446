import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Image,
  Keyboard,
  Platform,
  TouchableOpacity,
} from 'react-native';
import OpenAppSettings from 'react-native-app-settings';
import Resources from 'dating-mobile/src/resources';

import styles from './styles';

import phoneImg from './assets/phone.png';

export default class CallButton extends PureComponent {
  static displayName = 'call.views.audio-call-button.view';

  static propTypes = {
    disabled: PropTypes.bool,
    microphoneStatus: PropTypes.string,
    requestPermission: PropTypes.func,
    dial: PropTypes.func,
  };

  static defaultProps = {
    dial: () => {},
  };

  keyboardVisible = false;

  componentDidMount() {
    this.keyboardDidShowListener = Keyboard.addListener(
      'keyboardDidShow',
      () => {
        this.keyboardVisible = true;
      },
    );
    this.keyboardDidHideListener = Keyboard.addListener(
      'keyboardDidHide',
      () => {
        this.keyboardVisible = false;
      },
    );
  }

  componentWillUnmount() {
    this.keyboardDidShowListener?.remove();
    this.keyboardDidHideListener?.remove();
  }

  onPressed = async () => {
    const { microphoneStatus, dial, requestPermission } = this.props;
    const permissionDenied =
      (Platform.OS === 'ios' && microphoneStatus === 'denied') ||
      microphoneStatus === 'restricted';

    await this.dismissKeyboard();

    if (microphoneStatus === 'authorized') {
      dial();
    } else if (permissionDenied) {
      this.showPermissionsAlert(() => OpenAppSettings.open());
    } else {
      requestPermission('microphone').then(permission => {
        if (permission === 'authorized') {
          dial();
        }
      });
    }
  };

  showPermissionsAlert = (confirmCallback, rejectCallback) => {
    Alert.alert(
      Resources.strings['audio-call-without-permissions-alert-message'],
      null,
      [
        {
          text:
            Resources.strings[
              'call-without-permissions-alert-open-settings-action-title'
            ],
          onPress: confirmCallback,
        },
        {
          text: Resources.strings.cancel,
          onPress: rejectCallback,
          style: 'cancel',
        },
      ],
      { cancelable: false },
    );
  };

  async dismissKeyboard() {
    if (this.keyboardVisible) {
      return new Promise(resolve => {
        this.listener = Keyboard.addListener('keyboardDidHide', () => {
          this.listener.remove();
          resolve();
        });

        Keyboard.dismiss();
      });
    }

    return Promise.resolve();
  }

  render() {
    const { disabled } = this.props;

    return (
      <TouchableOpacity
        disabled={disabled}
        style={[styles.container, disabled ? styles.disabled : null]}
        onPress={this.onPressed}
      >
        <Image source={phoneImg} />
      </TouchableOpacity>
    );
  }
}
