import qs from 'qs';
import connect from '@sdv/connect';
import MediaModel from 'dating-mobile/src/models/call.media';

function getModels(flux, props) {
  const models = {};

  if (props.userId && props.attendeeId) {
    const modelId = qs.stringify({
      user: props.userId,
      attendee: props.attendeeId,
    });

    models.mediaModel = flux.get(MediaModel, modelId);
  }

  return models;
}

function mapStoresToProps(models) {
  return {
    disabled: !models.mediaModel,
    speakerphoneOn: models.mediaModel?.store.getState().speakerphoneOn,
  };
}

function mapActionsToProps(models) {
  return {
    setSpeakerphoneOn: models.mediaModel?.actions.setSpeakerphoneOn,
  };
}

export default connect(getModels, mapStoresToProps, mapActionsToProps);
