import Model from '@sdv/domain/electronicmail.addresses';
import connect from '@sdv/connect';

function createHoc(Component, options = {}) {

    const validatedEmailsPropName = options.validatedEmailsPropName || 'validatedEmails';
    const validateActionPropName = options.validateActionPropName || 'validate';

    function getModels(flux) {

        return {
            addressesModel: flux.get(Model)
        };

    }

    function mapStoresToProps(models) {
        const props = {};

        if (models.addressesModel) {
            props[validatedEmailsPropName] = models.addressesModel.store.getState().emails;
        }

        return props;
    }

    function mapStoresToActions(models) {
        const props = {};

        if (models.addressesModel) {
            props[validateActionPropName] = models.addressesModel.actions.validate;
        }

        return props;
    }

    function shouldReconnect() {
        return false;
    }

    const ConnectedComponent = connect(
        getModels,
        mapStoresToProps,
        mapStoresToActions,
        shouldReconnect
    )(Component);

    ConnectedComponent.displayName = 'electronicmail-addresses.controller';

    return ConnectedComponent;

}

export default createHoc;
