import React from 'react';
import { Alert, Text, TouchableOpacity } from 'react-native';
import styles from './styles';
import PropTypes from 'prop-types';
import Resources from 'dating-mobile/src/resources';

export default class BlockUserView extends React.Component {
    static displayName = 'inbox.views.all-letters.block-user.view';

    static propTypes = {
        blockUser: PropTypes.func.isRequired
    };

    onBlockPressed = () => {
        Alert.alert(
            Resources.strings['block-user-alert-title'],
            Resources.strings['block-user-in-inbox-alert-message'],
            [
                {
                    text: Resources.strings.block,
                    onPress: () => {
                        this.props.blockUser && this.props.blockUser();
                    }
                },
                {
                    text: Resources.strings.cancel,
                    style: 'cancel'
                }
            ]
        );
    };

    render() {
        return (
            <TouchableOpacity style={styles.blockContainer} onPress={this.onBlockPressed}>
                <Text style={styles.blockUserText}>{Resources.strings['action-block-user']}</Text>
            </TouchableOpacity>
        );
    }
};
