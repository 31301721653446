// @ts-ignore
import React, {
    EventHandler,
    PropsWithChildren,
    ReactElement,
    SyntheticEvent,
    useCallback,
} from 'react';
import {
    ActivityIndicator,
    GestureResponderEvent,
    Image,
    Text,
    TouchableOpacity,
    View,
} from 'react-native';

import Resources from 'dating-mobile/src/resources';
// @ts-ignore
import MediaView from 'dating-mobile/src/user/edit/views/media';
// @ts-ignore
import Photo from 'dating-mobile/src/components/photo';

import { styles } from './styles';

type PhotoPickerProps = PropsWithChildren<{}> & {
    loadingTint: string;
    select: EventHandler<GestureResponderEvent & SyntheticEvent>;
    progress: number;
    loading: boolean;
    error: string;
    imageElement: ReactElement;
    uri: string;
    basename: string;
    userId: string;
    waitUploading: boolean;
};

export const ViewComponent = React.memo((props: PhotoPickerProps) => {
    const {
        loadingTint,
        select,
        progress,
        loading,
        error,
        uri,
        basename,
        userId,
        waitUploading,
    } = props;

    const renderImage = useCallback(() => {
        if (basename && userId) {
            return (
                <MediaView
                    basename={basename}
                    id={userId}
                    round={false}
                    style={styles.iconContainer}
                />
            );
        }

        if (uri) {
            return <Photo style={styles.iconContainer} source={{ uri }} round={false} />;
        }

        return <Image style={styles.icon} source={Resources.images.cameraBigIcon()} />;
    }, [userId, basename, uri, loading, waitUploading]);

    const renderErrorComponent = useCallback(() => {
        return error ? (
            <View style={styles.errorContainer}>
                <Text style={styles.error}>{error}</Text>
            </View>
        ) : null;
    }, [error]);

    const renderLoadingIndicator = useCallback(() => {
        const shouldRender = waitUploading && loading;

        return (
            shouldRender && (
                <View style={styles.activityIndicatorContainer}>
                    <ActivityIndicator color={loadingTint} />
                    <Text style={[styles.counter, loadingTint ? { color: loadingTint } : {}]}>
                        {progress} %
                    </Text>
                </View>
            )
        );
    }, [progress, loadingTint, loading, waitUploading]);

    const renderTapableArea = useCallback(() => {
        const shouldRender = !waitUploading || !loading;

        return (
            shouldRender && (
                <TouchableOpacity onPress={select} style={styles.iconContainer}>
                    {renderImage()}
                </TouchableOpacity>
            )
        );
    }, [loading, waitUploading, renderImage, select]);

    return (
        <View style={styles.container}>
            {renderTapableArea()}
            {renderErrorComponent()}
            {renderLoadingIndicator()}
        </View>
    );
});

ViewComponent.displayName = 'stepped-registration.views.user-thumbnail.view';
