import React from 'react';
import PropTypes from 'prop-types';
import { Text, TouchableWithoutFeedback, View, Image, TouchableOpacity } from 'react-native';
import Photo from '../../../../../components/photo';
import { RESOURCE_ACCESS_STATUS } from '../../../../../models/paid-resource/model';
import { DELIVERY_STATUS, MESSAGE_TYPES } from '../../../../../models/common.messages/model';
import styles from './styles';
import * as Progress from 'react-native-progress';
import Resources from '../../../../../resources';
import testId from 'dating-mobile/src/utils/test-id';
import { getDevaluatedPricePlural } from 'dating-mobile/src/dialogs/messages/views/chat-log-with-input/pricing-selector';

export default class MediaContent extends React.PureComponent {
    static displayName = 'common.messages.message.media-content';
    static propTypes = {
        type: PropTypes.number,
        source: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
        resourcePath: PropTypes.string,
        path: PropTypes.string,
        status: PropTypes.number,
        canResend: PropTypes.bool,
        progress: PropTypes.number,
        openResource: PropTypes.func,
        resend: PropTypes.func,
        messageDelivered: PropTypes.bool,
        openFunc: PropTypes.func,
        isPaidChat: PropTypes.bool,
        showPriceForUnpaidPhoto: PropTypes.bool,
        openPaidPhotoPrice: PropTypes.number,
    };

    open = () => {
        this.props.openFunc(this.props.openResource);
    };

    render() {
        const { ...props } = this.props;

        const paidPhotoPrice = getDevaluatedPricePlural(props, props.openPaidPhotoPrice);

        const photoStyle = [styles.photo, this.props.messageDelivered ? {} : styles.unsentPhoto];

        return (
            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                {!!props.canResend && (
                    <TouchableOpacity onPress={props.resend}>
                        <Image source={require('./assets/retry_icon.png')} />
                    </TouchableOpacity>
                )}
                <TouchableWithoutFeedback onPress={this.open}>
                    <View style={styles.container}>
                        <Photo
                            style={photoStyle}
                            path={props.path}
                            source={props.source}
                            resizeMode={'contain'}
                            {...testId(
                                props.type === MESSAGE_TYPES.PHOTO
                                    ? Resources.strings['message-photo-accessibility-label']
                                    : Resources.strings['message-video-accessibility-label'],
                            )}
                        />
                        {props.status === RESOURCE_ACCESS_STATUS.PAYMENT_REQUIRED &&
                            !!props.isPaidChat && (
                                <View style={styles.darkOverlay}>
                                    <View style={styles.paidPhotoBadge}>
                                        <Text
                                            style={styles.description}
                                            {...testId(
                                                Resources.strings[
                                                    'message-unlock-button-accessibility-label'
                                                ],
                                            )}
                                        >
                                            {paidPhotoPrice}
                                        </Text>
                                        <Image
                                            style={styles.paidIcon}
                                            source={Resources.images.coinIcon()}
                                        />
                                    </View>
                                </View>
                            )}
                        {props.status === RESOURCE_ACCESS_STATUS.PAYMENT_REQUIRED &&
                            !props.isPaidChat(
                                <View style={styles.darkOverlay}>
                                    <Image
                                        source={Resources.images.lockIcon()}
                                        {...testId(
                                            Resources.strings['message-lock-accessibility-label'],
                                        )}
                                    />
                                    <Text
                                        style={styles.description}
                                        {...testId(
                                            Resources.strings[
                                                'message-unlock-button-accessibility-label'
                                            ],
                                        )}
                                    >
                                        Tap to view{' '}
                                        {props.type === MESSAGE_TYPES.PHOTO ? 'photo' : 'video'}
                                    </Text>
                                </View>,
                            )}
                        {props.status === RESOURCE_ACCESS_STATUS.NOT_FOUND && (
                            <View style={styles.darkOverlay}>
                                <Text style={styles.description}>
                                    {props.type === MESSAGE_TYPES.PHOTO ? 'Photo' : 'Video'} can't
                                    be loaded.
                                </Text>
                            </View>
                        )}
                        {!!props.canResend && <View style={styles.darkOverlay} />}
                        {props.status === RESOURCE_ACCESS_STATUS.AVAILABLE &&
                            props.type === MESSAGE_TYPES.VIDEO && (
                                <View style={styles.overlay}>
                                    <Image source={Resources.images.playVideoIcon()} />
                                </View>
                            )}
                        {!!props.progress && (
                            <View style={styles.progress}>
                                <Progress.Pie
                                    size={styles._progress.width}
                                    progress={props.progress / 100}
                                    color={styles._progress.color}
                                />
                            </View>
                        )}
                    </View>
                </TouchableWithoutFeedback>
            </View>
        );
    }
}
