import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-native';

import NavigationItem from '../navigation-item';

export default class SwitchItem extends React.PureComponent {
    static displayName = 'components.switch-item';

    static propTypes = {
        title: PropTypes.string,
        titleStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array]),
        titleContainerStyle: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.object,
            PropTypes.array,
        ]),
        titleNumberOfLines: PropTypes.number,
        value: PropTypes.bool,
        disabled: PropTypes.bool,
        onValueChange: PropTypes.func,
    };

    render() {
        const {
            title,
            titleStyle,
            titleContainerStyle,
            titleNumberOfLines,
            value,
            onValueChange,
            disabled,
        } = this.props;

        return (
            <NavigationItem
                disabled
                title={title}
                titleStyle={titleStyle}
                titleContainerStyle={titleContainerStyle}
                titleNumberOfLines={titleNumberOfLines}
                pinVisible={false}
                ValueComponent={
                    <Switch disabled={disabled} value={value} onValueChange={onValueChange} />
                }
            />
        );
    }
}
