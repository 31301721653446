import withWinker from 'dating-mobile/src/models/dialogs.messages/controllers/winker';
import { withBlockableUser } from 'dating-mobile/src/user/views/blocked-user/with-blockable-user';
import withUser from 'dating-mobile/src/models/user/controllers';
import withConfigValue from '../../../components/config-value';
import withIdentityId from '../../../models/identity/controller/id';
import withUserPresence from '../../../models/user.presence/controllers';
import withNavigation from './navigation';
import withController from './controller';
import View from './view';

export default withNavigation(
  withUser(
    withBlockableUser(
      withIdentityId(
        withConfigValue(
          withWinker(
            withUserPresence(withController(View), {
              userIdPropName: 'attendeeId',
            }),
            {
              userIdPropName: 'identity',
              winkDelayPropName: 'winkDelay',
              attendeeIdPropName: 'attendeeId',
            },
          ),
          {
            winkDelay: 'wink-delay.chat',
            winkEnabled: 'features.user-chat-wink-enabled',
            openProfileEnabled: 'features.extended-profile-enabled',
            privatePhotosEnabled: 'features.private-photos-enabled',
            showGalleryInChat: 'markup.chat-screen.gallery-in-chat',
            allowAlias: 'features.users-alias-enabled',
            audioCallsEnabled: 'features.audio-calls-enabled',
            audioCallsRequireTag: 'features.audio-calls-require-tag',
          },
        ),
        'identity',
      ),
      {
        userIdPropName: 'attendeeId',
      },
    ),
    {
      resultPropName: 'attendee',
      userIdPropName: 'attendeeId',
    },
  ),
);
