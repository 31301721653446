import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $statusBarStyle: '$primaryStatusBarStyle',
    $statusBarBackgroundColor: '$primaryBackgroundColor',

    header: {
        backgroundColor: '$primaryBackgroundColor',
        borderBottomColor: '$primaryBackgroundColor',
        shadowColor: '$primaryBackgroundColor',
        shadowRadius: 0,
        shadowOpacity: 0,
        elevation: 0,
        shadowOffset: {
            height: 0,
        }
    },

    headerTitle: {
        color: '$primaryTextColor',
        fontFamily: '$brandFontFamily'
    },

    container: {
        backgroundColor: '$primaryBackgroundColor',
        flex: 1,
    },

    informationContainer: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        paddingHorizontal: 40
    },

    informationHeader: {
        fontSize: 24,
        fontFamily: '$brandFontFamily',
        fontWeight: 'bold',
        color: '$primaryTextColor',
        textAlign: 'center'
    },

    informationDescription: {
        marginTop: 5,
        fontSize: 16,
        fontFamily: '$brandFontFamily',
        color: '$primaryTextColor',
        textAlign: 'center'
    },

    link: {
        color: '$brandLinkTextColor'
    },

    tryAgainButtonContainer: {
        marginHorizontal: 40,
        marginBottom: 40
    }

});

export default styles;
