import React from 'react';
import PropTypes from 'prop-types';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import Resources from 'dating-mobile/src/resources';
import UserThumbnail from 'dating-mobile/src/user/common/views/thumbnail';
import WithGradient from 'dating-mobile/src/components/with-gradient';
import styles from './styles';

export default class StreamingIndicator extends React.Component {
  static displayName = 'dialogs.messages.views.streaming-cta.view';

  static propTypes = {
    userName: PropTypes.string,
    description: PropTypes.string,
    isVisible: PropTypes.bool,
    onPress: PropTypes.func,
  };

  render() {
    if (!this.props.isVisible) {
      return <View />;
    }

    const titleText = Resources.strings.formatString(
      Resources.strings['join-stream-cta-title'],
      this.props.userName ? this.props.userName : '',
    );

    return (
      <TouchableOpacity onPress={this.props.onPress}>
        <WithGradient styles={styles.container}>
          <UserThumbnail
            style={styles.photo}
            userId={this.props.attendeeId}
            round
          />
          <View style={styles.photoFade} />
          <View style={styles.infoContainer}>
            <View style={styles.descriptionContainer}>
              <Text
                style={styles.titleText}
                numberOfLines={1}
                ellipsizeMode="tail"
              >
                {titleText}
              </Text>

              {this.props.description && (
                <Text
                  numberOfLines={1}
                  ellipsizeMode="tail"
                  style={styles.descriptionText}
                >
                  {this.props.description}
                </Text>
              )}
            </View>

            <View style={styles.liveBadgeContainer}>
              <Image
                style={styles.liveBadgeIcon}
                source={Resources.images.liveBadgeIcon()}
              />
              <Text style={styles.liveBadgeText}>
                {Resources.strings.live.toUpperCase()}
              </Text>
            </View>
          </View>
        </WithGradient>
      </TouchableOpacity>
    );
  }
}
