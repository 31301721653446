import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        flex: 1
    },

    list: {
        flex: 1
    },

    item: {
        width: '100%',
        '@flavor dma': {
            aspectRatio: 1,
        }
    },

    header: {
        height: 5
    },

    footer: {
        height: 5
    }
});

export default styles;
