import User from '@sdv/domain/user/model';
import flux from '@sdv/domain/app/flux';
import UserPreferences from '@sdv/domain/user/preferences/repository';
import '@sdv/commons/rx/store';
import { map } from "rxjs/operators";
import { combineLatest, defer } from 'rxjs';
import { Config } from '@sdv/domain/app/config';

export default class SimilarAgePreferences {
    constructor(userId) {
        this.userId = userId;
        const preferences = defer(() => {
            const model = flux.get(UserPreferences, userId);
            model.actions.get();
            return model.store.rxState();
        });

        const user = defer(() => {
            const model = flux.get(User, userId);
            model.actions.get();
            return model.store.rxState();
        });
        this.preferences = combineLatest(
            preferences,
            user,
            Config.shared().preferencesLimitations
        ).pipe(
            map(([preferences, user, preferencesLimitations]) => {
                let newPreferences = preferences;
                const minAgeLimit = preferencesLimitations['min-age'];
                const maxAgeLimit = preferencesLimitations['max-age'];
                if (Number(preferences.minage) <= minAgeLimit || Number(preferences.maxage) >= maxAgeLimit) {
                    let updatedMinAge;
                    switch (user.gender) {
                        case 'fem':
                            updatedMinAge = user.birthday.age - 5;
                            break;
                        case 'mal':
                            updatedMinAge = user.birthday.age - 10;
                            break;
                    }
                    newPreferences.minage = Math.max(updatedMinAge, minAgeLimit);
                    if (newPreferences.minage > newPreferences.maxage) {
                        newPreferences.maxage = maxAgeLimit;
                    }
                }
                return newPreferences;
            })
        );
    }
}
