import React from 'react';
import PropTypes from 'prop-types';
import Rate from 'react-native-rate';
import ConfigBuilder from 'dating-mobile/src/app/config-builder';
import { render } from 'dating-mobile/src/components/rx-render';
import {
  UserReviewCollecting,
  REVIEW_STATUS,
} from '@sdv/domain/rate-application/user-review-collecting';

export default function createController(Component) {
  const Controller = render(({ userId }, { useObservable, useDistinct }) => {
    if (!userId) {
      return null;
    }

    const userReviewCollecting = UserReviewCollecting.shared(userId);

    const showModal = useObservable(
      it => it.shouldShowReviewRequest,
      userReviewCollecting,
    );

    const onRateButtonPress = useDistinct(() => () => {
      const options = {
        AppleAppID: ConfigBuilder.config.appId,
        GooglePackageName: ConfigBuilder.config.appId,
        preferInApp: true,
        openAppStoreIfInAppFails: false,
      };

      Rate.rate(options);
      userReviewCollecting.userReviewCollected(REVIEW_STATUS.REVIEW_SENT);
    });

    const onCloseButtonPress = useDistinct(() => () => {
      userReviewCollecting.userReviewCollected(REVIEW_STATUS.DECLINED);
    });

    const sendFeedback = useDistinct(() => feedback => {
      userReviewCollecting.sendFeedback(feedback);
      userReviewCollecting.userReviewCollected(REVIEW_STATUS.FEEDBACK_SENT);
    });

    return (
      <Component
        isVisible={showModal}
        onRateButtonPress={onRateButtonPress}
        onCloseButtonPress={onCloseButtonPress}
        onSendFeedback={sendFeedback}
      />
    );
  });

  Controller.displayName = 'rate-application.modal.controller';

  Controller.propTypes = {
    userId: PropTypes.string,
  };

  return Controller;
}
