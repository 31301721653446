import React from 'react';
import PropTypes from 'prop-types';
import { UserBlocking } from '@sdv/domain/user/user-blocking';
import withUserController from 'dating-mobile/src/models/user/controllers';

export default function createController(Component) {
  class Controller extends React.Component {
    static displayName = 'feed.views.list.controller';

    static propTypes = {
      userId: PropTypes.string.isRequired,
      users: PropTypes.array.isRequired,
    };

    state = {};

    componentDidMount() {
      this.subscribe();
    }

    componentDidUpdate(prevProps) {
      const { userId } = this.props;

      if (userId !== prevProps.userId) {
        this.unsubscribe();
        this.subscribe();
      }
    }

    componentWillUnmount() {
      this.unsubscribe();
    }

    filteredUsers = () => {
      const { users } = this.props;
      const { blockedUsers } = this.state;

      if (!users || !blockedUsers) {
        return users;
      }

      return users.filter(
        user => blockedUsers.findIndex(blockedId => user.id === blockedId) < 0,
      );
    };

    scrollToOffset = (...args) => {
      if (this.listRef) {
        this.listRef.scrollToOffset(...args);
      }
    };

    unsubscribe() {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    }

    subscribe() {
      const { userId } = this.props;

      if (!userId) {
        return;
      }

      const userBlocking = UserBlocking.shared(userId);

      this.subscription = userBlocking.blockedUsersIds.subscribe(
        blockedUsers => {
          this.setState({
            blockedUsers,
          });
        },
      );
    }

    render() {
      const filteredUsers = this.filteredUsers();

      return (
        <Component
          {...this.props}
          ref={ref => {
            this.listRef = ref;
          }}
          users={filteredUsers}
        />
      );
    }
  }

  return withUserController(Controller, { userIdPropName: 'userId' });
}
