import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Keyboard, View } from 'react-native';
import OpenAppSettings from 'react-native-app-settings';
import RoundButton from '../../../components/buttons/round';
import Resources from '../../../resources';
import testId from '../../../utils/test-id';
import styles from './styles';

export default class CallButton extends React.PureComponent {
  static displayName = 'call.views.video-call-button';

  static propTypes = {
    silent: PropTypes.bool,
    hideWhenDisabled: PropTypes.bool,
    attendeeName: PropTypes.string,
    disabled: PropTypes.bool,
    shouldShowPermissionsAlert: PropTypes.bool,
    shouldShowOneWayCallAlert: PropTypes.bool,
    dial: PropTypes.func,
    style: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
  };

  onPressed = () => {
    const {
      silent,
      shouldShowOneWayCallAlert,
      shouldShowPermissionsAlert,
      dial,
    } = this.props;

    Keyboard.dismiss();

    if (!silent && shouldShowOneWayCallAlert) {
      this.showOneWayCallAlert(() => {
        if (shouldShowPermissionsAlert) {
          this.showPermissionsAlert(() => OpenAppSettings.open());
        } else if (dial) {
          dial();
        }
      });
    } else if (dial) {
      dial();
    }
  };

  showOneWayCallAlert = (confirmCallback, rejectCallback) => {
    const { attendeeName } = this.props;

    Alert.alert(
      Resources.strings.formatString(
        Resources.strings['call-one-way-camera-start-alert-message'],
        attendeeName,
      ),
      null,
      [
        {
          text:
            Resources.strings[
              'call-one-way-camera-start-alert-share-action-title'
            ],
          onPress: confirmCallback,
        },
        {
          text: Resources.strings.cancel,
          onPress: rejectCallback,
          style: 'cancel',
        },
      ],
      { cancelable: false },
    );
  };

  showPermissionsAlert = (confirmCallback, rejectCallback) => {
    Alert.alert(
      Resources.strings['call-without-permissions-alert-message'],
      null,
      [
        {
          text:
            Resources.strings[
              'call-without-permissions-alert-open-settings-action-title'
            ],
          onPress: confirmCallback,
        },
        {
          text: Resources.strings.cancel,
          onPress: rejectCallback,
          style: 'cancel',
        },
      ],
      { cancelable: false },
    );
  };

  render() {
    const { disabled, hideWhenDisabled, style } = this.props;

    if (disabled && hideWhenDisabled) {
      return null;
    }

    return (
      <View style={disabled ? styles.disabled : undefined}>
        <RoundButton
          {...this.props}
          onPress={this.onPressed}
          icon={Resources.images.videoCameraIcon()}
          style={[styles.button, style]}
          {...testId('Start video call button')}
        />
      </View>
    );
  }
}
