function createDialogsWinkActions(id) {

    class Actions {

        wink = (user, winkType) => (dispatch, flux) => {
        	dispatch({
                addedUser: user,
                winkType: winkType
            });

        	flux.api.dialogs.winks.post(user, id, winkType, (error) => {

                    if (error) {

                        dispatch({
                    		removedUser: user,
                    		winkType: winkType
                		});
                    }
                    
          	});
        }

    }

    Actions.displayName = createDialogsWinkActions.getDisplayName(id);

    return Actions;
}

createDialogsWinkActions.getDisplayName = function (id) {
    return `dialogs-wink.${id}`;
};

export default createDialogsWinkActions;

export const WINK_TYPES = Object.freeze({
    LIKE_USER: 'users.like',
    SEND_INVITE: 'dialogs.message.invite'
});
