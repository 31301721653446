import React from 'react';
import Modal from 'react-native-modal';
import { TERMS } from 'dating-mobile/src/routing/router/constants';
import Navigator from 'dating-mobile/src/routing/navigator';

import styles from 'dating-mobile/src/payment/screens/card/styles';

function withNavigation(Component) {
  return class Navigation extends React.Component {
    static displayName = 'payment.screens.card.navigation';

    state = { data: {} };

    show = data => {
      this.setState({
        data,
      });
    };

    close = () => {
      const {
        data: { onClose },
      } = this.state;

      this.setState({ data: {} });
      if (onClose) {
        onClose();
      }
    };

    openLink = (uri, title) => {
      Navigator.navigate(TERMS, {
        uri,
        title,
      });
      this.close();
    };

    render() {
      const {
        data: { onClose, ...props },
      } = this.state;

      return (
        <Modal
          style={styles.modal}
          onBackdropPress={this.close}
          isVisible={!!onClose}
        >
          {onClose && (
            <Component
              {...props}
              openLink={this.openLink}
              close={this.close}
              backButtonPressed={this.backButtonPressed}
            />
          )}
        </Modal>
      );
    }
  };
}

export default withNavigation;
