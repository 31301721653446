import React from 'react';
import PropTypes from 'prop-types';
import { Text, TouchableWithoutFeedback, View } from 'react-native';
import Photo from '../../../components/photo';
import Resources from '../../../resources';
import styles from './styles';

import user from '../../../models/user/controllers';

class UnansweredMessageContent extends React.Component {
    static displayName = 'notifications.popup.unanswered-message';
    static propTypes = {
        user: PropTypes.object,
        notification: PropTypes.object,
        openChatAndDismiss: PropTypes.func.isRequired,
        onReadyForDisplay: PropTypes.func.isRequired
    };

    ready = false;

    componentDidMount() {
        if (this.props.user && this.props.user.name) {
            this.ready = true;
            this.props.onReadyForDisplay();
        }
    }

    componentDidUpdate() {
        if (!this.ready && this.props.user && this.props.user.name) {
            this.ready = true;
            this.props.onReadyForDisplay();
        }
    }

    openChat = () => {
        if (!this.props.id) {
            return;
        }

        this.props.openChatAndDismiss(this.props.id);
    };

    render() {
        const userName = this.props.user && this.props.user.name;
        const thumbnail = this.props.user && this.props.user.thumbnail;
        const text = userName
            ? Resources.strings.formatString(
                Resources.strings['unanswered-message-notification-text-format'],
                userName
            )
            : '';

        return (
            <TouchableWithoutFeedback onPress={this.openChat}>
                <View style={styles.container}>
                    <View style={styles.photoContainer}>
                        <Photo
                            style={styles.photo}
                            userId={this.props.id}
                            basename={thumbnail}
                            placeholder={'avatar'}
                        />
                    </View>
                    <View style={styles.textContainer}>
                        <Text style={styles.text}>{text}</Text>
                    </View>
                </View>
            </TouchableWithoutFeedback>
        );

    }
}

const Content = user(UnansweredMessageContent);

export default (props) => {
    const userId = (props.notification && props.notification.attendee) || undefined;
    return (<Content id={userId} {...props} />);
};
