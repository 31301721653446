import React from 'react';
import PropTypes from 'prop-types';
import connect from '@sdv/connect';
import qs from 'qs';
import withUser from '../../../models/user/controllers';
import withUserPresence from '../../../models/user.presence/controllers';
import withHasTag from '../../../models/user.tags/controllers/has-tag';
import withPermissions from '../../../components/permissions';
import CallingModel from '../../../models/call.calling';

export default function createController(Component) {
  const displayName = 'call.views.video-call-button.controller';

  const USER_PROPERTY = `generated.${displayName}.user`;
  const PRESENCE_PROPERTY = `generated.${displayName}.presence`;
  const USER_IS_PROMOTER_PROPERTY = `generated.${displayName}.promoter`;
  const CAMERA_STATUS_PROPERTY = `generated.${displayName}.camera`;

  class Controller extends React.Component {
    static displayName = displayName;

    static propTypes = {
      disableWhenAttendeeWithoutCamera: PropTypes.bool,
      dial: PropTypes.func,
    };

    dial = () => {
      const { dial, [PRESENCE_PROPERTY]: attendeePresence } = this.props;
      const attendeeHasCamera = (
        (attendeePresence && attendeePresence.devices) ||
        []
      ).find(item => item.name === 'cam');

      dial({
        audioOnly: false,
        requestRemoteVideo: attendeeHasCamera,
      });
    };

    render() {
      const {
        disableWhenAttendeeWithoutCamera,
        [USER_PROPERTY]: attendee,
        [PRESENCE_PROPERTY]: attendeePresence,
        [USER_IS_PROMOTER_PROPERTY]: userIsPromoter,
        [CAMERA_STATUS_PROPERTY]: cameraStatus,
        ...props
      } = this.props;

      const attendeeOnline = attendeePresence && attendeePresence.online;
      const attendeeHasCamera = (
        (attendeePresence && attendeePresence.devices) ||
        []
      ).find(item => item.name === 'cam');

      props.attendeeName = attendee && attendee.name;
      props.dial = this.dial;
      props.shouldShowOneWayCallAlert = !attendeeHasCamera;
      props.shouldShowPermissionsAlert =
        !attendeeHasCamera &&
        (cameraStatus === 'denied' || cameraStatus === 'restricted');
      props.disabled =
        userIsPromoter || !disableWhenAttendeeWithoutCamera
          ? !attendeeOnline
          : !attendeeOnline || !attendeeHasCamera;

      return <Component {...props} />;
    }
  }

  function getModels(flux, props) {
    const models = {};

    if (props.userId && props.attendeeId) {
      const modelId = qs.stringify({
        user: props.userId,
        attendee: props.attendeeId,
      });

      models.callingModel = flux.get(CallingModel, modelId);
    }

    return models;
  }

  function mapStoresToProps() {
    return {};
  }

  function mapActionsToProps(models) {
    const props = {};

    if (models.callingModel) {
      props.dial = models.callingModel.actions.dial;
    }

    return props;
  }

  return withPermissions(
    withHasTag(
      withUserPresence(
        withUser(
          connect(getModels, mapStoresToProps, mapActionsToProps)(Controller),
          {
            userIdPropName: 'attendeeId',
            resultPropName: USER_PROPERTY,
          },
        ),
        {
          userIdPropName: 'attendeeId',
          resultPropName: PRESENCE_PROPERTY,
        },
      ),
      {
        tag: 'dialogs.messages.promoter',
        hasTagPropName: USER_IS_PROMOTER_PROPERTY,
      },
    ),
    {
      resultProps: {
        [CAMERA_STATUS_PROPERTY]: 'camera',
      },
      returnStatus: true,
    },
  );
}
