import flux from '@sdv/domain/app/flux';
import { singleton } from '@sdv/commons/utils/singleton';
import '@sdv/commons/rx/store';
import { map, filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import UserVowels from './vowels';
import UserTags from './personal';
import UserFeatures from './features';
import { UserTags as USER_TAGS } from './user-tags';

export default class Tags {
  static shared = singleton(() => new Tags());

  tagsOf(user) {
    const userTags = flux.get(UserTags, user);

    userTags.actions.get();

    return userTags.store.rxState().pipe(
      map(state => state.tags),
      filter(tags => Array.isArray(tags)),
    );
  }

  isPromoter(user) {
    return this.tagsOf(user).pipe(
      map(tags => tags.indexOf(USER_TAGS.IsPromoter) >= 0),
    );
  }

  vowelsOf(user) {
    const userVowels = flux.get(UserVowels, user);

    userVowels.actions.get();

    return userVowels.store.rxState().pipe(
      map(state => state.vowels),
      filter(vowels => Array.isArray(vowels)),
    );
  }

  featuresOf(user) {
    const userFeatures = flux.get(UserFeatures, user);

    userFeatures.actions.get();

    return userFeatures.store.rxState().pipe(
      map(state => state.features),
      filter(features => Array.isArray(features)),
    );
  }

  setTagEnabledForUser(tag, enabled, user) {
    return new Observable(observer => {
      const userTags = flux.get(UserTags, user);
      const action = enabled ? userTags.actions.put : userTags.actions.delete;

      action(tag, error => {
        if (error) {
          observer.error(error);
        } else {
          observer.next(undefined);
        }
        observer.complete();
      });
    });
  }
}

export { Tags };
