import React from 'react';
import { View, Platform } from 'react-native';
import PropTypes from 'prop-types';
import Swiper from 'react-native-swiper';
import styles from './styles';
import Stream from 'dating-mobile/src/dialogs/stream/screens';
import StreamPlaceholder from '../../views/streams-placeholder';
import { StreamTutorialOverlay } from 'dating-mobile/src/dialogs/stream/views/stream-tutorial-overlay';

export default class StreamsPagerView extends React.PureComponent {
    static displayName = 'streaming.screens.streams-pager.view';
    static propTypes = {
        id: PropTypes.string,
        next: PropTypes.string,
        prev: PropTypes.string,
        goBack: PropTypes.func,
        switchStream: PropTypes.func,
        showDiamonds: PropTypes.func,
        showProfile: PropTypes.func,
        showCurrentlyBroadcasting: PropTypes.func,
        userId: PropTypes.string.isRequired,
        swipeEnabled: PropTypes.bool,
    };

    screenChanged = index => {
        if (index === 0) {
            this.props.switchStream && this.props.prev && this.props.switchStream(this.props.prev);
            this.pager.scrollBy(0, false);
        }
        if (index === 2) {
            this.props.switchStream && this.props.next && this.props.switchStream(this.props.next);
            this.pager.scrollBy(0, false);
        }
    };

    showNextStream = () => {
        this.props.switchStream && this.props.next && this.props.switchStream(this.props.next);
    };

    render() {
        const horizontal = Platform.OS === 'android';

        return (
            <StreamTutorialOverlay userId={this.props.userId} currentStreamId={this.props.id}>
                <Swiper
                    horizontal={horizontal}
                    index={1}
                    showsButtons={false}
                    onIndexChanged={this.screenChanged}
                    showsPagination={false}
                    ref={ref => (this.pager = ref)}
                    loop={false}
                    keyboardShouldPersistTaps={'handled'}
                    scrollEnabled={this.props.swipeEnabled}
                >
                    <View style={styles.slide}>
                        <StreamPlaceholder userId={this.props.prev} />
                    </View>
                    <View style={styles.slide}>
                        <Stream
                            id={this.props.id}
                            goBack={this.props.goBack}
                            showDiamonds={this.props.showDiamonds}
                            showProfile={this.props.showProfile}
                            showCurrentlyBroadcasting={this.props.showCurrentlyBroadcasting}
                            showNextStream={this.showNextStream}
                        />
                    </View>
                    <View style={styles.slide}>
                        <StreamPlaceholder userId={this.props.next} />
                    </View>
                </Swiper>
            </StreamTutorialOverlay>
        );
    }
}
