import React, {Component} from 'react';
import {BackHandler, FlatList, View} from 'react-native';
import Button from '../../../components/buttons/base';
import styles from './styles';
import Item from '../views/item';
import userController from '../../../models/user/controllers';
import Resources from '../../../resources';
import testId from '../../../utils/test-id';

const ListItem = userController(Item);

export default class LiveStreamFollowTopScreen extends Component {
    static displayName = 'broadcasts.follow-top.screens.view';
    constructor(props) {
        super(props);
    }

    renderItem = ({item}) => {
        const selected = this.props.selectedItems.indexOf(item) !== -1;
        return (
            <View style={styles.itemContainer}>
                <ListItem onPress={this.props.itemPressed} selected={selected} key={item} id={item}/>
            </View>
        )
    };

    componentDidMount() {
        this.backButtonHandler = BackHandler.addEventListener('hardwareBackPress', () => { return true });
    }

    componentWillUnmount() {
        this.backButtonHandler.remove();
    }

    itemSeparatorComponent = () => {
        return (<View style={styles.itemSeparator} />);
    };

    listFooterComponent = () => {
        if (!this.props.streams || !this.props.streams.length) {
            return null;
        }

        return (
            <View style={styles.footer}>
                <Button
                    title={Resources.strings['follow-top-broadcasters-screen-done-button-title']}
                    onPress={this.props.onComplete}
                    {...testId('Done button')}
                />
            </View>
        );
    };

    render() {
        const items = this.props.streams.slice(0, 9);
        return (
            <View style={styles.container}>
                <FlatList
                    contentContainerStyle={styles.contentContainer}
                    style={styles.list}
                    data={items}
                    extraData={this.state}
                    renderItem={this.renderItem}
                    numColumns={3}
                    keyExtractor={item => item}
                    ItemSeparatorComponent={this.itemSeparatorComponent}
                    alwaysBounceVertical={false}
                    alwaysBounceHorizontal={false}
                />
                {this.listFooterComponent()}
            </View>
        )
    }
}
