import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  View,
  Image,
  Text,
  TouchableOpacity,
  TextInput,
  ScrollView,
} from 'react-native';
import Modal from 'react-native-modal';
import Resources from 'dating-mobile/src/resources';
import { GradientButton } from 'dating-mobile/src/components/buttons/gradient';

import starsImg from './images/stars.png';

import styles from './styles';

export default class ApplicationReviewView extends PureComponent {
  static displayName = 'rate-application.modal.view';

  static propTypes = {
    isVisible: PropTypes.bool,
    onRateButtonPress: PropTypes.func,
    onCloseButtonPress: PropTypes.func,
    onSendFeedback: PropTypes.func,
  };

  state = {
    feedBackText: '',
    showFeedbackScreen: false,
  };

  updateFeedback = feedBackText => {
    this.setState({ feedBackText });
  };

  sendFeedback = () => {
    const { onSendFeedback } = this.props;
    const { feedBackText } = this.state;

    if (onSendFeedback) {
      onSendFeedback(feedBackText.trim());
    }
  };

  onLaterButtonPress = () => {
    this.setState({ showFeedbackScreen: true });
  };

  render() {
    const { isVisible, onRateButtonPress, onCloseButtonPress } = this.props;
    const { feedBackText, showFeedbackScreen } = this.state;

    return (
      <Modal
        backdropOpacity={0.4}
        isVisible={isVisible}
        avoidKeyboard
        useNativeDriver
      >
        <View style={styles.container}>
          <TouchableOpacity
            onPress={onCloseButtonPress}
            style={styles.closeIconContainer}
          >
            <Image
              style={styles.closeIcon}
              source={Resources.images.closeIcon()}
            />
          </TouchableOpacity>
          {showFeedbackScreen ? (
            <ScrollView
              contentContainerStyle={styles.wrapper}
              keyboardShouldPersistTaps="handled"
              alwaysBounceVertical={false}
            >
              <Text style={styles.feedbackTitle}>
                {Resources.strings['rate-app-modal-feedback-title']}
              </Text>
              <TextInput
                multiline
                onChangeText={this.updateFeedback}
                style={styles.feedbackInput}
                placeholder={
                  Resources.strings['rate-app-modal-feedback-input-placeholder']
                }
                placeholderTextColor={styles.$placeholderColor}
                value={feedBackText}
              />
              <GradientButton
                disabled={!feedBackText.trim()}
                onPress={this.sendFeedback}
                buttonStyles={styles.button}
                buttonTextStyles={styles.buttonText}
                text={Resources.strings['rate-app-modal-send-button']}
              />
            </ScrollView>
          ) : (
            <ScrollView
              contentContainerStyle={styles.wrapper}
              keyboardShouldPersistTaps="handled"
              alwaysBounceVertical={false}
            >
              <Image
                style={styles.starsIcon}
                source={starsImg}
                resizeMode="cover"
              />
              <Text style={styles.title}>
                {Resources.strings['rate-app-modal-title']}
              </Text>
              <GradientButton
                onPress={onRateButtonPress}
                containerStyles={styles.buttonContainer}
                buttonStyles={styles.button}
                buttonTextStyles={styles.buttonText}
                text={Resources.strings['rate-app-modal-rate-button']}
              />
              <TouchableOpacity onPress={this.onLaterButtonPress}>
                <Text style={styles.askMeLaterText}>
                  {Resources.strings['rate-app-modal-later-button']}
                </Text>
              </TouchableOpacity>
            </ScrollView>
          )}
        </View>
      </Modal>
    );
  }
}
