import Resources from 'dating-mobile/src/resources';

export const displayTextPerPeriod = (price, number, unit, format) => {
  const unitToDisplay =
    number === '7' && unit === 'DAY'
      ? subscriptionUnitDisplayText('WEEK')
      : subscriptionUnitDisplayText(unit);

  return Resources.strings.formatString(format, price, unitToDisplay);
};

export const getPeriodText = (period, unit) => {
  const number = period ? period.toString() : '';

  let unitToDisplay;
  let numberToDisplay;

  if (number === '7' && unit === 'DAY') {
    unitToDisplay = subscriptionUnitDisplayText('WEEK');
    numberToDisplay = '1';
  } else {
    unitToDisplay = subscriptionUnitDisplayText(
      unit,
      number && parseInt(number, 10) > 1,
    );
    numberToDisplay = number;
  }

  if (numberToDisplay === '1' || numberToDisplay === '') {
    numberToDisplay = '';
  } else {
    numberToDisplay += ' ';
  }

  return {
    numberToDisplay,
    unitToDisplay,
  };
};

export const displaySubscriptionItemText = (period, unit) => {
  const { numberToDisplay, unitToDisplay } = getPeriodText(period, unit);

  return Resources.strings.formatString(
    Resources.strings['subscription-plan-card-price-per-time-format'],
    numberToDisplay,
    unitToDisplay,
  );
};

// TODO: Use more general version
export function formatPeriod(subscriptionPeriod, subscriptionPeriodUnit) {
  if (subscriptionPeriod === '7' && subscriptionPeriodUnit === 'DAY') {
    return Resources.strings['period-week'];
  }

  switch (subscriptionPeriodUnit) {
    case 'WEEK':
      return Resources.strings['period-week'];
    case 'MONTH':
      return Resources.strings['period-month'];
    default:
      return '';
  }
}

const subscriptionUnitDisplayText = (unitDescription, isPlural = false) => {
  switch (unitDescription) {
    case 'MONTH':
      return isPlural ? Resources.strings.months : Resources.strings.month;
    case 'YEAR':
      return isPlural ? Resources.strings.years : Resources.strings.year;
    case 'WEEK':
      return isPlural ? Resources.strings.weeks : Resources.strings.week;
    case 'DAY':
      return isPlural ? Resources.strings.days : Resources.strings.day;
    default:
      return unitDescription;
  }
};

export function getLengthOfSubscription(period, unit) {
  return Resources.strings.formatString(
    Resources.strings['subscription-free-length-format'],
    period,
    subscriptionUnitDisplayText(unit, period && parseInt(period, 10) > 1),
  );
}

export function getLengthOfTrial(trialPeriod) {
  const [[period, length] = []] = Object.entries(trialPeriod || {});

  if (period && length) {
    return Resources.strings.formatString(
      Resources.strings['subscription-free-length-format'],
      length,
      period,
    );
  }

  return null;
}

export function getTrialDisplayDate(trialPeriod) {
  const [[period, length] = []] = Object.entries(trialPeriod || {});

  if (period && length) {
    return Resources.strings.formatString(
      Resources.strings['subscription-description-trial-length'],
      length,
      period,
    );
  }

  return null;
}
