import PropTypes from 'prop-types';
import { AppState } from 'react-native';
import Navigator from 'dating-mobile/src/routing/navigator';
import { CHAT } from 'dating-mobile/src/routing/router/constants';
import IdentityModel from '@sdv/domain/identity/model';
import DialogsMessagesModel, {
  getId as getMessagesModelId,
} from '@sdv/domain/dialogs.messages';
import InvitationModel from '@sdv/domain/dialogs.automation.productions.invitation';
import BalanceModel from '@sdv/domain/credits.account.balance/model';
import UserTagsModel from '@sdv/domain/user/tags/personal';
import UserEventsModel, {
  EVENT_TYPE,
  getId as getEventsModelId,
} from '../../../models/user.events/model';
import Service from '../../service';
import UserTaggedModel, {
  TAGS,
  getId as getUserTaggedModelId,
} from '../../../models/users.targeted.tagged/model';

const MAX_INACTIVE_TIME = 5 * 60 * 1000;

export default class EventsReloader extends Service {
  static displayName = 'service.events-reloader';

  static contextTypes = {
    flux: PropTypes.object,
  };

  appState = AppState.currentState;

  appEnteredBackground = null;

  componentDidMount() {
    AppState.addEventListener('change', this.appStateChanged);
  }

  componentWillUnmount() {
    AppState.removeEventListener('change', this.appStateChanged);
  }

  appStateChanged = appState => {
    if (this.appState !== 'background' && appState === 'background') {
      this.appEnteredBackground = new Date().getTime();
    }
    if (appState === 'active' && this.appEnteredBackground) {
      const inactiveTime = new Date().getTime() - this.appEnteredBackground;

      this.appEnteredBackground = null;
      if (inactiveTime >= MAX_INACTIVE_TIME) {
        const identity = this.context.flux.get(IdentityModel).store.getState()
          .id;

        if (identity) {
          const currentOpenedChat = Navigator.routeInStackWithName(CHAT);

          if (
            currentOpenedChat &&
            currentOpenedChat.params &&
            currentOpenedChat.params.id
          ) {
            const messagesModel = this.context.flux.get(
              DialogsMessagesModel,
              getMessagesModelId(identity, currentOpenedChat.params.id),
            );

            messagesModel.actions.get();
          }

          const invitationModel = this.context.flux.get(
            InvitationModel,
            identity,
          );

          invitationModel.actions.get();

          Object.values(EVENT_TYPE).forEach(eventType => {
            const eventsModel = this.context.flux.get(
              UserEventsModel,
              getEventsModelId(identity, eventType),
            );

            eventsModel.actions.get();
          });

          const balanceModel = this.context.flux.get(BalanceModel, identity);

          balanceModel.actions.get();

          const userTagsModel = this.context.flux.get(UserTagsModel, identity);

          userTagsModel.actions.get();

          Object.values(TAGS).forEach(tag => {
            const userTaggedModel = this.context.flux.get(
              UserTaggedModel,
              getUserTaggedModelId(identity, tag),
            );

            if (userTaggedModel.store.isFilled()) {
              userTaggedModel.actions.get();
            }
          });
        }
      }
    }
    this.appState = appState;
  };
}
