function createNotificationsActions(id) {
    class Actions {
        notify = (payload, priority) => dispatch => {
            dispatch({
                priority,
                payload,
            });
        };

        dismiss = () => dispatch => {
            dispatch({});
        };
    }

    Actions.displayName = createNotificationsActions.getDisplayName(id);

    return Actions;
}

createNotificationsActions.getDisplayName = id => {
    return `notifications.${id}`;
};

export default createNotificationsActions;
