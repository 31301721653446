import React from 'react';
import tags from './tags';

const TAGS_PROPERTY = 'generated.user-tags.has-tag.tags';

export default function createController(Component, options = {}) {
    const tagName = options.tag;
    const tagPropName = options.tagPropName;
    const userIdPropName = options.userIdPropName || 'userId';
    const hasTagPropName = options.hasTagPropName || 'hasTag';

    class HasTag extends React.Component {
        static displayName = 'user-tags.has-tag';

        resolveValue = () => {
            if (!this.props[TAGS_PROPERTY]) {
                return undefined;
            }

            const tag = tagName || (tagPropName && this.props[tagPropName]);
            if (!tag) {
                return undefined;
            }

            return this.props[TAGS_PROPERTY].indexOf(tag) >= 0;
        };

        render() {
            const { [TAGS_PROPERTY]: tags, ...props } = this.props;

            return (
                <Component
                    {...props}
                    { ... { [hasTagPropName]: this.resolveValue() } }
                />
            );
        }

    }

    return tags(
        HasTag,
        {
            userIdPropName: userIdPropName,
            tagsPropName: TAGS_PROPERTY
        }
    );

}
