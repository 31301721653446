import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: 25,
    },
    image: {
        width: 36,
        height: 36,
        marginRight: 16,
    },
    label: {
        textAlign: 'left',
        fontFamily: '$brandFontFamily',
        fontSize: 17,
    },
    coinsBackground: {
        gradientColor: ['#FF9A2A', '#FF2458'],
    },
    messagesBackground: {
        gradientColor: ['#FC00FF', '#00DBDE'],
    },
    filtersBackground: {
        gradientColor: ['#168EDF', '#68E629'],
    },
    adsBackground: {
        gradientColor: ['#3237A8', '#00DBDE'],
    },
    peopleBackground: {
        gradientColor: ['#E852E3', '#ECDCE6'],
    },
});

export default styles;
