import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        flexDirection: 'column',
        alignItems: 'center'
    },

    nameContainer: {
        backgroundColor: '$bannerColor',
        height: 28,
        borderRadius: 14,
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 25
    },

    nameFormat: {
        fontFamily: '$brandFontFamily',
        fontSize: 14,
        color: 'white',
        textAlign: 'center'
    },

    name: {
        fontFamily: '$brandFontFamily',
        fontSize: 14,
        fontWeight: 'bold',
        color: 'white',
        textAlign: 'center'
    },

    info: {
        fontFamily: '$brandFontFamily',
        fontSize: 14,
        color: '$disabledColor',
        textAlign: 'center',
        marginTop: 8
    }
});

export default styles;
