import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $showBadConnectionNotification: false,
    '@flavor tubit': {
        $showBadConnectionNotification: true,
    },
    container: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'stretch',
    },

    contentContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        flexDirection: 'column',
        alignItems: 'stretch',
        backgroundColor: '$nearTransparent',
    },
    iOSStreamStyle: {
        flex: 1,
    },
    androidStreamStyle: {
        top: 0,
        width: '100%',
    },
    topPanel: {
        flexDirection: 'column',
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 0,
        paddingTop: 10,
    },

    topPanelTop: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },

    topPanelBottom: {
        flex: 1,
        flexDirection: 'row',
        marginTop: 5,
        justifyContent: 'space-between',
    },

    topPanelTopLeft: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },

    topPanelTopRight: {
        marginLeft: 10,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
    },

    statsSpace: {
        marginRight: 7,
    },

    muteButton: {
        marginLeft: 4,
        marginRight: 4,
    },

    reportButton: {
        width: 32,
        height: 32,
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 4,
    },

    rotateCameraButton: {
        marginRight: 4,
        marginLeft: 4,
    },

    closeButton: {
        width: 32,
        height: 32,
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 4,
    },

    broadcastTogetherButton: {
        width: 32,
        height: 32,
        alignItems: 'center',
        justifyContent: 'center',
    },
    chat: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'flex-end',
    },

    logContainer: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        flexGrow: 1,
    },
    chatSpacing: {
        height: 0,
        width: '100%',
        marginTop: 10,
    },

    log: {
        flex: 1,
        maxHeight: 250,
    },

    chatContainer: {
        flex: 1,
    },

    form: {},

    donation: {
        marginBottom: 10,
        marginLeft: 10,
        height: 180,
        alignItems: 'center',
        justifyContent: 'center',
    },

    startTimerContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '$shadowColor',
    },

    startTimer: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: 72,
        fontFamily: '$brandFontFamily',
    },

    guestStreamContainer: {
        width: 100,
        height: 150,
        backgroundColor: 'transparent',
        marginBottom: 10,
        alignItems: 'center',
        justifyContent: 'center',
    },
    endButtonContainer: {
        backgroundColor: '$inputBackgroundColor',
        borderRadius: 20,
        height: 40,
        width: '80%',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        alignSelf: 'center',
    },
    outgoingTextContainer: {
        backgroundColor: '$inputBackgroundColor',
        borderRadius: 20,
        height: 40,
        width: '80%',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        alignSelf: 'center',
    },
    guestStreamOverlayText: {
        color: 'white',
        fontFamily: '$brandFontFamily',
        fontSize: 12,
        textAlign: 'center',
    },

    secondTopPanel: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    guestStreamingView: {
        width: '100%',
        height: '100%',
    },
    guestStreamControlButtonContainer: {
        width: '80%',
        height: 30,
        position: 'absolute',
        bottom: 20,
        alignSelf: 'center',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    streamControlButtonContainer: {
        width: 30,
        height: 30,
        borderRadius: 15,
        backgroundColor: '$inputBackgroundColor',
        alignItems: 'center',
        justifyContent: 'center',
    },

    profileContainer: {
        justifyContent: 'flex-end',
        position: 'absolute',
        bottom: 0,
        width: '100%',
    },

    privateChatContainer: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        maxHeight: '70%',
        height: '70%',
    },
    privateChatContainerExpanded: {
        maxHeight: '100%',
        height: '90%',
    },
    cheersButton: {
        alignSelf: 'flex-end',
        marginRight: -25,
        marginBottom: 15,
        '@flavor tubit': {
            marginBottom: 0,
        },
    },
    streamerMessagesBtnContainer: {
        padding: 10,
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
});

export default styles;
