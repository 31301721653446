export default function createActions(id) {

    class Actions {

        upload = (media) => (dispatch) => {
            dispatch(media);
        };

        cancelUploading = (media) => (dispatch) => {
            dispatch(media);
        };

    }

    Actions.displayName = createActions.getDisplayName(id);

    return Actions;
}

createActions.getDisplayName = function (id) {
    return `dialogs-media-uploader.${id}`;
};
