const PERMISSION_STATUSES = {
  authorized: 'authorized',
  denied: 'denied',
  restricted: 'restricted',
  undetermined: 'undetermined',
};

const PERMISSION_TYPES = {
  location: 'location',
  camera: 'camera',
  microphone: 'microphone',
};

const currentPermissions = {
  [PERMISSION_TYPES.location]: PERMISSION_STATUSES.undetermined,
  [PERMISSION_TYPES.camera]: PERMISSION_STATUSES.undetermined,
  [PERMISSION_TYPES.microphone]: PERMISSION_STATUSES.undetermined,
};

function checkPermission(type) {
  return new Promise((res, rej) => {
    if (PERMISSION_TYPES[type]) {
      res(currentPermissions[type]);
    } else {
      rej('Unknown permission');
    }
  });
}

function handleKnownPermissions(type) {
  switch (type) {
    case PERMISSION_TYPES.location: {
      return handleGeolocation();
    }
    case PERMISSION_TYPES.microphone:
    case PERMISSION_TYPES.camera: {
      return handleMedia(type);
    }

    default:
      return Promise.reject('Unknown permission');
  }
}

function handleMedia(type) {
  const userMediaType =
    type === PERMISSION_TYPES.microphone ? { audio: true } : { video: true };

  if (navigator.mediaDevices) {
    return navigator.mediaDevices
      .getUserMedia(userMediaType)
      .then(() => {
        currentPermissions[type] = PERMISSION_STATUSES.authorized;

        return PERMISSION_STATUSES.authorized;
      })
      .catch(() => {
        currentPermissions[type] = PERMISSION_STATUSES.restricted;

        return PERMISSION_STATUSES.restricted;
      });
  }

  currentPermissions[type] = PERMISSION_STATUSES.restricted;

  return Promise.resolve(PERMISSION_STATUSES.restricted);
}

function handleGeolocation() {
  return new Promise((res, rej) => {
    navigator.geolocation.getCurrentPosition(
      () => {
        currentPermissions[PERMISSION_TYPES.location] =
          PERMISSION_STATUSES.authorized;
        res(PERMISSION_STATUSES.authorized);
      },
      () => {
        currentPermissions[PERMISSION_TYPES.location] =
          PERMISSION_STATUSES.restricted;
        rej(PERMISSION_STATUSES.restricted);
      },
    );
  });
}

export default {
  check: checkPermission,
  request: handleKnownPermissions,
  checkMultiple: types => Promise.all(types.map(checkPermission)),
};
