import React from 'react';
import PropTypes from 'prop-types';
import { ScrollView } from 'react-native';
import styles from './styles';
import Item, { getWidth as getItemWidth } from './item';

export default class Smiles extends React.PureComponent {
    static displayName = 'dialogs.common.views.smiles';
    static propTypes = {
        smiles: PropTypes.arrayOf(PropTypes.shape({
            basename: PropTypes.string.isRequired,
            pattern: PropTypes.string.isRequired,
            isAnimated: PropTypes.bool.isRequired
        })),
        onSmileSelect: PropTypes.func
    };

    state = {};

    onLaidOut = (event) => {
        const { width } = event.nativeEvent.layout;

        if (!width || this.listWidth === width) {
            return;
        }

        this.listWidth = width;

        const itemWidth = getItemWidth();
        const listHorizontalPadding = (this.listWidth - Math.floor(this.listWidth / itemWidth) * itemWidth) / 2;
        this.setState({
            listLaidOut: true,
            listHorizontalPadding: listHorizontalPadding
        });
    };

    renderItem = (item) => {
        return (
            <Item key={item.basename} smile={item} onPress={this.props.onSmileSelect} />
        );
    };

    render() {
        const smiles = this.props.smiles || [];

        const contentContainerStyle = this.state.listHorizontalPadding
            ? [styles.content, { paddingHorizontal: this.state.listHorizontalPadding }]
            : styles.content;

        return (
            <ScrollView
                style={styles.scroll}
                contentContainerStyle={contentContainerStyle}
                onLayout={this.onLaidOut}
            >
                {
                    !!this.state.listLaidOut && smiles.map(this.renderItem)
                }
            </ScrollView>
        );
    }
}
