import React from 'react';
import PropTypes from 'prop-types';
import { Text, TouchableHighlight, View } from 'react-native';
import testId from 'dating-mobile/src/utils/test-id';
import Resources from 'dating-mobile/src/resources';
import { PHOTO_TRANSFORMATION_TYPE } from 'dating-mobile/src/components/photo';
import { getBadgeDisplayText } from 'dating-mobile/src/dialogs/common/utils/badge-display-text';
import OnlineIndicator from 'dating-mobile/src/user/common/views/online-indicator';
import getDisplayDate from 'dating-mobile/src/utils/date-time-formatting';
import MessageText from 'dating-mobile/src/dialogs/common/views/message-text';
import IfConfigValue from 'dating-mobile/src/components/config-value/if';
import { UserNameAliased } from 'dating-mobile/src/user/common/views/user-name';
import { UserPhotoAliased } from 'dating-mobile/src/user/common/views/user-thumbnail-aliased';
import { Image } from 'react-native-animatable';
import StreamingIndicator from '../../streaming-indicator';

import styles from './styles';

class Contact extends React.Component {
  static displayName = 'contacts.item';

  static propTypes = {
    id: PropTypes.string,
    user: PropTypes.object,
    data: PropTypes.object,
    onSelect: PropTypes.func,
    small: PropTypes.bool,
  };

  shouldComponentUpdate(nextProps) {
    const { user, data, id } = this.props;

    const userName = user && user.name;
    const userThumbnail = user && user.thumbnail;
    const nextUserName = nextProps.user && nextProps.user.name;
    const nextUserThumbnail = nextProps.user && nextProps.user.thumbnail;

    return (
      id !== nextProps.id ||
      data !== nextProps.data ||
      userName !== nextUserName ||
      userThumbnail !== nextUserThumbnail
    );
  }

  onPressed = () => {
    const { onSelect, data } = this.props;

    if (onSelect) {
      onSelect(data);
    }
  };

  getCountMessages = () => {
    const { data } = this.props;

    return data?.stack &&
      Number.isInteger(Number(data.stack)) &&
      Number(data.stack) > 0
      ? Number(data.stack)
      : 0;
  };

  getItemStyles = hasUnreadMessages => {
    const { small } = this.props;

    return {
      container: [
        small ? styles.containerSmall : styles.container,
        hasUnreadMessages && styles.unreadContainer,
      ],
      username: [
        small ? styles.usernameSmall : styles.username,
        hasUnreadMessages && styles.unreadUsername,
      ],
      message: [
        small ? styles.messageSmall : styles.message,
        hasUnreadMessages && styles.unreadMessage,
      ],
      timestamp: hasUnreadMessages ? styles.unreadTimestamp : styles.timestamp,
    };
  };

  render() {
    const { id: userId, user = {}, data = {}, small } = this.props;
    const countMessages = this.getCountMessages();
    const hasUnreadMessages = countMessages > 0;
    const itemStyles = this.getItemStyles(hasUnreadMessages);
    const isMatchData = data.payload?.['text-id'] === 'match-added';

    return (
      <TouchableHighlight style={styles.touchable} onPress={this.onPressed}>
        <View style={itemStyles.container}>
          <View
            style={small ? styles.avatarContainerSmall : styles.avatarContainer}
          >
            <UserPhotoAliased
              style={small ? styles.avatarSmall : styles.avatar}
              userId={userId}
              basename={user.thumbnail}
              transformation={PHOTO_TRANSFORMATION_TYPE.DETECT_FACE}
              forceRetina
              placeholder="avatar"
              {...testId(
                Resources.strings['user-thumbnail-accessibility-label'],
              )}
            />
            {styles.$onlineIndicatorVisible && (
              <View
                style={styles.online}
                {...testId(
                  Resources.strings['online-indicator-accessibility-label'],
                )}
              >
                <OnlineIndicator userId={userId} />
              </View>
            )}
            {isMatchData && (
              <View style={styles.likedIconContainer}>
                <Image
                  style={styles.likedIcon}
                  source={Resources.images.peopleFeatureIcon()}
                />
              </View>
            )}
          </View>

          <View
            style={
              small
                ? styles.messageContentContainerSmall
                : styles.messageContentContainer
            }
          >
            <View style={styles.messageContent}>
              <View style={styles.usernameContainer}>
                <View style={styles.userInfoContainer}>
                  <IfConfigValue
                    path="features.users-alias-enabled"
                    equalsTo={true}
                  >
                    <UserNameAliased
                      userId={user.id}
                      style={itemStyles.username}
                    />
                  </IfConfigValue>

                  <IfConfigValue
                    path="features.users-alias-enabled"
                    equalsTo={false}
                  >
                    <Text
                      numberOfLines={1}
                      style={itemStyles.username}
                      {...testId(
                        Resources.strings['user-name-accessibility-label'],
                      )}
                    >
                      {user.name}
                    </Text>
                  </IfConfigValue>

                  <IfConfigValue
                    path="features.streams-enabled"
                    equalsTo={true}
                  >
                    <StreamingIndicator userId={userId} />
                  </IfConfigValue>
                </View>
                {!small && (
                  <Text
                    numberOfLines={1}
                    style={itemStyles.timestamp}
                    {...testId(
                      Resources.strings['message-time-accessibility-label'],
                    )}
                  >
                    {getDisplayDate(data.timestamp)}
                  </Text>
                )}
              </View>
              <View style={styles.messageInfoContainer}>
                <View style={styles.messageContainer}>
                  {!!data.payload && (
                    <MessageText
                      hasUnreadMessages={hasUnreadMessages}
                      textStyle={itemStyles.message}
                      numberOfLines={1}
                      data={data}
                      {...testId(
                        Resources.strings['message-text-accessibility-label'],
                      )}
                    />
                  )}
                </View>
                {!isMatchData && (
                  <View
                    style={[
                      styles.unreadMessageCount,
                      { opacity: data.stack > 0 ? 1 : 0 },
                    ]}
                  >
                    <Text
                      style={styles.unreadMessageCountText}
                      numberOfLines={1}
                      {...testId(
                        Resources.strings[
                          'unread-messages-indicator-accessibility-label'
                        ],
                      )}
                    >
                      {getBadgeDisplayText(countMessages)}
                    </Text>
                  </View>
                )}
              </View>
            </View>
          </View>
        </View>
      </TouchableHighlight>
    );
  }
}

export default Contact;

export function getHeight() {
  return styles.container.height;
}
