import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        flexDirection: 'row',
        borderRadius: 10,
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },

    textContainer: {
        flex: 1,
        paddingLeft: 16,
        paddingRight: 16,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },

    text: {
        color: '$primaryTextColor',
        fontFamily: '$brandFontFamily',
        fontSize: 17,
        fontWeight: 'normal',
        textAlign: 'center'
    }
});

export default styles;
