import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center'
    },
    text: {
        color: '$streamSystemTextColor',
        fontSize: 14,
        fontWeight: 'bold',
        fontFamily: '$brandFontFamily'

    }
});

export default styles;
