import React from 'react';
import PropTypes from 'prop-types';
import connect from '@sdv/connect';

import identityId from '../../../../models/identity/controller/id';
import configValue from '../../../../components/config-value';
import hasUnreadRequests from '../../../../models/user.events/controllers/has-unread-requests';
import UserEventsModel, { getId, EVENT_TYPE } from '../../../../models/user.events/model';
import StreamsModel from '../../../../models/dialogs.streams/model';

export default function createController(Component) {

    class Controller extends React.Component {
        static displayName = 'dialogs.chats.chat-requests.screen.controller';
        static propTypes = {
            id: PropTypes.string,
            canChat: PropTypes.bool,
            invitations: PropTypes.array,
            invitationsLoaded: PropTypes.bool,
            getInvitations: PropTypes.func,
            getStreams: PropTypes.func,
            staleChatRequest: PropTypes.func,
            deleteChatRequest: PropTypes.func,
            readInvitations: PropTypes.func.isRequired,
            automaticInvitationsEnabled: PropTypes.bool,
            startChat: PropTypes.func,
            updateCredentials: PropTypes.func
        };

        componentDidMount() {
            this.props.getStreams();
            if (this.props.invitationsLoaded === false && this.props.getInvitations) {
                this.props.getInvitations();
            }

            this.props.readInvitations && this.props.readInvitations();
        }

        componentWillReceiveProps(nextProps) {
            if (shouldReconnect(this.props, nextProps)) {
                if (nextProps.invitationsLoaded === false && nextProps.getInvitations) {
                    nextProps.getInvitations();
                }
            }

            const lastEventId = ((this.props.invitations || [])[0] || {})['event-id'];
            const nextLastEventId = ((nextProps.invitations || [])[0] || {})['event-id'];
            if (lastEventId !== nextLastEventId) {
                this.props.readInvitations && this.props.readInvitations();
            }
        }

        onChatSelected = (event) => {
            if (!event || !event['user-id']) {
                return;
            }

            const openChat = () => {
                this.props.staleChatRequest && this.props.staleChatRequest(event['user-id']);
                this.props.startChat && this.props.startChat({
                    id: event['user-id']
                });
            };
            this.props.canChat
                ? openChat()
                : (this.props.updateCredentials && this.props.updateCredentials(openChat));
        };

        onDeleteChatButtonPressed = (event) => {
            if (!event || !event['event-id']) {
                return;
            }

            this.props.deleteChatRequest(event['event-id']);
        };

        render() {
            let invitations = this.props.invitations || [];
            if (!this.props.automaticInvitationsEnabled) {
                invitations = invitations.filter(event => !event.payload || !event.payload.auto);
            }

            return (
                <Component
                    {...this.props}
                    streams={this.props.streams}
                    invitations={invitations}
                    onChatSelect={this.onChatSelected}
                    onDeleteChatButtonPress={this.onDeleteChatButtonPressed}
                />
            )
        }
    }

    function getModels(flux, props) {
        const models = {
            streamsModel: flux.get(StreamsModel)
        };

        if (props.identity) {
            models.invitationsModel = flux.get(UserEventsModel, getId(props.identity, EVENT_TYPE.INVITATION));
        }

        return models;
    }

    function mapModelsToProps(models) {
        const props = {
            streams: models.streamsModel.store.getState().streams
        };

        if (models.invitationsModel) {
            const invitationsModelState = models.invitationsModel.store.getState();
            props.invitations = invitationsModelState.events;
            props.invitationsLoaded = invitationsModelState.events.allEventsLoaded;
        }

        return props;
    }

    function mapModelsToActions(models) {
        const props = {
            getStreams: models.streamsModel.actions.get
        };

        if (models.invitationsModel) {
            props.getInvitations = models.invitationsModel.actions.get;
            props.staleChatRequest = models.invitationsModel.actions.stale;
            props.deleteChatRequest = models.invitationsModel.actions.delete;
        }

        return props;
    }

    function shouldReconnect(props, newProps) {
        return props.identity !== newProps.identity;
    }

    const ConnectedController = connect(
        getModels,
        mapModelsToProps,
        mapModelsToActions,
        shouldReconnect
    )(Controller);

    return identityId(
        hasUnreadRequests(
            configValue(
                ConnectedController,
                {
                    automaticInvitationsEnabled: 'features.automatic-invitations-enabled'
                }
            ),
            {
                userIdPropName: 'identity',
                readActionPropName: 'readInvitations'
            }
        ),
        'identity'
    )

}
