import React from 'react';
import { View, Platform } from 'react-native';
import PropTypes from 'prop-types';
import StatsView from '../../../stream/views/stats';
import Resources from '../../../../resources';
import styles from './styles';
import testId from '../../../../utils/test-id';
import KeyboardSpacer from 'react-native-keyboard-spacer';

import RoomController from '../../../../models/dialogs.room/controllers/controller';
import IdentityController from '../../../../models/identity/controller';
import StreamController from '../../../../models/dialogs.stream/controllers/controller';
import FollowersController from '../../../../models/dialogs.stream.followers/controller/controller';
import AutoGrowingTextInput from "react-native-autogrow-textinput/src/AutoGrowingTextInput";
import TextWithSmiles from 'dating-mobile/src/dialogs/common/views/text-with-smiles';

const ViewersCount = StreamController((props) => {
    return (
        <StatsView counter={props.viewers} icon={Resources.images.eyeStatsIcon()} {...testId('Viewers counter')} />
    )
});

const FollowersCount = FollowersController((props) => {
    return (
        <StatsView counter={props.counter} icon={Resources.images.starStatsIcon()} {...testId('Followers counter')} />
    )
});

class StreamStatus extends React.Component {
    static displayName = 'dialogs.stream.info.user.status';
    static propTypes = {
        id: PropTypes.string.isRequired,
        diamonds: PropTypes.number,
        status: PropTypes.string
    };

    constructor(props) {
        super(props);

        this.state = {
            status: props.status
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.status !== this.props.status) {
            this.setState({
                status: this.props.status
            });
        }
    }

    componentWillUnmount() {
        this.saveStatus();
    }

    onTextChanged = (text) => {
        this.setState({
            status: text
        });
    };

    saveStatus = () => {
        this.props.changeStatus && this.props.changeStatus(this.state.status);
    };

    render() {
        const isCurrentUser = this.props.id === this.props.identity.id;

        return (
            <View style={styles.container}>
                <View style={styles.stats}>
                    <StatsView counter={this.props.diamonds} icon={Resources.images.diamondStatsIcon()} {...testId('Diamond counter')}/>
                    { !!this.props.id && <FollowersCount id={this.props.id}/> }
                    { !!this.props.id && <ViewersCount id={this.props.id}/> }
                </View>
                {
                    isCurrentUser && (
                        <View style={styles.statusInputContainer}>
                            <AutoGrowingTextInput
                                placeholder={Resources.strings['streamStatusInputPlaceholder']}
                                placeholderTextColor={styles._statusInputPlaceholder.color}
                                selectionColor={styles._statusInputSelection.color}
                                style={styles.statusInput}
                                onChangeText={this.onTextChanged}
                                onBlur={this.saveStatus}
                                value={this.state.status}
                                keyboardAppearance={styles._statusInputKeyboardAppearance.keyboardAppearance}
                                {...testId('Status input')}
                            />
                        </View>
                    )
                }
                {
                    Platform.OS === 'ios' && (<KeyboardSpacer />)
                }
                {
                    !isCurrentUser && (
                        <TextWithSmiles text={this.props.status || ''} style={styles.status} smileSize={20} />
                    )
                }
            </View>
        )
    }
}

export default IdentityController(RoomController(StreamStatus));
