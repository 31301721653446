import React from 'react';
import PropTypes from 'prop-types';
import connect from '@sdv/connect';
import Model from '@sdv/domain/user.presence';

const GET_PROPERTY = 'generated.user-presence.controller.get';

export default function createController(Component, options = {}) {

    const userIdPropName = options.userIdPropName || 'id';
    const resultPropName = options.resultPropName || 'presence';

    class Controller extends React.Component {
        static displayName = 'user-presence.controller';
        static propTypes = {
            [userIdPropName]: PropTypes.string,
            [resultPropName]: PropTypes.object,
            [GET_PROPERTY]: PropTypes.func
        };
        static contextTypes = {
            flux: PropTypes.object
        };

        componentDidMount() {
            this.props[GET_PROPERTY] && this.props[GET_PROPERTY]();
        }

        componentDidUpdate(prevProps) {
            if (shouldReconnect(prevProps, this.props)) {
                this.props[GET_PROPERTY] && this.props[GET_PROPERTY]();
            }
        }

        render() {
            const { ...props } = this.props;

            delete props[GET_PROPERTY];

            return (
                <Component
                    {...props}
                />
            );
        }

    }

    function getModels(flux, props) {
        const models = {};

        if (props[userIdPropName]) {
            models.presenceModel = flux.get(Model, props[userIdPropName]);
        }

        return models;
    }

    function mapModelsToProps(models) {
        const presence = models.presenceModel
            ? models.presenceModel.store.getState()
            : {};
        if (resultPropName === '.') {
            return presence;
        }
        return {
            [resultPropName]: presence
        };
    }

    function mapModelsToActions(models) {
        const props = {};

        if (models.presenceModel) {
            props[GET_PROPERTY] = models.presenceModel.actions.get;
        }

        return props;
    }

    function shouldReconnect(props, newProps) {
        return props[userIdPropName] !== newProps[userIdPropName];
    }

    return connect(
        getModels,
        mapModelsToProps,
        mapModelsToActions,
        shouldReconnect
    )(Controller);

};
