import React from 'react';
import { Platform } from 'react-native';
import PropTypes from 'prop-types';
import { connectActionSheet } from '@expo/react-native-action-sheet';
import equal from 'fast-deep-equal';
import { profileMediaResource } from '@sdv/domain/resources/remote';

import withConfigValue from 'dating-mobile/src/components/config-value';
import IdentityId from '../../../../models/identity/controller/id';
import User from '../../../../models/user/controllers';
import UserMedia from '../../../../models/user.media/controller';
import { GALLERY_ITEMS } from '../../../../models/user.media/controller/gallery-controller';
import Resources from '../../../../resources';

const getThumbnail = user =>
  user && (user.thumbnail || user['thumbnail-pending']);

function createScreenController(Component) {
  class Screen extends React.Component {
    static displayName = 'gallery.screen.controller';

    static propTypes = {
      id: PropTypes.string.isRequired,
      identity: PropTypes.string,
      setNavigationBarState: PropTypes.func,
      deletePhoto: PropTypes.func,
      deleteVideo: PropTypes.func,
      togglePrivate: PropTypes.func,
      cheer: PropTypes.func,
      privatePhotosEnabled: PropTypes.bool,
      user: PropTypes.object,
      userThumbnailRequired: PropTypes.bool,
      goBack: PropTypes.func,
      setAsThumbnail: PropTypes.func,
      media: PropTypes.array,
      thumbnailCroppingEnabled: PropTypes.bool,
      isPreview: PropTypes.bool,
      showActionSheetWithOptions: PropTypes.func,
    };

    state = {};

    constructor(props) {
      super(props);

      this.userProfileGallery = props.privatePhotosEnabled
        ? [
            GALLERY_ITEMS.PUBLIC_PHOTOS,
            GALLERY_ITEMS.PRIVATE_PHOTOS,
            GALLERY_ITEMS.VIDEOS,
          ]
        : [GALLERY_ITEMS.PUBLIC_PHOTOS, GALLERY_ITEMS.VIDEOS];

      const itemsOrder =
        props.id && props.identity && props.id !== props.identity
          ? this.userProfileGallery
          : [GALLERY_ITEMS.PHOTOS, GALLERY_ITEMS.VIDEOS];

      this.state = {
        itemsOrder,
        title: '',
        moreButtonVisible: false,
        onMoreButtonPressed: this.onMoreButtonPressed,
        reportButtonVisible:
          !props.isPreview &&
          props.id &&
          props.identity &&
          props.id !== props.identity,
        isPrivate: false,
        togglePrivateButtonVisible: false,
        onTogglePrivateButtonPressed: this.onTogglePrivateButtonPressed,
      };
    }

    componentDidMount() {
      const { setNavigationBarState } = this.props;

      if (setNavigationBarState) {
        setNavigationBarState(this.state);
      }
    }

    componentWillReceiveProps(props) {
      const {
        reportButtonVisible,
        moreButtonVisible,
        togglePrivateButtonVisible,
        itemsOrder,
      } = this.state;

      const changes = {};

      const reportButtonVisibleNew =
        !props.isPreview &&
        props.id &&
        props.identity &&
        props.id !== props.identity;

      if (reportButtonVisible !== reportButtonVisibleNew) {
        changes.reportButtonVisible = reportButtonVisibleNew;
      }

      const thumbnail = getThumbnail(props.user);

      const moreButtonVisibleNew =
        !props.isPreview &&
        props.id &&
        props.identity &&
        props.id === props.identity &&
        this.media &&
        (!this.media.mediatype?.startsWith('image') ||
          (props.userThumbnailRequired
            ? this.media.basename !== thumbnail
            : true));

      if (moreButtonVisible !== moreButtonVisibleNew) {
        changes.moreButtonVisible = moreButtonVisibleNew;
      }

      const togglePrivateButtonVisibleNew =
        moreButtonVisible && this.media.mediatype?.startsWith('image');

      if (togglePrivateButtonVisible !== togglePrivateButtonVisibleNew) {
        changes.togglePrivateButtonVisible = togglePrivateButtonVisibleNew;
      }

      const itemsOrderNew =
        props.id && props.identity && props.id !== props.identity
          ? this.userProfileGallery
          : [GALLERY_ITEMS.PHOTOS, GALLERY_ITEMS.VIDEOS];

      if (!equal(itemsOrder, itemsOrderNew)) {
        changes.itemsOrder = itemsOrderNew;
      }

      if (Object.keys(changes).length) {
        this.setState(changes, () => {
          if (props.setNavigationBarState) {
            props.setNavigationBarState(this.state);
          }
        });
      }
    }

    onVisibleItemChanged = (index, count, item) => {
      const {
        userThumbnailRequired,
        user,
        id,
        identity,
        setNavigationBarState,
        isPreview,
      } = this.props;

      this.media = item;

      const title =
        index >= 0
          ? Resources.strings.formatString(
              Resources.strings['gallery-screen-title-format'],
              index + 1,
              count,
            )
          : '';

      const thumbnail = getThumbnail(user);

      const moreButtonVisible =
        !isPreview &&
        id &&
        identity &&
        id === identity &&
        item &&
        (!item.mediatype?.startsWith('image') ||
          (userThumbnailRequired ? item.basename !== thumbnail : true));

      this.setState(
        {
          title,
          moreButtonVisible,
          isPrivate: item && item.tags && item.tags.indexOf('hidden') >= 0,
          togglePrivateButtonVisible:
            moreButtonVisible && item?.mediatype?.startsWith('image'),
          reportedContent: item && profileMediaResource(item, user.id),
        },
        () => {
          if (setNavigationBarState) {
            setNavigationBarState(this.state);
          }
        },
      );
    };

    onMoreButtonPressed = () => {
      const {
        user,
        setAsThumbnail,
        deletePhoto,
        deleteVideo,
        goBack,
        setNavigationBarState,
        thumbnailCroppingEnabled,
        showActionSheetWithOptions,
      } = this.props;

      if (!this.media) {
        return;
      }

      const item = this.media;

      const options = [
        Resources.strings['gallery-screen-delete-button-title'],
        Resources.strings.cancel,
      ];

      const thumbnail = getThumbnail(user);

      const isPhoto = item.mediatype?.startsWith('image');

      const showSetAsThumbnail =
        isPhoto && (thumbnailCroppingEnabled || item.basename !== thumbnail);

      if (showSetAsThumbnail) {
        options.unshift(
          Resources.strings['gallery-screen-set-as-thumbnail-button-title'],
        );
      }

      showActionSheetWithOptions(
        {
          options,
          cancelButtonIndex: options.length - 1,
          destructiveButtonIndex: options.length - 2,
          showSeparators: Platform.OS === 'web',
        },
        async buttonIndex => {
          try {
            this.setState({ isLoading: true }, () => {
              if (setNavigationBarState) {
                setNavigationBarState(this.state);
              }
            });

            if (showSetAsThumbnail && buttonIndex === 0) {
              if (setAsThumbnail) {
                await setAsThumbnail(item.basename);
              }
            } else if (buttonIndex === options.length - 2) {
              if (isPhoto) {
                if (deletePhoto) {
                  await deletePhoto(item.basename);
                }
              } else if (deleteVideo) {
                await deleteVideo(item.basename);
              }

              const { media: newMedia } = this.props;

              if (!newMedia.length && goBack) {
                goBack();
              }
            }

            this.setState({ isLoading: false }, () => {
              if (setNavigationBarState) {
                setNavigationBarState(this.state);
              }
            });
          } catch (e) {
            this.setState({ isLoading: false }, () => {
              if (setNavigationBarState) {
                setNavigationBarState(this.state);
              }
            });
          }
        },
      );
    };

    onTogglePrivateButtonPressed = () => {
      const { togglePrivate } = this.props;

      if (!this.media) {
        return;
      }

      if (togglePrivate) {
        togglePrivate(this.media.basename);
      }
    };

    render() {
      return (
        <Component
          {...this.props}
          {...this.state}
          onVisibleItemChanged={this.onVisibleItemChanged}
        />
      );
    }
  }

  return Screen;
}

export default Component =>
  withConfigValue(
    User(
      UserMedia(
        IdentityId(
          connectActionSheet(createScreenController(Component)),
          'identity',
        ),
      ),
    ),
    {
      privatePhotosEnabled: 'features.private-photos-enabled',
      userThumbnailRequired: 'features.user-thumbnail-required',
      thumbnailCroppingEnabled: 'features.thumbnail-cropping-enabled',
    },
  );
