import merge from 'merge/merge';
import lohi from '@sdv/commons/utils/lohi';
import { convertToApi } from '../../../../../utils/object';
import session from '../../session';

class Transport {
  constructor(api, bus) {
    this.bus = bus;
    this.api = api;
    this.saved = {};
    this.bus.addListener(
      'event.credits.mall.exchanged',
      this.resend.bind(this),
    );
  }

  send(message) {
    const mess = merge.recursive(true, message);
    let resource = this.api.dialogs.media.messages;
    const recipient = mess['recipient-id'];
    const sender = mess['sender-id'];

    if (
      !mess.in &&
      !mess.out &&
      (typeof mess.in === 'boolean' || typeof mess.out === 'boolean')
    ) {
      this.saved = {};
    }

    if (!mess['recipient-id']) {
      return;
    }

    if (mess.phantom) {
      resource = this.api.dialogs.media.messages.phantom;
    }

    const pair = lohi(mess['recipient-id'], mess['sender-id']);

    delete mess['recipient-id'];
    delete mess['sender-id'];

    function check(err) {
      if (err && err.status === 402 && mess.network) {
        if (!this.saved.network) {
          this.saved = mess;
        }

        this.saved['recipient-id'] = recipient;
        this.saved['sender-id'] = sender;

        if (this.saved.network.ice && mess.network.ice) {
          this.saved.network.ice = this.saved.network.ice.concat(
            mess.network.ice,
          );
        } else if (mess.network.ice) {
          this.saved.network.ice = mess.network.ice;
        }
        if (this.canRequirePayment !== false) {
          this.canRequirePayment = false;
          this.bus.emit('event.call.payment.required', { recipient, sender });
        }
      } else if (!err) {
        this.canRequirePayment = true;
        this.bus.emit('event.call.payment.fullfilled', { recipient, sender });
      }

      return true;
    }

    mess.network = mess.network || {};
    mess.network.session = session.get();

    if (mess.network.ice) {
      mess.network.ice = convertToApi(mess.network.ice);
    }

    resource.post(pair.lower, pair.higher, mess, check.bind(this));
  }

  resend() {
    if (!this.saved.network) {
      return;
    }

    this.send(this.saved);
    this.saved = {};
  }
}

export default Transport;
