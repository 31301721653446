import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  $backgroundColor: '$primaryBackgroundColor',
  $foregroundColor: '$brandTextColor',
  $statusBarStyle: '$primaryStatusBarStyle',
  $statusBarBackgroundColor: '$primaryBackgroundColor',
  $titleVisible: false,
  $balanceVisible: true,
  $descriptionVisible: false,
  $descriptionIconVisible: false,
  $descriptionTitleVisible: true,

  header: {
    backgroundColor: '$backgroundColor',
    borderBottomColor: '$backgroundColor',
    shadowColor: '$backgroundColor',
  },

  headerTitle: {
    color: '$primaryTextColor',
  },

  container: {
    backgroundColor: '$backgroundColor',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'stretch',
  },

  balanceContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 30,
  },

  balanceTitle: {
    fontSize: 18,
    fontFamily: '$brandFontFamily',
  },

  descriptionContainer: {
    marginLeft: 40,
    marginRight: 40,
    marginBottom: 30,
  },

  descriptionIcon: {
    marginTop: 14,
    width: 90,
    height: 90,
  },

  descriptionTitle: {
    fontWeight: 'bold',
    color: '$primaryTextColor',
    textAlign: 'center',
    fontSize: 24,
    '@media ios': {
      fontFamily: '$brandFontFamily',
    },
  },

  description: {
    marginTop: 5,
  },

  products: {
    flex: 1,
    overflow: 'hidden',
    marginHorizontal: 9,
  },

  getDiscount: {
    marginTop: 24,
    marginHorizontal: 20,
  },

  getDiscountButton: {
    backgroundColor: '$accentTextColor',
  },

  getDiscountButtonTitle: {
    color: '$accentColor',
    fontSize: 16,
  },

  activityIndicatorContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },

  hint: {
    color: '$secondaryTextColor',
    fontFamily: '$brandFontFamily',
    fontWeight: 'normal',
    fontSize: 14,
    textAlign: 'center',
    marginTop: 10,
  },

  packageItem: {
    '@media android': {
      height: 60,
    },
  },
});

export default styles;
