export default function parseUserId(id) {

    if (typeof id === 'string') {
        return id;
    }

    const system = Math.floor(id / 10e9);

    return (((id % 10e9) * 100) + system).toString();

}
