import OS from '@sdv/domain/app/os';

class EventsPoller {
  constructor(context) {
    this.api = context.api;
    this.token = null;
    this.fetching = false;
    this.shouldStop = true;
  }

  start = () => {
    this.shouldStop = false;
    this.pollEvent();
  };

  stop = () => {
    this.shouldStop = true;

    if (this.timerId) {
      clearTimeout(this.timerId);

      this.timerId = null;
    }
  };

  isStarted = () => !this.shouldStop;

  pollEvent = cb => {
    if (this.fetching) {
      return;
    }

    this.fetching = true;

    let params = {};

    if (this.token) {
      params = { sync: this.token };
    }

    const date = new Date().toISOString();

    this.api.events.get(
      params,
      (error, data) => {
        if (data && data.length) {
          let lastToken;

          data.forEach(event => {
            const eventName = event.label || event.type;

            if (eventName) {
              const payload = event.payload || event.details;

              if (this.listener) {
                this.listener(eventName, payload);
              }
            }

            if (event.sync) {
              lastToken = event.sync;
            }
          });

          if (lastToken) {
            this.token = lastToken;
          }
        }

        this.fetching = false;

        if (!this.shouldStop) {
          this.timerId = setTimeout(() => {
            this.pollEvent();
          }, 2000);
        }

        if (cb) {
          cb(this.token);
        }
      },
      OS.shared().current === 'web' ? {} : { Date: date },
    );
  };

  setListener(listener) {
    this.listener = listener;
  }
}

export default EventsPoller;
