import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        width: '100%',
        height: '100%',
        backgroundColor: '$primaryBackgroundColor',
        justifyContent: 'space-between',
        flexGrow: 1,
        paddingHorizontal: 40,
    },

    photo: {
        width: '100%',
        height: '100%',
    },

    buttonsContainer: {
        marginBottom: 40,
    },

    takePhotoButton: {
        marginBottom: 10,
    },

    takePhotoButtonText: {
        fontWeight: 'normal',
    },

    galleryButton: {
        backgroundColor: 'white',
        borderColor: '$accentColor',
        borderWidth: 1,
    },

    galleryButtonText: {
        color: '$infoTextColor',
        fontWeight: 'normal',
    },

    photoPickerContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 66,
    },
});

export default styles;
