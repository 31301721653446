import React from 'react';
import PropTypes from 'prop-types';
import { withNavigationFocus } from 'react-navigation';
import { LayoutAnimation } from 'react-native';
import Resources from 'dating-mobile/src/resources';
import ImageButton from 'dating-mobile/src/components/navigation/views/image-button';
import SearchBar from 'dating-mobile/src/components/search-bar';
import CredentialsUpdater from 'dating-mobile/src/authentication/utils/credentials-updater';
import * as ROUTES from 'dating-mobile/src/routing/router/constants';
import styles from './styles';

export default function bindNavigation(Component) {
  class Navigation extends React.Component {
    static displayName = 'dialogs.chats.my-chats.navigation';

    static propTypes = {
      navigation: PropTypes.object,
    };

    showAllRequests = () => {
      const { navigation } = this.props;

      navigation.navigate(ROUTES.CHAT_REQUESTS);
    };

    startChat = params => {
      const { navigation } = this.props;

      navigation.navigate(ROUTES.CHAT, params);
    };

    setNavigationBarState = state => {
      const { navigation } = this.props;

      navigation.setParams(state);
    };

    render() {
      const { navigation } = this.props;
      const forceShow = navigation.getParam('forceShow', false);

      return (
        <Component
          {...this.props}
          forceShow={forceShow}
          startChat={this.startChat}
          showAllRequests={this.showAllRequests}
          updateCredentials={CredentialsUpdater.updateCredentials}
          setNavigationBarState={this.setNavigationBarState}
        />
      );
    }
  }

  Navigation.navigationOptions = ({ navigation, navigationOptions }) => {
    const likesListEnabled = navigation.getParam('likesListEnabled');

    if (likesListEnabled) {
      return { headerShown: false };
    }

    const shouldSearchBarShowParam = navigation.getParam('shouldSearchBarShow');
    const toggleSearchBarShow = shouldSearchBarShow =>
      // `setTimeout` fixes the animation blinking on Android
      // @see http://issues.local/browse/NP-701
      setTimeout(() => {
        LayoutAnimation.configureNext(
          LayoutAnimation.create(
            200,
            LayoutAnimation.Types.linear,
            LayoutAnimation.Properties.opacity,
          ),
        );
        navigation.setParams({ shouldSearchBarShow });
      }, 0);

    const config = {
      headerStyle: [navigationOptions.headerStyle, styles.header],
      headerTitleStyle: [
        navigationOptions.headerTitleStyle,
        styles.headerTitle,
      ],
      headerTitleContainerStyle: [
        navigationOptions.headerTitleContainerStyle,
        styles.headerTitleContainer,
        shouldSearchBarShowParam ? styles.headerTitleContainerSearchBar : null,
      ],
    };

    if (shouldSearchBarShowParam) {
      config.headerTitle = () => (
        <SearchBar
          handleChangeText={navigation.getParam('onFilterChange')}
          onClose={() => toggleSearchBarShow(false)}
          placeholder={
            Resources.strings['my-chats-screen-filter-input-placeholder']
          }
        />
      );
    } else {
      config.headerTitle = Resources.strings['my-chats-screen-title'];
      config.headerLeft = () => (
        <ImageButton
          image={Resources.images.loupeIcon()}
          onPress={() => toggleSearchBarShow(true)}
        />
      );
    }

    return config;
  };

  return withNavigationFocus(Navigation);
}
