import React from 'react';
import PropTypes from 'prop-types';
import EventEmitter from 'eventemitter3';
import withImagePicker from 'dating-mobile/src/components/image-picker';
import UserMediaModel, { getId as getUserMediaModelId, MEDIA_TYPE } from '../model';
import DialogMessagesModel, {
    getId as getDialogMessagesModelId,
} from '@sdv/domain/dialogs.messages';

class MediaSender extends React.Component {
    static displayName = 'dialogs.usermedia.media-sender';
    static contextTypes = {
        flux: PropTypes.object,
    };
    static propTypes = {
        identity: PropTypes.string.isRequired,
        attendee: PropTypes.string.isRequired,
        select: PropTypes.func,
        selectNewMedia: PropTypes.func,
        selectFromGallery: PropTypes.func,
        source: PropTypes.object,
        bus: PropTypes.object,
    };

    constructor(props, context) {
        super(props, context);

        this.flux = context.flux;
        this.bus = this.props.bus || new EventEmitter();
    }

    componentWillReceiveProps(props) {
        const uri = props.source && props.source.uri;
        const currentUri = this.props.source && this.props.source.uri;

        if (uri && uri !== currentUri) {
            this.processMedia(props.source);
        }
    }

    componentDidMount() {
        this.bus.addListener('command.messages.log.resend-media', this.resendMedia);
        this.bus.addListener('command.messages.form.send-media', this.sendMedia);
        this.bus.addListener('command.messages.form.send-new-media', this.sendNewMedia);
        this.bus.addListener('command.messages.form.send-library-media', this.sendLibraryMedia);
    }

    componentWillUnmount() {
        this.bus.removeListener('command.messages.log.resend-media', this.resendMedia);
        this.bus.removeListener('command.messages.form.send-media', this.sendMedia);
        this.bus.removeListener('command.messages.form.send-new-media', this.sendNewMedia);
        this.bus.removeListener('command.messages.form.send-library-media', this.sendLibraryMedia);
    }

    resendMedia = tag => {
        const messagesModel = this.flux.get(
            DialogMessagesModel,
            getDialogMessagesModelId(this.props.identity, this.props.attendee),
        );

        const message = messagesModel.store
            .getState()
            .messages.find(message => message.tag === tag);

        if (message && message.meta.localSource) {
            const mediaModel = this.flux.get(
                UserMediaModel,
                getUserMediaModelId(this.props.identity, this.props.attendee),
            );
            mediaModel.actions.send(message.meta.localSource, tag);
        }
    };

    sendMedia = () => {
        this.props.select && this.props.select();
    };

    sendNewMedia = () => {
        this.props.selectNewMedia && this.props.selectNewMedia();
    };

    sendLibraryMedia = () => {
        this.props.selectFromGallery && this.props.selectFromGallery();
    };

    processMedia = source => {
        const model = this.flux.get(
            UserMediaModel,
            getUserMediaModelId(this.props.identity, this.props.attendee),
        );
        model.actions.send(source);
    };

    render() {
        const { children } = this.props;

        return React.Children.map(children, child => React.cloneElement(child, { bus: this.bus }));
    }
}

export default withImagePicker(MediaSender, {
    mediaType: 'mixed',
    durationLimit: 15,
    noEdit: true,
});
