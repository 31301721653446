export enum UserTags {
  Membership = 'membership', // The logic of this tag is reversed. The absence of this tag means that the user has a membership
  PremiumMembership = 'membership.premium',
  Hidden = 'hidden',
  Boosted = 'users.boosted',
  IsPromoter = 'dialogs.streams.promoter',
  FreeCheersAvaliable = 'cheers.onboarding',
  Customer = 'customer',
  CustomerExperiment = 'e.1.g.1',
  AudioCallReady = 'audio.call.ready',
  CallsPackIsActive = 'credits.accounts.calls.pack',
  FeedbackSent = 'feedback.sent',
  PremiumMembershipDiscount = 'membership.premium.discount',
}
/**
 * @deprecated, use UserTags
 */
export const USER_TAGS = Object.freeze({
  MEMBERSHIP: UserTags.Membership,
  PREMIUM_MEMBERSHIP: UserTags.PremiumMembership,
  HIDDEN: UserTags.Hidden,
  BOOSTED: UserTags.Boosted,
  IS_PROMOTER: UserTags.IsPromoter,
  FREE_CHEERS_AVALIABLE: UserTags.FreeCheersAvaliable,
  CUSTOMER: UserTags.Customer,
  CUSTOMER_EXPERIMENT: UserTags.CustomerExperiment,
  AUDIO_CALL_READY: UserTags.AudioCallReady,
});

export enum TargetedTags {
  BoostInitiated = 'boost.initiated',
  ChatUnapproved = 'chat.unapproved',
}
/**
 * @deprecated, use TargetedTags
 */
export const TARGETED_TAGS = Object.freeze({
  BOOST_INITIATED: TargetedTags.BoostInitiated,
  CHAT_UNAPPROVED: TargetedTags.ChatUnapproved,
});

export enum PreviewTags {
  PresenceMobileApp = 'presence.mobileapp',
  AboutHidden = 'hidden.about',
  VipAccount = 'credits.accounts.vip',
  VipBasic = 'credits.accounts.vip.basic',
  VipPremium = 'credits.accounts.vip.premium',
}
