import React from 'react';
import PropTypes from 'prop-types';
import {
  View,
  Text,
  StatusBar,
  ActivityIndicator,
  ScrollView,
  Image,
} from 'react-native';
import { SafeAreaView } from 'react-navigation';
import { SubscriptionAgreement } from 'dating-mobile/src/payment/views/subscription-agreement';
import { formatPrice } from '@sdv/commons/price-with-code-localization';
import { formatPeriod } from 'dating-mobile/src/payment/utils/trial-pirce-formatting';
import Resources from '../../../resources';
import MembershipsList from '../../views/memberships-list';
import MembershipBenefits from '../../views/membership-benefits';
import { BasicSubscriptionBenefits } from '../../views/membership-benefits/basic-subscription';
import { PremiumSubscriptionBenefits } from '../../views/membership-benefits/premium-subscription';
import { FreeBoostBanner } from '../../views/free-boost-banner';
import IfConfigValue from '../../../components/config-value/if';
import Button from '../../../components/buttons/base';
import styles from './styles';
import { PackageList } from '../../views/package-list-horizontal';

export default class ScreenView extends React.Component {
  static displayName = 'payment.memberships.screen.view';

  static propTypes = {
    safeAreaInsets: PropTypes.object,
    products: PropTypes.array,
    topProductDiscount: PropTypes.string,
    isLoading: PropTypes.bool,
    onProductSelect: PropTypes.func,
    continueWithoutSubscribingPress: PropTypes.func,
    canSubscribe: PropTypes.bool,
    freeBoostOnSubscriptionAvailable: PropTypes.bool,
    ableToNavigateToCredits: PropTypes.bool,
    premiumSubscriptionEnabled: PropTypes.bool,
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
    premiumMembershipIsActive: PropTypes.bool,
    premiumMembershipDiscountUsed: PropTypes.bool,
  };

  state = {
    selectedPackageIndex: 0,
  };

  componentWillReceiveProps(nextProps) {
    const { products } = this.props;

    if (nextProps.products?.length !== products?.length) {
      this.setState({
        selectedPackageIndex: 0,
      });
    }
  }

  getSelectedPackage() {
    const { selectedPackageIndex } = this.state;
    const { products } = this.props;

    return products?.[selectedPackageIndex]?.[1]?.[0];
  }

  getHeader() {
    const {
      canSubscribe,
      premiumSubscriptionEnabled,
      premiumMembershipIsActive,
    } = this.props;

    const membershipIsActive = !canSubscribe;

    if (premiumSubscriptionEnabled) {
      if (premiumMembershipIsActive) {
        return (
          <View style={styles.motivationsHeaderContainer}>
            <Text style={styles.motivationsHeader}>
              {
                Resources.strings[
                  'memberships-screen-premium-subscription-active-title'
                ]
              }
            </Text>
          </View>
        );
      }

      const selectedPackage = this.getSelectedPackage();

      if (selectedPackage?.isPremiumMembership) {
        return (
          <View style={styles.motivationsHeaderContainer}>
            <Text style={styles.motivationsHeader}>
              {
                Resources.strings[
                  'memberships-screen-premium-subscription-title'
                ]
              }
            </Text>
          </View>
        );
      }

      if (membershipIsActive) {
        return (
          <View style={styles.motivationsHeaderContainer}>
            <Text style={styles.motivationsHeader}>
              {
                Resources.strings[
                  'memberships-screen-basic-subscription-active-title'
                ]
              }
            </Text>
          </View>
        );
      }

      return (
        <View style={styles.motivationsHeaderContainer}>
          <Text style={styles.motivationsHeader}>
            {Resources.strings['memberships-screen-basic-subscription-title']}
          </Text>
        </View>
      );
    }

    return (
      <View style={styles.motivationsHeaderContainer}>
        <Text style={styles.motivationsHeader}>
          {
            Resources.strings[
              canSubscribe
                ? 'memberships-screen-motivations-header-text'
                : 'memberships-screen-motivations-header-text-subscribed'
            ]
          }
        </Text>
        {!!canSubscribe && (
          <Text style={styles.motivationsSecondHeader}>
            {
              Resources.strings[
                'memberships-screen-motivations-second-header-text'
              ]
            }
          </Text>
        )}
      </View>
    );
  }

  getBenefits() {
    const {
      premiumSubscriptionEnabled,
      premiumMembershipIsActive,
    } = this.props;

    if (premiumSubscriptionEnabled) {
      if (premiumMembershipIsActive) {
        return <PremiumSubscriptionBenefits badgeHidden />;
      }

      const selectedPackage = this.getSelectedPackage();

      if (!selectedPackage) {
        return null;
      }

      if (selectedPackage?.isPremiumMembership) {
        return <PremiumSubscriptionBenefits />;
      }

      return <BasicSubscriptionBenefits />;
    }

    return <MembershipBenefits />;
  }

  purchasePackage = () => {
    const { selectedPackageIndex } = this.state;
    const { products, onProductSelect, canSubscribe } = this.props;
    const membershipIsActive = !canSubscribe;
    const pack = membershipIsActive
      ? products?.find(p => p?.[1]?.[0]?.isPremiumMembership)
      : products?.[selectedPackageIndex];

    if (pack && onProductSelect) {
      onProductSelect(pack);
    }
  };

  selectPackage = selectedPackageIndex => {
    const { isLoading } = this.props;

    if (!isLoading) {
      this.setState({
        selectedPackageIndex,
      });
    }
  };

  render() {
    const { selectedPackageIndex } = this.state;
    const {
      canSubscribe,
      style,
      safeAreaInsets,
      products,
      topProductDiscount,
      isLoading,
      onProductSelect,
      ableToNavigateToCredits,
      continueWithoutSubscribingPress,
      freeBoostOnSubscriptionAvailable,
      premiumSubscriptionEnabled,
      premiumMembershipIsActive,
      premiumMembershipDiscountUsed,
    } = this.props;

    const selectedPackage = this.getSelectedPackage();
    const hasSelectedPackage = !!selectedPackage;
    const containerStyle = style ? [styles.container, style] : styles.container;
    const membershipIsActive = !canSubscribe;

    return (
      <SafeAreaView
        forceInset={safeAreaInsets || styles.$safeAreaInsets}
        style={containerStyle}
      >
        <StatusBar
          barStyle={styles.$statusBarStyle}
          backgroundColor={styles.$statusBarBackgroundColor}
        />
        <ScrollView
          style={styles.scrollView}
          contentContainerStyle={styles.content}
          alwaysBounceVertical={false}
        >
          <Image
            source={Resources.images.membershipHeaderImage()}
            style={styles.headerImage}
          />
          {this.getHeader()}
          {canSubscribe && freeBoostOnSubscriptionAvailable && (
            <FreeBoostBanner />
          )}
          <View
            style={[
              styles.benefitsContainer,
              premiumSubscriptionEnabled && styles.benefitsContainerExtraMargin,
            ]}
          >
            {this.getBenefits()}
          </View>
          {!premiumSubscriptionEnabled && canSubscribe && (
            <View style={styles.productsContainer}>
              <MembershipsList
                products={products}
                topProductDiscount={topProductDiscount}
                isLoading={isLoading}
                onProductSelect={onProductSelect}
              />
            </View>
          )}
          {premiumSubscriptionEnabled && !premiumMembershipIsActive && (
            <View>
              {!!products?.length && (
                <PackageList
                  packages={products}
                  selectedPackageIndex={selectedPackageIndex}
                  style={styles.packageList}
                  onPackagePress={this.selectPackage}
                />
              )}
              {!products?.length && !isLoading && (
                <View style={styles.packageList}>
                  <ActivityIndicator animating />
                </View>
              )}
              <Button
                disabledGradientColor={styles.disabledButton.gradientColor}
                style={styles.button}
                disabled={isLoading || !hasSelectedPackage}
                onPress={this.purchasePackage}
                title={
                  membershipIsActive
                    ? Resources.strings[
                        'memberships-screen-upgrade-to-premium-button-title'
                      ]
                    : Resources.strings.get
                }
              />
            </View>
          )}
          {!!ableToNavigateToCredits && (
            <IfConfigValue
              path="features.discount-with-membership-enabled"
              equalsTo={true}
            >
              <View style={styles.continueWithoutSubscribing}>
                <Button
                  style={styles.continueWithoutSubscribingButton}
                  titleStyle={styles.continueWithoutSubscribingButtonTitleStyle}
                  title={
                    Resources.strings[
                      'memberships-screen-continue-with-credits-text'
                    ]
                  }
                  onPress={continueWithoutSubscribingPress}
                />
              </View>
            </IfConfigValue>
          )}
          <View style={styles.descriptionContainer}>
            {!premiumMembershipDiscountUsed &&
              !!selectedPackage?.price?.introductory && (
                <Text style={styles.introductoryPriceExplanation}>
                  {Resources.strings.formatString(
                    Resources.strings['introductory-price-explanation'],
                    selectedPackage.price.introductory || '',
                    formatPrice(selectedPackage.price, false) || '',
                    formatPeriod(
                      selectedPackage.price.subscriptionPeriodNumber,
                      selectedPackage.price.subscriptionPeriodUnit,
                    ),
                  )}
                </Text>
              )}
            <SubscriptionAgreement />
            {canSubscribe && freeBoostOnSubscriptionAvailable && (
              <Text style={styles.freeBoostExplanation}>
                {Resources.strings['free-boost-banner-explanation']}
              </Text>
            )}
          </View>
        </ScrollView>
        {!!isLoading && (
          <View style={styles.activityIndicatorContainer}>
            <ActivityIndicator animating />
          </View>
        )}
      </SafeAreaView>
    );
  }
}
