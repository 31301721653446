import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import PropTypes from 'prop-types';
import Gradient from 'dating-mobile/src/components/with-gradient';
import styles from './styles';

export class GradientButton extends React.PureComponent {
  static propTypes = {
    onPress: PropTypes.func,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    containerStyles: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.number,
      PropTypes.array,
    ]),
    buttonStyles: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.number,
      PropTypes.array,
    ]),
    buttonTextStyles: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.number,
      PropTypes.array,
    ]),
    children: PropTypes.node,
    disabled: PropTypes.bool,
  };

  render() {
    const {
      onPress,
      text,
      containerStyles,
      buttonStyles,
      buttonTextStyles,
      children,
      disabled,
    } = this.props;

    return (
      <View style={[styles.container, containerStyles]}>
        <TouchableOpacity onPress={onPress} disabled={disabled}>
          <Gradient
            styles={[
              styles.button,
              disabled ? styles.buttonDisabled : {},
              buttonStyles,
            ]}
          >
            {children || (
              <Text style={[styles.buttonText, buttonTextStyles]}>{text}</Text>
            )}
          </Gradient>
        </TouchableOpacity>
      </View>
    );
  }
}
