import {
    switchMap,
    filter,
    shareReplay,
    tap,
    startWith,
    distinctUntilChanged,
    delay,
} from 'rxjs/operators';
import { singleton } from '@sdv/commons/utils/singleton';
import { Permissions } from '@sdv/domain/app/permissions';
import AppFocus from '@sdv/domain/app/application/focus';
import flux from '@sdv/domain/app/flux';
import PushNotificationsModel from '@sdv/domain/device.permissions';

class PushNotificationPermissions {
    static shared = singleton(() => new PushNotificationPermissions());

    constructor() {
        this.pushNotificationEnabled = AppFocus.shared().focused.pipe(
            filter(Boolean),
            switchMap(() => Permissions.shared().hasNotificationsPermission()),
            delay(100), // Check permission call without delay causes system returns previous value instead of a new one on iOS 11 and lower.
            startWith(false),
            distinctUntilChanged(),
            tap(hasPermission =>
                flux.get(PushNotificationsModel).actions.updatePushNotification(hasPermission),
            ),
        );

        this.requestNotificationsPermission = Permissions.shared()
            .requestNotificationsPermission()
            .pipe(shareReplay(1));
    }
}

export default PushNotificationPermissions;
