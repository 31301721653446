import { Buffer } from 'buffer';

const VALIDATE_REQUEST_TIMEOUT = 3000;

function createAdressesActions(id = 'default') {

    class Actions {

        validate = (email) => (dispatch, flux) => {
            let timer = setTimeout(() => {
                dispatch({ email: email, valid: true })
                timer = null;
            }, VALIDATE_REQUEST_TIMEOUT);
            let encryptedCredentials = new Buffer(email).toString("base64");
            flux.api.electronicmail.addresses.get(encryptedCredentials, (error, res) => {
                if (timer) {
                    // validate email by default if server returns any error.
                    dispatch({ email: email, valid: !(res && res.valid === false) });
                    clearInterval(timer);
                    timer = null;
                }
            });
        }

    }

    Actions.displayName = createAdressesActions.getDisplayName(id);

    return Actions;

}

createAdressesActions.getDisplayName = function (id) {
    return `electronicmail-addresses.${id}`;
};

export default createAdressesActions;
