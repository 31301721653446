import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        flexShrink: 1,
    },
    row: {
        width: '100%',
        paddingBottom: 15,
        flexShrink: 1,
        flexDirection: 'row',
    },
    column: {
        flex: 1,
        paddingRight: 15,
    },
    'column:last-child': {
        paddingRight: 0,
    },
});

export default styles;
