import React from 'react';
import SignUpProposal, { ReminderState } from '@sdv/domain/authorization/signup-proposal';

function createFeedSignupBanner(Component) {

    class Controller extends React.Component {
        static displayName = 'broadcasts.discovery.views.feed-signup-banner.controller';

        constructor(props) {
            super(props);
            this.state = { signUpBannerVisible: false };
        }

        componentDidMount() {
            this.signUpProposal = SignUpProposal.shared();
            this.subscription = this.signUpProposal.state.subscribe(reminderState => {
                const bannerVisible = reminderState === ReminderState.active;
                if (this.state.signUpBannerVisible && !bannerVisible) {
                    this.banner && this.banner.closeWithAnimation(() => {
                        this.setState({ signUpBannerVisible: bannerVisible });
                    })
                } else {
                    this.setState({ signUpBannerVisible: bannerVisible });
                }
            });
        }

        componentWillUnmount() {
            this.subscription.unsubscribe();
        }

        onSignupPress = () => {
            this.signUpProposal.signUp();
        };

        onClosePress = () => {
            this.signUpProposal.decline();
        };

        render() {
            return this.state.signUpBannerVisible ? (
                <Component
                    ref={ref => this.banner = ref}
                    {...this.props}
                    onSignupPress={this.onSignupPress}
                    onClosePress={this.onClosePress}
                />
            ) : null;
        }

    }

    return Controller;

}

export default createFeedSignupBanner;
