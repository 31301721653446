import React from 'react';
import { render } from 'dating-mobile/src/components/rx-render';
import Session from '@sdv/domain/authorization/session';
import { UserIsCustomer } from '@sdv/domain/user/user-is-customer';
import { Config } from '@sdv/domain/app/config';

export default function createController(Component) {
  const Controller = render((props, { useObservable }) => {
    let subscriptionRequired = false;

    const anonymousChatEnabled = useObservable(
      it => it.anonymousChatEnabled,
      Config.shared(),
    );

    if (anonymousChatEnabled) {
      const userId = useObservable(it => it.userId, Session.shared());

      if (userId) {
        subscriptionRequired = useObservable(
          it => it.subscriptionRequiredForBoost,
          UserIsCustomer.shared(userId),
        );
      }
    }

    return <Component {...props} canBoost={!subscriptionRequired} />;
  });

  Controller.displayName = 'payment.screens.purchase.controller';

  return Controller;
}
