import React from 'react';
import connect from '@sdv/connect';
import Model, { getId } from '../model';

const GET_PROPERTY = 'generated.user-tag.exist.get-action';
const PUT_PROPERTY = 'generated.user-tag.exist.put-action';
const DELETE_PROPERTY = 'generated.user-tag.exist.delete-action';

export default function createController(Component, options = {}) {
    const userIdPropName = options.userIdPropName || 'userId';
    const existTagPropName = options.existTagPropName || 'existTag';
    const tag = options.tag;
    const setActionPropName = options.setActionPropName || 'setTag';

    class Tag extends React.Component {
        static displayName = 'user-tag.exist';

        componentDidMount() {
            this.props[GET_PROPERTY] && this.props[GET_PROPERTY]();
        }

        componentDidUpdate(prevProps) {
            if (shouldReconnect(prevProps, this.props)) {
                this.props[GET_PROPERTY] && this.props[GET_PROPERTY]();
            }
        }

        setTag = (exist, ...args) => {
            if (exist) {
                this.props[PUT_PROPERTY] && this.props[PUT_PROPERTY](...args)
            } else {
                this.props[DELETE_PROPERTY] && this.props[DELETE_PROPERTY](...args)
            }
        }

        render() {
            const { ...props } = this.props;

            delete props[GET_PROPERTY];
            delete props[PUT_PROPERTY];
            delete props[DELETE_PROPERTY];

            return (<Component {...props} { ... { [setActionPropName]: this.setTag } } />);
        }

    }

    function getModels(flux, props) {
        const models = {};

        if (props[userIdPropName] && tag) {
            models.tagModel = flux.get(Model, getId(props[userIdPropName], tag));
        }

        return models;
    }

    function mapModelsToProps(models) {
        const props = {};

        if (models.tagModel) {
            props[existTagPropName] = models.tagModel.store.getState().exist;
        }

        return props;
    }

    function mapModelsToActions(models) {
        const props = {};

        if (models.tagModel) {
            props[GET_PROPERTY] = models.tagModel.actions.get;
            props[PUT_PROPERTY] = models.tagModel.actions.put;
            props[DELETE_PROPERTY] = models.tagModel.actions.delete;
        }

        return props;
    }

    function shouldReconnect(props, newProps) {
        return props[userIdPropName] !== newProps[userIdPropName];
    }

    return connect(
        getModels,
        mapModelsToProps,
        mapModelsToActions,
        shouldReconnect
    )(Tag);

}
