import EStylesSheet from 'react-native-extended-stylesheet';
import { I18nManager, Platform } from 'react-native';
import shouldUseWebLayout from 'dating-mobile/src/utils/web-layout';

const webMaxWidth = shouldUseWebLayout() ? 1000 : 640;

const styles = EStylesSheet.create({
  container: {
    marginBottom: 16,
  },

  imageContainer: {
    width: '100%',
    maxWidth: Platform.OS === 'web' ? webMaxWidth : 'none',
    aspectRatio: Platform.OS === 'web' ? 0.48 : 1.98,
    justifyContent: 'center',
  },

  image: {
    transform: [
      {
        scaleX: I18nManager.isRTL ? -1 : 1,
      },
    ],
  },

  textContainer: {
    paddingHorizontal: 25,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },

  title: {
    color: '$accentTextColor',
    fontFamily: '$brandFontFamily',
    fontSize: 24,
    fontWeight: 'bold',
  },

  subtitle: {
    color: '$accentTextColor',
    fontFamily: '$brandFontFamily',
    fontSize: 18,
    marginBottom: 15,
  },

  button: {
    backgroundColor: '#ff623e',
    paddingHorizontal: 20,
    minWidth: 200,
  },

  buttonText: {
    color: '$accentTextColor',
    fontFamily: '$brandFontFamily',
    fontSize: 18,
    fontWeight: '600',
  },
});

export default styles;
