import Button from './view';
import withController from './controller';
import withPermissions from '../../../components/permissions';

export default withPermissions(
    withController(Button),
    {
        cameraAvailable: 'camera'
    }
);
