import actions from './actions';
import store from './store';

/**
 * @deprecated
 */
export default {
  store,
  actions,
};
