import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

export default function Day({ day, modifiers }) {
  const value = day.getDate();

  return (
    <div
      className={`cell-wrapper ${modifiers.selected ? 'selected' : ''} ${
        modifiers.disabled ? 'disabled' : ''
      }`}
    >
      {value}
    </div>
  );
}

Day.propTypes = {
  day: PropTypes.instanceOf(Date),
  modifiers: PropTypes.object,
};
