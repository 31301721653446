import React from 'react';
import PropTypes from 'prop-types';
import { View, Image, TouchableOpacity } from 'react-native';
import styles from './styles';

export class DisablableImageButton extends React.PureComponent {
    static propTypes = {
        image: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
        tint: PropTypes.string,
        onPress: PropTypes.func,
        style: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array]),
        disabled: PropTypes.bool,
        accessibilityLabel: PropTypes.bool,
        testID: PropTypes.bool,
    };

    static defaultProps = {
        disabled: false,
        onPress: () => {},
    };

    render() {
        const { image, tint, onPress, style, disabled, accessibilityLabel, testID } = this.props;
        const imageStyle = [
            styles.image,
            tint && { tintColor: tint },
            disabled && { opacity: 0.2 },
        ];

        const container = (
            <View style={[styles.container, style]}>
                <Image style={imageStyle} source={image} />
            </View>
        );

        return !disabled ? (
            <TouchableOpacity
                onPress={onPress}
                accessibilityLabel={accessibilityLabel}
                testID={testID}
            >
                {container}
            </TouchableOpacity>
        ) : (
            container
        );
    }
}
