import React, { useCallback, useMemo } from 'react';
import { View, Text, TouchableOpacity, StyleProp, TextStyle, ViewStyle } from 'react-native';
import styles from './styles';

type Props = {
    labelStyle: StyleProp<TextStyle>;
    indicatorStyle: StyleProp<ViewStyle>;
    routeName: string;
    labelText: string;
    activeTintColor: string;
    inactiveTintColor: string;
    isFocused: boolean;
    onPress: (routeName: string) => void;
    counter: number;
};

export const TopTabBarItem = (props: Props) => {
    const onItemPressed = useCallback(() => {
        const { onPress, routeName } = props;

        onPress(routeName);
    }, []);

    const counterToDisplay = useCallback((counter: number): string => {
        return counter > 99 ? '!' : counter.toString();
    }, []);

    const {
        labelStyle,
        activeTintColor,
        inactiveTintColor,
        isFocused,
        indicatorStyle,
        labelText,
        counter,
    } = props;

    const chooseMessageButton = useMemo(
        () =>
            counter > 0 && (
                <View style={styles.counterContainer}>
                    <Text style={styles.counterText}>{counterToDisplay(counter)}</Text>
                </View>
            ),
        [counter],
    );

    const spacer = useMemo(() => counter > 0 && <View style={styles.spacer} />, [counter]);

    const textColor = { color: isFocused ? activeTintColor : inactiveTintColor };

    return (
        <TouchableOpacity onPress={onItemPressed} style={styles.container}>
            <View style={styles.content}>
                {spacer}
                <Text style={[labelStyle, textColor]}>{labelText}</Text>
                {chooseMessageButton}
            </View>
            {isFocused && <View style={[styles.underline, indicatorStyle]} />}
        </TouchableOpacity>
    );
};
