import React from 'react';
import PropTypes from 'prop-types';
import RoomModel from '../model';

function createRoomController(Component) {
    class Controller extends React.Component {
        static displayName = 'dialogs.rooms.controller';
        static contextTypes = {
            flux: PropTypes.object,
        };
        static propTypes = {
            id: PropTypes.string.isRequired,
        };
        state = {};

        constructor(props, context) {
            super(props);
            this.model = context.flux.get(RoomModel, props.id);
            this.state = this.model.store.getState();
        }

        componentDidMount() {
            this.model.store.listen(this.updateState);
        }

        componentDidUpdate(prevProps, prevState, snapshot) {
            if (prevProps.id !== this.props.id) {
                this.model.store.unlisten(this.updateState);
                this.model = this.context.flux.get(RoomModel, this.props.id);
                this.model.store.listen(this.updateState);
            }
        }

        componentWillUnmount() {
            this.model.store.unlisten(this.updateState);
        }

        changeStatus = status => {
            this.model.actions.changeStatus(status);
        };

        updateState = state => {
            this.setState(state);
        };

        render() {
            return <Component {...this.state} {...this.props} changeStatus={this.changeStatus} />;
        }
    }

    return Controller;
}

export default createRoomController;
