import { combineLatest, Subscription } from 'rxjs';
import { switchMap, filter, distinctUntilChanged } from 'rxjs/operators';
import Session from '@sdv/domain/authorization/session';
import CallStatus from 'dating-mobile/src/models/call.status';
import { ChatAnonymity } from '@sdv/domain/chat/chat-anonymity';
import flux from '@sdv/domain/app/flux';

import Service from '../service';

export class CallTracker extends Service {
  static displayName = 'service.tracking.call-tracker';

  prevStatus = null;

  prevAttendee = null;

  callStartDate = null;

  componentDidMount() {
    this.subscribe();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  subscribe() {
    const callStatus = Session.shared().userId.pipe(
      filter(Boolean),
      switchMap(userId => flux.get(CallStatus, userId).store.rxState()),
      distinctUntilChanged(),
    );

    const callStatusSubscription = combineLatest(
      Session.shared().userId,
      callStatus,
    ).subscribe(
      ([userId, { status, attendee }]) => {
        if (status === 'connecting') {
          this.callStart(userId, attendee);
        } else if (status === 'idle' && this.prevStatus === 'speaking') {
          this.callEnd(userId, this.prevAttendee, this.callStartDate);
        } else if (status === 'idle' && this.prevStatus === 'connecting') {
          this.callCancel(userId, this.prevAttendee);
        }

        this.prevStatus = status;
        this.prevAttendee = attendee;

        if (status === 'speaking') {
          this.callStartDate = new Date();
        }
      },
      () => {},
    );

    this.disposeBag = new Subscription();
    this.disposeBag.add(callStatusSubscription);
  }

  unsubscribe() {
    if (this.disposeBag) {
      this.disposeBag.unsubscribe();
    }
  }

  callStart(userId, attendee) {
    new ChatAnonymity(userId, attendee).writingAsAnonymous.subscribe(
      writingAsAnonymous => {
        if (!writingAsAnonymous && userId) {
          flux.api.annals.add(userId, 'audio-call-start', {
            attendee,
            timestamp: new Date(),
          });
        }
      },
    );
  }

  callEnd(userId, attendee, startDate) {
    new ChatAnonymity(userId, attendee).writingAsAnonymous.subscribe(
      writingAsAnonymous => {
        if (!writingAsAnonymous && userId) {
          flux.api.annals.add(userId, 'audio-call-end', {
            attendee,
            startDate,
            endDate: new Date(),
          });
        }
      },
    );
  }

  callCancel(userId, attendee) {
    new ChatAnonymity(userId, attendee).writingAsAnonymous.subscribe(
      writingAsAnonymous => {
        if (!writingAsAnonymous && userId) {
          flux.api.annals.add(userId, 'audio-call-cancel', {
            attendee,
            timestamp: new Date(),
          });
        }
      },
    );
  }
}
