import { ImageUploader } from '@sdv/domain/app/image-uploader';

export class StreamCoverUploader {
    uploadStreamCover = (file, userId) => {
        const imageUploader = ImageUploader.shared();
        // TODO: Изменить на новый путь к каверу стримов
        return imageUploader.uploadImage(file, `dialogs/streams/${userId}/covers`);
    };

    getCoverPath = (userId, basename) => {
        return `/dialogs/streams/${userId}/covers/${basename}`;
    };
}
