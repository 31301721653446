import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  container: {
    width: '100%',
  },

  button: {
    paddingVertical: 10,
    borderRadius: 25,
    flexShrink: 0,
    flexGrow: 0,
    gradientColor: ['#FF982A', '#FE2357'],
  },

  buttonDisabled: {
    opacity: 0.2,
  },

  buttonText: {
    fontSize: 22,
    fontFamily: '$brandFontFamily',
    fontWeight: 'bold',
    color: '#ffffff',
    textAlign: 'center',
  },
});

export default styles;
