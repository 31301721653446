import React from 'react';
import PropTypes from 'prop-types';

function withNavigation(Component) {
  class Navigation extends React.Component {
    static displayName = 'streaming.streams-topics-picking-bonus.navigation';

    static propTypes = {
      navigation: PropTypes.object.isRequired,
    };

    onComplete = () => {
      const { navigation } = this.props;
      const completion = navigation.getParam('onComplete');

      if (completion) {
        completion();
      }
    };

    render() {
      return <Component {...this.props} onComplete={this.onComplete} />;
    }
  }

  Navigation.navigationOptions = () => ({
    headerShown: false,
  });

  return Navigation;
}

export default withNavigation;
