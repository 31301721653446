import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, TouchableWithoutFeedback } from 'react-native';
import { SafeAreaView } from 'react-navigation';
import withReader from 'dating-mobile/src/models/dialogs.messages/controllers/reader';
import { AnonymousChatNotice } from 'dating-mobile/src/dialogs/messages/views/anonymous-chat-notice';
import IfConfigValue from 'dating-mobile/src/components/config-value/if';
import withTargetedTags from 'dating-mobile/src/models/user.tags.targeted/controllers';
import withIsStreamer from 'dating-mobile/src/models/user/controllers/with-is-streamer';
import withUser from 'dating-mobile/src/models/user/controllers';
import withUserBalance from 'dating-mobile/src/models/credits.accounts.balance/controllers/controller';
import { Gender } from '@sdv/domain/user/gender';
import withOutgoingTyping from '../../../../models/user.events.typing/controllers/outgoing-typing';
import FormComponent, {
  RESOURCE_KEYBOARD_TYPE,
} from '../../../common/views/form';
import FormController from '../../../../models/common.messages/controllers/form-controller';
import { randomValueFromArray } from '../../../../utils/random';
import MessagesModel, {
  getId,
} from '../../../../models/dialogs.messages/utils/messages-adapter';
import booster from '../../../../models/dialogs.messages/controllers/booster';
import LogComponent from '../../../common/views/log';
import withHasTag from '../../../../models/user.tags/controllers/has-tag';
import LogControllerComponent from '../../../../models/common.messages/controllers/log-controller';
import MediaSenderController from '../../../../models/dialogs.usermedia/controllers/media-sender';
import Resources from '../../../../resources';
import BalanceRefiller, {
  PAYMENT_REASON,
} from '../../../../payment/utils/balance-refiller';
import withConfigValue from '../../../../components/config-value';
import { getTextInputPricingPlaceholderSelector } from './pricing-selector';

import styles from './styles';

const Form = withOutgoingTyping(FormComponent, {
  onStartTypingPropName: 'onTextChange',
});

const logReader = component => withReader(component);

const Log = withTargetedTags(
  withConfigValue(
    logReader(
      booster(LogComponent, {
        enabledPropName: 'boostMessagesEnabled',
        boostPropName: 'boostMessage',
        messagesTagsPropName: 'boostingMessagesTags',
        refillBalancePropName: 'refillBalanceForBoost',
      }),
    ),
    {
      boostMessagePrice: 'pricing.boost-message-price',
      boostMessagesEnabled: 'features.boost-message-enabled',
      anonymousUsingEnabled: 'features.anonymous-using-enabled',
      maxFreeMessagesPerDay: 'features.max-free-messages-per-day',
      reSendDelay: 'features.re-send-message-availability-delay',
      cheersSoundInChatEnabled: 'features.cheers-sound-in-chat-enabled',
    },
  ),
  {
    userIdPropName: 'user',
    targetIdPropName: 'attendee',
  },
);

const LogController = withUserBalance(
  withUser(
    withConfigValue(
      withHasTag(
        withHasTag(LogControllerComponent, {
          tag: 'membership',
          userIdPropName: 'identity',
          hasTagPropName: 'canSubscribe',
        }),
        {
          tag: 'customer',
          userIdPropName: 'identity',
          hasTagPropName: 'userIsCustomer',
        },
      ),
      {
        numberOfFastAnswerVariants: 'fast-answer.number-of-variants',
        unlimitedMessagesWithMembershipEnabled:
          'features.unlimited-messages-with-membership-enabled',
        trialMessagesEnabled: 'features.trial-messages-enabled',
        membershipEnabled: 'features.membership-enabled',
        discountWithMembershipEnabled:
          'features.discount-with-membership-enabled',
        showPaymentScreenImmediatelyForPayedMessages:
          'features.show-payment-screen-immediately-for-payed-messages',
        autoReplyDelay: 'fast-answer.auto-replay-delay',
      },
    ),
    {
      resultPropName: 'attendee',
      userIdPropName: 'attendeeId',
    },
  ),
  { userIdPropName: 'identity' },
);

class ChatLogWithInputView extends React.Component {
  static displayName = 'dialogs.messages.views.chat-log-with-input.view';

  static propTypes = {
    user: PropTypes.string,
    attendeeId: PropTypes.string,
    attendee: PropTypes.object,
    logStyle: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    formStyle: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    style: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    onInputFormHeightChange: PropTypes.func,
    forbidMediaFromCamera: PropTypes.bool,
    instant: PropTypes.bool,
    onKeyboardChange: PropTypes.func,
    currency: PropTypes.string, // eslint-disable-line
    devaluationRatio: PropTypes.number, // eslint-disable-line
    sendMessageNonDevaluatedPrice: PropTypes.number, // eslint-disable-line
    sendSmileNonDevaluatedPrice: PropTypes.number, // eslint-disable-line
    sendStickerNonDevaluatedPrice: PropTypes.number, // eslint-disable-line
    showPricingPlaceholders: PropTypes.bool,
    isStreamer: PropTypes.bool,
    showPricingPlaceholdersForStreamers: PropTypes.bool,
    useSeparateButtonForMediaAttach: PropTypes.bool,
    giftsEnabled: PropTypes.bool,
    smilesEnabled: PropTypes.bool,
    stickersEnabled: PropTypes.bool,
    inboxEnabled: PropTypes.bool,
    mediaInChatEnabled: PropTypes.bool,
    textMessageMaxLength: PropTypes.number,
    randomChatPlaceholdersEnabled: PropTypes.bool,
    creditsEnabledForFeatures: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    isBlocked: PropTypes.bool,
  };

  static contextTypes = {
    flux: PropTypes.object,
  };

  state = {
    quickRepliesVisible: false,
  };

  constructor(props) {
    super(props);

    if (props.showPricingPlaceholders) {
      this.formInputPlaceholderSelector = getTextInputPricingPlaceholderSelector(
        props,
      );
    }

    if (props.showPricingPlaceholdersForStreamers && props.isStreamer) {
      this.formInputPlaceholderSelector = getTextInputPricingPlaceholderSelector(
        props,
      );
    }

    this.chatLogPlaceholder = this.getChatLogPlaceholder();
  }

  getChatLogPlaceholder() {
    const { randomChatPlaceholdersEnabled, attendee } = this.props;
    const creditsEnabledForCheers = this.areCreditsEnabledForFeatures();

    if (creditsEnabledForCheers && attendee?.gender === Gender.Female) {
      return (
        <View>
          <Text style={styles.chatLogPlaceholderText}>
            {Resources.strings['chat-screen-send-gift-placeholder-title']}
          </Text>
          <TouchableWithoutFeedback onPress={this.showCheersKeyboard}>
            <Text style={styles.sendGiftButtonTitle}>
              {
                Resources.strings[
                  'chat-screen-send-gift-placeholder-button-title'
                ]
              }
            </Text>
          </TouchableWithoutFeedback>
        </View>
      );
    }

    if (randomChatPlaceholdersEnabled) {
      return randomValueFromArray(Resources.strings['chat-placeholders']);
    }

    return null;
  }

  showCheersKeyboard = () =>
    this.formRef?.showResourceKeyboard(RESOURCE_KEYBOARD_TYPE.CHEERS);

  dismissKeyboard = message => {
    if (this.formRef) {
      this.formRef.dismissKeyboard();
    }

    if (message?.autoReply) {
      this.setState({
        quickRepliesVisible: true,
      });
    }
  };

  onQuickRepliesClose = () => {
    this.setState({
      quickRepliesVisible: false,
    });
  };

  areCreditsEnabledForFeatures() {
    const { creditsEnabledForFeatures } = this.props;

    return (
      creditsEnabledForFeatures?.length &&
      creditsEnabledForFeatures.includes('cheers')
    );
  }

  render() {
    const {
      user,
      attendeeId,
      useSeparateButtonForMediaAttach,
      giftsEnabled,
      smilesEnabled,
      stickersEnabled,
      inboxEnabled,
      onInputFormHeightChange,
      forbidMediaFromCamera,
      instant,
      isStreamer,
      style,
      logStyle,
      mediaInChatEnabled,
      onKeyboardChange,
      formStyle,
      textMessageMaxLength,
      isBlocked,
    } = this.props;

    const { quickRepliesVisible } = this.state;
    const { flux } = this.context;
    const creditsEnabledForCheers = this.areCreditsEnabledForFeatures();
    const model = flux.get(MessagesModel, getId(user, attendeeId));

    return (
      <View style={[styles.container, style || {}]}>
        <IfConfigValue path="features.anonymous-using-enabled" equalsTo={true}>
          <View style={styles.noticeContainer}>
            <AnonymousChatNotice userId={user} attendeeId={attendeeId} />
          </View>
        </IfConfigValue>

        <View style={[styles.log, logStyle || {}]}>
          <MediaSenderController identity={user} attendee={attendeeId}>
            <LogController
              identity={user}
              attendeeId={attendeeId}
              model={model}
              refillBalance={(...args) =>
                BalanceRefiller.refillBalance(
                  PAYMENT_REASON.SEND_MESSAGE,
                  ...args,
                )
              }
              refillBalanceForCheer={(...args) =>
                BalanceRefiller.purchaseCredits(
                  PAYMENT_REASON.SEND_CHEER,
                  ...args,
                )
              }
              subscribe={(...args) =>
                BalanceRefiller.subscribe(PAYMENT_REASON.SEND_MESSAGE, ...args)
              }
              winkAnswers={Resources.strings['wink-answers']}
              userLikeAnswers={Resources.strings['user-like-answers']}
              quickReplayAnswers={Resources.strings['quick-replay-answers']}
              instant={instant}
              creditsEnabledForCheers={creditsEnabledForCheers}
            >
              <Log
                refillBalanceForBoost={(...args) =>
                  BalanceRefiller.refillBalance(
                    PAYMENT_REASON.BOOST_MESSAGE,
                    ...args,
                  )
                }
                user={user}
                attendee={attendeeId}
                placeholder={this.chatLogPlaceholder}
                onPress={this.dismissKeyboard}
                isPaidChat={isStreamer}
                quickRepliesVisible={quickRepliesVisible}
                onQuickRepliesClose={this.onQuickRepliesClose}
                creditsEnabledForCheers={creditsEnabledForCheers}
              />
            </LogController>
          </MediaSenderController>
        </View>
        {isBlocked ? (
          <SafeAreaView
            forceInset={{ bottom: 'always' }}
            style={styles.blockedContainer}
          >
            <Text style={styles.blockedText}>
              {Resources.strings['user-is-blocked-description']}
            </Text>
          </SafeAreaView>
        ) : (
          <View style={[styles.form, formStyle || {}]}>
            <MediaSenderController identity={user} attendee={attendeeId}>
              <FormController
                model={model}
                refillBalanceForText={(...args) =>
                  BalanceRefiller.refillBalance(
                    PAYMENT_REASON.SEND_MESSAGE,
                    ...args,
                  )
                }
                refillBalanceForCheer={(...args) =>
                  BalanceRefiller.refillBalance(
                    PAYMENT_REASON.SEND_CHEER,
                    ...args,
                  )
                }
                instant={instant}
              >
                <Form
                  user={user}
                  attendee={attendeeId}
                  ref={ref => {
                    this.formRef = ref;
                  }}
                  controlSafeArea
                  useSeparateButtonForMediaAttach={
                    useSeparateButtonForMediaAttach
                  }
                  mediaEnabled={mediaInChatEnabled}
                  giftsEnabled={giftsEnabled}
                  stickersEnabled={stickersEnabled}
                  smilesEnabled={smilesEnabled}
                  inboxEnabled={inboxEnabled}
                  onHeightChange={onInputFormHeightChange}
                  forbidMediaFromCamera={forbidMediaFromCamera}
                  onKeyboardChange={onKeyboardChange}
                  textInputPlaceholderSelector={
                    this.formInputPlaceholderSelector
                  }
                  inputStylingMode="dialog"
                  textMessageMaxLength={textMessageMaxLength}
                />
              </FormController>
            </MediaSenderController>
          </View>
        )}
      </View>
    );
  }
}

export default withIsStreamer(ChatLogWithInputView, {
  userIdPropName: 'attendeeId',
});
