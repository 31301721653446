import PropTypes from 'prop-types';
import { Linking, Platform } from 'react-native';
import Navigator from 'dating-mobile/src/routing/navigator';
import * as ROUTES from 'dating-mobile/src/routing/router/constants';
import Service from 'dating-mobile/src/services/service';
import Identity from '@sdv/domain/identity/model';
import { links } from 'react-native-firebase';

const isAuthorized = stack =>
  stack.includes(ROUTES.APP) ||
  stack.includes(ROUTES.SPECIAL_OFFER_MODAL) ||
  stack.includes(ROUTES.AUDIO_VIDEO_CALL_MODAL);

export default class DeeplinkingService extends Service {
  static contextTypes = {
    flux: PropTypes.object,
  };

  deepLinksQueue = [];

  componentDidMount() {
    this.identity = this.context.flux.get(Identity);
    this.userId = this.identity.store.getState().id;
    this.authorizedZoneIsActive = isAuthorized(Navigator.currentStack());

    this.identity.store.listen(this.onIdentityChanged);
    Navigator.listen(this.onScreenChanged);
    Linking.addEventListener('url', this.onLink);
    this.context.flux.bus.addListener(
      'push-notification.opened',
      this.onPushNotificationOpened,
    );

    Linking.getInitialURL().then(this.onLink);

    if (Platform.OS !== 'web') {
      links()
        .getInitialLink()
        .then(this.handleDynamicLinks);

      this.unsubscribeFromLinks = links().onLink(this.handleDynamicLinks);
    }
  }

  componentWillUnmount() {
    this.identity.store.unlisten(this.onIdentityChanged);
    Navigator.unlisten(this.onScreenChanged);
    Linking.removeEventListener('url', this.onLink);
    this.context.flux.bus.removeListener(
      'push-notification.opened',
      this.onPushNotificationOpened,
    );

    if (this.unsubscribeFromLinks && Platform.OS !== 'web') {
      this.unsubscribeFromLinks();
      this.unsubscribeFromLinks = null;
    }
  }

  handleDynamicLinks = url => {
    if (url) {
      this.tryNavigate(url, null);
    }
  };

  // eslint-disable-next-line no-unused-vars
  navigate(uri, userId, payload) {}

  // eslint-disable-next-line no-unused-vars
  navigateUnauthorized(uri, payload) {}

  tryNavigate = (uri, payload) => {
    if (!this.authorizedZoneIsActive) {
      const matched = this.navigateUnauthorized(uri, payload);

      if (matched) {
        return;
      }
    }

    if (!this.authorizedZoneIsActive || !this.userId) {
      this.deepLinksQueue.push({ uri, payload });

      return;
    }

    this.navigate(uri, this.userId, payload);
  };

  retryNavigate = () => {
    if (this.userId && this.authorizedZoneIsActive) {
      this.deepLinksQueue
        .splice(0)
        .forEach(({ uri, payload }) => this.tryNavigate(uri, payload));
    }
  };

  onLink = payload => {
    const url = typeof payload === 'string' ? payload : (payload || {}).url;

    if (url) {
      this.tryNavigate(url, null);
    }
  };

  onPushNotificationOpened = payload => {
    const { uri, ...otherData } = payload || {};

    if (uri) {
      this.tryNavigate(uri, otherData);
    }
  };

  onScreenChanged = ({ stack }) => {
    this.authorizedZoneIsActive = isAuthorized(stack);
    this.retryNavigate();
  };

  onIdentityChanged = state => {
    this.userId = state.id;
    this.retryNavigate();
  };
}
