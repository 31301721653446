import React from 'react';
import PropTypes from 'prop-types';
import Photo from 'dating-mobile/src/components/photo';
import { PixelRatio, StyleSheet } from 'react-native';
import { smilePath } from 'dating-mobile/src/resources/remote';
import { getStaticSmile } from './static-smiles';

function localSmileForName(name) {
    const staticSmile = getStaticSmile(name);

    if (staticSmile) {
        return staticSmile;
    }

    switch (name) {
        case 'monstro-anim-JDowMDc6':
            return require('./assets/monstro-anim-JDowMDc6.webp');
        case 'monstro-anim-JDp0aXBzeTo=':
            return require('./assets/monstro-anim-JDp0aXBzeTo=.webp');
        case 'monstro-anim-JDp5YXk6':
            return require('./assets/monstro-anim-JDp5YXk6.webp');
        case 'monstro-anim-JDphbmdlbDo=':
            return require('./assets/monstro-anim-JDphbmdlbDo=.webp');
        case 'monstro-anim-JDphbmdyeTo=':
            return require('./assets/monstro-anim-JDphbmdyeTo=.webp');
        case 'monstro-anim-JDphZG9yYWJsZTo=':
            return require('./assets/monstro-anim-JDphZG9yYWJsZTo=.webp');
        case 'monstro-anim-JDpib3JlZDo=':
            return require('./assets/monstro-anim-JDpib3JlZDo=.webp');
        case 'monstro-anim-JDpjb25mdXNlZDo=':
            return require('./assets/monstro-anim-JDpjb25mdXNlZDo=.webp');
        case 'monstro-anim-JDpjcnk6':
            return require('./assets/monstro-anim-JDpjcnk6.webp');
        case 'monstro-anim-JDpkb2g6':
            return require('./assets/monstro-anim-JDpkb2g6.webp');
        case 'monstro-anim-JDpkZXZpbDo=':
            return require('./assets/monstro-anim-JDpkZXZpbDo=.webp');
        case 'monstro-anim-JDpleHBsaWNpdDo=':
            return require('./assets/monstro-anim-JDpleHBsaWNpdDo=.webp');
        case 'monstro-anim-JDpmbGlydHk6':
            return require('./assets/monstro-anim-JDpmbGlydHk6.webp');
        case 'monstro-anim-JDpncnVtcHk6':
            return require('./assets/monstro-anim-JDpncnVtcHk6.webp');
        case 'monstro-anim-JDpnZWVreTo=':
            return require('./assets/monstro-anim-JDpnZWVreTo=.webp');
        case 'monstro-anim-JDpPTUc6':
            return require('./assets/monstro-anim-JDpPTUc6.webp');
        case 'monstro-anim-JDpraXNzOg==':
            return require('./assets/monstro-anim-JDpraXNzOg==.webp');
        case 'monstro-anim-JDpsb3ZlOg==':
            return require('./assets/monstro-anim-JDpsb3ZlOg==.webp');
        case 'monstro-anim-JDpsb29weTo=':
            return require('./assets/monstro-anim-JDpsb29weTo=.webp');
        case 'monstro-anim-JDpubzo=':
            return require('./assets/monstro-anim-JDpubzo=.webp');
        case 'monstro-anim-JDpvb3BzOg==':
            return require('./assets/monstro-anim-JDpvb3BzOg==.webp');
        case 'monstro-anim-JDpXT1c6':
            return require('./assets/monstro-anim-JDpXT1c6.webp');
        case 'monstro-anim-JDpzaWNrOg==':
            return require('./assets/monstro-anim-JDpzaWNrOg==.webp');
        case 'monstro-anim-JDpzb2I6':
            return require('./assets/monstro-anim-JDpzb2I6.webp');
        case 'monstro-anim-JDpzbGVlcHk6':
            return require('./assets/monstro-anim-JDpzbGVlcHk6.webp');
        case 'monstro-anim-JDpzbWlsZTo=':
            return require('./assets/monstro-anim-JDpzbWlsZTo=.webp');
        case 'monstro-anim-JDpzYWQ6':
            return require('./assets/monstro-anim-JDpzYWQ6.webp');
        case 'monstro-anim-JDpzZXJpb3VzOg==':
            return require('./assets/monstro-anim-JDpzZXJpb3VzOg==.webp');
        case 'monstro-anim-JDpieWU6':
            return require('./assets/monstro-anim-JDpieWU6.webp');
        case 'monstro-anim-JDpoZWxsbzo=':
            return require('./assets/monstro-anim-JDpoZWxsbzo=.webp');
        case 'monstro-anim-JDpmdW5ueTo=':
            return require('./assets/monstro-anim-JDpmdW5ueTo=.webp');
        case 'monstro-anim-JDpmZXN0aXZlOg==':
            return require('./assets/monstro-anim-JDpmZXN0aXZlOg==.webp');
        default:
            return null;
    }
}

const DEFAULT_ANIMATED_SMILE_SIZE = 180;
const ANIMATED_SMILE_FRAME = 10;

export default class Smile extends React.PureComponent {
    static displayName = 'components.smile';
    static propTypes = {
        smileName: PropTypes.string,
        style: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array]),
    };

    render() {
        let source = undefined;
        let path = undefined;
        let transformation = undefined;

        if (this.props.smileName) {
            source = localSmileForName(this.props.smileName);
            if (!source) {
                path = smilePath(this.props.smileName);

                const isAnimated = this.props.smileName.split('-').indexOf('anim') >= 0;
                const style = StyleSheet.flatten(this.props.style);
                let smileSize = undefined;
                if (style) {
                    if (typeof style.width === 'number') {
                        smileSize = style.width;
                    } else if (typeof style.height === 'number') {
                        smileSize = style.height;
                    }
                }
                if (!smileSize && isAnimated) {
                    smileSize = DEFAULT_ANIMATED_SMILE_SIZE;
                }

                if (smileSize) {
                    const imageSize = PixelRatio.getPixelSizeForLayoutSize(smileSize);
                    transformation = isAnimated
                        ? `.x${imageSize};crop=${imageSize *
                              ANIMATED_SMILE_FRAME}x0x${imageSize}x${imageSize}`
                        : `.${imageSize}x${imageSize}`;
                }
            }
        }

        return (
            <Photo
                {...this.props}
                source={source}
                path={path}
                size={transformation}
                forceRetina={true}
            />
        );
    }
}
