import { Platform } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  label: {
    marginHorizontal: 10,
    marginBottom: 2,
    color: Platform.select({
      web: '$inputTextColor',
      default: '$brandTextColor',
    }),
  },
});

export default styles;
