import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        width: '100%',
        flexDirection: 'column',
        alignItems: 'stretch',
        '@flavor dma': {
            height: '100%'
        }
    },

    touchableContent: {
        marginHorizontal: 30,
        marginVertical: 8,
        height: 210,
        '@flavor dma': {
            height: undefined,
            marginHorizontal: 20
        }
    },

    content: {
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'stretch',
        '@flavor dma': {
            justifyContent: 'flex-end'
        }
    },

    background: {
        position: 'absolute',
        top: 0,
        left: 0,
        borderRadius: 15,
        width: '100%',
        height: '100%'
    },

    titleContainer: {
        marginTop: 16,
        marginRight: 58,
        paddingLeft: 5,
        paddingTop: 5,
        paddingBottom: 7,
        paddingRight: 2,
        backgroundColor: '#000000b2',
        '@flavor dma': {
            backgroundColor: 'transparent',
            marginTop: undefined,
            marginBottom: 20,
            marginLeft: 20,
            marginRight: 32,
        }
    },

    title: {
        fontSize: 16,
        fontFamily: '$brandFontFamily',
        fontWeight: '500',
        color: 'white',
        '@flavor lovinga': {
            fontWeight: 'normal'
        },
        '@flavor dma': {
            fontSize: 32,
            fontWeight: '900'
        }
    }
});

export default styles;
