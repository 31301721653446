import React from 'react';
import Button from './view';
import withController from './controller';
import withPermissions from '../../../components/permissions';

const ConnectedComponent = withPermissions(
    withController(Button),
    {
        cameraAvailable: 'camera'
    }
);

export default React.forwardRef(
    (props, ref) => React.createElement(ConnectedComponent, { forwardedRef: ref, ...props })
);
