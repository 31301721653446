import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    container: {
        justifyContent: 'flex-start'
    },
    coverContainer: {
        width: 130,
        height: 130,
        backgroundColor: 'rgba(221, 221, 221, 1)',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 20,
        overflow: 'hidden'
    },
    coverImage: {
        position: 'absolute',
        width: 130,
        height: 130
    },
    text: {
        textAlign: 'center',
        width: '100%',
        maxHeight: 40,
        color: 'white',
        textShadowColor: 'rgba(0, 0, 0, 0.75)',
        textShadowOffset: { width: -1, height: 1 },
        textShadowRadius: 5,
    },
    attachmentIconsContainer: {
        justifyContent: 'center',
        alignItems: 'center'
    },
    photoAttachment: {
        marginBottom: 10
    },
    unsentLetter: {
        opacity: 0.5
    }
});

export default styles;
