import Actions from './actions';

function createDialogsWinkStore(id) {

    class Store {

        constructor(flux) {
            const actions = flux.getActions(Actions, id);
            this.bindAction(actions.wink, this.wink);
            this.state = {};
        }

        wink = (data) => {

            if (data && data.winkType) {
                const winkedUsers = (this.state[data.winkType] || []).slice();
                if (data.addedUser) {
                    if (winkedUsers.indexOf(data.addedUser) < 0) {
                        winkedUsers.push(data.addedUser);
                        this.setState({
                            [data.winkType]: winkedUsers
                        })
                    }
                } else if (data.removedUser) {
                    const index = winkedUsers.indexOf(data.removedUser);
                    if (index >= 0) {
                        winkedUsers.splice(index, 1);
                        this.setState({
                            [data.winkType]: winkedUsers
                        })
                    }
                }
            }
            
        }

    }

    Store.displayName = createDialogsWinkStore.getDisplayName(id);
    
    return Store;
}

createDialogsWinkStore.getDisplayName = function (id) {
    return `dialogs-wink.${id}`;
};

export default createDialogsWinkStore;
