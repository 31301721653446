import React from 'react';
import PropTypes from 'prop-types';
import Resources from '../../../resources';
import ImageButton from '../../../components/navigation/views/image-button';
import BalanceRefiller, {
  PAYMENT_REASON,
} from '../../../payment/utils/balance-refiller';
import CredentialsUpdater from '../../../authentication/utils/credentials-updater';

import View from './view';
import Controller from './controller';
import styles from './styles';
import { StyleSheet } from 'react-native';

const ScreenView = Controller(View);

export default class Screen extends React.Component {
  static displayName = 'dialogs.cheers.screen';

  static propTypes = {
    navigation: PropTypes.object.isRequired,
  };

  static navigationOptions = ({ navigation }) => ({
    title: Resources.strings['cheers-screen-title'],
    headerStyle: styles.header,
    headerTitleStyle: styles.headerTitle,
    headerLeft: () => (
      <ImageButton
        image={Resources.images.closeIcon()}
        tint={StyleSheet.flatten(styles.headerTitle).color}
        onPress={() => navigation.pop()}
      />
    ),
  });

  close = () => {
    const { navigation } = this.props;

    navigation.pop();
  };

  refillBalance = (...args) =>
    BalanceRefiller.refillBalance(PAYMENT_REASON.SEND_CHEER, ...args);

  render() {
    const { navigation } = this.props;
    const userId = navigation.getParam('id');

    return (
      <ScreenView
        userId={userId}
        refillBalance={this.refillBalance}
        updateCredentials={CredentialsUpdater.updateCredentials}
        close={this.close}
      />
    );
  }
}
