import EventEmitter from 'eventemitter3';
import Identity from '@sdv/domain/identity/model';
import WebSocketConnection from '../../websocket-connection';
import EventsPoller from '../events-poller';

class EventInbox {
  identity = null;

  timestamp = null;

  userId = null;

  eventEmitter = new EventEmitter();

  constructor(flux, getHost) {
    this.flux = flux;
    this.eventsPoller = new EventsPoller(flux);
    this.host = getHost();

    const identity = flux.get(Identity);

    identity.store.listen(this.init);
    this.init(identity.store.getState());
  }

  // TODO: Prevent race condition
  init = ({ id }) => {
    if (id === this.userId || (!id && !this.userId)) {
      return;
    }

    this.userId = id;

    if (this.userId) {
      this.eventsPoller.pollEvent(timestamp => {
        this.timestamp = timestamp;
        this.connect();
      });
    } else {
      this.timestamp = null;
      this.close();
    }
  };

  connect() {
    const maxRetry = 3;

    this.previousHost = null;

    this.connection = new WebSocketConnection(
      (key, shard) => this.makeUrl(key, shard, this.host),
      this.flux,
      this.flux.api.getUserAgent(),
      maxRetry,
      () => this.startFallback(),
    );

    this.connection.messages.addListener(e => {
      if (e.data) {
        const event = JSON.parse(e.data);
        const eventName = event.label || event.type;

        if (eventName) {
          const payload = event.payload || event.details;

          this.eventEmitter.emit(eventName, payload);
        }

        if (event.sync) {
          this.timestamp = event.sync;
          this.eventsPoller.token = this.timestamp;
        }
      }
    });
  }

  reconnect(host) {
    this.host = host || this.host;

    if (this.previousHost && host !== this.previousHost) {
      this.close();
      this.connect();
    }
  }

  makeUrl(key, shard, host) {
    this.previousHost = host;

    let shardedHost = 'wss://rt{shard}.zillapp.io/{key}';

    if (shard && key && host) {
      shardedHost = host.replace('{shard}', shard).replace('{key}', key);
    }

    return this.timestamp == null
      ? shardedHost
      : `${shardedHost}?sync=${this.timestamp}`;
  }

  close() {
    this.closeSocket();
    this.stopPolling();
  }

  closeSocket() {
    if (this.connection) {
      this.connection.close();
      this.connection = null;
    }
  }

  startFallback() {
    this.closeSocket();
    this.eventsPoller.stop();
    this.eventsPoller.setListener((eventType, listener) =>
      this.eventEmitter.emit(eventType, listener),
    );
    this.eventsPoller.start();
  }

  stopPolling() {
    this.eventsPoller.stop();
  }

  addListener(eventType, listener) {
    return this.eventEmitter.addListener(eventType, listener);
  }

  removeListener(eventType, listener) {
    this.eventEmitter.removeListener(eventType, listener);
  }

  pollEvent() {
    if (this.eventsPoller.isStarted()) {
      this.eventsPoller.pollEvent();
    }
  }
}

export default EventInbox;
