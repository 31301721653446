import React, { PureComponent } from 'react';
import { View, Text } from 'react-native';
import PropTypes from 'prop-types';
import EStyleSheet from 'react-native-extended-stylesheet';
import type from '@sdv/commons/utils/type';

import styles from './styles';

export default class NotificationBadge extends PureComponent {
    static displayName = 'components.notification-badge';

    static propTypes = {
        text: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
        style: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.object,
            PropTypes.array
        ]),
        textStyle: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.object,
            PropTypes.array
        ]),
        counterMaxValue: PropTypes.number,
    };

    static defaultProps = {
        counterMaxValue: 99,
    };

    // Хак: borderWidth + borderRadius оставляет ореол вокруг элемента из-за бага рендеринга в IOS
    // https://github.com/facebook/react-native/issues/2017
    render() {
        const style = EStyleSheet.flatten(this.props.style || {});
        const {
            width = 0,
            height = 0,
            borderWidth = 0,
            backgroundColor,
            borderColor,
            borderRadius,
        } = style;

        let text = this.props.text;

        if (type.isNumber(text)) {
            text = Math.min(text, this.props.counterMaxValue);
        }

        return (
            <View
                style={[styles.center, style, {
                    backgroundColor: borderColor,
                    borderWidth: undefined,
                    borderColor: undefined,
                }]}
            >
                <View
                    style={[styles.center, {
                        backgroundColor,
                        borderRadius,
                        width: width - borderWidth * 2,
                        height: height - borderWidth * 2,
                        borderWidth: undefined,
                        borderColor: undefined,
                    }]}
                >
                    {!!text && (
                        <Text style={[styles.text, this.props.textStyle]}>{text}</Text>
                    )}
                </View>
            </View>
        );
    }
}
