function CreateRoomActions(id = 'default') {
  class RoomActions {
    join = () => (dispatch, flux) => {
      console.log('RoomActions.join', id);
      flux.streamConnection?.notify('command.dialogs.rooms.join', { id });
      dispatch(null);
    };

    leave = () => (dispatch, flux) => {
      console.log('RoomActions.leave', id);
      flux.streamConnection?.notify('command.dialogs.rooms.leave', { id });
      dispatch(null);
    };

    changeStatus = status => (dispatch, flux) => {
      const newStatus = status || '';

      flux.streamConnection?.notify('command.dialogs.rooms.patch', {
        id,
        status: newStatus,
      });
      dispatch(newStatus);
    };

    block = user => (dispatch, flux) => {
      if (!user) {
        dispatch(null, new Error('User id should be not empty'));

        return;
      }

      flux.streamConnection?.notify('command.dialogs.rooms.users.mute', {
        id: user,
        room: id,
      });
      dispatch(user);
    };

    removeMessagesFromUser = user => dispatch => {
      if (!user) {
        dispatch(null, new Error('User id should be not empty'));

        return;
      }

      dispatch(user);
    };

    set = payload => dispatch => {
      dispatch(payload);
    };

    updateMessage = message => dispatch => {
      dispatch(message);
    };

    sendSystemMessage = content => dispatch => {
      dispatch(content);
    };

    send = (sender, message) => (dispatch, flux) => {
      const { ...newMessage } = message;

      newMessage.sender = sender;
      newMessage.recipient = id;
      newMessage.tag = newMessage.tag || generateTag(sender);
      newMessage.public = true;
      newMessage.status = 1;
      newMessage.timestamp = new Date().getTime();
      newMessage.text = newMessage.text || '';
      newMessage.meta = newMessage.meta || {};
      newMessage.meta.room = id;

      flux.streamConnection?.notify('command.dialogs.rooms.messages.add', {
        message: newMessage,
        room: id,
      });

      dispatch(newMessage);
    };
  }

  RoomActions.displayName = CreateRoomActions.getDisplayName(id);

  return RoomActions;
}

CreateRoomActions.getDisplayName = function(id) {
  return `dialogs-rooms.${id}`;
};

export default CreateRoomActions;

export function generateTag(sender) {
  return `${sender}_${+`${Date.now()}${Math.floor(
    Math.random() * 9000 + 1000,
  )}`}`;
}
