import React from 'react';
import { Platform } from 'react-native';
import PropTypes from 'prop-types';
import Resources from 'dating-mobile/src/resources';
import testId from 'dating-mobile/src/utils/test-id';
import RangePicker from '../../../components/range-picker';

import {
  FILTERS,
  KIDS_VALUES,
  PREFERRED_GENDER_VALUES,
} from '../../utils/filters';
import { CRITERIA_OPTIONS } from '../../../models/misc-data/utils';
import {
  ProfileDynamicParameterItem as ParametrizedItem,
  ProfileParameterItem as Item,
} from '../../../user/edit/views/profile-parameter-item';
import CheckMarkItem from '../../../components/check-mark-item';

class FilterItem extends React.Component {
  static displayName = 'feed.filter.screen.filter-item';

  static propTypes = {
    emptyValue: PropTypes.string,
    filter: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.any,
    onPress: PropTypes.func,
    allowedMinAge: PropTypes.number,
    allowedMaxAge: PropTypes.number,
    onFilterEditComplete: PropTypes.func,
  };

  render() {
    const {
      emptyValue,
      filter,
      value,
      onPress,
      allowedMinAge,
      allowedMaxAge,
      onFilterEditComplete,
    } = this.props;

    let displayValue;
    const isWeb = Platform.OS === 'web';

    switch (filter) {
      case FILTERS.GENDER:
        return (
          <Item
            title={
              Resources.strings['filter-screen-preferred-gender-item-title']
            }
            value={PREFERRED_GENDER_VALUES[value] || emptyValue}
            type={filter}
            onPress={onPress}
            {...testId(
              Resources.strings['preferred-gender-accessibility-label'],
            )}
          />
        );

      case FILTERS.AGE:
        displayValue = emptyValue;
        if (value && value.min && value.max) {
          displayValue = isWeb ? (
            <RangePicker
              range={{
                min: +allowedMinAge,
                max: +allowedMaxAge,
              }}
              placeholder={{
                from: Resources.strings.from,
                to: Resources.strings.to,
              }}
              selectedMin={+value.min}
              selectedMax={+value.max}
              onUpdateRange={onFilterEditComplete}
              itemStyle={{ width: 120 }}
            />
          ) : (
            `${value.min} - ${value.max}`
          );
        }

        return (
          <Item
            title={Resources.strings['filter-screen-age-range-item-title']}
            value={displayValue}
            isInline={isWeb}
            type={filter}
            onPress={onPress}
            {...testId(Resources.strings['preferred-age-accessibility-label'])}
          />
        );

      case FILTERS.LOCATION:
        return value && value.country ? (
          <ParametrizedItem
            title={Resources.strings['filter-screen-location-item-title']}
            dataType={CRITERIA_OPTIONS.COUNTRIES}
            value={value.country}
            valueFormat={value.city ? `${value.city}, {0}` : '{0}'}
            type={FILTERS.LOCATION}
            onPress={onPress}
          />
        ) : (
          <Item
            title={Resources.strings['filter-screen-location-item-title']}
            value={(value && value.city) || emptyValue}
            type={FILTERS.LOCATION}
            placeholder={
              Resources.strings['filter-screen-location-item-placeholder']
            }
            onPress={onPress}
            {...testId(Resources.strings['location-accessibility-label'])}
          />
        );

      case FILTERS.TRAVEL_DESTINATION:
        return value && value.country ? (
          <ParametrizedItem
            title={
              Resources.strings['filter-screen-travel-destination-item-title']
            }
            dataType={CRITERIA_OPTIONS.COUNTRIES}
            value={value.country}
            valueFormat={value.city ? `${value.city}, {0}` : '{0}'}
            type={FILTERS.TRAVEL_DESTINATION}
            onPress={onPress}
          />
        ) : (
          <Item
            title={
              !value || (!value.city && !value.country)
                ? Resources.strings[
                    'filter-screen-travel-destination-empty-item-title'
                  ]
                : Resources.strings[
                    'filter-screen-travel-destination-item-title'
                  ]
            }
            value={(value && value.city) || emptyValue}
            type={FILTERS.TRAVEL_DESTINATION}
            placeholder={
              Resources.strings['filter-screen-location-item-placeholder']
            }
            onPress={onPress}
            {...testId(
              Resources.strings['travel-destination-accessibility-label'],
            )}
          />
        );

      case FILTERS.EDUCATION:
        return (
          <ParametrizedItem
            title={Resources.strings['filter-screen-education-item-title']}
            dataType={CRITERIA_OPTIONS.EDUCATIONS}
            value={value}
            type={filter}
            onPress={onPress}
          />
        );

      case FILTERS.LANGUAGE:
        return (
          <ParametrizedItem
            title={Resources.strings['filter-screen-language-item-title']}
            dataType={CRITERIA_OPTIONS.LANGUAGES}
            value={value}
            type={filter}
            onPress={onPress}
          />
        );

      case FILTERS.RELATIONSHIP:
        return (
          <ParametrizedItem
            title={Resources.strings['filter-screen-relationship-item-title']}
            dataType={CRITERIA_OPTIONS.RELATIONSHIPS}
            value={value}
            type={filter}
            onPress={onPress}
          />
        );

      case FILTERS.KIDS:
        return (
          <Item
            title={Resources.strings['filter-screen-kids-item-title']}
            value={KIDS_VALUES[String(value)] || emptyValue}
            type={filter}
            onPress={onPress}
          />
        );

      case FILTERS.SMOKE:
        return (
          <ParametrizedItem
            title={Resources.strings['filter-screen-smoke-item-title']}
            dataType={CRITERIA_OPTIONS.SMOKE_SEARCH}
            value={value}
            type={filter}
            onPress={onPress}
          />
        );

      case FILTERS.DRINK:
        return (
          <ParametrizedItem
            title={Resources.strings['filter-screen-drink-item-title']}
            dataType={CRITERIA_OPTIONS.DRINKING_SEARCH}
            value={value}
            type={filter}
            onPress={onPress}
          />
        );

      case FILTERS.HEIGHT:
        displayValue = emptyValue;
        if (value.min && value.max) {
          displayValue = Resources.strings.formatString(
            Resources.strings['filter-screen-height-item-range-value-format'],
            value.min,
            value.max,
          );
        } else if (value.min) {
          displayValue = Resources.strings.formatString(
            Resources.strings[
              'filter-screen-height-item-more-than-value-format'
            ],
            value.min,
          );
        } else if (value.max) {
          displayValue = Resources.strings.formatString(
            Resources.strings[
              'filter-screen-height-item-less-than-value-format'
            ],
            value.max,
          );
        }

        return (
          <Item
            title={Resources.strings['filter-screen-height-item-title']}
            value={displayValue}
            type={FILTERS.HEIGHT}
            onPress={onPress}
          />
        );

      case FILTERS.EYES:
        return (
          <ParametrizedItem
            title={Resources.strings['filter-screen-eyes-item-title']}
            dataType={CRITERIA_OPTIONS.EYES_SEARCH}
            value={value}
            type={filter}
            onPress={onPress}
          />
        );

      case FILTERS.HAIR:
        return (
          <ParametrizedItem
            title={Resources.strings['filter-screen-hair-item-title']}
            dataType={CRITERIA_OPTIONS.HAIRS_SEARCH}
            value={value}
            type={filter}
            onPress={onPress}
          />
        );

      case FILTERS.INTERESTS:
        return (
          <ParametrizedItem
            title={Resources.strings['filter-screen-interests-item-title']}
            dataType={CRITERIA_OPTIONS.INTERESTS}
            value={value}
            type={filter}
            onPress={onPress}
          />
        );

      case FILTERS.BODY_TYPE:
        return (
          <ParametrizedItem
            title={Resources.strings['filter-screen-body-type-item-title']}
            dataType={CRITERIA_OPTIONS.BODY_TYPE}
            value={value}
            type={filter}
            onPress={onPress}
          />
        );

      case FILTERS.VIDEO_CHAT:
        return (
          <CheckMarkItem
            title={Resources.strings['filter-screen-video-chat-item-title']}
            selected={value}
            onSelectionChange={() => {
              onPress(FILTERS.VIDEO_CHAT);
            }}
          />
        );

      default:
        return null;
    }
  }
}

export default FilterItem;
