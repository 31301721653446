import PropTypes from 'prop-types';
import UserDefaults from 'react-native-default-preference';
import { analytics as createFirebaseTracker } from 'react-native-firebase';
import AppsFlyerTracker from 'dating-mobile/src/services/tracking/apps-flyer-tracker';
import IdentityModel from '@sdv/domain/identity/model';
import flux from '@sdv/domain/app/flux';
import MallModel from '../../models/credits.mall/model';
import { PAYMENT_REASON } from '../../payment/utils/balance-refiller';
import Service from '../service';
import { GATracker } from './ga-tracker';

const USERS_WITH_PURCHASES_PREFERENCES_KEY = `service.tracking.purchases:users-with-purchases`;
const PURCHASES_COUNT_KEY = `service.tracking.purchases:purchases-count`;

function getReason(purchase) {
  let reason = 'MENU';

  switch (purchase.reason || '') {
    case PAYMENT_REASON.SEND_MESSAGE:
      reason = 'SEND_MESSAGE';
      break;
    case PAYMENT_REASON.SEND_VIDEO:
      reason = 'SEND_VIDEO';
      break;
    case PAYMENT_REASON.SEND_PHOTO:
      reason = 'SEND_PHOTO';
      break;
    case PAYMENT_REASON.WATCH_VIDEO:
      reason = 'WATCH_VIDEO';
      break;
    case PAYMENT_REASON.WATCH_PHOTO:
      reason = 'WATCH_PHOTO';
      break;
    case PAYMENT_REASON.OPEN_LETTER:
      reason = 'OPEN_LETER';
      break;
    case PAYMENT_REASON.SEND_CHEER:
      reason = 'LIVE_STREAM_GIFT';
      break;
    case PAYMENT_REASON.USE_ADVANCED_SEARCH:
      reason = 'USE_ADVANCED_SEARCH';
      break;
    case PAYMENT_REASON.VIEW_PRIVATE_PHOTO:
      reason = 'VIEW_PRIVATE_PHOTO';
      break;
    case PAYMENT_REASON.START_MINGLE:
      reason = 'START_MINGLE';
      break;
    case PAYMENT_REASON.BOOST_PROFILE:
      reason = 'BOOST_PROFILE';
      break;
    case PAYMENT_REASON.BOOST_MESSAGE:
      reason = 'BOOST_MESSAGE';
      break;
    case PAYMENT_REASON.BECOME_HOST:
      reason = 'BECOME_HOST';
      break;
    case PAYMENT_REASON.CALL:
      reason = 'VIDEO_CHAT';
      break;
    case PAYMENT_REASON.ONBOARDING:
      reason = 'ONBOARDING';
      break;
    case PAYMENT_REASON.SKIP_SUBSCRIBE:
      reason = 'SKIP_SUBSCRIBE';
      break;
    case PAYMENT_REASON.SPECIAL_OFFER:
      reason = 'SPECIAL_OFFER';
      break;
    default:
      break;
  }

  return reason;
}

export default class PurchasesTrackingService extends Service {
  static displayName = 'service.tracking.purchases';

  static contextTypes = {
    flux: PropTypes.object,
  };

  purchasesIds = [];

  lastPurchaseError = null;

  constructor(props, context) {
    super(props, context);

    this.flux = context.flux;
    this.identityModel = this.flux.get(IdentityModel);
    this.identity = this.identityModel.store.getState().id;
    if (this.identity) {
      this.mallModel = this.flux.get(MallModel, this.identity);
    }
  }

  componentDidMount() {
    this.identityModel.store.listen(this.onIdentityChanged);
    if (this.mallModel) {
      this.mallModel.store.listen(this.onMallStateChanged);
    }
  }

  componentWillUnmount() {
    this.identityModel.store.unlisten(this.onIdentityChanged);
    if (this.mallModel) {
      this.mallModel.store.listen(this.onMallStateChanged);
    }
  }

  onIdentityChanged = state => {
    if (this.identity !== state.id) {
      this.identity = state.id;
      if (!this.identity) {
        if (this.mallModel) {
          this.mallModel.store.unlisten(this.onMallStateChanged);
        }
        this.mallModel = null;

        return;
      }

      this.mallModel = this.flux.get(MallModel, this.identity);
      this.mallModel.store.listen(this.onMallStateChanged);
    }
  };

  onMallStateChanged = state => {
    if (state.lastPurchaseError) {
      this.trackLastPurchaseError(state.lastPurchaseError);
    }

    if (state.lastPurchase) {
      this.trackLastPurchase(state.lastPurchase);
    }

    if (state.lastRestoredPurchases?.length) {
      state.lastRestoredPurchases.forEach(this.trackLastPurchase);
    }
  };

  trackLastPurchase = lastPurchase => {
    if (
      lastPurchase?.purchaseId &&
      !this.purchasesIds.includes(lastPurchase.purchaseId)
    ) {
      this.purchasesIds.push(lastPurchase.purchaseId);
      this.trackPurchase(lastPurchase);
    }
  };

  trackLastPurchaseError = error => {
    if (error && error !== this.lastPurchaseError) {
      this.lastPurchaseError = error;
      this.trackPurchaseErrorToAnnals(error);
    }
  };

  trackPurchase = async purchase => {
    const encodedUsersWithPurchases = await UserDefaults.get(
      USERS_WITH_PURCHASES_PREFERENCES_KEY,
    );
    const usersWithPurchases =
      (encodedUsersWithPurchases && JSON.parse(encodedUsersWithPurchases)) ||
      [];

    if (usersWithPurchases.indexOf(this.identity) < 0) {
      usersWithPurchases.push(this.identity);
      await UserDefaults.set(
        USERS_WITH_PURCHASES_PREFERENCES_KEY,
        JSON.stringify(usersWithPurchases),
      );

      this.trackAfPurchaseEvent('First Purchase', purchase);
    }

    if (!purchase.product.meta || !purchase.product.meta.Subscription) {
      this.trackAfPurchaseEvent('Credit Purchase', purchase);
    }

    this.trackAfPurchaseEvent('af_purchase', purchase);

    const encodedPurchasesCount = await UserDefaults.get(PURCHASES_COUNT_KEY);
    const purchasesCount =
      (encodedPurchasesCount && JSON.parse(encodedPurchasesCount)) || {};
    const numberOfPurchases = purchasesCount[this.identity] || 0;

    purchasesCount[this.identity] = numberOfPurchases + 1;
    await UserDefaults.set(PURCHASES_COUNT_KEY, JSON.stringify(purchasesCount));

    this.trackFirebasePurchaseEvent(
      'ecommerce_purchase',
      purchase,
      numberOfPurchases,
    );
    this.trackPurchaseToAnnals(purchase);
    this.trackPurchaseToGA(purchase, numberOfPurchases);
  };

  trackAfPurchaseEvent = (eventName, purchase) => {
    const isSubscription =
      purchase.product.meta && purchase.product.meta.Subscription;

    AppsFlyerTracker.trackEvent(
      eventName,
      {
        af_revenue: purchase.product.price,
        af_currency: purchase.product.currency,
        af_content_id: purchase.product.sku,
        af_quantity: isSubscription ? 1 : purchase.product.amount || 0,
        af_content_type: isSubscription
          ? 'CNProductManagerMembership'
          : 'CNProductManagerCredits',
      },
      () => {},
      () => {},
    );
  };

  trackFirebasePurchaseEvent = (eventName, purchase, numberOfPurchases) => {
    const isSubscription =
      purchase.product.meta && purchase.product.meta.Subscription;

    const reason = getReason(purchase);

    createFirebaseTracker().logEvent(eventName, {
      value: purchase.product.price,
      currency: purchase.product.currency,
      transaction_id: purchase.purchaseDetails?.transactionId,
      SOURCE_FROM: reason,
      PAYMENT_TYPE: isSubscription ? 'MEMBERSHIP' : 'CREDIT',
      CREDITS: purchase.product.amount || 0,
      PAYMENT_NUMBER: numberOfPurchases,
      RECURRENT_PAYMENT: isSubscription ? 'YES' : 'NO',
      PACKAGE: purchase.product.sku,
      PAYMENT_SYSTEM: 'INAPP',
    });
  };

  trackPurchaseToAnnals = purchase => {
    const isSubscription =
      purchase.product.meta && purchase.product.meta.Subscription;

    const reason = getReason(purchase);

    flux.api.annals.add(this.identity, 'purchase-completed', {
      time: new Date().getTime(),
      reason,
      price: purchase.product.price,
      paymentType: isSubscription ? 'MEMBERSHIP' : 'CREDIT',
      currency: purchase.product.currency,
      credits: purchase.product.amount || 0,
      PACKAGE: purchase.product.sku,
      transaction_id: purchase.purchaseDetails?.transactionId,
    });
  };

  trackPurchaseToGA = (purchase, numberOfPurchases) => {
    const isSubscription =
      purchase.product.meta && purchase.product.meta.Subscription;

    GATracker.trackEvent({
      purchaseCounter: numberOfPurchases,
      event: 'event_transaction_changeDL',
      ecommerce: {
        currencyCode: purchase.product.currency,
        purchase: {
          actionField: {
            id: purchase.purchaseDetails?.transactionId,
            revenue: purchase.product.price,
          },
          products: [
            {
              category: isSubscription ? 'MEMBERSHIP' : 'CREDIT', // "subscription/diamonds"
              id: purchase.product.sku,
              name: purchase.product.sku,
              price: purchase.product.price,
              quantity: 1,
              variant: isSubscription ? 'Recurring' : 'Non-recuring',
            },
          ],
        },
      },
    });
  };

  trackPurchaseErrorToAnnals = error => {
    flux.api.annals.add(this.identity, 'purchase-error', {
      errorMessage: error.errorMessage,
      step: error.step,
      transactionId: error.purchaseDetails?.transactionId,
      sku: error.product?.sku,
    });
  };
}
