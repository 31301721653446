import shuffleArray from 'dating-mobile/src/utils/shuffle-array';

function formatQuery(fallbackParams, params) {
    const query = Object.assign({}, fallbackParams, params);

    if (!query.gender) {
        delete query.gender;
        delete query['preferences.gender'];
    }

    return query;
}

function searchPromisified(flux, query) {
    return new Promise((resolve, reject) => {
        flux.api.users.search(query, (error, response) => {
            if (error) {
                reject(error);
            } else {
                resolve(response);
            }
        });
    });
}

function createFeedActions(id) {
    parseId(id);

    class FeedActions {
        static displayName = createFeedActions.getDisplayName(id);

        search = (select = 20, reload) => async (dispatch, flux) => {

            const state = flux.getStore(FeedActions.displayName).getState();
            const params = { ...state.params, select: select, omit: reload ? 0 : state.omit };
            const revision = state.revision;

            flux.api.users.search(formatQuery(state.fallbackParams, params), (error, response) => {
                if (error) {
                    return dispatch(null, error);
                }

                dispatch({ omit: params.omit, select: params.select, revision: revision, users: response || [] });
            });
            
        };

        searchWithCustomDistribution = (select = 20, groups = [], reload) => async (dispatch, flux) => {
            try {
                if (!groups || !groups.length) {
                    return this.search(select, reload);
                }

                const state = flux.getStore(FeedActions.displayName).getState();

                const paramsGroups = groups.map(({ percentage, params }, index) => ({
                    ...state.params,
                    ...params,
                    select: Math.round(select * percentage),
                    omit: (!reload && state.omitGroups[index]) || 0,
                }));

                const usersGroups = await Promise.all(paramsGroups.map(params =>
                    searchPromisified(flux, formatQuery(state.fallbackParams, params))));

                // Увеличиваем энтропию, чтобы юзеры с
                // одинаковым критерием выборки не шли друг за другом
                const users = shuffleArray(usersGroups.reduce((acc, group) => [...acc, ...group], []));

                dispatch({
                    users,
                    omit: paramsGroups.map(({ omit }) => omit),
                    select: paramsGroups.map(({ select }) => select),
                    usersLengths: usersGroups.map(group => group.length),
                    revision: state.revision,
                });
            } catch (error) {
                dispatch(null, error);
            }
        };

        setParams = (params, fallbackParams) => (dispatch) => {
            dispatch({
                params: params,
                fallbackParams: fallbackParams
            });
        };
    }

    return FeedActions
}

createFeedActions.getDisplayName = function(id) {
    return `feed.${id}`;
};

export default createFeedActions;

export function getId(userId, modifier) {
    return `${userId}:${modifier}`;
}

export function parseId(id) {
    if (typeof id !== 'string') {
        throw new Error('UserSearchModel should have string value as id')
    }
    let keys = id.split(':');
    if (keys.length === 1) {
        return {
            userId: keys[0],
            modifier: 'default'
        }
    } else if (keys.length === 2) {
        return {
            userId: keys[0],
            modifier: keys[1]
        }
    } else {
        throw new Error('UserSearchModel should have id in format "{userId}:{modifier}" or "{userID}"');
    }
}
