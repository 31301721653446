import React from 'react';
import PropTypes from 'prop-types';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import { SwipeListView } from 'react-native-swipe-list-view';
import LetterItemView from './item';
import styles from './styles';
import Resources from 'dating-mobile/src/resources';
import BlockUserView from '../block-user';
import testId from 'dating-mobile/src/utils/test-id';

export default class LettersList extends React.Component {
    static displayName = 'inbox.views.letters-list.view';
    static propTypes = {
        HeaderComponent: PropTypes.func,
        FooterComponent: PropTypes.func,
        letters: PropTypes.array,
        useDynamicLoading: PropTypes.bool,
        totalCount: PropTypes.number,
        loadMoreButtonTitleFormat: PropTypes.string,
        endReached: PropTypes.bool,
        onLoadMoreRequest: PropTypes.func,
        onLetterSelect: PropTypes.func,
        onEmptyList: PropTypes.func,
        onScrollEnabled: PropTypes.func,
        onLoadMoreClicked: PropTypes.func
    };

    extraDataRevision = 0;

    constructor(props, context) {
        super(props, context);

        props.onScrollEnabled && props.onScrollEnabled(true);
        this.state = {
            scroll: true,
        };
    }

    shouldComponentUpdate(nextProps) {
        return this.shouldListUpdate(nextProps) || this.shouldFooterUpdate(nextProps);
    }

    componentWillReceiveProps(nextProps) {
        if (this.shouldFooterUpdate(nextProps)) {
            this.extraDataRevision++;
        }
    }

    shouldListUpdate(nextProps) {
        return this.props.letters !== nextProps.letters;
    }

    shouldFooterUpdate(nextProps) {
        return this.props.letters !== nextProps.letters ||
            this.props.useDynamicLoading !== nextProps.useDynamicLoading ||
            this.props.totalCount !== nextProps.totalCount ||
            this.props.loadMoreButtonTitleFormat !== nextProps.loadMoreButtonTitleFormat ||
            this.props.endReached !== nextProps.endReached;
    }

    renderLetter = ({ item }) => {
        return (
            <LetterItemView
                key={String(item.id)}
                userId={this.props.userId}
                senderId={item.sender}
                data={item}
                onSelect={this.onLetterSelected}
            />
        );
    };

    onLetterSelected = (letter) => {
        this.props.onLetterSelect && this.props.onLetterSelect(letter);
    };

    renderBlock = ({ item }) => {
        return this.props.userId !== item.sender
            ? (
                <View style={styles.listActionsContainer}>
                    <BlockUserView userId={this.props.userId} attendeeId={item.sender}/>
                </View>
            )
            : null;
    };

    loadMore = () => {
        this.props.onLoadMoreRequest && this.props.onLoadMoreRequest();
        this.props.onLoadMoreClicked && this.props.onLoadMoreClicked();
    };

    renderFooter = (props) => {
        const moreCount = typeof this.props.totalCount !== 'undefined'
            ? this.props.totalCount - (this.props.letters || []).length : 0;
        if (this.props.useDynamicLoading || this.props.endReached || (this.props.totalCount === 0 && moreCount === 0)) {
            return null;
        }

        const title = Resources.strings.formatString(this.props.loadMoreButtonTitleFormat, moreCount);

        const FooterComponent = this.props.FooterComponent;

        return (
            <View style={styles.footerContainer}>
                <TouchableOpacity style={styles.loadMoreButton} onPress={this.loadMore}>
                    <View style={styles.loadMoreButtonContent}>
                        <Text style={styles.loadMoreButtonTitle}>{title}</Text>
                        <Image style={styles.loadMoreButtonIcon} source={Resources.images.navigationArrowIcon()}/>
                    </View>
                </TouchableOpacity>
                {
                    !!FooterComponent && (<FooterComponent {...props} />)
                }
            </View>
        );
    };

    updateScrollEnabled = (enabled) => {
        this.props.onScrollEnabled && this.props.onScrollEnabled(enabled);
        this.setState({ scroll: enabled });
    };

    render() {

        const isListEmpty = !this.props.letters || !this.props.letters.length;

        this.props.onEmptyList && this.props.onEmptyList(isListEmpty);

        return (!isListEmpty &&
            <SwipeListView
                useFlatList={true}
                data={this.props.letters || []}
                keyExtractor={item => String(item.id)}
                ListHeaderComponent={this.props.HeaderComponent}
                ListFooterComponent={this.renderFooter}
                style={styles.list}
                renderItem={this.renderLetter}
                renderHiddenItem={this.renderBlock}
                disableRightSwipe={true}
                extraData={this.extraDataRevision}
                onEndReachedThreshold={this.props.useDynamicLoading ? 1 : undefined}
                onEndReached={this.props.useDynamicLoading ? this.loadMore : undefined}
                rightOpenValue={-88}
                scrollEnabled={this.state.scroll}
                onRowOpen={() => this.updateScrollEnabled(false)}
                onRowDidClose={() => this.updateScrollEnabled(true)}
                removeClippedSubviews={false}
                swipeToOpenPercent={10}
                closeOnRowBeginSwipe={true}
                directionalDistanceChangeThreshold={10}
                {...testId(Resources.strings['letters-list-accessibility-label'])}
            />
        );
    }
};
