import { useEffect, useState } from 'react';
import { GeoLocation } from '@sdv/domain/user/types';
import { UserGeoAliasRepository } from '@sdv/domain/user.aliases/user-geo-alias-repository';

type LocatioAliasHookData = {
    loading: boolean;
    locationAlias: GeoLocation | null;
};

export const useLocationAliasData = (
    userId: string,
    seeingUserId: string,
): LocatioAliasHookData => {
    const [loading, setLoading] = useState<boolean>(true);
    const [locationAlias, setLocationAlias] = useState<GeoLocation | null>(null);

    useEffect(() => {
        setLoading(true);
        const userGeoAliasRepository = UserGeoAliasRepository.shared(userId);

        const subscription = userGeoAliasRepository.aliases.subscribe(
            aliases => {
                setLocationAlias(aliases ? aliases[seeingUserId] : null);
                setLoading(false);
            },
            () => {
                setLoading(false);
            },
            () => {
                setLoading(false);
            },
        );

        return () => {
            subscription.unsubscribe();
        };
    }, [userId, seeingUserId]);

    return {
        loading,
        locationAlias,
    };
};
