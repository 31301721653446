import { singleton } from '@sdv/commons/utils/singleton';

export class Places {
  static shared = singleton(() => new Places());

  constructor() {
    this.places = new window.google.maps.places.AutocompleteService();
    this.geocoder = new window.google.maps.Geocoder();
  }

  getPredictions(data) {
    return new Promise(res => {
      function onLoad(predictions) {
        res({ predictions });
      }

      this.places.getPlacePredictions(data, onLoad);
    });
  }

  getGeocode(data) {
    return new Promise(res => {
      function onLoad(results) {
        res({ results });
      }

      this.geocoder.geocode(data, onLoad);
    });
  }
}
