/**
 * ThreeDsData data
 * @typedef {
 * {
 *  CorrelationId: string,
 *  ACSUrl: string,
 *  PaReq: string,
 *  Md: string
 * }
 * } ThreeDsData
 */

/**
 *
 * @param url {string}
 * @param threeDsData {{ CorrelationId:string }}
 * @return {{string?}}
 */
export function createThreeDsReceiptFromUrl(url, threeDsData) {
  const resultMarker = '/people/#3ds=';

  if (url && url.indexOf(resultMarker) >= 0) {
    const threeDsResultString = decodeURIComponent(
      url.substring(url.indexOf(resultMarker) + resultMarker.length),
    );
    const threeDsReceipt = `<${threeDsData.CorrelationId}<3ds<${threeDsResultString}`;

    return threeDsReceipt;
  }

  return undefined;
}

/**
 * @param receipt {{ receipt:string, type:string }}
 * @return {ThreeDsData}
 */
export function getThreeDsData(receipt) {
  if (!receipt.receipt || receipt.type !== '3ds') {
    return undefined;
  }

  return parseThreeDsData(receipt.receipt);
}

/**
 * parse format: "<CorrelationId<3ds<acsUrl<paReq<md"
 * @param receiptString: string
 * @return {ThreeDsData}
 */
export function parseThreeDsData(receiptString) {
  const parts = receiptString.split('<');

  if (parts.length >= 6 && parts[2] === '3ds') {
    const threeDsData = {
      CorrelationId: parts[1],
      ACSUrl: parts[3],
      PaReq: parts[4],
      Md: parts[5],
    };

    return threeDsData;
  }

  return undefined;
}

/**
 *
 * @param threeDsData {ThreeDsData}
 * @param threeDsRedirectHost {string}
 * @return {string}
 */
export function prepareThreeDsHtml(threeDsData, threeDsRedirectHost) {
  return html
    .replace('{ACSUrl}', threeDsData.ACSUrl)
    .replace('{PaReq}', threeDsData.PaReq)
    .replace('{WebSiteTermAddress}', threeDsRedirectHost)
    .replace('{Md}', threeDsData.Md);
}

const html = `
<html>
    <header></header>
    <body>
        <form name="payment" method='post' action='{ACSUrl}'>
            <input type='hidden' name='PaReq' value='{PaReq}'/>
            <input type='hidden' name='TermUrl' value='{WebSiteTermAddress}'/>
            <input type='hidden' name='MD' value='{Md}'/>
            <input type='submit' value='Submit'/>
        </form>
        <script>document.payment.submit();</script>
    </body>
</html>
`;
