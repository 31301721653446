function createInvitationActions(userId) {

    class Actions {

        get = () => (dispatch, flux) => {

            flux.api.dialogs.automation.productions.obsolete.get(userId, (error, production) => {
                if (error) {
                    dispatch(null, error);
                    return;
                }
                dispatch(production || {});
            });

        };

        /**
         *
         * @param invitation
         * {
         *     text: string
         * }
         * or
         * {
         *     texts: {
         *         'moods/bored': string,
         *         'moods/flirty': string,
         *         'moods/friendly': string,
         *         'moods/naughty': string,
         *         'moods/romantic': string,
         *         'moods/unknown': string
         *     }
         * }
         * @returns {void}
         */
        put = (invitation) => (dispatch, flux) => {

            flux.api.dialogs.automation.productions.obsolete.put(userId, invitation, (error) => {
                if (error) {
                    dispatch(null, error);
                    return;
                }

                dispatch(invitation);
            });

        };

        delete = () => (dispatch, flux) => {

            flux.api.dialogs.automation.productions.obsolete.delete(userId, (error, production) => {
                if (error) {
                    dispatch(null, error);
                    return;
                }
                dispatch({});
            });

        };

        actualize = (changes) => (dispatch) => {
            dispatch(changes);
        };

    }

    Actions.displayName = createInvitationActions.getDisplayName(userId);

    return Actions;

}

createInvitationActions.getDisplayName = function (id) {
    return `dialogs-automation-productions-invitation.${id}`;
};

export default createInvitationActions;

