import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    header: {
        '@flavor secretly': {
            backgroundColor: '$primaryBackgroundColor',
            borderBottomColor: '$primaryBackgroundColor',
            shadowColor: '$primaryBackgroundColor',
        },
    },

    headerTitle: {
        '@flavor secretly': {
            color: '$primaryTextColor',
        },
    },

    container: {
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '$brandColor',
        '@flavor secretly': {
            backgroundColor: '$primaryBackgroundColor',
        },
    },

    item: {
        color: '$brandTextColor',
        '@flavor secretly': {
            color: '$primaryTextColor',
        },
    },
});

export default styles;
