import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Purchase } from 'dating-mobile/src/payment/methods/purchase';
import { PURCHASE } from 'dating-mobile/src/routing/router/constants';

import extendBannerIconFirst from '../assets/small_banner_icon_first.png';
import extendBannerIconSecond from '../assets/small_banner_icon_second.png';
import extendBannerIconThird from '../assets/small_banner_icon_third.png';

// TODO: replace with more reliable method
const getImageByUserId = id => {
  const modulo = id && Number.parseInt(id.slice(0, -2), 10) % 3;

  if (modulo === 2) {
    return extendBannerIconFirst;
  }

  if (modulo === 1) {
    return extendBannerIconSecond;
  }

  return extendBannerIconThird;
};

export default function createController(Component) {
  return class Controller extends PureComponent {
    static displayName =
      'components.purchase-promotion-banner.boost-extend-man-small.controller';

    static propTypes = {
      userId: PropTypes.string.isRequired,
      placeholder: PropTypes.object,
      navigation: PropTypes.object,
      style: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.object,
        PropTypes.array,
      ]),
      invoice: PropTypes.object,
      endTime: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.number,
      ]),
      isBoostPurchased: PropTypes.bool,
      hidden: PropTypes.bool,
    };

    state = {
      isLoading: false,
    };

    boost = () => {
      const { userId, navigation, invoice, isBoostPurchased } = this.props;
      const { isLoading } = this.state;

      if (!isBoostPurchased) {
        navigation.navigate(PURCHASE);
      } else {
        if (isLoading) {
          return;
        }

        this.setState({
          isLoading: true,
        });

        Purchase.shared()
          .pay(userId, invoice)
          .subscribe(
            () => {
              this.setState({
                isLoading: false,
              });
            },
            () => {
              this.setState({
                isLoading: false,
              });
            },
          );
      }
    };

    render() {
      const {
        style,
        placeholder,
        endTime,
        invoice,
        hidden,
        userId,
      } = this.props;

      return (
        <Component
          style={style}
          placeholder={placeholder}
          image={getImageByUserId(userId)}
          onPress={this.boost}
          endTime={invoice ? endTime : null}
          invoice={invoice}
          hidden={hidden}
        />
      );
    }
  };
}
