import React from 'react';
import PropTypes from 'prop-types';
import { View, Image, Text } from 'react-native';
import styles from './styles';
import Resources from '../../../../../resources';
import withController from './controller';
import withDictValues from 'dating-mobile/src/models/misc-data/controller';
import withConfigValue from 'dating-mobile/src/components/config-value';

class LookingForDescription extends React.PureComponent {
    static displayName = 'user.profile.views.profile-description.looking-for-description.view';
    static propTypes = {
        ownerId: PropTypes.string,
        preference: PropTypes.object,
        minAge: PropTypes.number,
        maxAge: PropTypes.number
    };

    render() {
        const gender = this.props.preference && this.props.preference['preferred-gender'];
        const genderText = Resources.strings.genderName(gender) || Resources.strings["profile-screen-looking-for-both"];

        const minAge = (this.props.preference && this.props.preference.minage) || this.props.minAge;
        const maxAge = (this.props.preference && this.props.preference.maxage) || this.props.maxAge;

        const displayText = Number(maxAge) === this.props.maxAge
            ? Resources.strings.formatString(Resources.strings["profile-screen-looking-for-view-description-max-age-pref-in-limitation-format"], genderText, minAge)
            : Resources.strings.formatString(Resources.strings["profile-screen-looking-for-view-description-format"], genderText, minAge, maxAge);

        const capitalizedText = displayText.charAt(0).toUpperCase() + displayText.substr(1);

        const about = this.props.preference && this.props.preference.about;

        if (styles.$simplifiedView) {
            const translationString = Resources.strings.genderName(gender)
                ? maxAge === this.props.maxAge ? 'profile-screen-looking-for-view-description-max-age-pref-in-limitation-simplified' : 'profile-screen-looking-for-view-description-format-simplified'
                : maxAge === this.props.maxAge ? 'profile-screen-looking-for-view-description-max-age-pref-in-limitation-simplified-both-gender' : 'profile-screen-looking-for-view-description-format-simplified-both-gender';
            const text = Resources.strings.formatString(Resources.strings[translationString], genderText.toLowerCase(), minAge, maxAge);

            return (
                <Text style={styles.textSimplified}>{text}</Text>
            );
        }

        return this.props.preference
            ? (
                <View style={styles.container}>
                    <View style={styles.item}>
                        <Image source={Resources.images.profileLookingForIcon()} style={styles.icon}/>
                        <Text style={styles.text}>{capitalizedText}</Text>
                    </View>
                    {
                        !!about && (
                            <Text style={styles.about}>{about}</Text>
                        )
                    }
                </View>

            )
            : null;
    }

}

export default withController(
    withDictValues(
        withConfigValue(
            LookingForDescription,
            {
                minAge: 'age-range.min-age',
                maxAge: 'age-range.max-age'
            }
        )
    )
)
;
