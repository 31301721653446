import React from 'react';
import PropTypes from 'prop-types';
import { CHAT } from 'dating-mobile/src/routing/router/constants';

export default function createController(Component) {
  class Controller extends React.PureComponent {
    static displayName = 'dialogs.messages.views.chat-button.controller';

    static propTypes = {
      navigation: PropTypes.object,
      id: PropTypes.string,
      viewStyle: PropTypes.string,
      onPress: PropTypes.func,
    };

    onPress = () => {
      const { navigation, id } = this.props;

      navigation.navigate(CHAT, {
        id: id || navigation.getParam('id'),
      });
    };

    render() {
      return <Component {...this.props} onPress={this.onPress} />;
    }
  }

  return Controller;
}
