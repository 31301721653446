import React from 'react';
import PropTypes from 'prop-types';
import { View, Picker, Platform } from 'react-native';
import styles from './styles';
import Resources from '../../../../resources';

import InputRow from '../../../../components/input-row';
import SafePicker from '../../../../components/safe-picker';
import RangePicker from '../../../../components/range-picker';
import Error from "../../../../components/validation-error";
import withConfigValue from 'dating-mobile/src/components/config-value';

const GENDER_KEY = 'preferred-gender';

class PreferenceEdit extends React.Component {
    static displayName = 'user.preference.form';
    static propTypes = {
        preference: PropTypes.object,
        onUpdatePreferences: PropTypes.func,
        updatePreference: PropTypes.func,
        emptyPreferredGenderEnabled: PropTypes.bool,
        allowGenderPicking: PropTypes.bool,
        allowAgeRangePicking: PropTypes.bool,
        defaultMinAge: PropTypes.number,
        defaultMaxAge: PropTypes.number,
        allowedMaxAge: PropTypes.number,
        allowedMinAge: PropTypes.number
    };

    state = {
        minage: null,
        maxage: null,
        [GENDER_KEY]: null
    };

    componentDidMount() {
        if (!this.props.allowAgeRangePicking) {
            const ages = this.getMinMaxAge();
            this.setState(ages, this.update);
        }
    }

    handlerAge = (age) => {
        const range = Object.keys(age).reduce((range, key) => {
            range[`${key}age`] = `${age[key]}`;
            return range;
        }, {});

        this.setState(range, this.update);
    };

    handlerPreference = (value) => {
        if (value === 'placeholder') {
            return;
        }

        this.setState({
            [GENDER_KEY]: value
        }, this.update);
    };

    update = async () => {
        const newPreferences = Object.keys(this.state).reduce((state, key) => {
            if (this.state[key]) {
                if (key === GENDER_KEY && this.state[key] === 'both') {
                    state[key] = null;
                } else {
                    state[key] = this.state[key];
                }
            }
            return state;
        }, {});

        if (this.props.onUpdatePreferences) {
            this.props.onUpdatePreferences(PreferenceEdit.displayName, newPreferences);
        }

        if (!this.props.updatePreference) {
            return;
        }

        try {
            await this.props.updatePreference(newPreferences);
        } catch (error) {
            this.setState({ error: error.message });
        }
    };

    getMinMaxAge() {
        let minage,
            maxage;

        if (this.state.minage || this.state.maxage) {
            minage = this.state.minage;
            maxage = this.state.maxage;
        } else if (this.props.preference) {
            minage = this.props.preference.minage;
            maxage = this.props.preference.maxage;
        }

        return {
            minage: minage || this.props.defaultMinAge,
            maxage: maxage || this.props.defaultMaxAge
        }
    }

    onClose = () => {
        if (!this.state[GENDER_KEY]) {
            this.setState({ [GENDER_KEY]: 'mal' }, () => {
                if (this.props.onUpdatePreferences) {
                    this.props.onUpdatePreferences(PreferenceEdit.displayName, { [GENDER_KEY]: 'mal' });
                }
            })
        }
    };

    render() {
        const { preference } = this.props;
        const { minage, maxage } = this.getMinMaxAge();

        const gender = this.state[GENDER_KEY] ||
            (preference || {})[GENDER_KEY] ||
            (this.props.emptyPreferredGenderEnabled ? 'both' : null);

        const title = !gender
            ? 'Gender'
            : (gender === 'both'
                ? Resources.strings['preferred-gender-empty-value-name']
                : (gender === 'mal' ? 'Man' : 'Woman'));

        const pickerStyle = this.props.error ? styles.pickerWithError : {};

        const pickerItems = [
            (<Picker.Item key={'preferred-gender-mal'} label={Resources.strings.man} value='mal'/>),
            (<Picker.Item key={'preferred-gender-fem'} label={Resources.strings.woman} value='fem'/>)
        ];
        if (this.props.emptyPreferredGenderEnabled) {
            pickerItems.unshift(
                <Picker.Item
                    key={'preferred-gender-both'}
                    label={Resources.strings['preferred-gender-empty-value-name']}
                    value={'both'}
                />
            );
        } else if (Platform.OS !== 'ios') {
            pickerItems.unshift(
                <Picker.Item
                    key={'preferred-gender-placeholder'}
                    label={Resources.strings.gender}
                    value={'placeholder'}
                />
            );
        }

        return (
            <View style={styles.container}>
                {
                    !!this.props.allowGenderPicking && (
                        <InputRow
                            label={Resources.strings['about-yourself-screen-preferred-gender-item-title']}
                            separator={true}
                        >
                            <SafePicker
                                style={pickerStyle}
                                titleStyle={styles.genderPickerTitle}
                                title={title}
                                onDone={this.onClose}
                                isPlaceholder={!gender}
                            >
                                <Picker
                                    selectedValue={gender}
                                    onValueChange={this.handlerPreference}
                                    style={styles.gender}
                                >
                                    {pickerItems}
                                </Picker>
                            </SafePicker>
                            <Error error={this.props.error}/>
                        </InputRow>
                    )
                }
                {
                    !!this.props.allowAgeRangePicking && (
                        <InputRow label={Resources.strings['about-yourself-screen-between-ages-item-title']} separator={true}>
                            <SafePicker title={Resources.strings.formatString(Resources.strings['about-yourself-screen-between-ages-item-value-format'], minage, maxage)}>
                                <RangePicker
                                    range={{
                                        min: this.props.allowedMinAge,
                                        max: this.props.allowedMaxAge
                                    }}
                                    placeholder={{from: 'From', to: 'To'}}
                                    selectedMin={minage}
                                    selectedMax={maxage}
                                    onUpdateRange={this.handlerAge}
                                />
                            </SafePicker>
                        </InputRow>
                    )
                }
            </View>
        );
    }
}

export default withConfigValue(
    PreferenceEdit,
    {
        allowedMinAge: 'age-range.min-age',
        allowedMaxAge: 'age-range.max-age'
    }
)
