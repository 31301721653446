import React from 'react';
import { View, Text } from 'react-native';
import PropTypes from 'prop-types';

import testId from 'dating-mobile/src/utils/test-id';
import Resources from 'dating-mobile/src/resources';

import Button from '../../../components/buttons/base';
import TextInput from '../../../components/text-input';
import Error from '../../../components/validation-error';

import styles from './styles';

class PasswordRecover extends React.Component {
  static displayName = 'authentication.password.recover.form';

  static propTypes = {
    error: PropTypes.object,
    email: PropTypes.string,
    password: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
    handlerChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.handleChange = {};
    this.validate = {};

    ['email'].forEach(name => {
      this.validate[name] = props.validate(name);
      this.handleChange[name] = props.handlerChange(name);
    });
  }

  shouldComponentUpdate(props) {
    return ['email', 'error'].some(key => this.isUpdated(props, key));
  }

  isUpdated(props, name) {
    return props[name] !== this.props[name];
  }

  isEmpty() {
    return ['email'].some(key => {
      return !this.props[key] || !this.props[key].length;
    });
  }

  render() {
    const {
      error: { email, server },
      ...props
    } = this.props;

    return (
      <View style={styles.container}>
        <View style={styles.inputs}>
          <Text
            style={styles.title}
            {...testId('Password recover screen title')}
          >
            {Resources.strings['password-recover-title']}
          </Text>
          <View style={styles.inputContainer}>
            <TextInput
              style={styles.input}
              placeholder={
                Resources.strings[
                  'password-recover-screen-email-input-placeholder'
                ]
              }
              value={props.email}
              autoCapitalize="none"
              keyboardType="email-address"
              onChange={this.handleChange.email}
              onBlur={this.validate.email}
              {...testId('Email address input')}
            />
            <Error error={email || server} />
          </View>
          {styles.$showHint && (
            <Text style={styles.hint}>
              {Resources.strings['password-recover-screen-reset-hint']}
            </Text>
          )}
        </View>
        <Button
          disabled={this.isEmpty()}
          title={Resources.strings['password-recover-screen-reset-button']}
          onPress={props.onSubmit}
          {...testId('Reset password button')}
        />
      </View>
    );
  }
}

export default PasswordRecover;
