import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        width: 40,
        height: 40,
        margin: 10
    },

    smile: {
        width: '100%',
        height: '100%'
    }
});

export default styles;
