import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'stretch',
        flexWrap: 'wrap'
    }
});

export default styles;
