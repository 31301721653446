import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { View, Text, TouchableOpacity, Image } from 'react-native';

import { GENDER } from '@sdv/domain/user/gender';
import Resources from 'dating-mobile/src/resources';
import testId from 'dating-mobile/src/utils/test-id';
import Button from 'dating-mobile/src/components/buttons/base';

import styles from './styles';

export default class ScreenView extends PureComponent {
    static displayName = 'stepped-registration.screens.gender.view';

    static propTypes = {
        onSubmit: PropTypes.func,
    };

    state = {};

    constructor(props) {
        super(props);

        this.onMaleSelect = this.onGenderSelect.bind(this, GENDER.male);
        this.onFemaleSelect = this.onGenderSelect.bind(this, GENDER.female);
    }

    onGenderSelect(gender) {
        this.setState({ gender });
    }

    onSubmit = () => {
        const { onSubmit } = this.props;
        const { gender } = this.state;

        if (onSubmit) {
            onSubmit(gender);
        }
    };

    render() {
        const { gender } = this.state;

        const maleImageStyle =
            gender === GENDER.male ? styles.selectedImage : styles.unselectedImage;
        const femaleImageStyle =
            gender === GENDER.female ? styles.selectedImage : styles.unselectedImage;

        return (
            <View style={styles.container}>
                <View>
                    <Text style={styles.subTitle}>
                        {Resources.strings['onboarding-screen-title']}
                    </Text>
                    <Text style={styles.title}>
                        {Resources.strings['onboarding-screen-gender-title']}
                    </Text>
                    <View style={styles.buttonsContainer}>
                        <View style={styles.genderButtonContainer}>
                            <TouchableOpacity
                                style={styles.genderButton}
                                onPress={this.onMaleSelect}
                                {...testId('Male button')}
                            >
                                <Image style={maleImageStyle} source={Resources.images.guyIcon()} />
                            </TouchableOpacity>
                            <Text style={styles.genderTitle}>
                                {Resources.strings['onboarding-screen-gender-male']}
                            </Text>
                        </View>
                        <View style={styles.genderButtonContainer}>
                            <TouchableOpacity
                                style={styles.genderButton}
                                onPress={this.onFemaleSelect}
                                {...testId('Female button')}
                            >
                                <Image
                                    style={femaleImageStyle}
                                    source={Resources.images.girlIcon()}
                                />
                            </TouchableOpacity>
                            <Text style={styles.genderTitle}>
                                {Resources.strings['onboarding-screen-gender-female']}
                            </Text>
                        </View>
                    </View>
                </View>
                <Button
                    disabled={!gender}
                    title={Resources.strings.next}
                    onPress={this.onSubmit}
                    style={styles.nextButton}
                    {...testId('Next button')}
                />
            </View>
        );
    }
}
