import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {TouchableOpacity} from 'react-native';
import Resources from 'dating-mobile/src/resources';
import IconWithText from 'dating-mobile/src/components/icon-with-text';

import styles from './styles';

export class StreamDiamondsView extends Component {
    static displayName = 'dialogs.stream.views.stream-diamonds-view';
    static propTypes = {
        text: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string
        ]),
        onPress: PropTypes.func
    };

    render() {
        const {onPress} = this.props;

        return (
            <TouchableOpacity onPress={onPress}>
                <IconWithText
                    text={this.props.text}
                    icon={Resources.images.diamondStatsIcon()}
                    containerStyle={styles.container}
                />
            </TouchableOpacity>
        );
    }
}
