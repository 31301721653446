function createStreamsActions(id = 'default') {
  class StreamsActions {
    get = () => (dispatch, flux) => {
      flux.streamConnection?.notify('command.dialogs.streams.find');
      flux.streamConnection?.notify('command.dialogs.streams.find.all');
      dispatch(null);
    };

    add = payload => dispatch => {
      dispatch(payload);
    };

    remove = payload => dispatch => {
      dispatch(payload);
    };

    set = streams => dispatch => {
      dispatch(streams);
    };

    setAll = streams => dispatch => {
      dispatch(streams);
    };

    addUnfiltered = payload => dispatch => {
      dispatch(payload);
    };

    update = payload => dispatch => {
      dispatch(payload);
    };
  }

  StreamsActions.displayName = createStreamsActions.getDisplayName(id);

  return StreamsActions;
}

createStreamsActions.getDisplayName = function(id) {
  return `dialogs-streams.${id}`;
};

export default createStreamsActions;
