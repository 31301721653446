import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $listHorizontal: false,

    '@flavor tubit': {
        $listHorizontal: true,
    },

    container: {
        borderRadius: 18,
        overflow: 'hidden',
        marginBottom: 8,
        marginHorizontal: 8,
        marginTop: 16,
        '@flavor tubit': {
            height: 44,
            borderRadius: 0,
        },
    },

    itemContainer: {
        flex: 1,
        '@flavor tubit': {
            flex: undefined,
            justifyContent: 'center',
        },
    },

    list: {
        flex: 1,
    },
});

export default styles;
