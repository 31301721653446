function NoopFunction(argument: any) {
  return argument;
}

export const functionBuildInProperties = Object.getOwnPropertyNames(
  NoopFunction,
);
export const functionPrototypeBuildInProperties = Object.getOwnPropertyNames(
  NoopFunction.prototype,
);
