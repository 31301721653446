import React from 'react';
import PropTypes from 'prop-types';
import { View, FlatList } from 'react-native';
import equal from 'fast-deep-equal';
import MessageItem from './message-item';
import TypeMessageItem from './type-message-item';
import styles from './styles';

export default class MessageSelection extends React.Component {
    static displayName = 'dialogs.mingle.message-selection';

    static propTypes = {
        messages: PropTypes.arrayOf(PropTypes.string),
        onMessageSelect: PropTypes.func,
        hideTypeMessageItem: PropTypes.bool,
        customMessageItemStyles: PropTypes.object,
        customTypeMessageItemStyles: PropTypes.object,
        customStyles: PropTypes.shape({
            header: PropTypes.object,
            footer: PropTypes.object,
            container: PropTypes.object,
        }),
    };

    shouldComponentUpdate(nextProps) {
        return !equal(this.props.messages, nextProps.messages);
    }

    onMessagePressed = message => {
        this.props.onMessageSelect && this.props.onMessageSelect(message);
    };

    onTypeMessagePressed = () => {
        this.props.onMessageSelect && this.props.onMessageSelect(null);
    };

    renderItem = ({ item }) => {
        const { customMessageItemStyles, customTypeMessageItemStyles } = this.props;

        switch (item.type) {
            case 'message':
                return (
                    <MessageItem
                        key={item.key}
                        message={item.message}
                        onPress={this.onMessagePressed}
                        customStyles={customMessageItemStyles}
                    />
                );
            case 'type-message':
                return (
                    <TypeMessageItem
                        customStyles={customTypeMessageItemStyles}
                        key={item.key}
                        onPress={this.onTypeMessagePressed}
                    />
                );
            default:
                return null;
        }
    };

    renderItemSeparator = () => {
        return <View style={styles.itemSeparator} />;
    };

    renderHeader = () => {
        const { customStyles = {} } = this.props;

        return <View style={[styles.header, customStyles.header]} />;
    };

    renderFooter = () => {
        const { customStyles = {} } = this.props;

        return <View style={[styles.footer, customStyles.footer]} />;
    };

    render() {
        const { customStyles = {} } = this.props;
        const messages = (this.props.messages || []).map(message => ({
            type: 'message',
            key: message,
            message,
        }));

        !this.props.hideTypeMessageItem &&
            messages.push({ type: 'type-message', key: 'type-message' });

        return (
            <View style={[styles.container, customStyles.container]}>
                <FlatList
                    style={styles.list}
                    renderItem={this.renderItem}
                    data={messages}
                    horizontal
                    keyExtractor={item => item.key}
                    ItemSeparatorComponent={this.renderItemSeparator}
                    ListHeaderComponent={this.renderHeader}
                    ListFooterComponent={this.renderFooter}
                    showsHorizontalScrollIndicator={false}
                />
            </View>
        );
    }
}
