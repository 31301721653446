import React from 'react';
import Resources from 'dating-mobile/src/resources';
// @ts-ignore
import { CHAT_STACK } from 'dating-mobile/src/routing/router/constants';
import { TopTabBarItem } from './top-tab-bar-item/view';
import styles from './styles';

const withIdentityId = require('dating-mobile/src/models/identity/controller/id')
  .default;
const {
  contacts,
  likes,
} = require('dating-mobile/src/models/user.events/controllers/events');
const { SafeAreaView } = require('react-navigation');

type Props = {
  navigation: {
    state: {
      routes: { key: string; routeName: string; routes: {}[] | undefined }[];
      index: number;
    };
    navigate(routeName: string): void;
  };
  style: any;
  labelStyle: any;
  indicatorStyle: any;
  activeTintColor: string;
  inactiveTintColor: string;
  contacts: { stack: number }[] | undefined;
  likes: { stack: number }[] | undefined;
};

export const TopTabBarView = (props: Props) => {
  const onPressed = (routeName: string) => {
    const { navigation } = props;

    navigation.navigate(routeName);
  };

  const getTabLabel = (routeName: string): string => {
    switch (routeName) {
      case CHAT_STACK:
        return Resources.strings['my-chats-screen-title'];
      default:
        return routeName;
    }
  };

  const getCounter = (routeName: string): number => {
    const { contacts: items = [] } = props;

    switch (routeName) {
      case CHAT_STACK:
        return items.reduce((a, b) => a + b.stack, 0);
      default:
        return 0;
    }
  };

  const {
    navigation,
    style,
    labelStyle,
    activeTintColor,
    inactiveTintColor,
    indicatorStyle,
  } = props;

  const { routes } = navigation.state;
  const visibleStyle = [styles.container, style];
  const activeRoutesInSelectedTab = routes[navigation.state.index].routes;
  const tabBarVisible =
    !activeRoutesInSelectedTab || activeRoutesInSelectedTab.length <= 1;
  // TODO: Reverse-engineering is needed to understand implementation of getLabelText function
  // TODO: Label should be read from outer props. Remove this code and make this component general-purposed

  return (
    <SafeAreaView style={tabBarVisible ? visibleStyle : styles.invisible}>
      {tabBarVisible &&
        routes.map((route, index) => {
          return (
            <TopTabBarItem
              key={route.key}
              routeName={route.routeName}
              labelText={getTabLabel(route.routeName)}
              labelStyle={labelStyle}
              onPress={onPressed}
              inactiveTintColor={inactiveTintColor}
              activeTintColor={activeTintColor}
              indicatorStyle={indicatorStyle}
              isFocused={navigation.state.index === index}
              counter={getCounter(route.routeName)}
            />
          );
        })}
    </SafeAreaView>
  );
};

export const TopTabBar = withIdentityId(contacts(likes(TopTabBarView)));
