import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Image, Text, View } from 'react-native';

import Resources from 'dating-mobile/src/resources';
import Button from 'dating-mobile/src/components/buttons/base';

import styles from './styles';

export default class StartSettingPreferencesView extends PureComponent {
    static displayName = 'stepped-registration.screens.start-setting-preferences.view';

    static propTypes = {
        image: PropTypes.string,
        title: PropTypes.string,
        onButtonPress: PropTypes.func,
    };

    render() {
        const { image, onButtonPress, title } = this.props;

        return (
            <View style={styles.container}>
                <Image source={image} style={styles.image} />
                <View>
                    <Text style={styles.subTitle}>
                        {Resources.strings['onboarding-screen-lets-start-subtitle']}
                    </Text>
                    <Text style={styles.title}>{title}</Text>
                </View>
                <Button
                    style={styles.button}
                    title={Resources.strings['onboarding-screen-lets-start-button-text']}
                    onPress={onButtonPress}
                />
            </View>
        );
    }
}
