import React from 'react';
import PropTypes from 'prop-types';
import { EVENT_TYPE } from '../model';
import events from './events';

const EVENTS_PROPERTY = 'generated.user-events.has-event.events';
const EVENTS_LOADED_PROPERTY = 'generated.user-events.has-event.events-loaded';

export default function createController(Component, options = {}) {

    const type = options.type;
    const userIdPropName = options.userIdPropName || 'userId';
    const attendeeIdPropName = options.attendeeIdPropName || 'attendeeId';
    const resultPropName = options.resultPropName || 'hasEvent';

    class HasEvent extends React.Component {
        static displayName = 'user-events.has-event';
        static propTypes = {
            [userIdPropName]: PropTypes.string,
            [attendeeIdPropName]: PropTypes.string,
            [EVENTS_PROPERTY]: PropTypes.array,
            [EVENTS_LOADED_PROPERTY]: PropTypes.bool
        };

        resolveValue = () => {
            if (!this.props[attendeeIdPropName]) {
                console.warn(`Attendee id is not defined`);
                return undefined;
            }

            if (!this.props[EVENTS_PROPERTY] || !this.props[EVENTS_LOADED_PROPERTY]) {
                return undefined;
            }

            return this.props[EVENTS_PROPERTY].findIndex(user => user['user-id'] === this.props[attendeeIdPropName]) >= 0;
        };

        render() {
            const { ...props } = this.props;

            const value = this.resolveValue();

            delete props[EVENTS_PROPERTY];
            delete props[EVENTS_LOADED_PROPERTY];

            return (<Component {...props} { ... { [resultPropName]: value } } />);
        }

    }

    return events(
        HasEvent,
        {
            type: type,
            userIdPropName: userIdPropName,
            eventsPropName: EVENTS_PROPERTY,
            eventsLoadedPropName: EVENTS_LOADED_PROPERTY
        }
    );

}

export const isContact = (Component, options) =>
    createController(
        Component,
        {
            resultPropName: 'isContact',
            ...(options || {}),
            type: EVENT_TYPE.CONTACT
        }
    );

export const hasInvitation = (Component, options) =>
    createController(
        Component,
        {
            resultPropName: 'hasInvitation',
            ...(options || {}),
            type: EVENT_TYPE.INVITATION
        }
    );
