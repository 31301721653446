import React from 'react';
import { Subscription } from 'rxjs';
import {
    ConnectionState,
    ConnectionMonitoring,
} from 'dating-mobile/src/platform/connection-monitoring';
import withHasTag from 'dating-mobile/src/models/user.tags/controllers/has-tag';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';

const TIMER_TIMEOUT = 10000;

export default function createBadConnectionNotificationController(Component) {
    class BadConnectionNotificationController extends React.Component {
        static displayName = 'components.bad-connection-notification.controller';
        state = {
            networkConnection: ConnectionState.UNKNOWN,
            websocketConnection: ConnectionState.UNKNOWN,
            hiddenManually: false,
        };

        componentDidMount() {
            this.disposeBag = new Subscription();

            this.disposeBag.add(
                ConnectionMonitoring.shared().networkState.subscribe(hasConnection => {
                    this.setState({ networkConnection: hasConnection });
                }),
            );

            this.disposeBag.add(
                ConnectionMonitoring.shared().websocketState.subscribe(hasConnection => {
                    this.setState({ websocketConnection: hasConnection });
                }),
            );

            this.disposeBag.add(
                ConnectionMonitoring.shared().serverConnectionState.subscribe(hasConnection => {
                    this.setState({ serverConnection: hasConnection });
                }),
            );
        }

        clearTimer = () => {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
        };

        componentWillUnmount() {
            this.clearTimer();
            if (this.disposeBag) {
                this.disposeBag.unsubscribe();
            }
        }

        onClose = () => {
            this.setState({ hiddenManually: true });
            this.clearTimer();
            this.timer = setTimeout(() => {
                this.setState({ hiddenManually: false });
            }, TIMER_TIMEOUT);
        };

        render() {
            const {
                networkConnection,
                websocketConnection,
                serverConnection,
                hiddenManually,
            } = this.state;
            const { isPromoter } = this.props;

            const hasError =
                networkConnection.state === ConnectionState.DISCONNECTED ||
                serverConnection === ConnectionState.DISCONNECTED ||
                websocketConnection.state === ConnectionState.DISCONNECTED;

            const badConnectionNotificationVisible = hasError && !hiddenManually && isPromoter;

            return badConnectionNotificationVisible ? (
                <Component
                    {...this.props}
                    networkConnection={networkConnection.state}
                    websocketConnection={websocketConnection.state}
                    serverConnection={serverConnection}
                    onClose={this.onClose}
                />
            ) : null;
        }
    }

    return withIdentityId(
        withHasTag(BadConnectionNotificationController, {
            tag: 'dialogs.messages.promoter',
            userIdPropName: 'userId',
            hasTagPropName: 'isPromoter',
        }),
    );
}
