import React from 'react';
import {View, Image, Text} from 'react-native';
import styles from './styles';
import Resources from 'dating-mobile/src/resources';
import Button from 'dating-mobile/src/components/buttons/base';
import PropTypes from "prop-types";
import {SafeAreaView} from "react-navigation";

export default class ApplicationReviewView extends React.Component {
    static displayName = 'rate-application.screen.view';
    static propTypes = {
        onRateButtonPress: PropTypes.func,
        onLaterButtonPress: PropTypes.func,
    };

    shouldComponentUpdate() {
        return false;
    }

    onRateButtonPress = () => {
        this.props.onRateButtonPress && this.props.onRateButtonPress()
    };

    onLaterButtonPress = () => {
        this.props.onLaterButtonPress && this.props.onLaterButtonPress()
    };

    render() {
        return (
            <View style={styles.container}>
                <Image
                    source={Resources.images.userRequestBackground()}
                    resizeMode={'cover'}
                    style={styles.backgroundImage}
                />
                <SafeAreaView forceInset={{ top: 'never', bottom: 'always' }} style={styles.safeAreaContainer}>
                    <Image source={Resources.images.rateStars()} style={styles.starsImage}/>
                    <Text style={styles.title}>{Resources.strings["rate-app-screen-title"]}</Text>
                    <View style={styles.buttonsContainer}>
                        <Button
                            onPress={this.onRateButtonPress}
                            style={styles.rateButton}
                            title={Resources.strings['rate-app-screen-rate-button']}
                        />
                        <Button
                            onPress={this.onLaterButtonPress}
                            style={styles.laterButton}
                            titleStyle={styles.laterButtonTitle}
                            title={Resources.strings['rate-app-screen-later-button']}
                        />
                    </View>
                </SafeAreaView>
            </View>
        );
    }
}
