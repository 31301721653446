import displayName from './display-name';
import { mapJsonToLetter } from "@sdv/domain/dialogs.mailbox/letter-repo";

function createActions(userId) {
    class Actions {

        getLetters = (select = 20, reload = false) => (dispatch, flux) => {
            const state = flux.getStore(displayName(userId)).getState();

            const lettersLength = state.letters.filter(letter => letter.introductory).length;

            const params = {
                fresh: true,
                order: 'freshed',
                select: select + 1,
                omit: reload ? 0 : lettersLength, ...this.options,
                types: '+introductory'
            };

            flux.api.users.events.get(userId, params, (error, result) => {
                if (error) {
                    return dispatch(null, error);
                }
                const letters = result.map(event => mapJsonToLetter(event.payload));

                return dispatch({
                    endReached: letters.length <= select,
                    letters: letters.slice(0, select)
                });
            });

        };
    }

    Actions.displayName = displayName(userId);

    return Actions;
}

createActions.getDisplayName = displayName;

export default createActions;
