import React from 'react';
import PropTypes from 'prop-types';
import UserModel from '@sdv/domain/user/model';

export default function controller(Component) {
  class Controller extends React.Component {
    static displayName =
      'user.common.views.user-badges.user-vip-badge.controller';

    static propTypes = {
      userId: PropTypes.string.isRequired,
    };

    static contextTypes = {
      flux: PropTypes.object,
    };

    state = {
      userIsVip: false,
      userIsPremiumVip: false,
    };

    componentDidMount() {
      const { userId } = this.props;

      if (userId) {
        this.subscribe();
      }
    }

    componentDidUpdate(prevProps) {
      const { userId } = this.props;

      if (prevProps.userId !== userId) {
        this.unsubscribe();
        this.subscribe();
      }
    }

    componentWillUnmount() {
      this.unsubscribe();
    }

    subscribe() {
      const { userId } = this.props;
      const { flux } = this.context;

      if (userId) {
        this.userSubscription = flux
          .get(UserModel, userId)
          .store.rxState()
          .subscribe(user => {
            this.setState({
              userIsVip: !!user?.vip,
              userIsPremiumVip: !!user?.premiumVip,
            });
          });
      }
    }

    unsubscribe() {
      if (this.userSubscription) {
        this.userSubscription.unsubscribe();
        this.userSubscription = null;
      }
    }

    render() {
      const { userIsVip, userIsPremiumVip } = this.state;

      return (
        <Component
          {...this.props}
          isVisible={userIsVip || userIsPremiumVip}
          isPremium={userIsPremiumVip}
        />
      );
    }
  }

  return Controller;
}
