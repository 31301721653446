import Persistence from '@sdv/domain/app/persistence';
import {shareReplay, tap, switchMap} from 'rxjs/operators';
import { singleton } from '@sdv/commons/utils/singleton';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

export default class FreeCreditsNotification {
    static shared = singleton((userId) => new FreeCreditsNotification(userId));

    constructor(userId) {
        this.persistence = Persistence.shared('freeCreditsBannerNotification', userId);
        this.acknowledged = new BehaviorSubject(null);
        this.notify = this.acknowledged.pipe(
            switchMap(acknowledged => this.persistence.rxValue('notifiedAboutFreeCredits')),
            map(notifiedAboutFreeCredits => {
                const notified = notifiedAboutFreeCredits !== undefined;
                if (!notified) {
                    this.persistence.store('notifiedAboutFreeCredits', 'true');
                }
                return !notified;
            }),
            shareReplay(1)
        )
    }

    acknowledge = () => {
        this.acknowledged.next(null);
    }
}
