import type from './utils/type';

function currencySign(currencyCode) {
  const formattedCode = type.isString(currencyCode)
    ? currencyCode.toUpperCase()
    : currencyCode;

  switch (formattedCode) {
    case 'USD':
      return '$';
    case 'EUR':
      return '€';
    case 'GBP':
      return '£';
    case 'AUD':
      return 'A$';
    case 'SAR':
      return '﷼';
    case 'CREDITS':
      return ''; // We use coin image instead
    default:
      return `${formattedCode} `;
  }
}

export function localizePrice(currencyCode, price) {
  return (
    currencySign(currencyCode) +
    (type.isNumber(currencyCode) ? price.toFixed(2) : price)
  );
}

export function formatPrice(price, forceLocalizePrice) {
  if (!price) {
    return null;
  }

  if (
    forceLocalizePrice &&
    price.localizedCurrency &&
    price.localizedWithoutCurrency
  ) {
    return localizePrice(
      price.localizedCurrency,
      price.localizedWithoutCurrency,
    );
  }

  if (price.localizedIncludingTax !== undefined) {
    return price.localizedIncludingTax;
  }

  return localizePrice(price.currency, price.includingTax);
}
