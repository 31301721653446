import React from 'react';
import PropTypes from 'prop-types';
import UserDefaults from 'react-native-default-preference';

import withConfigValue from 'dating-mobile/src/components/config-value';
import StreamsList from '@sdv/domain/streaming/streams-list';
import UserTags from '@sdv/domain/user/tags';
import { Subscription } from 'rxjs';
import withIdentityId from '../../../models/identity/controller/id';
import withTaggedUsers from '../../../models/users.targeted.tagged/controller/tagged-users';
import { TAGS } from '../../../models/users.targeted.tagged/model';

const STORAGE_FOLLOW_TOP_SHOWN_KEY = 'streams.discovery.follow-top.shown';

function createStreamsDiscoveryController(Component) {
  class Controller extends React.Component {
    static displayName = 'streams.discovery.controller';

    static propTypes = {
      isFocused: PropTypes.bool,
      userId: PropTypes.string,
      streams: PropTypes.array,
      showFollowTop: PropTypes.func,
      favoriteUsers: PropTypes.array,
      favoriteUsersLoaded: PropTypes.bool,
      streamAnounsmentEnabled: PropTypes.bool,
      showTopStreamOnComplete: PropTypes.bool,
      showStream: PropTypes.func,
    };

    tryingShowFollowTop = false;

    followTopShown = false;

    state = {
      popular: [],
      following: [],
      cooperative: [],
      isPromoter: false,
    };

    componentDidMount() {
      const { userId } = this.props;

      this.disposeBag = new Subscription();

      const popularStreams = new StreamsList();

      this.createPromoterSubscription(userId);

      const popularStreamsSubscription = popularStreams.subscription.subscribe(
        state => {
          this.setState({ ...state }, () => {
            this.updateState(this.props);
          });
        },
      );

      this.disposeBag.add(popularStreamsSubscription);
    }

    componentWillReceiveProps(props) {
      this.updateState(props);
    }

    shouldComponentUpdate(nextProps) {
      return nextProps.isFocused;
    }

    componentWillUnmount() {
      this.promoterSubscription?.unsubscribe();
      this.disposeBag?.unsubscribe();
    }

    createPromoterSubscription = nextUserId => {
      this.promoterSubscription?.unsubscribe();

      if (nextUserId) {
        this.promoterSubscription = UserTags.shared()
          .isPromoter(nextUserId)
          .subscribe(promoter => {
            this.setState({
              isPromoter: promoter,
            });
          });
      }
    };

    // TODO: Добавить обновления состояния стримов при появлении нового значения favoriteUsers
    updateState = props => {
      const { userId } = this.props;
      const {
        streams = [],
        cooperativeStreams = [],
        allStreams = [],
      } = this.state;

      if (userId !== props.userId) {
        this.createPromoterSubscription(props.userId);
      }

      const favorite = props.favoriteUsers || [];
      const following = streams.filter(id => favorite.indexOf(id) >= 0);

      const state = {
        popular: this.skipCurrentUserStream(streams),
        allStreams: this.skipCurrentUserStream(allStreams),
        following: this.skipCurrentUserStream(following),
        cooperative: this.skipCurrentUserStream(cooperativeStreams),
      };

      this.setState(state);

      this.tryShowFollowTop(props);
    };

    async tryShowFollowTop(props) {
      const { streams = [] } = this.state;
      const hasStreams = streams.length;

      if (
        !props.isFocused ||
        this.tryingShowFollowTop ||
        this.followTopShown ||
        !props.favoriteUsersLoaded ||
        !hasStreams
      ) {
        return;
      }

      this.tryingShowFollowTop = true;

      if (props.favoriteUsers && props.favoriteUsers.length === 0) {
        try {
          const followTopShownValue = await UserDefaults.get(
            STORAGE_FOLLOW_TOP_SHOWN_KEY,
          );
          const followTopShown =
            (followTopShownValue && JSON.parse(followTopShownValue)) || [];
          const { showFollowTop } = this.props;

          if (!followTopShown.includes(props.userId)) {
            followTopShown.push(props.userId);

            if (showFollowTop) {
              showFollowTop({
                hideBackButton: true,
                onComplete: ([stream]) => {
                  const {
                    streams: [streamFallback],
                  } = this.state;
                  const streamToShow = stream || streamFallback;
                  const { showTopStreamOnComplete, showStream } = this.props;

                  if (showTopStreamOnComplete && streamToShow && showStream) {
                    showStream(streamToShow);
                  }
                },
              });
            }
            await UserDefaults.set(
              STORAGE_FOLLOW_TOP_SHOWN_KEY,
              JSON.stringify(followTopShown),
            );
          }
        } catch (error) {
          // TODO: Handle error
        }
      }

      this.followTopShown = true;
      this.tryingShowFollowTop = false;
    }

    skipCurrentUserStream(streams = []) {
      const { userId } = this.props;

      return streams.filter(stream => stream !== userId);
    }

    render() {
      const { streamAnounsmentEnabled, userId, streams, ...props } = this.props;
      const { isPromoter } = this.state;

      return (
        <Component
          {...props}
          {...this.state}
          streamAnounsmentEnabled={streamAnounsmentEnabled}
          showNewsletterButton={isPromoter && streamAnounsmentEnabled}
        />
      );
    }
  }

  return Controller;
}

export default Component =>
  withIdentityId(
    withTaggedUsers(
      withConfigValue(createStreamsDiscoveryController(Component), {
        showTopStreamOnComplete: 'features.show-top-stream-after-follow-top',
        streamAnounsmentEnabled: 'features.stream-announcement-enabled',
      }),
      {
        tag: TAGS.FAVORITE,
        usersPropName: 'favoriteUsers',
        usersLoadedPropName: 'favoriteUsersLoaded',
      },
    ),
  );
