import React from 'react';
import {Image, Keyboard, Platform, StatusBar, TextInput, TouchableOpacity, View} from 'react-native';
import styles from './styles';
import PropTypes from 'prop-types';
import LetterHeaderView from 'dating-mobile/src/inbox/views/letter-header';
import Resources from 'dating-mobile/src/resources';
import KeyboardSpacer from 'react-native-keyboard-spacer';
import { SafeAreaView } from "react-navigation";
import ResourceKeyboard from 'dating-mobile/src/dialogs/common/views/resource-keyboard';
import { RESOURCE_KEYBOARD_TYPE } from "dating-mobile/src/dialogs/common/views/form";
import LetterAttachmentList from 'dating-mobile/src/inbox/views/letter-attachment-list';

const KEYBOARD_SHOWN_EVENT_NAME = Platform.OS === 'android' ? 'keyboardDidShow' : 'keyboardWillShow';
const KEYBOARD_HIDDEN_EVENT_NAME = Platform.OS === 'android' ? 'keyboardDidHide' : 'keyboardWillHide';

export default class LetterWritingView extends React.Component {
    static displayName = 'inbox.screens.letter-writing.view';
    static propTypes = {
        senderId: PropTypes.string,
        recipient: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string
        }),
        sendLetter: PropTypes.func,
        setTopBarState: PropTypes.func,
        dismiss: PropTypes.func,
        smilesEnabled: PropTypes.bool,
        select: PropTypes.func,
        selectFromGallery: PropTypes.func,
        forbidMediaFromCamera: PropTypes.bool,
        cover: PropTypes.string,
        onSendLetterButtonPress: PropTypes.func,
        onTextSet: PropTypes.func,
        onSubjectSet: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.state = {
            subject: '',
            text: '',
            keyboardVisible: false,
            resourceKeyboard: null,
            pendingResourceKeyboard: null
        }
    }

    componentDidMount() {
        this.props.setTopBarState && this.props.setTopBarState({
            title: Resources.strings.formatString(
                Resources.strings['inbox-owner-letter-header-title'],
                this.props.recipient.name
            )
        });
        Keyboard.addListener(KEYBOARD_SHOWN_EVENT_NAME, this.onKeyboardShown);
        Keyboard.addListener(KEYBOARD_HIDDEN_EVENT_NAME, this.onKeyboardHidden);
    }

    componentWillUnmount() {
        Keyboard.removeListener(KEYBOARD_SHOWN_EVENT_NAME, this.onKeyboardShown);
        Keyboard.removeListener(KEYBOARD_HIDDEN_EVENT_NAME, this.onKeyboardHidden);
    }

    sendLetter = () => {
        if (this.canSendLetter()) {
            this.props.onSendLetterButtonPress && this.props.onSendLetterButtonPress();
            this.props.dismiss && this.props.dismiss();
        }
    };

    onTextChanged = (text) => {
        this.props.onTextSet && this.props.onTextSet(text);
        this.setState({ text: text });
    };

    onSubjectChanged = (subject) => {
        this.props.onSubjectSet && this.props.onSubjectSet(subject);
        this.setState({ subject: subject });
    };

    onKeyboardShown = () => {
        this.setState({
            keyboardVisible: true,
            resourceKeyboard: null,
            pendingResourceKeyboard: null
        });
    };

    onKeyboardHidden = () => {
        this.setState({
            keyboardVisible: false,
            pendingResourceKeyboard: null,
            resourceKeyboard: this.state.pendingResourceKeyboard
        });
    };

    onTakeMediaFromCameraButtonPressed = () => {
        this.props.select && this.props.select();
    };

    onTakeMediaFromGalleryButtonPressed = () => {
        this.props.selectFromGallery && this.props.selectFromGallery();
    };

    addSmileToText = (smile) => {
        let newText = `${smile.pattern} `;
        if (this.state.text.trim() && !this.state.text.endsWith(' ')) {
            newText = ` ${newText}`;
        }

        const text = this.state.text + newText;
        this.setState({
            text: text
        });
        this.props.onTextSet && this.props.onTextSet(text);
    };

    onEmojiButtonPressed = () => {
        if (
            this.state.pendingResourceKeyboard === RESOURCE_KEYBOARD_TYPE.EMOJI ||
            this.state.resourceKeyboard === RESOURCE_KEYBOARD_TYPE.EMOJI
        ) {
            this.textInputRef && this.textInputRef.focus();
        } else {
            this.showResourceKeyboard(RESOURCE_KEYBOARD_TYPE.EMOJI);
        }
    };

    showResourceKeyboard = (keyboard) => {
        if (!this.state.keyboardVisible) {
            this.setState({
                resourceKeyboard: keyboard
            });
        } else {
            this.setState({
                pendingResourceKeyboard: keyboard
            });
            Keyboard.dismiss();
        }
    };

    canSendLetter = () => {
        return this.state.text && this.state.text.length > 0;
    };

    render() {
        const canSend = this.canSendLetter();

        const sendButtonIconStyle = [styles.sendButtonIcon, canSend ? styles.sendButtonIconActive : {}];

        const resourceKeyboardVisible = this.state.resourceKeyboard === RESOURCE_KEYBOARD_TYPE.EMOJI;

        const keyboardVisible = resourceKeyboardVisible || this.state.keyboardVisible;

        const resourceKeyboardStyle = resourceKeyboardVisible
            ? {}
            : {height: 0, overflow: 'hidden'};

        return (
            <View style={styles.container}>
                <StatusBar
                    barStyle={styles.$statusBarStyle}
                    backgroundColor={styles.$statusBarBackgroundColor}
                />
                <LetterHeaderView
                    attendeeId={this.props.recipient.id}
                    cover={this.props.cover}
                    showOnlyCover={true}
                    ownLetter={true}
                />
                <TextInput
                    placeholder={Resources.strings['inbox-letter-writing-subject-placeholder']}
                    placeholderTextColor={styles.$inputPlaceholderColor}
                    selectionColor={styles.$inputSelectionColor}
                    style={styles.subjectInput}
                    value={this.state.subject}
                    onChangeText={this.onSubjectChanged}
                    keyboardAppearance={styles.$inputKeyboardAppearance}
                />
                <View style={styles.separator}/>
                <LetterAttachmentList
                    senderId={this.props.senderId}
                    recipientId={this.props.recipient.id}
                    letterTag={this.props.letterTag}
                />
                <View style={styles.textInputContainer}>
                    <TextInput
                        forwardRef={ref => this.textInputRef = ref}
                        placeholder={Resources.strings['inbox-letter-writing-text-placeholder']}
                        placeholderTextColor={styles.$inputPlaceholderColor}
                        selectionColor={styles.$inputSelectionColor}
                        style={styles.textInput}
                        value={this.state.text}
                        onChangeText={this.onTextChanged}
                        keyboardAppearance={styles.$inputKeyboardAppearance}
                        multiline={true}
                    />
                </View>
                <View style={styles.actionButtonsContainer}>
                    <View style={styles.leftActionButtonsContainer}>
                        {
                            !!this.props.forbidMediaFromCamera && (
                                <TouchableOpacity
                                    style={styles.actionButton}
                                    onPress={this.onTakeMediaFromGalleryButtonPressed}
                                >
                                    <Image style={styles.actionIconStyle} source={Resources.images.galleryIcon()}/>
                                </TouchableOpacity>
                            )
                        }
                        {
                            !this.props.forbidMediaFromCamera && (
                                <TouchableOpacity
                                    style={styles.actionButton}
                                    onPress={this.onTakeMediaFromCameraButtonPressed}
                                >
                                    <Image style={styles.actionIconStyle} source={Resources.images.cameraIcon()}/>
                                </TouchableOpacity>
                            )
                        }
                        {
                            !!this.props.smilesEnabled && (
                                <TouchableOpacity
                                    style={styles.actionButton}
                                    onPress={this.onEmojiButtonPressed}
                                >
                                    <Image style={styles.actionIconStyle} source={Resources.images.sendSmileIcon()}/>
                                </TouchableOpacity>
                            )

                        }
                    </View>
                    <View style={styles.rightActionButtonsContainer}>
                        <TouchableOpacity style={styles.sendButton} onPress={this.sendLetter}>
                            <Image source={Resources.images.sendIcon()} style={sendButtonIconStyle}/>
                        </TouchableOpacity>
                    </View>
                </View>
                {
                    resourceKeyboardVisible && (
                        <View style={styles.resourceKeyboardSeparator} />
                    )
                }
                <View style={resourceKeyboardStyle}>
                    <ResourceKeyboard
                        smilesEnabled={this.props.smilesEnabled}
                        onSmileSelect={this.addSmileToText}
                    />
                </View>
                {
                    Platform.OS === 'ios' && (
                        <KeyboardSpacer />
                    )
                }
                {
                    Platform.OS === 'ios' && !keyboardVisible && (
                        <SafeAreaView forceInset={{ bottom: 'always', top: 'never' }} />
                    )
                }
            </View>
        );
    }
}
