import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  $streamingInputVisible: false,
  container: {
    flex: 1,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
  },
  modal: {
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  title: {
    fontSize: 22,
  },
  input: {
    width: 300,
    padding: 5,
    borderWidth: 1,
    borderColor: '#000',
    textAlign: 'center',
    margin: 20,
    color: '$primaryTextColor',
  },
  control: {
    width: 300,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  save: {
    fontSize: 16,
  },
  reset: {
    fontSize: 16,
  },
  error: {
    fontSize: 12,
    color: 'red',
  },
  close: {
    alignSelf: 'flex-end',
    padding: 10,
    fontSize: 16,
    color: '#000',
  },
  endpoint: {
    position: 'absolute',
    bottom: 10,
    right: 10,
  },
  icon: {
    width: 30,
    height: 30,
    tintColor: '$brandTextColor',
  },
});

export default styles;
