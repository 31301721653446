import React from 'react';
import { StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import Resources from 'dating-mobile/src/resources';
import ImageButton from 'dating-mobile/src/components/navigation/views/image-button';
import Navigator from 'dating-mobile/src/routing/navigator';
import { CHAT } from 'dating-mobile/src/routing/router/constants';
import styles from './styles';

function withNavigation(Component) {
  class Navigation extends React.Component {
    static displayName = 'inbox.screens.letter-writing.navigation';

    static propTypes = {
      navigation: PropTypes.object.isRequired,
    };

    componentDidMount() {
      const { navigation } = this.props;

      navigation.setParams({ dismiss: this.dismiss });
    }

    setTopBarState = state => {
      const { navigation } = this.props;

      if (navigation) navigation.setParams(state);
    };

    dismiss = () => {
      const { navigation } = this.props;
      const stack = Navigator.currentStack();

      if (navigation) {
        if (stack.indexOf(CHAT) >= 0) {
          navigation.navigate(CHAT, {
            id: navigation.getParam('recipientId'),
          });
        } else {
          navigation.pop();
        }
      }
    };

    render() {
      const { navigation } = this.props;

      return (
        <Component
          {...this.props}
          recipientId={navigation.getParam('recipientId')}
          setTopBarState={this.setTopBarState}
          dismiss={this.dismiss}
        />
      );
    }
  }

  Navigation.navigationOptions = ({ navigation }) => ({
    header: undefined,
    headerTransparent: true,
    headerStyle: undefined,
    headerTitle: navigation.getParam('title'),
    headerTitleStyle: styles.headerTitle,
    headerLeft: () => (
      <ImageButton
        image={Resources.images.backIcon()}
        tint={StyleSheet.flatten(styles.headerTitle).color}
        onPress={() => navigation.getParam('dismiss')()}
      />
    ),
  });

  return Navigation;
}

export default withNavigation;
