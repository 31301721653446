import React from 'react';
import PropTypes from 'prop-types';
import { withNavigation } from 'react-navigation';

export default function bindNavigation(Component) {
    class Navigation extends React.Component {
        static displayName = 'components.navigation-item.navigation';
        static propTypes = {
            navigation: PropTypes.object.isRequired,
            route: PropTypes.string.isRequired,
            routeParamsFromProps: PropTypes.func,
        };

        navigate = () => {
            const routeParams = this.props.routeParamsFromProps
                ? this.props.routeParamsFromProps(this.props)
                : undefined;
            this.props.navigation.navigate(this.props.route, routeParams);
        };

        render() {
            return <Component {...this.props} onPress={this.navigate} />;
        }
    }

    return withNavigation(Navigation);
}
