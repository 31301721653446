import Service from '../../service';
import PropTypes from 'prop-types';
import InvitationModel from '@sdv/domain/dialogs.automation.productions.invitation';
import { parseUserId } from '../../../utils/user-id';

export default class InvitationProvider extends Service {
    static displayName = 'service.dialogs-automation-productions-invitation-provider';
    static contextTypes = {
        flux: PropTypes.object
    };

    constructor(props, context) {
        super(props);

        this.events = context.flux.events;
    }

    componentDidMount() {
        this.events.addListener('event.dialogs.automation.productions.added', this.onProductionAdded);
        this.events.addListener('event.dialogs.automation.productions.found', this.onConsumerFound);
        this.events.addListener('event.dialogs.automation.productions.removed', this.onProductionRemoved);
    }

    componentWillUnmount() {
        this.events.removeListener('event.dialogs.automation.productions.added', this.onProductionAdded);
        this.events.removeListener('event.dialogs.automation.productions.found', this.onConsumerFound);
        this.events.removeListener('event.dialogs.automation.productions.removed', this.onProductionRemoved);
    }

    onProductionAdded = (data) => {

        if (!data || !data['user-id'] || (data.product && data.product !== 'invitation')) {
            return;
        }

        const model = this.context.flux.get(InvitationModel, parseUserId(data['user-id']));
        model.actions.actualize({
            consumers: [],
            active: data.approval === 'approved',
            amount: data['amount'],
            lifetime: data.lifetime
        });

    };

    onConsumerFound = (data) => {

        if (!data || !data['user-id'] || !data['consumer-id'] || (data.product && data.product !== 'invitation')) {
            return;
        }

        const model = this.context.flux.get(InvitationModel, parseUserId(data['user-id']));
        const state = model.store.getState();

        const consumers = (state.consumers || []).slice();
        consumers.push(data['consumer-id']);

        let amount = state.amount;
        if (amount) {
            amount--;
        }

        model.actions.actualize({
            consumers,
            amount
        });

    };

    onProductionRemoved = (data) => {

        if (!data || !data['user-id'] || (data.product && data.product !== 'invitation')) {
            return;
        }

        const model = this.context.flux.get(InvitationModel, parseUserId(data['user-id']));
        model.actions.actualize({
            active: false
        });

    };
}
