import React from 'react';
import PropTypes from 'prop-types';
import { View, FlatList, TouchableOpacity, Text, Image } from 'react-native';
import Resources from '../../../resources';
import styles from './styles';
import Send from '../../../models/dialogs.messages/controllers/send';
import IdentityId from '../../../models/identity/controller/id';
import testId from '../../../utils/test-id';

class FastAnswerPanel extends React.Component {
    static propTypes = {
        attendeeId: PropTypes.string.isRequired,
        variants: PropTypes.arrayOf(PropTypes.string).isRequired,
        send: PropTypes.func,
        dismiss: PropTypes.func
    };

    sendText = (text) => {
        this.props.send && this.props.send(text);
        this.props.dismiss && this.props.dismiss();
    };

    renderItem = ({item}) => {
        return (
            <TouchableOpacity key={item} style={styles.itemContainer} onPress={() => this.sendText(item)}>
                <View style={styles.itemContent}>
                    <Text style={styles.message} {...testId('Message text label')}>{item}</Text>
                    <Image style={styles.sendIcon} source={Resources.images.sendIcon()} {...testId('Send button')}/>
                </View>
            </TouchableOpacity>
        );
    };

    renderSeparator = () => {
        return (<View style={styles.itemSeparator} />);
    };

    renderHeader = () => {
        return (<View style={styles.listHeader} />);
    };

    renderFooter = () => {
        return (<View style={styles.listFooter} />);
    };

    render() {
        return (
            <View style={styles.container}>
                <FlatList
                    style={styles.list}
                    renderItem={this.renderItem}
                    data={this.props.variants}
                    ItemSeparatorComponent={this.renderSeparator}
                    ListHeaderComponent={this.renderHeader}
                    ListFooterComponent={this.renderFooter}
                    keyExtractor={item => item}
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                />
            </View>
        );
    }


}

export default IdentityId(Send(FastAnswerPanel, 'send'));
