import React from 'react';
import { View, ActivityIndicator, StatusBar } from 'react-native';
import styles from './styles';

export default class ScreenView extends React.Component {
    static displayName = 'payment.initial.screen.view';

    shouldComponentUpdate() {
        return false;
    }

    render() {
        return (
            <View style={styles.container}>
                <StatusBar
                    barStyle={styles.$statusBarStyle}
                    backgroundColor={styles.$statusBarBackgroundColor}
                />
                <ActivityIndicator animating={true} />
            </View>
        );
    }
}
