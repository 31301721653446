import React from 'react';
import PropTypes from 'prop-types';
import { render } from 'dating-mobile/src/components/rx-render';

import { ProfileBooster } from '@sdv/domain/user/profile-booster';

export default function createController(Component) {
  const Controller = render(
    ({ userId, placeholder, ...props }, { useObservable }) => {
      if (!userId) {
        return null;
      }

      const endTime = useObservable(
        it => it.endTime,
        ProfileBooster.shared(userId),
      );

      if (!endTime) {
        return placeholder || null;
      }

      return <Component {...props} endTime={endTime} />;
    },
  );

  Controller.propTypes = {
    userId: PropTypes.string,
    placeholder: PropTypes.node,
  };

  Controller.displayName = 'feed.views.boost-timeout.controller';

  return Controller;
}
