import React from 'react';
import PropTypes from 'prop-types';
import WinksModel from '@sdv/domain/dialogs.wink';
import connect from '@sdv/connect';

const WINK_PROPERTY = 'generated.dialogs-wink-controller.wink';

function createHoc(Component, options = {}) {
    const userIdPropName = options.userIdPropName || 'userId';
    const winkActionPropName = options.winkActionPropName || 'wink';

    class Wink extends React.Component {
        static displayName = 'dialogs-wink.controller';
        static propTypes = {
            [WINK_PROPERTY]: PropTypes.func
        };
        static contextTypes = {
            flux: PropTypes.object
        };

        wink = (user, winkType, callback) => {
            this.props[WINK_PROPERTY] && this.props[WINK_PROPERTY](user, winkType, (...args) => {
                this.context.flux.events.pollEvent();

                callback && callback(...args);
            });
        };

        render() {
            return (<Component {...this.props} { ...{ [winkActionPropName]: this.wink } } />);
        }

    }

    function getModels(flux, props) {
        const models = {};

        if (props[userIdPropName]) {
            models.winksModel = flux.get(WinksModel, props[userIdPropName]);
        }

        return models;
    }

    function mapStoresToProps() {
        return {};
    }

    function mapStoresToActions(models) {
        const props = {};

        if (models.winksModel) {
            props[WINK_PROPERTY] = models.winksModel.actions.wink;
        }

        return props;
    }

    function shouldReconnect(props, newProps) {
        return props[userIdPropName] !== newProps[userIdPropName];
    }

    return connect(
        getModels,
        mapStoresToProps,
        mapStoresToActions,
        shouldReconnect
    )(Wink);

}

export default createHoc;
