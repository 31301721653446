import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'react-native';
import styles from './styles';
import withMiscDataValue from '../../../../models/misc-data/controller/value';
import { CRITERIA_OPTIONS } from '../../../../models/misc-data/utils';
import testId from 'dating-mobile/src/utils/test-id';
import Resources from 'dating-mobile/src/resources';

class TravelDestinationText extends React.Component {
    static displayName = 'feed.view.list.item.travel-destination-text';
    static propTypes = {
        city: PropTypes.string,
        country: PropTypes.string
    };

    shouldComponentUpdate(nextProps) {
        return this.props.city !== nextProps.city || this.props.country !== nextProps.country;
    }

    render() {
        let country = this.props.country;
        let city = this.props.city;

        if (city || country) {
            let displayText = 'Travels to ';
            if (city) {
                displayText += city;
            }
            if (country && city) {
                displayText += ', ';
            }
            if (country) {
                displayText += country;
            }
            return (<Text 
                numberOfLines={1} 
                style={styles.travels}
                {...testId(Resources.strings['user-travel-destination-accessibility-label'])}
            >
                {displayText}
            </Text>);
        }
        else {
            return (<Text style={styles.travels} />);
        }
    }
}

export default withMiscDataValue(
    TravelDestinationText,
    {
        dataType: CRITERIA_OPTIONS.COUNTRIES,
        valuePropName: 'country',
        keyPropName: 'countryCode'
    }
);