import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import withUserController from 'dating-mobile/src/models/user/controllers';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';

export default function createController(Component) {
  class Controller extends PureComponent {
    static displayName =
      'stepped-registration.screens.photo-with-bio.controller';

    static propTypes = {
      updateUser: PropTypes.func.isRequired,
      onComplete: PropTypes.func.isRequired,
    };

    static contextTypes = {
      flux: PropTypes.object,
    };

    onSubmit = about => {
      const { updateUser, onComplete } = this.props;

      updateUser({
        about,
      });

      if (onComplete) {
        onComplete();
      }
    };

    render() {
      return <Component {...this.props} onSubmit={this.onSubmit} />;
    }
  }

  return withIdentityId(withUserController(Controller), 'id');
}
