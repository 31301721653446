import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import Photo from 'dating-mobile/src/components/photo';
import CheerContentController from 'dating-mobile/src/models/common.messages/controllers/cheer-content-controller';
import { DELIVERY_STATUS } from 'dating-mobile/src/models/common.messages/model';
import testId from 'dating-mobile/src/utils/test-id';
import Resources from 'dating-mobile/src/resources';
import SoundPlayer from 'dating-mobile/src/native-modules/sound-player';

import styles from './styles';

class CheerContent extends React.Component {
  static displayName = 'common.messages.message.cheer-content';

  static propTypes = {
    path: PropTypes.string,
    cheersSoundInChatEnabled: PropTypes.bool,
    message: PropTypes.shape({
      status: PropTypes.number,
      read: PropTypes.bool,
      tag: PropTypes.string,
    }).isRequired,
  };

  shouldComponentUpdate(nextProps) {
    const { path, message } = this.props;

    return (
      path !== nextProps.path ||
      message?.status !== nextProps.message?.status ||
      message?.tag !== nextProps.message?.tag ||
      message?.read !== nextProps.message?.read
    );
  }

  componentDidUpdate(prevProps) {
    const { message, cheersSoundInChatEnabled } = this.props;

    if (
      cheersSoundInChatEnabled &&
      message?.tag === prevProps.message?.tag &&
      message?.outgoing &&
      prevProps.message?.status === DELIVERY_STATUS.SENDING &&
      message?.status === DELIVERY_STATUS.DELIVERED
    ) {
      SoundPlayer.playSoundFile('gift_showed', 'mp3', false);
    }

    if (
      cheersSoundInChatEnabled &&
      message?.tag === prevProps.message?.tag &&
      !message?.outgoing &&
      !prevProps.message?.read &&
      message?.read
    ) {
      SoundPlayer.playSoundFile('gift_showed', 'mp3', false);
    }
  }

  render() {
    const { path, message } = this.props;

    const photoStyle = [
      styles.photo,
      message.status !== DELIVERY_STATUS.DELIVERED ? styles.unsentCheer : {},
    ];

    return (
      <View style={styles.container}>
        <Photo
          forceRetina
          style={photoStyle}
          path={path}
          resizeMode="contain"
          {...testId(Resources.strings['message-cheer-accessibility-label'])}
        />
      </View>
    );
  }
}

export default CheerContentController(CheerContent);
