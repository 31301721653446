// TODO:
import { View } from 'react-native';

export const AppleButton = View;

AppleButton.Style = {};

AppleButton.Type = {};

export const appleAuth = {
  isSupported: false,
};
