import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
// import { RTCView } from 'react-native-webrtc';
import styles from './styles';

const RTCView = View;

class Video extends React.Component {
  static displayName = 'dialogs.stream.view';

  static propTypes = {
    mode: PropTypes.string,
    zOrder: PropTypes.number,
    style: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
  };

  shouldComponentUpdate(nextProps) {
    return (
      this.props.video !== nextProps.video ||
      this.props.mode !== nextProps.mode ||
      this.props.style !== nextProps.style
    );
  }

  render() {
    const mode = this.props.mode || 'cover';

    const zOrder = this.props.zOrder || 0;

    const style = this.props.style
      ? [styles.video, this.props.style]
      : styles.video;

    return (
      <RTCView
        {...this.props}
        streamURL={this.props.video}
        style={style}
        objectFit={mode}
        zOrder={zOrder}
      />
    );
  }
}

export default Video;
