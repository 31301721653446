import React from 'react';
import PropTypes from 'prop-types';
import { withNavigationFocus } from 'react-navigation';
import Resources from 'dating-mobile/src/resources';
import { TIP } from 'dating-mobile/src/routing/router/constants';

import Controller from './controller';
import View from './view';
import styles from './styles';

const ScreenView = withNavigationFocus(Controller(View));

export default class Screen extends React.Component {
  static displayName = 'tips.tips.screen';

  static propTypes = {
    navigation: PropTypes.object,
  };

  static navigationOptions = ({ navigationOptions }) => ({
    headerTitle: Resources.strings['tips-screen-title'],
    headerStyle: [navigationOptions.headerStyle, styles.header],
    headerTitleStyle: [navigationOptions.headerTitleStyle, styles.headerTitle],
  });

  showTip = props => {
    const { navigation } = this.props;

    navigation.navigate(TIP, props);
  };

  render() {
    return <ScreenView showTip={this.showTip} />;
  }
}
