import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default function createController(Component) {
  return class Controller extends PureComponent {
    static displayName = 'components.time.timer.controller';

    static propTypes = {
      endTime: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.number,
      ]),
      updateInterval: PropTypes.number,
      onTimerEnd: PropTypes.func,
    };

    static defaultProps = {
      updateInterval: 1000,
    };

    constructor(props) {
      super(props);

      this.state = {
        remainingTime: this.getRemainingTime(),
      };
    }

    componentDidMount() {
      this.startTimer();
    }

    componentDidUpdate(prevProps) {
      const { endTime } = this.props;

      if (prevProps.endTime !== endTime) {
        this.stopTimer();
        this.startTimer();
      }
    }

    componentWillUnmount() {
      this.stopTimer();
    }

    getRemainingTime() {
      const { endTime } = this.props;

      return endTime ? Math.max(0, endTime - Date.now()) : null;
    }

    updateRemainingTime = () => {
      const remainingTime = this.getRemainingTime();

      this.setState({ remainingTime });

      if (!remainingTime) {
        this.stopTimer();

        const { endTime, onTimerEnd } = this.props;

        if (endTime && onTimerEnd) {
          onTimerEnd();
        }
      }
    };

    stopTimer() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    }

    startTimer() {
      const { endTime, updateInterval } = this.props;

      if (!this.intervalId && endTime) {
        this.intervalId = setInterval(this.updateRemainingTime, updateInterval);
      }
    }

    render() {
      const { endTime, updateInterval, ...props } = this.props;
      const { remainingTime } = this.state;

      return <Component {...props} remainingTime={remainingTime} />;
    }
  };
}
