export const CRITERIA_OPTIONS = Object.freeze({
    COUNTRIES: 'countries',
    INTERESTS: 'interests',
    EDUCATIONS: 'educations-v2',
    LANGUAGES: 'languages',
    RELATIONSHIPS: 'relationships',
    KIDS_SEARCH: 'kids-search',
    SMOKE: 'smoke',
    SMOKE_SEARCH: 'smoke-search',
    DRINKING: 'drinking-v2',
    DRINKING_SEARCH: 'drinking-search',
    HEIGHT: 'height',
    BODY_TYPE: 'bodytype',
    EYES: 'eyes',
    EYES_SEARCH: 'eyes-search',
    HAIRS: 'hairs',
    HAIRS_SEARCH: 'hairs-search',
});
