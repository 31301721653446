import Persistence from '@sdv/domain/app/persistence';
import { map, filter, tap } from 'rxjs/operators';

export default class StreamUsingTutorial {
    constructor(userId) {
        const persistence = new Persistence('app-first-launch', userId);
        const streamTutorialShowedKey = 'streamTutorialShowed';

        this.shouldShowStreamSwipingTutorial = persistence.rxValue(streamTutorialShowedKey).pipe(
            map(value => value === 'true' || value === true),
            filter(showed => !showed),
            tap(() => persistence.store(streamTutorialShowedKey, String(true))),
        );
    }
}
