import { Platform } from 'react-native';
import { singleton } from '@sdv/commons/utils/singleton';
import { combineLatest, of, ReplaySubject } from 'rxjs';
import { filter, first, flatMap, map } from 'rxjs/operators';
import Navigator from 'dating-mobile/src/routing/navigator';
import { THREE_DS } from 'dating-mobile/src/routing/router/constants';
import Config from 'dating-mobile/src/app/config';
import {
  createThreeDsReceiptFromUrl,
  getThreeDsData,
  prepareThreeDsHtml,
} from '@sdv/domain/payment/credit-card/three-ds';

export class ThreeDsGateway {
  static shared = singleton(() => new ThreeDsGateway());

  constructor() {
    this.resultSubject = new ReplaySubject(1);
  }

  /**
   *
   * @private
   * @param md {string}
   * @return {Observable<{success: boolean, receipt: string, md: string}>}
   */
  listenReceipt(md) {
    return this.resultSubject.pipe(filter(result => result.md === md));
  }

  /**
   *
   * @param receipt
   * @return {Observable<{success: boolean, receipt: string}>}
   */
  handleThreeDs(receipt) {
    const threeDsData = getThreeDsData(receipt);

    if (!threeDsData) {
      return of({ success: true, receipt });
    }

    return combineLatest(
      Config.shared().paramWithPath('endpoints.3ds-result-host'),
      Config.shared().paramWithPath('endpoints.web-host'),
      (redirectHost, webHost) => ({
        threeDsRedirectHost: redirectHost,
        webHost,
      }),
    ).pipe(
      first(),
      map(({ threeDsRedirectHost, webHost }) => {
        return this.openThreeDsScreen(
          receipt,
          threeDsData,
          threeDsRedirectHost,
          webHost,
        );
      }),
      flatMap(() => this.listenReceipt(threeDsData.Md)),
    );
  }

  openThreeDsScreen(receipt, threeDsData, threeDsRedirectHost, webHost) {
    const mdKey = threeDsData.Md;
    const onRedirect = url => {
      try {
        const threeDsReceipt = createThreeDsReceiptFromUrl(url, threeDsData);

        if (threeDsReceipt) {
          this.resultSubject.next({
            success: true,
            receipt: threeDsReceipt,
            md: mdKey,
          });
        }

        return !!threeDsReceipt;
      } catch (e) {
        // catches a malformed URI
        this.resultSubject.next({
          success: false,
          receipt,
          md: mdKey,
        });

        return false;
      }
    };

    const onCancel = () => {
      this.resultSubject.next({ success: false, receipt, md: mdKey });
    };

    function onStorageShange({ key, newValue }) {
      if (key === '3ds-receipt') {
        onRedirect(`/people/#3ds=${newValue}`);
        window.removeEventListener('storage', onStorageShange);
        window.localStorage.removeItem('3ds-receipt');
      }
    }

    if (Platform.OS === 'web') {
      window.addEventListener('storage', onStorageShange);
    }

    Navigator.navigate(THREE_DS, {
      request: {
        method: 'POST',
        html: prepareThreeDsHtml(threeDsData, threeDsRedirectHost),
        baseUrl: webHost,
      },
      onRedirect,
      onCancel,
    });
  }
}
