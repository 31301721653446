import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import { Image, TouchableOpacity, View } from 'react-native';
import Photo, { PHOTO_TRANSFORMATION_TYPE } from '../../../../../components/photo';
import Resources from '../../../../../resources';

export default class GalleryItem extends React.Component {
    static displayName = 'user.profile.views.photo-gallery.item';
    static propTypes = {
        path: PropTypes.string,
        media: PropTypes.shape({
            mediatype: PropTypes.string
        }),
        style: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.object,
            PropTypes.array
        ]),
        onPress: PropTypes.func
    };

    shouldComponentUpdate(nextProps) {
        const mediatype = this.props.media && this.props.media.mediatype;
        const nextMediatype = nextProps.media && nextProps.media.mediatype;
        return this.props.path !== nextProps.path ||
            mediatype !== nextMediatype ||
            this.props.style !== nextProps.style;
    }

    onPressed = () => {
        this.props.onPress && this.props.onPress(this.props.media);
    };

    render() {
        const isVideo = this.props?.media.mediatype?.startsWith('video');

        const containerStyle = this.props.style
            ? [styles.container, this.props.style]
            : styles.container;

        return (
            <View style={containerStyle}>
                <TouchableOpacity style={styles.content} onPress={this.onPressed}>
                    <View style={styles.content}>
                        <Photo
                            style={styles.photo}
                            path={this.props.path}
                            transformation={PHOTO_TRANSFORMATION_TYPE.DETECT_FACE}
                            placeholder={'avatar'}
                            accessibilityLabel={this.props.accessibilityLabel}
                            testID={this.props.testID} 
                        />
                        {
                            isVideo && (
                                <Image style={styles.icon} source={Resources.images.playVideoIcon()}/>
                            )
                        }
                    </View>
                </TouchableOpacity>
            </View>
        );
    }

}
