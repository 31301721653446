import React from 'react';
import PropTypes from 'prop-types';
import { View, TouchableWithoutFeedback, Text, StyleSheet } from 'react-native';
import Resources from 'dating-mobile/src/resources';
import LinearGradient from 'react-native-linear-gradient';
import styles from './styles';
import StickersKeyboard from '../stickers-keyboard';
import SmilesKeyboard from '../smiles-keyboard';
import CheersKeyboard from '../cheers-keyboard';

export const KEYBOARD_TYPES = Object.freeze({
    STICKERS: 1,
    SMILES: 2,
    GIFTS: 3,
});

const KEYBOARD_TYPE_TITLES = Object.freeze({
    [KEYBOARD_TYPES.STICKERS]: Resources.strings['resource-keyboard-stickers-keyboard-title'],
    [KEYBOARD_TYPES.SMILES]: Resources.strings['resource-keyboard-smiles-keyboard-title'],
    [KEYBOARD_TYPES.GIFTS]: Resources.strings['resource-keyboard-gifts-keyboard-title'],
});

export default class ResourceKeyboard extends React.PureComponent {
    static displayName = 'dialogs.common.views.resource-keyboard';

    static propTypes = {
        smilesEnabled: PropTypes.bool,
        stickersEnabled: PropTypes.bool,
        giftsEnabled: PropTypes.bool,
        onSmileSelect: PropTypes.func,
        onStickerSelect: PropTypes.func,
        onGiftSelect: PropTypes.func,
        onTypeSelect: PropTypes.func,
        freeCheersAvaliable: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        const selectedType = this.availableTypes()[0];

        this.state = {
            selectedType,
        };
    }

    componentDidMount() {
        this.setTypeSelected(this.state.selectedType);
    }

    componentDidUpdate() {
        const availableTypes = this.availableTypes();
        const currentSelectedType = this.state.selectedType;

        const firstSelectedTypeInitRequired = !currentSelectedType && availableTypes.length > 0;
        const currentlySelectedTypeIsUnavailable =
            currentSelectedType && availableTypes.indexOf(currentSelectedType) < 0;

        if (firstSelectedTypeInitRequired || currentlySelectedTypeIsUnavailable) {
            this.setTypeSelected(availableTypes[0]);
        }
    }

    availableTypes = () => {
        const types = [];

        if (this.props.stickersEnabled) {
            types.push(KEYBOARD_TYPES.STICKERS);
        }
        if (this.props.smilesEnabled) {
            types.push(KEYBOARD_TYPES.SMILES);
        }
        if (this.props.giftsEnabled) {
            types.push(KEYBOARD_TYPES.GIFTS);
        }

        return types;
    };

    onTypeSelect = type => () => {
        this.setTypeSelected(type);
    };

    setTypeSelected = type => {
        this.setState({
            selectedType: type,
        });
        this.props.onTypeSelect && this.props.onTypeSelect(type);
    };

    renderSwitchItem = type => {
        const isSelected = this.state.selectedType === type;
        const itemStyle = isSelected ? styles.selectedSwitchItem : styles.switchItem;
        const itemTitleStyle = isSelected ? styles.selectedSwitchItemTitle : styles.switchItemTitle;
        const { gradientColor } = StyleSheet.flatten(itemStyle);

        const content = <Text style={itemTitleStyle}>{KEYBOARD_TYPE_TITLES[type] || ''}</Text>;

        const container = gradientColor ? (
            <LinearGradient colors={gradientColor} style={itemStyle}>
                {content}
            </LinearGradient>
        ) : (
            <View style={itemStyle}>{content}</View>
        );

        return (
            <TouchableWithoutFeedback key={type} onPress={this.onTypeSelect(type)}>
                {container}
            </TouchableWithoutFeedback>
        );
    };

    render() {
        const availableTypes = this.availableTypes();

        return (
            <View style={styles.container}>
                {availableTypes.length > 1 && (
                    <View style={styles.switcherContainer}>
                        <View style={styles.switcher}>
                            {availableTypes.map(this.renderSwitchItem)}
                        </View>
                    </View>
                )}
                <View style={styles.keyboard}>
                    {this.state.selectedType === KEYBOARD_TYPES.STICKERS && (
                        <StickersKeyboard onStickerSelect={this.props.onStickerSelect} />
                    )}
                    {this.state.selectedType === KEYBOARD_TYPES.SMILES && (
                        <SmilesKeyboard onSmileSelect={this.props.onSmileSelect} />
                    )}
                    {this.state.selectedType === KEYBOARD_TYPES.GIFTS && (
                        <CheersKeyboard
                            onCheerSelected={this.props.onGiftSelect}
                            freeCheersAvaliable={this.props.freeCheersAvaliable}
                        />
                    )}
                </View>
            </View>
        );
    }
}
