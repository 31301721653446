import Actions from './actions';

function createTypingStore(id) {
    class Store {
        constructor(flux) {
            const actions = flux.getActions(Actions, id);
            this.state = {
                incoming: false,
                outgoing: false
            };
            this.bindAction(actions.startTyping, this.update);
            this.bindAction(actions.endTyping, this.update);
            this.bindAction(actions.actualizeIncomingTyping, this.update);
        }

        update = (payload) => {
            if (!payload) {
                return;
            }
            this.setState(payload);
        };
    }
    Store.displayName = createTypingStore.getDisplayName(id);
    return Store;
}


createTypingStore.getDisplayName = function (id) {
    return `user-events-typing.${id}`;
};

export default createTypingStore;
