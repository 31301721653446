/* eslint-disable global-require */
import { Platform } from 'react-native';

export default {
  addPhoto: () => require('../assets/add_photo.png'),
  addTopicIcon: () => require('../assets/add_topic_icon.png'),
  acceptIcon: () => require('../assets/accept_icon.png'),
  arrowDownIcon: () => require('../assets/arrow_down_icon.png'),
  arrowUpIcon: () => require('../assets/arrow_up_icon.png'),
  attachSteamIcon: () => require('../assets/attach_stream_icon.png'),
  backIcon: () => require('../assets/back_icon.png'),
  balanceIcon: () => require('../assets/balance_icon.png'),
  cameraIcon: () => require('../assets/camera_icon.png'),
  cameraWhiteIcon: () => require('../assets/camera_white_icon.png'),
  cameraBigIcon: () => require('../assets/camera_big_icon.png'),
  checkMarkIcon: () => require('../assets/checkmark_icon.png'),
  coinIcon: () => require('../assets/coin_icon.png'),
  coinsIcon: () => require('../assets/coins_icon.png'),
  coinIconBig: () => require('../assets/coin_icon_big.png'),
  closeIcon: () => require('../assets/close_icon.png'),
  closeWithShadowIcon: () => require('../assets/close_shadow_icon.png'),
  closeSmallIcon: () => require('../assets/close_small_icon.png'),
  declineIcon: () => require('../assets/decline_icon.png'),
  diamondStatsIcon: () => require('../assets/diamond_stats_icon.png'),
  disclosureIcon: () => require('../assets/disclosure_icon.png'),
  editIcon: () => require('../assets/edit_icon.png'),
  eyeIcon: () => require('../assets/eye_icon.png'),
  noBenefit: () => require('../assets/no_benefit.png'),
  eyeStatsIcon: () => require('../assets/eye_stats_icon.png'),
  guyIcon: () => require('../assets/guy_icon.png'),
  girlIcon: () => require('../assets/girl_icon.png'),
  giftBigIcon: () => require('../assets/gift_big_icon.png'),
  giftBigIconAnimated: () => require('../assets/gift_big_icon_animated.webp'),
  giftOpenedIcon: () => require('../assets/gift_opened_icon.png'),
  lockIcon: () => require('../assets/lock_icon.png'),
  loupeIcon: () => require('../assets/loupe_icon.png'),
  newUserIcon: () => require('../assets/new_user_icon.png'),
  notificationsIcon: () => require('../assets/notifications_icon.png'),
  pinIcon: () => require('../assets/pin_icon.png'),
  onlineWithCameraIcon: () =>
    require('../assets/online_with_cam_indicator.png'),
  playVideoIcon: () => require('../assets/play_video_icon.png'),
  profileLookingForIcon: () =>
    require('../assets/profile_looking_for_icon.png'),
  sendGiftIcon: () => require('../assets/send_gift_icon.png'),
  sendSmileIcon: () => require('../assets/send_smile_icon.png'),
  sendIcon: () => require('../assets/send_icon.png'),
  sendDisabledIcon: () => require('../assets/send_disabled_icon.png'),
  authenticationCTAIcon: () => require('../assets/authentication_icon.png'),
  startChatIcon: () => require('../assets/start_chat_icon.png'),
  newChatsIcon: () => require('../assets/new_chats.png'),
  starStatsIcon: () => require('../assets/star_stats_icon.png'),
  startStream: () => require('../assets/start_stream.png'),
  startStreamIcon: () => require('../assets/start_stream_icon.png'),
  startStreamShadow: () => require('../assets/start_stream_shadow.png'),
  subscribeIcon: () => require('../assets/subscribe_icon.png'),
  supportIcon: () => require('../assets/support_icon.png'),
  tabBarChat: () => require('../assets/tab_bar_chat.png'),
  tabBarChatDisabled: () => require('../assets/tab_bar_chat_inactive.png'),
  tabBarProfile: () => require('../assets/tab_bar_profile.png'),
  tabBarProfileDisabled: () =>
    require('../assets/tab_bar_profile_inactive.png'),
  tabBarStreams: () => require('../assets/tab_bar_streams.png'),
  tabBarStreamsDisabled: () =>
    require('../assets/tab_bar_streams_inactive.png'),
  tabBarTips: () => require('../assets/tab_bar_tips.png'),
  tabBarTipsDisabled: () => require('../assets/tab_bar_tips_inactive.png'),
  tabBarFollowedStreams: () =>
    require('../assets/tab_bar_following_inactive.png'),
  tabBarFollowedStreamsDisabled: () =>
    require('../assets/tab_bar_following_inactive.png'),
  tabBarChatRequests: () => require('../assets/tab_bar_icon_chat_requests.png'),
  tabBarChatRequestsDisabled: () =>
    require('../assets/tab_bar_icon_chat_requests_inactive.png'),
  tabBarInbox: () => require('../assets/tab_bar_inbox.png'),
  tabBarInboxDisabled: () => require('../assets/tab_bar_inbox_inactive.png'),
  viewersIcon: () => require('../assets/viewers_icon.png'),
  winkIcon: () => require('../assets/wink_icon.png'),
  photoContentIcon: () => require('../assets/photo_content_icon.png'),
  videoContentIcon: () => require('../assets/video_content_icon.png'),
  letterIcon: () => require('../assets/letter_icon.png'),
  photoIcon: () => require('../assets/photo_icon.png'),
  letsMingleIcon: () => require('../assets/lets_mingle_icon.png'),
  restorePurchasesIcon: () => require('../assets/restore_purchases_icon.png'),
  housingIcon: () => require('../assets/housing_icon.png'),
  galleryIcon: () => require('../assets/gallery_icon.png'),
  videoCameraIcon: () => require('../assets/video_camera_icon.png'),
  rotateCameraIcon: () => require('../assets/rotate_camera_icon.png'),
  microphoneIcon: () => require('../assets/microphone_icon.png'),
  microphoneMutedIcon: () => require('../assets/microphone_muted_icon.png'),
  cameraOffIcon: () => require('../assets/camera_off_icon.png'),
  membershipHeaderImage: () =>
    require('../assets/subscribe_motivational_image.png'),
  starFilledIcon: () => require('../assets/star_filled_icon.png'),
  starEmptyIcon: () => require('../assets/star_empty_icon.png'),
  navigationArrowIcon: () => require('../assets/navigation_arrow_icon.png'),
  deleteAttachIcon: () => require('../assets/delete_attach_icon.png'),
  sendEmailBackground: () => require('../assets/send_email_background.png'),
  googleIcon: () => require('../assets/google_icon.png'),
  liveBadgeIcon: () => require('../assets/live_badge_icon.png'),
  rateStars: () => require('../assets/rate_stars.png'),
  facebookMessengerIcon: () => require('../assets/facebook_messenger_icon.png'),
  whatsappIcon: () => require('../assets/whatsapp_icon.png'),
  streamsTopicsCheckmarkIcon: () =>
    require('../assets/checkmark_stream_topic_icon.png'),
  contactIcon: () => require('../assets/contact_icon.png'),
  userRequestBackground: () => null,
  avatarsCollage: () => require('../assets/avatars_collage.png'),
  settingsIcon: () => require('../assets/settings_icon.png'),
  conversationsBadgeIcon: () =>
    require('../assets/conversations_badge_icon.png'),
  swipeVertical: () => require('../assets/swipe_vertical.png'),
  payPalIcon: () => require('../assets/paypal_icon.png'),
  cardVisaIcon: () => require('../assets/card_visa_icon.png'),
  cardAmexIcon: () => require('../assets/card_amex_icon.png'),
  cardDiscoverIcon: () => require('../assets/card_discover_icon.png'),
  cardJcbIcon: () => require('../assets/card_jcb_icon.png'),
  cardMastercardIcon: () => require('../assets/card_mastercard_icon.png'),
  googlePlayIcon: () => require('../assets/google_play_icon.png'),
  creditCardIcon: () => require('../assets/card_icon.png'),
  locationArrowIcon: () => require('../assets/location_arrow_icon.png'),
  rerunVideoIcon: () => require('../assets/rerun_video_icon.png'),
  datingPromoteMale: () => require('../assets/promote/dating/mal.png'),
  datingPromoteFemale: () => require('../assets/promote/dating/fem.png'),
  datingPromoteCommon: () => require('../assets/promote/dating/uni.png'),
  tubitPromoteMale: () => require('../assets/promote/tubit/mal.png'),
  tubitPromoteFemale: () => require('../assets/promote/tubit/fem.png'),
  tubitPromoteCommon: () => require('../assets/promote/tubit/uni.png'),
  geoLocationMarkIcon: () => require('../assets/geolocation_mark_icon.png'),
  congratulationsBackground: () =>
    require('../assets/congratulations-background.png'),
  peopleFeatureIcon: () => require('../assets/features-people.png'),
  skipSubscribeTitleBg: () => require('../assets/skip-subscribe-title-bg.png'),
  geoLocationIcon: () => require('../assets/geolocation.png'),
  onboardingChatIcon: () => require('../assets/onboarding_chat.png'),
  eyeOpened: () => require('../assets/eye_opened.png'),
  eyeClosed: () => require('../assets/eye_closed.png'),
  faqIcon: () => require('../assets/faq_icon.png'),
  checkboxEmpty: () => require('../assets/checkbox_empty.png'),
  checkboxFilled: () => require('../assets/checkbox_filled.png'),
  findMatchesIconAnimated: () => require('../assets/icon_search_animated.webp'),
  groupStreamMessageIcon: () =>
    require('../assets/group_stream_message_icon.png'),
  freeCheerIcon: () => require('../assets/free_cheer_icon.png'),
  chatsListPlaceholder: () => require('../assets/chats_list_placeholder.png'),
  createNewsletterButtonIcon: () =>
    require('../assets/create-newsletter-button-icon.png'),
  notificationStreamAnnouncementIsSentIcon: () =>
    require('../assets/notification-stream-announcement-is-sent-icon.png'),
  boostBannerIconGreen: () => require('../assets/boost_banner_icon_green.png'),
  boostBannerIconPurple: () =>
    require('../assets/boost_banner_icon_purple.png'),
  boostBannerIconFemale: () =>
    require('../assets/boost_banner_icon_female.png'),
  filterIcon: () => require('../assets/filter_icon_horizontal.png'),
  reportIcon: () => require('../assets/secretly/report_icon.png'),
  visaProtected: () => require('../assets/secretly/visa.png'),
  masterCardProtected: () => require('../assets/secretly/mastercard.png'),
  tabBarFeed: () => require('../assets/secretly/tab_bar_feed.png'),
  tabBarFeedDisabled: () => require('../assets/secretly/tab_bar_feed.png'),
  onboardingFirst: () => require('../assets/secretly/onboarding_first.jpg'),
  onboardingSecond: () => require('../assets/secretly/onboarding_second.png'),
  onboardingRocket: () => require('../assets/secretly/onboarding_rocket.png'),
  onboardingChat: () => require('../assets/secretly/onboarding_chat.png'),
  onboardingChatAr: () => require('../assets/secretly/onboarding_chat_ar.png'),
  logoIcon: () => require('../assets/secretly/logo_icon.png'),
  logoIconWithText: () => require('../assets/secretly/logo_with_text.png'),
  boostIcon: () => require('../assets/secretly/star.png'),
  playMarket: () => require('../assets/secretly/play_market.png'),
  appleStore: () => require('../assets/secretly/apple_store.png'),
  tabBarPurchase: () => require('../assets/secretly/tab_bar_purchase.png'),
  tabBarPurchaseDisabled: () =>
    require('../assets/secretly/tab_bar_purchase.png'),
  anonymousAvatar: () => require('../assets/secretly/anonymous_avatar.png'),
  defaultAvatar: () => require('../assets/secretly/default_avatar_white.png'),
  cupIcon: () => require('../assets/secretly/cup_small.png'),
  crownIcon: () => require('../assets/crown.png'),
  locationIcon: () => require('../assets/location.png'),
  callBenefitIcon: () => require('../assets/call_benefit.png'),
  vipIcon: (scale = '') => require(`../assets/secretly/vip${scale}.png`),
  superVipIcon: (scale = '') => require(`../assets/super_vip${scale}.png`),
  vipBadgeIcon: () => require('../assets/secretly/vip_badge_icon.png'),
  superVipBadgeIcon: () => require('../assets/super_vip_badge_icon.png'),
  callStart: () => require('../assets/call_start.png'),
  callEnd: () => require('../assets/call_end.png'),
  attention: () => require('../assets/attention.png'),
  splash: () =>
    Platform.OS === 'web'
      ? require('../assets/splash.png')
      : { uri: 'splash_screen' },
  shareIcon: () => require('../assets/share.png'),
};
