import React from 'react';
import PropTypes from 'prop-types';
import EventEmitter from 'eventemitter3';
import { WINK_TYPES } from '@sdv/domain/dialogs.wink';
import Resources from 'dating-mobile/src/resources';
import { subscriptionRequiredForMessages } from 'dating-mobile/src/services/tracking/paid-messages';
import { DELIVERY_STATUS, MESSAGE_TYPES, MessagesActionsError } from '../model';
import { randomValuesFromArray } from '../../../utils/random';

const MESSAGES_PER_PAGE = 30;

class LogController extends React.Component {
  static displayName = 'messages.log-controller';

  static propTypes = {
    bus: PropTypes.object,
    model: PropTypes.object,
    refillBalance: PropTypes.func,
    refillBalanceForCheer: PropTypes.func,
    subscribe: PropTypes.func,
    numberOfFastAnswerVariants: PropTypes.number,
    winksEnabled: PropTypes.bool,
    winkAnswers: PropTypes.arrayOf(PropTypes.string),
    userLikeAnswers: PropTypes.arrayOf(PropTypes.string),
    quickReplayAnswers: PropTypes.arrayOf(PropTypes.string),
    unlimitedMessagesWithMembershipEnabled: PropTypes.bool,
    trialMessagesEnabled: PropTypes.bool,
    membershipEnabled: PropTypes.bool,
    discountWithMembershipEnabled: PropTypes.bool,
    userIsCustomer: PropTypes.bool,
    canSubscribe: PropTypes.bool,
    instant: PropTypes.bool,
    children: PropTypes.node,
    autoReplyDelay: PropTypes.number,
    attendee: PropTypes.object,
    showPaymentScreenImmediatelyForPayedMessages: PropTypes.bool,
    creditsEnabledForCheers: PropTypes.bool,
    balance: PropTypes.shape({
      amount: PropTypes.number,
    }),
  };

  static contextTypes = {
    flux: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.bus = props.bus || new EventEmitter();
    this.model = props.model;

    this.state = {
      ...this.stateFromModelState(this.model.store.getState()),
      isLoading: false,
      allMessagesLoaded: false,
    };
  }

  componentDidMount() {
    this.model.store.listen(this.updateState);
    this.bus.addListener('event.messages.log.end-reached', this.showMore);
    this.bus.addListener('command.messages.log.purchase', this.purchase);
    this.bus.addListener(
      'command.messages.log.purchase-credits-for-cheer',
      this.refillBalanceForCheer,
    );
    this.bus.addListener('command.messages.log.subscribe', this.subscribe);
    this.bus.addListener('command.messages.log.send-text', this.sendText);
    this.bus.addListener('command.messages.log.send-sticker', this.sendSticker);

    this.reloadMessages();
  }

  componentDidUpdate(prevProps) {
    const { model, userIsCustomer, attendee } = this.props;
    const modelId = this.model.store.displayName;
    const newModelId = model.store.displayName;

    if (modelId !== newModelId) {
      this.model.store.unlisten(this.updateState);
      this.model = model;
      this.model.store.listen(this.updateState);

      // eslint-disable-next-line
      this.setState(
        {
          ...this.stateFromModelState(this.model.store.getState()),
          isLoading: false,
          allMessagesLoaded: false,
        },
        () => {
          this.reloadMessages();
        },
      );

      return;
    }

    if (
      userIsCustomer !== prevProps.userIsCustomer ||
      attendee?.name !== prevProps.attendee?.name
    ) {
      // eslint-disable-next-line
      this.setState(this.stateFromModelState(this.model.store.getState()));
    }
  }

  componentWillUnmount() {
    this.bus.removeListener('command.messages.log.send-text', this.sendText);
    this.bus.removeListener(
      'command.messages.log.send-sticker',
      this.sendSticker,
    );
    this.bus.removeListener('command.messages.log.purchase', this.purchase);
    this.bus.removeListener(
      'command.messages.log.purchase-credits-for-cheer',
      this.refillBalanceForCheer,
    );
    this.bus.removeListener('command.messages.log.subscribe', this.subscribe);
    this.bus.removeListener('event.messages.log.end-reached', this.showMore);
    this.model.store.unlisten(this.updateState);

    if (this.timerId) {
      clearTimeout(this.timerId);
    }
  }

  getRefillAction(message) {
    const {
      unlimitedMessagesWithMembershipEnabled,
      subscribe,
      refillBalance,
      showPaymentScreenImmediatelyForPayedMessages,
      refillBalanceForCheer,
      creditsEnabledForCheers,
      balance,
    } = this.props;

    if (!showPaymentScreenImmediatelyForPayedMessages) {
      return null;
    }

    if (
      refillBalanceForCheer &&
      creditsEnabledForCheers &&
      message?.type === MESSAGE_TYPES.CHEER
    ) {
      const minCreditsAmount =
        balance?.amount && message.content?.price
          ? message.content.price - balance.amount
          : null;

      return (successCb, errorCb) =>
        refillBalanceForCheer(successCb, errorCb, {
          minCreditsAmount,
          descriptionHint: null,
          descriptionTitle: minCreditsAmount
            ? Resources.strings.formatString(
                Resources.strings[
                  'chat-screen-purchase-credits-to-send-gifts-payment-title'
                ],
                minCreditsAmount,
              )
            : '',
        });
    }

    if (
      unlimitedMessagesWithMembershipEnabled &&
      message.type !== MESSAGE_TYPES.CHEER
    ) {
      if (this.canSubscribe()) {
        return subscribe;
      }

      return null;
    }

    return refillBalance;
  }

  stateFromModelState = state => {
    const { messages } = state;
    const {
      winksEnabled,
      winkAnswers,
      numberOfFastAnswerVariants,
      userLikeAnswers,
      quickReplayAnswers,
      trialMessagesEnabled,
      userIsCustomer,
      discountWithMembershipEnabled,
      unlimitedMessagesWithMembershipEnabled,
      attendee,
    } = this.props;
    const unpaidMessages = messages.filter(
      message => message.status === DELIVERY_STATUS.UNPAID,
    );
    const unpaidGifts = unpaidMessages.filter(
      message => message.type === MESSAGE_TYPES.CHEER,
    );

    const lastMessage = messages.length ? messages[0] : null;
    const fastAnswerVariants = [];

    if (
      winksEnabled &&
      winkAnswers &&
      winkAnswers.length &&
      lastMessage &&
      !lastMessage.outgoing &&
      lastMessage.reason
    ) {
      // eslint-disable-next-line
      switch (lastMessage.reason) {
        case WINK_TYPES.SEND_INVITE:
          fastAnswerVariants.push(
            ...randomValuesFromArray(winkAnswers, numberOfFastAnswerVariants),
          );
          break;
        case WINK_TYPES.LIKE_USER:
          fastAnswerVariants.push(
            ...randomValuesFromArray(
              userLikeAnswers,
              numberOfFastAnswerVariants,
            ),
          );
          break;
      }
    }

    const hasOnlyAutoMessage = messages.length === 1 && messages[0].autoMessage;

    if (hasOnlyAutoMessage && quickReplayAnswers && attendee?.name) {
      fastAnswerVariants.push(
        ...randomValuesFromArray(
          quickReplayAnswers,
          numberOfFastAnswerVariants,
        ).map(answer => Resources.strings.formatString(answer, attendee.name)),
      );
    }

    const freeMessagesAreOver =
      trialMessagesEnabled &&
      !userIsCustomer &&
      messages.filter(message => message.needPay).length > 0;
    const paymentRequired = unpaidMessages.length || freeMessagesAreOver;
    const shouldOfferMembership =
      discountWithMembershipEnabled && !userIsCustomer;

    if (hasOnlyAutoMessage && !this.autoReplayAdded) {
      this.addAutoReplay();
    }

    const subscribeRequired =
      this.canSubscribe() &&
      (unlimitedMessagesWithMembershipEnabled
        ? unpaidMessages.length > unpaidGifts.length
        : paymentRequired && shouldOfferMembership);

    // TODO: tmp
    subscriptionRequiredForMessages.next(!!subscribeRequired);

    return {
      messages,
      fastAnswerVariants,
      subscribeRequired,
      paymentRequired: unlimitedMessagesWithMembershipEnabled
        ? !!unpaidGifts.length
        : paymentRequired && (!this.canSubscribe() || !shouldOfferMembership),
    };
  };

  showMore = () => {
    const { isLoading, allMessagesLoaded, messages } = this.state;

    if (isLoading || allMessagesLoaded) {
      return;
    }

    this.setState({
      isLoading: true,
    });

    const presentedMessagesCount = messages.filter(
      message => message.status === DELIVERY_STATUS.DELIVERED,
    ).length;

    this.model.actions.get(MESSAGES_PER_PAGE, presentedMessagesCount, error => {
      if (
        error instanceof MessagesActionsError &&
        error.code === MessagesActionsError.CODES.PARTIAL_LOAD
      ) {
        this.setState({
          allMessagesLoaded: true,
          isLoading: false,
        });
      } else {
        this.setState({
          isLoading: false,
        });
      }
    });
  };

  updateState = state => {
    const { messages: prevMessages } = this.state;
    const previousLastMessage = prevMessages.length ? prevMessages[0] : null;

    this.setState(this.stateFromModelState(state), () => {
      const { messages } = this.state;
      const { unlimitedMessagesWithMembershipEnabled } = this.props;
      const lastMessage = messages.length ? messages[0] : null;

      if (lastMessage) {
        if (
          !previousLastMessage ||
          previousLastMessage.tag !== lastMessage.tag
        ) {
          this.bus.emit('command.messages.log.scroll-down');

          if (
            unlimitedMessagesWithMembershipEnabled &&
            lastMessage.outgoing &&
            lastMessage.status === DELIVERY_STATUS.DELIVERED &&
            lastMessage.type !== MESSAGE_TYPES.CHEER
          ) {
            this.removeUnpaidMessages(
              message => message.type === MESSAGE_TYPES.CHEER,
            );
          }

          if (
            unlimitedMessagesWithMembershipEnabled &&
            !lastMessage.outgoing &&
            messages.filter(message => !message.outgoing).length === 1
          ) {
            this.resendUnpaidMessages();
          }
        }

        if (
          lastMessage.status === DELIVERY_STATUS.UNPAID &&
          (!previousLastMessage ||
            previousLastMessage.tag !== lastMessage.tag ||
            previousLastMessage.status !== lastMessage.status)
        ) {
          this.refillBalanceFor(lastMessage);
        }
      }
    });
  };

  purchase = () => {
    const { refillBalance } = this.props;

    if (refillBalance) {
      refillBalance(() => {
        this.resendUnpaidMessages();
      });
    }
  };

  refillBalanceForCheer = price => {
    const { refillBalanceForCheer, balance } = this.props;
    const minCreditsAmount =
      balance?.amount && price ? price - balance.amount : null;

    if (refillBalanceForCheer) {
      refillBalanceForCheer(
        () => {
          this.resendUnpaidMessages();
        },
        () => {},
        {
          minCreditsAmount,
          descriptionHint: null,
          descriptionTitle: minCreditsAmount
            ? Resources.strings.formatString(
                Resources.strings[
                  'chat-screen-purchase-credits-to-send-gifts-payment-title'
                ],
                minCreditsAmount,
              )
            : '',
        },
      );
    }
  };

  subscribe = () => {
    const { unlimitedMessagesWithMembershipEnabled, subscribe } = this.props;

    if (unlimitedMessagesWithMembershipEnabled) {
      if (subscribe) {
        subscribe(
          () => {
            this.resendUnpaidMessages();
          },
          () => {},
          { shouldPopToTopOnPaymentCompleted: false },
        );
      }
    } else {
      this.purchase();
    }
  };

  sendText = (text, tag) => {
    const { model, instant, refillBalance } = this.props;

    if (typeof text === 'string' && text.length && model) {
      model.actions.send(MESSAGE_TYPES.TEXT, text, tag, instant, error => {
        if (
          error instanceof MessagesActionsError &&
          error.code === MessagesActionsError.CODES.PAYMENT_REQUIRED
        ) {
          if (refillBalance) {
            refillBalance(() => {
              this.sendText(text, error.tag);
            });
          }
        }
      });
    }
  };

  sendSticker = (sticker, tag) => {
    const { model, instant, refillBalance } = this.props;

    if (model) {
      model.actions.send(
        MESSAGE_TYPES.STICKER,
        sticker,
        tag,
        instant,
        error => {
          if (
            error instanceof MessagesActionsError &&
            error.code === MessagesActionsError.CODES.PAYMENT_REQUIRED
          ) {
            if (refillBalance) {
              refillBalance(() => {
                this.sendSticker(sticker, error.tag);
              });
            }
          }
        },
      );
    }
  };

  addAutoReplay() {
    const { autoReplyDelay } = this.props;

    this.autoReplayAdded = true;

    if (!this.timerId && autoReplyDelay) {
      this.timerId = setTimeout(() => {
        const { messages } = this.state;
        const hasOnlyAutoMessage =
          messages.length === 1 && messages[0].autoMessage;

        this.timerId = null;

        if (hasOnlyAutoMessage) {
          this.setState({
            messages: [
              {
                autoReply: true,
                content: Resources.strings['auto-replay-message-text'],
                outgoing: true,
                tag: 'autoReply',
                sender: messages[0].recipient,
                recipient: messages[0].sender,
                type: MESSAGE_TYPES.TEXT,
              },
              ...messages,
            ],
          });
        }
      }, autoReplyDelay);
    }
  }

  canSubscribe() {
    const { membershipEnabled, canSubscribe } = this.props;

    return membershipEnabled && canSubscribe;
  }

  reloadMessages() {
    this.removeUnpaidMessages();

    this.setState({
      isLoading: true,
    });

    this.model.actions.get(MESSAGES_PER_PAGE, 0, error => {
      if (
        error instanceof MessagesActionsError &&
        error.code === MessagesActionsError.CODES.PARTIAL_LOAD
      ) {
        this.setState({
          allMessagesLoaded: true,
          isLoading: false,
        });
      } else {
        this.setState({
          isLoading: false,
        });
      }
    });
  }

  refillBalanceFor(message) {
    if (this.isRefilling) {
      return;
    }
    const refillAction = this.getRefillAction(message);

    if (refillAction) {
      this.isRefilling = true;

      refillAction(
        () => {
          this.isRefilling = false;
          this.resendUnpaidMessages();
        },
        () => {
          this.isRefilling = false;
        },
      );
    }
  }

  removeUnpaidMessages(filter) {
    const { messages } = this.state;
    const unpaidMessages = messages
      .filter(
        message =>
          message.status === DELIVERY_STATUS.UNPAID &&
          (!filter || filter(message)),
      )
      .reverse();

    unpaidMessages.forEach(message => {
      this.model.actions.delete(message.tag);
    });
  }

  resendUnpaidMessages() {
    const { instant } = this.props;
    const { messages } = this.state;
    const unpaidMessages = messages
      .filter(message => message.status === DELIVERY_STATUS.UNPAID)
      .reverse();

    unpaidMessages.forEach(message => {
      this.model.actions.send(
        message.type,
        message.content,
        message.tag,
        instant,
      );
    });
  }

  render() {
    const { children } = this.props;

    return React.Children.map(children, child =>
      React.cloneElement(child, {
        ...this.state,
        bus: this.bus,
      }),
    );
  }
}

export default LogController;
