import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { FaqList } from '../../views/faq-list';

import styles from './styles';

export default class ScreenView extends PureComponent {
    static displayName = 'faq.screens.faq-categories.view';

    static propTypes = {
        topics: PropTypes.array,
        goToDetails: PropTypes.func,
    };

    render() {
        return (
            <View style={styles.container}>
                <FaqList {...this.props} />
            </View>
        );
    }
}
