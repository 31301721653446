import React from 'react';
import PropTypes from 'prop-types'
import withRoles from './index';

const ROLE_DEMO = 'demo';

export default function createController(Component) {

    class IsAnonymous extends React.Component {
        static displayName = 'user-roles.controller.is-anonymous';

        static propTypes = {
            roles: PropTypes.array
        };

        getIsAnonymous = () => {
            const { roles } = this.props;
            return roles && roles.includes(ROLE_DEMO);
        };

        render() {
            const isAnonymous = this.getIsAnonymous();

            return (
                <Component {...this.props} isAnonymous={isAnonymous}/>
            );
        }

    }

    return withRoles(
        IsAnonymous,
    );
}
