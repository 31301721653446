import {
    map,
    distinctUntilChanged,
    filter,
    switchMap,
    take
} from 'rxjs/operators';
import { defer, of, Observable } from 'rxjs';
import '@sdv/commons/rx/store';
import Identity from '@sdv/domain/identity/model';
import EmailValidation from '@sdv/domain/electronicmail.addresses';
import flux from '@sdv/domain/app/flux';

export const ChangeEmailResult = Object.freeze({
    success: 1,
    invalidFormat: 2,
    notReal: 3,
    alreadyTaken: 4
});

export const ChangePasswordResult = Object.freeze({
    success: 1,
    smallLength: 2
});

export class UserCredentials {

    constructor(user) {
        this.user = user;

        const identity = flux.get(Identity);

        this.email = identity.store.rxState()
            .pipe(
                map(state => state.email || null),
                distinctUntilChanged()
            );

        const emailIsReal = (email) => {
            return defer(() => {
                const emailValidation = flux.get(EmailValidation);
                emailValidation.actions.validate(email);
                return emailValidation.store.rxState()
                    .pipe(
                        map(state => state.emails[email]),
                        filter(value => typeof value === 'boolean'),
                        take(1)
                    );
            });
        };

        this.changeEmail = (email) => {
            return defer(() => {

                if (!email || !email.trim() || !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email.trim())) {
                    return of(ChangeEmailResult.invalidFormat);
                }

                return emailIsReal(email)
                    .pipe(
                        switchMap(isReal => {
                            if (!isReal) {
                                return of(ChangeEmailResult.notReal);
                            }

                            return new Observable(observer => {
                                identity.actions.patch({ email: email.trim() }, error => {
                                    if (error) {
                                        if (error.status === 400 && error.src === 'email') {
                                            observer.next(ChangeEmailResult.alreadyTaken);
                                        } else {
                                            observer.error(error);
                                        }
                                    } else {
                                        observer.next(ChangeEmailResult.success);
                                    }
                                    observer.complete();
                                });
                            });
                        })
                    );
            });
        };

        this.changePassword = (password) => {
            if (!password || password.length < 4) {
                return of(ChangePasswordResult.invalidFormat);
            }

            return new Observable(observer => {
                identity.actions.patch({ password: password }, error => {
                    if (error) {
                        observer.error(error);
                    } else {
                        observer.next(ChangeEmailResult.success);
                    }
                    observer.complete();
                });
            });
        };
    }

}
