import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'react-native';
import styles from './styles';
import user from '../../../../../models/user/controllers';
import Resources from "../../../../../resources";

class ChatRequestsHeader extends React.Component {
    static displayName = 'dialogs.chats.chat-requests.header';
    static propTypes = {
        firstUserId: PropTypes.string,
        secondUserId: PropTypes.string,
        firstUser: PropTypes.object,
        secondUser: PropTypes.object,
        totalCount: PropTypes.number,
        onPress: PropTypes.func
    };

    shouldComponentUpdate(nextProps) {
        const firstUserName = this.props.firstUser && this.props.firstUser.name;
        const nextFirstUserName = nextProps.firstUser && nextProps.firstUser.name;
        const secondUserName = this.props.secondUser && this.props.secondUser.name;
        const nextSecondUserName = nextProps.secondUser && nextProps.secondUser.name;
        return firstUserName !== nextFirstUserName ||
            secondUserName !== nextSecondUserName ||
            this.props.totalCount !== nextProps.totalCount;
    }

    onPressed = () => {
        this.props.onPress && this.props.onPress(this.props.user);
    };

    render() {
        let firstUserName = this.props.firstUser && this.props.firstUser.name;
        let secondUserName = this.props.secondUser && this.props.secondUser.name;

        let text;
        if (firstUserName && secondUserName) {
            if (secondUserName) {
                if (+this.props.totalCount <= 2) {
                    text = Resources.strings.formatString(
                        Resources.strings['my-chats-screen-information-about-two-invitations-format'],
                        firstUserName,
                        secondUserName
                    );
                } else {
                    text = Resources.strings.formatString(
                        Resources.strings['my-chats-screen-information-about-invitations-format'],
                        firstUserName,
                        secondUserName,
                        this.props.totalCount - 2
                    );
                }
            }
        } else {
            firstUserName
                ? Resources.strings.formatString(
                    Resources.strings['my-chats-screen-information-about-one-invitation-format'],
                    firstUserName
                )
                : '';
        }

        return <Text style={styles.text}>{text}</Text>;
    }
}

export default user(
    user(
        ChatRequestsHeader,
        {
            userIdPropName: 'secondUserId',
            resultPropName: 'secondUser'
        }
    ),
    {
        userIdPropName: 'firstUserId',
        resultPropName: 'firstUser'
    }
)
