import React from 'react';
import { Text, View, Image } from 'react-native';
import styles from './styles';
import Photo from '../../../../../components/photo';

class FanListItem extends React.Component {
    static displayName = 'dialogs.stream.info.fan.item';
    render() {
        const {...props} = this.props.user;
        const basename = props.thumbnail || props['thumbnail-pending'];

        return (
            <View style={styles.container}>
                <View style={{flexDirection: 'row', marginLeft: 20, alignItems: 'center', justifyContent: 'center'}}>
                    <Photo
                        key={basename}
                        userId={props.id}
                        basename={basename}
                        round={true}
                        style={styles.image}
                        placeholder={'avatar'}
                    />
                    <Text style={styles.nameText}>{props.name}</Text>
                </View>
                <View style={styles.diamondsView}>
                    <Text style={styles.diamondsCounterText}>{this.props.amount}</Text>
                    <Image style={{width: 16, height: 14}} source={require('./../assets/diamond.png')}/>
                </View>
            </View>
        )
    }
}

export default FanListItem;
