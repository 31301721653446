import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, TouchableOpacity, Image, StatusBar, BackHandler } from 'react-native';
import { SafeAreaView } from 'react-navigation';
import styles from './styles';
import Resources from '../../../../resources';
import Button from '../../../../components/buttons/base';
import UserPreferencesController from '../../../../models/user.preference/controllers';
import IdentityController from '../../../../models/identity/controller';

import configValue from '../../../../components/config-value';
import oppositeGender from '../../../../utils/opposite-gender';
import testId from '../../../../utils/test-id';

class GenderPicking extends React.PureComponent {
    static displayName = 'user.edit.gender-picking.view';
    static propTypes = {
        defaultMinAge: PropTypes.number,
        defaultMaxAge: PropTypes.number,
        emptyPreferredGenderEnabled: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.backButtonHandler = BackHandler.addEventListener('hardwareBackPress', () => { return true });
    }

    componentWillUnmount() {
        this.backButtonHandler.remove();
    }

    disabled = () => {
        return !this.state.gender && (!this.props.emptyPreferredGenderEnabled || this.state.gender !== null);
    };

    submit = () => {
        const changes = {
            'preferred-gender': this.state.gender
        };

        if (!this.props.preference.minage) {
            changes.minage = this.props.defaultMinAge;
        }

        if (!this.props.preference.maxage) {
            changes.maxage = this.props.defaultMaxAge;
        }

        this.props.updatePreference(changes).then(() => {
            this.props.setSearchParams && this.props.setSearchParams(changes);
            const onComplete = this.props.navigation.getParam('onComplete') || (() => this.props.navigation.pop());
            onComplete();
        });
    };

    itemPressed = (gender) => () => {
        if (this.state.gender === gender) {
            return;
        }

        this.setState({
            gender: this.state.gender
                ? (this.props.emptyPreferredGenderEnabled ? null : gender)
                : (this.props.emptyPreferredGenderEnabled && this.state.gender === null ? oppositeGender(gender) : gender)
        });
    };

    render() {
        const text = Resources.strings['gender-picking-screen-title'];

        const maleButtonSelected = this.state.gender === 'mal' || (this.state.gender === null && this.props.emptyPreferredGenderEnabled);
        const femaleButtonSelected = this.state.gender === 'fem' || (this.state.gender === null && this.props.emptyPreferredGenderEnabled);

        const selected = styles.selectedImage;
        const unselected = styles.unselectedImage;
        const manImageStyle = [styles.buttonImage, maleButtonSelected ? selected : unselected];
        const womanImageStyle = [styles.buttonImage, femaleButtonSelected ? selected : unselected];

        const manButtonStyle = [
            styles.button,
            maleButtonSelected
                ? styles.selectedMaleImageContainer
                : styles.unselectedImageContainer
        ];
        const womanButtonStyle = [
            styles.button,
            femaleButtonSelected
                ? styles.selectedFemaleImageContainer
                : styles.unselectedImageContainer
        ];
        return (
            <SafeAreaView forceInset={{top: 'never', bottom: 'always'}} style={styles.container}>
                <StatusBar
                    barStyle={styles.$statusBarStyle}
                    backgroundColor={styles.$statusBarBackgroundColor}
                />
                <Text style={styles.title}>{text}</Text>
                <View style={styles.buttonsContainer}>
                    <TouchableOpacity style={manButtonStyle} onPress={this.itemPressed('mal')} {...testId('Man button')}>
                        <Image style={manImageStyle} source={Resources.images.guyIcon()}/>
                    </TouchableOpacity>
                    <TouchableOpacity style={womanButtonStyle} onPress={this.itemPressed('fem')} {...testId('Woman button')}>
                        <Image style={womanImageStyle} source={Resources.images.girlIcon()}/>
                    </TouchableOpacity>
                </View>
                <View style={styles.nextButton}>
                    <Button
                        disabled={this.disabled()}
                        title={Resources.strings['about-yourself-screen-button-title']}
                        onPress={this.submit}
                        {...testId('Next step button')}
                    />
                </View>
            </SafeAreaView>
        );

    }
}

const GenderPickingComponent = configValue(UserPreferencesController(GenderPicking), {
    defaultMinAge: 'preferences.default-min-age',
    defaultMaxAge: 'preferences.default-max-age',
    emptyPreferredGenderEnabled: 'features.empty-preferred-gender-enabled',
});

const GenderPickingScreen = IdentityController((props) => {
    return <GenderPickingComponent {...props} id={props.identity.id}/>
});

export default GenderPickingScreen;
