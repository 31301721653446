import React from 'react';
import PropTypes from 'prop-types';

function controller(Component) {

    class Controller extends React.Component {
        static displayName = 'components.webview.view.controller';
        static propTypes = {
            url: PropTypes.string,
            request: PropTypes.object,
            onRedirect: PropTypes.func
        };

        render() {
            return (<Component
                url={this.props.url}
                request={this.props.request}
                onUrlChange={this.props.onRedirect}
            />);
        }
    }

    return Controller;
}

export default controller;
