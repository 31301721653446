import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  $foregroundColor: '$brandTextColor',
  $titleVisible: true,
  $descriptionIconVisible: false,
  $backgroundColor: '$primaryBackgroundColor',
  $statusBarStyle: '$primaryStatusBarStyle',
  $statusBarBackgroundColor: '$primaryBackgroundColor',
  $descriptionTitleVisible: true,
  $balanceVisible: false,

  header: {
    backgroundColor: '$backgroundColor',
    borderBottomColor: '$backgroundColor',
    shadowColor: '$backgroundColor',
  },

  headerTitle: {
    color: '$foregroundColor',
  },

  container: {
    backgroundColor: '$backgroundColor',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'stretch',
  },

  balanceContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 56,
  },

  balanceTitle: {
    fontSize: 16,
    marginBottom: 16,
    color: '$foregroundColor',
    fontWeight: '500',
    fontFamily: '$brandFontFamily',
  },

  descriptionContainer: {
    marginLeft: 40,
    marginRight: 40,
    marginBottom: 36,
  },

  descriptionIcon: {
    marginTop: 14,
    width: 90,
    height: 90,
  },

  descriptionTitle: {
    marginTop: 32,
    fontWeight: 'bold',
    color: '$primaryTextColor',
    fontFamily: '$brandFontFamily',
    textAlign: 'center',
    fontSize: 20,
  },

  description: {
    marginTop: 5,
  },

  products: {
    flex: 1,
    marginBottom: 40,
    overflow: 'visible',
    marginHorizontal: 9,
  },

  getDiscount: {
    marginTop: 24,
    marginHorizontal: 20,
  },

  getDiscountButton: {
    backgroundColor: '$accentTextColor',
  },

  getDiscountButtonTitle: {
    color: '$accentColor',
    fontSize: 16,
  },

  activityIndicatorContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },

  placeholderContainer: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },

  placeholder: {
    color: '$foregroundColor',
    fontFamily: '$brandFontFamily',
    fontWeight: 'normal',
    fontSize: 14,
  },

  boostDiscountTimeout: {
    marginHorizontal: 40,
    marginBottom: 30,
  },

  boostTimeout: {
    marginBottom: 30,
  },
});

export default styles;
