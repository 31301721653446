import React from 'react';
import Service from './service';
import FunctionalService from './functional';
import TrackingService from './tracking';

export default class Services extends Service {
    static displayName = 'services';

    render() {
        return [
            (<FunctionalService key="functional" />),
            (<TrackingService key="tracking" />)
        ];
    }
}
