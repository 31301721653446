import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { chunk } from 'lodash';
import EStyleSheet from 'react-native-extended-stylesheet';
import CheckboxItem from '../checkbox-item';
import styles from './styles';

export default class CheckboxOfImages extends PureComponent {
    static propTypes = {
        options: PropTypes.shape({
            id: PropTypes.string,
            onPress: PropTypes.func,
            image: PropTypes.string,
        }),
        numberOfColumns: PropTypes.number,
    };

    static defaultProps = {
        numberOfColumns: 1,
    };

    render() {
        const { options, numberOfColumns } = this.props;
        const rows = chunk(options, numberOfColumns);

        return (
            <View style={styles.container}>
                {rows.map(columns => (
                    <View style={styles.row} key={columns[0].id}>
                        {columns.map((option, index) => (
                            <View
                                style={EStyleSheet.child(styles, 'column', index, columns.length)}
                                key={option.id}
                            >
                                <CheckboxItem {...option} />
                            </View>
                        ))}
                    </View>
                ))}
            </View>
        );
    }
}
