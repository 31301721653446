import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import Resources from 'dating-mobile/src/resources';
import styles from './styles';

class FreeCreditsBannerView extends React.PureComponent {
    static displayName = 'feed.views.free-credits-banner.view';
    static propTypes = {
        visible: PropTypes.bool,
        freeCreditsAmount: PropTypes.number,
        onClosePress: PropTypes.func,
    };

    render() {
        const freeCredits = this.props.freeCreditsAmount ? this.props.freeCreditsAmount : '';
        const freeCreditsText = Resources.strings.formatString(
            Resources.strings['free-credits-banner-description'],
            freeCredits
        );
        return this.props.visible
            ? (
                <View style={styles.container}>
                    <View style={styles.iconWithTextContainer}>
                        <Image style={styles.image} source={Resources.images.coinsIcon()}/>
                        <View style={styles.textContainer}>
                            <Text style={styles.title}>{Resources.strings["free-credits-banner-title"]}</Text>
                            <Text style={styles.description}>{freeCreditsText}</Text>
                        </View>
                    </View>
                    <TouchableOpacity style={styles.closeButton} onPress={this.props.onClosePress}>
                        <Text style={styles.closeTitle}>{Resources.strings['free-credits-banner-close-button-title']}</Text>
                    </TouchableOpacity>
                </View>
            )
            : null;
    }

}

export default FreeCreditsBannerView;

