import { createOptimisticActions } from '@sdv/model';

export const USER_GOALS = Object.freeze({
    FRIENDSHIP: 'fri',
    DATING: 'dat',
    CHAT: 'cha'
});

function createUserGoalsActions(id) {
    class Actions extends createOptimisticActions(['put']) {

        get = () => (dispatch, flux) => {
            const resource = flux.api.variable('user-id') === id
                ? flux.api.users.private.goals
                : flux.api.users.goals;
            resource.get(id, (error, goals) => {
                if (error) {
                    dispatch(null, error);
                    return;
                }
                dispatch({
                    goals: goals
                });
            });
        };

        put(goals) {
            return super.put(goals, function (goals) {
                this.dispatch({
                    goals: goals
                });
                this.flux.api.users.goals.put(goals, (error) => {
                    if (error) {
                        return this.revert(error);
                    }
                    return this.commit();
                });
            });
        };

    }

    Actions.displayName = createUserGoalsActions.getDisplayName(id);

    return Actions;
}

createUserGoalsActions.getDisplayName = function(id) {
    return `user-goals.${id}`;
};

export default createUserGoalsActions;
