import EStyleSheet from 'react-native-extended-stylesheet';

const defaultContentStyle = {
  alignItems: 'center',
  maxWidth: '100% - 80', // delivery style width = 32 + delivery marginRight = 8 + log list container horizontal padding = 16 * 2
};

const styles = EStyleSheet.create({
  $deliveryTimeAlwaysVisible: true,

  containerOutgoing: {
    alignItems: 'flex-end',
  },

  containerIncoming: {
    alignItems: 'flex-start',
  },

  contentOutgoing: {
    ...defaultContentStyle,
    flexDirection: 'row-reverse',
  },

  contentIncoming: {
    ...defaultContentStyle,
    flexDirection: 'row',
  },

  containerAutoReply: {
    alignItems: 'center',
  },

  date: {
    fontSize: 10,
    marginTop: 2,
    color: '$secondaryTextColor',
    fontFamily: '$brandFontFamily',
  },

  delivery: {
    marginRight: 8,
    width: 32,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
  },

  deliveryText: {
    color: '$accentColor',
  },

  userThumbnail: {
    width: '100%',
    height: '100%',
  },

  attentionIcon: {
    marginLeft: 10,
  },

  notDeliveredLabel: {
    fontSize: 12,
    marginTop: 2,
    color: '#e02020',
    fontFamily: '$brandFontFamily',
  },
});

export default styles;
