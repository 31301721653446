import EStyleSheet from 'react-native-extended-stylesheet';
import { Platform } from 'react-native';

const coverSize = 96;

const styles = EStyleSheet.create({
    $placeholderColor: '$primaryPlaceholderColor',
    header: {
        backgroundColor: 'transparent',
        borderBottomColor: 'transparent',
        shadowColor: 'transparent',
        shadowRadius: 0,
        shadowOpacity: 0,
        elevation: 0,
        shadowOffset: {
            height: 0,
        },
        ...Platform.select({
            android: {
                marginTop: 20,
            },
        }),
    },

    content: {
        flex: 1,
    },
    container: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        flex: 1,
        paddingTop: 85, // TODO  нужно как-то по-другому...
        backgroundColor: 'transparent',
        paddingHorizontal: 10,
    },
    contentContainer: {
        height: '100%',
        justifyContent: 'space-between',
    },
    presetContainer: {
        flexDirection: 'row',
        backgroundColor: '$shadowColor',
        justifyContent: 'space-between',
        padding: 16,
        borderRadius: 12,
    },

    buttonContainer: {
        alignItems: 'center',
        paddingTop: 14,
        paddingBottom: 30,
    },
    button: {
        width: 256,
    },
    input: {
        fontSize: 17,
        color: '$brandTextColor',
        padding: 0,
        margin: 0,
        fontFamily: '$brandFontFamily',
    },
    bold: {
        fontWeight: 'bold',
    },
    cover: {
        backgroundColor: '$primaryBackgroundColor',
        width: coverSize,
        height: coverSize,
        borderRadius: coverSize / 2,
        flexShrink: 0,
        flexGrow: 0,
        overflow: 'hidden',
    },
    image: {
        flexGrow: 1,
        flexShrink: 0,
        flex: 1,
    },
    changeCover: {
        backgroundColor: '$shadowColor',
        paddingHorizontal: 15,
        height: 32,
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
    },
    changeCoverText: {
        fontFamily: '$brandFontFamily',
        textAlign: 'center',
        fontSize: 10,
        color: '$brandTextColor',
    },
    info: {
        flex: 1,
        flexShrink: 0,
        flexGrow: 1,
        paddingTop: 10,
        paddingLeft: 10,
        justifyContent: 'space-between',
    },
    topics: {
        alignSelf: 'stretch',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    topicContainer: {
        height: 30,
    },
    topicHint: {
        borderColor: '$accentColor',
        borderWidth: 2,
        height: 34,
        margin: 3,
    },
    topicText: {
        fontSize: 11,
    },
});

export default styles;
