import EStyleSheet from 'react-native-extended-stylesheet';
import { Platform } from 'react-native';

const styles = EStyleSheet.create({
    $statusBarStyle: '$primaryStatusBarStyle',
    $statusBarBackgroundColor: '$primaryBackgroundColor',

    container: {
        flex: 1,
        backgroundColor: '$primaryBackgroundColor',
    },

    scroll: {
        flexGrow: 1,
    },

    scrollContent: {
        flexGrow: 1,
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'space-between',
    },

    messagesContainer: {
        marginTop: 24,
        height: 72,
        marginBottom: 16,
    },

    chooseMessageButton: {
        alignSelf: 'center',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: 'white',
        marginTop: 24,
        height: 48,
        borderRadius: 24,
        marginBottom: 16,
        paddingHorizontal: 22,
        shadowRadius: 8,
        shadowOpacity: 0.15,
        shadowOffset: { width: 0, height: 0 },
        elevation: 8,
    },

    chooseMessageButtonTitle: {
        fontSize: 16,
        fontFamily: '$brandFontFamily',
        color: '$infoTextColor',
    },

    headerContainer: {
        alignItems: 'center',
        ...Platform.select({
            android: {
                marginTop: 20,
            },
            ios: {
                marginTop: 40,
            },
        }),
    },

    title: {
        fontFamily: '$brandFontFamily',
        color: '$primaryTextColor',
        fontSize: 24,
        fontWeight: 'bold',
        textAlign: 'left',
        width: '100%',
        paddingHorizontal: 30,
    },

    description: {
        fontFamily: '$brandFontFamily',
        color: '$primaryTextColor',
        marginTop: 5,
        fontSize: 16,
        textAlign: 'left',
        width: '100%',
        paddingHorizontal: 30,
    },

    listContainer: {
        marginTop: 24,
    },

    activityIndicatorContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

export default styles;
