import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        width: '100%',
        flex: 1,
    },

    collection: {
        flex: 1
    },

    itemContainer: {
        backgroundColor: '$brandBorderColor',
    },

    thumbnailPlaceholder: {
        width: 96,
        height: 96,
        overflow: 'hidden',
        '@flavor lovinga, udates': {
            borderRadius: 15,
            marginRight: 8
        },
        '@flavor dma': {
            width: 136,
            height: 136,
            marginLeft: 12,
            borderRadius: 20,
        }
    },

    firstItemContainer: {
        borderBottomLeftRadius: 10,
        borderTopLeftRadius: 10,
        '@flavor lovinga, udates': {
            borderBottomLeftRadius: 15,
            borderTopLeftRadius: 15
        },
        '@flavor dma': {
            borderBottomLeftRadius: 20,
            borderTopLeftRadius: 20
        }
    },

    lastItemContainer: {
        borderBottomRightRadius: 10,
        borderTopRightRadius: 10,
        '@flavor lovinga, udates': {
            borderBottomRightRadius: 15,
            borderTopRightRadius: 15
        },
        '@flavor dma': {
            borderBottomRightRadius: 20,
            borderTopRightRadius: 20
        }
    },

    addPhotoBackground: {
        backgroundColor: '$accentColor',
        borderRadius: 15,
        height: 96,
        aspectRatio: 1,
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 8,
        marginLeft: 16,
        '@flavor secretly': {
            backgroundColor: '$minorAccentColor',
        },
        '@flavor dma': {
            height: 136
        }
    },

    buttonContainer: {
        backgroundColor: '$accentColor',
        marginTop: 24,
        marginLeft: 16,
        marginRight: 16,
        borderRadius: 20,
        height: 60,
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonText: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '$accentTextColor'
    }
});

export default styles;
