import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Stock } from '@sdv/domain/payment/stock';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';

export const SUBSCRIBE_MOTIVATION = Object.freeze({
    none: 0,
    save: 1,
    most_popular: 2,
    best_price: 3,
});

export default function bindController(Component) {
    class SubscribePlansController extends PureComponent {
        static propTypes = {
            onSelectPlan: PropTypes.func,
            id: PropTypes.string,
        };

        state = {
            currentPlan: 0,
            plans: [],
        };

        componentDidMount() {
            this.subscribe();
        }

        componentDidUpdate(prevProps) {
            const { id } = this.props;

            if (id !== prevProps.id) {
                this.unsubscribe();
                this.subscribe();
            }
        }

        componentWillUnmount() {
            this.unsubscribe();
        }

        sortPacks = packages => {
            let membershipPacks = packages.filter(pack => pack[0].containsMembership);

            let maxPrice = 0;
            let maxPackAtIndex = 0;

            let freePackIndex;

            membershipPacks.forEach((pack, index) => {
                const price = parseFloat(pack[0].price?.localizedWithoutCurrency);

                if (maxPrice < price) {
                    maxPrice = price;
                }

                if (!!pack[0].price?.trialPeriod && !freePackIndex) {
                    freePackIndex = index;
                }
                maxPackAtIndex = index;
            });

            const biggestItem = membershipPacks.splice(maxPackAtIndex, 1);

            if (biggestItem && biggestItem.length > 0) {
                biggestItem[0].mark = SUBSCRIBE_MOTIVATION.best_price;
            }

            const freeItem = membershipPacks.splice(freePackIndex, 1);

            if (freeItem && freeItem.length > 0) {
                freeItem[0].mark = SUBSCRIBE_MOTIVATION.most_popular;
            }
            if (membershipPacks[0]) {
                membershipPacks[0].mark = SUBSCRIBE_MOTIVATION.save;
            }

            membershipPacks = (biggestItem || []).concat(freeItem || []).concat(membershipPacks);

            return membershipPacks || [];
        };

        handleSelectPlan = id => {
            const { onSelectPlan } = this.props;
            const { plans } = this.state;

            if (onSelectPlan) {
                const selectedInvoice = plans.find(plan => plan.id === id)?.invoice;

                onSelectPlan(selectedInvoice);
            }

            this.setState({
                currentPlan: id,
            });
        };

        subscribe() {
            const { id } = this.props;

            if (!id) {
                return;
            }

            this.subscription = Stock.shared(id).preloadedPackagesInStock.subscribe(
                packages => {
                    const membershipPacks = this.sortPacks(packages);
                    let counter = 0;
                    let newCurrentPlan = 0;

                    const plans = membershipPacks.map(pack => {
                        const product = pack[0];

                        counter += 1;

                        const trialPeriod = product.price?.trialPeriod;
                        const subscriptionPeriod = product.price?.subscriptionPeriodNumber;
                        const subscriptionUnit = product.price?.subscriptionPeriodUnit;

                        const subscribeMotivation = pack.mark || SUBSCRIBE_MOTIVATION.none;
                        const price = product.price?.localizedIncludingTax;

                        if (pack.mark === SUBSCRIBE_MOTIVATION.most_popular) {
                            newCurrentPlan = counter;
                        }

                        const invoice = pack[1][0];

                        return {
                            id: counter,
                            invoice,
                            price,
                            subscribeMotivation,
                            trialPeriod,
                            subscriptionPeriod,
                            subscriptionUnit,
                        };
                    });

                    this.setState(
                        {
                            plans,
                            isLoading: false,
                        },
                        () => {
                            const { currentPlan } = this.state;

                            if (!plans.some(plan => plan.id === currentPlan)) {
                                this.handleSelectPlan(newCurrentPlan);
                            }
                        },
                    );
                },
                () => {},
            );
        }

        unsubscribe() {
            if (this.subscription) {
                this.subscription.unsubscribe();
                this.subscription = null;
            }
        }

        render() {
            return (
                <Component {...this.props} {...this.state} onSelectPlan={this.handleSelectPlan} />
            );
        }
    }

    return withIdentityId(SubscribePlansController, 'id');
}
