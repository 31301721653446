import React from 'react';
import { Keyboard } from 'react-native';
import PropTypes from 'prop-types';

import { Buffer } from 'buffer';
import type from '@sdv/commons/utils/type';
import Resources from 'dating-mobile/src/resources';

function createController(Component) {
    class Modal extends React.Component {
        static contextTypes = {
            flux: PropTypes.object,
        };

        static propTypes = {
            error: PropTypes.string,
            isRealEmail: PropTypes.bool,
            onSubmit: PropTypes.func,
        };

        static errorMessage = {
            name: Resources.strings['error-message-name'],
            email: Resources.strings['error-message-email'],
            password: Resources.strings['error-message-password'],
            password2: Resources.strings['error-message-password2'],
        };

        constructor(props, context) {
            super(props);
            this.flux = context.flux;
            this.state = {
                error: {
                    server: props.error,
                },
            };
        }

        componentWillReceiveProps(nextProps) {
            const { error } = this.props;

            if (nextProps.error !== error) {
                this.setState(prevState => ({
                    error: {
                        ...prevState.error,
                        server: nextProps.error,
                    },
                }));
            }
        }

        handlerChange = name => event => {
            const value = event.nativeEvent.text;

            this.setState({
                [name]: value,
            });
        };

        onPressButton = async () => {
            Keyboard.dismiss();

            const { error, ...credentials } = this.state;
            const { isRealEmail, onSubmit } = this.props;

            if (isRealEmail) {
                this.setState({ isPending: true });

                try {
                    await validateRealEmail(this.flux, credentials.email);

                    this.setState({ isPending: false });
                } catch (err) {
                    if (error.email !== err.message) {
                        this.setState(prevState => ({
                            isPending: false,
                            error: {
                                ...prevState.error,
                                email: err.message,
                            },
                        }));
                    }

                    return;
                }
            }

            const isValid = Object.keys(credentials).every(key => this.handleValidation(key)());

            if (!isValid) {
                return;
            }

            onSubmit(Component.displayName, credentials);
        };

        handleValidation = name => () => {
            const { [name]: value, error } = this.state;

            if (type.isUndefined(value)) {
                return false;
            }

            const isValid = this.validate(name, value)();
            const message = !isValid ? Modal.errorMessage[name] : null;

            if (error[name] !== message) {
                this.setState(prevState => ({
                    error: {
                        ...prevState.error,
                        [name]: message,
                    },
                }));
            }

            return isValid;
        };

        validate(name, value) {
            const { password } = this.state;

            switch (name) {
                case 'email':
                    return () => value.trim() && /\S+@\S+\.\S+/.test(value.trim());
                case 'password':
                    return () => value && String(value).length >= 4;
                case 'password2':
                    return () => value && String(value) === password;
                case 'name':
                    return () => value.trim() && String(value.trim()).length >= 0;
                default:
                    return () => true;
            }
        }

        render() {
            return (
                <Component
                    {...this.props}
                    {...this.state}
                    handlerChange={this.handlerChange}
                    onSubmit={this.onPressButton}
                    validate={this.handleValidation}
                />
            );
        }
    }

    return Modal;
}

export default createController;

function validateRealEmail(flux, email) {
    return new Promise((resolve, reject) => {
        flux.api.electronicmail.addresses.get(
            Buffer.from(email).toString('base64'),
            (error, response) => {
                if (error || !response.valid) {
                    return reject(
                        error ||
                            new Error(Resources.strings['sign-up-screen-email-validation-error']),
                    );
                }

                return resolve();
            },
        );
    });
}
