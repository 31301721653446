import { singleton } from '@sdv/commons/utils/singleton';
import { of } from 'rxjs';
import { shareReplay, switchMap, take } from 'rxjs/operators';
// import { ResourceProfileBooster } from './resource-profile-booster';
import { ProductProfileBooster } from './product-profile-booster';

export class ProfileBooster {
  static shared = singleton(userId => new ProfileBooster(userId));

  constructor(userId) {
    // TODO:
    this.currentBooster = of(new ProductProfileBooster(userId)).pipe(
      shareReplay(1),
    );

    this.endTime = this.currentBooster.pipe(
      switchMap(booster => booster.endTime),
    );
  }

  boost() {
    return this.currentBooster.pipe(
      take(1),
      switchMap(booster => booster.boost()),
    );
  }
}
