// Initial component you can find by link: https://github.com/localz/react-native-searchbar/blob/master/index.js
import React, { Component } from 'react';
import { View, TextInput } from 'react-native';
import PropTypes from 'prop-types';
import ImageButton from 'dating-mobile/src/components/navigation/views/image-button';
import Resources from '../../resources';
import styles from './styles';

export default class Search extends Component {
    static propTypes = {
        placeholder: PropTypes.string,
        handleChangeText: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        onHide: PropTypes.func,
        onClose: PropTypes.func,
        clearOnBlur: PropTypes.bool,
        focusOnLayout: PropTypes.bool,
        keyboardAppearance: PropTypes.string,
    };

    static defaultProps = {
        placeholder: 'Search',
        clearOnBlur: false,
        focusOnLayout: true,
        keyboardAppearance: 'default',
    };

    constructor(props) {
        super(props);

        this.state = {
            input: '',
        };
    }

    getValue = () => {
        const { input } = this.state;

        return input;
    };

    setValue = input => {
        return this.setState({ input });
    };

    hide = () => {
        const { onHide } = this.props;
        const { input } = this.state;

        if (onHide) {
            onHide(input);
        }
    };

    _onClose = () => {
        const { onClose } = this.props;

        this._clearInput();

        if (onClose) {
            onClose();
        }
    };

    _handleBlur = () => {
        const { onBlur, clearOnBlur } = this.props;

        if (onBlur) {
            onBlur();
        }

        if (clearOnBlur) {
            this._clearInput();
        }
    };

    _clearInput = () => {
        this._onChangeText('');
    };

    _onChangeText = input => {
        const { handleChangeText } = this.props;

        this.setState({ input });

        if (handleChangeText) {
            handleChangeText(input);
        }
    };

    render = () => {
        const { placeholder, onFocus, focusOnLayout, keyboardAppearance } = this.props;
        const { input } = this.state;

        return (
            <View style={styles.container}>
                <View style={styles.nav}>
                    <TextInput
                        ref={ref => {
                            this.textInput = ref;
                        }}
                        onLayout={() => focusOnLayout && this.textInput.focus()}
                        style={styles.input}
                        selectionColor={styles.$selectionColor}
                        onChangeText={this._onChangeText}
                        onFocus={() => (onFocus ? onFocus() : null)}
                        onBlur={this._handleBlur}
                        placeholder={placeholder}
                        placeholderTextColor={styles.$placeholderTextColor}
                        value={input}
                        underlineColorAndroid="transparent"
                        returnKeyType="search"
                        autoCapitalize="none"
                        autoCorrect={false}
                        keyboardAppearance={keyboardAppearance}
                    />
                    <ImageButton
                        image={Resources.images.closeIcon()}
                        onPress={this._onClose}
                        tint={styles.$closeIconColor}
                    />
                </View>
            </View>
        );
    };
}
