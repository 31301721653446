import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '$focusedColor',
    },
    icon: {
        width: 108,
        height: 108,
        borderRadius: 54,
    },
    iconContainer: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    animatedIcon: {
        width: 240,
        height: 240,
        position: 'absolute',
    },
    text: {
        fontFamily: '$brandFontFamily',
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center',
        marginTop: 50,
        color: '$brandTextColor',
        marginHorizontal: 90,
    },
});

export default styles;
