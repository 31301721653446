import { Platform } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'stretch',
    },

    swipeHint: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: 1,
    },

    swipeHintTextContainer: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },

    swipeHintImage: {
        marginBottom: 25,
        ...Platform.select({
            android: {
                transform: [{ rotate: '90deg' }],
            },
        }),
    },

    swipeHintText: {
        fontSize: 17,
        color: '$brandTextColor',
        fontFamily: '$brandFontFamily'
    },
});

export default styles;
