import React from 'react';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';
import withTaggedUsers from "dating-mobile/src/models/users.targeted.tagged/controller/tagged-users";
import withStreams from "dating-mobile/src/models/dialogs.streams/controllers/controller";
import { TAGS } from "dating-mobile/src/models/users.targeted.tagged/model";

export default function withCurrentlyStreamingFollowedController(Component) {

    class Controller extends React.Component {
        static displayName = 'routing.views.following-tab-bar-item.controller';
        static propTypes = {
        };

        render() {
            const {
                streams,
                favoriteUsers,
                ...props
            } = this.props;

            const streams_ = streams || [];
            const following = (favoriteUsers || []).filter(userId => streams_.indexOf(userId) >= 0);
            const followingCount = following.length;
            const text = followingCount > 0 ? followingCount : undefined;

            return (<Component
                {...props}
                badgeText={text}
            />);
        }
    }

    return withIdentityId(
        withTaggedUsers(
            withStreams(Controller),
            {
                tag: TAGS.FAVORITE,
                usersPropName: 'favoriteUsers',
            }
        )
    );
}
