import EStyleSheet from 'react-native-extended-stylesheet';
import { Platform } from 'react-native';

const styles = EStyleSheet.create({
  $backgroundColor: '$primaryBackgroundColor',
  $statusBarStyle: '$primaryStatusBarStyle',
  $foregroundColor: '$primaryTextColor',
  $statusBarBackgroundColor: '$backgroundColor',

  $safeAreaInsets: {
    top: 'never',
    bottom: 'always',
  },

  header: {
    backgroundColor: '$backgroundColor',
    borderBottomColor: '$backgroundColor',
    shadowColor: '$backgroundColor',
  },

  headerTitle: {
    color: '$foregroundColor',
  },

  container: {
    backgroundColor: '$backgroundColor',
    flex: 1,
  },

  scrollView: {
    flexGrow: 1,
  },

  content: {
    flexDirection: 'column',
    alignItems: 'stretch',
  },

  motivationsHeaderContainer: {
    flexDirection: 'column',
    alignItems: 'stretch',
    paddingHorizontal: 40,
    paddingBottom: 20,
    marginTop: 32,
  },

  motivationsHeader: {
    fontWeight: 'bold',
    color: '$foregroundColor',
    ...Platform.select({
      ios: {
        fontFamily: '$brandFontFamily',
      },
      android: {
        // TODO:
      },
    }),
    textAlign: 'center',
    fontSize: 20,
  },

  motivationsSecondHeader: {
    marginTop: 5,
    fontSize: 16,
    color: '$foregroundColor',
    fontFamily: '$brandFontFamily',
    textAlign: 'center',
  },

  benefitsContainer: {
    flexDirection: 'column',
    alignItems: 'stretch',
    paddingTop: 20,
    paddingHorizontal: 50,
    alignSelf: 'center',
  },

  benefitsContainerExtraMargin: {
    marginBottom: 10,
  },

  productsContainer: {
    marginTop: 40,
    paddingHorizontal: 40,
    flex: 1,
    flexDirection: 'column',
    alignItems: 'stretch',
  },

  activityIndicatorContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },

  descriptionContainer: {
    paddingTop: 40,
    paddingHorizontal: 40,
    paddingBottom: 24,
  },

  continueWithoutSubscribing: {
    marginTop: 24,
    paddingHorizontal: 40,
  },

  continueWithoutSubscribingButton: {
    backgroundColor: 'transparent',
    borderColor: '$foregroundColor',
    borderWidth: 1,
  },

  continueWithoutSubscribingButtonTitleStyle: {
    fontSize: 16,
    fontFamily: '$brandFontFamily',
    fontWeight: 'normal',
    color: '$foregroundColor',
  },

  headerImage: {
    width: 0,
    height: 0,
  },

  freeBoostExplanation: {
    fontSize: 12,
    fontFamily: '$brandFontFamily',
    textAlign: 'center',
    color: '$disabledColor',
    marginTop: 10,
  },

  introductoryPriceExplanation: {
    fontSize: 12,
    fontFamily: '$brandFontFamily',
    textAlign: 'center',
    color: '$disabledColor',
    marginBottom: Platform.OS === 'web' ? 0 : 10,
  },

  packageList: {
    marginBottom: 40,
  },

  button: {
    width: 284,
    alignSelf: 'center',
  },

  disabledButton: {
    gradientColor: '$accentButtonDisabledGradientColor',
  },
});

export default styles;
