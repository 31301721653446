import EStyleSheet from 'react-native-extended-stylesheet';
import { Platform } from 'react-native';

const isWeb = Platform.OS === 'web';

const styles = EStyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'column',
    paddingHorizontal: 10,
    paddingVertical: 5,
    ...(isWeb ? { height: 120 } : {}),
  },

  image: {
    width: '100%',
    aspectRatio: 1,
    ...(isWeb ? { height: '100%' } : {}),
  },
});

export default styles;
