import React from 'react';
import PropTypes from 'prop-types';
import {View, Image, TouchableOpacity, ActivityIndicator, Text} from 'react-native';
import styles from './styles';
import Resources from '../../../../resources';
import withImagePicker from 'dating-mobile/src/components/image-picker';
import testId from '../../../../utils/test-id'

class AddItem extends React.PureComponent {
    static displayName = 'user.multi-photo-uploading.add-item';
    static propTypes = {
        select: PropTypes.func.isRequired,
        source: PropTypes.object,
        onPhotoSelect: PropTypes.func,
        progress: PropTypes.number,
        isUploading: PropTypes.bool
    };

    onPressed = () => {
        this.props.select();
    };

    componentWillReceiveProps(nextProps) {
        if (!nextProps.source) {
            return;
        }

        const { mediaType, ...image } = nextProps.source;
        if (mediaType !== 'photo') {
            return;
        }

        const uri = image.uri;
        const currentUri = this.props.source && this.props.source.uri;

        if (uri && uri !== currentUri) {
            this.props.onPhotoSelect && this.props.onPhotoSelect(image);
        }
    }

    render() {
        return (
            <View style={styles.container}>
                {
                    !this.props.isUploading && (
                        <TouchableOpacity onPress={this.onPressed} {...testId('Upload private photo button')}>
                            <Image style={styles.icon} source={Resources.images.cameraBigIcon()} />
                        </TouchableOpacity>
                    )
                }
                {
                    !!this.props.isUploading && (
                        <View style={styles.photosContainer}>
                            <ActivityIndicator color={styles._progress.color} animating={true} />
                            {
                                typeof this.props.progress === 'number' && (
                                    <Text style={styles.progress}>{this.props.progress} %</Text>
                                )
                            }
                        </View>
                    )
                }
            </View>
        );
    }

}

export default withImagePicker(
    AddItem,
    {
        mediaType: 'photo'
    }
);
