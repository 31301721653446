import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $showAddThumbnailText: false,

    '@flavor secretly': {
        $showAddThumbnailText: true,
    },

    container: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'stretch',
        marginBottom: 20,
    },

    title: {
        marginTop: 3,
        marginBottom: 40,
        fontSize: '$screenTitleSize',
        textAlign: '$brandTitleAlign',
        color: '$brandTextColor',
        fontFamily: '$brandFontFamily',
        fontWeight: 'bold',
    },

    addThumbnailContainerHidden: {
        position: 'absolute',
        opacity: 0,
    },

    addThumbnail: {
        backgroundColor: 'rgba(108, 99, 167, 1)',
        width: 100,
        height: 100,
        borderRadius: 30,
        justifyContent: 'center',
        alignItems: 'center',
        '@flavor lovinga': {
            height: 144,
            width: 144,
            borderRadius: 72,
            backgroundColor: '#f5f8fa',
        },
        '@flavor dma': {
            height: 144,
            width: 144,
            borderRadius: 72,
            backgroundColor: '#a4a8b3',
        },
        '@flavor udates': {
            height: 120,
            width: 120,
            borderRadius: 60,
            backgroundColor: '$accentColor',
        },
        '@flavor secretly': {
            height: 120,
            width: 120,
            borderRadius: 60,
            backgroundColor: '$minorAccentColor',
        },
    },

    addThumbnailText: {
        color: '$brandTextColor',
        fontFamily: '$brandFontFamily',
        fontSize: 12,
        marginTop: 5,
    },

    thumbnailContainer: {
        alignItems: 'center',
    },

    editContainer: {
        flex: 1,
        marginTop: 30,
        '@flavor lovinga': {
            marginTop: 24,
        },
    },

    thumbnail: {
        width: 100,
        height: 100,
        borderRadius: 30,
        '@flavor lovinga, dma': {
            height: 144,
            width: 144,
            borderRadius: 72,
        },
        '@flavor udates, secretly': {
            height: 120,
            width: 120,
            borderRadius: 60,
            backgroundColor: '$accentColor',
        },
        '@flavor dma': {
            backgroundColor: '#a4a8b3',
        },
        '@flavor lovinga': {
            backgroundColor: '#f5f8fa',
        },
    },

    thumbnailIcon: {
        '@flavor lovinga': {
            tintColor: '$disabledTextColor',
        },
    },

    aboutYourself: {
        fontSize: 16,
        color: '$brandTextColor',
    },

    anonymousBirthdayInfo: {
        color: '$infoTextColor',
        textAlign: 'center',
        marginTop: 55,
        fontSize: 13,
    },

    locationPickerContainer: {
        width: '100%',
        '@flavor udates': {
            width: undefined,
            flex: 1,
        },
    },

    locationPicker: {
        color: '$brandPlaceholderColor',
        textAlign: 'center',
        fontWeight: '600',
        fontFamily: '$brandFontFamily',
        fontSize: 16,
        flexShrink: 1,
        '@flavor udates': {
            textAlign: 'right',
            fontWeight: 'normal',
            fontSize: 17,
        },
    },

    locationPickerFilled: {
        color: '$brandTextColor',
        '@flavor udates': {
            color: '$brandPlaceholderColor',
        },
    },
    profileVisibleContainer: {
        marginTop: 30,
    },

    $showAgeRangePicking: true,
    '@flavor udates': {
        $showAgeRangePicking: false,
    },
});

export default styles;
