import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'black'
    },

    item: {
        flex: 1
    },

    thumbnailPlaceholder: {
        width: '100%',
        height: '100%',
        backgroundColor: '$disabledColor'
    }

});

export default styles;
