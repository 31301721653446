import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        width: '100%',
        height: 273,
        alignItems: 'flex-start',
        justifyContent: 'flex-end'
    },
    cover: {
        width: '100%',
        height: '100%',
        position: 'absolute'
    },
    photoContainer: {
        marginLeft: 8,
        marginBottom: 8,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    onlineIndicatorContainer: {
        marginLeft: 10,
        alignItems: 'center',
        justifyContent: 'center'
    },
    name: {
        color: 'white',
        fontFamily: '$brandFontFamily',
        fontWeight: '600',
        fontSize: 24,
        marginLeft: 16
    }
});

export default styles;
