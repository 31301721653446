import PropTypes from 'prop-types';
import { EVENT_TYPE } from 'dating-mobile/src/models/user.events/model';
import UserModel from 'dating-mobile/src/models/user/model';
import Service from '../../service';
import EventsModel, { getId } from '../../../models/user.events/model';
import withIdentityId from '../../../models/identity/controller/id';

const isValidEventType = eventType =>
  eventType === EVENT_TYPE.CONTACT ||
  eventType === EVENT_TYPE.INVITATION ||
  eventType === EVENT_TYPE.BOOST_DISCOUNT ||
  eventType === EVENT_TYPE.USER_LIKES;

class UserEventsProvider extends Service {
  static displayName = 'service.user-events-provider';

  static contextTypes = {
    flux: PropTypes.object,
  };

  constructor(props, context) {
    super(props);

    this.flux = context.flux;
    this.events = context.flux.events;
  }

  componentDidMount() {
    this.events.addListener('event.users.events.added', this.eventReceived);
    this.events.addListener('event.users.events.staled', this.eventStaled);
    this.events.addListener('event.users.events.removed', this.eventRemoved);
  }

  componentWillUnmount() {
    this.events.removeListener('event.users.events.added', this.eventReceived);
    this.events.removeListener('event.users.events.staled', this.eventStaled);
    this.events.removeListener('event.users.events.removed', this.eventRemoved);
  }

  eventReceived = data => {
    const id = this.props.userId;

    if (!id) {
      return;
    }

    if (isValidEventType(data.type)) {
      if (data.type === EVENT_TYPE.CONTACT && data['event-id']) {
        const invitationModel = this.flux.get(
          EventsModel,
          getId(id, EVENT_TYPE.INVITATION),
        );

        invitationModel.actions.delete(data['event-id'], true);
        // Actualize invitation events model because invitation event after user response becomes message event.
        // Server automatically changes it's type but it doesn't send any event about it.
      }

      const applyAction = actionData => {
        const eventsModel = this.flux.get(
          EventsModel,
          getId(id, actionData.type),
        );

        eventsModel.actions.patch(actionData);
      };

      if (!data['user-details']) {
        if (data['user-id']) {
          const userModel = this.flux.get(UserModel, data['user-id']);

          userModel.actions.get(() => {
            applyAction({
              ...data,
              'user-details': userModel.store.getState(),
            });
          });
        }
      } else {
        applyAction(data);
      }
    }
  };

  eventStaled = data => {
    const id = this.props.userId;

    if (!id) {
      return;
    }

    const userId = data['user-id'];

    if (!userId) {
      return;
    }

    if (isValidEventType(data.type)) {
      const eventsModel = this.flux.get(EventsModel, getId(id, data.type));

      eventsModel.actions.stale(userId);
    }
  };

  eventRemoved = data => {
    const id = this.props.userId;

    if (!id) {
      return;
    }

    const eventId = data['event-id'];

    if (!eventId) {
      return;
    }

    if (isValidEventType(data.type)) {
      const eventsModel = this.flux.get(EventsModel, getId(id, data.type));

      eventsModel.actions.delete(eventId, true);
    }
  };
}

export default withIdentityId(UserEventsProvider);
