import { bindCallback, of, throwError } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function toObservable(f, ...args) {
    return bindCallback(f)(...args).pipe(
        // eslint-disable-next-line no-shadow
        mergeMap(args => {
            const [error, data] = args;

            if (error) {
                return throwError(error);
            }

            return of(data);
        }),
    );
}
