import React, { PureComponent } from 'react';
import { View, StyleSheet } from 'react-native';
import ImageButton from 'dating-mobile/src/components/navigation/views/image-button';
import Resources from 'dating-mobile/src/resources';
import RotateCameraView from 'dating-mobile/src/components/video/views/camera';
import VolumeView from 'dating-mobile/src/components/video/views/mute';
import styles from './styles';

export default class StreamControlsView extends PureComponent {
    render() {
        const { onSwitchMicrophoneMute, onRotateCamera, onClose, microphoneMuted } = this.props;

        return (
            <View style={styles.container}>
                <RotateCameraView rotateCamera={onRotateCamera} />
                <VolumeView onPress={onSwitchMicrophoneMute} microphoneMuted={microphoneMuted} />
                <ImageButton
                    image={Resources.images.closeIcon()}
                    onPress={onClose}
                    tint={StyleSheet.flatten(styles.headerTitle).color}
                    style={styles.closeIcon}
                />
            </View>
        );
    }
}
