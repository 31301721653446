/* eslint-disable no-await-in-loop */
import { config } from 'react-native-firebase';
import UserDefaults from 'react-native-default-preference';
import merge from 'merge/merge';
import url from 'url';
import flux from '@sdv/domain/app/flux';
import { singleton } from '@sdv/commons/utils/singleton';
import ConfigBuilder from 'dating-mobile/src/app/config-builder';
import ProductConfigModel from 'dating-mobile/src/models/config/model/product';
import {
  HOST_URI_STORAGE_KEY,
  STREAMING_ENDPOINT_STORAGE_KEY,
  WS_ENDPOINT_STORAGE_KEY,
} from 'dating-mobile/src/components/endpoint-switcher/constants';
import isTestBuild from 'dating-mobile/src/utils/is-test-build';

const FirebaseConfig = config();

const KEY_PREFIX = 'singular__';
const FIREBASE_STORAGE_KEY_PREFIX = 'firebase-cache.';
const FIREBASE_CACHED_KEYS_STORAGE_KEY = `${FIREBASE_STORAGE_KEY_PREFIX}keys`;
const TIMEOUT = 4 * 1000;

export class ConfigLoader {
  static shared = singleton(() => new ConfigLoader());

  initialized = false;

  loadCachedValues = async () => {
    const stringKeysValue = await UserDefaults.get(
      FIREBASE_CACHED_KEYS_STORAGE_KEY,
    );
    let keys = stringKeysValue && JSON.parse(stringKeysValue);

    if (!Array.isArray(keys)) {
      keys = [];
    }

    let result = {};

    for (let index = 0; index < keys.length; index++) {
      const key = keys[index];
      const stringValue = await UserDefaults.get(
        FIREBASE_STORAGE_KEY_PREFIX + key,
      );

      try {
        const value = JSON.parse(stringValue);

        const change = this.changeForKeyValuePair(key, value);

        result = merge.recursive(true, result, change);
      } catch {
        console.warn(`Firebase value for key '${key}' has invalid format`);
      }
    }

    return result;
  };

  loadFetchedValues = async () => {
    let keys = await FirebaseConfig.getKeysByPrefix(KEY_PREFIX);

    if (!Array.isArray(keys)) {
      keys = [];
    }

    const stringOldKeysValue = await UserDefaults.get(
      FIREBASE_CACHED_KEYS_STORAGE_KEY,
    );
    const oldKeys = stringOldKeysValue && JSON.parse(stringOldKeysValue);

    if (Array.isArray(oldKeys)) {
      for (let index = 0; index < oldKeys.length; index++) {
        await UserDefaults.clear(oldKeys[index]);
      }
    }

    await UserDefaults.set(
      FIREBASE_CACHED_KEYS_STORAGE_KEY,
      JSON.stringify(keys),
    );

    let result = {};

    for (let index = 0; index < keys.length; index++) {
      const key = keys[index];

      try {
        const snapshot = await FirebaseConfig.getValue(key);

        const stringValue = snapshot.val();

        const value = JSON.parse(stringValue);

        await UserDefaults.set(
          FIREBASE_STORAGE_KEY_PREFIX + key,
          JSON.stringify(value),
        );

        const change = this.changeForKeyValuePair(key, value);

        result = merge.recursive(true, result, change);
      } catch {
        console.warn(`Firebase value for key '${key}' has invalid format`);
      }
    }

    return result;
  };

  pathForFirebaseKey = key => {
    return key
      .replace(KEY_PREFIX, '')
      .split('__')
      .filter(part => part)
      .map(part => part.replace(/_/g, '-'))
      .join('.');
  };

  changeForKeyValuePair = (key, value) => {
    const path = this.pathForFirebaseKey(key);

    const parts = path.split('.');

    const result = {};
    let current = result;

    for (let index = 0; index < parts.length - 1; index++) {
      const part = parts[index];

      current[part] = {};
      current = current[part];
    }

    const lastPart = parts[parts.length - 1];

    current[lastPart] = value;

    return result;
  };

  reloadConfig = async () => {
    const { ...baseConfig } = ConfigBuilder.config;

    if (isTestBuild) {
      const apiEndpoint = await UserDefaults.get(HOST_URI_STORAGE_KEY);

      if (apiEndpoint) {
        const { host } = url.parse(apiEndpoint);

        flux.api.setBaseHost(`//${host}`);
        baseConfig.host = apiEndpoint;
      }

      const streamingEndpoint = await UserDefaults.get(
        STREAMING_ENDPOINT_STORAGE_KEY,
      );

      const wsEndpoint = await UserDefaults.get(WS_ENDPOINT_STORAGE_KEY);

      if (flux.events) {
        flux.events.reconnect(wsEndpoint);
      }

      if (streamingEndpoint) {
        baseConfig.endpoints = {
          ...baseConfig.endpoints,
          streaming: streamingEndpoint,
        };
      }

      if (wsEndpoint) {
        baseConfig.endpoints = {
          ...baseConfig.endpoints,
          ws: wsEndpoint,
        };
      }
    }

    const fetchArgs = isTestBuild ? [0] : [];
    const fetchPromise = FirebaseConfig.fetch(...fetchArgs).then(() =>
      FirebaseConfig.activateFetched(),
    );

    try {
      const races = [fetchPromise];

      if (!this.initialized) {
        const timeoutPromise = new Promise((resolve, reject) => {
          setTimeout(reject, TIMEOUT, 'Timeout');
        });

        races.push(timeoutPromise);
      }

      await Promise.race(races);

      const fetchedConfig = await this.loadFetchedValues();

      flux
        .get(ProductConfigModel)
        .actions.set(merge.recursive(true, baseConfig, fetchedConfig));
    } catch {
      fetchPromise
        .then(() => this.loadFetchedValues())
        .then(conf => {
          flux
            .get(ProductConfigModel)
            .actions.set(merge.recursive(true, baseConfig, conf));
        });

      const cachedConfig = await this.loadCachedValues();

      flux
        .get(ProductConfigModel)
        .actions.set(merge.recursive(true, baseConfig, cachedConfig));
    }

    this.initialized = true;
  };
}
