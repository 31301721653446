import EStyleSheet from 'react-native-extended-stylesheet';

const textStyle = {
  fontFamily: '$brandFontFamily',
  color: '$primaryTextColor',
  textAlign: 'center',
};

const styles = EStyleSheet.create({
  $backgroundColor: '$primaryBackgroundColor',
  $statusBarStyle: '$primaryStatusBarStyle',
  $statusBarBackgroundColor: '$backgroundColor',

  header: {
    backgroundColor: '$backgroundColor',
    borderBottomColor: '$backgroundColor',
    shadowColor: '$backgroundColor',
  },

  headerTitle: {
    color: '$primaryTextColor',
  },

  scrollView: {
    flexGrow: 1,
  },

  content: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    paddingHorizontal: 20,
    paddingBottom: 40,
  },

  firstStarImage: {
    position: 'absolute',
    top: -50,
    left: 60,
    width: 21,
  },

  secondStarImage: {
    position: 'absolute',
    bottom: 20,
    right: 40,
    width: 35,
  },

  thirdStarImage: {
    position: 'absolute',
    bottom: -50,
    right: 100,
    width: 22,
  },

  fourthStarImage: {
    position: 'absolute',
    bottom: -70,
    left: 20,
    width: 40,
  },

  fifthStarImage: {
    position: 'absolute',
    bottom: -100,
    right: 10,
    width: 22,
  },

  title: {
    ...textStyle,
    fontWeight: 'bold',
    fontSize: 24,
    marginBottom: 10,
    textTransform: 'uppercase',
  },

  subTitle: {
    ...textStyle,
    fontSize: 18,
    marginBottom: 40,
  },

  imagesContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 40,
  },

  image: {
    margin: 3,
    flex: 1,
  },

  discountText: {
    fontFamily: '$brandFontFamily',
    color: '#ff344d',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 24,
    textTransform: 'uppercase',
    marginBottom: 20,
  },

  buttonWrapper: {
    width: '100%',
    minHeight: 140,
  },

  button: {
    borderRadius: 12,
    paddingVertical: 10,
  },

  buttonContent: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

  buttonTitle: {
    color: '$brandTextColor',
    fontFamily: '$brandFontFamily',
    fontSize: 20,
    fontWeight: '700',
    lineHeight: 28,
    textAlign: 'center',
  },

  priceContainer: {
    fontFamily: '$brandFontFamily',
    fontSize: 18,
    fontWeight: 'normal',
    textAlign: 'center',
    lineHeight: 28,
  },

  creditImg: {
    width: 20,
    marginLeft: 4,
  },

  currentPrice: {
    color: '$brandTextColor',
  },

  oldPrice: {
    textDecorationLine: 'line-through',
    color: 'rgba(255,255,255,0.7)',
  },

  disabledText: {
    color: '$disabledTextColor',
  },

  activityIndicatorContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default styles;
