import React, { Component } from 'react';
import { StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import controller from '../../../../models/dialogs.room/controllers/controller';
import StreamInfoView from '../../views/info-view';
import styles from './styles';
import ImageButton from '../../../../components/navigation/views/image-button';
import Resources from '../../../../resources';

const InfoView = controller(StreamInfoView);

class StreamInfoContainer extends Component {
  static displayName = 'dialogs.stream.info.screen';

  static contextTypes = {
    flux: PropTypes.object,
  };

  static propTypes = {
    id: PropTypes.string,
    navigation: PropTypes.object.isRequired,
  };

  static navigationOptions = () => ({
    headerStyle: styles.header,
    headerTitleStyle: styles.headerTitle,
    headerBackImage: () => (
      <ImageButton
        image={Resources.images.backIcon()}
        tint={StyleSheet.flatten(styles.headerTitle).color}
      />
    ),
  });

  state = {};

  getId() {
    const { id, navigation } = this.props;

    return id || navigation.getParam('id');
  }

  render() {
    return <InfoView id={this.getId()} />;
  }
}

export default StreamInfoContainer;
