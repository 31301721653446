import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { singleton } from '@sdv/commons/utils/singleton';
import { faqList } from '@sdv/domain/app/faq-list';

export class FaqRepository {
    static shared = singleton(() => new FaqRepository());

    static fallbackLang = 'en-US';

    getTopicsPreview() {
        return of(faqList).pipe(
            map(list =>
                list.map(({ id, localizedTitle }) => ({
                    id,
                    localizedTitle,
                })),
            ),
        );
    }

    getTopic(id, lang) {
        return of(faqList).pipe(
            map(list => {
                const faq = list.find(item => item.id === id);

                if (!faq) {
                    return null;
                }

                return {
                    id,
                    localizedSource: faq.source[lang] || faq.source[FaqRepository.fallbackLang],
                };
            }),
        );
    }
}
