import actions from './actions';
import { getId as internalGetId, parseId as internalParseId } from '@sdv/domain/dialogs.messages';
import store from './store';

export default {
    actions: actions,
    store: store
};

export function getId(...args) {
    return internalGetId(...args);
}

export function parseId(...args) {
    return internalParseId(...args);
}
