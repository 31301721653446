import React, { ComponentType, useCallback } from 'react';
import {
  appleAuth,
  AppleRequestResponse,
} from '@invertase/react-native-apple-authentication';
// @ts-ignore
import IdentityModel from '@sdv/domain/identity/model';
// @ts-ignore
import UserModel from '@sdv/domain/user/model';
// @ts-ignore
import flux from '@sdv/domain/app/flux';
// @ts-ignore
import withConfigValue from 'dating-mobile/src/components/config-value';

import { ControllerProps, ViewProps } from './types';

const system = 'apple';

export default function withController(Component: ComponentType<ViewProps>) {
  const Controller = (props: ControllerProps) => {
    const {
      userNameMaxLength,
      onAuthStarted,
      onAuthFinished,
      ...restProps
    } = props;

    const identityModel = flux.get(IdentityModel);

    const updateUser = useCallback(
      user =>
        new Promise<void>((resolve, reject) => {
          const { id } = identityModel.store.getState();

          if (!id) {
            reject(new Error('Id can not be undefined'));
          }

          flux.get(UserModel, id).actions.patch(user, (error: Error) => {
            if (error) {
              reject(error);
            } else {
              resolve();
            }
          });
        }),
      [identityModel],
    );

    const signIn = useCallback(
      (token: string) =>
        new Promise<void>((resolve, reject) => {
          identityModel.actions.signInViaOAuth2(
            system,
            token,
            (error: Error) => {
              if (error) {
                reject(error);
              } else {
                resolve();
              }
            },
          );
        }),
      [identityModel],
    );

    const signUpViaOAuth2 = useCallback(
      ({ identityToken, email }: AppleRequestResponse) =>
        new Promise<void>((resolve, reject) => {
          identityModel.actions.signUpViaOAuth2(
            system,
            identityToken,
            email,
            (error: Error) => {
              if (error) {
                reject(error);
              } else {
                resolve();
              }
            },
          );
        }),
      [identityModel],
    );

    const signUp = useCallback(
      async (authResponse: AppleRequestResponse) => {
        if (
          !authResponse.fullName ||
          (!authResponse.fullName.givenName && authResponse.fullName.familyName)
        ) {
          throw new Error('Name can not be empty');
        }

        await signUpViaOAuth2(authResponse);

        await updateUser({
          name: `${authResponse.fullName.givenName || ''} ${authResponse
            .fullName.familyName || ''}`
            .trim()
            .slice(0, userNameMaxLength),
        });
      },
      [signUpViaOAuth2, updateUser],
    );

    const authorize = useCallback(
      async (authResponse: AppleRequestResponse) => {
        if (!authResponse.identityToken) {
          throw new Error('Identity token can not be empty');
        }

        if (authResponse.email) {
          try {
            await signUp(authResponse);
          } catch (e) {
            if (e.status === 400 && e.src === 'email') {
              await signIn(authResponse.identityToken);
            } else {
              throw e;
            }
          }
        } else {
          await signIn(authResponse.identityToken);
        }
      },
      [signIn, signUp],
    );

    const onPress = useCallback(async () => {
      try {
        onAuthStarted();

        const appleAuthRequestResponse = await appleAuth.performRequest({
          requestedOperation: appleAuth.Operation.LOGIN,
          requestedScopes: [appleAuth.Scope.EMAIL, appleAuth.Scope.FULL_NAME],
        });

        const credentialState = await appleAuth.getCredentialStateForUser(
          appleAuthRequestResponse.user,
        );

        if (credentialState === appleAuth.State.AUTHORIZED) {
          await authorize(appleAuthRequestResponse);

          onAuthFinished();
        } else {
          onAuthFinished(new Error('Unable to authorize'));
        }
      } catch (e) {
        onAuthFinished(e);
      }
    }, [authorize, onAuthStarted, onAuthFinished]);

    if (!appleAuth.isSupported) {
      return null;
    }

    return <Component {...restProps} onPress={onPress} />;
  };

  Controller.defaultProps = {
    onAuthStarted: () => {},
    onAuthFinished: () => {},
  };

  Controller.displayName = 'authentication.views.apple-auth-button.controller';

  return withConfigValue(Controller, {
    userNameMaxLength: 'validation.user-name.max-length',
  });
}
