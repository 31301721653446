import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-native';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import withIdentityId from 'dating-mobile/src/models/identity/controller/id';
import { Stock } from '@sdv/domain/payment/stock';
import Resources from 'dating-mobile/src/resources';
import { Purchase } from 'dating-mobile/src/payment/methods/purchase';
import UserTagsModel from '@sdv/domain/user/tags/personal';
import UserModel from '@sdv/domain/user/model';
import flux from '@sdv/domain/app/flux';
import { PAYMENT_REASON } from '@sdv/domain/app/balance-refiller';

export default function createController(Component) {
  class Controller extends PureComponent {
    static displayName = 'stepped-registration.screens.subscription.controller';

    static propTypes = {
      userId: PropTypes.string,
      onComplete: PropTypes.func,
    };

    state = {
      isLoading: false,
      invoice: null,
    };

    componentDidMount() {
      this.subscribe();
    }

    componentDidUpdate(prevProps) {
      const { userId } = this.props;

      if (userId !== prevProps.userId) {
        this.unsubscribe();
        this.subscribe();
      }
    }

    componentWillUnmount() {
      this.unsubscribe();
    }

    onSubmit = () => {
      const { userId } = this.props;
      const { invoice } = this.state;

      if (invoice) {
        this.setState({
          isLoading: true,
        });

        const subscription = Purchase.shared()
          .pay(userId, invoice, PAYMENT_REASON.ONBOARDING)
          .subscribe(({ success }) => {
            this.setState({
              isLoading: false,
            });

            if (success) {
              flux.get(UserTagsModel, userId)?.actions?.get();
              flux.get(UserModel, userId)?.actions?.get();
              this.onPaymentCompleted();
            }
          });

        if (this.disposeBag) {
          this.disposeBag.add(subscription);
        }
      }
    };

    onPaymentCompleted() {
      const { onComplete } = this.props;

      Alert.alert(
        Resources.strings['memberships-screen-success-alert-title'],
        Resources.strings['memberships-screen-success-alert-message'],
        [
          {
            text: Resources.strings.ok,
            style: 'cancel',
            onPress: onComplete,
            onDismiss: onComplete,
          },
        ],
      );
    }

    subscribe() {
      const { userId } = this.props;

      if (userId) {
        this.setState({
          isLoading: true,
        });

        const subscription = Stock.shared(userId)
          .preloadedPackagesInStock.pipe(
            map(
              products =>
                products?.find(p => p?.[1]?.[0]?.isPremiumMembership)?.[1]?.[0],
            ),
          )
          .subscribe(invoice => {
            this.setState({
              invoice,
              isLoading: false,
            });
          });

        this.disposeBag = new Subscription();
        this.disposeBag.add(subscription);
      }
    }

    unsubscribe() {
      if (this.disposeBag) {
        this.disposeBag.unsubscribe();
        this.disposeBag = null;
      }
    }

    render() {
      const { isLoading, invoice } = this.state;

      return (
        <Component
          onSubmit={this.onSubmit}
          isLoading={isLoading}
          invoice={invoice}
        />
      );
    }
  }

  return withIdentityId(Controller, 'userId');
}
