let type = {};

// Add some isType methods: isArguments, isFunction, isString, isNumber, isDate, isRegExp, isError.
['Arguments', 'Function', 'String', 'Number', 'Date', 'RegExp', 'Error', 'File'].forEach(function (name) {
    
    type['is' + name] = function (obj) {
        return Object.prototype.toString.call(obj) === '[object ' + name + ']';
    };

});

type.isFinite = function (value) {
    return isFinite(value) && !type.isNaN(parseFloat(value));
};

type.isNaN = function (value) {
    return type.isNumber(value) && value !== +value;
};

type.isBoolean = function (value) {
    return value === true || value === false || toString.call(value) === '[object Boolean]';
};

type.isNull = function (value) {
    return value === null;
};

type.isUndefined = function (value) {
    return value === void 0;
};

type.isObject = function (value) {
    var type = typeof value;
    return type === 'function' || (type === 'object' && !!value);
};

export default type;
