
function createTypingActions(id) {
    const params = parseId(id);

    class Actions {
        startTyping = () => (dispatch, flux) => {
            flux.api.events.post(
                params.attendee,
                {
                    payload: { sender: params.identity },
                    label: 'event.user.typing.started',
                },
                (error) => {
                    if (error) {
                        return dispatch(null, error);
                    }
                    dispatch({ outgoing: true })
                }
            );
        };

        endTyping = () => (dispatch, flux) => {
            flux.api.events.post(
                params.attendee,
                {
                    payload: { sender: params.identity },
                    label: 'event.user.typing.ended',
                },
                (error) => {
                    if (error) {
                        return dispatch(null, error);
                    }
                    dispatch({ outgoing: false })
                },
            );
        };

        actualizeIncomingTyping = (active) => (dispatch) => {
            dispatch({ incoming: active });
        }
    }
    Actions.displayName = createTypingActions.getDisplayName(id);
    return Actions;
}

createTypingActions.getDisplayName = function (id) {
    return `user-events-typing.${id}`;
};

export function getId(identity, attendee) {
    return `${identity}:${attendee}`;
}

export function parseId(id) {
    let keys;

    if (typeof id !== 'string' || (keys = id.split(':')).length !== 2 || !keys[0] || !keys[1]) {
        throw new Error('MessagesModel should have id in format "{identity}:{attendee}"');
    }

    return {
        identity: keys[0],
        attendee: keys[1]
    };
}

export default createTypingActions;
