import React from 'react';
import PropTypes from 'prop-types';
import ConfigValue from './index';

class Switcher extends React.Component {
    static displayName = 'config.value.switcher';
    static propTypes = {
        path: PropTypes.string,
        value: PropTypes.any
    };

    render() {
        const { children } = this.props;

        if (typeof children === 'function') {
            return children(this.props.value);
        } else {
            console.warn('Switcher inner element should be function');
            return null;
        }
    }
}

export default ConfigValue(Switcher);
