import { Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { singleton } from '@sdv/commons/utils/singleton';
// @ts-ignore
import api, { toObservable } from '@sdv/domain/api';
import { Cheer } from '@sdv/domain/cheers/types/cheer';
import { UserTags as Tags } from '@sdv/domain/user/tags/user-tags';
// @ts-ignore
import UserTags from '@sdv/domain/user/tags';

export class FreeCheersRepository {
    static shared = singleton(userId => new FreeCheersRepository(userId));

    readonly userId: string;

    constructor(userId: string) {
        this.userId = userId;
    }

    getAvailableCheers(): Observable<[Cheer]> {
        // @ts-ignore
        return UserTags.shared()
            .tagsOf(this.userId)
            .pipe(
                switchMap(tags =>
                    // @ts-ignore
                    tags.indexOf(Tags.FreeCheersAvaliable) < 0 ? of([]) : this.getFreeCheers(),
                ),
                distinctUntilChanged(),
            );
    }

    // eslint-disable-next-line class-methods-use-this
    private getFreeCheers() {
        return toObservable(api.cheers['onboarding'].list).pipe(
            // @ts-ignore
            map(json => json.map(str => new Cheer('/cheers/onboarding/'+str))),
            catchError(_ => of([])),
        );
    }
}
