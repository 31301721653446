import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, BackHandler } from 'react-native';
import testId from 'dating-mobile/src/utils/test-id';
import Resources from 'dating-mobile/src/resources';
import styles from './styles';

import CreateScreen from './index';

import UserController from '../../../models/user/controllers';

import Button from '../../../components/buttons/base';
import TextButton from '../../../components/navigation/views/text-button';
import Google from '../../../components/place-autocomplete';

class RegistrationTravels extends React.PureComponent {
  static displayName = 'user.travels.registration';

  static propTypes = {
    navigation: PropTypes.object.isRequired,
    addTrip: PropTypes.func.isRequired,
    error: PropTypes.string,
  };

  state = {};

  componentDidMount() {
    const { navigation } = this.props;

    this.backButtonHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      () => {
        return true;
      },
    );

    if (navigation) {
      navigation.setParams({
        title: null,
        headerRight: () => <TextButton title="Skip" onPress={this.skip} />,
      });
    }
  }

  componentWillUnmount() {
    this.backButtonHandler.remove();
  }

  skip = () => {
    this.goNext();
  };

  onSubmit = () => {
    const { addTrip } = this.props;
    const { selected } = this.state;

    if (selected && selected.location) {
      addTrip(selected.location);
      this.goNext();
    }
  };

  goNext = () => {
    const { navigation } = this.props;
    const onComplete =
      navigation.getParam('onComplete') || (() => navigation.pop());

    onComplete();
  };

  handleSelect = value => {
    this.setState({
      selected:
        (value && {
          name: value.name,
          location: {
            country: value.country,
            city: value.city,
          },
        }) ||
        null,
    });
  };

  render() {
    const { error } = this.props;
    const { selected } = this.state;

    return (
      <View style={styles.registrationContainer}>
        <Text style={styles.title}>Add Destination</Text>

        <View style={styles.registrationInputContainer}>
          <Google
            inputContainerStyle={styles.registrationInput}
            style={styles.inputText}
            placeholder="City or Country"
            geocode
            disableTypewritingSelect
            onSelect={this.handleSelect}
            error={error}
            {...testId(
              Resources.strings['destination-input-accessibility-label'],
            )}
          />
        </View>

        <Button
          disabled={!selected}
          title={Resources.strings.next}
          onPress={this.onSubmit}
          {...testId(Resources.strings['next-button-accessibility-label'])}
        />
      </View>
    );
  }
}

export default [UserController, CreateScreen].reduce(
  (component, controller) => {
    return controller(component);
  },
  RegistrationTravels,
);
