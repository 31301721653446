import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  $defaultIconSize: 40,
  iconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    width: '100%',
    height: '100%',
  },
});

export default styles;
