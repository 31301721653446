import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({

    container: {
        width: '100%',
        height: '100%',
        borderRadius: 64,
        backgroundColor: '$unselectedColor',
        alignItems: 'center',
        justifyContent: 'center'
    },

    icon: {
        tintColor: '$disabledColor',
        width: 56,
        height: 56
    },

    progressContainer: {
        flexDirection: 'column'
    },

    progress: {
        fontSize: 15,
        color: '$infoTextColor',
        fontWeight: 'bold',
        fontFamily: '$brandFontFamily',
    }

});

export default styles;
