import React from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import IfConfigValue from 'dating-mobile/src/components/config-value/if';
import ChatButton from 'dating-mobile/src/dialogs/messages/views/chat-button';
import CallButton from 'dating-mobile/src/call/views/video-call-button';
import WriteLetterButton, {
  WRITE_LETTER_BUTTON_TYPE,
} from 'dating-mobile/src/inbox/views/write-letter-button';
import CheerButton from 'dating-mobile/src/dialogs/cheers/views/cheers-button';
import Resources from 'dating-mobile/src/resources';
import MediaAdd from 'dating-mobile/src/user/edit/views/add-media';
import Button from 'dating-mobile/src/components/buttons/base';
import RoundButton from 'dating-mobile/src/components/buttons/round';
import styles from './styles';
import shouldUseWebLayout from '../../../../utils/web-layout';

export default class ProfileActionButtonsView extends React.Component {
  static displayName = 'user.profile.views.action-buttons.view';

  static propTypes = {
    userId: PropTypes.string,
    attendeeId: PropTypes.string,
    presence: PropTypes.object,
    inboxEnabled: PropTypes.bool,
    videoUploadingEnabled: PropTypes.bool,
  };

  shouldComponentUpdate(nextProps) {
    const { userId, attendeeId, inboxEnabled, presence } = this.props;

    return (
      userId !== nextProps.userId ||
      attendeeId !== nextProps.attendeeId ||
      inboxEnabled !== nextProps.inboxEnabled ||
      presence !== nextProps.presence
    );
  }

  renderChatButton = (ownProfile, userOnline) => {
    const { attendeeId, inboxEnabled } = this.props;
    const userOnlineDependedOnInboxEnabling = inboxEnabled ? userOnline : true;
    const manualCommunicationButtonsVisible = ownProfile
      ? false
      : userOnlineDependedOnInboxEnabling;

    if (!manualCommunicationButtonsVisible) {
      return null;
    }

    return (
      <IfConfigValue path="features.chats-enabled" equalsTo={true}>
        <ChatButton
          viewStyle={shouldUseWebLayout() ? 'text' : undefined}
          style={styles.button}
          titleStyle={styles.buttonTitle}
          noIcon
          id={attendeeId}
        />
      </IfConfigValue>
    );
  };

  renderCallButton = (ownProfile, userOnline) => {
    const { userId, attendeeId } = this.props;
    const callButtonVisible = ownProfile ? false : !!userOnline;

    if (!callButtonVisible) {
      return null;
    }

    return (
      <IfConfigValue path="features.video-chat-enabled" equalsTo={true}>
        <CallButton
          userId={userId}
          attendeeId={attendeeId}
          disableWhenAttendeeWithoutCamera
          hideWhenDisabled
          style={styles.videoCallButton}
          iconStyle={styles.videoCallIcon}
        />
      </IfConfigValue>
    );
  };

  renderCheerButton = (ownProfile, userOnline) => {
    const { attendeeId, inboxEnabled } = this.props;
    const userOnlineDependedOnInboxEnabling = inboxEnabled ? userOnline : true;
    const manualCommunicationButtonsVisible = ownProfile
      ? false
      : userOnlineDependedOnInboxEnabling;

    if (!manualCommunicationButtonsVisible) {
      return null;
    }

    return (
      <IfConfigValue path="features.chat-gifts-enabled" equalsTo={true}>
        <IfConfigValue
          path="markup.profile-screen.has-cheers-button"
          equalsTo={true}
        >
          <CheerButton style={styles.sendGift} attendeeId={attendeeId} />
        </IfConfigValue>
      </IfConfigValue>
    );
  };

  renderWriteLetterButton = (ownProfile, userOnline) => {
    const { attendeeId, inboxEnabled } = this.props;
    const writeLetterButtonVisible = ownProfile
      ? false
      : !userOnline && inboxEnabled;

    if (!writeLetterButtonVisible) {
      return null;
    }

    return (
      <WriteLetterButton
        style={styles.writeLetterButton}
        type={WRITE_LETTER_BUTTON_TYPE.ROUND}
        userId={attendeeId}
      />
    );
  };

  renderMediaAddButton = ownProfile => {
    const { attendeeId, videoUploadingEnabled } = this.props;
    const mediaType = videoUploadingEnabled ? 'mixed' : 'photo';

    if (!ownProfile) {
      return false;
    }

    return (
      <MediaAdd
        id={attendeeId}
        mediaType={mediaType}
        loadingTint={styles.$addMediaButtonLoadingColor}
      >
        {!!styles.$mediaButtonIsCompact && (
          <RoundButton icon={Resources.images.cameraWhiteIcon()} />
        )}
        {!styles.$mediaButtonIsCompact && (
          <Button
            style={styles.buttonContainer}
            title={Resources.strings['gallery-view-add-media-button-title']}
          />
        )}
      </MediaAdd>
    );
  };

  render() {
    const { userId, attendeeId, presence } = this.props;

    const ownProfile = !!userId && !!attendeeId && userId === attendeeId;
    const userOnline = !!(presence && presence.online);

    return (
      <View style={styles.container} pointerEvents="box-none">
        {this.renderChatButton(ownProfile, userOnline)}
        {this.renderCallButton(ownProfile, userOnline)}
        {this.renderCheerButton(ownProfile, userOnline)}
        {this.renderWriteLetterButton(ownProfile, userOnline)}
        {this.renderMediaAddButton(ownProfile)}
      </View>
    );
  }
}
