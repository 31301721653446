import { combineLatest } from 'rxjs';
import { switchMap, filter, map, distinctUntilChanged } from 'rxjs/operators';
import geolib from 'geolib';
import pick from 'lodash/pick';
import flux from '@sdv/domain/app/flux';
import User from '@sdv/domain/user/model';
import IdentityModel from '@sdv/domain/identity/model';
import { isDefaultLocation } from './location-updater';

export class DistanceToUser {
    constructor(userId) {
        this.distance = flux
            .get(IdentityModel)
            .store.rxState()
            .pipe(
                switchMap(({ id }) =>
                    combineLatest(
                        flux.get(User, id).store.rxState(),
                        flux.get(User, userId).store.rxState(),
                    ),
                ),
                map(users => users.map(user => pick(user, ['latitude', 'longitude']))),
                filter(locations =>
                    locations.every(
                        location =>
                            location.latitude && location.longitude && !isDefaultLocation(location),
                    ),
                ),
                map(([current, target]) => {
                    const distance = geolib.convertUnit(
                        'km',
                        geolib.getDistance(current, target),
                        0,
                    );

                    return distance === 0 ? 1 : distance;
                }),
                distinctUntilChanged(),
            );
    }
}
