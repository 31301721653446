import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    info: {
        fontSize: 14,
        textAlign: 'center',
        fontFamily: '$brandFontFamily',
        color: '$infoTextColor',
        '@flavor dma, ytm': {
            fontSize: 16,
            color: '$brandTextColor'
        },
        '@flavor secretly': {
            fontSize: 16,
            color: '$primaryTextColor'
        }
    },

    link: {
        color: '$brandLinkTextColor',
        textDecorationLine: 'underline',
        '@flavor dma': {
            textDecorationLine: undefined,
        }
    }
});

export default styles;
