import React from 'react';
import PropTypes from 'prop-types';
import { Text, TouchableOpacity } from 'react-native';
import styles from './styles';
import Resources from '../../../../../resources';

export default class TypeMessageItem extends React.Component {
    static displayName = 'dialogs.mingle.message-selection.type-message-item';

    static propTypes = {
        message: PropTypes.string,
        onPress: PropTypes.func,
        customStyles: PropTypes.shape({
            container: PropTypes.object,
        }),
    };

    shouldComponentUpdate() {
        return false;
    }

    onPressed = () => {
        this.props.onPress && this.props.onPress();
    };

    render() {
        const { customStyles = {} } = this.props;

        return (
            <TouchableOpacity
                style={[styles.container, customStyles.container]}
                onPress={this.onPressed}
            >
                <Text style={styles.title}>
                    {Resources.strings['start-mingle-screen-type-message-button-title']}
                </Text>
            </TouchableOpacity>
        );
    }
}
