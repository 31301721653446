import EStyleSheet from 'react-native-extended-stylesheet';
import { I18nManager, Platform } from 'react-native';

const styles = EStyleSheet.create({
  container: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    height: 54,
    borderRadius: 27,
  },

  activeContent: {
    backgroundColor: '$accentColor',
  },

  disabledContent: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },

  disabledGradient: {
    gradientColor: '$brandButtonDisabledGradientColor',
  },

  title: {
    fontSize: 18,
    fontFamily: '$brandFontFamily',
    fontWeight: 'bold',
    color: '$accentTextColor',
    textAlign: Platform.select({
      web: 'center',
    }),
  },

  disabledModifier: {
    color: '$accentTextColor',
  },

  icon: {
    position: 'absolute',
    left: 15,
    width: 20,
    height: 20,
    top: 15,
    transform: [
      {
        scaleX: I18nManager.isRTL ? -1 : 1,
      },
    ],
  },
});

export default styles;
