import { makeNavigationItem } from 'dating-mobile/src/components/navigation-item';
import Resources from 'dating-mobile/src/resources';
import { CUSTOMER_SUPPORT } from 'dating-mobile/src/routing/router/constants';
import testId from 'dating-mobile/src/utils/test-id';

export const CustomerSupportNavigationItem = makeNavigationItem({
  route: CUSTOMER_SUPPORT,
  title: Resources.strings['customer-support-navigation-item-title'],
  icon: Resources.images.supportIcon(),
  ...testId(
    Resources.strings['customer-support-navigation-item-accessibility-label'],
  ),
});
