import React from 'react';
import { StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import Resources from '../../../../resources';
import BalanceRefiller, {
  PAYMENT_REASON,
} from '../../../../payment/utils/balance-refiller';

import ImageButton from '../../../../components/navigation/views/image-button';

import Controller from './controller';
import View from './view';
import styles from './styles';

const ScreenView = Controller(View);

class Screen extends React.Component {
  static displayName = 'dialogs.mingle.start.screen';

  static propTypes = {
    navigation: PropTypes.object.isRequired,
  };

  static navigationOptions = ({ navigation }) => ({
    headerTitle: '',
    headerStyle: styles.header,
    headerTitleStyle: styles.headerTitle,
    headerLeft: () => (
      <ImageButton
        image={Resources.images.backIcon()}
        tint={StyleSheet.flatten(styles.headerTitle).color}
        onPress={() => navigation.pop()}
      />
    ),
  });

  subscribe = (...args) => {
    BalanceRefiller.subscribe(PAYMENT_REASON.START_MINGLE, ...args);
  };

  refillBalance = (...args) => {
    BalanceRefiller.refillBalance(PAYMENT_REASON.START_MINGLE, ...args);
  };

  close = () => {
    const { navigation } = this.props;

    navigation.pop();
  };

  render() {
    return (
      <ScreenView
        subscribe={this.subscribe}
        refillBalance={this.refillBalance}
        close={this.close}
      />
    );
  }
}

export default Screen;
