import * as ROUTES from 'dating-mobile/src/routing/router/constants';

import { REGISTRATION_STEP } from './registration-steps';

export const ROUTES_MAPPING = {
  [REGISTRATION_STEP.TRAVEL_DESTINATION]: ROUTES.REGISTRATION_TRAVELS_SCREEN,
  [REGISTRATION_STEP.UPDATE_USER]: ROUTES.REGISTRATION_UPDATE_USER_SCREEN,
  [REGISTRATION_STEP.USER_GOALS]: ROUTES.USER_GOALS_PICKING,
  [REGISTRATION_STEP.CHOOSE_PREFERENCES]: ROUTES.REGISTRATION_GENDER_PICKING,
  [REGISTRATION_STEP.UPLOAD_PRIVATE_PHOTOS]: ROUTES.UPLOAD_PRIVATE_PHOTOS,
  [REGISTRATION_STEP.SEND_START_MESSAGES]: ROUTES.FREE_START_MESSAGES,
  [REGISTRATION_STEP.TOPICS_PICKING]: ROUTES.STREAMS_TOPICS_PICKING,
  [REGISTRATION_STEP.TOPICS_PICKING_BONUS]: ROUTES.STREAMS_TOPICS_PICKING_BONUS,
  [REGISTRATION_STEP.BIRTHDAY_WITH_GENDER]: ROUTES.BITHDAY_WITH_GENDER,
  [REGISTRATION_STEP.PHOTO_WITH_BIO]: ROUTES.PHOTO_WITH_BIO,
  [REGISTRATION_STEP.SUBSCRIPTION]: ROUTES.SUBSCRIPTION,
  [REGISTRATION_STEP.BOOST]: ROUTES.BOOST,
};

export function routeForRegistrationStep(step) {
  return ROUTES_MAPPING[step];
}
