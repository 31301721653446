import EStyleSheet from 'react-native-extended-stylesheet';

const textStyle = {
  fontFamily: '$brandFontFamily',
  color: '$primaryTextColor',
  textAlign: 'center',
};

const styles = EStyleSheet.create({
  $backgroundColor: '$primaryBackgroundColor',
  $statusBarStyle: '$primaryStatusBarStyle',
  $statusBarBackgroundColor: '$backgroundColor',

  header: {
    backgroundColor: '$backgroundColor',
    borderBottomColor: '$backgroundColor',
    shadowColor: '$backgroundColor',
  },

  headerTitle: {
    color: '$primaryTextColor',
  },

  scrollView: {
    flexGrow: 1,
  },

  content: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexGrow: 1,
    paddingHorizontal: 32,
  },

  title: {
    ...textStyle,
    fontWeight: 'bold',
    fontSize: 24,
    marginBottom: 10,
    marginTop: 20,
    textTransform: 'uppercase',
  },

  subTitle: {
    ...textStyle,
    fontSize: 18,
    marginBottom: 60,
  },

  benefits: {
    marginBottom: 20,
  },

  button: {
    marginTop: 40,
    marginBottom: 40,
    minWidth: 260,
    alignSelf: 'center',
  },

  disabledButton: {
    gradientColor: '$accentButtonDisabledGradientColor',
  },

  concernText: {
    fontSize: 12,
    fontFamily: '$brandFontFamily',
    textAlign: 'center',
    color: '$disabledColor',
    marginBottom: 10,
    '@media android': {
      fontSize: 11,
    },
  },

  activityIndicatorContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default styles;
