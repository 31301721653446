import React from 'react';
import PropTypes from 'prop-types';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';
import withHasUnreadRequests from 'dating-mobile/src/models/user.events/controllers/has-unread-requests';
import withConfigValue from 'dating-mobile/src/components/config-value';

export default function createController(Component) {

    class Controller extends React.Component {
        static displayName = 'routing.views.chat-requests-tab-bar-item.controller';
        static propTypes = {
            hasUnreadNonAutoRequests: PropTypes.bool,
            hasUnreadRequests: PropTypes.bool,
            automaticInvitationsEnabled: PropTypes.bool
        };

        render() {
            const hasUnreadRequests = this.props.hasUnreadNonAutoRequests ||
                (this.props.automaticInvitationsEnabled && this.props.hasUnreadRequests);

            return (<Component
                {...this.props}
                showIndicator={hasUnreadRequests}
            />);
        }
    }

    return withIdentityId(
        withHasUnreadRequests(
            withConfigValue(
                Controller,
                {
                    chatRequestsEnabled: 'features.chat-requests-enabled',
                    automaticInvitationsEnabled: 'features.automatic-invitations-enabled'
                }
            ),
            {
                resultPropName: 'hasUnreadRequests',
                nonAutoResultPropName: 'hasUnreadNonAutoRequests'
            }
        )
    );
}
