import { singleton } from '@sdv/commons/utils/singleton';
import api, { toObservable } from '@sdv/domain/api';
import {
  concatMap,
  distinctUntilChanged,
  map,
  tap,
  switchMapTo,
} from 'rxjs/operators';
import {
  from,
  of,
  combineLatest,
  ReplaySubject,
  defer,
  merge,
  EMPTY,
} from 'rxjs';
import { shopName, SupportedMethods } from '@sdv/domain/app/payment/shop-info';
import { localize } from '@sdv/domain/app/payment/price-localization';
import OS from '@sdv/domain/app/os';
import { UserLocation } from '@sdv/domain/user/location';
import { Config } from '@sdv/domain/app/config';

const isWeb = OS.shared().current === 'web';

// TODO: Hack. Try to find out more reliable workaround
const parsePrice = price => {
  let parsedPrice = null;

  if (price) {
    if (typeof price.localizedWithoutCurrency === 'string') {
      parsedPrice = parseFloat(
        price.localizedWithoutCurrency.replace(/,|\s/g, ''),
      );
    }

    if (
      (parsedPrice === null || Number.isNaN(parsedPrice)) &&
      typeof price.localizedIncludingTax === 'string'
    ) {
      const [match] =
        price.localizedIncludingTax.replace(/,|\s/g, '').match(/\d+(\.\d+)?/) ||
        [];

      parsedPrice = parseFloat(match);
    }
  }

  return parsedPrice;
};

export class Stock {
  static shared = singleton(userId => new Stock(userId));

  constructor(userId) {
    this.userId = userId;

    const cache = new ReplaySubject(1);
    const lastValue = defer(() => {
      if (!this.isLoading) {
        this.isLoading = true;

        return this.packagesInStock();
      }

      return EMPTY;
    }).pipe(
      tap(
        it => {
          this.loadingEnded();

          if (it) {
            cache.next(it);
          }
        },
        this.loadingEnded,
        this.loadingEnded,
      ),
      switchMapTo(EMPTY),
    );

    this.preloadedPackagesInStock = merge(cache, lastValue);
  }

  loadingEnded = () => {
    this.isLoading = false;
  };

  packagesInStock = () => {
    return of(this.userId).pipe(
      concatMap(id => {
        return combineLatest(
          this.getPackagesFromApi(id, shopName),
          this.taxes(id),
        );
      }),
      map(([list, tax]) => {
        return list.map(it => {
          const pack = this.mapJSONToPackage(it);
          const invoices = this.mapJSONToInvoice(
            it,
            pack.containsMembership,
            tax,
          );

          return [pack, invoices];
        });
      }),
      concatMap(packWithInvoiceList => {
        const allInvoiceSkuList = this.extractSku(packWithInvoiceList);

        return from(localize(allInvoiceSkuList)).pipe(
          map(skuWithLocalizedPriceList => {
            return packWithInvoiceList.map(packWithInvoices => {
              return this.localizeInvoices(
                packWithInvoices,
                skuWithLocalizedPriceList,
              );
            });
          }),
        );
      }),
      map(packages => {
        const memberships = [];

        packages
          .filter(it => it[0].containsMembership)
          .forEach(packWithInvoices => {
            const [pack, invoices] = packWithInvoices;

            const membership = memberships.find(
              desc =>
                desc[0].membershipPeriod === pack.membershipPeriod &&
                desc[0].isPremiumMembership === pack.isPremiumMembership,
            );

            if (!membership) {
              memberships.push(packWithInvoices);
            } else {
              membership[1].push(...invoices);
            }
          });

        const callsPacks = [];

        packages
          .filter(it => !it[0].containsMembership && it[0].containsCallsPack)
          .forEach(packWithInvoices => {
            callsPacks.push(packWithInvoices);
          });

        const boost = [];

        packages
          .filter(it => !it[0].containsMembership && it[0].boostTime)
          .forEach(packWithInvoices => {
            const [pack, invoices] = packWithInvoices;

            if (!boost[pack.boostTime]) {
              boost[pack.boostTime] = packWithInvoices;
            } else {
              boost[pack.boostTime][1].push(...invoices);
            }
          });

        const packs = [];

        packages
          .filter(
            it =>
              !it[0].containsMembership &&
              !it[0].boostTime &&
              !it[0].containsCallsPack,
          )
          .forEach(packWithInvoices => {
            const [pack, invoices] = packWithInvoices;

            if (!packs[pack.creditsAmount]) {
              packs[pack.creditsAmount] = packWithInvoices;
            } else {
              packs[pack.creditsAmount][1].push(...invoices);
            }
          });

        return memberships
          .filter(it => it)
          .concat(packs.filter(it => it))
          .concat(boost.filter(it => it))
          .concat(callsPacks.filter(it => it));
      }),
      map(packages => {
        return this.skipPacksWithEmptyInvoices(packages);
      }),
      map(packages => {
        return packages.map(packWithInvoice => {
          const [pack, invoices] = packWithInvoice;

          const localizedPack = Object.assign({}, pack);

          if (invoices.length) {
            const bestOffer = invoices.reduce((a, b) => {
              if (a.price.includingTax < b.price.includingTax) {
                return a;
              }

              return b;
            });

            localizedPack.price = bestOffer.price;
          }

          return [localizedPack, invoices];
        });
      }),
      map(this.addPackageBenefit),
    );
  };

  extractDiscounts(list) {
    const packagesWithDiscount = list.filter(it => it.meta?.SkuWithoutDiscount);
    const skuMap = packagesWithDiscount.reduce(
      (acc, it) => ({ ...acc, [it.sku]: it.meta.SkuWithoutDiscount }),
      {},
    );
    const allSku = list.map(it => it.sku);
    const skuWithoutDiscount = Object.values(skuMap).filter(it =>
      allSku.includes(it),
    );
    const skuWithDiscount = Object.keys(skuMap);

    return list
      .map(it => {
        if (skuWithoutDiscount.includes(it.sku)) {
          return null;
        }

        if (skuWithDiscount.includes(it.sku)) {
          return {
            ...it,
            packageWithoutDiscount: list.find(
              item => item.sku === skuMap[it.sku],
            ),
          };
        }

        return it;
      })
      .filter(Boolean);
  }

  trailPackagesInStock = () => {
    return this.preloadedPackagesInStock.pipe(
      map(packages => {
        return packages.filter(packsWithInvoices => {
          const [pack] = packsWithInvoices;

          return pack.price?.trialPeriod;
        });
      }),
    );
  };

  introductoryPackagesInStock = () => {
    return this.preloadedPackagesInStock.pipe(
      map(packages => {
        return packages.filter(packsWithInvoices => {
          const [pack] = packsWithInvoices;

          return pack.price?.introductory;
        });
      }),
    );
  };

  /**
   * @private
   */
  taxes(userId) {
    return new UserLocation(userId).currentLocation().pipe(
      concatMap(location =>
        Config.shared().countryTaxes.pipe(
          map(countryTaxes => countryTaxes[location.country]),
        ),
      ),
      distinctUntilChanged(),
    );
  }

  /**
   * @private
   */
  extractSku(packWithInvoiceList) {
    return packWithInvoiceList
      .reduce((acc, it) => [...acc, ...it[1]], [])
      .reduce(
        (acc, it) => [
          ...acc,
          isWeb ? it : it.sku,
          ...(it.skuWithoutDiscount && !isWeb ? [it.skuWithoutDiscount] : []),
        ],
        [],
      );
  }

  /**
   * @private
   */
  localizeInvoices(packWithInvoices, skuWithLocalizedPriceList) {
    const [pack, invoices] = packWithInvoices;
    const localizedInvoices = invoices.map(invoice => {
      return this.localizeInvoice(skuWithLocalizedPriceList, invoice);
    });

    return [pack, localizedInvoices];
  }

  /**
   * @private
   */
  localizeInvoice(skuWithLocalizedPriceList, invoice) {
    const localized = skuWithLocalizedPriceList.find(
      it => it.sku === invoice.sku,
    );
    const localizedWithoutDiscount =
      invoice.skuWithoutDiscount &&
      skuWithLocalizedPriceList.find(
        it => it.sku === invoice.skuWithoutDiscount,
      );
    const localizedInvoice = Object.assign({}, invoice);

    if (localized) {
      localizedInvoice.price = {
        ...localizedInvoice.price,
        localizedIncludingTax: localized.localizedPrice,
        localizedCurrency: localized.localizedCurrency,
        localizedWithoutCurrency: localized.localizedPriceWithoutCurrency,
        introductory: localized.introductoryPrice,
        subscriptionPeriodNumber: localized.subscriptionPeriodNumber,
        subscriptionPeriodUnit: localized.subscriptionPeriodUnit,
        trialPeriod: localized.trialPeriod,
      };
    }

    if (localizedWithoutDiscount) {
      localizedInvoice.priceWithoutDiscount = {
        ...localizedInvoice.priceWithoutDiscount,
        localizedIncludingTax: localizedWithoutDiscount.localizedPrice,
        localizedCurrency: localizedWithoutDiscount.localizedCurrency,
        localizedWithoutCurrency:
          localizedWithoutDiscount.localizedPriceWithoutCurrency,
        introductory: localizedWithoutDiscount.introductoryPrice,
        subscriptionPeriodNumber:
          localizedWithoutDiscount.subscriptionPeriodNumber,
        subscriptionPeriodUnit: localizedWithoutDiscount.subscriptionPeriodUnit,
        trialPeriod: localizedWithoutDiscount.trialPeriod,
      };
    }

    return localizedInvoice;
  }

  skipPacksWithEmptyInvoices(packages) {
    return packages.filter(packWithInvoice => {
      const [, invoices] = packWithInvoice;

      return invoices.length > 0;
    });
  }

  getBoostTime = duration => {
    if (!duration) {
      return null;
    }

    return duration
      .split(':')
      .reduce((acc, time) => 60 * acc + parseInt(time, 10));
  };

  /**
   * @private
   */
  getPackagesFromApi = (id, shop) => {
    return toObservable(api.credits.mall.credits.get, id, { shop });
  };

  /**
   * @private
   */
  mapJSONToPackage = json => {
    return {
      creditsAmount: json.amount,
      containsMembership: json.meta?.Subscription,
      containsCallsPack: json.meta?.Safe?.['package.calls'] === 'True',
      isPremiumMembership: json.meta?.Safe?.['membership.premium'] === 'True',
      boostTime: this.getBoostTime(json.meta?.Safe?.['package.boost.duration']),
      isTopBoost: json.meta?.Safe?.['package.boost.top'] === 'True',
      membershipPeriod: json.meta?.Recurring?.Period,
      price: {
        includingTax: json.price,
        currency: json.currency,
      },
    };
  };

  /**
   * @private
   */
  mapJSONToInvoice = (json, containsMembership, tax) => {
    if ((!json.methods || json.methods.length === 0) && shopName === 'ios') {
      // eslint-disable-next-line no-param-reassign
      json.methods = ['apple'];
    } else if (
      (!json.methods || json.methods.length === 0) &&
      shopName === 'web'
    ) {
      // eslint-disable-next-line no-param-reassign
      json.methods = ['cards'];
    }

    return json.methods
      .map(method => {
        const recurringSku = json.meta?.Recurring?.Sku;
        const isTrial = recurringSku && recurringSku !== json.sku;
        const mobilePriceWithoutDiscount = json.packageWithoutDiscount
          ? {
              includingTax: json.packageWithoutDiscount.price,
              currency: json.packageWithoutDiscount.currency,
              tax,
            }
          : null;

        const webPriceWithoutDiscount = isTrial
          ? {
              includingTax: json.meta?.Recurring?.Price,
              currency: json.currency,
              tax,
            }
          : mobilePriceWithoutDiscount;

        return {
          creditsAmount: json.amount,
          containsMembership: json.meta?.Subscription,
          isTopBoost: json.meta?.Safe?.['package.boost.top'] === 'True',
          boostTime: this.getBoostTime(
            json.meta?.Safe?.['package.boost.duration'],
          ),
          membershipPeriod: json.meta?.Recurring?.Period,
          isTrial,
          containsCallsPack: json.meta?.Safe?.['package.calls'] === 'True',
          isPremiumMembership:
            json.meta?.Safe?.['membership.premium'] === 'True',
          price: {
            includingTax: json.price,
            currency: json.currency,
            tax,
          },
          description: json.meta?.Description,
          discount: json.meta?.DiscountPercentage,
          skuWithoutDiscount: json.packageWithoutDiscount?.sku,
          priceWithoutDiscount: isWeb
            ? webPriceWithoutDiscount
            : mobilePriceWithoutDiscount,
          sku: json.sku,
          method,
        };
      })
      .filter(it => it.containsMembership === containsMembership)
      .filter(it => SupportedMethods[it.method]);
  };

  addPackageBenefit(packages) {
    const packagesWithBoostAndPrice = packages.filter(
      ([pack]) =>
        pack.boostTime && parsePrice(pack.price) && !pack.containsMembership,
    );

    const cheapestPack = packagesWithBoostAndPrice.reduce(
      (currentCheapestPack, [pack]) => {
        if (
          !currentCheapestPack ||
          parsePrice(pack.price) < parsePrice(currentCheapestPack.price)
        ) {
          return pack;
        }

        return currentCheapestPack;
      },
      null,
    );

    if (!cheapestPack) {
      return packages;
    }

    const pricePerSecondOfCheapestPack =
      parsePrice(cheapestPack.price) / cheapestPack.boostTime;

    return packages.map(packWithInvoices => {
      if (packagesWithBoostAndPrice.includes(packWithInvoices)) {
        const [pack, ...rest] = packWithInvoices;
        const pricePerSecond = parsePrice(pack.price) / pack.boostTime;
        const benefitPercentage = Math.ceil(
          (1 - pricePerSecond / pricePerSecondOfCheapestPack) * 100,
        );

        return [
          {
            ...pack,
            benefitPercentage,
          },
          ...rest,
        ];
      }

      return packWithInvoices;
    });
  }
}

/**
 * Package data
 * @typedef {
 * {
 *  creditsAmount: number,
 *  containsMembership: bool,
 *  price: {
 *      price: number,
 *      currency: string
 *  }
 * }
 * } Package
 */

/**
 * Invoice data
 * @typedef {
 * {
 *  creditsAmount: number,
 *  containsMembership: bool,
 *  price: {
 *      price: number,
 *      currency: string
 *  },
 *  sku: string,
 *  method: string,
 *  currency: string,
 *  localizedPrice: number
 * }
 * } Invoice
 */
