import React from 'react';
import PropTypes from 'prop-types';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import Resources from 'dating-mobile/src/resources';
import styles from './styles';

export default class NavigationItemView extends React.PureComponent {
  static displayName = 'components.navigation-item.view';

  static propTypes = {
    onPress: PropTypes.func,
    disabled: PropTypes.bool,
    isInline: PropTypes.bool,
    icon: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.string,
    ]),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    hint: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    pinVisible: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    ValueComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    iconContainerStyle: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    titleStyle: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    titleNumberOfLines: PropTypes.number,
    iconStyle: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    pinStyle: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    titleContainerStyle: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    pinIcon: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    accessibilityLabel: PropTypes.string,
    testID: PropTypes.string,
  };

  render() {
    const {
      ValueComponent,
      icon,
      value,
      onPress,
      disabled,
      accessibilityLabel,
      testID,
      iconContainerStyle,
      iconStyle,
      titleStyle,
      title,
      hint,
      pinVisible,
      pinIcon,
      pinStyle,
      isInline,
      titleNumberOfLines = 1,
      titleContainerStyle: titleContStyle,
    } = this.props;

    const hasIcon = typeof icon !== 'undefined';
    const hasTextValue = !ValueComponent && !!value;
    const Container = isInline ? View : TouchableOpacity;

    let titleContainerStyle = hasIcon
      ? styles.titleContainer
      : styles.titleContainerWithoutIcon;

    titleContainerStyle = titleContStyle
      ? [titleContainerStyle, titleContStyle]
      : titleContainerStyle;

    const titleWrapperStyle = hasTextValue
      ? styles.titleWrapper
      : styles.titleWrapperWithoutValue;

    return (
      <Container
        onPress={onPress}
        disabled={disabled}
        accessibilityLabel={accessibilityLabel}
        testID={testID}
      >
        <View style={styles.item}>
          {hasIcon && (
            <View style={[styles.iconContainer, iconContainerStyle]}>
              <Image style={[styles.icon, iconStyle]} source={icon} />
            </View>
          )}
          <View style={titleContainerStyle}>
            <View style={titleWrapperStyle}>
              <Text
                style={[styles.title, titleStyle]}
                numberOfLines={titleNumberOfLines}
              >
                {title}
              </Text>
              {hint && <Text style={styles.hint}>{hint}</Text>}
            </View>
            {!!ValueComponent && typeof ValueComponent === 'function' && (
              <ValueComponent />
            )}
            {!!ValueComponent &&
              typeof ValueComponent !== 'function' &&
              ValueComponent}
            {hasTextValue && (
              <Text
                numberOfLines={1}
                style={[styles.value, disabled && styles.valueDisabled]}
              >
                {value}
              </Text>
            )}
            {!!pinVisible && !disabled && !isInline && (
              <View style={styles.pinContainer}>
                <Image
                  source={pinIcon || Resources.images.pinIcon()}
                  style={[styles.pin, pinStyle]}
                />
              </View>
            )}
            {disabled && <View style={styles.expander} />}
          </View>
        </View>
      </Container>
    );
  }
}
