import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({

    container: {
        flexDirection: 'column',
        paddingTop: 10,
        backgroundColor: '$primaryBackgroundColor'
    },

    cheers: {
        marginTop: 6,
        height: 200
    }
});

export default styles;
