import EventEmitter from 'eventemitter3';
import { mediaDevices } from 'react-native-webrtc';
import { mixin } from '../../../utils/object';

const CHROME_STREAM_TIMEOUT = 400;
const GIVE_UP_TIMEOUT = 15000;
const UNAPPROVED_TIMEOUT = 1000;

function StreamSource(config) {
  this.unapproved_timeout =
    (config && config['user-media-timeout']) || UNAPPROVED_TIMEOUT;
  this._stream = null;
  this._counter = 0;
  this._gotUserMediaCallback = false;
  this._unfinished = null;
  this._front = true;
}

StreamSource.prototype = mixin(new EventEmitter(), {
  _stopTrack(track) {
    track.stop();
  },

  _stop: function stopStream() {
    this._stream.oninactive = null;

    this._stream.getVideoTracks().forEach(function(track) {
      track.onended = null;
    });

    if (this._stream.getTracks) {
      this._stream.getTracks().forEach(this._stopTrack);
    } else if (this._stream.stop) {
      this._stream.stop();
    }

    this._stream = null;
  },

  _tryReceive(stream) {
    let rejected = false;

    return new Promise(function(resolve, reject) {
      if (typeof stream.active === 'undefined') {
        resolve(stream); // FF не имеет active но при этом возвращает ошибку при попытке получить занятый поток

        return;
      }

      // FIXME: нет возможности нормально проверить доступность потока в хром
      // он возворащает поток с флагом active true который вне потока JS меняется на false
      setTimeout(function() {
        if (rejected) {
          return;
        }

        if (stream.active) {
          resolve(stream);

          return;
        }

        stream.onactive = function() {
          resolve(stream);
        };
      }, CHROME_STREAM_TIMEOUT);

      stream.oninactive = function() {
        rejected = true;
        reject('busy stream');
      };
    });
  },

  _getUserMedia({ audio, video } = {}) {
    const requestAudio = typeof audio !== 'undefined' ? audio : true;
    const requestVideo = typeof video !== 'undefined' ? video : false;
    // const requestVideo =
    //   typeof video !== 'undefined'
    //     ? video
    //     : {
    //         facingMode: this._front ? 'user' : 'environment',
    //         mandatory: {
    //           minWidth: 1280, // Hack: WebRTC getUserMedia function throws an error when called second time.
    //           minHeight: 720, // Set up default constraints explicitly fixes this bug. Constraints are added by WebRTC module itself when this fields are empty.
    //         },
    //       };
    const constraints = {
      audio: requestAudio,
      video: requestVideo,
    };

    return mediaDevices
      .getUserMedia(constraints)
      .then(this._tryReceive.bind(this))
      .then(this._clean.bind(this))
      .then(
        function(stream) {
          this._gotUserMediaCallback = true;
          // bus.emit('command.overlay.close', { name: 'camera-permissions-prompt' });
          this._stream = stream;

          this._stream.getVideoTracks().forEach(
            function(track) {
              track.onended = function() {
                const ended =
                  this._stream.getVideoTracks().filter(function(track) {
                    return track.readyState !== 'ended';
                  }).length === 0;

                if (ended) {
                  this._counter = 0;
                  this.close();
                  this.emit('inactive');
                }
              }.bind(this);
            }.bind(this),
          );

          this._stream.oninactive = function() {
            this._counter = 0;
            this.close();
            this.emit('inactive');
          }.bind(this);

          return this._stream;
        }.bind(this),
      )
      .catch(
        function(error) {
          const tryWithoutAudio =
            (error.name === 'NotFoundError' ||
              error.name === 'NotAllowedError') &&
            requestAudio;

          if (video && tryWithoutAudio) {
            return this._getUserMedia({ video, audio: false });
          }

          this._gotUserMediaCallback = true;
          // bus.emit('command.overlay.close', { name: 'camera-permissions-prompt' });
          this._clean();
          this.close();
          throw error;
        }.bind(this),
      );
  },

  get({ audio, video } = {}) {
    this._counter++;

    if (this._stream) {
      return Promise.resolve(this._stream);
    }

    if (this._unfinished) {
      return this._unfinished;
    }

    this._unfinished = Promise.race([
      this._getUserMedia({ audio, video }),
      new Promise(
        function(resolve, reject) {
          this._timeout = setTimeout(
            function() {
              reject({ name: 'timeout' });
              this._clean();
              this.close();
            }.bind(this),
            GIVE_UP_TIMEOUT,
          );
        }.bind(this),
      ),
    ]);

    setTimeout(
      function() {
        if (this._gotUserMediaCallback) {
          return false;
        }

        // bus.emit('overlay', 'camera-permissions-prompt');
      }.bind(this),
      this.unapproved_timeout,
    );

    // bus.on('event.overlay.camera-permissions-prompt.closed', function () {
    //     this._gotUserMediaCallback = true;
    // }.bind(this));

    return this._unfinished;
  },

  _clean(stream) {
    this._timeout = this._timeout && clearTimeout(this._timeout);
    stream && (stream.onactive = stream.oninactive = null);
    this._unfinished = null;

    return stream;
  },

  toggleAudio(enable) {
    this._stream &&
      this._stream.getAudioTracks().forEach(function(track) {
        track.enabled = !!enable;
      });
  },

  toggleVideo(enable) {
    this._stream &&
      this._stream.getVideoTracks().forEach(function(track) {
        track.enabled = !!enable;
      });
  },

  rotateVideo() {
    this._front = !this._front;
    this._stream &&
      this._stream.getVideoTracks().forEach(track => {
        typeof track._switchCamera === 'function' && track._switchCamera();
      });
  },

  close() {
    this._counter--;

    if (this._counter <= 0) {
      this._counter = 0;
      this._clean();
      this._stream && this._stop();
    }
  },
});

export default StreamSource;
