import flux from '@sdv/domain/app/flux';
import { singleton } from '@sdv/commons/utils/singleton';
import Identity from '@sdv/domain/identity/model';

class PasswordUpdating {
    static shared = singleton(() => new PasswordUpdating());

    updatePassword = (password, completion) => {
        flux.get(Identity).actions.updatePassword(password, completion);
    };
}

export { PasswordUpdating };
