import { createOptimisticActions } from '@sdv/model';

function createPreferenceActions(userId) {

    class Actions extends createOptimisticActions(['put']) {

        get = () => (dispatch, flux) => {

            flux.api.users.preferences.get(userId, (error, response) => {

                if (error) {
                    return dispatch(null, error);
                }

                return dispatch(response);
            });

        };

        put(preferences) {

            return super.put(preferences, function (preferences) {

                this.dispatch(preferences);

                this.flux.api.users.preferences.put(userId, preferences, (error) => {
                    if (error) {
                        return this.revert(error);
                    }
                    return this.commit();
                });

            });

        }

    }

    Actions.displayName = createPreferenceActions.getDisplayName(userId);

    return Actions;
}

createPreferenceActions.getDisplayName = function (id) {
    return `user-preferences.${id}`;
};

export default createPreferenceActions;
