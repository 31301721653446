import qs from 'qs';
import MediaUploader from '@sdv/domain/dialogs.media-uploader';
import connect from '@sdv/connect';
import PropTypes from "prop-types";
import React from 'react';

export default function createController(Component) {

    class Controller extends React.Component {
        static displayName = 'inbox.views.media-attachment.controller';

        static propTypes = {
            senderId: PropTypes.string,
            recipientId: PropTypes.string,
            uploads: PropTypes.array,
            resource: PropTypes.object
        };

        render() {
            let progress = null;
            let resource = this.props.resource;

            const transfer = this.props.uploads[this.props.resource.uri];
            if (transfer !== undefined) {
                switch (transfer.status) {
                    case 'uploading':
                        progress = transfer.progress;
                        break;
                    case 'complete':
                        resource = transfer.result;
                        break;
                    default:
                        break;
                }
            }

            return (<Component
                {...this.props}
                progress={progress}
                resource={resource}
            />);
        }
    }


    function getModels(flux, props) {
        const models = {};

        if (props.senderId && props.recipientId) {
            const modelId = qs.stringify({ senderId: props.senderId, recipientId: props.recipientId });
            models.mediaUploader = flux.get(MediaUploader, modelId);
        }

        return models;
    }

    function mapStoresToProps(models) {
        let props = {};

        if (models.mediaUploader) {
            props.uploads = models.mediaUploader.store.getState().uploads
        }

        return props;
    }

    return connect(getModels, mapStoresToProps)(Controller);
}
