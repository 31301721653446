import withUser from 'dating-mobile/src/models/user/controllers';
import React from 'react';
import PropTypes from 'prop-types';

export default function createNewUserIndicatorController(Component) {

    class NewUserController extends React.Component {

        static displayName = 'user.common.views.new-user-indicator.controller';
        static propTypes = {
            user: PropTypes.object.isRequired,
        };

        isRecentlyCreatedUser = () => {
            const oneDayInMs = 24 * 60 * 60 * 1000;

            return this.props.user &&
                (Date.now() - Date.parse(this.props.user['registration-date'])) <= oneDayInMs;
        };

        render() {
            return (
                <Component
                    isNew={this.isRecentlyCreatedUser()}
                />
            );
        }
    }

    return withUser(NewUserController, {
        userIdPropName: 'userId'
    });

}