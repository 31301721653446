import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $cheersButtonVisible: false,
    $privateTagButtonVisible: true,

    '@flavor lovinga, udates': {
        $cheersButtonVisible: true
    },

    '@flavor lovinga': {
        $privateTagButtonVisible: false
    },

    $statusBarStyle: 'light-content',
    $statusBarBackgroundColor: 'black',

    headerBackground: {
        flex: 1,
        backgroundColor: '$shadowColor'
    },

    headerTitle: {
        color: 'white',
        fontFamily: '$brandFontFamily'
    },

    container: {
        backgroundColor: 'black',
        flex: 1
    },

    actionButtonsContainer: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        bottom: 20,
        height: 72,
        '@flavor lovinga, udates': {
            justifyContent: 'flex-end',
            bottom: 24
        },
        '@flavor dma': {
            bottom: 24
        }
    },

    tagsContainer: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        position: 'absolute',
        paddingHorizontal: 20,
        bottom: 20,
        '@flavor lovinga, udates': {
            paddingHorizontal: 16,
            bottom: 16
        }
    },

    sendGiftButton: {
        backgroundColor: 'white',
        marginRight: 16
    },

    togglePrivateButton: {
        flexDirection: 'row',
        justifyContent: 'center',
        height: 40,
        backgroundColor: '$primaryBackgroundColor',
        borderRadius: 20,
        paddingHorizontal: 15
    },

    setPrivateButtonText: {
        marginTop: 11,
        color: '$primaryTextColor',
        fontSize: 14,
        fontFamily: '$brandFontFamily',
        fontWeight: 'bold'
    },

    unsetPrivateButtonText: {
        marginTop: 11,
        color: '$accentColor',
        fontSize: 14,
        fontFamily: '$brandFontFamily',
        fontWeight: 'bold'
    },

    videoCallButton: {
        marginRight: 16,
        marginLeft: 16,
        backgroundColor: '$acceptColor'
    },
    videoCallIcon: {
        tintColor: 'white'
    }
});

export default styles;
