import { BaseStore } from '@sdv/model';
import Actions from './actions';
import callsApi from '../call.api/api';

function createStore(id) {
  class Store extends BaseStore {
    constructor(flux) {
      super(flux);

      this.state = {
        needPermissions: false,
        needCameraPermission: false,
        needMicrophonePermission: false,
      };

      callsApi.addListener('overlay', this.onPermissionsOverlay);

      const actions = flux.getActions(Actions, id);

      this.bindAction(actions.ignore, this.ignore);
      this.bindAction(actions.reset, this.reset);
    }

    reset = () => {
      this.setState({
        needPermissions: false,
        needCameraPermission: false,
        needMicrophonePermission: false,
      });
    };

    ignore = () => {
      this.setState({
        needPermissions: false,
        needCameraPermission: false,
        needMicrophonePermission: false,
      });
      callsApi.emit('event.overlay.call-permissions-ignored');
    };

    onPermissionsOverlay = name => {
      if (name === 'camera-blocked') {
        this.setState({
          needPermissions: true,
          needCameraPermission: true,
        });
      }

      if (name === 'microphone-blocked') {
        this.setState({
          needPermissions: true,
          needMicrophonePermission: true,
        });
      }
    };
  }

  Store.displayName = createStore.getDisplayName(id);
  Store.config = {
    getState(state) {
      return { ...state.data };
    },
  };

  return Store;
}

createStore.getDisplayName = function(id) {
  return `call-permissions.${id}`;
};

export default createStore;
