import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet } from 'react-native';
import FollowButton from 'dating-mobile/src/user/common/views/set-favorite-button';
import { dialogResource } from '@sdv/domain/resources/remote';
import Resources from 'dating-mobile/src/resources';
import testId from 'dating-mobile/src/utils/test-id';
import ButtonGroup from 'dating-mobile/src/components/navigation/views/button-group';
import ImageButton from 'dating-mobile/src/components/navigation/views/image-button';
import ReportButton from 'dating-mobile/src/components/report-button';
import CallButton from 'dating-mobile/src/call/views/video-call-button';
import { AudioCallButton } from 'dating-mobile/src/call/views/audio-call-button';
import { AnimatedAppearing } from 'dating-mobile/src/components/animated-appearing';
import * as ROUTES from 'dating-mobile/src/routing/router/constants';
import flux from '@sdv/domain/app/flux';
import IfConfigValue from '../../../components/config-value/if';
import ChatTitle from '../views/chat-title';
import styles from './styles';

export default function(Component) {
  class Navigation extends React.Component {
    static displayName = 'dialogs.messages.screen.navigation';

    static propTypes = {
      navigation: PropTypes.object.isRequired,
    };

    static navigationOptions = ({ navigation }) => {
      const {
        showChatTitle,
        onProfilePress,
        id,
        userId,
        allowAlias,
        animateChatTitle,
        showAudioCallButton,
      } = navigation.state.params;

      return {
        headerTitle: '',
        headerStyle: styles.header,
        headerTitleStyle: styles.headerTitle,
        headerLeftContainerStyle: styles.headerLeftContainer,
        headerRightContainerStyle: styles.headerRightContainer,
        headerLeft: () => (
          <ButtonGroup>
            <ImageButton
              image={Resources.images.backIcon()}
              tint={StyleSheet.flatten(styles.headerTitle).color}
              onPress={() => navigation.pop()}
              {...testId(
                Resources.strings[
                  'navigation-bar-back-button-accessibility-label'
                ],
              )}
            />
            <AnimatedAppearing
              useNativeDriver
              animationDuration={animateChatTitle ? 300 : 0}
              appearingAnimationName="fadeInUp"
              disappearingAnimationName="fadeOutDown"
              isVisible={showChatTitle}
              style={styles.chatTitle}
            >
              <ChatTitle id={id} onPress={onProfilePress} />
            </AnimatedAppearing>
          </ButtonGroup>
        ),
        headerRight: () => (
          <ButtonGroup>
            <IfConfigValue path="features.streams-enabled" equalsTo={true}>
              {styles.$followButtonVisible && (
                <FollowButton holderId={userId} targetId={id} />
              )}
            </IfConfigValue>
            {!!showAudioCallButton && (
              <AudioCallButton userId={userId} attendeeId={id} />
            )}
            {styles.$reportButtonVisible && (
              <ReportButton
                id={id}
                tint={StyleSheet.flatten(styles.headerTitle).color}
                showIcon
                allowBlock
                allowAlias={allowAlias}
                reportedContent={dialogResource(userId, id)}
                showProfileDisclosureButton
                onChatDelete={() => navigation.pop()}
              />
            )}
            <IfConfigValue path="features.video-chat-enabled" equalsTo={true}>
              <CallButton
                userId={userId}
                attendeeId={id}
                hideWhenDisabled
                iconStyle={styles.videoCallIcon}
                style={styles.videoCallButton}
              />
            </IfConfigValue>
          </ButtonGroup>
        ),
      };
    };

    componentDidMount() {
      const { navigation } = this.props;
      const code = navigation.getParam('invitationCode');

      if (code) {
        flux.api.codes.invitation.post({ code });
      }
    }

    setTopBarState = state => {
      const { navigation } = this.props;

      if (navigation) {
        navigation.setParams(state);
      }
    };

    onDialogApprovalBannerClose = () => {
      const { navigation } = this.props;

      if (navigation) {
        navigation.pop();
      }
    };

    onPopToRootButtonPress = () => {
      const { navigation } = this.props;

      if (navigation) {
        navigation.popToTop();
      }
    };

    openMedia = options => {
      const { navigation } = this.props;

      navigation.navigate(ROUTES.GALLERY, options);
    };

    openProfile = options => {
      const { navigation } = this.props;

      navigation.navigate(ROUTES.PROFILE, options);
    };

    render() {
      const { navigation } = this.props;

      return (
        <Component
          {...this.props}
          onPopToRootButtonPress={this.onPopToRootButtonPress}
          attendeeId={navigation.getParam('id')}
          setTopBarState={this.setTopBarState}
          onDialogApprovalBannerClose={this.onDialogApprovalBannerClose}
          openMedia={this.openMedia}
          openProfile={this.openProfile}
          navigation={null}
        />
      );
    }
  }

  return Navigation;
}
