import React from 'react';
import PropTypes from 'prop-types';
import { AppState, StatusBar, View } from 'react-native';
import { SafeAreaView } from 'react-navigation';
import SoundPlayer from 'dating-mobile/src/native-modules/sound-player';
import { BlockUserButton } from 'dating-mobile/src/call/views/block-user-button';
import CallConnectingInformation from '../../views/call-connecting-information';
import RoundButton from '../../../components/buttons/round';
import Resources from '../../../resources';
import UserThumbnail from '../../../user/common/views/thumbnail';

import styles from './styles';

export default class IncomingCallView extends React.Component {
  static displayName = 'video-chat.incoming-call';

  static propTypes = {
    userId: PropTypes.string,
    attendeeId: PropTypes.string,
    showVideo: PropTypes.bool,
    onAcceptButtonPress: PropTypes.func,
    onDeclineButtonPress: PropTypes.func,
  };

  isPlaying = false;

  componentDidMount() {
    this.playSoundFile();
    AppState.addEventListener('change', this.appStateChanged);
    this.appStateChanged(AppState.currentState);
  }

  shouldComponentUpdate(nextProps) {
    const { userId, attendeeId, showVideo } = this.props;

    return (
      userId !== nextProps.userId ||
      attendeeId !== nextProps.attendeeId ||
      showVideo !== nextProps.showVideo
    );
  }

  componentWillUnmount() {
    this.stopSoundFile();
    AppState.removeEventListener('change', this.appStateChanged);
  }

  appStateChanged = nextAppState => {
    if (this.appState !== nextAppState) {
      if (nextAppState === 'active') {
        this.playSoundFile();
      } else {
        this.stopSoundFile();
      }
    }

    this.appState = nextAppState;
  };

  playSoundFile() {
    if (!this.isPlaying) {
      SoundPlayer.playSoundFile('ringtone', 'mp3', true);
    }

    this.isPlaying = true;
  }

  stopSoundFile() {
    if (this.isPlaying) {
      SoundPlayer.stop();
    }

    this.isPlaying = false;
  }

  render() {
    const {
      userId,
      attendeeId,
      onAcceptButtonPress,
      onDeclineButtonPress,
      showVideo,
    } = this.props;

    return (
      <SafeAreaView
        forceInset={{ top: 'never', bottom: 'always' }}
        style={styles.container}
      >
        <StatusBar
          barStyle={styles.$statusBarStyle}
          backgroundColor={styles.$statusBarBackgroundColor}
        />
        {!!showVideo && (
          <UserThumbnail
            style={styles.background}
            userId={attendeeId}
            size={styles.$hasBackgroundBlurred ? '.150x150' : undefined}
            blurRadius={styles.$hasBackgroundBlurred ? 7 : 0}
            placeholder={undefined}
          />
        )}
        <View style={styles.overlay} />
        <View style={styles.callConnectionInformation}>
          <CallConnectingInformation
            userId={userId}
            attendeeId={attendeeId}
            isVideoCall={showVideo}
          />
        </View>
        <View style={styles.buttonsContainer}>
          <View style={styles.streamButtonsContainer}>
            <BlockUserButton
              userId={userId}
              attendeeId={attendeeId}
              onBlock={onDeclineButtonPress}
            />
          </View>
          <View style={styles.controlButtonsContainer}>
            <RoundButton
              icon={Resources.images.callEnd()}
              style={styles.declineButton}
              onPress={onDeclineButtonPress}
            />
            <RoundButton
              icon={Resources.images.callStart()}
              style={styles.acceptButton}
              onPress={onAcceptButtonPress}
            />
          </View>
        </View>
      </SafeAreaView>
    );
  }
}
