import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    disabled: {
        opacity: 0.5
    },

    icon: {
        tintColor: 'white'
    }
});

export default styles;
