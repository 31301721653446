import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity, Modal, View, Text, Image } from 'react-native';
import Resources from 'dating-mobile/src/resources';
import testId from 'dating-mobile/src/utils/test-id';
import isTestBuild from 'dating-mobile/src/utils/is-test-build';
import Endpoint from '.';
import styles from './styles';

const ENDPOINT_FEATURE_KEY = 'endpoint-switch';

class EndpointSwitcher extends Component {
  static displayName = 'modal.endpoint.switcher';
  static contextTypes = {
    flux: PropTypes.object,
  };
  state = {
    isVisible: false,
  };

  constructor(props, context) {
    super(props);
    this.flux = context.flux;
    this.bus = context.flux.bus;
    this.state[ENDPOINT_FEATURE_KEY] = isTestBuild;
  }

  toggleOpen = () => {
    this.setState(state => ({
      isVisible: !state.isVisible,
    }));
  };

  render() {
    if (!this.state[ENDPOINT_FEATURE_KEY]) {
      return null;
    }

    return (
      <View style={styles.endpoint} {...testId('Change endpoint button')}>
        <TouchableOpacity onPress={this.toggleOpen}>
          <Image source={Resources.images.supportIcon()} style={styles.icon} />
        </TouchableOpacity>

        <Modal
          animationType="slide"
          transparent={false}
          visible={this.state.isVisible}
          onRequestClose={() => {}}
        >
          <View style={styles.modal}>
            <TouchableOpacity onPress={this.toggleOpen}>
              <Text style={styles.close}>Close</Text>
            </TouchableOpacity>

            <Endpoint hide={this.toggleOpen} />
          </View>
        </Modal>
      </View>
    );
  }
}

export default EndpointSwitcher;
