import React from 'react';
import PropTypes from 'prop-types';
import connect from '@sdv/connect';
import Model, { getId } from '../model';

const GET_PROPERTY = 'generated.dialogs-letter.controller.get';

export default function createController(Component, options = {}) {

    const senderPropName = options.senderPropName || 'sender';
    const recipientPropName = options.recipientPropName || 'recipient';
    const letterPropName = options.letterPropName || 'letter';
    const isIntroductoryPropName = options.isIntroductoryPropName || 'introductory';
    const resultPropName = options.resultPropName || '.';
    const onLetterViewedPropName = options.onLetterViewedPropName || 'onLetterViewed';

    class Controller extends React.Component {
        static displayName = 'dialogs-letter.controller';
        static propTypes = {
            [senderPropName]: PropTypes.string,
            [recipientPropName]: PropTypes.string,
            [letterPropName]: PropTypes.string,
            [resultPropName]: PropTypes.object,
            [GET_PROPERTY]: PropTypes.func
        };
        static contextTypes = {
            flux: PropTypes.object
        };

        componentDidMount() {
            this.props[GET_PROPERTY] && this.props[GET_PROPERTY]();
        }

        componentDidUpdate(prevProps) {
            if (shouldReconnect(prevProps, this.props)) {
                this.props[GET_PROPERTY] && this.props[GET_PROPERTY]();
            }
        }

        render() {
            const { ...props } = this.props;

            delete props[GET_PROPERTY];

            return (
                <Component
                    {...props}
                />
            );
        }

    }

    function getModels(flux, props) {
        const models = {};

        if (props[senderPropName] && props[recipientPropName] && props[letterPropName]) {
            models.letterModel = flux.get(Model, getId(
                props[recipientPropName],
                props[senderPropName],
                props[letterPropName],
                props[isIntroductoryPropName]
            ));
        }

        return models;
    }

    function mapModelsToProps(models) {
        const letter = models.letterModel
            ? models.letterModel.store.getState()
            : {};
        if (resultPropName === '.') {
            return letter;
        }
        return {
            [resultPropName]: letter
        };
    }

    function mapModelsToActions(models) {
        const props = {};

        if (models.letterModel) {
            props[GET_PROPERTY] = models.letterModel.actions.get;
            props[onLetterViewedPropName] = models.letterModel.actions.markAsRead;
        }

        return props;
    }

    function shouldReconnect(props, newProps) {
        return props[senderPropName] !== newProps[senderPropName] ||
            props[recipientPropName] !== newProps[recipientPropName] ||
            props[letterPropName] !== newProps[letterPropName] ||
            props[isIntroductoryPropName] !== newProps[isIntroductoryPropName];
    }

    return connect(
        getModels,
        mapModelsToProps,
        mapModelsToActions,
        shouldReconnect
    )(Controller);

};
