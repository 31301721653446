import Navigator from 'dating-mobile/src/routing/navigator';
import { LETTER } from 'dating-mobile/src/routing/router/constants';

export default function readLetter(letter) {
  Navigator.navigate(LETTER, {
    recipient: letter.recipient,
    sender: letter.sender,
    letter: letter.id,
    introductory: letter.introductory,
  });
}
