export const mapInvoiceToProduct = function(invoice) {
  return {
    sku: invoice.sku,
    meta: {
      Subscription: invoice.containsMembership,
    },
    amount: invoice.creditsAmount,
    price: invoice.price.includingTax,
    skuType: invoice.containsMembership ? 'sub' : 'inapp',
    isPremiumMembership: invoice.isPremiumMembership,
    boostTime: invoice.boostTime,
    currency: invoice.currency || invoice.price.currency,
    methods: [invoice.method],
  };
};
