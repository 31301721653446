import StyleSheet from 'react-native-extended-stylesheet';
import Style from 'react-native-extended-stylesheet/src/style';
import Sheet from 'react-native-extended-stylesheet/src/sheet';
import Value from 'react-native-extended-stylesheet/src/value';

/*

const styles = StyleSheet.create({

    $numberOfLines: 1,
    '@flavor flavor-name': {
        $numberOfLines: 2
    },

    item: {
        width: ({ $numberOfLines }) => `${Math.ceil(100 / $numberOfLines)}%`,
        aspectRatio: 1.1,
        '@flavor flavor-name': {
            aspectRatio: 1
        }
    }

});

 */

const PREFIX = '@flavor';

function isFlavorQuery(string) {
    return typeof string === 'string' && string.startsWith(PREFIX);
}

function process(object) {

    const flavorQueryKeys = [];

    const result = Object.keys(object).reduce((map, key) => {

        if (!isFlavorQuery(key)) {
            map[key] = object[key];
        } else {
            flavorQueryKeys.push(key);
        }

        return map;

    }, {});

    if (flavorQueryKeys.length) {

        const flavor = StyleSheet.value('$flavor');

        flavorQueryKeys.forEach(key => {

            const requiredFlavors = key
                .replace(PREFIX, '')
                .split(',')
                .map(flavor => flavor.trim());

            if (requiredFlavors.indexOf(flavor) >= 0) {
                merge(result, object[key]);
            }

        });

    }

    return result;
}

function isObject(object) {
    return typeof object === 'object' && object !== null;
}

function merge(object, flavorQueryObject) {

    Object.keys(flavorQueryObject).forEach(key => {

        if (isObject(object[key]) && isObject(flavorQueryObject[key])) {
            Object.assign(object[key], flavorQueryObject[key]);
        } else {
            object[key] = flavorQueryObject[key];
        }

    });

}


(function (processSource) {

    Style.prototype.processSource = function (...args) {
        const initialSource = this.source;
        this.source = process(this.source);
        const result = processSource.apply(this, ...args);
        this.source = initialSource;
        return result;
    };

})(Style.prototype.processSource);

(function (processMediaQueries) {

    Sheet.prototype.processMediaQueries = function (...args) {
        const initialSource = this.source;
        this.source = process(this.source);
        const result = processMediaQueries.apply(this, ...args);
        this.source = initialSource;
        return result;
    };

})(Sheet.prototype.processMediaQueries);

(function (calc) {

    Value.prototype.calc = function (...args) {

        if (typeof this.value === 'function') {
            const calculate = this.value;
            const argument = this.varsArr.reverse().reduce((allVars, vars) => Object.assign(allVars, vars), {});
            this.value = () => calculate(argument);
        }

        return calc.apply(this, {}, ...args);
    };

})(Value.prototype.calc);
