import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    wrapper: {
        flex: 1,
    },
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    text: {
        fontSize: 18,
        paddingVertical: 5,
        color: '$brandTextColor'
    },
    image: {
        width: 10,
        height: 10
    },
    input: {
        fontSize: 17,
        borderBottomColor: '$brandBorderColor',
        borderBottomWidth: 1,
        fontFamily: '$brandFontFamily',
        fontWeight: '600',
        color: '$brandTextColor',
    },
    interests: {
        flex: 1,
        width: '100%',
        alignItems: 'stretch',
        justifyContent: 'center',
        backgroundColor: 'red'
    },
    interestSelected: {
        flex: 0.3,
        height: 120,
        margin: 3,
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    interest: {
        flex: 0.3,
        height: 120,
        margin: 3,
        alignItems: 'center',
        justifyContent: 'flex-start',
        opacity: 0.2
    },
    interestImage: {
        width: 80,
        height: 80,
        marginBottom: 8
    },
    interestText: {
        maxWidth: 100,
        textAlign: 'center',
        fontSize: 14,
        color: '$brandTextColor',
    },
    heightPicker: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    heightSeparator: {
        fontSize: 18,
        color: '$brandTextColor',
        marginHorizontal: 10
    }
});

export default styles;
