import EStyleSheet from 'react-native-extended-stylesheet';
import { Platform } from 'react-native';

const styles = EStyleSheet.create({
    header: {
        backgroundColor: 'transparent',
        borderBottomColor: 'transparent',
        shadowColor: 'transparent',
        shadowRadius: 0,
        shadowOpacity: 0,
        elevation: 0,
        shadowOffset: {
            height: 0,
        },
        ...Platform.select({
            android: {
                marginTop: 20,
            },
        }),
    },
    content: {
        flex: 1,
    },
    headerTitle: {
        fontFamily: '$brandFontFamily',
        color: '$primaryTextColor',
        width: 250,
        fontWeight: 'bold',
        fontSize: 18,
    },
    container: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        flex: 1,
        paddingBottom: 30,
        ...Platform.select({
            android: {
                paddingTop: 40,
            },
            ios: {
                paddingTop: 60,
            },
        }),
        paddingHorizontal: 20,
    },
    expander: {
        flexGrow: 1,
    },
    description: {
        marginTop: 30,
        fontFamily: '$brandFontFamily',
        fontSize: 13,
        color: '$primaryTextColor',
        paddingBottom: 10,
        textAlign: 'justify',
    },
    topicsList: {
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    buttonContainer: {
        alignItems: 'center',
    },
    button: {
        width: 256,
    },
});

export default styles;
