import React, { PureComponent } from 'react';
import { Platform, StatusBar, Text, View } from 'react-native';
import Button from 'dating-mobile/src/components/buttons/base';
import styles from './styles';
import Topic from 'dating-mobile/src/streaming/views/topics-picking/item';
import Resources from 'dating-mobile/src/resources';

import StreamController from 'dating-mobile/src/models/dialogs.stream/controllers/controller';
import VideoView from 'dating-mobile/src/dialogs/stream/components/video';

const StreamComponent = StreamController(VideoView);

export default class StreamTopicsSelectionView extends PureComponent {
    renderTopics = () => {
        const { topics, selectedTopics, onChangeTopic, canChoose } = this.props;

        return topics.map(topic => {
            const selected = ~selectedTopics.indexOf(topic);
            return (
                <Topic
                    selected={selected}
                    displayText={Resources.strings['streams-topics-list'][topic]}
                    onPress={onChangeTopic(topic)}
                    key={topic}
                    disabled={!canChoose && !selected}
                />
            );
        });
    };

    render() {
        return (
            <View style={styles.content}>
                {Platform.OS === 'ios' && <StatusBar hidden={true} />}
                {Platform.OS === 'android' && (
                    <StatusBar
                        backgroundColor={'transparent'}
                        barStyle={'light-content'}
                        translucent
                    />
                )}
                <StreamComponent key={`stream-${this.props.id}`} id={this.props.id} />
                <View style={styles.container}>
                    <Text style={styles.description}>
                        {Resources.strings['stream-topics-selection-description']}
                    </Text>

                    <View style={styles.topicsList}>{this.renderTopics()}</View>

                    <View style={styles.expander} />

                    <View style={styles.buttonContainer}>
                        <Button
                            style={styles.button}
                            title={Resources.strings['ok']}
                            onPress={this.props.onSaveChanges}
                        />
                    </View>
                </View>
            </View>
        );
    }
}
