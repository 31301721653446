import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, Image } from 'react-native';
import styles from './styles';
import Resources from 'dating-mobile/src/resources';
import TextWithSmiles from '../text-with-smiles';

class ChatText extends React.PureComponent {
    static displayName = 'components.message-text';
    static propTypes = {
        numberOfLines: PropTypes.number.isRequired,
        hasUnreadMessages: PropTypes.bool,
        textStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
            PropTypes.number,
        ]).isRequired,
        data: PropTypes.shape({
            payload: PropTypes.object.isRequired,
        }).isRequired
    };

    renderText = (text, textStyle) => {
        const { numberOfLines, accessibilityLabel, testID } = this.props;

        if (typeof text === 'string') {
            return (
                <Text
                    numberOfLines={numberOfLines}
                    style={textStyle}
                    accessibilityLabel={accessibilityLabel}
                    testID={testID}
                >
                    <TextWithSmiles text={text} />
                </Text>
            );
        }

        return text;
    };


    render() {
        const payload = this.props.data && this.props.data.payload;
        const meta = payload && payload.meta;

        let imageSource = null;
        let cheerSource = null;
        let text = payload && payload.text;

        if (meta) {
            if (meta.letter) {
                imageSource = Resources.images.letterIcon();
                text = Resources.strings['chat-message-letter-content-placeholder'];
            } else {
                if (meta.reference) {
                    if (meta.reference.startsWith('cheer')) {
                        cheerSource = Resources.images.sendGiftIcon();
                        text = Resources.strings['chat-message-cheer-content-placeholder'];
                    } else if (meta.reference.startsWith('photo')) {
                        imageSource = Resources.images.photoContentIcon();
                        text = Resources.strings['chat-message-photo-content-placeholder'];
                    } else if (meta.reference.startsWith('video')) {
                        imageSource = Resources.images.videoContentIcon();
                        text = Resources.strings['chat-message-video-content-placeholder'];
                    } else if (meta.reference.startsWith('sticker')) {
                        text = Resources.strings['chat-message-sticker-content-placeholder'];
                    }
                } else {
                    const attachment = meta.attachments && meta.attachments[0] && meta.attachments[0]['media-type'];
                    if (attachment) {
                        if (attachment.startsWith('image')) {
                            imageSource = Resources.images.photoContentIcon();
                            text = Resources.strings['chat-message-photo-content-placeholder'];
                        } else if (attachment.startsWith('video')) {
                            imageSource = Resources.images.videoContentIcon();
                            text = Resources.strings['chat-message-video-content-placeholder'];
                        }
                    }
                }
            }
        }

        if (payload['text-id'] === 'match-added') {
            text = (
                <View style={styles.matchTextContainer}>
                    <Text style={styles.matchText}>
                        {Resources.strings['match-notification-first-part']}
                    </Text>
                    <Text style={[styles.matchText, styles.accentedText]}>
                        {Resources.strings['match-notification-second-part-trimmed']}
                    </Text>
                    <Text style={styles.matchText}>!</Text>
                </View>
            );
        }

        const textWithAttachmentStyle = this.props.hasUnreadMessages ? styles.unreadTextWithAttachment : styles.textWithAttachment;

        const textStyle = [this.props.textStyle || {}, imageSource ? textWithAttachmentStyle : {}];
        const attachmentImageStyle = this.props.hasUnreadMessages ? styles.unreadAttachmentImage : styles.attachmentImage;

        return (
            <View style={styles.container}>
                {
                    imageSource && (
                        <Image style={attachmentImageStyle} source={imageSource}/>
                    )
                }
                {
                    cheerSource && (
                        <Image style={styles.cheerImage} source={cheerSource}/>
                    )
                }
                {this.renderText(text, textStyle)}
            </View>
        );
    };
}

export default ChatText;
