import { BaseStore } from '@sdv/model';
import callsApi from '../call.api/api';

function createStore(id) {
  class Store extends BaseStore {
    constructor() {
      super();

      this.state = {
        data: { status: 'idle' },
      };

      callsApi.addListener('command.chat.media.answer', this.onAnswer);
      callsApi.addListener('command.invite.add', this.onIncoming);
      callsApi.addListener('event.chat.media.destroyed', this.onCancel);
      callsApi.addListener('command.chat.media.stop', this.onCancel);
      callsApi.addListener(
        'event.chat.media.state.changed',
        this.mediaChatStateChanged,
      );
      callsApi.addListener('command.invite.remove', this.onCancel);
    }

    output(state) {
      return { ...state.data };
    }

    onAnswer = invite => {
      this.state.data = { status: 'speaking', attendee: invite.sender };
      this.emitChange();
    };

    onIncoming = invite => {
      this.state.data = { status: 'ringing', invite };
      this.emitChange();
    };

    onCancel = () => {
      this.state.data = { status: 'idle' };
      this.emitChange();
    };

    mediaChatStateChanged = state => {
      if (
        this.state.data.status !== 'connecting' &&
        (state.pending.in || state.pending.out) &&
        !state.state.in &&
        !state.state.out
      ) {
        this.state.data = { status: 'connecting', attendee: state.attendee };
        this.emitChange();

        return;
      }
      if (
        this.state.data.status !== 'speaking' &&
        (state.state.in || state.state.out)
      ) {
        this.state.data = { status: 'speaking', attendee: state.attendee };
        this.emitChange();
      }
    };
  }

  Store.displayName = createStore.getDisplayName(id);
  Store.config = {
    getState(state) {
      return { ...state.data };
    },
  };

  return Store;
}

createStore.getDisplayName = function(id) {
  return `call-operator.${id}`;
};

export default createStore;
