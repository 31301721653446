import React, { Component } from 'react';
import PropTypes from 'prop-types';

import withStream from 'dating-mobile/src/models/dialogs.stream/controllers/controller';

export default function createStreamRerunBannerController(WrappedComponent) {
    class StreamRerunBannerController extends Component {
        static displayName = 'dialogs.stream.views.stream-rerun-banner.controller';

        static propTypes = {
            restream: PropTypes.number,
            id: PropTypes.string,
        };

        state = {
            streamChanged: false
        };

        static getDerivedStateFromProps(props, state) {

            return {
                id: props.id,
                streamChanged: props.id !== state.id
            };
        }

        shouldComponentUpdate(nextProps, nextState, nextContext) {
            return nextState.streamChanged !== this.state.streamChanged
        }

        render() {
            const { restream } = this.props;

            return (
                <WrappedComponent
                    visible={restream === 1}
                    needAnimationStart={this.state.streamChanged}
                />
            );

        }

    }

    return withStream(StreamRerunBannerController);
}
