import React from 'react';
import PropTypes from 'prop-types';
import { ActivityIndicator, StatusBar, StyleSheet, View } from 'react-native';
import { WebView } from 'react-native-webview';
import { createCardInterpolatedStyles } from 'dating-mobile/src/routing/router/stack';
import ImageButton from '../../components/navigation/views/image-button';
import Resources from '../../resources';
import styles from './styles';

class TermsScreen extends React.PureComponent {
  static contextTypes = {
    flux: PropTypes.object,
  };

  static propTypes = {
    navigation: PropTypes.object,
  };

  static navigationOptions = ({ navigation }) => ({
    title: navigation.getParam('title'),
    headerStyle: styles.header,
    headerTitleStyle: styles.headerTitle,
    headerLeft: () => null,
    headerRight: () => (
      <ImageButton
        image={Resources.images.closeIcon()}
        onPress={() => navigation.pop()}
        tint={StyleSheet.flatten(styles.headerTitle).color}
      />
    ),
    cardStyleInterpolator: (...args) =>
      createCardInterpolatedStyles(
        args,
        navigation.getParam('halfWidth')
          ? {
              cardStyle: { width: '50%', alignSelf: 'flex-end' },
            }
          : {},
      ),
  });

  resetStyle = () => {
    return styles.$forcePaddingFix
      ? 'document.body.style.padding = "5px"'
      : "document.querySelector('font') && document.querySelector('font').style.setProperty('line-height', 'normal', 'important');";
  };

  onLoaded = () => {
    if (this.isLoaded) {
      return;
    }

    this.isLoaded = true;

    const { navigation } = this.props;
    const uri = navigation.getParam('uri');

    if (!uri || uri.split('#').length !== 2) {
      return;
    }

    this.webView.reload();
  };

  render() {
    const { navigation } = this.props;

    return (
      <View style={styles.container}>
        <StatusBar
          barStyle={styles.$statusBarStyle}
          backgroundColor={styles.$statusBarBackgroundColor}
        />
        <WebView
          ref={ref => {
            this.webView = ref;
          }}
          style={styles.webView}
          startInLoadingState
          renderLoading={() => (
            <View style={styles.loading}>
              <ActivityIndicator />
            </View>
          )}
          source={{ uri: navigation.getParam('uri') }}
          javaScriptEnabled
          injectedJavaScript={this.resetStyle()}
          onLoadEnd={this.onLoaded}
        />
      </View>
    );
  }
}

export default TermsScreen;
