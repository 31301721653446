import EStyleSheet from 'react-native-extended-stylesheet';
import shouldUseWebLayout from 'dating-mobile/src/utils/web-layout';

const isDesktopLayout = shouldUseWebLayout();

const styles = EStyleSheet.create({
  container: {
    width: '100%',
    flex: 1,
    backgroundColor: '$primaryBackgroundColor',
    alignItems: 'center',
  },
  contentWrapper: {
    alignItems: 'center',
  },
  logo: {
    width: 120,
    height: 120,
    marginTop: 100,
    marginBottom: 50,
  },
  loaderWrapper: {
    width: isDesktopLayout ? 380 : '95%',
    height: isDesktopLayout ? 125 : 180,
    borderRadius: 15,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    paddingHorizontal: 10,
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  loaderText: {
    color: '$brandTextColor',
    fontSize: 16,
    fontWeight: 400,
    textAlign: 'center',
  },
});

export default styles;
