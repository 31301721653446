import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    connectingScreenContainer: {
        alignItems: 'center',
        justifyContent: 'center'
    },
    connectingBackground: {
        width: '100%',
        height: '100%',
        backgroundColor: 'black'
    },
    connectingText: {
        position: 'absolute',
        top: 170,
        fontFamily: '$brandFontFamily',
        fontWeight: 'bold',
        fontSize: 24,
        color: 'white',
        textAlign: 'center',
        '@flavor tubit': {
            color: '#ffffff4c',
        }
    },
    connectingOverlay: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        backgroundColor: '$shadowColor'
    },
});

export default styles;
