function createActions(id) {
  class Actions {
    dial = (options = {}) => dispatch => {
      dispatch(options);
    };
  }

  Actions.displayName = createActions.getDisplayName(id);

  return Actions;
}

createActions.getDisplayName = function(id) {
  return `call-calling.${id}`;
};

export default createActions;
