import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        width: 32,
        height: 32
    },
    text: {
        fontSize: 16,
        color: '#fff',
    }
});

export default styles;
