import EStyleSheet from 'react-native-extended-stylesheet';
import { I18nManager } from 'react-native';

const styles = EStyleSheet.create({
    container: {
        flex: 1,
        borderColor: '$primaryBorderColor',
        borderRadius: 18,
        paddingLeft: 45,
        paddingVertical: 10,
        borderWidth: 1,
        marginTop: 10,
    },

    imageContainer: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: 50,
        height: 50,
        overflow: 'hidden',
        borderBottomLeftRadius: 20,
    },

    image: {
        position: 'absolute',
        flex: 1,
        width: 60,
        left: -16,
        bottom: -75,
        transform: [
            {
                scaleX: I18nManager.isRTL ? -1 : 1,
            },
        ],
    },

    text: {
        fontFamily: '$brandFontFamily',
        color: '$primaryTextColor',
        fontSize: 13,
    },
});

export default styles;
