import { FlatList, View } from 'react-native';
import styles from './styles';
import React from 'react';
import PropTypes from 'prop-types';
import MediaAttachmentView from '../media-attachment';

export default class LetterAttachmentList extends React.PureComponent {
    static displayName = 'inbox.views.letter-attachment-list.view';
    static propTypes = {
        senderId: PropTypes.string,
        recipientId: PropTypes.string,
        letterTag: PropTypes.string,
        attachments: PropTypes.array,
        onRemoveAttachmentButtonPress: PropTypes.func
    };

    componentDidUpdate(prevProps) {
        const previousAttachmentsCount = prevProps.attachments && prevProps.attachments.length;
        const currentAttachmentsCount = this.props.attachments && this.props.attachments.length;
        if (previousAttachmentsCount < currentAttachmentsCount) {
            this.attachmentsListRef && this.attachmentsListRef.scrollToEnd();
        }
    }

    removeItem = item => {
        this.props.onRemoveAttachmentButtonPress && this.props.onRemoveAttachmentButtonPress(item);
    };

    renderItem = ({ item }) => {
        return (
            <MediaAttachmentView
                senderId={this.props.senderId}
                recipientId={this.props.recipientId}
                resource={item}
                onRemovePress={this.removeItem}
            />
        )
    };

    render() {
        const hasAttachments = this.props.attachments && this.props.attachments.length > 0;
        if (!hasAttachments) { return null }

        return (
            <View style={styles.container}>
                <FlatList
                    ref={ref => this.attachmentsListRef = ref}
                    data={this.props.attachments}
                    renderItem={this.renderItem}
                    keyExtractor={item => item.uri}
                    horizontal={true}
                    numColumns={1}
                    alwaysBounceHorizontal={false}
                    showsHorizontalScrollIndicator={false}
                />
            </View>
        );
    }
}
