import Service from '../../service';
import PropTypes from "prop-types";
import TypingModel, { getId } from '../../../models/user.events.typing/model';
import withIdentityId from '../../../models/identity/controller/id';

class IncomingTypingIndication extends Service {
    static displayName = 'services-dialogs-messages-incoming-typing';
    static contextTypes = {
        flux: PropTypes.object
    };
    static propTypes = {
        userId: PropTypes.string
    };

    componentDidMount() {
        this.context.flux.events.addListener('ci:event.user.typing.started', this.typingStarted);
        this.context.flux.events.addListener('ci:event.user.typing.ended', this.typingEnded);
        this.context.flux.events.addListener('message:message:v2', this.onNewMessage);
    }

    componentWillUnmount() {
        this.context.flux.events.removeListener('ci:event.user.typing.started', this.typingStarted);
        this.context.flux.events.removeListener('ci:event.user.typing.ended', this.typingEnded);
        this.context.flux.events.removeListener('message:message:v2', this.onNewMessage);
    }

    typingStarted = (data) => {
        if (!data) {
            return;
        }
        const sender = data.sender;
        this.context.flux.get(TypingModel, getId(this.props.userId, sender)).actions.actualizeIncomingTyping(true)
    };

    typingEnded = (data) => {
        if (!data) {
            return;
        }
        const sender = data.sender;
        this.context.flux.get(TypingModel, getId(this.props.userId, sender)).actions.actualizeIncomingTyping(false)
    };

    onNewMessage = (data) => {
        if (!data) {
            return;
        }
        const sender = data.sender;
        if (this.props.userId !== sender) {
            this.context.flux.get(TypingModel, getId(this.props.userId, sender)).actions.actualizeIncomingTyping(false)
        }
    }
}

export default withIdentityId(IncomingTypingIndication)
