import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Api } from '@sdv/network/api';
import { NewsletterSendingNotifier } from './newsletter-sending-notifier';

export class Newsletter {
    userId: string;

    notifier: NewsletterSendingNotifier = new NewsletterSendingNotifier();

    constructor(userId: string) {
        this.userId = userId;
    }

    send(text: string): Observable<void> {
        // @ts-ignore
        return Api.shared()
            .sendMessageToFavorites(text, this.userId)
            .pipe(tap(() => this.notifier.notifyUser(this.userId)));
    }
}
