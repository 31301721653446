import React from 'react';
import PropTypes from 'prop-types';
import { Image, Text, TouchableHighlight, View } from 'react-native';
import Resources from 'dating-mobile/src/resources';
import UserThumbnail from 'dating-mobile/src/user/common/views/thumbnail';
import OnlineIndicator from 'dating-mobile/src/user/common/views/online-indicator';
import getDisplayDate from 'dating-mobile/src/utils/date-time-formatting';
import StreamingIndicator from 'dating-mobile/src/dialogs/chats/views/streaming-indicator';
import IfConfigValue from 'dating-mobile/src/components/config-value/if';
import UserName from 'dating-mobile/src/user/common/views/user-name';
import testId from 'dating-mobile/src/utils/test-id';
import LetterText from '../../text';
import styles from './styles';

export default class LetterItemView extends React.Component {
  static displayName = 'inbox.views.flat-list.item.view';

  static propTypes = {
    userId: PropTypes.string,
    senderId: PropTypes.string,
    data: PropTypes.object,
    onSelect: PropTypes.func,
    isContact: PropTypes.bool,
    unreadCount: PropTypes.number,
  };

  shouldComponentUpdate(nextProps) {
    return (
      this.props.userId !== nextProps.userId ||
      this.props.data !== nextProps.data ||
      this.props.senderId !== nextProps.senderId ||
      this.props.unreadCount !== nextProps.unreadCount ||
      this.props.isContact !== nextProps.isContact ||
      this.props.data.read !== nextProps.data.read
    );
  }

  onPressed = () => {
    this.props.onSelect && this.props.onSelect(this.props.data);
  };

  render() {
    const data = this.props.data || {};
    const read = this.props.userId !== this.props.senderId ? data.read : true;
    const containerStyle = read ? styles.readContainer : styles.unreadContainer;
    const userNameStyle = read ? styles.username : styles.unreadUsername;
    const letterSubjectStyle = read
      ? styles.letterSubjectStyle
      : styles.unreadLetterSubjectStyle;

    return (
      <TouchableHighlight style={styles.touchable} onPress={this.onPressed}>
        <View style={containerStyle}>
          <View style={styles.avatarContainer}>
            <UserThumbnail
              style={styles.avatar}
              userId={this.props.senderId}
              round
            />
            <View style={styles.online}>
              <OnlineIndicator userId={this.props.senderId} />
            </View>
            {!!this.props.isContact && (
              <Image
                style={styles.isContact}
                source={Resources.images.contactIcon()}
              />
            )}
          </View>

          <View style={styles.contentContainer}>
            <View style={styles.content}>
              <View style={styles.usernameContainer}>
                <View style={styles.userInfoContainer}>
                  <UserName
                    userId={this.props.senderId}
                    style={userNameStyle}
                  />
                  <IfConfigValue
                    path="features.streams-enabled"
                    equalsTo={true}
                  >
                    <StreamingIndicator userId={this.props.senderId} />
                  </IfConfigValue>
                </View>
                <Text style={styles.unreadTimestamp}>
                  {getDisplayDate(data.timestamp)}
                </Text>
              </View>
              <View style={styles.letterInfoContainer}>
                <View style={styles.letterSubjectContainer}>
                  <LetterText
                    textStyle={letterSubjectStyle}
                    numberOfLines={1}
                    text={data.subject}
                    meta={data.meta}
                    {...testId(
                      Resources.strings['letter-subject-accessibility-label'],
                    )}
                  />
                </View>
                {this.props.unreadCount > 0 && (
                  <View style={styles.unreadMessageCount}>
                    <Text
                      style={styles.unreadMessageCountText}
                      {...testId(
                        Resources.strings[
                          'unread-messages-indicator-accessibility-label'
                        ],
                      )}
                      numberOfLines={1}
                    >
                      {this.props.unreadCount}
                    </Text>
                  </View>
                )}
                {data.introductory && (
                  <View style={styles.introTextContainer}>
                    <Text style={styles.introText} numberOfLines={1}>
                      {Resources.strings[
                        'inbox-free-letter-indicator-title'
                      ].toUpperCase()}
                    </Text>
                  </View>
                )}
              </View>

              <Text
                style={styles.letterText}
                numberOfLines={1}
                {...testId(
                  Resources.strings['letter-text-accessibility-label'],
                )}
              >
                {data.text}
              </Text>
            </View>
          </View>
        </View>
      </TouchableHighlight>
    );
  }
}
