import React from 'react';
import { Image, Text, TouchableOpacity, View, ScrollView } from 'react-native';
// @ts-ignore
import { SafeAreaView } from 'react-navigation';
// @ts-ignore
import Form from 'dating-mobile/src/dialogs/common/views/form';
import styles from './styles';
import Resources from '../resources';

export interface Props {
    sendText(text: string): void;
    close(): void;
    userId: string;
}

interface State {}

export default class NewsletterScreen extends React.Component<Props, State> {
    static displayName = 'newsletter.view';

    submit = (text: string) => {
        const { sendText } = this.props;

        sendText(text);
    };

    onClosePressed = () => {
        const { close } = this.props;

        close();
    };

    render() {
        const { userId } = this.props;

        return (
            <SafeAreaView forceInset={{ top: 'always', bottom: 'never' }} style={styles.container}>
                <TouchableOpacity style={styles.closeButton} onPress={this.onClosePressed}>
                    <Image source={Resources.images.closeIcon()} />
                </TouchableOpacity>
                <ScrollView
                    style={styles.scrollView}
                    contentContainerStyle={styles.contentContainer}
                >
                    <View style={styles.topView} />
                    <View style={styles.groupStreamMessageIconContainer}>
                        <Image
                            source={Resources.images.groupStreamMessageIcon()}
                            style={styles.groupStreamMessageIcon}
                        />
                    </View>
                    <View style={styles.separatorView} />
                    <View style={styles.titleDescriptionContainer}>
                        <Text style={styles.titleDescription}>
                            Let your Fans know when you’ll Go Live!
                        </Text>
                    </View>
                    <View style={styles.separatorView} />
                    <View style={styles.subtitleDescriptionContainer}>
                        <Text style={styles.subtitleDescription}>
                            Write when you’ll go Live and get more viewers and more gifts!
                        </Text>
                    </View>
                    <View style={styles.bottomView} />
                </ScrollView>
                <Form
                    style={styles.form}
                    user={userId}
                    autoFocus
                    controlSafeArea
                    onSend={(text: string) => this.submit(text)}
                    inputStylingMode="dialog"
                />
            </SafeAreaView>
        );
    }
}
