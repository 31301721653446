import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    list: {
        width: '100%',
    },

    introductoryLettersSection: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },

    separatorBetweenLetterSections: {
        height: 8,
        width: '100%',
        backgroundColor: '$primaryBorderColor'
    }
});

export default styles;
