import React from 'react';
import PropTypes from 'prop-types';
import View from './view';
import withController from './controller';
import withUser from '../../../models/user/controllers';
import withConfigValue from '../../../components/config-value';
import withIdentityId from '../../../models/identity/controller/id';

const IncomingCallComponent = withUser(
  withConfigValue(withIdentityId(withController(View)), {
    defaultDisplayTime: 'notifications.display-time.default',
    videoChatEnabled: 'features.video-chat-enabled',
  }),
  {
    userIdPropName: 'attendeeId',
    resultPropName: 'attendee',
  },
);

export default class IncomingCall extends React.Component {
  static propTypes = {
    notification: PropTypes.object,
  };

  render() {
    const { notification } = this.props;
    const attendeeId =
      (notification && notification['attendee-id']) || undefined;

    return <IncomingCallComponent attendeeId={attendeeId} {...this.props} />;
  }
}
