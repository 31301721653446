import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, TouchableWithoutFeedback } from 'react-native';
import Photo, { PHOTO_TRANSFORMATION_TYPE } from '../../../../components/photo';
import OnlineIndicator from 'dating-mobile/src/user/common/views/online-indicator';
import styles from './styles';
import testId from 'dating-mobile/src/utils/test-id';
import Resources from 'dating-mobile/src/resources';

class ProfileHeader extends React.Component {
    static propTypes = {
        user: PropTypes.object,
        presence: PropTypes.object,
        onThumbnailPress: PropTypes.func
    };

    onThumbnailPressed = () => {
        const basename = this.props.user && (this.props.user['thumbnail-pending'] || this.props.user.thumbnail);
        this.props.onThumbnailPress && this.props.onThumbnailPress(basename);
    };

    render() {
        const user = this.props.user || {};

        const basename = user['thumbnail-pending'] || user.thumbnail;

        let cover = user.cover;
        if (cover) {
            cover = cover.substring(0, cover.indexOf('.'));
            if (!cover.startsWith('/')) {
                cover = `/users/${user.id}/${cover}`;
            }
        }

        let userName = user.name;
        if (userName && user.birthday && user.birthday.age) {
            userName = `${userName}, ${user.birthday.age}`;
        }

        return (
            <View style={styles.container}>
                <Photo
                    path={cover}
                    style={styles.cover}
                />
                <View style={styles.photoContainer}>
                    <TouchableWithoutFeedback onPress={this.onThumbnailPressed}>
                        <Photo
                            key={basename}
                            userId={this.props.id}
                            basename={basename}
                            round={false}
                            style={{ width: 112, height: 95, borderRadius: 10 }}
                            transformation={PHOTO_TRANSFORMATION_TYPE.DETECT_FACE}
                            placeholder={'avatar'}
                        />
                    </TouchableWithoutFeedback>
                    <Text
                        style={styles.name}
                        {...testId(Resources.strings['user-name-accessibility-label'])}
                    >
                        {userName || ''}
                    </Text>
                    <View style={styles.onlineIndicatorContainer}>
                        <OnlineIndicator userId={user.id}/>
                    </View>
                </View>
            </View>
        );
    }

}

export default ProfileHeader;
