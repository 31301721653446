import React from 'react';
import PropTypes from 'prop-types';
import { Alert, StyleSheet } from 'react-native';

import createScreen from './hoc';
import identityController from '../../../models/identity/controller';
import authController from '../../../models/identity/controller/auth';
import PasswordRecover from '../../views/password-recover';
import ImageButton from '../../../components/navigation/views/image-button';
import Resources from '../../../resources';

import styles from './styles';

const PasswordRecoverComponent = authController(PasswordRecover);

class PasswordScreenComponent extends React.PureComponent {
  static displayName = 'authentication.password.screen';

  static contextTypes = {
    flux: PropTypes.object,
  };

  static propTypes = {
    navigation: PropTypes.object.isRequired,
    recoverPassword: PropTypes.func.isRequired,
    error: PropTypes.object,
  };

  constructor(props, context) {
    super(props);
    this.flux = context.flux;
  }

  recover = async (label, payload) => {
    const { recoverPassword, navigation } = this.props;
    const { email } = payload;

    try {
      await recoverPassword(email);
      Alert.alert(
        Resources.strings['password-recover-notification-sent'],
        null,
        [
          {
            text: Resources.strings.ok,
            onPress: () => {
              navigation.pop();
            },
          },
        ],
      );
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { error } = this.props;

    return <PasswordRecoverComponent error={error} onSubmit={this.recover} />;
  }
}

const PasswordScreen = createScreen(
  identityController(PasswordScreenComponent),
);

PasswordScreen.navigationOptions = () => ({
  headerStyle: styles.header,
  headerBackImage: () => (
    <ImageButton
      image={Resources.images.backIcon()}
      tint={StyleSheet.flatten(styles.tint).color}
    />
  ),
});

export default PasswordScreen;
