import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    header: {
        backgroundColor: '$primaryBackgroundColor',
        borderBottomColor: '$primaryBackgroundColor',
        shadowColor: '$primaryBackgroundColor',
        shadowRadius: 0,
        shadowOpacity: 0,
        elevation: 0,
        shadowOffset: {
            height: 0,
        }
    },
    headerTitle: {
        color: '$primaryTextColor',
        fontFamily: '$brandFontFamily'
    },
    container: {
        backgroundColor: '$primaryBackgroundColor',
        flex: 1
    },
    inboxSelector: {
        flex: 1
    },
    inboxSelectorContainer: {
        position: 'absolute',
        bottom: 0,
        height: 180,
        width: '100%'
    },
    introductoryListContainer: {
        width: '100%',
        marginBottom: 4
    },
    introductoryList: {
        width: '100%',
        marginBottom: 4
    },
    list: {
        flex: 1
    },
    separator: {
        width: '100%',
        height: 4
    },
    overlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: '$shadowColor'
    }
});

export default styles;
