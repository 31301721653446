import React from 'react';
import { Animated, Image, LayoutAnimation, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import styles from './styles';
import Resources from 'dating-mobile/src/resources';
import Button from 'dating-mobile/src/components/buttons/base';
import LinearGradient from 'react-native-linear-gradient';

export default class FollowButton extends React.PureComponent {
    static displayName = 'dialogs.chat.button';
    static propTypes = {
        isFavorite: PropTypes.bool.isRequired,
        onPress: PropTypes.func,
        followingStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array]),
        notFollowingStyle: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.object,
            PropTypes.array,
        ]),
        titleStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array]),
    };

    constructor(props) {
        super(props);
        this.state = { width: new Animated.Value(100) };
        this.updateWidth(props.isFavorite);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.isFavorite !== prevProps.isFavorite) {
            this.toggleWidth();
        }
    }

    toggleWidth() {
        const animation = LayoutAnimation.create(
            100,
            LayoutAnimation.Types.linear,
            LayoutAnimation.Properties.opacity,
        );
        LayoutAnimation.configureNext(animation);

        this.updateWidth(this.props.isFavorite);
        this.forceUpdate();
    }

    updateWidth(isFavorite) {
        this.style = isFavorite ? this.props.followingStyle : this.props.notFollowingStyle;
    }

    textProps = {
        numberOfLines: 1,
        ellipsizeMode: 'clip',
    };

    render() {
        if (this.props.isFavorite) {
            const style = this.style
                ? [styles.followStarContainer, this.style]
                : styles.followStarContainer;
            return (
                <TouchableOpacity onPress={this.props.onPress}>
                    <LinearGradient colors={styles._disabledGradient.gradientColor} style={style}>
                        <Image source={Resources.images.starFilledIcon()} />
                    </LinearGradient>
                </TouchableOpacity>
            );
        }

        const style = this.style ? [styles.followButton, this.style] : styles.followButton;
        const titleStyle = this.props.titleStyle
            ? [styles.followButtonTitle, this.props.titleStyle]
            : styles.followButtonTitle;

        const content = (
            <Button
                title={Resources.strings['streamStatusFollowButtonTitle']}
                onPress={this.props.onPress}
                icon={Resources.images.starEmptyIcon()}
                style={style}
                iconStyle={styles.followButtonIcon}
                titleStyle={titleStyle}
                textProps={this.textProps}
            />
        );

        if (styles.$buttonHasGradient) {
            return (
                <LinearGradient
                    colors={styles._activeGradient.gradientColor}
                    start={{ x: 0, y: 0 }}
                    end={{ x: 1, y: 0 }}
                    style={style}
                >
                    {content}
                </LinearGradient>
            );
        } else {
            return content;
        }
    }
}
