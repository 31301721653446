import React from 'react';
import PropTypes from 'prop-types';
import { Picker, StyleSheet, View } from 'react-native';
import testId from 'dating-mobile/src/utils/test-id';
import ImageButton from '../../../../components/navigation/views/image-button';
import Resources from '../../../../resources';
import styles from './styles';

class PickerEditing extends React.PureComponent {
  static displayName = 'user.edit.picker-editing';

  static propTypes = {
    navigation: PropTypes.object.isRequired,
    onComplete: PropTypes.func,
    min: PropTypes.number,
    max: PropTypes.number,
    value: PropTypes.number,
    placeholder: PropTypes.string,
    measure: PropTypes.string,
  };

  static navigationOptions = ({ navigation, navigationOptions }) => ({
    title: navigation.getParam('title'),
    headerLeft: () => (
      <ImageButton
        image={Resources.images.backIcon()}
        tint={StyleSheet.flatten(navigationOptions.headerTitleStyle).color}
        onPress={() => {
          const goBack = navigation.getParam('goBack');

          if (goBack) {
            goBack();
          } else {
            navigation.pop();
          }
        }}
        {...testId('Back button')}
      />
    ),
  });

  static getItems(data) {
    return (
      <Picker.Item
        key={`${data.label}-${data.value}`}
        label={data.label}
        value={data.value}
      />
    );
  }

  constructor(props) {
    super(props);
    this.state = { selectedValue: this.getDefaultValue() };
  }

  componentDidMount() {
    const { navigation } = this.props;

    navigation.setParams({ goBack: this.goBack });
  }

  goBack = () => {
    const { navigation, onComplete } = this.props;
    const { selectedValue } = this.state;
    const completion = onComplete || navigation.getParam('onComplete');

    if (selectedValue !== this.getDefaultValue() && completion) {
      completion(selectedValue);
    }

    navigation.pop();
  };

  getDefaultValue = () => {
    const { navigation, value } = this.props;

    return value || navigation.getParam('value');
  };

  getMinValue = () => {
    const { navigation, min } = this.props;

    return min || navigation.getParam('min');
  };

  getMaxValue = () => {
    const { navigation, max } = this.props;

    return max || navigation.getParam('max');
  };

  getPlaceholder = () => {
    const { navigation, placeholder } = this.props;

    return placeholder || navigation.getParam('placeholder');
  };

  onValueChange = data => {
    this.setState({ selectedValue: data });
  };

  generateItems(min, max) {
    const { measure } = this.props;

    return Array.from({ length: max + 1 }, (v, k) => k)
      .splice(min, max)
      .map(value => ({
        label: `${value} ${measure || ''}`,
        value,
      }));
  }

  render() {
    const { selectedValue } = this.state;
    const items = this.generateItems(
      this.getMinValue(),
      this.getMaxValue(),
    ).map(PickerEditing.getItems);
    const placeholder = this.getPlaceholder();

    return (
      <View style={styles.container}>
        <Picker
          style={styles.picker}
          selectedValue={selectedValue}
          onValueChange={this.onValueChange}
        >
          {placeholder && (
            <Picker.Item label={placeholder} value="placeholder" />
          )}
          {items}
        </Picker>
      </View>
    );
  }
}

export default PickerEditing;
