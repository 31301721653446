import EStyleSheet from 'react-native-extended-stylesheet';
import { I18nManager } from 'react-native';

const styles = EStyleSheet.create({
    container: {
        width: 66,
        height: 66,
        borderRadius: 33,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '$accentColor',
        elevation: 8,
        shadowRadius: 8,
        shadowOpacity: 0.15,
        shadowOffset: { width: 0, height: 0 },
        '@flavor lovinga': {
            width: 72,
            height: 72,
            borderRadius: 36
        },
        '@flavor dma': {
            width: 84,
            height: 84,
            borderRadius: 42
        }
    },

    icon: {
        transform: [
            {
                scaleX: I18nManager.isRTL ? -1 : 1
            }
        ]
    }
});

export default styles;
