import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '$brandColor'
    },

    safeAreaContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    backgroundImage: {
        position: 'absolute',
        width: '100%',
        height: '100%'
    },

    starsImage: {
        width: 200,
        top: '25%'
    },

    title: {
        top: '30%',
        fontSize: 34,
        fontFamily: '$brandFontFamily',
        textAlign: 'center',
        color: '$brandTextColor',
        fontWeight: 'bold'
    },

    buttonsContainer: {
        paddingHorizontal: 40,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: '100%',
    },

    rateButton: {
        marginTop: 300,
        marginBottom: 15
    },

    laterButton: {
        backgroundColor: 'transparent',
        height: 50,
        marginBottom: 15,
    },

    laterButtonTitle: {
        fontSize: 16,
        fontFamily: '$brandFontFamily',
        color: '$brandTextColor',
        fontWeight: '500',
        '@flavor lovinga': {
            color: '$minorAccentColor',
            fontWeight: 'normal'
        }
    },
});

export default styles;
