import Service from '../../service';
import PropTypes from 'prop-types';
import IdentityModel from '@sdv/domain/identity/model';
import BalanceModel from '@sdv/domain/credits.account.balance/model';

export default class CreditsAccountsBalanceProvider extends Service {
    static displayName = 'service.credits-accounts-balance-provider';
    static contextTypes = {
        flux: PropTypes.object
    };

    componentDidMount() {
        this.context.flux.events.addListener('account:credits', this.onEventReceived);
    }

    componentWillUnmount() {
        this.context.flux.events.removeListener('account:credits', this.onEventReceived);
    }

    onEventReceived = (data) => {
        if (!data || typeof data.balance !== 'number') {
            return;
        }

        const identity = this.context.flux.get(IdentityModel).store.getState().id;
        if (!identity) {
            return
        }

        const contactsModel = this.context.flux.get(BalanceModel, identity);
        contactsModel.actions.update(data);
    };
}
