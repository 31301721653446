import qs from 'qs';
import { BaseStore } from '@sdv/model';
import Actions from './actions';
import callsApi from '../call.api/api';
import CallStatusModel from '../call.status';

function createStore(id) {
  const { user, attendee } = qs.parse(id);

  class Store extends BaseStore {
    constructor(flux) {
      super();

      this.state = {};

      const actions = flux.getActions(Actions, id);

      this.bindAction(actions.interrupt, this.interrupt);
      // TODO: Move from here
      callsApi.addListener('event.call.payment.required', this.cancelCall);
      this.callStatus = flux.get(CallStatusModel, user);
    }

    cancelCall = () => {
      const callState = this.callStatus.store.getState();

      if (
        callState.status === 'connecting' &&
        attendee === callState.attendee
      ) {
        this.interrupt();
      }
    };

    interrupt = () => {
      callsApi.emit('command.chat.media.stop', attendee);
    };
  }

  Store.displayName = createStore.getDisplayName(id);

  return Store;
}

createStore.getDisplayName = function(id) {
  return `call-interrupting.${id}`;
};

export default createStore;
