import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { merge, Subject } from 'rxjs';
import { singleton } from '@sdv/commons/utils/singleton';
import AppConfig from 'dating-mobile/src/app/config';
import { CurrentScreen } from 'dating-mobile/src/services/functional/current-screen';

import {
  COMPLETED_STEP,
  STEPPED_REGISTRATION_STEP,
  getOrderedSteps,
  INITIAL_STEP,
} from './registration-steps';

import { ROUTES_MAPPING } from './stepped-registration-route';

const allowedRoutes = Object.values(ROUTES_MAPPING);

export class OnboardingProgress {
  static shared = singleton(() => new OnboardingProgress());

  constructor() {
    this.currentStep = new Subject();

    const steppedRegistrationEnabled = AppConfig.shared()
      .paramWithPath('features.stepped-registration-enabled')
      .pipe(filter(Boolean));

    const currentProgress = this.currentStep.pipe(
      map(step => this.calcProgress(step)),
    );

    const resetProgress = CurrentScreen.shared().stack.pipe(
      filter(stack => allowedRoutes.every(route => !stack.includes(route))),
      map(() => null),
    );

    this.progress = steppedRegistrationEnabled.pipe(
      switchMap(() => merge(currentProgress, resetProgress)),
      distinctUntilChanged(),
    );
  }

  calcProgress(step) {
    if (
      step === COMPLETED_STEP ||
      step === STEPPED_REGISTRATION_STEP.SUBSCRIBE ||
      step === STEPPED_REGISTRATION_STEP.FIND_MATCHES
    ) {
      return null;
    }

    const steps = [
      INITIAL_STEP,
      ...getOrderedSteps().filter(
        registrationStep =>
          registrationStep !== STEPPED_REGISTRATION_STEP.SUBSCRIBE,
      ),
    ];

    const secondStepFirstItemIndex = steps.indexOf(
      STEPPED_REGISTRATION_STEP.START_SETTING_PREFERENCES,
    );
    const currentStepIndex = steps.indexOf(step);
    const isFirstStep = currentStepIndex < secondStepFirstItemIndex;

    return (
      (isFirstStep
        ? (currentStepIndex + 1) / (secondStepFirstItemIndex + 1)
        : (currentStepIndex - secondStepFirstItemIndex + 1) /
          (steps.length - secondStepFirstItemIndex + 1)) || 0
    );
  }

  goToStep(step) {
    this.currentStep.next(step);
  }

  goToFirstStep() {
    this.currentStep.next(INITIAL_STEP);
  }

  isOnboardingComplete = () => {
    return this.currentStep.pipe(map(step => step === COMPLETED_STEP));
  };
}
