import React from 'react';
import PropTypes from 'prop-types';
import { Image, View } from 'react-native';
import CurrencyBasedText from 'dating-mobile/src/components/currency/currency-based-text';
import withConfigValue from 'dating-mobile/src/components/config-value';
import styles from './styles';
import Resources from '../../../../../resources';

class BalanceComponent extends React.PureComponent {
  static propTypes = {
    balance: PropTypes.shape({
      amount: PropTypes.number,
    }),
    currencyType: PropTypes.string,
  };

  render() {
    const { balance, currencyType } = this.props;

    const amount = typeof balance?.amount === 'number' ? balance.amount : '';

    return (
      <View style={styles.container}>
        <CurrencyBasedText
          style={styles.balance}
          strings={{
            coins: Resources.strings.formatString(
              Resources.strings['cheer-keyboard-coins-balance-format'],
              amount,
            ),
            credits: Resources.strings.formatString(
              Resources.strings['cheer-keyboard-credits-balance-format'],
              amount,
            ),
          }}
        />
        {currencyType === 'credits' && (
          <Image style={styles.coin} source={Resources.images.coinIcon()} />
        )}
      </View>
    );
  }
}

export default withConfigValue(BalanceComponent, {
  currencyType: 'currency.type',
});
