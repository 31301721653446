import React from 'react';
import { Image, Text, View } from 'react-native';
import PropTypes from 'prop-types';

import styles from './styles';

export default class Badge extends React.PureComponent {
  static displayName = 'user.common.views.user-badges.badge-item.view';

  static propTypes = {
    icon: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.string,
    ]),
    text: PropTypes.string,
  };

  render() {
    const { icon, text } = this.props;

    return (
      <View style={styles.container}>
        <Image source={icon} style={styles.icon} resizeMode="contain" />
        <Text style={styles.text} numberOfLines={2}>
          {text}
        </Text>
      </View>
    );
  }
}
