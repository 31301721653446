import EStyleSheet from 'react-native-extended-stylesheet';

const defaultTextStyle = {
  fontSize: 13,
  color: '$brandTextColor',
  fontFamily: '$brandFontFamily',
};

const styles = EStyleSheet.create({
  container: {
    borderRadius: 15,
    flexDirection: 'row',
    backgroundColor: '$brandColor',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingVertical: 12,
  },

  textContainer: {
    flexShrink: 1,
  },

  extendTitle: {
    ...defaultTextStyle,
    marginBottom: 2,
  },

  extendSubtitle: {
    ...defaultTextStyle,
  },

  extendButton: {
    paddingVertical: 8,
    paddingHorizontal: 20,
    borderRadius: 17,
    borderWidth: 1,
    borderColor: '$brandTextColor',
  },

  extendButtonText: {
    ...defaultTextStyle,
  },
});

export default styles;
