import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { View, Text, TouchableOpacity, Platform } from 'react-native';
import Gradient from 'dating-mobile/src/components/with-gradient';
import Resources from 'dating-mobile/src/resources';
import { displaySubscriptionItemText } from 'dating-mobile/src/payment/utils/trial-pirce-formatting';
import { localizePrice } from '@sdv/commons/price-with-code-localization';

import styles from './styles';
import { SUBSCRIBE_MOTIVATION } from '../subscribe-plans/controller';

class SubscribePlanItem extends PureComponent {
    static propTypes = {
        isActive: PropTypes.bool,
        price: PropTypes.string,
        subscribeMotivation: PropTypes.object,
        trialPeriod: PropTypes.bool,
        onPress: PropTypes.func,
        id: PropTypes.number,
        subscriptionPeriod: PropTypes.string,
        subscriptionUnit: PropTypes.string,
        invoice: PropTypes.object,
        customStyles: PropTypes.shape({
            textNotActive: PropTypes.shape({
                color: PropTypes.string,
            }),
            subtitleTextNotActive: PropTypes.shape({
                color: PropTypes.string,
            }),
            container: PropTypes.shape({
                gradientColor: PropTypes.array,
            }),
            containerActive: PropTypes.shape({
                gradientColor: PropTypes.array,
            }),
            separator: PropTypes.shape({
                backgroundColor: PropTypes.string,
            }),
            separatorNotActive: PropTypes.shape({
                backgroundColor: PropTypes.string,
            }),
        }),
    };

    onPress = () => {
        const { id, onPress } = this.props;

        if (onPress) {
            onPress(id);
        }
    };

    getTextFromMotivation = motivation => {
        switch (motivation) {
            case SUBSCRIBE_MOTIVATION.none:
                return Resources.strings['subscription-plan-card-no-motivation'];
            case SUBSCRIBE_MOTIVATION.save:
                return Resources.strings['subscription-plan-card-save-motivation'];
            case SUBSCRIBE_MOTIVATION.most_popular:
                return Resources.strings['subscription-plan-card-most-popular-motivation'];
            case SUBSCRIBE_MOTIVATION.best_price:
                return Resources.strings['subscription-plan-card-best-price-motivation'];
            default:
                return '';
        }
    };

    getTrialsPeriodText = trial => {
        if (trial.days) {
            return trial.days === 1
                ? Resources.strings.formatString(
                      Resources.strings['subscription-plan-card-single-day-format'],
                      trial.days,
                  )
                : Resources.strings.formatString(
                      Resources.strings['subscription-plan-card-multiple-days-format'],
                      trial.days,
                  );
        }
        if (trial.weeks && trial.weeks === 1) {
            return Resources.strings.formatString(
                Resources.strings['subscription-plan-card-multiple-days-format'],
                7,
            );
        }

        return undefined;
    };

    getDisplayPeriod = (number, unit) => {
        if (unit === 'MONTH') {
            if (number && parseInt(number, 10) === 1) {
                return [number, Resources.strings.month];
            }

            return [number, Resources.strings.months];
        }
        if (unit === 'YEAR') {
            if (number && parseInt(number, 10) === 1) {
                return [12, Resources.strings.months];
            }

            return [number, Resources.strings.years];
        }

        if (unit === 'DAY') {
            if (number && parseInt(number, 10) === 1) {
                return [number, Resources.strings.day];
            }

            return [number, Resources.strings.days];
        }

        return [number, unit];
    };

    pricePerWeek = (invoice, subscriptionPeriod, subscriptionUnit, trialPeriod) => {
        if (trialPeriod) {
            return localizePrice(
                invoice?.price?.localizedCurrency || invoice?.price?.currency,
                '0.00',
            );
        }

        const totalPrice =
            invoice?.price?.localizedWithoutCurrency &&
            parseFloat(invoice.price.localizedWithoutCurrency.replace(',', '.'));
        let amount;

        if (subscriptionUnit === 'MONTH') {
            const period = subscriptionPeriod && parseInt(subscriptionPeriod, 10);

            amount = totalPrice / (period * 4.33);
        }
        if (subscriptionUnit === 'YEAR') {
            const period = subscriptionPeriod && parseInt(subscriptionPeriod, 10);

            amount = totalPrice / (period * 52);
        }
        if (subscriptionUnit === 'WEEK') {
            amount = totalPrice / subscriptionPeriod;
        }
        if (subscriptionUnit === 'DAY') {
            const period = subscriptionPeriod && parseInt(subscriptionPeriod, 10);

            amount = period === 7 ? totalPrice : (totalPrice / period) * 7;
        }

        if (amount && (invoice?.price?.localizedCurrency || invoice?.price?.currency)) {
            return localizePrice(
                invoice?.price?.localizedCurrency || invoice?.price?.currency,
                Math.round(amount * 100) / 100,
            );
        }

        return undefined;
    };

    renderHeader() {
        const { isActive, subscribeMotivation } = this.props;
        const headerTitle = this.getTextFromMotivation(subscribeMotivation);

        if (!isActive) {
            return null;
        }

        return (
            <View style={styles.headerContainer}>
                <Text style={styles.headerText}>{headerTitle}</Text>
            </View>
        );
    }

    render() {
        const {
            isActive,
            trialPeriod,
            subscriptionPeriod,
            subscriptionUnit,
            price,
            invoice,
            customStyles = {},
        } = this.props;

        const containerStyles = [
            [styles.container, customStyles && customStyles.container],
            isActive && [styles.containerActive, customStyles && customStyles.containerActive],
        ];
        const contentContainerStyle = [
            styles.contentContainer,
            isActive && styles.contentContainerActive,
        ];

        const contentType = trialPeriod ? '$free' : undefined;

        const [number, unit = ''] = this.getDisplayPeriod(subscriptionPeriod, subscriptionUnit);

        const mainContentFirstLine = trialPeriod ? this.getTrialsPeriodText(trialPeriod) : number;

        const mainContentSecondLine = trialPeriod
            ? Resources.strings['subscription-plan-card-free-title']
            : unit.toUpperCase();

        const footerText =
            Platform.OS === 'android'
                ? Resources.strings['subscription-item-footer-text']
                : displaySubscriptionItemText(subscriptionPeriod, subscriptionUnit);

        const firstMainText = [
            styles.firstMainText,
            styles[`firstMainText${contentType}`],
            !isActive && [styles.textNotActive, customStyles && customStyles.textNotActive],
        ];
        const secondMainText = [
            styles.secondMainText,
            styles[`secondMainText${contentType}`],
            !isActive && [styles.textNotActive, customStyles && customStyles.textNotActive],
        ];

        const separatorStyle = [
            styles.separator,
            customStyles.separator,
            !isActive && [
                styles.separatorNotActive,
                customStyles && customStyles.separatorNotActive,
            ],
        ];

        const secondaryContentContainer = [
            styles.secondaryContentContainer,
            isActive && styles.secondaryContentContainerActive,
        ];

        const mainContentContainer = [
            styles.mainContentContainer,
            isActive && styles.mainContentContainerActive,
        ];

        const priceStringText =
            Platform.OS === 'android'
                ? `${this.pricePerWeek(
                      invoice,
                      subscriptionPeriod,
                      subscriptionUnit,
                      trialPeriod,
                  )}\n`
                : `${price}\n`;

        const secondaryTextStyle = [
            styles.secondaryText,
            !isActive && customStyles && customStyles.textNotActive,
        ];

        const secondaryTextSubtitleStyle = [
            styles.secondaryText,
            !isActive && customStyles && customStyles.subtitleTextNotActive,
        ];

        return (
            <TouchableOpacity onPress={this.onPress}>
                <Gradient styles={containerStyles}>
                    {this.renderHeader()}
                    <View style={contentContainerStyle}>
                        <View style={mainContentContainer}>
                            <Text adjustsFontSizeToFit minimumFontScale={0} style={firstMainText}>
                                {mainContentFirstLine}
                            </Text>
                            <Text style={secondMainText}>{mainContentSecondLine}</Text>
                        </View>

                        <View style={separatorStyle} />

                        <View style={secondaryContentContainer}>
                            <Text style={styles.secondaryContentTextContainer}>
                                <Text style={secondaryTextStyle}>{priceStringText}</Text>
                                <Text style={secondaryTextSubtitleStyle}>{footerText}</Text>
                                {!!trialPeriod && Platform.OS === 'android' && (
                                    <Text style={styles.superscript}>
                                        {
                                            Resources.strings[
                                                'subscription-item-footer-text-trial-modifier'
                                            ]
                                        }
                                    </Text>
                                )}
                            </Text>
                        </View>
                    </View>
                </Gradient>
            </TouchableOpacity>
        );
    }
}

export { SubscribePlanItem };
