import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';

import Resources from 'dating-mobile/src/resources';
import CheckboxView from 'dating-mobile/src/user/views/user-preferences';

import styles from './styles';

export default class UserGoalsView extends PureComponent {
    static displayName = 'stepped-registration.screens.relationship-priority.view';

    static propTypes = {
        options: PropTypes.shape({
            id: PropTypes.string,
            image: PropTypes.string,
        }),
        selected: PropTypes.array,
        onToggle: PropTypes.func,
        onButtonPress: PropTypes.func,
    };

    render() {
        const { options, selected, onToggle, onButtonPress } = this.props;

        return (
            <View style={styles.container}>
                <CheckboxView
                    selected={selected}
                    options={options}
                    title={Resources.strings['onboarding-screen-more-important-title']}
                    buttonTitle={Resources.strings.next}
                    onPress={onButtonPress}
                    onToggle={onToggle}
                />
            </View>
        );
    }
}
