import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  container: {
    alignItems: 'center',
  },

  disabled: {
    opacity: 0.5,
  },
});

export default styles;
