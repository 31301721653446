import EStyleSheet from 'react-native-extended-stylesheet';
import { Dimensions } from 'react-native';

const isSmallDevice = Dimensions.get('window').width <= 700;

const styles = EStyleSheet.create({
  modal: {
    maxWidth: 590,
    width: '100%',
    marginVertical: '10%',
    alignSelf: 'center',
  },
  modalTitleWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 30,
  },
  modalTitle: {
    fontSize: 23,
    fontFamily: '$brandFontFamily',
    color: 'black',
    fontWeight: '700',
  },

  container: {
    alignSelf: 'center',
    alignItems: 'center',
    maxWidth: isSmallDevice ? Dimensions.get('window').width - 50 : 590,
    width: '100%',
    backgroundColor: 'white',
    borderRadius: isSmallDevice ? 25 : 50,
    justifyContent: 'space-between',
    paddingHorizontal: isSmallDevice ? 25 : 50,
    paddingVertical: 50,
  },

  button: { width: '80%', marginTop: 30 },

  cameraAndEditor: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },

  video: {
    width: '100%',
  },
});

export default styles;
