import React, { PureComponent } from 'react';
import { Platform } from 'react-native';
import PropTypes from 'prop-types';
import { connectActionSheet } from '@expo/react-native-action-sheet';
import Identity from '@sdv/domain/identity/model';
import Keychain from 'dating-mobile/src/utils/keychain';
import Resources from 'dating-mobile/src/resources';

export default function createController(Component) {
  class Controller extends PureComponent {
    static displayName = 'user.screens.account-settings.controller';

    static propTypes = {
      logout: PropTypes.func,
      showActionSheetWithOptions: PropTypes.func,
    };

    static contextTypes = {
      flux: PropTypes.object,
    };

    onLogoutButtonPressed = () => {
      const { showActionSheetWithOptions } = this.props;

      showActionSheetWithOptions(
        {
          options: [
            Resources.strings['account-screen-logout-button-title'],
            Resources.strings.cancel,
          ],
          title: Resources.strings['account-screen-logout-modal-title'],
          destructiveButtonIndex: 0,
          cancelButtonIndex: 1,
          showSeparators: Platform.OS === 'web',
        },
        buttonIndex => {
          if (buttonIndex === 0) {
            const { flux } = this.context;

            flux.get(Identity).actions.signOut(async () => {
              await Keychain.clearToken();

              const { logout } = this.props;

              if (logout) {
                logout();
              }
            });
          }
        },
      );
    };

    render() {
      return (
        <Component
          {...this.props}
          onLogoutButtonPress={this.onLogoutButtonPressed}
        />
      );
    }
  }

  return connectActionSheet(Controller);
}
