import React from 'react';
import { ActivityIndicator, FlatList, View } from 'react-native';
import PropTypes from 'prop-types';
import styles from './styles';
import { SupportedMethods } from 'dating-mobile/src/platform/payment/shop-info';
import { PaymentMethodItemView } from 'dating-mobile/src/payment/views/payment-method-item';
import { PayPalPaymentMethodItem } from 'dating-mobile/src/payment/views/paypal-button';
import { GooglePlayPaymentMethodItem } from 'dating-mobile/src/payment/views/google-button';
import { CardPaymentMethodItem } from 'dating-mobile/src/payment/views/card-button';

export default class PaymentTypeList extends React.Component {

    static displayName = 'payment.screens.payment-methods.view';

    static propTypes = {
        invoices: PropTypes.array.isRequired,
        onInvoiceClick: PropTypes.func.isRequired,
        paymentInProgress: PropTypes.bool
    };

    shouldComponentUpdate(nextProps) {
        return this.props.invoices !== nextProps.invoices ||
            this.props.paymentInProgress !== nextProps.paymentInProgress;
    }

    renderItem = ({ item, index }) => {
        let itemComponent;
        switch (item.method) {
            case SupportedMethods.google:
                itemComponent = (<GooglePlayPaymentMethodItem
                    invoice={item}
                    onPress={() => this.props.onInvoiceClick(item)}
                />);
                break;
            case SupportedMethods.paypal:
                itemComponent = (<PayPalPaymentMethodItem
                    invoice={item}
                    onPress={() => this.props.onInvoiceClick(item)}
                />);
                break;
            case SupportedMethods.cards:
                itemComponent = (<CardPaymentMethodItem
                    invoice={item}
                    onPress={() => this.props.onInvoiceClick(item)}
                />);
                break;
            default:
                itemComponent = (<PaymentMethodItemView
                    title={item.method}
                    price={item.price}
                    onPress={() => this.props.onInvoiceClick(item)}
                />);
                break;
        }
        return itemComponent;
    };

    render() {
        return (
            <View style={styles.container}>
                <FlatList
                    style={styles.invoices}
                    data={this.props.invoices}
                    ItemSeparatorComponent={() => <View style={styles.invoiceSeparator}/>}
                    renderItem={this.renderItem}
                    alwaysBounceVertical={false}
                    keyExtractor={item => item.sku}
                />
                {
                    this.props.paymentInProgress && (
                        <View style={styles.activityIndicatorContainer}>
                            <ActivityIndicator animating={true}/>
                        </View>
                    )
                }
            </View>
        );
    }
};
