import EStyleSheet from 'react-native-extended-stylesheet';
import { I18nManager } from 'react-native';

const textStyle = {
    fontFamily: '$brandFontFamily',
    color: '$primaryTextColor',
};

const styles = EStyleSheet.create({
    container: {
        paddingVertical: 10,
        paddingHorizontal: 23,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    imageContainer: {
        width: 66,
        height: 66,
        marginRight: 10,
        overflow: 'hidden',
        borderBottomLeftRadius: 27.5,
    },

    circle: {
        width: 55,
        height: 55,
        backgroundColor: '$brandColor',
        borderRadius: 27.5,
        position: 'absolute',
        bottom: 0,
    },

    image: {
        width: '100%',
        height: '100%',
        zIndex: 1,
        '@flavor secretly': {
            height: 85,
            position: 'absolute',
            left: -10,
            top: 10,
        },
        transform: [
            {
                scaleX: I18nManager.isRTL ? -1 : 1,
            },
        ],
    },

    textContainer: {
        flexShrink: 1,
    },

    title: {
        ...textStyle,
        fontSize: 20,
        marginBottom: 3,
    },

    subtitle: {
        ...textStyle,
        fontSize: 14,
    },

    textBold: {
        fontWeight: 'bold',
    },
});

export default styles;
