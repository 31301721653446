import React from 'react';
import { TouchableOpacity, Image } from 'react-native';
import PropTypes from 'prop-types';
import Resources from '../../../../resources';

class CameraController extends React.PureComponent {
    static displayName = 'camera.controller';
    static propTypes = {
        rotateCamera: PropTypes.func.isRequired,
    };
    state = {
        isFront: false,
    };

    switchCamera = () => {
        this.setState(state => {
            return { ...state, isFront: !state.isFront };
        }, this.props.rotateCamera);
    };

    render() {
        return (
            <TouchableOpacity onPress={this.switchCamera}>
                <Image source={Resources.images.rotateCameraIcon()} />
            </TouchableOpacity>
        );
    }
}

export default CameraController;
