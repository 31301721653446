import EStyleSheet from 'react-native-extended-stylesheet';

const textStyle = {
    fontFamily: '$brandFontFamily',
    color: '$brandTextColor',
};

const styles = EStyleSheet.create({
    container: {
        width: '100%',
        height: '100%',
        backgroundColor: '$brandColor',
        justifyContent: 'space-between',
        flexGrow: 1,
        paddingHorizontal: 30,
    },

    title: {
        ...textStyle,
        fontSize: 22,
        textAlign: 'center',
        marginTop: 20,
    },

    datePickerInputRow: {
        marginBottom: 40,
    },

    dateTitle: {
        ...textStyle,
        textAlign: 'center',
        marginTop: 30,
    },

    dateContainer: {
        width: '100%',
    },

    genderPicker: {
        width: '100%',
        height: '100%',
        justifyContent: 'center',
    },

    genderInnerPicker: { 
        height: '100%' ,
    },

    genderPickerTitle: {
        ...textStyle,
        textAlign: 'center',
        marginTop: 30,
    },

    genderPickerButtonContainerStyle: {
        width: '100%',
        height: '100%',
        justifyContent: 'center',
    },

    privacyHint: {
        fontFamily: '$brandFontFamily',
        color: '$infoTextColor',
        fontSize: 11,
        textAlign: 'center',
        marginTop: 20,
    },

    nextButtonContainer: {
        marginBottom: 30,
    },

    nextButton: {
        marginBottom: 10,
    },
});

export default styles;
