import Actions, { parseId } from './actions';

function createEventsStore(id) {
    const { userId } = parseId(id);

    const getEventsWithoutUnansweredAutoMessages = events =>
        events.filter(
            ({ payload, total }) =>
                !payload.auto || payload['sender-id'].toString() !== userId || total > 1,
        );

    class Store {
        constructor(flux) {
            const actions = flux.getActions(Actions, id);

            this.state = {
                events: [],
                allEventsLoaded: false,
                count: 0,
            };

            this.bindAction(actions.get, this.get);
            this.bindAction(actions.patch, this.patch);
            this.bindAction(actions.stale, this.stale);
            this.bindAction(actions.delete, this.delete);
        }

        patch = payload => {
            const events = this.state.events.slice();

            const index = events.findIndex(event => event['user-id'] === payload['user-id']);
            if (index >= 0) {
                events.splice(index, 1);
            }

            events.unshift(payload);

            this.setState({
                events: events,
                eventsWithoutUnansweredAutoMessages: getEventsWithoutUnansweredAutoMessages(events),
                count: events.length,
            });
        };

        get = data => {
            if (!data) {
                return;
            }

            this.setState({
                events: data.events,
                eventsWithoutUnansweredAutoMessages: getEventsWithoutUnansweredAutoMessages(
                    data.events,
                ),
                count: data.count || 0,
                allEventsLoaded: true,
            });
        };

        stale = userId => {
            if (!userId) {
                return;
            }

            const events = this.state.events.slice();
            const index = events.findIndex(event => event['user-id'] === userId);
            if (index >= 0) {
                const { ...event } = events[index];
                event.stale = true;
                event.stack = 0;
                events[index] = event;
                this.setState({
                    events: events,
                    eventsWithoutUnansweredAutoMessages: getEventsWithoutUnansweredAutoMessages(
                        events,
                    ),
                });
            }
        };

        delete = id => {
            if (!id) return;

            const events = this.state.events.slice();
            const index = events.findIndex(event => event['event-id'] === id);

            let newEventsCount = this.state.count - 1;

            if (newEventsCount < 0) {
                newEventsCount = 0;
            }

            if (index >= 0) {
                events.splice(index, 1);
                this.setState({
                    events: events,
                    eventsWithoutUnansweredAutoMessages: getEventsWithoutUnansweredAutoMessages(
                        events,
                    ),
                    count: newEventsCount,
                });
            }
        };
    }

    Store.displayName = createEventsStore.getDisplayName(id);

    return Store;
}

createEventsStore.getDisplayName = function(id) {
    return `user-events.${id}`;
};

export default createEventsStore;
