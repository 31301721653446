import React from 'react';
import { Text, View } from 'react-native';
import styles from './styles';
import Resources from 'dating-mobile/src/resources';
import PropTypes from 'prop-types';
import NavigationItem from 'dating-mobile/src/components/navigation-item';

export default class CustomerSupportView extends React.Component {
    static displayName = 'user.screens.customer-support.view';

    static propTypes = {
        hasFacebook: PropTypes.bool,
        whatsapp: PropTypes.bool,
        hasEmail: PropTypes.bool,
        onEmailClick: PropTypes.func.isRequired,
        onWhatsAppClick: PropTypes.func.isRequired,
        onFacebookClick: PropTypes.func.isRequired,
    };

    whatsAppNumber = () => {
        return <Text style={styles.whatsAppNumber}>{this.props.whatsapp}</Text>;
    };

    onEmailClick = () => {
        this.props.onEmailClick && this.props.onEmailClick();
    };

    onWhatsAppClick = () => {
        this.props.onWhatsAppClick && this.props.onWhatsAppClick();
    };

    onFacebookClick = () => {
        this.props.onFacebookClick && this.props.onFacebookClick();
    };

    render() {
        const customerSupportTypes = [];

        if (this.props.hasFacebook) {
            customerSupportTypes.push(Resources.strings['customer-support-facebook-messenger']);
        }
        if (this.props.whatsapp) {
            customerSupportTypes.push(Resources.strings['customer-support-whatsApp']);
        }
        if (this.props.hasEmail) {
            customerSupportTypes.push(Resources.strings['customer-support-email']);
        }

        const messageTemplate = [
            '',
            Resources.strings['customer-support-message-1'],
            Resources.strings['customer-support-message-2'],
            Resources.strings['customer-support-message-3'],
        ][customerSupportTypes.length];

        const message = Resources.strings.formatString(messageTemplate, ...customerSupportTypes);
        return (
            <View style={styles.container}>
                <Text style={styles.supportMessage}>{message}</Text>
                {!!this.props.hasFacebook && (
                    <NavigationItem
                        title={Resources.strings['customer-support-facebook-messenger']}
                        onPress={this.onFacebookClick}
                        icon={Resources.images.facebookMessengerIcon()}
                        titleStyle={styles.title}
                        titleContainerStyle={styles.titleContainer}
                        iconStyle={styles.icon}
                    />
                )}
                {!!this.props.whatsapp && (
                    <NavigationItem
                        title={Resources.strings['customer-support-whatsApp']}
                        onPress={this.onWhatsAppClick}
                        icon={Resources.images.whatsappIcon()}
                        ValueComponent={this.whatsAppNumber}
                        titleStyle={styles.title}
                        titleContainerStyle={styles.titleContainer}
                        iconStyle={styles.icon}
                    />
                )}
                {!!this.props.hasEmail && (
                    <NavigationItem
                        title={Resources.strings['customer-support-email']}
                        onPress={this.onEmailClick}
                        icon={Resources.images.letterIcon()}
                        titleStyle={styles.title}
                        titleContainerStyle={styles.titleContainer}
                        iconStyle={styles.icon}
                    />
                )}
            </View>
        );
    }
}
