import React, { PureComponent } from 'react';
import { I18nManager, Text, TouchableOpacity, View } from 'react-native';
// @ts-ignore
import ChatsList from 'dating-mobile/src/dialogs/chats/views/chats-list';
import Resources from 'dating-mobile/src/resources';

import { Chat, ViewProps } from './types';
import styles from './styles';

export default class extends PureComponent<ViewProps> {
  static displayName = 'screens.block-list.view';

  onUnblockPress = (chat: Chat) => () => {
    const { onUnblockPress } = this.props;

    onUnblockPress(chat);
  };

  renderHiddenItem = ({ item }: { item: Chat }) => {
    return (
      <View style={styles.hiddenRow}>
        <TouchableOpacity
          style={styles.unblockButton}
          onPress={this.onUnblockPress(item)}
        >
          <Text style={styles.unblockButtonTitle}>
            {Resources.strings['block-list-unblock-button-title']}
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  render() {
    return (
      <ChatsList
        {...this.props}
        hidePlaceholder
        renderHiddenItem={this.renderHiddenItem}
        rightOpenValue={I18nManager.isRTL ? 0 : -styles.unblockButton.width}
        leftOpenValue={I18nManager.isRTL ? styles.unblockButton.width : 0}
      />
    );
  }
}
