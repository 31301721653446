import React from 'react';
import PropTypes from 'prop-types';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { withNavigationFocus } from 'react-navigation';
import Resources from '../../../../resources';
import ReportButton from '../../../../components/report-button';
import ImageButton from '../../../../components/navigation/views/image-button';
import ButtonGroup from '../../../../components/navigation/views/button-group';
import PureScreenView from './view';
import ScreenController from './controller';
import styles from './styles';

const getTitle = navigation => {
  const title = navigation.getParam('title');
  const isLoading = navigation.getParam('isLoading');

  if (isLoading) {
    return () => <ActivityIndicator />;
  }

  return title || '';
};

const ScreenHeaderBackgroundView = ({ isFocused }) => {
  if (!isFocused) {
    return null;
  }

  return <View style={styles.headerBackground} />;
};

ScreenHeaderBackgroundView.propTypes = {
  isFocused: PropTypes.bool,
};

const ScreenHeaderBackground = withNavigationFocus(ScreenHeaderBackgroundView);

const ScreenView = ScreenController(PureScreenView);

class GalleryScreen extends React.Component {
  static displayName = 'gallery.screen';

  static propTypes = {
    navigation: PropTypes.object.isRequired,
  };

  static navigationOptions = ({ navigation }) => ({
    headerTitle: getTitle(navigation),
    headerTransparent: true,
    headerStyle: undefined,
    headerBackground: () => <ScreenHeaderBackground />,
    headerTitleStyle: styles.headerTitle,
    headerLeft: () => (
      <ImageButton
        image={Resources.images.backIcon()}
        tint={StyleSheet.flatten(styles.headerTitle).color}
        onPress={() => navigation.pop()}
      />
    ),
    headerRight: () => (
      <ButtonGroup>
        {navigation.getParam('reportButtonVisible') && (
          <ReportButton
            id={navigation.getParam('id')}
            tint={StyleSheet.flatten(styles.headerTitle).color}
            showIcon
            allowBlock
            reportedContent={navigation.getParam('reportedContent')}
          />
        )}
        {navigation.getParam('moreButtonVisible') && (
          <ImageButton
            image={Resources.images.reportIcon()}
            tint={StyleSheet.flatten(styles.headerTitle).color}
            onPress={() => navigation.getParam('onMoreButtonPressed')()}
          />
        )}
      </ButtonGroup>
    ),
    headerTintColor: styles.headerTitle.color,
  });

  setNavigationBarState = state => {
    const { navigation } = this.props;

    navigation.setParams(state);
  };

  goBack = () => {
    const { navigation } = this.props;

    navigation.goBack();
  };

  isPreview() {
    const { navigation } = this.props;

    return navigation.getParam('preview');
  }

  render() {
    const { navigation } = this.props;

    return (
      <ScreenView
        id={navigation.getParam('id')}
        basename={navigation.getParam('basename')}
        setNavigationBarState={this.setNavigationBarState}
        goBack={this.goBack}
        cheer={this.cheer}
        isPreview={this.isPreview()}
      />
    );
  }
}

export default GalleryScreen;
