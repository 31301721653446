import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        flexDirection: 'row',
        borderRadius: 10,
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },

    iconContainer: {
        marginLeft: 16,
        width: 68,
        height: 68,
        borderRadius: 34,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '$accentColor'
    },

    icon: {
        height: 24,
        width: 24,
        tintColor: '$accentTextColor'
    },

    textContainer: {
        flex: 1,
        paddingLeft: 16,
        paddingRight: 16,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },

    text: {
        color: '$primaryTextColor',
        fontFamily: '$brandFontFamily',
        fontSize: 17
    }
});

export default styles;
