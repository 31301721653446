import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    list: {
        width: '100%',
    },

    footerContainer: {
        width: '100%',
        paddingHorizontal: 40,
        paddingVertical: 20
    },

    loadMoreButton: {
        width: '100%',
        height: 36,
        borderRadius: 18,
        backgroundColor: '$focusedColor'
    },

    loadMoreButtonContent: {
        width: '100%',
        height: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },

    loadMoreButtonTitle: {
        fontSize: 13,
        fontFamily: '$brandFontFamily'
    },

    loadMoreButtonIcon: {
        marginLeft: 8,
        width: 9,
        height: 6
    },

    listActionsContainer: {
        flex: 1,
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    }
});

export default styles;
