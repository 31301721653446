import EStyleSheet from 'react-native-extended-stylesheet';

const textStyle = {
    fontFamily: '$brandFontFamily',
    color: '$brandTextColor',
};

const styles = EStyleSheet.create({
    container: {
        width: '100%',
        height: '100%',
        backgroundColor: '$primaryBackgroundColor',
        justifyContent: 'space-between',
        flexGrow: 1,
        paddingHorizontal: 40,
    },

    subTitle: {
        ...textStyle,
        fontSize: 14,
    },

    title: {
        ...textStyle,
        fontWeight: 'bold',
        fontSize: 24,
        marginBottom: 15,
    },

    genderButtonContainer: {
        marginRight: 25,
    },

    genderTitle: {
        ...textStyle,
        fontSize: 14,
        textAlign: 'center',
    },

    buttonsContainer: {
        flexDirection: 'row',
    },

    genderButton: {
        width: 72,
        height: 72,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '$brandBorderColor',
        marginBottom: 8,
    },

    selectedImage: {},

    unselectedImage: {
        opacity: 0.3,
    },

    nextButton: {
        marginBottom: 40,
    },
});

export default styles;
