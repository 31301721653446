import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    property: {
        height: 59,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'flex-start'
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    label: {
        flexGrow: 1,
        fontSize: 16,
        paddingRight: 20,
        '@media(max-width: 320)': {
            fontSize: 14
        },
        color: '$brandTextColor',
        '@flavor dma': {
            flexGrow: undefined,
            fontSize: 18,
            fontWeight: '500',
        },
        '@flavor udates': {
            flexGrow: undefined,
            fontFamily: '$brandFontFamily',
            fontSize: 17,
            fontWeight: '500',
        }
    },
    separator: {
        height: 1,
        width: '100%',
        backgroundColor: '$brandBorderColor'
    },
    error: {
        fontSize: 14,
        paddingVertical: 5,
        color: '$errorColor',
        fontFamily: '$brandFontFamily',
    },
});

export default styles;
