import LocalizedString from 'react-native-localization';
import { USER_GOALS } from '@sdv/domain/user.goals';
import commonImages from './images/images.common';
import commonStrings from './strings/strings.common';

const priceVariants = {
  coins: {
    one: 'price-one-coin',
    many: 'price-coins',
  },
  credits: {
    one: 'price-one-credit',
    many: 'price-credits',
  },
};

const resources = {
  images: commonImages,
  strings: new LocalizedString(commonStrings),
};

resources.strings.genderName = function(gender) {
  switch (gender) {
    case 'mal':
      return resources.strings.man;
    case 'fem':
      return resources.strings.woman;
    default:
      return '';
  }
};

resources.strings.goalName = function(goal) {
  switch (goal) {
    case USER_GOALS.FRIENDSHIP:
      return resources.strings['user-goals-friendship'];
    case USER_GOALS.DATING:
      return resources.strings['user-goals-dating'];
    case USER_GOALS.CHAT:
      return resources.strings['user-goals-chat'];
    default:
      return '';
  }
};

resources.strings.formatPrice = function(currency, price) {
  if (!currency || !price) {
    return null;
  }

  const priceNearOne = price < 1.01 && price > 0.99;
  const textId = priceVariants[currency][priceNearOne ? 'one' : 'many'];

  return resources.strings.formatString(resources.strings[textId], price);
};

export default resources;
