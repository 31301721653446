import { Platform } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  $hasButtonFeedback: true,
  $useTextForCurrency: false,
  $forceLocalizePrice: true,
  $showBenefit: true,

  '@media android': {
    $hasButtonFeedback: false,
  },

  container: {
    ...Platform.select({
      web: { width: '95%', alignSelf: 'center' },
      default: { width: '100%' },
    }),
    flexDirection: 'column',
    overflow: 'visible',
    paddingHorizontal: 5,
    height: 74,
    backgroundColor: '$primaryBackgroundColor',
    borderRadius: 15,
    shadowColor: 'black',
    shadowRadius: 8,
    shadowOpacity: 0.15,
    shadowOffset: { width: 0, height: 0 },
    marginVertical: 10,
    '@media android': {
      elevation: 4,
    },
  },

  content: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'visible',
    justifyContent: 'space-around',
  },

  amountContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    flexBasis: '33%',
  },

  wreathImg: {
    position: 'absolute',
    top: -12,
    bottom: 0,
  },

  coin: {
    width: vars => (vars.$useTextForCurrency ? 0 : 24),
    height: vars => (vars.$useTextForCurrency ? 0 : 24),
    marginRight: vars => (vars.$useTextForCurrency ? 0 : 6),
  },

  amount: {
    fontFamily: '$brandFontFamily',
    fontWeight: 'normal',
    fontSize: 20,
    color: '$brandColor',
  },

  amountSmall: {
    fontSize: 18,
  },

  creditsAmount: {
    '@media ios': {
      fontFamily: '$brandFontFamily',
    },
    fontWeight: 'bold',
    fontSize: 17,
    color: '$primaryTextColor',
  },

  bonusContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flexBasis: '33%',
  },

  discountContainer: {
    width: 76,
    alignSelf: 'center',
    backgroundColor: '$accentColor',
    borderRadius: 14,
    height: 28,
    justifyContent: 'center',
  },

  discount: {
    textAlign: 'center',
    fontSize: 16,
    color: '$brandTextColor',
    fontFamily: '$brandFontFamily',
  },

  benefitContainer: {
    width: 76,
    alignSelf: 'center',
    backgroundColor: '$accentColor',
    borderRadius: 14,
    height: 22,
    justifyContent: 'center',
  },

  benefit: {
    textAlign: 'center',
    fontSize: 10,
    color: '$brandTextColor',
    fontFamily: '$brandFontFamily',
  },

  priceContainer: {
    flexBasis: '33%',
    alignItems: 'center',
    justifyContent: 'center',
  },

  priceWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },

  price: {
    fontFamily: '$brandFontFamily',
    fontWeight: 'normal',
    fontSize: 18,
    color: '$brandColor',
    textAlign: 'right',
  },

  creditImg: {
    width: 18,
    marginLeft: 4,
    ...Platform.select({ web: { height: 18 } }),
  },

  creditImgDisabled: {
    opacity: 0.5,
  },

  priceForCredit: {
    fontWeight: 'bold',
    fontSize: 17,
    color: '$primaryTextColor',
    textAlign: 'right',
    '@media ios': {
      fontFamily: '$brandFontFamily',
    },
  },

  oldPrice: {
    fontSize: 15,
    textDecorationLine: 'line-through',
    color: '$disabledTextColor',
    textAlign: 'right',
  },

  oldPriceWrapper: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 7,
  },
});

export default styles;
