function createFeaturesActions(id = 'default') {
  class Actions {
    get = () => (dispatch, flux) => {
      flux.api.users.tags.features.get(id, (error, existedTags) => {
        if (error) {
          dispatch(null, error);

          return;
        }

        dispatch(existedTags);
      });
    };
  }

  Actions.displayName = createFeaturesActions.getDisplayName(id);

  return Actions;
}

createFeaturesActions.getDisplayName = function(id) {
  return `user-tags-features.${id}`;
};

export default createFeaturesActions;
