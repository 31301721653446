import React from 'react';
import { TouchableOpacity, View, Text, Image } from 'react-native';
import PropTypes from 'prop-types';

import Resources from '../../../resources';

import styles from './styles';

function DropDownItem({
  onPress,
  value,
  leftIcon,
  rightIcon,
  selected,
  root,
  isOpened,
}) {
  const actualLeftIcon = leftIcon();
  const actualRightIcon = root ? (
    <Image
      source={
        isOpened
          ? Resources.images.arrowDownIcon()
          : Resources.images.arrowUpIcon()
      }
      resizeMode="contain"
      style={styles.icon}
    />
  ) : (
    rightIcon()
  );

  return (
    <TouchableOpacity
      style={selected ? styles.selectedContainer : styles.container}
      onPress={onPress}
    >
      {actualLeftIcon && (
        <View style={styles.leftIconContainer}>{actualLeftIcon}</View>
      )}
      <View style={styles.textContainer}>
        <Text style={styles.text}>{value}</Text>
      </View>
      {actualRightIcon && (
        <View style={styles.rightIconContainer}>{actualRightIcon}</View>
      )}
    </TouchableOpacity>
  );
}

DropDownItem.propTypes = {
  leftIcon: PropTypes.func,
  rightIcon: PropTypes.func,
  onPress: PropTypes.func,
  value: PropTypes.string,
  selected: PropTypes.bool,
  root: PropTypes.bool,
  isOpened: PropTypes.bool,
};

DropDownItem.defaultProps = {
  leftIcon: () => {},
  rightIcon: () => {},
};

export default DropDownItem;
