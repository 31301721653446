import PropTypes from 'prop-types';
import { Platform } from 'react-native';
import { analytics as createFirebaseTracker } from 'react-native-firebase';
import { combineLatest } from 'rxjs';
import { take, filter, switchMap } from 'rxjs/operators';
import AppsFlyerTracker from 'dating-mobile/src/services/tracking/apps-flyer-tracker';
import IdentityModel from '@sdv/domain/identity/model';
import Session from '@sdv/domain/authorization/session';
import User from '@sdv/domain/user/model';
import flux from '@sdv/domain/app/flux';
import request from '@sdv/commons/utils/request';
import { CurrentScreen } from 'dating-mobile/src/services/functional/current-screen';
import { REGISTRATION_STEP } from 'dating-mobile/src/authentication/utils/registration-steps';
import { ROUTES_MAPPING } from 'dating-mobile/src/authentication/utils/registration-step-route';
import withConfigValue from 'dating-mobile/src/components/config-value';
import UserModel from 'dating-mobile/src/models/user/model';

import Service from '../service';
import { GATracker } from './ga-tracker';

class RegistrationTrackingService extends Service {
  static displayName = 'service.tracking.registration';

  static contextTypes = {
    flux: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);

    this.identityModel = context.flux.get(IdentityModel);
    this.identity = this.identityModel.store.getState().id;

    CurrentScreen.shared()
      .screen.pipe(
        filter(
          screen =>
            screen === ROUTES_MAPPING[REGISTRATION_STEP.BIRTHDAY_WITH_GENDER],
        ),
        take(1),
        switchMap(() => CurrentScreen.shared().screen),
        filter(
          screen => screen === ROUTES_MAPPING[REGISTRATION_STEP.PHOTO_WITH_BIO],
        ),
        take(1),
      )
      .subscribe(() => this.trackRegistrationToGA());
  }

  componentDidMount() {
    this.identityModel.store.listen(this.onIdentityChanged);
  }

  componentWillUnmount() {
    this.identityModel.store.unlisten(this.onIdentityChanged);
  }

  onIdentityChanged = state => {
    if (
      state.demo &&
      state.authorizationMethod === 'signup:password' &&
      state.id &&
      state.id !== this.identity
    ) {
      AppsFlyerTracker.trackEvent(
        'af_promo_registration',
        {},
        () => {},
        () => {},
      );
    }

    if (!state.demo && state.id && state.id !== this.identity) {
      const FirebaseTracker = createFirebaseTracker();
      // eslint-disable-next-line
      switch (state.authorizationMethod || '') {
        case 'signup:google':
          AppsFlyerTracker.trackEvent(
            'af_complete_registration',
            {
              af_registration_method: 'google',
            },
            () => {},
            () => {},
          );
          FirebaseTracker.logEvent('sign_up', {
            sign_up_method: 'GOOGLE',
          });
          FirebaseTracker.setUserProperty('YES', 'USER_GOOGLE');
          break;
        case 'signin:google':
          FirebaseTracker.logEvent('login', {
            LOGIN_METHOD: 'GOOGLE',
          });
          FirebaseTracker.setUserProperty('YES', 'USER_GOOGLE');
          break;
        case 'signup:facebook':
          AppsFlyerTracker.trackEvent(
            'af_complete_registration',
            {
              af_registration_method: 'facebook',
            },
            () => {},
            () => {},
          );
          FirebaseTracker.logEvent('sign_up', {
            sign_up_method: 'FB',
          });
          FirebaseTracker.setUserProperty('YES', 'USER_FB');
          break;
        case 'signin:facebook':
          FirebaseTracker.logEvent('login', {
            LOGIN_METHOD: 'FB',
          });
          FirebaseTracker.setUserProperty('YES', 'USER_FB');
          break;
        case 'signup:password':
          AppsFlyerTracker.trackEvent(
            'af_complete_registration',
            {
              af_registration_method: 'password',
            },
            () => {},
            () => {},
          );
          FirebaseTracker.logEvent('sign_up', {
            sign_up_method: 'EMAIL',
          });
          if (Platform.OS === 'web') this.trackRegistrationToAPI(state.id);
          break;
        case 'signin:password':
          FirebaseTracker.logEvent('sign_up', {
            LOGIN_METHOD: 'EMAIL',
          });
          break;
        case 'signup:apple':
          AppsFlyerTracker.trackEvent(
            'af_complete_registration',
            {
              af_registration_method: 'apple',
            },
            () => {},
            () => {},
          );
          FirebaseTracker.logEvent('sign_up', {
            sign_up_method: 'APPLE',
          });
          FirebaseTracker.setUserProperty('YES', 'USER_APPLE');
          break;
        case 'signin:apple':
          FirebaseTracker.logEvent('login', {
            LOGIN_METHOD: 'APPLE',
          });
          FirebaseTracker.setUserProperty('YES', 'USER_APPLE');
          break;
      }
    }
    this.identity = state.demo ? null : state.id;
  };

  async trackRegistrationToAPI(id) {
    const country = flux.get(UserModel, id).store.state.data?.country; // TODO: Handle errors
    const afidInfo = window.localStorage.getItem('afid_info');

    if (afidInfo) {
      const { host } = this.props;
      const data = { ...JSON.parse(afidInfo) };

      if (country) {
        data.country = country;
      }
      await request(`${host}/analytics/${id}/registration`, {
        method: 'PUT',
        headers: {
          Authorization: flux.api.authorize(),
          'Content-Type': 'application/json;charset=UTF-8',
        },
        body: JSON.stringify(data),
      });

      window.localStorage.removeItem('afid_info');
    }
  }

  trackRegistrationToGA() {
    Session.shared()
      .userId.pipe(
        filter(Boolean),
        switchMap(userId =>
          combineLatest(
            flux.get(User, userId).store.rxState(),
            flux.get(IdentityModel).store.rxState(),
          ),
        ),
        take(1),
      )
      .subscribe(([user, { email }]) => {
        GATracker.trackEvent({
          event: 'event_registration_changeDL',
          account: {
            email,
            registration: true,
            gender: user.gender,
            age: user.birthday?.age,
            countryByIp: user.country,
          },
        });
      });
  }
}

export default withConfigValue(RegistrationTrackingService, {
  host: 'host',
});
