import * as ROUTES from 'dating-mobile/src/routing/router/constants';
import { STEPPED_REGISTRATION_STEP, INITIAL_STEP } from './registration-steps';

export const ROUTES_MAPPING = {
  [INITIAL_STEP]: ROUTES.REGISTER,
  [STEPPED_REGISTRATION_STEP.PASSWORD]: ROUTES.ENTER_PASSWORD,
  [STEPPED_REGISTRATION_STEP.NAME]: ROUTES.ENTER_NAME,
  [STEPPED_REGISTRATION_STEP.GENDER]: ROUTES.ENTER_GENDER,
  [STEPPED_REGISTRATION_STEP.BIRTHDAY]: ROUTES.ENTER_BIRTHDAY,
  [STEPPED_REGISTRATION_STEP.PHOTO]: ROUTES.ENTER_THUMBNAIL,
  [STEPPED_REGISTRATION_STEP.GEOLOCATION]: ROUTES.TURN_ON_GEOLOCATION,
  [STEPPED_REGISTRATION_STEP.NOTIFICATIONS]: ROUTES.TURN_ON_NOTIFICATIONS,
  [STEPPED_REGISTRATION_STEP.START_SETTING_PREFERENCES]:
    ROUTES.START_SETTING_PREFERENCES,
  [STEPPED_REGISTRATION_STEP.RELATIONSHIP_PRIORITY]:
    ROUTES.SPECIFY_RELATIONSHIP_PRIORITY,
  [STEPPED_REGISTRATION_STEP.USER_GOALS]: ROUTES.CHOOSE_USER_GOALS,
  [STEPPED_REGISTRATION_STEP.PREFERRED_PERSONALITY]: ROUTES.CHOOSE_PERSONALITY,
  [STEPPED_REGISTRATION_STEP.BODY_TYPE]: ROUTES.CHOOSE_BODY_TYPE,
  [STEPPED_REGISTRATION_STEP.PREFERRED_AGE]: ROUTES.CHOOSE_PREFERRED_AGE,
  [STEPPED_REGISTRATION_STEP.FIND_MATCHES]: ROUTES.SEARCH_MATCHES,
  [STEPPED_REGISTRATION_STEP.SUBSCRIBE]: ROUTES.SUBSCRIBE,
  [STEPPED_REGISTRATION_STEP.SEND_START_MESSAGES]: ROUTES.FREE_START_MESSAGES,
};

export function routeForRegistrationStep(step) {
  return ROUTES_MAPPING[step];
}
