import React from 'react';
import PropTypes from 'prop-types';
import {
  View,
  FlatList,
  Text,
  TouchableOpacity,
  I18nManager,
  StyleSheet,
} from 'react-native';
import { SwipeListView } from 'react-native-swipe-list-view';
import ChatsPlaceholder from 'dating-mobile/src/components/placeholder';
import testId from 'dating-mobile/src/utils/test-id';
import withConfigValue from 'dating-mobile/src/components/config-value';
import { EventsListPlaceholder } from 'dating-mobile/src/components/events-list-placeholder';

import ContactItem from './item';
import Resources from '../../../../resources';
import styles from './styles';

class ChatsList extends React.Component {
  static displayName = 'dialogs.chat.chats-list';

  static contextTypes = {
    flux: PropTypes.object,
  };

  static propTypes = {
    chats: PropTypes.array,
    navigation: PropTypes.object,
    onDeleteChatButtonPress: PropTypes.func,
    onChatSelect: PropTypes.func,
    ListHeaderComponent: PropTypes.func,
    likesEnabled: PropTypes.bool,
    minimized: PropTypes.bool,
    isFocused: PropTypes.bool,
    renderHiddenItem: PropTypes.func,
    rightOpenValue: PropTypes.number,
    leftOpenValue: PropTypes.number,
    hidePlaceholder: PropTypes.bool,
  };

  static defaultProps = {
    hidePlaceholder: false,
  };

  componentWillReceiveProps(nextProps) {
    const { isFocused } = this.props;

    if (isFocused && !nextProps.isFocused && this.list) {
      this.list.safeCloseOpenRow();
    }
  }

  shouldComponentUpdate(nextProps) {
    const { chats, ListHeaderComponent } = this.props;

    return (
      chats !== nextProps.chats ||
      ListHeaderComponent !== nextProps.ListHeaderComponent
    );
  }

  renderItem = ({ item }) => {
    const { minimized } = this.props;
    const { 'user-details': userDetails, ...data } = item;
    const userId = data['user-id'];

    return (
      <ContactItem
        small={minimized}
        key={userId}
        id={userId}
        user={userDetails}
        data={data}
        onSelect={this.onChatSelected}
      />
    );
  };

  onChatSelected = (...args) => {
    const { onChatSelect } = this.props;

    if (onChatSelect) {
      onChatSelect(...args);
    }
  };

  onDeletePressed = item => () => {
    const { onDeleteChatButtonPress } = this.props;

    if (this.list) {
      this.list.safeCloseOpenRow();
    }

    if (onDeleteChatButtonPress) {
      onDeleteChatButtonPress(item);
    }
  };

  renderHiddenItem = (rowData, rowMap) => {
    const { renderHiddenItem } = this.props;

    if (renderHiddenItem) {
      return renderHiddenItem(rowData, rowMap);
    }

    const { item } = rowData;

    return (
      <View style={styles.hiddenRow}>
        <TouchableOpacity
          style={styles.deleteButton}
          onPress={this.onDeletePressed(item)}
        >
          <Text style={styles.deleteButtonTitle}>
            {Resources.strings.delete}
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  defaultRender = () => {
    const {
      chats,
      ListHeaderComponent,
      rightOpenValue,
      leftOpenValue,
      minimized,
    } = this.props;

    const List = minimized ? FlatList : SwipeListView;

    // Have to add removeClippedSubviews={false} because sometimes content of ListView doesn't draws when corresponding tab opened.
    // https://github.com/react-navigation/react-navigation/issues/1238
    return (
      <View style={styles.container}>
        <List
          ref={ref => {
            this.list = ref;
          }}
          style={styles.scrollView}
          removeClippedSubviews={false}
          data={chats || []}
          renderItem={this.renderItem}
          renderHiddenItem={this.renderHiddenItem}
          rightOpenValue={
            rightOpenValue ||
            (I18nManager.isRTL
              ? 0
              : -StyleSheet.flatten(styles.deleteButton).width)
          }
          leftOpenValue={
            leftOpenValue ||
            (I18nManager.isRTL
              ? StyleSheet.flatten(styles.deleteButton).width
              : 0)
          }
          disableRightSwipe={!I18nManager.isRTL}
          disableLeftSwipe={I18nManager.isRTL}
          directionalDistanceChangeThreshold={10}
          renderHeader={ListHeaderComponent}
          keyExtractor={item => item['user-id']}
          {...testId(Resources.strings['chat-list-accessibility-label'])}
        />
      </View>
    );
  };

  // TODO: Remove this method as soon as SwipeListView will use FlatList or better to display chats and move placeholder into it
  placeholderRender = () => {
    const { likesEnabled, ListHeaderComponent } = this.props;

    return (
      <View style={styles.container}>
        {!likesEnabled && ListHeaderComponent && ListHeaderComponent()}
        {this.renderPlaceholderBody()}
      </View>
    );
  };

  renderPlaceholderBody = () => {
    const { likesEnabled, navigation } = this.props;

    return likesEnabled ? (
      <EventsListPlaceholder
        image={Resources.images.chatsListPlaceholder()}
        mainText={Resources.strings['likes-placeholder-motivational-text']}
        secondaryText={
          Resources.strings['chat-list-placeholder-description-text']
        }
      />
    ) : (
      <ChatsPlaceholder
        navigate={navigation.navigate}
        bodyText={Resources.strings['chats-placeholder-text']}
        buttonText={Resources.strings['chats-placeholder-button-title']}
      />
    );
  };

  render() {
    const { chats, hidePlaceholder } = this.props;

    return !chats.length && !hidePlaceholder
      ? this.placeholderRender()
      : this.defaultRender();
  }
}

export default withConfigValue(ChatsList, {
  likesEnabled: 'features.likes-list-enabled',
});
