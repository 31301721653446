import React from 'react';
import PropTypes from 'prop-types';
import withMiscData from './index';

const VALUES_PROPERTY = 'generated.misc-data.value.values';

export default function createController(Component, options = {}) {

    const key = options.key;
    const keyPropName = options.keyPropName || 'name';
    const dataType = options.dataType;
    const dataTypePropName = options.dataTypePropName || 'dataType';
    const valuePropName = options.valuePropName || 'value';

    class Value extends React.Component {
        static displayName = 'misc-data.value';
        static propTypes = {
            [keyPropName]: PropTypes.string,
            [VALUES_PROPERTY]: PropTypes.object
        };

        render() {
            const { ...props } = this.props;

            const valueKey = props[keyPropName] || key;
            props[valuePropName] = props[VALUES_PROPERTY] && typeof valueKey === 'string'
                ? props[VALUES_PROPERTY][valueKey]
                : undefined;

            delete props[VALUES_PROPERTY];

            return (<Component {...props} />);
        }

    }

    return withMiscData(
        Value,
        {
            dataType: dataType,
            dataTypePropName: dataTypePropName,
            valuesPropName: VALUES_PROPERTY
        }
    );

}
