import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $placeholderTextColor: '$primaryPlaceholderColor',
    $selectionColor: '$accentColor',
    $closeIconColor: '$primaryTextColor',
    container: {
        flex: 1,
        width: '100%',
        height: 36,
        borderColor: '$primaryBorderColor',
        borderBottomWidth: 1,
        borderTopWidth: 1,
    },
    nav: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '$primaryBackgroundColor',
        paddingHorizontal: 20,
    },
    input: {
        padding: 0,
        margin: 0,
        flex: 1,
        fontSize: 16,
        color: '$primaryTextColor',
    }
});

export default styles;
