import EStyleSheet from 'react-native-extended-stylesheet';

const smilePack = {
    width: 48,
    height: 48,
    padding: 8
};

const styles = EStyleSheet.create({
    container: {
        flexDirection: 'column',
        paddingTop: 0,
        backgroundColor: '$primaryBackgroundColor'
    },

    smiles: {
        marginTop: 0,
        height: 210
    },

    smilePacks: {
        height: 48
    },

    smilePack: smilePack,

    selectedSmilePack: {
        ...smilePack,
        backgroundColor: '$focusedColor',
        '@flavor tubit': {
            gradientColor: '$brandButtonDisabledGradientColor'
        }
    },

    smilePackIcon: {
        width: '100%',
        height: '100%'
    }
});

export default styles;
