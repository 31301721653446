import guid from '@sdv/commons/utils/guid';
import lohi from '@sdv/commons/utils/lohi';
import ServerDate from '@sdv/domain/api/date';
import { ChatAnonymity } from '@sdv/domain/chat/chat-anonymity';
import { take } from 'rxjs/operators';

const GET_HEADERS = {
  accept: 'application/json+videos',
};

function createMessagesActions(id) {
  const params = parseId(id);

  const chatAnonymity = new ChatAnonymity(params.identity, params.attendee);

  class MessagesActions {
    send = (text, reference, tag, instant) => (dispatch, flux) => {
      const request = {
        tag: tag || generateTag(params.identity),
        text: text || '',
        reference,
        instant: typeof instant === 'undefined' || instant ? 1 : 0,
      };

      const pair = lohi(params.identity, params.attendee);

      const serverDate = ServerDate(flux.api);

      serverDate.now(date => {
        const message = {
          tag: request.tag,
          sender: params.identity,
          recipient: params.attendee,
          text: request.text,
          timestamp: date,
          meta: {
            reference: request.reference,
          },
        };

        dispatch(message);

        chatAnonymity.writingAsAnonymous
          .pipe(take(1))
          .subscribe(writingAsAnonymous => {
            const resource = writingAsAnonymous
              ? flux.api.dialogs.messages.avatars.post
              : flux.api.dialogs.messages.post;

            resource(pair.lower, pair.higher, request, error => {
              if (error) {
                dispatch(
                  {
                    ...message,
                    status: DELIVERY_STATUS.FAILED,
                  },
                  error,
                );
              }
            });
          });
      });
    };

    read = messageId => (dispatch, flux) => {
      const pair = lohi(params.identity, params.attendee);

      chatAnonymity.writingAsAnonymous
        .pipe(take(1))
        .subscribe(writingAsAnonymous => {
          const resource = writingAsAnonymous
            ? flux.api.chat.avatars.put
            : flux.api.chat.put;

          resource(pair.lower, pair.higher, messageId, { read: true });

          dispatch(messageId);
        });
    };

    setReadForOutgoing = messageTag => dispatch => {
      dispatch(messageTag);
    };

    patch = message => dispatch => {
      dispatch(message);
    };

    delete = tag => dispatch => {
      dispatch(tag);
    };

    get = (count = 30, skip = 0) => (dispatch, flux) => {
      const pair = lohi(params.identity, params.attendee);

      chatAnonymity.writingAsAnonymous
        .pipe(take(1))
        .subscribe(writingAsAnonymous => {
          const resource = writingAsAnonymous
            ? flux.api.dialogs.messages.avatars.get
            : flux.api.dialogs.messages.get;

          resource(
            pair.lower,
            pair.higher,
            { select: count, omit: skip },
            (err, msgs) => {
              let error = err;

              if (error) {
                dispatch(null, error);

                return;
              }

              const messages = (msgs || []).map(message => ({
                ...message,
                meta: message.meta || {},
              }));

              if (messages.length !== count) {
                error = new MessagesActionsError(
                  MessagesActionsError.CODES.PARTIAL_LOAD,
                );
              }

              dispatch(messages, error);
            },
            GET_HEADERS,
          );
        });
    };
  }

  MessagesActions.displayName = createMessagesActions.getDisplayName(id);

  return MessagesActions;
}

createMessagesActions.getDisplayName = function(id) {
  return `dialogs-messages.${id}`;
};

export default createMessagesActions;

export function MessagesActionsError(code) {
  const instance = new Error(`MessagesActionsError (CODE: ${code})`);

  instance.name = 'MessagesActionsError';
  instance.code = code;

  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(instance, MessagesActionsError.prototype);
  } else {
    // eslint-disable-next-line no-proto
    instance.__proto__ = MessagesActionsError.prototype;
  }

  if (Error.captureStackTrace) {
    Error.captureStackTrace(instance, MessagesActionsError);
  }

  return instance;
}

MessagesActionsError.prototype = Object.create(Error.prototype);

if (Object.setPrototypeOf) {
  Object.setPrototypeOf(MessagesActionsError, Error);
} else {
  // eslint-disable-next-line no-proto
  MessagesActionsError.__proto__ = Error;
}

MessagesActionsError.CODES = Object.freeze({
  PARTIAL_LOAD: 1,
  PAYMENT_REQUIRED: 2,
});

export function parseId(id) {
  const error = new Error(
    'MessagesModel should have id in format "{identity}:{attendee}"',
  );

  if (typeof id !== 'string') {
    throw error;
  } else {
    const keys = id.split(':');

    if (keys.length !== 2 || !keys[0] || !keys[1]) {
      throw error;
    }

    return {
      identity: keys[0],
      attendee: keys[1],
    };
  }
}

export function getId(identity, attendee) {
  return `${identity}:${attendee}`;
}

export function generateTag(sender, prefix) {
  return `${prefix || ''}${sender}_${guid()}`;
}

export const DELIVERY_STATUS = Object.freeze({
  FAILED: -1,
  DELIVERED: 1,
  UNPAID: 2,
});
