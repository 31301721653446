import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    image: {
        width: '100%',
        height: '100%'
    },
    content: {
        aspectRatio: 1,
        width: '100%'
    },
    container: {
        margin: 8,
    },
    middleItemModifier: {
        marginTop: -40
    }
});

export default styles;
