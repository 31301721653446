import withTargetedTags from 'dating-mobile/src/models/user.tags.targeted/controllers';
import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-native';
import Resources from 'dating-mobile/src/resources';

export default function createController(Component) {

    class Controller extends React.Component {

        static displayName = 'inbox.views.all-letters.block-user.controller';

        static propTypes = {
            userId: PropTypes.string.isRequired,
            attendeeId: PropTypes.string.isRequired,
            addTag: PropTypes.func.isRequired,
        };

        blockUser = () => {
            this.props.addTag && this.props.addTag('blocked', (error) => {
                if (error && error.status === 409) {
                    Alert.alert(
                        Resources.strings['user-already-blocked-alert-title'],
                        '',
                        [{text: Resources.strings.cancel, style: 'cancel'}]
                    );
                }
            });
        };

        render() {
            return (<Component blockUser={this.blockUser}/>);
        }
    }

    return withTargetedTags(
        Controller,
        {
            userIdPropName: 'userId',
            targetIdPropName: 'attendeeId'
        }
    );
}
