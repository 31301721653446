import { AppState } from 'react-native';
import Permissions from 'react-native-permissions';
import PropTypes from 'prop-types';
import Service from '../../service';
import configValue from '../../../components/config-value';

class DevicesModifying extends Service {
  static displayName = 'services-devices-modifying';

  appState = AppState.currentState;

  static contextTypes = {
    flux: PropTypes.object,
  };

  componentDidMount() {
    AppState.addEventListener('change', this.appStateChanged);
    this.appStateChanged(AppState.currentState);
  }

  componentWillUnmount() {
    AppState.removeEventListener('change', this.appStateChanged);
  }

  appStateChanged = nextAppState => {
    if (nextAppState === 'active') {
      Permissions.checkMultiple(['camera', 'microphone']).then(response => {
        const devicesAndTechnologies = [];

        if (response.camera === 'authorized') {
          devicesAndTechnologies.push('cam');
        }
        if (response.microphone === 'authorized') {
          devicesAndTechnologies.push('mic');
        }
        if (this.props.videoChatEnabled || this.props.audioCallsEnabled) {
          devicesAndTechnologies.push('webrtc');
        }
        this.context.flux.api.augment(
          'x-devices',
          devicesAndTechnologies.join(','),
        );
      });
    }
    this.appState = nextAppState;
  };
}

export default configValue(DevicesModifying, {
  videoChatEnabled: 'features.video-chat-enabled',
  audioCallsEnabled: 'features.audio-calls-enabled',
});
