import React from "react";
import PropTypes from "prop-types";
import withConfigValue from 'dating-mobile/src/components/config-value';
import SimilarAgePreferences from '@sdv/domain/user/preferences/similar-age-preferences';
import UserPreferencesModel from '@sdv/domain/user/preferences/repository';

export default function controller(Component) {
    class Controller extends React.Component {
        static displayName = 'user.profile.views.profile-description.looking-for-description.controller';
        static propTypes = {
            ownerId: PropTypes.string,
            userId: PropTypes.string,
            similarAgePreferences: PropTypes.bool
        };
        static contextTypes = {
            flux: PropTypes.object
        };

        state = {
            preferences: null
        };

        constructor(props){
            super(props);
            this.subscribe();
        }

        componentDidUpdate(prevProps) {
            if (this.props.ownerId !== prevProps.ownerId
                || this.props.userId !== prevProps.userId
                || this.props.similarAgePreferences !== prevProps.similarAgePreferences) {
                this.unsubscribe();
                this.subscribe();
            }
        }

        componentWillUnmount() {
            this.unsubscribe();
        }

        subscribe() {
            if (!this.props.ownerId) {
                return;
            }

            if (this.props.similarAgePreferences && this.props.ownerId !== this.props.userId) {
                this.userPreferences = new SimilarAgePreferences(this.props.ownerId);
                this.userPreferencesSubscription = this.userPreferences.preferences
                    .subscribe(preferences => this.setState({ preferences: preferences }))
            } else {
                this.userPreferences = this.flux.get(UserPreferencesModel, this.props.ownerId).store.rxState();
                this.userPreferencesSubscription = this.userPreferences.preferences
                    .subscribe(preferences => this.setState({ preferences: preferences }))
            }
        }

        unsubscribe() {
            this.userPreferencesSubscription && this.userPreferencesSubscription.unsubscribe();
        }

        render() {
            return (<Component
                preference={this.state.preferences}
                {...this.props}
            />);
        }
    }

    return withConfigValue(
        Controller,
        {
            similarAgePreferences: 'features.similar-age-preferences-in-profile-enabled'
        }
    );
}
