import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    button: {
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonBackgroundColor: {
        backgroundColor: '$minorAccentColor'
    },
    longButtonTitle: {
        color: 'white',
        fontSize: 16,
        fontWeight: 'bold',
        fontFamily: '$brandFontFamily'
    },
    roundButtonStyle: {
        tintColor: 'white'
    },
});

export default styles;
