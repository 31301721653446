import { LETTER_DELIVERY_STATUS } from '@sdv/domain/dialogs.mailbox/outbox/letter-delivery-status';
import LetterWritingModel from "../../letter-writing";
import guid from '@sdv/commons/utils/guid';
import qs from "qs";

export default function createOutgoingLettersActions(id) {

    const senderId = id;

    class Actions {
        sendLetter(letter) {
            return (dispatch, flux) => {
                dispatch(letter);

                const modelId = qs.stringify({ senderId: senderId, recipientId: letter.recipientId, letterTag: letter.tag });
                const letterWritingModel = flux.get(LetterWritingModel, modelId);
                const letterState = letterWritingModel.store.getState();

                const sendingFail = () => {
                    const letterWithFailedStatus = {
                        ...letter,
                        status: LETTER_DELIVERY_STATUS.FAILED
                    };
                    dispatch(letterWithFailedStatus);
                };

                const sendLetterWith = (attachments) => {
                    let updatedAttachments = [];
                    for (let attachment of attachments) {
                        switch (attachment.mediaType) {
                            case 'photo':
                                updatedAttachments.push(`photo:${attachment.basename}`);
                                break;
                            case 'video':
                                updatedAttachments.push(`video:${attachment.basename}`);
                                break;
                        }
                    }
                    const letterContent = {
                        tag: letter.tag,
                        text: letterState.text,
                        subject: letterState.subject,
                        cover: letterState.cover,
                        attachments: updatedAttachments
                    };
                    flux.api.dialogs.letters.post(
                        letter.recipientId,
                        senderId,
                        letterContent,
                        (error) => {
                            if (error) {
                                sendingFail();
                            }
                        }
                    );
                };

                let dispose;
                const sendLetterIfAttachmentAreUploaded = state => {
                    const uploadFailed = state.attachments.findIndex(attachment => attachment.uploadFailed) >= 0;
                    if (uploadFailed) {
                        sendingFail();
                        dispose && dispose();
                    }
                    const uploaded = state.attachments.findIndex(attachment => attachment.local) < 0;
                    if (uploaded) {
                        sendLetterWith(state.attachments);
                        dispose && dispose();
                    }
                    return uploaded
                };

                if (!sendLetterIfAttachmentAreUploaded(letterState)) {
                    dispose = letterWritingModel.store.listen((state) => { sendLetterIfAttachmentAreUploaded(state) })
                }
            }
        };
    }

    Actions.displayName = createOutgoingLettersActions.getDisplayName(id);

    return Actions;
}

createOutgoingLettersActions.getDisplayName = function(id) {
    return `dialogs-mailbox-outbox.${id}`;
};

export function newLetter() {
    return guid();
}
