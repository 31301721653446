import React from 'react';
import { Subscription } from 'rxjs';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';
import Resources from 'dating-mobile/src/resources';
import StreamDescriptionEditingViewModel from '@sdv/domain/StreamDescriptionEditingViewModel';
import withImagePicker from 'dating-mobile/src/components/image-picker';

export default function createStreamDescriptionPreset(Component) {
    class StreamDescriptionPresetController extends React.Component {
        constructor(props) {
            super(props);
            this.viewModel = StreamDescriptionEditingViewModel.shared(props.userId);

            this.state = {};
        }

        componentDidMount() {
            this.disposeBag = new Subscription();

            const coverSubscription = this.viewModel.cover.subscribe(cover =>
                this.setState({ cover }),
            );
            this.disposeBag.add(coverSubscription);

            const titleSubscription = this.viewModel.title.subscribe(title =>
                this.setState({ title }),
            );
            this.disposeBag.add(titleSubscription);

            const topicsSubscription = this.viewModel.topics.subscribe(topics =>
                this.setState({ topics }),
            );
            this.disposeBag.add(topicsSubscription);

            const hintSubscription = this.viewModel.hintVisible.subscribe(hintVisible =>
                this.setState({ hintVisible }),
            );
            this.disposeBag.add(hintSubscription);

            this.viewModel.startStreamPreview();
        }

        componentWillReceiveProps(nextProps, nextContext) {
            if (nextProps.source?.uri !== this.props.source?.uri) {
                this.viewModel.coverSelected.next(nextProps.source);
            }
        }

        componentWillUnmount() {
            this.disposeBag && this.disposeBag.unsubscribe();
        }

        handleChangeTitle = text => {
            this.viewModel.title.next(text);
        };

        handleChangeCover = () => {
            this.props.select && this.props.select();
        };

        handleChangeTopic = topic => () => {
            this.viewModel.topicPressed.next(topic);
        };

        handleGoLivePressed = () => {
            this.viewModel.goLiveButtonPressed.next();
        };

        render() {
            return (
                <Component
                    onChangeTitle={this.handleChangeTitle}
                    onChangeTopic={this.handleChangeTopic}
                    onChangeCover={this.handleChangeCover}
                    onSaveChanges={this.handleGoLivePressed}
                    id={this.props.userId}
                    {...this.props}
                    {...this.state}
                />
            );
        }
    }

    return withIdentityId(
        withImagePicker(StreamDescriptionPresetController, {
            title: Resources.strings['stream-description-preset-change-cover'],
        }),
    );
}
