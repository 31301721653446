import React from 'react';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import Resources from 'dating-mobile/src/resources';
import styles from './styles';

export interface Props {
    onPress(): void;
    userId: string;
}

interface State {}

export default class CreateNewsletterButton extends React.Component<Props, State> {
    static displayName = 'create-newsletter-button.view';

    render() {
        const { strings, images } = Resources;

        const { onPress } = this.props;

        return (
            <View style={styles.container}>
                <TouchableOpacity onPress={onPress}>
                    <LinearGradient
                        style={styles.button}
                        colors={styles.$backgroudGradientColors}
                        start={{ x: 0, y: 0 }}
                        end={{ x: 1, y: 0 }}
                    >
                        <Image style={styles.image} source={images.createNewsletterButtonIcon()} />
                        <Text style={styles.title}>
                            {strings['create-newsletter-button-title']}
                        </Text>
                    </LinearGradient>
                </TouchableOpacity>
            </View>
        );
    }
}
