import { BaseStore } from "@sdv/model";

export default class Store extends BaseStore {
    static displayName = 'dialogs-mailbox.letter-cover';

    constructor() {
        super();
        this.state = {
            covers: [
                "18315a",
                "1f6656",
                "2e2528",
                "6a205f",
                "97cfe3",
                "b0dad8",
                "b82025",
                "bbdef5",
                "bc1f46",
                "c9a574",
                "d1ae70",
                "d86227",
                "e3e2d5",
                "e8eae9",
                "ebc8a6",
                "ece4cd",
                "efded2",
                "f4dcc4",
                "f4e7d7",
                "f6ece0",
                "fcfddf",
                "fff2d3",
                "fff4e5",
                "fffef4"
            ]
        };
    }
}
