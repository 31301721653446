import { Platform } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  container: {
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'center',
    borderRadius: 18,
    overflow: 'hidden',
    ...Platform.select({
      web: { cursor: 'pointer' },
      default: {},
    }),
  },

  photo: {
    width: 130,
    height: 130,
    backgroundColor: 'black',
  },

  unsentPhoto: {
    opacity: 0.5,
  },

  overlay: {
    position: 'absolute',
    width: 130,
    height: 130,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  darkOverlay: {
    position: 'absolute',
    width: 130,
    height: 130,
    backgroundColor: '$shadowColor',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  description: {
    fontSize: 10,
    color: 'white',
    '@flavor lovinga': {
      fontSize: 16,
      lineHeight: 16,
      marginBottom: -3,
    },
  },

  paidPhotoBadge: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 27,
    borderRadius: 13,
    paddingLeft: 8,
    paddingRight: 6,
    backgroundColor: '$shadowColor',
  },

  paidIcon: {
    marginLeft: 4,
    marginBottom: -1,
    width: 15,
    height: 15,
  },

  progress: {
    position: 'absolute',
    top: 10,
    right: 10,
    width: 20,
    color: 'white',
  },
});

export default styles;
