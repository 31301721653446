import React from 'react';
import PropTypes from 'prop-types';
import { View, TextInput } from 'react-native';
import ValidationError from 'dating-mobile/src/components/validation-error';
import styles from './styles';

export default class EditTextForm extends React.PureComponent {
    static displayName = 'components.forms.edit-text.view';
    static propTypes = {
        ...TextInput.propTypes,
        onValueChange: PropTypes.func,
        validationError: PropTypes.string
    };

    render() {
        const { onValueChange, validationError, ...props } = this.props;

        return (
            <View style={styles.container}>
                <View style={styles.textInputContainer}>
                    <TextInput
                        style={styles.textInput}
                        onChangeText={onValueChange}
                        placeholderTextColor={styles.$placeholderColor}
                        autoFocus={true}
                        {...props}
                    />
                    <ValidationError error={validationError} style={styles.validationError} />
                </View>
            </View>
        );
    }

}
