function createActions(id) {
    class Actions {}

    Actions.displayName = createActions.getDisplayName(id);
    return Actions;
}

createActions.getDisplayName = function(id) {
    return `call-operator.${id}`;
};

export default createActions;
