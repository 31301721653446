import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Image,
  Platform,
  ScrollView,
  StatusBar,
  Text,
  View,
} from 'react-native';
import OpenAppSettings from 'react-native-app-settings';
import NavigationItem from 'dating-mobile/src/components/navigation-item';
import AuthenticateNavigationItem from 'dating-mobile/src/user/views/authenticate-navigation-item';
import { CustomerSupportNavigationItem } from 'dating-mobile/src/user/views/customer-support-navigation-item';
import { RestorePurchasesNavigationItem } from 'dating-mobile/src/user/views/restore-purchases-navigation-item';
import BalanceRefiller from 'dating-mobile/src/payment/utils/balance-refiller';
import UserAboutMeText from 'dating-mobile/src/user/common/views/user-about-me-text';
import UserBadges from 'dating-mobile/src/user/common/views/user-badges';
import { BoostProfileBanner } from 'dating-mobile/src/feed/views/boost-profile-banner';
import { HideUserFromFeedSwitchItem } from 'dating-mobile/src/user/views/user-visibility-in-feed';
import { Gender } from '@sdv/domain/user/gender';
import { PurchasePromotionBanner } from 'dating-mobile/src/components/purchase-promotion-banner';
import * as ROUTES from 'dating-mobile/src/routing/router/constants';

import CurrencyBasedText from '../../../components/currency/currency-based-text';
import testId from '../../../utils/test-id';
import Can from '../../../models/user.ability/controller';
import Profile from '../../common/views/profile';
import CredentialsUpdater from '../../../authentication/utils/credentials-updater';
import BalanceController from '../../../models/credits.accounts.balance/controllers/controller';
import Resources from '../../../resources';
import IfConfigValue from '../../../components/config-value/if';
import { PAYMENT_REASON } from '../../../payment/utils/balance-refiller';
import { ShareButton } from '../../views/share-button';

import styles from './styles';

const BalanceView = BalanceController(props => {
  let balance = '';

  if (props.balance && props.balance.amount !== undefined) {
    balance = props.balance.amount;
  }

  return (
    <View style={styles.balanceContainer}>
      <Text style={styles.balance} {...testId('Balance label')}>
        {balance}
      </Text>
      {styles.$coinsIconVisible && (
        <Image
          style={styles.coin}
          source={Resources.images.coinIcon()}
          {...testId('Coin icon')}
        />
      )}
    </View>
  );
});

export default class AccountScreen extends Component {
  static displayName = 'user.account.screen';

  static propTypes = {
    userId: PropTypes.string,
    membershipIsInactive: PropTypes.bool,
    premiumMembershipIsActive: PropTypes.bool,
    userIsBoosted: PropTypes.bool,
    pushNotificationsEnabled: PropTypes.bool,
    boostProfileEnabled: PropTypes.bool,
    onLogoutButtonPress: PropTypes.func,
    appVersion: PropTypes.string,
    navigation: PropTypes.object,
    user: PropTypes.object,
    premiumSubscriptionEnabled: PropTypes.bool,
    callsPackEnabled: PropTypes.bool,
    callsPackIsActive: PropTypes.bool,
    creditsEnabledForFeatures: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.arrayOf(PropTypes.string),
    ]),
  };

  static contextTypes = {
    flux: PropTypes.object,
  };

  constructor(props, context) {
    super(props);
    this.bus = context.flux.bus;
  }

  componentDidMount() {
    const { navigation } = this.props;

    if (!navigation) return;

    navigation.setParams({
      edit: this.onEditPressed,
    });

    const shouldOpenPurchaseScreen = navigation.getParam(
      'shouldOpenPurchaseScreen',
      false,
    );

    if (shouldOpenPurchaseScreen) this.handlePurchasePress();

    this.statusBarListener = navigation.addListener('didFocus', () => {
      StatusBar.setBarStyle(styles.$statusBarStyle);
      StatusBar.setBackgroundColor(styles.$statusBarBackgroundColor);
    });
  }

  componentWillUnmount() {
    this.statusBarListener.remove();
  }

  handlePurchasePress = () => {
    BalanceRefiller.purchaseCredits(PAYMENT_REASON.MANUAL);
  };

  openSubscriptionScreen = () => {
    const { membershipIsInactive } = this.props;
    const subscribe = () => BalanceRefiller.subscribe(PAYMENT_REASON.MANUAL);
    const purchaseCredits = () =>
      BalanceRefiller.purchaseCredits(PAYMENT_REASON.MANUAL);

    if (membershipIsInactive) {
      subscribe();
    } else {
      purchaseCredits();
    }
  };

  onSubscribePressed = status => () => {
    return status
      ? this.openSubscriptionScreen()
      : CredentialsUpdater.updateCredentials(this.openSubscriptionScreen);
  };

  onEditPressed = status => {
    const { navigation, userId } = this.props;

    const edit = id => navigation.navigate(ROUTES.PROFILE_EDIT, { id });

    return status
      ? edit(userId)
      : CredentialsUpdater.updateCredentials(identity => edit(identity.id));
  };

  editTravels = () => {
    const { navigation, userId } = this.props;

    navigation.navigate(ROUTES.PROFILE_TRAVELS_SCREEN, {
      id: userId,
    });
  };

  renderBalance = () => {
    const { userId } = this.props;

    return <BalanceView id={userId} />;
  };

  renderMembershipStatus = () => {
    const { membershipIsInactive } = this.props;

    if (typeof membershipIsInactive !== 'boolean') {
      return null;
    }

    const statusStyle = membershipIsInactive
      ? styles.inactiveMembershipStatus
      : styles.activeMembershipStatus;

    const text = membershipIsInactive
      ? Resources.strings['account-screen-membership-inactive-status-text']
      : Resources.strings['account-screen-membership-active-status-text'];

    return <Text style={statusStyle}>{text}</Text>;
  };

  onThumbnailPress = status => () => {
    this.onEditPressed(status);
  };

  onPushSettingsPress = () => {
    const { pushNotificationsEnabled, navigation } = this.props;

    if (pushNotificationsEnabled) {
      navigation.navigate(ROUTES.NOTIFICATIONS_SETTINGS);
    } else {
      OpenAppSettings.open();
    }
  };

  renderPushSettingsStatus = () => {
    const text =
      Resources.strings['account-screen-notification-settings-status-text'];
    const statusStyle = styles.pushSettingsStatus;

    return <Text style={statusStyle}>{text}</Text>;
  };

  render() {
    const {
      user,
      pushNotificationsEnabled,
      boostProfileEnabled,
      userIsBoosted,
      userId,
      membershipIsInactive,
      premiumMembershipIsActive,
      appVersion,
      premiumSubscriptionEnabled,
      callsPackEnabled,
      callsPackIsActive,
      creditsEnabledForFeatures,
    } = this.props;

    const pushStatusView = pushNotificationsEnabled
      ? null
      : this.renderPushSettingsStatus;
    const shouldShowBoostLabel = boostProfileEnabled && userIsBoosted;
    const subscriptionIsActive =
      !membershipIsInactive || premiumMembershipIsActive;

    return (
      <View style={styles.container}>
        <ScrollView
          style={styles.scrollView}
          contentContainerStyle={styles.content}
        >
          <View style={styles.profileContainer}>
            <Can do="update" of="profile">
              {status => (
                <Profile
                  userId={userId}
                  hideOnlineIndicator
                  isBoosted={shouldShowBoostLabel}
                  onThumbnailPress={this.onThumbnailPress(status)}
                  usernameStyle={styles.userName}
                />
              )}
            </Can>
          </View>
          <IfConfigValue
            path="features.anonymous-using-enabled"
            equalsTo={true}
          >
            <View style={styles.userBadges}>
              <UserBadges userId={userId} />
            </View>
            {Platform.OS !== 'web' && <ShareButton />}

            {premiumSubscriptionEnabled ? (
              <NavigationItem
                title={
                  Resources.strings['account-screen-membership-item-title']
                }
                titleStyle={styles.purchaseStatusTitle}
                ValueComponent={
                  <Text style={styles.activationStatus}>
                    {
                      Resources.strings[
                        subscriptionIsActive
                          ? 'account-screen-status-activated'
                          : 'account-screen-status-deactivated'
                      ]
                    }
                  </Text>
                }
                disabled={subscriptionIsActive}
                onPress={() => BalanceRefiller.subscribe(PAYMENT_REASON.MANUAL)}
              />
            ) : (
              <View style={styles.aboutMeContainer}>
                <UserAboutMeText userId={userId} style={styles.aboutMe} />
              </View>
            )}
            {callsPackEnabled && Platform.OS !== 'web' && (
              <NavigationItem
                title={
                  Resources.strings['account-screen-calls-pack-item-title']
                }
                titleStyle={styles.purchaseStatusTitle}
                ValueComponent={
                  <Text style={styles.activationStatus}>
                    {
                      Resources.strings[
                        callsPackIsActive
                          ? 'account-screen-status-activated'
                          : 'account-screen-status-deactivated'
                      ]
                    }
                  </Text>
                }
                disabled={callsPackIsActive}
                onPress={() =>
                  BalanceRefiller.purchaseCallsPack(
                    PAYMENT_REASON.MANUAL,
                    () => {},
                    () => {},
                    { skipPremium: true },
                  )
                }
              />
            )}
            {!!creditsEnabledForFeatures?.length && (
              <NavigationItem
                title={
                  <CurrencyBasedText
                    strings={{ credits: Resources.strings.diamonds }}
                  />
                }
                onPress={this.handlePurchasePress}
                ValueComponent={this.renderBalance}
                titleStyle={styles.title}
                iconStyle={styles.icon}
                pinStyle={styles.pin}
                titleContainerStyle={styles.itemTitleContainer}
              />
            )}
            <HideUserFromFeedSwitchItem
              titleStyle={styles.visibilitySwitcher}
              userId={userId}
            />
          </IfConfigValue>
          <IfConfigValue path="features.boost-profile-enabled" equalsTo={true}>
            {user?.gender === Gender.Male && (
              <PurchasePromotionBanner
                hidePremiumSubscriptionBanner
                userId={userId}
                style={styles.getToTop}
                placeholder={
                  <BoostProfileBanner id={userId} style={styles.getToTop} />
                }
              />
            )}
          </IfConfigValue>
          <IfConfigValue
            path="features.authentication-reminding-enabled"
            equalsTo={true}
          >
            <AuthenticateNavigationItem id={userId} />
          </IfConfigValue>
          <IfConfigValue
            path="features.combined-payment-form-enabled"
            equalsTo={false}
          >
            <IfConfigValue path="features.membership-enabled" equalsTo={true}>
              <Can do="create" of="purchase">
                {status => (
                  <NavigationItem
                    title={
                      Resources.strings['account-screen-membership-item-title']
                    }
                    disabled={!membershipIsInactive}
                    onPress={this.onSubscribePressed(status)}
                    ValueComponent={this.renderMembershipStatus}
                    icon={Resources.images.subscribeIcon()}
                    pinVisible={membershipIsInactive}
                    titleStyle={styles.title}
                    iconStyle={styles.icon}
                    pinStyle={styles.pin}
                    titleContainerStyle={styles.itemTitleContainer}
                  />
                )}
              </Can>
            </IfConfigValue>
            <NavigationItem
              title={
                <CurrencyBasedText
                  strings={{ coins: 'Coins', credits: 'Credits' }}
                />
              }
              onPress={this.handlePurchasePress}
              ValueComponent={this.renderBalance}
              icon={Resources.images.balanceIcon()}
              titleStyle={styles.title}
              iconStyle={styles.icon}
              pinStyle={styles.pin}
              titleContainerStyle={styles.itemTitleContainer}
            />
          </IfConfigValue>
          <IfConfigValue
            path="features.push-notifications-settings-enabled"
            equalsTo={true}
          >
            <NavigationItem
              title={
                Resources.strings[
                  'account-screen-notification-settings-item-title'
                ]
              }
              onPress={this.onPushSettingsPress}
              ValueComponent={pushStatusView}
              titleStyle={styles.title}
              iconStyle={styles.icon}
              pinStyle={styles.pin}
              icon={Resources.images.notificationsIcon()}
              titleContainerStyle={styles.itemTitleContainer}
            />
          </IfConfigValue>
          <IfConfigValue
            path="features.account-settings-enabled"
            equalsTo={false}
          >
            <RestorePurchasesNavigationItem
              icon={Resources.images.restorePurchasesIcon()}
              iconStyle={styles.icon}
              titleStyle={styles.title}
              titleContainerStyle={styles.itemTitleContainer}
            />
          </IfConfigValue>
          <IfConfigValue
            path="features.account-settings-enabled"
            equalsTo={false}
          >
            <CustomerSupportNavigationItem
              iconVisible
              iconStyle={styles.icon}
              titleStyle={styles.title}
              titleContainerStyle={styles.itemTitleContainer}
            />
          </IfConfigValue>
          <IfConfigValue path="features.travels-enabled" equalsTo={true}>
            <NavigationItem
              title={
                Resources.strings[
                  'account-screen-travel-destination-item-title'
                ]
              }
              onPress={this.editTravels}
              {...testId('Travel destination button')}
              titleStyle={styles.title}
              icon={Resources.images.locationArrowIcon()}
              iconStyle={styles.icon}
              pinStyle={styles.pin}
              titleContainerStyle={styles.itemTitleContainer}
            />
          </IfConfigValue>
          {!!appVersion && <Text>{appVersion}</Text>}
        </ScrollView>
      </View>
    );
  }
}
