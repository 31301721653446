import React, { PureComponent } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
// @ts-ignore
import { UserPhotoAliased } from 'dating-mobile/src/user/common/views/user-thumbnail-aliased';
// @ts-ignore
import { PHOTO_TRANSFORMATION_TYPE } from 'dating-mobile/src/components/photo';
// @ts-ignore
import testId from 'dating-mobile/src/utils/test-id';

import { ViewProps } from './types';
import styles from './styles';

export default class ProfilePreview extends PureComponent<ViewProps> {
  static displayName = 'dialogs.messages.views.profile-preview.view';

  render() {
    const { userId, user, onPress } = this.props;

    return (
      <View style={styles.container}>
        <TouchableOpacity onPress={onPress}>
          <View style={styles.thumbnailWrapper}>
            <UserPhotoAliased
              round
              forceRetina
              userId={userId}
              placeholder="avatar"
              style={styles.thumbnail}
              transformation={PHOTO_TRANSFORMATION_TYPE.DETECT_FACE}
              basename={user['thumbnail-pending'] || user.thumbnail}
              {...testId('Chat attendee thumbnail')}
            />
          </View>
        </TouchableOpacity>
        <View style={styles.userDetails}>
          <Text numberOfLines={2} style={styles.userName}>
            {user.name}
          </Text>
          <Text numberOfLines={4} style={styles.userAbout}>
            {user.about}
          </Text>
        </View>
      </View>
    );
  }
}
