import React from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import UserThumbnail from 'dating-mobile/src/user/common/views/thumbnail';
import styles from './styles';

export default class StreamsPlaceholder extends React.PureComponent {
  static displayName = 'streaming.views.streams-placeholder.view';

  static propTypes = {
    userId: PropTypes.string,
  };

  render() {
    return (
      <View style={styles.container}>
        <UserThumbnail
          style={styles.background}
          userId={this.props.userId}
          size=".50x50"
          blurRadius={4}
          fallback
        />
      </View>
    );
  }
}
