import merge from 'merge/merge';
import commonConfig from './config/config.common';
import commonTheme from './theme/theme.common';
import AppConfig from '../native-modules/app-config';

const FLAVOR = 'secretly';

const ConfigBuilder = Object.freeze({
  config: Object.freeze(merge.recursive(true, commonConfig, AppConfig)),

  theme: Object.freeze(merge.recursive(true, commonTheme)),

  flavor: FLAVOR,

  select: object => {
    return object[FLAVOR];
  },
});

export default ConfigBuilder;
