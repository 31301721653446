import guid from '@sdv/commons/utils/guid';
import * as events from 'events';
import * as mediasoupClient from 'mediasoup-client';
import { NativeModules, Platform } from 'react-native';
import {
  MediaStream,
  MediaStreamTrack,
  mediaDevices,
} from 'react-native-webrtc';

const ICE_SERVERS = [
  { urls: ['stun:stun.l.google.com:19302'] },
  {
    urls: ['turn:turn1.flirtwith.com:3478'],
    username: 'testvideo',
    credential: 'test3pta',
  },
];

class Room extends events.EventEmitter {
  static streamingRoomsCounter = 0;

  constructor(id, transport) {
    super();

    this.id = id;
    this.transport = transport;
    this.session = `${guid()}`;
    this.joinded = 0;
    this.recvTransport = null;
    this.sendTransport = null;
    this.audioProducer = null;
    this.videoProducer = null;
    this.localStream = null;
    this.remoteStream = null;
    this.tracks = {};
    this.joinQueue = [];
    this.front = true;
    this.attached = null;
  }

  updateRoomTitle = title => {
    this.transport.setRoomTitle(this.id, title);
  };

  updateRoomCover = cover => {
    this.transport.setRoomCover(this.id, cover);
  };

  updateRoomTopics = topics => {
    this.transport.setRoomTopics(this.id, topics);
  };

  async join(callback) {
    callback = callback || function() {};

    if (!mediasoupClient.isDeviceSupported()) {
      return callback(new Error('Device is not supported'));
    }

    if (Platform.OS === 'ios') {
      NativeModules.WebRTCModule.activatePlayAndRecondAudioConfiguration();
    }
    if (this.joinded) {
      this.joinded++;

      return this.joinQueue.length
        ? this.joinQueue.push(callback)
        : callback({ message: 'success' });
    }

    this.joinded++;
    this.joinQueue.push(callback);

    this.room = new mediasoupClient.Room({
      transportOptions: {
        tcp: false,
      },
      turnServers: ICE_SERVERS,
    });

    this.room.on('newpeer', this.handlePeer);

    this.room.on('request', (request, success, error) => {
      this.transport.sendMediasoupRequest(
        this.id,
        this.session,
        request,
        success,
        error,
      );
    });

    this.room.on('notify', notification => {
      this.transport.sendMediasoupNotification(
        this.id,
        this.session,
        notification,
      );
    });

    this.transport.getStream(this.id);

    this.transport.realtime.addListener(
      'event.dialogs.streams.notification',
      this.handleNotification,
    );
    this.transport.realtime.addListener(
      'document.dialogs.streams.stream',
      this.updateStream,
    );
    this.transport.realtime.addListener(
      'event.dialogs.streams.updated',
      this.updateStream,
    );
    this.transport.realtime.addListener(
      'event.dialogs.streams.attachment.pending.added',
      this.addIncomingAttachment,
    );
    this.transport.realtime.addListener(
      'event.dialogs.streams.attachment.pending.removed',
      this.pendingRequestRemoved,
    );

    try {
      const peers = await this.room.join(this.session);
      // Create the Transport for receiving media from remote Peers.
      this.recvTransport = this.room.createTransport('recv');
      this.sendTransport = this.room.createTransport('send');

      // Handle Peers already in to the Room.
      peers.forEach(this.handlePeer);

      this.joinQueue = this.joinQueue.reduce((queue, callback) => {
        callback({ message: 'success' });
        return queue;
      }, []);
    } catch (error) {
      this.joinded = 0;
      this.joinQueue = this.joinQueue.reduce((queue, callback) => {
        callback(error);
        return queue;
      }, []);
    }
  }

  async startStreamPreview() {
    const constraints = { audio: true };

    constraints.video = {
      facingMode: this.front ? 'user' : 'environment',
      mandatory: {
        minWidth: 1280, // Hack: WebRtc getUserMedia function throws an error when called second time.
        minHeight: 720, // Set up default constraints explicitly fixes this bug.
        // Constraints are added by WebRTC module itself when this fields are empty.
      },
    };

    try {
      const stream = await mediaDevices.getUserMedia(constraints);

      if (this.localStream) {
        this.localStream
          .getVideoTracks()
          .forEach(track => this.localStream.removeTrack(track));

        stream
          .getVideoTracks()
          .forEach(track => this.localStream.addTrack(track));
      } else {
        this.localStream = stream;
      }
      this.emit('event.stream.local.changed', stream);
    } catch (e) {
      console.log(e);
    }
  }

  startLiveStream() {
    if (this.localStream) {
      if (this.localStream.getAudioTracks()[0]) {
        this.audioProducer = this.room.createProducer(
          this.localStream.getAudioTracks()[0],
        );
        this.audioProducer.send(this.sendTransport);
      }

      if (this.localStream.getVideoTracks()[0]) {
        this.videoProducer = this.room.createProducer(
          this.localStream.getVideoTracks()[0],
        );
        this.videoProducer.send(this.sendTransport);
      }
      Room.streamingRoomsCounter++;
    }
  }

  async startStream() {
    const constraints = { video: true, audio: true };

    constraints.video = {
      facingMode: this.front ? 'user' : 'environment',
      mandatory: {
        minWidth: 1280, // Hack: WebRtc getUserMedia function throws an error when called second time.
        minHeight: 720, // Set up default constraints explicitly fixes this bug.
        // Constraints are added by WebRTC module itself when this fields are empty.
      },
    };

    try {
      const stream = await mediaDevices.getUserMedia(constraints);

      if (this.localStream) {
        this.localStream
          .getVideoTracks()
          .forEach(track => this.localStream.removeTrack(track));

        stream
          .getVideoTracks()
          .forEach(track => this.localStream.addTrack(track));
      } else {
        this.localStream = stream;
      }

      if (stream.getAudioTracks()[0]) {
        this.audioProducer = this.room.createProducer(
          stream.getAudioTracks()[0],
        );
        this.audioProducer.send(this.sendTransport);
      }

      if (stream.getVideoTracks()[0]) {
        this.videoProducer = this.room.createProducer(
          stream.getVideoTracks()[0],
        );
        this.videoProducer.send(this.sendTransport);
      }
      Room.streamingRoomsCounter++;
      this.emit('event.stream.local.changed', stream);
    } catch (e) {
      console.log(e);
    }
  }

  stopProducers() {
    this.videoProducer && this.videoProducer.close();
    this.audioProducer && this.audioProducer.close();
    this.videoProducer = null;
    this.audioProducer = null;
  }

  stopStream() {
    this.stopProducers();
    this.localStream &&
      this.localStream.getTracks().forEach(track => track.stop());
    this.localStream = null;
    this.remoteStream = null;
    this.tracks = {};
    Room.streamingRoomsCounter--;
    this.leave();
  }

  switchCam() {
    this.front = !this.front;

    if (!this.localStream) {
      return;
    }

    this.localStream.getVideoTracks().forEach(track => {
      track._switchCamera();
    });
  }

  toggleAudio(mute) {
    if (!this.audioProducer) {
      return;
    }

    mute ? this.audioProducer.pause() : this.audioProducer.resume();
  }

  setMuteState(mute) {
    if (!this.remoteStream) {
      return;
    }

    this.remoteStream.getAudioTracks().forEach(track => {
      track.setVolume(mute ? 0 : 1);
    });
  }

  pause() {
    if (this.audioProducer) {
      this.audioProducer.pause();
    }
    if (this.videoProducer) {
      this.videoProducer.pause();
    }
    const consumers = this.getConsumers();
    consumers.forEach(consumer => consumer.pause());
  }

  resume() {
    if (this.audioProducer) {
      this.audioProducer.resume();
    }
    if (this.videoProducer) {
      this.videoProducer.resume();
    }
    const consumers = this.getConsumers();
    consumers.forEach(consumer => {
      if (consumer) {
        consumer.resume();
      }
    });
  }

  getConsumers = () => {
    if (!this.room) return [];
    const peers = this.room.peers;

    let consumers = [];
    peers.forEach(peer => {
      const data = peer.consumers;
      if (Array.isArray(data) && data.length) {
        consumers = [...consumers, ...data];
      }
    });
    return consumers;
  };

  leave() {
    this.joinded--;

    if (this.joinded <= 0) {
      this.joinded = 0;
      this.localStream && this.stopStream();
      this.room && this.room.leave();
      this.room = null;
      this.transport.realtime.removeListener(
        'event.dialogs.streams.updated',
        this.updateStream,
      );
      this.transport.realtime.removeListener(
        'document.dialogs.streams.stream',
        this.updateStream,
      );
      this.transport.realtime.removeListener(
        'event.dialogs.streams.notification',
        this.handleNotification,
      );
      this.transport.realtime.removeListener(
        'event.dialogs.streams.attachment.pending.added',
        this.addIncomingAttachment,
      );
      this.transport.realtime.removeListener(
        'event.dialogs.streams.attachment.pending.removed',
        this.pendingRequestRemoved,
      );
      this.emit('event.liveroom.left');
    }
  }

  attachCoStreamer(coStreamerRoomId) {
    this.transport.attachCoStreamer(this.id, coStreamerRoomId);
  }

  detachCoStreamer() {
    this.transport.detachCoStreamer(this.id, this.attached);
  }

  declineCoStreamer(id) {
    this.transport.detachCoStreamer(this.id, id);
  }

  handleNotification = (incomingSession, notification) => {
    this.session === incomingSession &&
      this.room &&
      this.room.receiveNotification(notification);
  };

  updateStream = payload => {
    if (payload.id === this.id) {
      if (payload.attached && payload.attached !== this.attached) {
        this.attached = payload.attached;
        this.emit('event.costreamer.added', this.attached);
      }

      if (this.attached && !payload.attached) {
        this.attached = null;
        this.emit('event.costreamer.removed');
      }
    }
  };

  addIncomingAttachment = data => {
    if (String(data.payload.attached) === String(this.id)) {
      this.emit('event.costreamer.invitation.added', data.payload.room);
    }
  };

  pendingRequestRemoved = data => {
    if (String(data.payload.room) === String(this.id)) {
      this.emit('event.costreamer.invitation.removed', data.payload.attached);
    }
  };

  handlePeer = peer => {
    peer.consumers.forEach(this.handleConsumer);
    peer.on('newconsumer', this.handleConsumer);
  };

  handleConsumer = async consumer => {
    try {
      const track = await consumer.receive(this.recvTransport);
      this.remoteStream = new MediaStream();

      if (
        track.kind === 'audio' &&
        Platform.OS === 'ios' &&
        Room.streamingRoomsCounter === 0
      ) {
        NativeModules.WebRTCModule.activatePlaybackAudioConfiguration();
      }

      const mediaStreamTrack = new MediaStreamTrack(track);
      mediaStreamTrack.streamReactTag = track.streamReactTag;
      this.tracks[track.kind] = mediaStreamTrack;

      if (track.kind === 'video') {
        this.remoteStream._reactTag = track.streamReactTag;
      }

      Object.keys(this.tracks).forEach(kind => {
        this.remoteStream.addTrack(this.tracks[kind]);
      });

      this.emit('event.stream.remote.changed', this.remoteStream);
    } catch (error) {
      console.log(error);
    }
  };
}

export default Room;
