import React from 'react';
import PropTypes from 'prop-types';
import { View, StatusBar, Text, ScrollView } from 'react-native';
import { SafeAreaView } from 'react-navigation';
import Button from '../../../components/buttons/base';
import PhotoUploading from '../../views/multi-photo-uploading';
import styles from './styles';
import equal from 'fast-deep-equal';
import testId from '../../../utils/test-id';

export default class ScreenView extends React.Component {
    static displayName = 'user.upload-private-photos.view';
    static propTypes = {
        header: PropTypes.string,
        description: PropTypes.string,
        photos: PropTypes.arrayOf(PropTypes.string),
        canAddPhoto: PropTypes.bool,
        isUploading: PropTypes.bool,
        progress: PropTypes.number,
        grabButtonTitle: PropTypes.string,
        grabButtonDisabled: PropTypes.bool,
        onGrabButtonPress: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.photos = (props.photos || []).slice().reverse();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.photos !== nextProps.photos) {
            this.photos = (nextProps.photos || []).slice().reverse();
        }
    }

    shouldComponentUpdate(nextProps) {
        return this.props.header !== nextProps.header ||
            this.props.description !== nextProps.description ||
            !equal(this.props.photos, nextProps.photos) ||
            this.props.canAddPhoto !== nextProps.canAddPhoto ||
            this.props.isUploading !== nextProps.isUploading ||
            this.props.progress !== nextProps.progress ||
            this.props.grabButtonTitle !== nextProps.grabButtonTitle ||
            this.props.grabButtonDisabled !== nextProps.grabButtonDisabled;
    }

    onPhotoSelected = (...args) => {
        this.props.onPhotoSelect && this.props.onPhotoSelect(...args);
    };

    onPhotoDeleted = (...args) => {
        this.props.onPhotoDelete && this.props.onPhotoDelete(...args);
    };

    onGrabButtonPressed = () => {
        this.props.onGrabButtonPress && this.props.onGrabButtonPress();
    };

    render() {
        return (
            <SafeAreaView forceInset={styles.$safeAreaInset} style={styles.container}>
                <ScrollView
                    horizontal={false}
                    style={styles.scroll}
                    contentContainerStyle={styles.scrollContent}
                    alwaysBounceVertical={false}
                    keyboardShouldPersistTaps={'handled'}
                >
                    <View style={styles.informationContainer}>
                        <Text style={styles.informationHeader} {...testId('Upload private photos screen title')}>{this.props.header || ''}</Text>
                        <Text style={styles.informationDescription} {...testId('Upload private photos screen description')}>{this.props.description || ''}</Text>
                    </View>
                    <View style={styles.photosContainer}>
                        {
                            this.props.id && (
                                <PhotoUploading
                                    photos={this.photos}
                                    canAddPhoto={this.props.canAddPhoto}
                                    isUploading={this.props.isUploading}
                                    progress={this.props.progress}
                                    onPhotoSelect={this.onPhotoSelected}
                                    onPhotoDelete={this.onPhotoDeleted}
                                />
                            )
                        }
                    </View>
                    <View style={styles.grabCoinsButtonContainer}>
                        <Button
                            title={this.props.grabButtonTitle}
                            disabled={this.props.grabButtonDisabled}
                            onPress={this.onGrabButtonPressed}
                            {...testId('Grab coins button')}
                        />
                    </View>
                </ScrollView>
            </SafeAreaView>
        );
    }
}
