function createStreamActions(id) {
  class StreamActions {
    join = () => (dispatch, flux) => {
      flux.mediasoupClient?.join(id);
      dispatch(null);
    };

    startPreview = streamId => (dispatch, flux) => {
      flux.mediasoupClient?.startBroadcastPreview(streamId);
      dispatch(streamId);
    };

    broadcast = guestId => (dispatch, flux) => {
      flux.mediasoupClient?.broadcast(id);
      dispatch(guestId);
    };

    stopBroadcast = () => (dispatch, flux) => {
      flux.mediasoupClient?.stopBroadcast(id);
      dispatch(null);
    };

    leave = () => (dispatch, flux) => {
      flux.mediasoupClient?.leave(id);
      dispatch(null);
    };

    muteAudio = state => (dispatch, flux) => {
      flux.mediasoupClient?.muteAudio(id, state);
    };

    muteMicrophone = state => (dispatch, flux) => {
      flux.mediasoupClient?.muteMicrophone(id, state);
    };

    rotateCamera = () => (dispatch, flux) => {
      flux.mediasoupClient?.rotateCamera(id);
    };

    updateStream = (title, cover, topics) => (dispatch, flux) => {
      flux.mediasoupClient?.updateStream(id, title, cover, topics);
      dispatch({ title, cover, topics });
    };

    update = payload => dispatch => {
      dispatch(payload);
    };

    pause = () => (dispatch, flux) => {
      flux.mediasoupClient?.pauseStream(id);
    };

    resume = () => (dispatch, flux) => {
      flux.mediasoupClient?.resumeStream(id);
    };

    sendStreamAttachmentRequest = guestId => (dispatch, flux) => {
      flux.mediasoupClient?.sendAttachStreamRequest(id, guestId);
      dispatch(guestId);
    };

    accept = guestId => (dispatch, flux) => {
      flux.mediasoupClient?.sendAttachStreamRequest(id, guestId);
      dispatch(guestId);
    };

    decline = guestId => (dispatch, flux) => {
      flux.mediasoupClient?.declineCostream(id, guestId);
      dispatch(guestId);
    };
  }

  StreamActions.displayName = createStreamActions.getDisplayName(id);

  return StreamActions;
}

createStreamActions.getDisplayName = function(id) {
  return `dialogs-stream.${id}`;
};

export default createStreamActions;
