import MessagesModel, { getId } from '@sdv/domain/dialogs.messages';
import guid from '@sdv/commons/utils/guid';
import { LETTER_DELIVERY_STATUS } from '@sdv/domain/dialogs.mailbox/outbox/letter-delivery-status';
import LetterWritingModel from '../../letter-writing';
import qs from "qs";

export default function decorateActions(actionsCreator) {

    function createActions(id) {

        const senderId = id;

        class Actions extends actionsCreator(id) {
            sendLetter(letter) {
                let baseSendLetter = super.sendLetter;

                return (dispatch, flux) => {
                    const modelId = qs.stringify({ senderId: senderId, recipientId: letter.recipientId, letterTag: letter.tag });
                    const letterWritingModel = flux.get(LetterWritingModel, modelId);
                    const letterState = letterWritingModel.store.getState();

                    const composedLetter = {
                        tag: letter.tag,
                        sender: senderId,
                        recipient: letter.recipientId,
                        text: letterState.text,
                        cover: letterState.cover,
                        subject: letterState.subject,
                        attachments: letterState.attachments
                    };
                    const messagesModel = flux.get(MessagesModel, getId(composedLetter.sender, composedLetter.recipient));
                    const messages = messagesModel.store.getState().messages;
                    const message = messages.find(message => message.tag === composedLetter.tag);
                    if (!message) {
                        messagesModel.actions.patch({
                            tag: composedLetter.tag,
                            sender: composedLetter.sender,
                            recipient: composedLetter.recipient,
                            text: composedLetter.text,
                            timestamp: (new Date()).getTime(),
                            meta: {
                                letter: `${guid()}`,
                                cover: composedLetter.cover,
                                introductory: composedLetter.introductory,
                                attachments: composedLetter.attachments
                            }
                        });
                    }
                    baseSendLetter(letter)(
                        (letter, err) => {
                            if (letter.status === LETTER_DELIVERY_STATUS.FAILED) {
                                messagesModel.actions.delete(letter.tag);
                            }
                            dispatch(letter, err);
                        },
                        flux
                    );
                };
            };
        }

        return Actions;
    }

    return createActions;
};
