import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import withConfigValue from 'dating-mobile/src/components/config-value';
import Resources from 'dating-mobile/src/resources';
import BenefitItem from './item';
import styles from './styles';

class MembershipBenefits extends React.Component {
  static displayName = 'payment.membership-benefits';

  static propTypes = {
    unlimitedMessagesWithMembershipEnabled: PropTypes.bool,
    privatePhotosAccessibleWithMembershipEnabled: PropTypes.bool,
    advancedSearchOnlyWithMembershipEnabled: PropTypes.bool,
    devaluationRatio: PropTypes.number,
    currency: PropTypes.string,
    freeCreditsAmount: PropTypes.number,
    discountWithMembershipEnabled: PropTypes.bool,
    travelDestinationSearchOnlyWithMembershipEnabled: PropTypes.bool,
    housekeepingEnabled: PropTypes.bool,
    vipEnabled: PropTypes.bool,
    searchOnlyWithMembershipEnabled: PropTypes.bool,
    unlimitedLetsMingleWithMembership: PropTypes.bool,
  };

  renderItems() {
    const {
      freeCreditsAmount,
      currency,
      devaluationRatio,
      discountWithMembershipEnabled,
      unlimitedLetsMingleWithMembership,
      advancedSearchOnlyWithMembershipEnabled,
      unlimitedMessagesWithMembershipEnabled,
      privatePhotosAccessibleWithMembershipEnabled,
      travelDestinationSearchOnlyWithMembershipEnabled,
      searchOnlyWithMembershipEnabled,
      housekeepingEnabled,
      vipEnabled,
    } = this.props;

    const items = [];

    if (freeCreditsAmount) {
      const format =
        currency &&
        {
          credits:
            Resources.strings[
              'memberships-screen-free-credits-benefit-description-format'
            ],
          coins:
            Resources.strings[
              'memberships-screen-free-coins-benefit-description-format'
            ],
        }[currency];
      const title =
        format &&
        devaluationRatio &&
        freeCreditsAmount &&
        Resources.strings.formatString(
          format,
          freeCreditsAmount * devaluationRatio,
        );

      items.push(
        <BenefitItem
          key="free-credits"
          title={title}
          icon={Resources.images.coinIcon()}
          iconStyle={styles.iconWithoutTint}
        />,
      );
    }

    if (discountWithMembershipEnabled) {
      items.push(
        <BenefitItem
          key="optional-membership-discount"
          title={
            Resources.strings[
              'memberships-screen-having-membership-benefit-description'
            ]
          }
          iconContainerStyle={styles.highlightedIcon}
          titleStyle={styles.highlightedTitle}
        />,
      );
    }

    if (unlimitedLetsMingleWithMembership) {
      items.push(
        <BenefitItem
          key="unlimited-mingle"
          title={
            Resources.strings['membership-screen-unlimited-mingle-description']
          }
        />,
      );
    }

    if (advancedSearchOnlyWithMembershipEnabled) {
      items.push(
        <BenefitItem
          key="advanced-search"
          title={
            Resources.strings[
              'memberships-screen-advanced-search-benefit-description'
            ]
          }
          icon={Resources.images.filterIcon()}
        />,
      );
    }

    if (privatePhotosAccessibleWithMembershipEnabled) {
      items.push(
        <BenefitItem
          key="private-photos"
          title={
            Resources.strings[
              'memberships-screen-private-photos-benefit-description'
            ]
          }
          icon={Resources.images.photoIcon()}
        />,
      );
    }

    if (travelDestinationSearchOnlyWithMembershipEnabled) {
      items.push(
        <BenefitItem
          key="travel-destination-search-only-with-membership-enabled"
          title={
            Resources.strings[
              'memberships-screen-travel-destination-search-benefit-description'
            ]
          }
        />,
      );
    }

    if (housekeepingEnabled) {
      items.push(
        <BenefitItem
          key="housekeeping-enabled"
          title={
            Resources.strings[
              'memberships-screen-housekeeping-benefit-description'
            ]
          }
        />,
      );
    }

    if (searchOnlyWithMembershipEnabled) {
      items.push(
        <BenefitItem
          key="filtering"
          title={
            Resources.strings['memberships-screen-filter-benefit-description']
          }
          icon={Resources.images.filterIcon()}
        />,
      );
    }

    if (unlimitedMessagesWithMembershipEnabled) {
      items.push(
        <BenefitItem
          key="unlimited-messages"
          title={
            Resources.strings[
              'memberships-screen-unlimited-messages-benefit-description'
            ]
          }
          icon={Resources.images.newChatsIcon()}
          iconStyle={styles.iconWithoutTint}
        />,
      );
    }

    if (vipEnabled) {
      items.push(
        <BenefitItem
          key="special-badge"
          title={
            Resources.strings[
              'memberships-screen-special-badge-benefit-description'
            ]
          }
          icon={Resources.images.cupIcon()}
        />,
        <BenefitItem
          key="more-profiles-views"
          title={
            Resources.strings[
              'memberships-screen-more-profiles-views-benefit-description'
            ]
          }
          icon={Resources.images.eyeIcon()}
        />,
      );
    }

    return items;
  }

  render() {
    return <View style={styles.container}>{this.renderItems()}</View>;
  }
}

export default withConfigValue(MembershipBenefits, {
  freeCreditsAmount: 'membership.free-credits-amount',
  currency: 'currency.type',
  devaluationRatio: 'currency.devaluation-ratio',
  unlimitedMessagesWithMembershipEnabled:
    'features.unlimited-messages-with-membership-enabled',
  privatePhotosAccessibleWithMembershipEnabled:
    'features.private-photos-accessible-with-membership-enabled',
  advancedSearchOnlyWithMembershipEnabled:
    'features.advanced-search-only-with-membership-enabled',
  discountWithMembershipEnabled: 'features.discount-with-membership-enabled',
  travelDestinationSearchOnlyWithMembershipEnabled:
    'features.travel-destination-search-only-with-membership-enabled',
  housekeepingEnabled: 'features.housekeeping-enabled',
  unlimitedLetsMingleWithMembership:
    'features.unlimited-lets-mingle-with-membership',
  vipEnabled: 'features.vip-enabled',
  searchOnlyWithMembershipEnabled:
    'features.search-only-with-membership-enabled',
});
