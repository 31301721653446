import React from 'react';
import PropTypes from 'prop-types';
import Resources from 'dating-mobile/src/resources';
import NavigationItem from 'dating-mobile/src/components/navigation-item';
import styles from './styles';
import Can from 'dating-mobile/src/models/user.ability/controller';
import { ActivityIndicator, Platform } from "react-native";

export default class RestorePurchasesNavigationItem extends React.PureComponent {
    static displayName = 'user.views.restore-purchases-navigation-item.view';
    static propTypes = {
        restorePurchasesEnabled: PropTypes.bool,
        pinIcon: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.object,
            PropTypes.array
        ]),
        icon: PropTypes.object,
        pinVisible: PropTypes.bool,
        onItemPress: PropTypes.func,
        isIndicatorVisible: PropTypes.bool
    };

    itemPressed = (status) => () => {
        this.props.onItemPress && this.props.onItemPress(status);
    };

    renderPurchasesRestoringStatus = () => {
        return (
            <ActivityIndicator
                style={styles.activityIndicator}
                animating={!!this.props.isIndicatorVisible}
            />
        )
    };

    render() {
        return (
            <Can do={'restore'} of={'purchase'}>
                {
                    status => (Platform.OS === 'ios' && this.props.restorePurchasesEnabled) && (
                        <NavigationItem
                            title={Resources.strings['account-screen-restore-purchases-item-title']}
                            onPress={this.itemPressed(status)}
                            pinIcon={this.props.pinIcon}
                            pinVisible={this.props.pinVisible}
                            icon={this.props.icon}
                            ValueComponent={this.renderPurchasesRestoringStatus}
                            {...this.props}
                        />
                    )
                }
            </Can>
        );
    }

}
