import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet } from 'react-native';
import Resources from 'dating-mobile/src/resources';
import Can from 'dating-mobile/src/models/user.ability/controller';
import testId from 'dating-mobile/src/utils/test-id';
import * as ROUTES from 'dating-mobile/src/routing/router/constants';

import ImageButton from '../../../components/navigation/views/image-button';
import TextButton from '../../../components/navigation/views/text-button';
import { FILTER_TYPES } from '../../utils/filters';
import { pureController as Controller } from '../filter/controller';

import View from './view';
import styles from './styles';

const ScreenView = Controller(View);

class Screen extends React.Component {
  static displayName = 'feed.filter.screen';

  static propTypes = {
    navigation: PropTypes.object.isRequired,
  };

  static navigationOptions = ({ navigation }) => ({
    title: Resources.strings['advanced-filters-screen-title'],
    headerStyle: styles.header,
    headerTitleStyle: styles.headerTitle,
    headerLeft: () => (
      <ImageButton
        image={Resources.images.backIcon()}
        onPress={() => {
          const onCloseButtonPress = navigation.getParam('onCloseButtonPress');

          if (onCloseButtonPress) {
            onCloseButtonPress();
          }
        }}
        tint={StyleSheet.flatten(styles.headerTitle).color}
        {...testId(
          Resources.strings['navigation-bar-back-button-accessibility-label'],
        )}
      />
    ),
    headerRight: () => (
      <TextButton
        title={Resources.strings['filter-screen-reset-button-title']}
        onPress={navigation.getParam('onResetAdvancedFiltersButtonPress')}
        tint={StyleSheet.flatten(styles.headerTitle).color}
      />
    ),
  });

  editFilter = (filterType, props) => {
    const { navigation } = this.props;

    switch (filterType) {
      case FILTER_TYPES.SELECTION:
        navigation.navigate(ROUTES.SELECTION_EDITING, props);
        break;
      case FILTER_TYPES.RANGE:
        navigation.navigate(ROUTES.RANGE_PICKER_EDITING, props);
        break;
      case FILTER_TYPES.LOCATION:
        navigation.navigate(ROUTES.LOCATION_EDITING, props);
        break;
      case FILTER_TYPES.INTERESTS:
        navigation.navigate(ROUTES.INTERESTS_EDITING, props);
        break;
    }
  };

  close = () => {
    const { navigation } = this.props;

    navigation.pop();
  };

  setNavigationBarState = state => {
    const { navigation } = this.props;

    navigation.setParams(state);
  };

  render() {
    const { navigation } = this.props;

    return (
      <Can do="use" of="advanced search">
        {status => (
          <ScreenView
            canUseAdvancedSearch={status}
            setNavigationBarState={this.setNavigationBarState}
            editFilter={this.editFilter}
            close={this.close}
            initialState={navigation.getParam('params')}
            onComplete={navigation.getParam('onComplete')}
          />
        )}
      </Can>
    );
  }
}

export default Screen;
