import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    errorContainer: {
        position: 'absolute',
        top: '50%',
        marginTop: -10,
        right: 5,
    },
    error: {
        width: 20,
        height: 20,
        backgroundColor: '$errorColor',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 10,
    },
    errorIcon: {
        fontSize: 14,
        textAlign: 'center',
        color: '$errorTextColor',
    }
});

export default styles;
