import React, { PureComponent } from 'react';
import { Platform } from 'react-native';
import { withNavigationFocus } from 'react-navigation';
import PropTypes from 'prop-types';
import { Subscription } from 'rxjs';
import witIdentityId from 'dating-mobile/src/models/identity/controller/id';
import flux from '@sdv/domain/app/flux';
import { PartialMatchSearch } from '@sdv/domain/users/partial-match-search';

export default function withController(Component) {
  class Controller extends PureComponent {
    static displayName = 'feed.screens.list.controller';

    static propTypes = {
      id: PropTypes.string,
      isFocused: PropTypes.bool,
      setNavigationBarState: PropTypes.func.isRequired,
      openProfile: PropTypes.func.isRequired,
      openFilter: PropTypes.func.isRequired,
    };

    state = {
      isLoading: true,
      users: [],
      hasError: false,
      partialMatchLength: 0,
    };

    componentDidMount() {
      const { setNavigationBarState } = this.props;

      setTimeout(() => {
        setNavigationBarState({
          onFilterButtonPress: this.onFilterButtonPress,
        });
      }, 1000);

      this.subscribe();
    }

    componentDidUpdate(prevProps) {
      const { id } = this.props;

      if (id !== prevProps.id) {
        this.unsubscribe();
        this.subscribe();
      }
    }

    componentWillUnmount() {
      this.unsubscribe();
    }

    onFilterButtonPress = () => {
      const { openFilter, id } = this.props;

      if (Platform.OS === 'web') {
        flux.api.annals.add(id, 'feed-filter-opened', {
          timestamp: new Date(),
        });
      }

      openFilter();
    };

    onItemPress = id => {
      const { openProfile } = this.props;

      openProfile(id);
    };

    setHeaderHidden = hidden => {
      if (this.navigationBarHidden !== hidden) {
        this.navigationBarHidden = hidden;

        setTimeout(() => {
          this.forceUpdate();

          const { setNavigationBarState } = this.props;

          if (setNavigationBarState) {
            setNavigationBarState({
              navigationBarHidden: hidden,
            });
          }
        }, 0);
      }
    };

    reload = () => {
      if (this.partialSearch) this.partialSearch.getNext();
    };

    subscribe() {
      const { id } = this.props;

      if (!id) {
        return;
      }

      this.partialSearch = PartialMatchSearch.shared(id);
      this.disposeBag = new Subscription();

      const allUsersSubscription = this.partialSearch.allUsers.subscribe(
        users => {
          this.setState({ users });
        },
      );

      const hasErrorSubscription = this.partialSearch.fetchError.subscribe(
        error => {
          this.setState({ hasError: !!error });
        },
      );

      const isLoadingSubscription = this.partialSearch.isLoading.subscribe(
        isLoading => {
          this.setState({ isLoading });
        },
      );

      const partialMatchLengthSubscription = this.partialSearch.partialMatchLength.subscribe(
        partialMatchLength => {
          this.setState({ partialMatchLength });
        },
      );

      this.disposeBag.add(allUsersSubscription);
      this.disposeBag.add(hasErrorSubscription);
      this.disposeBag.add(isLoadingSubscription);
      this.disposeBag.add(partialMatchLengthSubscription);
    }

    unsubscribe() {
      if (this.disposeBag) this.disposeBag.unsubscribe();

      this.partialSearch = null;
      this.disposeBag = null;
    }

    render() {
      const { id, isFocused } = this.props;
      const { users, isLoading, hasError, partialMatchLength } = this.state;

      if (!id) {
        return null;
      }

      return (
        <Component
          id={id}
          isFocused={isFocused}
          showHeader
          showFooter={!isLoading && !hasError && !!users.length}
          users={users}
          reload={this.reload}
          navigationBarHidden={this.navigationBarHidden}
          setHeaderHidden={this.setHeaderHidden}
          onItemPress={this.onItemPress}
          isLoading={isLoading}
          hasError={hasError}
          partialMatchLength={partialMatchLength}
        />
      );
    }
  }

  return withNavigationFocus(witIdentityId(Controller, 'id'));
}
