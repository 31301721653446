import withConfigValue from 'dating-mobile/src/components/config-value';
import withController from './controller';
import withNavigation from '../../controllers/navigation';
import View from './view';

export const SteppedRegistrationPhotoWithBioScreen = withNavigation(
  withConfigValue(withController(View), {
    thumbnailRequired:
      'features.thumbnail-required-during-registration-enabled',
  }),
);
