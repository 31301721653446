const cloneNotNull = function (obj) {
    const result = {};
    for (let name in obj) {
        if (obj[name] !== null && obj[name] !== undefined) {
            result[name] = obj[name]
        }
    }
    return result;
}

export { cloneNotNull };
