import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        width: '100%',
        flexDirection: 'column'
    },

    list: {
        flex: 1
    },

    listContainer: {
        position: 'absolute',
        left: 0,
        top: '100%',
        width: '100%',
        backgroundColor: '$primaryBackgroundColor',
        shadowRadius: 8,
        shadowOpacity: 0.15,
        shadowOffset: { width: 0, height: 0 },
        elevation: 8
    },

    item: {
        paddingHorizontal: 16,
        height: 50,
        flexDirection: 'row',
        alignItems: 'center'
    },

    text: {
        color: '$primaryTextColor',
        fontSize: 16
    }
});

export default styles;
