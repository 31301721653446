import React from 'react';
import { Image, Text, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import Resources from 'dating-mobile/src/resources';
import Thumbnail from 'dating-mobile/src/user/common/views/thumbnail';
import styles from './styles';

export default class FavoriteWithThumbnailButton extends React.PureComponent {
  static displayName = 'user.common.views.favorite-with-thumbnail-button';

  static propTypes = {
    isFavorite: PropTypes.bool,
    onPress: PropTypes.func,
    targetId: PropTypes.string,
  };

  render() {
    const { isFavorite, targetId } = this.props;
    const containerStyle = isFavorite
      ? [styles.container, styles.containerFavorite]
      : styles.container;
    const followIconStyle = isFavorite
      ? [styles.followIcon, styles.followIconFavorite]
      : styles.followIcon;
    const titleKey = isFavorite
      ? 'stream-screen-follow-button-following-title'
      : 'streamStatusFollowButtonTitle';

    return (
      <TouchableOpacity
        onPress={this.props.onPress}
        style={containerStyle}
        disabled={isFavorite}
      >
        <Image
          style={followIconStyle}
          source={
            isFavorite
              ? Resources.images.starFilledIcon()
              : Resources.images.starEmptyIcon()
          }
        />
        <Text style={styles.title}>{Resources.strings[titleKey]}</Text>
        <Thumbnail userId={targetId} style={styles.thumbnail} />
      </TouchableOpacity>
    );
  }
}
