import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({

    $statusBarStyle: '$primaryStatusBarStyle',
    $statusBarBackgroundColor: '$primaryBackgroundColor',
    $safeAreaInsets: {
        top: 'never',
        bottom: 'always'
    },
    $emptySafeAreaInsets: {
        top: 'never',
        bottom: 'never'
    },

    header: {
        backgroundColor: '$primaryBackgroundColor',
        borderBottomColor: '$primaryBackgroundColor',
        shadowColor: '$primaryBackgroundColor'
    },

    headerTitle: {
        color: '$primaryTextColor'
    },

    container: {
        flex: 1,
        backgroundColor: '$primaryBackgroundColor'
    },

    scroll: {
        flexGrow: 1
    },

    scrollContent: {

    },

    partHeaderContainer: {
        backgroundColor: '$secondaryBackgroundColor',
        height: 35,
        flexDirection: 'row',
        alignItems: 'center'
    },

    partHeader: {
        marginLeft: 24,
        fontSize: 16,
        fontFamily: '$brandFontFamily',
        color: '$secondaryTextColor'
    },

    credits: {
        flex: undefined
    },

    memberships: {
        flex: undefined
    }

});

export default styles;
