import Actions from './actions';
import { createOptimisticStore } from '@sdv/model';

function createUserGoalsStore(id) {

    class Store extends createOptimisticStore(['put']) {

        constructor(flux) {
            const actions = flux.getActions(Actions, id);
            super(actions);
            this.bindAction(actions.get, this.update);
        };

        update(payload) {

            if (payload) {
                this.state.data = payload;
                this.setState(this.state);
            }

        }

    }

    Store.displayName = createUserGoalsStore.getDisplayName(id);

    return Store;
}

createUserGoalsStore.getDisplayName = function (id) {
    return `user-goals.${id}`;
};

export default createUserGoalsStore;
