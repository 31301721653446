import React from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import Resources from 'dating-mobile/src/resources';
import Button from 'dating-mobile/src/components/buttons/base';
import testId from 'dating-mobile/src/utils/test-id';
import styles from './styles';

export default class FollowButton extends React.PureComponent {
    static displayName = 'user.common.views.set-favorite-button';

    static propTypes = {
        isFavorite: PropTypes.bool.isRequired,
        onPress: PropTypes.func,
        onLayout: PropTypes.func,
        style: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array]),
    };

    render() {
        const { isFavorite, style, onPress, onLayout } = this.props;

        if (typeof isFavorite === 'undefined') {
            return null;
        }

        if (isFavorite) {
            return <View onLayout={onLayout} />;
        }
        const calculatedStyle = style ? [styles.followButton, style] : styles.followButton;

        return (
            <Button
                title={Resources.strings.streamStatusFollowButtonTitle}
                onPress={onPress}
                {...testId('Follow button')}
                style={calculatedStyle}
                titleStyle={styles.followButtonTitle}
                onLayout={onLayout}
            />
        );
    }
}
