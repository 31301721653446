import React, { PureComponent } from 'react';
import { View, Text } from 'react-native';
import PropTypes from 'prop-types';

import Button from 'dating-mobile/src/components/buttons/base';
import TextInput from 'dating-mobile/src/components/text-input';
import Error from 'dating-mobile/src/components/validation-error';
import Resources from 'dating-mobile/src/resources';
import testId from 'dating-mobile/src/utils/test-id';

import styles from './styles';

export class SetNewPassword extends PureComponent {
    static displayName = 'authentication.views.set-new-password';

    static propTypes = {
        error: PropTypes.object,
        password: PropTypes.string,
        password2: PropTypes.string,
        onSubmit: PropTypes.func.isRequired,
        validate: PropTypes.func.isRequired,
        handlerChange: PropTypes.func.isRequired,
        goToPasswordRecover: PropTypes.func,
        expired: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.validatePassword = props.validate('password');
        this.handlerChangePassword = props.handlerChange('password');
        this.validatePassword2 = props.validate('password2');
        this.handlerChangePassword2 = props.handlerChange('password2');
    }

    focusSecondInput = () => {
        this.secondPasswordInput?.focus();
    };

    onSubmit = () => {
        const { password, password2, onSubmit } = this.props;

        if (password && password2 && onSubmit) {
            onSubmit();
        }
    };

    render() {
        const { password, password2, error, goToPasswordRecover, expired } = this.props;
        const validationError = error.password || error.server;

        if (expired) {
            return (
                <View style={styles.expiredLinkContainer}>
                    <Text style={styles.expiredLinkTitle}>
                        {Resources.strings['set-new-password-expired-link-title']}
                    </Text>
                    <Button
                        onPress={goToPasswordRecover}
                        title={Resources.strings['set-new-password-expired-link-button-title']}
                    />
                </View>
            );
        }

        return (
            <View style={styles.container}>
                <View style={styles.inputs}>
                    <Text style={styles.title} {...testId('Create new password title')}>
                        {Resources.strings['set-new-password-title']}
                    </Text>
                    <View style={styles.inputContainer}>
                        <TextInput
                            style={styles.input}
                            placeholder={Resources.strings['set-new-password-input-placeholder']}
                            value={password}
                            autoCapitalize="none"
                            autoFocus
                            secureTextEntry
                            textContentType="newPassword"
                            returnKeyType="next"
                            onChange={this.handlerChangePassword}
                            onBlur={this.validatePassword}
                            error={validationError}
                            onSubmitEditing={this.focusSecondInput}
                            blurOnSubmit={false}
                            {...testId('Password input')}
                        />
                        <Error error={validationError} />
                    </View>
                    <View style={styles.inputContainer}>
                        <TextInput
                            forwardRef={input => {
                                this.secondPasswordInput = input;
                            }}
                            style={styles.input}
                            placeholder={
                                Resources.strings['set-new-password-input-confirm-placeholder']
                            }
                            value={password2}
                            autoCapitalize="none"
                            secureTextEntry
                            returnKeyType="done"
                            onChange={this.handlerChangePassword2}
                            onBlur={this.validatePassword2}
                            error={error.password2}
                            onSubmitEditing={this.onSubmit}
                            {...testId('Confirm password input')}
                        />
                        <Error error={error.password2} />
                    </View>
                </View>
                <Button
                    disabled={!password || !password2}
                    title={Resources.strings['set-new-password-button-title']}
                    onPress={this.onSubmit}
                    {...testId('Set new password button')}
                />
            </View>
        );
    }
}
