import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $avatarBackgroundColors: [],

    '@flavor secretly': {
        $avatarBackgroundColors: ['#C0D7F1', '#CAE9E4', '#D8EEC2', '#F3D4D6', '#F9E6C7', '#EDE1F8'],
    },
});

export default styles;
