import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, TouchableWithoutFeedback } from 'react-native';
import Resources from 'dating-mobile/src/resources';
import OnlineIndicator from 'dating-mobile/src/user/common/views/online-indicator';
import { UserThumbnailAliased } from 'dating-mobile/src/user/common/views/user-thumbnail-aliased';
import { UserNameAliased } from 'dating-mobile/src/user/common/views/user-name';

import styles from './styles';

class Profile extends React.PureComponent {
  static displayName = 'user.common.views.profile';

  static propTypes = {
    userId: PropTypes.string,
    hideOnlineIndicator: PropTypes.bool,
    isBoosted: PropTypes.bool,
    onThumbnailPress: PropTypes.func,
    usernameStyle: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    thumbnailStyle: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
  };

  render() {
    const {
      userId,
      onThumbnailPress,
      isBoosted,
      hideOnlineIndicator,
      thumbnailStyle,
      usernameStyle,
    } = this.props;

    return (
      <View style={styles.container} pointerEvents="box-none">
        <TouchableWithoutFeedback onPress={onThumbnailPress}>
          <UserThumbnailAliased
            style={[styles.thumbnail, thumbnailStyle]}
            userId={userId}
            round
          />
        </TouchableWithoutFeedback>
        {isBoosted && (
          <View style={styles.boostContainer}>
            <Text style={styles.boostText}>
              {Resources.strings['profile-view-boosted-text']}
            </Text>
          </View>
        )}
        <View style={styles.user}>
          <UserNameAliased
            userId={userId}
            style={[styles.name, usernameStyle]}
            numberOfLines={1}
          />
          {!hideOnlineIndicator && <OnlineIndicator userId={userId} />}
        </View>
        <Text style={styles.userIdText}>
          {Resources.strings.formatString(
            Resources.strings['profile-view-user-id-text'],
            userId,
          )}
        </Text>
      </View>
    );
  }
}

export default Profile;
