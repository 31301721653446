import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $statusBarStyle: '$brandStatusBarStyle',
    $statusBarBackgroundColor: '$brandColor',
    '@flavor secretly': {
        $statusBarStyle: '$primaryStatusBarStyle',
        $statusBarBackgroundColor: '$primaryBackgroundColor'
    },

    container: {
        backgroundColor: '$brandColor',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        '@flavor secretly': {
            backgroundColor: '$primaryBackgroundColor'
        }
    }
});

export default styles;
