import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $hasVideoCameraIcon: false,
    '@flavor dma': {
        $hasVideoCameraIcon: true
    },

    container: {
        flexDirection: 'row',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: '$acceptColor',
        //TODO: implement a better solution
        borderRadius: 10,
        '@flavor lovinga': {
            borderRadius: 24
        },
        '@flavor dma': {
            borderRadius: 20
        },
        '@flavor tubit': {
            borderRadius: 0
        }
    },
    photoContainer: {
        marginLeft: 15,
        marginRight: 11
    },
    photo: {
        width: 60,
        height: 60
    },
    contactIcon: {
        left: 0,
        top: 0,
        width: 15,
        height: 15,
        position: 'absolute'
    },
    textContainer: {
        height: '100%',
        flex: 1,
        justifyContent: 'center',
        paddingTop: 10,
        paddingBottom: 10,
        paddingRight: 15
    },
    username: {
        fontSize: 17,
        fontFamily: '$brandFontFamily',
        fontWeight: '600',
        marginBottom: 2,
        color: 'white',
        '@flavor dma': {
            fontSize: 19,
        }
    },
    message: {
        fontWeight: 'normal',
        fontFamily: '$brandFontFamily',
        color: 'white',
        '@flavor dma': {
            fontSize: 18
        }
    },
    cameraIconStyle: {
        marginRight: 24,
        marginLeft: 10,
    }
});

export default styles;
