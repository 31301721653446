import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

export class PreferencesInterceptor {
    constructor(preferences, userId) {
        this.userId = userId;
        this.preferences = preferences;
        this.preferencesDidSet = new Subject();
    }

    savePreference(key, value) {
        return this.preferences.savePreference(key, value).pipe(
            tap(() => {
                this.preferencesDidSet.next({
                    screen: key,
                    selectedValue: value,
                    userId: this.userId,
                });
            }),
        );
    }
}
