import React from 'react';
import { View, Text, Image, TouchableWithoutFeedback } from 'react-native';
import controller from '../../../../../models/common.messages/controllers/letter-content-controller';
import styles from './style';
import Photo from '../../../../../components/photo';
import { RESOURCE_ACCESS_STATUS } from '../../../../../models/paid-resource/model';
import Resources from '../../../../../resources';
import { DELIVERY_STATUS } from "dating-mobile/src/models/common.messages/model";
import testId from 'dating-mobile/src/utils/test-id';

class LetterContent extends React.PureComponent {

    constructor(props) {
        super(props);
    }

    open = () => {
        this.props.openFunc(this.props.openResource);
    };

    renderTitle = () => {
        if (this.props.message.content.introductory) {
            return (
                <Text style={styles.text}>{Resources.strings['chat-message-cheer-content-free']}</Text>
            );
        }

        if (this.props.status === RESOURCE_ACCESS_STATUS.AVAILABLE) {
            return (
                <Text style={styles.text}>{this.props.message.placeholder}</Text>
            );
        }

        if (this.props.status === RESOURCE_ACCESS_STATUS.PAYMENT_REQUIRED) {
            return (
                <Image source={Resources.images.lockIcon()} />
            );
        }
    };

    render() {
        let containsPhoto = false;
        let containsVideo = false;
        const containerStyle = this.props.message.outgoing ? { flexDirection: 'row-reverse' } : { flexDirection: 'row' };
        const attachmentsLength = this.props.message.content.attachments && this.props.message.content.attachments.length || 0;
        const viewsMargin = attachmentsLength
            ? (this.props.message.outgoing ? { marginRight: 10 } : { marginLeft: 10 })
            : 0;

        const coverStyle = [styles.coverImage, this.props.message.status !== DELIVERY_STATUS.DELIVERED ? styles.unsentLetter : {}];

        for (let i = 0; i < attachmentsLength; i++) {
            const item = this.props.message.content.attachments[i];

            if (item.uri && item.uri.startsWith('photo:')) {
                containsPhoto = true;
            }

            if (item.uri && item.uri.startsWith('video:')) {
                containsVideo = true;
            }

            if (containsPhoto && containsVideo) { break; }
        }

        let title = this.renderTitle();

        return (
            <TouchableWithoutFeedback onPress={this.open} {...testId(Resources.strings['message-letter-accessibility-label'])}>
                <View style={[styles.container, containerStyle]}>
                    <View style={styles.coverContainer}>
                        <Photo
                            style={coverStyle}
                            path={`/dialogs/letters/covers/${this.props.message.content.cover}`}
                        />
                        { title }
                    </View>
                    <View style={[styles.attachmentIconsContainer, viewsMargin]}>
                        {
                            containsPhoto && (
                                <Image
                                    style={styles.photoAttachment}
                                    source={require('./assets/ChatField_Photo.png')}
                                />
                            )
                        }
                        {
                            containsVideo && (
                                <Image source={Resources.images.videoContentIcon}/>
                            )
                        }
                    </View>
                </View>
            </TouchableWithoutFeedback>
        );
    }
}

export default controller(LetterContent);
