import React from 'react';
import { render } from 'dating-mobile/src/components/rx-render';
import { ChatAnonymity } from '@sdv/domain/chat/chat-anonymity';
import { Config } from '@sdv/domain/app/config';
import Session from '@sdv/domain/authorization/session';
import Resources from 'dating-mobile/src/resources';

import { PHOTO_PLACEHOLDER_TYPE } from './constants';

export default function createController(Component) {
  const Controller = render((props, { useObservable, useDistinct }) => {
    let { defaultSource } = props;

    const anonymousChatEnabled = useObservable(
      it => it.anonymousChatEnabled,
      Config.shared(),
    );

    if (
      anonymousChatEnabled &&
      !props.defaultSource &&
      props.userId &&
      props.placeholder === PHOTO_PLACEHOLDER_TYPE.AVATAR
    ) {
      const userId = useObservable(it => it.userId, Session.shared());

      if (userId && userId !== props.userId) {
        const chatAnonymity = useDistinct(
          (...args) => new ChatAnonymity(...args),
          userId,
          props.userId,
        );
        const writingAsAnonymous = useObservable(
          it => it.writingAsAnonymous,
          chatAnonymity,
        );

        if (!writingAsAnonymous) {
          defaultSource = Resources.images.anonymousAvatar();
        }
      }
    }

    return <Component {...props} defaultSource={defaultSource} />;
  });

  Controller.displayName = 'components.photo.controller';

  return Controller;
}
