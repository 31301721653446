import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $statusBarStyle: 'light-content',

    container: {
        flex: 1
    }
});

export default styles;
