import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    photo: {
        width: 74,
        height: 74,
        marginLeft: 12,
        borderRadius: 37,
        '@flavor ytm': {
            borderRadius: 30
        }
    }
});

export default styles;
