import React from 'react';
import { BehaviorSubject, Subscription } from 'rxjs';

/**
 * @deprecated use RxRender instead
 */
export class RxController extends React.Component {

    constructor(props) {
        super(props);
        this.rxProps = new BehaviorSubject(props);
    }

    componentDidUpdate() {
        this.rxProps.next(this.props);
    }

    componentDidMount() {
        this.subscription = new Subscription();
    }

    componentWillUnmount() {
        this.rxProps.complete();
        this.subscription && this.subscription.unsubscribe();
    }

    addSubscription = (subscription) => {
        this.subscription.add(subscription);
    };
}
