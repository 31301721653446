// @ts-ignore
import React from 'react';
// @ts-ignore
import withUser from 'dating-mobile/src/models/user/controllers';
// @ts-ignore
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';

type Props = {
    onComplete: Function;
};

export const withMandatoryPhotoController = (Component: any) => {
    const Controller = React.memo((props: Props) => {
        const { onComplete } = props;

        return <Component {...props} onComplete={onComplete} waitUploading />;
    });

    Controller.displayName = 'stepped-registration.screens.mandatory-photo.controller';

    return withIdentityId(withUser(Controller), 'id');
};
