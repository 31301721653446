import Color from 'color';
import EStyleSheet from 'react-native-extended-stylesheet';

const textStyle = {
  fontFamily: '$brandFontFamily',
  color: '$brandTextColor',
};

const styles = EStyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: '$brandColor',
    justifyContent: 'space-between',
    flexGrow: 1,
    paddingHorizontal: 30,
  },

  addThumbnailContainerHidden: {
    position: 'absolute',
    opacity: 0,
  },

  iconContainer: {
    height: 136,
    width: 136,
    borderRadius: 68,
    backgroundColor: () =>
      Color(EStyleSheet.value('$primaryBackgroundColor'))
        .alpha(0.5)
        .rgb()
        .string(),
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },

  icon: {
    width: 40,
  },

  iconText: {
    ...textStyle,
    marginTop: 10,
    fontSize: 13,
  },

  aboutInput: {
    ...textStyle,
    fontSize: 15,
    textAlign: 'center',
    marginTop: 30,
  },

  aboutInputRow: {
    marginBottom: 80,
  },

  nextButton: {
    marginBottom: 40,
  },

  thumbnailDescription: {
    fontSize: 14,
    color: '$infoTextColor',
    fontFamily: '$brandFontFamily',
    alignSelf: 'center',
    textAlign: 'center',
    marginBottom: 30,
    marginHorizontal: 20,
  },
});

export default styles;
