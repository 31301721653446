import React from "react";
import { Text, View } from "react-native";
import PropTypes from "prop-types";
import styles from './styles';
import Resources from "dating-mobile/src/resources";
import Button from 'dating-mobile/src/components/buttons/base';

export default class DialogApprovalBanner extends React.PureComponent {
    static displayName = "dialogs.messages.views.dialog-approval-banner.view";
    static propTypes = {
        onContinuePress: PropTypes.func,
        onReportPress: PropTypes.func
    };

    onContinuePress = () => {
        this.props.onContinuePress && this.props.onContinuePress();
    };

    onReportPress = () => {
        this.props.onReportPress && this.props.onReportPress();
    };

    render() {
        return (
            <View style={styles.container}>
                <Text style={styles.title}>{Resources.strings['dialog-approval-banner-title']}</Text>
                <View style={styles.buttonsContainer}>
                    <Button
                        style={styles.report}
                        titleStyle={styles.reportTitle}
                        title={Resources.strings['dialog-approval-banner-report-button']}
                        onPress={this.onReportPress}
                    />
                    <Button
                        style={styles.continue}
                        titleStyle={styles.continueTitle}
                        title={Resources.strings['dialog-approval-banner-continue-button']}
                        onPress={this.onContinuePress}
                    />
                </View>
            </View>
        );
    }
}
