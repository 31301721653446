import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet } from 'react-native';
import { CHAT } from 'dating-mobile/src/routing/router/constants';

import Resources from '../../../../resources';
import ImageButton from '../../../../components/navigation/views/image-button';
import Can from '../../../../models/user.ability/controller';
import CredentialsUpdater from '../../../../authentication/utils/credentials-updater';

import controller from './controller';
import View from './view';
import styles from './styles';

const ScreenView = controller(View);

export default class Screen extends React.Component {
  static displayName = 'dialogs.chats.chat-requests.screen';

  static propTypes = {
    navigation: PropTypes.object.isRequired,
  };

  static navigationOptions = ({ navigationOptions }) => ({
    headerTitle: Resources.strings['chat-requests-screen-title'],
    headerStyle: [navigationOptions.headerStyle, styles.header],
    headerTitleStyle: [navigationOptions.headerTitleStyle, styles.headerTitle],
    headerBackImage: () => (
      <ImageButton
        image={Resources.images.backIcon()}
        tint={StyleSheet.flatten(styles.headerTitle).color}
      />
    ),
  });

  startChat = params => {
    const { navigation } = this.props;

    navigation.navigate(CHAT, params);
  };

  render() {
    const { navigation } = this.props;

    return (
      <Can do="create" of="message">
        {status => (
          <ScreenView
            canChat={status}
            startChat={this.startChat}
            updateCredentials={CredentialsUpdater.updateCredentials}
            safeAreaInsets={navigation.getParam('safeAreaInsets')}
          />
        )}
      </Can>
    );
  }
}
