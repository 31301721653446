import React from 'react';
import { View, Text, Image } from 'react-native';
import styles from './styles';

import UserMediaController from '../../../../models/user.media/controller';
import MediaView from '../media';
import MediaAdd from '../add-media';
import Resources from '../../../../resources';

class Medias extends React.PureComponent {
    static displayName = 'users.media.list';

    render() {
        const media = (Array.isArray(this.props.media) ? this.props.media : [])
            .filter(media => media.mediatype?.startsWith('image'));

        const list = media.splice(0, 2).map((media) => (
            <MediaView
                {...this.props}
                key={media.basename}
                id={this.props.id}
                basename={media.basename}
                couldBeDeleted={true}
            />
    ));

        return (
            <View style={styles.container}>
                {list}
                {
                    media.length >= 3 && (
                        <View style={styles.photo}>
                            <Text style={styles.counter}>+{media.length - 2}</Text>
                        </View>
                    )
                }
                <View style={styles.addMedia}>
                    <MediaAdd id={this.props.id} mediaType={'photo'}>
                        <Image source={Resources.images.addPhoto()}/>
                    </MediaAdd>
                </View>
            </View>
        );

    }

}

export default UserMediaController(Medias);
