import Actions from './actions';

function createNotificationsStore(id) {
    class Store {
        constructor(flux) {
            const actions = flux.getActions(Actions, id);

            this.state = {
                notification: null,
            };
            this.bindAction(actions.notify, this.notify);
            this.bindAction(actions.dismiss, this.dismiss);
        }

        notify = payload => {
            if (!payload) {
                return;
            }

            payload.priority = payload.priority || 0;
            if (!this.state.notification || this.state.notification.priority < payload.priority) {
                this.setState({
                    notification: payload,
                });
            }
        };

        dismiss = payload => {
            if (!payload) {
                return;
            }

            this.setState({
                notification: null,
            });
        };
    }

    Store.displayName = createNotificationsStore.getDisplayName(id);

    return Store;
}

createNotificationsStore.getDisplayName = id => {
    return `notifications.${id}`;
};

export default createNotificationsStore;
