import EStyleSheet from 'react-native-extended-stylesheet';
import { Platform } from 'react-native';

const styles = EStyleSheet.create({
  header: {
    backgroundColor: '$primaryBackgroundColor',
    borderBottomColor: '$primaryBackgroundColor',
    shadowColor: '$primaryBackgroundColor',
    shadowRadius: 0,
    shadowOpacity: 0,
    elevation: 0,
    shadowOffset: {
      height: 0,
    },
  },

  headerTitle: {
    color: '$primaryTextColor',
    fontFamily: '$brandFontFamily',
  },

  container: {
    backgroundColor: '$primaryBackgroundColor',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },

  scrollContainer: {
    width: '100%',
  },

  titleContainer: {
    marginBottom: 66,
  },

  title: {
    fontSize: 20,
    fontFamily: '$brandFontFamily',
    color: '$primaryTextColor',
    fontWeight: 'bold',
    textAlign: 'center',
  },

  subtitle: {
    fontSize: 18,
    fontFamily: '$brandFontFamily',
    color: '$primaryTextColor',
    textAlign: 'center',
  },

  image: {
    marginBottom: 32,
    alignSelf: 'center',
  },

  imageBig: {
    width: 100,
    height: 100,
  },

  description: {
    fontSize: 16,
    fontFamily: '$brandFontFamily',
    color: '$primaryTextColor',
    textAlign: 'center',
    marginBottom: 77,
  },

  benefitsContainer: {
    alignItems: 'center',
    paddingHorizontal: 50,
  },

  benefitsSmall: {
    ...Platform.select({
      ios: {
        minHeight: 210,
      },
      android: {
        minHeight: 245,
      },
    }),
  },

  benefitsLarge: {
    ...Platform.select({
      ios: {
        minHeight: 245,
      },
      android: {
        minHeight: 280,
      },
    }),
  },

  packageList: {
    marginBottom: 40,
  },

  button: {
    width: 284,
    alignSelf: 'center',
  },

  disabledButton: {
    gradientColor: '$accentButtonDisabledGradientColor',
  },

  agreementContainer: {
    paddingTop: 40,
    paddingHorizontal: 40,
    paddingBottom: 24,
  },

  activityIndicatorContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },

  creditImg: {
    width: 20,
    marginLeft: 4,
  },

  buttonContent: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

  buttonTitle: {
    fontSize: 18,
    fontFamily: '$brandFontFamily',
    fontWeight: 'bold',
    color: '$accentTextColor',
  },

  disabledModifier: {
    color: '$accentTextColor',
  },
});

export default styles;
