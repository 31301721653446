import EStylesSheet from 'react-native-extended-stylesheet';

const styles = EStylesSheet.create({
    container: {
        width: '100%',
        aspectRatio: 1.67,
        marginBottom: 100,
        marginTop: 20,
    },

    banner: {
        width: '100%',
        height: '100%',
    },
});

export default styles;
