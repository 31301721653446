import { range, merge, interval, timer, Subject, Subscription, of } from 'rxjs';
import Persistence from '@sdv/domain/app/persistence';
import {
    map,
    switchMap,
    filter,
    skip,
    concat,
    delayWhen,
    shareReplay,
} from 'rxjs/operators';

const msInDay = 24 * 60 * 60 * 1000;
const defaultUserMessageDelay = 30000;

export default class CheersReminder {
    constructor(flux, userId, streamerId, userMessageDelay = defaultUserMessageDelay) {
        const lastCheerButtonTapTimestampKey = `app-first-launch.lastCheerButtonTapTimestamp.${userId}`;
        const persistence = Persistence.shared();
        const cheerButtonTap = new Subject();
        const delayFn = power => userMessageDelay * Math.pow(2, power);

        this.subscriptions = new Subscription();
        this.updateReminder = () => {
            cheerButtonTap.next(undefined);
        };

        const lastCheerButtonTapTime = merge(
            persistence.rxValue(lastCheerButtonTapTimestampKey).pipe(map(storeValue => storeValue && Number(storeValue))),
            cheerButtonTap.pipe(map(() => Date.now()))
        ).pipe(shareReplay(1));

        this.subscriptions.add(
            lastCheerButtonTapTime
                .pipe(
                    skip(1),
                    switchMap((value) => value && persistence.rxStore(lastCheerButtonTapTimestampKey, String(value)))
                ).subscribe()
        );

        const wasCheerButtonPressedInLast24Hours = lastCheerButtonTapTime
            .pipe(
                map(time => !!time && (Date.now() - time) <= msInDay),
                filter(wasButtonPressed => !wasButtonPressed)
            );


        this.reminder = wasCheerButtonPressedInLast24Hours
            .pipe(
                switchMap((wasButtonPressed) => {
                    if (!wasButtonPressed) {
                        return range(0, 6)
                            .pipe(
                                delayWhen((value) => timer(delayFn(value))),
                                concat(interval(delayFn(6)))
                            );
                    }

                    return of();
                })
            );
    }
}
