import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import Resources from 'dating-mobile/src/resources';
import styles from './styles';
import UserName from 'dating-mobile/src/user/common/views/user-name';

class LetterSentNotificationContent extends React.Component {
    static displayName = 'notifications.popup.letter-sent';
    static propTypes = {
        recipientId: PropTypes.string,
        notification: PropTypes.object,
        onReadyForDisplay: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.props.onReadyForDisplay();
    }

    render() {
        return (
            <View style={styles.container}>
                <View style={styles.textContainer}>
                    <UserName
                        userId={this.props.recipientId}
                        style={styles.text}
                        format={Resources.strings['letter-sent-notification-text']}
                    />
                </View>
            </View>
        );

    }
}

class LetterSentNotification extends React.Component {
    render() {
        const recipientId = (this.props.notification && this.props.notification.recipient) || undefined;
        return (<LetterSentNotificationContent recipientId={recipientId} {...this.props} />);
    }
}

export default LetterSentNotification;
