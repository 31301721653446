import { Platform } from 'react-native';
// import * as InAppUtils from 'react-native-iap';
import { parse } from 'iso8601-duration';

const InAppUtils = {};

let preparePromise;

const openConnection = () => {
  InAppUtils.openConnections = (InAppUtils.openConnections || 0) + 1;

  if (InAppUtils.openConnections <= 1) {
    preparePromise = InAppUtils.initConnection();
  }

  return preparePromise;
};

const closeConnection = () => {
  InAppUtils.openConnections = InAppUtils.openConnections
    ? InAppUtils.openConnections - 1
    : 0;

  return Promise.resolve();
};

export const SUBSCRIPTION_PERIOD = Object.freeze({
  day: 'DAY',
  week: 'WEEK',
  month: 'MONTH',
  year: 'YEAR',
});

const getLocalizedPrice = (price, currency) =>
  currency === 'CREDITS' || !price
    ? price
    : new Intl.NumberFormat(navigator.language, {
        style: 'currency',
        currency,
      }).format(price);

const cache = {};

const localize = async function(skuList) {
  if (cache[skuList]) {
    return cache[skuList];
  }

  await openConnection();

  const storeProducts = [];

  if (Platform.OS === 'ios') {
    storeProducts.push(...(await InAppUtils.getProducts(skuList)));
  } else if (Platform.OS === 'android') {
    storeProducts.push(...(await InAppUtils.getSubscriptions(skuList)));
    storeProducts.push(...(await InAppUtils.getProducts(skuList)));
  }

  const storeProductsBySku = {};

  storeProducts.forEach(product => {
    storeProductsBySku[product.productId] = product;
  });

  closeConnection();

  const result = storeProducts.map(product => {
    let subscriptionPeriodNumber = product.subscriptionPeriodNumberIOS;
    let subscriptionPeriodUnit = product.subscriptionPeriodUnitIOS;

    if (Platform.OS === 'android' && product.subscriptionPeriodAndroid) {
      const subscriptionPeriod = parse(product.subscriptionPeriodAndroid);

      if (subscriptionPeriod.days) {
        subscriptionPeriodNumber = subscriptionPeriod.days;
        subscriptionPeriodUnit = SUBSCRIPTION_PERIOD.day;
      } else if (subscriptionPeriod.months) {
        subscriptionPeriodNumber = subscriptionPeriod.months;
        subscriptionPeriodUnit = SUBSCRIPTION_PERIOD.month;
      } else if (subscriptionPeriod.years) {
        subscriptionPeriodNumber = subscriptionPeriod.years;
        subscriptionPeriodUnit = SUBSCRIPTION_PERIOD.year;
      } else if (subscriptionPeriod.weeks) {
        subscriptionPeriodNumber = subscriptionPeriod.weeks;
        subscriptionPeriodUnit = SUBSCRIPTION_PERIOD.week;
      }
    }

    let trialPeriod;

    if (product.freeTrialPeriodAndroid) {
      trialPeriod = parse(product.freeTrialPeriodAndroid);
    } else if (
      product.introductoryPricePaymentModeIOS &&
      product.introductoryPricePaymentModeIOS === 'FREETRIAL'
    ) {
      trialPeriod = {};
      switch (product.introductoryPriceSubscriptionPeriodIOS) {
        case SUBSCRIPTION_PERIOD.day:
          trialPeriod.days = product.introductoryPriceNumberOfPeriodsIOS;
          break;
        case SUBSCRIPTION_PERIOD.week:
          trialPeriod.days = 7 * product.introductoryPriceNumberOfPeriodsIOS;
          break;
        case SUBSCRIPTION_PERIOD.month:
          trialPeriod.months = product.introductoryPriceNumberOfPeriodsIOS;
          break;
        case SUBSCRIPTION_PERIOD.year:
          trialPeriod.years = product.introductoryPriceNumberOfPeriodsIOS;
          break;
      }
    }

    return {
      sku: product.productId,
      localizedPrice: product.localizedPrice,
      localizedCurrency: product.currency,
      localizedPriceWithoutCurrency: product.price,
      introductoryPrice: product.introductoryPrice, // TODO: Add period and etc.
      subscriptionPeriodNumber,
      subscriptionPeriodUnit,
      trialPeriod,
    };
  });

  cache[skuList] = result;

  return result;
};

function localizeWeb(productList) {
  if (cache[productList]) {
    return Promise.resolve(cache[productList]);
  }

  const result = productList.map(product => {
    let subscriptionPeriodNumber;
    let subscriptionPeriodUnit;
    let trialPeriod;
    const localizedCurrency = product.price.currency.toUpperCase();
    const priceWithoutDiscount = product?.priceWithoutDiscount?.includingTax;
    const localizedPriceWithoutCurrency = `${
      product.isTrial ? priceWithoutDiscount : product.price.includingTax
    }`;
    const introductoryPriceWithoutCurrency = `${
      product.isTrial ? product.price.includingTax : ''
    }`;

    if (product.membershipPeriod) {
      const subscriptionPeriod = parse(product.membershipPeriod);

      if (product.isTrial) {
        trialPeriod = subscriptionPeriod;
      }

      if (subscriptionPeriod.days) {
        subscriptionPeriodNumber = subscriptionPeriod.days;
        subscriptionPeriodUnit = SUBSCRIPTION_PERIOD.day;
      } else if (subscriptionPeriod.months) {
        subscriptionPeriodNumber = subscriptionPeriod.months;
        subscriptionPeriodUnit = SUBSCRIPTION_PERIOD.month;
      } else if (subscriptionPeriod.years) {
        subscriptionPeriodNumber = subscriptionPeriod.years;
        subscriptionPeriodUnit = SUBSCRIPTION_PERIOD.year;
      } else if (subscriptionPeriod.weeks) {
        subscriptionPeriodNumber = subscriptionPeriod.weeks;
        subscriptionPeriodUnit = SUBSCRIPTION_PERIOD.week;
      }
    }

    return {
      sku: product.sku,
      localizedPrice: getLocalizedPrice(
        localizedPriceWithoutCurrency,
        localizedCurrency,
      ),
      localizedPriceWithoutCurrency,
      introductoryPrice: getLocalizedPrice(
        introductoryPriceWithoutCurrency,
        localizedCurrency,
      ), // TODO: Add period and etc.
      subscriptionPeriodNumber,
      subscriptionPeriodUnit,
      trialPeriod,
    };
  });

  cache[productList] = result;

  return Promise.resolve(result);
}

const actualLocalize = Platform.OS === 'web' ? localizeWeb : localize;

export { actualLocalize as localize };
