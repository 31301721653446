import React from 'react';
import PropTypes from 'prop-types';
import FinishedBroadcastViewHoc from '../stream-finished';
import ConnectingView from '../connecting';
import StreamController from '../../../../models/dialogs.stream/controllers/controller';
import VideoView from '../../components/video';

const StreamComponent = StreamController(VideoView);

class StreamVideoView extends React.PureComponent {
    static displayName = 'stream.video.view';
    static propTypes = {
        stream: PropTypes.shape({
            id: PropTypes.string.isRequired,
            finished: PropTypes.bool,
            hasVideo: PropTypes.bool,
        }).isRequired,
        userId: PropTypes.string.isRequired,
        onPress: PropTypes.func,
        isCompact: PropTypes.bool,
        videoMode: PropTypes.string,
        zOrder: PropTypes.number,
    };

    render() {
        const userIsStreamer = this.props.userId === this.props.stream.id;
        return this.props.stream.finished && !userIsStreamer
            ? <FinishedBroadcastViewHoc
                holder={this.props.userId}
                target={this.props.stream.id}
                id={this.props.stream.id}
                backButtonTap={this.props.onPress}
            />
            : (
                this.props.stream.hasVideo
                    ? <StreamComponent zOrder={this.props.zOrder} key={`stream-${this.props.stream.id}`} id={this.props.stream.id} mode={this.props.videoMode}/>
                    : <ConnectingView id={this.props.stream.id} connectingTextVisible={!this.props.isCompact}/>
            )
    }

}

export default StreamVideoView;
