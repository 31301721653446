import { Subject, combineLatest, BehaviorSubject, of } from 'rxjs';
import {
  map,
  switchMap,
  distinctUntilChanged,
  tap,
  shareReplay,
  catchError,
  filter,
  timeout,
} from 'rxjs/operators';
import { omit } from 'lodash';
import { singleton } from '@sdv/commons/utils/singleton';
import api, { toObservable } from '@sdv/domain/api';
import flux from '@sdv/domain/app/flux';
import UsersSearchModel from 'dating-mobile/src/models/users.search/model';
import PreferencesModel from 'dating-mobile/src/models/user.preference/model';
import User from '@sdv/domain/user/model';
import { isDefaultLocation } from '@sdv/domain/location/location-updater';
import ConfigModel from 'dating-mobile/src/models/config/model';
import geohash from 'ngeohash';

const GEOHASH_PRECISION = 5;
const COUNT_PER_PAGE = 30;
const UPDATE_COUNT = 30;

const normalizeParams = params =>
  Object.entries(params).reduce(
    (acc, [key, value]) => (value === null ? acc : { ...acc, [key]: value }),
    {},
  );

export class UserFeedRepository {
  static shared = singleton(
    (userId, config) => new UserFeedRepository(userId, config),
  );

  constructor(id, { count = COUNT_PER_PAGE, updateCount = UPDATE_COUNT } = {}) {
    flux.get(PreferencesModel, id).actions.get(); // TODO maybe this is BAD

    this.omitSubject = new BehaviorSubject(0);
    this.isLoading = new BehaviorSubject(false);
    this.fetchError = new Subject();

    const preferences = flux.get(PreferencesModel, id).store.rxState();
    const filters = flux
      .get(UsersSearchModel, id)
      .store.rxState()
      .pipe(
        map(data => data.params),
        tap(() => this.omitSubject.next(0)),
      );

    const configState = flux.get(ConfigModel).store.rxState();
    const userState = flux.get(User, id).store.rxState();

    const configValues = combineLatest(configState, userState).pipe(
      map(([conf, user]) => {
        const whitelistedCountries =
          conf.features['default-location-in-search-whitelisted-countries'];

        return {
          locationBasedFeedEnabled:
            conf.features['location-based-feed-enabled'],
          defaultMinAge: conf.preferences['default-min-age'],
          defaultMaxAge: conf.preferences['default-max-age'],
          defaultCountry:
            user?.country &&
            whitelistedCountries &&
            (whitelistedCountries === 'all' ||
              whitelistedCountries.includes(user.country))
              ? user.country
              : undefined,
        };
      }),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
    );

    const userLocation = userState.pipe(
      filter(user => user.latitude && user.longitude),
      map(user => {
        const { latitude, longitude } = user;

        return isDefaultLocation({ latitude, longitude })
          ? {}
          : {
              near: geohash.encode(latitude, longitude, GEOHASH_PRECISION),
            };
      }),
    );

    const fetchedLocation = configValues.pipe(
      switchMap(data => {
        return data.locationBasedFeedEnabled ? userLocation : of({});
      }),
      distinctUntilChanged(),
    );

    // params from filter
    const searchParams = combineLatest(
      filters,
      preferences,
      configValues,
      fetchedLocation,
      this.omitSubject,
    ).pipe(
      map(
        ([filtersData, preferencesData, configData, location, omitTimes]) => ({
          minage: preferencesData.minage || configData.defaultMinAge,
          maxage: preferencesData.maxage || configData.defaultMaxAge,
          country: configData.defaultCountry,
          ...omit(filtersData, ['preferences.gender']),
          near: location.near,
          select: count,
          omit: omitTimes * updateCount,
        }),
      ),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
    );

    this.users = searchParams.pipe(
      switchMap(params => this.getUsersFromApi(params)),
      shareReplay(1),
    );
  }

  getUsersFromApi = params => {
    this.loadingStarted();

    return toObservable(api.users.feed.get, normalizeParams(params)).pipe(
      timeout(5000),
      tap(
        () => {
          this.fetchError.next(null);
          this.loadingEnded();
        },
        error => {
          this.fetchError.next(error);
          this.loadingEnded();
        },
        () => {
          this.loadingEnded();
        },
      ),
      catchError(() => of([])),
    );
  };

  loadingStarted = () => {
    this.isLoading.next(true);
  };

  loadingEnded = () => {
    this.isLoading.next(false);
  };

  getNext = () => {
    if (!this.isLoading.getValue()) {
      this.omitSubject.next(this.omitSubject.getValue() + 1);
    }
  };
}
