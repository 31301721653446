function createMiscDataActions(id) {

    class Actions {

        get = () => (dispatch, flux) => {

            let request = id === 'countries' ? flux.api.data : flux.api.dict;
            request.get(id, (error, result) => {
                if (result) {
                    dispatch(result);
                } else {
                    dispatch(null, error);
                }
            });

        }

    }

    Actions.displayName = createMiscDataActions.getDisplayName(id);

    return Actions;
}

createMiscDataActions.getDisplayName = function(id) {
    return `misc-data.${id}`;
};

export default createMiscDataActions;
