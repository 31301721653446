import { combineLatest, Subscription } from 'rxjs';
import { map, filter, distinctUntilChanged } from 'rxjs/operators';
import Session from '@sdv/domain/authorization/session';
import flux from '@sdv/domain/app/flux';
import { Permissions } from '@sdv/domain/app/permissions';

import Service from '../service';

export class LocationPermissionTracker extends Service {
  static displayName = 'service.tracking.location-permission-tracker';

  componentDidMount() {
    this.subscribe();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  subscribe() {
    const locationDetectionEnabled = Permissions.shared().permissions.pipe(
      map(({ location }) => location),
      filter(permission => typeof permission === 'boolean'),
      distinctUntilChanged(),
    );
    const locationDetectionEnabledSubscription = combineLatest(
      Session.shared().userId,
      locationDetectionEnabled,
    ).subscribe(
      ([userId, enabled]) => this.tack(userId, enabled),
      () => {},
    );

    this.disposeBag = new Subscription();
    this.disposeBag.add(locationDetectionEnabledSubscription);
  }

  unsubscribe() {
    if (this.disposeBag) {
      this.disposeBag.unsubscribe();
    }
  }

  tack(userId, permissionGranted) {
    if (userId) {
      flux.api.annals.add(userId, 'location-permission-status', {
        permissionGranted,
      });
    }
  }
}
