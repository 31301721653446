import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        height: 5,
        width: '100%',
        backgroundColor: '$focusedColor',
    },

    fillContainer: {
        position: 'absolute',
        height: 5,
        backgroundColor: '$accentColor',
    },
});

export default styles;
