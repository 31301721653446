import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import * as ROUTES from 'dating-mobile/src/routing/router/constants';

function bindNavigation(Component) {
  class Navigation extends PureComponent {
    static propTypes = {
      navigation: PropTypes.object.isRequired,
    };

    handleClose = () => {
      const { navigation } = this.props;
      const onComplete = navigation.getParam('onComplete');
      const registrationInProgress = navigation.getParam(
        'registrationInProgress',
      );

      if (registrationInProgress) {
        navigation.navigate(ROUTES.SKIP_SUBSCRIBE, { onComplete });
      } else {
        navigation.pop();
      }
    };

    handleContinuePress = () => {
      const { navigation } = this.props;
      const onComplete = navigation.getParam('onComplete');

      navigation.navigate(ROUTES.CONGRATULATION, { onComplete });
    };

    render() {
      const { navigation } = this.props;

      return (
        <Component
          onContinue={this.handleContinuePress}
          onClose={this.handleClose}
          registrationInProgress={navigation.getParam('registrationInProgress')}
          {...this.props}
        />
      );
    }
  }

  Navigation.navigationOptions = () => {
    return {
      headerShown: false,
    };
  };

  return Navigation;
}

export default bindNavigation;
