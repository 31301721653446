import React from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity, View } from 'react-native';
import styles from './styles';
import Photo from 'dating-mobile/src/components/photo';
import UserName from 'dating-mobile/src/user/common/views/user-name';
import FollowButton from 'dating-mobile/src/user/common/views/set-favorite-button';
import IconWithText from 'dating-mobile/src/components/icon-with-text';
import Resources from 'dating-mobile/src/resources';

class Profile extends React.PureComponent {
    static displayName = 'user.thumbnail.name';
    static propTypes = {
        id: PropTypes.string,
        holderId: PropTypes.string,
        showFollowButton: PropTypes.bool,
        name: PropTypes.string,
        viewersCount: PropTypes.number
    };

    render() {
        const { ...props } = this.props.user;
        const basename = props.thumbnail;
        const [width, height] = [this.props.width || 40, this.props.height || 40];

        styles.user = [styles.user, { paddingLeft: width + 8, borderRadius: Math.ceil(width / 2) }];

        return (
            <TouchableOpacity onPress={this.props.onPress}>
                <View style={styles.container}>
                    <View style={styles.user}>
                        <View style={styles.userInfo}>
                            <UserName
                                userId={props.id}
                                style={styles.name}
                                numberOfLines={1}
                                ellipsizeMode={'tail'}
                            />

                            <IconWithText icon={Resources.images.eyeStatsIcon()} text={this.props.viewersCount}/>
                        </View>

                        {this.props.showFollowButton && (
                            <FollowButton
                                holderId={this.props.holderId}
                                targetId={props.id}
                                style={styles.followButton}
                            />
                        )}
                    </View>

                    <View style={{ marginRight: -width }}>
                        <Photo
                            key={basename}
                            userId={props.id}
                            basename={basename}
                            round={true}
                            placeholder={'avatar'}
                            style={{ width: width, height: height }}
                        />
                    </View>
                </View>
            </TouchableOpacity>
        );
    }
}

export default Profile;
