/* eslint-disable import/named */
import React from 'react';
import PropTypes from 'prop-types';
import Navigator from 'dating-mobile/src/routing/navigator';
import { LETTER } from 'dating-mobile/src/routing/router/constants';
import PaidResourceModel, {
  getId as getPaidResourceModelId,
  RESOURCE_ACCESS_STATUS,
} from '../../paid-resource/model';
import BalanceRefiller, {
  PAYMENT_REASON,
} from '../../../payment/utils/balance-refiller';
import { getLetterPath } from '../../../resources/remote';

function CreateLetterContentController(Component) {
  class Controller extends React.Component {
    static displayName = 'common.messages.letter.content.controller';

    static propTypes = {
      message: PropTypes.shape({
        outgoing: PropTypes.bool.isRequired,
        sender: PropTypes.string.isRequired,
        recipient: PropTypes.string.isRequired,
        content: PropTypes.shape({
          id: PropTypes.string.isRequired,
          cover: PropTypes.string.isRequired,
        }),
      }).isRequired,
    };

    static contextTypes = {
      flux: PropTypes.object.isRequired,
    };

    constructor(props, context) {
      super(props);
      this.flux = context.flux;

      const { message } = props;
      const identity = message.outgoing ? message.sender : message.recipient;
      const letterPath = getLetterPath(
        message.sender,
        message.recipient,
        message.content.id,
        message.content.introductory,
      );

      if (!message.outgoing) {
        this.paidResourceModel = this.flux.get(
          PaidResourceModel,
          getPaidResourceModelId(identity, letterPath),
        );
        this.state = { status: this.paidResourceModel.store.getState().status };
      } else {
        this.state = { status: RESOURCE_ACCESS_STATUS.AVAILABLE };
      }
    }

    componentDidMount() {
      const { status } = this.state;

      if (this.paidResourceModel) {
        this.paidResourceModel.store.listen(this.onResourceAccessStatusChanged);
        if (status === RESOURCE_ACCESS_STATUS.UNKNOWN) {
          this.paidResourceModel.actions.access();
        }
      }
    }

    componentWillUnmount() {
      if (this.paidResourceModel) {
        this.paidResourceModel.store.unlisten(
          this.onResourceAccessStatusChanged,
        );
      }
    }

    onResourceAccessStatusChanged = state => {
      const { status } = this.state;

      if (status !== state.status) {
        this.setState({
          status: state.status,
        });
      }
    };

    openResource = () => {
      const { status } = this.state;
      const {
        message: {
          recipient,
          sender,
          content: { introductory, id },
        },
      } = this.props;

      if (
        status !== RESOURCE_ACCESS_STATUS.AVAILABLE &&
        status !== RESOURCE_ACCESS_STATUS.PAYMENT_REQUIRED
      ) {
        return;
      }
      const navigate = () => {
        Navigator.navigate(LETTER, {
          recipient,
          sender,
          letter: id,
          introductory,
        });
      };

      if (status === RESOURCE_ACCESS_STATUS.AVAILABLE) {
        navigate();
      } else {
        this.paidResourceModel.actions.buy(error => {
          if (error) {
            if (error.status === 402) {
              BalanceRefiller.refillBalance(
                PAYMENT_REASON.OPEN_LETTER,
                this.openResource,
              );
            }

            return;
          }
          navigate();
        });
      }
    };

    render() {
      return (
        <Component
          {...this.state}
          {...this.props}
          openResource={this.openResource}
        />
      );
    }
  }

  return Controller;
}

export default CreateLetterContentController;
