import React from 'react';
import PropTypes from 'prop-types';
import { chatRequests } from './events';
import UserDefaults from 'react-native-default-preference';
import date from '@sdv/domain/api/date';
import { Subject } from 'rxjs';

const CHAT_REQUESTS_PROPERTY = 'generated.user-events.has-unread-requests.chat-requests';
const CHAT_REQUESTS_LOADED_PROPERTY = 'generated.user-events.has-unread-requests.chat-requests-loaded';
const readSubject = new Subject();

export default function createController(Component, options = {}) {

    const userIdPropName = options.userIdPropName || 'userId';
    const resultPropName = options.resultPropName || 'hasUnreadRequests';
    const nonAutoResultPropName = options.nonAutoResultPropName || 'hasUnreadNonAutoRequests';
    const readActionPropName = options.readActionPropName || 'readRequests';

    class HasUnreadRequests extends React.Component {
        static displayName = 'user-events.has-unread-requests';
        static propTypes = {
            [userIdPropName]: PropTypes.string,
            [CHAT_REQUESTS_PROPERTY]: PropTypes.array,
            [CHAT_REQUESTS_LOADED_PROPERTY]: PropTypes.bool
        };

        static contextTypes = {
            flux: PropTypes.object
        };

        constructor(props, context) {
            super(props, context);
            this.date = date(context.flux.api);
        }

        state = {hasUnreadRequests: false, hasUnreadNonAutoRequests: false};

        storageKey = () => {
            return `chat-requests.${this.props[userIdPropName]}.last-read-request-timestamp`;
        };

        load = async () => {
            if (!this.props[userIdPropName]) {
                return;
            }

            const chatRequests = this.props[CHAT_REQUESTS_PROPERTY] || [];

            const lastReadRequestTimestampValue = await UserDefaults.get(this.storageKey()) || 0;

            const hasUnreadRequests = chatRequests.findIndex(request => !request.stale &&
                Date.parse(request.timestamp) > lastReadRequestTimestampValue) > -1;

            const hasUnreadNonAutoRequests = chatRequests.findIndex(request => !request.stale &&
                Date.parse(request.timestamp) > lastReadRequestTimestampValue &&
                (!request.payload || !request.payload.auto)) > -1;

            this.setState({
                hasUnreadRequests: hasUnreadRequests,
                hasUnreadNonAutoRequests: hasUnreadNonAutoRequests
            });
        };

        read = () => {
            if (!this.props[userIdPropName]) {
                return;
            }

            this.date.now((serverDate) => {

                UserDefaults.set(this.storageKey(), serverDate.toString());

                readSubject.next(true);
            });
        };

        componentDidMount() {
            this.subscription = readSubject.subscribe(() => {
               this.load().then();
            });
            this.load().then();
        }

        componentWillUnmount() {
            this.subscription && this.subscription.unsubscribe();
        }

        componentDidUpdate(prevProps) {
            const chatRequests = this.props[CHAT_REQUESTS_PROPERTY];
            const prevRequests = prevProps[CHAT_REQUESTS_PROPERTY];
            if (chatRequests !== prevRequests) {
                this.load().then();
            }
        }

        resolveValue = () => {
            return this.state.hasUnreadRequests;
        };

        resolveNonAutoValue = () => {
            return this.state.hasUnreadNonAutoRequests;
        };

        render() {
            const {...props} = this.props;

            delete props[CHAT_REQUESTS_PROPERTY];
            delete props[CHAT_REQUESTS_LOADED_PROPERTY];

            return (
                <Component
                    {...props}
                    {
                        ...{
                            [resultPropName]: this.resolveValue(),
                            [nonAutoResultPropName]: this.resolveNonAutoValue(),
                            [readActionPropName]: this.read
                        }
                    }
                />
            );
        }

    }

    return chatRequests(
        HasUnreadRequests,
        {
            userIdPropName: userIdPropName,
            eventsPropName: CHAT_REQUESTS_PROPERTY,
            eventsLoadedPropName: CHAT_REQUESTS_LOADED_PROPERTY
        }
    );

}
