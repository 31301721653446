import React from 'react';
import PropTypes from 'prop-types';
import { Modal, TouchableOpacity, View, SafeAreaView } from 'react-native';
import styles from './styles';
import Resources from '../../resources';

import Button from '../buttons/base';

class ActionSheet extends React.Component {
    static propTypes = {
        modalVisible: PropTypes.bool,
        buttonHidden: PropTypes.bool,
        buttonTitle: PropTypes.string,
        onCancel: PropTypes.func
    };

    render() {

        return (
            <Modal
                animationType="slide"
                transparent={true}
                visible={this.props.modalVisible}
                onRequestClose={this.props.onCancel}
            >
                <SafeAreaView style={styles.safeArea}>
                    <View style={styles.modalContainer}>
                        <TouchableOpacity style={styles.container} onPress={this.props.onCancel} />
                        {this.props.children}
                        {
                            !this.props.buttonHidden && (
                                <Button
                                    style={styles.button}
                                    titleStyle={styles.buttonText}
                                    onPress={this.props.onDone || this.props.onCancel}
                                    title={this.props.buttonTitle || Resources.strings['action-sheet-default-button-title']}
                                />
                            )
                        }
                    </View>
                </SafeAreaView>
            </Modal>
        );

    }

}

export default ActionSheet;
