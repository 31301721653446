import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-native';
import { switchMap, filter } from 'rxjs/operators';
import Session from '@sdv/domain/authorization/session';
import { Stock } from '@sdv/domain/payment/stock';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';
import { PAYMENT_REASON } from 'dating-mobile/src/platform/balance-refiller';
import Resources from 'dating-mobile/src/resources';
import { Purchase } from '../../methods/purchase';

export default function bindController(Component) {
  class SkipSubscriptionController extends PureComponent {
    static propTypes = {
      id: PropTypes.string,
      onPurchaseCompleted: PropTypes.func,
    };

    state = { isLoading: false };

    componentDidMount() {
      this.packsSubscription = Session.shared()
        .userId.pipe(
          filter(Boolean),
          switchMap(id => {
            return Stock.shared(id).trailPackagesInStock();
          }),
        )
        .subscribe(packs => {
          this.setState({ pack: packs[0] });
        });
    }

    componentWillUnmount() {
      this.packsSubscription.unsubscribe();
    }

    startTrial = () => {
      const { id } = this.props;
      const { pack } = this.state;

      if (!id || !pack) {
        return;
      }

      const invoice = pack[1][0];

      this.setState({ isLoading: true });

      Purchase.shared()
        .pay(id, invoice, PAYMENT_REASON.SKIP_SUBSCRIBE)
        .subscribe(({ success }) => {
          this.setState({ isLoading: false });
          if (success) {
            this.onPaymentCompleted();
          }
        });
    };

    onPaymentCompleted = () => {
      const { onPurchaseCompleted } = this.props;

      Alert.alert(
        Resources.strings['memberships-screen-success-alert-title'],
        Resources.strings['memberships-screen-success-alert-message'],
        [
          {
            text: Resources.strings.ok,
            style: 'cancel',
            onPress: () => {
              onPurchaseCompleted();
            },
            onDismiss: () => {
              onPurchaseCompleted();
            },
          },
        ],
      );
    };

    render() {
      const { pack, isLoading } = this.state;

      const price = pack ? pack[0].price : undefined;

      return (
        <Component
          {...this.props}
          price={price}
          isLoading={isLoading}
          onSubscribePress={this.startTrial}
        />
      );
    }
  }

  return withIdentityId(SkipSubscriptionController, 'id');
}
