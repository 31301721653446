import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Platform, Text } from 'react-native';
import Resources from 'dating-mobile/src/resources';
import { escapeRegExpSpecialCharacters } from 'dating-mobile/src/utils/regexp';

import styles from './styles';

const TERMS_LINK_MARK = '{link:terms}';
const POLICY_LINK_MARK = '{link:policy}';

const linkMarkPattern = `${escapeRegExpSpecialCharacters(
    TERMS_LINK_MARK,
)}|${escapeRegExpSpecialCharacters(POLICY_LINK_MARK)}`;

export default class SubscriptionAgreement extends PureComponent {
    static displayName = 'payment.views.subscription-agreement.view';

    static propTypes = {
        onTermsLinkPress: PropTypes.func,
        onPolicyLinkPress: PropTypes.func,
        children: PropTypes.object,
    };

    render() {
        const { onTermsLinkPress, onPolicyLinkPress, children } = this.props;

        let text = Resources.strings.formatString(
            Resources.strings['memberships-screen-terms-agreement-text-format'],
            TERMS_LINK_MARK,
            POLICY_LINK_MARK,
        );

        const titles = {
            [TERMS_LINK_MARK]:
                Resources.strings['memberships-screen-terms-agreement-terms-link-text'],
            [POLICY_LINK_MARK]:
                Resources.strings['memberships-screen-terms-agreement-policy-link-text'],
        };

        const pressHandlers = {
            [TERMS_LINK_MARK]: onTermsLinkPress,
            [POLICY_LINK_MARK]: onPolicyLinkPress,
        };

        const result = [];
        const regExp = new RegExp(linkMarkPattern);
        let match;

        while ((match = text.match(regExp))) {
            const pattern = match[0];

            const part = text.substring(0, match.index);

            result.push(<Text key={`links-${part}-${result.length}`}>{part}</Text>);

            result.push(
                <Text key={`links-${pattern}`} style={styles.link} onPress={pressHandlers[pattern]}>
                    {titles[pattern]}
                </Text>,
            );

            text = text.substring(match.index + pattern.length);
        }

        result.push(<Text key={`links-${text}-${result.length}`}>{text}</Text>);

        return (
            <Text style={styles.description}>
                {children}
                {Platform.select({
                    ios: Resources.strings['memberships-screen-ios-autorenewable-description-text'],
                    android:
                        Resources.strings[
                            'memberships-screen-android-autorenewable-description-text'
                        ],
                })}
                <Text>{'\n\n'}</Text>
                {result}
            </Text>
        );
    }
}
