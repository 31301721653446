import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { singleton } from '@sdv/commons/utils/singleton';
import { SupportedMethods } from '@sdv/domain/app/payment/shop-info';
import { PayPalPayment } from '@sdv/domain/payment/paypal';
import {
  AppStorePayment,
  CreditCardScreenOpener,
  GoogleStorePayment,
  CreditsPayment,
} from './index';

export class Purchase {
  static shared = singleton(() => new Purchase());

  isLoading = new Subject();

  pay = (userId, invoice, reason, exchangeInterceptor, options) => {
    let payment;

    switch (invoice && invoice.method) {
      case SupportedMethods.google:
        payment = new GoogleStorePayment(
          userId,
          invoice,
          reason,
          exchangeInterceptor,
        );
        break;
      case SupportedMethods.apple:
        payment = new AppStorePayment(
          userId,
          invoice,
          reason,
          exchangeInterceptor,
        );
        break;
      case SupportedMethods.cards:
        payment = new CreditCardScreenOpener(
          userId,
          invoice,
          reason,
          exchangeInterceptor,
        );
        break;
      case SupportedMethods.paypal:
        payment = new PayPalPayment({
          userId,
          invoice,
        });
        break;
      case SupportedMethods.credits:
        payment = new CreditsPayment(userId, invoice, reason, options);
        break;
    }

    this.isLoading.next(true);

    return payment.purchase().pipe(
      tap(
        () => this.isLoading.next(false),
        () => this.isLoading.next(false),
      ),
    );
  };
}
