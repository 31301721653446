import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        backgroundColor: '$accentColor',
        height: 80,
        borderRadius: 20,
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: 'row'
    },
    textContainer: {
        marginLeft: 12,
    },
    title: {
        color: '$accentTextColor',
        fontFamily: '$brandFontFamily',
        fontSize: 16
    },
    description: {
        color: '$accentTextColor',
        fontFamily: '$brandFontFamily',
        fontSize: 18,
        fontWeight: 'bold'
    },
    image: {
        marginLeft: 24,
        width: 60
    },
    iconWithTextContainer: {
        flex: 0.75,
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: 'row'
    },
    closeButton: {
        flex: 0.25
    },
    closeTitle: {
        alignSelf: 'center',
        color: '$accentTextColor',
        fontFamily: '$brandFontFamily',
        fontSize: 14
    }
});

export default styles;
