import { createOptimisticActions } from '@sdv/model';

function createTagsActions(id) {

    const { holder, target } = parseId(id);

    class Actions extends createOptimisticActions(['patch', 'delete']) {

        get = () => (dispatch, flux) => {
            flux.api.users.targeted.tags.get(holder, target, (error, tags) => {
                if (error) {
                    return dispatch(null, error);
                }

                return dispatch(tags);
            });
        };

        patch(tag) {

            return super.patch(tag, function() {
                this.dispatch(tag);

                this.flux.api.users.targeted.tags.post(holder, target, { tag: tag }, (error) => {

                    if (error) {
                        return this.revert(error);
                    }

                    return this.commit();
                });

            });
        };

        delete(tag) {
            return super.patch(tag, function() {
                this.dispatch(tag);

                this.flux.api.users.targeted.tags.delete(holder, target, tag, (error) => {

                    if (error) {
                        return this.revert(error);
                    }

                    return this.commit();
                });

            });
        };

    }

    Actions.displayName = createTagsActions.getDisplayName(id);

    return Actions
}

createTagsActions.getDisplayName = function(id) {
    return `user-targeted-tags.${id}`;
};

export default createTagsActions;

export function parseId(id) {
    let keys;

    if (typeof id !== 'string' || (keys = id.split(':')).length < 2 || !keys[0] || !keys[1]) {
        throw new Error('UserTagsModel should have id in format "{holder}:{target}"');
    }

    return {
        holder: keys[0],
        target: keys[1]
    };
}

export function getId(holder, target) {
    return `${holder}:${target}`;
}
