function createCodesStore(userId) {

    class Store {

        constructor() {
            this.state = {};
        }

    }

    Store.displayName = createCodesStore.getDisplayName(userId);

    return Store;
}

createCodesStore.getDisplayName = function (id) {
    return `codes.${id}`;
};

export default createCodesStore;
