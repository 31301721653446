import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    blockContainer: {
        width: 88,
        alignItems: 'center',
        backgroundColor: '#999',
        justifyContent: 'center',
    },

    blockUserText: {
        color: 'white',
        textAlign: 'center',
        fontFamily: '$brandFontFamily',
        fontSize: 14,
        '@flavor lovinga': {
            color: '$accentTextColor'
        }
    }
});

export default styles;
