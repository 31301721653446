import EStyleSheet from 'react-native-extended-stylesheet';

const textStyle = {
  fontFamily: '$brandFontFamily',
  fontWeight: 'normal',
  color: '$primaryTextColor',
};

const styles = EStyleSheet.create({
  modalContainer: {
    margin: 0,
  },

  container: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'stretch',
    position: 'absolute',
    bottom: 0,
    backgroundColor: '$primaryBackgroundColor',
    paddingHorizontal: 28,
    paddingTop: 32,
    paddingBottom: 25,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
  },

  itemSeparator: {
    marginTop: 14,
  },

  itemContainer: {
    height: 56,
    borderRadius: 18,
    flexDirection: 'row',
    alignItems: 'stretch',
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: 'rgba(54,190,132,0.15)',
  },

  itemContent: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },

  titleContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 22,
  },

  title: {
    ...textStyle,
    fontSize: 18,
  },

  subtitle: {
    ...textStyle,
    fontSize: 16,
  },

  message: {
    ...textStyle,
    flex: 1,
    fontSize: 14,
  },
});

export default styles;
