import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  container: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  connectingText: {
    top: 10,
    fontSize: 18,
    fontFamily: '$brandFontFamily',
    color: 'rgba(255, 255, 255, 0.5)',
  },
  username: {
    marginTop: 20,
    marginBottom: 20,
    fontSize: 22,
  },
  thumbnail: {
    width: 200,
    height: 200,
  },
});

export default styles;
