import { Platform } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  text: {
    paddingTop: 12,
    color: '$primaryTextColor',
    fontSize: 12,
    fontWeight: 'normal',
    fontFamily: '$brandFontFamily',
    textAlign: 'center',
  },
  icon: {
    maxWidth: 32,
    ...Platform.select({
      web: {
        height: 32,
        width: '100%',
      },
    }),
  },
});

export default styles;
