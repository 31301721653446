import React from 'react';
import PropTypes from 'prop-types';
import UserController from '../../../../models/user/controllers';
import { View, Text, TouchableOpacity, Image } from 'react-native';
import styles from './styles';
import Resources from '../../../../resources';
import { PHOTO_TRANSFORMATION_TYPE } from '../../../../components/photo';
import Photo from '../../../../components/photo';
import testId from '../../../../utils/test-id';

class CurrentlyBroadcastingItem extends React.PureComponent {
    static displayName = 'dialogs.stream.currently-broadcasting.list-item';
    static propTypes = {
        user: PropTypes.object.isRequired,
        onBroadcastTogetherButtonPress: PropTypes.func,
    };

    render() {

        return (
            <View style={styles.container}>
                <View style={styles.userDataContainer}>
                    <Photo
                        style={styles.avatar}
                        userId={this.props.user.id}
                        basename={this.props.user.thumbnail}
                        transformation={PHOTO_TRANSFORMATION_TYPE.DETECT_FACE}
                        placeholder={'avatar'}
                        {...testId('Streamer thumbnail')}
                    />
                    <Text style={styles.name} numberOfLines={1}>{this.props.user.name}</Text>
                </View>
                <TouchableOpacity style={styles.buttonContainer} onPress={this.props.onBroadcastTogetherButtonPress} {...testId('Attach to stream button')}>
                    <Image
                        source={Resources.images.attachSteamIcon()}
                    />
                </TouchableOpacity>
            </View>
        );

    }

}

export default UserController(CurrentlyBroadcastingItem);
