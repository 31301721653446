import React, { Component } from 'react';
import PropTypes from 'prop-types';

const isShallowEqual = (valueA, valueB) => {
  if (valueA === valueB) {
    return true;
  }

  if (!valueA || !valueB) {
    return valueA === valueB;
  }

  const valueAKeys = Object.keys(valueA);
  const valueBKeys = Object.keys(valueB);

  const len1 = valueAKeys.length;
  const len2 = valueBKeys.length;

  if (len1 !== len2) {
    return false;
  }

  for (let i = 0; i < len1; i++) {
    const key = valueAKeys[i];

    if (valueA[key] !== valueB[key]) {
      return false;
    }
  }

  return true;
};

const connectWrapper = (
  getModels,
  mapStoresToProps,
  mapActionsToProps,
  shouldReconnect,
  InnerComponent,
) => {
  class Connect extends Component {
    constructor(props, context) {
      super(props, context);

      this.init(this.props);
    }

    init = props => {
      this.models = getModels(this.context.flux, props);
      this.modelActions = mapActionsToProps(this.models);
      this.modelState = mapStoresToProps(this.models);
    };

    reconnect = props => {
      this.unsubscriveFromStores();
      this.init(props);
      this.subscribeToStores();
      this.forceUpdate();
    };

    subscribeToStores = () => {
      Object.values(this.models).forEach(
        model => model.store && model.store.listen(this.actualizeState),
      );
    };

    unsubscriveFromStores = () => {
      Object.values(this.models).forEach(
        model => model.store && model.store.unlisten(this.actualizeState),
      );
    };

    actualizeState = () => {
      this.modelState = mapStoresToProps(this.models);
      this.forceUpdate();
    };

    componentDidMount() {
      this.subscribeToStores();
    }

    componentWillReceiveProps(nextProps) {
      if (shouldReconnect(this.props, nextProps)) {
        this.reconnect(nextProps);
      }
    }

    componentWillUnmount() {
      this.unsubscriveFromStores();
    }

    render() {
      return React.createElement(InnerComponent, {
        ...this.props,
        ...this.modelState,
        ...this.modelActions,
      });
    }
  }

  class PureConnect extends Connect {
    componentWillReceiveProps(nextProps) {
      if (
        !isShallowEqual(this.models, getModels(this.context.flux, nextProps))
      ) {
        this.reconnect(nextProps);
      }
    }
  }

  const contextTypes = {
    flux: PropTypes.object,
  };

  Connect.contextTypes = contextTypes;
  PureConnect.contextTypes = contextTypes;

  return shouldReconnect ? Connect : PureConnect;
};

const connect = (
  getModels,
  mapStoresToProps,
  mapActionsToProps,
  shouldReconnect,
) => {
  return Comp =>
    connectWrapper(
      getModels,
      mapStoresToProps ||
        function() {
          return {};
        },
      mapActionsToProps ||
        function() {
          return {};
        },
      shouldReconnect,
      Comp,
    );
};

export default connect;
