import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, Text } from 'react-native';
import Resources from 'dating-mobile/src/resources';
import UserName from 'dating-mobile/src/user/common/views/user-name';
import styles from './styles';

class FollowMessage extends Component {
    static displayName = 'common.views.message.system.components.follow-message';
    static propTypes = {
        userId: PropTypes.string,
        targetId: PropTypes.string,
    };

    render() {
        return (
            <View style={styles.container}>
                <Text style={styles.text} >
                    {
                        Resources.strings.formatString(
                            Resources.strings['stream-follow-message'],
                            <UserName userId={this.props.userId} />,
                            <UserName userId={this.props.targetId} />
                        )
                    }
                </Text>
            </View>

        );
    }
}

export default FollowMessage;
