import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  View,
  TouchableOpacity,
  Dimensions,
  Platform,
  Image,
  TouchableWithoutFeedback,
  StatusBar,
  Alert,
  Text,
  Keyboard,
} from 'react-native';
import * as Animatable from 'react-native-animatable';
// import { withSafeArea } from 'react-native-safe-area';
import Resources from 'dating-mobile/src/resources';
import KeepAwake from 'react-native-keep-awake';
import testId from 'dating-mobile/src/utils/test-id';
import StreamerInfo from 'dating-mobile/src/dialogs/stream.info/views/user-profile';
import PrivateChatPopup from 'dating-mobile/src/dialogs/messages/popup';
import PrivateChatBadge from 'dating-mobile/src/dialogs/messages/views/unread-badge-button';
import ChatPopupRouter from 'dating-mobile/src/dialogs/chats-popup-router';
import { StreamDiamondsView } from 'dating-mobile/src/dialogs/stream/views/stream-diamonds-view';
import { StreamRerunBanner } from 'dating-mobile/src/dialogs/stream/views/stream-rerun-banner';
import ReportButton from 'dating-mobile/src/components/report-button';
import { streamResource } from '@sdv/domain/resources/remote';
import StreamController from '../../../models/dialogs.stream/controllers/controller';
import LogComponent from '../../common/views/log';
import LogController from '../../../models/common.messages/controllers/log-controller';
import FormComponent, {
  KEYBOARD_TYPE,
  RESOURCE_KEYBOARD_TYPE,
} from '../../common/views/form';
import FormController from '../../../models/common.messages/controllers/form-controller';
import BalanceRefiller, {
  PAYMENT_REASON,
} from '../../../payment/utils/balance-refiller';
import Message from '../views/message';
import IfConfigValue from '../../../components/config-value/if';
import { BadConnectionNotification } from 'dating-mobile/src/components/bad-connection-notification';

import ThumbnailNameView from '../../../user/common/views/thumbnail-name';
import UserController from '../../../models/user/controllers';
import GiftView from '../components/gift';
import VolumeView from '../../../components/video/views/mute';
import RotateCameraView from '../../../components/video/views/camera';
import MessagesBlockerController from '../../../models/dialogs.room/controllers/messages-blocker';
import StreamingView from '../views/streaming';
import CheersButton from '../views/cheers-button';
import Can from '../../../models/user.ability/controller';
import styles from './styles';

const GiftComponent = UserController(GiftView);
const Profile = UserController(ThumbnailNameView);
const MuteControl = StreamController(VolumeView);
const RotateCameraControl = StreamController(RotateCameraView);
const SafeAreaView = View;

class StreamView extends Component {
  static displayName = 'dialogs.stream.view';

  static propTypes = {
    privateChatVisible: PropTypes.bool,
    profileVisible: PropTypes.bool,
    mute: PropTypes.func,
    guestStreamControlButtonsVisible: PropTypes.bool.isRequired,
    hasOutgoingPendingStreamRequest: PropTypes.bool,
    id: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    messagesModel: PropTypes.object.isRequired,
    guestStream: PropTypes.object,
    hostStream: PropTypes.object,
    onChatPress: PropTypes.func,
  };

  static contextTypes = {
    flux: PropTypes.object,
  };

  constructor(props, context) {
    super(props);
    this.flux = context.flux;

    this.state = {
      cheersButtonVisible: true,
      topPanelVisible: true,
      endButtonVisible: false,
      isStreamerChatsVisible: false,
      chatVisible: props.privateChatVisible,
    };

    this.PrivateChatBadgeButton = this.props.onChatPress
      ? props => (
          <PrivateChatBadge
            {...props}
            onPress={this.props.onChatPress}
            attendeeId={this.props.id}
          />
        )
      : undefined;
  }

  onShowCheersPressed = () => {
    this.form && this.form.showResourceKeyboard(RESOURCE_KEYBOARD_TYPE.CHEERS);
  };

  dismissKeyboardAndPopups = () => {
    this.props.onCloseProfilePress && this.props.onCloseProfilePress();
    this.props.onClosePrivateChatPress && this.props.onClosePrivateChatPress();
    this.form && this.form.dismissKeyboard();
  };

  onKeyboardChanged = keyboard => {
    this.setState({
      cheersButtonVisible: keyboard !== KEYBOARD_TYPE.CHEERS,
      topPanelVisible: typeof keyboard !== 'number',
    });
  };

  diamondsViewTap = () => {
    this.props.showDiamonds && this.props.showDiamonds(this.props.id);
  };

  tapProfile = () => {
    this.props.showProfile && this.props.showProfile();
  };

  onPressClose = () => {
    this.props.goBack && this.props.goBack();
  };

  componentDidMount() {
    KeepAwake.activate();
    this.height = Dimensions.get('screen').height;
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
    KeepAwake.deactivate();
  }

  mute = () => {
    if (this.props.mute) {
      this.props.mute();
    }
  };

  guestVideoPressed = () => {
    if (
      this.props.id === this.props.userId &&
      !this.props.guestStreamControlButtonsVisible
    ) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.setState({ endButtonVisible: !this.state.endButtonVisible });
      this.timer = setTimeout(() => {
        this.setState({ endButtonVisible: false });
      }, 3000);
    }
  };

  broadcastTogetherTap = () => {
    if (this.props.id === this.props.userId) {
      this.props.showCurrentlyBroadcasting(this.props.onStreamAttach);
    } else {
      this.props.attachStream();
    }
  };

  onKeyboardVisible = visible => {
    this.setState({ keyboardVisible: visible });
  };

  toggleStreamerChatsPopup = () => {
    this.setState({
      isStreamerChatsVisible: !this.state.isStreamerChatsVisible,
    });
  };

  chatAnimationFinished = () => {
    if (!this.props.privateChatVisible) {
      this.setState({ chatVisible: this.props.privateChatVisible });
    }
  };

  chatAnimationStarted = () => {
    if (this.props.privateChatVisible) {
      Keyboard.dismiss();
      this.setState({ chatVisible: this.props.privateChatVisible });
    }
  };

  streamerChatAnimationStateChanged = isStartAnimation => () => {
    if (isStartAnimation && this.state.isStreamerChatsVisible) {
      this.setState({
        streamChatMounted: this.state.isStreamerChatsVisible,
      });
    }

    if (!isStartAnimation && !this.state.isStreamerChatsVisible) {
      this.setState({
        streamChatMounted: this.state.isStreamerChatsVisible,
      });
    }
  };

  onBlock = () => {
    const { goBack } = this.props;

    Alert.alert(
      Resources.strings['member-is-blocked'],
      '',
      [{ text: Resources.strings.ok, style: 'cancel' }],
      { cancelable: false },
    );

    if (goBack) {
      goBack();
    }
  };

  render() {
    const { ...state } = this.state;

    const { id } = this.props;
    const userIsStreamer = this.props.id === this.props.userId;

    let image = null;

    if (this.props.donation) {
      image = (
        <View style={styles.donation}>
          <GiftComponent
            id={this.props.donation.sender}
            muted={this.props.hostStream.audioMuted}
            donationPath={this.props.donation.url.path}
          />
        </View>
      );
    }

    const streamStyle = Platform.select({
      ios: styles.iOSStreamStyle,
      android: [{ height: this.height }, styles.androidStreamStyle],
    });

    const topOffset = StatusBar.currentHeight || 0;

    const showBottomControls =
      this.props.donation ||
      this.props.showPublicChatLog ||
      this.props.sendDonationVisible ||
      this.props.showPublicChatInput ||
      this.props.coStreamVisible;

    const privateChatContainerStyle = [
      styles.privateChatContainer,
      this.state.keyboardVisible ? styles.privateChatContainerExpanded : {},
    ];

    return (
      <TouchableWithoutFeedback
        onPress={this.dismissKeyboardAndPopups}
        style={styles.container}
      >
        <View style={styles.container}>
          {Platform.OS === 'ios' && <StatusBar hidden />}
          {Platform.OS === 'android' && (
            <StatusBar
              backgroundColor="transparent"
              barStyle="light-content"
              translucent
            />
          )}
          <View style={[streamStyle, { backgroundColor: 'black' }]}>
            <StreamingView
              zOrder={0}
              userId={this.props.userId}
              stream={this.props.hostStream}
              onPress={this.onPressClose}
              isCompact={false}
            />
          </View>
          {!!this.props.timeBeforeStart && (
            <View style={styles.startTimerContainer}>
              <Text style={styles.startTimer}>
                {this.props.timeBeforeStart}
              </Text>
            </View>
          )}
          <SafeAreaView
            style={styles.contentContainer}
            pointerEvents="box-none"
          >
            {!!styles.$showBadConnectionNotification && (
              <BadConnectionNotification />
            )}
            {!!this.state.topPanelVisible && (
              <View style={[styles.topPanel, { marginTop: topOffset }]}>
                <View style={styles.topPanelTop}>
                  <View style={styles.topPanelTopLeft}>
                    <View style={styles.topPanelTopLeft}>
                      <Profile
                        id={id}
                        holderId={this.props.userId}
                        showFollowButton={!userIsStreamer}
                        onPress={this.tapProfile}
                        viewersCount={this.props.hostStream.viewers}
                      />
                    </View>
                  </View>
                  <View style={styles.topPanelTopRight}>
                    {!userIsStreamer && (
                      <ReportButton
                        showIcon
                        allowBlock
                        id={this.props.id}
                        reportedContent={streamResource(this.props.id)}
                        onBlock={this.onBlock}
                        style={styles.reportButton}
                        blockUserAlertMessage={
                          Resources.strings[
                            'block-user-broadcasts-alert-message'
                          ]
                        }
                      />
                    )}
                    {userIsStreamer && !!this.props.hostStream.hasVideo && (
                      <View style={styles.rotateCameraButton}>
                        <RotateCameraControl
                          id={id}
                          {...testId('Rotate camera button')}
                        />
                      </View>
                    )}
                    {!!this.props.hostStream.hasVideo &&
                      !this.props.hostStream.finished && (
                        <View style={styles.muteButton}>
                          <MuteControl
                            id={id}
                            remote={this.props.userId !== id}
                            {...testId('Mute button')}
                            onPress={this.mute}
                          />
                        </View>
                      )}
                    <TouchableOpacity
                      onPress={this.onPressClose}
                      style={styles.closeButton}
                      {...testId('Close stream button')}
                    >
                      <Image source={Resources.images.closeWithShadowIcon()} />
                    </TouchableOpacity>
                  </View>
                </View>
                <View style={styles.secondTopPanel} pointerEvents="box-none">
                  <View style={styles.topPanelBottom}>
                    <StreamDiamondsView
                      onPress={this.diamondsViewTap}
                      text={this.props.hostStream.diamonds}
                    />
                    <StreamRerunBanner id={this.props.id} />
                  </View>
                  {!this.props.coStreamVisible &&
                    !this.props.hostStream.finished && (
                      <IfConfigValue
                        path="features.attach-to-stream-enabled"
                        equalsTo={true}
                      >
                        <Can do="start" of="stream">
                          <TouchableOpacity
                            style={styles.broadcastTogetherButton}
                            onPress={this.broadcastTogetherTap}
                            {...testId('Broadcast together button')}
                          >
                            <Image
                              source={Resources.images.attachSteamIcon()}
                            />
                          </TouchableOpacity>
                        </Can>
                      </IfConfigValue>
                    )}
                </View>
              </View>
            )}

            {showBottomControls && (
              <View style={styles.chat}>
                <View style={styles.logContainer}>
                  <View style={styles.chatContainer}>
                    {this.props.donation && image}
                    {this.props.showPublicChatLog && (
                      <View style={styles.log}>
                        <MessagesBlockerController room={id}>
                          <LogController
                            holderId={this.props.userId}
                            targetId={id}
                            model={this.props.messagesModel}
                            refillBalance={(...args) =>
                              BalanceRefiller.refillBalance(
                                PAYMENT_REASON.SEND_MESSAGE,
                                ...args,
                              )
                            }
                          >
                            <LogComponent
                              MessageComponent={Message}
                              MessageSeparatorComponent={() => null}
                              onPress={this.dismissKeyboardAndPopups}
                            />
                          </LogController>
                        </MessagesBlockerController>
                      </View>
                    )}
                  </View>

                  <View>
                    {this.props.coStreamVisible && (
                      <View
                        style={styles.guestStreamContainer}
                        {...testId('Costreamer video')}
                      >
                        <TouchableOpacity
                          style={styles.guestStreamingView}
                          onPress={this.guestVideoPressed}
                        >
                          <StreamingView
                            zOrder={1}
                            userId={this.props.userId}
                            stream={this.props.guestStream}
                            onPress={this.onPressClose}
                            isCompact
                            videoMode="contain"
                          />
                        </TouchableOpacity>
                        {this.props.coStreamInviteVisible && (
                          <View
                            style={styles.guestStreamControlButtonContainer}
                          >
                            <TouchableOpacity
                              style={styles.streamControlButtonContainer}
                              onPress={this.props.coStreamDeclineInvite}
                            >
                              <Image source={Resources.images.declineIcon()} />
                            </TouchableOpacity>
                            <TouchableOpacity
                              style={styles.streamControlButtonContainer}
                              onPress={this.props.coStreamAcceptInvite}
                            >
                              <Image source={Resources.images.acceptIcon()} />
                            </TouchableOpacity>
                          </View>
                        )}
                        {this.state.endButtonVisible &&
                          this.props.coStreamActiveControlsVisible && (
                            <TouchableOpacity
                              onPress={this.props.endActiveCoStream}
                              style={styles.endButtonContainer}
                            >
                              <Text style={styles.guestStreamOverlayText}>
                                {this.props.coStreamActiveIsRequesting
                                  ? Resources.strings.cancel
                                  : Resources.strings[
                                      'stream-screen-end-button'
                                    ]}
                              </Text>
                            </TouchableOpacity>
                          )}
                        {!this.state.endButtonVisible &&
                          this.props.coStreamActiveControlsVisible &&
                          this.props.coStreamActiveIsRequesting && (
                            <View
                              onPress={this.props.endActiveCoStream}
                              style={styles.outgoingTextContainer}
                              pointerEvents="none"
                            >
                              <Text style={styles.guestStreamOverlayText}>
                                {
                                  Resources.strings[
                                    'stream-screen-has-outgoing-invite-title'
                                  ]
                                }
                              </Text>
                            </View>
                          )}
                      </View>
                    )}
                    {this.state.cheersButtonVisible &&
                      this.props.sendDonationVisible && (
                        <CheersButton
                          style={styles.cheersButton}
                          onPress={this.onShowCheersPressed}
                          streamerId={id}
                          userId={this.props.userId}
                        />
                      )}
                  </View>
                  {userIsStreamer && (
                    <View style={styles.streamerMessagesBtnContainer}>
                      <PrivateChatBadge
                        onPress={this.toggleStreamerChatsPopup}
                      />
                    </View>
                  )}
                </View>
                {this.props.showPublicChatInput ? (
                  <View style={styles.form}>
                    <FormController
                      model={this.props.messagesModel}
                      refillBalanceForText={(...args) =>
                        BalanceRefiller.refillBalance(
                          PAYMENT_REASON.SEND_MESSAGE,
                          ...args,
                        )
                      }
                      refillBalanceForCheer={(...args) =>
                        BalanceRefiller.refillBalance(
                          PAYMENT_REASON.SEND_CHEER,
                          ...args,
                        )
                      }
                    >
                      <FormComponent
                        controlSafeArea
                        ref={ref => (this.form = ref)}
                        onKeyboardChange={this.onKeyboardChanged}
                        inputStylingMode="stream"
                        InputExtraControl={this.PrivateChatBadgeButton}
                        freeCheersAvaliable
                      />
                    </FormController>
                  </View>
                ) : (
                  <View style={styles.chatSpacing} />
                )}
              </View>
            )}

            <Animatable.View
              style={styles.profileContainer}
              animation={
                this.props.profileVisible ? 'fadeInUpBig' : 'fadeOutDownBig'
              }
              duration={300}
              easing="linear"
              useNativeDriver
              onAnimationEnd={Keyboard.dismiss}
            >
              <StreamerInfo
                streamerId={this.props.id}
                onClose={this.props.onCloseProfilePress}
                onChatPress={this.props.onChatPress}
                privateChatPopupEnabled={this.props.privateChatPopupEnabled}
              />
            </Animatable.View>

            {userIsStreamer && (
              <Animatable.View
                style={privateChatContainerStyle}
                animation={
                  this.state.isStreamerChatsVisible
                    ? 'fadeInUpBig'
                    : 'fadeOutDownBig'
                }
                duration={300}
                easing="linear"
                onAnimationEnd={this.streamerChatAnimationStateChanged(false)}
                onAnimationBegin={this.streamerChatAnimationStateChanged(true)}
                useNativeDriver
              >
                {!!this.state.streamChatMounted && (
                  <ChatPopupRouter
                    userId={this.props.id}
                    onClose={this.toggleStreamerChatsPopup}
                    onKeyboardVisible={this.onKeyboardVisible}
                  />
                )}
              </Animatable.View>
            )}

            <Animatable.View
              style={privateChatContainerStyle}
              animation={
                this.props.privateChatVisible ? 'fadeInUpBig' : 'fadeOutDownBig'
              }
              duration={300}
              easing="linear"
              useNativeDriver
              onAnimationBegin={this.chatAnimationStarted}
              onAnimationEnd={this.chatAnimationFinished}
            >
              {!!this.state.chatVisible && (
                <PrivateChatPopup
                  attendeeId={this.props.id}
                  onClose={this.props.onClosePrivateChatPress}
                  onKeyboardVisible={this.onKeyboardVisible}
                />
              )}
            </Animatable.View>
          </SafeAreaView>
        </View>
      </TouchableWithoutFeedback>
    );
  }
}

export default StreamView;
