import PropTypes from 'prop-types';
import Service from 'dating-mobile/src/services/service';
import Navigator from 'dating-mobile/src/routing/navigator';
import { APP } from 'dating-mobile/src/routing/router/constants';
import withIdentity from 'dating-mobile/src/models/identity/controller';
import withConfigValue from 'dating-mobile/src/components/config-value';
import { Permissions } from '@sdv/domain/app/permissions';
import { LocationUpdater } from '@sdv/domain/location/location-updater';

class LocationProvider extends Service {
  static displayName = 'service.location-provider';

  static propTypes = {
    identity: PropTypes.object,
    requestPermissionsBeforeAuthorization: PropTypes.bool,
    locationDetectionEnabled: PropTypes.bool,
  };

  static contextTypes = {
    flux: PropTypes.object,
  };

  permissionRequested = false;

  locationSetForUsers = [];

  authorizedZoneScreenIsActive = false;

  componentDidMount() {
    Navigator.listen(this.onScreenChanged);
    this.authorizedZoneScreenIsActive = Navigator.currentStack().includes(APP);
    this.updateLocation(this.props);
  }

  componentWillUnmount() {
    Navigator.unlisten(this.onScreenChanged);
  }

  componentWillReceiveProps(props) {
    this.updateLocation(props);
  }

  onScreenChanged = ({ stack }) => {
    this.authorizedZoneScreenIsActive = stack.includes(APP);
    this.updateLocation(this.props);
  };

  updateLocation(props) {
    if (props.locationDetectionEnabled) {
      if (
        !this.permissionRequested &&
        (props.requestPermissionsBeforeAuthorization ||
          this.authorizedZoneScreenIsActive)
      ) {
        this.permissionRequested = true;
        Permissions.shared()
          .requestLocationPermission()
          .subscribe(
            () => this.updateLocationIfHasPermission(props),
            () => {},
          );
      } else {
        this.updateLocationIfHasPermission(props);
      }
    }
  }

  updateLocationIfHasPermission(props) {
    if (
      props.identity &&
      props.identity.id &&
      (props.identity.authorizationMethod || '').startsWith('signup') &&
      this.locationSetForUsers.indexOf(props.identity.id) < 0
    ) {
      if (!this.locationSetForUsers.includes(props.identity.id)) {
        this.locationSetForUsers.push(props.identity.id);
        LocationUpdater.shared().updateLocation();
      }
    }
  }
}

export default withConfigValue(withIdentity(LocationProvider), {
  requestPermissionsBeforeAuthorization:
    'permissions.request-before-authorization',
  locationDetectionEnabled: 'features.location-detection-enabled',
});
