import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { View, FlatList } from 'react-native';

import { FaqItem } from '../faq-item';

import styles from './styles';

export class FaqList extends PureComponent {
    static displayName = 'faq.views.faq-list';

    static propTypes = {
        topics: PropTypes.array,
        goToDetails: PropTypes.func,
    };

    renderItem = ({ item }) => {
        const { goToDetails } = this.props;

        return <FaqItem {...item} goToDetails={goToDetails} key={item.id} />;
    };

    render() {
        const { topics } = this.props;

        return (
            <View style={styles.container}>
                <FlatList
                    renderItem={this.renderItem}
                    data={topics}
                    keyExtractor={item => item.id}
                    numColumns={1}
                    alwaysBounceVertical={false}
                />
            </View>
        );
    }
}
