import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        flexGrow: 0,
        flexShrink: 0,
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 20,
    },
    headerText: {
        color: '$primaryTextColor',
        fontSize: 18,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    headerLeft: {
        flexGrow: 0,
        flexShrink: 0,
    },
    navigationButtonIcon: {
        '@flavor lovinga': {
            tintColor: '$navButtonLightTintColor',
        },
    },
    closeButton: {
        width: 32,
        height: 32,
        alignItems: 'center',
        justifyContent: 'center',
    },
    headerCenter: {
        justifyContent: 'center',
        flexGrow: 1,
        flexShrink: 0,
    },
    headerRight: {
        flexGrow: 0,
        flexShrink: 0,
    },
});

export default styles;
