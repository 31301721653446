import connect from '@sdv/connect';
import MediaModel from '../../../models/call.media';
import qs from 'qs';

function getModels(flux, props) {
    const models = {};

    if (
        props.userId &&
        props.attendeeId &&
        (typeof props.cameraAvailable === 'undefined' || props.cameraAvailable)
    ) {
        const modelId = qs.stringify({ user: props.userId, attendee: props.attendeeId });
        models.mediaModel = flux.get(MediaModel, modelId);
    }

    return models;
}

function mapStoresToProps(models) {
    return {
        disabled: !models.mediaModel || !models.mediaModel.store.getState().localStream
    };
}

function mapActionsToProps(models) {
    const props = {};

    if (models.mediaModel) {
        props.rotate = models.mediaModel.actions.rotateCamera;
    }

    return props;
}

export default connect(getModels, mapStoresToProps, mapActionsToProps);
