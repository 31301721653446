import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { View, Text } from 'react-native';
import DatePicker from 'react-native-date-picker';

import Resources from 'dating-mobile/src/resources';
import testId from 'dating-mobile/src/utils/test-id';
import Button from 'dating-mobile/src/components/buttons/base';

import styles from './styles';

export default class ScreenView extends PureComponent {
    static displayName = 'stepped-registration.screens.birthday.view';

    static propTypes = {
        onSubmit: PropTypes.func,
        minAge: PropTypes.number,
    };

    static defaultProps = {
        minAge: 18,
    };

    constructor(props) {
        super(props);

        const maxDate = this.getMaxDate();

        this.state = {
            date: Number.isNaN(maxDate) ? new Date() : maxDate,
        };
    }

    onDateChange = date => this.setState({ date });

    getMaxDate() {
        const { minAge } = this.props;

        return new Date().dateWithYearOffset(-minAge);
    }

    onSubmit = () => {
        const { onSubmit } = this.props;
        const { date } = this.state;

        if (onSubmit) {
            onSubmit(date);
        }
    };

    render() {
        const { date } = this.state;

        return (
            <View style={styles.container}>
                <View>
                    <Text style={styles.subTitle}>
                        {Resources.strings['onboarding-screen-title']}
                    </Text>
                    <Text style={styles.title}>
                        {Resources.strings['onboarding-screen-birthday-title']}
                    </Text>
                    <Text style={styles.hint}>
                        {Resources.strings['onboarding-screen-birthday-hint']}
                    </Text>
                    <DatePicker
                        mode="date"
                        date={date}
                        maximumDate={this.getMaxDate()}
                        onDateChange={this.onDateChange}
                    />
                </View>
                <Button
                    title={Resources.strings.next}
                    onPress={this.onSubmit}
                    style={styles.button}
                    {...testId('Next button')}
                />
            </View>
        );
    }
}
