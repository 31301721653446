import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    header: {
        '@flavor secretly': {
            backgroundColor: '$primaryBackgroundColor',
            borderBottomColor: '$primaryBackgroundColor',
            shadowColor: '$primaryBackgroundColor',
        },
    },
    headerTitle: {
        '@flavor secretly': {
            color: '$primaryTextColor',
        },
    },
    tint: {
        color: '$brandTextColor',
        '@flavor secretly': {
            color: '$primaryTextColor',
        },
    },
    container: {
        width: '100%',
        height: '100%',
        backgroundColor: '$brandColor',
        '@flavor secretly': {
            backgroundColor: '$primaryBackgroundColor',
        },
    },
    itemContainer: {
        height: 60,
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    selectionIcon: {
        width: 22,
        height: 22,
        borderRadius: 11,
        alignItems: 'center',
        justifyContent: 'center',
    },
    dotIcon: {
        width: 11,
        height: 11,
        borderRadius: 5.5,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#0096ff',
    },
    selectedIcon: {
        backgroundColor: '#0096ff',
    },
    unselectedIcon: {
        borderColor: '$disabledColor',
        borderWidth: 1,
    },
    textContainer: {
        marginLeft: 17,
        justifyContent: 'center',
        borderBottomWidth: 1,
        borderBottomColor: '$brandBorderColor',
        flex: 1,
        width: '100%',
        height: '100%',
        '@flavor secretly': {
            borderBottomColor: '$primaryBorderColor',
        },
    },
    text: {
        fontFamily: '$brandFontFamily',
        fontSize: 17,
        fontWeight: '600',
        color: '$brandTextColor',
        '@flavor secretly': {
            color: '$primaryTextColor',
        },
    },
    flatList: {
        width: '100%',
        height: '100%',
        marginLeft: 17,
    },
});

export default styles;
