import React from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity, Text, View } from 'react-native';
import styles from './styles';
import testId from '../../../../../utils/test-id';

export default class PaymentRequired extends React.Component {
  static propTypes = {
    motivationText: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    purchaseButtonTitle: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.string,
    ]),
    onPurchaseButtonPress: PropTypes.func,
    hint: PropTypes.node,
  };

  render() {
    const {
      motivationText,
      purchaseButtonTitle,
      onPurchaseButtonPress,
      hint,
    } = this.props;

    const MotivationText =
      typeof motivationText === 'function'
        ? motivationText
        : () => motivationText || '';

    const ButtonTitle =
      typeof purchaseButtonTitle === 'function'
        ? purchaseButtonTitle
        : () => purchaseButtonTitle || '';

    return (
      <View style={styles.container}>
        <Text style={styles.text}>
          <MotivationText />
        </Text>
        <TouchableOpacity
          style={[styles.button, !!hint && styles.marginBottomSmall]}
          onPress={onPurchaseButtonPress}
          {...testId('Purchase button')}
        >
          <Text style={styles.buttonText}>
            <ButtonTitle />
          </Text>
        </TouchableOpacity>
        {!!hint && <View style={styles.marginBottomSmall}>{hint}</View>}
      </View>
    );
  }
}
