import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Image,
  TouchableWithoutFeedback,
  View,
  Text,
  findNodeHandle,
} from 'react-native';
// import { BlurView } from '@react-native-community/blur';

import Resources from 'dating-mobile/src/resources';

import styles from './styles';

const BlurView = View;

export default class StreamTutorial extends PureComponent {
  static displayName = 'dialogs.stream.views.stream-tutorial-overlay.view';

  static propTypes = {
    showHint: PropTypes.bool,
    onHintPress: PropTypes.func,
  };

  state = {
    containerRef: null,
  };

  onContainerViewLoaded = () => {
    this.setState({ containerRef: findNodeHandle(this.containerRef) });
  };

  render() {
    const { children, showHint, onHintPress } = this.props;

    const { containerRef } = this.state;

    return (
      <View style={styles.container}>
        {!!showHint && (
          <TouchableWithoutFeedback onPressIn={onHintPress}>
            <View style={styles.swipeHint}>
              <BlurView
                blurType="dark"
                blurAmount={4}
                style={styles.swipeHint}
                viewRef={containerRef}
              />
              {!!containerRef && (
                <View style={[styles.swipeHint, styles.swipeHintTextContainer]}>
                  <Image
                    source={Resources.images.swipeVertical()}
                    style={styles.swipeHintImage}
                  />
                  <Text style={styles.swipeHintText}>
                    {Resources.strings['stream-screen-swipe-hint-text']}
                  </Text>
                </View>
              )}
            </View>
          </TouchableWithoutFeedback>
        )}
        <View
          style={styles.container}
          ref={ref => {
            this.containerRef = ref;
          }}
          onLayout={this.onContainerViewLoaded}
        >
          {children}
        </View>
      </View>
    );
  }
}
