import React from 'react';
import PropTypes from 'prop-types';
import { View, Platform, StatusBar } from 'react-native';
import { SafeAreaView } from 'react-navigation';
import KeyboardSpacer from 'react-native-keyboard-spacer';
import identityModel from '@sdv/domain/identity/model';
import styles from './styles';
import Keychain from '../../../utils/keychain';

import ImageButton from '../../../components/navigation/views/image-button';
import Resources from '../../../resources';
import TextButton from '../../../components/navigation/views/text-button';

function createIndexScreen(Component) {
  class Screen extends React.Component {
    static displayName = 'authentication.identity.screen';

    static navigationOptions = ({ navigation }) => ({
      headerStyle: styles.header,
      headerLeft: () => (
        <ImageButton
          image={Resources.images.backIcon()}
          onPress={() => navigation.pop()}
        />
      ),
      headerRight: () => (
        <TextButton
          title={navigation.getParam('rightTitle')}
          onPress={navigation.getParam('rightAction')}
        />
      ),
    });

    static contextTypes = {
      flux: PropTypes.object,
    };

    static propTypes = {
      navigation: PropTypes.object,
    };

    modalResult = null;

    constructor(props, context) {
      super(props);
      this.model = context.flux.get(identityModel);
      this.bus = props.navigation.getParam('bus');
      this.identity = this.model.store.getState();
    }

    componentDidMount() {
      const { navigation } = this.props;

      if (navigation.getParam('isRoot')) {
        this.model.store.listen(this.handleIdentityUpdate);
      }
    }

    componentWillUnmount() {
      const { navigation } = this.props;

      if (navigation.getParam('isRoot')) {
        this.model.store.unlisten(this.handleIdentityUpdate);
        if (this.modalResult) {
          this.bus?.emit(
            'event.credentials.update.completed',
            this.modalResult,
          );
        } else {
          this.bus?.emit('event.credentials.update.cancelled');
        }
      }
    }

    handleIdentityUpdate = async state => {
      const { email, token } = state;
      const { email: prevEmail } = this.identity;

      if (!email || !email.length || email === prevEmail) {
        return;
      }

      this.model.store.unlisten(this.handleIdentityUpdate);

      try {
        await Keychain.setToken(token);
      } catch (error) {
        console.log(error);
      }

      this.modalResult = state;
    };

    render() {
      return (
        <View style={styles.container}>
          <StatusBar
            barStyle={styles.$statusBarStyle}
            backgroundColor={styles.$statusBarBackgroundColor}
          />
          <SafeAreaView style={styles.safeArea}>
            <Component {...this.props} />
          </SafeAreaView>
          {Platform.OS === 'ios' && <KeyboardSpacer />}
        </View>
      );
    }
  }

  return Screen;
}

export default createIndexScreen;
