import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    iconContainer: {
        paddingVertical: 20,
        alignSelf: 'stretch'
    },

    icon: {
        tintColor: '$brandNotificationsColor'
    },

    title: {
        color: '$brandNotificationsColor'
    },
});

export default styles;


