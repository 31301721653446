import connect from '@sdv/connect';
import InterruptingModel from '../../../models/call.interrupting';
import qs from 'qs';

function getModels(flux, props) {
    const models = {};

    if (props.userId && props.attendeeId) {
        const modelId = qs.stringify({ user: props.userId, attendee: props.attendeeId });
        models.interruptingModel = flux.get(InterruptingModel, modelId);
    }

    return models;
}

function mapStoresToProps() {
    return {};
}

function mapActionsToProps(models) {
    const props = {};

    if (models.interruptingModel) {
        props.interrupt = models.interruptingModel.actions.interrupt;
    }

    return props;
}

export default connect(getModels, mapStoresToProps, mapActionsToProps);
