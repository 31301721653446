import { Text, View, Image } from 'react-native';
import React from 'react';
import styles from './styles';

const StreamStatsCounter = (props) => {
    let mainImage = null;
    let backgroundImage = null;
    if (props.images) {
        mainImage = props.images[0] ? <Image source={props.images[0]}/> : null;
        backgroundImage = props.images[1] ? <Image style={{ marginLeft: -46 }} source={props.images[1]}/> : null;
    }


    return (
        <View style={styles.container}>
            <View style={{ flexDirection: 'row' }}>
                {mainImage}
                {backgroundImage}
            </View>
            <Text style={styles.counterText}>{props.value}</Text>
            <Text style={styles.descriptionText}>{props.text}</Text>
        </View>
    );
};

export default StreamStatsCounter;
