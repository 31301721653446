function createBalanceActions(id) {

    class BalanceActions {

        get = () => (dispatch, flux) => {

            flux.api.credits.accounts.balance.get(id, function (error, response) {
                if (error) {
                    dispatch(null, error);
                    return;
                }

                dispatch(response);
            });

        };

        update = (amount) => (dispatch) => {
            dispatch(amount);
        };
    }

    BalanceActions.displayName = createBalanceActions.getDisplayName(id);

    return BalanceActions;

}

createBalanceActions.getDisplayName = function (id) {
    return `credits-accounts-balance.${id}`;
};

export default createBalanceActions;
