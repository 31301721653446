import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        flexDirection: 'column',
        paddingHorizontal: 40,
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1
    },
    text: {
        color: '$primaryTextColor',
        fontSize: 20,
        fontWeight: '500',
        fontFamily: '$brandFontFamily',
        textAlign: 'center',
        marginBottom: 32,
        '@flavor dma': {
            fontSize: 24
        },
        '@flavor tubit': {
            fontSize: 16,
            paddingBottom: 70,
        }
    }
});

export default styles;
