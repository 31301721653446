
export function randomValueFromArray(array) {
    return array[Math.floor(Math.random() * array.length)];
}

export function randomValuesFromArray(array, count) {
    const indexes = [];
    while (indexes.length !== count && indexes.length < array.length) {
        const newIndex = Math.floor(Math.random() * array.length);
        if (indexes.indexOf(newIndex) < 0) {
            indexes.push(newIndex);
        }
    }
    return indexes.map(index => array[index]);
}
