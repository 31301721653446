import { Platform } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

const commonContainerStyles = {
  height: 50,
  width: '100%',
  flexDirection: 'row',
  alignItems: 'center',
  paddingHorizontal: 10,
};

const styles = EStyleSheet.create({
  container: commonContainerStyles,

  selectedContainer: {
    ...commonContainerStyles,
    backgroundColor: '$secondaryBackgroundColor',
  },
  textContainer: {
    flex: 1,
  },
  text: {
    fontSize: 17,
    fontFamily: '$brandFontFamily',
    fontWeight: '600',
    color: '$inputTextColor',

    '@flavor ytm, udates': {
      textAlign: 'left',
    },
    '@flavor secretly': {
      fontWeight: 'normal',
      fontSize: 16,
    },
  },

  leftIconContainer: {
    marginRight: 15,
  },
  rightIconContainer: {
    marginLeft: 15,
  },

  icon: {
    width: 20,
    height: 20,
    tintColor: Platform.select({
      web: '$inputTextColor',
      default: '$accentTextColor',
    }),
  },
});

export default styles;
