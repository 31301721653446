import React from 'react';
import qs from 'qs';
import connect from '@sdv/connect';
import MediaModel from '../../../models/call.media';

function getModels(flux, props) {
    const models = {};

    if (props.userId && props.attendeeId) {
        const modelId = qs.stringify({ user: props.userId, attendee: props.attendeeId });
        models.mediaModel = flux.get(MediaModel, modelId);
    }

    return models;
}

function mapStoresToProps(models) {
    const props = {};

    if (models.mediaModel) {
        const state = models.mediaModel.store.getState();
        props.hasLocalVideo = !!state.localStream;
    }

    return props;
}

export default connect(
    getModels,
    mapStoresToProps
);

