import Actions from '../../actions/page/satellite';

class StoreSatellite {
    static displayName = 'config/page/satellite';

    constructor(flux) {
        const actions = flux.getActions(Actions);
        this.state = {};
        this.bindAction(actions.set, this.set);
    }

    set = (data) => {
        this.setState(data);
    }
}

export default StoreSatellite;
