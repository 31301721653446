import React from 'react';
import WinksModel from '@sdv/domain/dialogs.wink';
import connect from '@sdv/connect';

const WINKED_USERS = 'generated.dialogs-wink.can-wink.winked-users';

export default function createController(Component, options={}) {
    const userIdPropName = options.userIdPropName || 'userId';
    const winkType = options.winkType;
    const attendeeIdPropName = options.attendeeIdPropName || 'attendeeId';
    const canWinkPropName = options.canWinkPropName || 'canWink';

    class CanWink extends React.Component {
        static displayName = 'dialogs-wink.controller.can-wink';

        render() {
            const { ...props } = this.props;

            const canWinkUser = props[attendeeIdPropName] && props[WINKED_USERS] && props[WINKED_USERS].indexOf(props[attendeeIdPropName]) < 0;

            delete props[WINKED_USERS];

            return <Component {...props} { ... { [canWinkPropName]: canWinkUser }} />
        }

    }

    function getModels(flux, props) {
        const models = {};

        if (props[userIdPropName] && winkType) {
            models.winksModel = flux.get(WinksModel, props[userIdPropName]);
        }

        return models;
    }

    function mapStoresToProps(models) {
        const props = {};

        if (models.winksModel) {
            props[WINKED_USERS] = models.winksModel.store.getState()[winkType] || [];
        }

        return props;
    }

    function mapStoresToActions() {
        return {};
    }

    function shouldReconnect(props, newProps) {
        return props[userIdPropName] !== newProps[userIdPropName];
    }

    return connect(
        getModels,
        mapStoresToProps,
        mapStoresToActions,
        shouldReconnect
    )(CanWink);

}
