import React from 'react';
import PropTypes from 'prop-types';
import withConfigValue from 'dating-mobile/src/components/config-value';
import * as ROUTES from 'dating-mobile/src/routing/router/constants';

export default function bindNavigation(Component) {
  class Navigation extends React.Component {
    static displayName = 'components.placeholder.navigation';

    static propTypes = {
      navigate: PropTypes.func.isRequired,
      feedEnabled: PropTypes.bool,
      streamsEnabled: PropTypes.bool,
    };

    onCtaPressed = () => {
      const { feedEnabled, streamsEnabled, navigate } = this.props;

      if (feedEnabled) {
        navigate(ROUTES.FEED);
      } else if (streamsEnabled) {
        navigate(ROUTES.DISCOVERY);
      } else navigate(ROUTES.APP);
    };

    render() {
      return <Component {...this.props} onButtonPress={this.onCtaPressed} />;
    }
  }

  return withConfigValue(Navigation, {
    feedEnabled: 'features.feed-enabled',
    streamsEnabled: 'features.streams-enabled',
  });
}
