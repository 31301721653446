import Actions, { RESOURCE_ACCESS_STATUS } from './actions';

function createPaidResourceStore(path) {

    class Store {

        constructor(flux) {
            const actions = flux.getActions(Actions, path);
            this.state = {
                status: RESOURCE_ACCESS_STATUS.UNKNOWN,
                retryAfter: null
            };
            this.bindAction(actions.access, this.update);
            this.bindAction(actions.buy, this.update);
        }

        update = (payload) => {
            if (payload) {
                this.setState(payload);
            }
        };

    }

    Store.displayName = createPaidResourceStore.getDisplayName(path);

    return Store;

}

createPaidResourceStore.getDisplayName = function (path) {
    return `paid-resource.${path}`;
};

export default createPaidResourceStore;
