import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet } from 'react-native';
import Resources from 'dating-mobile/src/resources';
import testId from 'dating-mobile/src/utils/test-id';
import ImageButton from '../../../../components/navigation/views/image-button';
import TextButton from '../../../../components/navigation/views/text-button';
import Google from '../../../../components/place-autocomplete';

import styles from './styles';

class LocationEditing extends React.PureComponent {
  static displayName = 'user.edit.range-picker-editing';

  static propTypes = {
    navigation: PropTypes.object.isRequired,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    allowClear: PropTypes.bool,
    onlyCities: PropTypes.bool,
    onComplete: PropTypes.func,
  };

  static navigationOptions = ({ navigation, navigationOptions }) => ({
    title: navigation.getParam('title'),
    headerStyle: [navigationOptions.headerStyle, styles.header],
    headerTitleStyle: [navigationOptions.headerTitleStyle, styles.headerTitle],
    headerLeft: () => (
      <ImageButton
        image={Resources.images.backIcon()}
        tint={
          StyleSheet.flatten([
            navigationOptions.headerTitleStyle,
            styles.headerTitle,
          ]).color
        }
        onPress={() => navigation.pop()}
        {...testId(
          Resources.strings['navigation-bar-back-button-accessibility-label'],
        )}
      />
    ),
    headerRight: () =>
      navigation.getParam('clearButtonVisible') ? (
        <TextButton
          title={
            Resources.strings['location-editing-screen-clear-button-title']
          }
          tint={
            StyleSheet.flatten([
              navigationOptions.headerTitleStyle,
              styles.headerTitle,
            ]).color
          }
          onPress={navigation.getParam('onClearButtonPress')}
        />
      ) : null,
  });

  componentDidMount() {
    const { navigation } = this.props;

    navigation.setParams({
      clearButtonVisible: this.getClearAllowed() && !!this.getValue(),
      onClearButtonPress: this.onClearButtonPressed,
    });
  }

  onlyCities() {
    const { navigation, onlyCities } = this.props;

    return onlyCities !== undefined
      ? onlyCities
      : navigation.getParam('onlyCities');
  }

  getValue = () => {
    const { navigation, value } = this.props;

    return value || navigation.getParam('value');
  };

  getPlaceholder = () => {
    const { navigation, placeholder } = this.props;

    return placeholder || navigation.getParam('placeholder');
  };

  getClearAllowed = () => {
    const { navigation, allowClear } = this.props;

    return allowClear || navigation.getParam('allowClear');
  };

  onClearButtonPressed = () => {
    const { navigation, onComplete } = this.props;
    const completion = onComplete || navigation.getParam('onComplete');

    if (completion) {
      completion({});
    }

    navigation.pop();
  };

  onPlaceSelected = value => {
    const { navigation, onComplete } = this.props;
    const completion = onComplete || navigation.getParam('onComplete');

    if (!value) {
      return;
    }

    let result = {};

    if (value.hasOwnProperty('city')) {
      result = { city: value.city, country: value.country };
    } else {
      result = { country: value.country };
    }

    if (value.hasOwnProperty('lat') && value.hasOwnProperty('lon')) {
      result.lat = value.lat;
      result.lon = value.lon;
    }

    if (completion) {
      completion(result);
    }

    navigation.pop();
  };

  render() {
    return (
      <View style={styles.container}>
        <Google
          inputContainerStyle={styles.input}
          style={styles.inputText}
          defaultValue={this.getValue()}
          geocode
          disableTypewritingSelect
          onlyCities={this.onlyCities()}
          placeholder={
            this.getPlaceholder() ||
            Resources.strings['location-editing-screen-placeholder']
          }
          placeholderTextColor={styles.placeholder.color}
          onSelect={this.onPlaceSelected}
          {...testId(
            Resources.strings['city-or-country-input-accessibility-label'],
          )}
        />
      </View>
    );
  }
}

export default LocationEditing;
