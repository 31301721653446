import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Text, View } from 'react-native';
import Button from 'dating-mobile/src/components/buttons/base';

import Checkbox from './checkbox-of-images';
import styles from './styles';

export default class UserPreferences extends PureComponent {
    static propTypes = {
        title: PropTypes.string,
        buttonTitle: PropTypes.string,
        onPress: PropTypes.func,
        options: PropTypes.array,
        selected: PropTypes.array,
        onToggle: PropTypes.func,
        numberOfColumns: PropTypes.number,
    };

    handleToggleOption = option => () => {
        const { onToggle } = this.props;

        if (onToggle) {
            onToggle(option);
        }
    };

    getOptions = () => {
        const { options, selected } = this.props;

        return options.map(({ id, image, text }) => {
            return {
                id,
                text,
                onPress: this.handleToggleOption(id),
                image,
                isActive: selected.includes(id),
            };
        });
    };

    render() {
        const { title, buttonTitle, onPress, numberOfColumns, selected } = this.props;

        return (
            <View style={styles.container}>
                <Text style={styles.title}>{title}</Text>
                <View style={styles.checkboxContainer}>
                    <Checkbox options={this.getOptions()} numberOfColumns={numberOfColumns} />
                </View>
                <Button
                    style={styles.button}
                    disabled={!selected || !selected.length}
                    title={buttonTitle}
                    onPress={onPress}
                />
            </View>
        );
    }
}
