import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        width: '100%',
        height: '100%',
        alignItems: 'flex-start',
        justifyContent: 'flex-start'
    },

    donation: {
        width: 130,
        height: 130,
        marginLeft: 0,
        marginTop: 0,
        marginBottom: 22,
    },

    userContainer: {
        height: 28,
        borderRadius: 14,
        paddingLeft: 3,
        alignItems: 'center',
        flexDirection: 'row',
        backgroundColor: '$shadowColor',
    },

    star: {
        marginLeft: 4,
        marginRight: 4
    },

    userName: {
        color: '$accentColor',
        fontFamily: '$brandFontFamily',
        fontSize: 14,
        fontWeight: 'bold'
    },

    message: {
        color: 'white',
        fontFamily: '$brandFontFamily',
        fontSize: 14,
        marginRight: 10
    }
});

export default styles;
