import React from 'react';
import PropTypes from 'prop-types';
import { withNavigation } from 'react-navigation';
import Navigator from 'dating-mobile/src/routing/navigator';
import { PHOTO } from 'dating-mobile/src/routing/router/constants';

export default function bindNavigation(Component) {
  class Navigation extends React.Component {
    static displayName = 'inbox.views.media-attachment.navigation';

    static propTypes = {
      navigation: PropTypes.object.isRequired,
      resource: PropTypes.object,
    };

    openResource = () => {
      const {
        navigation,
        resource: { mediaType, local, uri, preview },
      } = this.props;
      const isPhotoAttachment =
        mediaType.startsWith('image') || mediaType.startsWith('photo');

      if (isPhotoAttachment && navigation) {
        const parameters = local ? { source: { uri } } : { path: preview };

        navigation.navigate(PHOTO, parameters);
      } else if (!isPhotoAttachment) {
        const parameters = local ? { source: { uri } } : { path: uri };

        Navigator.showVideo(parameters);
      }
    };

    render() {
      return <Component {...this.props} openResource={this.openResource} />;
    }
  }

  return withNavigation(Navigation);
}
