import React from 'react';
import { Image, Text, View, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import { withLocationAlias } from 'dating-mobile/src/user/aliases/hocs';
import IfConfigValue from 'dating-mobile/src/components/config-value/if';
import { DistanceToUserView } from 'dating-mobile/src/user/common/views/distance-to-user';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';
import Resources from '../../../../resources';
import withUser from '../../../../models/user/controllers';
import styles from './styles';
import withMiscDataValue from '../../../../models/misc-data/controller/value';
import { CRITERIA_OPTIONS } from '../../../../models/misc-data/utils';

class ChatGalleryHeader extends React.PureComponent {
    static displayName = 'dialogs.messages.views.chat-gallery-header';
    static propTypes = {
        onPress: PropTypes.func,
        searchNearbyEnabled: PropTypes.bool,
        geoLocationAliasingEnabled: PropTypes.bool,
    };

    onPress = () => {
        if (this.props.onPress) {
            this.props.onPress();
        }
    };

    render() {
        let textToDraw = null;

        if (this.props.user && this.props.user.name) {
            textToDraw = this.props.user.name;
        }

        if (
            textToDraw &&
            this.props.user &&
            this.props.user.birthday &&
            this.props.user.birthday.age
        ) {
            textToDraw = `${textToDraw}, ${this.props.user.birthday.age}`;
        }

        const city = (this.props.user && this.props.user.city) || null;
        const countryISO = (this.props.user && this.props.user.country) || null;
        const userInfo = (this.props.user && this.props.user.about) || null;

        const attendeeId = this.props.user && this.props.user.id;

        return (
            <TouchableOpacity style={styles.container} onPress={this.onPress}>
                <View style={styles.infoContainer}>
                    <Text numberOfLines={2} ellipsizeMode="middle" style={styles.name}>
                        {textToDraw}
                    </Text>
                    <IfConfigValue path={'features.anonymous-using-enabled'} equalsTo={true}>
                        <Text numberOfLines={3} style={styles.userInfo}>
                            {userInfo}
                        </Text>
                    </IfConfigValue>
                    <IfConfigValue path={'features.search-nearby-enabled'} equalsTo={true}>
                        {!!city && !!countryISO && (
                            <Text style={styles.travel}>
                                <DistanceToUserView
                                    prefix={
                                        <Image
                                            source={Resources.images.geoLocationMarkIcon()}
                                            resizeMode={'cover'}
                                            style={styles.locateIcon}
                                        />
                                    }
                                    suffix={Resources.strings['distance-to-user-text-separator']}
                                    userId={this.props.id}
                                    locationAliasingEnabled={this.props.geoLocationAliasingEnabled}
                                />
                                <CountryNameView
                                    city={city}
                                    name={countryISO}
                                    userId={this.props.userId}
                                    identityId={attendeeId}
                                />
                            </Text>
                        )}
                    </IfConfigValue>
                </View>
                <Image style={styles.disclosure} source={Resources.images.disclosureIcon()} />
            </TouchableOpacity>
        );
    }
}

const CountryNameComponent = props => {
    let formattedText = '';
    if (props.city) {
        formattedText += props.city;
    }
    if (props.country) {
        if (formattedText.length > 0) {
            formattedText += ', ';
        }
        formattedText += props.country;
    }

    return <Text style={styles.travel}>{formattedText}</Text>;
};

const CountryNameComponentWithAlias = withIdentityId(withLocationAlias(CountryNameComponent));

const CountryNameView = withMiscDataValue(CountryNameComponentWithAlias, {
    dataType: CRITERIA_OPTIONS.COUNTRIES,
    valuePropName: 'country',
});

export default withUser(withLocationAlias(ChatGalleryHeader));
