import React from 'react';
import PropTypes from 'prop-types';
import { Image, Text, View } from 'react-native';
import styles from './styles';
import Resources from 'dating-mobile/src/resources';

export default class LetterTextView extends React.PureComponent {
    static displayName = 'inbox.views.flat-list.item.text.view';
    static propTypes = {
        numberOfLines: PropTypes.number.isRequired,
        textStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]).isRequired,
        text: PropTypes.string,
        meta: PropTypes.object
    };

    render() {
        const meta = this.props.meta;

        let photoSource = null;
        let videoSource = null;
        let text = this.props.text;

        if (meta) {
            let hasPhoto = meta.attachments && meta.attachments.find(it => it.mediatype && it.mediatype?.startsWith('image'));
            let hasVideo = meta.attachments && meta.attachments.find(it => it.mediatype && it.mediatype?.startsWith('video'));
            if (hasPhoto) {
                photoSource = Resources.images.photoContentIcon();
            }
            if (hasVideo) {
                videoSource = Resources.images.videoContentIcon();
            }
        }

        const textStyle = [this.props.textStyle || {}, (photoSource || videoSource) ? styles.textWithAttachment : {}];

        return (
            <View style={styles.container}>
                {
                    photoSource && (
                        <Image style={styles.image} source={photoSource}/>
                    )
                }
                {
                    videoSource && (
                        <Image style={styles.image} source={videoSource}/>
                    )
                }
                <Text
                    numberOfLines={this.props.numberOfLines}
                    style={textStyle}
                    accessibilityLabel={this.props.accessibilityLabel}
                    testID={this.props.testID}
                >
                    {text}
                </Text>
            </View>
        );
    };
}
