import React from 'react';
import Rate from 'react-native-rate';
import PropTypes from 'prop-types';
import ConfigBuilder from 'dating-mobile/src/app/config-builder';

export default function createController(Component) {
  class Controller extends React.Component {
    static displayName = 'rate-application.screen.controller';
    static propTypes = {
      onRateShowing: PropTypes.func.isRequired,
      dismiss: PropTypes.func.isRequired,
    };

    onRateButtonPress = () => {
      let options = {
        AppleAppID: ConfigBuilder.config.appId,
        GooglePackageName: ConfigBuilder.config.appId,
        preferInApp: true,
        openAppStoreIfInAppFails: false,
      };
      Rate.rate(options);
      this.props.onRateShowing && this.props.onRateShowing(true);
      this.props.dismiss && this.props.dismiss();
    };

    onLaterButtonPress = () => {
      this.props.onRateShowing && this.props.onRateShowing(false);
      this.props.dismiss && this.props.dismiss();
    };

    render() {
      return (
        <Component
          onRateButtonPress={this.onRateButtonPress}
          onLaterButtonPress={this.onLaterButtonPress}
        />
      );
    }
  }

  return Controller;
}
