import React from 'react';
import PropTypes from 'prop-types';
import Resources from 'dating-mobile/src/resources';
import ImageButton from '../../navigation/views/image-button';
import styles from './styles';
import { StyleSheet } from 'react-native';

function bindNavigation(Component, screenTitle) {
  class Navigation extends React.Component {
    static displayName = 'components.webview.screen.navigation';

    static propTypes = {
      navigation: PropTypes.object.isRequired,
    };

    close = closeModal => {
      const { navigation } = this.props;

      if (closeModal) {
        navigation.goBack();
      }

      navigation.pop();
    };

    setNavigationBarState = state => {
      const { navigation } = this.props;

      navigation.setParams(state);
    };

    render() {
      const { navigation } = this.props;

      return (
        <Component
          {...this.props}
          url={navigation.getParam('url')}
          request={navigation.getParam('request')}
          onRedirect={navigation.getParam('onRedirect')}
          onCancel={navigation.getParam('onCancel')}
          close={this.close}
          setNavigationBarState={this.setNavigationBarState}
        />
      );
    }
  }

  Navigation.navigationOptions = ({ navigation }) => {
    return {
      headerTitle: screenTitle,
      headerStyle: styles.header,
      headerTitleStyle: styles.headerTitle,
      headerLeft: () => (
        <ImageButton
          image={Resources.images.backIcon()}
          tint={StyleSheet.flatten(styles.headerTitle).color}
          onPress={navigation.getParam('onCloseButtonPress')}
        />
      ),
    };
  };

  return Navigation;
}

export default bindNavigation;
