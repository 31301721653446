import {
  RTCPeerConnection,
  RTCIceCandidate,
  RTCSessionDescription,
} from 'react-native-webrtc';

function Call(attendee, options, bus) {
  this.bus = bus;

  let establishing = false;
  const config = (options && options.media && options.media.call) || {};

  this._disconnectTimer = null;
  this._connection = null;
  this._renegotiation = false;
  this._ices = [];

  this.version = 1;
  this.remoteVersion = null;
  this.phase = 0;

  this._updateSignalingState = function() {
    if (!this._connection) {
      return;
    }

    const { signalingState } = this._connection;
    const iceState = this._connection.iceConnectionState;

    if (
      signalingState === 'stable' &&
      (iceState === 'connected' || iceState === 'completed')
    ) {
      establishing = false;
      this.bus.emit('command.chat.media.resync', attendee);
    }
  };

  this._updateConnState = function() {
    let state;

    if (!this._connection) {
      return;
    }

    state = this._connection.iceConnectionState;

    if (state === 'connected') {
      // Обратная совместимость со старыми приложениями
      if (
        !this.remoteVersion &&
        this.phase === 0 &&
        this._connection.localDescription.type === 'answer'
      ) {
        this._renegotiation = true;
      }

      establishing = false;
      this.setPhase(1);

      this.bus.emit('event.call.connected', attendee);
      this.bus.emit('command.chat.media.resync', attendee);
    }

    if (state === 'failed' || state === 'closed') {
      this.bus.emit('command.chat.media.stop', attendee);

      return;
    }

    if (state === 'disconnected') {
      this._disconnectTimer =
        this._disconnectTimer ||
        setTimeout(
          function() {
            this.bus.emit('command.chat.media.stop', attendee);
          }.bind(this),
          config.timeout,
        );

      return;
    }

    this._disconnectTimer && clearTimeout(this._disconnectTimer);
    this._disconnectTimer = null;
  };

  this._gotRemoteStream = function(event) {
    // TODO: переделать на персонализированную шину
    this.bus.emit('event.call.stream.remote.attached', attendee, event.stream);
  };

  this._gotIceCandidate = function(evt) {
    if (evt.candidate) {
      this._ices.push(evt.candidate);

      return;
    }

    // пустое свойство candidate - закончили получение кандидатов
    this._iceGathered && this._iceGathered(this._retrieveNetwork());
    this._iceGathered = null;
    this.bus.emit('event.call.ice.gathered', attendee);
  };

  this._createDescription = function() {
    this._connection.onicecandidate = this._gotIceCandidate.bind(this);

    if (this._connection.signalingState === 'have-remote-offer') {
      return this._connection.createAnswer();
    }

    return this._connection.createOffer({
      offerToReceiveAudio: true,
      offerToReceiveVideo: true,
    });
  };

  this._gotLocalDescription = function(description) {
    const offer = description;
    const answer = description;

    if (description.type === 'offer') {
      // Fixes an issue when call between Chrome and Firefox. and firefox offers 100 and 120 Payloads
      // Если кратко: каждый из браузеров назначает кодеку VP8 разный payload type и этот конфликт они не могут разрешить
      // https://bugs.chromium.org/p/webrtc/issues/detail?id=5450#
      if (
        offer.sdp.indexOf('a=rtpmap:120 VP8/90000') >= 0 &&
        offer.sdp.indexOf('a=rtpmap:100 VP8/90000') >= 0
      ) {
        offer.sdp = offer.sdp.replace(/a=rtpmap:120 VP8\/90000\r\n/i, '');
        offer.sdp = offer.sdp.replace(/a=rtcp-fb:120 nack\r\n/i, '');
        offer.sdp = offer.sdp.replace(/a=rtcp-fb:120 nack pli\r\n/i, '');
        offer.sdp = offer.sdp.replace(/a=rtcp-fb:120 ccm fir\r\n/i, '');
      }
    }

    this.bus.emit('event.call.sdp.created', attendee);

    return this._connection.setLocalDescription(description);
  };

  this._gotStream = function(stream) {
    this._connection && this._connection.addStream(stream);
    // TODO: переделать на персонализированную шину
    this.bus.emit('event.call.stream.local.attached', attendee, stream);
  };

  this._addCandidate = function(ice) {
    this._connection &&
      this._connection.addIceCandidate(new RTCIceCandidate(ice));
  };

  this.updateSource = function(source) {
    if (this._source === source) {
      return Promise.resolve();
    }

    this._source && this._source.close();
    this._source = source;

    // TODO: Pass options
    return source
      .get()
      .then(this._gotStream.bind(this))
      .catch(function() {
        return Promise.resolve();
      });
  };

  this.popIces = function() {
    const popped = this._ices;

    this._ices = [];

    return popped;
  };

  this.destroy = function() {
    this._source && this._source.close();
    this._connection.close();
    this._connection = null;
    this._renegotiation = false;
    this._ices = [];
    this.version = 1;
    this.remoteVersion = null;
    this.phase = 0;
    this._disconnectTimer && clearTimeout(this._disconnectTimer);
    this._disconnectTimer = null;
  };

  this._renegotiate = function(evt) {
    if (this.hasConnection()) {
      this._renegotiation = true;
    }
  };

  this._retrieveNetwork = function() {
    return {
      sdp: this._connection.localDescription,
    };
  };

  this._getNetwork = function() {
    return new Promise(
      function(resolve) {
        if (this._connection.iceGatheringState === 'complete') {
          resolve(this._retrieveNetwork());
        }

        this._iceGathered = resolve;

        setTimeout(
          function() {
            this._iceGathered &&
              this._connection &&
              this._iceGathered(this._retrieveNetwork());
            this._iceGathered = null;
          }.bind(this),
          1500,
        );
      }.bind(this),
    );
  };

  this.hasConnection = function() {
    return (
      this._connection.iceConnectionState === 'connected' ||
      this._connection.iceConnectionState === 'completed'
    );
  };

  this.isRenegotiationNeeded = function() {
    return this._renegotiation;
  };

  this.getSessionDescription = function(streamSource) {
    this._renegotiation = false;
    establishing = true;

    return this.updateSource(streamSource)
      .then(this._createDescription.bind(this))
      .then(this._gotLocalDescription.bind(this))
      .then(this._getNetwork.bind(this))
      .catch(error => console.log(error));
  };

  this.setSessionDescription = function(network) {
    let process = Promise.resolve();

    if (network.sdp) {
      establishing = true;

      process = process
        .then(
          this._connection.setRemoteDescription(
            new RTCSessionDescription(network.sdp),
          ),
        )
        .catch(error => console.log(error));
    }

    if (network.ice) {
      process = process
        .then(
          function() {
            network.ice.forEach(this._addCandidate.bind(this));
          }.bind(this),
        )
        .catch(error => console.log(error));
    }

    return process;
  };

  this.rollback = function() {
    return this._connection.setLocalDescription(
      new RTCSessionDescription({ type: 'rollback' }),
    );
  };

  this.setRemoteVersion = function(version) {
    this.remoteVersion = version;
  };

  this.setPhase = function(phase) {
    this.phase = phase;
  };

  this.isEstablishing = function() {
    return establishing;
  };

  this._connection = new RTCPeerConnection(config);
  this._connection.onaddstream = this._gotRemoteStream.bind(this);
  this._connection.oniceconnectionstatechange = this._updateConnState.bind(
    this,
  );
  this._connection.onsignalingstatechange = this._updateSignalingState.bind(
    this,
  );
  this._connection.onnegotiationneeded = this._renegotiate.bind(this);
}

export default Call;
