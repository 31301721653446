import EStylesSheet from 'react-native-extended-stylesheet';
import { I18nManager, Platform } from 'react-native';
import Color from 'color';

const styles = EStylesSheet.create({
  container: {
    marginBottom: 16,
    width: '100%',
  },

  imageContainer: {
    width: '100%',
    aspectRatio: Platform.OS === 'web' ? 0.505 : 1.98,
    justifyContent: 'center',
  },

  image: {
    transform: [
      {
        scaleX: I18nManager.isRTL ? -1 : 1,
      },
    ],
  },

  textContainer: {
    paddingHorizontal: 25,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },

  title: {
    color: '$accentTextColor',
    fontFamily: '$brandFontFamily',
    fontSize: 20,
    marginBottom: 10,
  },

  timer: {
    color: '$accentTextColor',
    fontFamily: '$brandFontFamily',
    fontSize: 24,
    marginBottom: 15,
  },

  button: {
    backgroundColor: '#ff623e',
    paddingHorizontal: 20,
    minWidth: 220,
  },

  buttonText: {
    color: '$accentTextColor',
    fontFamily: '$brandFontFamily',
    fontSize: 17,
    fontWeight: '500',
  },

  oldPrice: {
    textDecorationLine: 'line-through',
    fontSize: 15,
    color: () =>
      Color(EStylesSheet.value('$accentTextColor'))
        .alpha(0.64)
        .rgb()
        .string(),
  },

  hint: {
    color: '$secondaryTextColor',
    fontFamily: '$brandFontFamily',
    fontSize: 14,
    textAlign: 'center',
  },

  creditImg: {
    width: 20,
    marginLeft: 2,
  },

  buttonContent: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default styles;
