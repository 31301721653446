import React from 'react';
import PropTypes from 'prop-types';
import { BackHandler } from 'react-native';

import Controller from './controller';
import View from './view';

const Component = Controller(View);

export default class PushNearbyScreen extends React.Component {
  static displayName = 'user.free-start-messages.screen';

  static propTypes = {
    navigation: PropTypes.object,
  };

  static navigationOptions = () => ({
    headerShown: false,
    gestureEnabled: false,
  });

  componentDidMount() {
    this.backButtonHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      () => {
        return true;
      },
    );
  }

  componentWillUnmount() {
    this.backButtonHandler.remove();
  }

  complete = () => {
    const { navigation } = this.props;
    const onComplete =
      navigation.getParam('onComplete') || (() => navigation.pop());

    onComplete();
  };

  render() {
    return <Component complete={this.complete} />;
  }
}
