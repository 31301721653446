import React from 'react';
import PropTypes from 'prop-types';
import OpenAppSettings from 'react-native-app-settings';

export default function withNavigation(Component) {
  class Navigation extends React.Component {
    static displayName =
      'notifications.views.turn-on-push-notifications.navigation';

    static propTypes = {
      navigation: PropTypes.object.isRequired,
    };

    notNowPressed = () => {
      this.dismiss();
    };

    turnOnNotificationPressed = () => {
      this.dismiss();
      OpenAppSettings.open();
    };

    dismiss = () => {
      const { navigation } = this.props;

      navigation?.pop();
    };

    render() {
      return (
        <Component
          {...this.props}
          notNowPress={this.notNowPressed}
          turnOnNotificationPress={this.turnOnNotificationPressed}
        />
      );
    }
  }

  Navigation.navigationOptions = () => ({
    headerShown: false,
  });

  return Navigation;
}
