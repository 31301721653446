import React from 'react';

import withIdentityId from 'dating-mobile/src/models/identity/controller/id';
import withIsAnonymous from 'dating-mobile/src/models/user.roles/controller/is-anonymous';
import hasTag from 'dating-mobile/src/models/user.tags/controllers/has-tag';
import configValue from 'dating-mobile/src/components/config-value';
import withDevicePermissions from 'dating-mobile/src/models/device.permissions/controller';
import { USER_TAGS } from '@sdv/domain/user/tags/user-tags';

export default function withBagCounter(Component) {
    class Controller extends React.Component {
        static displayName = 'routing.views.account-tab-bar-item.controller';

        calculateCounter = () => {
            const { isAnonymous } = this.props;
            let counter = 0;

            if (isAnonymous) {
                counter += 1;
            }

            return counter;
        };

        render() {
            return <Component {...this.props} badge={this.calculateCounter()} />;
        }
    }

    return withIdentityId(
        withIsAnonymous(
            hasTag(
                configValue(withDevicePermissions(Controller), {
                    boostProfileEnabled: 'features.boost-profile-enabled',
                }),
                {
                    tag: USER_TAGS.BOOSTED,
                    userIdPropName: 'id',
                    hasTagPropName: 'userIsBoosted',
                    presencePropName: 'isPresence',
                },
            ),
        ),
        'id',
    );
}
