import React from 'react';
import PropTypes from 'prop-types';
import { CHEERS_MODAL } from 'dating-mobile/src/routing/router/constants';

export default function bindNavigation(Component) {
  class Navigation extends React.Component {
    static displayName = 'dialogs.cheers.views.cheers-button.navigation';

    static propTypes = {
      attendeeId: PropTypes.string,
      navigation: PropTypes.object.isRequired,
    };

    onCheersButtonPressed = () => {
      const { navigation, attendeeId } = this.props;

      if (navigation)
        navigation.navigate(CHEERS_MODAL, {
          id: attendeeId,
        });
    };

    render() {
      return <Component {...this.props} onPress={this.onCheersButtonPressed} />;
    }
  }

  return Navigation;
}
