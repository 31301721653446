
class SmilesActions {
    static displayName = 'dialogs-smiles'

    get = () => (dispatch, flux) => {
        flux.api.dialogs.smiles.list((error, smiles) => {
            if (error) {
                dispatch(null, error);
                return;
            }
            dispatch(smiles);
        })
    };

}

export default SmilesActions;
