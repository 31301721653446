class MailboxStore {

    /**
     * @param {Alt} flux
     * @param {MailboxActions} actions
     */
    constructor(flux, actions) {
        this.state = {
            letters: [],
            endReached: false
        };

        this.letters = new Map();

        this.eventInbox = flux.letterEventInbox;
        this.eventInbox.addOnLetterAddedListener(this.onNewLetterAdded.bind(this));
        this.eventInbox.addOnLetterReadListener(this.onLetterRead.bind(this));

        this.bindAction(actions.getLetters, this.onGotLetters);
    }

    /**
     * Return store state
     * @return {{
            omit,
            letters,
            endReached
        }}
     */
    getState() {

    }

    /**
     * @param result Result
     * @param {Letter[]} result.letters A collection of letters
     * @param {number} result.omit A number of omitted letters
     * @param {number} result.select A number of selected letters
     */
    onGotLetters = (result) => {
        if (result) {
            for (let letter of result.letters) {
                this.letters.set(letter.id, letter);
            }

            this.setState({
                letters: [...this.letters.values()],
                endReached: result.endReached
            });
        }
    };

    onNewLetterAdded(letter) {
        this.letters.set(letter.id, letter);

        this.updateModel();
    }

    onLetterRead(letter) {
        if (this.letters.has(letter.id)) {
            const { ...stored } = this.letters.get(letter.id);
            stored.read = true;
            this.letters.set(letter.id, stored);

            this.updateModel();
        }
    }

    addNewLetterOrderedByTime(letter) {
        const copy = new Map();
        let inserted = false;
        this.letters.forEach((savedLetter, letterId) => {
            if (!inserted && new Date(savedLetter.timestamp) < new Date(letter.timestamp)) {
                copy.set(letter.id, letter);
                inserted = true;
            }
            copy.set(letterId, savedLetter);
        });
        if (!inserted) {
            copy.set(letter.id, letter);
        }
        this.letters = copy;
    }

    /**
     * @protected
     */
    updateModel = () => {
        this.setState({
            letters: [...this.letters.values()]
        });
    }
}

export default MailboxStore;
