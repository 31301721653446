import { BehaviorSubject } from 'rxjs';
import UserModel from '@sdv/domain/user/model';
import flux from '@sdv/domain/app/flux';
import '@sdv/commons/rx/store';

import {
    map
} from 'rxjs/operators';

class UserNameEditing {

    constructor(userId) {
        this.userId = userId;
        this.model = flux.get(UserModel, this.userId);

        this.nameSubject = new BehaviorSubject('');
        this.name = this.nameSubject;

        this.valid = this.name.pipe(
            map(name => !!name && name.trim())
        )
    }

    save() {
        this.model.actions.patch({ name: this.nameSubject.value });
    }

}

export default UserNameEditing;
