import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Resources from 'dating-mobile/src/resources';

import Preferences from '../../utils/preferences';
import { USER_GOALS, PREFERENCE_TYPE } from '../../utils/preferences-types';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';

const imagesMap = {
    fun: require('./assets/fun.jpg'),
    date: require('./assets/date.jpg'),
    longTerm: require('./assets/term.jpg'),
};

function getOptions() {
    return [
        {
            id: USER_GOALS.fun,
            image: imagesMap.fun,
            text: Resources.strings['onboarding-screen-look-for-option-fun-text'],
        },
        {
            id: USER_GOALS.date,
            image: imagesMap.date,
            text: Resources.strings['onboarding-screen-look-for-option-date-text'],
        },
        {
            id: USER_GOALS.longTerm,
            image: imagesMap.longTerm,
            text: Resources.strings['onboarding-screen-look-for-option-term-text'],
        },
    ];
}

export default function createController(Component) {
    class Controller extends PureComponent {
        static displayName = 'stepped-registration.screens.user-goals.controller';

        static propTypes = {
            onComplete: PropTypes.func.isRequired,
            id: PropTypes.string.isRequired,
        };

        state = {
            selected: [],
        };

        constructor(props) {
            super(props);

            this.options = getOptions();
        }

        handleToggle = id => {
            const { selected } = this.state;

            const isSelected = selected.includes(id);

            if (isSelected) {
                this.setState({
                    selected: selected.filter(option => option !== id),
                });
            } else {
                this.setState({
                    selected: [...selected, id],
                });
            }
        };

        submit = () => {
            const { selected } = this.state;
            const { onComplete, id } = this.props;

            if (selected && selected.length) {
                Preferences.shared(id)
                    .savePreference(PREFERENCE_TYPE.userGoals, selected)
                    .subscribe(onComplete, onComplete);
            }
        };

        render() {
            const { selected } = this.state;

            if (!this.options) {
                return null;
            }

            return (
                <Component
                    selected={selected}
                    options={this.options}
                    onToggle={this.handleToggle}
                    onButtonPress={this.submit}
                />
            );
        }
    }

    return withIdentityId(Controller, 'id');
}
