import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
  $hasBottomLine: true,
  $contentBackgroundColor: '$primaryBackgroundColor',
  $underHeader: true,
  $matchHeight: 'auto',
  $height: 80,

  matchNotificationStyleModifier: {
    height: 'auto',
    marginTop: 15,
  },

  container: {
    position: 'absolute',
    top: 0,
    opacity: 0,
    flexDirection: 'column',
    alignItems: 'stretch',
    width: '100%',
  },

  content: {
    marginLeft: 20,
    marginRight: 20,
    backgroundColor: '$contentBackgroundColor',
    height: '$height',
    // Should be also changes in incoming-call, unanswered-message
    borderRadius: 10,
    shadowColor: 'black',
    shadowRadius: 8,
    shadowOpacity: 0.15,
    shadowOffset: { width: 0, height: 0 },
    elevation: 8,
  },

  bottomLineContainer: {
    position: 'absolute',
    width: '100%',
    bottom: 4,
    left: 0,
    flexDirection: 'column',
    alignItems: 'center',
  },

  bottomLine: {
    height: 3,
    width: 35,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    borderRadius: 1.5,
  },
});

export default styles;
