import View from './view';
import withNavigation from './navigation';
import withController from './controller';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';
import withUser from 'dating-mobile/src/models/user/controllers';
import withConfigValue from 'dating-mobile/src/components/config-value';
import withImagePicker from 'dating-mobile/src/components/image-picker';

export default withNavigation(
    withIdentityId(
        withUser(
            withImagePicker(
                withController(
                    withConfigValue(
                        View,
                        {
                            smilesEnabled: 'features.smiles-enabled'
                        }
                    )
                ),
                {
                    mediaType: 'mixed',
                    durationLimit: 15
                }
            ),
            {
                userIdPropName: 'recipientId',
                resultPropName: 'recipient'
            }
        ),
        'senderId'
    )
);
