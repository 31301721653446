import React from 'react';
import {
  View,
  Text,
  FlatList,
  Image,
  TouchableOpacity,
  Keyboard,
} from 'react-native';
import PropTypes from 'prop-types';
import styles from './styles';

import CreateScreen from './index';
import UserController from '../../../models/user/controllers';
import DictValuesController from '../../../models/misc-data/controller';

import Google from '../../../components/place-autocomplete';
import TextButton from '../../../components/navigation/views/text-button';
import ImageButton from '../../../components/navigation/views/image-button';
import Resources from '../../../resources';

const TravelDestinationTextComponent = ({
  country,
  city,
  dictValues,
  ...props
}) => {
  let countryName = country;

  if (country && dictValues) {
    countryName = dictValues[country];
  }

  const place = `${city || ''}${
    city && countryName ? `, ${countryName}` : countryName || ''
  }`;

  return <Text {...props}>{place}</Text>;
};

TravelDestinationTextComponent.propTypes = {
  country: PropTypes.string,
  city: PropTypes.string,
  dictValues: PropTypes.object,
};

const TravelDestinationText = DictValuesController(
  TravelDestinationTextComponent,
);

class ProfileTravel extends React.Component {
  static displayName = 'user.travels.profile';

  static contextTypes = {};

  static propTypes = {
    navigation: PropTypes.object.isRequired,
    addTrip: PropTypes.func.isRequired,
    deleteTrip: PropTypes.func.isRequired,
    user: PropTypes.object,
  };

  state = {
    hideList: false,
  };

  componentDidMount() {
    const { navigation } = this.props;

    if (navigation) {
      navigation.setParams({
        title: 'New Travel Destination',
        headerRight: () => (
          <TextButton title="Create" disabled onPress={this.create} />
        ),
        headerLeft: () => (
          <ImageButton
            image={Resources.images.backIcon()}
            onPress={() => navigation.pop()}
          />
        ),
      });
    }
  }

  create = () => {
    const { addTrip } = this.props;
    const { selected } = this.state;

    if (selected && selected.location) {
      if (addTrip) {
        addTrip(selected.location);
      }

      Keyboard.dismiss();
      this.googleTextField?.clear();
      this.onSelectedChanged(null);
    }
  };

  deleteItem = index => {
    const { user, deleteTrip } = this.props;
    const travels = user?.meta?.travels || [];

    if (travels[index]) {
      if (deleteTrip) {
        deleteTrip(travels[index]);
      }
    }
  };

  renderItem = ({ item, index }) => {
    const name = `${item.city}:${item.country}`;

    return (
      <View key={name} style={styles.item}>
        <TravelDestinationText
          style={styles.list}
          {...item}
          dataType="countries"
        />

        <TouchableOpacity onPress={() => this.deleteItem(index)}>
          <Image style={styles.image} source={Resources.images.closeIcon()} />
        </TouchableOpacity>
      </View>
    );
  };

  onSelectedChanged = value => {
    this.setState(
      {
        selected:
          (value && {
            name: value.name,
            location: {
              country: value.country,
              city: value.city,
            },
          }) ||
          null,
      },
      () => {
        const { navigation } = this.props;
        const { selected } = this.state;

        if (navigation) {
          navigation.setParams({
            headerRight: () => (
              <TextButton
                title="Create"
                disabled={!selected}
                onPress={this.create}
              />
            ),
          });
        }
      },
    );
  };

  toggleList = state => {
    this.setState({
      hideList: state,
    });
  };

  render() {
    const { user } = this.props;
    const { hideList } = this.state;
    const travels = user?.meta?.travels || [];

    return (
      <View style={styles.profileContainer}>
        <View style={styles.profileInputContainer}>
          <Google
            inputContainerStyle={styles.profileInput}
            ref={ref => {
              this.googleTextField = ref;
            }}
            style={styles.inputText}
            placeholder="City or Country"
            geocode
            disableTypewritingSelect
            onSelect={this.onSelectedChanged}
            onDropDownShown={this.toggleList}
          />
        </View>
        {travels.length > 0 && !hideList && (
          <Text style={styles.subTitle}>My Travel Destination</Text>
        )}
        {!hideList && (
          <FlatList
            data={travels}
            numColumns={1}
            renderItem={this.renderItem}
            scrollEnabled
            keyExtractor={item => `${item.city}:${item.country}`}
          />
        )}
      </View>
    );
  }
}

export default [UserController, CreateScreen].reduce(
  (component, controller) => {
    return controller(component);
  },
  ProfileTravel,
);
