import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 0,
    },

    status: {
        marginLeft: 40,
        marginRight: 40,
        marginTop: 10,
        textAlign: 'center',
        color: '$brandTextColor',
        fontFamily: '$brandFontFamily',
        fontWeight: '500',
        fontSize: 16,
        '@flavor lovinga': {
            fontWeight: 'normal'
        }
    },

    statusInputContainer: {
        flexDirection: 'row',
        marginLeft: 40,
        marginRight: 40,
        marginTop: 20,
        paddingBottom: 19,
        borderBottomWidth: 1,
        borderBottomColor: '$brandBorderColor'
    },

    statusInput: {
        flex: 1,
        color: '$brandTextColor',
        fontSize: 17,
        fontFamily: '$brandFontFamily',
        fontWeight: '600',
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        textAlign: 'center'
    },

    statusInputPlaceholder: {
        color: '$brandPlaceholderColor'
    },

    statusInputSelection: {
        color: '$accentColor'
    },

    statusInputKeyboardAppearance: {
        keyboardAppearance: '$keyboardAppearance'
    },

    stats: {
        width: '70%',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
    }
});

export default styles;
