import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    hiddenRow: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        backgroundColor: '$brandColor',
    },
    unblockButton: {
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        width: 80,
    },
    unblockButtonTitle: {
        color: 'white',
        fontFamily: '$brandFontFamily',
        fontSize: 14,
    },
    header: {
        backgroundColor: '$primaryBackgroundColor',
        borderBottomColor: '$primaryBackgroundColor',
        shadowColor: '$primaryBackgroundColor',
    },
    headerTitle: {
        color: '$primaryTextColor',
    },
});

export default styles;
