import EStyleSheet from 'react-native-extended-stylesheet';

const buttonSize = 110;

const styles = EStyleSheet.create({
    $animationEnabled: true,
    '@flavor lovinga': {
        $animationEnabled: false,
    },
    cheersButtonContainer: {
        width: buttonSize,
        height: buttonSize,
        borderRadius: buttonSize / 2,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '$accentColor',
        '@flavor lovinga': {
            backgroundColor: '$brandColor'
        }
    },
    cheersButtonIcon: {
        maxWidth: buttonSize,
        maxHeight: buttonSize,
    },
});

export default styles;
