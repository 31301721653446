// Модуль создания одного инстанса source

import Source from './source';

let source;

function get(config) {
  if (source) {
    return source;
  }

  source = new Source(config);

  return source;
}

export default get;
