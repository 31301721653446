import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  View,
  Text,
  TouchableOpacity,
  ActivityIndicator,
  ImageBackground,
  Image,
} from 'react-native';
import Resources from 'dating-mobile/src/resources';
import withAspectRatio from 'dating-mobile/src/components/aspect-ratio';

import styles from './styles';

const AspectRatioImage = withAspectRatio(ImageBackground);

export default class BoostProfileBanner extends PureComponent {
  static displayName = 'feed.views.boost-profile-banner.view';

  static propTypes = {
    onPress: PropTypes.func,
    isLoading: PropTypes.bool,
    style: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    subscriptionRequired: PropTypes.bool,
    image: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    useImageInBackground: PropTypes.bool,
  };

  renderWithBackgroundImage() {
    const {
      style,
      onPress,
      isLoading,
      subscriptionRequired,
      image,
    } = this.props;

    return (
      <TouchableOpacity style={style} onPress={onPress} disabled={isLoading}>
        <AspectRatioImage
          style={styles.imageContainer}
          imageStyle={styles.image}
          source={image}
        >
          {isLoading && (
            <View style={styles.overlay}>
              <ActivityIndicator />
            </View>
          )}
          <View style={styles.textContainerWithBackgroundImage}>
            <Text style={styles.title}>
              {
                Resources.strings[
                  subscriptionRequired
                    ? 'feed-screen-subscription-required-title'
                    : 'feed-screen-boost-title'
                ]
              }
            </Text>
            <Text style={styles.description}>
              {
                Resources.strings[
                  subscriptionRequired
                    ? 'feed-screen-subscription-required-description'
                    : 'feed-screen-boost-description'
                ]
              }
            </Text>
          </View>
        </AspectRatioImage>
      </TouchableOpacity>
    );
  }

  render() {
    const { image, useImageInBackground, style } = this.props;

    if (useImageInBackground) {
      return this.renderWithBackgroundImage();
    }

    return (
      <View style={[styles.container, style]}>
        <View style={styles.textContainer}>
          <Text style={styles.title}>
            {Resources.strings['feed-screen-discover-people-title']}
          </Text>
          <Text style={styles.description}>
            {Resources.strings['feed-screen-discover-people-description']}
          </Text>
        </View>
        <Image source={image} />
      </View>
    );
  }
}
