import React from 'react';
import { StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import Resources from 'dating-mobile/src/resources';
import ImageButton from '../../../components/navigation/views/image-button';
import styles from './styles';

function navigation(Component) {
  class Navigation extends React.Component {
    static displayName = 'user.screens.notifications-settings.navigation';

    static propTypes = {
      navigation: PropTypes.object.isRequired,
    };

    render() {
      return <Component {...this.props} />;
    }
  }

  Navigation.navigationOptions = () => ({
    headerTitle: Resources.strings['notification-setting-screen-title'],
    headerStyle: styles.header,
    headerTitleStyle: styles.headerTitle,
    headerBackImage: () => (
      <ImageButton
        image={Resources.images.backIcon()}
        tint={StyleSheet.flatten(styles.headerTitle).color}
      />
    ),
  });

  return Navigation;
}

export default navigation;
