const FETCH_COUNT = 50;

function createUserTaggedActions(id) {

    const { user, tag } = parseId(id);

    class Actions {

        get = () => (dispatch, flux) => {

            function fetchUsers(users) {
                flux.api.users.targeted.tagged[tag].get(user, {omit: users.length, select: FETCH_COUNT}, (error, res) => {
                    if (error) {
                        dispatch(null, error);
                        return;
                    }
                    const values = res && res['user-ids'] || [];
                    if (values) {
                        const updatedUsers = users.concat(values);
                        if (values.length < FETCH_COUNT) {
                            dispatch(updatedUsers);
                        } else {
                            fetchUsers(updatedUsers);
                        }
                    }
                });
            }

            fetchUsers([]);
        };

        actualize = (addedUser, removedUser) => (dispatch) => {
            dispatch({ new: addedUser, staled: removedUser });
        };

    }

    Actions.displayName = createUserTaggedActions.getDisplayName(id);

    return Actions;
}

export function getId(user, tag) {
    return `${user}:${tag}`;
}

export function parseId(id) {
    let keys;

    if (typeof id !== 'string' || (keys = id.split(':')).length !== 2) {
        throw new Error('UserTagged model should have id in format "{user}:{tag}"');
    }

    return {
        user: keys[0],
        tag: keys[1]
    };
}

createUserTaggedActions.getDisplayName = function (id) {
    return `user-tagged.${id}`;
};

export const TAGS = Object.freeze({
    FAVORITE: 'favorite',
    CONTACT: 'dialogs.known'
});

export default createUserTaggedActions;
