import React from 'react';
import PropTypes from 'prop-types';
import { View, FlatList } from 'react-native';
import Item from './item';
import styles from './styles';
import withAspectRatio from '../../../components/aspect-ratio';

const AspectRatioView = withAspectRatio(View);

export default class TipsView extends React.Component {
    static displayName = 'tips.views.tips';
    static propTypes = {
        tips: PropTypes.array,
        onTipSelect: PropTypes.func
    };

    renderHeader = () => {
        return (<View style={styles.header} />);
    };

    renderFooter = () => {
        return (<View style={styles.footer} />);
    };

    renderItem = ({item}) => {
        return (
            <AspectRatioView style={styles.item} key={item.id}>
                <Item tip={item} onPress={this.props.onTipSelect} />
            </AspectRatioView>
        );
    };

    render() {
        return (
            <View style={styles.container}>
                <FlatList
                    renderItem={this.renderItem}
                    data={this.props.tips || []}
                    keyExtractor={item => item.id}
                    ListHeaderComponent={this.renderHeader}
                    ListFooterComponent={this.renderFooter}
                    numColumns={1}
                />
            </View>
        );
    }
}
