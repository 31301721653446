import EventEmitter from 'eventemitter3';
import { NavigationActions } from 'react-navigation';

let navigator;
let videoDialogRef;
let alertDialogRef;
let cardPaymentRef;
const eventEmitter = new EventEmitter();

function getStackFromState(state) {
  let navigationState = state;

  if (!navigationState) {
    return [];
  }

  const result = [];

  while (navigationState.routes) {
    const route = navigationState.routes[navigationState.index];

    result.push(route.routeName);
    navigationState = route;
  }

  return result;
}

function setNavigator(navigatorRef) {
  navigator = navigatorRef;

  eventEmitter.emit('navigator-ready');
}

function getParams() {
  if (navigator) {
    return navigator.state.nav.routes[0].params;
  }

  return undefined;
}

function onNavigationStateChange(previous, current) {
  eventEmitter.emit('state-changed', {
    previousState: previous,
    state: current,
    previousStack: getStackFromState(previous),
    stack: getStackFromState(current),
  });
}

function currentStack() {
  return getStackFromState(navigator && navigator.state && navigator.state.nav);
}

function routeInStackWithName(name) {
  let navigationState = navigator && navigator.state && navigator.state.nav;

  if (!navigationState) {
    return undefined;
  }

  while (navigationState.routes) {
    const route = navigationState.routes[navigationState.index];

    if (route.routeName === name) {
      return route;
    }

    navigationState = route;
  }

  return null;
}

function navigate(routeName, params, action) {
  navigator.dispatch(
    NavigationActions.navigate({
      routeName,
      params,
      action,
    }),
  );
}

function pop() {
  navigator.dispatch(NavigationActions.back());
}

function listen(callback) {
  eventEmitter.addListener('state-changed', callback);
}

function unlisten(callback) {
  eventEmitter.removeListener('state-changed', callback);
}

function setVideoDialog(ref) {
  videoDialogRef = ref;
}

function showVideo(...args) {
  videoDialogRef.show(...args);
}

function setAlertDialog(ref) {
  alertDialogRef = ref;
}

function showAlert(...args) {
  alertDialogRef.show(...args);
}

function setCardPayment(ref) {
  cardPaymentRef = ref;
}

function showCardPayment(...args) {
  cardPaymentRef.show(...args);
}

function addOnNavigatorReadyListener(callback) {
  eventEmitter.addListener('navigator-ready', callback);
}

function removeOnNavigatorReadyListener(callback) {
  eventEmitter.removeListener('navigator-ready', callback);
}

export default {
  navigate,
  pop,
  setNavigator,
  onNavigationStateChange,
  showVideo,
  setVideoDialog,
  setAlertDialog,
  showAlert,
  setCardPayment,
  showCardPayment,
  currentStack,
  routeInStackWithName,
  listen,
  unlisten,
  addOnNavigatorReadyListener,
  removeOnNavigatorReadyListener,
  getParams,
};
