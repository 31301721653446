// @ts-ignore
import { toObservable } from '@sdv/domain/api';
// @ts-ignore
import flux from '@sdv/domain/app/flux';
import { Observable, Subject, merge } from 'rxjs';
import { singleton } from '@sdv/commons/utils/singleton';
import { map, shareReplay } from 'rxjs/operators';
import { transformId } from '@sdv/commons/utils/transform-id';
import { AnimatorLocationAliasesMap } from './types/animator-location-aliases-map';

const ANIMATOR_ID_PREFIX = 'userId:';

export class UserGeoAliasRepository {
    static shared = singleton(userId => new UserGeoAliasRepository(userId));

    aliases: Observable<AnimatorLocationAliasesMap>;

    updatedAliases: Subject<AnimatorLocationAliasesMap>;

    hasAliases: Observable<boolean>;

    constructor(private userId: string) {
        this.userId = userId;

        const loadedAliases: Observable<AnimatorLocationAliasesMap> = toObservable(
            flux.api.users.aliases.users.get,
            this.userId,
        );

        this.updatedAliases = new Subject();

        this.aliases = merge(loadedAliases, this.updatedAliases).pipe(
            map((users: AnimatorLocationAliasesMap) =>
                this.withoutKeyPrefixLocationAliasesMap(users),
            ),
            shareReplay(1),
        );

        this.hasAliases = this.aliases.pipe(
            map(animatorLocationMap => !!Object.keys(animatorLocationMap).length),
        );
    }

    setAliases = (locationAliasesMap: AnimatorLocationAliasesMap): Observable<void> => {
        return new Observable(observer => {
            const dataWithPrefixedKeys = this.withKeyPrefixLocationAliasesMap(locationAliasesMap);

            if (!Object.getOwnPropertyNames(dataWithPrefixedKeys).length) {
                const warning = 'UserGeoAliasRepository: There are no animator aliases to save';

                observer.error(warning);

                return;
            }

            flux.api.users.aliases.users.patch(this.userId, dataWithPrefixedKeys, () => {
                this.updatedAliases.next(dataWithPrefixedKeys);
                observer.next();
                observer.complete();
            });
        });
    };

    private withKeyPrefixLocationAliasesMap = (animatorLocationMap: AnimatorLocationAliasesMap) => {
        const animatorLocationKeys = Object.keys(animatorLocationMap);

        if (!animatorLocationKeys.length) {
            return animatorLocationMap;
        }

        return animatorLocationKeys.reduce((acc: AnimatorLocationAliasesMap, item) => {
            acc[`${ANIMATOR_ID_PREFIX}${item}`] = animatorLocationMap[item];

            return acc;
        }, {});
    };

    private withoutKeyPrefixLocationAliasesMap = (
        animatorLocationMap: AnimatorLocationAliasesMap | undefined,
    ): AnimatorLocationAliasesMap => {
        if (!animatorLocationMap) {
            return {};
        }

        const animatorLocationKeys = Object.keys(animatorLocationMap);

        return animatorLocationKeys.reduce((acc: AnimatorLocationAliasesMap, item) => {
            const startPosition = item.indexOf(ANIMATOR_ID_PREFIX) + ANIMATOR_ID_PREFIX.length;
            const animatorId = item.substr(startPosition, item.length);
            const transformedAnimatorId = transformId(animatorId);

            acc[transformedAnimatorId] = animatorLocationMap[item];

            return acc;
        }, {});
    };
}
