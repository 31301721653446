import { Platform } from 'react-native';
import { Observable } from 'rxjs';
import Navigator from 'dating-mobile/src/routing/navigator';
import { CREDIT_CARD } from 'dating-mobile/src/routing/router/constants';

export class CreditCardScreenOpener {
  constructor(id, invoice, reason) {
    this.invoice = invoice;
    this.reason = reason;
  }

  purchase = () => {
    return new Observable(observer => {
      const onComplete = success => () => {
        if (success) {
          observer.next({ success });
        } else {
          observer.next({ error: true });
        }
        observer.complete();
      };

      if (Platform.OS === 'web') {
        Navigator.showCardPayment({
          reason: this.reason,
          invoice: this.invoice,
          onComplete: onComplete(true),
          onClose: onComplete(false),
        });
      } else {
        Navigator.navigate(CREDIT_CARD, {
          reason: this.reason,
          invoice: this.invoice,
          onComplete: onComplete(true),
          onClose: onComplete(false),
        });
      }

      return () => {};
    });
  };
}
