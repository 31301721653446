import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { View, Text, Image, Platform } from 'react-native';

import TextInput from 'dating-mobile/src/components/text-input';
import Resources from 'dating-mobile/src/resources';
import testId from 'dating-mobile/src/utils/test-id';
import Button from 'dating-mobile/src/components/buttons/base';
import InputRow from 'dating-mobile/src/components/input-row';
import { ProfileVisibilitySwitcher } from 'dating-mobile/src/user/views/user-visibility-in-feed';
import MediaAdd from 'dating-mobile/src/user/edit/views/add-media';
import MediaView from 'dating-mobile/src/user/edit/views/media';
import Photo from 'dating-mobile/src/components/photo';
import withAutoScale from 'dating-mobile/src/components/auto-scale-image';

import styles from './styles';

const ScaledImage = withAutoScale(Image);

export default class ScreenView extends PureComponent {
  static displayName =
    'stepped-registration.screens.screens.photo-with-bio.view';

  static propTypes = {
    onSubmit: PropTypes.func,
    source: PropTypes.object,
    id: PropTypes.string.isRequired,
    user: PropTypes.object,
    thumbnailRequired: PropTypes.bool,
  };

  state = {
    about: '',
    isLoading: false,
  };

  onSubmit = () => {
    const { onSubmit } = this.props;
    const { about } = this.state;

    if (onSubmit) {
      onSubmit(about);
    }
  };

  updateAbout = about => {
    this.setState({ about });
  };

  loadingStart = () => this.setState({ isLoading: true });

  loadingEnd = () => this.setState({ isLoading: false });

  render() {
    const { id, user, thumbnailRequired } = this.props;
    const { about, isLoading } = this.state;
    const thumbnail = user && (user['thumbnail-pending'] || user.thumbnail);
    const hasThumbnail = !!thumbnail && !isLoading;
    const addThumbnailContainerStyle = hasThumbnail
      ? styles.addThumbnailContainerHidden
      : null;
    const uri = this.mediaAddRef && this.mediaAddRef.uri;
    const isWeb = Platform.OS === 'web';
    const actualThumbnailRequired = !isWeb && thumbnailRequired;

    return (
      <View style={styles.container}>
        <View>
          {!thumbnailRequired && !isWeb && (
            <Text style={styles.thumbnailDescription}>
              {Resources.strings['about-yourself-screen-add-thumbnail-purpose']}
            </Text>
          )}
          <View style={addThumbnailContainerStyle}>
            <MediaAdd
              style={styles.iconContainer}
              id={id}
              mediaType="photo"
              ref={ref => {
                this.mediaAddRef = ref;
              }}
              onLoadingStart={this.loadingStart}
              onLoadingEnd={this.loadingEnd}
            >
              <ScaledImage
                style={styles.icon}
                source={Resources.images.cameraBigIcon()}
              />
              {isWeb && (
                <Text style={styles.iconText}>
                  {
                    Resources.strings[
                      'about-yourself-screen-add-thumbnail-text'
                    ]
                  }
                </Text>
              )}
            </MediaAdd>
          </View>
          {hasThumbnail && !uri && (
            <MediaView
              style={styles.iconContainer}
              basename={thumbnail}
              id={id}
            />
          )}
          {hasThumbnail && !!uri && (
            <Photo style={styles.iconContainer} source={{ uri }} />
          )}
        </View>
        <View>
          <InputRow separator style={styles.aboutInputRow}>
            <TextInput
              style={styles.aboutInput}
              value={about}
              placeholder={
                Resources.strings['about-yourself-screen-text-about-title']
              }
              onChangeText={this.updateAbout}
            />
          </InputRow>
          <ProfileVisibilitySwitcher
            userId={id}
            disabled={actualThumbnailRequired && !hasThumbnail}
          />
        </View>
        <Button
          disabled={(actualThumbnailRequired && !hasThumbnail) || isLoading}
          title={Resources.strings['action-sheet-default-button-title']}
          onPress={this.onSubmit}
          style={styles.nextButton}
          {...testId('Next button')}
        />
      </View>
    );
  }
}
