import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        flexGrow: 1,
        backgroundColor: '#ffffff',
    },
    safeArea: {
        flexGrow: 1,
        paddingHorizontal: 46,
    },
    title: {
        fontSize: 32,
        fontFamily: '$brandFontFamily',
        fontWeight: 'bold',
        color: '#000000',
        textAlign: 'center',
        marginBottom: 68,
    },
    imageContainer: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
    image: {
        width: '100%',
    },
    button: {
        flexGrow: 1,
        justifyContent: 'flex-end',
        marginBottom: 30,
    },
});

export default styles;
