import 'react-native-gesture-handler';
import 'dating-mobile/src/extensions';

import { AppRegistry } from 'react-native';
import { enableScreens } from 'react-native-screens';
import EStyleSheet from 'react-native-extended-stylesheet';

import App from './app';
import ConfigBuilder from './config-builder';

enableScreens();

EStyleSheet.build(ConfigBuilder.theme);

AppRegistry.registerComponent('Dating', () => App);

AppRegistry.runApplication('Dating', {
  rootTag: document.getElementById('root'),
});

export default App;
