import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {},

    content: {
        flexDirection: 'column',
    },

    bubble: {
        backgroundColor: '$accentColor',
        flexDirection: 'row',
        alignItems: 'center',
        height: 64,
        borderRadius: 32,
        '@flavor dma': {
            height: 84,
            borderRadius: 20,
        },
        paddingRight: 24,
    },

    triangle: {
        alignSelf: 'center',
        tintColor: '$accentColor',
    },

    text: {
        fontSize: 17,
        color: '$accentTextColor',
        fontFamily: '$brandFontFamily',
        marginLeft: 24,
        '@flavor dma': {
            fontSize: 20,
            fontWeight: 'bold',
        },
    },

    sendIcon: {
        width: 24,
        height: 24,
        marginLeft: 15,
        tintColor: '$accentTextColor',
    },
});

export default styles;
