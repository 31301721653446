import React from 'react';
import PropTypes from 'prop-types';
import { View, Picker, Platform, Text } from 'react-native';
import styles from '../styles';

import DatePicker from '../../../../../components/date-picker';
import InputRow from '../../../../../components/input-row';
import SafePicker from '../../../../../components/safe-picker';
import Error from '../../../../../components/validation-error';
import ConfigValue from '../../../../../components/config-value';
import Resources from '../../../../../resources';

class UserEditGenderBirthday extends React.PureComponent {
    static displayName = 'user.edit.gender.birthday';
    static propTypes = {
        user: PropTypes.object.isRequired,
        onUpdate: PropTypes.func.isRequired,
        birthdayFirst: PropTypes.bool,
    };

    state = {};

    handlersChange = {};

    constructor(props) {
        super(props);

        ['birthday', 'gender'].forEach(key => {
            this.handlersChange[key] = this.handleChange(key);
        });
    }

    handleChange = key => async value => {
        if (value === 'placeholder') {
            return;
        }
        let state = {};

        if (key === 'birthday') {
            if (!value) {
                state = { [key]: null };
            } else if (UserEditGenderBirthday.getAge(value) < 18) {
                state = { error: 'You must be 18 years old or above' };
            } else {
                state = { [key]: value.toISOString() };
            }
        } else {
            state = { [key]: value };
        }

        this.setState(state, () => {
            this.props.onUpdate(UserEditGenderBirthday.displayName, this.state);
        });
    };

    onClose = () => {
        if (!this.state.gender) {
            this.setState({ gender: 'mal' }, () => {
                this.props.onUpdate(UserEditGenderBirthday.displayName, this.state);
            });
        }
    };

    static getAge(dateString) {
        const today = new Date();
        const birthDate = new Date(dateString);
        let age = today.getFullYear() - birthDate.getFullYear();
        let m = today.getMonth() - birthDate.getMonth();

        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    }

    render() {
        const { user } = this.props;
        const gender = this.state.gender || user.gender;
        const title = gender
            ? gender === 'mal'
                ? Resources.strings['gender-picker-man-item-text']
                : Resources.strings['gender-picker-woman-item-text']
            : Resources.strings['about-yourself-screen-gender-placeholder'];

        const dateString = this.state.birthday || (user.birthday && user.birthday['birth-date']);
        const date = dateString && new Date(dateString);
        const error = this.props.error;
        const genderPickerStyle = [
            styles.genderPicker,
            error && error.gender ? styles.genderPickerWithError : {},
        ];
        const minAge = new Date().dateWithYearOffset(-this.props.minAge);

        const dateInput = (
            <InputRow separator={true}>
                <DatePicker
                    useUniversalDatePicker={styles.$useUniversalDatePicker}
                    modal
                    style={styles.dateTitle}
                    containerStyle={styles.dateContainer}
                    date={date}
                    maxDate={minAge}
                    onSelect={this.handlersChange.birthday}
                    placeholderText={Resources.strings['about-yourself-screen-date-of-birth-text']}
                />
                <Error error={this.state.error || error.birthday} />
            </InputRow>
        );

        const text = Resources.strings['about-yourself-screen-gender-title'];
        const label = text && text.length > 0 ? text : null;

        const genderInput = (
            <InputRow separator={true} label={label}>
                <SafePicker
                    title={title}
                    isPlaceholder={!gender}
                    onDone={this.onClose}
                    style={genderPickerStyle}
                    titleStyle={styles.genderPickerTitle}
                    buttonContainerStyle={styles.genderPickerButtonContainerStyle}
                >
                    <Picker selectedValue={gender} onValueChange={this.handlersChange.gender}>
                        {Platform.OS === 'android' && (
                            <Picker.Item label={Resources.strings['gender']} value="placeholder" />
                        )}
                        <Picker.Item
                            label={Resources.strings['gender-picker-man-item-text']}
                            value="mal"
                        />
                        <Picker.Item
                            label={Resources.strings['gender-picker-woman-item-text']}
                            value="fem"
                        />
                    </Picker>
                </SafePicker>
                <Error error={error.gender} />
            </InputRow>
        );

        return this.props.birthdayFirst ? (
            <View style={styles.container}>
                {dateInput}
                {genderInput}
            </View>
        ) : (
            <View style={styles.container}>
                {genderInput}
                {dateInput}
            </View>
        );
    }
}

export default ConfigValue(UserEditGenderBirthday, { minAge: 'age-range.min-age' });
