import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { View, Text, Platform } from 'react-native';
import Resources from 'dating-mobile/src/resources';
import withConfigValue from 'dating-mobile/src/components/config-value';

import BenefitItem from './item';

import styles from './styles';

export class MembershipBenefits extends PureComponent {
  static displayName = 'payment.membership-benefits.premium-subscription';

  static propTypes = {
    unlimitedMessagesWithMembershipEnabled: PropTypes.bool,
    style: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.number,
      PropTypes.array,
    ]),
    badgeHidden: PropTypes.bool,
  };

  getBadge() {
    const { badgeHidden } = this.props;

    if (badgeHidden) {
      return null;
    }

    return (
      <View style={styles.badge}>
        <Text style={styles.badgeText}>
          {Resources.strings['memberships-screen-badge-hot']}
        </Text>
      </View>
    );
  }

  render() {
    const { style, unlimitedMessagesWithMembershipEnabled } = this.props;

    return (
      <View
        style={[
          styles.container,
          styles.extraMargin,
          unlimitedMessagesWithMembershipEnabled ? styles.big : styles.small,
          style,
        ]}
      >
        <BenefitItem
          title={
            Resources.strings[
              'memberships-screen-basic-filter-benefit-description'
            ]
          }
          icon={Resources.images.filterIcon()}
          containerStyle={styles.itemContainer}
        />
        {!!unlimitedMessagesWithMembershipEnabled && (
          <BenefitItem
            title={
              Resources.strings[
                'memberships-screen-unlimited-messages-benefit-description'
              ]
            }
            icon={Resources.images.newChatsIcon()}
            iconStyle={styles.iconWithoutTint}
            containerStyle={styles.itemContainer}
          />
        )}
        <BenefitItem
          title={
            Resources.strings[
              'memberships-screen-premium-special-badge-benefit-description'
            ]
          }
          icon={Resources.images.crownIcon()}
          containerStyle={styles.itemContainer}
        />
        <BenefitItem
          title={Resources.strings.formatString(
            Resources.strings[
              'memberships-screen-basic-more-profiles-views-benefit-description'
            ],
            5,
          )}
          icon={Resources.images.eyeIcon()}
          containerStyle={styles.itemContainer}
        />
        <View style={styles.badgeContainer}>
          {this.getBadge()}
          <BenefitItem
            title={
              <Text>
                <Text>
                  {
                    Resources.strings[
                      'memberships-screen-premium-location-search-0'
                    ]
                  }
                </Text>
                <Text style={styles.bold}>
                  {
                    Resources.strings[
                      'memberships-screen-premium-location-search-1'
                    ]
                  }
                </Text>
              </Text>
            }
            icon={Resources.images.locationIcon()}
            containerStyle={styles.itemWithBadgeContainer}
          />
        </View>
        {Platform.OS !== 'web' && (
          <View style={styles.badgeContainer}>
            {this.getBadge()}
            <BenefitItem
              title={
                <Text>
                  <Text style={styles.bold}>
                    {Resources.strings['memberships-screen-premium-calls-0']}
                  </Text>
                  <Text>
                    {Resources.strings['memberships-screen-premium-calls-1']}
                  </Text>
                </Text>
              }
              icon={Resources.images.callBenefitIcon()}
              containerStyle={styles.itemWithBadgeContainer}
            />
          </View>
        )}
      </View>
    );
  }
}

export const PremiumSubscriptionBenefits = withConfigValue(MembershipBenefits, {
  unlimitedMessagesWithMembershipEnabled:
    'features.unlimited-messages-with-membership-enabled',
});
