import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    $inputKeyboardAppearance: '$keyboardAppearance',
    $inputSelectionColor: '$brandTextColor',
    $inputPlaceholderColor: '$primaryPlaceholderColor',
    container: {
        width: '100%',
        height: '100%',
        backgroundColor: '$brandColor',
    },
    containerContent: {
        alignItems: 'center',
        flexGrow: 1,
    },
    content: {
        flexShrink: 1,
        alignSelf: 'stretch',
        justifyContent: 'center',
        paddingHorizontal: 20,
    },
    titleContainer: {
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
        marginVertical: 40,
    },
    title: {
        color: '$brandTextColor',
        fontFamily: '$brandFontFamily',
        fontWeight: 'bold',
        fontSize: 17,
    },
    logo: {
        marginTop: 10,
    },
    nameInput: {
        width: '100%',
        color: '$inputTextColor',
        fontSize: 17,
        fontWeight: '600',
        fontFamily: '$brandFontFamily',
        paddingVertical: 19,
        borderBottomColor: '$brandBorderColor',
        borderBottomWidth: 1,
    },
    topicsContainer: {
        width: '100%',
        marginBottom: 10,
    },
    topicsDescription: {
        color: '$brandTextColor',
        fontFamily: '$brandFontFamily',
        fontWeight: '600',
        fontSize: 17,
        marginTop: 36,
        marginBottom: 18,
    },
    buttonContainer: {
        width: '100%',
        paddingHorizontal: 40,
        marginBottom: 48,
    },
    buttonIcon: {
        width: 18,
        height: 18,
    },
    agreementTextStyle: {
        width: '100%',
        marginBottom: 10,
    },
});

export default styles;
