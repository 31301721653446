import {
  map,
  filter,
  switchMap,
  tap,
  shareReplay,
  distinctUntilChanged,
} from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import UserSession from '@sdv/domain/application/user-session';
import Session from '@sdv/domain/authorization/session';
import { Config } from '@sdv/domain/app/config';
import { singleton } from '@sdv/commons/utils/singleton';
import User from '@sdv/domain/user/model';
import flux from '@sdv/domain/app/flux';
import Persistence from '@sdv/domain/app/persistence';

const selectAds = ([gender, prevAds], adsType) =>
  gender === prevAds ? adsType.uni : gender;

export class CrossAppAds {
  static shared = singleton(() => new CrossAppAds());

  static feedAdsType = {
    uni: 'uni',
    mal: 'mal',
    fem: 'fem',
  };

  static streamsDiscoveryAdsType = {
    uni: 'uni',
    mal: 'mal',
    fem: 'fem',
  };

  constructor() {
    const previousFeedAdsKey = 'CrossAppAds.previousFeedAds';
    const previousStreamsDiscoveryAdsKey =
      'CrossAppAds.previousStreamsDiscoveryAds';
    const persistence = Persistence.shared();
    const config = Config.shared();
    const { numberOfVisits } = UserSession.shared();

    const previousFeedAds = persistence.rxValue(previousFeedAdsKey);
    const previousStreamsDiscoveryAds = persistence.rxValue(
      previousStreamsDiscoveryAdsKey,
    );

    const gender = Session.shared().userId.pipe(
      filter(Boolean),
      switchMap(userId => flux.get(User, userId).store.rxState()),
      map(user => user.gender || 'mal'),
      distinctUntilChanged(),
    );

    this.feedAds = numberOfVisits.pipe(
      switchMap(() => config.feedAdsEnabled.pipe(filter(Boolean))),
      switchMap(() =>
        combineLatest(gender, previousFeedAds).pipe(
          map(data => selectAds(data, CrossAppAds.feedAdsType)),
        ),
      ),
      tap(adsType =>
        persistence.rxStore(previousFeedAdsKey, adsType).subscribe(),
      ),
      shareReplay(1),
    );

    this.streamsDiscoveryAds = numberOfVisits.pipe(
      switchMap(() => config.streamsDiscoveryAdsEnabled.pipe(filter(Boolean))),
      switchMap(() =>
        combineLatest(gender, previousStreamsDiscoveryAds).pipe(
          map(data => selectAds(data, CrossAppAds.streamsDiscoveryAdsType)),
        ),
      ),
      tap(adsType =>
        persistence
          .rxStore(previousStreamsDiscoveryAdsKey, adsType)
          .subscribe(),
      ),
      shareReplay(1),
    );
  }
}
