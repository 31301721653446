import React from 'react';
import PropTypes from 'prop-types';
import { Platform, StyleSheet, View } from 'react-native';
import testId from 'dating-mobile/src/utils/test-id';
import ImageButton from 'dating-mobile/src/components/navigation/views/image-button';
import Resources from 'dating-mobile/src/resources';
import DatePicker from '../../../../components/date-picker';
import styles from './styles';

class DateValueEditing extends React.PureComponent {
  static displayName = 'user.edit.date-value-editing';

  static propTypes = {
    navigation: PropTypes.object.isRequired,
    onComplete: PropTypes.func,
    value: PropTypes.object,
    minDate: PropTypes.object,
  };

  static navigationOptions = ({ navigation, navigationOptions }) => ({
    title: navigation.getParam('title'),
    headerStyle: [navigationOptions.headerStyle, styles.header],
    headerTitleStyle: [navigationOptions.headerTitleStyle, styles.headerTitle],
    headerLeft: () => (
      <ImageButton
        image={Resources.images.backIcon()}
        tint={
          StyleSheet.flatten([
            navigationOptions.headerTitleStyle,
            styles.headerTitle,
          ]).color
        }
        onPress={() => {
          const goBack = navigation.getParam('goBack');

          if (goBack) {
            goBack();
          } else {
            navigation.pop();
          }
        }}
        {...testId('Back button')}
      />
    ),
  });

  constructor(props) {
    super(props);
    this.state = { date: this.getDefaultValue() };
  }

  componentDidMount() {
    const { navigation } = this.props;

    navigation.setParams({ goBack: this.goBack });
  }

  goBack = () => {
    const { navigation, onComplete } = this.props;
    const { date } = this.state;

    if (date !== this.getDefaultValue()) {
      const completion = onComplete || navigation.getParam('onComplete');

      completion(date);
    }

    navigation.pop();
  };

  getDefaultValue = () => {
    const { navigation, value } = this.props;

    return value || navigation.getParam('value');
  };

  getMinDate = () => {
    const { navigation, minDate } = this.props;

    return minDate || navigation.getParam('minDate');
  };

  onDateChanged = date => {
    this.setState({ date: date.toISOString() }, () => {
      if (Platform.OS === 'android' && !styles.$useUniversalDatePicker) {
        this.goBack();
      }
    });
  };

  dismiss = () => {
    if (Platform.OS === 'android') {
      this.goBack();
    }
  };

  render() {
    const defaultDate = this.getDefaultValue();
    const date = defaultDate && new Date(defaultDate);
    const minDate = this.getMinDate();

    return (
      <View style={styles.container}>
        <DatePicker
          onCancel={this.dismiss}
          modal={false}
          useUniversalDatePicker={styles.$useUniversalDatePicker}
          ignoreBackgroundClick
          containerStyle={styles.dateContainer}
          date={date}
          maxDate={minDate}
          onSelect={this.onDateChanged}
        />
      </View>
    );
  }
}

export default DateValueEditing;
