// @ts-ignore
import MessagesModel, { getId } from '@sdv/domain/dialogs.messages';
// @ts-ignore
import flux from '@sdv/domain/app/flux';
import { map } from 'rxjs/operators';

type Message = {
  meta?: {
    automation?: {
      product: string;
    };
  };
};

export class AutoMessageChecker {
  readonly hasOnlyAutoMessage: string;

  constructor(userId: string, attendeeId: string) {
    const messagesModel = flux.get(MessagesModel, getId(userId, attendeeId));

    this.hasOnlyAutoMessage = messagesModel.store
      .rxState()
      .pipe(
        map(
          ({ messages }: { messages: Message[] }) =>
            messages.length === 1 &&
            messages[0].meta?.automation?.product === 'notice',
        ),
      );
  }
}
