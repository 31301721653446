import { combineLatest, Subscription } from 'rxjs';
import { switchMap, filter, take, map } from 'rxjs/operators';
import Session from '@sdv/domain/authorization/session';
import {
  REVIEW_STATUS,
  UserReviewCollecting,
} from '@sdv/domain/rate-application/user-review-collecting';
import flux from '@sdv/domain/app/flux';

import Service from '../service';

export class RateAppTracker extends Service {
  static displayName = 'service.tracking.rate-app-tracker';

  componentDidMount() {
    this.subscribe();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  subscribe() {
    const userReviewCollecting = Session.shared().userId.pipe(
      filter(Boolean),
      map(userId => UserReviewCollecting.shared(userId)),
    );

    const modalShowedSubscription = combineLatest(
      Session.shared().userId,
      userReviewCollecting.pipe(
        switchMap(it => it.shouldShowReviewRequest),
        filter(Boolean),
        take(1),
      ),
    ).subscribe(
      ([userId]) => this.tackModalShowed(userId),
      () => {},
    );

    const modalClosedSubscription = combineLatest(
      Session.shared().userId,
      userReviewCollecting.pipe(
        switchMap(it => it.reviewRequestShowed),
        take(1),
      ),
    ).subscribe(
      ([userId, reviewStatus]) => {
        if (reviewStatus === REVIEW_STATUS.REVIEW_SENT) {
          this.tackRateButtonTapped(userId);
        } else if (reviewStatus === REVIEW_STATUS.FEEDBACK_SENT) {
          this.tackFeedbackSent(userId);
        } else if (reviewStatus === REVIEW_STATUS.DECLINED) {
          this.tackCloseButtonTapped(userId);
        }
      },
      () => {},
    );

    this.disposeBag = new Subscription();
    this.disposeBag.add(modalShowedSubscription);
    this.disposeBag.add(modalClosedSubscription);
  }

  unsubscribe() {
    if (this.disposeBag) {
      this.disposeBag.unsubscribe();
    }
  }

  tackModalShowed(userId) {
    if (userId) {
      flux.api.annals.add(userId, 'rate-app-modal-showed', {});
    }
  }

  tackRateButtonTapped(userId) {
    if (userId) {
      flux.api.annals.add(userId, 'rate-app-modal-rate-button-tapped', {});
    }
  }

  tackCloseButtonTapped(userId) {
    if (userId) {
      flux.api.annals.add(userId, 'rate-app-modal-close-button-tapped', {});
    }
  }

  tackFeedbackSent(userId) {
    if (userId) {
      flux.api.annals.add(userId, 'rate-app-modal-feedback-sent', {});
    }
  }
}
