
function createUserMediaStore(id) {

    class Store {

        constructor() {
            this.state = {};
        }

    }

    Store.displayName = createUserMediaStore.getDisplayName(id);

    return Store;

}

createUserMediaStore.getDisplayName = function (id) {
    return `dialogs.usermedia.${id}`;
};

export default createUserMediaStore;
