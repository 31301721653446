import MailboxActions from '../base/actions';
import displayName, { parseId } from './display-name';


function createActions(id) {

    let {userId, attendeeId} = parseId(id);

    class Actions extends MailboxActions {

        constructor() {
            super(displayName(id), userId, {
                participant: attendeeId,
                direction: null
            });
        }
    }

    Actions.displayName = displayName(userId);

    return Actions;
}

createActions.getDisplayName = displayName;

export default createActions;
