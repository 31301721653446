import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { View, Text, TouchableOpacity } from 'react-native';

import Resources from 'dating-mobile/src/resources';

import styles from './styles';

export default class BoostExtendBannerWoman extends PureComponent {
  static displayName =
    'components.purchase-promotion-banner.boost-extend-woman.view';

  static propTypes = {
    onExtendPress: PropTypes.func,
  };

  render() {
    const { onExtendPress } = this.props;

    return (
      <View style={styles.container}>
        <View style={styles.textContainer}>
          <Text style={styles.extendTitle}>
            {Resources.strings['my-chats-screen-boost-profile-ended-title']}
          </Text>
          <Text style={styles.extendSubtitle}>
            {Resources.strings['my-chats-screen-boost-profile-ended-subtitle']}
          </Text>
        </View>
        <TouchableOpacity style={styles.extendButton} onPress={onExtendPress}>
          <Text style={styles.extendButtonText}>
            {
              Resources.strings[
                'my-chats-screen-boost-profile-ended-button-text'
              ]
            }
          </Text>
        </TouchableOpacity>
      </View>
    );
  }
}
