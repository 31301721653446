import actions from './actions';
import store from './store';


export default {
    actions: actions,
    store: store
};

export { buildId } from './display-name'
