import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import CheersReminder from '@sdv/domain/streaming/cheers-reminder';

export default function createCheersButtonController(Component) {
    class CheersButtonController extends PureComponent {
        static displayName = 'dialogs.stream.views.cheers-button.controller';

        static propTypes = {
            streamerId: PropTypes.string.isRequired,
            userId: PropTypes.string.isRequired,
            onPress: PropTypes.func,
            animationDuration: PropTypes.number,
        };

        static defaultProps = {
            animationDuration: 600,
        };

        static contextTypes = {
            flux: PropTypes.object,
        };

        state = {
            animate: false,
        };

        componentDidMount() {
            this.subscribe();
        }

        componentWillUnmount() {
            this.unsubscribe();

            if (this.animationTimer) {
                clearInterval(this.animationTimer);
                this.animationTimer = null;
            }
        }

        componentDidUpdate(prevProps) {
            if (this.props.userId !== prevProps.userId || this.props.streamerId !== prevProps.streamerId) {
                this.unsubscribe();
                this.subscribe();
            }
        }

        subscribe() {
            const {
                userId,
                streamerId,
            } = this.props;

            const cheersReminder = new CheersReminder(this.context.flux, userId, streamerId);

            this.updateReminder = cheersReminder.updateReminder;
            this.subscription = cheersReminder.reminder.subscribe(this.startAnimation);
        }

        unsubscribe() {
            if (this.subscription) {
                this.subscription.unsubscribe();
            }
            this.updateReminder = null;
        }

        startAnimation = () => {
            this.setState({ animate: true });

            if (!this.animationTimer) {
                this.animationTimer = setTimeout(() => {
                    this.animationTimer = null;
                    this.setState({ animate: false });
                }, this.props.animationDuration);
            }
        };

        updateReminderOnPress() {
            this.updateReminder && this.updateReminder();
            return this.props.onPress && this.props.onPress();
        }

        render() {

            return (
                <Component
                    {...this.props}
                    animate={this.state.animate}
                    onPress={this.updateReminderOnPress.bind(this)}
                />
            );

        }

    }

    return CheersButtonController;
}
