import StreamActions from './actions';

function createStreamStore(id) {
  class StreamStore {
    constructor(flux) {
      const actions = flux.getActions(StreamActions, id);

      this.mediasoupClient = flux.mediasoupClient;
      this.state = {
        attached: null,
        audioMuted: this.mediasoupClient?.audioMuted,
        microphoneMuted: this.mediasoupClient?.microphoneMuted,
        pendingQueue: [],
      };

      this.bindAction(actions.update, this.actualize);
      this.bindAction(actions.join, this.join);
      this.bindAction(actions.broadcast, this.broadcast);
      this.bindAction(actions.leave, this.leave);
      this.bindAction(actions.stopBroadcast, this.leave);
      this.bindAction(actions.accept, this.removePendingStream);
      this.bindAction(actions.decline, this.removePendingStream);
      this.bindAction(
        actions.sendStreamAttachmentRequest,
        this.addOutgoingInvitation,
      );
      this.bindAction(actions.startPreview, this.join);
    }

    actualize(payload) {
      this.setState({ ...payload });
    }

    join() {
      this.mediasoupClient?.addListener('event.audio.muted', this.updateAudio);
      this.mediasoupClient?.addListener(
        'event.microphone.muted',
        this.updateMicrophone,
      );
      this.mediasoupClient?.addListener('event.tracks.added', this.updateVideo);
      this.mediasoupClient?.addListener(
        'event.costream.invitation.added',
        this.addPendingStream,
      );
      this.mediasoupClient?.addListener(
        'event.costream.invitation.removed',
        this.removeCostreamInvite,
      );
    }

    broadcast(guestId) {
      this.join();
      if (guestId) {
        this.setState({ outgoingInvite: guestId });
      }
    }

    leave() {
      this.mediasoupClient?.removeListener(
        'event.audio.muted',
        this.updateAudio,
      );
      this.mediasoupClient?.removeListener(
        'event.microphone.muted',
        this.updateMicrophone,
      );
      this.mediasoupClient?.removeListener(
        'event.tracks.added',
        this.updateVideo,
      );
      this.mediasoupClient?.removeListener(
        'event.costream.invitation.added',
        this.addPendingStream,
      );
      this.mediasoupClient?.removeListener(
        'event.costream.invitation.removed',
        this.removeCostreamInvite,
      );
      this.actualize({ video: null });
    }

    updateMicrophone = state => {
      if (state === this.state.microphoneMuted) {
        return;
      }
      this.setState({ microphoneMuted: state });
    };

    updateAudio = state => {
      if (state === this.state.audioMuted) {
        return;
      }
      this.setState({ audioMuted: state });
    };

    updateVideo = (receivedId, data) => {
      if (receivedId === id && data.payload) {
        this.actualize({ video: data.payload.toURL() });
      }
    };

    addPendingStream = (room, attached) => {
      if (
        room === id &&
        attached &&
        this.state.pendingQueue.indexOf(attached) === -1
      ) {
        const pendingQueue = this.state.pendingQueue.slice();

        pendingQueue.push(attached);
        this.setState({ pendingQueue });
      }
    };

    removePendingStream = () => {
      const pendingQueue = this.state.pendingQueue.slice();

      pendingQueue.shift();
      this.setState({ pendingQueue });
    };

    addOutgoingInvitation = guestId => {
      if (this.state.outgoingInvite !== guestId) {
        this.setState({ outgoingInvite: guestId });
      }
    };

    removeCostreamInvite = (room, invited) => {
      if (room === id && this.state.outgoingInvite === invited) {
        this.setState({ outgoingInvite: null });
      } else if (invited === id) {
        const pendingQueue = this.state.pendingQueue.slice();
        const index = pendingQueue.indexOf(room);

        if (index >= 0) {
          pendingQueue.splice(index, 1);
        }
        this.setState({ pendingQueue });
      }
    };
  }

  StreamStore.displayName = createStreamStore.getDisplayName(id);

  return StreamStore;
}

createStreamStore.getDisplayName = function(id) {
  return `dialogs-stream.${id}`;
};

export default createStreamStore;
