import Navigator from 'dating-mobile/src/routing/navigator';
import * as ROUTES from 'dating-mobile/src/routing/router/constants';

const isAuthorized = stack =>
  stack.includes(ROUTES.APP) ||
  stack.includes(ROUTES.SPECIAL_OFFER_MODAL) ||
  stack.includes(ROUTES.AUDIO_VIDEO_CALL_MODAL);

export default class AuthorizedDeepLinking {
  deepLinksQueue = [];

  constructor(deepLinking, flux) {
    this.flux = flux;
    this.deepLinking = deepLinking;
    this.deepLinkListeners = [];
    this.deepLinking.addDeepLinkListener(this.lastDeepLink);

    this.authorizedZoneIsActive = false;

    Navigator.addOnNavigatorReadyListener(this.navigatorReady);
  }

  navigatorReady = () => {
    this.authorizedZoneIsActive = isAuthorized(Navigator.currentStack());
    Navigator.listen(this.onScreenChanged);
  };

  lastDeepLink = (uri, payload) => {
    this.handleDeepLink(uri, payload);
  };

  handleDeepLink = (uri, payload) => {
    if (!this.authorizedZoneIsActive) {
      this.deepLinksQueue.push({ uri, payload });

      return;
    }

    this.deepLinkListeners.forEach(listener => listener(uri, payload));
  };

  addDeepLinkListener(listener) {
    if (this.deepLinkListeners.indexOf(listener) < 0) {
      this.deepLinkListeners.push(listener);
    }
  }

  removeDeepLinkListener(listener) {
    this.deepLinkListeners = this.deepLinkListeners.filter(
      item => item !== listener,
    );
  }

  onScreenChanged = ({ stack }) => {
    this.authorizedZoneIsActive = isAuthorized(stack);

    if (this.authorizedZoneIsActive) {
      this.deepLinksQueue
        .splice(0)
        .forEach(({ uri, payload }) => this.handleDeepLink(uri, payload));
    }
  };
}
