import React from 'react';
import PropTypes from 'prop-types';
import withConfigValue from 'dating-mobile/src/components/config-value';
import withIdentityId from 'dating-mobile/src/models/identity/controller/id';
import { RxController } from 'dating-mobile/src/components/rx-controller';
import Resources from 'dating-mobile/src/resources';
import {
  PAYMENT_PROCESSING,
  ROOT,
} from 'dating-mobile/src/routing/router/constants';
import { AutobuySettingsRepo } from '@sdv/domain/autobuy/autobuy-settings-repo';
import flux from '@sdv/domain/app/flux';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { CreditCardsRepo } from '@sdv/domain/payment/credit-cards-repo';
import merge from 'merge/merge';
import PaymentRequestApi from '../../utils/payment-request';

const MODE_CARD_PICKER = 0;
const MODE_CARD_FORM = 1;

function controller(Component) {
  class Controller extends RxController {
    static displayName = 'payment.screens.card.controller';

    static propTypes = {
      id: PropTypes.string,
      onComplete: PropTypes.func,
      reason: PropTypes.string.isRequired,
      invoice: PropTypes.object,
      backButtonPressed: PropTypes.object,
      close: PropTypes.func,
    };

    constructor(props) {
      super(props);
      this.state = {
        card: null,
        mode: MODE_CARD_PICKER,
        autobuySettings: null,
        isLoading: false,
      };

      this.cards = this.rxProps.pipe(
        map(it => it.userId),
        distinctUntilChanged(),
        switchMap(userId => {
          return CreditCardsRepo.shared(userId).cards();
        }),
      );
    }

    componentDidMount() {
      super.componentDidMount();
      const { backButtonPressed, close } = this.props;

      flux.api.annals.add(this.props.id, 'card-payment-form-opened', {
        timestamp: new Date(),
      });

      this.addSubscription(
        this.rxProps
          .pipe(
            map(it => [it.id, it.invoice]),
            distinctUntilChanged(
              (prev, cur) => prev[0] === cur[0] && prev[1] === cur[1],
            ),
            switchMap(it => {
              const [id, invoice] = it;

              return AutobuySettingsRepo.shared(id).proposedSettings(invoice);
            }),
          )
          .subscribe(settings => {
            this.setState({
              autobuySettings: settings,
            });
          }),
        () => {},
      );

      this.addSubscription(
        this.cards
          .pipe(
            map(cards => cards.length > 0),
            distinctUntilChanged(),
          )
          .subscribe(
            hasCards => {
              this.setState({ hasSavedCard: hasCards });
            },
            () => {},
          ),
      );
      if (backButtonPressed)
        this.addSubscription(
          backButtonPressed.subscribe(
            () => {
              if (
                this.state.hasSavedCard &&
                this.state.mode === MODE_CARD_FORM
              ) {
                this.setState({ mode: MODE_CARD_PICKER });
              } else if (close) {
                close(false, false);
              }
            },
            () => {},
          ),
        );
    }

    updateCard = card => {
      this.setState({ card });
    };

    onCardSelect = card => {
      flux.api.annals.add(this.props.id, 'card-payment-card-selected', {
        timestamp: new Date(),
      });

      this.updateCard(card);
    };

    onFormDirty = () => {
      flux.api.annals.add(this.props.id, 'card-payment-form-touched', {
        timestamp: new Date(),
      });
    };

    onStorageChange = ({ key, newValue }) => {
      if (key === 'payment-result' && newValue) {
        const { success } = JSON.parse(newValue);

        if (success) {
          this.setState({
            isLoading: false,
          });
          this.props.onComplete();
          this.props.close();
        } else {
          this.setState({
            error: true,
            isLoading: false,
          });
        }

        window.removeEventListener('storage', this.onStorageChange);
        window.localStorage.removeItem('payment-result');
      }
    };

    purchase = (card = this.state.card) => {
      this.setState({
        isLoading: true,
      });

      window.localStorage.setItem(
        'payment',
        JSON.stringify({
          userId: this.props.id,
          reason: this.props.reason,
          invoice: this.props.invoice,
          card,
          autobuySettings: this.state.autobuySettings,
        }),
      );
      window.open(`${window.location.origin}/${ROOT}/${PAYMENT_PROCESSING}`);

      window.addEventListener('storage', this.onStorageChange);
    };

    onAutobuyEnable = enabled => {
      const { autobuySettings } = this.state;
      const newAutobuySettings = Object.assign({}, autobuySettings);

      if (newAutobuySettings) {
        autobuySettings.enabled = enabled;
      }
      this.setState({
        autobuySettings: newAutobuySettings,
      });
    };

    onBrowserCardSelect = async () => {
      const METHODS = Object.values(this.props.paymentsMethods);
      const DETAILS = {
        label: Resources.strings['browser-card-purchase-default-label'],
        amount: {
          currency: 'USD',
          value: '1',
        },
      };
      let canMakeRequest;

      if (!this.paymentRequestApi) {
        this.paymentRequestApi = PaymentRequestApi.init(flux.api);
      }

      try {
        canMakeRequest = await this.paymentRequestApi.canMakePayment(METHODS, {
          total: DETAILS,
        });
      } catch (error) {
        canMakeRequest = false;
      }

      if (canMakeRequest) {
        this.paymentRequestApi
          .process(
            METHODS,
            this.paymentRequestApi.createDetails(
              merge(DETAILS, {
                price: this.props.invoice.price.includingTax,
              }),
            ),
          )
          .then(({ details }) =>
            this.purchase({
              holder: details.cardholderName,
              verification: details.cardSecurityCode,
              number: details.cardNumber,
              expDate: {
                month: details.expiryMonth,
                year: details.expiryYear.slice(2),
              },
            }),
          )
          .catch(() => {});
      }
    };

    openCardForm = () => {
      this.setState({ mode: MODE_CARD_FORM });
    };

    onTermsLinkPressed = () => {
      const { useLocalizedLinks, termsLink, openLink } = this.props;

      const link =
        (useLocalizedLinks && termsLink && Resources.strings[termsLink]) ||
        termsLink;

      if (openLink && link) {
        openLink(
          link,
          Resources.strings[
            'memberships-screen-terms-agreement-terms-link-text'
          ],
        );
      }
    };

    onSelfClose = () => {
      flux.api.annals.add(this.props.id, 'card-payment-form-closed', {
        timestamp: new Date(),
      });

      this.props.close(false, true);
    };

    render() {
      const purchaseEnabled = !!this.state.card;
      const cardPickerVisible =
        this.state.hasSavedCard && this.state.mode === MODE_CARD_PICKER;

      return (
        <Component
          {...this.props}
          onSelfClose={this.onSelfClose}
          onPurchasePress={this.purchase}
          onTermsLinkPressed={this.onTermsLinkPressed}
          onCardUpdate={this.updateCard}
          onFormDirty={this.onFormDirty}
          onCardSelect={this.onCardSelect}
          onBrowserCardSelect={this.onBrowserCardSelect}
          purchaseEnabled={purchaseEnabled}
          paymentException={this.state.error}
          cardPickerVisible={cardPickerVisible}
          onNewCardPress={this.openCardForm}
          autobuySettings={this.state.autobuySettings}
          onAutobuyEnable={this.onAutobuyEnable}
          isLoading={this.state.isLoading}
        />
      );
    }
  }

  return withConfigValue(withIdentityId(Controller, 'id'), {
    host: 'host',
    currency: 'currency.type',
    termsLink: 'links.terms',
    useLocalizedLinks: 'links.use-localized-links',
    paymentsMethods: 'payments-methods',
  });
}

export default controller;
