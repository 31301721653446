import React from 'react';
import PropTypes from 'prop-types';
import Resources from 'dating-mobile/src/resources';
import IdentityModel from '@sdv/domain/identity/model';

export const UNAUTHORIZED = 401;
export const FORBIDDEN = 403;

const UserHocController = Component => {
    class IdentityController extends React.Component {
        static contextTypes = {
            flux: PropTypes.object,
        };

        mounted = false;

        constructor(props, context) {
            super(props);
            this.model = context.flux.get(IdentityModel);
            this.modelState = this.model.store.getState() || {};
            this.state = {
                isLoading: false,
            };
        }

        componentDidMount() {
            this.mounted = true;
            this.model.store.listen(this.updateState);
        }

        componentWillUnmount() {
            this.mounted = false;
            this.model.store.unlisten(this.updateState);
        }

        auth = token => {
            return new Promise((resolve, reject) => {
                return this.model.actions.auth(token, error => {
                    if (error) {
                        return reject(error);
                    }

                    return resolve(this.model.store.getState());
                });
            });
        };

        signIn = (email, password) => {
            this.setError(null);
            this.setState({ isLoading: true });

            return new Promise((resolve, reject) => {
                return this.model.actions.loginUser(email, password, error => {
                    this.setState({ isLoading: false });

                    if (error) {
                        if (error.status === UNAUTHORIZED) {
                            error = new Error(
                                Resources.strings['sign-in-error-incorrect-credentials'],
                            );
                        } else if (error.status === FORBIDDEN) {
                            error = new Error('Forbidden');
                        } else {
                            error = { message: error.message || error.status };
                        }

                        this.setError(error.message);
                        return reject(error);
                    }

                    return resolve(this.model.store.getState());
                });
            });
        };

        signUp = (email, password) => {
            this.setError(null);
            this.setState({ isLoading: true });

            return new Promise((resolve, reject) => {
                return this.model.actions.signUp(email, password, error => {
                    this.setState({ isLoading: false });

                    if (error) {
                        if (error.status === 400 && error.src === 'email') {
                            error = new Error(
                                Resources.strings['sign-up-error-email-already-taken'],
                            );
                        } else if (!error.message && error.src) {
                            error = new Error(`${error.src} ${error.desc}`);
                        }

                        this.setError(error.message);
                        return reject(error);
                    }

                    return resolve(this.model.store.getState());
                });
            });
        };

        signUpAsAnonymous = () => {
            this.setError(null);

            return new Promise((resolve, reject) => {
                return this.model.actions.signUpAsAnonymous(error => {
                    if (error) {
                        error =
                            error.status === 400 && error.src === 'email'
                                ? new Error(Resources.strings['sign-up-error-email-already-taken'])
                                : error;

                        this.setError(error.message);
                        return reject(error);
                    }

                    return resolve(this.model.store.getState());
                });
            });
        };

        updatePassword = password => {
            return new Promise((resolve, reject) => {
                return this.model.actions.updatePassword(password, (error, response) => {
                    if (error) {
                        this.setError(
                            error.desc ? error.desc : Resources.strings['default-error-message'],
                        );

                        return reject(error);
                    }

                    return resolve(response);
                });
            });
        };

        logout = () => {
            return new Promise((resolve, reject) => {
                return this.model.actions.signOut(error => {
                    if (error) {
                        this.setError(
                            error.desc ? error.desc : Resources.strings['default-error-message'],
                        );

                        return reject(error);
                    }

                    return resolve();
                });
            });
        };

        recoverPassword = email => {
            return new Promise((resolve, reject) => {
                return this.model.actions.recover(email, error => {
                    if (error) {
                        this.setError(
                            error.desc ? error.desc : Resources.strings['default-error-message'],
                        );
                        return reject(error);
                    }

                    return resolve();
                });
            });
        };

        setError = error => {
            this.modelState.error = error;
            this.mounted && this.forceUpdate();
        };

        updateState = state => {
            this.modelState = {
                error: this.modelState.error,
                ...state,
            };
            this.mounted && this.forceUpdate();
        };

        render() {
            const { error, ...state } = this.modelState;

            return (
                <Component
                    {...this.props}
                    error={error}
                    identity={state}
                    updateIdentity={this.updateUser}
                    auth={this.auth}
                    signIn={this.signIn}
                    signUp={this.signUp}
                    signUpAsAnonymous={this.signUpAsAnonymous}
                    logout={this.logout}
                    recoverPassword={this.recoverPassword}
                    updatePassword={this.updatePassword}
                    isLoading={this.state.isLoading}
                />
            );
        }
    }

    IdentityController.displayName = 'identity.controller';

    return IdentityController;
};

export default UserHocController;
