import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        height: 25,
        borderRadius: 12.5,
        minWidth: 60,
        paddingLeft: 10,
        paddingRight: 10,
        alignItems: 'center',
        flexDirection: 'row'
    },

    containerWithShadow: {
        backgroundColor: '$shadowColor',
    },

    text: {
        color: '$primaryTextColor',
        fontSize: 10,
        fontFamily: '$brandFontFamily',
        fontWeight: 'bold',
        marginLeft: 5
    },

    textWithShadow: {
        color: 'white'
    },

    image: {
        tintColor: '$primaryTextColor'
    },

    imageWithShadow: {
        tintColor: 'white'
    }
});

export default styles;
