import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
    container: {
        flexShrink: 0,
    },
    controlBlock: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 15,
    },
    label: {
        fontSize: 14,
        color: '$brandTextColor',
        fontFamily: '$brandFontFamily',
        flexGrow: 1,
        flexShrink: 1,
    },
    switch: {
        flexShrink: 0,
        flexGrow: 0,
    },
    descriptionBlock: {},
    description: {
        fontSize: 12,
        color: '$infoTextColor',
        fontFamily: '$brandFontFamily',
    },
});

export default styles;
