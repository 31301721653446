import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Text } from 'react-native';

import { formatTimeNumericShort } from '../formatters';

export default class Timer extends PureComponent {
  static displayName = 'components.time.timer.view';

  static propTypes = {
    remainingTime: PropTypes.number,
    formatter: PropTypes.func,
  };

  static defaultProps = {
    formatter: formatTimeNumericShort,
  };

  render() {
    const { remainingTime, formatter, ...props } = this.props;

    return <Text {...props}>{formatter(remainingTime)}</Text>;
  }
}
